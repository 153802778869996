<template>
    <div class="CustomerHeroPropertiesLine">
        <MHeading
            class="CustomerHeroPropertiesLine__title"
            level="h4"
            variant="gray"
        >
            {{ title }}
        </MHeading>
        <div class="CustomerHeroPropertiesLine__value">
            <slot v-if="hasValue">
                {{ value }}
            </slot>
            <slot
                v-else
                name="fallback"
            >
                {{ __('common:not_filled') }}
            </slot>
        </div>
    </div>
</template>

<script lang="ts">
    import {defineComponent} from 'vue';

    export default defineComponent({
        components: {},
        props: {
            hasValue: {type: Boolean, default: true},
            title: {type: String, required: true},
            value: {type: String, default: undefined},
        },
    });
</script>

<style lang="scss" scoped>
    .CustomerHeroPropertiesLine {
        @apply tw-flex tw-flex-row tw-gap-2 tw-items-center md:tw-justify-start tw-justify-between;
    }

    .CustomerHeroPropertiesLine__title {
        @apply tw-shrink-0;
        @apply tw-w-32;
        @apply tw-truncate;
    }

    .CustomerHeroPropertiesLine__value {
        @apply tw-truncate;
    }
</style>

