import type {Ref} from 'vue';
import {computed} from 'vue';
import chroma from 'chroma-js';

export default function(
    color: Ref<string>,
    forecastStyle: Ref<boolean | undefined>,
    size: 'sm' | 'md' | 'lg' = 'lg',
    editable = false,
    loading: Ref<boolean> | null = null,
) {
    const isDark = computed(() => {
        return chroma(color.value).get('lab.l') < 75;
    });

    const needsContrast = computed(() => {
        return chroma(color.value).luminance() > 0.5;
    });

    const textColor = computed(() => {
        return needsContrast.value ? darkest.value : '#ffffff';
    });

    const forecastBackground = computed(() => {
        return chroma(color.value)
            .luminance(0.6)
            .saturate(0.2)
            .alpha(0.8);
    });

    const lighter = computed(() => {
        const newColor = chroma(color.value)
            .luminance(0.7)
            .saturate(1)
            .brighten(1);

        return newColor.luminance() >= 0.9
            ? newColor.luminance(0.9).saturate(0.5)
            : newColor; // Avoid white color
    });

    const darken = computed(() => {
        return chroma(color.value).darken(0.5);
    });

    const darkest = computed(() => {
        return chroma(color.value)
            .darken(1)
            .saturate(3);
    });

    const eventClass = computed(() => {
        let computedClass =
            'tw-flex tw-items-center tw-absolute tw-h-full tw-rounded-2xl animate printable tw-px-1';

        if (editable) {
            computedClass += ' tw-cursor-pointer tw-ring-gray-500 hover:tw-ring-2';
        }

        if (forecastStyle.value) {
            computedClass += ' tw-z-10 stripes';
        } else {
            computedClass += ' tw-z-20';
        }

        if (loading && loading.value) {
            computedClass += ' tw-animate-pulse tw-opacity-60';
        }

        return computedClass;
    });

    const eventStyle = computed(() => {
        let computedStyle = {
            backgroundColor: color.value,
            color: needsContrast.value ? darkest.value : textColor.value,
        };

        if (forecastStyle.value) {
            computedStyle = {
                backgroundColor: forecastBackground.value,
                color: darkest.value,
            };
        }

        return computedStyle;
    });

    const fullDayStickClass = computed(() => {
        let computedClass = 'tw-relative tw-h-3/4 tw-rounded-full tw-border';
        if (forecastStyle.value) {
            computedClass += ' stripes-small';
        } else {
            computedClass += ' tw-z-10';
        }

        return computedClass;
    });

    const fullDayStickStyle = computed(() => {
        return {
            ...eventStyle.value,
            borderColor: forecastStyle.value ? darken.value : lighter.value,
            width: size === 'lg' ? '8px' : '7px',
        };
    });

    const badgeClass = computed(() => {
        let computedClass =
            'tw-flex tw-items-center tw-rounded-full tw-shadow-sm tw-p-1';
        if (editable) {
            computedClass += ' tw-cursor-pointer hover:tw-ring-2 tw-ring-gray-500';
        }
        if (forecastStyle.value) {
            computedClass += ' tw-relative stripes';
        }

        return computedClass;
    });

    const badgeStyle = computed(() => {
        if (forecastStyle.value) {
            return {
                backgroundColor: forecastBackground.value,
                color: darkest.value,
            };
        }

        return {
            backgroundColor: color.value,
            color: needsContrast.value ? darkest.value : textColor.value,
        };
    });

    const iconClass = computed(() => {
        let computedClass =
            'tw-flex tw-items-center tw-justify-center tw-rounded-full';
        switch (size) {
            case 'sm':
                computedClass += ' tw-z-10 tw-text-sm tw-w-4 tw-h-4';
                break;
            case 'md':
                computedClass += ' tw-shrink-0 tw-z-10 tw-text-sm tw-w-4 tw-h-4';
                break;
            case 'lg':
                computedClass +=
                    ' tw-shrink-0 tw-z-10 tw-text-sm tw-w-6 tw-h-6';
                break;
        }

        if (loading && loading.value) {
            computedClass += ' tw-animate-spin ';
        }

        return computedClass;
    });

    const iconStyle = computed(() => {
        if (needsContrast.value) {
            return {
                backgroundColor: darken.value,
                color: lighter.value,
            };
        }

        return {
            backgroundColor: lighter.value,
            color: darkest.value,
        };
    });

    // STYLE NOT LINKED TO CSS PROPERTY

    const event = computed(() => {
        if (forecastStyle.value) {
            return {
                main: forecastBackground.value,
                alt: darkest.value,
            };
        }

        return {
            main: color.value,
            alt: isDark.value ? chroma(color.value).brighten(3.5) : chroma(color.value).darken(3),
        };
    });

    const icon = computed(() => {
        if (isDark.value) {
            return {
                main: darkest.value,
                alt: lighter.value,
            };
        }

        return {
            main: lighter.value,
            alt: darken.value,
        };
    });

    return {
        eventClass,
        eventStyle,
        fullDayStickClass,
        fullDayStickStyle,
        badgeClass,
        badgeStyle,
        iconClass,
        iconStyle,
        icon,
        event,
    };
}
