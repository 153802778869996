import type {Collection} from '@meekohq/lumos';
import {collect} from '@meekohq/lumos';
import type ISplittable from '@/modules/legacy/libs/periodSplitter/ISplittable';
import {HourValue} from '@/modules/legacy/libs/periodSplitter/HourValue';
import moment from 'moment';
import momentTz from 'moment-timezone';
import type {Moment} from 'moment/moment';

interface ITodayEventAndPeriodTimes {
    originStart: Moment;
    originEnd: Moment;
    eventStartTime: Moment;
    eventEndTime: Moment;
    periodStart: Moment;
    periodEnd: Moment;
}

export default class SliceEvent<TSplittable extends ISplittable> {
    protected _events: Collection<TSplittable>;
    protected _startAt: number | HourValue;
    protected _endAt: number | HourValue;

    public constructor(events: TSplittable[], startAt: number | HourValue, endAt: number | HourValue) {
        this._events = SliceEvent.sortEvents(collect(events));
        this._startAt = startAt;
        this._endAt = endAt;
    }

    protected static sortEvents<TSplittable extends ISplittable>(events: Collection<TSplittable>) {
        return events.sortBy('startedAt');
    }

    public slice(): TSplittable[] {
        const sliceEvents: TSplittable[] = [];

        for (const event of this._events.all()) {
            const newEvent = this.processItem(event);

            if (newEvent) {
                sliceEvents.push(newEvent);
            }
        }

        return sliceEvents;
    }

    protected processItem(event: TSplittable): TSplittable | false {
        if (this._startAt instanceof HourValue && this._endAt instanceof HourValue) {
            return this.sliceByHours(event, this._startAt, this._endAt);
        }

        if (typeof this._startAt === 'number' && typeof this._endAt === 'number') {
            return this.sliceByTimestamps(event, this._startAt, this._endAt);
        }

        return event;
    }

    protected sliceByTimestamps(event: TSplittable, startAt: number, endAt: number): TSplittable | false {
        if (
            (event.startedAt < startAt && event.endedAt < startAt)
            || (event.startedAt > endAt && event.endedAt > endAt)
        ) {
            return false;
        }

        if (event.startedAt < startAt) {
            event.startedAt = startAt;
        }

        if (event.endedAt > endAt) {
            event.endedAt = endAt;
        }

        return event;
    }

    protected sliceByHours(event: TSplittable, startAt: HourValue, endAt: HourValue): TSplittable | false {
        // Format data to have comparable times
        const {originStart, originEnd, eventStartTime, eventEndTime, periodStart, periodEnd}
            = this.getTodayTimeEventAndPeriod(event, startAt, endAt);

        if (
            (eventStartTime.unix() < periodStart.unix() && eventEndTime.unix() < periodStart.unix())
            || (eventStartTime.unix() > periodEnd.unix() && eventEndTime.unix() > periodEnd.unix())
        ) {
            return false;
        }

        if (eventStartTime.unix() < periodStart.unix()) {
            event.startedAt = originStart
                .hour(periodStart.hour())
                .minute(periodStart.minute())
                .second(periodStart.second())
                .unix();
        }

        if (eventEndTime.unix() > periodEnd.unix()) {
            event.endedAt = originEnd
                .hour(periodEnd.hour())
                .minute(periodEnd.minute())
                .second(periodEnd.second())
                .unix();
        }

        return event;
    }

    private getTodayTimeEventAndPeriod(
        event: TSplittable,
        startAt: HourValue,
        endAt: HourValue,
    ): ITodayEventAndPeriodTimes {
        // Convert event date to Moment object
        const originStart = moment.unix(event.startedAt).utc();
        const originEnd = moment.unix(event.endedAt).utc();

        // Create today Moment object with event times to be able to compare with period times
        const eventStartTime = moment.utc({
            hour: originStart.hour(),
            minute: originStart.minute(),
            second: originStart.second(),
        });
        const eventEndTime = moment.utc({
            hour: originEnd.hour(),
            minute: originEnd.minute(),
            second: originEnd.second(),
        });

        // Create today Moment object with period times
        const periodStart = startAt.getTimezone() !== null
            ? momentTz.tz(startAt.toObject(), startAt.getTimezone() as string).utc()
            : moment.utc(startAt.toObject());
        const periodEnd = endAt.getTimezone() !== null
            ? momentTz.tz(endAt.toObject(), endAt.getTimezone() as string).utc()
            : moment.utc(endAt.toObject());

        return {originStart, originEnd, eventStartTime, eventEndTime, periodStart, periodEnd};
    }
}
