import __ from '@/modules/app/utils/i18n-facade';

export type DocTemplatesDestination = {
    name: string;
    type: string;
    icon: string;
};

export default function(): DocTemplatesDestination[] {
    return [
        {
            name: __('organization:contract_and_estimate'),
            type: 'contract',
            icon: 'fas fa-file-signature',
        },
        {
            name: __('organization:staff_contract'),
            type: 'staff_contract',
            icon: 'fas fa-file-signature',
        },
        {
            name: __('common:kids_profiles'),
            type: 'kid',
            icon: 'fas fa-child-reaching',
        },
        {
            name: __('common:family_members_profiles'),
            type: 'family_member',
            icon: 'fas fa-user-tie',
        },
        {
            name: __('common:staffs_profiles'),
            type: 'staff',
            icon: 'fas fa-users',
        },
        {
            name: __('common:the_transactions'),
            type: 'transaction',
            icon: 'fas fa-money-check-edit-alt',
        },
        {
            name: __('organization:tax_certificate'),
            type: 'tax-certificate',
            icon: 'fas fa-stamp',
        },
        {
            name: __('organization:sepa_mandate'),
            type: 'sepa_mandate',
            icon: 'fas fa-stamp',
        },
    ];
}
