import Vue from 'vue';
import {cloneDeep, isArray} from 'lodash-es';

export default class CopyObject {
    public static copy(source, target, deleteInTarget = true) {
        const sourceKeys = Object.keys(source);
        const targetKeys = Object.keys(target);

        sourceKeys.forEach(k => {
            if (isArray(source[k])) {
                Vue.set(target, k, cloneDeep(source[k]));
                CopyObject.copy(source[k], target[k], deleteInTarget);
            } else if (CopyObject.isObject(source[k]) && CopyObject.isObject(target[k])) {
                // Recursive sync through objects
                CopyObject.copy(source[k], target[k], deleteInTarget);
            } else {
                Vue.set(target, k, cloneDeep(source[k]));
            }
        });

        if (deleteInTarget) {
            cloneDeep(targetKeys).reverse().forEach(k => {
                if (!sourceKeys.includes(k)) {
                    Vue.delete(target, k);
                }
            });
        }
    }

    protected static isObject(data) {
        return typeof data === 'object' && data !== null;
    }
}
