<template>
    <CListRow
        :variant="archived ? 'archived' : 'default'"
    >
        <CHStack
            align="center"
            distribute="between"
            gap="2"
        >
            <div class="tw-flex-1">
                <slot/>
            </div>
            <CHStack
                align="center"
                class="tw-w-24"
                distribute="end"
                gap="1"
            >
                <CTooltip v-if="shared">
                    <CCenter class="tw-text-purple-600 tw-bg-purple-400 tw-bg-opacity-25 tw-rounded-md tw-h-6 tw-w-6">
                        <i
                            aria-hidden="true"
                            class="fad fa-link"
                        />
                    </CCenter>
                    <template #content>
                        <CText>
                            {{ __('app:share_with_other_organization') }}
                        </CText>
                    </template>
                </CTooltip>
                <CTooltip v-if="archived">
                    <CCenter class="tw-text-orange-600 tw-bg-orange-400 tw-bg-opacity-25 tw-rounded-md tw-h-6 tw-w-6">
                        <i
                            aria-hidden="true"
                            class="fas fa-archive"
                        />
                    </CCenter>
                    <template #content>
                        <CText>
                            {{ __('common:archived') }}
                        </CText>
                    </template>
                </CTooltip>
                <slot name="action"/>
            </CHStack>
        </CHStack>
    </CListRow>
</template>

<script lang="ts">
    import {defineComponent} from 'vue';

    export default defineComponent({
        components: {},
        props: {
            shared: {type: Boolean, default: false},
            archived: {type: Boolean, default: false},
        },
        setup() {
            return {};
        },
    });
</script>

<style lang="scss" scoped>
    //
</style>
