import type {
    AuthenticationContract,
    CredentialsType,
    UserObjectType,
} from '@/modules/auth/utils/AuthenticationContract';
import route from '@/modules/legacy/libs/ziggy';
import {app, catcher, hydrateResources, MqlOperation, MqlUnauthorizedError, type ResponseObject} from '@meekohq/lumos';
import type UserModel from '@/modules/user/models/UserModel';
import type {AxiosInstance} from 'axios';
import useRum from '@/modules/app/composables/useRum';
import {Types} from '@/types';

export default class BasicAuthentication implements AuthenticationContract {
    protected readonly _localtorageKey = 'credentials';
    protected _legacyApi: AxiosInstance;

    constructor(legacyApi: AxiosInstance) {
        this._legacyApi = legacyApi;
    }

    public defineBeforeLoginCallback(callback: (credentials: CredentialsType) => Promise<boolean>): AuthenticationContract {
        this.beforeLoginCallback = callback;

        return this;
    }

    public defineAfterLoginCallback(callback: (user: UserObjectType, credentials: CredentialsType) => Promise<boolean>): AuthenticationContract {
        this.afterLoginCallback = callback;

        return this;
    }

    public defineAfterLogoutCallback(callback: () => Promise<void>): AuthenticationContract {
        this.afterLogoutCallback = callback;

        return this;
    }

    public async loginByEmail(email: string, password: string): Promise<UserModel | undefined> {
        const response = await this._legacyApi.post(route('login'), {
            email,
            password,
        });

        this.storeCredentials(
            response.data.meta.api_token,
            response.data.meta.legacy_token,
        );

        return await this.loginByCredentials();
    }

    public async supportLoginByEmail(email: string, token: string): Promise<UserModel | undefined> {
        const response = await this._legacyApi.post(route('login'), {
            email,
            password: 'easy-support',
            easysupporttoken: token,
        });

        this.storeCredentials(
            response.data.meta.api_token,
            response.data.meta.legacy_token,
        );

        return await this.loginByCredentials();
    }

    public async loginByCredentials(): Promise<UserModel | undefined> {
        if (!this.hasCredentials()) {
            await this.logout();

            return;
        }

        await this.migrateFromLegacyCredentials();

        const credentials = this.getCredentials() as CredentialsType;

        const beforeCallbackResult = await this.beforeLoginCallback(credentials);

        if (!beforeCallbackResult) {
            await this.logout();
            throw new Error('Login failed via callback.');
        }

        try {
            const userObject = await this.retrieveUser();

            const afterCallbackResult = await this.afterLoginCallback(userObject, credentials);

            if (!afterCallbackResult) {
                await this.logout();
                throw new Error('Login failed via callback.');
            }

            return userObject.userModel;
        } catch (e) {
            catcher().on(MqlUnauthorizedError, async () => {
                await this.logout();
            }).catch(e);
        }
    }

    public async logout() {
        this.deleteCredentials();

        await this.afterLogoutCallback();
    }

    public async retrieveUser() {
        // Retrieve user from API
        const result = await new MqlOperation<ResponseObject.ResourceObject>('user/me').run();
        const userModel = hydrateResources<UserModel>(result.content);

        const loadOrganizationPromise = userModel.organizations().load();
        const loadLegacyUserPromise = this._legacyApi.get(route('users.show', {user: userModel.getKey()}));

        await Promise.all([loadOrganizationPromise, loadLegacyUserPromise]);

        // Retrieve legacy user from API
        const legacyResponse = await loadLegacyUserPromise;

        return {
            userModel: userModel,
            legacyUser: legacyResponse.data,
        };
    }

    public hasCredentials(): boolean {
        return !!localStorage.getItem(this._localtorageKey);
    }

    public getCredentials(): CredentialsType | null {
        return JSON.parse(localStorage.getItem(this._localtorageKey) as string) as CredentialsType | null;
    }

    public storeCredentials(token: string, legacyToken: string): void {
        localStorage.setItem(
            this._localtorageKey,
            JSON.stringify({
                token: token,
                legacy_token: legacyToken,
            }),
        );
    }

    public deleteCredentials(): void {
        localStorage.removeItem(this._localtorageKey);
    }

    protected beforeLoginCallback: ((credentials: CredentialsType) => Promise<boolean>) = async () => true;

    protected afterLoginCallback: ((user: UserObjectType, credentials: CredentialsType) => Promise<boolean>) = async () => true;

    protected afterLogoutCallback: (() => Promise<void>) = async () => undefined;

    private async migrateFromLegacyCredentials() {
        let credentials = this.getCredentials() as any;

        // Reorganize credentials object
        if ('user' in credentials) {
            const token = credentials.api_token;
            const legacyToken = credentials.legacy_token;

            this.storeCredentials(token, legacyToken);
        }

        credentials = this.getCredentials() as CredentialsType;

        if (!credentials.token.includes('|')) {
            useRum().addAction('M_Login_With_Legacy_Token');

            app<AxiosInstance>(Types.Api).defaults.headers.common['Authorization'] = `Bearer ${credentials.token}`;

            const result = await new MqlOperation<{ token: string }>('auth/refresh-token').run();

            this.storeCredentials(result.content.token, credentials.legacy_token);
        }
    }
}
