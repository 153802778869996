<template>
    <div
        class="loader d-flex justify-content-center align-items-center"
        style="padding: 20px 0;"
    >
        <div
            class="circle d-flex align-items-center"
            :class="computedSize"
        >
            <i
                aria-hidden="true"
                class="far fa-sync fa-spin text-primary"
            />
            <span class="title">{{ title }}</span>
        </div>
    </div>
</template>

<script>
    export default {
        props: [
            'size',
            'light',
            'title',
            'shadow',
        ],
        computed: {
            computedSize() {
                let output = '';
                if (this.size === 'sm') {
                    output += ' sm';
                }

                if (this.shadow !== 'false') {
                    output += ' card-shadow-md';
                }

                if (this.light === 'true') {
                    output += ' light';
                }

                if (this.title) {
                    output += ' withTitle';
                }

                return output;
            },
        },
    };
</script>

<style lang="scss" scoped>
    .circle {
        text-align: center;
        border-radius: 100px;
        background: #ffffff;
        padding: 20px;

        &.light {
            background-color: #eef3f9;
        }

        i, svg {
            font-size: 2em;
        }

        &.withTitle {
            .title {
                margin-left: 15px;
                font-weight: 600;
                font-size: 18px;
            }
        }

        &.sm {
            padding: 18px;

            i, svg {
                font-size: 1.5em;
            }

            &.withTitle {
                .title {
                    font-size: 16px;
                }
            }
        }
    }
</style>
