<template>
    <CVStack gap="4">
        <SimulationFilters
            :day-separator-filter="daySeparatorFilter"
            :group-filter="groupFilter"
            :halfday-time-filter="halfdayTimeFilter"
            :planning-type-filter="planningTypeFilter"
            :simulation="simulation"
            :supervision-filter="supervisionFilter"
            :tag-filter="tagFilter"
            @update-day-separator-filter="daySeparatorFilter = $event"
            @update-group-filter="groupFilter = $event"
            @update-halfday-time-filter="halfdayTimeFilter = $event"
            @update-planning-type-filter="planningTypeFilter = $event"
            @update-supervision-filter="supervisionFilter = $event"
            @update-tag-filter="tagFilter = $event"
        />
        <SimulationListSkeleton v-if="loading"/>
        <CVStack
            v-else
            gap="4"
        >
            <WeekDays/>
            <CVStack
                v-if="simulation"
                gap="6"
            >
                <template v-for="(weeks, year) in summaries">
                    <Year
                        :key="'year-' + year"
                        :day-separator="daySeparatorFilter"
                        :halfday-time="halfdayTimeFilter"
                        :kids-group-id="groupFilterId"
                        :kids-planning-types="planningTypeFilter"
                        :kids-tag-ids="tagFilterIds"
                        :registrations="registrations"
                        :show-supervision="supervisionFilter"
                        :weeks="weeks"
                        :year="year"
                    />
                </template>
            </CVStack>
        </CVStack>
    </CVStack>
</template>

<script lang="ts">
    import type {PropType} from 'vue';
    import {computed, defineComponent, ref, watch} from 'vue';
    import type SimulationListModel from '@/modules/planning/models/SimulationListModel';
    import type KidsGroupModel from '@/modules/organization/models/KidsGroupModel';
    import SimulationFilters from '@/modules/planning/components/simulation/SimulationFilters.vue';
    import SimulationListSkeleton from '@/modules/planning/components/simulation/SimulationListSkeleton.vue';
    import WeekDays from '@/modules/planning/components/simulation/Week/WeekDays.vue';
    import Year from '@/modules/planning/components/simulation/Year.vue';
    import type {BaseSummariesInterface} from '@/modules/planning/composables/simulation/useSimulationSummaries';
    import useSimulationSummaries from '@/modules/planning/composables/simulation/useSimulationSummaries';
    import useSimulationFilters from '@/modules/planning/composables/simulation/useSimulationFilters';
    import useManager from '@/modules/app/composables/useManager';
    import {MqlOperation, type ResponseObject} from '@meekohq/lumos';
    import moment from 'moment';

    export default defineComponent({
        components: {
            SimulationListSkeleton,
            SimulationFilters,
            WeekDays,
            Year,
        },
        props: {
            simulation: {
                type: Object as PropType<SimulationListModel>,
                required: true,
            },
        },
        setup(props) {
            const loading = ref(false);
            const summaries = ref();
            const {activeOrganization} = useManager();
            const {generateSimulationSummaries} = useSimulationSummaries();

            // Init filters of simulation
            const {
                groupFilter,
                isGroupLoading,
                isPlanningTypeLoading,
                isTagLoading,
                isSupervisionLoading,
                planningTypeFilter,
                supervisionFilter,
                tagFilter,
                daySeparatorFilter,
                halfdayTimeFilter,
            } = useSimulationFilters('registation');
            const tagFilterIds = computed(() => tagFilter.value?.map(tag => tag.getKey()));
            const groupFilterId = computed(() => groupFilter.value ? (groupFilter.value as unknown as KidsGroupModel)?.getKey() : null);

            // Format from and to props to moment objects with start and end of week to have plain weeks
            const from = computed(() => moment(props.simulation.attributes.period.started_at).startOf('week'));
            const to = computed(() => moment(props.simulation.attributes.period.ended_at).endOf('week'));

            // Get registrations of simulation list
            const registrations = computed(() => props.simulation.registrations().value());
            const registrationIds = computed(() => {
                return registrations.value.map(registration => registration.getKey());
            });

            // Get and format summaries
            async function getSummariesData() {
                loading.value = true;

                new MqlOperation<ResponseObject.CustomObject>('planning/period_summaries', {
                    from: from.value.unix(),
                    to: to.value.unix(),
                    interval: 60,
                    organization_id: activeOrganization.value.getKey(),
                    registration_ids: registrationIds.value,
                    kids_tag_ids: tagFilterIds.value?.length ? tagFilterIds.value : [],
                    kids_group_ids: groupFilterId.value ? [groupFilterId.value] : [],
                    kids_planning_types: planningTypeFilter.value ? planningTypeFilter.value : [],
                    get_supervision: supervisionFilter.value,
                }).run().then(async result => {
                    loading.value = false;
                    summaries.value = generateSimulationSummaries((result.content as {
                        data: BaseSummariesInterface
                    }).data);
                });
            }

            watch([() => props.simulation, isGroupLoading, isPlanningTypeLoading, isTagLoading, isSupervisionLoading], async () => {
                // Refresh summaries only when all filters are loaded
                if (props.simulation && !isGroupLoading.value && !isPlanningTypeLoading.value && !isTagLoading.value && !isSupervisionLoading.value) {
                    await getSummariesData();
                }
            }, {deep: true});

            return {
                groupFilter,
                groupFilterId,
                loading,
                planningTypeFilter,
                registrations,
                summaries,
                supervisionFilter,
                tagFilter,
                tagFilterIds,
                daySeparatorFilter,
                halfdayTimeFilter,
            };
        },
    });
</script>
