<script setup lang="ts">
    import {Epoch, EpochInfo, Str} from '@meekohq/lumos';
    import {computed, reactive} from 'vue';
    import type {ModalType} from '@/modules/app/composables/useModal';
    import {useDateTimeModifiersPresets} from '@/modules/meeko-ui/composables/useDateTimeModifiers';
    import useError from '@/modules/app/composables/useError';

    const props = defineProps<{
        week: number;
        day: number;
        type: string;
        planningModel: any;
        opening: string;
        closing: string;
        modal: ModalType;
    }>();

    const {startOfMinute} = useDateTimeModifiersPresets();
    const errorHandler = useError();

    const weekDays = computed(() => {
        return EpochInfo.weekdays();
    });

    const newPlanning = reactive({
        nursery_id: null,
        week: props.week,
        day: props.day,
        type: props.type,
        start_time: Epoch.parse(props.opening || '08:00:00', 'HH:mm:ss'),
        end_time: Epoch.parse(props.closing || '18:00:00', 'HH:mm:ss'),
    });

    function save() {
        if (!newPlanning.start_time || !newPlanning.end_time) {
            return;
        }

        if (newPlanning.end_time.lessThanOrEqualTo(newPlanning.start_time as Epoch)) {
            errorHandler.addErrorCode('startBeforeEnd', '0x2EDA212753');

            return;
        }

        const days = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];

        const planning = {
            day: days[newPlanning.day],
            start_time: newPlanning.start_time.toFormat('HH:mm:ss'),
            end_time: newPlanning.end_time.toFormat('HH:mm:ss'),
            week: newPlanning.week,
            type: newPlanning.type,
            id: Str.uuid(),
        };

        props.planningModel.plannings.push(planning);
        props.modal.hide();
    }
</script>

<template>
    <MModal
        :header-title="__('common:add_range', {range: weekDays[day - 1]})"
        :modal="modal"
    >
        <CForm>
            <CFormTwoColumns>
                <CFormGroup>
                    <CLabel>
                        {{ __('common:date_from') }}
                    </CLabel>
                    <MTimePicker
                        v-model="newPlanning.start_time"
                        class="tw-w-full"
                        :modifier="startOfMinute"
                    />
                </CFormGroup>
                <CFormGroup>
                    <CLabel>
                        {{ __('common:date_to') }}
                    </CLabel>
                    <MTimePicker
                        v-model="newPlanning.end_time"
                        class="tw-w-full"
                        :modifier="startOfMinute"
                    />
                </CFormGroup>
            </CFormTwoColumns>
            <CFormErrorMessageList :errors="errorHandler.get('startBeforeEnd')"/>
        </CForm>
        <template #footer-end="{hide}">
            <MButton
                :label="__('common:actions.cancel')"
                @click="hide"
            />
            <MButton
                :label="__('common:actions.save')"
                variant="primary"
                @click="save"
            />
        </template>
    </MModal>
</template>
