<template>
    <CHStack
        class="NotificationsListItem__item"
        distribute="between"
    >
        <CHStack
            align="center"
            class="NotificationsListItem__title"
            gap="2"
        >
            <font-awesome-icon
                fixed-width
                :icon="notification.icon"
            />
            {{ notification.text }}
        </CHStack>
        <CHStack
            align="center"
            distribute="start"
            gap="4"
        >
            <CCheckbox
                :value="notification.viaMail"
                @input="$emit('change:via-mail', $event)"
            >
                <FontAwesomeIcon
                    class="tw-text-cyan-600"
                    icon="fa fa-envelope"
                />
            </CCheckbox>
            <CCheckbox
                :value="notification.viaBroadcast"
                @input="$emit('change:via-broadcast', $event)"
            >
                <FontAwesomeIcon
                    class="tw-text-yellow-600"
                    icon="fa fa-bell"
                />
            </CCheckbox>
        </CHStack>
    </CHStack>
</template>

<script lang="ts">
    import type {PropType} from 'vue';
    import {defineComponent} from 'vue';
    import type {NotificationType} from '@/modules/user/utils/NotificationsTypes';


    export default defineComponent({
        props: {
            notification: {
                type: Object as PropType<NotificationType>, required: true,
            },
        },
        setup() {
            return {};
        },
    });
</script>

<style lang="scss" scoped>
    .NotificationsListItem__item {
        @apply tw-w-full;
    }
</style>
