<template>
    <div>
        <div class="table-responsive">
            <table class="table table-sm">
                <thead>
                    <tr>
                        <th
                            v-for="(enfant, i) in tauxEffort.length"
                            :key="'enfant ' + i"
                            scope="col"
                        >
                            <template v-if="enfant === tauxEffort.length">
                                {{ __('common:count_and_more', {count: enfant}) }}
                            </template>
                            <template v-else>
                                {{ __('common:kid_with_count', {count: enfant}) }}
                            </template>
                        </th>
                        <th
                            v-if="editable"
                            class="text-nowrap text-right"
                            scope="col"
                        >
                            <MButton
                                class="mr-1"
                                variant="success"
                                @click="addEnfant"
                            >
                                <FontAwesomeIcon icon="fa fa-plus-circle"/>
                            </MButton>
                            <MButton
                                v-if="tauxEffort.length > 2"
                                variant="danger"
                                @click="subEnfant"
                            >
                                <FontAwesomeIcon icon="fa fa-times-circle"/>
                            </MButton>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th
                            v-for="(taux, i) in tauxEffort"
                            :key="'taux ' + i"
                        >
                            <CInputGroup class="tw-font-normal">
                                <MInput
                                    v-model.number="taux.value"
                                    v-float
                                    :disabled="!editable"
                                    placeholder="9"
                                />
                                <CInputAddon>
                                    %
                                </CInputAddon>
                            </CInputGroup>
                        </th>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            tauxEffort: {},
            editable: {
                type: Boolean,
                default: true,
            },
        },
        data() {
            return {};
        },
        computed: {},
        watch: {},
        mounted() {
            this.reset();
        },
        methods: {
            addEnfant() {
                this.tauxEffort.push({value: null});
            },
            subEnfant() {
                this.tauxEffort.splice(this.tauxEffort.length - 1, 1);
            },
            reset() {
                if (this.tauxEffort.length === 0) {
                    this.$emit('updateTaux', [
                        {value: 0.06},
                        {value: 0.05},
                        {value: 0.04},
                        {value: 0.03},
                        {value: 0.03},
                        {value: 0.03},
                        {value: 0.03},
                        {value: 0.02},
                    ]);
                }
            },
        },
    };
</script>

<style lang="scss" scoped>
    th {
        vertical-align: middle !important;
    }
</style>
