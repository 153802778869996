<template>
    <GridList
        :customer="customer"
        :email="nursery.email"
    />
</template>

<script lang="ts">
    import {defineComponent} from 'vue';
    import GridList from '@/modules/subscription/components/PaymentMethod/GridListWithAdd.vue';

    export default defineComponent({
        components: {
            GridList,
        },
        props: {
            customer: {},
            nursery: {},
        },
        setup() {
            //
        },
    });
</script>

<style
    lang="scss"
    scoped
>
    //
</style>
