<template>
    <div>
        <SegmentBar
            :halfday-display="halfdayDisplay"
            :hide-hours="hideHours"
            :hide-tooltip="hideTooltip"
            :loading="loading"
            :segments="visiblesOccupations"
            :styling-segment-fn="style"
            :tooltip-text-fn="tooltipText"
        />
    </div>
</template>

<script lang="ts">
    import type {PropType} from 'vue';
    import {computed, defineComponent} from 'vue';
    import type OrganizationModel from '@/modules/organization/models/OrganizationModel';
    import SegmentBar from '@/modules/planning/components/simulation/Summary/SegmentBar.vue';
    import type {OccupationInterface} from '@/modules/planning/components/simulation/Summary/SummaryTypes';
    import useSegmentSizing from '@/modules/planning/composables/core/useSegmentSizing';
    import __ from '@/modules/app/utils/i18n-facade';
    import _maxBy from 'lodash-es/maxBy';
    import moment from 'moment';

    export default defineComponent({
        components: {SegmentBar},
        props: {
            loading: {
                type: Boolean,
                default: false,
            },
            occupations: {
                type: Array as PropType<OccupationInterface[]>,
                required: true,
            },
            organization: {
                type: Object as PropType<OrganizationModel>,
                required: true,
            },
            opening: {
                type: String,
                required: true,
            },
            closing: {
                type: String,
                required: true,
            },
            hideTooltip: {
                type: Boolean,
                default: false,
            },
            hideHours: {
                type: Boolean,
                default: true,
            },
            halfdayDisplay: {
                type: Boolean,
                default: false,
            },
        },
        setup(props) {
            const maxKids = computed(() => {
                const maxOccupation = _maxBy(props.occupations, 'count');
                if (maxOccupation) {
                    return maxOccupation.count;
                }

                return 0;
            });

            const occupationDate = computed(() => moment.unix(props.occupations[0]?.started_at));
            const {unixToPercent} = useSegmentSizing();

            // Get visible occupations segments
            const visiblesOccupations = computed(() => props.occupations.map(occupation => {
                const segmentLeftPercent = occupation.segmentLeftPercent ?? unixToPercent(occupation.started_at, occupationDate.value, props.opening, props.closing, true);
                const segmentRightPercent = occupation.segmentRightPercent ?? (100 - unixToPercent(occupation.ended_at, occupationDate.value, props.opening, props.closing, true));

                return {
                    ...occupation,
                    segmentRightPercent,
                    segmentLeftPercent,
                };
            }).filter(occupation => {
                if (occupation.segmentLeftPercent === 0) {
                    return occupation.segmentRightPercent < 100;
                } else if (occupation.segmentLeftPercent === 100) {
                    return occupation.segmentRightPercent > 0;
                } else {
                    return true;
                }
            }));

            function style(occupation: OccupationInterface) {
                const opacity = occupation.count === 0
                    ? 0.12
                    : ((occupation.count * 100) / maxKids.value)  / 100;

                let tooManyKids = false;
                if (props.organization && props.organization.attributes.places && props.organization.attributes.occ_max_rate) {
                    const nbPercent = Math.ceil(props.organization.attributes.places * props.organization.attributes.occ_max_rate / 100);
                    tooManyKids = (occupation.count > nbPercent);
                }

                return {
                    'left': occupation.segmentLeftPercent.toFixed(2) + '%',
                    'right': occupation.segmentRightPercent.toFixed(2) + '%',
                    'background-color': tooManyKids ? '#EF0606' : `rgba(0,122,255,${opacity})`,
                };
            }

            function tooltipText(occupation: OccupationInterface) {
                return __('planning:kids_with_count', {count: occupation.count});
            }

            return {
                occupationDate,
                style,
                tooltipText,
                visiblesOccupations,
            };
        },
    });
</script>
