import useConcurrentCallback from '@/modules/app/composables/useConcurrentCallback';
import {app, type Collection, Epoch} from '@meekohq/lumos';
import type {
    ApplyTemplateUseCase,
} from '@/modules/human-resources/apply-template/application/use-cases/ApplyTemplateUseCase';
import type TemplateModel from '@/modules/human-resources/models/TemplateModel';
import type {WeeksDto} from '@/modules/human-resources/apply-template/application/dto/WeeksDto';
import type TemplatePlanningModel from '@/modules/human-resources/models/TemplatePlanningModel';
import useError from '@/modules/app/composables/useError';
import {Types} from '@/types';
import __ from '@/modules/app/utils/i18n-facade';
import type InvalidEvent from '@/modules/human-resources/apply-template/domain/InvalidEvent';
import {
    ConflictedTemplateEventsError,
} from '@/modules/human-resources/template/domain/errors/ConflictedTemplateEventsError';
import useNotification from '@/modules/meeko-ui/composables/useNotification';
import {
    ConflictedPlanningEventsError,
} from '@/modules/human-resources/template/domain/errors/ConflictedPlanningEventsError';

type ErrorDetailType = {
    rules?: Array<{
        code: string;
        detail: any;
        title: string;
    }>;
    event: {
        id: string;
        started_at: string;
        ended_at: string;
    };
};

export default function useApplyTemplate() {
    const {resolveLastCallback} = useConcurrentCallback();
    const invalidEventErrors = useError();

    const customMessages = {
        '0x3115DA45A6': (detail: ErrorDetailType) => {
            const epochStartedAt = Epoch.parse(detail.event.started_at, 'yyyy-MM-dd HH:mm:ss');
            const epochEndedAt = Epoch.parse(detail.event.ended_at, 'yyyy-MM-dd HH:mm:ss');

            // Event can only be on the same day with template event
            return __('planning:errors.date_overlap_specified', {
                period: `<b>${__('common:date_format.same_day_period', {
                    date: epochStartedAt.toLocaleString(Epoch.presets.DATE_SHORT),
                    startTime: epochStartedAt.toLocaleString(Epoch.presets.TIME_SIMPLE),
                    endTime: epochEndedAt.toLocaleString(Epoch.presets.TIME_SIMPLE),
                })}</b>`,
            });
        },
    };

    async function applyTemplate(template: TemplateModel, weeks: WeeksDto, deleteRealEvents: boolean, fallbackOrganizationId: string): Promise<void> {
        invalidEventErrors.reset();

        try {
            const invalidEvents = await resolveLastCallback(() => {
                return app().make<ApplyTemplateUseCase>(Types.ApplyTemplateUseCase)
                    .applyTemplate(template, weeks, deleteRealEvents, fallbackOrganizationId);
            });

            parseInvalidEvents(invalidEvents);
        } catch (e) {
            if (e instanceof ConflictedTemplateEventsError) {
                useNotification().error(__('calendar:errors.conflicted_template_events'));

                return Promise.reject(e);
            }

            throw e;
        }

        return Promise.resolve();
    }

    async function applyTemplatePlanning(planning: TemplatePlanningModel, weeks: WeeksDto, deleteRealEvents: boolean, fallbackOrganizationId: string): Promise<void> {
        invalidEventErrors.reset();

        try {
            const invalidEvents = await resolveLastCallback(() => {
                return app().make<ApplyTemplateUseCase>(Types.ApplyTemplateUseCase)
                    .applyTemplatePlanning(planning, weeks, deleteRealEvents, fallbackOrganizationId);
            });

            parseInvalidEvents(invalidEvents);
        } catch (e) {
            if (e instanceof ConflictedPlanningEventsError) {
                useNotification().error(__('calendar:errors.conflicted_template_events'));

                return Promise.reject(e);
            }

            throw e;
        }

        return Promise.resolve();
    }

    function parseInvalidEvents(invalidEvents: Collection<InvalidEvent>) {
        invalidEvents.each(invalidEvent => {
            for (const item of invalidEvent.getException().errors.toArray()) {
                const customMessage = customMessages?.[item.code];
                const message = typeof customMessage === 'function' ? customMessage(item.detail) : __('common:errors.generic');
                const source = (item.detail as ErrorDetailType)?.event?.id ?? item.source?.pointer;

                invalidEventErrors.add(source, item.code, message);
            }
        });
    }

    return {
        invalidEventErrors,
        applyTemplate,
        applyTemplatePlanning,
    };
}
