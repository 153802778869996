<template>
    <transition
        mode="out-in"
        name="fade"
    >
        <div>
            <div class="row">
                <div class="col-12 col-sm-8">
                    <MHeading class="tw-mb-2">
                        <i
                            aria-hidden="true"
                            class="fas fa-check-circle text-success fa-fw tw-mr-1"
                        />{{ __('common:authorizations') }}
                    </MHeading>
                    <div
                        v-if="kid.authorizations.length"
                        class="form-group ml-2 mr-2"
                    >
                        <div
                            v-for="(authorization, i) in kid.authorizations"
                            :key="'kidAuth' + i"
                            class="form-row mt-1"
                        >
                            <div class="col-12 col-lg-6">
                                <label class="label form-label">{{ authorization.name }} : </label>
                            </div>
                            <div class="col-12 col-lg-6">
                                <div class="form-check form-check-inline">
                                    <label class="label form-check-label">
                                        <input
                                            v-model="authorization.authorized"
                                            class="form-check-input"
                                            :disabled="!$can('update', 'kids_authorizations')"
                                            type="radio"
                                            :value="1"
                                            @change="save(authorization)"
                                        >{{ __('common:yes') }}
                                    </label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <label class="label form-check-label">
                                        <input
                                            v-model="authorization.authorized"
                                            class="form-check-input"
                                            :disabled="!$can('update', 'kids_authorizations')"
                                            type="radio"
                                            :value="0"
                                            @change="save(authorization)"
                                        >{{ __('common:no') }}
                                    </label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <label class="label form-check-label">
                                        <input
                                            v-model="authorization.authorized"
                                            class="form-check-input"
                                            :disabled="!$can('update', 'kids_authorizations')"
                                            type="radio"
                                            :value="null"
                                            @change="save(authorization)"
                                        >{{ __('common:n_a') }}
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <h3
                        v-else
                        class="h3"
                    >
                        {{ __('family_kid:empty_authorizations') }}
                    </h3>
                </div>
                <div class="col-12 col-sm-4">
                    <MHeading class="tw-mb-2">
                        <i
                            aria-hidden="true"
                            class="fa fa-pencil text-primary mr-2"
                        />{{ __('family_kid:specifics_authorizations') }}
                    </MHeading>
                    <div class="form-group">
                        <textarea
                            v-model="kid.specific_authorization_note"
                            class="form-control"
                            :disabled="!$can('update', 'kids_authorizations')"
                            rows="3"
                        />
                    </div>

                    <template v-if="$showCAF(nursery.country)">
                        <MHeading class="tw-mb-2">
                            <i
                                aria-hidden="true"
                                class="fa fa-user-shield text-primary mr-2"
                            />{{ __('family_kid:gdpr') }}
                        </MHeading>
                        <div class="form-row mt-2 mt-lg-0">
                            <div class="col-12">
                                <label class="label form-label">
                                    {{ __('family_kid:gdpr_date_colon') }}
                                    <MHelpTooltip placement="top">
                                        <template #default>
                                            {{ __('family_kid:for_caf_extractions_indicate_a_consent_date_or_leave_blank_to_avoid_transmitting_information_filoué') }}
                                        </template>
                                    </MHelpTooltip>
                                </label>
                            </div>
                            <div class="col-12 col-lg-8">
                                <MDatePicker
                                    v-model="kid.gdpr_consent_date"
                                    class="tw-w-full"
                                    :disabled="!$can('update', 'kids_authorizations')"
                                    format="yyyy-MM-dd"
                                />
                            </div>
                        </div>
                    </template>
                    <div
                        v-if="$can('update', 'kids_authorizations')"
                        class="text-right my-2"
                    >
                        <button
                            class="btn btn-sm btn-success mt-2"
                            @click="saveCustom"
                        >
                            {{ __('common:actions.save') }}
                        </button>
                    </div>
                </div>
            </div>

            <template v-if="$can('update', 'nursery')">
                <hr class="hr mt-2">
                <MButton
                    :label="__('family_kid:manage_organization_authorizations_dots')"
                    variant="info"
                    @click="authorizationModal.show()"
                />
            </template>
        </div>
    </transition>
</template>

<script>
    import Vue from 'vue';
    import _cloneDeep from 'lodash-es/cloneDeep';
    import _forEach from 'lodash-es/forEach';
    import _head from 'lodash-es/head';
    import route from '@/modules/legacy/libs/ziggy';
    import useApi from '@/modules/app/composables/useApi';
    import ManageAuthorizations from './ManageAuthorizations.vue';
    import {nursery} from '@/modules/legacy/mixins/nursery';
    import __ from '@/modules/app/utils/i18n-facade';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';
    import useModal from '@/modules/app/composables/useModal';

    export default {
        mixins: [nursery],
        props: {
            kid: {
                type: Object,
                required: true,
            },
            nursery: {
                type: Object,
                required: true,
            },
        },
        computed: {
            authorizationModal() {
                return useModal({
                    component: ManageAuthorizations,
                    props: {
                        nursery: this.nursery,
                    },
                });
            },
        },
        watch: {
            'nursery.authorizations': 'syncAuthorizations',
            'kid.id': 'syncAuthorizations',
        },
        mounted() {
            this.syncAuthorizations();
        },
        methods: {
            save(authorization) {
                useApi().legacy.put(route('kids.authorizations.update', {
                    kid: this.kid.id,
                    authorization: authorization.id,
                }), authorization).then(() => {
                    useNotification().success(__('family_kid:authorization_updated_successfully_one'));
                }).catch(error => {
                    if (error.response && error.response.status === 422) {
                        _forEach(error.response.data.errors, value => {
                            useNotification().error(_head(value));
                        });
                    } else {
                        useNotification().error(error);
                    }
                });
            },

            saveCustom() {
                useApi().legacy.put(route('kids.update', {
                    nurseries: this.nursery.id,
                    kid: this.kid.id,
                }), {
                    specific_authorization_note: this.kid.specific_authorization_note,
                    gdpr_consent_date: this.kid.gdpr_consent_date || null,
                }).then(() => {
                    useNotification().success(__('family_kid:authorization_updated_successfully_one'));
                }).catch(error => {
                    if (error?.response?.status === 422) {
                        _forEach(error.response.data.errors, value => {
                            useNotification().error(_head(value));
                        });
                    } else {
                        useNotification().error(error);
                    }
                });
            },

            syncAuthorizations() {
                const savedAuthorizations = _cloneDeep(this.kid.authorizations);
                this.kid.authorizations = _cloneDeep(this.nursery.authorizations);

                this.kid.authorizations.forEach(authorization => {
                    const hasAuthorization = savedAuthorizations.find(item => item.id === authorization.id);
                    if (hasAuthorization) {
                        Vue.set(authorization, 'authorized', hasAuthorization.authorized);
                    } else {
                        Vue.set(authorization, 'authorized', null);
                    }
                });
            },
        },
    };
</script>
