import {type Ref, ref} from 'vue';
import {Epoch} from '@meekohq/lumos';
import injectableKey from '@/modules/app/utils/injectableKey';
import type {MCalendarMode} from '@/modules/meeko-ui/components/MCalendar/useMCalendarState';

export type MCalendarView = 'year' | 'month' | 'day';

export const keyMCalendarNavigation = injectableKey<ReturnType<typeof useMCalendarNavigation>>();

export default function useMCalendarNavigation(mode: MCalendarMode) {
    const activeMonth = ref(Epoch.now()) as Ref<Epoch>;
    const yearsRange = ref<{from: number; to: number}>({from: 0, to: 0});
    const activeView = ref<MCalendarView>();

    const showTime = ['time', 'dateTime'].includes(mode);
    const showDay = ['dateTime', 'date'].includes(mode);
    const showMonth = ['dateTime', 'date', 'month'].includes(mode);
    const showYear = ['dateTime', 'date', 'month', 'year'].includes(mode);

    if (showDay) {
        activeView.value = 'day';
    } else if (showMonth) {
        activeView.value = 'month';
    } else if (showYear) {
        activeView.value = 'year';
    } else {
        activeView.value = undefined;
    }

    function setActiveMonthFromDate(date: Epoch | undefined) {
        if (!date) {
            activeMonth.value = Epoch.now();

            return;
        }
        activeMonth.value = Epoch.fromJSDate(date.toJSDate());
    }

    function toggleActiveView(view: MCalendarView) {
        if (activeView.value === view) {
            if (showDay) {
                activeView.value = 'day';
            } else if (showMonth) {
                activeView.value = 'month';
            } else if (showYear) {
                activeView.value = 'year';
            } else {
                activeView.value = undefined;
            }
        } else {
            activeView.value = view;
        }
    }

    function setYearsRangeFromActiveMonth() {
        yearsRange.value = {
            from: activeMonth.value.year - 5,
            to: activeMonth.value.year + 6,
        };
    }

    return {
        activeView,
        toggleActiveView,

        activeMonth,
        setActiveMonthFromDate,

        yearsRange,
        setYearsRangeFromActiveMonth,

        showTime,
        showDay,
        showMonth,
        showYear,
    };
}
