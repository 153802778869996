<template>
    <CList
        v-if="results.length"
        :striped="true"
    >
        <ContactListItem
            v-for="contactModel in resultsOrdered"
            :key="contactModel.id"
            :contact-model="contactModel"
            @deleted="onContactDeleted"
            @updated="onContactUpdated"
        />
    </CList>
    <div v-else>
        <CAlert
            class="tw-mb-4"
            remember-hash="update-june-2020-cashier-contact-explanation"
            :title-text="__('billing_core:contact_persons')"
        >
            <template #icon>
                <i
                    aria-hidden="true"
                    class="fad fa-info-circle tw-text-3xl tw-text-blue-500"
                />
            </template>
            <p v-html="__('billing_core:this_is_where_you_can_add_additional_billing_contacts')"/>
        </CAlert>
        <div class="tw-text-center tw-text-disabled tw-p-4">
            {{ __('billing_core:no_contact_saved') }}
        </div>
    </div>
</template>

<script lang="ts">
    import type {LengthAwarePaginator} from '@meekohq/lumos';
    import type CustomerModel from '@/modules/cashier/models/CustomerModel';
    import ContactModel from '@/modules/cashier/models/ContactModel';
    import {filter, orderBy} from 'lodash-es';
    import ContactListItem from '@/modules/cashier/components/core/contact/molecules/ContactListItem.vue';
    import type {PropType} from 'vue';
    import {computed, defineComponent, ref, watch} from 'vue';
    import {useRoute} from 'vue-router/composables';

    export default defineComponent({
        components: {ContactListItem},
        props: {
            customerModel: {type: Object as PropType<CustomerModel>, required: true},
        },
        setup(props, {emit}) {
            const results = ref<ContactModel[]>([]);
            const paginator = ref<LengthAwarePaginator<ContactModel>>();
            const route = useRoute();

            const resultsOrdered = computed(() => {
                return orderBy(results.value, 'id');
            });

            function addToResult(contactModel: ContactModel) {
                results.value.unshift(contactModel);
            }

            watch(() => props.customerModel, () => {
                fetchResults(1);
            });

            fetchResults(Number(route?.query.page) || 1);

            function onContactDeleted(contactModel: ContactModel) {
                results.value = filter(results.value, function(c: ContactModel) {
                    return c.getKey() !== contactModel.getKey();
                }) as ContactModel[];

                emit('deleted', contactModel);
            }

            function onContactUpdated(contactModel: ContactModel) {
                emit('updated', contactModel);
            }

            function fetchResults(page = 1, perPage = 15): void {
                ContactModel.query().where('customer_id', props.customerModel.getKey())
                    .paginate(perPage, page)
                    .then(response => {
                        paginator.value = response;
                        results.value = response.items().all();
                    });
            }

            return {
                results,
                resultsOrdered,
                addToResult,
                onContactDeleted,
                onContactUpdated,
            };
        },
    });
</script>

