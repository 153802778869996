<template>
    <video
        class="PhotoVideoPlayer"
        controls
        height="auto"
        preload="metadata"
        :src="`${url}`"
    >
        <source
            :src="`${url}`"
            :type="extensionType"
        >
        {{ __('photo:video_not_supported') }}
    </video>
</template>

<script>
    export default {
        props: {
            url: {
                type: String,
                required: true,
            },
            extensionType: {
                type: String,
                default: 'video/mp4',
            },
        },
    };
</script>

<style lang="scss" scoped>
    .PhotoVideoPlayer {
        @apply tw-aspect-video;
    }
</style>
