<template>
    <div>
        <div class="CFormRangerPicker">
            <input
                class="CFormRangerPicker__slider"
                :max="rangeMax"
                :min="rangeMin"
                :step="step"
                type="range"
                :value="firstValue"
                @change="emitSliderRange($event.target.value, secondValue)"
            >
            <input
                class="CFormRangerPicker__slider"
                :max="rangeMax"
                :min="rangeMin"
                :step="step"
                type="range"
                :value="secondValue"
                @change="emitSliderRange(firstValue, $event.target.value)"
            >
        </div>
        <CForm
            v-if="showInputs"
            class="tw-mt-4"
        >
            <CFormTwoColumns>
                <CInput
                    :max="rangeMax"
                    :min="rangeMin"
                    placeholder="∞"
                    :step="step"
                    type="number"
                    :value="firstValue"
                    @input="emitSliderRange($event, secondValue)"
                />
                <CInput
                    :max="rangeMax"
                    :min="rangeMin"
                    placeholder="∞"
                    :step="step"
                    type="number"
                    :value="secondValue"
                    @input="emitSliderRange(firstValue, $event)"
                />
            </CFormTwoColumns>
        </CForm>
    </div>
</template>

<script>
    import {defineComponent} from 'vue';
    import {debounce} from 'lodash-es';

    export default defineComponent({
        props: {
            step: {
                type: [String, Number],
                default: 1,
            },
            // rangeMin et rangeMax sont simplement les valeurs minimales et maximales du slider.
            // Exemple: le slider ira de 0 à 100
            rangeMin: {
                type: [String, Number],
                default: 0,
            },
            rangeMax: {
                type: [String, Number],
                default: 100,
            },
            // firstValue et secondValue sont les valeurs des deux thumbs du slider
            firstValue: {
                type: [String, Number, null],
                default: null,
            },
            secondValue: {
                type: [String, Number, null],
                default: null,
            },
            showInputs: {
                type: Boolean,
                default: true,
            },
        },
        setup(props, {emit}) {
            const emitSliderRange = debounce((sliderMin, sliderMax) => {
                let rangeMin = sliderMin === null ? null : parseInt(sliderMin);
                let rangeMax = sliderMax === null ? null : parseInt(sliderMax);

                if (rangeMax !== null && rangeMin === null && rangeMax < 0) {
                    rangeMin = rangeMax;
                    rangeMax = null;
                } else if (rangeMin !== null && rangeMax === null && rangeMin < 0) {
                    rangeMax = rangeMin;
                    rangeMin = null;
                } else if (rangeMax !== null && rangeMin !== null && rangeMax < rangeMin) {
                    const tmp = rangeMax;
                    rangeMax = rangeMin;
                    rangeMin = tmp;
                }

                emit('change', {rangeMin, rangeMax});
            }, 100);

            return {
                emitSliderRange,
            };
        },
    });
</script>

<style lang="scss" scoped>
    .CFormRangerPicker {
        @apply tw-bg-gray-200;
        @apply tw-relative;
        @apply tw-h-2;
        @apply tw-flex tw-items-center tw-justify-center;
        @apply tw-rounded-full;
        @apply tw-shadow-inner;
    }

    .CFormRangerPicker__slider {
        @apply tw-absolute;
        @apply tw-w-full tw-h-0;
        @apply tw-pointer-events-none;
        @apply tw-appearance-none;
        background: none;
    }

    .CFormRangerPicker__slider::-webkit-slider-thumb {
        @apply tw-w-6 tw-h-6;
        @apply tw-rounded-full;
        @apply tw-bg-primary-500;
        @apply tw-appearance-none;
        @apply tw-pointer-events-auto;
        @apply tw-border-none;
        @apply tw-shadow-md;
    }

    .CFormRangerPicker__slider::-moz-range-thumb {
        @apply tw-w-6 tw-h-6;
        @apply tw-rounded-full;
        @apply tw-bg-primary-500;
        @apply tw-appearance-none;
        @apply tw-pointer-events-auto;
        @apply tw-border-none;
        @apply tw-shadow-md;
    }
</style>
