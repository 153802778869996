<template>
    <div class="tw-p-2">
        <CForm>
            <div class="tw-flex tw-items-center tw-gap-1">
                <MHelpTooltip placement="top">
                    <span v-html="__('billing_core:certificate_is_calculated_from_payment_date_added_not_invoice_with_example')"/>
                </MHelpTooltip>
                <div class="tw-text-gray-500">
                    {{ __('billing_core:how_is_the_certificate_calculated_ask') }}
                </div>
            </div>

            <loader
                v-if="kidsLoading"
                light="true"
                shadow="false"
                size="sm"
            />

            <CFormGroup v-else-if="kidModels.length > 1">
                <CLabel>{{ __('common:kid_one') }}</CLabel>
                <CFormSelect
                    v-model="selectedKid"
                    :options="kidModels"
                    :search-bar="false"
                    text-path="fullname"
                    value-path="id"
                />
            </CFormGroup>

            <CFormGroup>
                <CLabel>{{ __('common:start_date') }}</CLabel>
                <CFormDatepicker v-model="fromDate"/>
            </CFormGroup>

            <CFormGroup>
                <CLabel>{{ __('common:end_date') }}</CLabel>
                <CFormDatepicker v-model="toDate"/>
            </CFormGroup>

            <download-template
                :additional-items="additionalItems"
                custom-class="tw-w-full"
                doc-type="tax-certificate"
                :from-date="fromDate"
                :item="customerModel"
                :nursery="nursery"
                right
                sm
                :to-date="toDate"
                :user="user"
                variant="light"
            />
        </CForm>
    </div>
</template>

<script>
    import moment from 'moment';
    import CustomerModel from '@/modules/cashier/models/CustomerModel';
    import KidModel from '@/modules/family/models/KidModel';
    import DownloadTemplate from '@/modules/legacy/components/Modules/DownloadTemplate.vue';
    import FamilyModel from '@/modules/family/models/FamilyModel';

    export default {
        components: {
            DownloadTemplate,
        },
        props: {
            customerModel: {
                type: CustomerModel,
                required: true,
            },
            user: {
                type: Object,
                required: true,
            },
            nursery: {
                type: Object,
                required: true,
            },
        },
        data: () => ({
            fromDate: null,
            toDate: null,
            kidModels: [],
            selectedKid: null,
            kidsLoading: false,
        }),
        computed: {
            additionalItems() {
                const arr = [];
                if (this.selectedKid) {
                    arr.push({
                        name: 'kid_id',
                        value: this.selectedKid,
                    });
                }

                return arr;
            },
        },
        mounted() {
            this.fromDate = moment()
                .startOf('year')
                .subtract(1, 'year')
                .toISOString();

            this.toDate = moment()
                .endOf('year')
                .subtract(1, 'year')
                .toISOString();

            this.getKids();
        },
        methods: {
            getKids() {
                this.kidsLoading = true;

                KidModel.query().whereHas(new KidModel().family(), query => {
                    query.whereHas(new FamilyModel().customers(), query1 => {
                        query1.where('id', this.customerModel.id);
                    });
                }).all().then(response => {
                    this.kidModels = response.all();
                    this.selectedKid = this.kidModels[0] ? this.kidModels[0].id : null;
                    this.kidsLoading = false;
                }).catch(() => {
                    this.kidsLoading = false;
                });
            },
        },
    };
</script>
