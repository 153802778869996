<template>
    <CVStack>
        <!-- Default frequency select -->
        <Frequency
            v-if="!hasRules"
            v-model="frequency"
            :customizable="true"
            :disabled="disabled"
            :exclude-frequencies="excludeFrequencies"
        />
        <!-- List of existing rules -->
        <template v-if="hasExistingRules">
            <Rule
                v-for="(rule, i) in rulesetCopy.rules"
                :key="'rule-' + i"
                :class="{'tw-mt-4': i > 0}"
                :disabled="disabled"
                :rule="rule"
                @remove="removeRule"
                @validate="updateRuleByIndex(i, $event)"
            />
        </template>
        <!-- Pending new rule -->
        <Rule
            v-if="draftRule && frequency === 'custom'"
            ref="draftRuleCompo"
            :class="{'tw-mt-4': rulesetCopy.rules.length > 0}"
            :disabled="disabled"
            :rule="draftRule"
            @cancel="resetDraftRule(true)"
            @remove="resetDraftRule(true)"
            @validate="addDraftRule"
        />
        <!-- Add new rule action if multi -->
        <div
            v-if="hasExistingRules && multi && !disabled"
            class="tw-mt-4"
        >
            <CButton
                :icon-plus="true"
                @click="frequency = 'custom'"
            >
                {{ __('common:add_rule') }}
            </CButton>
        </div>
    </CVStack>
</template>

<script lang="ts">
    import type {PropType} from 'vue';
    import {computed, defineComponent, nextTick, ref, watch} from 'vue';
    import FrequencyValue from '@/modules/calendar/utils/RuleSet/values/FrequencyValue';
    import RuleSet from '@/modules/calendar/utils/RuleSet/RuleSet';
    import type RuleObject from '@/modules/calendar/utils/RuleSet/Rule';
    import Rule from '@/modules/calendar/components/RuleSet/Rule.vue';
    import useRuleSetRef from '@/modules/calendar/composables/RuleSet/useRuleSetRef';
    import useDraftRule from '@/modules/calendar/composables/RuleSet/useDraftRule';
    import Frequency from '@/modules/calendar/components/RuleSet/Rule/Frequency.vue';
    import _cloneDeep from 'lodash-es/cloneDeep';

    export default defineComponent({
        components: {
            Frequency,
            Rule,
        },
        props: {
            value: {
                type: Object as PropType<RuleSet>,
                default: undefined,
            },
            excludeFrequencies: {
                type: Array as PropType<FrequencyValue[]>,
                default: () => [FrequencyValue.second, FrequencyValue.minute, FrequencyValue.hour, FrequencyValue.once],
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            multi: {
                type: Boolean,
                default: false,
            },
        },
        setup(props, {emit}) {
            const rulesetCopy = ref<RuleSet>(props.value ? RuleSet.fromJSON(props.value) : new RuleSet());
            const frequency = ref<FrequencyValue | 'custom'>();

            const {addRule, updateRuleByIndex, removeRule} = useRuleSetRef(rulesetCopy);
            const {rule: draftRule, ruleCompo: draftRuleCompo, init: initDraftRule} = useDraftRule();

            const hasRules = computed(() => {
                if (props.multi) {
                    return hasExistingRules.value;
                } else {
                    return draftRule.value || hasExistingRules.value;
                }
            });

            const hasExistingRules = computed(() =>
                rulesetCopy.value.rules.length && rulesetCopy.value.rules.filter(rule => rule.interval).length,
            );

            function addDraftRule(rule: RuleObject) {
                addRule(rule);
                resetDraftRule();
            }

            // Remove custom rule pending validation
            function resetDraftRule(forceFrequency = false) {
                if (hasExistingRules.value || forceFrequency) {
                    frequency.value = undefined;
                }

                draftRule.value = undefined;
            }

            watch(frequency, (newFreq, oldFreq) => {
                if (newFreq) {
                    if (oldFreq) {
                        // Reset ruleset rule if first rule frequency change
                        rulesetCopy.value.rules = [];
                    }
                    // Init new Rule
                    initDraftRule({interval: 1, freq: (newFreq === 'custom' ? FrequencyValue.week : newFreq)});

                    nextTick(() => {
                        // If Rule is not custom, add simple rule to ruleset, else show rule config
                        if (draftRule.value && frequency.value !== 'custom') {
                            addDraftRule(draftRule.value);
                        }
                    });
                }
            });

            // Keep v-model updated
            watch(() => _cloneDeep(rulesetCopy.value), value => {
                if (value.rules?.length > 0) {
                    emit('input', value);
                } else {
                    emit('input', null);
                }
            });

            return {
                addDraftRule,
                frequency,
                FrequencyValue,
                hasRules,
                hasExistingRules,
                draftRule,
                draftRuleCompo,
                updateRuleByIndex,
                removeRule,
                resetDraftRule,
                rulesetCopy,
            };
        },
    });
</script>
