<script setup lang="ts">
    import InvoiceMiniature
        from '@/modules/cashier/payment/infrastructure/components/InvoiceMiniature/InvoiceMiniature.vue';
    import useFormatNumber from '@/modules/cashier/composables/useFormatNumber';
    import type {CreditNoteAllocationAggregate} from '@/modules/cashier/payment/domain/CreditNoteAllocationAggregate';

    const props = defineProps<{ allocation: CreditNoteAllocationAggregate }>();

    const {format} = useFormatNumber(props.allocation.currencyIsoCode);
</script>

<template>
    <InvoiceMiniature :invoice="allocation.source">
        <div class="PaymentSuggestionsListItemHeaderCreditNote">
            <FontAwesomeIcon
                class="PaymentSuggestionsListItemHeaderCreditNote__icon"
                icon="fa fa-file-import"
            />
            <div>
                <MHeading level="h3">
                    {{ __('common:credit_note') }}
                </MHeading>
                <MHeading>
                    {{ format(allocation.allocatableAmount) }}
                </MHeading>
            </div>
        </div>
    </InvoiceMiniature>
</template>

<style lang="scss" scoped>
    .PaymentSuggestionsListItemHeaderCreditNote {
        @apply tw-flex tw-flex-row tw-items-center tw-gap-3;
    }

    .PaymentSuggestionsListItemHeaderCreditNote__icon {
        @apply tw-text-green-500 tw-text-3xl;
    }
</style>
