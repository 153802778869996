import {Model} from '@meekohq/lumos';
import CustomerModel from '@/modules/cashier/models/CustomerModel';
import AddressModel from '@/modules/cashier/models/AddressModel';

export default class OrderModel extends Model {
    static readonly DRAFT: string = 'draft';
    static readonly PENDING: string = 'pending';
    static readonly SUCCEEDED: string = 'succeeded';

    public type = 'cashier/orders';

    public attributes: {
        id: string;
        tenant_id: string | undefined;
        customer_id: string | null | undefined;
        number: string | undefined;
        status: string | undefined;
        billing_address_id: string | null | undefined;
        shipping_address_id: string | null | undefined;
        purchase_order_number: string | null | undefined;
    } = {
            id: this.uuid(),
            tenant_id: undefined,
            customer_id: undefined,
            number: undefined,
            status: undefined,
            billing_address_id: undefined,
            shipping_address_id: undefined,
            purchase_order_number: undefined,
        };

    customer() {
        return this.belongsTo('customer', CustomerModel, 'customer_id');
    }

    defaultBillingAddress() {
        return this.belongsTo('defaultBillingAddress', AddressModel, 'billing_address_id');
    }
}
