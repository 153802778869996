import {Model} from '@meekohq/lumos';

export default class RuleModel extends Model {
    public type = 'calendar/rules';

    public attributes: {
        id: string;
        account_id: string | undefined,
        ruleset_id: string | undefined,
        start_date: string | undefined | null,
        end_date: string | undefined | null,
        count: number | undefined | null,
        interval: string | undefined | null,
        interval_count: number | undefined | null,
        week_starts_at: number | undefined | null,
        by_set_pos: number[] | undefined | null,
        by_weekday: number[] | undefined | null,
        by_month: number[] | undefined | null,
        by_month_day: number[] | undefined | null,
        by_year_day: number[] | undefined | null,
        by_week_no: number[] | undefined | null,
        by_hour: number[] | undefined | null,
        by_minute: number[] | undefined | null,
        by_second: number[] | undefined | null,
        options: string[] | undefined | null,
        created_at: string | undefined;
        updated_at: string | undefined;
    } = {
            id: this.uuid(),
            account_id: undefined,
            ruleset_id: undefined,
            start_date: undefined,
            end_date: undefined,
            count: undefined,
            interval: undefined,
            interval_count: undefined,
            week_starts_at: undefined,
            by_set_pos: undefined,
            by_weekday: undefined,
            by_month: undefined,
            by_month_day: undefined,
            by_year_day: undefined,
            by_week_no: undefined,
            by_hour: undefined,
            by_minute: undefined,
            by_second: undefined,
            options: undefined,
            created_at: undefined,
            updated_at: undefined,
        };
}
