import type {SetupContext} from 'vue';
import {computed} from 'vue';
import RequestStageModel from '@/modules/request/models/RequestStageModel';
import type RequestTypeStageModel from '@/modules/request/models/RequestTypeStageModel';
import type RequestModel from '@/modules/request/models/RequestModel';
import UserModel from '@/modules/user/models/UserModel';
import {Status} from '@/modules/request/utils/Status';
import useManager from '@/modules/app/composables/useManager';
import useAuth from '@/modules/app/composables/useAuth';
import {useRoute} from 'vue-router/composables';
import useNotification from '@/modules/meeko-ui/composables/useNotification';
import {EventBus} from '@/modules/legacy/utils/bus';
import swal from 'sweetalert2/dist/sweetalert2.js';
import __ from '@/modules/app/utils/i18n-facade';

export default function(request: RequestModel, context: SetupContext | undefined = undefined) {
    const {legacyAccount} = useManager();
    const {legacyUser} = useAuth();
    const {success} = useNotification();

    const requestStages = computed(() => request.requestStages().value());
    const lastRequestStage = computed(() => requestStages.value?.sortBy('computed.created_at').last());
    const requestTypeStage = computed(() => lastRequestStage.value.requestTypeStage().value());
    const requestType = computed(() => request.requestType().value());
    const source = computed(() => request.source().value());
    const resources = computed(() => request.events().value());

    const route = useRoute();

    const refreshPlanningRouteNames = [
        'planning.kids',
        'planning.kids.weekly',
        'planning.kids.monthly',
        'planning.kids.yearly',
        'planning.staffs',
        'planning.staffs.daily',
        'planning.staffs.weekly',
    ];

    async function save(stage: RequestStageModel) {
        await stage.save();

        request.requestStages().value().push(stage);

        // Fresh events after status being saved by the observer
        request.events().value().each(event => {
            event.fresh();
        });

        context?.emit('stageSaved');

        success(__('request:request_processed_successfully'));
    }

    async function createRequestStage(requestTypeStageModel: RequestTypeStageModel) {
        // We check that the new type of stage desired is not identical to the last
        // No concrete use cases, only for security
        if (requestTypeStageModel.getKey() === requestTypeStage.value.getKey()) {
            return;
        }

        // Init new stage attributes and relations
        const newRequestStage = new RequestStageModel();
        newRequestStage.attributes.account_id = legacyAccount.value.id.toString();
        newRequestStage.request().associate(request);
        newRequestStage.requestTypeStage().associate(requestTypeStageModel);

        // Link auth user to stage source
        const userModel = new UserModel();
        userModel.id = `${legacyUser.value.id}`;
        newRequestStage.source().associate(userModel);

        if (requestTypeStageModel.attributes.type_status === Status.rejected) {
            // If context, block popover stageSavedhidden on click outside
            context?.emit('preventClickOutside', false);

            // If status is rejected, ask reason of reject
            swal({
                title: __('request:refusal_reason'),
                input: 'text',
                inputPlaceholder: __('request:request_refused_because_dots'),
                type: null,
                confirmButtonText: __('common:actions.deny'),
                cancelButtonText: __('common:actions.cancel'),
            }).then(async result => {
                if (typeof result.value === 'string') {
                    newRequestStage.attributes.explanation = result.value;
                    await save(newRequestStage);
                    refreshStaffPlannings();
                }
            }).finally(() => {
                // If context, unblock popover hidden on click outside
                context?.emit('preventClickOutside', true);
            });

        } else {
            await save(newRequestStage);
            refreshStaffPlannings();
        }
    }

    function refreshStaffPlannings(): void {
        if (route?.matched.some(({name}) => name && refreshPlanningRouteNames.includes(name))) {
            // Refresh calendar staffs data (plannings, supervision, etc...)
            EventBus.$emit('calendar:staffs:refresh', true);
        }
    }

    return {
        requestStages,
        lastRequestStage,
        requestTypeStage,
        requestType,
        source,
        resources,

        save,
        createRequestStage,
    };
}
