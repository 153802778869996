import type {
    PaginatePersonalAccessTokenUseCase,
} from '@/modules/auth/personal-access-token/application/PaginatePersonalAccessTokenUseCase';
import type {
    PaginatePersonalAccessTokenRequest,
} from '@/modules/auth/personal-access-token/application/PaginatePersonalAccessTokenRequest';
import type {LengthAwarePaginator} from '@meekohq/lumos';
import PersonalAccessTokenModel from '@/modules/auth/personal-access-token/domain/PersonalAccessTokenModel';
import UserModel from '@/modules/user/models/UserModel';

export class PaginatePersonalAccessTokenInteractor implements PaginatePersonalAccessTokenUseCase {
    async paginate(request: PaginatePersonalAccessTokenRequest): Promise<LengthAwarePaginator<PersonalAccessTokenModel>> {
        return PersonalAccessTokenModel.query()
            .where('consumer', request.consumer)
            .where('tokenable_id', request.userId)
            .where('tokenable_type', new UserModel().type)
            .paginate(request.perPage, request.page, undefined, {
                params: {
                    orderBy: 'lastUsedAt',
                },
            });
    }
}
