<template>
    <div>
        <CVStack gap="6">
            <div>
                <template v-if="loader.isDone()">
                    <CAlert
                        v-if="!paymentMethods.length"
                        class="tw-mb-4"
                        :close-button="false"
                    >
                        <template #icon>
                            <div class="tw-rounded-full tw-h-6 tw-w-6 tw-bg-blue-300 tw-flex tw-items-center tw-justify-center">
                                <i
                                    aria-hidden="true"
                                    class="fas fa-info tw-text-blue-700 tw-text-base"
                                />
                            </div>
                        </template>
                        {{ __('subscription:no_payment_method') }}
                    </CAlert>
                    <div class="tw-flex tw-flex-wrap tw--m-2">
                        <PaymentMethodCardObject
                            v-for="paymentMethod in paymentMethods"
                            :key="paymentMethod.id"
                            class="tw-m-2"
                            :customer="customer"
                            :is-default="defaultMethod === paymentMethod.id"
                            :payment-method="paymentMethod"
                            @defaultPaymentMethodSelected="setDefault"
                            @paymentMethodDeleted="deleteMethod"
                        />
                    </div>
                </template>
                <CSkeleton
                    v-else
                    :count="1"
                    :grid-col="4"
                    grid-item-class="tw-h-32"
                    layout="grid"
                    :spinner="true"
                />
            </div>

            <CDisclosure
                :open="addVisible"
                :title="__('subscription:add_payment_method')"
            >
                <CVStack>
                    <AddSepa
                        class="mb-3"
                        :customer="customer"
                        :email="email"
                        @created="fetchPaymentMethods"
                        @defaultPaymentMethodSelected="setDefault"
                    />

                    <CDisclosure
                        :open="false"
                        :title="__('subscription:other_payment_method')"
                    >
                        <AddCard
                            class="mt-2"
                            :customer="customer"
                            :email="email"
                            @created="fetchPaymentMethods"
                            @defaultPaymentMethodSelected="setDefault"
                        />
                    </CDisclosure>
                </CVStack>
            </CDisclosure>
        </CVStack>
    </div>
</template>

<script lang="ts">
    import {defineComponent, onMounted, ref, watch} from 'vue';
    import useApi from '@/modules/app/composables/useApi';
    import route from '@/modules/legacy/libs/ziggy';
    import Loader from '@/modules/legacy/helpers/loader.helper';
    import {filter} from 'lodash-es';
    import AddCard from '@/modules/subscription/components/PaymentMethod/AddCard.vue';
    import PaymentMethodCardObject from '@/modules/subscription/components/PaymentMethod/PaymentMethodCardObject.vue';
    import AddSepa from '@/modules/subscription/components/PaymentMethod/AddSepa.vue';

    export default defineComponent({
        components: {
            AddSepa,
            AddCard,
            PaymentMethodCardObject,
        },
        props: {
            customer: {type: Object, required: true},
            email: {type: String, required: true},
            addVisible: {type: Boolean, default: true},
        },
        setup(props) {
            const loader = ref(new Loader());
            const paymentMethods = ref<any[]>([]);
            const defaultMethod = ref();

            onMounted(() => {
                loader.value.start();
                fetchPaymentMethods();
            });

            watch(() => props.customer, () => {
                fetchPaymentMethods();
            });

            const fetchPaymentMethods = function() {
                loader.value.start();
                useApi().legacy.get(route('customers.sources.index', {
                    customer: props.customer.id,
                })).then(response => {
                    paymentMethods.value = response.data.sources;
                    defaultMethod.value = response.data.defaultSource;
                    loader.value.stop();
                });
            };

            const deleteMethod = function(deletedSource) {
                paymentMethods.value = filter(paymentMethods.value, function(paymentMethod) {
                    return paymentMethod.id !== deletedSource.id;
                });
            };

            const setDefault = function(source) {
                defaultMethod.value = source;
            };

            return {
                loader,
                paymentMethods,
                defaultMethod,
                fetchPaymentMethods,
                deleteMethod,
                setDefault,
            };
        },
    });
</script>

<style
    lang="scss"
    scoped
>
    //
</style>
