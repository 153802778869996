import {ref, watch} from 'vue';
import moment from 'moment';

export default function() {
    const years = ref<any[]>([]);

    const from = ref(moment().startOf('week'));
    watch(from, () => {
        getYears();
    });

    const to = ref(moment().add('3', 'months').startOf('week'));
    watch(to, () => {
        getYears();
    });

    function getYears() {
        years.value = [];

        if (moment(to.value).isBefore(from.value, 'day')) {
            return;
        }

        const numberOfYears =
            moment(to.value).year() - moment(from.value).year();
        let i = 0;
        do {
            const year = moment(from.value).year() + i;
            i++;

            let fromWeek = moment(from.value)
                .startOf('week')
                .week();
            let toWeek = moment(to.value)
                .endOf('week')
                .week();

            if (numberOfYears) {
                if (
                    year ===
                    moment(from.value)
                        .startOf('week')
                        .year()
                ) {
                    toWeek = moment()
                        .year(year)
                        .weeksInYear();
                } else if (
                    year ===
                    moment(to.value)
                        .endOf('week')
                        .year()
                ) {
                    fromWeek = 1;
                } else {
                    fromWeek = 1;
                    toWeek = moment()
                        .year(year)
                        .weeksInYear();
                }
            }

            years.value.push({
                year,
                weeks: getWeeks(fromWeek, toWeek, year),
            });
        } while (i <= numberOfYears);
    }

    function getWeeks(fromWeek: number, toWeek: number, year: number) {
        const weeks: any[] = [];
        for (let i = fromWeek; i <= toWeek; i++) {
            weeks.push({
                week: i,
                year,
                date: moment()
                    .year(year)
                    .week(i)
                    .startOf('week'),
                selected: false,
            });
        }

        return weeks;
    }

    function selectAllWeeks(select: boolean, half: 'odd' | 'even') {
        years.value.forEach(year => {
            year.weeks.forEach(week => {
                if (half === 'odd') {
                    week.week % 2
                        ? (week.selected = true)
                        : (week.selected = false);
                } else if (half === 'even') {
                    week.week % 2
                        ? (week.selected = false)
                        : (week.selected = true);
                } else {
                    select
                        ? (week.selected = true)
                        : (week.selected = false);
                }
            });
        });
    }

    return {
        from,
        to,
        years,
        selectAllWeeks,
    };
}
