<script setup lang="ts">
    import {provide, ref, watch} from 'vue';
    import useMCalendarYears, {keyMCalendarYears} from '@/modules/meeko-ui/components/MCalendar/useMCalendarYears';
    import {useElementHover} from '@vueuse/core';
    import MCalendarYearsItem from '@/modules/meeko-ui/components/MCalendar/MCalendarYearsItem.vue';

    const yearsState = useMCalendarYears();
    provide(keyMCalendarYears, yearsState);

    const {
        yearsInSelectedRange,
        hoveredYear,
    } = yearsState;

    const yearsElement = ref();
    const isHovered = useElementHover(yearsElement);

    watch(isHovered, newValue => {
        if (!newValue) {
            hoveredYear.value = undefined;
        }
    });
</script>

<template>
    <div
        ref="yearsElement"
        class="MCalendarYears"
    >
        <MCalendarYearsItem
            v-for="year in yearsInSelectedRange"
            :key="year.year.toTimestamp()"
            :year="year"
        />
    </div>
</template>

<style scoped lang="scss">
    .MCalendarYears {
        @apply tw-grow;
        @apply tw-grid tw-grid-flow-row tw-grid-cols-3 tw-gap-1;
    }
</style>
