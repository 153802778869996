import {app} from '@meekohq/lumos';
import type {BroadcastContract} from '@/modules/app/utils/BroadcastContract';
import {Types} from '@/types';

export default function() {
    const broadcast = app<BroadcastContract>(Types.Broadcast);

    return {
        sessionChannel: broadcast.sessionChannel,
        privateChannel: broadcast.privateChannel,
        notificationEvent: broadcast.notificationEvent,
    };
}
