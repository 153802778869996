<script setup lang="ts">
    import useModal from '@/modules/app/composables/useModal';
    import PersonalAccessTokensModalCreate
        from '@/modules/auth/personal-access-token/infrastructure/components/PersonalAccessTokensModalCreate.vue';
    import type {
        GeneratePersonalAccessTokenResponse,
    } from '@/modules/auth/personal-access-token/application/GeneratePersonalAccessTokenResponse';
    import PersonalAccessTokensModalToken
        from '@/modules/auth/personal-access-token/infrastructure/components/PersonalAccessTokensModalToken.vue';
    import PersonalAccessTokensList
        from '@/modules/auth/personal-access-token/infrastructure/components/PersonalAccessTokensList.vue';
    import {ref} from 'vue';

    const peronsalAccessTokenListEl = ref<InstanceType<typeof PersonalAccessTokensList> | null>(null);

    function showCreateModal() {
        useModal({
            component: PersonalAccessTokensModalCreate,
            listeners: modal => ({
                created: async (response: GeneratePersonalAccessTokenResponse) => {
                    modal.hide();
                    showTokenModal(response.token);

                    await peronsalAccessTokenListEl.value?.paginate();
                },
            }),
        }).show();
    }

    function showTokenModal(token: string) {
        useModal({
            component: PersonalAccessTokensModalToken,
            props: {
                token,
            },
        }).show();
    }
</script>

<template>
    <div class="tw-flex tw-flex-col tw-gap-4">
        <MButton
            class="tw-self-end"
            icon-plus
            :label="__('user:add_key')"
            variant="primary"
            @click="showCreateModal"
        />
        <PersonalAccessTokensList ref="peronsalAccessTokenListEl"/>
    </div>
</template>
