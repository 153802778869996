<template>
    <SimplePageTemplate @click="hidePopovers">
        <template #title>
            <MPageTitle>
                {{ __('app:menu.kids_planning') }}
            </MPageTitle>
        </template>
        <template #actions>
            <top-actions
                :current-display="__('common:weekly')"
                :date="date"
                :nursery="nursery"
            />
        </template>
        <template #content>
            <TopActionsSwitcher
                class="d-print-none"
                :current-display="__('common:weekly')"
                :date="date"
                :nursery="nursery"
                print
            />
            <div class="card card-3d mt-3">
                <div class="card-body">
                    <div class="row mb-2 d-print-none">
                        <div class="col-6 col-sm-4 order-2 order-sm-1 text-left">
                            <button
                                class="btn btn-success btn-sm"
                                @click="date = date.clone().startOf('week').subtract(1, 'weeks'); pushQueryDate(date, 'week');"
                            >
                                <i
                                    aria-hidden="true"
                                    class="fa fa-angle-left mr-1"
                                />{{ __('common:longweek_with_number', {week: fromMoment(date).subWeeks(1).weekNumber}) }}
                            </button>
                        </div>
                        <div class="col-12 col-sm-4 order-1 order-sm-2 text-center mb-3">
                            <MHeading class="d-flex align-items-center justify-content-center">
                                {{ __('common:longweek_with_number', {week: fromMoment(date).weekNumber}) }}
                                <OccupationBadge
                                    class="tw-ml-2"
                                    :enable-count="false"
                                    :loading="occupationLoading || loading"
                                    :occupations="occupations"
                                />
                            </MHeading>
                            <div class="text-muted mb-1">
                                {{ fromMoment(date).startOfWeek().toLocaleString(Epoch.presets.DATE_SHORT) }} - {{ fromMoment(date).endOfWeek().toLocaleString(Epoch.presets.DATE_SHORT) }}
                            </div>
                        </div>
                        <div class="col-6 col-sm-4 order-3 order-sm-3 text-right">
                            <button
                                class="btn btn-success btn-sm"
                                @click="date = date.clone().startOf('week').add(1, 'weeks'); pushQueryDate(date, 'week');"
                            >
                                {{ __('common:longweek_with_number', {week: fromMoment(date).addWeeks(1).weekNumber}) }}<i
                                    aria-hidden="true"
                                    class="fa fa-angle-right ml-1"
                                />
                            </button>
                        </div>
                    </div>
                    <h6
                        class="h6 d-none d-print-flex mb-5 pb-4 text-primary text-center justify-content-center w-100"
                        style="margin-top: -20px;"
                    >
                        {{
                            __('common:date_format.human_from_to', {
                                from: fromMoment(date).startOfWeek().toLocaleString(Epoch.presets.DATE_SHORT),
                                to: fromMoment(toDay).endOfWeek().toLocaleString(Epoch.presets.DATE_SHORT),
                            })
                        }} - {{ __('common:longweek_with_number', {week: fromMoment(date).weekNumber}) }}
                    </h6>
                    <transition>
                        <div>
                            <loader
                                v-if="loading"
                                light="true"
                                shadow="false"
                            />
                            <div
                                v-else
                                id="context-parent"
                                class="tw-flex mt-5"
                            >
                                <div class="col first-col-day p-0">
                                    <div>
                                        <div
                                            class="d-table w-100 mb-2 px-1 header-row"
                                            :class="{'big': showStaffSummary}"
                                        >
                                            <div class="kid pr-sm-3 float-none float-sm-left mb-0 mt-0 mt-sm-3">
                                                <MDropdown class="tw-w-full">
                                                    <MButton
                                                        class="tw-w-full"
                                                        icon-menu
                                                        :label="currentGroup.id ? currentGroup.name : __('common:all_kids')"
                                                        size="sm"
                                                        variant="primary"
                                                    />
                                                    <template #content>
                                                        <div>
                                                            <CDropdownMenuItem
                                                                class="tw-w-full"
                                                                :label="__('common:all_kids')"
                                                                @click="switchGroup()"
                                                            />
                                                            <CDropdownMenuItem
                                                                v-for="(group, i) in nursery.groups"
                                                                :key="'group' + i"
                                                                class="tw-w-full"
                                                                :label="group.name"
                                                                @click="switchGroup(group)"
                                                            />
                                                            <CDropdownMenuDivider class="tw-my-1"/>
                                                            <tags
                                                                class="tw-px-3 tw-py-2"
                                                                filter
                                                                :selected-tags.sync="selectedTags"
                                                                taggable-type="kid"
                                                                :tags="nursery.tags"
                                                            />
                                                        </div>
                                                    </template>
                                                </MDropdown>
                                            </div>
                                        </div>

                                        <div
                                            v-show="!loading"
                                            style="margin: 0px -0.3rem;"
                                        >
                                            <div
                                                v-for="(kid, i) in kids"
                                                :key="'kid' + i"
                                                class="line d-table w-100 tw-h-12"
                                            >
                                                <div class="kid tw-flex tw-items-center position-relative mb-0 tw-font-display float-none float-sm-left tw-h-full">
                                                    <BadgeTasks
                                                        size="sm"
                                                        :tasks="tasksByKidId[kid.id]"
                                                    >
                                                        <LegacyKidAvatar
                                                            class="tw-w-6"
                                                            :kid="kid"
                                                        />
                                                    </BadgeTasks>
                                                    <div class="ml-1 tw-font-grandhotel name text-capitalize">
                                                        <router-link
                                                            v-if="$can('read', 'kids_details')"
                                                            :class="kid.gender === 'male' ? 'tw-text-boy' : 'tw-text-girl'"
                                                            :to="{name:'kids.show',params:{nursery:nursery.id, kid:kid.id}}"
                                                        >
                                                            {{ $fullName(kid) }}
                                                        </router-link>
                                                        <span
                                                            v-else
                                                            :class="kid.gender === 'male' ? 'tw-text-boy' : 'tw-text-girl'"
                                                        >
                                                            {{ $fullName(kid) }}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div
                                    v-for="(dayOfWeek, dayIndex) in enumerateDaysBetweenDates"
                                    :key="'currentDay' + dayIndex"
                                    class="col col-day"
                                >
                                    <div>
                                        <div
                                            class="d-table w-100 mb-2 header-row"
                                            :class="{'big': showStaffSummary}"
                                        >
                                            <router-link
                                                class="day-text pointer"
                                                tag="div"
                                                :to="{name:'planning.kids',params:{nursery:nursery.id, date:dayOfWeek.clone().format('DD/MM/Y')}}"
                                            >
                                                <h6 class="h6 tw-text-gray-500 tw-font-normal">
                                                    <div
                                                        class="tw-flex lg:tw-flex-row tw-flex-col tw-items-center tw-justify-center tw-w-full d-print-none"
                                                        :class="{'tw-text-blue-500 tw-font-semibold': moment(dayOfWeek).isSame(date, 'day')}"
                                                    >
                                                        <div>
                                                            {{ $capitalize(fromMoment(dayOfWeek).toFormat('cccc')) }}
                                                        </div>
                                                        <div class="tw-mx-1 lg:tw-block tw-hidden">
                                                            -
                                                        </div>
                                                        <div>
                                                            {{ $capitalize(fromMoment(dayOfWeek).toFormat('dd/MM')) }}
                                                        </div>
                                                    </div>
                                                    <div class="w-100 d-none d-print-block">
                                                        {{ $capitalize(fromMoment(dayOfWeek).toFormat('cccc - dd/MM')) }}
                                                    </div>
                                                    <OccupationBadge
                                                        class="tw-mt-2"
                                                        :day="dayOfWeek"
                                                        :enable-count="false"
                                                        :loading="occupationLoading"
                                                        :occupations="occupations"
                                                    />
                                                </h6>
                                            </router-link>
                                            <div class="lg:tw-block tw-hidden planning">
                                                <div
                                                    class="planning summary-bar"
                                                    style="margin: 20px -0.8rem 0px;"
                                                >
                                                    <summary-bar
                                                        :class="{'mb-2': showStaffSummary}"
                                                        :closing="closing"
                                                        :day="dayOfWeek"
                                                        :nursery="nursery"
                                                        :opening="opening"
                                                        small
                                                        :summary="weekOccupationRateBar(dayOfWeek)"
                                                    />
                                                    <summary-bar-staff
                                                        v-if="showStaffSummary"
                                                        :closing="closing"
                                                        :day="dayOfWeek"
                                                        :nursery="nursery"
                                                        :opening="opening"
                                                        small
                                                        :summary="weekOccupationRateBarStaff(dayOfWeek)"
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div style="margin: 0 -1.25rem;">
                                            <div
                                                v-for="(kid, i) in kids"
                                                :key="'kid' + i"
                                                class="line d-table w-100 tw-h-12"
                                            >
                                                <div class="tw-pt-3">
                                                    <planning
                                                        :closing="closing"
                                                        :date="date"
                                                        :day="dayOfWeek"
                                                        :kid="kid"
                                                        :nursery="nursery"
                                                        :opening="opening"
                                                        :show-menu="$can('update', 'kids_planning')"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <context-menu
                                    id="contextMenu"
                                    :event="event"
                                    :nursery="nursery"
                                    :visible="toggleMenu"
                                />
                            </div>
                        </div>
                    </transition>
                    <h1
                        v-if="!Object.keys(kids).length"
                        class="h1 tw-font-grandhotel text-center"
                    >
                        {{ __('common:no_kids') }}
                    </h1>
                </div>
            </div>
        </template>
    </SimplePageTemplate>
</template>

<script>
    import route from '@/modules/legacy/libs/ziggy';
    import useApi from '@/modules/app/composables/useApi';
    import moment from 'moment';
    import {collect, Epoch} from '@meekohq/lumos';
    import _filter from 'lodash-es/filter';
    import _debounce from 'lodash-es/debounce';
    import _find from 'lodash-es/find';
    import _map from 'lodash-es/map';
    import _head from 'lodash-es/head';
    import _forEach from 'lodash-es/forEach';
    import _concat from 'lodash-es/concat';
    import * as ProcessedData from '@/modules/legacy/libs/ProcessedData';
    import {nursery} from '@/modules/legacy/mixins/nursery';
    import {url} from '@/modules/legacy/mixins/url';
    import {
        getOverrunEvents,
        getPlanningEventsFromKidContracts,
        getPresenceEvents,
    } from '@/modules/legacy/libs/Planning';
    import useBroadcast from '@/modules/app/composables/useBroadcast';
    import useGetTasks from '@/modules/activity/composables/useGetTasks';
    import SummaryBar from '@/modules/legacy/components/Modules/PlanningGenerator/Summary.vue';
    import SummaryBarStaff from '@/modules/legacy/components/Modules/PlanningGenerator/StaffSummary.vue';
    import Planning from '@/modules/planning/components/kid/WeeklyPlanning.vue';
    import ContextMenu from '@/modules/legacy/components/Modules/PlanningGenerator/ContextMenu.vue';
    import TopActions from '@/modules/planning/components/kid/TopActions.vue';
    import OccupationBadge from '@/modules/planning/components/core/OccupationBadge.vue';
    import BadgeTasks from '@/modules/activity/components/pages/BadgeTasks.vue';
    import KidModel from '@/modules/family/models/KidModel';
    import __ from '@/modules/app/utils/i18n-facade';
    import useEpoch from '@/modules/app/composables/useEpoch';
    import LegacyKidAvatar from '@/modules/app/components/atoms/avatars/LegacyKidAvatar.vue';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';
    import SimplePageTemplate from '@/modules/app/components/templates/SimplePageTemplate.vue';
    import TopActionsSwitcher from '@/modules/planning/components/kid/TopActionsSwitcher.vue';
    import {useIntervalFn} from '@vueuse/core';
    import useCalendar from '@/modules/human-resources/composables/calendar/useCalendar';

    export default {
        components: {
            TopActionsSwitcher,
            SimplePageTemplate,
            LegacyKidAvatar,
            'summary-bar': SummaryBar,
            'planning': Planning,
            'top-actions': TopActions,
            ContextMenu,
            SummaryBarStaff,
            OccupationBadge,
            BadgeTasks,
        },
        mixins: [nursery, url],
        props: ['nursery', 'user'],
        data: () => ({
            loading: false,
            occupationLoading: false,
            date: moment(),
            kids: [],
            currentGroup: {},
            occupations: [],
            supervision: [],
            event: {},
            toggleMenu: false,
            showStaffSummary: window.localStorage.getItem('showStaffSummary') === 'true',
            planningDisplayPreference: window.localStorage.getItem('planningDisplayPreference'),
            selectedTags: JSON.parse(localStorage.getItem('tags:kids:planning')),
            useGetTasks: undefined,
            tasks: undefined,
            tasksByKidId: collect(),
            Epoch,
        }),
        computed: {
            moment() {
                return moment;
            },
            fromDay() {
                return this.date.clone().startOf('week');
            },
            toDay() {
                return this.date.clone().endOf('week');
            },
            opening() {
                let hours = this.supervision[0] ? this.supervision[0].summary : [];
                this.occupations[0] ? hours = hours.concat(this.occupations[0].summary) : null;

                return this.$getOpening(this.nursery.openingHours, true, false, hours);
            },
            closing() {
                let hours = this.supervision[0] ? this.supervision[0].summary : [];
                this.occupations[0] ? hours = hours.concat(this.occupations[0].summary) : null;

                return this.$getClosing(this.nursery.openingHours, true, false, hours);
            },
            enumerateDaysBetweenDates() {
                const dates = [];

                const currDate = moment(this.fromDay).startOf('day');
                const lastDate = moment(this.toDay).startOf('day');

                dates.push(currDate.clone());
                while (currDate.add(1, 'days').diff(lastDate) <= 0) {
                    const day = currDate.clone().locale('en').format('ddd').toLowerCase();
                    if (day === 'sat' || day === 'sun') {
                        const hasDay = _find(this.nursery.openingHours, {day});
                        hasDay ? dates.push(currDate.clone()) : null;
                    } else {
                        dates.push(currDate.clone());
                    }
                }

                return dates;
            },
        },
        watch: {
            'date': function(val, old) {
                if (val.unix() !== old.unix()) {
                    this.loading = true;
                    this.debounceDate();
                }
            },
            'fromDay': function(val, oldVal) {
                if (val.format('YYYY-MM-DD') !== oldVal.format('YYYY-MM-DD')) {
                    this.occupationLoading = true;
                    this.debounceFromDay(val, oldVal);
                }
            },
            'nursery.id': function() {
                this.fetchNursery();
            },
            'selectedTags': _debounce(function(val) {
                if (val) {
                    localStorage.setItem('tags:kids:planning', JSON.stringify(val));
                } else {
                    localStorage.removeItem('tags:kids:planning');
                }

                this.getKids();
                this.getOccupation();
                this.showStaffSummary ? this.getSupervision() : null;
            }, 500),
            '$route.query.date': function(date) {
                this.date = date ? moment.unix(date) : moment();
            },
            'kids': async function(kids) {
                this.tasks = await this.useGetTasks.getTasks(kids.map(kid => kid.id));
            },
            tasks() {
                this.tasksByKidId = this.useGetTasks.groupTasks();
            },
        },
        created() {
            this.useGetTasks = useGetTasks({
                constrainToResourceModel: KidModel,
            });
        },
        beforeMount() {
            this.date = this.$route.query.date ? moment.unix(this.$route.query.date) : useCalendar().getClosestOpeningDay();
            this.fetchAll();
        },
        mounted() {
            if (this.selectedTags) {
                const existingTags = this.selectedTags.filter(tag => this.nursery.tags.find(item => item.id === tag));
                if (existingTags.length !== this.selectedTags.length) {
                    this.selectedTags = existingTags;
                }
            }

            useIntervalFn(() => {
                // Update events with no end each minute to display the current time
                this.kids.forEach(kid => {
                    if (kid.presences.filter(event => !event.ended_at).length > 0) {
                        kid.events = this.getEvents(kid);
                    }
                });
            }, 1000 * 60);

            useBroadcast().sessionChannel.bind('occupation', response => {
                ProcessedData.retrieve(response.processedDataUrl, response => {
                    this.occupations = response.data;
                    this.occupationLoading = false;
                });
            });

            useBroadcast().sessionChannel.bind('kid_plannings', response => {
                ProcessedData.retrieve(response.processedDataUrl, response => {
                    this.kids = _map(response.data, kid => {
                        _map(kid.presences, presence => {
                            presence.type = 'presence';
                            presence.started_at = presence.droped_at;
                            presence.ended_at = presence.picked_up_at;
                            presence.nursery_id = this.nursery.id;

                            return presence;
                        });

                        _map(kid.absences, absence => {
                            absence.type = 'absence';
                            absence.event_type = 'absence';
                            absence.nursery_id = this.nursery.id;

                            return absence;
                        });

                        kid.events = this.getEvents(kid);

                        return kid;
                    });
                    this.loading = false;
                });
            });

            if (this.showStaffSummary) {
                useBroadcast().sessionChannel.bind('supervision', response => {
                    ProcessedData.retrieve(response.processedDataUrl, response => {
                        this.supervision = response.data;
                        this.occupationLoading = false;
                    });
                });
            }

            this.fetchNursery();
        },
        beforeDestroy() {
            this.$bus.$off('refresh:kids');

            this.$bus.$off('clicked:event');

            this.$bus.$off('created:absence');
            this.$bus.$off('updated:absence');
            this.$bus.$off('deleted:absence');

            this.$bus.$off('created:presence');
            this.$bus.$off('updated:presence');
            this.$bus.$off('deleted:presence');

            this.$bus.$off('created:planning');
            this.$bus.$off('updated:planning');
            this.$bus.$off('deleted:planning');

            useBroadcast().sessionChannel.unbind('occupation');
            useBroadcast().sessionChannel.unbind('kid_plannings');
            this.showStaffSummary ? useBroadcast().sessionChannel.unbind('supervision') : null;
        },
        methods: {
            fromMoment: useEpoch().fromMoment,
            debounceDate: _debounce(function() {
                this.fetchAll();
            }, 1000),
            debounceFromDay: _debounce(function() {
                if (this.nursery.places && this.nursery.openingHours.length) {
                    this.getOccupation();
                    this.showStaffSummary ? this.getSupervision() : null;
                }
            }, 1000),
            fetchNursery() {
                this.occupationLoading = false;

                if (this.$route.params.date) {
                    this.date = moment(this.$route.params.date, 'DD/MM/Y');
                }

                this.currentGroup = {
                    id: null,
                    name: __('common:all_kids'),
                };

                this.$bus.$on('clicked:event', event => {
                    this.event = event;
                    this.event.start_time = moment.unix(this.event.started_at).format(
                        'HH:mm:ss');
                    this.event.end_time = moment.unix(this.event.ended_at).format('HH:mm:ss');
                    this.toggleMenu = true;
                    setTimeout(() => {
                        this.toggleMenu = false;
                    }, 200);
                });

                this.$bus.$on('refresh:kids', () => {
                    this.getKids();
                });

                this.$bus.$on('created:absence', absences => {
                    if (!absences.length) {
                        absences = [absences];
                    }

                    absences.forEach(absence => {
                        const kid = this.kids.find(item => item.id === absence.kid_id);
                        if (kid) {
                            absence.type = 'absence';
                            absence.event_type = 'absence';
                            absence.nursery_id = this.nursery.id;
                            kid.absences.push(absence);
                            kid.events = this.getEvents(kid);
                        }
                    });

                    this.getOccupation();
                    this.showStaffSummary ? this.getSupervision() : null;
                });

                this.$bus.$on('updated:absence', absence => {
                    const kid = this.kids.find(item => item.id === absence.kid_id);
                    if (kid && kid.absences.length) {
                        const abs = kid.absences.find(item => item.id === absence.id);
                        if (abs) {
                            abs.started_at = absence.started_at;
                            abs.ended_at = absence.ended_at;
                            abs.vacation = absence.vacation;
                            abs.billed = absence.billed;
                            abs.note = absence.note;
                            abs.unit = absence.unit;
                            abs.hours = absence.hours;
                            abs.hourly_rate = absence.hourly_rate;
                            kid.events = this.getEvents(kid);
                            this.getOccupation();
                            this.showStaffSummary ? this.getSupervision() : null;
                        }
                    }
                });

                this.$bus.$on('deleted:absence', absence => {
                    const kid = this.kids.find(item => item.id === absence.kid_id);
                    if (kid && kid.absences.length) {
                        const abs = kid.absences.find(item => item.id === absence.id);
                        if (abs) {
                            const i = kid.absences.indexOf(abs);
                            kid.absences.splice(i, 1);
                            kid.events = this.getEvents(kid);
                            this.getOccupation();
                            this.showStaffSummary ? this.getSupervision() : null;
                        }
                    }
                });

                this.$bus.$on('created:presence', presence => {
                    const kid = this.kids.find(item => item.id === presence.kid_id);
                    if (kid) {
                        presence.started_at = presence.droped_at;
                        presence.ended_at = presence.picked_up_at;
                        presence.type = 'presence';
                        presence.nursery_id = this.nursery.id;
                        kid.presences.push(presence);
                        kid.events = this.getEvents(kid);
                        this.getOccupation();
                        this.showStaffSummary ? this.getSupervision() : null;
                    }
                });

                this.$bus.$on('updated:presence', presence => {
                    const kid = this.kids.find(item => item.id === presence.kid_id);
                    if (kid && kid.presences.length) {
                        const pre = kid.presences.find(item => item.id === presence.id);
                        if (pre) {
                            pre.droped_at = presence.droped_at;
                            pre.picked_up_at = presence.picked_up_at;
                            pre.started_at = presence.droped_at;
                            pre.ended_at = presence.picked_up_at;
                            pre.drop_note = presence.drop_note;
                            pre.pick_up_note = presence.pick_up_note;

                            kid.events = this.getEvents(kid);
                            this.getOccupation();
                            this.showStaffSummary ? this.getSupervision() : null;
                        }
                    }
                });

                this.$bus.$on('deleted:presence', presence => {
                    const kid = this.kids.find(item => item.id === presence.kid_id);
                    if (kid && kid.presences.length) {
                        const pre = kid.presences.find(item => item.id === presence.id);
                        if (pre) {
                            const i = kid.presences.indexOf(pre);
                            kid.presences.splice(i, 1);
                            kid.events = this.getEvents(kid);
                            this.getOccupation();
                            this.showStaffSummary ? this.getSupervision() : null;
                        }
                    }
                });

                this.$bus.$on('created:planning', planning => {
                    const kid = this.kids.find(item => item.id === planning.kid_id);
                    if (kid) {
                        const contract = kid.contracts.find(item => item.id === planning.contract_id);
                        if (contract) {
                            planning.nursery_id = this.nursery.id;
                            contract.plannings.push(planning);
                            kid.events = this.getEvents(kid);
                            this.getOccupation();
                            this.showStaffSummary ? this.getSupervision() : null;
                        }
                    } else {
                        this.fetchAll();
                    }
                });

                this.$bus.$on('updated:planning', planning => {
                    const kid = this.kids.find(item => item.id === planning.kid_id);
                    if (kid) {
                        const contract = kid.contracts.find(item => item.id === planning.contract_id);
                        if (contract && contract.plannings.length) {
                            const plan = contract.plannings.find(item => item.id === planning.id);
                            if (plan) {
                                plan.week = planning.week;
                                plan.day = planning.day;
                                plan.start_time = planning.start_time;
                                plan.end_time = planning.end_time;
                                plan.note = planning.note;
                                plan.unit = planning.unit;
                                plan.hours = planning.hours;
                                plan.hourly_rate = planning.hourly_rate;
                            }
                        }
                        kid.events = this.getEvents(kid);
                        this.getOccupation();
                        this.showStaffSummary ? this.getSupervision() : null;
                    }
                });

                this.$bus.$on('deleted:planning', planning => {
                    const kid = this.kids.find(item => item.id === planning.kid_id);
                    if (kid) {
                        const contract = kid.contracts.find(item => item.id === planning.contract_id);
                        if (contract && contract.plannings.length) {
                            const plan = contract.plannings.find(item => item.id === planning.id);
                            if (plan) {
                                const i = contract.plannings.indexOf(plan);
                                contract.plannings.splice(i, 1);
                            }
                        }
                        kid.absences = kid.absences.filter(item => item.planning_id !== planning.id);
                        kid.events = this.getEvents(kid);
                        this.getOccupation();
                        this.showStaffSummary ? this.getSupervision() : null;
                    }
                });
            },
            getKids() {
                this.loading = true;

                let tags = undefined;
                if (this.selectedTags) {
                    tags = this.selectedTags.length ? this.selectedTags : '';
                }

                if (this.currentGroup) {
                    useApi().legacy.get(route('nurseries.planning.kids', {nurseries: this.nursery.id}), {
                        params: {
                            from: this.fromDay.unix(),
                            to: this.toDay.unix(),
                            group_id: this.currentGroup.id ? this.currentGroup.id : null,
                            tags,
                            order_by: window.localStorage.getItem('display:orderBy') ? window.localStorage.getItem('display:orderBy') : 'first_name',
                        },
                    }).catch(error => {
                        if (error && error.response && error.response.status === 422) {
                            _forEach(error.response.data.errors, function(value) {
                                useNotification().error(_head(value));
                            });
                        } else {
                            useNotification().error(error);
                        }
                    });
                }
            },
            getOccupation() {
                this.occupationLoading = true;

                let tags = undefined;
                if (this.selectedTags) {
                    tags = this.selectedTags.length ? this.selectedTags : '';
                }

                useApi().legacy.get(route('nurseries.occupation', {nurseries: this.nursery.id}), {
                    params: {
                        from: this.fromDay.unix(),
                        to: this.toDay.unix(),
                        group_id: this.currentGroup.id ? this.currentGroup.id : null,
                        interval: 15,
                        tags,
                    },
                }).catch(error => {
                    this.occupationLoading = false;
                    if (error && error.response && error.response.status === 422) {
                        _forEach(error.response.data.errors, function(value) {
                            useNotification().error(_head(value));
                        });
                    } else {
                        useNotification().error(error);
                    }
                });
            },
            getSupervision() {
                this.occupationLoading = true;

                let tags = undefined;
                if (this.selectedTags) {
                    tags = this.selectedTags.length ? this.selectedTags : '';
                }

                useApi().legacy.get(route('nurseries.supervision', {nurseries: this.nursery.id}), {
                    params: {
                        from: this.fromDay.unix(),
                        to: this.toDay.unix(),
                        group_id: this.currentGroup.id ? this.currentGroup.id : null,
                        interval: 15,
                        tags,
                    },
                }).catch(error => {
                    this.occupationLoading = false;
                    if (error && error.response && error.response.status === 422) {
                        _forEach(error.response.data.errors, function(value) {
                            useNotification().error(_head(value));
                        });
                    } else {
                        useNotification().error(error);
                    }
                });
            },
            switchGroup(group) {
                if (!group) {
                    this.currentGroup = {
                        id: null,
                        name: __('common:all_kids'),
                    };
                } else {
                    this.currentGroup = group;
                }
                this.fetchAll();
            },
            getEvents(kid) {
                const kidPlannings = this.computeKidPlanning(kid);
                const kidPresences = getPresenceEvents(kid.presences, kidPlannings);
                const kidOverruns = getOverrunEvents(kidPresences, kidPlannings);

                return _concat(kidPlannings, kidPresences, kid.absences, kidOverruns);
            },
            getOccupationRate(day) {
                let occupation = null;

                if (this.occupations.length) {
                    occupation = this.occupations.find(o => {
                        return o.date === day.clone().format('YYYY-MM-DD');
                    });
                }

                return occupation ? occupation.percent.toFixed(1) : null;
            },
            weekOccupationRateBar(date) {
                const filter = _filter(this.occupations, occupation => {
                    return occupation.date === moment(date).format('YYYY-MM-DD');
                });

                if (filter.length) {
                    return _head(filter).summary;
                }

                return [];
            },
            weekOccupationRateBarStaff(date) {
                const filter = _filter(this.supervision, supervision => {
                    return supervision.date === moment(date).format('YYYY-MM-DD');
                });

                if (filter.length) {
                    return _head(filter).summary;
                }

                return [];
            },
            hidePopovers() {
                this.$root.$emit('bv::hide::popover');
            },
            computeKidPlanning(kid) {
                let output = [];
                _forEach(this.enumerateDaysBetweenDates, day => {
                    output = _concat(output, getPlanningEventsFromKidContracts(kid.contracts, day));
                });

                return output;
            },
            fetchAll() {
                this.getKids();
                this.getOccupation();

                if (this.showStaffSummary) {
                    this.getSupervision();
                }
            },
        },
    };
</script>

<style
    lang="scss"
    scoped
>
    @import "bootstrap/scss/functions";
    @import "@/assets/_bootstrap/variables";
    @import "bootstrap/scss/mixins/breakpoints";

    @include media-breakpoint-up(sm) {
        .kid {
            width: 120px;

            .dropdown-toggle {
                font-size: 12px;
                height: 20px;
                padding: 0px 9px;
                margin-top: 4px;

                &::after {
                    bottom: 8px;
                }
            }
        }

        .first-col-day {
            max-width: 120px;

            .planning {
                width: calc(100% - 170px - 35px);
            }

            .summary-bar {
                margin-left: 170px;
            }
        }
    }

    .selected-day {
        display: none;
    }

    @media print {
        @page {
            size: auto;
            max-height: 100%;
            max-width: 100%;
        }
        body {
            writing-mode: tb-rl; //landscape
        }
        .dropdown-toggle {
            &::after {
                display: none;
            }
        }
    }

    .name {
        font-size: 1.2rem;
        line-height: 1.5rem;
        position: relative;

        overflow: hidden;
        text-overflow: ellipsis;
        width: 110px;
        white-space: nowrap;
    }

    .col-day:not(.first-col-day) {
        min-width: 100px;
        transition: flex-grow .2s ease-in-out;
        opacity: .8;
        padding: 0px 20px;

        &:hover {
            opacity: 1;
            flex-grow: 10;
        }
    }

    .header-row {
        height: 40px;

        &.big {
            height: 70px;
        }
    }

    .btn, .badge {
        -webkit-print-color-adjust: exact !important; /* Chrome, Safari */
        print-color-adjust: exact !important; /*Firefox*/
        border: 0 !important;
    }

    .line {
        padding: 0 .5rem;

        &:nth-child(even) {
            @apply tw-bg-primary-50;
        }
    }

    .day-text {
        position: absolute;
        top: -40px;
        left: 0px;
        width: 100%;
        text-align: center;

        .badge {
            font-size: 12px;
        }
    }

    .day {
        width: 140px;
        display: inline-block;
        padding: 1.25rem 0;
        background-color: $gray-200;
        margin: 0 5px;
        cursor: pointer;

        h1, h5 {
            transition: color 200ms;
            color: $gray-600;
            font-weight: 400;
        }

        &:first-of-type {
            margin-left: 0;
        }

        &:last-of-type {
            margin-right: 0;
        }

        transition: all 200ms ease-in;
        transform: scale(1);

        &:hover {
            background-color: $gray-300;

            h1, h5 {
                color: $gray-700;
            }
        }

        &.active {
            $color: theme-color('primary');
            background-color: $color;

            h1, h5 {
                color: white;
            }

            transform: scale(1.05);
            box-shadow: 0 0 0 1px $color, 0 1.5px 1px 0 rgba(67, 69, 139, .15), 0 2px 5px 0 rgba(50, 50, 93, .1), 0 1px 2px 0 rgba(0, 0, 0, .08), 0 0 0 0 transparent;
        }

        @include media-breakpoint-down(sm) {
            h1 {
                font-size: 2rem;
            }

            h5 {
                font-size: 1rem;
            }
        }
    }

    .badge {
        font-weight: 500;
        padding: 0.4em 0.5em;

        &.badge-warning {
            color: white;
        }

        &.badge-danger {
            background-color: #ff4b4a;
        }
    }

    .nav-link {
        font-size: 15px;
        padding: 8px 15px;
    }
</style>
