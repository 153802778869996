<template>
    <div class="TransactionForm">
        <CFormGroup class="TransactionForm__amount-item">
            <CLabel>
                {{ __('common:amount_one') }}
            </CLabel>
            <CurrencyInput
                v-model="transaction.attributes.amount"
                :allow-undefined="false"
                :currency="amountCurrency"
                :input-props="{size:'lg'}"
                :min="0"
                @input="$emit('amountChanged', $event)"
            />
            <CFormErrorMessageList :errors="transactionError.get('amount')"/>
        </CFormGroup>
        <div
            class="TransactionForm__grid"
            :class="canSelectCustomer ? 'TransactionForm__grid--two-col' : 'TransactionForm__grid--three-col'"
        >
            <CFormGroup
                v-if="canSelectCustomer"
                class="TransactionForm__item"
            >
                <CLabel>
                    {{ __('common:customer_one') }}
                </CLabel>
                <CustomerFinder
                    v-model="customer"
                    button-class="tw-w-full"
                    :inject-query="customerFinderQuery"
                />
                <CFormErrorMessageList :errors="transactionError.get('customer_id')"/>
            </CFormGroup>
            <CFormGroup class="TransactionForm__item">
                <CLabel>
                    {{ __('billing_transaction:paid_at') }}
                </CLabel>
                <MDatePicker
                    v-model="transaction.attributes.date"
                    allow-undefined
                />
                <CFormErrorMessageList :errors="transactionError.get('date')"/>
            </CFormGroup>
            <CFormGroup class="TransactionForm__item">
                <CLabel>
                    {{ __('billing_transaction:method') }}
                </CLabel>
                <PaymentMethodFinder
                    v-model="paymentMethod"
                    button-class="tw-w-full"
                />
                <CFormErrorMessageList :errors="transactionError.get('payment_method_id')"/>
            </CFormGroup>
            <CFormGroup class="TransactionForm__item">
                <CLabel>
                    {{ __('billing_transaction:reference') }}
                </CLabel>
                <CInput
                    v-model="transaction.attributes.reference"
                    :placeholder="__('billing_transaction:ref_dash_xxxxx')"
                />
                <CFormErrorMessageList :errors="transactionError.get('reference')"/>
            </CFormGroup>
        </div>
    </div>
</template>

<script lang="ts">
    import type {PropType} from 'vue';
    import {computed, defineComponent} from 'vue';
    import {catcher, ValidationError} from '@meekohq/lumos';
    import CustomerFinder from '@/modules/cashier/components/core/customer/organisms/CustomerFinder.vue';
    import CustomerModel from '@/modules/cashier/models/CustomerModel';
    import PaymentMethodFinder from '@/modules/cashier/components/payment-method/PaymentMethodFinder.vue';
    import useManager from '@/modules/app/composables/useManager';
    import type {TransactionStateType} from '@/modules/cashier/composables/issuing/transaction/useTransactionState';
    import TransactionModel from '@/modules/cashier/transaction/domain/TransactionModel';
    import CurrencyInput from '@/modules/app/components/atoms/CurrencyInput.vue';

    export default defineComponent({
        components: {
            CurrencyInput,
            PaymentMethodFinder,
            CustomerFinder,
        },
        props: {
            state: {
                type: Object as PropType<TransactionStateType>,
                required: true,
            },
            canSelectCustomer: {
                type: Boolean,
                default: true,
            },
        },
        setup(props) {
            const {
                bus,
                transaction,
                customer,
                paymentMethod,
                currency,
                transactionError,
            } = props.state;

            const {activeTenant} = useManager();

            const amountCurrency = computed(() => {
                return transaction.value.computed.currency_iso_code ? transaction.value.computed.currency_iso_code : currency.value?.attributes.code;
            });

            // Fetch only customers from the current organization
            const customerFinderQuery = CustomerModel.query().whereHas(new CustomerModel().tenant(), query => {
                query.where('id', activeTenant.value.getKey());
            });

            bus.on('reset-error', () => {
                transactionError.reset();
            });

            bus.on('error', e => {
                catcher()
                    .on(ValidationError, value => {
                        if (
                            value.model instanceof TransactionModel
                            && value.model.getKey() === transaction.value.getKey()
                        ) {
                            transactionError.reset();
                            transactionError.addValidationError(value);
                        }
                    })
                    .catch(e);
            });

            return {
                transactionError,
                amountCurrency,
                customer,
                customerFinderQuery,
                paymentMethod,
                transaction,
            };
        },
    });
</script>

<style lang="scss" scoped>
    .TransactionForm {
        @apply tw-contents;
    }

    .TransactionForm__amount-item {
        @apply sm:tw-w-[calc(50%-6px)] tw-w-full;
    }

    .TransactionForm__grid {
        @apply tw-w-full tw-gap-3 tw-items-center;
        @apply tw-grid tw-grid-cols-1;

        &--three-col {
            @apply sm:tw-grid-cols-3;
        }

        &--two-col {
            @apply sm:tw-grid-cols-2;
        }
    }

    .TransactionForm__item {
        @apply tw-min-w-0;
    }
</style>
