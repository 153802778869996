import {Epoch, Model} from '@meekohq/lumos';
import AllocationModel from '@/modules/cashier/models/AllocationModel';
import TenantModel from '@/modules/cashier/models/TenantModel';
import CustomerModel from '@/modules/cashier/models/CustomerModel';
import PaymentModel from '@/modules/cashier/models/PaymentModel';
import InvoiceTypeValue from '@/modules/cashier/utils/billing/invoice/InvoiceTypeValue';
import InvoiceStatusValue from '@/modules/cashier/utils/billing/invoice/InvoiceStatusValue';
import TagModel from '@/modules/tag/models/TagModel';
import TagPivot from '@/modules/tag/models/TagPivot';

export default class InvoiceModel extends Model {
    public type = 'cashier/invoices';

    public attributes: {
        id: string;
        increment: number | null;
        tenant_id: string | undefined;
        customer_id: string | undefined;
        organization_id: string | undefined;
        no: string | undefined;
        contract_no: string | undefined;
        kid_name: string | undefined;
        start_period_date: string | null;
        end_period_date: string | null;
        date: string | undefined;
        due_date: string | undefined;
        state: string | undefined;
        grand_total: number | null;
        type: string | null;
        status: InvoiceStatusValue | undefined;
        uncollectible_date: string | undefined;
        uncollectible_description: string | undefined;
    } = {
            id: this.uuid(),
            increment: null,
            tenant_id: undefined,
            customer_id: undefined,
            organization_id: undefined,
            no: undefined,
            contract_no: undefined,
            kid_name: undefined,
            start_period_date: null,
            end_period_date: null,
            date: undefined,
            due_date: undefined,
            state: undefined,
            grand_total: null,
            type: null,
            status: undefined,
            uncollectible_date: undefined,
            uncollectible_description: undefined,
        };

    public computed: {
        unsigned_grand_total: number,
        invoice_type?: InvoiceTypeValue,
        remaining_amount: number,
        currency_iso_code: string,
        distributed_amount: number,
    } = {
            unsigned_grand_total: 0,
            remaining_amount: 0,
            distributed_amount: 0,
            currency_iso_code: '',
            invoice_type: undefined,
        };

    get paymentsRelation() {
        return this.computed.invoice_type === InvoiceTypeValue.creditNote
            ? this.refunds().value()
            : this.payments().value();
    }

    get isCreditNote() {
        return this.computed.invoice_type === InvoiceTypeValue.creditNote;
    }

    get isDraft() {
        return this.attributes.status === InvoiceStatusValue.draft;
    }

    get isEditing() {
        return this.attributes.status === InvoiceStatusValue.draft && this.attributes.increment !== null;
    }

    get isCompany() {
        return this.attributes.type === 'company';
    }

    get date(): Epoch | undefined {
        if (!this.attributes.date) {
            return undefined;
        }

        return Epoch.parse(this.attributes.date, 'yyyy-MM-dd');
    }

    get remaingAmount(): number {
        return this.computed.remaining_amount;
    }

    tenant() {
        return this.belongsTo('tenant', TenantModel, 'tenant_id');
    }

    customer() {
        return this.belongsTo('customer', CustomerModel, 'customer_id');
    }

    /**
     * Allocations from this invoice to other resources.
     */
    allocationsAsSource() {
        return this.morphMany('allocationsAsSource', AllocationModel, 'source');
    }

    /**
     * Allocations from other resources to this invoice.
     */
    allocationsAsDestination() {
        return this.morphMany('allocationsAsDestination', AllocationModel, 'destination');
    }

    payments() {
        return this.morphToMany('payments', PaymentModel, AllocationModel, 'destination', 'destination_id', 'source_id')
            .wherePivot('source_type', new PaymentModel().getType());
    }

    refunds() {
        return this.morphToMany('refunds', PaymentModel, AllocationModel, 'source', 'source_id', 'destination_id')
            .wherePivot('destination_type', new PaymentModel().getType());
    }

    tags() {
        return this.morphToMany('tags', TagModel, TagPivot, 'resource', undefined, 'tag_id');
    }
}
