/* eslint-disable @typescript-eslint/member-ordering */
import type {Collection} from '@meekohq/lumos';
import {collect} from '@meekohq/lumos';
import type ISplittable from '@/modules/legacy/libs/periodSplitter/ISplittable';
import _clone from 'lodash-es/clone';
import getOverlappingEvents from '@/modules/legacy/libs/periodSplitter/getOverlappingEvents';

export interface ISplittedEvent<TSplittable extends ISplittable> {
    currentEvent: TSplittable | null,
    beforeEvent: TSplittable | null,
    afterEvent: TSplittable | null,
}

export type StrategyCallbackType<TSplittable extends ISplittable> =
    (originalEvent: TSplittable, overlappingEvent: TSplittable | null, splittedEvents: ISplittedEvent<TSplittable>) => TSplittable[];

export default class SplitEvent<TSplittable extends ISplittable> {
    protected _events: Collection<TSplittable>;

    /**
     *
     * @param events
     */
    public constructor(events: TSplittable[]) {
        this._events = SplitEvent.sortEvents(collect(events));
    }

    /**
     *
     * @param events
     */
    protected static sortEvents<TSplittable extends ISplittable>(events: Collection<TSplittable>) {
        return events.sortBy('startedAt');
    }

    /**
     *
     * @param currentEvent
     * @param overlappingEvent
     */
    protected static constrainInEventBoundaries<TSplittable extends ISplittable>(
        currentEvent: TSplittable, overlappingEvent: TSplittable,
    ): ISplittedEvent<TSplittable> {
        // Init splitted events
        let beforeEvent: TSplittable | null = null;
        let afterEvent: TSplittable | null = null;

        if (currentEvent.startedAt < overlappingEvent.startedAt) {
            // Split before Event
            beforeEvent = _clone(currentEvent);
            beforeEvent.endedAt = overlappingEvent.startedAt;

            // Change started at of currentEvent
            currentEvent.startedAt = overlappingEvent.startedAt;
        }

        if (currentEvent.endedAt > overlappingEvent.endedAt) {
            // Split after Event
            afterEvent = _clone(currentEvent);
            afterEvent.startedAt = overlappingEvent.endedAt;

            // Change ended at of currentEvent
            currentEvent.endedAt = overlappingEvent.endedAt;
        }

        return {currentEvent, beforeEvent, afterEvent};
    }

    /**
     *
     * @param currentEvent
     * @param beforeEvent
     * @param afterEvent
     */
    protected static initSplittedEvent<TSplittable extends ISplittable>(
        currentEvent: TSplittable | null = null,
        beforeEvent: TSplittable | null = null,
        afterEvent: TSplittable | null = null,
    ): ISplittedEvent<TSplittable> {
        return {
            currentEvent,
            beforeEvent,
            afterEvent,
        };
    }

    /**
     *
     * @param strategyCallback
     */
    public setStrategyCallback(strategyCallback: StrategyCallbackType<TSplittable>) {
        this._strategyCallback = strategyCallback;

        return this;
    }

    public split(): TSplittable[] {
        let splitEvents: TSplittable[] = [];

        for (const event of this._events.all()) {
            const newEvents = this.processItem(event);

            if (newEvents.length) {
                splitEvents = splitEvents.concat(newEvents);
            }
        }

        return splitEvents;
    }

    // eslint-disable-next-line @typescript-eslint/naming-convention
    protected _strategyCallback: StrategyCallbackType<TSplittable> = () => [];

    /**
     *
     * @param event
     */
    protected processItem(event: TSplittable): TSplittable[] {
        let newEvents: TSplittable[] = [];

        // Get all events overlapping
        const overlappingEvents = getOverlappingEvents(event, this._events);

        const cloneEvent = _clone(event);

        if (overlappingEvents.length) {
            for (const overlappingEvent of overlappingEvents) {
                const splittedEvents = SplitEvent.constrainInEventBoundaries(_clone(event), overlappingEvent);

                const keepedEvents = this._strategyCallback(cloneEvent, overlappingEvent, splittedEvents);
                if (keepedEvents.length) {
                    newEvents = newEvents.concat(keepedEvents);
                }
            }
        } else {
            const keepedEvents = this._strategyCallback(cloneEvent, null, SplitEvent.initSplittedEvent());
            if (keepedEvents.length) {
                newEvents = newEvents.concat(keepedEvents);
            }
        }

        return collect(newEvents).unique().toArray();
    }
}
