<template>
    <div
        class="tw-cursor-pointer hover:tw-bg-gray-50"
        :class="showByList ? 'tw-min-h-14' : 'tw-border tw-border-gray-200 tw-shadow tw-rounded-lg tw-p-3'"
        @click="$emit('showInvoice')"
    >
        <div class="tw-flex tw-flex-wrap tw-items-center">
            <!-- INVOICE_NUM & KID -->
            <div
                class="tw-flex"
                :class="showByList ? 'tw-w-full lg:tw-w-3/6 tw-gap-4' : 'lg:tw-w-6/12 tw-gap-2'"
            >
                <template v-if="showByList">
                    <div class="tw-flex tw-w-full lg:tw-w-1/2 tw-items-center">
                        <InvoiceIcon
                            class="tw-text-2xl tw-mr-3"
                            :invoice="invoice"
                        />
                        <div>
                            <InvoiceNumberTitle
                                :invoice="invoice"
                                with-color-status
                            />
                            <div class="tw-text-base tw-font-semibold tw-text-gray-500">
                                <span v-if="inverseDateKid">{{ $formatDate(invoice.date) }}</span>
                                <router-link
                                    v-else-if="invoice.kid_id"
                                    class="tw-text-gray-500 hover:tw-text-blue-600 hover:tw-underline"
                                    :to="{name:'kids.show',params:{nursery:nursery.id, kid:invoice.kid_id}}"
                                    @click.stop
                                >
                                    <MTooltip
                                        :label="__('common:show_kid_page')"
                                        placement="top"
                                    >
                                        <div class="tw-truncate tw-max-w-32 md:tw-max-w-40">
                                            {{ invoice.kid_name }}
                                        </div>
                                    </MTooltip>
                                </router-link>
                            </div>
                        </div>
                        <div class="lg:tw-hidden tw-flex-1 tw-text-base tw-text-gray-700 tw-font-semibold tw-uppercase tw-flex tw-flex-col tw-items-end tw-ml-2">
                            <div class="tw-flex lg:tw-none tw-w-full tw-justify-end tw-mb-2 tw-capitalize">
                                <InvoiceStatusBadge :invoice="invoice"/>
                            </div>
                            <div
                                v-if="inverseDateKid"
                                class="tw-line-clamp-2 tw-text-right tw-max-w-32 md:tw-max-w-48"
                            >
                                {{ invoice.kid_name }}
                            </div>
                            <span v-else>{{ $formatDate(invoice.date) }}</span>
                        </div>
                    </div>
                    <div class="tw-hidden lg:tw-block tw-w-1/2 tw-self-center">
                        <template v-if="inverseDateKid">
                            <div v-if="invoice.kid_id">
                                <router-link
                                    class="tw-text-base tw-text-gray-600 tw-font-semibold tw-uppercase hover:tw-text-blue-600 hover:tw-underline"
                                    :to="{name:'kids.show',params:{nursery:nursery.id, kid:invoice.kid_id}}"
                                    @click.prevent
                                >
                                    <MTooltip
                                        :label="__('common:show_kid_page')"
                                        placement="top"
                                    >
                                        <div class="tw-truncate tw-max-w-32 md:tw-max-w-48">
                                            {{ invoice.kid_name }}
                                        </div>
                                    </MTooltip>
                                </router-link>
                            </div>
                            <router-link
                                :to="{name:'cashier/customers.show.invoices',params:{nursery:nursery.id, customer:invoice.customer_id}}"
                                @click.prevent
                            >
                                <MTooltip
                                    :label="__('common:view_customer_account')"
                                    placement="top"
                                >
                                    <span class="tw-text-sm tw-tracking-wide tw-uppercase tw-font-semibold tw-text-gray-500 hover:tw-text-orange-500">
                                        <i
                                            v-if="invoice.customer && invoice.customer.person === 'legal'"
                                            class="fad fa-city fa-swap-opacity"
                                        />
                                        <i
                                            v-else
                                            class="fas fa-user"
                                        />
                                        {{ invoice.customer && invoice.customer.name ? invoice.customer.name : __('billing_invoice:view_account') }}
                                    </span>
                                </MTooltip>
                            </router-link>
                        </template>
                        <span
                            v-else
                            class="tw-text-base tw-text-gray-600 tw-font-semibold tw-uppercase"
                        >{{ $formatDate(invoice.date) }}</span>
                    </div>
                </template>

                <template v-else>
                    <LegacyKidAvatar
                        class="tw-w-10 tw-shrink-0 tw-self-center tw-mr-2"
                        :kid="{...invoice.kid}"
                    />
                    <div>
                        <div class="tw-block">
                            <router-link
                                v-if="invoice.kid_id"
                                class="tw-font-grandhotel tw-text-2xl hover:tw-text-blue-600 hover:tw-underline"
                                :class="[invoice.kid.gender === 'female' ? 'tw-text-girl' : 'tw-text-boy']"
                                :to="{name:'kids.show',params:{nursery:nursery.id, kid:invoice.kid_id}}"
                                @click.prevent
                            >
                                <MTooltip
                                    :label="__('common:show_kid_page')"
                                    placement="top"
                                >
                                    <div class="tw-truncate tw-max-w-32 md:tw-max-w-48">
                                        {{ $fullName(invoice.kid) }}
                                    </div>
                                </MTooltip>
                            </router-link>
                        </div>
                        <InvoiceNumberTitle :invoice="invoice"/>
                    </div>
                </template>
            </div>

            <!-- PRICE & ICONS -->
            <div
                class="tw-w-full lg:tw-self-center"
                :class="showByList ? 'lg:tw-w-1/6 tw-my-2 tw-border-b tw-border-gray-300 tw-pb-2 lg:tw-my-0 lg:tw-border-none lg:tw-pb-0' : 'xl:tw-w-3/12 tw-order-3 xl:tw-order-2 tw-mt-2 xl:tw-mt-0'"
            >
                <InvoicesListItemDetailsPopover
                    class="tw-w-full"
                    :invoice="invoice"
                    :nursery="nursery"
                />
            </div>

            <!-- TAGS -->
            <div
                v-if="showByList"
                class="lg:tw-w-1/6 lg:tw-pl-6"
            >
                <TagsSelector
                    v-if="invoiceModel"
                    :editable="$can('update', 'invoices')"
                    prevent-default
                    size="sm"
                    stop-propagation
                    :taggable-model="invoiceModel"
                    taggable-type="invoice"
                    :tags="invoiceModel.tags().value().toArray()"
                    @attached="invoiceModel.tags().value().push($event)"
                    @detached="invoiceModel.tags().mutate(v => v.filter(c => c.getKey() !== $event.getKey()))"
                />
            </div>

            <!-- BADGE & ACTIONS -->
            <div
                class="tw-flex tw-flex-col tw-ml-auto tw-self-center tw-text-right tw-justify-end"
                :class="showByList ? 'lg:tw-w-1/6' : 'xl:tw-w-3/12 tw-order-2 xl:tw-order-3'"
            >
                <div
                    class="tw-w-full tw-justify-end"
                    :class="[showByList ? 'tw-hidden lg:tw-flex tw-mt-1' : 'tw-flex']"
                >
                    <InvoiceStatusBadge :invoice="invoice"/>
                </div>

                <!-- ACTIONS -->
                <div class="tw-mt-2">
                    <template v-if="$can('update', 'invoices')">
                        <MTooltip
                            v-if="invoice.sent_at"
                            :label="isDraft ? __('billing_invoice:draft_send_on') + ' ' + moment(invoice.sent_at).format('DD/MM/YYYY') : __('billing_invoice:invoice_send_on') + ' ' + moment(invoice.sent_at).format('DD/MM/YYYY')"
                            placement="top"
                        >
                            <MButton
                                class="tw-inline-flex tw-items-center tw-py-1"
                                :loading="invoice.sending"
                                variant="ghost"
                                @click.stop="send"
                            >
                                <FontAwesomeIcon
                                    v-if="invoice.sending"
                                    class="tw-text-lg tw-text-cyan-500"
                                    fixed-width
                                    icon="fa fa-circle-notch fa-spin"
                                />
                                <FontAwesomeIcon
                                    v-else
                                    class="tw-text-lg tw-text-cyan-700"
                                    fixed-width
                                    icon="fad fa-envelope-open"
                                />
                            </MButton>
                        </MTooltip>
                        <MTooltip
                            v-else-if="!isDraft"
                            :label="__('billing_invoice:send_invoice')"
                            placement="top"
                        >
                            <MButton
                                class="tw-inline-flex tw-items-center tw-py-1"
                                :loading="invoice.sending"
                                variant="ghost"
                                @click.stop="send"
                            >
                                <FontAwesomeIcon
                                    v-if="invoice.sending"
                                    class="tw-text-lg tw-text-cyan-500"
                                    fixed-width
                                    icon="fa fa-circle-notch fa-spin"
                                />
                                <FontAwesomeIcon
                                    v-else
                                    class="tw-text-lg tw-text-cyan-500"
                                    fixed-width
                                    icon="fad fa-paper-plane"
                                />
                            </MButton>
                        </MTooltip>
                    </template>

                    <template v-if="invoice.sent_at && $can('update', 'invoices') && !isDraft">
                        <div
                            v-if="invoice.dunning_dates && invoice.dunning_dates.length"
                            class="tw-inline-flex tw-items-center"
                        >
                            <MTooltip placement="top">
                                <div class="tw-relative">
                                    <MButton
                                        :disabled="invoice.sendingReminder"
                                        variant="ghost"
                                        @click.stop="sendReminder"
                                    >
                                        <FontAwesomeIcon
                                            v-if="invoice.sendingReminder"
                                            class="tw-text-lg tw-text-gray-600"
                                            fixed-width
                                            icon="fad fa-alarm-exclamation"
                                        />
                                        <FontAwesomeIcon
                                            v-else
                                            class="tw-text-lg tw-text-orange-500"
                                            icon="fad fa-alarm-exclamation"
                                        />
                                    </MButton>
                                    <div
                                        v-if="invoice.dunning_dates.length > 1"
                                        class="tw-absolute tw--top-1 tw--right-1"
                                    >
                                        <MNotificationBadge :count="invoice.dunning_dates.length"/>
                                    </div>
                                </div>
                                <template #content>
                                    <div v-html="getDunningText(invoice)"/>
                                </template>
                            </MTooltip>
                        </div>
                        <MTooltip
                            v-else
                            :label="__('billing_invoice:raise_invoice')"
                            placement="top"
                        >
                            <MButton
                                class="tw-inline-flex tw-items-center tw-py-1"
                                :disabled="invoice.sendingReminder"
                                variant="ghost"
                                @click.stop="sendReminder"
                            >
                                <FontAwesomeIcon
                                    v-if="invoice.sendingReminder"
                                    class="tw-text-lg tw-text-gray-600"
                                    fixed-width
                                    icon="fad fa-alarm-exclamation"
                                />
                                <FontAwesomeIcon
                                    v-else
                                    class="tw-text-lg tw-text-gray-600"
                                    fixed-width
                                    icon="fad fa-alarm-exclamation"
                                />
                            </MButton>
                        </MTooltip>
                    </template>

                    <div
                        v-if="shouldShowPayments"
                        class="tw-relative tw-inline-flex tw-items-center tw-self-center"
                        :class="{
                            'tw--ml-1': showByList,
                            'tw-border-l tw-border-gray-300 tw-h-6 tw-ml-1': !showByList && shouldPrintDivider
                        }"
                        @click.stop
                    >
                        <MTooltip
                            v-if="invoice.computed.remaining_amount > 0"
                            :label="$can('create', 'transactions')
                                ? __('billing_invoice:outstanding_balance_amount_colon', {amount: useFormatNumber(invoice.currency_iso_code).format(invoice.computed.remaining_amount)})
                                : __('common:error.forbidden')
                            "
                            placement="top"
                        >
                            <MButton
                                class="tw-ml-1 tw-py-1"
                                :disabled="!$can('create', 'transactions')"
                                variant="ghost"
                                @click="showCreateTransactionModal"
                            >
                                <FontAwesomeIcon
                                    class="tw-text-lg"
                                    icon="fad fa-money-check-edit-alt"
                                />
                            </MButton>
                        </MTooltip>
                        <MButton
                            v-else
                            class="tw-ml-1 tw-py-1"
                            variant="ghost"
                            @click="$emit('showInvoice', true)"
                        >
                            <FontAwesomeIcon
                                class="tw-text-lg"
                                icon="fad fa-money-check-edit-alt"
                            />
                        </MButton>
                    </div>

                    <!-- OTHER ACTIONS ... -->
                    <div
                        v-if="showByList"
                        class="tw-inline-block"
                    >
                        <CDropdownMenu>
                            <template #button>
                                <CButton
                                    icon-ellipsis
                                    variant="ghost"
                                />
                            </template>
                            <a
                                class="tw-w-full"
                                :href="invoice.download_url"
                            >
                                <CDropdownMenuItem
                                    class="tw-w-full"
                                    icon="fa fa-download"
                                >
                                    {{ __('common:actions.download') }}
                                </CDropdownMenuItem>
                            </a>
                            <CDropdownMenuItem
                                v-if="$can('create', 'invoices') && $can('update', 'invoices')"
                                icon="fad fa-copy"
                                @click="duplicateInvoice"
                            >
                                {{ __('common:actions.duplicate') }}
                            </CDropdownMenuItem>
                            <CDropdownMenuItem
                                v-if="$can('delete', 'invoices') && isDraft"
                                icon="fa fa-trash"
                                variant="danger"
                                @click="deleteInvoice"
                            >
                                {{ __('common:actions.delete_dots') }}
                            </CDropdownMenuItem>
                        </CDropdownMenu>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';
    import moment from 'moment';
    import route from '@/modules/legacy/libs/ziggy';
    import useApi from '@/modules/app/composables/useApi';
    import _head from 'lodash-es/head';
    import _forEach from 'lodash-es/forEach';
    import _cloneDeep from 'lodash-es/cloneDeep';
    import {invoice} from '@/modules/legacy/mixins/invoice';
    import __ from '@/modules/app/utils/i18n-facade';
    import InvoiceTypeValue from '@/modules/cashier/utils/billing/invoice/InvoiceTypeValue';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';
    import useAbility from '@/modules/app/composables/useAbility';
    import useMagicModal from '@/modules/app/composables/useMagicModal';
    import useModal from '@/modules/app/composables/useModal';
    import useFormatNumber from '@/modules/cashier/composables/useFormatNumber';
    import MNotificationBadge from '@/modules/meeko-ui/components/MNotificationBadge.vue';
    import LegacyKidAvatar from '@/modules/app/components/atoms/avatars/LegacyKidAvatar.vue';
    import InvoicesListItemDetailsPopover
        from '@/modules/cashier/components/billing/invoice/molecules/InvoicesListItemDetailsPopover.vue';
    import TagsSelector from '@/modules/tag/components/TagsSelector.vue';
    import TransactionModalCreate
        from '@/modules/cashier/transaction/infrastructure/components/TransactionModalCreate.vue';
    import InvoiceStatusBadge from '@/modules/cashier/components/billing/invoice/atoms/InvoiceStatusBadge.vue';
    import InvoiceNumberTitle from '@/modules/cashier/components/billing/invoice/atoms/InvoiceNumberTitle.vue';
    import InvoiceIcon from '@/modules/cashier/components/billing/invoice/atoms/InvoiceIcon.vue';

    export default {
        components: {
            InvoiceIcon,
            InvoiceNumberTitle,
            InvoiceStatusBadge,
            TagsSelector,
            LegacyKidAvatar,
            InvoicesListItemDetailsPopover,
            MNotificationBadge,
        },
        mixins: [invoice],
        props: {
            invoice: {
                type: Object,
                required: true,
            },
            invoiceModel: {
                type: Object,
                default: undefined,
                required: false,
            },
            customer: {
                type: Object,
                required: true,
            },
            nursery: {
                type: Object,
                required: true,
            },
            showByList: {
                type: Boolean,
                default: false,
            },
            inverseDateKid: {
                type: Boolean,
                default: false,
            },
        },
        emits: ['showInvoice'],
        data: () => ({
            allows: useAbility().allows,
            payment: null,
        }),
        computed: {
            moment() {
                return moment;
            },
            isDraft() {
                return this.invoice.status === 'draft';
            },
            isCreditNote() {
                return this.invoice.invoice_type === InvoiceTypeValue.creditNote;
            },
            /*
             * Should show payments popover when user has permissions and the invoice is not a draft or is not a credit_note (so an invoice)
             */
            shouldShowPayments() {
                return useAbility().can('read', 'transactions') && (!this.isDraft || this.invoice.invoice_type === 'invoice');
            },
            /*
             * Should print a divider between the <InvoicesTransactionsPopover /> and send/remind bill buttons.
             * This computed return true when user has invoices update permissions and the invoice can or has been sent.
             */
            shouldPrintDivider() {
                return useAbility().can('update', 'invoices') && (!this.isDraft || this.invoice.sent_at || this.invoice.sending);
            },
        },

        async beforeMount() {
            if (this.invoice.invoice_type === InvoiceTypeValue.invoice) {
                this.payment = this.invoiceModel.payments().value().first();
            } else {
                this.payment = this.invoiceModel.refunds().value().first(payment => payment.attributes.refund);
            }
        },
        methods: {
            useFormatNumber,

            showCreateTransactionModal() {
                return useModal({
                    component: TransactionModalCreate,
                    props: {
                        canSelectCustomer: false,
                        tenantId: this.invoice.tenant_id,
                        payment: this.payment,
                        invoice: this.invoice,
                        customer: this.customer,
                    },
                    listeners: modal => ({
                        created: () => {
                            this.onRefreshInvoice();
                            modal.hide();
                        },
                    }),
                }).show();
            },

            send() {
                Vue.set(this.invoice, 'sending', true);
                useApi().legacy.post(
                    route('nurseries.invoices.sendbymail', {
                        nurseries: this.nursery.id,
                    }),
                    {
                        invoices: [this.invoice.id],
                    },
                )
                    .then(() => {
                        Vue.set(this.invoice, 'sending', false);
                        this.invoice.sent_at = moment().format();
                    })
                    .catch(error => {
                        Vue.set(this.invoice, 'sending', false);
                        if (error?.response && error.response.status === 422) {
                            _forEach(error.response.data.errors, value => {
                                useNotification().error(_head(value));
                            });
                        } else {
                            useNotification().error(error);
                        }
                    });
            },

            sendReminder() {
                Vue.set(this.invoice, 'sendingReminder', true);
                useApi().legacy.post(
                    route('nurseries.invoices.sendreminderbymail', {
                        nurseries: this.nursery.id,
                    }),
                    {
                        invoices: [this.invoice.id],
                    },
                )
                    .then(() => {
                        Vue.set(this.invoice, 'sendingReminder', false);
                        this.invoice.dunning_dates.push(moment().format());
                    })
                    .catch(error => {
                        Vue.set(this.invoice, 'sendingReminder', false);
                        if (error?.response && error.response.status === 422) {
                            _forEach(error.response.data.errors, value => {
                                useNotification().error(_head(value));
                            });
                        } else {
                            useNotification().error(error);
                        }
                    });
            },
            onRefreshInvoice() {
                useApi().legacy.get(
                    route('nurseries.invoices.show', {
                        nurseries: this.nursery.id,
                        invoice: this.invoice.id,
                        with: ['lines', 'customer', 'tags', 'kid'],
                    }),
                )
                    .then(() => {
                        this.$emit('updateInvoices');
                    })
                    .catch(error => {
                        if (error?.response && error.response.status === 422) {
                            _forEach(error.response.data.errors, value => {
                                useNotification().error(_head(value));
                            });
                        } else {
                            useNotification().error(error);
                            throw error;
                        }
                    });
            },

            async deleteInvoice() {
                await useMagicModal().deleteConfirmationModal({
                    text: this.isCreditNote ? __('billing_invoice:are_you_sure_you_want_to_delete_this_credit_note_ask') : __('billing_invoice:are_you_sure_you_want_to_delete_this_invoice_ask'),
                    title: this.isCreditNote ? __('billing_invoice:delete_the_credit_note') : __('billing_invoice:delete_the_invoice'),
                    onConfirm: async () => {
                        this.$emit('deleteInvoice', this.invoice);
                    },
                });
            },

            duplicateInvoice() {
                const duplicatedInvoice = _cloneDeep(this.invoice);

                const newFromDate = moment(duplicatedInvoice.start_period_date)
                    .add(1, 'month')
                    .format('YYYY-MM-DD');
                const newToDate = moment(duplicatedInvoice.end_period_date)
                    .add(1, 'month')
                    .format('YYYY-MM-DD');

                duplicatedInvoice.id = null;
                duplicatedInvoice.increment = null;
                duplicatedInvoice.status = 'draft';
                duplicatedInvoice.date = moment().format('YYYY-MM-DD');
                duplicatedInvoice.due_date = moment()
                    .add(1, 'month')
                    .format('YYYY-MM-DD');

                duplicatedInvoice.start_period_date = duplicatedInvoice.type === 'custom' ? undefined : newFromDate;
                duplicatedInvoice.end_period_date = duplicatedInvoice.type === 'custom' ? undefined : newToDate;

                this.$emit('duplicateInvoice', duplicatedInvoice);
            },

            getDunningText(invoice) {
                let txt = __('billing_invoice:dunning_history_colon');
                invoice.dunning_dates.forEach(dunningDate => {
                    txt +=
                        '<div> ' +
                        __('common:the') +
                        ' ' +
                        moment(dunningDate).format('DD/MM/YYYY') +
                        '</div>';
                });

                return txt;
            },
        },
    };
</script>
