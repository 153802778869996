import useModalManager from '@/modules/app/composables/useModalManager';
import useModal from '@/modules/app/composables/useModal';
import {until} from '@vueuse/core';
import {ref} from 'vue';
import ConfirmationModal from '@/modules/app/components/organisms/ConfirmationModal.vue';
import __ from '@/modules/app/utils/i18n-facade';

export default function useMagicModal() {
    const {fire} = useModalManager();

    async function confirmationModal(option: {
        title?: string,
        text?: string,
        confirmButtonText?: string,
        dismissButtonText?: string,
        confirmButtonVariant?: string,
        discardButtonVariant?: string,
        type?: string,
        onConfirm?: () => Promise<void>,
        onDismiss?: () => Promise<void>,
    }): Promise<{
            isConfirmed: boolean,
            isDismissed: boolean,
        }> {
        const result = ref();

        const modal = useModal({
            component: ConfirmationModal,
            props: {
                type: 'question',
                wideButtons: true,
                ...option,
            },
            listeners() {
                return {
                    confirmed() {
                        result.value = true;
                    },
                    dismissed() {
                        result.value = null;
                    },
                };
            },
        });

        fire(modal);

        await until(result).toMatch(v => v !== undefined);

        if (result.value && option.onConfirm) {
            await option.onConfirm();
        }

        if (!result.value && option.onDismiss) {
            await option.onDismiss();
        }

        modal.hide();

        return {
            isConfirmed: result.value === true,
            isDismissed: result.value === null,
        };
    }

    async function deleteConfirmationModal(option: {
        title?: string,
        text?: string,
        confirmButtonText?: string,
        dismissButtonText?: string,
        onConfirm?: () => Promise<void>,
        onDismiss?: () => Promise<void>,
    }) {
        return confirmationModal({
            confirmButtonText: __('common:actions.delete'),
            confirmButtonVariant: 'danger',
            type: 'danger',
            ...option,
        });
    }

    return {
        confirmationModal,
        deleteConfirmationModal,
    };
}
