import {Model} from '@meekohq/lumos';

export default class CallPivot extends Model {

    public type = 'activity/calls_pivot';

    public attributes: {
        id: string,
        account_id: string | undefined,
        call_id: string | undefined,
        resource_id: string | undefined,
        resource_type: string | undefined,

    } = {
            id: this.uuid(),
            account_id: undefined,
            call_id: undefined,
            resource_id: undefined,
            resource_type: undefined,
        };
}
