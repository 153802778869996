import TransactionModel from '@/modules/cashier/transaction/domain/TransactionModel';
import type {QueryBuilder} from '@meekohq/lumos';
import {isNil} from 'lodash-es';
import TransactionTypeValue from '@/modules/cashier/transaction/domain/TransactionTypeValue';
import type {TransactionFilterDto} from '@/modules/cashier/transaction/application/dto/TransactionFilterDto';

export class TransformFiltersToQueryBuilderFactory {
    static transform(options: TransactionFilterDto): QueryBuilder<TransactionModel> {
        const query = TransactionModel.query();

        if (options.transactionIds && options.transactionIds.length > 0) {
            query.whereIn('id', options.transactionIds);
        }

        if (options.period?.from) {
            // Constrain the query to transactions that have a date greater than or equal to the "from" date
            query.whereDateTime('date', '>=', options.period.from);
        }

        if (options.period?.to) {
            // Constrain the query to transactions that have a date less than or equal to the "to" date
            query.whereDateTime('date', '<=', options.period.to);
        }

        // If the search value is set, we don't apply any other filters. This way, the user
        // can see all transactions that match the search value without having to clear the other filters.
        if (options.search) {
            // Constrain the query to transactions that have a reference that contains the search value
            query.where(query1 => {
                query1.orWhere('reference', 'like', `%${options.search}%`)
                    .orWhere('description', 'like', `%${options.search}%`);

                const searchNumber = Number(options.search?.replace(',', '.'));
                if (!isNaN(searchNumber)) {
                    query1.orWhere('amount', '=', searchNumber);
                }
            });
        } else {
            // We use isNil here because we want to include 0 values but not null and undefined values
            if (!isNil(options.amountMin)) {
                // If the amountMin value is lower than 0
                if ((options.amountMin as number) < 0) {
                    query.where(query1 => {
                        // We include negative transactions that are greater or equal to the amountMin value
                        query1.where(query2 => {
                            query2.where('amount', '<=', Math.abs(options.amountMin as number));
                            query2.where('type', TransactionTypeValue.debit);
                        });

                        // We include positive transactions that are greater or equal to 0
                        // only if the amountMax value is not set or is greater than 0
                        if (isNil(options.amountMax) || (options.amountMax && options.amountMax >= 0)) {
                            query1.orWhere(query2 => {
                                query2.where('amount', '>=', 0);
                                query2.where('type', TransactionTypeValue.credit);
                            });
                        }
                    });
                } else {
                    // If the amountMin value is greater or equal to 0,
                    // we only include positive transactions that are greater or equal to the amountMin value
                    query.where(query2 => {
                        query2.where('amount', '>=', Math.abs(options.amountMin as number));
                        query2.where('type', TransactionTypeValue.credit);
                    });
                }
            }

            // We use isNil here because we want to include 0 values but not null and undefined values
            if (!isNil(options.amountMax)) {
                // If the amountMax value is greater or equal to 0
                if ((options.amountMax as number) >= 0) {
                    query.where(query1 => {
                        // We include positive transactions that are less or equal to the amountMax value
                        query1.where(query2 => {
                            query2.where('amount', '<=', options.amountMax);
                            query2.where('type', TransactionTypeValue.credit);
                        });

                        // We include negative transactions that are greater or equal to 0
                        // only if the amountMin value is not set or is lower than 0
                        if (isNil(options.amountMin) || (options.amountMin && options.amountMin <= 0)) {
                            query1.orWhere(query2 => {
                                query2.where('amount', '>=', 0);
                                query2.where('type', TransactionTypeValue.debit);
                            });
                        }
                    });
                } else {
                    // If the amountMax value is lower than 0,
                    // we only include negative transactions that are greater or equal to the amountMax value
                    query.where(query1 => {
                        query1.where('amount', '>=', Math.abs(options.amountMax as number));
                        query1.where('type', TransactionTypeValue.debit);
                    });
                }
            }

            if (options.tenants?.length) {
                // if the Tenant filter has more than one tenant selected, we use the whereIn method to constrain the query.
                // Otherwise, we use the where method to constrain the query for performance.
                if (options.tenants.length > 1) {
                    query.whereIn('tenant_id', options.tenants.map(tenant => tenant.getKey()));
                } else {
                    query.where('tenant_id', options.tenants[0].getKey());
                }
            }

            const invoiceIds = options.invoiceIds;
            if (invoiceIds && invoiceIds.length > 0) {
                query.where(function(query1) {
                    // We scope the query using two whereHas statements. These relations are HasManyThrough in the backend
                    // going through the allocations to match the legacy invoices.
                    query1.whereHas('payments', query2 => {
                        query2.whereHas('invoices', query3 => {
                            if (invoiceIds.length > 1) {
                                query3.whereIn('id', invoiceIds);
                            } else {
                                query3.where('id', invoiceIds[0]);
                            }
                        });
                    });

                    query1.orWhereHas('refunds', query2 => {
                        query2.whereHas('creditNotes', query3 => {
                            if (invoiceIds.length > 1) {
                                query3.whereIn('id', invoiceIds);
                            } else {
                                query3.where('id', invoiceIds[0]);
                            }
                        });
                    });
                });
            }

            if (options.customers && options.customers.length > 0) {
                // if the Tenant filter has more than one tenant selected, we use the whereIn method to constrain the query.
                // Otherwise, we use the where method to constrain the query for performance.
                if (options.customers.length > 1) {
                    query.whereIn('customer_id', options.customers.map(customer => {
                        return customer.getKey();
                    }));
                } else {
                    query.where('customer_id', options.customers[0].getKey());
                }
            }

            if (options.paymentMethodName) {
                // Constrain the query to transactions that have related payment methods that match the payment method filter
                query.whereHas('paymentMethod', query => {
                    query.where('name', options.paymentMethodName);
                });
            }
        }

        if (options.hasRemainingAmount) {
            query.where('remaining_to_distribute_amount', '>', 0);
        }

        if (options.orderBy) {
            query.orderBy(options.orderBy as string, options.queryDirection);
        }

        return query;
    }
}
