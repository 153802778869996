<template>
    <CButtonGroup>
        <CButton
            :disabled="disabled"
            @click="ruleDropdown.toggle()"
        >
            {{ ruleDetails }}
        </CButton>
        <CDropdown
            ref="ruleDropdown"
            legacy-mode
            @clickOutside="resetChanges"
        >
            <div class="tw-min-w-64 tw-max-w-76">
                <CHStack>
                    <template v-if="ruleCopy.options && ruleCopy.options.length">
                        <CInput
                            v-model="interval"
                            class="tw-w-16 tw-mr-2"
                            min="1"
                            type="number"
                        />
                    </template>
                    <Config
                        v-model="ruleCopy"
                        :configs="configs"
                    />
                </CHStack>
                <CHStack
                    class="tw-mt-8"
                    distribute="end"
                >
                    <CButton @click="cancel">
                        {{ __('common:actions.cancel') }}
                    </CButton>
                    <CButton
                        class="tw-ml-2"
                        variant="primary"
                        @click="validate"
                    >
                        {{ __('common:actions.validate') }}
                    </CButton>
                </CHStack>
            </div>
        </CDropdown>
        <CButton
            :disabled="disabled"
            @click="remove"
        >
            <i
                aria-hidden="true"
                class="fas fa-trash"
            />
        </CButton>
    </CButtonGroup>
</template>


<script lang="ts">
    import type {PropType} from 'vue';
    import {computed, defineComponent, ref, watch} from 'vue';
    import type {ConfigsType} from '@/modules/calendar/components/Reminder/Config.vue';
    import Config from '@/modules/calendar/components/Reminder/Config.vue';
    import DirectionValue from '@/modules/calendar/utils/RuleSet/values/DirectionValue';
    import FrequencyValue from '@/modules/calendar/utils/RuleSet/values/FrequencyValue';
    import OptionValue from '@/modules/calendar/utils/RuleSet/values/OptionValue';
    import Rule from '@/modules/calendar/utils/RuleSet/Rule';
    import useRuleSetHumanDetails from '@/modules/calendar/composables/RuleSet/useRuleSetHumanDetails';
    import __ from '@/modules/app/utils/i18n-facade';

    export default defineComponent({
        components: {Config},
        props: {
            rule: {
                type: Object as PropType<Rule>,
                default: undefined,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
        },
        setup(props, {emit}) {
            const ruleCopy = ref<Rule>(props.rule ? Rule.fromJSON(props.rule) : new Rule());

            const ruleDropdown = ref();

            const interval = ref(props.rule?.interval ? Math.abs(props.rule?.interval) : 1);
            const direction = computed<DirectionValue>(() => ruleCopy.value?.interval
                ? (ruleCopy.value.interval > 0 ? DirectionValue.futur : DirectionValue.past)
                : DirectionValue.futur,
            );

            watch(interval, value => {
                if (ruleCopy.value?.interval && ruleCopy.value.interval < 0) {
                    ruleCopy.value.interval = -Math.abs(value);
                } else {
                    ruleCopy.value.interval = Math.abs(value);
                }
            });

            const configs: ConfigsType = [
                {
                    value: {interval: 1, freq: FrequencyValue.once},
                    text: __('common:at_end'),
                },
                {
                    value: {
                        freq: FrequencyValue.minute,
                        direction: DirectionValue.past,
                        options: [OptionValue.excludeStartDate],
                    },
                    text: __('calendar:minute_before'),
                },
                {
                    value: {
                        freq: FrequencyValue.hour,
                        direction: DirectionValue.past,
                        options: [OptionValue.excludeStartDate],
                    },
                    text: __('calendar:hour_before'),
                },
                {
                    value: {
                        freq: FrequencyValue.day,
                        direction: DirectionValue.past,
                        options: [OptionValue.excludeStartDate],
                    },
                    text: __('calendar:day_before'),
                },
                {
                    value: {
                        freq: FrequencyValue.minute,
                        direction: DirectionValue.futur,
                        options: [OptionValue.excludeStartDate],
                    },
                    text: __('calendar:minute_after'),
                },
                {
                    value: {
                        freq: FrequencyValue.hour,
                        direction: DirectionValue.futur,
                        options: [OptionValue.excludeStartDate],
                    },
                    text: __('calendar:hour_after'),
                },
                {
                    value: {
                        freq: FrequencyValue.day,
                        direction: DirectionValue.futur,
                        options: [OptionValue.excludeStartDate],
                    },
                    text: __('calendar:day_after'),
                },
            ];

            const {getReminderRuleDetails} = useRuleSetHumanDetails();

            // Translation rule details
            const ruleDetails = computed(() => {
                return getReminderRuleDetails(ruleCopy.value, interval.value, direction.value);
            });

            function resetChanges() {
                ruleCopy.value = Rule.fromJSON(props.rule);

                interval.value = props.rule?.interval ? Math.abs(props.rule?.interval) : 1;
            }

            // Validate rule changes
            function validate() {
                emit('validate', ruleCopy.value);
                ruleDropdown.value.hide();
            }

            // Cancel rule change
            function cancel() {
                resetChanges();
                emit('cancel', props.rule);
                ruleDropdown.value.hide();
            }

            // Remove rule
            function remove() {
                emit('remove', props.rule);
                ruleDropdown.value.hide();
            }

            return {
                cancel,
                configs,
                direction,
                interval,
                remove,
                resetChanges,
                ruleCopy,
                ruleDetails,
                ruleDropdown,
                validate,
            };
        },
    });
</script>
