<template>
    <loader
        v-if="loading"
        light="true"
        shadow="false"
        size="sm"
        :title="__('common:loading_dots')"
    />
    <div
        v-else
        class="lg:tw-grid lg:tw-grid-cols-1 lg:tw-grid-flow-row lg:tw-gap-3"
    >
        <div class="tw-col-span-1 tw-mb-3 lg:tw-mb-0 tw-p-3">
            <StaffAllocationsStickyHeader
                v-if="staffs.length > 0"
                :balance-types="balanceTypes"
            />
            <StaffAllocationsListItem
                v-for="staff in staffs"
                :key="'staff-' + staff.getKey()"
                :balance-types="balanceTypes"
                :staff="staff"
                @showEvent="openEventModal($event)"
            />
            <CPagination
                v-if="paginator"
                class="tw-text-center tw-mt-2"
                :paginator="paginator"
                @navigate="$page => getStaff($page)"
            />
        </div>
        <EventModal
            v-if="selectedEvent"
            ref="eventModal"
            :event="selectedEvent"
            @delete="removeEvent()"
            @hidden="selectedEvent = null"
        />
    </div>
</template>

<script lang="ts">
    import type {PropType} from 'vue';
    import {computed, defineComponent, nextTick, onMounted, ref, watch} from 'vue';
    import StaffAllocationsListItem
        from '@/modules/human-resources/components/balance/List/StaffAllocationsListItem.vue';
    import StaffAllocationsStickyHeader
        from '@/modules/human-resources/components/balance/List/StaffAllocationsStickyHeader.vue';
    import EventModal from '@/modules/human-resources/components/calendar/EventModal.vue';
    import StaffModel from '@/modules/human-resources/models/StaffModel';
    import BalanceTypeModel from '@/modules/human-resources/models/BalanceTypeModel';
    import usePaginator from '@/modules/legacy/utils/usePaginator';
    import type {Collection} from '@meekohq/lumos';
    import {collect} from '@meekohq/lumos';
    import moment from 'moment';
    import _throttle from 'lodash-es/throttle';
    import EventModel from '@/modules/human-resources/models/EventModel';
    import ContractModel from '@/modules/human-resources/models/ContractModel';
    import BalanceAllocationModel from '@/modules/human-resources/models/BalanceAllocationModel';
    import type TeamModel from '@/modules/human-resources/models/TeamModel';
    import type OrganizationModel from '@/modules/organization/models/OrganizationModel';

    export default defineComponent({
        name: 'StaffAllocationsList',
        components: {StaffAllocationsStickyHeader, StaffAllocationsListItem, EventModal},
        props: {
            periodFilter: {type: Object as PropType<{ from: string; to: string }>, required: true},
            organizationsFilter: {type: Array as PropType<OrganizationModel[]>, required: true},
            balanceTypesFilter: {type: Array as PropType<string[]>, required: true},
            teamsFilter: {type: Array as PropType<TeamModel[]>, required: true},
        },
        setup(props) {
            const loading = ref(false);
            const staffs = ref<StaffModel[]>([]);
            const balanceTypes = ref<Collection<BalanceTypeModel>>(collect());
            const organizationIds = computed(() => collect(props.organizationsFilter).pluck('id').all() as string[]);
            const teamIds = computed(() => collect(props.teamsFilter).pluck('id').all() as string[]);

            async function getBalanceTypes() {
                const query = BalanceTypeModel.query();

                if (props.balanceTypesFilter?.length) {
                    query.whereIn('id', props.balanceTypesFilter);
                }

                query.whereHas('organizations', query1 =>
                    query1.scope('active').whereIn('id', organizationIds.value),
                ).orderBy('name');

                balanceTypes.value = await query.get();
            }

            const {paginator, currentPage, setPaginator} = usePaginator(
                getStaff,
                true,
            );

            async function getStaff(page: number = currentPage.value || 1) {
                loading.value = true;

                const staffsQuery = StaffModel.query()
                    .with(new StaffModel().balanceAllocations(), query1 => {
                        // Filter by selected organizations and period
                        if (props.periodFilter.from && props.periodFilter.to) {
                            // Get only sources which response of filter criterias
                            query1.where(query2 => {
                                // If event source, filter by period the date_event and datetime_event started_at and by organization relation
                                query2.whereHas('event', query3 => {
                                    query3.where('status', 'validated');
                                    query3.where(query4 => {
                                        query4.whereBetween('started_at', [props.periodFilter.from, props.periodFilter.to])
                                            .orWhereBetween('started_at_date', [props.periodFilter.from, props.periodFilter.to]);
                                    }).whereIn('nursery_id', organizationIds.value);
                                });

                                // If contract source, filter by period the started_at and by organizations relation
                                query2.orWhereHas('contract', query3 => {

                                    query3.whereBetween('started_at', [props.periodFilter.from, props.periodFilter.to]);
                                    query3.whereHas('organizations', query4 => {
                                        query4.whereIn('organization_id', organizationIds.value);
                                    });
                                });
                            });
                        }

                        // Filter by selected balance types
                        query1.whereHas('balanceType', query2 => {
                            if (props.balanceTypesFilter?.length) {
                                query2.whereIn('id', props.balanceTypesFilter);
                            }
                            query2.whereHas('organizations', query3 => {
                                query3.whereIn('id', organizationIds.value);
                            });
                        });

                        query1.with(new BalanceAllocationModel().balanceType())
                            .with(new BalanceAllocationModel().source(), (query2, relation) => {
                                relation.morphWith(EventModel, 'organization');
                                relation.morphWith(ContractModel, 'organizations');
                            });
                    })
                    .with(new StaffModel().balanceAllocations(), query1 => {
                        // Filter by selected balance types
                        query1.where(query2 => {
                            // If event source, filter by period the date_event and datetime_event started_at and by organization relation
                            BalanceAllocationModel.restrictToConfirmedScope(query2, organizationIds.value);
                        });
                        query1.whereHas('balanceType', query2 => {
                            if (props.balanceTypesFilter?.length) {
                                query2.whereIn('id', props.balanceTypesFilter);
                            }
                            query2.whereHas('organizations', query3 => {
                                query3.whereIn('id', organizationIds.value);
                            });
                        });
                    }, 'allBalanceAllocations')
                    .whereHas('organizations', query =>
                        query.scope('active').whereIn('id', organizationIds.value),
                    );

                if (teamIds.value?.length) {
                    staffsQuery.whereHas(new StaffModel().teams(), query => {
                        query.whereIn('id', teamIds.value);
                    });
                }

                return staffsQuery.paginate(20, page)
                    .then(response => {
                        currentPage.value = page;
                        setPaginator({
                            current_page: response.currentPage(),
                            last_page: response.lastPage(),
                        });

                        staffs.value = response.items().all();
                        loading.value = false;
                    })
                    .finally(() => {
                        loading.value = false;
                    });
            }

            function fetchData() {
                getBalanceTypes();
                getStaff(1);
            }

            const throttledFetch = _throttle(() => fetchData(), 1500, {
                trailing: true, leading: false,
            });

            onMounted(() => {
                throttledFetch();
            });

            watch(props, () => {
                throttledFetch();

            });

            const selectedEvent = ref<EventModel>();
            const eventModal = ref<null | { modal: { show: () => null; hide: () => null } }>(null);

            function openEventModal(event: EventModel) {
                selectedEvent.value = event;
                nextTick(() => {
                    eventModal.value?.modal.show();
                });
            }

            function removeEvent() {
                staffs.value = staffs.value.map(staff => {
                    if (staff.id === selectedEvent.value?.attributes.staff_id) {
                        staff.balanceAllocations()
                            .mutate(value =>
                                value.reject(item =>
                                    item.attributes.source_id === selectedEvent.value?.id
                                    && item.attributes.source_type === new EventModel().type,
                                ),
                            );

                        staff.balanceAllocations()
                            .setContainer('allBalanceAllocations')
                            .mutate(value =>
                                value.reject(item =>
                                    item.attributes.source_id === selectedEvent.value?.id
                                    && item.attributes.source_type === new EventModel().type,
                                ),
                            );

                        return staff;
                    }

                    return staff;
                });
            }

            return {
                balanceTypes,
                eventModal,
                getStaff,
                loading,
                moment,
                openEventModal,
                paginator,
                removeEvent,
                selectedEvent,
                staffs,
            };
        },
    });
</script>
