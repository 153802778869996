import {ServiceProvider} from '@meekohq/lumos';
import TagModel from '@/modules/tag/models/TagModel';
import TagsOrganizationsPivot from '@/modules/tag/models/TagsOrganizationsPivot';
import TagPivot from '@/modules/tag/models/TagPivot';

export default class TagServiceProvider extends ServiceProvider {
    public boot() {
        this.loadModelsFrom([
            TagModel,
            TagsOrganizationsPivot,
            TagPivot,
        ]);
    }
}
