import {Model} from '@meekohq/lumos';
import OrganizationModel from '@/modules/organization/models/OrganizationModel';
import OrganizationTenantPivot from '@/modules/organization/models/OrganizationTenantPivot';


export default class TenantModel extends Model {
    public type = 'cashier/tenants';

    public attributes: {
        id: string;
        name: string | undefined;
    } = {
            id: this.uuid(),
            name: undefined,
        };

    organizations() {
        return this.belongsToMany('organizations', OrganizationModel, OrganizationTenantPivot, 'tenant_id', 'organization_id');
    }
}
