<template>
    <div>
        <CButton @click="$refs.invoiceFilter.toggle()">
            {{ invoiceStatusText }}
            <i class="fa fa-caret-down tw-ml-1"/>
        </CButton>
        <CDropdown
            ref="invoiceFilter"
            legacy-mode
        >
            <CList class="tw-cursor-pointer">
                <CListRow @click="updateInvoiceStatus(null)">
                    <i
                        v-if="
                            !value ||
                                typeof value === 'undefined' ||
                                value.length === 0
                        "
                        class="fa fa-check tw-mr-1"
                    />
                    {{ __('billing_invoice:all_invoices') }}
                </CListRow>
                <template v-if="value && Array.isArray(value)">
                    <CListRow @click="updateInvoiceStatus('draft')">
                        <i
                            v-if="value && value.includes('draft')"
                            class="fa fa-check tw-mr-1"
                        />
                        {{ __('billing_invoice:proforma_invoice_other') }}
                    </CListRow>
                    <CListRow @click="updateInvoiceStatus('pending')">
                        <i
                            v-if="value && value.includes('pending')"
                            class="fa fa-check tw-mr-1"
                        />
                        {{ __('billing_invoice:pending_invoice_other') }}
                    </CListRow>
                    <CListRow @click="updateInvoiceStatus('incomplete')">
                        <i
                            v-if="value && value.includes('incomplete')"
                            class="fa fa-check tw-mr-1"
                        />
                        {{ __('billing_invoice:incomplete_invoice_other') }}
                    </CListRow>
                    <CListRow @click="updateInvoiceStatus('paid')">
                        <i
                            v-if="value && value.includes('paid')"
                            class="fa fa-check tw-mr-1"
                        />
                        {{ __('billing_invoice:paid_invoice_other') }}
                    </CListRow>
                    <CListRow @click="updateInvoiceStatus('unpaid')">
                        <i
                            v-if="value && value.includes('unpaid')"
                            class="fa fa-check tw-mr-1"
                        />
                        {{ __('common:unpaid_invoice_other') }}
                    </CListRow>
                    <CListRow @click="updateInvoiceStatus('uncollectible')">
                        <i
                            v-if="value && value.includes('uncollectible')"
                            class="fa fa-check tw-mr-1"
                        />
                        {{ __('billing_invoice:uncollectible_invoice_other') }}
                    </CListRow>
                </template>
            </CList>
        </CDropdown>
    </div>
</template>

<script>
    import _isEqual from 'lodash-es/isEqual';
    import InvoiceStatusValue from '@/modules/cashier/utils/billing/invoice/InvoiceStatusValue';
    import __ from '@/modules/app/utils/i18n-facade';

    export default {
        props: {
            value: {},
        },
        computed: {
            invoiceStatusText() {
                let txt = __('billing_invoice:invoices_state');
                if (!this.value || this.value.length === 0) {
                    txt = __('billing_invoice:all_invoices');
                } else if (this.value.length === 1) {
                    if (this.value.includes(InvoiceStatusValue.draft)) {
                        txt = __('billing_invoice:proforma_invoice_other');
                    }

                    if (this.value.includes(InvoiceStatusValue.pending)) {
                        txt = __('billing_invoice:pending_invoice_other');
                    }

                    if (this.value.includes(InvoiceStatusValue.incomplete)) {
                        txt = __('billing_invoice:incomplete_invoice_other');
                    }

                    if (this.value.includes(InvoiceStatusValue.paid)) {
                        txt = __('billing_invoice:paid_invoice_other');
                    }

                    if (this.value.includes(InvoiceStatusValue.unpaid)) {
                        txt = __('common:unpaid_invoice_other');
                    }

                    if (this.value.includes(InvoiceStatusValue.uncollectible)) {
                        txt = __('billing_invoice:uncollectible_invoice_other');
                    }
                }

                return txt;
            },
        },
        mounted() {
            if (!Array.isArray(this.value)) {
                this.$emit('input', []);
            }
        },
        methods: {
            updateInvoiceStatus(val) {
                let states = this.value ? this.value.slice() : [];

                if (!val) {
                    states = [];
                } else if (states.includes(val)) {
                    states = states.filter(e => e !== val);
                } else {
                    states.push(val);

                    const allStates = [
                        InvoiceStatusValue.draft,
                        InvoiceStatusValue.pending,
                        InvoiceStatusValue.incomplete,
                        InvoiceStatusValue.paid,
                        InvoiceStatusValue.unpaid,
                        InvoiceStatusValue.uncollectible,
                    ];

                    if (_isEqual(
                        allStates.sort((a, b) => a.localeCompare(b)),
                        states.sort((a, b) => a.localeCompare(b)),
                    )) {
                        states = [];
                    }
                }

                this.$emit('input', states);
            },
        },
    };
</script>
