<template>
    <div
        class="CFormTwoColumns"
        :class="style"
    >
        <slot/>
    </div>
</template>

<script lang="ts">
    import {computed, defineComponent} from 'vue';

    export default defineComponent({
        components: {},
        props: {
            responsive: {type: Boolean, default: true},
        },
        setup(props) {
            const style = computed(() => {
                const output = [] as any[];

                if (props.responsive) {
                    output.push('CFormTwoColumnsResponsive');

                }

                return output;
            });

            return {
                style,
            };
        },
    });
</script>

<style lang="scss" scoped>
    .CFormTwoColumns {
        @apply tw-grid tw-grid-cols-2 tw-gap-4;

        > * {
            @apply tw-col-span-2;
        }

        &.CFormTwoColumnsResponsive > * {
            @apply sm:tw-col-span-1;
        }
    }
</style>
