import {Model} from '@meekohq/lumos';

export default class TagPivot extends Model {
    public type = 'tags_pivot';

    public attributes: {
        account_id: string | undefined;
        tag_id: string | undefined;
        resource_id: string | undefined;
        resource_type: string | undefined;
    } = {
            account_id: undefined,
            tag_id: undefined,
            resource_id: undefined,
            resource_type: undefined,
        };
}
