<template>
    <div class="tw-inline-block">
        <component
            :is="as"
            ref="downloadElement"
            v-bind="dynamicTemplateProps"
            @click.stop="saveBeforeDownloadTemplate"
        >
            <template
                v-if="as === 'CButton' && !buttonWithoutText"
                #left-icons
            >
                <FontAwesomeIcon icon="fa fa-download"/>
            </template>
            <template v-else-if="as === 'CButton' && buttonWithoutText">
                <div>
                    <FontAwesomeIcon
                        class="tw-text-blue-500"
                        icon="fa fa-download"
                    />
                </div>
            </template>
            <template
                v-else-if="as === 'MMenuItem'"
                #icon
            >
                <FontAwesomeIcon
                    v-if="downloading"
                    icon="fa fa-spin fa-circle-notch"
                />
                <FontAwesomeIcon
                    v-else
                    icon="fa fa-download"
                />
            </template>
            <template v-if="downloading">
                {{ !buttonWithoutText ? __('common:downloading_dots') : '' }}
            </template>
            <template v-else-if="loading">
                {{ !buttonWithoutText ? __('common:loading_dots') : '' }}
            </template>
            <template v-else>
                {{ !buttonWithoutText ? __('common:actions.download') : '' }}
            </template>
        </component>

        <CDropdown
            ref="docTemplateDropdown"
            legacy-mode
            placement="top"
        >
            <div class="tw-w-full">
                <template v-if="loading">
                    <CDropdownMenuItem key="template-loading">
                        <FontAwesomeIcon
                            class="tw-mr-1"
                            icon="fas fa-circle-notch fa-spin"
                        />
                        {{ __('common:loading_in_progress_dots') }}
                    </CDropdownMenuItem>
                </template>
                <template v-else>
                    <template v-if="filteredDocTemplates.length > 0">
                        <div class="tw-max-h-60 tw-overflow-y-auto">
                            <CDropdownMenuItem
                                v-for="(template, i) in filteredDocTemplates"
                                :key="'template-' + i"
                                class="tw-w-full"
                                icon="fa fa-download"
                                @click.prevent.stop="download(template)"
                            >
                                {{ template.name }}
                            </CDropdownMenuItem>
                        </div>
                    </template>
                    <template v-else>
                        <CDropdownMenuItem
                            key="template-default"
                            icon="fa fa-external-link"
                            onclick="Intercom('showArticle', 1860558)"
                            :to="{ name: 'settings' }"
                        >
                            {{ __('organization:create_a_new_document_template_dots') }}
                        </CDropdownMenuItem>
                    </template>
                </template>
            </div>
        </CDropdown>
    </div>
</template>

<script lang="ts">
    import type {PropType, Ref} from 'vue';
    import {computed, defineComponent, nextTick, ref, toRef} from 'vue';
    import moment from 'moment';
    import {MqlOperation, type ResponseObject} from '@meekohq/lumos';
    import __ from '@/modules/app/utils/i18n-facade';
    import _sortBy from 'lodash-es/sortBy';
    import _cloneDeep from 'lodash-es/cloneDeep';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';
    import {useRouter} from 'vue-router/composables';
    import type CDropdown from '@/modules/meeko-ui/components/MDropdown.vue';
    import useDownload from '@/modules/app/composables/useDownload';
    import type {LegacyKid} from '@/modules/family/composables/kid/useLegacyKidContract';
    import useLegacyKidContract from '@/modules/family/composables/kid/useLegacyKidContract';

    export default defineComponent({
        props: {
            as: {
                type: String as PropType<'CButton' | 'CDropdownMenuItem' | 'MMenuItem'>,
                default: 'CButton',
            },
            item: {
                type: Object,
                required: true,
            },
            additionalItems: {
                type: Array as PropType<Array<{ name: string, value: string | number }>>,
                default: null,
            },
            nursery: {
                type: Object,
                required: true,
            },
            docType: {
                type: String as PropType<'contract' | 'registration' | 'kid' | 'family_member' | 'staff' | 'transaction' | 'tax-certificate' | 'sepa_mandate' | 'staff_contract'>,
                required: true,
            },
            fromDate: {
                type: String,
                default: null,
            },
            toDate: {
                type: String,
                default: null,
            },
            sm: {
                type: Boolean,
                default: false,
            },
            variant: {
                type: String,
                default: 'info',
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            loading: {
                type: Boolean,
                default: false,
            },
            right: {
                type: Boolean,
                default: false,
            },
            dropup: {
                type: Boolean,
                default: false,
            },
            waitForCallback: {
                type: Boolean,
                default: false,
            },
            customClass: {
                type: String,
                default: '',
            },
            buttonWithoutText: {
                type: Boolean,
                default: false,
            },
        },
        setup(props, {emit}) {
            const {error: toastError} = useNotification();
            const router = useRouter();
            const {downloadFromBase64} = useDownload();

            const docTemplateDropdown = ref<CDropdown>();
            const downloading = ref(false);
            const downloadElement = ref();

            const filteredDocTemplates = computed(() => {
                // Filter registration as a contract type
                const docType = props.docType === 'registration' ? 'contract' : props.docType;

                const clonedTemplates = _cloneDeep(props.nursery.docTemplates);

                return _sortBy(clonedTemplates.filter(item => item.type === docType), 'name');
            });

            const dynamicTemplateProps = computed(() => {
                if (props.as === 'CDropdownMenuItem') {
                    return {
                        class: props.customClass,
                        disabled: props.disabled,
                        loading: props.loading || downloading.value,
                        icon: 'fa fa-download',
                    };
                } else if (props.as === 'MMenuItem') {
                    return {
                        disabled: props.disabled || downloading.value,
                        variant: 'default',
                        autoClosePopover: false,
                    };
                } else {
                    return {
                        class: props.customClass,
                        disabled: props.disabled,
                        loading: props.loading || downloading.value,
                        size: props.sm ? 'base' : null,
                        variant: props.variant,
                    };
                }
            });

            function download(template) {
                docTemplateDropdown.value?.hide();

                downloading.value = true;

                // Generate docs params for the operation depending on the doc template type
                const params: { [key: string]: number | string | string[] } = {
                    doc_template_id: template.id,
                };

                switch (props.docType) {
                    case 'contract': {
                        const contractConfigId = props.item.config ? props.item.config.id : '';
                        params.contract_id = props.item.id.toString();
                        params.config_id = contractConfigId.toString();

                        if (props.item.kid_id) {
                            params.kid_id = props.item.kid_id.toString();
                        }

                        if (props.item.first_family_member_id || props.item.second_family_member_id) {
                            params.family_member_ids = [];

                            if (props.item.first_family_member_id) {
                                params.family_member_ids.push(props.item.first_family_member_id.toString());
                            }

                            if (props.item.second_family_member_id) {
                                params.family_member_ids.push(props.item.second_family_member_id.toString());
                            }
                        }
                        break;
                    }
                    case 'staff_contract': {
                        params.staff_id = props.item.staff.id.toString();
                        params.staff_contract_id = props.item.contract.id.toString();
                        break;
                    }
                    case 'registration': {
                        const regisrationConfigId = props.item.config ? props.item.config.id : '';
                        params.registration_id = props.item.id.toString();
                        params.config_id = regisrationConfigId.toString();
                        break;
                    }
                    case 'kid':
                        params.kid_id = props.item.id.toString();
                        params.family_member_ids = [];

                        // eslint-disable-next-line no-case-declarations
                        const {getKidDocumentFamilyMemberIds} = useLegacyKidContract(toRef(props, 'item') as Ref<LegacyKid>);
                        params.family_member_ids = getKidDocumentFamilyMemberIds();
                        break;
                    case 'staff':
                        params.staff_id = props.item.id.toString();
                        break;
                    case 'family_member':
                        params.family_member_id = props.item.id.toString();
                        break;
                    case 'transaction':
                        params.transaction_id = props.item.getKey();
                        break;
                    case 'sepa_mandate':
                        params.sepa_mandate_id = props.item.id.toString();
                        break;
                    case 'tax-certificate':
                        params.customer_id = props.item.id.toString();
                        if (props.fromDate) {
                            params.from = moment(props.fromDate).format('Y-MM-DD');
                        }
                        if (props.fromDate) {
                            params.to = moment(props.toDate).format('Y-MM-DD');
                        }
                        break;
                }

                if (props.additionalItems) {
                    props.additionalItems.forEach(additionalItem => {
                        params[additionalItem.name] = additionalItem.value.toString();
                    });
                }

                params.organization_id = props.nursery.id.toString();

                new MqlOperation<ResponseObject.CustomObject>('doc_template/download', {
                    docs: [params],
                }).run().then(async result => {
                    // Generate fake link to download
                    downloadFromBase64(
                        result.content.data.file,
                        result.content.data.name + '.' + result.content.data.extension,
                        'application/' + result.content.data.extension,
                    );
                }).then(() => {
                    emit('downloaded');

                    if (props.as === 'MMenuItem') {
                        downloadElement.value?.closePopover();
                    }
                }).catch(() => {
                    toastError(__('common:errors.generic'));
                }).finally(() => {
                    downloading.value = false;
                });
            }

            function saveBeforeDownloadTemplate() {
                if (props.waitForCallback) {
                    emit('saveBeforeDownloadTemplate', {
                        done() {
                            if (filteredDocTemplates.value.length === 1) {
                                const template = filteredDocTemplates.value[0];
                                download(template);
                            } else {
                                docTemplateDropdown.value?.show();
                            }
                        },
                    });
                } else {
                    emit('saveBeforeDownloadTemplate');
                    nextTick(() => {
                        if (filteredDocTemplates.value.length === 1) {
                            const template = filteredDocTemplates.value[0];
                            download(template);
                        } else {
                            docTemplateDropdown.value?.show();
                        }
                    });
                }
            }

            function createTemplate() {
                router?.push({name: 'settings', params: {nursery: props.nursery.id}});
            }

            return {
                createTemplate,
                docTemplateDropdown,
                download,
                downloading,
                downloadElement,
                filteredDocTemplates,
                saveBeforeDownloadTemplate,
                dynamicTemplateProps,
            };
        },
    });
</script>
