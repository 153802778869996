<template>
    <div
        class="card-chart"
        :class="{'settings': showSettings}"
    >
        <div class="chart-title">
            <div class="ml-1 mb-3">
                <div class="d-flex flex-row ml-1 mb-3">
                    <div class="d-flex align-items-center tw-text-base tw-font-semibold text-uppercase">
                        {{ selectedDataType.text }}
                    </div>
                    <div
                        class="d-flex align-items-center flex-row ml-auto tw-text-sm tw-font-semibold text-uppercase pointer"
                        @click="showSettings = !showSettings"
                    >
                        {{ fromDate.toFormat('MMM yyyy') }} - {{ toDate.toFormat('MMM yyyy') }}<i
                            aria-hidden="true"
                            class="fas fa-pencil ml-2"
                        />
                    </div>
                </div>
            </div>
            <div class="actions">
                <div class="tw-grid tw-grid-cols-4 tw-gap-2">
                    <MMenu class="tw-w-full">
                        <MMenuButton class="tw-w-full">
                            <MButton
                                class="tw-w-full"
                                icon-menu
                                :label="selectedDataType.text"
                            />
                        </MMenuButton>
                        <MMenuItems>
                            <MMenuItem
                                v-for="(item, i) in dataTypeOptions"
                                :key="'dataOption' + i"
                                :label="item.text"
                                @click="selectedDataType = item"
                            />
                        </MMenuItems>
                    </MMenu>
                    <MMenu
                        v-if="selectedDataType.value === 'amounts'"
                        class="tw-w-full"
                    >
                        <MMenuButton class="tw-w-full">
                            <MButton
                                class="tw-w-full"
                                icon-menu
                                :label="selectedAmountType.text"
                            />
                        </MMenuButton>
                        <MMenuItems>
                            <MMenuItem
                                v-for="(item, i) in amountTypeOptions"
                                :key="'invoiceOption' + i"
                                :label="item.text"
                                @click="selectedAmountType = item"
                            />
                        </MMenuItems>
                    </MMenu>
                    <MMenu
                        v-else
                        class="tw-w-full"
                    >
                        <MMenuButton class="tw-w-full">
                            <MButton
                                class="tw-w-full"
                                icon-menu
                                :label="selectedHourType.text"
                            />
                        </MMenuButton>
                        <MMenuItems>
                            <MMenuItem
                                v-for="(item, i) in hourTypeOptions"
                                :key="'paymentOption' + i"
                                :label="item.text"
                                @click="selectedHourType = item"
                            />
                        </MMenuItems>
                    </MMenu>
                    <MMonthPicker v-model="fromDate"/>
                    <MMonthPicker v-model="toDate"/>
                </div>
            </div>
        </div>
        <div class="chart-content">
            <div class="chart-container">
                <div class="w-100">
                    <loader
                        v-if="loading"
                        light="true"
                        shadow="false"
                        size="sm"
                    />
                    <bar-slim
                        v-else
                        :chart-data="chartData"
                        :nursery="nursery"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import useApi from '@/modules/app/composables/useApi';
    import route from '@/modules/legacy/libs/ziggy';
    import _head from 'lodash-es/head';
    import _forEach from 'lodash-es/forEach';
    import _map from 'lodash-es/map';
    import _round from 'lodash-es/round';
    import * as ProcessedData from '@/modules/legacy/libs/ProcessedData';
    import BarSlim from '@/modules/legacy/components/Modules/Charts/BarSlim.vue';
    import {useLocalStorage} from '@/modules/legacy/helpers/useLocalStorage';
    import useBroadcast from '@/modules/app/composables/useBroadcast';
    import __ from '@/modules/app/utils/i18n-facade';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';
    import {Epoch} from '@meekohq/lumos';

    export default {
        components: {
            BarSlim,
        },
        props: {
            nursery: {
                type: Object,
            },
        },
        data: () => ({
            chartData: {
                name: __('dashboard:meeko_chart'),
                categories: [],
                colors: ['#56c900', '#0091ff', '#ffbf00', '#f44336', '#ff8100', '#229bf1', '#00c7dc', '#ef5a9d', '#415065', '#707aa2'],
                series: [],
                type: null,
                stacked: true,
            },
            fromDate: Epoch.now(),
            toDate: Epoch.now(),
            dataTypeOptions: [{
                text: __('common:amount_other'),
                value: 'amounts',
            }, {
                text: __('common:hour_other'),
                value: 'hours',
            }],
            selectedDataType: {},
            amountTypeOptions: [{
                text: __('common:both'),
                value: undefined,
            }, {
                text: __('common:forecasted_male_other'),
                value: 'estimated',
            }, {
                text: __('common:billed_other'),
                value: 'billed',
            }],
            selectedAmountType: {},
            hourTypeOptions: [{
                text: __('common:forecasted_female_other'),
                value: 'estimated',
            }, {
                text: __('dashboard:billed_female_other'),
                value: 'billed',
            }],
            selectedHourType: {},
            broadcast_id: Math.floor(100000 + Math.random() * 900000),
            loading: false,
            showSettings: false,
        }),
        watch: {
            selectedDataType() {
                this.selectedAmountType = this.amountTypeOptions[0];
                this.selectedHourType = this.hourTypeOptions[0];
                this.getRevenues();
            },
            selectedAmountType() {
                this.getRevenues();
            },
            selectedHourType() {
                this.getRevenues();
            },
            fromDate(value) {
                window.localStorage.setItem('dashboard:billingDetails:from', value.toFormat('yyyy-MM'));
                this.getRevenues();
            },
            toDate(value) {
                window.localStorage.setItem('dashboard:billingDetails:to', value.toFormat('yyyy-MM'));
                this.getRevenues();
            },
            'nursery.id': {
                handler() {
                    this.getRevenues();
                },
                deep: true,
            },
        },
        mounted() {
            this.initFilters();

            useBroadcast().sessionChannel.bind('billing-stats-' + this.broadcast_id, response => {
                ProcessedData.retrieve(response.processedDataUrl, response => {
                    const recurrentData = [];
                    const occasionalData = [];
                    const adaptationData = [];
                    const absenceData = [];
                    const companyData = [];
                    const overrunData = [];
                    const mealData = [];
                    const healthData = [];
                    const registrationData = [];
                    const otherData = [];

                    const stats = _map(response.data[0].stats, 'stats');

                    if (stats) {
                        const hoursToSum = ['absence', 'adaptation', 'occasional', 'overrun', 'recurrent'];
                        const pricesToSum = ['absence', 'adaptation', 'cmg', 'custom', 'deposit', 'occasional', 'overrun', 'recurrent', 'registration'];

                        // EVERY MONTHS
                        stats.forEach(stat => {
                            let totalRecurrent = 0;
                            let totalOccasional = 0;
                            let totalAdaptation = 0;
                            let totalAbsence = 0;
                            let totalCompany = 0;
                            let totalOverrun = 0;
                            let totalMeal = 0;
                            let totalHealth = 0;
                            let totalRegistration = 0;
                            let totalOther = 0;

                            if (this.selectedDataType.value === 'hours') {
                                totalRecurrent += stat.presence.hours.recurrent + stat.custom.hours.recurrent;
                                totalOccasional += stat.presence.hours.occasional + stat.custom.hours.occasional;
                                totalAdaptation += stat.presence.hours.adaptation + stat.custom.hours.adaptation;
                                totalAbsence += stat.presence.hours.absence + stat.custom.hours.absence;
                                totalOverrun += stat.presence.hours.overrun + stat.custom.hours.overrun;

                                hoursToSum.forEach(lineType => {
                                    totalCompany += stat.company.hours[lineType];
                                });
                            } else {
                                totalRecurrent += stat.presence.prices.recurrent + stat.custom.prices.recurrent + stat.presence.prices.cmg + stat.custom.prices.cmg;
                                totalOccasional += stat.presence.prices.occasional + stat.custom.prices.occasional;
                                totalAdaptation += stat.presence.prices.adaptation + stat.custom.prices.adaptation;
                                totalAbsence += stat.presence.prices.absence + stat.custom.prices.absence;
                                totalOverrun += stat.presence.prices.overrun + stat.custom.prices.overrun;
                                totalMeal += stat.presence.prices.meal + stat.custom.prices.meal;
                                totalHealth += stat.presence.prices.health + stat.custom.prices.health;
                                totalRegistration += stat.presence.prices.registration + stat.custom.prices.registration;
                                totalOther += stat.presence.prices.custom + stat.custom.prices.custom + stat.presence.prices.deposit + stat.custom.prices.deposit;

                                pricesToSum.forEach(lineType => {
                                    totalCompany += stat.company.prices[lineType];
                                });
                            }

                            recurrentData.push(_round(totalRecurrent, 2));
                            occasionalData.push(_round(totalOccasional, 2));
                            adaptationData.push(_round(totalAdaptation, 2));
                            absenceData.push(_round(totalAbsence, 2));
                            companyData.push(_round(totalCompany, 2));
                            overrunData.push(_round(totalOverrun, 2));
                            mealData.push(_round(totalMeal, 2));
                            healthData.push(_round(totalHealth, 2));
                            registrationData.push(_round(totalRegistration, 2));
                            otherData.push(_round(totalOther, 2));
                        });
                    }

                    this.chartData.type = this.selectedDataType.value === 'amounts' ? this.nursery.currency : 'h';

                    this.chartData.series = [
                        {
                            name: __('common:recurrent'),
                            data: recurrentData,
                        },
                        {
                            name: __('common:occasional'),
                            data: occasionalData,
                        },
                        {
                            name: __('common:adaptation'),
                            data: adaptationData,
                        },
                        {
                            name: __('common:absence'),
                            hidden: false,
                            data: absenceData,
                        },
                        {
                            name: __('common:company_one'),
                            data: companyData,
                        },
                        {
                            name: __('common:overrun_other'),
                            data: overrunData,
                        },
                        {
                            name: __('common:meal'),
                            hidden: false,
                            data: mealData,
                        },
                        {
                            name: __('common:care'),
                            hidden: false,
                            data: healthData,
                        },
                        {
                            name: __('common:registration_fees'),
                            hidden: false,
                            data: registrationData,
                        },
                        {
                            name: __('common:other_other'),
                            hidden: false,
                            data: otherData,
                        },
                    ],

                    this.rereshChartCategories();
                    this.loading = false;
                });
            });
        },
        beforeDestroy() {
            useBroadcast().sessionChannel.unbind('billing-stats-' + this.broadcast_id);
        },
        methods: {
            getRevenues() {
                if (this.loading) {
                    return;
                }

                this.loading = true;
                useApi().legacy.post(route('stats.sales'), {
                    from: this.fromDate.toFormat('yyyy-MM'),
                    to: this.toDate.toFormat('yyyy-MM'),
                    nurseries: [this.nursery.id],
                    type: this.selectedDataType.value === 'amounts' ? this.selectedAmountType.value : this.selectedHourType.value,
                    with_detail: true,
                    broadcast_id: this.broadcast_id,
                }).catch(error => {
                    this.loading = false;
                    if (error && error.response && error.response.status === 422) {
                        _forEach(error.response.data.errors, value => {
                            useNotification().error(_head(value));
                        });
                    } else {
                        useNotification().error(error);
                    }
                });
            },

            rereshChartCategories() {
                this.chartData.categories = [];

                let monthIterator = this.fromDate;
                while (monthIterator.lessThanOrEqualTo(this.toDate) || monthIterator.toFormat('M') === this.toDate.toFormat('M')) {
                    this.chartData.categories.push(monthIterator.toFormat('MMM'));
                    monthIterator = monthIterator.addMonths();
                }
            },

            initFilters() {
                this.selectedDataType = this.dataTypeOptions[0];
                const {getLocalStorage} = useLocalStorage();

                const fromTextValue = getLocalStorage('dashboard:billingDetails:from');
                const toTextValue = getLocalStorage('dashboard:billingDetails:to');

                if (fromTextValue) {
                    this.fromDate = Epoch.parse(fromTextValue, 'yyyy-MM');
                } else {
                    this.fromDate = Epoch.now().startOfMonth();
                }

                if (toTextValue) {
                    this.toDate = Epoch.parse(toTextValue, 'yyyy-MM');
                } else {
                    this.toDate = Epoch.now().startOfMonth().addMonths(5);
                }
            },
        },
    };
</script>
