<template>
    <SimplePageTemplate>
        <template #title>
            <MPageTitle>
                {{ __('app:menu.staffs_planning') }}
            </MPageTitle>
        </template>
        <template #actions>
            <TopActions
                :current-display="__('common:weekly')"
                :nursery="organization"
                @manageTemplates="showManageTemplatesModal = true"
                @resetAllEvents="resetAllEvents"
                @useTemplates="showUseTemplateModal = true"
            />
        </template>
        <template #content>
            <TopActionsSwitcher
                :current-display="__('common:weekly')"
                :nursery="organization"
            />
            <CCard
                body-size="sm"
                :border="false"
                class="tw-mt-3 tw-relative tw-z-0"
                :shadow="true"
            >
                <div class="tw-flex tw-flex-wrap print:tw-hidden">
                    <div
                        class="tw-w-1/2 sm:tw-w-1/3 tw-order-2 sm:tw-order-1 tw-text-left"
                    >
                        <CButton
                            variant="success"
                            @click="date = date.clone().subtract(1, 'weeks')"
                        >
                            <i
                                aria-hidden="true"
                                class="fa fa-angle-left tw-mr-1"
                            />{{ __('common:week') }}
                            {{
                                date
                                    .clone()
                                    .subtract(1, 'weeks')
                                    .week()
                            }}
                        </CButton>
                    </div>
                    <div
                        class="tw-w-full sm:tw-w-1/3 tw-order-1 sm:tw-order-2 tw-text-center"
                    >
                        <MHeading>
                            {{ __('common:longweek_with_number', {week: date.week()}) }}
                        </MHeading>
                        <div class="tw-text-gray-500">
                            {{
                                date
                                    .clone()
                                    .startOf('week')
                                    .format('DD/MM/Y')
                            }}
                            -
                            {{
                                date
                                    .clone()
                                    .endOf('week')
                                    .format('DD/MM/Y')
                            }}
                        </div>
                    </div>
                    <div class="tw-w-1/2 sm:tw-w-1/3 tw-order-3 tw-text-right">
                        <CButton
                            variant="success"
                            @click="date = date.clone().add(1, 'weeks')"
                        >
                            {{ __('common:week') }}
                            {{
                                date
                                    .clone()
                                    .add(1, 'weeks')
                                    .week()
                            }}
                            <i
                                aria-hidden="true"
                                class="fa fa-angle-right tw-ml-1"
                            />
                        </CButton>
                    </div>
                </div>
                <h4 class="h4 tw-hidden print:tw-flex tw-mt-0 tw-pb-5 tw-text-blue-500 tw-justify-center tw-w-full tw-capitalize">
                    {{
                        __('common:date_format.human_date_to_date_with_week', {
                            from: date
                                .clone()
                                .startOf('week')
                                .format('DD/MM/YYYY'),
                            to: date
                                .clone()
                                .endOf('week')
                                .format('DD/MM/YYYY'),
                            week: date.week(),
                        })
                    }}
                </h4>

                <div class="tw-overflow-x-auto tw-overflow-y-hidden print:tw-overflow-x-visible tw-py-6">
                    <loader
                        v-if="loading"
                        light="true"
                        shadow="false"
                        :title="__('common:loading_dots')"
                    />
                    <div
                        v-else-if="mainPayload.processed"
                        class="tw-flex tw-justify-between tw-relative"
                    >
                        <div class="tw-w-28">
                            <div class="tw-mt-6 tw-mb-6">
                                <div class="tw-h-7"/>
                                <div>
                                    <FilterGroup
                                        class="tw-w-full tw-mb-2"
                                        :nursery="organization"
                                        :value="selectedGroup"
                                        variant="primary"
                                        @input="selectedGroup = $event"
                                    />
                                    <div>
                                        <CButton
                                            class="tw-w-full"
                                            icon-menu
                                            variant="primary"
                                            @click="$refs.staffFilter.toggle()"
                                        >
                                            {{
                                                selectedPlanning === 'present' || selectedGroup !== null
                                                    ? __('planning:present_team')
                                                    : __('planning:active_staff')
                                            }}
                                        </CButton>
                                        <CDropdown
                                            ref="staffFilter"
                                            legacy-mode
                                        >
                                            <CList class="tw-cursor-pointer">
                                                <CListRow @click="changePlanningType('present')">
                                                    <i
                                                        v-if="selectedPlanning === 'present' || selectedGroup !== null"
                                                        class="fa fa-check tw-mr-2"
                                                    />
                                                    {{ __('planning:present_team') }}
                                                </CListRow>
                                                <MTooltip
                                                    class="tw-w-full"
                                                    :hoverable="selectedGroup !== null"
                                                    :label="__('planning:group_selected')"
                                                    placement="top"
                                                >
                                                    <CListRow
                                                        :variant="selectedGroup !== null ? 'disabled' : 'default'"
                                                        @click="changePlanningType('active')"
                                                    >
                                                        <i
                                                            v-if="selectedPlanning === 'active' && selectedGroup === null"
                                                            class="fa fa-check tw-mr-2"
                                                        />
                                                        {{ __('planning:active_staff') }}
                                                    </CListRow>
                                                </MTooltip>
                                                <CDropdownMenuDivider
                                                    v-if="teamFilter && teamFilter.hasQueryResults"
                                                    class="tw-my-4"
                                                />
                                                <TeamFinder
                                                    ref="teamFilter"
                                                    v-model="selectedTeams"
                                                    button-class="tw-w-full"
                                                    multi
                                                />
                                            </CList>
                                        </CDropdown>
                                    </div>
                                </div>
                            </div>

                            <div v-show="!loading">
                                <div
                                    v-for="(staff, i) in staffs"
                                    :key="'staff' + i"
                                    class="tw-h-9 tw-mb-1"
                                >
                                    <div
                                        class="tw-flex tw-items-center tw-font-grandhotel tw-text-xl tw-h-full tw-capitalize"
                                        :class="staff.genderColor"
                                    >
                                        <BadgeTasks
                                            class="tw-mr-3"
                                            size="sm"
                                            :tasks="tasksByStaffId[staff.getKey()]"
                                        >
                                            <StaffAvatar
                                                class="tw-w-8"
                                                :staff-model="staff"
                                            />
                                        </BadgeTasks>
                                        <router-link
                                            class="tw-text-inherit tw-truncate"
                                            :to="{
                                                name: 'staffs.show',
                                                params: {
                                                    nursery: organization.id,
                                                    staff: staff.id,
                                                },
                                            }"
                                        >
                                            {{ $fullName(staff.attributes) }}
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="tw-flex tw-justify-between tw-w-full">
                            <div
                                v-for="(dayOfWeek, dayIndex) in enumerateDaysBetweenDates"
                                :key="'currentDay_' + dayIndex"
                                class="tw-w-full tw-min-w-48 tw-ml-3 showDayHoursTrigger"
                            >
                                <div style="padding-left: 16px;">
                                    <router-link
                                        class="tw-cursor-pointer tw-text-center"
                                        tag="div"
                                        :to="{
                                            name: 'planning.staffs.daily',
                                            params: {
                                                nursery: organization.id,
                                                date: dayOfWeek
                                                    .clone()
                                                    .format('DD/MM/Y'),
                                            },
                                        }"
                                    >
                                        <h6 class="h6 tw-relative tw-h-5">
                                            <div
                                                class="showDay tw-w-full print:tw-hidden"
                                                :class="[
                                                    {'tw-text-blue-500 tw-font-semibold': moment(dayOfWeek).isSame(moment(), 'day')},
                                                ]"
                                            >
                                                {{ $capitalize(dayOfWeek.format('dddd - DD/MM')) }}
                                                <CBadge
                                                    v-if="!occupationLoading && getOccupationRate(dayOfWeek) == null"
                                                    size="sm"
                                                    variant="light"
                                                >
                                                    {{ __('common:closed') }}
                                                </CBadge>
                                            </div>
                                            <div class="tw-w-full tw-hidden print:tw-block">
                                                {{ $capitalize(dayOfWeek.format('dddd - DD/MM')) }}
                                            </div>
                                        </h6>
                                    </router-link>
                                    <div class="tw-h-7 tw--ml-2 tw-pr-3.5 tw--mt-1 tw-mb-1">
                                        <Hours
                                            class="showDayHours"
                                            :closing="mainPayload.closings[dayOfWeek.format('YYYY-MM-DD')]"
                                            grid-on-hover
                                            :opening="mainPayload.openings[dayOfWeek.format('YYYY-MM-DD')]"
                                            simple
                                            small
                                        />
                                    </div>
                                    <div class="tw-mb-5">
                                        <summary-bar
                                            class="tw-mb-2"
                                            :closing="mainPayload.closings[dayOfWeek.format('YYYY-MM-DD')]"
                                            :day="dayOfWeek"
                                            :loading="occupationLoading || !mainPayload.processed || !occupationPayload.processed"
                                            :nursery="organization"
                                            :opening="mainPayload.openings[dayOfWeek.format('YYYY-MM-DD')]"
                                            small
                                            :summary="occupationPayload.data[dayOfWeek.format('YYYY-MM-DD')]"
                                        />
                                        <summary-bar-staff
                                            :closing="mainPayload.closings[dayOfWeek.format('YYYY-MM-DD')]"
                                            :day="dayOfWeek"
                                            :jobs="jobs"
                                            :loading="supervisionLoading || !mainPayload.processed || !supervisionPayload.processed"
                                            :nursery="organization"
                                            :opening="mainPayload.openings[dayOfWeek.format('YYYY-MM-DD')]"
                                            small
                                            :summary="supervisionPayload.data[dayOfWeek.format('YYYY-MM-DD')]"
                                        />
                                    </div>
                                </div>

                                <div
                                    v-for="(staff, i) in staffs"
                                    :key="'staff_planning' + i"
                                    class="tw-flex tw-items-center tw-h-9 tw-mb-1"
                                >
                                    <PlanningGenerator
                                        always-show-full-day
                                        always-show-ghost
                                        :closing="mainPayload.closings[dayOfWeek.format('YYYY-MM-DD')]"
                                        :create-with-modal="true"
                                        :day="dayOfWeek"
                                        :events="staff.events().value().all().filter(event => event.attributes.date_event === null)"
                                        :full-day-events="mainPayload.fullDayEvents[dayOfWeek.format('YYYY-MM-DD')]"
                                        :opening="mainPayload.openings[dayOfWeek.format('YYYY-MM-DD')]"
                                        :show-divider="false"
                                        show-note
                                        size="md"
                                        :staff="staff"
                                        @add="newEvent(dayOfWeek, staff, true, true)"
                                        @pushEvent="staff.events().value().all().push($event)"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <h1
                    v-if="!loading && staffs.length === 0"
                    class="h1 tw-font-grandhotel tw-text-center tw-pt-4"
                >
                    {{ __('planning:there_are_no_team_member') }}
                </h1>
            </CCard>

            <ManageTemplatesModal
                v-if="showManageTemplatesModal"
                :current-week="date.week()"
                :staffs-from-current-week="staffs"
                @hidden="showManageTemplatesModal = false"
            />

            <UseTemplateModal
                v-if="showUseTemplateModal"
                @hidden="showUseTemplateModal = false"
                @update:plannings="getCalendar()"
            />

            <EventModal
                v-if="selectedEvent"
                ref="eventModal"
                :event="selectedEvent"
                @add="onEventAdded"
                @delete="onEventDeleted"
                @hidden="selectedEvent = null"
            />
        </template>
    </SimplePageTemplate>
</template>

<script lang="ts">
    import type {Ref} from 'vue';
    import {defineComponent, onMounted, onUnmounted, ref, set, watch} from 'vue';
    import moment from 'moment';
    import useCalendar from '@/modules/human-resources/composables/calendar/useCalendar';
    import Line from '@/modules/human-resources/components/calendar/Line.vue';
    import Hours from '@/modules/legacy/components/Modules/PlanningGenerator/Hours.vue';
    import EventModal from '@/modules/human-resources/components/calendar/EventModal.vue';
    import ManageTemplatesModal
        from '@/modules/human-resources/components/calendar/CalendarTemplate/Weekly/ManageTemplates.vue';
    import UseTemplateModal
        from '@/modules/human-resources/components/calendar/CalendarTemplate/Weekly/UseTemplate.vue';
    import Summary from '@/modules/legacy/components/Modules/PlanningGenerator/Summary.vue';
    import StaffSummary from '@/modules/legacy/components/Modules/PlanningGenerator/StaffSummary.vue';
    import TopActions from '@/modules/planning/components/staff/TopActions.vue';
    import FilterGroup from '@/modules/family/components/kid/Index/FilterGroup.vue';
    import BadgeTasks from '@/modules/activity/components/pages/BadgeTasks.vue';
    import TeamFinder from '@/modules/human-resources/components/team/TeamFinder.vue';
    import JobModel from '@/modules/human-resources/models/JobModel';
    import useUserFilters, {OutputType} from '@/modules/app/composables/useUserFilters';
    import type TeamModel from '@/modules/human-resources/models/TeamModel';
    import useMetrics from '@/modules/app/composables/useRum';
    import StaffModel from '@/modules/human-resources/models/StaffModel';
    import useGetTasks from '@/modules/activity/composables/useGetTasks';
    import StaffAvatar from '@/modules/app/components/atoms/avatars/StaffAvatar.vue';
    import SimplePageTemplate from '@/modules/app/components/templates/SimplePageTemplate.vue';
    import TopActionsSwitcher from '@/modules/planning/components/staff/TopActionsSwitcher.vue';
    import {whenever} from '@vueuse/core';
    import {EventBus} from '@/modules/legacy/utils/bus';

    export default defineComponent({
        components: {
            TopActionsSwitcher,
            SimplePageTemplate,
            StaffAvatar,
            'PlanningGenerator': Line,
            Hours,
            EventModal,
            ManageTemplatesModal,
            UseTemplateModal,

            'summary-bar': Summary,
            'summary-bar-staff': StaffSummary,
            TopActions,
            FilterGroup,
            TeamFinder,
            BadgeTasks,
        },
        setup() {
            const teamFilter = ref();
            const {addAction} = useMetrics();
            const {selectedOptions: selectedTeams} =
                useUserFilters('calendar:staffs:teams', OutputType.array, []);

            const {selectedOptions: selectedPlanning} =
                useUserFilters('calendar:staffs:planningType', OutputType.value, 'present');

            const {selectedOptions: selectedGroup} =
                useUserFilters('calendar:staffs:group', OutputType.value, null);

            const {
                organization,

                changePlanningType,
                date,
                enumerateDaysBetweenDates,
                opening,
                closing,

                loading,
                getCalendar,
                resetAllEvents,

                staffs,

                occupationLoading,
                supervisionLoading,
                weekOccupationRateBar,
                weekOccupationRateBarStaff,

                fullDayEvents,
                selectedEvent,

                newEvent,
                onEventAdded,
                onEventDeleted,
                eventModal,

                getOccupationRate,
            } = useCalendar(
                undefined,
                undefined,
                null,
                true,
                true,
                true,
                ref({
                    teams: (selectedTeams as Ref<TeamModel[]>),
                    planningType: (selectedPlanning as Ref<'present' | 'active'>),
                    group: (selectedGroup as Ref<string | null>),
                }),
            );

            const {
                tasks,
                tasksGroupByModelId: tasksByStaffId,
                getTasks: getTasksRelatedToStaffs,
            } = useGetTasks({
                constrainToResourceModel: StaffModel,
            });

            watch(staffs, newStaffs => {
                getTasksRelatedToStaffs(newStaffs.map(staff => staff.getKey()))
                    .then(tasksRelatedToStaffs => tasks.value = tasksRelatedToStaffs);
            });

            watch(selectedTeams, () => {
                getCalendar();
            });

            onMounted(() => {
                addAction('M_Planning_Staff_Display', {value: 'week'});

                // Update opening and closing hours when the calendar is refreshed
                EventBus.$on('calendar:staffs:refresh', () => {
                    refreshPayload();
                });
            });

            onUnmounted(() => {
                EventBus.$off('calendar:staffs:refresh');
            });

            const jobs = ref<any[]>([]);

            JobModel.query().get().then(data => {
                jobs.value = data.all();
            });

            const showManageTemplatesModal = ref(false);
            const showUseTemplateModal = ref(false);

            // ****** Keep data in cache for performance
            const mainPayload = ref({
                processed: false,
                openings: {},
                closings: {},
                fullDayEvents: {},
            });

            whenever(() => loading.value === false, () => {
                refreshPayload();
                mainPayload.value.processed = true;
            });

            const occupationPayload = ref({
                processed: false,
                data: {},
            });

            whenever(() => occupationLoading.value === false, () => {
                for (const day of enumerateDaysBetweenDates.value) {
                    occupationPayload.value.data[day.format('YYYY-MM-DD')] = weekOccupationRateBar(day);
                }
                occupationPayload.value.processed = true;
            });

            const supervisionPayload = ref({
                processed: false,
                data: {},
            });

            whenever(() => supervisionLoading.value === false, () => {
                for (const day of enumerateDaysBetweenDates.value) {
                    supervisionPayload.value.data[day.format('YYYY-MM-DD')] = weekOccupationRateBarStaff(day);
                }
                supervisionPayload.value.processed = true;
            });

            function refreshPayload() {
                for (const day of enumerateDaysBetweenDates.value) {
                    mainPayload.value.openings[day.format('YYYY-MM-DD')] = opening(day, true);
                    mainPayload.value.closings[day.format('YYYY-MM-DD')] = closing(day, true);
                    mainPayload.value.fullDayEvents[day.format('YYYY-MM-DD')] = fullDayEvents(day).all();
                }
            }

            return {
                mainPayload,
                occupationPayload,
                supervisionPayload,

                changePlanningType,
                moment,

                organization,

                set,

                date,
                enumerateDaysBetweenDates,

                loading,
                getCalendar,
                resetAllEvents,

                teamFilter,
                selectedTeams,
                staffs,
                selectedPlanning,
                selectedGroup,

                occupationLoading,
                supervisionLoading,
                weekOccupationRateBar,
                weekOccupationRateBarStaff,

                fullDayEvents,
                selectedEvent,

                newEvent,
                onEventAdded,
                onEventDeleted,
                eventModal,

                getOccupationRate,

                jobs,

                showManageTemplatesModal,
                showUseTemplateModal,
                tasksByStaffId,
            };
        },
    });
</script>

<style lang="scss" scoped>
    .showDayHours {
        @apply tw-opacity-0 tw-transition-all tw-ease-in-out tw-duration-300;
    }

    .showDay {
        @apply tw-transition-all tw-ease-in-out tw-absolute tw-top-5;
    }

    .showDayHoursTrigger:hover {
        .showDayHours {
            @apply tw-opacity-100;
        }

        .showDay {
            @apply tw-top-0;
        }
    }

    @media print {
        .showDayHoursTrigger {
            .showDayHours {
                @apply tw-opacity-100;
            }

            .showDay {
                @apply tw-top-0;
            }
        }
    }
</style>
