import {Model} from '@meekohq/lumos';
import OrderModel from '@/modules/cashier/models/OrderModel';

export default class AddressModel extends Model {
    public type = 'cashier/customer_addresses';

    public attributes: {
        id: string;
        address: {
            line1: string | undefined;
            line2: string | undefined;
            postal_code: string | undefined;
            city: string | undefined;
            state: string | undefined;
            country_code: string | undefined;
        };
        name: string | undefined;
        customer_id: string | undefined;
        tenant_id: string | undefined;
        type: string | undefined;
        label: string | undefined;
        email: string | undefined;
    } = {
            id: this.uuid(),
            address: {
                line1: undefined,
                line2: undefined,
                postal_code: undefined,
                city: undefined,
                state: undefined,
                country_code: undefined,
            },
            name: undefined,
            customer_id: undefined,
            tenant_id: undefined,
            type: undefined,
            label: undefined,
            email: undefined,
        };

    get fullAddress(): string {
        let address = this.attributes.name;
        address += ', ' + this.attributes.address.line1;
        address += ', ' + this.attributes.address.postal_code;
        address += ' ' + this.attributes.address.city;
        address += ', ' + this.attributes.address.country_code;

        return address as string;
    }

    orders() {
        return this.hasMany('orders', OrderModel, 'billing_address_id');
    }
}
