<script lang="ts" setup>
    import type {LegacyInvoiceType} from '@/modules/cashier/utils/billing/invoice/LegacyInvoiceType';
    import {
        invoiceStatuses,
        useInvoiceStatusState,
    } from '@/modules/cashier/utils/billing/invoice/useInvoiceStatusState';
    import {computed, toRef} from 'vue';
    import InvoiceModel from '@/modules/cashier/models/InvoiceModel';

    const props = defineProps<{
        invoice: InvoiceModel | LegacyInvoiceType;
    }>();

    const {invoiceStatus} = useInvoiceStatusState(toRef(props, 'invoice'));

    const isCompany = computed(() => {
        if (props.invoice instanceof InvoiceModel) {
            return props.invoice.isCompany;
        }

        return props.invoice.type === 'company';
    });

    const invoiceIcon = computed(() => {
        let icon = '';
        let className = '';

        switch (invoiceStatus.value) {
            case invoiceStatuses.incomplete:
            case invoiceStatuses.pending:
                className = 'InvoiceIcon--is-orange';
                icon = 'fad fa-file-invoice';
                break;
            case invoiceStatuses.unpaid:
            case invoiceStatuses.uncollectible:
                className = 'InvoiceIcon--is-red';
                icon = 'fad fa-file-invoice';
                break;
            case invoiceStatuses.paid:
                className = 'InvoiceIcon--is-green';
                icon = 'fad fa-file-invoice';
                break;
            case invoiceStatuses.editing:
                className = 'InvoiceIcon--is-black';
                icon = 'fad fa-file-pen';
                break;
            case invoiceStatuses.draft:
            default:
                className = 'InvoiceIcon--is-gray';
                icon = 'fad fa-file';

        }

        if (isCompany.value) {
            icon = 'fad fa-city fa-swap-opacity';
        }

        return {
            icon,
            className,
        };
    });
</script>

<template>
    <FontAwesomeIcon
        class="InvoiceIcon"
        :class="invoiceIcon.className"
        fixed-width
        :icon="invoiceIcon.icon"
    />
</template>

<style lang="scss" scoped>
    .InvoiceIcon--is-gray {
        @apply tw-text-gray-500;
    }

    .InvoiceIcon--is-orange {
        @apply tw-text-orange-500;
    }

    .InvoiceIcon--is-red {
        @apply tw-text-red-500;
    }

    .InvoiceIcon--is-green {
        @apply tw-text-green-500;
    }

    .InvoiceIcon--is-black {
        @apply tw-text-black;
    }
</style>
