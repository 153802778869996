<script setup lang="ts">
    import FeesInput from '@/modules/cashier/components/settings/RulesCards/FeesInput.vue';
    import CurrencyInput from '@/modules/app/components/atoms/CurrencyInput.vue';
    import {Epoch} from '@meekohq/lumos';
    import {computed} from 'vue';

    const props = defineProps<{
        nursery: any;
        fee: any;
    }>();

    const epochHour = computed(() => {
        if (!props.fee.hour) {
            return undefined;
        }

        let hour = Epoch.parse(props.fee.hour, 'HH:mm:ss');
        if (hour && hour.isValid) {
            return hour;
        }

        hour = Epoch.parse(props.fee.hour, 'HH:mm');
        if (hour && hour.isValid) {
            return hour;
        }

        return undefined;
    });

    function setHour(value: Epoch) {
        props.fee.hour = value?.toFormat('HH:mm:ss') ?? null;
    }
</script>

<template>
    <div>
        <div class="tw-grid tw-grid-cols-4 tw-gap-4 tw-mb-4">
            <FeesInput
                class="md:tw-col-span-2 tw-col-span-full"
                :fee="fee"
                @removeFees="$emit('removeFees', fee)"
            />
        </div>
        <div class="tw-grid lg:tw-grid-cols-4 sm:tw-grid-cols-2 tw-grid-cols-1 tw-gap-4">
            <CInputGroup>
                <CInputAddon>
                    {{ __('common:meal_types.milk') }}
                </CInputAddon>
                <CurrencyInput
                    v-model="fee.amount.milk"
                    :currency="nursery.currency"
                />
            </CInputGroup>
            <CInputGroup>
                <CInputAddon>
                    {{ __('common:meal_types.mix') }}
                </CInputAddon>
                <CurrencyInput
                    v-model="fee.amount.mix"
                    :currency="nursery.currency"
                />
            </CInputGroup>
            <CInputGroup>
                <CInputAddon>
                    {{ __('common:meal_types.normal') }}
                </CInputAddon>
                <CurrencyInput
                    v-model="fee.amount.normal"
                    :currency="nursery.currency"
                />
            </CInputGroup>
            <MTooltip
                :label="__('billing_setting:hour_to_bill_meal')"
                placement="top"
            >
                <MTimePicker
                    allow-undefined
                    class="tw-w-full"
                    :value="epochHour"
                    @input="setHour"
                />
            </MTooltip>
        </div>
    </div>
</template>
