<template>
    <CVStack>
        <CHStack align="center">
            <CText>{{ __('common:each') }}</CText>
            <CInput
                v-model="rule.interval"
                class="tw-w-16 tw-mx-2"
                min="1"
                type="number"
            />
            <CText>{{ __('calendar:weekly_one') }}</CText>
        </CHStack>
        <CButtonGroup class="tw-mt-4">
            <CButton
                v-for="(day, i) in days"
                :key="'day-' + i"
                :variant="rule.byweekday?.includes(day.value) ? 'gray' : 'light'"
                @click="toggleDay(day.value)"
            >
                {{ day.text }}
            </CButton>
        </CButtonGroup>
    </CVStack>
</template>

<script lang="ts">
    import type {PropType} from 'vue';
    import {computed, defineComponent, onUnmounted} from 'vue';
    import type Rule from '@/modules/calendar/utils/RuleSet/Rule';
    import DayValue from '@/modules/calendar/utils/RuleSet/values/DayValue';
    import {EpochInfo} from '@meekohq/lumos';

    export default defineComponent({
        props: {
            rule: {
                type: Object as PropType<Rule>,
                required: true,
            },
        },
        setup(props) {
            const weekDays = computed(() => EpochInfo.weekdays('short'));

            const days: Array<{
                text: string,
                value: any
            }> = Object.keys(DayValue).filter(x => isNaN(parseInt(x))).map(day => {
                switch (day) {
                    case 'monday':
                        return {value: DayValue[day], text: weekDays.value[0]};
                    case 'tuesday':
                        return {value: DayValue[day], text: weekDays.value[1]};
                    case 'wednesday':
                        return {value: DayValue[day], text: weekDays.value[2]};
                    case 'thursday':
                        return {value: DayValue[day], text: weekDays.value[3]};
                    case 'friday':
                        return {value: DayValue[day], text: weekDays.value[4]};
                    case 'saturday':
                        return {value: DayValue[day], text: weekDays.value[5]};
                    default:
                        return {value: DayValue[day], text: weekDays.value[6]};
                }
            });

            function initData() {
                if (!props.rule.interval) {
                    props.rule.interval = 1;
                }

                if (!props.rule.byweekday) {
                    props.rule.byweekday = [];
                }
            }

            initData();

            onUnmounted(() => {
                if (props.rule.byweekday?.length === 0) {
                    props.rule.byweekday = undefined;
                }
            });

            function toggleDay(day: DayValue) {
                if (props.rule.byweekday?.includes(day)) {
                    props.rule.byweekday = props.rule.byweekday.filter(value => value !== day);
                } else {
                    props.rule.byweekday?.push(day);
                }
            }

            return {
                days,
                toggleDay,
            };
        },
    });
</script>
