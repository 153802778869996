<template>
    <div
        ref="transactionListItemAmount"
        class="TransactionListItemAmount"
    >
        <MTooltip
            v-if="transaction.isFailed"
            :label="__('billing_transaction:failed_date', {
                date: Epoch.fromISOString(transaction.attributes.failure_date).toLocaleString(Epoch.presets.DATE_SHORT),
            })"
            placement="top"
        >
            <FontAwesomeIcon
                class="tw-text-danger-600 tw-leading-none"
                icon="fas fa-reply"
            />
        </MTooltip>
        <FontAwesomeIcon
            v-else-if="transaction.isCredit"
            class="tw-text-green-500 tw-text-xl tw-leading-none"
            icon="fa fa-arrow-up-right"
        />
        <FontAwesomeIcon
            v-else
            class="tw-text-red-500 tw-text-xl tw-leading-none"
            icon="fa fa-arrow-down-left"
        />
        <MListItemTitle
            class="tw-truncate"
            :class="{'tw-line-through': transaction.isFailed}"
            :title="(transaction.isDebit ? '-' : '') + format(transaction.attributes.amount)"
        />
    </div>
</template>

<script lang="ts">
    import type {PropType} from 'vue';
    import {defineComponent, ref} from 'vue';
    import type TransactionModel from '@/modules/cashier/transaction/domain/TransactionModel';
    import useFormatNumber from '@/modules/cashier/composables/useFormatNumber';
    import MListItemTitle from '@/modules/meeko-ui/components/MListItemTitle.vue';
    import {useElementHover} from '@vueuse/core';
    import useEpoch from '@/modules/app/composables/useEpoch';
    import {Epoch} from '@meekohq/lumos';

    export default defineComponent({
        components: {
            MListItemTitle,
        },
        props: {
            transaction: {
                type: Object as PropType<TransactionModel>,
                required: true,
            },
        },
        setup(props) {
            const transactionListItemAmount = ref();

            const isHovered = useElementHover(transactionListItemAmount);
            // Get currency formatter from country code
            const {format} = useFormatNumber(props.transaction.computed.currency_iso_code);

            const {fromISOString, presets} = useEpoch();

            return {
                fromISOString,
                presets,
                transactionListItemAmount,
                isHovered,
                format,
            };
        },
        computed: {
            Epoch() {
                return Epoch;
            },
        },
    });
</script>

<style lang="scss" scoped>
    .TransactionListItemAmount {
        @apply tw-flex tw-items-center tw-gap-2;
    }
</style>
