<script setup lang="ts">
    import type {PropType} from 'vue';
    import {Epoch} from '@meekohq/lumos';
    import useFormatNumber from '@/modules/cashier/composables/useFormatNumber';
    import type InvoiceModel from '@/modules/cashier/models/InvoiceModel';

    const props = defineProps({
        invoice: {
            type: Object as PropType<InvoiceModel>,
            required: true,
        },
    });
    const {format} = useFormatNumber(props.invoice.computed.currency_iso_code);

</script>

<template>
    <div class="InvoiceMiniaturePaymentList">
        <div
            v-for="payment in invoice.paymentsRelation"
            :key="payment.getKey()"
            class="InvoiceMiniaturePaymentList__item"
            :class="{'InvoiceMiniaturePaymentList__item--highlighted': payment.getKey() === invoice.extra.payment.getKey()}"
        >
            <FontAwesomeIcon
                v-if="payment.getKey() === invoice.extra.payment.getKey()"
                icon="fas fa-arrow-right"
            />
            {{
                __('billing_transaction:payment_of_date_for_amount', {
                    date: Epoch.fromISOString(payment.attributes.date).toLocaleString(Epoch.presets.DATE_SHORT),
                    amount: format(payment.attributes.amount),
                })
            }}
        </div>
    </div>
</template>

<style lang="scss" scoped>
    .InvoiceMiniaturePaymentList {
        @apply tw-flex tw-flex-col tw-gap-1;
    }

    .InvoiceMiniaturePaymentList__item {
        @apply tw-flex tw-gap-2 tw-items-baseline;
        @apply tw-text-gray-500;
    }

    .InvoiceMiniaturePaymentList__item--highlighted {
        @apply tw-text-primary-500 tw-font-semibold tw-bg-primary-50 tw-py-0.5 tw-px-1.5 tw-rounded-lg;
    }
</style>
