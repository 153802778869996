import {Model} from '@meekohq/lumos';

export default class UnitModel extends Model {
    public type = 'cashier/units';

    public attributes: {
        id: string;
        tenant_id: string | undefined;
        extend_unit_id: string | null | undefined;
        extend_with_factor: number | undefined;
        internal_id: string;
        name: string | undefined;
        description: string | null | undefined;
        symbol: string | undefined;
        native: string | null | undefined;
        archived_at: string | null | undefined;
        created_at: string | undefined;
        updated_at: string | undefined;
    } = {
            id: this.uuid(),
            tenant_id: undefined,
            description: null,
            extend_unit_id: undefined,
            extend_with_factor: 1,
            internal_id: this.uuid(),
            name: undefined,
            native: null,
            symbol: undefined,
            archived_at: null,
            created_at: undefined,
            updated_at: undefined,
        };

    extendUnit() {
        return this.belongsTo('extendUnit', UnitModel, 'extend_unit_id');
    }
}
