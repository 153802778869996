<template>
    <div class="tw-grid tw-grid-cols-2 tw-gap-4">
        <template v-for="(balanceType, index) in everyBalanceTypes">
            <BalanceType
                :key="index"
                :balance-type="balanceType"
                :source="source"
                :staff="staff"
                :sum-with-source-allocation="true"
            />
        </template>
    </div>
</template>

<script lang="ts">
    import type {PropType} from 'vue';
    import {computed, defineComponent, ref, watch} from 'vue';
    import type StaffModel from '@/modules/human-resources/models/StaffModel';
    import type {Collection} from '@meekohq/lumos';
    import {collect} from '@meekohq/lumos';
    import BalanceTypeModel from '@/modules/human-resources/models/BalanceTypeModel';
    import BalanceType from '@/modules/human-resources/components/balance/BalanceType.vue';
    import type ContractModel from '@/modules/human-resources/models/ContractModel';
    import BalanceAllocationModel from '@/modules/human-resources/models/BalanceAllocationModel';

    export default defineComponent({
        components: {BalanceType},
        props: {
            balanceTypes: {type: Object as PropType<Collection<BalanceTypeModel>>, default: collect()},
            source: {type: Object as PropType<ContractModel>, required: true},
            staff: {type: Object as PropType<StaffModel>, required: true},
        },
        setup(props, {emit}) {
            const fetchedBalanceTypes = ref(collect<BalanceTypeModel>());

            watch(() => props.balanceTypes, async () => {
                // If contract has an id, we fetch the corresponding balanceTypes.
                if (props.source.getKey()) {
                    fetchedBalanceTypes.value = await BalanceTypeModel.query()
                        .whereHas('organizations', query => query.scope('active'))
                        .where(query => {
                            query.whereHas(new BalanceTypeModel().balanceAllocations(), query1 => {
                                query1.where('staff_id', props.staff.getKey())
                                    .where('source_id', props.source.getKey())
                                    .where('source_type', props.source.getType())
                                    .where(query2 => BalanceAllocationModel.restrictToConfirmedScope(query2));
                            });
                        })
                        .with(new BalanceTypeModel().balanceAllocations(), query => {
                            query.where('staff_id', props.staff.getKey())
                                .where(query2 => BalanceAllocationModel.restrictToConfirmedScope(query2));
                        })
                        .get();
                }
            }, {immediate: true});

            // Return a collection of fetched Balancetypes and balanceType's prop.
            const everyBalanceTypes = computed(() => {
                props.balanceTypes.each(item => {
                    const match = fetchedBalanceTypes.value.contains(m => m.id === item.id);
                    if (!match) {
                        fetchedBalanceTypes.value.push(item);
                    }
                });

                return fetchedBalanceTypes.value;
            });

            watch(everyBalanceTypes, balanceTypesValue => {
                let allocationsToSave = [];
                balanceTypesValue.each(balanceType => {
                    // Get allocations.
                    const allocations = balanceType.balanceAllocations().setQuery(query => {
                        query.where('staff_id', props.staff.getKey());
                    }).value();

                    // Return a collection of allocations wich need to be saved.
                    const dirtyAllocations = allocations
                        .each(item => {
                            if (item.exist && item.attributes.value === 0) {
                                item.markForDeletion();
                            }
                        })
                        .filter(item => {
                            if (item.exist && item.markedForDeletion && item.attributes.value === 0) {
                                return true;
                            }

                            return item.isDirty() && item.attributes.value !== 0;
                        });

                    allocationsToSave = allocationsToSave.concat(dirtyAllocations.toArray());
                });

                emit('changed', allocationsToSave);
            }, {deep: true});

            return {
                everyBalanceTypes,
            };
        },
    });
</script>
