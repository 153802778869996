<script lang="ts" setup>
    import {computed, nextTick, onMounted, ref} from 'vue';
    import {isNil} from 'lodash-es';

    const props = withDefaults(
        defineProps<{
            value: Number | undefined,
            placeholder?: string,
            readonly?: boolean,
            disabled?: boolean,
            hasError?: boolean,
            autofocus?: boolean,
            variant?: string,
            size?: string,
            rounded?: string,
            align?: string,
            allowUndefined?: boolean,
            min?: number,
            max?: number,
        }>(), {
            placeholder: undefined,
            variant: 'border',
            size: 'default',
            rounded: 'both',
            align: 'left',
            allowUndefined: false,
            min: undefined,
            max: undefined,
        });

    const emit = defineEmits<{
        (e: 'input', value: number | undefined): void
        (e: 'change', value: number | undefined): void
    }>();

    const inputEl = ref<HTMLInputElement | null>(null);

    onMounted(() => {
        if (props.autofocus) {
            nextTick(() => {
                inputEl.value?.focus({
                    preventScroll: true,
                });
            });
        }
    });

    function onInput(event: Event) {
        emit('input', computeEventValue(event));
    }

    function onChange(event: Event) {
        emit('change', computeEventValue(event));
    }

    function computeEventValue(event: Event) {
        const target = event.target as HTMLInputElement;

        if (props.allowUndefined && target.value === '') {
            return undefined;
        }

        let value = Number(target.value);

        if ((!isNil(props.max) && value > props.max)) {
            // @ts-ignore
            inputEl.value!.value = props.max;
            value = props.max;
        }

        if ((!isNil(props.min) && value < props.min)) {
            // @ts-ignore
            inputEl.value!.value = props.min;
            value = props.min;
        }

        return value;
    }

    const styling = useStyling();

    function useStyling() {
        return computed(() => {
            const output = [] as any[];

            const variants = {
                'border': useBorderVariant(),
                'underline': useUnderlineVariant(),
                'underline-hover': useUnderlineHoverVariant(),
            };
            output.push(variants[props.variant]);

            const sizes = {
                default: 'MNumberInput--base',
                lg: 'MNumberInput--lg',
                sm: 'MNumberInput--sm',
                xs: 'MNumberInput--xs',
            };
            output.push(sizes[props.size]);

            const alignments = {
                left: 'tw-text-left',
                center: 'tw-text-center',
                right: 'tw-text-right',
            };
            output.push(alignments[props.align]);
            output.push('');

            return output;
        });
    }

    function useBorderVariant() {
        const output = [] as any[];

        const states = {
            default: 'MNumberInput--light',
            error: 'MNumberInput--light MNumberInput--has-error',
            readonly: 'MNumberInput--light MNumberInput--is-readonly',
        };

        output.push(selectState(states));

        switch (props.rounded) {
            case 'left':
                output.push('!tw-rounded-r-none');
                break;
            case 'right':
                output.push('!tw-rounded-l-none');
                break;
            case 'none':
                output.push('!tw-rounded-none');
                break;
        }

        return output;
    }

    function useUnderlineVariant(): string {
        const states = {
            default: 'tw-bg-transparent tw-border-gray-200 tw-border-b tw-border-dashed focus:tw-border-solid focus:tw-border-blue-500 tw-outline-0',
            error: 'tw-bg-transparent tw-border-red-500 tw-border-b focus:tw-border-red-500 tw-z-10',
            readonly: 'tw-bg-transparent tw-border-gray-200 tw-bg-gray-50 tw-border-b tw-opacity-75',
        };

        return selectState(states);
    }

    function useUnderlineHoverVariant(): string {
        const states = {
            default: 'tw-bg-transparent tw-border-transparent hover:tw-border-gray-200 tw-border-b focus:tw-border-blue-500',
            error: 'tw-bg-transparent tw-border-red-500 tw-border-b focus:tw-border-red-500 tw-z-10',
            readonly: 'tw-bg-transparent tw-border-gray-200 tw-bg-gray-50 tw-border-b tw-opacity-75',
        };

        return selectState(states);
    }

    function selectState(states: {
        default: string;
        readonly: string;
        error: string
    }): string {
        if (props.readonly) {
            return states['readonly'];
        }

        return states[props.hasError ? 'error' : 'default'];
    }
</script>

<template>
    <input
        ref="inputEl"
        class="MNumberInput"
        :class="styling"
        :disabled="disabled"
        inputmode="numeric"
        :max="max"
        :min="min"
        pattern="/d+"
        :placeholder="placeholder"
        :readonly="readonly"
        type="number"
        :value="value"
        @change="onChange"
        @input="onInput"
    >
</template>
