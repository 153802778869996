<template>
    <MModal
        :header-title="__('family_contract:new_contract')"
        :modal="modal"
        size="7xl"
    >
        <CTabMenu class="tw-mb-4">
            <CTabMenuItem
                :active="selectedTab === 1"
                @click="selectedTab = 1"
            >
                <FontAwesomeIcon
                    class="tw-mr-2"
                    icon="fa fa-pencil"
                />
                {{ __('common:terms') }}
            </CTabMenuItem>
            <CTabMenuItem
                v-if="contract.type === 'recurrent'"
                :active="selectedTab === 2"
                @click="selectedTab = 2"
            >
                <FontAwesomeIcon
                    class="tw-mr-2"
                    icon="fa fa-calendar"
                />
                {{ __('common:planning_one') }}
            </CTabMenuItem>
            <CTabMenuItem
                v-if="$can('update', 'invoices')"
                :active="selectedTab === 3"
                @click="selectedTab = 3"
            >
                <FontAwesomeIcon
                    class="tw-mr-2"
                    icon="fa fa-suitcase"
                />
                {{ __('common:resources') }}
            </CTabMenuItem>
            <CTabMenuItem
                v-if="$can('update', 'invoices')"
                :active="selectedTab === 4"
                @click="selectedTab = 4"
            >
                <FontAwesomeIcon
                    class="tw-mr-2"
                    icon="fa fa-wallet"
                />
                {{ __('common:billing') }}
            </CTabMenuItem>
            <CTabMenuItem
                v-if="contract.company && $can('update', 'invoices')"
                :active="selectedTab === 5"
                @click="selectedTab = 5"
            >
                <FontAwesomeIcon
                    class="tw-mr-2"
                    icon="fa fa-city"
                />
                {{ __('common:company_one') }}
                <MAlertBadge
                    class="tw-ml-2"
                    :visible="errors.company"
                />
            </CTabMenuItem>
            <CTabMenuItem
                v-if="$can('update', 'invoices')"
                :active="selectedTab === 6"
                @click="selectedTab = 6"
            >
                <FontAwesomeIcon
                    class="tw-mr-2"
                    icon="fa fa-university"
                />
                {{ __('common:sepa_direct_debit') }}
            </CTabMenuItem>
        </CTabMenu>
        <module-modalites
            v-if="selectedTab === 1"
            :contract="contract"
            :kid="kid"
            :nursery="nursery"
        />
        <module-planning
            v-if="selectedTab === 2"
            :contract="contract"
            :nursery="nursery"
        />
        <module-ressources
            v-if="selectedTab === 3"
            :contract="contract"
            :kid="kid"
            :nursery="nursery"
        />
        <module-tarifs
            v-if="selectedTab === 4"
            :cashier-payload="cashierPayload"
            :contract="contract"
            :kid="kid"
            :nursery="nursery"
            :user="user"
        />
        <module-company
            v-if="selectedTab === 5"
            :cashier-payload="cashierPayload"
            :contract="contract"
            :kid="kid"
            :nursery="nursery"
            @has-errors="errors.company = $event"
        />
        <module-sepa
            v-if="selectedTab === 6"
            :contract="contract"
            :kid="kid"
            :nursery="nursery"
            :user="user"
        />
        <template #footer-end="{hide}">
            <MButton
                variant="light"
                @click="hide"
            >
                {{ __('common:actions.cancel') }}
            </MButton>

            <MButton
                :disabled="hasErrors"
                :loading="loading"
                @click="save(1)"
            >
                <template #left-icons>
                    <FontAwesomeIcon icon="fas fa-file-pen"/>
                </template>
                {{ __('family_contract:save_in_draft') }}
            </MButton>
            <MButton
                :disabled="hasErrors"
                :loading="loading"
                variant="primary"
                @click="warnSave"
            >
                <template #left-icons>
                    <FontAwesomeIcon icon="fas fa-check"/>
                </template>
                {{ __('family_contract:confirm_contract_dots') }}
            </MButton>
        </template>
    </MModal>
</template>

<script>
    import Vue from 'vue';
    import moment from 'moment';
    import route from '@/modules/legacy/libs/ziggy';
    import useApi from '@/modules/app/composables/useApi';
    import swal from 'sweetalert2/dist/sweetalert2.js';
    import _head from 'lodash-es/head';
    import _forEach from 'lodash-es/forEach';
    import _cloneDeep from 'lodash-es/cloneDeep';
    import Modalites from './Modal/Modalites.vue';
    import Planning from './Modal/Planning.vue';
    import Ressources from './Modal/Ressources.vue';
    import Tarifs from './Modal/Facturation.vue';
    import Sepa from './Modal/Sepa.vue';
    import Company from './Modal/Company.vue';
    import LegacyContractIntegrationService from '@/modules/family/utils/kid/LegacyContractIntegrationService';
    import __ from '@/modules/app/utils/i18n-facade';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';
    import {Epoch} from '@meekohq/lumos';
    import MAlertBadge from '@/modules/meeko-ui/components/MAlertBadge.vue';

    export default {
        components: {
            MAlertBadge,
            'module-modalites': Modalites,
            'module-planning': Planning,
            'module-ressources': Ressources,
            'module-tarifs': Tarifs,
            'module-sepa': Sepa,
            'module-company': Company,
        },
        props: ['modal', 'kid', 'nursery', 'currentContract', 'user'],
        data: () => ({
            selectedTab: 1,
            contract: {},
            loading: false,
            cashierPayload: {
                companyOrderModel: undefined,
                familyOrderModel: undefined,
                familyEndpoints: [],
                companyEndpoints: [],
                familyModel: undefined,
            },
            errors: {
                company: false,
            },
        }),
        computed: {
            hasErrors() {
                return this.errors.company;
            },
        },
        mounted() {
            if (this.currentContract) {
                this.contract = this.currentContract;
            } else {
                this.contract = {
                    id: null,
                    started_at: Epoch.now().toFormat('yyyy-MM-dd'),
                    ended_at: Epoch.now().addMonths(1).toFormat('yyyy-MM-dd'),
                    recurrent_weeks: 1,
                    plannings: [],
                    exceptions: [],
                    type: 'recurrent',
                    draft: false,
                    first_family_member_id: null,
                    second_family_member_id: null,
                };
            }

            this.cashierPayload.companyOrderModel = undefined;
            this.cashierPayload.familyOrderModel = undefined;
            this.cashierPayload.familyModel = undefined;
            this.cashierPayload.familyEndpoints = [];
            this.cashierPayload.companyEndpoints = [];
            this.errors = {
                company: false,
            };
        },
        methods: {
            warnSave() {
                swal({
                    title: __('family_contract:validate_contract_ask'),
                    text: __('family_contract:validate_contract_warning'),
                    type: 'warning',
                    confirmButtonClass: 'btn btn-primary mr-2',
                    confirmButtonText: __('common:actions.validate'),
                    cancelButtonText: __('common:actions.cancel'),
                }).then(result => {
                    if (result.value) {
                        this.save(0);
                    }
                });
            },

            save(draft) {
                this.loading = true;
                if ((this.contract.temporaryFamilySepa && this.contract.temporaryFamilySepa.id === null) && (this.contract.temporaryCompanySepa && this.contract.temporaryCompanySepa.id === null)) {
                    this.saveSepa(error => {
                        if (error) {
                            this.loading = false;
                        } else {
                            this.saveSepa(error => {
                                if (error) {
                                    this.loading = false;
                                } else {
                                    this.save(draft);
                                }
                            }, this.contract.temporaryCompanySepa, true);
                        }
                    }, this.contract.temporaryFamilySepa);
                } else if (this.contract.temporaryFamilySepa && this.contract.temporaryFamilySepa.id === null) {
                    this.saveSepa(error => {
                        if (error) {
                            this.loading = false;
                        } else {
                            this.save(draft);
                        }
                    }, this.contract.temporaryFamilySepa);
                } else if (this.contract.temporaryCompanySepa && this.contract.temporaryCompanySepa.id === null) {
                    this.saveSepa(error => {
                        if (error) {
                            this.loading = false;
                        } else {
                            this.save(draft);
                        }
                    }, this.contract.temporaryCompanySepa, true);
                } else {
                    const data = _cloneDeep(this.contract);
                    data.draft = draft;

                    if (data.company_cycle === null || data.company_cycle === 0) {
                        data.company_cycle = 1;
                    }

                    if (this.contract.type === 'recurrent') {
                        data.exceptions.forEach(ex => {
                            // Get all dates between start and end
                            const diffDates = this.getDatesBetween(ex.started_at, ex.ended_at);

                            // Loop in those dates and add to plannings
                            diffDates.forEach(date => {
                                const exception = {
                                    day: null,
                                    type: 'tailored',
                                    week: null,
                                    note: ex.note,
                                    skip: 0,
                                };

                                const weekDiff = moment(date).week() - moment(this.contract.started_at).week() + 1;
                                const yearDiff = moment(date).year() - moment(this.contract.started_at).year();
                                exception.week = weekDiff + (yearDiff * 52);
                                exception.day = moment(date).locale('en').format('ddd').toLowerCase();
                                if (exception.week >= 1) {
                                    data.plannings.push(exception);
                                }
                            });
                        });
                    } else {
                        data.plannings = [];
                    }

                    if (data.mode === 'manuel') {
                        data.cafpro_resources = null;
                    }

                    data.nursery_id = this.nursery.id;
                    data.kid_id = this.kid.id;

                    useApi().legacy.post(route('kid.contracts.store'), data).then(async response => {
                        const contract = response.data;

                        const contractExtension = new LegacyContractIntegrationService(contract, this.cashierPayload);
                        await contractExtension.postCreate(response);

                        if (data.draft) {
                            useNotification().success(__('family_contract:contract_save_in_draft_successfully'));
                        } else {
                            useNotification().success(__('family_contract:contract_validated'));
                        }
                        await this.saveBillingConfig(data, contract);
                        this.loading = false;
                        this.kid.contracts.push(contract);

                        this.modal.hide();
                    }).catch(error => {
                        this.loading = false;
                        if (error?.response?.status === 422) {
                            _forEach(error.response.data.errors, value => {
                                useNotification().error(_head(value));
                            });
                        } else {
                            useNotification().error(error);
                        }
                    });
                }
            },

            saveBillingConfig(contract, responseContract) {
                return new Promise((resolve, reject) => {
                    const data = _cloneDeep(contract.config);

                    if (responseContract.id) {
                        data.contract_id = responseContract.id;
                    }

                    useApi().legacy.post(route('billingConfigs.store'), data)
                        .then(response => {
                            Vue.set(this.contract, 'config', response.data);
                            Vue.set(responseContract, 'config', response.data);
                            const index = this.kid.contracts.indexOf(responseContract);
                            Vue.set(this.kid.contracts, index, responseContract);
                            resolve();
                        }).catch(error => {
                            if (error?.response?.status === 422) {
                                _forEach(error.response.data.errors, value => {
                                    useNotification().error(_head(value));
                                });
                            } else {
                                useNotification().error(error);
                            }
                            reject();
                        });
                });
            },

            saveSepa(callback, sepa, company = false) {
                useApi().legacy.post(route('sepas.store'), sepa)
                    .then(response => {
                        if (company) {
                            this.contract.company_sepa_mandate_id = response.data.id;
                            this.contract.temporaryCompanySepa = null;
                        } else {
                            this.contract.sepa_mandate_id = response.data.id;
                            this.contract.temporaryFamilySepa = null;
                        }
                        useApi().legacy.post(route('nurseries.sepas.attach', {
                            nurseries: this.nursery.id,
                            sepa: response.data.id,
                        })).then(() => {
                            callback();
                        }).catch(error => {
                            this.loading = false;
                            if (error?.response?.status === 422) {
                                _forEach(error.response.data.errors, value => {
                                    useNotification().error(_head(value));
                                });
                            } else {
                                useNotification().error(error);
                            }
                            callback(error);
                        });
                    }).catch(error => {
                        this.loading = false;
                        if (error?.response?.status === 422) {
                            _forEach(error.response.data.errors, value => {
                                useNotification().error(_head(value));
                            });
                        } else {
                            useNotification().error(error);
                        }
                        callback(error);
                    });
            },

            getDatesBetween(startDate, endDate) {
                const dates = [];

                const currDate = moment(startDate).startOf('day');
                const lastDate = moment(endDate).startOf('day');

                dates.push(currDate.clone().toDate());
                while (currDate.add(1, 'days').diff(lastDate) <= 0) {
                    dates.push(currDate.clone().toDate());
                }

                return dates;
            },
        },
    };
</script>

