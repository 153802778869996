<script setup lang="ts">
    import {ref, toRef, watch} from 'vue';
    import type {Epoch} from '@meekohq/lumos';
    import type {DateTimeModifierSingle} from '@/modules/meeko-ui/composables/useDateTimeModifiers';
    import useMDatePickertTime from '@/modules/meeko-ui/composables/useMDatePickertTime';

    const props = withDefaults(defineProps<{
        value: Epoch;
        modifier?: DateTimeModifierSingle;
        disabled?: boolean;
        hasError?: boolean;
        autofocus?: boolean;
    }>(), {
        modifier: undefined,
        disabled: false,
        hasError: false,
        autofocus: false,
    });

    const emit = defineEmits<{
        (e: 'input', value: Epoch): void
    }>();

    const {
        internalValue,
        hour,
        setHour,
        addHours,
        subHours,
        minute,
        setMinute,
        addMinutes,
        subMinutes,
    } = useMDatePickertTime(toRef(props, 'value'));

    const hourEl = ref();
    const minuteEl = ref();

    watch(internalValue, () => {
        emit('input', props.modifier ? props.modifier(internalValue.value) : internalValue.value);
    }, {deep: true});
</script>

<template>
    <div class="tw-flex tw-gap-0.5">
        <div class="tw-flex tw-flex-col tw-gap-2">
            <div
                class="MTimePicker__minute-button"
                @click="addHours(1)"
            >
                +1
            </div>
            <div>
                <MNumberInput
                    ref="hourEl"
                    :autofocus="autofocus"
                    class="!tw-w-10 tw-text-center tw-font-semibold"
                    :max="23"
                    :min="0"
                    onfocus="this.select()"
                    :value="hour"
                    variant="underline"
                    @input="setHour"
                />
            </div>
            <div
                class="MTimePicker__minute-button"
                @click="subHours(1)"
            >
                -1
            </div>
        </div>
        <div class="tw-flex tw-items-center tw-text-gray-500 tw-text-sm tw-font-semibold">
            {{ __('common:hour_lower_short') }}
        </div>
        <div class="tw-flex tw-flex-col tw-justify-between">
            <div
                class="MTimePicker__minute-button"
                @click="addMinutes(5)"
            >
                +5
            </div>
            <div>
                <MNumberInput
                    ref="minuteEl"
                    class="!tw-w-10 tw-text-center tw-font-semibold"
                    :max="59"
                    :min="0"
                    onfocus="this.select()"
                    :value="minute"
                    variant="underline"
                    @input="setMinute"
                />
            </div>
            <div
                class="MTimePicker__minute-button"
                @click="subMinutes(5)"
            >
                -5
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
    .MTimePicker__button {
        @apply tw-px-2 tw-py-1 tw-rounded tw-text-primary-500 tw-text-sm tw-font-semibold tw-cursor-pointer tw-text-center;
        @apply tw-transition-all hover:tw-bg-primary-100 hover:tw-text-primary-600 tw-capitalize tw-select-none;
    }

    .MTimePicker__minute-button {
        @apply tw-py-1 tw-rounded tw-text-gray-500 tw-text-center tw-font-display tw-text-sm tw-cursor-pointer tw-select-none;
        @apply tw-transition-all hover:tw-bg-primary-100 hover:tw-text-primary-600 tw-bg-gray-100;
    }
</style>
