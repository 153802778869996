<script lang="ts">
    import type {ComponentPublicInstance, PropType} from 'vue';
    import {computed, defineComponent, ref, watch} from 'vue';
    import {collect, Epoch, MqlUnprocessableEntityError} from '@meekohq/lumos';
    import __ from '@/modules/app/utils/i18n-facade';
    import useAuth from '@/modules/app/composables/useAuth';
    import useManager from '@/modules/app/composables/useManager';
    import useAbility from '@/modules/app/composables/useAbility';
    import {mapPaymentToAllocation} from '@/modules/cashier/composables/issuing/transaction/useTransactionState';
    import useRejectRestoreTransaction
        from '@/modules/cashier/transaction/infrastructure/components/useRejectRestoreTransaction';
    import type TransactionModel from '@/modules/cashier/transaction/domain/TransactionModel';
    import type OrganizationModel from '@/modules/organization/models/OrganizationModel';
    import DownloadTemplate from '@/modules/legacy/components/Modules/DownloadTemplate.vue';
    import AlertModal from '@/modules/app/components/organisms/AlertModal.vue';
    import TransactionModalEdit from '@/modules/cashier/transaction/infrastructure/components/TransactionModalEdit.vue';
    import type PaymentModel from '@/modules/cashier/models/PaymentModel';
    import useMagicModal from '@/modules/app/composables/useMagicModal';
    import useModal from '@/modules/app/composables/useModal';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';
    import useError from '@/modules/app/composables/useError';
    import useEpoch from '@/modules/app/composables/useEpoch';
    import {useElementSize} from '@vueuse/core';
    import useFormatNumber from '@/modules/cashier/composables/useFormatNumber';
    import MProgressBar from '@/modules/meeko-ui/components/MProgressBar.vue';
    import type {LegacyInvoiceType} from '@/modules/cashier/utils/billing/invoice/LegacyInvoiceType';

    export default defineComponent({
        components: {
            MProgressBar,
            AlertModal,
            DownloadTemplate,
        },
        props: {
            invoice: {
                type: Object as PropType<LegacyInvoiceType>,
                required: true,
            },
            transaction: {
                type: Object as PropType<TransactionModel>,
                required: true,
            },
            organization: {
                type: Object as PropType<OrganizationModel>,
                required: true,
            },
            payment: {
                type: Object as PropType<PaymentModel>,
                required: false,
                default: undefined,
            },
            editingAllocations: {
                type: Boolean,
                default: false,
            },
        },
        setup(props, {emit}) {
            const user = useAuth().user;
            const legacyNursery = useManager().legacyNursery;

            const actionsDropdown = ref();
            const statusReference = ref();

            const editButtonRef = ref<ComponentPublicInstance>();
            const deleteButtonRef = ref<ComponentPublicInstance>();
            const rejectButtonRef = ref<ComponentPublicInstance>();
            const restoreButtonRef = ref<ComponentPublicInstance>();

            const {format} = useFormatNumber(props.transaction.computed.currency_iso_code);

            const {can} = useAbility();

            const allocations = computed(() => {
                const data = collect();

                // Transaction can have multiple allocation on same payment
                props.transaction.allocations.each(allocation => {
                    data.push(mapPaymentToAllocation(allocation));
                });

                return data;
            });

            const isRejectAlertModalVisible = ref(false);

            const transactions = computed(() => collect(props.transaction));
            const failureDate = ref(new Epoch().toISOString());

            const transactionError = useError();

            const {fromISOString} = useEpoch();

            const {bus, reject, restore, loading} = useRejectRestoreTransaction();

            bus.on('restored', () => emit('restored', props.transaction));
            bus.on('rejected', () => emit('rejected', props.transaction));
            bus.on('error', error => {
                // TODO: Remplacer par une erreur métier et non MQL
                if (error instanceof MqlUnprocessableEntityError && error.operation.result.status === 422) {
                    useNotification().error(__('billing_transaction:errors.restore_transaction', {
                        context: props.transaction.isCredit ? 'credit' : 'debit',
                    }));
                } else {
                    useNotification().error(__('common:errors.generic'));
                }
            });

            watch(failureDate, value => {
                const transactionDate = fromISOString(props.transaction.attributes.date as string);
                const safeFailureDate = value ? fromISOString(value as string) : undefined;

                // We check the failure date set to the end of the day to avoid any timezone issue
                if (!safeFailureDate || transactionDate.greaterThanOrEqualTo(safeFailureDate.endOfDay())) {
                    transactionError.add('failureDate', 'failure-date-must-be-future', __('billing_transaction:errors.failure_date_cannot_be_before_transaction_date'));
                } else {
                    transactionError.remove('failureDate', 'failure-date-must-be-future');
                }
            });

            const editModal = useModal({
                component: TransactionModalEdit,
                props: {
                    canSelectCustomer: false,
                    invoice: props.invoice,
                    payment: props.payment,
                    transactionId: props.transaction.getKey(),
                },
                listeners: modal => ({
                    updated(event) {
                        emit('updated', event);
                        modal.hide();
                    },
                }),
            });

            function onEditButtonClicked() {
                editModal.show();
            }

            async function onDeleteButtonClicked() {
                await useMagicModal().deleteConfirmationModal({
                    text: __('billing_transaction:delete_transaction_ask'),
                    onConfirm: async () => {
                        loading.value = true;
                        await props.transaction.delete();
                        emit('deleted', props.transaction);
                        loading.value = false;
                    },
                });
            }

            async function onRestoreButtonClicked() {
                await useMagicModal().confirmationModal({
                    text: __('billing_transaction:ask_restore_transaction', {
                        context: props.transaction.isCredit ? 'credit' : 'debit',
                    }),
                    onConfirm: () => restore(transactions.value),
                });
            }

            function handleReject() {
                reject(transactions.value, Epoch.fromISOString(failureDate.value));
            }

            function onRejectButtonClicked() {
                openRejectionModal();
            }

            function openRejectionModal() {
                failureDate.value = new Epoch().toISOString();
                isRejectAlertModalVisible.value = true;
            }

            function cancelRejection() {
                failureDate.value = new Epoch().toISOString();
            }

            const paymentMethodName = computed(() => props.transaction.paymentMethod().value()?.attributes.name);

            const transactionListItemEl = ref(null);
            const {width} = useElementSize(transactionListItemEl);

            return {
                width,
                transactionListItemEl,
                paymentMethodName,
                actionsDropdown,
                editButtonRef,
                deleteButtonRef,
                rejectButtonRef,
                restoreButtonRef,
                isRejectAlertModalVisible,
                allocations,
                legacyNursery,
                user,
                loading,
                failureDate,
                can,
                openRejectionModal,
                cancelRejection,
                reject,
                restore,
                Epoch,
                onEditButtonClicked,
                onDeleteButtonClicked,
                onRestoreButtonClicked,
                onRejectButtonClicked,
                statusReference,
                transactionError,
                handleReject,
                format,
            };
        },
    });
</script>

<template>
    <div class="PaymentAllocationsListItemTransaction">
        <div class="PaymentAllocationsListItemTransaction__column-header">
            <MTooltip
                v-if="transaction.isFailed"
                :label="__('billing_transaction:failed_date', {
                    date: Epoch.fromISOString(transaction.attributes.failure_date).toLocaleString(Epoch.presets.DATE_SHORT),
                })"
                placement="top"
            >
                <FontAwesomeIcon
                    class="tw-text-danger-600 tw-text-3xl"
                    fixed-width
                    icon="fas fa-reply"
                />
            </MTooltip>
            <FontAwesomeIcon
                v-else
                class="tw-text-green-500 tw-text-3xl"
                fixed-width
                icon="fa fa-arrow-up-right"
            />
            <div class="tw-min-w-0">
                <MHeading
                    class="tw-truncate"
                    :class="{'tw-line-through': transaction.isFailed}"
                    level="h4"
                >
                    {{ paymentMethodName }}
                </MHeading>
                <MHeading
                    :class="{'tw-line-through': transaction.isFailed}"
                    level="h2"
                >
                    {{ format(transaction.attributes.amount) }}
                </MHeading>
            </div>
        </div>
        <div class="PaymentAllocationsListItemTransaction__column-actions">
            <MMenu>
                <MMenuButton>
                    <MTooltip
                        :hoverable="editingAllocations"
                        :label="__('billing_transaction:save_breakdowns_first')"
                        placement="top"
                    >
                        <MButton
                            :disabled="editingAllocations"
                            icon-ellipsis
                            variant="ghost"
                        />
                    </MTooltip>
                </MMenuButton>
                <MMenuItems>
                    <DownloadTemplate
                        as="MMenuItem"
                        class="tw-w-full"
                        custom-class="tw-w-full"
                        doc-type="transaction"
                        :item="transaction"
                        :nursery="legacyNursery"
                        :user="user"
                    />
                    <MMenuItem
                        v-if="can('update', 'transactions')"
                        ref="editButtonRef"
                        :label="__('common:actions.update')"
                        @click="onEditButtonClicked"
                    >
                        <template #icon>
                            <FontAwesomeIcon icon="fa fa-pen"/>
                        </template>
                    </MMenuItem>
                    <MMenuItem
                        v-if="can('update', 'transactions') && !transaction.isFailed"
                        ref="rejectButtonRef"
                        :label="__('common:actions.reject')"
                        variant="danger"
                        @click="onRejectButtonClicked"
                    >
                        <template #icon>
                            <FontAwesomeIcon icon="fa fa-reply"/>
                        </template>
                    </MMenuItem>
                    <MMenuItem
                        v-if="can('update', 'transactions') && transaction.isFailed"
                        ref="restoreButtonRef"
                        :label="__('billing_transaction:actions.dont_reject')"
                        @click="onRestoreButtonClicked"
                    >
                        <template #icon>
                            <FontAwesomeIcon icon="fa fa-check"/>
                        </template>
                    </MMenuItem>
                    <MMenuItem
                        v-if="can('delete', 'transactions')"
                        ref="deleteButtonRef"
                        :label="__('common:actions.delete_dots')"
                        variant="danger"
                        @click="onDeleteButtonClicked"
                    >
                        <template #icon>
                            <FontAwesomeIcon icon="fa fa-trash"/>
                        </template>
                    </MMenuItem>
                </MMenuItems>
            </MMenu>
            <AlertModal
                v-if="isRejectAlertModalVisible"
                :confirm-button-disabled="transactionError.has('failureDate')"
                :confirm-text="__('common:actions.reject')"
                :loading="loading"
                :title="__('billing_transaction:reject_transaction', {
                    context: transaction.isCredit ? 'credit' : 'debit',
                })"
                @canceled="cancelRejection"
                @confirmed="handleReject"
                @hidden="isRejectAlertModalVisible = false"
            >
                {{
                    __('billing_transaction:ask_reject_transaction', {
                        context: transaction.isCredit ? 'credit' : 'debit',
                    })
                }}
                <CFormGroup class="tw-mt-4">
                    <CLabel>
                        {{ __('billing_transaction:failed_at') }}
                    </CLabel>
                    <CFormDatepicker
                        v-model="failureDate"
                        button-class="tw-w-full"
                        :has-error="transactionError.has('failureDate')"
                    />
                    <CFormErrorMessageList :errors="transactionError.get('failureDate')"/>
                </CFormGroup>
            </AlertModal>
        </div>
        <div class="PaymentAllocationsListItemTransaction__column-progress-bar">
            <div class="PaymentAllocationsListItemTransaction__progress-bar-label">
                {{ __('billing_transaction:remaining_amount_to_allocate', {value: format(transaction.computed.remaining_to_distribute_amount)}) }}
            </div>
            <MProgressBar
                :remaining="transaction.computed.remaining_to_distribute_amount"
                :total="transaction.attributes.amount"
            />
        </div>
        <div class="PaymentAllocationsListItemTransaction__column-informations">
            <div class="PaymentAllocationsListItemTransaction__information">
                <FontAwesomeIcon
                    fixed-width
                    icon="fad fa-calendar"
                />
                {{ Epoch.fromISOString(transaction.attributes.date).toLocaleString(Epoch.presets.DATE_SHORT) }}
            </div>
            <div
                v-if="transaction.attributes.reference"
                class="PaymentAllocationsListItemTransaction__information"
            >
                <FontAwesomeIcon
                    fixed-width
                    icon="fad fa-receipt"
                />
                {{ transaction.attributes.reference }}
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    .PaymentAllocationsListItemTransaction {
        @apply tw-grid md:tw-grid-cols-24 tw-grid-cols-20 tw-items-center tw-gap-3;
    }

    .PaymentAllocationsListItemTransaction__column-header {
        @apply md:tw-col-span-9 tw-col-span-16;
        @apply tw-flex tw-gap-3 tw-items-center;
    }

    .PaymentAllocationsListItemTransaction__column-actions {
        @apply md:tw-col-span-2 tw-col-span-4;
        @apply md:tw-order-last;
        @apply tw-text-end;
    }

    .PaymentAllocationsListItemTransaction__column-progress-bar {
        @apply md:tw-col-span-7 tw-col-span-10;
    }

    .PaymentAllocationsListItemTransaction__progress-bar-label {
        @apply tw-text-sm tw-text-gray-500 tw-mb-0.5;
    }

    .PaymentAllocationsListItemTransaction__column-informations {
        @apply md:tw-col-span-6 tw-col-span-10;
    }

    .PaymentAllocationsListItemTransaction__information {
        @apply tw-text-gray-500 tw-text-sm tw-truncate;
    }
</style>
