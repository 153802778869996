export const Types = {
    Auth: Symbol('Auth'),
    Api: Symbol('Api'),
    LegacyApi: Symbol('LegacyApi'),
    Broadcast: Symbol('Broadcast'),
    Gate: Symbol('Gate'),
    GetPaymentRefundablesUseCase: Symbol('GetPaymentRefundablesUseCase'),
    PaginateTransactionUseCase: Symbol('PaginateTransactionUseCase'),
    MassDeleteTransactionsByFiltersUseCase: Symbol('MassDeleteTransactionsByFiltersUseCase'),
    RejectAndRestoreTransactionsUseCase: Symbol('RejectAndRestoreTransactionsUseCase'),
    SumTransactionsByCurrenciesUseCase: Symbol('SumTransactionsByCurrenciesUseCase'),
    ApplyTemplateUseCase: Symbol('ApplyTemplateUseCase'),
    GetTasksUseCase: Symbol('GetTasksUseCase'),
    SaveRefundablesUseCase: Symbol('SaveRefundablesUseCase'),
    AllocationAggregateUseCase: Symbol('AllocationAggregateUseCase'),
    GetAllocationsSuggestionsForPaymentUseCase: Symbol('GetAllocationsSuggestionsForPaymentUseCase'),
    GetAllocationsForInvoiceUseCase: Symbol('GetAllocationsForInvoiceUseCase'),
    GetAllocationsForCreditNoteUseCase: Symbol('GetAllocationsForCreditNoteUseCase'),
    DetachCreditNoteUseCase: Symbol('DetachCreditNoteUseCase'),
    SaveContractUseCase: Symbol('SaveContractUseCase'),
    GetOverlappingContractsUseCase: Symbol('GetOverlappingContractsUseCase'),

    // Use Cases of module auth
    SudoModeUseCase: Symbol('SudoModeUseCase'),
    GeneratePersonalAccessTokenUseCase: Symbol('GeneratePersonalAccessTokenUseCase'),
    PaginatePersonalAccessTokenUseCase: Symbol('PaginatePersonalAccessTokenUseCase'),
    UpdatePersonalAccessTokenUseCase: Symbol('UpdatePersonalAccessTokenUseCase'),
    DeletePersonalAccessTokenUseCase: Symbol('DeletePersonalAccessTokenUseCase'),
};
