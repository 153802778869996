import type {SerializerType} from '@/modules/app/composables/useMagicFilter';
import {toNumber, toString} from 'lodash-es';

export default {
    read: async (value: string) => {
        return toNumber(value);
    },
    write: (value: number) => {
        return toString(value);
    },
} as SerializerType;
