<template>
    <CCard
        body-size="none"
        :border="false"
        :shadow="true"
    >
        <VerticalNavWithContent>
            <template #menu>
                <CVerticalNavigationWrapper>
                    <CVerticalNavigationItem :to="{name: 'humanresources/settings.calendar.eventType'}">
                        {{ __('hr:event_type') }}
                    </CVerticalNavigationItem>
                    <CVerticalNavigationItem
                        v-if="$can('settings', 'staffs_balances')"
                        :to="{name: 'humanresources/settings.calendar.balanceType'}"
                    >
                        {{ __('common:balances') }}
                    </CVerticalNavigationItem>
                </CVerticalNavigationWrapper>
            </template>
            <router-view/>
        </VerticalNavWithContent>
    </CCard>
</template>

<script lang="ts">
    import {defineComponent} from 'vue';
    import VerticalNavWithContent from '@/modules/app/components/layouts/VerticalNavWithContent.vue';

    export default defineComponent({
        components: {VerticalNavWithContent},
        props: {},
        setup() {
            return {};
        },
    });
</script>
