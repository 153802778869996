<template>
    <Component
        :is="wrapper"
        ref="wrapperRef"
        v-bind="wrapperProps"
        @shown="onShown"
    >
        <slot
            v-if="!alwaysOpen"
            :is-array="Array.isArray(value)"
            name="trigger"
            :toggle="toggle"
            :value="value"
        >
            <CButton
                align="left"
                :class="buttonClass"
                :disabled="buttonDisabled"
                :icon-sort="true"
                :size="buttonSize"
                :variant="buttonVariant"
                @click="toggle"
            >
                <template v-if="multi">
                    <slot
                        v-if="value.length"
                        :lang-var="__('components:count_item', {count: value.length})"
                        name="button-text-multi"
                        :values="value"
                    >
                        VALUE
                    </slot>
                    <slot
                        v-else
                        name="button-text-empty"
                    >
                        {{ fallBackText }}
                    </slot>
                </template>
                <template v-else>
                    <slot
                        v-if="value"
                        name="button-text"
                        :value="value"
                    >
                        VALUE
                    </slot>
                    <slot
                        v-else
                        name="button-text-empty"
                    >
                        {{ fallBackText }}
                    </slot>
                </template>
            </CButton>
        </slot>
        <template #content>
            <CFinder
                ref="finder"
                :best-match="bestMatch"
                :disabled="disabled"
                :display-select-all-options="displaySelectAllOptions"
                :has-more-result="hasMore && !loading"
                :has-unselect="hasUnselect"
                :hide-fallback-if-no-result="hideFallbackIfNoResult"
                :hide-selected-option="hideSelectedOption"
                :is-loading="loading"
                :multi="multi"
                :multi-minimum="multiMinimum"
                :options="prepareOptions"
                ref-path="id"
                :search-bar="searchBar"
                :unselect-value="unselectValue"
                :value="value"
                @bestMatch="$emit('bestMatch', $event)"
                @fallback="$emit('fallback')"
                @input="onChange"
                @keydown.tab="hide"
                @search="updateSearchValue"
                @wantMore="fetchNextModels"
            >
                <template
                    v-if="$scopedSlots.bestMatch"
                    #bestMatch="{searchValue}"
                >
                    <slot
                        :best-match="bestMatch"
                        name="bestMatch"
                        :search-value="searchValue"
                    />
                </template>
                <template
                    v-if="$scopedSlots.fallback"
                    #fallback="{searchValue}"
                >
                    <slot
                        name="fallback"
                        :search-value="searchValue"
                    />
                </template>

                <template #unselect-item>
                    <slot name="unselect-item"/>
                </template>

                <template #unselect-text>
                    <slot name="unselect-text"/>
                </template>

                <template #default="{option, searchValue}">
                    <slot
                        :option="option"
                        :search-value="searchValue"
                    />
                </template>

                <template #empty-result>
                    <slot name="empty-result"/>
                </template>
            </CFinder>
        </template>
    </Component>
</template>

<script lang="ts">
    import type {Model, QueryBuilder} from '@meekohq/lumos';
    import type {PropType} from 'vue';
    import {computed, defineComponent, ref, watch} from 'vue';
    import {debounce, isArray} from 'lodash-es';
    import __ from '@/modules/app/utils/i18n-facade';
    import {useRefHistory} from '@vueuse/core';

    export default defineComponent({
        props: {
            value: {},
            builder: {type: Object as PropType<QueryBuilder<any>>, required: true},
            queryParams: {type: Object as PropType<Record<string, unknown>>, default: undefined, required: false},
            bestMatchBuilder: {type: Object as PropType<QueryBuilder<any>>, required: false},
            optionsCallback: {
                type: Function as PropType<(models: Model[]) => any[]>,
                default: undefined,
                required: false,
            },
            multi: {type: Boolean, default: false},
            multiMinimum: {type: [Number, String], default: 0},
            disabled: {type: Boolean, default: false},
            hasUnselect: {type: Boolean, default: false},
            fallBackText: {type: String, default: () => __('common:actions.select')},
            searchBar: {type: Boolean, default: true},
            hideSelectedOption: {type: Boolean, default: false},
            displaySelectAllOptions: {type: Boolean, default: true},
            hideFallbackIfNoResult: {type: Boolean, default: false},
            unselectValue: {},
            wrapperClass: {},
            buttonClass: {},
            buttonVariant: {},
            buttonSize: {},
            alwaysOpen: {type: Boolean, default: false},
            preventHideOnClick: {type: Boolean as PropType<boolean>, default: false},
            stopPropagation: {type: Boolean, default: false},
            preventDefault: {type: Boolean, default: false},
            buttonDisabled: {type: Boolean as PropType<boolean>, default: false},
            wrapper: {type: String as PropType<'CPopover' | 'ResourceFinderWrapper'>, default: 'CPopover'},
        },
        emits: ['input', 'search', 'bestMatch', 'fallback'],
        setup(props, {emit}) {
            const finderRef = ref();
            const wrapperRef = ref();

            const models = ref<Model[]>([]);
            const bestMatch = ref();
            const currentPage = ref(1);
            const hasMore = ref(true);
            const loading = ref(false);

            const {history: wrapperRefHistory} = useRefHistory(wrapperRef);

            watch(wrapperRefHistory, value => {
                // Solution de backup pour l'usage des finders en compo dynamique avec un alwaysOpen
                // On attend que le composant soit monté pour l'ouvrir car il est asynchrone
                if (value.length > 1 && props.alwaysOpen && wrapperRef.value?.visible === false) {
                    show();
                }
            });

            const wrapperProps = computed(() => {
                if (props.wrapper === 'CPopover') {
                    return {
                        'variant': 'dropdown',
                        'clickable': false,
                        'no-padding': true,
                        'prevent-hide-on-click': props.preventHideOnClick,
                        'stop-propagation': props.stopPropagation,
                        'prevent-default': props.preventDefault,
                        'content-class': ['tw-min-w-56'],
                    };
                } else {
                    return {
                        class: [props.wrapperClass, 'tw-min-w-56'],
                    };
                }
            });

            const prepareOptions = computed(() => {
                if (props.optionsCallback) {
                    return props.optionsCallback(models.value as any);
                }

                return models.value;
            });

            const toggle = function() {
                wrapperRef.value?.toggle();
            };

            const updateSearchValue = function(value) {
                emit('search', value);
                fetchModelsDebounced();
            };

            const fetchModels = function(page = 1): void {
                loading.value = true;

                const builder = props.builder;

                if (bestMatch.value) {
                    builder.where('id', '!=', bestMatch.value.id);
                }

                builder.paginate(100, page, true, {params: props.queryParams}).then(paginator => {
                    loading.value = false;

                    currentPage.value = paginator.currentPage();
                    hasMore.value = paginator.hasMorePages();

                    if (paginator.onFirstPage()) {
                        models.value = paginator.items().all();
                    } else {
                        models.value = models.value.concat(paginator.items().all());
                    }
                });
            };

            const fetchModelsDebounced = debounce(fetchModels, 300);

            const fetchNextModels = function() {
                fetchModels(currentPage.value + 1);
            };

            const onChange = function(values: any | any[]) {
                if (!props.multi && !props.alwaysOpen) {
                    wrapperRef.value?.hide();
                }

                emit('input', values);
            };

            const hide = function() {
                wrapperRef.value?.hide();
            };

            function show() {
                wrapperRef.value?.show();
            }

            const onShown = function() {
                emit('search', '');
                if (props.bestMatchBuilder) {
                    props.bestMatchBuilder.first().then(value => {
                        bestMatch.value = value;
                        fetchModels();
                    });
                } else {
                    fetchModels();
                }
            };

            return {
                finderRef,
                wrapperRef,
                wrapperProps,
                toggle,
                hide,
                show,
                hasMore,
                prepareOptions,
                onChange,
                updateSearchValue,
                fetchNextModels,
                loading,
                isArray,
                onShown,
                bestMatch,
            };
        },
    });
</script>
