<template>
    <CCard
        :border="false"
        class="CustomerHero"
        :shadow="true"
    >
        <template #header>
            <CustomerHeroHeader
                :customer="customer"
                :legacy-nursery="legacyNursery"
                :legacy-user="legacyUser"
                :loading="loading"
                @updated="$emit('updated')"
            />
        </template>
        <div class="CustomerHero__container">
            <CustomerHeroProperties
                v-if="customer && !loading"
                class="tw-shrink-0"
                :customer-model="customer"
                :family-model-collection="familyModelCollection"
            />
            <div class="CustomerHero__stats-container">
                <CustomerHeroStats
                    v-if="!loading && customer"
                    :customer="customer"
                />
            </div>
        </div>
    </CCard>
</template>

<script lang="ts">
    import type {PropType} from 'vue';
    import {defineComponent} from 'vue';
    import type CustomerModel from '@/modules/cashier/models/CustomerModel';
    import type FamilyModel from '@/modules/family/models/FamilyModel';
    import CustomerHeroProperties
        from '@/modules/cashier/components/core/customer/CustomerHero/molecules/CustomerHeroProperties.vue';
    import CustomerHeroHeader
        from '@/modules/cashier/components/core/customer/CustomerHero/molecules/CustomerHeroHeader.vue';
    import CustomerHeroStats
        from '@/modules/cashier/components/core/customer/CustomerHero/molecules/CustomerHeroStats.vue';

    export default defineComponent({
        components: {CustomerHeroStats, CustomerHeroHeader, CustomerHeroProperties},
        props: {
            customer: {type: Object as PropType<CustomerModel | undefined>, default: undefined},
            loading: {type: Boolean, default: false, required: true},
            familyModelCollection: {type: Array as PropType<FamilyModel[]>, required: true},
            legacyNursery: {type: Object, required: true},
            legacyUser: {type: Object, required: true},
        },
    });
</script>

<style lang="scss" scoped>
    .CustomerHero {
        @apply tw-border-t tw-border-orange-400;
    }

    .CustomerHero__container {
        @apply tw-flex md:tw-flex-row tw-flex-col tw-justify-between tw-gap-8;
    }

    .CustomerHero__stats-container {
        @apply md:tw-w-auto tw-w-full tw-flex tw-flex-col tw-gap-4;
        @apply tw-shrink-0;
    }
</style>

