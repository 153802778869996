import type {QueryBuilder} from '@meekohq/lumos';
import {collect, Epoch, Model} from '@meekohq/lumos';
import type PaymentStatusValue from '@/modules/cashier/utils/billing/payment/PaymentStatusValue';
import TenantModel from '@/modules/cashier/models/TenantModel';
import CustomerModel from '@/modules/cashier/models/CustomerModel';
import CurrencyModel from '@/modules/cashier/models/CurrencyModel';
import AllocationModel from '@/modules/cashier/models/AllocationModel';
import InvoiceModel from '@/modules/cashier/models/InvoiceModel';
import TransactionModel from '@/modules/cashier/transaction/domain/TransactionModel';
import useManager from '@/modules/app/composables/useManager';

export default class PaymentModel extends Model {
    public type = 'cashier/payments';

    public attributes: {
        id: string,
        tenant_id: string | undefined,
        customer_id: string | undefined,
        currency_id: string | undefined,
        date: string | undefined,
        due_date: string | undefined,
        amount: number | undefined,
        status: PaymentStatusValue | undefined,
        refund: boolean | undefined,
    } = {
            id: this.uuid(),
            tenant_id: undefined,
            customer_id: undefined,
            currency_id: undefined,
            date: undefined,
            due_date: undefined,
            amount: undefined,
            status: undefined,
            refund: undefined,
        };

    public computed: {
        remaining_amount: number,
        paid_amount: number,
        currency_iso_code: string
    } = {
            remaining_amount: 0,
            paid_amount: 0,
            currency_iso_code: '',
        };

    get invoicesRelation() {
        return this.attributes.refund
            ? this.creditNotes().value()
            : this.invoices().value();
    }

    get isRefund() {
        return this.attributes.refund;
    }

    get date(): Epoch | undefined {
        return this.attributes.date ? Epoch.fromISOString(this.attributes.date) : undefined;
    }

    public static belongingToAuthUserScope(query: QueryBuilder<PaymentModel>) {
        const tenantIds = collect(useManager().activeOrganization.value.tenants().value()).keyBy('id').keys().all();
        query.whereIn('id', tenantIds);
    }

    tenant() {
        return this.belongsTo('tenant', TenantModel, 'tenant_id');
    }

    customer() {
        return this.belongsTo('customer', CustomerModel, 'customer_id');
    }

    currency() {
        return this.belongsTo('currency', CurrencyModel, 'currency_id');
    }

    /**
     * Allocations from this payment to other resources.
     */
    allocationsAsSource() {
        return this.morphMany('allocationsAsSource', AllocationModel, 'source');
    }

    /**
     * Allocations from other resources to this payment.
     */
    allocationsAsDestination() {
        return this.morphMany('allocationsAsDestination', AllocationModel, 'destination');
    }

    invoices() {
        return this.morphToMany('invoices', InvoiceModel, AllocationModel, 'source', 'source_id', 'destination_id')
            .wherePivot('destination_type', new InvoiceModel().getType());
    }

    creditNotes() {
        return this.morphToMany('creditNotes', InvoiceModel, AllocationModel, 'destination', 'destination_id', 'source_id')
            .wherePivot('source_type', new InvoiceModel().getType());
    }

    debits() {
        return this.morphToMany('debits', TransactionModel, AllocationModel, 'source', 'source_id', 'destination_id')
            .wherePivot('destination_type', new TransactionModel().getType());
    }

    credits() {
        return this.morphToMany('credits', TransactionModel, AllocationModel, 'destination', 'destination_id', 'source_id')
            .wherePivot('source_type', new TransactionModel().getType());
    }
}
