<template>
    <MModal
        :header-title="__('family_family:filoue_export')"
        :modal="modal"
    >
        <CForm>
            <CFormGroup>
                <CLabel>
                    {{ __('family_family:unique_identifier_afas_omega') }}
                    <CHelpTooltip
                        class="tw-ml-1"
                        placement="top"
                    >
                        {{ __('family_family:unique_identifier_afas_omega_helper') }}
                    </CHelpTooltip>
                </CLabel>
                <CInput
                    v-model="idequ"
                    :placeholder="__('family_family:unique_identifier_afas_omega_example')"
                />
            </CFormGroup>
            <CFormGroup>
                <CLabel>
                    {{ __('family_family:caf_number') }}
                    <CHelpTooltip
                        class="tw-ml-1"
                        placement="top"
                    >
                        {{ __('family_family:caf_number_helper') }}
                    </CHelpTooltip>
                </CLabel>
                <CInput
                    v-model="caf_no"
                    :placeholder="__('family_family:caf_number_example')"
                />
            </CFormGroup>
            <CFormGroup>
                <CLabel>{{ __('common:year_one') }}</CLabel>
                <MYearPicker v-model="date"/>
            </CFormGroup>
        </CForm>

        <template #footer-end="{hide}">
            <MButton
                :label="__('common:actions.cancel')"
                @click="hide"
            />
            <MButton
                :disabled="!idequ || !caf_no"
                :label="__('common:actions.export')"
                :loading="loading"
                variant="primary"
                @click="exportFiloue"
            />
        </template>
    </MModal>
</template>

<script lang="ts">
    import type {PropType} from 'vue';
    import {defineComponent, ref} from 'vue';
    import Manager from '@/modules/legacy/store/manager.store';
    import route from '@/modules/legacy/libs/ziggy';
    import {Epoch, JsonAPISchema} from '@meekohq/lumos';
    import useApi from '@/modules/app/composables/useApi';
    import __ from '@/modules/app/utils/i18n-facade';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';
    import usePopover from '@/modules/app/composables/usePopover';
    import type {ModalType} from '@/modules/app/composables/useModal';

    export default defineComponent({
        props: {
            modal: {
                type: Object as PropType<ModalType>,
                required: true,
            },
        },
        setup(props) {
            const popover = usePopover();

            const date = ref(Epoch.now());

            const idequ = ref('');
            const caf_no = ref('');
            const loading = ref(false);

            function exportFiloue() {
                loading.value = true;
                useApi().legacy.post(
                    route('caf/filoue.download'),
                    new JsonAPISchema.JsonApiDocument(new JsonAPISchema.JsonApiData({
                        from: date.value.startOfYear().toISOString(),
                        to: date.value.endOfYear().startOfSecond().toISOString(),
                        organization_id: `${Manager.legacyNursery.id}`,
                        idequ: idequ.value,
                        caf_no: caf_no.value,
                    })),
                )
                    .then(() => {
                        useNotification().success(__('family_family:data_sended_successfully'));
                        loading.value = false;
                        props.modal.hide();
                    })
                    .catch(() => {
                        loading.value = false;
                    });
            }

            return {
                popover,
                date,
                idequ,
                caf_no,
                loading,
                exportFiloue,
            };
        },
    });
</script>
