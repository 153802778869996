<template>
    <div class="tw-px-3">
        <div class="tw-flex tw-items-end tw-justify-between">
            <slot/>
        </div>
        <hr class="hr tw-my-1 tw-border-gray-200">
    </div>
</template>

<script lang="ts">
    import {defineComponent} from 'vue';

    export default defineComponent({
        components: {},
        props: {},
        setup() {
            return {};
        },
    });
</script>

<style
    lang="scss"
    scoped
>
</style>
