import {Model} from '@meekohq/lumos';

export default class DocumentPivot extends Model {
    public type = 'media/documents_pivot';

    public attributes: {
        id: string;
        account_id: string | undefined;
        document_id: string | undefined;
        resource_id: string | undefined;
        resource_type: string | undefined;
    } = {
            id: this.uuid(),
            account_id: undefined,
            document_id: undefined,
            resource_id: undefined,
            resource_type: undefined,
        };
}
