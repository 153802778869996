<template>
    <div>
        <div
            v-if="displayPreference === 'month'"
            class="tw-flex tw-justify-between tw-flex-wrap"
        >
            <div class="tw-w-auto tw-order-1">
                <CButton
                    class="print:!tw-hidden tw-capitalize"
                    variant="success"
                    @click="date = date.clone().subtract(1, 'month'); getCalendar();"
                >
                    <i
                        aria-hidden="true"
                        class="fa fa-angle-left tw-mr-1"
                    />
                    {{ fromMoment(date).subMonths(1).monthLong }}
                </CButton>
            </div>
            <div class="tw-w-full md:tw-w-1/2 xl:tw-w-1/3 tw-order-2 md:tw-order-1 tw-text-center tw-mt-2 md:tw-mt-0">
                <MHeading class="tw-capitalize">
                    {{ fromMoment(date).toFormat('MMMM yyyy') }}
                </MHeading>
                <div class="tw-mt-1">
                    <EventTotals
                        :contract="staff.activeContract(date.format()) ? `${staff.activeContract(date.format()).attributes.hours_per_week}h` : '0h'"
                        :forecast="totalPlanned(date.clone().startOf('month'), date.clone().endOf('month'))"
                        :real="totalAchieved(date.clone().startOf('month'), date.clone().endOf('month'))"
                    />
                </div>
            </div>
            <div class="tw-w-auto tw-order-1">
                <CButton
                    class="print:!tw-hidden tw-capitalize"
                    variant="success"
                    @click="date = date.clone().add(1, 'month'); getCalendar();"
                >
                    {{ fromMoment(date).addMonths(1).monthLong }}
                    <i
                        aria-hidden="true"
                        class="fa fa-angle-right tw-ml-1"
                    />
                </CButton>
            </div>
        </div>
        <div
            v-else
            class="tw-flex tw-justify-between tw-flex-wrap"
        >
            <div class="tw-w-auto tw-order-1">
                <CButton
                    class="print:!tw-hidden"
                    variant="success"
                    @click="date = date.clone().subtract(1, 'weeks'); getCalendar();"
                >
                    <i
                        aria-hidden="true"
                        class="fa fa-angle-left tw-mr-1"
                    />
                    {{ __('common:longweek_with_number', {week: fromMoment(date).subWeeks(1).toFormat('W')}) }}
                </CButton>
            </div>
            <div class="tw-w-full md:tw-w-1/2 xl:tw-w-1/3 tw-order-2 md:tw-order-1 tw-text-center tw-mt-2 md:tw-mt-0">
                <MHeading>
                    {{ __('common:longweek_with_number', {week: from.week()}) }}
                </MHeading>
                <div class="tw-mt-1">
                    <EventTotals
                        :contract="staff.activeContract(date.format()) ? `${staff.activeContract(date.format()).attributes.hours_per_week}h` : '0h'"
                        :forecast="totalPlanned(from, to)"
                        :real="totalAchieved(from, to)"
                    />
                </div>
            </div>
            <div class="tw-w-auto tw-order-1">
                <CButton
                    class="print:!tw-hidden"
                    variant="success"
                    @click="date = date.clone().add(1, 'weeks'); getCalendar();"
                >
                    {{ __('common:longweek_with_number', {week: fromMoment(date).addWeeks(1).toFormat('W')}) }}
                    <i
                        aria-hidden="true"
                        class="fa fa-angle-right tw-ml-1"
                    />
                </CButton>
            </div>
        </div>

        <div class="tw-flex tw-flex-wrap tw-justify-between tw-gap-2 tw-my-4 print:tw-hidden">
            <div class="tw-flex tw-flex-wrap tw-gap-2">
                <FilterEvents :event-filters.sync="eventFilters"/>
                <CButtonGroup>
                    <CButton
                        :variant="displayPreference === 'week' ? 'primary' : 'light'"
                        @click="displayPreference = 'week'"
                    >
                        {{ __('common:week') }}
                    </CButton>
                    <CButton
                        :variant="displayPreference === 'month' ? 'primary' : 'light'"
                        @click="displayPreference = 'month'"
                    >
                        {{ __('common:month') }}
                    </CButton>
                </CButtonGroup>
            </div>
            <div class="tw-flex tw-flex-wrap tw-gap-2">
                <MDropdown v-if="$can('create', 'staffs_planning') && $can('update', 'staffs_planning')">
                    <MButton>
                        <template #left-icons>
                            <FontAwesomeIcon icon="fad fa-calendar-alt"/>
                        </template>
                        {{ __('planning:manage_planning') }}
                    </MButton>
                    <template #content>
                        <CDropdownMenuItem
                            class="tw-w-full"
                            icon="fad fa-calendar-day"
                            :label="__('planning:manage_typical_week')"
                            @click="showManageTemplatesModal = true"
                        />
                        <CDropdownMenuItem
                            class="tw-w-full"
                            icon="fad fa-copy"
                            :label="__('planning:apply_typical_week')"
                            @click="showUseTemplateModal = true"
                        />
                        <CDropdownMenuItem
                            v-if="$can('delete', 'staffs_planning')"
                            class="tw-w-full"
                            icon="fad fa-calendar-times"
                            :label="__('planning:delete_events_dots')"
                            variant="danger"
                            @click="$refs.massDeleteEventsModal.show()"
                        />
                    </template>
                </MDropdown>
                <MassDeleteEventsModal
                    ref="massDeleteEventsModal"
                    :from="from"
                    :staff="staff"
                    :to="to"
                    @deleted="getCalendar"
                />
                <MButton
                    v-if="$can('read', 'staffs_statistics')"
                    @click="$refs.staffStatisticsModal.$refs.modal.show()"
                >
                    <template #left-icons>
                        <FontAwesomeIcon icon="fad fa-swap-opacity fa-file-chart-line"/>
                    </template>
                    {{ __('common:statistic_other') }}
                </MButton>
                <MDropdown>
                    <MTooltip placement="top-end">
                        <MButton variant="gray">
                            <FontAwesomeIcon icon="fa fa-external-link"/>
                        </MButton>
                        <template #content>
                            {{ __('planning:print_or_share') }}
                        </template>
                    </MTooltip>
                    <template #content>
                        <CDropdownMenuItem
                            class="tw-w-full print:!tw-hidden"
                            icon="fa fa-print"
                            :label="__('planning:print_planning')"
                            @click="printPlanning"
                        />
                        <CDropdownMenuItem
                            class="tw-w-full print:!tw-hidden"
                            icon="fa fa-calendar-alt"
                            :label="__('planning:planning_subscription')"
                            @click="$refs.modalplanning.show()"
                        />
                    </template>
                </MDropdown>
                <StaffPlanningSubscriberModal
                    ref="modalplanning"
                    :staff-model="staff"
                />
            </div>
        </div>

        <loader
            v-if="loading"
            light="true"
            shadow="false"
            size="sm"
            :title="__('common:loading_dots')"
        />
        <div
            v-for="(week, w) in weeksToDisplay"
            v-else
            :key="'week' + w"
            style="page-break-inside: avoid;"
        >
            <div
                v-if="w > 0"
                class="tw-mx-5 tw-border-b tw-mb-5 tw-border-gray-200"
            />

            <div
                class="tw-w-full tw-mb-2 tw-hidden sm:tw-block print:tw-block"
                :style="fullDayEvents().count() ? 'padding-left: 105px; padding-right: 55px' : 'padding-left: 85px; padding-right: 55px'"
            >
                <Hours
                    :closing="closing()"
                    :opening="opening()"
                />
            </div>
            <div v-show="!loading">
                <div
                    v-for="(day, i) in enumerateDaysBetweenDates.filter(item => item.week() === week)"
                    :key="`day_${i}week_${w}`"
                >
                    <div class="tw-flex tw-flex-wrap sm:tw-flex-nowrap print:tw-flex-nowrap tw-w-full tw-items-center tw-mb-2">
                        <div class="tw-w-full sm:tw-w-28 print:tw-w-28">
                            <CPopover
                                hoverable
                                placement="top"
                            >
                                <MHoverable class="tw-capitalize tw-font-semibold tw-cursor-default">
                                    {{ day.format('ddd DD/MM') }}
                                </MHoverable>
                                <template #content>
                                    <div
                                        class="tw-text-gray-600 tw-text-base tw-text-center"
                                        style="width: 200px"
                                    >
                                        <EventTotals
                                            :forecast="totalPlanned(day.clone().startOf('day'), day.clone().endOf('day'))"
                                            :real="totalAchieved(day.clone().startOf('day'), day.clone().endOf('day'))"
                                        />
                                    </div>
                                </template>
                            </CPopover>
                        </div>

                        <div class="tw-w-11/12 sm:tw-w-full print:tw-w-full">
                            <PlanningGenerator
                                :closing="closing()"
                                :day="day"
                                :events="filteredEvents.all()"
                                :full-day-events="fullDayEvents().all()"
                                :opening="opening()"
                                show-note
                                :staff="staff"
                                @add="newEvent(day, staff, true, true)"
                                @pushEvent="events.push($event)"
                            />
                        </div>
                        <div class="tw-w-1/12 tw-pl-1 sm:tw-w-8 sm:tw-ml-2 print:tw-w-8 print:tw-ml-2">
                            <MButton
                                v-if="$can('create', 'staffs_planning')"
                                class="print:!tw-hidden"
                                variant="primary"
                                @click="newEvent(day, staff, true)"
                            >
                                <FontAwesomeIcon icon="fa fa-plus"/>
                            </MButton>
                        </div>
                    </div>

                    <div
                        v-if="numberOfWeeks > 1 && i + 1 === enumerateDaysBetweenDates.filter(item => item.week() === week).length"
                        class="tw-w-full tw-ml-4 tw-pt-3 tw-mb-6"
                    >
                        <div class="tw-flex tw-items-center">
                            <div class="tw-text-base tw-text-gray-500 tw-font-semibold tw-mr-2">
                                {{ __('common:total') }} - {{ __('common:shortweek_with_number', {week: week}) }}
                            </div>
                            <EventTotals
                                :forecast="totalPlanned(day.clone().startOf('week'), day.clone().endOf('week'))"
                                :real="totalAchieved(day.clone().startOf('week'), day.clone().endOf('week'))"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <ManageTemplatesModal
            v-if="showManageTemplatesModal"
            :current-week="weeksToDisplay.values().next().value"
            :default-plannings="events"
            :staff="staff"
            @hidden="showManageTemplatesModal = false"
        />

        <UseTemplateModal
            v-if="showUseTemplateModal"
            :staff="staff"
            @hidden="showUseTemplateModal = false"
            @update:plannings="getCalendar()"
        />

        <StaffStatisticsModal
            ref="staffStatisticsModal"
            :staff="staff"
        />

        <EventModal
            v-if="selectedEvent"
            ref="eventModal"
            :event="selectedEvent"
            @add="onEventAdded"
            @delete="onEventDeleted"
            @hidden="selectedEvent = null"
        />
    </div>
</template>

<script lang="ts">
    import type {PropType} from 'vue';
    import {computed, defineComponent, onMounted, ref, watch} from 'vue';
    import moment from 'moment';
    import {Epoch} from '@meekohq/lumos';
    import useCalendar from '@/modules/human-resources/composables/calendar/useCalendar';
    import StaffModel from '@/modules/human-resources/models/StaffModel';
    import Line from '@/modules/human-resources/components/calendar/Line.vue';
    import Hours from '@/modules/legacy/components/Modules/PlanningGenerator/Hours.vue';
    import FilterEvents from '@/modules/human-resources/components/calendar/FilterEvents.vue';
    import EventModal from '@/modules/human-resources/components/calendar/EventModal.vue';
    import EventTotals from '@/modules/human-resources/components/calendar/EventTotals.vue';
    import ManageTemplatesModal
        from '@/modules/human-resources/components/calendar/CalendarTemplate/Daily/ManageTemplates.vue';
    import UseTemplateModal from '@/modules/human-resources/components/calendar/CalendarTemplate/Daily/UseTemplate.vue';
    import StaffStatisticsModal from '@/modules/human-resources/components/statistics/StaffStatisticsModal.vue';
    import StaffPlanningSubscriberModal
        from '@/modules/human-resources/components/calendar/StaffPlanningSubscriberModal.vue';
    import MassDeleteEventsModal from '@/modules/human-resources/components/planning/MassDeleteEventsModal.vue';
    import useMetrics from '@/modules/app/composables/useRum';
    import useEpoch from '@/modules/app/composables/useEpoch';

    export default defineComponent({
        components: {
            MassDeleteEventsModal,
            PlanningGenerator: Line,
            Hours,
            FilterEvents,
            EventModal,
            EventTotals,

            ManageTemplatesModal,
            UseTemplateModal,
            StaffStatisticsModal,
            StaffPlanningSubscriberModal,
        },
        props: {
            staff: {
                type: Object as PropType<StaffModel>,
                required: true,
            },
        },
        setup(props) {
            const {parse, fromMoment} = useEpoch();
            const date = ref(moment());
            const {addAction} = useMetrics();
            const from = computed(() => {
                if (displayPreference.value === 'month') {
                    return date.value.clone().startOf('month');
                }

                return date.value.clone().startOf('week');
            });
            const to = computed(() => {
                if (displayPreference.value === 'month') {
                    return date.value.clone().endOf('month');
                }

                return date.value.clone().endOf('week');
            });
            const numberOfWeeks = computed(() => {
                return to.value.diff(from.value, 'weeks');
            });

            const showManageTemplatesModal = ref(false);
            const showUseTemplateModal = ref(false);

            const displayPreference = ref<'week' | 'month'>('week');
            watch(displayPreference, () => {
                window.localStorage.setItem(
                    'staffs:show:planning:displayRange',
                    displayPreference.value,
                );
                getCalendar();
            });

            const tempStaff = computed(() => {
                const staff = new StaffModel();
                staff.id = props.staff.id;

                return staff;
            });

            const {
                enumerateDaysBetweenDates,
                weeksToDisplay,
                opening,
                closing,

                filteredEvents,
                fullDayEvents,
                eventFilters,
                loading,
                getCalendar,
                resetAllEvents,

                events,
                selectedEvent,
                totalPlanned,
                totalAchieved,

                newEvent,
                addEvent,
                onEventAdded,
                onEventDeleted,
                eventModal,

                printPlanning,
            } = useCalendar(from, to, tempStaff.value);

            onMounted(() => {
                const localDisplayPreference = window.localStorage.getItem(
                    'staffs:show:planning:displayRange',
                );
                if (localDisplayPreference === 'week' || localDisplayPreference === 'month') {
                    displayPreference.value = localDisplayPreference;
                }

                addAction('M_HR_Staff_Summary_Display', {value: displayPreference.value === 'week' ? 'week' : 'month'});

                getCalendar();
            });

            return {
                moment,
                date,
                from,
                to,
                numberOfWeeks,
                displayPreference,

                opening,
                closing,
                enumerateDaysBetweenDates,
                weeksToDisplay,

                filteredEvents,
                fullDayEvents,
                fromMoment,
                eventFilters,
                Epoch,
                loading,
                getCalendar,
                resetAllEvents,
                parse,
                printPlanning,

                events,
                selectedEvent,
                totalPlanned,
                totalAchieved,

                newEvent,
                addEvent,
                onEventAdded,
                onEventDeleted,
                eventModal,

                showManageTemplatesModal,
                showUseTemplateModal,
            };
        },
    });
</script>
