<script setup lang="ts">
    import {computed, defineProps, withDefaults, ref, onMounted} from 'vue';
    import type StaffModel from '@/modules/human-resources/models/StaffModel';
    import FilterContractStates from '@/modules/human-resources/components/staff/FilterContractStates.vue';
    import StaffsListItem from '@/modules/human-resources/components/statistics/StaffsListItem.vue';
    import TeamFinder from '@/modules/human-resources/components/team/TeamFinder.vue';
    import type TeamModel from '@/modules/human-resources/models/TeamModel';
    import type OrganizationModel from '@/modules/organization/models/OrganizationModel';
    import type {ModelCollection} from '@meekohq/lumos';
    import type EventModel from '@/modules/human-resources/models/EventModel';
    import TagModel from '@/modules/tag/models/TagModel';
    import TagsResourceFinder from '@/modules/tag/components/TagsResourceFinder.vue';

    const emit = defineEmits([
        'update:selectedStaff',
        'update:selectedTags',
        'update:withoutTags',
        'update:selectedContracts',
        'update:selectedTeams',
    ]);

    const props = withDefaults(defineProps<{
        staffs: StaffModel[];
        selectedStaff: StaffModel;
        organizations: OrganizationModel[];
        selectedContracts: string[];
        staffEvents: ModelCollection<EventModel>;
        selectedTeams: TeamModel[];
        selectedTags?: string[];
        eventFilters?: string[];
        withoutTags?: boolean;
    }>(), {
        selectedTags: () => ([]),
        eventFilters: () => ([]),
    });

    const showReal = computed(() => {
        return props.eventFilters?.includes('real') ?? false;
    });

    const teams = ref(props.selectedTeams);
    const tagFinderQuery = TagModel.query().where('type', 'staff');

    /*
     * <TagResourceFinder /> is using TagModel, useStaff is using tag ids, so we need to make a reactive conversions between them
     * `selectedTagModels` is the reactive array of TagModel instances used by <TagResourceFinder />
     * `getSelectedTagsModels` is used in onMounted to load the selected tags models after selectedTags has been load from localStorage
     */
    const selectedTagModels = ref<TagModel[]>([]);
    async function getSelectedTagsModels() {
        if (!props.selectedTags?.length) {
            selectedTagModels.value = [];

            return;
        }
        selectedTagModels.value = (await TagModel.query().whereIn('id', props.selectedTags || []).get()).all();
    }

    onMounted(getSelectedTagsModels);

    function updateSelectedTags(newTagModels: TagModel[]) {
        selectedTagModels.value = newTagModels;
        const tagIds = newTagModels.map(tag => tag.getKey());
        if (!tagIds.length) {
            emit('update:selectedTags', undefined);

            return;
        }
        emit('update:selectedTags', tagIds);
        emit('update:withoutTags', false);
    }
    function updateWithoutTags(newWithoutTags: boolean) {
        if (newWithoutTags) {
            emit('update:selectedTags', undefined);
        }
        emit('update:withoutTags', newWithoutTags);
    }
</script>

<template>
    <div>
        <MDropdown class="tw-mb-4">
            <MButton>
                <template #left-icons>
                    <FontAwesomeIcon icon="fas fa-users"/>
                </template>
                {{ __('hr:filter_staff') }}
            </MButton>
            <template #content>
                <CHStack>
                    <TagsResourceFinder
                        has-unselect
                        :inject-query="tagFinderQuery"
                        multi
                        only-active-organization
                        :search-bar="false"
                        :value="selectedTagModels"
                        :without-tags-option-is-selected="withoutTags"
                        @input="updateSelectedTags"
                        @without-tags-option-is-selected="updateWithoutTags"
                    />
                    <FilterContractStates
                        class="tw-ml-3"
                        :value="selectedContracts"
                        @input="$emit('update:selectedContracts', $event)"
                    />
                </CHStack>

                <TeamFinder
                    v-model="teams"
                    button-class="tw-w-full"
                    class="tw-my-2"
                    multi
                    :organizations="organizations"
                    @input="$emit('update:selectedTeams', $event)"
                />
            </template>
        </MDropdown>
        <div
            class="tw-overflow-y-scroll"
            style="max-height: 600px;"
        >
            <CCard
                v-for="(staff, i) in staffs"
                :key="'staff' + i"
                body-size="xs"
                class="tw-mb-2 hover:tw-bg-gray-50 tw-shadow-sm tw-cursor-pointer"
                :class="{ '!tw-bg-blue-500': staff === selectedStaff }"
                @click.native="$emit('update:selectedStaff', staff)"
            >
                <StaffsListItem
                    :events="staff === selectedStaff ? staffEvents : undefined"
                    :selected-staff="selectedStaff"
                    :show-real="showReal"
                    :staff="staff"
                />
            </CCard>
        </div>
    </div>
</template>
