<template>
    <div>
        <div
            v-for="(week, i) in registration.contract_recurrent_weeks"
            :key="'week' + i"
            class="mb-4"
        >
            <h5
                v-if="registration.contract_recurrent_weeks > 1"
                class="h5 mb-0 mt-3"
            >
                {{ __('planning:reference_week_number', {week: week}) }}
                <span class="text-muted small">
                    -
                    {{ __('common:starting_from') }}
                    {{ parse(registration.contract_started_at, 'yyyy-MM-dd').addWeeks(i).startOfWeek().toLocaleString(Epoch.presets.DATE_SHORT) }}
                </span>
            </h5>
            <hr class="hr">
            <div class="d-table w-100 mb-2">
                <div
                    class="d-none d-sm-block"
                    style="padding-left: 85px; padding-right: 65px;"
                >
                    <hours
                        :closing="closing"
                        :opening="opening"
                    />
                </div>
            </div>
            <div
                v-for="(day, key) in enumerateDaysBetweenDates(weekStart.clone().add(week, 'weeks').format(), weekEnd.clone().add(week, 'weeks').format())"
                :key="'day' + key"
                class="d-table w-100 mb-2"
            >
                <div class="day text-capitalize text-sm-right pr-sm-4 mb-1 mb-sm-0 tw-font-display float-none float-sm-left">
                    {{ day.format('dddd') }}
                </div>
                <div class="planning float-none float-sm-left pr-sm-3">
                    <planning-generator
                        :closing="closing"
                        :day="day"
                        :events="events"
                        inner-text
                        :nursery="nursery"
                        :opening="opening"
                        :show-clock="false"
                        :show-menu="$can('update', 'registrations')"
                        :user="user"
                    />
                </div>
                <div
                    v-if="!disabled && $can('update', 'registrations')"
                    class="add float-none float-sm-right pr-sm-3"
                >
                    <button
                        class="btn btn-success btn-sm rounded-circle"
                        @click="addPlage(week, day)"
                    >
                        <i
                            aria-hidden="true"
                            class="fa fa-plus"
                        />
                    </button>
                </div>
            </div>
        </div>
        <div
            v-if="!disabled && $can('update', 'registrations')"
            class="text-center"
        >
            <MButton
                :label="__('common:add_week')"
                variant="success"
                @click="registration.contract_recurrent_weeks++"
            />
            <MButton
                v-if="registration && registration.contract_recurrent_weeks > 1"
                class="ml-2"
                :label="__('registration:remove_week')"
                variant="danger"
                @click="removeWeek"
            />
        </div>
    </div>
</template>

<script>
    import _cloneDeep from 'lodash-es/cloneDeep';
    import _concat from 'lodash-es/concat';
    import _forEach from 'lodash-es/forEach';
    import _filter from 'lodash-es/filter';
    import _find from 'lodash-es/find';
    import moment from 'moment';
    import Hours from '@/modules/legacy/components/Modules/PlanningGenerator/Hours.vue';
    import EditPlanning from '@/modules/planning/components/Modal/EditPlanning.vue';
    import NewPlanning from '@/modules/planning/components/Modal/NewPlanning.vue';
    import {nursery} from '@/modules/legacy/mixins/nursery';
    import {getPlanningEventsFromKidContractOrRegistration} from '@/modules/legacy/libs/Planning';
    import {Epoch} from '@meekohq/lumos';
    import useEpoch from '@/modules/app/composables/useEpoch';
    import useModal from '@/modules/app/composables/useModal';

    export default {
        components: {
            hours: Hours,

        },
        mixins: [nursery],
        props: ['nursery', 'registration', 'disabled', 'user'],
        data: () => ({
            Epoch,
            planningOnEdition: {},
            addOnWeek: 1,
            addOnDay: '',
            events: [],
        }),
        computed: {
            newModal() {
                return useModal();
            },
            editModal() {
                return useModal();
            },
            moment() {
                return moment;
            },
            opening() {
                return this.$getOpening(this.nursery.openingHours, false, false, this.events);
            },
            closing() {
                return this.$getClosing(this.nursery.openingHours, false, false, this.events);
            },
            exactOpening() {
                return this.$getOpening(this.nursery.openingHours, true, true, this.events);
            },
            exactClosing() {
                return this.$getClosing(this.nursery.openingHours, true, true, this.events);
            },
            weekStart() {
                return moment().startOf('week').subtract(1, 'week');
            },
            weekEnd() {
                return this.weekStart.clone().endOf('week');
            },
        },
        watch: {
            'registration.plannings': {
                handler() {
                    this.computeEvents();
                },
                deep: true,
                immediate: true,
            },
        },
        mounted() {
            this.$bus.$on('clicked:event', segment => {
                if (this.disabled) {
                    return;
                }

                this.planningOnEdition = _filter(this.registration.plannings, planning => {
                    return planning.id === segment.id;
                })[0];

                const editPlanningModal = useModal({
                    component: EditPlanning,
                    props: {
                        planning: this.planningOnEdition,
                        planningModel: this.registration,
                    },
                });
                editPlanningModal.show();
            });

            this.computeEvents();
        },
        beforeDestroy() {
            this.$bus.$off('clicked:event');
        },
        methods: {
            parse: useEpoch().parse,
            computeEvents() {
                let events = [];
                if (this.registration) {

                    // Create a copy of the registration to simulate the started date of the contract.
                    // Because getPlanningEventsFromKidContractOrRegistration needed it to compute the events.
                    // This function calculate week from the contract start date.
                    const registrationForEvents = _cloneDeep(this.registration);
                    registrationForEvents.contract_started_at = Epoch.now().toFormat('yyyy-MM-dd');

                    for (let week = 1; week <= registrationForEvents.contract_recurrent_weeks; week++) {
                        _forEach(
                            this.enumerateDaysBetweenDates(
                                this.weekStart.clone().add(week, 'weeks').format(),
                                this.weekEnd.clone().add(week, 'weeks').format(),
                            ), day => {
                                events = _concat(
                                    events,
                                    getPlanningEventsFromKidContractOrRegistration(
                                        day,
                                        null,
                                        registrationForEvents,
                                        {onlyHours: true},
                                    ),
                                );
                            });
                    }
                }

                this.events = events;
            },
            removeWeek() {
                this.registration.contract_recurrent_weeks--; // Ne pas inverser les deux lignes, car le watcher sur plannings recompute les events.
                this.registration.plannings = this.registration.plannings.filter(planning => planning.week <= this.registration.contract_recurrent_weeks);
            },
            enumerateDaysBetweenDates(start, end) {
                const dates = [];

                const currDate = moment(start).startOf('day');
                const lastDate = moment(end).startOf('day');

                dates.push(currDate.clone());
                while (currDate.add(1, 'days').diff(lastDate) <= 0) {
                    const day = currDate.clone().locale('en').format('ddd').toLowerCase();
                    if (day === 'sat' || day === 'sun') {
                        const hasDay = _find(this.nursery.openingHours, {day});
                        hasDay ? dates.push(currDate.clone()) : null;
                    } else {
                        dates.push(currDate.clone());
                    }
                }

                return dates;
            },
            addPlage(week, day) {
                this.addOnWeek = week;
                this.addOnDay = day.locale('en').day();

                // We use the last event to copy its start and end time, otherwise we use the organization opening and
                // closing hours.

                const opening = this.events?.length ? this.events[this.events.length - 1].start_time : this.exactOpening;
                const closing = this.events?.length ? this.events[this.events.length - 1].end_time : this.exactClosing;

                const newPlanningModal = useModal({
                    component: NewPlanning,
                    props: {
                        closing,
                        planningModel: this.registration,
                        day: this.addOnDay,
                        opening,
                        type: 'recurrent',
                        week: this.addOnWeek,
                    },
                });
                newPlanningModal.show();
            },
        },
    };
</script>

<style
    lang="scss"
    scoped
>
    @import "bootstrap/scss/functions";
    @import "@/assets/_bootstrap/variables";
    @import "bootstrap/scss/mixins/breakpoints";

    @include media-breakpoint-up(sm) {
        .day {
            width: 100px;
        }

        .planning {
            width: calc(100% - 100px - 35px);
        }

        .add {
            .btn {
                width: 31px;
                height: 31px;
            }

            width: 35px;
        }
    }
</style>
