<template>
    <div>
        <MDropdown placement="bottom-end">
            <MButton>
                <template #left-icons>
                    <FontAwesomeIcon icon="fad fa-calendar-alt"/>
                </template>
                {{ __('planning:manage_planning') }}
            </MButton>
            <template #content>
                <CDropdownMenuItem
                    class="tw-w-full"
                    icon="fas fa-plus"
                    @click="addMultiplePlannings()"
                >
                    {{ __('planning:add_multiple_plannings') }}
                </CDropdownMenuItem>
                <CDropdownMenuDivider class="tw-my-2"/>
                <CDropdownMenuItem
                    class="tw-w-full"
                    icon="fas fa-calendar-plus"
                    variant="blue"
                    @click="addOccasional(false)"
                >
                    {{ __('planning:add_reservation') }}
                </CDropdownMenuItem>
                <CDropdownMenuItem
                    class="tw-w-full"
                    icon="fas fa-calendar-plus"
                    variant="yellow"
                    @click="addAdaptation"
                >
                    {{ __('planning:add_adaptation') }}
                </CDropdownMenuItem>
                <CDropdownMenuItem
                    v-if="kid && kid.id"
                    class="tw-w-full"
                    icon="fas fa-calendar-minus"
                    variant="red"
                    @click="addAbsence"
                >
                    {{ __('planning:add_absence') }}
                </CDropdownMenuItem>
                <CDropdownMenuItem
                    v-else
                    class="tw-w-full"
                    icon="fas fa-calendar-minus"
                    variant="red"
                    @click="addAbsences"
                >
                    {{ __('planning:add_absence') }}
                </CDropdownMenuItem>
            </template>
        </MDropdown>
        <add-kids-absences
            ref="addKidsAbsences"
            :date="date"
            :nursery="nursery"
        />
        <AddMultiplePlanningsModal
            v-if="showMulplePlanningsModal"
            :kid="kid"
            @hidden="showMulplePlanningsModal = false"
        />
    </div>
</template>

<script>
    import moment from 'moment';
    import {nursery} from '@/modules/legacy/mixins/nursery';
    import useAuth from '@/modules/app/composables/useAuth';
    import AddKidsAbsences from '@/modules/legacy/components/Modules/PlanningGenerator/AddKidsAbsences.vue';
    import AddMultiplePlanningsModal
        from '@/modules/legacy/components/Modules/PlanningSummary/AddMultiplePlanningsModal.vue';
    import useEditEventModal from '@/modules/planning/composables/core/useEditEventModal';

    export default {
        components: {
            AddKidsAbsences,
            AddMultiplePlanningsModal,
        },
        mixins: [nursery],
        props: {
            nursery: {},
            date: {},
            kid: {},
        },
        data: () => ({
            showMulplePlanningsModal: false,
        }),
        computed: {
            user() {
                return useAuth().user.value;
            },
        },
        methods: {
            addAbsences() {
                this.$root.$emit('bv::hide::popover');
                this.$refs.addKidsAbsences.$refs.modal.show();
            },

            addAbsence() {
                this.$root.$emit('bv::hide::popover');

                const opening = this.$getOpening(this.nursery.openingHours, false);
                const closing = this.$getClosing(this.nursery.openingHours, false);

                const date = moment(this.date).format('YYYY-MM-DD');

                const segment = {};
                segment.started_at = moment(date + ' ' + opening, 'YYYY-MM-DD HH:mm').format();
                segment.ended_at = moment(date + ' ' + closing, 'YYYY-MM-DD HH:mm').format();
                segment.kid_id = this.kid?.id;
                segment.nursery_id = this.nursery.id;
                segment.type = 'absence';
                segment.note = null;
                segment.contract_hourly_rate = null;
                segment.hourly_rate = null;
                segment.isNew = true;
                segment.rangeAbsence = true;
                segment.unit = 'day';

                useEditEventModal().show(segment);
            },

            addOccasional(isAdaptation) {
                this.$root.$emit('bv::hide::popover');

                const opening = this.$getOpening(this.nursery.openingHours, false);
                const closing = this.$getClosing(this.nursery.openingHours, false);

                const date = moment(this.date).format('YYYY-MM-DD');

                const segment = {};
                segment.started_at = moment(
                    date + ' ' + opening,
                    'YYYY-MM-DD HH:mm',
                ).format();
                segment.ended_at = moment(
                    date + ' ' + closing,
                    'YYYY-MM-DD HH:mm',
                ).format();
                segment.start_time = moment(opening, 'HH:mm').format('HH:mm:ss');
                segment.end_time = moment(closing, 'HH:mm').format('HH:mm:ss');
                segment.kid_id = this.kid?.id;
                segment.nursery_id = this.nursery.id;
                segment.type = isAdaptation ? 'adaptation' : 'occasional';
                segment.note = null;
                segment.contract_hourly_rate = null;
                segment.hourly_rate = null;
                segment.isNew = true;
                segment.unit = 'day';

                useEditEventModal().show(segment);
            },

            addAdaptation() {
                this.addOccasional(true);
            },

            addMultiplePlannings() {
                this.$root.$emit('bv::hide::popover');
                this.showMulplePlanningsModal = true;
            },
        },
    };
</script>
