<template>
    <div :class="[{'down' : sum.type === 'activities'}, {'small' : small}, line ? 'line-icon-type' : 'icon-type']">
        <FontAwesomeIcon
            v-if="sum.type === 'arrival'"
            icon="fa fa-clock"
        />
        <FontAwesomeIcon
            v-else-if="sum.type === 'departure'"
            icon="far fa-clock"
        />
        <template v-else-if="sum.type === 'meals'">
            <img
                v-if="sum.rating === null"
                :alt="__('transmission:apple')"
                src="@/assets/images/tablet/apple_0.svg"
                width="25px"
            >
            <img
                v-else-if="sum.rating === 0"
                :alt="__('transmission:apple')"
                src="@/assets/images/tablet/apple_0.svg"
                width="25px"
            >
            <img
                v-else-if="sum.rating === 1"
                :alt="__('transmission:apple')"
                src="@/assets/images/tablet/apple_1.svg"
                width="25px"
            >
            <img
                v-else
                :alt="__('transmission:apple')"
                src="@/assets/images/tablet/apple_2.svg"
                width="25px"
            >
        </template>
        <div
            v-else-if="sum.type === 'milk'"
            class="tw-relative"
        >
            <img
                :alt="__('transmission:baby_bottle')"
                src="@/assets/images/tablet/baby_bottle.svg"
                width="27px"
            >
            <template v-if="!line">
                <img
                    v-if="sum.rating === 0"
                    :alt="__('transmission:apple')"
                    class="milk-bottle-complementary-icon"
                    src="@/assets/images/tablet/apple_0.svg"
                    width="14px"
                >
                <img
                    v-else-if="sum.rating === 1"
                    :alt="__('transmission:apple')"
                    class="milk-bottle-complementary-icon"
                    src="@/assets/images/tablet/apple_1.svg"
                    width="14px"
                >
                <img
                    v-else-if="sum.rating === 2"
                    :alt="__('transmission:apple')"
                    class="milk-bottle-complementary-icon"
                    src="@/assets/images/tablet/apple_2.svg"
                    width="14px"
                >
            </template>
        </div>
        <template v-else-if="sum.type === 'naps'">
            <img
                v-if="sum.rating === 0"
                :alt="__('transmission:moon')"
                src="@/assets/images/tablet/moon_0.svg"
                width="37px"
            >
            <img
                v-else-if="sum.rating === 1"
                :alt="__('transmission:moon')"
                src="@/assets/images/tablet/moon_1.svg"
                width="33px"
            >
            <img
                v-else
                :alt="__('transmission:moon')"
                src="@/assets/images/tablet/moon_2.svg"
                width="33px"
            >
        </template>
        <img
            v-else-if="sum.type === 'weights'"
            :alt="__('transmission:balance')"
            src="@/assets/images/tablet/balance.svg"
            width="30px"
        >
        <img
            v-else-if="sum.type === 'temperatures'"
            :alt="__('transmission:thermometer')"
            src="@/assets/images/tablet/thermometer.svg"
            width="11px"
        >
        <img
            v-else-if="sum.type === 'drugs'"
            :alt="__('transmission:pills')"
            src="@/assets/images/tablet/pills.svg"
            width="28px"
        >
        <template v-else-if="sum.type === 'diapers'">
            <img
                v-if="sum.diaper_type === null"
                :alt="__('common:hygiene_types.diaper')"
                src="@/assets/images/tablet/baby_diaper.svg"
                width="22px"
            >
            <img
                v-else-if="sum.diaper_type === 'diaper'"
                :alt="__('common:hygiene_types.diaper')"
                src="@/assets/images/tablet/diaper.svg"
                width="28px"
            >
            <img
                v-else-if="sum.diaper_type === 'potty'"
                :alt="__('transmission:potty')"
                src="@/assets/images/tablet/potty.svg"
                width="28px"
            >
            <img
                v-else
                :alt="__('transmission:toilet')"
                src="@/assets/images/tablet/toilet.svg"
                width="25px"
            >
        </template>
        <activity-img
            v-else-if="sum.type === 'activities' && !isHover"
            class="align-self-center"
            :icon="sum.category_icon"
            line
            small
        />
        <activity-img
            v-else-if="sum.type === 'activities' && isHover"
            class="align-self-center"
            :icon="sum.category_icon"
            is-hover
            line
        />
        <FontAwesomeIcon
            v-else-if="sum.type === 'photos'"
            class="tw-text-pink-500 pt-1"
            icon="fa fa-image"
            style="font-size: 25px;"
        />
        <FontAwesomeIcon
            v-else-if="sum.type === 'video'"
            class="tw-text-pink-500 pt-1"
            icon="fa fa-video"
            style="font-size: 25px;"
        />
        <img
            v-else
            :alt="__('common:observation_one')"
            src="@/assets/images/tablet/observation.svg"
            width="25px"
        >
    </div>
</template>

<script>
    import ActivityImg from './ActivityImg.vue';
    import moment from 'moment';

    export default {
        name: 'SummaryImg',
        components: {
            ActivityImg,
        },
        props: {
            opening: null,
            closing: null,
            day: null,
            line: {
                type: Boolean,
                default: false,
            },
            modal: {
                type: Boolean,
                default: false,
            },
            sum: {type: Object},
            small: {
                type: Boolean,
                default: false,
            },
            isHover: {
                type: Boolean,
                default: false,
            },
        },
        data: () => ({
            windowWidth: 0,
            windowHeight: 0,
        }),
        computed: {
            moment() {
                return moment;
            },
        },
        mounted() {
            this.$nextTick(function() {
                window.addEventListener('resize', this.getWindowWidth);
                window.addEventListener('resize', this.getWindowHeight);

                this.getWindowWidth();
                this.getWindowHeight();
            });
        },
        beforeDestroy() {
            window.removeEventListener('resize', this.getWindowWidth);
            window.removeEventListener('resize', this.getWindowHeight);
        },
        methods: {

            unixToPercent(unix) {
                const openingTimestamp = this.day.clone().hours(moment.duration(this.opening).hours())
                    .minutes(moment.duration(this.opening).minutes())
                    .seconds(moment.duration(this.opening).seconds())
                    .unix();

                const closingTimestamp = this.day.clone().hours(moment.duration(this.closing).hours())
                    .minutes(moment.duration(this.closing).minutes())
                    .seconds(moment.duration(this.closing).seconds())
                    .unix();

                return ((unix - openingTimestamp) * 100) / (closingTimestamp - openingTimestamp);
            },

            left(sum) {
                let minus = 0;
                if (this.windowWidth > 0 && this.windowWidth < 572) {
                    minus = 3.5;
                } else if (this.windowWidth < 892) {
                    minus = 3;
                } else if (this.windowWidth < 1192) {
                    minus = 2.5;
                } else if (this.windowWidth < 1592) {
                    minus = 1.5;
                } else {
                    minus = 1;
                }

                return (this.unixToPercent(moment(sum.done_at).unix()) - minus) + '%';
            },

            getWindowWidth() {
                this.windowWidth = document.documentElement.clientWidth;
            },

            getWindowHeight() {
                this.windowHeight = document.documentElement.clientHeight;
            },
        },
    };
</script>

<style
    lang="scss"
    scoped
>

    .icon-type {
        .fa-clock {
            font-size: 26px;
            color: #584ee0;
        }
    }

    .line-icon-type {
        .fa-clock {
            font-size: 23px;
            color: #584ee0;
        }

        &.down {
            //margin-bottom: 8px;
        }

        &.small {
            img {
                height: 30px;
                width: 30px;
            }

            .fa-clock {
                font-size: 23px;
                width: 30px;
                color: #584ee0;
                padding-top: 8px;
            }
        }

        img {
            height: 45px;
            width: 45px;
        }
    }

    .milk-bottle-complementary-icon {
        @apply tw-absolute tw-right-0 tw-bottom-0;
    }
</style>
