<template>
    <div class="transmission">
        <div
            v-if="!selectedMedia"
            class="text-center"
        >
            <MHeading
                class="tw-mb-2"
                level="h1"
            >
                {{ __('family_kid:transmission') }}
            </MHeading>
            <div class="tw-flex tw-flex-row tw-items-center tw-justify-center tw-gap-2">
                <template v-if="!weeklyDisplay">
                    <MDatePicker
                        :value="fromJSDate(date).toISOString()"
                        @input="date = fromISOString($event).toJSDate()"
                    />
                    <CButton
                        v-model="weeklyDisplay"
                        @click="weeklyDisplay = !weeklyDisplay"
                    >
                        <template #left-icons>
                            <FontAwesomeIcon icon="fa fa-calendar"/>
                        </template>
                        {{ __('common:weekly') }}
                    </CButton>
                </template>
                <template v-else>
                    <MDropdown>
                        <CButton>
                            {{ __('common:longweek_with_number', {week: fromJSDate(date).weekNumber}) }}
                        </CButton>
                        <template #content>
                            <div class="tw-flex tw-flex-row tw-items-center tw-gap-2">
                                <CButton
                                    variant="link"
                                    @click="date = fromJSDate(date).subWeeks(1).toJSDate()"
                                >
                                    <template #left-icons>
                                        <FontAwesomeIcon icon="fa fa-angle-left"/>
                                    </template>
                                    {{ __('common:longweek_with_number', {week: fromJSDate(date).subWeeks(1).weekNumber}) }}
                                </CButton>
                                <CButton
                                    variant="link"
                                    @click="date = fromJSDate(date).addWeeks(1).toJSDate()"
                                >
                                    <template #right-icons>
                                        <FontAwesomeIcon icon="fa fa-angle-right"/>
                                    </template>
                                    {{ __('common:longweek_with_number', {week: fromJSDate(date).addWeeks(1).weekNumber}) }}
                                </CButton>
                            </div>
                            <CText>
                                {{
                                    __('common:date_format.human_from_to', {
                                        from: fromDay.toLocaleString(Epoch.presets.DATE_SHORT),
                                        to: toDay.toLocaleString(Epoch.presets.DATE_SHORT),
                                    })
                                }}
                            </CText>
                        </template>
                    </MDropdown>
                    <CButton
                        v-model="weeklyDisplay"
                        @click="weeklyDisplay = !weeklyDisplay"
                    >
                        <template #left-icons>
                            <FontAwesomeIcon icon="fa fa-calendar"/>
                        </template>
                        {{ __('common:daily') }}
                    </CButton>
                </template>
            </div>
        </div>
        <div v-if="!loading">
            <div v-if="!weeklyDisplay">
                <CVStack
                    v-if="!selectedMedia && dailySummaries.length"
                    class="kid-summary"
                    gap="2"
                >
                    <CHStack
                        v-for="(sum, i) in dailySummaries"
                        :key="i"
                        align="center"
                        class="even:tw-bg-gray-50 tw-rounded-lg tw-p-2"
                        gap="3"
                    >
                        <CCenter class="tw-w-10">
                            <SummaryImg :sum="sum"/>
                        </CCenter>
                        <SummaryDescription
                            :selected-media.sync="selectedMedia"
                            :sum="sum"
                        />
                    </CHStack>
                </CVStack>
                <transition
                    v-else-if="selectedMedia"
                    mode="out-in"
                    name="fade"
                >
                    <MMediaFullScreen
                        v-if="selectedMedia"
                        :selected-media="transformedMedia"
                        @close="selectedMedia = undefined"
                    />
                </transition>
                <div
                    v-else
                    class="kid-summary"
                >
                    <MEmptyIndex :title="__('family_kid:empty_index')"/>
                </div>
            </div>
            <div v-else>
                <WeeklyTransmissions
                    v-if="!loading"
                    class="kid-summary"
                    :date="date"
                    :nursery="nursery"
                    :user="user"
                    :weekly-summaries="weeklySummaries"
                />
            </div>
        </div>
        <div
            v-else
            class="kid-summary"
        >
            <loader
                light="true"
                shadow="false"
                size="sm"
                :title="__('common:loading_dots')"
            />
        </div>
    </div>
</template>

<script>
    import useApi from '@/modules/app/composables/useApi';
    import route from '@/modules/legacy/libs/ziggy';
    import _orderBy from 'lodash-es/orderBy';
    import __ from '@/modules/app/utils/i18n-facade';
    import WeeklyTransmissions from '@/modules/legacy/components/Modules/Transmission/Weekly.vue';
    import SummaryImg from '@/modules/legacy/components/Modules/Transmission/SummaryImg.vue';
    import SummaryDescription from '@/modules/legacy/components/Modules/Transmission/SummaryDescription.vue';
    import useEpoch from '@/modules/app/composables/useEpoch';
    import {Epoch, now} from '@meekohq/lumos';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';
    import MMediaFullScreen from '@/modules/meeko-ui/components/MMediaFullScreen.vue';
    import MEmptyIndex from '@/modules/meeko-ui/components/MEmptyIndex.vue';

    export default {
        components: {
            MEmptyIndex,
            MMediaFullScreen,
            SummaryDescription,
            SummaryImg,
            WeeklyTransmissions,
        },
        props: {
            user: {type: Object},
            kid: {type: Object},
            nursery: {type: Object},
        },
        data() {
            return {
                dailySummaries: [],
                Epoch,
                weeklySummaries: [],
                selectedMedia: null,
                loading: false,
                date: now().toJSDate(),
                weeklyDisplay: false,
                delay: {show: 500, hide: 100},
            };
        },
        computed: {
            transformedMedia() {
                // On ecrase la valeur type de selectedMedia car le components générique de media attends le format de la video
                return {
                    ...this.selectedMedia,
                    type: this.selectedMedia.videoType,
                    taken_at: this.selectedMedia.done_at,
                };
            },
            fromDay() {
                return this.fromJSDate(this.date).startOfWeek();
            },
            toDay() {
                return this.fromJSDate(this.date).endOfWeek();
            },
        },
        watch: {
            weeklyDisplay(val) {
                if (val) {
                    this.getWeeklySummaries();
                } else {
                    this.getDailySummaries();
                }
            },
            date: {
                handler(val) {
                    if (val) {
                        if (this.weeklyDisplay) {
                            this.getWeeklySummaries();
                        } else {
                            this.getDailySummaries();
                        }
                    }
                },
                immediate: true,
            },
            selectedMedia(media) {
                this.selectedMedia = media;
            },
        },
        methods: {
            fromISOString: useEpoch().fromISOString,
            fromJSDate: useEpoch().fromJSDate,
            fromTimestamp: useEpoch().fromTimestamp,
            getDailySummaries() {
                this.dailySummaries = [];
                this.selectedMedia = null;
                this.loading = true;

                useApi().legacy.get(
                    route('kids.show', {
                        kid: this.kid,
                        from: this.fromJSDate(this.date)
                            .startOfDay()
                            .toTimestamp(),
                        to: this.fromJSDate(this.date)
                            .endOfDay()
                            .toTimestamp(),
                    }),
                )
                    .then(response => {
                        response.data.presences.forEach(presence => {
                            if (presence.droped_at) {
                                const sum = {};
                                sum.done_at = this.fromTimestamp(presence.droped_at).toISOString();
                                sum.note = presence.drop_note;
                                sum.type = 'arrival';
                                sum.staff = presence.droppedByStaff?.id ? presence.droppedByStaff : null;
                                this.dailySummaries.push(sum);
                            }
                            if (presence.picked_up_at) {
                                const sum = {};
                                sum.done_at = this.fromTimestamp(presence.picked_up_at).toISOString();
                                sum.note = presence.pick_up_note;
                                sum.type = 'departure';
                                sum.staff = presence.pickedUpByStaff?.id ? presence.pickedUpByStaff : null;
                                this.dailySummaries.push(sum);
                            }
                        });

                        response.data.meals.forEach(meal => {
                            const sum = {};
                            sum.done_at = meal.done_at;
                            sum.rating = meal.rating;
                            sum.milk = meal.milk;
                            sum.note = meal.note;
                            sum.type = sum.milk !== null ? 'milk' : 'meals';
                            sum.staff = meal.staff?.id ? meal.staff : null;
                            sum.done_at ? this.dailySummaries.push(sum) : null;
                        });

                        response.data.naps.forEach(nap => {
                            const sum = {};
                            sum.done_at = nap.started_at;
                            sum.started_at = nap.started_at;
                            sum.ended_at = nap.ended_at;
                            sum.rating = nap.rating;
                            sum.note = nap.note;
                            sum.type = 'naps';
                            sum.staff = nap.staff?.id ? nap.staff : null;
                            this.dailySummaries.push(sum);
                        });

                        response.data.temperatures.forEach(temperature => {
                            const sum = {};
                            sum.done_at = temperature.done_at;
                            sum.degree = temperature.degree;
                            sum.note = temperature.note;
                            sum.temperature_type = temperature.type;
                            sum.type = 'temperatures';
                            sum.staff = temperature.staff?.id ? temperature.staff : null;
                            sum.done_at ? this.dailySummaries.push(sum) : null;
                        });

                        response.data.weights.forEach(weight => {
                            const sum = {};
                            sum.done_at = weight.done_at;
                            sum.kilogram = weight.kilogram;
                            sum.note = weight.note;
                            sum.type = 'weights';
                            sum.staff = weight.staff?.id ? weight.staff : null;
                            sum.done_at ? this.dailySummaries.push(sum) : null;
                        });

                        response.data.drugs.forEach(drug => {
                            const sum = {};
                            sum.done_at = drug.done_at;
                            sum.name = drug.name;
                            sum.note = drug.note;
                            sum.drug_type = drug.type;
                            sum.type = 'drugs';
                            sum.staff = drug.staff?.id ? drug.staff : null;
                            sum.done_at ? this.dailySummaries.push(sum) : null;
                        });

                        response.data.diapers.forEach(diaper => {
                            const sum = {};
                            sum.done_at = diaper.done_at;
                            sum.poop = diaper.poop;
                            sum.pee = diaper.pee;
                            sum.note = diaper.note;
                            sum.nose = diaper.nose;
                            sum.eyes = diaper.eyes;
                            sum.ear = diaper.ear;
                            sum.cream = diaper.cream;
                            sum.diaper_type = diaper.type;
                            sum.type = 'diapers';
                            sum.staff = diaper.staff?.id ? diaper.staff : null;
                            this.dailySummaries.push(sum);
                        });

                        response.data.observations.forEach(obs => {
                            obs.type = 'notes';
                            this.dailySummaries.push(obs);
                        });

                        response.data.activities.forEach(activity => {
                            const sum = {};
                            const kid = activity.kids.find(
                                item => item.id === this.kid.id,
                            );
                            sum.done_at = activity.done_at;
                            sum.name = activity.name;
                            sum.category = activity.category;
                            sum.category_icon = activity.category_icon;
                            sum.note = kid ? kid.note : null;
                            sum.type = 'activities';
                            sum.staff = activity.staff?.id ? activity.staff : null;
                            this.dailySummaries.push(sum);
                        });

                        response.data.photos.forEach(media => {
                            const sum = {};
                            sum.done_at = media.taken_at;
                            sum.name = media.video ? __('family_kid:new_video') : __('family_kid:new_photo');
                            sum.photo_url = media.photo_url;
                            sum.video = media.video;
                            sum.videoType = media.type;
                            sum.type = media.video ? 'video' : 'photos';
                            sum.description = media.description;
                            sum.staff = media.staff?.id ? media.staff : null;
                            this.dailySummaries.push(sum);
                        });

                        this.dailySummaries.forEach(sum => {
                            sum.done_at = Number.isInteger(sum.done_at)
                                ? sum.done_at
                                : this.fromJSDate(new Date(sum.done_at)).toTimestamp();
                        });
                        this.dailySummaries = _orderBy(
                            this.dailySummaries,
                            'done_at',
                        );
                        this.loading = false;
                    })
                    .catch(error => {
                        if (error && error.response && error.response.status) {
                            useNotification().error(error);
                            this.loading = false;
                        }
                    });
            },

            getWeeklySummaries() {
                this.weeklySummaries = [];
                this.selectedMedia = null;
                this.loading = true;

                useApi().legacy.get(
                    route('kids.show', {
                        kid: this.kid,
                        from: this.fromJSDate(this.date)
                            .startOfWeek()
                            .toTimestamp(),
                        to: this.fromJSDate(this.date)
                            .endOfWeek()
                            .toTimestamp(),
                    }),
                )
                    .then(response => {
                        response.data.presences.forEach(presence => {
                            if (presence.droped_at) {
                                const sum = {};
                                sum.done_at = presence.droped_at;
                                sum.note = presence.drop_note;
                                sum.type = 'arrival';
                                sum.staff = presence.droppedByStaff?.id ? presence.droppedByStaff : null;
                                this.weeklySummaries.push(sum);
                            }
                            if (presence.picked_up_at) {
                                const sum = {};
                                sum.done_at = presence.picked_up_at;
                                sum.note = presence.pick_up_note;
                                sum.type = 'departure';
                                sum.staff = presence.pickedUpByStaff?.id ? presence.pickedUpByStaff : null;
                                this.weeklySummaries.push(sum);
                            }
                        });

                        response.data.meals.forEach(meal => {
                            const sum = {};
                            sum.done_at = meal.done_at;
                            sum.rating = meal.rating;
                            sum.milk = meal.milk;
                            sum.note = meal.note;
                            sum.type = sum.milk ? 'milk' : 'meals';
                            sum.staff = meal.staff?.id ? meal.staff : null;
                            sum.done_at ? this.weeklySummaries.push(sum) : null;
                        });

                        response.data.naps.forEach(nap => {
                            const sum = {};
                            sum.done_at = nap.started_at;
                            sum.started_at = nap.started_at;
                            sum.ended_at = nap.ended_at;
                            sum.rating = nap.rating;
                            sum.note = nap.note;
                            sum.type = 'naps';
                            sum.staff = nap.staff?.id ? nap.staff : null;
                            this.weeklySummaries.push(sum);
                        });

                        response.data.temperatures.forEach(temperature => {
                            const sum = {};
                            sum.done_at = temperature.done_at;
                            sum.degree = temperature.degree;
                            sum.note = temperature.note;
                            sum.temperature_type = temperature.type;
                            sum.type = 'temperatures';
                            sum.staff = temperature.staff?.id ? temperature.staff : null;
                            sum.done_at ? this.weeklySummaries.push(sum) : null;
                        });

                        response.data.weights.forEach(weight => {
                            const sum = {};
                            sum.done_at = weight.done_at;
                            sum.kilogram = weight.kilogram;
                            sum.note = weight.note;
                            sum.type = 'weights';
                            sum.staff = weight.staff?.id ? weight.staff : null;
                            sum.done_at ? this.weeklySummaries.push(sum) : null;
                        });

                        response.data.drugs.forEach(drug => {
                            const sum = {};
                            sum.done_at = drug.done_at;
                            sum.name = drug.name;
                            sum.note = drug.note;
                            sum.drug_type = drug.type;
                            sum.type = 'drugs';
                            sum.staff = drug.staff?.id ? drug.staff : null;
                            sum.done_at ? this.weeklySummaries.push(sum) : null;
                        });

                        response.data.diapers.forEach(diaper => {
                            const sum = {};
                            sum.done_at = diaper.done_at;
                            sum.poop = diaper.poop;
                            sum.pee = diaper.pee;
                            sum.note = diaper.note;
                            sum.nose = diaper.nose;
                            sum.eyes = diaper.eyes;
                            sum.ear = diaper.ear;
                            sum.cream = diaper.cream;
                            sum.diaper_type = diaper.type;
                            sum.type = 'diapers';
                            sum.staff = diaper.staff?.id ? diaper.staff : null;
                            this.weeklySummaries.push(sum);
                        });

                        response.data.observations.forEach(obs => {
                            obs.type = 'notes';
                            this.weeklySummaries.push(obs);
                        });

                        response.data.activities.forEach(activity => {
                            const sum = {};
                            const kid = activity.kids.find(
                                item => item.id === this.kid.id,
                            );
                            sum.done_at = activity.done_at;
                            sum.name = activity.name;
                            sum.category = activity.category;
                            sum.category_icon = activity.category_icon;
                            sum.note = kid ? kid.note : null;
                            sum.type = 'activities';
                            sum.staff = activity.staff?.id ? activity.staff : null;
                            this.weeklySummaries.push(sum);
                        });

                        response.data.photos.forEach(media => {
                            const sum = {};
                            sum.done_at = media.taken_at;
                            sum.name = media.video ? __('family_kid:new_video') : __('family_kid:new_photo');
                            sum.url = media.photo_url;
                            sum.video = media.video;
                            sum.videoType = media.type;
                            sum.type = media.video ? 'video' : 'photos';
                            sum.description = media.description;
                            sum.staff = media.staff?.id ? media.staff : null;
                            this.weeklySummaries.push(sum);
                        });
                        this.weeklySummaries = _orderBy(
                            this.weeklySummaries,
                            'done_at',
                        );
                        this.loading = false;
                    })
                    .catch(error => {
                        if (error && error.response && error.response.status) {
                            useNotification().error(error);
                            this.loading = false;
                        }
                    });
            },
        },
    };
</script>

<style
    lang="scss"
    scoped
>
    .transmission {
        .kid-summary {
            @apply tw-p-4;
            margin-top: -12px;
            background: none;
            border: 1px solid #e8e8e8;
            border-radius: 20px;

            li {
                margin-top: 15px;
                font-size: 22px;
                line-height: 1.2;

                .time {
                    font-size: 16px;
                }

                div {
                    display: inline-block;
                }

                .description {
                    font-size: 16px;
                    margin-left: 45px;
                    color: #5c7276;

                    div {
                        font-size: 18px;
                    }
                }

                .observations {
                    font-size: 18px;
                    font-weight: 600;
                }
            }
        }
    }

    ul.kid-summary {
        padding: 8px;
        background: #f7f7f7;
        margin: 10px 0;
        border-radius: 5px;
        font-weight: 400;

        li {
            list-style: none;
            min-height: 40px;
            font-size: 20px;
            position: relative;
        }
    }

    .photo {
        img {
            width: 100%;
            border-radius: 10px;
        }
    }
</style>
