<template>
    <div>
        <div
            v-if="minimum && uppercase && lowercase && number"
            class="tw-text-success-500"
        >
            <FontAwesomeIcon
                :fixed-width="true"
                icon="far fa-check-circle"
            />
            {{ __('components:password.your_password_is_secure') }}
        </div>
        <template v-else>
            <div class="tw-grid sm:tw-grid-cols-2 tw-grid-cols-1">
                <div>
                    <ul class="list-unstyled mb-0">
                        <li :class="{'tw-text-green-500': minimum, 'tw-text-red-500': !minimum && formSent}">
                            <i
                                class="far fa-fw mr-1"
                                :class="{'fa-check-circle': minimum, 'fa-times-circle tw-text-red-500': !minimum && formSent, 'fa-times-circle tw-text-gray-200': !minimum && !formSent}"
                            />
                            {{ __('components:password.minimum') }}
                        </li>
                        <li :class="{'tw-text-green-500': number, 'tw-text-red-500': !number && formSent}">
                            <i
                                class="far fa-fw mr-1"
                                :class="{'fa-check-circle': number, 'fa-times-circle tw-text-red-500': !number && formSent, 'fa-times-circle tw-text-gray-200': !number && !formSent}"
                            />
                            {{ __('components:password.number') }}
                        </li>
                    </ul>
                </div>
                <div>
                    <ul class="list-unstyled mb-0">
                        <li :class="{'tw-text-green-500': uppercase, 'tw-text-red-500': !uppercase && formSent}">
                            <i
                                class="far fa-fw mr-1"
                                :class="{'fa-check-circle': uppercase, 'fa-times-circle tw-text-red-500': !uppercase && formSent, 'fa-times-circle tw-text-gray-200': !uppercase && !formSent}"
                            />
                            {{ __('components:password.uppercase') }}
                        </li>
                        <li :class="{'tw-text-green-500': lowercase, 'tw-text-red-500': !lowercase && formSent}">
                            <i
                                class="far fa-fw mr-1"
                                :class="{'fa-check-circle': lowercase, 'fa-times-circle tw-text-red-500': !lowercase && formSent, 'fa-times-circle tw-text-gray-200': !lowercase && !formSent}"
                            />
                            {{ __('components:password.lowercase') }}
                        </li>
                    </ul>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
    export default {
        name: 'PasswordStrengthMeter',
        props: {
            password: {
                type: String,
                required: true,
            },
            formSent: {
                type: Boolean,
                default: false,
                required: false,
            },
        },
        data: () => ({
            minimum: false,
            uppercase: false,
            lowercase: false,
            number: false,
        }),
        watch: {
            password(val) {
                this.minimum = val.length >= 8;
                this.uppercase = val.search(/[A-Z]/) >= 0;
                this.lowercase = val.search(/[a-z]/) >= 0;
                this.number = val.search(/[0-9]/) >= 0;

                this.$emit('update:valid', this.minimum && this.uppercase && this.lowercase && this.number);
            },
        },
    };
</script>
