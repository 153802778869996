<script setup lang="ts">
    import type {PropType} from 'vue';
    import {computed} from 'vue';
    import {Epoch} from '@meekohq/lumos';
    import type InvoiceModel from '@/modules/cashier/models/InvoiceModel';

    const props = defineProps({
        invoice: {
            type: Object as PropType<InvoiceModel>,
            required: true,
        },
    });

    const displayPeriod = computed(() => {
        return props.invoice.attributes.start_period_date && props.invoice.attributes.end_period_date;
    });
</script>

<template>
    <div class="InvoiceMiniatureDescription">
        <div class="InvoiceMiniatureDescription__header">
            <MHeading level="h4">
                {{ __('common:description') }}
            </MHeading>
        </div>
        <div class="InvoiceMiniatureDescription__item">
            {{ __('billing_transaction:kid_name_colon') }}
            <div class="tw-font-semibold">
                {{ invoice.attributes.kid_name }}
            </div>
        </div>
        <div class="InvoiceMiniatureDescription__item">
            {{ __('billing_core:contract_number_colon') }}
            <div class="tw-font-semibold">
                {{ invoice.attributes.contract_no }}
            </div>
        </div>
        <div
            v-if="displayPeriod"
            class="InvoiceMiniatureDescription__item"
        >
            {{ __('billing_invoice:period') }}
            <div class="tw-font-semibold">
                {{
                    __('common:date_format.human_from_to', {
                        from: Epoch.fromISOString(invoice.attributes.start_period_date).toLocaleString(Epoch.presets.DATE_SHORT),
                        to: Epoch.fromISOString(invoice.attributes.end_period_date).toLocaleString(Epoch.presets.DATE_SHORT),
                    })
                }}
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    .InvoiceMiniatureDescription__header {
        @apply tw-bg-gray-100 tw-py-1 tw-px-1.5 tw-rounded-lg tw-mb-1;
    }

    .InvoiceMiniatureDescription__item {
        @apply tw-flex tw-flex-row tw-items-baseline tw-gap-1;
    }
</style>
