import type EndpointModel from '@/modules/cashier/models/EndpointModel';
import {find, findIndex} from 'lodash-es';
import SubscriptionModel from '@/modules/cashier/models/SubscriptionModel';
import type {Model} from '@meekohq/lumos';
import {ModelCollection} from '@meekohq/lumos';

export default class OfflineEndpointSubscriberService<T extends Model> {
    private readonly _endpointModel: EndpointModel;
    private readonly _resourceModel: T;

    constructor(endpointModel: EndpointModel, resourceModel: T) {
        this._endpointModel = endpointModel;
        this._resourceModel = resourceModel;
    }

    private static initRelation(endpointModel: EndpointModel): void {
        if (!endpointModel.subscriptions().value()) {
            endpointModel.subscriptions().set(new ModelCollection());
        }
    }

    isSubscribedToAll(topics: string[]) {
        let subscribed = true;
        topics.forEach(topic => {
            if (!this.isSubscribedToTopic(topic)) {
                subscribed = false;
            }
        });

        return subscribed;
    }

    isSubscribedToOne(topics: string[]) {
        let current = 0;
        topics.forEach(topic => {
            if (this.isSubscribedToTopic(topic)) {
                current++;
            }
        });

        return current > 0;
    }

    isSubscribedToTopic(topic: string): boolean {
        const subscriptions = this._endpointModel.subscriptions().value().all();

        return find(subscriptions, subscription => {
            return subscription.attributes.topic === topic &&
                subscription.attributes.resource_type === this._resourceModel.type &&
                subscription.attributes.resource_id === this._resourceModel.id &&
                subscription.markedForDeletion === false;
        }) !== undefined;
    }

    subscribeToTopic(endpointModel: EndpointModel, topic: string): void {
        OfflineEndpointSubscriberService.initRelation(endpointModel);

        const subscriptions = endpointModel.subscriptions().value().all();

        const index = findIndex(subscriptions, (subscription: SubscriptionModel) => {
            return subscription.attributes.topic === topic &&
                subscription.attributes.resource_type === this._resourceModel.type &&
                subscription.attributes.resource_id === this._resourceModel.id;
        });

        if (index === -1) {
            // Subscription not found
            const newSubscription = new SubscriptionModel();
            newSubscription.attributes.topic = topic;
            newSubscription.attributes.tenant_id = this._resourceModel.attributes.tenant_id;
            newSubscription.attributes.resource_type = this._resourceModel.type;
            newSubscription.attributes.resource_id = this._resourceModel.id;
            newSubscription.attributes.endpoint_id = endpointModel.id;
            subscriptions.push(newSubscription);
        } else {
            const subscription = subscriptions[index];
            subscription.listeners['delete'] = [];
            subscription.markedForDeletion = false;
        }
    }

    unsubscribeFromTopic(endpointModel: EndpointModel, topic: string): void {
        OfflineEndpointSubscriberService.initRelation(endpointModel);

        const subscriptions = endpointModel.subscriptions().value().all();

        const deleteFromRelation = function(subscription) {
            const relation = endpointModel.subscriptions().value();
            endpointModel.subscriptions().set(new ModelCollection<SubscriptionModel>(relation).reject(m => {
                return m.getKey() === subscription.getKey();
            }));
        };

        const index = findIndex(subscriptions, (subscription: SubscriptionModel) => {
            return subscription.attributes.topic === topic &&
                subscription.attributes.resource_type === this._resourceModel.type &&
                subscription.attributes.resource_id === this._resourceModel.id;
        });
        const subscription = subscriptions[index];
        if (subscription.exist) {
            subscription.on('delete', () => deleteFromRelation(subscription));
            subscription.markForDeletion();
        } else {
            // Subscription don't exist in DB, remove from local
            deleteFromRelation(subscription);
        }
    }
}
