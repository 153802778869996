import {clone} from 'lodash-es';
import {ref} from 'vue';
import type BalanceAllocationModel from '@/modules/human-resources/models/BalanceAllocationModel';
import type {Model} from '@meekohq/lumos';

export default function() {
    const allocations = ref<BalanceAllocationModel[]>([]);

    const save = async function <T extends Model>(source: T) {
        for (const allocation of clone(allocations.value)) {
            allocation.attributes.source_id = source.getKey();
            await allocation.save();
        }
    };

    return {
        allocations,
        save,
    };
}
