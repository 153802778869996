<template>
    <CCard class="RowItem">
        <div class="RowItem__container">
            <div class="RowItem__header">
                <KidTitle
                    :nursery="nursery"
                    :registration="registration"
                />
                <div class="RowItem__kid-age">
                    {{ `${useKidAge().getByBirthdate(
                        Epoch.fromISOString(registration.kid_birthdate),
                        Epoch.fromISOString(registration.contract_started_at)
                    )} ${__('registration:on_entry')}` }}
                </div>
                <div class="RowItem__contract-start">
                    {{ __('common:date_format.human_starting_from', {from: parse(registration.contract_started_at, 'yyyy-MM-dd').toLocaleString(Epoch.presets.DATE_SHORT)}) }}
                </div>
            </div>
            <div class="RowItem__col">
                <div class="RowItem__timeline">
                    <div class="RowItem__timeline-row">
                        <div class="RowItem__timeline-date">
                            {{ parse(registration.contract_started_at, 'yyyy-MM-dd').toLocaleString(Epoch.presets.DATE_2_DIGIT) }}
                        </div>
                        <div class="RowItem__duration-container">
                            <span class="RowItem__duration-text">
                                {{
                                    useDuration().toHuman({
                                        dateFrom: Epoch.fromISOString(registration.contract_started_at),
                                        dateTo: Epoch.fromISOString(registration.contract_ended_at),
                                        options: {
                                            autoSuffix: false,
                                            forKids: true,
                                        },
                                    })
                                }}
                            </span>
                            <div class="RowItem__arrow-container">
                                <div class="RowItem__arrow"/>
                                <FontAwesomeIcon
                                    class="RowItem__arrow-angle"
                                    icon="fa-angle-right far"
                                />
                            </div>
                        </div>
                        <div class="RowItem__timeline-date">
                            {{ parse(registration.contract_ended_at, 'yyyy-MM-dd').toLocaleString(Epoch.presets.DATE_2_DIGIT) }}
                        </div>
                    </div>
                </div>
                <div class="RowItem__row">
                    <div class="RowItem__texts">
                        <div class="RowItem__light-gray-text">
                            <FontAwesomeIcon
                                class="RowItem__icon"
                                icon="fas fa-folder"
                            />
                            {{ __('common:registration') }}
                        </div>
                        <div class="RowItem__gray-text">
                            {{ parse(registration.requested_at, 'yyyy-MM-dd').toLocaleString(Epoch.presets.DATE_SHORT) }}
                        </div>
                    </div>
                    <div class="RowItem__texts">
                        <div class="RowItem__light-gray-text">
                            <FontAwesomeIcon
                                class="RowItem__icon"
                                icon="fas fa-money-bill-alt"
                            />
                            {{ __('common:income') }}
                        </div>
                        <div class="RowItem__gray-text">
                            {{ registration.first_parent_salary + registration.second_parent_salary }}{{ nursery.currency }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="RowItem__kid-planning">
                <kid-planning-summary
                    class="mt-2"
                    :nursery="nursery"
                    :plannings="registration.plannings"
                    :weeks="registration.contract_recurrent_weeks"
                />
            </div>
            <div class="RowItem__footer">
                <TagsSelector
                    class="RowItem__tags"
                    :editable="can('update', 'registrations')"
                    :taggable-model="registrationModel"
                    taggable-type="registration"
                    :tags="registrationModel.tags().value().toArray()"
                    @attached="registrationModel.tags().value().push($event)"
                    @detached="registrationModel.tags().mutate(v => v.filter(c => c.getKey() !== $event.getKey()))"
                />
                <CTooltip v-if="can('read', 'invoices')">
                    <CButton
                        round
                        size="lg"
                        variant="light"
                        @click="$emit('estimate', registration)"
                    >
                        <FontAwesomeIcon icon="fas fa-file-invoice"/>
                    </CButton>
                    <template #content>
                        {{ __('common:quote') }}
                    </template>
                </CTooltip>
            </div>
        </div>
    </CCard>
</template>

<script lang="ts">
    import {defineComponent} from 'vue';
    import KidPlanningSummary from '@/modules/legacy/components/Modules/KidPlanningSummary.vue';
    import useAbility from '@/modules/app/composables/useAbility';
    import KidTitle from '@/modules/registration/components/molecules/List/KidTitle.vue';
    import {Epoch} from '@meekohq/lumos';
    import useEpoch from '@/modules/app/composables/useEpoch';
    import useDuration from '@/modules/app/composables/useDuration';
    import TagsSelector from '@/modules/tag/components/TagsSelector.vue';
    import useKidAge from '@/modules/family/composables/kid/useKidAge';

    export default defineComponent({
        components: {
            TagsSelector,
            KidTitle,
            KidPlanningSummary,
        },
        props: {
            nursery: {
                type: Object,
                required: true,
            },
            registration: {
                type: Object,
                required: true,
            },
            registrationModel: {
                type: Object,
                required: true,
            },
        },
        setup() {
            const {can} = useAbility();
            const {parse} = useEpoch();

            return {
                Epoch,
                can,
                parse,
            };
        },
        methods: {useKidAge, useDuration},
    });
</script>

<style lang="scss" scoped>
    .RowItem {
        @apply tw-mb-3;
    }

    .RowItem__container {
        @apply tw-flex lg:tw-flex-row tw-flex-col;
    }

    .RowItem__header {
        @apply lg:tw-mr-auto tw-w-64 tw-flex tw-flex-col tw-justify-center lg:tw-mb-0 tw-mb-3;
    }

    .RowItem__footer {
        @apply tw-flex tw-items-center lg:tw-ml-auto lg:tw-mt-0 tw-mt-3;
    }

    .RowItem__tags {
        @apply tw-mr-4;
    }

    .RowItem__kid-age {
        @apply tw-text-gray-800 tw-uppercase tw-tracking-wide tw-font-bold tw-mt-1;
    }

    .RowItem__contract-start {
        @apply tw-text-gray-600 tw-text-sm tw-uppercase tw-tracking-wide tw-font-bold;
    }

    .RowItem__timeline {
        @apply tw-flex tw-items-center;
    }

    .RowItem__timeline-row {
        @apply tw-flex tw-justify-center tw-items-end;
    }

    .RowItem__timeline-date {
        @apply tw-font-semibold tw-text-sm tw-text-gray-600;
    }

    .RowItem__duration-container {
        @apply tw-flex tw-flex-col tw-items-center tw-mx-3;
    }

    .RowItem__duration-text {
        @apply tw-text-blue-500 tw-font-semibold tw-text-sm;
    }

    .RowItem__arrow-container {
        @apply tw-flex tw-h-5 tw-items-center tw-relative;
    }

    .RowItem__arrow {
        @apply tw-w-16 tw-h-1 tw-bg-blue-300 tw-flex-grow tw-rounded-full;
    }

    .RowItem__arrow-angle {
        @apply tw-absolute tw-text-blue-300 tw-text-3xl tw--right-0.5;
    }

    .RowItem__kid-planning {
        @apply tw-flex tw-items-center lg:tw-ml-12 lg:tw-w-72;
    }

    .RowItem__row {
        @apply tw-flex tw-gap-6 tw-mt-3;
    }

    .RowItem__texts {
        @apply tw-text-sm tw-font-bold;
    }

    .RowItem__gray-text {
        @apply tw-text-gray-800;
    }

    .RowItem__light-gray-text {
        @apply tw-uppercase tw-text-gray-400;
    }

    .RowItem__col {
        @apply tw-flex tw-justify-center tw-flex-col tw-gap-4 lg:tw-mb-0 tw-mb-3;
    }
</style>
