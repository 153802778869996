<script setup lang="ts">
    import useAbility from '@/modules/app/composables/useAbility';
    import useFormatNumber from '@/modules/cashier/composables/useFormatNumber';
    import type CustomerModel from '@/modules/cashier/models/CustomerModel';
    import type {LegacyInvoiceType} from '@/modules/cashier/utils/billing/invoice/LegacyInvoiceType';
    import {Epoch} from '@meekohq/lumos';

    const props = defineProps<{
        invoice: LegacyInvoiceType,
        customer: CustomerModel,
    }>();

    const {allows} = useAbility();
    const {format} = useFormatNumber(props.invoice.currency_iso_code);
</script>

<template>
    <MBox class="TransactionModalSubHeaderInvoice">
        <div class="TransactionModalSubHeaderInvoice__content">
            <div class="TransactionModalSubHeaderInvoice__header">
                <FontAwesomeIcon
                    class="TransactionModalSubHeaderInvoice__icon"
                    icon="fad fa-file-invoice"
                />
                <div>
                    <MHeading
                        level="h3"
                        variant="green"
                    >
                        {{ invoice.no }}
                    </MHeading>
                    <div class="TransactionModalSubHeaderInvoice__date">
                        {{ Epoch.fromISOString(invoice.date).toLocaleString(Epoch.presets.DATE_SHORT) }}
                    </div>
                </div>
                <CBadge
                    v-if="allows('support') && invoice.contract_no"
                    variant="gray"
                >
                    {{ invoice.contract_no }}
                </CBadge>
            </div>
            <div>
                <MHeading level="h4">
                    {{ invoice.kid_name }}
                </MHeading>
                <div class="TransactionModalSubHeaderInvoice__customer">
                    <FontAwesomeIcon
                        class="tw-w-4 tw-text-orange-500"
                        icon="fad fa-file-user"
                    />
                    <div class="tw-font-semibold">
                        {{ customer.attributes.name }}
                    </div>
                </div>
            </div>
            <MHeading level="h2">
                {{ format(invoice.grand_total) }}
            </MHeading>
        </div>
    </MBox>
</template>

<style lang="scss" scoped>
    .TransactionModalSubHeaderInvoice {
        @apply tw-p-4;
    }

    .TransactionModalSubHeaderInvoice__content {
        @apply tw-flex tw-flex-wrap tw-items-center tw-justify-between tw-gap-4;
    }

    .TransactionModalSubHeaderInvoice__header {
        @apply tw-flex tw-flex-row tw-items-center tw-gap-3;
    }

    .TransactionModalSubHeaderInvoice__icon {
        @apply tw-text-green-500 tw-text-3xl;
    }

    .TransactionModalSubHeaderInvoice__date {
        @apply tw-text-gray-400 tw-font-display tw-text-base tw-tracking-wider;
    }

    .TransactionModalSubHeaderInvoice__customer {
        @apply tw-flex tw-flex-row tw-items-baseline tw-gap-1;
    }
</style>
