import type {AxiosResponse} from 'axios';
import OrderModel from '@/modules/cashier/models/OrderModel';
import route from '@/modules/legacy/libs/ziggy';
import type FamilyModel from '@/modules/family/models/FamilyModel';
import type ContactModel from '@/modules/cashier/models/ContactModel';
import type MemberModel from '@/modules/family/models/MemberModel';
import useApi from '@/modules/app/composables/useApi';

export type PayloadType = {
    companyOrderModel: OrderModel | undefined,
    familyOrderModel: OrderModel | undefined,
    familyEndpoints: Array<ContactModel | MemberModel>,
    companyEndpoints: Array<ContactModel | MemberModel>,
    familyModel: FamilyModel | undefined,
};

export default class LegacyContractIntegrationService {
    private readonly originalContract: any;
    private _cashierPayload: PayloadType;

    constructor(contract: any, cashierPayload: PayloadType) {
        this.originalContract = contract;
        this._cashierPayload = cashierPayload;
    }

    static async saveEndpointsAndSubscriptions(models: Array<ContactModel | MemberModel>) {
        for (const model of models) {
            const endpointModel = model.notificationEndpoint().value();

            const subscriptions = endpointModel.subscriptions().value().all();

            if (subscriptions && subscriptions.length) {
                await endpointModel.save();
                const promises = [] as any[];
                for (const subscriptionModel of subscriptions) {
                    promises.push(subscriptionModel.save());
                }
                await Promise.all(promises);
            }
        }
    }

    async postUpdate(response: AxiosResponse) {
        const contract = response.data;
        const {
            familyOrderModel,
            companyOrderModel,
            companyEndpoints,
            familyEndpoints,
        } = this.extractContractBag();

        // Update Family OrderModel
        if (familyOrderModel) {
            if (familyOrderModel.extra.markForDelete) {
                await familyOrderModel.delete();
            } else {
                familyOrderModel.attributes.status = contract.draft ? OrderModel.DRAFT : OrderModel.PENDING;
                familyOrderModel.attributes.number = `${contract.no}-${contract.id}`;
                const familyOrder = await familyOrderModel.save();

                if (contract.family_order_id !== familyOrder.id) {
                    await this.attachContractToOrder(contract, 'family_order_id', {
                        family_order_id: familyOrder.id,
                        draft: contract.draft,
                    }, familyOrder);
                }

                // After saving the order, we save the related endpoints and subscriptions
                await LegacyContractIntegrationService.saveEndpointsAndSubscriptions(familyEndpoints);
            }

        }


        // Update Company OrderModel
        if (companyOrderModel) {
            if (companyOrderModel.extra.markForDelete) {
                await companyOrderModel.delete();
            } else {
                companyOrderModel.attributes.status = contract.draft ? OrderModel.DRAFT : OrderModel.PENDING;
                companyOrderModel.attributes.number = contract.no + '-E' + '-' + contract.id;
                const companyOrder = await companyOrderModel.save();

                if (this.originalContract.company_order_id !== companyOrder.id) {
                    await this.attachContractToOrder(contract, 'company_order_id', {
                        company_order_id: companyOrder.id,
                        draft: contract.draft,
                    }, companyOrder);
                }

                // After saving the order, we save the related endpoints and subscriptions
                await LegacyContractIntegrationService.saveEndpointsAndSubscriptions(companyEndpoints);
            }
        }
    }

    async postCreate(response: AxiosResponse) {
        const contract = response.data;
        const {
            familyOrderModel,
            companyOrderModel,
            companyEndpoints,
            familyEndpoints,
        } = this.extractContractBag();

        // Save Family OrderModel
        if (familyOrderModel) {
            familyOrderModel.attributes.status = contract.draft ? OrderModel.DRAFT : OrderModel.PENDING;
            familyOrderModel.attributes.number = `${contract.no}-${contract.id}`;
            const familyOrder = await familyOrderModel.save();

            await this.attachContractToOrder(contract, 'family_order_id', {
                family_order_id: familyOrder.id,
                draft: contract.draft,
            }, familyOrder);

            // After saving the order, we save the related endpoints and subscriptions
            await LegacyContractIntegrationService.saveEndpointsAndSubscriptions(familyEndpoints);
        }

        // Save Company OrderModel
        if (companyOrderModel) {
            companyOrderModel.attributes.status = contract.draft ? OrderModel.DRAFT : OrderModel.PENDING;
            companyOrderModel.attributes.number = `${contract.no}-E-${contract.id}`;
            const companyOrder = await companyOrderModel.save();

            await this.attachContractToOrder(contract, 'company_order_id', {
                company_order_id: companyOrder.id,
                draft: contract.draft,
            }, companyOrder);

            // After saving the order, we save the related endpoints and subscriptions
            await LegacyContractIntegrationService.saveEndpointsAndSubscriptions(companyEndpoints);
        }
    }

    private extractContractBag() {
        return {
            familyOrderModel: this._cashierPayload.familyOrderModel,
            companyOrderModel: this._cashierPayload.companyOrderModel,
            companyEndpoints: this._cashierPayload.companyEndpoints,
            familyEndpoints: this._cashierPayload.familyEndpoints,
            familyModel: this._cashierPayload.familyModel,
        };
    }

    private async attachContractToOrder(contract, key: string, data: any = {}, orderModel: OrderModel) {
        await useApi().legacy.put(route('kid.contracts.update', {
            contract: contract.id,
        }), data);

        this.originalContract[key] = orderModel.id;
        contract[key] = orderModel.id;

        return true;
    }
}
