import {ref, type Ref, watch} from 'vue';
import type {MCalendarMode, UseMCalendar} from '@/modules/meeko-ui/components/MCalendar/useMCalendarState';
import injectableKey from '@/modules/app/utils/injectableKey';
import useMCalendarUtils from '@/modules/meeko-ui/components/MCalendar/useMCalendarUtils';
import type {DateTimePeriod} from '@/modules/meeko-ui/types/DateTime';
import type {DateTimeModifiers, DateTimeModifiersPeriod} from '@/modules/meeko-ui/composables/useDateTimeModifiers';
import useDateTimeModifiers from '@/modules/meeko-ui/composables/useDateTimeModifiers';

export type MCalendarInputFocusScope = undefined | 'from' | 'to';

export const keyUseMCalendarInputState = injectableKey<ReturnType<typeof useMCalendarInputState>>();

export default function useMCalendarInputState(
    userValue: UseMCalendar,
    allowUndefined: boolean,
    mode: MCalendarMode,
    modifiers: DateTimeModifiers | undefined,
) {
    const {cloneUserValue, isUserValueEqualsToInternalValue} = useMCalendarUtils();

    const internalValue = ref(cloneUserValue(userValue)) as Ref<DateTimePeriod>;
    const focusScope = ref<MCalendarInputFocusScope>(undefined);

    let unit: 'minute' | 'day' | 'month' | 'year';
    switch (mode) {
        case 'dateTime':
        case 'time':
            unit = 'minute';
            break;
        case 'date':
            unit = 'day';
            break;
        case 'month':
            unit = 'month';
            break;
        case 'year':
            unit = 'year';
            break;
        default:
            throw new Error(`Unknown mode: ${mode}`);
    }

    function toggleFocusScope(scope: 'from' | 'to', value: boolean) {
        if (value) {
            focusScope.value = scope;

            return;
        }

        if (scope === 'from' && focusScope.value === 'from') {
            focusScope.value = 'to';

            return;
        }

        if (scope === 'to' && focusScope.value === 'to') {
            focusScope.value = undefined;

            return;
        }

        focusScope.value = scope;
    }

    function setDates(dates: DateTimePeriod) {

        if (!userValue.isPeriod) {
            setDatesSingle(dates);

            return;
        }

        setDatesPeriod(dates);
    }

    function setDatesSingle(dates: DateTimePeriod) {
        const fromStartOfUnit = dates.from?.startOf(unit);
        const {modifySingle} = useDateTimeModifiers(modifiers);

        // If the value is undefined and allowUndefined is true, set the value to undefined
        if (!dates.from) {
            if (allowUndefined) {
                internalValue.value = {
                    from: undefined,
                    to: undefined,
                };

                return;
            }

            return;
        }

        // Otherwise, set the value to the start of the unit and run the modifier
        internalValue.value = {
            from: modifySingle(fromStartOfUnit),
            to: undefined,
        };
    }

    function setDatesPeriod(dates: DateTimePeriod) {
        const fromStartOfUnit = dates.from?.startOf(unit);
        const fromEndtOfUnit = dates.from?.endOf(unit);
        const toStartOfUnit = dates.to?.startOf(unit);
        const toEndOfUnit = dates.to?.endOf(unit);

        const periodModifiers = modifiers as DateTimeModifiersPeriod | undefined;
        const {modifySingle: modifyFrom} = useDateTimeModifiers(periodModifiers?.from);
        const {modifySingle: modifyTo} = useDateTimeModifiers(periodModifiers?.to);

        // If both dates are undefined and allowUndefined is true, set the value to undefined
        if (!dates.from && !dates.to) {
            if (allowUndefined) {
                internalValue.value = {
                    from: undefined,
                    to: undefined,
                };

                return;
            }

            return;
        }

        // If date is infinite set the from and to value
        if (!dates.from || !dates.to) {
            internalValue.value = {
                from: modifyFrom(fromStartOfUnit),
                to: modifyTo(toEndOfUnit),
            };

            return;
        }

        // If the from date is less than or equal to the to date, set the from and to from value
        if (modifyFrom(fromStartOfUnit)!.lessThanOrEqualTo(modifyTo(toStartOfUnit))) {
            internalValue.value = {
                from: modifyFrom(fromStartOfUnit),
                to: modifyTo(toEndOfUnit),
            };

            return;
        }

        // If the from date is greater than the to date, set the from and to from value
        if (modifyFrom(fromStartOfUnit)!.equalTo(modifyFrom(internalValue.value.from))) {
            internalValue.value = {
                from: modifyFrom(toStartOfUnit),
                to: modifyTo(toEndOfUnit),
            };

            return;
        }

        internalValue.value = {
            from: modifyFrom(fromStartOfUnit),
            to: modifyTo(fromEndtOfUnit),
        };
    }

    watch(userValue.value, () => {
        if (!isUserValueEqualsToInternalValue(userValue, internalValue.value)) {
            internalValue.value = cloneUserValue(userValue);
        }
    });

    return {
        allowUndefined,

        mode,
        internalValue,

        toggleFocusScope,
        focusScope,
        setDates,
    };
}
