import type {GetTasksUseCase} from '@/modules/activity/ticket/application/use-cases/GetTasksUseCase';
import type TicketFilterDto from '@/modules/activity/ticket/application/dto/TicketFilterDto';
import type {ModelCollection} from '@meekohq/lumos';
import type TicketModel from '@/modules/activity/ticket/domain/TicketModel';
import type TicketRepositoryPort from '@/modules/activity/ticket/application/ports/TicketRepositoryPort';
import type TicketRelationOptionDto from '@/modules/activity/ticket/application/dto/TicketRelationOptionDto';

export class GetTaskService implements GetTasksUseCase
{
    constructor(
        private readonly ticketRepository: TicketRepositoryPort,
    ) {}

    /**
     * Retrieves tasks based on the provided filters and load relations depending on optionnal options.
     */
    public async getTasksByFilters(filters: TicketFilterDto, relationOptions?: TicketRelationOptionDto): Promise<ModelCollection<TicketModel>> {
        const tickets = await this.ticketRepository.getByFilters(filters);
        await this.ticketRepository.loadTicketsRelations(tickets, relationOptions);

        return tickets;
    }
}
