<template>
    <div
        ref="familyMembersListItemEl"
        class="FamilyMembersListItem"
    >
        <MagicIndexItemColumn
            class="FamilyMembersListItem__column-name"
            :grid-enabled="false"
        >
            <div class="tw-flex tw-gap-3 tw-items-center tw-min-w-0">
                <BadgeTasks :tasks="tasksByMemberId[familyMember.getKey()]">
                    <MemberAvatar
                        class="tw-w-10"
                        :member-model="familyMember"
                    />
                </BadgeTasks>
                <MListItemTitle
                    :title="$fullName(familyMember.attributes)"
                    :variant="familyMember.attributes.gender === 'male' ? 'male' : 'female'"
                />
            </div>
        </MagicIndexItemColumn>
        <MagicIndexItemColumn
            class="FamilyMembersListItem__column-phone"
            :container-width="width"
            :grid-title="__('common:phone.general')"
            :is-empty="!hasAtLeastOnePhone(familyMember)"
        >
            <div class="tw-flex tw-flex-col tw-gap-2">
                <MListIcon
                    v-if="familyMembersPhones[familyMember.attributes.id].mobile"
                    icon="fa fa-mobile"
                    :title="familyMembersPhones[familyMember.attributes.id].mobile"
                />
                <MListIcon
                    v-if="familyMembersPhones[familyMember.attributes.id].landline"
                    icon="fa fa-phone"
                    :title="familyMembersPhones[familyMember.attributes.id].landline"
                />
                <MListIcon
                    v-if="familyMembersPhones[familyMember.attributes.id].office"
                    icon="fad fa-phone-office"
                    :title="familyMembersPhones[familyMember.attributes.id].office"
                />
            </div>
        </MagicIndexItemColumn>
        <MagicIndexItemColumn
            class="FamilyMembersListItem__column-email"
            :container-width="width"
            :grid-title="__('common:email')"
            :is-empty="!familyMember.attributes.email"
        >
            <div class="tw-truncate">
                {{ familyMember.attributes.email }}
            </div>
        </MagicIndexItemColumn>
        <MagicIndexItemColumn
            class="FamilyMembersListItem__column-tags"
            :container-width="width"
            :grid-title="__('common:tags')"
        >
            <TagsSelector
                :editable="can('update', 'family_members_details')"
                prevent-default
                size="sm"
                :taggable-model="familyMember"
                taggable-type="family_member"
                :tags="familyMember.tags().value().toArray()"
                @attached="familyMember.tags().value().push($event)"
                @detached="familyMember.tags().mutate(v => v.filter(c => c.getKey() !== $event.getKey()))"
            />
        </MagicIndexItemColumn>
    </div>
</template>

<script lang="ts">
    import {computed, defineComponent, type PropType, ref} from 'vue';
    import BadgeTasks from '@/modules/activity/components/pages/BadgeTasks.vue';
    import MListItemTitle from '@/modules/meeko-ui/components/MListItemTitle.vue';
    import TagsSelector from '@/modules/tag/components/TagsSelector.vue';
    import MemberAvatar from '@/modules/app/components/atoms/avatars/MemberAvatar.vue';
    import MagicIndexItemColumn from '@/modules/magic-index/components/atoms/MagicIndexItemColumn.vue';
    import MListIcon from '@/modules/meeko-ui/components/MListIcon.vue';
    import useAbility from '@/modules/app/composables/useAbility';
    import type MemberModel from '@/modules/family/models/MemberModel';
    import {useElementSize} from '@vueuse/core';

    export default defineComponent({
        components: {
            MListIcon,
            MagicIndexItemColumn,
            MemberAvatar,
            TagsSelector,
            MListItemTitle,
            BadgeTasks,
        },
        props: {
            familyMember: {
                type: Object as PropType<MemberModel>,
                required: true,
            },
            paginator: {
                type: Object,
                required: true,
            },
            tasksByMemberId: {
                type: Object,
                required: true,
            },
        },
        setup(props) {
            const {can} = useAbility();
            const familyMembersListItemEl = ref(null);
            const {width} = useElementSize(familyMembersListItemEl);

            const familyMembersPhones = computed(() => {
                const familyMemberPhones = {};

                if (!props.paginator?.items().count()) {
                    return familyMemberPhones;
                }

                props.paginator.items().each((familyMember: MemberModel) => {
                    const phones = {
                        mobile: familyMember.attributes.mobile_phone,
                        landline: familyMember.attributes.phone,
                        office: familyMember.attributes.office_phone,
                    };

                    if (familyMember.attributes.id) {
                        familyMemberPhones[familyMember.attributes.id] = phones;
                    }
                });

                return familyMemberPhones;
            });

            function hasAtLeastOnePhone(familyMember: MemberModel) {
                return !!(familyMember.attributes.mobile_phone || familyMember.attributes.phone || familyMember.attributes.office_phone);
            }

            return {
                can,
                familyMembersListItemEl,
                width,
                familyMembersPhones,
                hasAtLeastOnePhone,
            };
        },
    });
</script>

<style lang="scss" scoped>
    .FamilyMembersListItem {
        @apply tw-grid tw-grid-cols-24 tw-items-center tw-gap-3;
        container-type: inline-size;
        container-name: FamilyMembersListItem;
    }

    .FamilyMembersListItem__column-name {
        @apply tw-col-span-full;

        @container FamilyMembersListItem (min-width: 48rem) {
            @apply tw-col-span-10;
        }

        @container FamilyMembersListItem (min-width: 64rem) {
            @apply tw-col-span-12;
        }
    }

    .FamilyMembersListItem__column-phone {
        @apply tw-col-span-full;

        @container FamilyMembersListItem (min-width: 48rem) {
            @apply tw-col-span-4;
        }
    }

    .FamilyMembersListItem__column-email {
        @apply tw-col-span-full;

        @container FamilyMembersListItem (min-width: 48rem) {
            @apply tw-col-span-6;
        }

        @container FamilyMembersListItem (min-width: 64rem) {
            @apply tw-col-span-5;
        }
    }

    .FamilyMembersListItem__column-tags {
        @apply tw-col-span-full;

        @container FamilyMembersListItem (min-width: 48rem) {
            @apply tw-col-span-4;
        }

        @container FamilyMembersListItem (min-width: 64rem) {
            @apply tw-col-span-3;
        }
    }
</style>
