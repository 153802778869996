import {Model} from '@meekohq/lumos';
import ContractModel from '@/modules/human-resources/models/ContractModel';
import OrganizationModel from '@/modules/organization/models/OrganizationModel';


export default class ContractOrganizationPivot extends Model {
    public type = 'hr/contracts_organizations_pivot';

    public attributes: {
        id: string | undefined
        account_id: string | number | undefined;
        contract_id: string | undefined;
        organization_id: string | undefined;
    } = {
            id: this.uuid(),
            account_id: undefined,
            contract_id: undefined,
            organization_id: undefined,
        };

    contract() {
        return this.belongsTo('contract', ContractModel, 'contract_id');
    }

    organization() {
        return this.belongsTo('organization', OrganizationModel, 'organization_id');
    }
}
