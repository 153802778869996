<template>
    <MModal
        :modal="modal"
        size="7xl"
    >
        <template #header>
            <div class="ContractEditModal__header">
                {{ __('family_contract:contract_number', {number: contract.no}) }}
                <support-meeko :ressource="contract"/>
            </div>
        </template>

        <CTabMenu class="tw-mb-4">
            <CTabMenuItem
                :active="selectedTab === 'modalites'"
                @click="selectedTab = 'modalites'"
            >
                <FontAwesomeIcon
                    class="tw-mr-2"
                    icon="fa fa-pencil"
                />
                {{ __('common:terms') }}
            </CTabMenuItem>
            <CTabMenuItem
                v-if="contract.type === 'recurrent'"
                :active="selectedTab === 'planning'"
                @click="selectedTab = 'planning'"
            >
                <FontAwesomeIcon
                    class="tw-mr-2"
                    icon="fa fa-calendar"
                />
                {{ __('common:planning_one') }}
            </CTabMenuItem>
            <CTabMenuItem
                v-if="contract.type === 'recurrent' && hasAdaptation"
                :active="selectedTab === 'adaptation'"
                @click="selectedTab = 'adaptation'"
            >
                <FontAwesomeIcon
                    class="tw-mr-2"
                    icon="fa fa-users"
                />
                {{ __('common:adaptation_other') }}
            </CTabMenuItem>
            <CTabMenuItem
                v-if="$can('update', 'kids_contracts')"
                :active="selectedTab === 'ressources'"
                @click="selectedTab = 'ressources'"
            >
                <FontAwesomeIcon
                    class="tw-mr-2"
                    icon="fa fa-suitcase"
                />
                {{ __('common:resources') }}
            </CTabMenuItem>
            <CTabMenuItem
                v-if="$can('read', 'invoices')"
                :active="selectedTab === 'tarifs'"
                @click="selectedTab = 'tarifs'"
            >
                <FontAwesomeIcon
                    class="tw-mr-2"
                    icon="fa fa-wallet"
                />
                {{ __('common:billing') }}
            </CTabMenuItem>
            <CTabMenuItem
                v-if="contract.company && $can('read', 'invoices')"
                :active="selectedTab === 'company'"
                @click="selectedTab = 'company'"
            >
                <FontAwesomeIcon
                    class="tw-mr-2"
                    icon="fa fa-city"
                />
                {{ __('common:company_one') }}
                <MAlertBadge
                    class="tw-ml-2"
                    :visible="errors.company"
                />
            </CTabMenuItem>
            <CTabMenuItem
                v-if="$can('read', 'invoices')"
                :active="selectedTab === 'sepa'"
                @click="selectedTab = 'sepa'"
            >
                <FontAwesomeIcon
                    class="tw-mr-2"
                    icon="fa fa fa-university"
                />
                {{ __('common:sepa_direct_debit') }}
            </CTabMenuItem>
        </CTabMenu>

        <module-modalites
            v-if="selectedTab === 'modalites'"
            :contract="contract"
            :disabled="disabled"
            :kid="kid"
            :nursery="nursery"
        />
        <module-planning
            v-if="selectedTab === 'planning'"
            :contract="contract"
            :disabled="disabled"
            :nursery="nursery"
        />
        <module-adaptation
            v-if="selectedTab === 'adaptation' && hasAdaptation"
            :contract="contract"
            :nursery="nursery"
        />
        <module-ressources
            v-if="selectedTab === 'ressources'"
            :contract="contract"
            :disabled="disabled"
            :kid="kid"
            :nursery="nursery"
        />
        <module-tarifs
            v-if="selectedTab === 'tarifs'"
            :cashier-payload="cashierPayload"
            :contract="contract"
            :disabled="disabled"
            :kid="kid"
            :nursery="nursery"
            :updating="updating"
            :user="user"
        />
        <module-company
            v-if="selectedTab === 'company'"
            :cashier-payload="cashierPayload"
            :contract="contract"
            :kid="kid"
            :nursery="nursery"
            @has-errors="errors.company = $event"
        />
        <module-sepa
            v-if="selectedTab === 'sepa'"
            :contract="contract"
            :disabled="disabled"
            :kid="kid"
            :nursery="nursery"
            :user="user"
            @saveBeforeDownloadTemplate="saveBeforeDownloadTemplate"
        />

        <template #footer-start>
            <download-template
                v-if="contract.config && $can('read', 'invoices')"
                class="mr-auto"
                :disabled="updating"
                doc-type="contract"
                dropup
                :item="contract"
                :loading="loading"
                :nursery="nursery"
                :user="user"
                wait-for-callback
                @saveBeforeDownloadTemplate="saveBeforeDownloadTemplate"
            />
        </template>
        <template #footer-end="{hide}">
            <MButton
                variant="light"
                @click="hide"
            >
                {{ __('common:actions.cancel') }}
            </MButton>

            <MButton
                v-if="contract.draft"
                :disabled="hasErrors"
                :loading="updating || isGeneratingInvoices"
                @click="saveAsDraft"
            >
                <template #left-icons>
                    <FontAwesomeIcon icon="fas fa-file-pen"/>
                </template>
                {{ __('family_contract:save_in_draft') }}
            </MButton>
            <MButton
                v-if="contract.draft"
                :disabled="hasErrors"
                :loading="updating || isGeneratingInvoices"
                variant="primary"
                @click="warnSave"
            >
                <template #left-icons>
                    <FontAwesomeIcon icon="fas fa-check"/>
                </template>
                {{ __('family_contract:confirm_contract_dots') }}
            </MButton>
            <MButton
                v-else
                :disabled="hasErrors"
                :loading="updating || isGeneratingInvoices"
                variant="primary"
                @click="save(0)"
            >
                <template #left-icons>
                    <FontAwesomeIcon icon="fas fa-save"/>
                </template>
                {{ __('family_contract:save_contract') }}
            </MButton>
        </template>
    </MModal>
</template>

<script>
    import Vue from 'vue';
    import moment from 'moment';
    import swal from 'sweetalert2/dist/sweetalert2.js';
    import route from '@/modules/legacy/libs/ziggy';
    import useApi from '@/modules/app/composables/useApi';
    import _head from 'lodash-es/head';
    import _forEach from 'lodash-es/forEach';
    import _cloneDeep from 'lodash-es/cloneDeep';
    import Modalites from './Modal/Modalites.vue';
    import Planning from './Modal/Planning.vue';
    import Adaptation from './Modal/Adaptation.vue';
    import Ressources from './Modal/Ressources.vue';
    import Tarifs from './Modal/Facturation.vue';
    import Sepa from './Modal/Sepa.vue';
    import Company from './Modal/Company.vue';
    import DownloadTemplate from '@/modules/legacy/components/Modules/DownloadTemplate.vue';
    import LegacyContractIntegrationService from '@/modules/family/utils/kid/LegacyContractIntegrationService';
    import __ from '@/modules/app/utils/i18n-facade';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';
    import MAlertBadge from '@/modules/meeko-ui/components/MAlertBadge.vue';
    import {useLegacyKidContractErrors} from '@/modules/family/composables/kid/useLegacyKidContractErrors.ts';

    export default {
        components: {
            MAlertBadge,
            'module-modalites': Modalites,
            'module-planning': Planning,
            'module-adaptation': Adaptation,
            'module-ressources': Ressources,
            'module-tarifs': Tarifs,
            'module-sepa': Sepa,
            'module-company': Company,
            DownloadTemplate,
        },
        props: ['kid', 'nursery', 'contract', 'disabled', 'user', 'modal'],
        data() {
            return {
                selectedTab: 'modalites',
                loading: false,
                updating: false,
                isGeneratingInvoices: false,
                cashierPayload: {
                    companyOrderModel: undefined,
                    familyOrderModel: undefined,
                    familyEndpoints: [],
                    companyEndpoints: [],
                    familyModel: undefined,
                },
                errors: {
                    company: false,
                },
            };
        },
        computed: {
            moment() {
                return moment;
            },
            hasAdaptation() {
                return this.contract.plannings && this.contract.plannings.filter(item => item.type === 'adaptation').length;
            },
            hasErrors() {
                return this.errors.company;
            },
        },
        mounted() {
            this.cashierPayload.companyOrderModel = undefined;
            this.cashierPayload.familyOrderModel = undefined;
            this.cashierPayload.familyModel = undefined;
            this.cashierPayload.familyEndpoints = [];
            this.cashierPayload.companyEndpoints = [];

            // Check contract errors on modal open
            this.errors = useLegacyKidContractErrors().getLegacyContractErrors(this.contract);

            this.$bus.$on('generate:invoices', () => {
                this.generateInvoices();
            });
        },
        beforeDestroy() {
            this.$bus.$off('generate:invoices');
        },
        methods: {
            warnSave() {
                swal({
                    title: __('family_contract:validate_contract_ask'),
                    text: __('family_contract:validate_contract_warning'),
                    type: 'warning',
                    confirmButtonClass: 'btn btn-primary mr-2',
                    confirmButtonText: __('common:actions.validate'),
                    cancelButtonText: __('common:actions.cancel'),
                }).then(result => {
                    if (result.value) {
                        this.contract.draft = 0;
                        this.save(error => {
                            if (!error) {
                                useNotification().success(__('family_contract:contract_now_validated'));
                                this.modal.hide();
                                this.$emit('closed', this.kid.contracts[this.contract.index]);
                            } else {
                                this.contract.draft = 1;
                            }
                        });
                    }
                });
            },

            /**
             * Save contract and related models
             * @param callback
             */
            save(callback) {
                this.updating = true;

                // Save sepa if newly created
                if ((this.contract.temporaryFamilySepa && this.contract.temporaryFamilySepa.id === null) && (this.contract.temporaryCompanySepa && this.contract.temporaryCompanySepa.id === null)) {
                    this.saveSepa(error => {
                        if (error) {
                            this.updating = false;
                        } else {
                            this.saveSepa(error => {
                                if (error) {
                                    this.updating = false;
                                } else {
                                    this.save();
                                }
                            }, this.contract.temporaryCompanySepa, true);
                        }
                    }, this.contract.temporaryFamilySepa);
                } else if (this.contract.temporaryFamilySepa && this.contract.temporaryFamilySepa.id === null) {
                    this.saveSepa(error => {
                        if (error) {
                            this.updating = false;
                        } else {
                            this.save();
                        }
                    }, this.contract.temporaryFamilySepa);
                } else if (this.contract.temporaryCompanySepa && this.contract.temporaryCompanySepa.id === null) {
                    this.saveSepa(error => {
                        if (error) {
                            this.updating = false;
                        } else {
                            this.save();
                        }
                    }, this.contract.temporaryCompanySepa, true);
                } else {
                    const data = _cloneDeep(this.contract);

                    if (data.broked_at === undefined) {
                        data.broked_at = null;
                    }

                    if (data.company_cycle === null || data.company_cycle === 0) {
                        data.company_cycle = 1;
                    }

                    if (this.contract.type !== 'recurrent') {
                        data.plannings = [];
                    }

                    if (data.mode === 'manuel') {
                        data.cafpro_resources = null;
                    }

                    // Save billing config then update kid contract
                    this.saveBillingConfig(error => {
                        if (error) {
                            this.updating = false;
                            callback(error);
                        } else {
                            useApi().legacy.put(route('kid.contracts.update', {
                                contract: this.contract.id,
                            }), data).then(async response => {
                                const contractExtension = new LegacyContractIntegrationService(this.contract, this.cashierPayload);
                                await contractExtension.postUpdate(response);

                                this.contract.billing_config_id = response.data.billing_config_id;
                                Vue.set(this.kid.contracts, this.contract.index, response.data);
                                if (typeof callback === 'function') {
                                    callback();
                                } else {
                                    useNotification().success(__('family_contract:contract_saved'));
                                    this.modal.hide();
                                    this.$emit('closed', this.kid.contracts[this.contract.index]);
                                }
                                this.updating = false;
                            }).catch(error => {
                                this.updating = false;
                                if (error?.response?.status === 422) {
                                    _forEach(error.response.data.errors, value => {
                                        useNotification().error(_head(value));
                                    });
                                } else {
                                    useNotification().error(error);
                                }
                                callback(error);
                            });
                        }
                    });
                }
            },

            saveBillingConfig(callback) {
                if (this.contract.billing_config_id && this.contract.config) {
                    useApi().legacy.put(route('billingConfigs.update', {
                        billingConfig: this.contract.billing_config_id,
                    }), this.contract.config)
                        .then(response => {
                            this.contract.config = response.data;
                            if (typeof callback === 'function') {
                                callback();
                            }
                        }).catch(error => {
                            this.loading = false;
                            if (error?.response?.status === 422) {
                                _forEach(error.response.data.errors, value => {
                                    useNotification().error(_head(value));
                                });
                            } else {
                                useNotification().error(error);
                            }
                            callback(error);
                        });
                } else {
                    const data = _cloneDeep(this.contract.config);
                    data.contract_id = this.contract.id;
                    useApi().legacy.post(route('billingConfigs.store'), data)
                        .then(response => {
                            Vue.set(this.contract, 'config', response.data);
                            if (typeof callback === 'function') {
                                callback();
                            }
                        }).catch(error => {
                            this.loading = false;
                            if (error?.response?.status === 422) {
                                _forEach(error.response.data.errors, value => {
                                    useNotification().error(_head(value));
                                });
                            } else {
                                useNotification().error(error);
                            }
                            callback(error);
                        });
                }
            },

            /*
                Save the contract before executing the callback and download the pdf
             */
            saveBeforeDownloadTemplate(callback) {
                this.loading = true;
                useNotification().info(__('family_contract:saving_contract_before_download_dots'));
                this.save(() => {
                    this.loading = false;
                    useNotification().success(__('family_contract:contract_saved_successfully'));

                    // The callback must return an object with a done method to conform to this signature
                    return callback.done(true);
                });
            },

            saveAsDraft() {
                this.save(error => {
                    if (!error) {
                        useNotification().success(__('family_contract:contract_save_in_draft_successfully'));
                        this.modal.hide();
                        this.$emit('closed', this.kid.contracts[this.contract.index]);
                    }
                });
            },

            saveSepa(callback, sepa, company = false) {
                useApi().legacy.post(route('sepas.store'), sepa)
                    .then(response => {

                        if (company) {
                            this.contract.company_sepa_mandate_id = response.data.id;
                            this.contract.temporaryCompanySepa = null;
                        } else {
                            this.contract.sepa_mandate_id = response.data.id;
                            this.contract.temporaryFamilySepa = null;
                        }

                        useApi().legacy.post(route('nurseries.sepas.attach', {
                            nurseries: this.nursery.id,
                            sepa: response.data.id,
                        })).then(() => {
                            callback();
                        }).catch(error => {
                            this.updating = false;
                            if (error?.response?.status === 422) {
                                _forEach(error.response.data.errors, value => {
                                    useNotification().error(_head(value));
                                });
                            } else {
                                useNotification().error(error);
                            }
                        });
                    }).catch(error => {
                        this.updating = false;
                        if (error?.response?.status === 422) {
                            _forEach(error.response.data.errors, value => {
                                useNotification().error(_head(value));
                            });
                        } else {
                            useNotification().error(error);
                        }
                        callback(error);
                    });
            },

            generateInvoices() {
                this.save(error => {
                    if (!error) {
                        this.isGeneratingInvoices = true;
                        useNotification().info(__('family_contract:saving_contract_before_creating_invoices_dots'));
                        useApi().legacy.post(route('kid.contracts.generateInvoices'), {
                            contracts: [this.contract.id],
                        }).then(() => {
                            useNotification().success(__('family_contract:invoices_created_successfully'));
                            this.$bus.$emit('invoices:created');
                            this.isGeneratingInvoices = false;
                        }).catch(error => {
                            this.$bus.$emit('invoices:created');
                            this.isGeneratingInvoices = false;
                            if (error?.response?.status === 422) {
                                _forEach(error.response.data.errors, value => {
                                    useNotification().error(_head(value));
                                });
                            } else {
                                useNotification().error(error);
                            }
                        });
                    }
                });
            },

            getDatesBetween(startDate, endDate) {
                const dates = [];

                const currDate = moment(startDate).startOf('day');
                const lastDate = moment(endDate).startOf('day');

                dates.push(currDate.clone().toDate());
                while (currDate.add(1, 'days').diff(lastDate) <= 0) {
                    dates.push(currDate.clone().toDate());
                }

                return dates;
            },
        },
    };
</script>

<style
    lang="scss"
    scoped
>
    .ContractEditModal__header {
        @apply tw-font-display tw-font-normal tw-text-xl tw-text-primary-950;
    }
</style>
