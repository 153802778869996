<script setup lang="ts">
    import {Epoch} from '@meekohq/lumos';
    import useAbility from '@/modules/app/composables/useAbility';
    import type {PropType} from 'vue';

    defineProps({
        futureClosedPeriod: {
            type: Array as PropType<Array<{
                id: number,
                name: string,
                started_at: string,
                ended_at: string,
            }>>,
            required: true,
        },
    });

    const emit = defineEmits(['deleteClosedPeriod']);
    const {can} = useAbility();
</script>

<template>
    <CList class="OpeningManagerListClosePeriods">
        <CListRow
            v-for="(closedPeriod, i) in futureClosedPeriod"
            :key="'closed-period' + i"
            class="OpeningManagerListClosePeriods__item"
            :hover="false"
        >
            <div class="OpeningManagerListClosePeriods__item-header">
                <MHeading level="h3">
                    {{ closedPeriod.name }}
                </MHeading>
                <MButton
                    v-if="can('update', 'nursery')"
                    class="tw-shrink-0"
                    variant="link-danger"
                    @click="emit('deleteClosedPeriod', closedPeriod.id)"
                >
                    <FontAwesomeIcon icon="fa fa-times"/>
                </MButton>
            </div>
            <div class="OpeningManagerListClosePeriods__grid">
                <div class="OpeningManagerListClosePeriods__grid-item">
                    <div class="OpeningManagerListClosePeriods__grid-item-text">
                        {{ __('common:start_colon') }}
                    </div>
                    {{ Epoch.fromTimestamp(closedPeriod.started_at).toLocaleString(Epoch.presets.DATE_SHORT) }}
                </div>
                <div class="OpeningManagerListClosePeriods__grid-item">
                    <div class="OpeningManagerListClosePeriods__grid-item-text">
                        {{ __('common:end_colon') }}
                    </div>
                    {{ Epoch.fromTimestamp(closedPeriod.ended_at).toLocaleString(Epoch.presets.DATE_SHORT) }}
                </div>
            </div>
        </CListRow>
    </CList>
</template>

<style lang="scss" scoped>
    .OpeningManagerListClosePeriods__item {
        @apply tw-flex tw-flex-col tw-gap-2;
    }

    .OpeningManagerListClosePeriods__item-header {
        @apply tw-flex tw-justify-between tw-items-start tw-gap-4;
    }

    .OpeningManagerListClosePeriods__grid {
        @apply tw-grid tw-grid-cols-2 tw-gap-4 tw-items-center;
    }

    .OpeningManagerListClosePeriods__grid-item {
        @apply tw-flex tw-flex-wrap tw-gap-1 tw-items-center;
    }

    .OpeningManagerListClosePeriods__grid-item-text {
        @apply tw-text-gray-500 tw-text-sm;
    }
</style>
