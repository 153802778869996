<template>
    <div class="tw-border tw-border-gray-300 tw-rounded-lg tw-p-3">
        <div class="tw-flex tw-justify-end">
            <template v-if="value.editing">
                <MTooltip
                    :label="__('common:actions.cancel')"
                    placement="top"
                >
                    <CButton
                        class="tw-border tw-border-gray-300 tw-mr-2"
                        :round="true"
                        variant="ghost"
                        @click="cancelEdit"
                    >
                        <i class="fas fa-times"/>
                    </CButton>
                </MTooltip>
                <MTooltip
                    :label="__('common:actions.save')"
                    placement="top"
                >
                    <CButton
                        :round="true"
                        variant="primary"
                        @click="confirmEditSepa"
                    >
                        <i class="fas fa-save"/>
                    </CButton>
                </MTooltip>
            </template>
            <template v-else-if="$can('update', 'kids_contracts')">
                <MTooltip
                    v-if="value && value.id"
                    :label="__('common:actions.download')"
                    placement="top"
                >
                    <DownloadTemplate
                        :additional-items="additionalItemsForDownload"
                        button-without-text
                        doc-type="sepa_mandate"
                        :item="value"
                        :nursery="manager.legacyNursery"
                        :user="user"
                        variant="light"
                        wait-for-callback
                        @saveBeforeDownloadTemplate="$emit('saveBeforeDownloadTemplate', $event)"
                    />
                </MTooltip>
                <MTooltip
                    :label="__('common:actions.update')"
                    placement="top"
                >
                    <CButton
                        class="tw-ml-2"
                        :round="true"
                        variant="primary"
                        @click="startEditSepa"
                    >
                        <i class="fas fa-pencil"/>
                    </CButton>
                </MTooltip>
            </template>
        </div>
        <div class="tw-mt-1">
            <div>
                <CLabel>
                    {{ __('common:last_name') }}
                </CLabel>
                <CInput
                    v-if="value.editing"
                    v-model="value.name"
                    :placeholder="__('common:last_name')"
                    type="text"
                />
                <CBadge
                    v-else
                    variant="default"
                >
                    {{ value.name }}
                </CBadge>
            </div>
            <div class="tw-mt-3">
                <CLabel>{{ __('common:iban') }}</CLabel>
                <CInput
                    v-if="value.editing"
                    v-model.trim="value.iban"
                    _placeholder="__('common:iban_example')"
                    type="text"
                />
                <CBadge
                    v-else
                    variant="default"
                >
                    {{ value.iban }}
                </CBadge>
            </div>
            <div class="tw-mt-3">
                <CLabel>{{ __('common:bic') }}</CLabel>
                <CInput
                    v-if="value.editing"
                    v-model="value.bic"
                    :placeholder="__('billing_setting:bic_example')"
                    type="text"
                />
                <CBadge
                    v-else
                    variant="default"
                >
                    {{ value.bic }}
                </CBadge>
            </div>
            <div class="tw-flex tw-mt-3">
                <div class="tw-w-1/2 tw-mr-2">
                    <CLabel>
                        {{ __('billing_core:sepa_mandate_unique_reference') }}
                        <MTooltip>
                            <FontAwesomeIcon
                                class="tw-text-cyan-500 tw-ml-1"
                                icon="fas fa-info-circle"
                            />
                            <template #content>
                                {{ __('family_contract:unique_mandate_reference') }}
                            </template>
                        </MTooltip>
                    </CLabel>
                    <CInput
                        v-if="value.editing"
                        v-model="value.rum"
                        :placeholder="__('family_contract:unique_mandate_reference')"
                        type="text"
                    />
                    <CBadge
                        v-else
                        variant="default"
                    >
                        {{ value.rum }}
                    </CBadge>
                </div>
                <div class="tw-w-1/2">
                    <CLabel>
                        {{ __('family_contract:signed_on') }}
                    </CLabel>
                    <MDatePicker
                        v-if="value.editing"
                        v-model="value.signed_at"
                        class="tw-w-full"
                        format="yyyy-MM-dd"
                    />
                    <CBadge
                        v-else
                        variant="default"
                    >
                        {{ $formatDate(value.signed_at, 'DD/MM/YYYY') }}
                    </CBadge>
                </div>
            </div>
        </div>
        <div
            v-if="value.editing && value.id"
            class="tw-flex tw-mt-4"
        >
            <CButton
                class="tw-text-red-500 tw-mr-2"
                @click="removeSepa"
            >
                {{ __('common:actions.delete') }}
            </CButton>
            <CButton
                class="tw-border tw-border-gray-900"
                @click="detachSepa"
            >
                <i class="fas fa-link tw-mr-1"/>
                {{ __('common:actions.remove') }}
            </CButton>
        </div>
    </div>
</template>

<script lang="ts">
    import type {PropType} from 'vue';
    import {computed, defineComponent, ref, set} from 'vue';
    import route from '@/modules/legacy/libs/ziggy';
    import swal from 'sweetalert2/dist/sweetalert2.js';
    import useApi from '@/modules/app/composables/useApi';
    import Manager from '@/modules/legacy/store/manager.store';
    import _forEach from 'lodash-es/forEach';
    import _head from 'lodash-es/head';
    import _cloneDeep from 'lodash-es/cloneDeep';
    import DownloadTemplate from '@/modules/legacy/components/Modules/DownloadTemplate.vue';
    import __ from '@/modules/app/utils/i18n-facade';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';

    export default defineComponent({
        components: {DownloadTemplate},
        props: {
            value: {
                type: Object as PropType<{ id: string, editing: boolean }>,
                required: true,
            },
            user: {
                type: Object as PropType<any>,
                required: true,
            },
            contract: {
                type: Object as PropType<{ first_family_member_id: string, second_family_member_id: string }>,
                required: true,
            },
            billingAddressId: {
                type: String,
                default: () => null,
            },
        },
        setup(props, {emit}) {
            const manager = Manager;
            const savedValue = ref({});

            function onSepaEdited(sepa: Object | null) {
                emit('input', sepa);
            }

            /*
                Format the parents data to pass variables to the DownloadTemplate component
             */
            const contractParentsIds = computed(() => {
                const contractParentsIds: Array<{}> = [];

                if (props.contract.first_family_member_id) {
                    contractParentsIds.push({
                        name: 'family_member_ids[]',
                        value: props.contract.first_family_member_id,
                    });
                }

                if (props.contract.second_family_member_id) {
                    contractParentsIds.push({
                        name: 'family_member_ids[]',
                        value: props.contract.second_family_member_id,
                    });
                }

                return contractParentsIds;
            });

            /*
                Aggregate all additional data to be passed to the DownloadTemplate component
             */
            const additionalItemsForDownload = computed(() => {
                if (props.billingAddressId) {
                    return [
                        {
                            name: 'customer_address_id',
                            value: props.billingAddressId,
                        },
                        ...contractParentsIds.value,
                    ];
                } else {
                    return contractParentsIds.value;
                }
            });

            function updateSepa() {
                useApi().legacy.put(
                    route('sepas.update', {sepa: props.value.id}),
                    props.value,
                )
                    .then(() => {
                        savedValue.value = _cloneDeep(props.value);
                        set(props.value, 'editing', false);
                        useNotification().success(__('billing_setting:sepa_updated_successfully'));
                    })
                    .catch(error => {
                        if (error && error.response && error.response.status === 422) {
                            _forEach(error.response.data.errors, value => {
                                useNotification().error(_head(value) as string);
                            });
                        } else {
                            useNotification().error(error);
                        }
                    });
            }

            function detachSepa() {
                onSepaEdited(null);
            }

            function removeSepa() {
                swal({
                    title: __('billing_setting:delete_sepa_confirmation'),
                    text: __('family_contract:sepa_will_be_deleted_on_all_contracts'),
                    type: 'warning',
                    confirmButtonClass: 'btn btn-danger mr-2',
                    confirmButtonText: __('common:actions.delete'),
                    cancelButtonText: __('common:actions.cancel'),
                }).then(result => {
                    if (result.value) {
                        useApi().legacy.delete(
                            route('sepas.destroy', {
                                sepa: props.value.id,
                            }),
                        )
                            .then(() => {
                                detachSepa();
                                useNotification().success(__('billing_setting:sepa_deleted_successfully'));
                            })
                            .catch(error => {
                                if (
                                    error &&
                                    error.response &&
                                    error.response.status === 422
                                ) {
                                    _forEach(error.response.data.errors, value => {
                                        useNotification().error(_head(value) as string);
                                    });
                                } else {
                                    useNotification().error(error);
                                }
                            });
                    }
                });
            }

            function confirmEditSepa() {
                if (props.value.id) {
                    updateSepa();
                } else {
                    set(props.value, 'editing', false);
                    savedValue.value = _cloneDeep(props.value);
                    onSepaEdited(props.value);
                }
            }

            function startEditSepa() {
                savedValue.value = _cloneDeep(props.value);
                set(props.value, 'editing', true);
            }

            function cancelEdit() {
                if (props.value.id) {
                    onSepaEdited(_cloneDeep(savedValue.value));
                    set(props.value, 'editing', false);
                } else {
                    onSepaEdited(null);
                }
            }

            return {
                additionalItemsForDownload,
                detachSepa,
                removeSepa,
                confirmEditSepa,
                startEditSepa,
                cancelEdit,
                manager,
            };
        },
    });
</script>
