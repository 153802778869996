<template>
    <router-link
        class="suggestion"
        :to="to"
    >
        <div class="header d-flex align-items-center">
            <div
                class="title"
                v-html="highlighted(suggestion.title)"
            />
        </div>
        <div class="additionals d-flex row">
            <div class="col-6">
                <dl class="additional mb-0">
                    <ul class="list-unstyled">
                        <li :class="access.staffs_access ? 'text-primary' : 'text-muted'">
                            <i
                                class="fa fa-fw mr-2"
                                :class="access.staffs_access ? 'fa-eye' : 'fa-eye-slash'"
                                style="margin-top: 2px;"
                            />
                            {{ __('common:for_team') }}
                        </li>
                        <li :class="access.parents_access ? 'text-primary' : 'text-muted'">
                            <i
                                class="fa fa-fw mr-2"
                                :class="access.parents_access ? 'fa-eye' : 'fa-eye-slash'"
                                style="margin-top: 2px;"
                            />
                            {{ __('common:for_parents') }}
                        </li>
                        <li :class="access.web_access ? 'text-primary' : 'text-muted'">
                            <i
                                class="fa fa-fw mr-2"
                                :class="access.web_access ? 'fa-eye' : 'fa-eye-slash'"
                                style="margin-top: 2px;"
                            />
                            {{ __('document:meeko_website') }}
                        </li>
                    </ul>
                </dl>
            </div>
            <div class="col-6 text-right align-self-end">
                <MButton
                    variant="purple"
                    @click.prevent.stop="openLink(suggestion.additionals.link ? suggestion.additionals.link : suggestion.additionals.file_url)"
                >
                    {{ __('document:view_document') }}
                    <template #right-icons>
                        <FontAwesomeIcon icon="fa fa-arrow-right fa-fw"/>
                    </template>
                </MButton>
            </div>
        </div>
    </router-link>
</template>

<script>

    import useHighlighter from '@/modules/legacy/helpers/useHighlighter';

    export default {
        props: ['suggestion', 'query'],
        computed: {
            to() {
                return {
                    name: 'documents.index', params: {
                        nursery: this.suggestion.bindings.nursery_id,
                    },
                };
            },
            access() {
                const nurseries = this.suggestion.additionals.nurseries;
                const nursery = nurseries[0];

                return {
                    staffs_access: nursery ? nursery.staffs_access : false,
                    parents_access: nursery ? nursery.parents_access : false,
                    web_access: nursery ? nursery.web_access : false,
                };
            },
        },
        methods: {
            highlighted(value) {
                return useHighlighter().highlight(this.query, value);
            },
            openLink(link) {
                window.open(link, '_blank');
            },
        },
    };
</script>
