import {CacheServiceProvider, DatabaseServiceProvider, HttpServiceProvider} from '@meekohq/lumos';

export default {
    /*
    |--------------------------------------------------------------------------
    | Application Environment
    |--------------------------------------------------------------------------
    |
    | This value determines the "environment" your application is currently
    | running in. This may determine how you prefer to configure various
    | services the application utilizes.
    |
    */
    env: import.meta.env.VITE_APP_ENV,

    /*
    |--------------------------------------------------------------------------
    | API Base URL
    |--------------------------------------------------------------------------
    */
    api_base_url: window.localStorage.getItem('cto_api_base_url') ?? import.meta.env.VITE_API_URL,
    production_api_base_url: 'https://api.meeko.app',
    staging_api_base_url: 'https://staging-api.meeko.app',
    local_api_base_url: 'http://localhost:8080',

    /*
    |--------------------------------------------------------------------------
    | App Version
    |--------------------------------------------------------------------------
    */
    version: import.meta.env.VITE_APP_VERSION,

    /*
    |--------------------------------------------------------------------------
    | Application Locale Configuration
    |--------------------------------------------------------------------------
    |
    | The application locale determines the default locale that will be used
    | by the translation service provider. You are free to set this value
    | to any of the locales which will be supported by the application.
    |
    */

    locale: 'fr',

    /*
    |--------------------------------------------------------------------------
    | Application Fallback Locale
    |--------------------------------------------------------------------------
    |
    | The fallback locale determines the locale to use when the current one
    | is not available.
    |
    */

    fallback_locale: 'fr',

    cache: {
        key: 'lumos-cache',
    },

    /*
    |--------------------------------------------------------------------------
    | Autoloaded Service Providers
    |--------------------------------------------------------------------------
    |
    | The service providers listed here will be automatically loaded on the
    | request to your application. Feel free to add your own services to
    | this array to grant expanded functionality to your applications.
    |
    */
    providers: [
        /*
         * Lumos Framework Service Providers...
         */
        CacheServiceProvider,
        DatabaseServiceProvider,
        HttpServiceProvider,

        /*
         * App Provider
         */
        () => import('@/modules/app/providers/MonitoringServiceProvider'),
        () => import('@/modules/app/providers/AppServiceProvider'),
        () => import('@/modules/app/providers/ModuleAbilitiesServiceProvider'),
        () => import('@/modules/app/providers/BroadcastServiceProvider'),
        () => import('@/modules/app/providers/HttpServiceProvider'),

        /*
         * Modules Providers
         */
        () => import('@/modules/coherence-ui/CoherenceServiceProvider'),
        () => import('@/modules/meeko-ui/MeekoUIServiceProvider'),
        () => import('@/modules/activity/ActivityServiceProvider'),
        () => import('@/modules/calendar/CalendarServiceProvider'),
        () => import('@/modules/cashier/CashierServiceProvider'),
        () => import('@/modules/family/FamilyServiceProvider'),
        () => import('@/modules/human-resources/HumanResourcesServiceProvider'),
        () => import('@/modules/organization/OrganizationServiceProvider'),
        () => import('@/modules/registration/RegistrationServiceProvider'),
        () => import('@/modules/request/RequestServiceProvider'),
        () => import('@/modules/tag/TagServiceProvider'),
        () => import('@/modules/user/UserServiceProvider'),
        () => import('@/modules/document/DocumentsServiceProvider'),
        () => import('@/modules/photo/PhotosServiceProvider'),
        () => import('@/modules/health/application/HealthServiceProvider'),
        () => import('@/modules/planning/utils/core/PlanningServiceProvider'),
        () => import('@/modules/auth/AuthServiceProvider'),
        () => import('@/modules/app/providers/TranslationServiceProvider'),
        () => import('@/modules/app/providers/VueServiceProvider'),
    ],
};
