import {ServiceProvider} from '@meekohq/lumos';
import Vue from 'vue';

export default class MeekoUIServiceProvider extends ServiceProvider {
    public boot() {
        // Legacy
        Vue.component('CModal', () => import('./components/MModal.vue'));
        Vue.component('CPopover', () => import('./components/CPopover.vue'));
        Vue.component('CTooltip', () => import('./components/MTooltip.vue'));
        Vue.component('CButton', () => import('./components/MButton.vue'));
        Vue.component('CInput', () => import('./components/MInput.vue'));
        Vue.component('CSkeletonItem', () => import('./components/MSkeleton.vue'));
        Vue.component('CHelpTooltip', () => import('./components/MHelpTooltip.vue'));
        Vue.component('CModalPortal', () => import('./components/MPortal.vue'));
        Vue.component('CDropdown', () => import('./components/MDropdown.vue'));

        // Layout
        Vue.component('MBox', () => import('./components/MBox.vue'));

        // Typography
        Vue.component('MPageTitle', () => import('./components/MPageTitle.vue'));
        Vue.component('MHeading', () => import('./components/MHeading.vue'));
        Vue.component('MClamp', () => import('./components/MClamp.vue'));

        // Overlay
        Vue.component('MPortal', () => import('./components/MPortal.vue'));
        Vue.component('MModal', () => import('./components/MModal.vue'));
        Vue.component('MTooltip', () => import('./components/MTooltip.vue'));
        Vue.component('MDropdown', () => import('./components/MDropdown.vue'));
        Vue.component('MHelpTooltip', () => import('./components/MHelpTooltip.vue'));

        Vue.component('MMenu', () => import('./components/MMenu.vue'));
        Vue.component('MSubMenu', () => import('./components/MSubMenu.vue'));
        Vue.component('MMenuButton', () => import('./components/MMenuButton.vue'));
        Vue.component('MMenuItems', () => import('./components/MMenuItems.vue'));
        Vue.component('MMenuItem', () => import('./components/MMenuItem.vue'));
        Vue.component('MMenuDivider', () => import('./components/MMenuDivider.vue'));
        Vue.component('MMenuOverlay', () => import('./components/MMenuOverlay.vue'));

        Vue.component('MPopover', () => import('./components/MPopover.vue'));
        Vue.component('MPopoverButton', () => import('./components/MPopoverButton.vue'));
        Vue.component('MPopoverPanel', () => import('./components/MPopoverPanel.vue'));
        Vue.component('MPopoverOverlay', () => import('./components/MPopoverOverlay.vue'));

        // Forms
        Vue.component('MButton', () => import('./components/MButton.vue'));
        Vue.component('MButtonSelect', () => import('./components/MButtonSelect.vue'));
        Vue.component('MInput', () => import('./components/MInput.vue'));
        Vue.component('MNumberInput', () => import('./components/MNumberInput.vue'));
        Vue.component('MInputGroup', () => import('./components/MInputGroup.vue'));
        Vue.component('MTimePicker', () => import('./components/MTimePicker.vue'));
        Vue.component('MTimePeriodPicker', () => import('./components/MTimePeriodPicker.vue'));
        Vue.component('MDateTimePicker', () => import('./components/MDateTimePicker.vue'));
        Vue.component('MDateTimePeriodPicker', () => import('./components/MDateTimePeriodPicker.vue'));
        Vue.component('MDatePicker', () => import('./components/MDatePicker/MDatePicker.vue'));
        Vue.component('MDatePeriodPicker', () => import('./components/MDatePeriodPicker.vue'));
        Vue.component('MMonthPicker', () => import('./components/MMonthPicker.vue'));
        Vue.component('MMonthPeriodPicker', () => import('./components/MMonthPeriodPicker.vue'));
        Vue.component('MYearPicker', () => import('./components/MYearPicker.vue'));
        Vue.component('MYearPeriodPicker', () => import('./components/MYearPeriodPicker.vue'));

        // StepPanel
        Vue.component('MStepPanel', () => import('./components/MStepPanel/MStepPanel.vue'));
        Vue.component('MStepPanelContent', () => import('./components/MStepPanel/MStepPanelContent.vue'));
        Vue.component('MStepPanelHeader', () => import('./components/MStepPanel/MStepPanelHeader.vue'));
        Vue.component('MStepPanelHeaderItem', () => import('./components/MStepPanel/MStepPanelHeaderItem.vue'));

        // Feedback
        Vue.component('MSkeleton', () => import('./components/MSkeleton.vue'));
        Vue.component('MHoverable', () => import('./components/MHoverable.vue'));

        // Others
        Vue.component('MPromised', () => import('./components/MPromised.vue'));
        Vue.component('MSettingsButton', () => import('./components/MSettingsButton.vue'));
        Vue.component('MMask', () => import('./components/MMask.vue'));
        Vue.component('MAvatar', () => import('./components/MAvatar.vue'));
        Vue.component('MAvatarEditor', () => import('./components/MAvatarEditor.vue'));

        // Icons
        Vue.component('MIconDownload', () => import('./components/MIconDownload.vue'));
        Vue.component('MIconEdit', () => import('./components/MIconEdit.vue'));
        Vue.component('MIconDelete', () => import('./components/MIconDelete.vue'));

        // Errors
        Vue.component('MErrorMessage', () => import('./components/MErrorMessage.vue'));
        Vue.component('MErrorMessageList', () => import('./components/MErrorMessageList.vue'));
    }
}
