<template>
    <h1 class="h1 tw-font-grandhotel tw-m-0 tw-leading-normal tw-text-3xl">
        <slot/>
    </h1>
</template>

<script lang="ts">
    import {defineComponent} from 'vue';

    export default defineComponent({
        components: {},
        props: {},
        setup() {
            return {};
        },
    });
</script>

