<template>
    <MModal
        :header-title="__('registration:add_registration')"
        :modal="modal"
        size="6xl"
    >
        <MStepPanel
            ref="stepper"
            @has-next-step="hasNextStep = $event"
            @has-previous-step="hasPreviousStep = $event"
        >
            <MStepPanelHeader>
                <MStepPanelHeaderItem
                    :is-completed="isStepOneCompleted"
                    :step="1"
                    :title="__('common:the_kid')"
                >
                    <template #icon>
                        <FontAwesomeIcon icon="fa fa-child-reaching"/>
                    </template>
                </MStepPanelHeaderItem>
                <MStepPanelHeaderItem
                    :is-completed="isStepTwoCompleted"
                    :step="2"
                    :title="__('common:the_contract_one')"
                >
                    <template #icon>
                        <FontAwesomeIcon icon="fa fa-file"/>
                    </template>
                </MStepPanelHeaderItem>
                <MStepPanelHeaderItem
                    :is-completed="isStepThreeCompleted"
                    :step="3"
                    :title="__('common:the_parents')"
                >
                    <template #icon>
                        <FontAwesomeIcon icon="fa fa-users"/>
                    </template>
                </MStepPanelHeaderItem>
            </MStepPanelHeader>
            <MStepPanelContent v-slot="{activeStep}">
                <CForm v-if="activeStep === 1">
                    <CFormThreeColumns>
                        <CFormGroup>
                            <CLabel>{{ __('common:first_name') }}</CLabel>
                            <MInput
                                id="kid_first_name"
                                v-model="registration.kid_first_name"
                                :placeholder="__('common:first_name')"
                            />
                        </CFormGroup>
                        <CFormGroup>
                            <CLabel>{{ __('common:last_name') }}*</CLabel>
                            <MInput
                                id="kid_last_name"
                                v-model="registration.kid_last_name"
                                :placeholder="__('common:last_name')"
                            />
                        </CFormGroup>
                        <CFormGroup>
                            <CLabel>
                                <FontAwesomeIcon
                                    class="tw-mr-2 tw-text-primary-500"
                                    icon="fa fa-venus-mars"
                                />
                                {{ __('common:gender_one') }}
                            </CLabel>
                            <CFormSelect
                                v-model="registration.kid_gender"
                                :label="__('common:gender_one')"
                                :options="[
                                    {value: 'male', text: __('common:gender.boy')},
                                    {value: 'female', text: __('common:gender.girl')},
                                    {value: '', text: __('common:gender.unknow')},
                                ]"
                                :search-bar="false"
                                text-path="text"
                                value-path="value"
                            />
                        </CFormGroup>
                    </CFormThreeColumns>
                    <CFormTwoColumns>
                        <CFormGroup>
                            <CLabel>
                                <FontAwesomeIcon
                                    class="tw-mr-2 tw-text-primary-500"
                                    icon="fa fa-birthday-cake"
                                />
                                {{ __('common:date_of_birth_or_estimate') }}*
                            </CLabel>
                            <MDatePicker
                                v-model="registration.kid_birthdate"
                                class="tw-w-full"
                                format="yyyy-MM-dd"
                            />
                        </CFormGroup>
                        <CFormGroup>
                            <CLabel>
                                <FontAwesomeIcon
                                    class="tw-mr-2 tw-text-primary-500"
                                    icon="fa fa-map-marker"
                                />
                                {{ __('common:birth_place') }}
                            </CLabel>
                            <MInput
                                id="kid_birthplace"
                                v-model="registration.kid_birthplace"
                                :placeholder="__('common:birth_place')"
                            />
                        </CFormGroup>
                    </CFormTwoColumns>
                </CForm>

                <div v-else-if="activeStep === 2">
                    <div class="form-group">
                        <div class="form-row">
                            <div class="col-12 col-lg-4">
                                <label class="label">{{ __('common:start_contract') }}*</label>
                                <MDatePicker
                                    v-model="registration.contract_started_at"
                                    class="tw-w-full"
                                    format="yyyy-MM-dd"
                                />
                            </div>
                            <div class="col-12 col-lg-4">
                                <label class="label">{{ __('common:end_contract') }}*</label>
                                <MDatePicker
                                    v-model="registration.contract_ended_at"
                                    class="tw-w-full"
                                    format="yyyy-MM-dd"
                                />
                            </div>
                            <div class="col-12 col-lg-4">
                                <label class="label">{{ __('registration:flexible_planning') }}
                                    <MHelpTooltip placement="top">
                                        <template #default>
                                            {{ __('registration:can_the_planning_be_adjusted') }}
                                        </template>
                                    </MHelpTooltip>
                                </label>
                                <select
                                    v-model="registration.negotiable"
                                    class="form-control form-control-sm"
                                    :title="__('registration:flexible_planning')"
                                >
                                    <option :value="1">
                                        {{ __('common:yes') }}
                                    </option>
                                    <option :value="0">
                                        {{ __('common:no') }}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="mt-3">
                        <h3 class="h3 tw-font-grandhotel text-center">
                            {{ __('registration:desired_planning') }}
                        </h3>
                        <div class="mt-2">
                            <div class="text-center small">
                                {{ __('registration:to_modify_a_range_click_on_it') }}
                            </div>
                            <module-planning
                                v-if="registration"
                                :nursery="nursery"
                                :registration="registration"
                            />
                        </div>
                    </div>
                </div>

                <div v-else-if="activeStep === 3">
                    <div class="form-group">
                        <div class="form-row">
                            <div
                                class="col-12 col-lg-6 pr-3"
                                style="border-right: 1px solid #DDD;"
                            >
                                <div class="row">
                                    <div class="col">
                                        <select
                                            v-model="registration.first_parent_role"
                                            class="form-control"
                                            :title="__('common:role')"
                                        >
                                            <option value="mother">
                                                {{ __('common:relationship.parent_female') }}
                                            </option>
                                            <option value="father">
                                                {{ __('common:relationship.parent_male') }}
                                            </option>
                                            <option value="brother">
                                                {{ __('common:relationship.sibling_male') }}
                                            </option>
                                            <option value="sister">
                                                {{ __('common:relationship.sibling_female') }}
                                            </option>
                                            <option value="uncle">
                                                {{ __('common:relationship.pibling_male') }}
                                            </option>
                                            <option value="aunt">
                                                {{ __('common:relationship.pibling_female') }}
                                            </option>
                                            <option value="stepmother">
                                                {{ __('common:relationship.parent_in_law_female') }}
                                            </option>
                                            <option value="stepfather">
                                                {{ __('common:relationship.parent_in_law_male') }}
                                            </option>
                                            <option value="grandparent">
                                                {{ __('common:relationship.grandparent') }}
                                            </option>
                                            <option value="legal_custodian_male">
                                                {{ __('common:relationship.legal_custodian_male') }}
                                            </option>
                                            <option value="legal_custodian_female">
                                                {{ __('common:relationship.legal_custodian_female') }}
                                            </option>
                                            <option value="other">
                                                {{ __('common:other_one') }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div class="row mt-3">
                                    <div class="col">
                                        <label
                                            class="label"
                                            for="first_parent_first_name"
                                        >{{ __('common:first_name') }}*</label>
                                        <input
                                            id="first_parent_first_name"
                                            v-model="registration.first_parent_first_name"
                                            class="form-control"
                                            :placeholder="__('common:first_name')"
                                        >
                                    </div>
                                    <div class="col">
                                        <label
                                            class="label"
                                            for="first_parent_last_name"
                                        >{{ __('common:last_name') }}*</label>
                                        <input
                                            id="first_parent_last_name"
                                            v-model="registration.first_parent_last_name"
                                            class="form-control"
                                            :placeholder="__('common:last_name')"
                                        >
                                    </div>
                                </div>
                                <div class="mt-2 row">
                                    <div class="col">
                                        <label
                                            class="label"
                                            for="first_parent_email"
                                        >{{ __('common:email') }}*</label>
                                        <input
                                            id="first_parent_email"
                                            v-model="registration.first_parent_email"
                                            class="form-control"
                                            :placeholder="__('common:email')"
                                        >
                                    </div>
                                    <div
                                        v-if="showCAF()"
                                        class="col"
                                    >
                                        <label
                                            class="label"
                                            for="first_parent_alloc_no"
                                        >{{ __('family_member:beneficiary_number_short') }}</label>
                                        <input
                                            id="first_parent_alloc_no"
                                            v-model="registration.first_parent_alloc_no"
                                            class="form-control"
                                            :placeholder="__('family_member:beneficiary_number_short')"
                                        >
                                    </div>
                                </div>
                                <hr class="hr mt-4">
                                <div class="mt-2">
                                    <label
                                        class="label"
                                        for="first_parent_address"
                                    >{{ __('common:address.address') }}</label>
                                    <input
                                        id="first_parent_address"
                                        v-model="registration.first_parent_address"
                                        class="form-control"
                                        :placeholder="__('common:address.address')"
                                    >
                                </div>
                                <div class="mt-2 row">
                                    <div class="col-5">
                                        <label
                                            class="label"
                                            for="first_parent_zipcode"
                                        >{{ __('common:address.zip_code') }}</label>
                                        <input
                                            id="first_parent_zipcode"
                                            v-model="registration.first_parent_zipcode"
                                            class="form-control"
                                            :placeholder="__('common:address.zip_code')"
                                        >
                                    </div>
                                    <div class="col-7">
                                        <label
                                            class="label"
                                            for="first_parent_city"
                                        >{{ __('common:address.city') }}</label>
                                        <input
                                            id="first_parent_city"
                                            v-model="registration.first_parent_city"
                                            class="form-control"
                                            :placeholder="__('common:address.city')"
                                        >
                                    </div>
                                </div>
                                <div class="mt-2 row">
                                    <div class="col">
                                        <CFormGroup>
                                            <CLabel>{{ __('common:address.country') }}</CLabel>
                                            <SelectCountry
                                                v-model="registration.first_parent_country"
                                                button-class="tw-py-2"
                                            />
                                        </CFormGroup>
                                    </div>
                                </div>
                                <hr class="hr mt-4">
                                <div class="mt-2 row">
                                    <div class="col">
                                        <label
                                            class="label"
                                            for="first_parent_phone"
                                        >{{ __('common:phone.landline') }}</label>
                                        <input
                                            id="first_parent_phone"
                                            v-model="registration.first_parent_phone"
                                            class="form-control"
                                            :placeholder="__('common:phone.landline')"
                                        >
                                    </div>
                                    <div class="col">
                                        <label
                                            class="label"
                                            for="first_parent_mobile_phone"
                                        >{{ __('common:phone.cellular') }}</label>
                                        <input
                                            id="first_parent_mobile_phone"
                                            v-model="registration.first_parent_mobile_phone"
                                            class="form-control"
                                            :placeholder="__('common:phone.cellular')"
                                        >
                                    </div>
                                </div>
                                <hr class="hr mt-4">
                                <div class="mt-2 row">
                                    <div class="col">
                                        <label
                                            class="label"
                                            for="first_parent_salary"
                                        >{{ __('common:annual_income_for_the_year', {year: salaryDate}) }}
                                        </label>
                                        <input
                                            id="first_parent_salary"
                                            v-model.number="registration.first_parent_salary"
                                            class="form-control"
                                            :placeholder="__('common:annual_income')"
                                        >
                                    </div>
                                </div>
                                <div class="mt-2">
                                    <label
                                        class="label"
                                        for="first_parent_job"
                                    >{{ __('common:job') }}</label>
                                    <input
                                        id="first_parent_job"
                                        v-model="registration.first_parent_job"
                                        class="form-control"
                                        :placeholder="__('common:job')"
                                    >
                                </div>
                                <div class="mt-2">
                                    <label
                                        class="label"
                                        for="first_parent_company_name"
                                    >{{ __('common:company_name') }}</label>
                                    <input
                                        id="first_parent_company_name"
                                        v-model="registration.first_parent_company_name"
                                        class="form-control"
                                        :placeholder="__('common:name')"
                                    >
                                </div>
                                <div class="mt-2 row">
                                    <div class="col">
                                        <label class="label">
                                            {{ __('common:company_contract') }}
                                            <MHelpTooltip placement="top">
                                                <template #default>
                                                    {{ __('registration:company_can_finance_nursery_place') }}
                                                </template>
                                            </MHelpTooltip>
                                        </label>
                                        <select
                                            v-model="registration.first_parent_company_contract"
                                            class="form-control"
                                            :title="__('registration:flexible_planning')"
                                        >
                                            <option :value="1">
                                                {{ __('common:yes') }}
                                            </option>
                                            <option :value="0">
                                                {{ __('common:no') }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <template v-if="registration.first_parent_company_contract">
                                    <div class="mt-2">
                                        <label
                                            class="label"
                                            for="first_parent_company_address"
                                        >{{ __('common:company_address') }}</label>
                                        <input
                                            id="first_parent_company_address"
                                            v-model="registration.first_parent_company_address"
                                            class="form-control"
                                            :placeholder="__('common:address.address')"
                                        >
                                    </div>
                                    <div class="mt-2 row">
                                        <div class="col-5">
                                            <label
                                                class="label"
                                                for="first_parent_company_zipcode"
                                            >{{ __('common:address.zip_code') }}</label>
                                            <input
                                                id="first_parent_company_zipcode"
                                                v-model="registration.first_parent_company_zipcode"
                                                class="form-control"
                                                :placeholder="__('common:address.zip_code')"
                                            >
                                        </div>
                                        <div class="col-7">
                                            <label
                                                class="label"
                                                for="second_parent_company_city"
                                            >{{ __('common:address.city') }}</label>
                                            <input
                                                id="first_parent_company_city"
                                                v-model="registration.first_parent_company_city"
                                                class="form-control"
                                                :placeholder="__('common:address.city')"
                                            >
                                        </div>
                                    </div>
                                    <div class="mt-2 row">
                                        <div class="col">
                                            <CFormGroup>
                                                <CLabel>{{ __('common:address.country') }}</CLabel>
                                                <SelectCountry
                                                    v-model="registration.first_parent_company_country"
                                                    button-class="tw-py-2"
                                                />
                                            </CFormGroup>
                                        </div>
                                    </div>
                                    <div class="mt-2 row">
                                        <div class="col">
                                            <label
                                                class="label"
                                                for="first_parent_company_phone"
                                            >{{ __('common:phone.landline') }}</label>
                                            <input
                                                id="first_parent_company_phone"
                                                v-model="registration.first_parent_company_phone"
                                                class="form-control"
                                                :placeholder="__('common:phone.landline')"
                                            >
                                        </div>
                                        <div class="col">
                                            <label
                                                class="label"
                                                for="first_parent_company_email"
                                            >{{ __('common:email') }}</label>
                                            <input
                                                id="first_parent_company_email"
                                                v-model="registration.first_parent_company_email"
                                                class="form-control"
                                                :placeholder="__('common:email')"
                                            >
                                        </div>
                                    </div>
                                </template>
                            </div>
                            <div class="col-12 col-lg-6 pl-3">
                                <div class="row">
                                    <div class="col">
                                        <select
                                            v-model="registration.second_parent_role"
                                            class="form-control"
                                            :title="__('common:role')"
                                        >
                                            <option value="mother">
                                                {{ __('common:relationship.parent_female') }}
                                            </option>
                                            <option value="father">
                                                {{ __('common:relationship.parent_male') }}
                                            </option>
                                            <option value="brother">
                                                {{ __('common:relationship.sibling_male') }}
                                            </option>
                                            <option value="sister">
                                                {{ __('common:relationship.sibling_female') }}
                                            </option>
                                            <option value="uncle">
                                                {{ __('common:relationship.pibling_male') }}
                                            </option>
                                            <option value="aunt">
                                                {{ __('common:relationship.pibling_female') }}
                                            </option>
                                            <option value="stepmother">
                                                {{ __('common:relationship.parent_in_law_female') }}
                                            </option>
                                            <option value="stepfather">
                                                {{ __('common:relationship.parent_in_law_male') }}
                                            </option>
                                            <option value="grandparent">
                                                {{ __('common:relationship.grandparent') }}
                                            </option>
                                            <option value="legal_custodian_male">
                                                {{ __('common:relationship.legal_custodian_male') }}
                                            </option>
                                            <option value="legal_custodian_female">
                                                {{ __('common:relationship.legal_custodian_female') }}
                                            </option>
                                            <option value="other">
                                                {{ __('common:other_one') }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div class="row mt-3">
                                    <div class="col">
                                        <label
                                            class="label"
                                            for="second_parent_first_name"
                                        >{{ __('common:first_name') }}</label>
                                        <input
                                            id="second_parent_first_name"
                                            v-model="registration.second_parent_first_name"
                                            class="form-control"
                                            :placeholder="__('common:first_name')"
                                        >
                                    </div>
                                    <div class="col">
                                        <label
                                            class="label"
                                            for="second_parent_last_name"
                                        >{{ __('common:last_name') }}</label>
                                        <input
                                            id="second_parent_last_name"
                                            v-model="registration.second_parent_last_name"
                                            class="form-control"
                                            :placeholder="__('common:last_name')"
                                        >
                                    </div>
                                </div>
                                <div class="mt-2 row">
                                    <div class="col">
                                        <label
                                            class="label"
                                            for="second_parent_email"
                                        >{{ __('common:email') }}</label>
                                        <input
                                            id="second_parent_email"
                                            v-model="registration.second_parent_email"
                                            class="form-control"
                                            :placeholder="__('common:email')"
                                        >
                                    </div>
                                    <div
                                        v-if="showCAF()"
                                        class="col"
                                    >
                                        <label
                                            class="label"
                                            for="second_parent_alloc_no"
                                        >{{ __('family_member:beneficiary_number_short') }}</label>
                                        <input
                                            id="second_parent_alloc_no"
                                            v-model="registration.second_parent_alloc_no"
                                            class="form-control"
                                            :placeholder="__('family_member:beneficiary_number_short')"
                                        >
                                    </div>
                                </div>
                                <hr class="hr mt-4">
                                <div class="mt-2">
                                    <label
                                        class="label tw-mr-1"
                                        for="second_parent_address"
                                    >{{ __('common:address.address') }} -</label>
                                    <button
                                        class="btn btn-sm btn-link text-secondary p-0 m-0"
                                        style="margin-bottom: .3rem !important;"
                                        @click="copyAddress"
                                    >
                                        {{ __('registration:copy_first_parent_address') }}
                                    </button>
                                    <input
                                        id="second_parent_address"
                                        v-model="registration.second_parent_address"
                                        class="form-control"
                                        :placeholder="__('common:address.address')"
                                    >
                                </div>
                                <div class="mt-2 row">
                                    <div class="col-5">
                                        <label
                                            class="label"
                                            for="second_parent_zipcode"
                                        >{{ __('common:address.zip_code') }}</label>
                                        <input
                                            id="second_parent_zipcode"
                                            v-model="registration.second_parent_zipcode"
                                            class="form-control"
                                            :placeholder="__('common:address.zip_code')"
                                        >
                                    </div>
                                    <div class="col-7">
                                        <label
                                            class="label"
                                            for="second_parent_city"
                                        >{{ __('common:address.city') }}</label>
                                        <input
                                            id="second_parent_city"
                                            v-model="registration.second_parent_city"
                                            class="form-control"
                                            :placeholder="__('common:address.city')"
                                        >
                                    </div>
                                </div>
                                <div class="mt-2 row">
                                    <div class="col">
                                        <CFormGroup>
                                            <CLabel>{{ __('common:address.country') }}</CLabel>
                                            <SelectCountry
                                                v-model="registration.second_parent_country"
                                                button-class="tw-py-2"
                                            />
                                        </CFormGroup>
                                    </div>
                                </div>
                                <hr class="hr mt-4">
                                <div class="mt-2 row">
                                    <div class="col">
                                        <label
                                            class="label"
                                            for="second_parent_phone"
                                        >{{ __('common:phone.landline') }}</label>
                                        <input
                                            id="second_parent_phone"
                                            v-model="registration.second_parent_phone"
                                            class="form-control"
                                            :placeholder="__('common:phone.landline')"
                                        >
                                    </div>
                                    <div class="col">
                                        <label
                                            class="label"
                                            for="second_parent_mobile_phone"
                                        >{{ __('common:phone.cellular') }}</label>
                                        <input
                                            id="second_parent_mobile_phone"
                                            v-model="registration.second_parent_mobile_phone"
                                            class="form-control"
                                            :placeholder="__('common:phone.cellular')"
                                        >
                                    </div>
                                </div>
                                <hr class="hr mt-4">
                                <div class="mt-2 row">
                                    <div class="col">
                                        <label
                                            class="label"
                                            for="second_parent_salary"
                                        >{{ __('common:annual_income_for_the_year', {year: salaryDate}) }}</label>
                                        <input
                                            id="second_parent_salary"
                                            v-model.number="registration.second_parent_salary"
                                            class="form-control"
                                            :placeholder="__('common:annual_income')"
                                        >
                                    </div>
                                </div>
                                <div class="mt-2">
                                    <label
                                        class="label"
                                        for="second_parent_job"
                                    >{{ __('common:job') }}</label>
                                    <input
                                        id="second_parent_job"
                                        v-model="registration.second_parent_job"
                                        class="form-control"
                                        :placeholder="__('common:job')"
                                    >
                                </div>
                                <div class="mt-2">
                                    <label
                                        class="label"
                                        for="second_parent_company_name"
                                    >{{ __('common:company_name') }}</label>
                                    <input
                                        id="second_parent_company_name"
                                        v-model="registration.second_parent_company_name"
                                        class="form-control"
                                        :placeholder="__('common:name')"
                                    >
                                </div>
                                <div class="mt-2 row">
                                    <div class="col">
                                        <label class="label">
                                            {{ __('common:company_contract') }}
                                            <MHelpTooltip placement="top">
                                                <template #default>
                                                    {{ __('registration:company_can_finance_nursery_place') }}
                                                </template>
                                            </MHelpTooltip>
                                        </label>
                                        <select
                                            v-model="registration.second_parent_company_contract"
                                            class="form-control"
                                            :title="__('registration:flexible_planning')"
                                        >
                                            <option :value="1">
                                                {{ __('common:yes') }}
                                            </option>
                                            <option :value="0">
                                                {{ __('common:no') }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <template v-if="registration.second_parent_company_contract">
                                    <div class="mt-2">
                                        <label
                                            class="label"
                                            for="second_parent_company_address"
                                        >{{ __('common:company_address') }}</label>
                                        <input
                                            id="second_parent_company_address"
                                            v-model="registration.second_parent_company_address"
                                            class="form-control"
                                            :placeholder="__('common:address.address')"
                                        >
                                    </div>
                                    <div class="mt-2 row">
                                        <div class="col-5">
                                            <label
                                                class="label"
                                                for="second_parent_company_zipcode"
                                            >{{ __('common:address.zip_code') }}</label>
                                            <input
                                                id="second_parent_company_zipcode"
                                                v-model="registration.second_parent_company_zipcode"
                                                class="form-control"
                                                :placeholder="__('common:address.zip_code')"
                                            >
                                        </div>
                                        <div class="col-7">
                                            <label
                                                class="label"
                                                for="second_parent_company_city"
                                            >{{ __('common:address.city') }}</label>
                                            <input
                                                id="second_parent_company_city"
                                                v-model="registration.second_parent_company_city"
                                                class="form-control"
                                                :placeholder="__('common:address.city')"
                                            >
                                        </div>
                                    </div>
                                    <div class="mt-2 row">
                                        <div class="col">
                                            <CFormGroup>
                                                <CLabel>{{ __('common:address.country') }}</CLabel>
                                                <SelectCountry
                                                    v-model="registration.second_parent_company_country"
                                                    button-class="tw-py-2"
                                                />
                                            </CFormGroup>
                                        </div>
                                    </div>
                                    <div class="mt-2 row">
                                        <div class="col">
                                            <label
                                                class="label"
                                                for="second_parent_company_phone"
                                            >{{ __('common:phone.landline') }}</label>
                                            <input
                                                id="second_parent_company_phone"
                                                v-model="registration.second_parent_company_phone"
                                                class="form-control"
                                                :placeholder="__('common:phone.landline')"
                                            >
                                        </div>
                                        <div class="col">
                                            <label
                                                class="label"
                                                for="second_parent_company_email"
                                            >{{ __('common:email') }}</label>
                                            <input
                                                id="second_parent_company_email"
                                                v-model="registration.second_parent_company_email"
                                                class="form-control"
                                                :placeholder="__('common:email')"
                                            >
                                        </div>
                                    </div>
                                </template>
                            </div>
                        </div>
                        <div class="form-row pl-3">
                            <div class="mt-2 row">
                                <div class="col-12">
                                    <label
                                        class="label"
                                        for="contract_dependent_children"
                                    >{{ __('common:dependent_children') }}</label>
                                    <input
                                        id="contract_dependent_children"
                                        v-model="registration.contract_dependent_children"
                                        class="form-control"
                                        :placeholder="__('registration:one')"
                                        type="number"
                                    >
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </MStepPanelContent>
        </MStepPanel>

        <template #footer-start>
            <MButton
                v-if="hasPreviousStep"
                variant="primary"
                @click="$refs.stepper.previousStep()"
            >
                <template #left-icons>
                    <FontAwesomeIcon icon="fa fa-arrow-left"/>
                </template>
                {{ __('common:actions.previous') }}
            </MButton>
        </template>

        <template #footer-end>
            <MButton
                class="tw-mr-2"
                @click="cancelRegistration"
            >
                {{ __('common:actions.cancel') }}
            </MButton>

            <template v-if="hasNextStep">
                <MButton
                    variant="primary"
                    @click="$refs.stepper.nextStep()"
                >
                    <template #right-icons>
                        <FontAwesomeIcon icon="fa fa-arrow-right"/>
                    </template>
                    {{ __('common:actions.next') }}
                </MButton>
            </template>
            <template v-else>
                <MButton
                    :loading="loading"
                    variant="primary"
                    @click="save()"
                >
                    {{ __('common:actions.save') }}
                </MButton>
            </template>
        </template>
    </MModal>
</template>

<script>
    import _clone from 'lodash-es/clone';
    import _cloneDeep from 'lodash-es/cloneDeep';
    import _head from 'lodash-es/head';
    import _forEach from 'lodash-es/forEach';
    import route from '@/modules/legacy/libs/ziggy';
    import useApi from '@/modules/app/composables/useApi';
    import moment from 'moment';
    import defaultRegistration from '@/modules/legacy/contents/defaultRegistration';
    import SelectCountry from '@/modules/legacy/components/Modules/SelectCountry.vue';
    import Planning from './Planning/Planning.vue';
    import {nursery} from '@/modules/legacy/mixins/nursery';
    import __ from '@/modules/app/utils/i18n-facade';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';
    import {Epoch} from '@meekohq/lumos';

    export default {
        components: {
            SelectCountry,
            'module-planning': Planning,
        },
        mixins: [nursery],
        props: {
            modal: {
                type: Object,
                required: true,
            },
            nursery: {},
            registration: {
                type: Object,
                default() {
                    return _cloneDeep(defaultRegistration);
                },
            },
        },
        data() {
            return {
                loading: false,
                hasPreviousStep: false,
                hasNextStep: false,
            };
        },
        computed: {
            registrationExists() {
                return this.registration && this.registration.id !== null;
            },
            moment() {
                return moment;
            },
            salaryDate() {
                if (this.registration.contract_started_at) {
                    return moment(this.registration.contract_started_at).subtract(2, 'years').format('YYYY');
                }

                return '';
            },
            isStepOneCompleted() {
                return !!this.registration.kid_last_name && !!this.registration.kid_birthdate;
            },
            isStepTwoCompleted() {
                return !!this.registration.contract_started_at && !!this.registration.contract_ended_at;
            },
            isStepThreeCompleted() {
                return !!this.registration.first_parent_first_name && !!this.registration.first_parent_last_name && !!this.registration.first_parent_email;
            },
        },
        watch: {
            'registration.first_parent_role': function(val) {
                this.setParentGenderFromRole(val, 'first_parent_gender');
            },
            'registration.second_parent_role': function(val) {
                this.setParentGenderFromRole(val, 'second_parent_gender');
            },
            'registration.id': function(val) {
                if (val === null) {
                    this.$refs.wizard.navigateToTab(0);
                }
            },
        },
        methods: {
            save() {
                const data = _clone(this.registration);

                this.loading = true;

                if (!data.requested_at) {
                    data.requested_at = Epoch.now().toFormat('yyyy-MM-dd');
                }

                this.store(data, registration => {
                    this.registration.id = registration.id;
                    this.attach(registration => {
                        this.$emit('registrationCreated', registration);
                        useNotification().success(__('registration:update_success'));
                        this.modal.hide();
                    });
                });
            },

            store(data, callback) {
                useApi().legacy.post(route('registrations.store'), data).then(response => {
                    callback(response.data);
                }).catch(error => {
                    this.loading = false;
                    if (error && error.response && error.response.status === 422) {
                        _forEach(error.response.data.errors, function(value) {
                            useNotification().error(_head(value));
                        });
                    } else {
                        useNotification().error(error);
                    }
                });
            },

            attach(callback) {
                useApi().legacy.post(route('nurseries.registrations.attach', {
                    nurseries: this.nursery.id,
                    registration: this.registration.id,
                })).then(response => {
                    callback(response.data);
                }).catch(error => {
                    if (error && error.response && error.response.status === 422) {
                        _forEach(error.response.data.errors, function(value) {
                            useNotification().error(_head(value));
                        });
                    } else {
                        useNotification().error(error);
                    }
                });
            },

            copyAddress() {
                this.registration.second_parent_address = this.registration.first_parent_address;
                this.registration.second_parent_city = this.registration.first_parent_city;
                this.registration.second_parent_country = this.registration.first_parent_country;
                this.registration.second_parent_zipcode = this.registration.first_parent_zipcode;
                this.registration.second_parent_phone = this.registration.first_parent_phone;
            },

            cancelRegistration() {
                this.modal.hide();
            },

            showCAF() {
                return this.$showCAF(this.nursery.country);
            },

            setParentGenderFromRole(role, registrationParentGenderkey) {
                const females = ['mother', 'sister', 'aunt', 'stepmother', 'legal_custodian_female'];

                if (females.indexOf(role) >= 0) {
                    this.registration[registrationParentGenderkey] = 'female';
                } else {
                    this.registration[registrationParentGenderkey] = 'male';
                }
            },
        },
    };
</script>

<style
    lang="scss"
    scoped
>
    #store-registration {
        @media (min-width: 576px) {
            .modal-lg {
                max-width: 80%;
            }
        }
    }
</style>
