<script setup lang="ts">
    import type {PropType} from 'vue';
    import useFormatNumber from '@/modules/cashier/composables/useFormatNumber';
    import type InvoiceModel from '@/modules/cashier/models/InvoiceModel';

    const props = defineProps({
        invoice: {
            type: Object as PropType<InvoiceModel>,
            required: true,
        },
    });

    const {format} = useFormatNumber(props.invoice.computed.currency_iso_code);
</script>

<template>
    <div class="InvoiceMiniatureTotal">
        <div class="InvoiceMiniatureTotal__item">
            {{ __('common:total_colon') }}
            <div class="tw-font-semibold">
                {{ format(invoice.attributes.grand_total) }}
            </div>
        </div>
        <div
            v-if="invoice.computed.remaining_amount > 0"
            class="InvoiceMiniatureTotal__item"
        >
            {{ __('billing_invoice:outstanding_balance_colon') }}
            <div class="tw-font-semibold">
                {{ format(invoice.computed.remaining_amount) }}
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    .InvoiceMiniatureTotal {
        @apply tw-border-t tw-border-gray-200;
        @apply tw-flex tw-flex-col tw-items-end tw-gap-1;
    }

    .InvoiceMiniatureTotal__item {
        @apply tw-flex tw-flex-row tw-items-baseline tw-gap-1;
    }
</style>
