<template>
    <svg
        style="enable-background:new 0 0 48 48"
        viewBox="0 0 48 48"
        xml:space="preserve"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M42 41H28V16h10c2.2 0 4 1.8 4 4v21z"
            style="fill:#1e88e5"
        />
        <path
            d="M28 41h14v2H28v-2z"
            style="fill:#1976d2"
        />
        <path
            d="M34 21h4v4h-4v-4zm0 7h4v4h-4v-4zm0 7h4v4h-4v-4z"
            style="fill:#0d47a1"
        />
        <path
            d="M9 5h20v4H9z"
            style="fill:#64b5f6"
        />
        <path
            d="M32 41H6V10c0-1.1.9-2 2-2h22a2 2 0 0 1 2 2v31z"
            style="fill:#90caf9"
        />
        <path
            d="M6 41h26v2H6v-2z"
            style="fill:#64b5f6"
        />
        <path
            d="M17 33h4v6h-4v-6zm-7 0h4v6h-4v-6zm14 0h4v6h-4v-6zm-7-14h4v4h-4v-4zm-7 0h4v4h-4v-4zm14 0h4v4h-4v-4zm-7 7h4v4h-4v-4zm-7 0h4v4h-4v-4zm14 0h4v4h-4v-4zm-7-14h4v4h-4v-4zm-7 0h4v4h-4v-4zm14 0h4v4h-4v-4z"
            style="fill:#1565c0"
        />
    </svg>
</template>

<script lang="ts">
    import {defineComponent} from 'vue';

    export default defineComponent({});
</script>
