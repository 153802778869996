<script setup lang="ts">
    import useAbility from '@/modules/app/composables/useAbility';

    defineProps({
        nursery: {
            type: Object,
            required: true,
        },
    });

    const emit = defineEmits(['deleteHoliday']);
    const {can} = useAbility();
</script>

<template>
    <CList class="OpeningManagerListPublicHolidays">
        <CListRow
            v-for="(holiday, i) in nursery.holidays"
            :key="'holiday' + i"
            class="OpeningManagerListPublicHolidays__item"
            :hover="false"
        >
            <MHeading
                class="tw-truncate"
                level="h3"
            >
                {{ holiday.name }}
            </MHeading>
            <MButton
                v-if="can('update', 'nursery')"
                class="tw-shrink-0"
                variant="link-danger"
                @click="emit('deleteHoliday', holiday, i)"
            >
                <FontAwesomeIcon icon="fa fa-times"/>
            </MButton>
        </CListRow>
    </CList>
</template>

<style lang="scss" scoped>
    .OpeningManagerListPublicHolidays__item {
        @apply tw-flex tw-flex-row tw-items-center tw-justify-between tw-gap-4;
    }
</style>
