<template>
    <loader v-if="loading"/>
    <CVStack
        v-else-if="staff"
        gap="2"
    >
        <CVStack>
            <CInline
                align-x="between"
                align-y="center"
            >
                <CPageHeading
                    class="d-print-none tw-capitalize"
                    variant="pink"
                >
                    {{ $fullName(staff.attributes) }}
                    <template v-if="staff.attributes.job">
                        <span class="tw-hidden md:tw-inline">-&nbsp;</span>
                        <small class="small tw-text-pink-600 tw-block md:tw-inline">{{ $capitalize(staff.attributes.job) }}
                        </small>
                    </template>
                </CPageHeading>
                <div class="tw-hidden d-print-block tw-w-full">
                    <h3 class="h3 tw-font-grandhotel tw-text-blue-800 tw-text-center tw-w-full tw-justify-items-center">
                        {{ $fullName(staff.attributes) }}
                        <span
                            v-if="nursery"
                            class="tw-font-display tw-text-lg"
                        >{{ `- ${nursery.name} ${nursery.city}` }}</span>
                    </h3>
                    <hr class="hr tw-hidden d-print-block">
                </div>

                <CHStack
                    align="center"
                    class="d-print-none"
                    gap="2"
                >
                    <TagsSelector
                        :taggable-model="staff"
                        taggable-type="staff"
                        :tags="staff.tags().value().toArray()"
                        @attached="staff.tags().value().push($event)"
                        @detached="staff.tags().mutate(v => v.filter(c => c.getKey() !== $event.getKey()))"
                    />
                    <DownloadTemplate
                        :additional-items="additionalItems"
                        doc-type="staff"
                        :item="staff"
                        :nursery="nursery"
                        right
                        sm
                        :user="user"
                        variant="light"
                    />
                </CHStack>
            </CInline>
        </CVStack>

        <CTabMenu
            class="d-print-none"
            variant="pink"
        >
            <CTabMenuItem
                exact
                :to="{
                    name: 'staffs.show',
                    params: { nursery: nursery.id, staff: staff.id },
                }"
                variant="pink"
            >
                <i class="fa fa-user mr-2"/>
                {{ __('common:summary') }}
            </CTabMenuItem>
            <CTabMenuItem
                v-if="$can('read', 'staffs_contracts')"
                exact
                :to="{
                    name: 'staffs.show',
                    params: { nursery: nursery.id, staff: staff.id },
                    query: { nav: 'contracts' },
                }"
                variant="pink"
            >
                <i class="fa fa-handshake tw-mr-2"/>
                {{ __('common:contract_other') }}
            </CTabMenuItem>
            <CTabMenuItem
                exact
                :to="{
                    name: 'staffs.show',
                    params: { nursery: nursery.id, staff: staff.id },
                    query: { nav: 'biography' },
                }"
                variant="pink"
            >
                <i class="fa fa-book tw-mr-2"/>
                {{ __('common:biography') }}
            </CTabMenuItem>
            <CTabMenuItem
                v-if="$can('read', 'staffs_documents')"
                exact
                :to="{
                    name: 'staffs.show',
                    params: { nursery: nursery.id, staff: staff.id },
                    query: { nav: 'documents' },
                }"
                variant="pink"
            >
                <i class="fas fa-file-alt mr-2"/>
                {{ __('common:document_other') }}
            </CTabMenuItem>
        </CTabMenu>

        <CCard
            :border="false"
            :shadow="true"
        >
            <module-summary
                v-if="$route.query.nav === 'summary' || !$route.query.nav"
                :staff="staff"
            />
            <module-contracts
                v-if="$route.query.nav === 'contracts'"
                :nursery="nursery"
                :staff="staff"
            />
            <module-biography
                v-if="$route.query.nav === 'biography'"
                :nursery="nursery"
                :staff="staff"
            />
            <template v-if="$route.query.nav === 'documents'">
                <template v-if="staff">
                    <div class="tw-mb-4 tw-flex tw-justify-between items-center">
                        <MHeading>{{ __('common:documents_of', {name: staff.fullname}) }}</MHeading>
                        <CButton
                            v-if="can('create', 'staffs_documents')"
                            :icon-plus="true"
                            :to="{name: 'staffs.show.documents.create', query: {nav:'documents'}}"
                            variant="primary"
                        >
                            {{ __('common:add_document') }}
                        </CButton>
                    </div>
                    <DocumentList
                        :bus="bus"
                        :edit-item-fn="editItemFn"
                        permission-key="staffs_documents"
                        :related-resource="staff"
                        :show-visibilities="{
                            staffs_access: false,
                            parents_access: false,
                            web_access: false,
                        }"
                    />
                    <router-view
                        :staff-model="staff"
                        @created="bus.emit('documentCreated', $event)"
                        @deleted="bus.emit('documentDeleted', $event)"
                        @updated="bus.emit('documentUpdated', $event)"
                    />
                </template>
            </template>
        </CCard>
    </CVStack>
</template>

<script lang="ts">
    import {computed, defineComponent, ref, watch} from 'vue';
    import useStaff from '@/modules/human-resources/composables/staff/useStaff';
    import moment from 'moment';
    import Summary from '@/modules/human-resources/components/staff/Summary.vue';
    import Contracts from '@/modules/human-resources/components/staff/Contracts.vue';
    import Biography from '@/modules/human-resources/components/staff/Biography.vue';
    import DownloadTemplate from '@/modules/legacy/components/Modules/DownloadTemplate.vue';
    import {useRoute, useRouter} from 'vue-router/composables';
    import DocumentList from '@/modules/document/components/organisms/DocumentList.vue';
    import mitt from 'mitt';
    import useAbility from '@/modules/app/composables/useAbility';
    import TagsSelector from '@/modules/tag/components/TagsSelector.vue';

    export default defineComponent({
        components: {
            TagsSelector,
            DocumentList,
            'module-summary': Summary,
            'module-contracts': Contracts,
            'module-biography': Biography,
            DownloadTemplate,
        },
        props: ['nursery', 'user'],
        setup() {
            const selectedTab = ref<'summary' | 'contracts' | 'documents' | 'biography'>('summary');

            const router = useRouter();

            const bus = mitt();

            const {can} = useAbility();
            const route = useRoute();

            const {selectedStaff: staff, loading, getStaff} = useStaff();
            watch(() => route.params.staff, val => {
                getStaff(val);
            }, {immediate: true});

            // Additional items for download template
            const additionalItems = computed(() => {
                const arr: any[] = [];
                const activeContract = staff.value?.activeContract(undefined, moment().format());
                if (activeContract) {
                    arr.push({
                        name: 'staff_contract_id',
                        value: activeContract.getKey(),
                    });
                }

                return arr;
            });

            function editItemFn(document) {
                router.replace({
                    name: 'staffs.show.documents.edit',
                    query: {nav: 'documents'},
                    params: {document: document.getKey()},
                });
            }

            return {
                selectedTab,
                staff,
                loading,
                additionalItems,
                bus,
                editItemFn,
                can,
            };
        },
    });
</script>
