<template>
    <div
        :key="'event-' + event.id"
        ref="eventSegment"
        class="tw-group hover:tw-z-[2000] focus:tw-z-[2000]"
        :class="[eventClass, {'tw-z-[2000]': resizing}]"
        :style="eventStyle"
        tabindex="0"
        @click="shift || alt ? copyEvent() : onSegmentClicked()"
    >
        <div
            v-if="pending"
            class="tw-w-full tw-h-full tw-absolute tw-left-0 tw-top-0 tw-animate-pulse tw-bg-white tw-opacity-80"
            :class="eventClass"
        />
        <OverrunSubSegments
            class="tw-absolute tw-inset-0"
            :event="eventCopy.value"
            :events="events"
        />

        <div
            class="tw-flex tw-items-center tw-w-full tw-h-full"
            @mouseenter.self="!resizing ? $refs.segmentTooltip.show() : null"
            @mouseleave="$refs.segmentTooltip.hide()"
        >
            <SegmentResizers v-if="resizable"/>

            <div
                v-if="displayIcon"
                :class="iconClass"
                :style="iconStyle"
            >
                <CIcon
                    v-if="pending"
                    :path="
                        loading
                            ? 'fad fa-spinner-third'
                            : 'fad fa-hourglass-half'
                    "
                    :provider="eventType.icon.attributes.provider"
                />
                <CIcon
                    v-else
                    :path="
                        loading
                            ? 'fad fa-spinner-third'
                            : eventType.icon.attributes.path
                    "
                    :provider="eventType.icon.attributes.provider"
                />
            </div>
            <div
                v-if="displayText"
                class="tw-flex tw-flex-wrap tw-items-center tw-truncate tw-z-10 tw-w-full tw-h-full"
                style="margin-left: 2px;"
            >
                <div
                    v-if="size === 'lg'"
                    class="tw-ml-1"
                >
                    <div class="tw-w-full tw-text-sm tw-font-semibold">
                        {{ titleHours }}
                    </div>
                    <div class="tw-w-full tw-opacity-90 tw-text-sm tw-font-semibold tw--mt-1">
                        {{
                            showNote && event.attributes.note
                                ? subtitle + ' • ' + event.attributes.note
                                : subtitle
                        }}
                    </div>
                </div>
                <div
                    v-else
                    class="tw-w-full tw-text-sm tw-w-full tw-font-semibold"
                >
                    <div v-if="displayAsCompact">
                        <div>
                            {{
                                readableHoursFromDate(eventCopy.value.startedAt)
                            }}
                        </div>
                        <div
                            class="tw-ml-auto"
                            style="margin-top: -5px;"
                        >
                            {{ readableHoursFromDate(eventCopy.value.endedAt) }}
                        </div>
                    </div>
                    <div v-else>
                        {{ titleHours }}
                    </div>
                </div>
            </div>
        </div>

        <CDropdown
            ref="segmentTooltip"
            class="tw-min-w-60 tw-max-w-80"
            legacy-mode
            placement="top"
        >
            <div class="tw-flex tw-items-center">
                <div
                    v-if="eventType.attributes.icon_id"
                    class="tw-flex tw-shrink-0 tw-items-center tw-justify-center tw-rounded-full tw-shadow tw-mr-3"
                    :class="iconClass"
                    :style="iconStyle"
                >
                    <CIcon
                        :path="
                            loading
                                ? 'fad fa-spinner-third'
                                : eventType.icon.attributes.path
                        "
                        :provider="eventType.icon.attributes.provider"
                    />
                </div>
                <div class="tw-mr-3">
                    <div class="tw-text-sm tw-font-semibold tw-mb-1">
                        {{ titleHours }}
                        <CBadge
                            class="!tw-text-sm tw-ml-1"
                            variant="cyan"
                        >
                            {{ eventCopy.value.readableHours }}
                        </CBadge>
                    </div>

                    <div
                        v-if="nurseryName || event.kidsGroup().value()"
                        class="tw-opacity-90 tw-text-sm"
                    >
                        <template
                            v-if="nurseryName && event.kidsGroup().value()"
                        >
                            {{ nurseryName }} •
                            {{ event.kidsGroup().value().attributes.name }}
                        </template>
                        <template v-else-if="nurseryName">
                            {{ nurseryName }}
                        </template>
                        <template v-else-if="event.kidsGroup().value()">
                            {{ event.kidsGroup().value().attributes.name }}
                        </template>
                    </div>

                    <div class="tw-opacity-90 tw-text-sm">
                        {{
                            event.attributes.note
                                ? eventType.attributes.name +
                                    ' • ' +
                                    event.attributes.note
                                : eventType.attributes.name
                        }}
                    </div>
                </div>
            </div>
        </CDropdown>

        <CDropdown
            v-if="showMenu"
            ref="segmentMenu"
            :arrow="false"
            :clickable="false"
            content-class="!tw-bg-transparent !tw-shadow-none !tw-ring-0 tw-w-48"
            legacy-mode
            no-padding
            overlay
            placement="top-start"
            @shown="onSegmentMenusShown(event)"
        >
            <SegmentRequestMenu
                v-if="!isTemplate && event.request().value()"
                :request="event.request().value()"
                @stageSaved="onRequestStageSaved"
            />
            <template v-else-if="!event.isPending">
                <CButton
                    v-if="event.attributes.forecast && can('create', 'staffs_planning')"
                    align="left"
                    class="tw-w-full !tw-rounded-full tw-mt-2"
                    variant="success"
                    @click="addRealEvent"
                >
                    <i
                        aria-hidden="true"
                        class="fa fa-calendar-check tw-ml-1 tw-mr-2"
                    />{{ __('common:add_real') }}
                </CButton>
                <CButton
                    align="left"
                    class="tw-w-full !tw-rounded-full tw-mt-2"
                    variant="primary"
                    @click="updateEvent"
                >
                    <i
                        aria-hidden="true"
                        class="fa fa-edit tw-ml-1 tw-mr-2"
                    />{{ __('common:actions.update') }}
                </CButton>
                <CButton
                    align="left"
                    class="tw-w-full !tw-rounded-full tw-mt-2"
                    variant="black"
                    @click.stop="copyEvent"
                >
                    <div class="tw-flex tw-items-center">
                        <div>
                            <i
                                aria-hidden="true"
                                class="fa fa-copy tw-ml-1 tw-mr-2"
                            />{{ __('common:actions.copy') }}
                        </div>
                        <div class="tw-text-sm tw-uppercase tw-text-gray-200 tw-font-semibold tw-rounded tw-border tw-brder-gray-200 tw-ml-auto tw-mr-2 tw-px-1 tw-leading-5">
                            {{ __('hr_calendar:shortcut_alt_click') }}
                        </div>
                    </div>
                </CButton>
                <CButton
                    v-if="!hasLockedAllocations && can('delete', 'staffs_planning')"
                    align="left"
                    class="tw-w-full !tw-rounded-full tw-mt-2"
                    variant="danger"
                    @click="deleteEvent"
                >
                    <i
                        aria-hidden="true"
                        class="fa fa-times tw-ml-1 tw-mr-2"
                    />{{ __('common:actions.delete') }}
                </CButton>
            </template>
        </CDropdown>
    </div>
</template>

<script lang="ts">
    import {computed, defineComponent, onUnmounted, type PropType, ref, set, toRef} from 'vue';
    import {useMagicKeys} from '@vueuse/core';
    import __ from '@/modules/app/utils/i18n-facade';
    import _cloneDeep from 'lodash-es/cloneDeep';
    import {EventBus} from '@/modules/legacy/utils/bus';
    import {readableHoursFromDate} from '@/modules/legacy/libs/planning/planning';
    import useSegment from '@/modules/human-resources/composables/calendar/useSegment';
    import EventModel from '@/modules/human-resources/models/EventModel';
    import type TemplateEventModel from '@/modules/human-resources/models/TemplateEventModel';
    import SegmentResizers from '@/modules/human-resources/components/calendar/SegmentResizers.vue';
    import type CDropdown from '@/modules/meeko-ui/components/MDropdown.vue';
    import OverrunSubSegments from '@/modules/human-resources/components/calendar/OverrunSubSegments.vue';
    import SegmentRequestMenu from '@/modules/human-resources/components/calendar/SegmentRequestMenu.vue';
    import useAbility from '@/modules/app/composables/useAbility';
    import RequestModel from '@/modules/request/models/RequestModel';
    import RequestStageModel from '@/modules/request/models/RequestStageModel';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';

    export default defineComponent({
        components: {
            OverrunSubSegments,
            SegmentResizers,
            SegmentRequestMenu,
        },
        props: {
            day: {
                type: Object,
            },
            opening: {
                type: String,
            },
            closing: {
                type: String,
            },
            event: {
                type: Object as PropType<EventModel | TemplateEventModel>,
                required: true,
            },
            events: {
                type: Array as PropType<EventModel[] | TemplateEventModel[]>,
                default: () => [],
            },
            showMenu: {
                type: Boolean,
                default: true,
            },
            showNote: {
                type: Boolean,
                default: false,
            },
            size: {
                type: String,
                default: 'lg',
            },
            resizable: {
                type: Boolean,
                default: true,
            },
            autoSave: {
                type: Boolean,
                default: true,
            },
            isTemplate: {
                type: Boolean,
                default: false,
            },
        },
        setup(props) {
            const {
                eventCopy,
                eventSegment,
                loading,
                eventType,
                eventStyle,
                eventClass,
                iconClass,
                iconStyle,
                displayText,
                displayIcon,
                displayAsCompact,

                titleHours,
                nurseryName,
                subtitle,
                title,
                titlePresence,

                resizing,
                pending,
            } = useSegment(
                toRef(props, 'event'),
                props.day,
                toRef(props, 'opening'),
                toRef(props, 'closing'),
                props.size as 'sm' | 'md' | 'lg',
                props.resizable,
                props.autoSave,
            );

            const {can} = useAbility();

            EventBus.$on('segment:dragend', () => {
                eventSegment.value.blur();
            });

            onUnmounted(() => {
                EventBus.$off('segment:dragend');
            });

            const segmentMenu = ref<CDropdown>();
            const isLoadingEventRequest = ref(false);

            const hasLockedAllocations = computed(() => {
                if (props.event instanceof EventModel) {
                    return props.event
                        .balanceAllocations()
                        .value()
                        .contains(
                            allocation =>
                                allocation.attributes.confirmed_at !== null,
                        );
                } else {
                    return false;
                }
            });

            function updateEvent() {
                EventBus.$emit('calendar:staff:update:event', props.event);
            }

            function addRealEvent() {
                const currentEvent = props.event as EventModel;

                const newEvent = new EventModel();
                newEvent.attributes = _cloneDeep(currentEvent.attributes);
                newEvent.attributes.id = newEvent.uuid();
                newEvent.attributes.forecast = false;
                newEvent
                    .eventType()
                    .associate(currentEvent.eventType().value());
                newEvent.referenceEvent().associate(currentEvent);
                set(newEvent.extra, 'forceReal', true);
                EventBus.$emit('calendar:staff:create:event', newEvent);
            }

            function copyEvent() {
                EventBus.$emit('calendar:staff:event:copy', props.event);
                segmentMenu.value?.hide();
                useNotification().success(__('hr_calendar:event_copied'));
            }

            async function deleteEvent() {
                await props.event.delete();
                EventBus.$emit('calendar:staff:delete:event', props.event);
                EventBus.$emit('calendar:staffs:refresh');
            }

            async function onRequestStageSaved() {
                if (props.event instanceof EventModel) {
                    await props.event.fresh();
                    eventCopy.value.value.attributes.status = props.event.attributes.status;
                    eventCopy.value.value
                        .save()
                        .then(() => {
                            eventCopy.value.commit();
                            segmentMenu.value?.hide();
                        })
                        .catch(() => {
                            eventCopy.value.rollback();
                        });
                }
            }

            function onSegmentClicked() {
                if (!resizing.value) {
                    if (props.isTemplate) {
                        EventBus.$emit(
                            'calendar:staff:update:templateEvent',
                            props.event,
                        );
                    } else if (props.showMenu) {
                        segmentMenu.value?.show();
                    }
                }
            }

            async function onSegmentMenusShown(event: EventModel) {
                // If the event has an associated request and is pending, we need to load the request if not already loaded
                if (
                    !props.isTemplate
                    && can('update', 'hr_request')
                    && event.isPending && event.attributes.request_id
                    && !event.request().value()
                ) {
                    isLoadingEventRequest.value = true;

                    const request = await RequestModel.query()
                        .with(new RequestModel().events())
                        .with(new RequestModel().requestType())
                        .with(new RequestModel().source())
                        .with(new RequestModel().requestStages(), query => {
                            query.with(new RequestStageModel().requestTypeStage());
                        }).find(event.attributes.request_id);

                    event.request().set(request);

                    isLoadingEventRequest.value = false;
                }
            }

            const {shift, alt} = useMagicKeys();

            return {
                can,
                isLoadingEventRequest,
                eventCopy,
                eventSegment,
                loading,
                hasLockedAllocations,
                updateEvent,
                addRealEvent,
                copyEvent,
                deleteEvent,
                onRequestStageSaved,

                eventType,
                eventStyle,
                eventClass,
                iconClass,
                iconStyle,
                displayText,
                displayIcon,
                displayAsCompact,

                titleHours,
                nurseryName,
                title,
                titlePresence,

                resizing,
                pending,

                segmentMenu,
                subtitle,
                onSegmentClicked,
                onSegmentMenusShown,

                shift,
                alt,
                readableHoursFromDate,
            };
        },
    });
</script>
