import {computed, watch} from 'vue';
import {isNil, round as _round} from 'lodash-es';
import type {TransactionStateType} from '@/modules/cashier/composables/issuing/transaction/useTransactionState';
import AllocationModel from '@/modules/cashier/models/AllocationModel';
import type PaymentModel from '@/modules/cashier/models/PaymentModel';

export type TransactionAllocationStoreType = ReturnType<typeof useTransactionAllocationsState>;

export default function useTransactionAllocationsState(
    transactionState: TransactionStateType,
    payment?: PaymentModel,
) {

    const {
        transaction,
        invoiceAllocation,
        sumOtherAllocations,
    } = transactionState;

    /**
     * Remaining amount of the transaction
     */
    const transactionRemainingAmount = computed(() => {
        if (transaction.value.attributes.amount) {
            let computedRemainingAmount = transaction.value.attributes.amount - sumOtherAllocations.value;
            if (invoiceAllocation.value?.attributes.amount) {
                computedRemainingAmount -= invoiceAllocation.value.attributes.amount;
            }

            return _round(computedRemainingAmount, 2);
        }

        return 0;
    });

    /**
     * Prefill the allocation with the invoice data
     */
    async function prefillInvoiceAllocation() {
        if (payment && invoiceAllocation) {
            invoiceAllocation.value = new AllocationModel();
            // Set the allocation amount, but not more than the remaining amount of the payment
            if (payment.computed.remaining_amount >= transactionRemainingAmount.value) {
                invoiceAllocation.value.attributes.amount = transactionRemainingAmount.value;
            } else {
                invoiceAllocation.value.attributes.amount = payment.computed.remaining_amount;
            }

            if (transaction.value.isDebit) {
                invoiceAllocation.value.source().associate(payment);
                invoiceAllocation.value.destination().associate(transaction.value);
            } else {
                invoiceAllocation.value.destination().associate(payment);
                invoiceAllocation.value.source().associate(transaction.value);
            }
        }
    }

    /**
     * Watch transaction amount to update allocation amount
     * From invoice we update invoiceAllocation every time
     */
    watch(() => transaction.value.attributes.amount, (newAmount, oldAmount) => {
        if (!isNil(newAmount) && !isNil(oldAmount) && payment && invoiceAllocation.value instanceof AllocationModel) {
            changeAllocationAmount(newAmount, invoiceAllocation.value, payment);
        }
    });

    function changeAllocationAmount(amount: number, allocation: AllocationModel, allocationPayment: PaymentModel) {
        const originalAllocationAmount = allocation.original?.amount ?? 0;
        const remainingAmountPayment = originalAllocationAmount + allocationPayment.computed.remaining_amount;

        const amountWithoutOtherAllocations = Math.max(_round(amount - sumOtherAllocations.value, 2), 0);

        allocation.attributes.amount = Math.min(remainingAmountPayment, amountWithoutOtherAllocations);

        if (allocation.exists) {
            if (allocation.markedForDeletion && allocation.attributes.amount && allocation.attributes.amount > 0) {
                allocation.markedForDeletion = false;
            }

            if (allocation.attributes.amount === 0) {
                allocation.markForDeletion();
            }
        }
    }

    return {
        prefillInvoiceAllocation,
        transactionRemainingAmount,
    };
}
