import type {QueryBuilder} from '@meekohq/lumos';
import {Model} from '@meekohq/lumos';
import StaffModel from '@/modules/human-resources/models/StaffModel';
import BalanceTypeModel from '@/modules/human-resources/models/BalanceTypeModel';
import BalancePeriodModel from '@/modules/human-resources/models/BalancePeriodModel';
import EventModel from '@/modules/human-resources/models/EventModel';
import allocationConfig from '@/modules/human-resources/config/allocations';
import {round} from 'lodash-es';

export default class BalanceAllocationModel extends Model {
    public type = 'hr/balance/allocations';

    public attributes: {
        id: string;
        account_id: string | undefined;
        staff_id: string | undefined;
        balance_type_id: string | undefined;
        source_id: string | undefined;
        source_type: string | undefined;
        value: number;
        confirmed_at: string | null;
    } = {
            id: this.uuid(),
            account_id: undefined,
            staff_id: undefined,
            balance_type_id: undefined,
            source_id: undefined,
            source_type: undefined,
            value: 0,
            confirmed_at: null,
        };

    get roundedValue(): number {
        return round(this.attributes.value, allocationConfig.roundValuePrecision);
    }

    public static restrictToConfirmedScope(query: QueryBuilder<BalanceAllocationModel>, organizationIds: string[] = []) {
        query.whereHas(new BalanceAllocationModel().event(), query2 => {
            query2.where('status', 'validated');

            if (organizationIds.length > 0) {
                query2.whereIn('organization_id', organizationIds);
            }
        });

        // If contract source, filter by period the started_at and by organizations relation
        query.orWhereHas('contract', query2 => {
            query2.whereHas('organizations', query3 => {
                if (organizationIds.length > 0) {
                    query3.whereIn('organization_id', organizationIds);
                }
            });
        });
    }

    staff() {
        return this.belongsTo('staff', StaffModel, 'staff_id');
    }

    balanceType() {
        return this.belongsTo('balanceType', BalanceTypeModel, 'balance_type_id');
    }

    balancePeriod() {
        return this.belongsTo('balancePeriod', BalancePeriodModel, 'balance_period_id');
    }

    source() {
        return this.morphTo<EventModel>('source');
    }

    event() {
        return this.belongsTo('event', EventModel, 'source_id');
    }
}
