<template>
    <CForm>
        <CFormTwoColumns>
            <CFormGroup>
                <CLabel>{{ __('activity:caller') }}</CLabel>
                <StaffFinder
                    button-class="tw-w-full"
                    has-active-contract
                    :value="call.staff().value()"
                    @input="call.staff().associate($event)"
                />
                <CFormErrorMessageList :errors="callErrors.getErrors('staff_id')"/>
            </CFormGroup>
            <CFormGroup>
                <CLabel>{{ __('common:phone.general') }}</CLabel>
                <CPhoneInput
                    v-model="call.attributes.phone"
                    :suggested-phones="suggestedPhones"
                />
                <CFormErrorMessageList :errors="callErrors.getErrors('phone')"/>
            </CFormGroup>
            <CFormGroup>
                <CLabel>{{ __('activity:start_of_call') }}*</CLabel>
                <CFormDatepicker
                    v-model="call.attributes.started_at"
                    :time="true"
                />
                <CFormErrorMessageList :errors="callErrors.getErrors('started_at')"/>
            </CFormGroup>
            <CFormGroup>
                <CLabel>{{ __('activity:duration') }}</CLabel>
                <CInput
                    v-model="callDuration"
                    step="1"
                    type="time"
                    @focus="stopDurationIncrement"
                />
                <CFormErrorMessageList :errors="callErrors.getErrors('duration')"/>
            </CFormGroup>
            <CFormGroup>
                <CLabel>{{ __('activity:direction') }}*</CLabel>
                <CFormSelect
                    v-model="call.attributes.direction"
                    :options="callDirections"
                    :search-bar="false"
                />
                <CFormErrorMessageList :errors="callErrors.getErrors('direction')"/>
            </CFormGroup>
            <CFormGroup>
                <CLabel>{{ __('activity:outcome') }}*</CLabel>
                <CFormSelect
                    v-model="call.attributes.outcome"
                    :options="callOutcomes"
                    :search-bar="false"
                />
                <CFormErrorMessageList :errors="callErrors.getErrors('outcome')"/>
            </CFormGroup>
        </CFormTwoColumns>
        <CFormGroup>
            <CLabel>{{ __('common:comment') }}</CLabel>
            <CFormTextArea v-model="call.attributes.description"/>
            <CFormErrorMessageList :errors="callErrors.getErrors('description')"/>
        </CFormGroup>
        <CHStack
            distribute="end"
            gap="2"
        >
            <CButton
                :label="__('common:actions.cancel')"
                @click="undo"
            />
            <CButton
                :disabled="disableSaveButton"
                :label="__('common:actions.save')"
                :loading="loading"
                variant="primary"
                @click="save"
            />
        </CHStack>
    </CForm>
</template>

<script lang="ts">
    import {defineComponent, onBeforeMount, onBeforeUnmount, onMounted, type PropType, ref, watch} from 'vue';
    import type CallModel from '@/modules/activity/models/CallModel';
    import CallOutcomeValue from '@/modules/activity/utils/CallOutcomeValue';
    import CallDirectionValue from '@/modules/activity/utils/CallDirectionValue';
    import useManager from '@/modules/app/composables/useManager';
    import moment from 'moment/moment';
    import ErrorHandler from '@/modules/legacy/libs/errors/errorHandler';
    import StaffFinder from '@/modules/request/components/Teams/StaffFinder.vue';
    import useAuth from '@/modules/app/composables/useAuth';
    import StaffModel from '@/modules/human-resources/models/StaffModel';
    import type {SuggestedPhone} from '@/modules/coherence-ui/components/Forms/CPhoneInput.vue';
    import __ from '@/modules/app/utils/i18n-facade';

    export default defineComponent({
        components: {StaffFinder},
        props: {
            call: {
                type: Object as PropType<CallModel>,
                required: true,
            },
            suggestedPhones: {
                type: Array as PropType<SuggestedPhone[]>,
                required: false,
                default: () => [],
            },
            disableSaveButton: {
                type: Boolean,
                default: false,
            },
        },
        setup(props, {emit}) {
            const {activeOrganization} = useManager();

            const {user} = useAuth();
            const loading = ref(false);

            const callDirections = Object.values(CallDirectionValue).map(direction => {
                return {
                    value: direction,
                    text: direction === 'incoming' ? __('activity:incoming') : __('activity:outgoing'),
                };
            });

            const callOutcomes = Object.values(CallOutcomeValue).map(outcome => {
                switch (outcome) {
                    case CallOutcomeValue.connected:
                        return {value: outcome, text: __('common:connected')};
                    case CallOutcomeValue.noResponse:
                        return {value: outcome, text: __('activity:no_response')};
                    case CallOutcomeValue.voicemail:
                        return {value: outcome, text: __('activity:voicemail')};
                    case CallOutcomeValue.unavailable:
                        return {value: outcome, text: __('activity:unavailable')};
                    case CallOutcomeValue.wrongNumber:
                        return {value: outcome, text: __('activity:wrong_number')};
                }
            });

            const callErrors = ref(new ErrorHandler());

            // Init call data
            const callDuration = ref(props.call.attributes.duration ? moment.utc(props.call.attributes.duration * 1000).format('HH:mm:ss') : '00:00:00');
            let durationInterval;

            onBeforeMount(() => {
                if (!props.call.exists && props.suggestedPhones.length) {
                    props.call.attributes.phone = props.suggestedPhones[0].number;
                }
            });

            onBeforeUnmount(() => {
                stopDurationIncrement();
            });

            function stopDurationIncrement() {
                if (durationInterval) {
                    clearInterval(durationInterval);
                }
            }

            async function associateStaff(email: string) {
                const staff = await StaffModel.query().where('email', email).first();
                if (staff) {
                    props.call.staff().associate(staff);
                }
            }

            watch(() => props.call.attributes.duration, () => {
                callDuration.value = props.call.attributes.duration ? moment.utc(props.call.attributes.duration * 1000).format('HH:mm:ss') : '00:00:00';
            });

            onMounted(async () => {
                if (!props.call.exists) {
                    props.call.attributes.direction = CallDirectionValue.outcoming;
                    props.call.attributes.outcome = CallOutcomeValue.connected;
                    props.call.attributes.started_at = moment().format();

                    if (user.value.attributes.email) {
                        await associateStaff(user.value.attributes.email);
                    }

                    props.call.attributes.duration = 0;
                    durationInterval = setInterval(() => {
                        if (typeof props.call.attributes.duration === 'number' && Number(props.call.attributes.duration) >= 0) {
                            props.call.attributes.duration++;
                        }
                    }, 1000);
                }
            });

            async function save() {
                loading.value = true;

                const call = props.call;
                stopDurationIncrement();
                const oldDuration = call.attributes.duration;

                try {
                    call.attributes.account_id = activeOrganization.value.attributes.account_id;
                    call.attributes.duration = callDuration.value ? moment.duration(callDuration.value).as('seconds') : null;
                    call.attributes.phone = call.attributes.phone ? call.attributes.phone.replace(/ /g, '') : null;

                    await call.save();

                    callErrors.value.reset();
                    emit('saved', call);
                } catch (e) {
                    callErrors.value.reset(e);
                    call.attributes.duration = oldDuration;
                } finally {
                    loading.value = false;
                }
            }

            const undo = function() {
                emit('undo');
            };

            return {
                callDirections,
                callDuration,
                callErrors,
                callOutcomes,
                loading,
                save,
                undo,
                stopDurationIncrement,
            };
        },
    });
</script>
