<script setup lang="ts">
    import type {PropType} from 'vue';
    import {computed} from 'vue';

    const props = defineProps({
        status: {
            type: String as PropType<'draft' | 'pending' | 'incomplete' | 'uncollectible' | 'unpaid' | 'paid'>,
            required: true,
        },
    });

    const invoiceAvatar = computed(() => {
        let icon = '';
        let color = '';

        switch (props.status) {
            case 'pending':
            case 'incomplete':
                color = 'InvoiceAvatar--is-orange';
                icon = 'fa fa-file-invoice';
                break;
            case 'uncollectible':
            case 'unpaid':
                color = 'InvoiceAvatar--is-red';
                icon = 'fa fa-file-invoice';
                break;
            case 'paid':
                color = 'InvoiceAvatar--is-green';
                icon = 'fa fa-file-invoice';
                break;
            case 'draft':
            default:
                color = 'InvoiceAvatar--is-gray';
                icon = 'fa fa-file-pen';
        }

        return {icon, color};
    });
</script>

<template>
    <MAvatar>
        <div
            class="tw-w-full tw-h-full tw-flex tw-justify-center tw-items-center"
            :class="invoiceAvatar.color"
        >
            <FontAwesomeIcon :icon="invoiceAvatar.icon"/>
        </div>
    </MAvatar>
</template>

<style scoped lang="scss">
    .InvoiceAvatar--is-gray {
        @apply tw-text-gray-500 tw-bg-gray-500/20;
    }

    .InvoiceAvatar--is-orange {
        @apply tw-text-orange-500 tw-bg-orange-500/20;
    }

    .InvoiceAvatar--is-red {
        @apply tw-text-red-500 tw-bg-red-500/20;
    }

    .InvoiceAvatar--is-green {
        @apply tw-text-green-500 tw-bg-green-500/20;
    }
</style>
