import {Model} from '@meekohq/lumos';
import {trim} from 'lodash-es';
import EndpointModel from '@/modules/cashier/models/EndpointModel';
import KidModel from '@/modules/family/models/KidModel';
import KidMemberPivot from '@/modules/family/models/KidMemberPivot';
import FamilyModel from '@/modules/family/models/FamilyModel';
import FamilyMemberPivot from '@/modules/family/models/FamilyMemberPivot';
import OrganizationModel from '@/modules/organization/models/OrganizationModel';
import MemberOrganizationPivot from '@/modules/family/models/MemberOrganizationPivot';
import TagModel from '@/modules/tag/models/TagModel';
import TagPivot from '@/modules/tag/models/TagPivot';

export default class MemberModel extends Model {
    public type = 'family/members';

    public attributes: {
        id: string;
        account_id: string | undefined;
        nursery_id: string | undefined;
        first_name: string | undefined;
        last_name: string | undefined;
        email: string | undefined;
        gender: string | undefined;
        birthdate: string | undefined;
        avatar_url: string | undefined;
        phone: string | undefined;
        mobile_phone: string | undefined;
        office_phone: string | undefined;
        note: string | undefined;
        allocator_no: string | undefined;
        job: string | undefined;
        address: {
            line1: string | undefined;
            line2: string | undefined;
            postal_code: string | undefined;
            city: string | undefined;
            state: string | undefined;
            country_code: string | undefined;
        };
    } = {
            id: this.uuid(),
            account_id: undefined,
            nursery_id: undefined,
            first_name: undefined,
            last_name: undefined,
            email: undefined,
            gender: undefined,
            birthdate: undefined,
            avatar_url: undefined,
            phone: undefined,
            mobile_phone: undefined,
            office_phone: undefined,
            note: undefined,
            allocator_no: undefined,
            job: undefined,
            address: {
                line1: undefined,
                line2: undefined,
                postal_code: undefined,
                city: undefined,
                state: undefined,
                country_code: undefined,
            },
        };

    get fullname(): string {
        return this.attributes.first_name + ' ' + this.attributes.last_name;
    }

    get fullAddress(): string {
        let address = this.attributes.address?.line1 || '';
        address += ' ' + (this.attributes.address?.line2 || '');
        address += ', ' + (this.attributes.address?.postal_code || '');
        address += ' ' + (this.attributes.address?.city || '');
        address += ', ' + (this.attributes.address?.country_code || '');

        address = address.replace(' , ', ', ');

        return trim(address, ' ,');
    }

    get isBoy() {
        return this.attributes.gender === 'male';
    }

    get isGirl() {
        return this.attributes.gender === 'female';
    }

    notificationEndpoint() {
        return this.morphOne('notificationEndpoint', EndpointModel, 'resource');
    }

    kids() {
        return this.belongsToMany('kids', KidModel, KidMemberPivot, 'family_member_id', 'kid_id');
    }

    families() {
        return this.belongsToMany('families', FamilyModel, FamilyMemberPivot, 'member_id', 'family_id');
    }

    organizations() {
        return this.belongsToMany('organizations', OrganizationModel, MemberOrganizationPivot, 'member_id', 'organization_id');
    }

    tags() {
        return this.morphToMany('tags', TagModel, TagPivot, 'resource', undefined, 'tag_id');
    }

    tagsPivots() {
        return this.hasMany('tagsPivots', TagPivot, 'resource_id').setQuery(query => {
            query.where('resource_type', '=', this.type);
        });
    }
}
