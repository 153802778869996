<template>
    <div class="TagsManagerListItemHeader">
        <FontAwesomeIcon
            class="TagsManagerListItemHeader__icon"
            :icon="category.icon"
        />
        <MHeading level="h3">
            {{ category.name }}
        </MHeading>
    </div>
</template>

<script lang="ts">
    import {defineComponent} from 'vue';

    export default defineComponent({
        props: {
            category: {
                type: Object,
                required: true,
            },
        },
    });
</script>

<style lang="scss" scoped>
    .TagsManagerListItemHeader {
        @apply tw-flex tw-flex-row tw-items-center tw-gap-2;
    }

    .TagsManagerListItemHeader__icon {
        @apply tw-text-primary-500 tw-text-xl;
    }
</style>

