import {Model} from '@meekohq/lumos';
import BalanceTypeModel from '@/modules/human-resources/models/BalanceTypeModel';

export default class BalancePeriodModel extends Model {
    public type = 'hr/balance/periods';

    public attributes: {
        id: string;
        account_id: string | undefined;
        balance_type_id: string | undefined;
        name: string | undefined;
        description: string | undefined;
        period: {
            started_at: string | undefined;
            ended_at: string | undefined;
        };
        archived_at: string | undefined;
    } = {
            id: this.uuid(),
            account_id: undefined,
            balance_type_id: undefined,
            name: undefined,
            description: undefined,
            period: {
                started_at: undefined,
                ended_at: undefined,
            },
            archived_at: undefined,
        };

    balanceType() {
        return this.belongsTo('balanceType', BalanceTypeModel, 'balance_type_id');
    }
}

