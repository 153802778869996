<template>
    <div class="CDropdownMenu">
        <div
            v-if="$slots.button"
            class="CDropdownMenu__target"
            :class="targetClass"
            @click.stop.prevent="toggle()"
        >
            <slot name="button"/>
        </div>
        <CDropdown
            class="tw-w-full"
            :clickable="clickable"
            :hoverable="hoverable"
            legacy-mode
            :placement="placement"
            :popover="popover"
            :prevent-hide-on-click="!hideOnClick"
            :reference="parent"
            :stop-propagation="stopPropagation"
        >
            <div
                class="CDropdownMenu__content"
                :class="contentClass"
                @click.stop="hide()"
            >
                <slot/>
            </div>
        </CDropdown>
        <slot name="extra"/>
    </div>
</template>

<script lang="ts">
    import type {PropType} from 'vue';
    import {defineComponent} from 'vue';
    import usePopover from '@/modules/app/composables/usePopover';

    export default defineComponent({
        props: {
            placement: {type: String as PropType<string>, default: 'bottom-end'},
            hideOnClick: {type: Boolean as PropType<boolean>, default: true},
            contentClass: {type: String, default: ''},
            targetClass: {type: String, default: ''},
            parent: {},
            hoverable: {type: Boolean, default: false},
            clickable: {type: Boolean, default: true},
            stopPropagation: {type: Boolean, default: false},
        },
        setup(props, {emit}) {
            const popover = usePopover();

            function show(delay = 0) {
                popover.bus.emit('show', delay);
                emit('show');
            }

            function hide(delay = 0) {
                popover.bus.emit('hide', delay);
                emit('hide');
            }

            function toggle(delay = 0) {
                popover.bus.emit('toggle', delay);
                emit('toggle');
            }

            return {
                popover,
                show, hide, toggle,
            };
        },
    });
</script>

<style
    lang="scss"
    scoped
>
    .CDropdownMenu__content {
        @apply tw-min-w-56 tw-flex tw-flex-col tw-gap-1;
    }
</style>
