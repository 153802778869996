<template>
    <CVStack gap="4">
        <h3 class="h3 text-secondary text-center tw-font-grandhotel">
            {{ plan }}
        </h3>

        <div class="tw-grid lg:tw-grid-cols-2 tw-grid-cols-1 tw-gap-8">
            <div>
                <CHeading level="h3">
                    {{ __('common:billing_address_one') }}
                </CHeading>
                <CForm
                    id="payment-form"
                    method="post"
                    @submit.prevent="subscribe($event)"
                >
                    <CFormGroup>
                        <CLabel>{{ __('common:name') }}</CLabel>
                        <CInput
                            v-model="customerCopy.name"
                            :has-error="errors.name"
                            :placeholder="__('subscription:my_organization')"
                        />
                        <CFormErrorMessage v-if="errors.name">
                            {{ errors.name[0] }}
                        </CFormErrorMessage>
                    </CFormGroup>
                    <CFormTwoColumns>
                        <CFormGroup>
                            <CLabel>{{ __('common:address.country') }}</CLabel>
                            <SelectCountry
                                v-model="customerCopy.country"
                                :countries-filter="countriesFilter"
                                :has-error="errors.country"
                            />
                            <CFormErrorMessage v-if="errors.country">
                                {{ errors.country[0] }}
                            </CFormErrorMessage>
                        </CFormGroup>
                    </CFormTwoColumns>
                    <CFormTwoColumns>
                        <CFormGroup>
                            <CLabel>{{ __('common:address.address') }}</CLabel>
                            <CInput
                                v-model="customerCopy.address"
                                :has-error="errors.address"
                                :placeholder="__('subscription:example.address')"
                            />
                            <CFormErrorMessage v-if="errors.address">
                                {{ errors.address[0] }}
                            </CFormErrorMessage>
                        </CFormGroup>
                        <CFormGroup>
                            <CLabel>{{ __('common:address.city') }}</CLabel>
                            <CInput
                                v-model="customerCopy.city"
                                :has-error="errors.city"
                                :placeholder="__('subscription:example.city')"
                            />
                            <CFormErrorMessage v-if="errors.city">
                                {{ errors.city[0] }}
                            </CFormErrorMessage>
                        </CFormGroup>
                    </CFormTwoColumns>
                    <CFormTwoColumns>
                        <CFormGroup>
                            <CLabel>{{ __('common:address.zip_code') }}</CLabel>
                            <CInput
                                v-model="customerCopy.zipcode"
                                :has-error="errors.zipcode"
                                :placeholder="__('subscription:example.zipcode')"
                            />
                            <CFormErrorMessage v-if="errors.zipcode">
                                {{ errors.zipcode[0] }}
                            </CFormErrorMessage>
                        </CFormGroup>
                        <CFormGroup>
                            <CLabel>{{ __('common:address.region') }}</CLabel>
                            <CInput
                                v-model="customerCopy.state"
                                :has-error="errors.state"
                                :placeholder="__('subscription:example.zipcode')"
                            />
                            <CFormErrorMessage v-if="errors.state">
                                {{ errors.state[0] }}
                            </CFormErrorMessage>
                        </CFormGroup>
                    </CFormTwoColumns>
                </CForm>
            </div>
            <div>
                <template v-if="$route.params.plan === 'yearly-1188-2018'">
                    <CHeading level="h3">
                        {{ __('subscription:to_thank_for_confidence_colon') }}
                    </CHeading>
                    <CButtonGroup class="tw-w-full">
                        <CButton
                            v-if="discount.coupon && !['yearly-premium', 'yearly-8', 'meeko19'].includes(discount.coupon)"
                            class="tw-flex-1"
                            :variant="!['yearly-premium', 'yearly-8', 'meeko19'].includes(discount.coupon) ? 'primary' : 'light'"
                        >
                            <CVStack
                                align="center"
                                class="tw-p-3"
                                distribute="center"
                            >
                                <CCenter class="tw-bg-white tw-rounded-full tw-h-10 tw-w-10">
                                    <i
                                        aria-hidden="true"
                                        class="fas fa-medal tw-text-3xl tw-text-yellow-500"
                                    />
                                </CCenter>
                                <CText
                                    font-size="lg"
                                    font-weight="semibold"
                                >
                                    {{ __('subscription:special_discount') }}
                                </CText>
                            </CVStack>
                        </CButton>
                        <CButton
                            v-if="customer.is_premium"
                            class="tw-flex-1"
                            :variant="discount.coupon === 'yearly-premium' ? 'primary' : 'light'"
                            @click="setPromo('yearly-premium')"
                        >
                            <CVStack
                                align="center"
                                class="tw-p-3"
                                distribute="center"
                            >
                                <CCenter class="tw-bg-white tw-rounded-full tw-h-10 tw-w-10">
                                    <i
                                        aria-hidden="true"
                                        class="fas fa-medal tw-text-3xl tw-text-yellow-500"
                                    />
                                </CCenter>
                                <CText
                                    font-size="lg"
                                    font-weight="semibold"
                                >
                                    {{ __('subscription:premium_discount') }}
                                </CText>
                            </CVStack>
                        </CButton>
                        <CButton
                            class="tw-flex-1"
                            :variant="discount.coupon === 'yearly-8' ? 'primary' : 'light'"
                            @click="setPromo('yearly-8')"
                        >
                            <CVStack
                                align="center"
                                class="tw-p-3"
                                distribute="center"
                            >
                                <CCenter class="tw-bg-white tw-rounded-full tw-h-10 tw-w-10">
                                    <i
                                        aria-hidden="true"
                                        class="fas fa-badge-percent tw-text-3xl tw-text-green-500"
                                    />
                                </CCenter>
                                <CText
                                    font-size="lg"
                                    font-weight="semibold"
                                >
                                    {{ __('subscription:yearly_discount') }}
                                </CText>
                            </CVStack>
                        </CButton>
                        <CButton
                            v-if="displayTabletCoupon"
                            class="tw-flex-1"
                            :variant="discount.coupon === 'meeko19' ? 'primary' : 'light'"
                            @click="setPromo('meeko19')"
                        >
                            <CVStack
                                align="center"
                                class="tw-p-3"
                                distribute="center"
                            >
                                <CCenter class="tw-bg-white tw-rounded-full tw-h-10 tw-w-10">
                                    <i
                                        aria-hidden="true"
                                        class="fas fa-tablet-alt tw-text-3xl tw-text-purple-500"
                                    />
                                </CCenter>
                                <div class="tw-leading-5">
                                    <CText
                                        font-size="lg"
                                        font-weight="semibold"
                                    >
                                        {{ __('subscription:tablet_discount') }}
                                    </CText>
                                    <br>
                                    <CText font-size="sm">
                                        {{ __('subscription:eight_percent_in_subsequent_years') }}
                                    </CText>
                                </div>
                            </CVStack>
                        </CButton>
                    </CButtonGroup>
                </template>
                <CHeading
                    class="tw-mt-4"
                    level="h3"
                >
                    {{ __('common:summary') }}
                </CHeading>
                <CVStack gap="4">
                    <CFormGroup>
                        <CLabel>{{ __('subscription:add_discount') }}</CLabel>
                        <CButtonGroup class="tw-w-full">
                            <CInput
                                v-model="coupon"
                                :has-error="errors.coupon && errors.coupon.length"
                                :placeholder="__('subscription:promo_code')"
                            />
                            <CButton
                                class="tw-w-1/3"
                                :label="__('common:actions.validate')"
                                @click="addCouponCode(coupon)"
                            />
                        </CButtonGroup>
                        <CFormErrorMessage v-if="errors.coupon && errors.coupon.length">
                            {{ errors.coupon ? errors.coupon[0] : undefined }}
                        </CFormErrorMessage>
                    </CFormGroup>
                    <div class="tw-rounded-lg tw-shadow-md  tw-ring-1 tw-ring-black/20 tw-w-full">
                        <CHStack class="tw-border-b tw-border-b-gray-400 tw-px-2 tw-py-2">
                            <CText>{{ __('subscription:subscription_price') }}</CText>
                            <CText class="tw-ml-auto">
                                {{ amount }} €
                            </CText>
                        </CHStack>
                        <CHStack
                            v-if="! _isEmpty(discount)"
                            class="tw-border-b tw-border-b-gray-400 tw-bg-gradient-to-br tw-from-green-300 tw-to-green-500 tw-px-2 tw-py-2"
                        >
                            <CText font-weight="semibold">
                                {{ discount.name }}
                            </CText>
                            <CText
                                v-if="discount.percentage || discount.amount"
                                class="tw-ml-auto"
                                font-weight="semibold"
                            >
                                <template v-if="discount.percentage">
                                    {{ discount.percentage }}% ({{ (discount.percentage / 100 * amount).toFixed(2) }} €)
                                </template>
                                <template v-else>
                                    {{ discount.amount.toFixed(2) }} €
                                </template>
                            </CText>
                        </CHStack>
                        <CHStack class="tw-border-b tw-border-b-gray-400 tw-px-2 tw-py-2">
                            <CText>{{ __('subscription:sub_total') }}</CText>
                            <CText class="tw-ml-auto">
                                {{ subTotal }} €
                            </CText>
                        </CHStack>
                        <CHStack class="tw-px-2 tw-py-2">
                            <CText>{{ __('common:vat') }}</CText>
                            <CText class="tw-ml-auto">
                                ({{ vatRate }}%) {{ vatValue }} €
                            </CText>
                        </CHStack>
                        <CHStack class="tw-bg-gradient-to-br tw-from-cyan-600 tw-to-blue-600 tw-text-white tw-rounded-inherit !tw-rounded-t-none tw-px-2 tw-py-2">
                            <CText font-weight="semibold">
                                {{ __('common:total') }}
                            </CText>
                            <CText
                                class="tw-ml-auto"
                                font-weight="semibold"
                            >
                                {{ total }} €
                            </CText>
                        </CHStack>
                    </div>
                </cvstack>
            </div>
        </div>

        <CDisclosure
            :open="false"
            :title="__('subscription:select_or_add_payment_method')"
        >
            <GridListWithAdd
                :add-visible="false"
                :customer="customer"
                :email="nursery.email"
            />
        </CDisclosure>

        <CAlert
            v-if="!_isEmpty(errors.stripe)"
            variant="danger"
        >
            <ul class="list-unstyled mb-0">
                <li
                    v-for="(error, i) in errors.stripe"
                    :key="'error' + i"
                >
                    {{ error }}
                </li>
            </ul>
        </CAlert>

        <CAlert
            v-if="stripe.error"
            :close-button="false"
            variant="danger"
        >
            {{ stripe.error }}
        </CAlert>

        <CCheckbox
            v-model="TOSCheck"
            class="tw-mt-4"
        >
            <span
                v-html="__('subscription:by_validating_the_subscription_you_certify_that_you_have_read_and_accepted_our_tos', {
                    termsOfUseLink: link
                })"
            />
        </CCheckbox>
        <MTooltip
            v-if="!TOSCheck"
            :label="__('subscription:tos_unchecked_warning')"
            placement="top"
        >
            <MButton
                ref="submit"
                class="tw-w-full"
                disabled
                form="payment-form"
                :label="__('subscription:subscribing_meeko')"
                :loading="loader.isLoading('subscribe')"
                size="lg"
                type="submit"
                variant="success"
            />
        </MTooltip>
        <MButton
            v-else
            ref="submit"
            class="tw-w-full"
            form="payment-form"
            :label="__('subscription:subscribing_meeko')"
            :loading="loader.isLoading('subscribe')"
            size="lg"
            type="submit"
            variant="success"
        />
    </CVStack>
</template>

<script>
    import _orderBy from 'lodash-es/orderBy';
    import swal from 'sweetalert2/dist/sweetalert2.js';
    import moment from 'moment';
    import route from '@/modules/legacy/libs/ziggy';
    import useApi from '@/modules/app/composables/useApi';
    import _isEmpty from 'lodash-es/isEmpty';
    import GridListWithAdd from '@/modules/subscription/components/PaymentMethod/GridListWithAdd.vue';
    import Loader from '@/modules/legacy/helpers/loader.helper';
    import {collect, config} from '@meekohq/lumos';
    import {cloneDeep} from 'lodash-es';
    import SelectCountry from '@/modules/legacy/components/Modules/SelectCountry.vue';
    import __ from '@/modules/app/utils/i18n-facade';

    export default {
        components: {
            SelectCountry,
            GridListWithAdd,
        },
        props: ['nursery', 'customer'],
        data: () => ({
            customerCopy: [],
            amount: 0,
            discount: {},
            coupon: undefined,
            countriesVAT: [
                {
                    countries: ['BE', 'FR', 'LU', 'MC'],
                    amount: 20,
                },
            ],
            countriesFilter: country => ['CD', 'CG', 'BE', 'DJ', 'FR', 'MU', 'IL', 'RE', 'LU', 'MA', 'MQ', 'MC', 'NC', 'GP', 'GF', 'MF', 'SN', 'CH'].includes(country.id),
            errors: [],
            plan: undefined,
            sources: [],
            defaultSource: {},
            stripeClient: window.Stripe(config('services.stripe.key')),
            stripe: {
                error: '',
            },
            loader: new Loader(),
            payment_intent: '',
            TOSCheck: false,
        }),
        computed: {
            link() {
                return `<a  class="tw-ml-1"
                        href="https://www.meeko.pro/conditions-utilisations-solution-saas"
                        target="_blank"
                    >${__('subscription:terms_of_use')}</a>`;
            },
            moment() {
                return moment;
            },
            sortedSources() {
                return _orderBy(this.sources, ['created'], ['desc']);
            },
            _isEmpty() {
                return _isEmpty;
            },
            subTotal() {
                const amount = this.discount.percentage ? this.amount * this.discount.percentage / 100 : this.discount.amount;

                return (this.amount - (amount ? amount : 0)).toFixed(2);
            },
            vatRate() {
                return collect(this.countriesVAT).first(item => item.countries.includes(this.customerCopy.country))?.amount ?? 0;
            },
            vatValue() {
                return (this.subTotal * (this.vatRate / 100)).toFixed(2);
            },
            total() {
                return (parseFloat(this.subTotal) + parseFloat(this.vatValue)).toFixed(2);
            },
            displayTabletCoupon() {
                const availableCountries = ['FR', 'BE', 'LU', 'CH', 'MC'];

                return availableCountries.includes(this.customerCopy.country);
            },
        },
        mounted() {
            this.customerCopy = cloneDeep(this.customer);

            if (this.customerCopy.country === null) {
                this.customerCopy.country = this.nursery.country || 'FR';
            }

            if (this.$route.params.plan === 'monthly-99-2018') {
                this.amount = 99.00;
                this.plan = __('subscription:monthly_subscription');
                if (this.customer.is_premium) {
                    this.addCouponCode('monthly-premium');
                    this.plan = __('subscription:monthly_premium_subscription');
                }
            }

            if (this.$route.params.plan === 'yearly-1188-2018') {
                this.amount = 1188.00;
                this.plan = __('subscription:yearly_subscription');
                if (this.customer.is_premium) {
                    this.plan = __('subscription:yearly_premium_subscription');
                    this.addCouponCode('yearly-premium');
                } else {
                    this.addCouponCode('yearly-8');
                }
            }
        },
        methods: {
            setPromo(name) {
                this.addCouponCode(name);
            },
            addCouponCode(coupon) {
                const data = {
                    coupon,
                    plan: this.$route.params.plan,
                };

                useApi().legacy.post(route('customers.plans.coupon', {
                    customer: this.customer.id,
                }), data).then(response => {
                    this.discount = {
                        name: response.data.name,
                        coupon: response.data.coupon,
                        amount: response.data.amount,
                        percentage: response.data.percentage,
                    };
                    this.errors.coupon = undefined;
                }).catch(error => {
                    this.errors = error.response.data.errors;
                }).then(() => {
                    this.coupon = undefined;
                });
            },
            handlePaymentIntent(payment_intent) {
                return new Promise((resolve, reject) => {
                    this.stripeClient.confirmCardPayment(payment_intent).then(result => {
                        if (result.error) {
                            reject(result.error.message);
                        } else {
                            resolve();
                        }
                    });
                });
            },
            subscribe() {
                this.loader.start('subscribe');

                const data = {
                    ...this.customerCopy,
                    discount: this.discount,
                    plan: this.$route.params.plan,
                };

                useApi().legacy.post(route('customers.plans.subscribe', {
                    customer: this.customer.id,
                }), data).then(response => {
                    if (response.data.payment_intent) {
                        this.payment_intent = response.data.payment_intent;
                        this.handlePaymentIntent(this.payment_intent).then(() => {
                            response.data.customer.subscription_status = 'active';
                            this.subscribeSuccess(response.data.customer);
                        }).catch(() => {
                            response.data.customer.subscription_status = 'incomplete';
                            this.subscribeIncomplete(response.data.customer);
                        }).finally(() => {
                            this.loader.stop('subscribe');
                        });
                    } else if (response.data.status === 'incomplete') {
                        this.loader.stop('subscribe');
                        response.data.customer.subscription_status = 'incomplete';
                        this.subscribeIncomplete(response.data.customer);
                    } else {
                        this.loader.stop('subscribe');
                        response.data.customer.subscription_status = 'active';
                        this.subscribeSuccess(response.data.customer);
                    }
                }).catch(error => {
                    this.errors = error.response.data.errors;
                }).finally(() => {
                    this.loader.stop('subscribe');
                });
            },
            subscribeSuccess(customer) {
                this.$emit('customerUpdated', customer);
                this.$router.push({name: 'subscriptions.plan'});
                swal({
                    title: __('subscription:subscription_activated_successfully'),
                    type: 'success',
                    showCloseButton: true,
                    showCancelButton: false,
                    confirmButtonClass: 'btn btn-primary mr-2',
                    confirmButtonText: __('common:actions.close'),
                    buttonsStyling: false,
                });
            },
            subscribeIncomplete(customer) {
                this.$emit('customerUpdated', customer);
                this.$router.push({name: 'subscriptions.plan'});
                swal({
                    title: __('subscription:errors.payment_not_be_processed'),
                    text: __('subscription:go_to_your_invoices_to_regularize_the_situation'),
                    type: 'warning',
                    showCloseButton: true,
                    showCancelButton: false,
                    confirmButtonClass: 'btn btn-primary mr-2',
                    confirmButtonText: __('common:actions.close'),
                    buttonsStyling: false,
                });
            },
        },
    };
</script>
