<script setup lang="ts">
    import {computed, inject, nextTick, ref} from 'vue';
    import type MFloatingContext from '@/modules/meeko-ui/components/MFloatingContext';
    import {whenever} from '@vueuse/core';
    import MFloatingContainer from '@/modules/meeko-ui/components/MFloatingContainer.vue';

    const context = inject<ReturnType<typeof MFloatingContext>>('MFloatingContext');

    if (!context) {
        throw new Error('MFloating must be used inside a MPopover');
    }

    const {
        panelId,
        isOpen,
        floatingStyles,
        floatingEl,
        isInjected,
        usePortal,
        isPositioned,
        middlewareData,
        isReady,
        finalPlacement,
    } = context;

    const myFloatingEl = ref();

    whenever(isInjected, () => {
        nextTick(() => {
            floatingEl.value = myFloatingEl.value;
        });
    });

    function onAfterEnter() {
        nextTick(() => {
            isReady.value = true;
        });
    }

    function onAfterLeave() {
        isInjected.value = false;
        isReady.value = false;
    }

    const computedClass = computed(() => {
        const output = [] as string[];

        output.push(placementValues[finalPlacement.value]);

        return output;
    });

    const placementValues = {
        'top-start': 'tw-origin-bottom-left',
        'top': 'tw-origin-bottom',
        'top-end': 'tw-origin-bottom-right',
        'bottom-start': 'tw-origin-top-left',
        'bottom': 'tw-origin-top',
        'bottom-end': 'tw-origin-top-right',
        'left-start': 'tw-origin-top-right',
        'left': 'tw-origin-right',
        'left-end': 'tw-origin-bottom-right',
        'right-start': 'tw-origin-top-left',
        'right': 'tw-origin-left',
        'right-end': 'tw-origin-bottom-left',
    };
</script>

<template>
    <portal
        v-if="isInjected"
        :disabled="!usePortal"
        to="overlay"
    >
        <MFloatingContainer
            :key="panelId"
            :context="context"
        >
            <div
                ref="myFloatingEl"
                class="MFloating"
                :class="{'MFloating--animated': isReady}"
                :style="{
                    ...floatingStyles,
                    visibility: middlewareData.hide?.referenceHidden
                        ? 'hidden'
                        : 'visible',
                }"
            >
                <transition
                    name="floating"
                    @after-enter="onAfterEnter"
                    @after-leave="onAfterLeave"
                >
                    <div
                        v-show="isOpen && isPositioned"
                        :class="[computedClass]"
                    >
                        <slot/>
                    </div>
                </transition>
            </div>
        </MFloatingContainer>
    </portal>
</template>

<style scoped lang="scss">
    .MFloating {
        z-index: 3060;
    }

    .MFloating--animated {
        transition: transform 0.65s cubic-bezier(0.43, 0.33, 0.14, 1.01) 0s;
    }

    .floating-enter-active {
        transition: opacity .25s cubic-bezier(0, 1, .4, 1), transform .25s cubic-bezier(.18, 1.25, .4, 1);
    }

    .floating-enter {
        opacity: 0;
        transform: scale(.85)
    }

    .floating-enter-to, .floating-leave {
        opacity: 1;
        transform: scale(1)
    }

    .floating-leave-active {
        transition: opacity .2s ease-in, transform .2s ease-in;
    }

    .floating-leave-to {
        opacity: 0;
        transform: scale(.95)
    }


    .ContextualPopover-animate {
        opacity: 0;
        transition: opacity .25s cubic-bezier(0, 1, .4, 1), transform .25s cubic-bezier(.18, 1.25, .4, 1);
        transform: scale(.85)
    }

    .ContextualPopover-animate-exiting {
        transition: opacity .2s ease-in, transform .2s ease-in;
        transform: scale(.95)
    }

    .ContextualPopover-animate-entered, .ContextualPopover-animate-entering, .ContextualPopover-noAnimation {
        opacity: 1;
        transform: scale(1)
    }
</style>
