<template>
    <planning-generator
        :closing="closing"
        :day="day"
        :events="events"
        inner-text
        :nursery="legacyNursery"
        :opening="opening"
        :show-clock="false"
        :show-divider="false"
        :show-menu="false"
        small
        :user="legacyUser"
    />
</template>

<script lang="ts">
    import type {PropType} from 'vue';
    import {computed, defineComponent} from 'vue';
    import useManager from '@/modules/app/composables/useManager';
    import useAuth from '@/modules/app/composables/useAuth';
    import usePlanningOpening from '@/modules/planning/composables/core/usePlanningOpening';
    import {getPlanningEventsFromKidContracts} from '@/modules/legacy/libs/Planning';
    import type {Moment} from 'moment';
    import _concat from 'lodash-es/concat';
    import _map from 'lodash-es/map';

    export default defineComponent({
        props: {
            day: {
                type: Object as PropType<Moment>,
                required: true,
            },
            kid: {
                type: Object,
                required: true,
            },
        },
        setup(props) {
            const {legacyNursery} = useManager();
            const {legacyUser} = useAuth();
            const {getOpening, getClosing} = usePlanningOpening();

            const computedPlanning = computed(() => {
                return getPlanningEventsFromKidContracts(props.kid.contracts, props.day, true);
            });

            const computedAbsences = computed(() => {
                _map(props.kid.absences, absence => {
                    absence.type = 'absence';
                    absence.event_type = 'absence';
                    absence.nursery_id = legacyNursery.value.id;

                    return absence;
                });

                return props.kid.absences;
            });

            const events = computed(() => {
                return _concat(computedPlanning.value, computedAbsences, computedAbsences.value);
            });

            const opening = computed(() => {
                return getOpening(legacyNursery.value.openingHours, computedPlanning.value, false, false);
            });

            const closing = computed(() => {
                return getClosing(legacyNursery.value.openingHours, computedPlanning.value, false, false);
            });

            return {
                closing,
                events,
                legacyNursery,
                legacyUser,
                opening,
            };
        },
    });
</script>
