import type PaymentModel from '@/modules/cashier/models/PaymentModel';
import type {CreditNoteAllocationAggregate} from '@/modules/cashier/payment/domain/CreditNoteAllocationAggregate';
import type {
    TransactionCreditAllocationAggregate,
} from '@/modules/cashier/payment/domain/TransactionCreditAllocationAggregate';
import type {
    TransactionDebitAllocationAggregate,
} from '@/modules/cashier/payment/domain/TransactionDebitAllocationAggregate';
import type {
    InvoiceAllocationAggregatePort,
} from '@/modules/cashier/payment/application/ports/InvoiceAllocationAggregatePort';
import type {
    CreditNoteAllocationAggregatePort,
} from '@/modules/cashier/payment/application/ports/CreditNoteAllocationAggregatePort';
import type {
    GetAllocationsSuggestionsForPaymentUseCase,
} from '@/modules/cashier/payment/application/GetAllocationsSuggestionsForPaymentUseCase';

export class GetAllocationsSuggestionsForPaymentInteractor implements GetAllocationsSuggestionsForPaymentUseCase
{
    private readonly _invoiceAllocationsAggregateRepository: InvoiceAllocationAggregatePort;
    private readonly _creditNoteAllocationsAggregateRepository: CreditNoteAllocationAggregatePort;

    constructor(
        invoiceAllocationsAggregateRepository: InvoiceAllocationAggregatePort,
        creditNoteAllocationsAggregateRepository: CreditNoteAllocationAggregatePort,
    ) {
        this._invoiceAllocationsAggregateRepository = invoiceAllocationsAggregateRepository;
        this._creditNoteAllocationsAggregateRepository = creditNoteAllocationsAggregateRepository;
    }

    public async fetchCreditTransaction(payment: PaymentModel): Promise<TransactionCreditAllocationAggregate[]> {
        return this._invoiceAllocationsAggregateRepository.getTransactionSuggestions(payment);
    }

    public async fetchDebitTransaction(payment: PaymentModel): Promise<TransactionDebitAllocationAggregate[]> {
        return this._creditNoteAllocationsAggregateRepository.getTransactionSuggestions(payment);
    }

    public async fetchCreditNote(payment: PaymentModel): Promise<CreditNoteAllocationAggregate[]> {
        return this._invoiceAllocationsAggregateRepository.getCreditNoteSuggestions(payment);
    }
}
