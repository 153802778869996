import useSaveDocumentAction from '@/modules/document/composables/useSaveDocumentAction';
import type {Ref} from 'vue';
import {computed, ref, unref, watch} from 'vue';
import mitt from 'mitt';
import DocumentModel from '@/modules/document/models/DocumentModel';
import {
    catcher,
    collect,
    type Collection,
    MqlOperation,
    MqlTransaction,
    type ResponseObject,
    ValidationError,
} from '@meekohq/lumos';
import type OrganizationModel from '@/modules/organization/models/OrganizationModel';
import type DocumentOrganizationPivot from '@/modules/document/models/DocumentOrganizationPivot';
import type {MaybeRef} from '@vueuse/core';
import useError from '@/modules/app/composables/useError';
import __ from '@/modules/app/utils/i18n-facade';
import {UploadError} from '@/modules/legacy/errors';
import useNotification from '@/modules/meeko-ui/composables/useNotification';

export type VisibilitiesType = {
    staffs_access: boolean,
    parents_access: boolean,
    web_access: boolean,
};

export default function(activeOrganization: MaybeRef<OrganizationModel>) {
    const {loading, saveAndAttachDocument} = useSaveDocumentAction();

    /**
     * States
     */
    const documentFormError = useError();
    const {error} = useNotification();
    const bus = mitt<{ error: Error, saved: DocumentModel, deleted: DocumentModel }>();
    const document = ref(new DocumentModel()) as Ref<DocumentModel>;
    const relatedResource = ref();
    const file = ref<File>();
    const visibilities = ref<VisibilitiesType>({
        staffs_access: false,
        parents_access: false,
        web_access: false,
    });
    const fileInputErrors = ref<Error[]>([]);

    // All the organizations that can be attached to the document
    const availableOrganizations = ref(collect()) as Ref<Collection<OrganizationModel>>;

    // List the organizations that have been selected
    const selectedOrganizations = ref([unref(activeOrganization).clone()]) as Ref<OrganizationModel[]>;

    /**
     * Getters
     */

    // List the organizations that have been selected and are not already attached to the document
    const organizationsToAttach = computed(() => {
        // 1. Remove the organizations that are already attached to the document
        return selectedOrganizations.value
            .filter(o1 => {
                return document.value
                    .organizations()
                    .value()
                    .doesntContain(o2 => o1.getKey() === o2.getKey());
            });
    });

    // List the organizations that have been unselected and were attached to the document
    const organizationsToDetach = computed(() => {
        /*
         1. Reject the organizations that have been selected
        */
        return document
            .value
            .organizations()
            .value()
            .reject(organization => {
                return selectedOrganizations.value.map(o => o.getKey()).includes(organization.getKey());
            })
            .toArray();
    });

    // List the organizations pivots that have to be updated
    const organizationsPivotsToUpdate = computed(() => {
        /*
         1. Reject the organizations that will be detached
         2. Map to DocumentOrganizationPivot
        */
        return document.value.organizations().value()
            .reject(o1 => {
                return organizationsToDetach.value.map(o => o.getKey()).includes((o2: OrganizationModel) => o1.getKey() === o2.getKey());
            })
            .map(o => o.pivot<DocumentOrganizationPivot>());
    });

    /**
     * Actions
     */

    // Update automatically the visibilities on the pivots when the visibilities or the available organizations change
    watch([selectedOrganizations, visibilities], () => {
        /*
         1. Map to DocumentOrganizationPivot
         2. Update visibilities on pivots
        */
        selectedOrganizations.value
            .filter(organization => !!organization.pivot<DocumentOrganizationPivot>())
            .map(organization => organization.pivot<DocumentOrganizationPivot>())
            .forEach(pivot => {
                pivot.attributes.staffs_access = visibilities.value.staffs_access;
                pivot.attributes.parents_access = visibilities.value.parents_access;
                pivot.attributes.web_access = visibilities.value.web_access;
            });
    }, {deep: true});

    /**
     * Save the document and attach it to organizations
     */
    async function save() {
        documentFormError.reset();

        if (!document.value.attributes.name) {
            documentFormError.addErrorCode('name', '0x2EAA809FB3');
        }

        if (!document.value.attributes.link && !file.value && !document.value.attributes.filename) {
            documentFormError.add('filename', '0x37ED75D978', __('document:errors.file_or_link_required'));
        }

        // If the file input is invalid (file is too big), do not save the document
        if (fileInputErrors.value.length || documentFormError.hasErrors.value) {
            return;
        }

        loading.value = true;

        const organizationsStatuses = prepareOrganizationsStatuses();

        try {
            // Save the document and related pivots
            await saveAndAttachDocument(
                document.value,
                file.value,
                organizationsToAttach.value,
                organizationsToDetach.value,
                organizationsPivotsToUpdate.value,
                visibilities.value,
                relatedResource.value,
            );

            const notifyUpdate = document.value.exists
                && (
                    'link' in document.value.changes
                    || 'filename' in document.value.changes
                );

            await new MqlOperation<ResponseObject.CustomObject>('media/documents/notify', {
                document_id: document.value.getKey(),
                notify_update: notifyUpdate,
                organizations_statuses: organizationsStatuses,
            }).run();

            // Emit the saved event
            bus.emit('saved', document.value);
        } catch (e) {
            catcher()
                .on(ValidationError, value => documentFormError.addValidationError(value))
                .on(UploadError, () => error(__('document:errors.send_file')))
                .catch(e);
        } finally {
            loading.value = false;
        }
        loading.value = false;
    }

    function prepareOrganizationsStatuses() {
        const organizationsStatuses: { [key: string]: boolean } = {};

        organizationsPivotsToUpdate.value.toArray().forEach(pivot => {
            if (pivot.attributes.organization_id) {
                organizationsStatuses[pivot.attributes.organization_id!] = pivot.exists;
            }
        });

        organizationsToAttach.value.forEach(organization => {
            organizationsStatuses[organization.getKey()] = false;
        });

        return organizationsStatuses;
    }

    async function remove() {
        if (document.value.exists) {
            // get the pivot to delete if it exists
            const resourceToDetach = document.value.resources().value().first(pivot => {
                return (`${pivot.attributes.resource_id}` === `${relatedResource.value.getKey()}`)
                    && (pivot.attributes.resource_type === relatedResource.value.getType());
            });

            const mqlRunner = new MqlTransaction();

            // Detach all the organizations
            document.value.organizations().value().each(organization => {
                document.value.organizations().detach(organization, {mqlRunner});
            });

            // Delete the pivot between document and the related resource
            resourceToDetach?.delete({mqlRunner});
            // Delete the document
            document.value.delete({mqlRunner});

            await mqlRunner.run();

            bus.emit('deleted', document.value);
        }
    }

    function setAvailableOrganizations(organizations: Collection<OrganizationModel>) {
        availableOrganizations.value = collect(organizations);
    }

    return {
        activeOrganization,
        availableOrganizations,
        selectedOrganizations,
        bus,
        document,
        documentFormError,
        file,
        fileInputErrors,
        loading,
        organizationsToAttach,
        relatedResource,
        save,
        setAvailableOrganizations,
        remove,
        visibilities,
        organizationsPivotsToUpdate,
    };
}
