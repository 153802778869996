<template>
    <div class="EditInvoiceDetailsLine">
        <div class="EditInvoiceDetailsLine__item-description">
            <div class="tw-float-left">
                <invoiceType v-model="line.type"/>
            </div>
            {{ line.name }}
        </div>
        <MListItemValue
            v-if="withTva"
            class="EditInvoiceDetailsLine__item-tva"
            hide-title-on-desktop
            hide-title-on-tablet
        >
            <template #title>
                {{ __('common:vat') }}
            </template>
            <template #default>
                {{ line.tva }}%
            </template>
        </MListItemValue>
        <MListItemValue
            class="EditInvoiceDetailsLine__item-price"
            hide-title-on-desktop
            hide-title-on-tablet
        >
            <template #title>
                {{ __('billing_invoice:price_with_vat') }}
            </template>
            <template #default>
                {{ formattedLinePrice }}
            </template>
        </MListItemValue>
        <MListItemValue
            class="EditInvoiceDetailsLine__item-quantity"
            hide-title-on-desktop
            hide-title-on-tablet
        >
            <template #title>
                {{ __('billing_invoice:quantity') }}
            </template>
            <template #default>
                {{ formattedLineQuantity }}
            </template>
        </MListItemValue>
        <MListItemValue
            class="EditInvoiceDetailsLine__item-total"
            hide-title-on-desktop
            hide-title-on-tablet
        >
            <template #title>
                {{ __('billing_invoice:total_with_vat') }}
            </template>
            <template #default>
                {{ format(line.total) }}
            </template>
        </MListItemValue>
    </div>
</template>

<script lang="ts">
    import {computed, defineComponent} from 'vue';
    import InvoiceType from '@/modules/cashier/components/billing/invoice/InvoiceType.vue';
    import {invoice} from '@/modules/legacy/mixins/invoice';
    import {nursery} from '@/modules/legacy/mixins/nursery';
    import useFormatNumber from '@/modules/cashier/composables/useFormatNumber';
    import MListItemValue from '@/modules/meeko-ui/components/MListItemValue.vue';
    import NumberFormatter from '@/modules/app/utils/NumberFormatter';
    import humanizeQuantity from '@/modules/cashier/composables/billing/invoice/humanizeQuantity';

    export default defineComponent({
        components: {MListItemValue, InvoiceType},
        mixins: [invoice, nursery],
        props: {
            line: {
                type: Object,
                required: true,
            },
            withTva: {
                type: Boolean,
                required: true,
            },
            currency: {
                type: String,
                required: true,
            },
        },
        setup(props) {
            const {format} = useFormatNumber(props.currency);
            const amountFormatter = new NumberFormatter({
                minimumFractionDigits: 2,
                currencyCode: props.currency,
            });

            const formattedLinePrice = computed(() => {
                return amountFormatter.parse(props.line.price).asString;
            });

            const formattedLineQuantity = computed(() => {
                return humanizeQuantity(props.line.qty, props.line.unit);
            });

            return {
                format,
                formattedLinePrice,
                formattedLineQuantity,
            };
        },
    });
</script>

<style lang="scss" scoped>
    .EditInvoiceDetailsLine {
        @apply tw-relative tw-flex tw-flex-row md:tw-flex-nowrap tw-flex-wrap tw-items-center tw-gap-2;
        @apply md:even:tw-bg-transparent even:tw-bg-blue-50 odd:tw-bg-blue-50;
        @apply tw-rounded-lg;
        @apply tw-p-2;
    }

    .EditInvoiceDetailsLine__item {
        @apply tw-flex tw-flex-row tw-items-center tw-justify-between;
        @apply tw-text-gray-800 tw-text-right;

        &-type {
            @extend .EditInvoiceDetailsLine__item;
            @apply md:tw-w-12 tw-w-full tw-text-center;
        }

        &-tva {
            @extend .EditInvoiceDetailsLine__item;
            @apply md:tw-w-20 tw-w-full;
        }

        &-price {
            @extend .EditInvoiceDetailsLine__item;
            @apply md:tw-w-28 tw-w-full;
        }

        &-quantity {
            @extend .EditInvoiceDetailsLine__item;
            @apply md:tw-w-40 tw-w-full;
        }

        &-total {
            @extend .EditInvoiceDetailsLine__item;
            @apply md:tw-w-24 tw-w-full;
        }
    }

    .EditInvoiceDetailsLine__item-description {
        @apply tw-text-gray-800;
        @apply md:tw-flex-1 md:tw-w-24 tw-w-full;
    }
</style>
