<template>
    <div>
        <CButton
            :size="size"
            @click="$refs.contractFilter.toggle()"
        >
            {{ contractStatesText }}
            <i
                aria-hidden="true"
                class="fa fa-caret-down tw-ml-1"
            />
        </CButton>
        <CDropdown
            ref="contractFilter"
            legacy-mode
        >
            <CList class="tw-cursor-pointer">
                <CListRow @click="updateContractStates(null)">
                    <i
                        v-if="!value || typeof value === 'undefined' || value.length === 0"
                        aria-hidden="true"
                        class="fa fa-check tw-mr-1"
                    />
                    {{ __('common:every_contract') }}
                </CListRow>
                <template v-if="value && Array.isArray(value)">
                    <CListRow @click="updateContractStates('coming')">
                        <i
                            v-if="value && value.includes('coming')"
                            aria-hidden="true"
                            class="fa fa-check tw-mr-1"
                        />
                        {{ __('common:upcoming_contract') }}
                    </CListRow>
                    <CListRow @click="updateContractStates('current')">
                        <i
                            v-if="value && value.includes('current')"
                            aria-hidden="true"
                            class="fa fa-check tw-mr-1"
                        />
                        {{ __('common:current_contract') }}
                    </CListRow>
                    <CListRow @click="updateContractStates('past')">
                        <i
                            v-if="value && value.includes('past')"
                            aria-hidden="true"
                            class="fa fa-check tw-mr-1"
                        />
                        {{ __('common:past_contract') }}
                    </CListRow>
                    <CDropdownMenuDivider/>
                    <!-- Not delete this div -->
                    <div/>
                    <CListRow
                        :class="{'tw-cursor-not-allowed': value && value.length === 0}"
                        size="sm"
                        @click="value && value.length > 0 ? updateContractStates('including_draft') : null;"
                    >
                        <i
                            v-if="value && value.includes('including_draft')"
                            aria-hidden="true"
                            class="fa fa-check tw-mr-1"
                        />
                        {{ __('common:include_drafts') }}
                    </CListRow>
                </template>
            </CList>
        </CDropdown>
    </div>
</template>

<script lang="ts">
    import {computed, defineComponent} from 'vue';
    import _isEqual from 'lodash-es/isEqual';
    import __ from '@/modules/app/utils/i18n-facade';

    export default defineComponent({
        props: {
            value: {
                type: Array,
                default: () => [],
            },
            size: {type: String, default: 'base'},
        },
        setup(props, {emit}) {

            const contractStatesText = computed(() => {
                let txt = __('hr_contract:filter_by_contract');
                if (!props.value || props.value.length === 0) {
                    txt = __('common:every_contract');
                }

                if (props.value.length === 1) {
                    if (props.value.includes('coming')) {
                        txt = __('common:upcoming_contract');
                    }

                    if (props.value.includes('current')) {
                        txt = __('common:current_contract');
                    }

                    if (props.value.includes('past')) {
                        txt = __('common:past_contract');
                    }

                    if (props.value.includes('including_draft')) {
                        txt = __('common:include_drafts');
                    }
                }

                return txt;
            });

            function updateContractStates(val) {
                let states = props.value ? props.value.slice() : [];

                if (!val) {
                    states = [];
                } else if (states.includes(val)) {
                    states = states.filter(e => e !== val);
                } else {
                    states.push(val);

                    const allStates = ['coming', 'current', 'past', 'including_draft'];
                    if (_isEqual([...allStates].sort(), [...states].sort())) {
                        states = [];
                    }
                }

                // Remove 'including_draft' filter if it's the only selected filter
                if (_isEqual(['including_draft'], states)) {
                    states = [];
                }

                emit('input', states);
            }

            return {
                contractStatesText,
                updateContractStates,
            };
        },
    });
</script>
