<template>
    <div>
        <div>
            <div class="row mb-3">
                <div class="col-12 col-sm-5 order-2 order-sm-1 mt-2 mt-sm-0">
                    <form autocomplete="off">
                        <input
                            id="search"
                            v-model="search"
                            autocomplete="!off"
                            class="form-control form-control-sm"
                            :placeholder="__('common:search_dots')"
                        >
                    </form>
                </div>
                <div
                    v-if="$can('create', 'posts')"
                    class="col-12 col-sm-6 text-sm-right ml-auto order-1 order-sm-2"
                >
                    <button
                        class="btn btn-sm btn-primary"
                        @click="addPostModal"
                    >
                        <i
                            aria-hidden="true"
                            class="fa fa-plus mr-2"
                        />{{ __('news:publish_news') }}
                    </button>
                </div>
            </div>
            <div
                v-if="!loading"
                class="row"
            >
                <div class="p-3 w-100">
                    <transition-group
                        name="list"
                        tag="div"
                    >
                        <div
                            v-for="(post, i) in orderedPosts"
                            :key="'post' + post.id"
                            class="card mb-3"
                        >
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-12 col-md-7">
                                        <MListItemTitle :title="post.title"/>
                                    </div>
                                    <div class="col-12 col-md-5 text-md-right mt-md-1 mb-md-0 my-2">
                                        <a
                                            v-if="$can('update', 'posts')"
                                            class="mr-2 btn btn-sm btn-info"
                                            href="#"
                                            @click="editPostModal(post)"
                                        >
                                            <i
                                                aria-hidden="true"
                                                class="fa fa-edit mr-2"
                                            />{{ __('common:actions.update') }}
                                        </a>
                                        <a
                                            v-if="$can('delete', 'posts')"
                                            class="btn btn-sm btn-danger"
                                            href="#"
                                            @click="deletePost(post, i)"
                                        >
                                            <i
                                                aria-hidden="true"
                                                class="fa fa-trash mr-2"
                                            />{{ __('common:actions.delete') }}
                                        </a>
                                    </div>
                                </div>
                                <div class="tw-flex tw-items-center tw-text-gray-500 tw-font-semibold">
                                    <CTooltip class="tw-text-sm tw-mr-1">
                                        <i
                                            v-if="post.notified_at"
                                            class="fad fa-check-circle tw-text-green-500"
                                        />
                                        <i
                                            v-else
                                            class="fad fa-clock tw-text-gray-600"
                                        />
                                        <template #content>
                                            <span v-if="post.notified_at">
                                                {{
                                                    __('news:send_at_with_date_female', {
                                                        date: Epoch.fromTimestamp(post.notified_at).toLocaleString(Epoch.presets.DATE_SHORT),
                                                        time: Epoch.fromTimestamp(post.notified_at).toLocaleString(Epoch.presets.TIME_SIMPLE),
                                                    })
                                                }}
                                            </span>
                                            <span v-else>
                                                {{ __('news:not_sent') }}
                                            </span>
                                        </template>
                                    </CTooltip>
                                    <template v-if="post.expire_at">
                                        {{
                                            __('common:date_format.human_from_to_short_with_time', {
                                                from: Epoch.fromTimestamp(post.posted_at).toLocaleString(Epoch.presets.DATE_SHORT),
                                                fromTime: Epoch.fromTimestamp(post.posted_at).toLocaleString(Epoch.presets.TIME_SIMPLE),
                                                to: Epoch.fromTimestamp(post.expire_at).toLocaleString(Epoch.presets.DATE_SHORT),
                                                toTime: Epoch.fromTimestamp(post.expire_at).toLocaleString(Epoch.presets.TIME_SIMPLE),
                                            })
                                        }}
                                    </template>
                                    <template v-else>
                                        {{
                                            __('common:date_format.date_with_time', {
                                                date: Epoch.fromTimestamp(post.posted_at).toLocaleString(Epoch.presets.DATE_SHORT),
                                                time: Epoch.fromTimestamp(post.posted_at).toLocaleString(Epoch.presets.TIME_SIMPLE),
                                            })
                                        }}
                                    </template>
                                </div>
                                <div
                                    v-if="post.web_access || post.parents_access || post.staffs_access"
                                    class="text-muted"
                                >
                                    <span
                                        v-if="post.staffs_access"
                                        class="badge badge-primary px-2 py-1 mr-1"
                                    >{{ __('common:for_team') }}
                                    </span>
                                    <span
                                        v-if="post.parents_access"
                                        class="badge badge-secondary px-2 py-1 mr-1"
                                    >{{ __('common:for_parents') }}
                                    </span>
                                    <span
                                        v-if="post.web_access"
                                        class="badge badge-info px-2 py-1 mr-1"
                                    >{{ __('common:website_meeko') }}
                                    </span>
                                </div>
                                <small
                                    v-else
                                    class="small text-muted"
                                >&nbsp;-&nbsp;{{ __('news:not_displayed_female') }}
                                </small>
                                <hr class="hr">
                                <p
                                    class="mt-3 mb-0 trumbowyg-viewer"
                                    v-html="post.content"
                                />
                            </div>
                        </div>
                    </transition-group>
                </div>
                <div
                    v-if="total > perPage"
                    class="tw-w-full tw-flex tw-justify-center tw-mt-4"
                >
                    <MPagination
                        :paginator="paginator"
                        @navigate="currentPage = $event"
                    />
                </div>
                <add-post-modal
                    ref="addPostModal"
                    :current-post="currentPost"
                    modal-id="addPostModal"
                    :nursery="nursery"
                    :user="user"
                    @postEdited="editPost"
                    @postStored="addPost"
                />
            </div>
            <h1
                v-if="!Object.keys(posts).length"
                class="h1 tw-font-grandhotel text-center"
            >
                {{ __('news:there_are_no_activities') }}
            </h1>
        </div>
        <div
            v-if="loading"
            key="loading"
        >
            <loader custom-class="la-2x"/>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';
    import swal from 'sweetalert2/dist/sweetalert2.js';
    import route from '@/modules/legacy/libs/ziggy';
    import _orderBy from 'lodash-es/orderBy';
    import _debounce from 'lodash-es/debounce';
    import _cloneDeep from 'lodash-es/cloneDeep';
    import _head from 'lodash-es/head';
    import _forEach from 'lodash-es/forEach';
    import useApi from '@/modules/app/composables/useApi';
    import {Base64} from 'js-base64';
    import AddPostModal from './AddPostModal.vue';
    import MListItemTitle from '@/modules/meeko-ui/components/MListItemTitle.vue';
    import __ from '@/modules/app/utils/i18n-facade';
    import {Epoch, LengthAwarePaginator} from '@meekohq/lumos';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';
    import MPagination from '@/modules/meeko-ui/components/MPagination.vue';
    import {url} from '@/modules/legacy/mixins/url';

    export default {
        components: {
            MPagination,
            MListItemTitle,
            'add-post-modal': AddPostModal,
        },
        mixins: [url],
        props: ['nursery', 'user'],
        data: () => ({
            loading: 1,
            posts: [],
            currentPage: 1,
            total: 0,
            perPage: 20,
            search: null,
            currentPost: {},
            paginator: undefined,
        }),
        computed: {
            Epoch() {
                return Epoch;
            },
            orderedPosts() {
                const posts = [];
                this.posts.forEach(post => {
                    post.content = this.tryDecodeBase64(post.content);
                    posts.push(post);
                });

                return _orderBy(posts, 'posted_at', 'desc');
            },
        },
        watch: {
            currentPage() {
                this.getPosts();
            },
            search: _debounce(function() {
                this.getPosts();
            }, 500),
            paginator() {
                this.pushQueryString('p', this.paginator.currentPage());
            },
        },
        mounted() {
            this.getPosts();
        },
        methods: {
            getPosts() {
                this.loading = 1;
                useApi().legacy.get(route('posts.index'), {
                    params: {
                        nursery_id: this.nursery.id,
                        page: this.currentPage,
                        per_page: this.perPage,
                        search: this.search,
                    },
                }).then(response => {
                    this.posts = response.data.data;
                    this.total = response.data.total;
                    this.perPage = response.data.per_page;
                    this.paginator = new LengthAwarePaginator(
                        response.data.data,
                        parseInt(response.data.total),
                        parseInt(response.data.per_page),
                        parseInt(response.data.current_page),
                        parseInt(response.data.last_page),
                    );
                    this.loading = 0;
                }).catch(error => {
                    if (error && error.response && error.response.status === 422) {
                        _forEach(error.response.data.errors, function(value) {
                            useNotification().error(_head(value));
                        });
                    } else {
                        useNotification().error(error);
                    }
                });
            },
            addPostModal() {
                this.currentPost = {
                    id: null,
                    title: '',
                    content: '',
                    parents_access: 0,
                    web_access: 0,
                    staffs_access: 0,
                    posted_at: Epoch.now().toTimestamp(),
                    expire_at: null,
                    nurseries: [],
                };
                setTimeout(() => {
                    this.$refs.addPostModal.$refs.modal.show();
                }, 300);
            },
            addPost() {
                this.currentPage = 1;
                this.getPosts();
            },
            editPostModal(post) {
                this.currentPost = _cloneDeep(post);
                setTimeout(() => {
                    this.$refs.addPostModal.$refs.modal.show();
                }, 300);
            },
            editPost(post) {
                const postToEdit = this.posts.find(item => item.id === post.id);
                if (postToEdit) {
                    const index = this.posts.indexOf(postToEdit);
                    Vue.set(this.posts, index, post);
                }
            },
            deletePost(post, index) {
                swal({
                    title: `<h1 class="h1 tw-font-grandhotel">${__('news:delete_news')}</h1>`,
                    confirmButtonText: __('common:actions.delete'),
                    cancelButtonText: __('common:actions.cancel'),
                }).then(result => {
                    if (result.value) {
                        useApi().legacy.delete(route('posts.destroy', {
                            post: post.id,
                        }))
                            .then(() => {
                                const postToDelete = this.posts.find(item => item.id === post.id);
                                if (postToDelete) {
                                    index = this.posts.indexOf(postToDelete);
                                    this.posts.splice(index, 1);
                                    useNotification().success(__('news:news_deleted_successfully'));
                                }
                            }).catch(error => {
                                if (error && error.response && error.response.status === 422) {
                                    _forEach(error.response.data.errors, function(value) {
                                        useNotification().error(_head(value));
                                    });
                                } else {
                                    useNotification().error(error);
                                }
                            });
                    }
                });
            },

            tryDecodeBase64(str) {
                return this.isBase64(str) ? Base64.decode(str) : str;
            },

            isBase64(str) {
                try {
                    const decodedStr = Base64.decode(str);

                    return window.btoa(unescape(encodeURIComponent(decodedStr))) == str;
                } catch (err) {
                    return false;
                }
            },
        },
    };
</script>

<style lang="scss" scoped>
    .avatar {
        height: 80px;
        width: 80px;
        float: left;
        margin-left: -10px;
        margin-top: -8px;
        margin-right: 10px;
    }

    .informations {
        background-color: #E3F2FD;
        padding: 8px 20px;
        margin: 0 -20px;
        color: #2b4c65;
    }

    .general-equipments {
        > div {
            display: inline-block;
            text-align: center;
            font-size: 0.8rem;
        }

        img {
            height: 50px;
            width: 50px;
        }
    }
</style>
