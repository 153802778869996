<template>
    <component
        :is="tag"
        :class="baseClass"
        v-on="$listeners"
    >
        <slot/>
    </component>
</template>

<script lang="ts">
    import {computed, defineComponent} from 'vue';

    export default defineComponent({
        props: {
            tag: {type: String, default: 'div'},
            space: {type: String, default: 'none'},
            alignY: {type: String, default: 'stretch'},
            alignX: {type: String, default: 'start'},
            reverse: {type: Boolean, default: false},
            width: {type: String, default: 'auto'},
            height: {type: String, default: 'auto'},
            wrap: {type: Boolean, default: false},
        },
        setup(props) {
            const {baseClass} = useStyling(props);

            return {baseClass};
        },
    });

    function useStyling(props) {
        const baseClass = computed(() => {
            const output = [] as any[];

            output.push('CInline');

            if (props.reverse) {
                output.push('CInline-reverse');
            }

            output.push(alignXValues[props.alignX]);
            output.push(alignYValues[props.alignY]);
            output.push(spacesValues[props.space]);
            output.push(widthsValues[props.width]);
            output.push(heightsValues[props.width]);
            if (props.wrap) {
                output.push('CInline-wrap');
            }

            return output;
        });

        return {baseClass};
    }

    export const alignXValues = {
        start: 'CInline-align-x-start',
        end: 'CInline-align-x-end',
        center: 'CInline-align-x-center',
        between: 'CInline-align-x-between',
        around: 'CInline-align-x-around',
        evenly: 'CInline-align-x-evenly',
    };

    export const alignYValues = {
        stretch: 'CInline-align-y-stretch',
        start: 'CInline-align-y-start',
        end: 'CInline-align-y-end',
        center: 'CInline-align-y-center',
        baseline: 'CInline-align-y-baseline',
    };

    export const spacesValues = {
        'none': '',
        'xs': 'CInline-space-1',
        'sm': 'CInline-space-2',
        'md': 'CInline-space-4',
        'lg': 'CInline-space-6',
        'xl': 'CInline-space-8',
        '2xl': 'CInline-space-10',
    };

    export const widthsValues = {
        auto: '',
        full: 'CInline-width-full',
    };

    export const heightsValues = {
        auto: '',
        full: 'CInline-height-full',
    };
</script>

<style
    lang="scss"
    scoped
>
    .CInline {
        @apply tw-flex;

        &.CInline-height-full {
            @apply tw-h-full;
        }

        &.CInline-width-full {
            @apply tw-w-full;
        }

        &.CInline-wrap {
            @apply tw-flex-wrap;
        }

        &.CInline-reverse {
            @apply tw-flex-row-reverse;
        }

        &.CInline-align-x-start {
            @apply tw-justify-start;
        }

        &.CInline-align-x-end {
            @apply tw-justify-end;
        }

        &.CInline-align-x-center {
            @apply tw-justify-center;
        }

        &.CInline-align-x-between {
            @apply tw-justify-between;
        }

        &.CInline-align-x-around {
            @apply tw-justify-around;
        }

        &.CInline-align-x-evenly {
            @apply tw-justify-evenly;
        }

        &.CInline-align-y-stretch {
            @apply tw-items-stretch;
        }

        &.CInline-align-y-start {
            @apply tw-items-start;
        }

        &.CInline-align-y-end {
            @apply tw-items-end;
        }

        &.CInline-align-y-center {
            @apply tw-items-center;
        }

        &.CInline-align-y-baseline {
            @apply tw-items-baseline;
        }

        &.CInline-space-1 {
            @apply tw-space-x-1;
        }

        &.CInline-space-2 {
            @apply tw-space-x-2;
        }

        &.CInline-space-4 {
            @apply tw-space-x-4;
        }

        &.CInline-space-6 {
            @apply tw-space-x-6;
        }

        &.CInline-space-8 {
            @apply tw-space-x-8;
        }

        &.CInline-space-10 {
            @apply tw-space-x-10;
        }
    }
</style>
