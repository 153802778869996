<template>
    <SelectResource
        ref="SelectResource"
        :builder="queryBuilder"
        :button-class="buttonClass"
        :disabled="disabled"
        :has-unselect="hasUnselect"
        :hide-on-click="!checkable"
        :model="eventTypeModel"
        :unselect-value="unselectValue"
        :value="value"
        @fallback="showModal"
        @input="onModelSelected"
        @search="onSearchValue"
    >
        <template #trigger="{toggle}">
            <slot
                name="trigger"
                :toggle="toggle"
                :value="value"
            />
        </template>
        <template #button>
            <div
                v-if="value"
                class="tw-w-auto tw-flex tw-items-center"
            >
                <div
                    class="tw-h-5 tw-w-5 tw-mr-2 tw-rounded-full tw-flex tw-justify-center tw-items-center tw-shrink-0"
                    :style="getIconStyle(value.attributes.color)"
                >
                    <CIcon
                        v-if="getIcon(value.attributes.icon_id)"
                        class="tw-text-sm"
                        :path="getIcon(value.attributes.icon_id).attributes.path"
                        :provider="getIcon(value.attributes.icon_id).attributes.provider"
                    />
                    <template v-else/>
                </div>
                <span>{{ value.attributes.name }}</span>
            </div>
            <template v-else>
                <slot name="unselectedValue">
                    {{ __('hr:find_or_add_event_type') }}
                </slot>
            </template>
        </template>

        <template
            v-if="createOptionEnabled"
            #fallback="{searchValue}"
        >
            <template v-if="searchValue">
                <i
                    aria-hidden="true"
                    class="fas fa-plus fa-fw tw--ml-1 tw-mr-1"
                />{{ __('common:actions.add') }} {{ searchValue }}
            </template>
            <template v-else>
                <i
                    aria-hidden="true"
                    class="fas fa-plus fa-fw tw--ml-1 tw-mr-1"
                />{{ __('hr:add_event_type') }}
            </template>
        </template>

        <template
            v-if="value && hasUnselect"
            #unselect-item
        >
            <slot name="unselect-item">
                <i
                    aria-hidden="true"
                    class="fad fa-unlink fa-fw tw--ml-1 tw-mr-1"
                />{{ __('hr:deselect_current_type') }}
            </slot>
        </template>

        <template #default="{option, searchValue}">
            <div class="tw-flex tw-items-center tw-pr-2">
                <div
                    class="tw-h-5 tw-w-5 tw-mr-2 tw-rounded-full tw-flex tw-justify-center tw-items-center"
                    :style="getIconStyle(option.attributes.color)"
                >
                    <CIcon
                        v-if="getIcon(option.attributes.icon_id)"
                        class="tw-text-sm"
                        :path="getIcon(option.attributes.icon_id).attributes.path"
                        :provider="getIcon(option.attributes.icon_id).attributes.provider"
                    />
                    <template v-else/>
                </div>
                <div
                    class="tw-truncate"
                    v-html="TextHighlighter.highlight(searchValue, option.attributes.name)"
                />
                <div
                    v-if="checkable && !uncheckedTypes.includes(option.id)"
                    class="tw-ml-2 tw-mr-auto"
                >
                    <i
                        aria-hidden="true"
                        class="fa fa-check tw-text-gray-500"
                    />
                </div>
            </div>
        </template>
    </SelectResource>
</template>


<script>
    import {ref} from 'vue';
    import Loader from '@/modules/legacy/helpers/loader.helper';
    import TextHighlighter from '@/modules/legacy/helpers/text-highlighter.helper';
    import SelectResource from '@/modules/app/components/SelectResource.vue';
    import EventTypeModel from '@/modules/human-resources/models/EventTypeModel';
    import DataStore from '@/modules/legacy/store/data.store';
    import Manager from '@/modules/legacy/store/manager.store';
    import useEventStyle from '@/modules/human-resources/composables/calendar/useEventStyle';

    export default {
        components: {SelectResource},
        props: {
            unselectValue: {},
            value: {},
            organizationModel: {},
            injectQuery: {},
            disabled: {
                default: false,
            },
            customModal: {
                default: false,
            },
            createOptionEnabled: {
                default: false,
            },
            hasUnselect: {
                type: Boolean,
                default: false,
            },
            checkable: {
                type: Boolean,
                default: false,
            },
            uncheckedTypes: {
                type: Array,
                default: null,
            },
            buttonClass: {},
        },

        emits: ['input', 'hideModal', 'showModal'],

        data() {
            return {
                icon: null,
                eventTypeModel: EventTypeModel,
                loader: new Loader(),
                TextHighlighter: new TextHighlighter,
                searchValue: '',
            };
        },

        computed: {
            queryBuilder() {
                const organizationModal = this.organizationModel
                    ? this.organizationModel
                    : Manager.activeOrganization;

                const query = EventTypeModel.query().orderBy('name')
                    .whereLike('name', `*${this.searchValue}*`)
                    .where('archived_at', '=', null)
                    .whereHas(new EventTypeModel().organizationsPivots(), q => {
                        q.where('organization_id', '=', organizationModal.id)
                            .where('archived_at', '=', null);
                    });

                if (this.injectQuery) {
                    query.inject(this.injectQuery);
                }

                return query;
            },

            defaultModal: {
                cache: false,
                get() {
                    return this.$refs.defaultModal;
                },
            },

            finder: {
                cache: false,
                get() {
                    return this.$refs.finder;
                },
            },

            SelectResource: {
                cache: false,
                get() {
                    return this.$refs.SelectResource;
                },
            },
        },

        methods: {

            getIcon(iconId) {
                return DataStore.iconPath(iconId);
            },
            onSearchValue(event) {
                this.searchValue = event;
            },

            onModelCreated(model) {
                this.hideModal();
                this.onModelSelected(model);
            },

            onModelSelected(model) {
                this.$emit('input', model);
            },

            hideModal() {
                if (this.customModal) {
                    this.$emit('hideModal');
                } else {
                    this.defaultModal.hide();
                }
            },

            showModal() {
                if (this.customModal) {
                    this.$emit('showModal');
                } else {
                    this.defaultModal.show();
                }
                this.SelectResource.hide();
            },

            getIconStyle(color) {
                return color
                    ? useEventStyle(ref(color), ref(false), 'md', true).badgeStyle
                        .value
                    : null;
            },
        },
    };
</script>
