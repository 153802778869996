<template>
    <div
        v-if="registration.id && !loading"
        class="tw-mt-7"
    >
        <div class="tw-flex tw-flex-col lg:tw-flex-row">
            <div class="lg:tw-w-64 tw-w-full tw-flex tw-flex-col tw-gap-3">
                <m-layout-box class="tw-p-5">
                    <m-form-inplace-editor
                        ref="kid"
                        :force-edit="hasError(errors, ['kid_first_name', 'kid_last_name','kid_birthdate','kid_gender'])"
                        :input-refs="['kid_first_name', 'kid_last_name']"
                        no-border
                        :no-edit="!$can('update', 'registrations')"
                        :no-edit-btn="!$can('update', 'registrations')"
                        no-padding
                        @editing="$refs.kid_first_name.$el.focus()"
                        @keyup.enter.native="$refs.kid.save"
                        @save="saveRegistration"
                    >
                        <h1
                            class="h1 text-center tw-text-blue-500 tw-font-grandhotel tw-text-4xl tw-mx-5"
                            :class="{'tw-text-girl': registration.kid_gender === 'female'}"
                        >
                            <span
                                class="tw-mr-2 tw-break-words"
                                :class="{'tw-text-gray-300': !registration.kid_first_name}"
                            >
                                {{ $capitalize(registration.kid_first_name ? registration.kid_first_name : __('common:first_name')) }}
                            </span>
                            <span
                                class="tw-break-words"
                                :class="{'tw-text-gray-600': !registration.kid_last_name}"
                            >
                                {{ $capitalize(registration.kid_last_name ? registration.kid_last_name : __('common:last_name')) }}
                            </span>
                        </h1>
                        <p class="text-center tw-font-display tw-mb-0">
                            <span v-if="moment(registration.kid_birthdate).unix() < moment().unix()">
                                <template v-if="registration.kid_gender === 'male'">
                                    {{ __('common:born_on_male') }}
                                    {{ parse(kid.birthdate, 'yyyy-MM-dd').toLocaleString(Epoch.presets.DATE_SHORT) }}
                                </template>
                                <template v-else>
                                    {{ __('common:born_on_female') }}
                                    {{ parse(kid.birthdate, 'yyyy-MM-dd').toLocaleString(Epoch.presets.DATE_SHORT) }}
                                </template>
                            </span>
                            <span v-else>
                                {{ __('common:will_be_born_on', {date: formatDate(registration.kid_birthdate)}) }}
                            </span>
                        </p>
                        <p class="text-center tw-font-display tw-mb-0">
                            {{
                                __(registration.kid_gender === 'male' ? 'common:welcome_at_age_male' : 'common:welcome_at_age_female', {
                                    value: useKidAge().getByBirthdate(
                                        Epoch.fromISOString(registration.kid_birthdate),
                                        Epoch.fromISOString(registration.contract_started_at),
                                    ),
                                })
                            }}
                        </p>
                        <template #edition>
                            <m-form-group-inplace-inputs>
                                <label class="label tw-block tw-text-grey-darker tw-text-sm tw-font-semibold tw-mb-0">
                                    {{ __('common:gender_one') }}
                                </label>
                                <CFormSelect
                                    v-model="registration.kid_gender"
                                    class="tw-w-full"
                                    :has-error="hasError(errors, ['kid_gender'])"
                                    :options="[{value: 'male', text: __('common:gender.boy')}, {value: 'female', text: __('common:gender.girl')}, {value: null, text: __('common:gender.unknow')}]"
                                    :search-bar="false"
                                />
                                <label class="label tw-block tw-text-grey-darker tw-text-sm tw-font-semibold tw-mb-0 tw-mt-2">
                                    {{ __('common:first_name') }}
                                </label>
                                <m-form-input
                                    ref="kid_first_name"
                                    v-model="registration.kid_first_name"
                                    :class="{'is-invalid': hasError(errors, ['kid_first_name'])}"
                                    size="sm"
                                />
                                <label class="label tw-block tw-text-grey-darker tw-text-sm tw-font-semibold tw-mb-0 tw-mt-2">
                                    {{ __('common:last_name') }}
                                </label>
                                <m-form-input
                                    ref="kid_last_name"
                                    v-model="registration.kid_last_name"
                                    :class="{'is-invalid': hasError(errors, ['kid_last_name'])}"
                                    size="sm"
                                />
                                <label class="label tw-block tw-text-grey-darker tw-text-sm tw-font-semibold tw-mb-0 tw-mt-2">
                                    {{ __('common:birthdate') }}
                                </label>
                                <MDatePicker
                                    v-model="registration.kid_birthdate"
                                    class="tw-w-full"
                                    format="yyyy-MM-dd"
                                />
                            </m-form-group-inplace-inputs>
                        </template>
                    </m-form-inplace-editor>
                    <div class="tw-bg-gray-300 tw--mx-5 tw-h-px tw-my-3"/>
                    <div
                        class="text-center tw--mx-5 tw--my-3 hover:tw-bg-gray-50 tw-cursor-pointer"
                        @click="editPlanning"
                    >
                        <kid-planning-summary
                            :nursery="nursery"
                            :plannings="registration.plannings"
                            :weeks="registration.contract_recurrent_weeks"
                        />
                        <div class="tw-border-b tw-border-dashed tw-h-px tw-my-3 tw-mb-0"/>
                        <div class="tw-p-2">
                            <div class="tw-flex tw-justify-center tw-items-end">
                                <div class="tw-font-semibold tw-text-sm">
                                    {{ formatDate(contract.started_at) }}
                                </div>
                                <div class="tw-w-16 tw-mx-3 tw-flex tw-flex-col tw-items-center">
                                    <span class="tw-text-blue-500 tw-font-semibold tw-text-sm">
                                        {{ diffBetweenTwoDates(contract.ended_at, contract.started_at) }}
                                        {{ __('common:month') }}
                                    </span>
                                    <div class="tw-flex tw-h-5 tw-items-center tw-relative tw-w-full">
                                        <div class="h-arrow tw-bg-blue-300 tw-flex-grow tw-rounded-full"/>
                                        <i
                                            class="fa-angle-right far tw-absolute tw-text-blue-300"
                                            style="right: -2px; font-size: 26px;"
                                        />
                                    </div>
                                </div>
                                <div class="tw-font-semibold tw-text-sm">
                                    {{ formatDate(contract.ended_at) }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tw-bg-gray-300 tw--mx-5 tw-h-px tw-my-3"/>
                    <m-form-inplace-editor
                        ref="requested"
                        :force-edit="hasError(errors, ['requested_at'])"
                        :no-edit="!$can('update', 'registrations')"
                        :no-edit-btn="!$can('update', 'registrations')"
                        @save="saveRegistration"
                    >
                        <p class="text-center mb-0">
                            {{ __('registration:requested_at') }}
                            <span class="tw-font-semibold">{{ formatDate(registration.requested_at) }}</span>
                        </p>
                        <template #edition>
                            <MDatePicker
                                v-model="registration.requested_at"
                                class="tw-w-full"
                                format="yyyy-MM-dd"
                            />
                        </template>
                    </m-form-inplace-editor>
                    <div
                        v-if="$can('delete', 'registrations')"
                        class="tw-mt-3 tw--mb-4 tw--mx-4"
                    >
                        <button
                            class="tw-bg-red-100 tw-border-red-500 tw-font-semibold tw-py-1 tw-rounded-full tw-text-red-500 tw-text-sm tw-w-full hover:tw-bg-red-500 hover:tw-text-white"
                            @click="remove"
                        >
                            {{ __('registration:delete_registration') }}
                        </button>
                    </div>
                </m-layout-box>
                <div class="tw-flex tw-flex-wrap tw-gap-3">
                    <div class="tw-flex-grow">
                        <first-parent
                            :errors="errors"
                            :registration="registration"
                            @save="setAndSaveRegistration"
                        />
                    </div>
                    <div class="tw-flex-grow">
                        <second-parent
                            :errors="errors"
                            :registration="registration"
                            @save="setAndSaveRegistration"
                        />
                    </div>
                </div>
            </div>
            <div class="main tw-mt-4 lg:tw-ml-10 lg:tw-mt-0 tw-w-full">
                <m-layout-box class="tw-p-5 sm:tw-h-40 tw-h-60 tw-overflow-y-auto tw-flex tw-flex-col tw-gap-6">
                    <div
                        v-for="(nurseryRegistration, key) in sortedNurseries"
                        :key="'sortedNursery' + key"
                    >
                        <deposited-in-nursery
                            :id="nurseryRegistration.id"
                            :accepted="nurseryRegistration.accepted"
                            :city="nurseryRegistration.city"
                            :declined="nurseryRegistration.declined"
                            :deposited="nurseryRegistration.deposited"
                            :kid_id="nurseryRegistration.kid_id"
                            :name="nurseryRegistration.name"
                            :permissions="nurseryRegistration.permissions"
                            :registration="registration"
                            @estimateInvoice="estimateInvoice"
                            @refreshNurseries="refreshNurseries"
                        />
                    </div>
                </m-layout-box>
                <div class="tw-my-8 tw-flex tw-flex-wrap tw-gap-2 tw-items-center tw-justify-between">
                    <MHeading>
                        {{ __('registration:registration_follow_up') }}
                    </MHeading>
                    <div class="tw-flex tw-gap-2 tw-items-center">
                        <a
                            v-if="nursery.websites[0]"
                            :href="'https://' + nursery.websites[0].slug + '.meeko.site/registration/' + registration.uuid"
                            target="_blank"
                        >
                            <MTooltip
                                :label="__('registration:link_to_modify_folder')"
                                placement="top"
                            >
                                <MButton>
                                    {{ __('registration:parent_link') }}
                                    <template #right-icons>
                                        <FontAwesomeIcon icon="fa fa-share-square"/>
                                    </template>
                                </MButton>
                            </MTooltip>
                        </a>
                        <TagsSelector
                            v-if="registrationModel"
                            :editable="$can('update', 'registrations')"
                            :taggable-model="registrationModel"
                            taggable-type="registration"
                            :tags="registrationModel.tags().value().toArray()"
                            @attached="registrationModel.tags().value().push($event)"
                            @detached="registrationModel.tags().mutate(v => v.filter(c => c.getKey() !== $event.getKey()))"
                        />
                    </div>
                    <div class="section-title"/>
                </div>
                <activity-timeline
                    :registration="registration"
                />
            </div>
        </div>

        <edit-plannings
            ref="planning"
            :country="user"
            :nursery="nursery"
            :opening-hours="nursery.openingHours"
            :registration="registration"
            @registrationUpdated="setAndSaveRegistration"
        />
        <estimate-invoice
            ref="estimateInvoice"
            :contract="contract"
            :kid="kid"
            :nursery="selectedNursery"
            :registration="registration"
            :user="user"
            @registrationUpdated="setRegistration"
        />
    </div>
    <div
        v-else
        key="loader"
    >
        <loader custom-class="la-2x"/>
    </div>
</template>

<script>
    import Vue from 'vue';
    import route from '@/modules/legacy/libs/ziggy';
    import useApi from '@/modules/app/composables/useApi';
    import moment from 'moment';
    import swal from 'sweetalert2/dist/sweetalert2.js';
    import _head from 'lodash-es/head';
    import _find from 'lodash-es/find';
    import _reverse from 'lodash-es/reverse';
    import _sortBy from 'lodash-es/sortBy';
    import _forEach from 'lodash-es/forEach';
    import {url} from '@/modules/legacy/mixins/url';
    import EditPlannings from '@/modules/registration/components/Planning/EditPlannings.vue';
    import EstimateInvoice from '@/modules/registration/components/Estimate/Modal.vue';
    import KidPlanningSummary from '@/modules/legacy/components/Modules/KidPlanningSummary.vue';
    import DepositedInNursery from '@/modules/registration/components/Partials/DepositedInNursery.vue';
    import ParentCard1 from '@/modules/registration/components/Partials/ParentCard.vue';
    import ParentCard2 from '@/modules/registration/components/Partials/ParentCard2.vue';
    import ActivityTimeline from '@/modules/registration/components/ActivityTimeline.vue';
    import {intersection, keys} from 'lodash-es';
    import __ from '@/modules/app/utils/i18n-facade';
    import useMoment from '@/modules/app/composables/useMoment';
    import {Epoch} from '@meekohq/lumos';
    import useEpoch from '@/modules/app/composables/useEpoch';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';
    import useKidAge from '@/modules/family/composables/kid/useKidAge';
    import RegistrationModel from '@/modules/registration/models/RegistrationModel';
    import TagsSelector from '@/modules/tag/components/TagsSelector.vue';

    export default {
        components: {
            TagsSelector,
            ActivityTimeline,
            'first-parent': ParentCard1,
            'second-parent': ParentCard2,
            'deposited-in-nursery': DepositedInNursery,
            'edit-plannings': EditPlannings,
            'estimate-invoice': EstimateInvoice,
            'kid-planning-summary': KidPlanningSummary,
        },
        mixins: [url],
        props: ['user', 'nursery'],
        data: () => ({
            Epoch,
            registration: {},
            registrationModel: {},
            contract: {},
            kid: {},
            errors: {},
            loading: false,
            saveLoading: false,
            selectedNursery: null,
            formatDate: useMoment().formatDate,
        }),
        computed: {
            sortedNurseries() {
                const nurseriesWithPermission = [];
                this.user.nurseries.forEach(nursery => {
                    if (this.$can('update', 'registrations', nursery.permissions)) {
                        const accountNursery = this.nurseries.find(item => item.id === nursery.id);
                        if (accountNursery) {
                            Vue.set(accountNursery, 'permissions', nursery.permissions);
                            nurseriesWithPermission.push(accountNursery);
                        }
                    }
                });

                return _reverse(_sortBy(nurseriesWithPermission, ['deposited', 'accepted']));
            },
            moment() {
                return moment;
            },
            nurseries() {
                const output = [];

                _forEach(this.user.nurseries, nursery => {
                    const registrationNursery = _find(this.registration.nurseries, n => {
                        return n.id === nursery.id;
                    });

                    const tmp = {};
                    tmp.id = nursery.id;
                    tmp.name = nursery.name;
                    tmp.city = nursery.city;
                    tmp.deposited = false;
                    tmp.declined = false;
                    if (registrationNursery) {
                        tmp.deposited = true;
                        tmp.accepted = registrationNursery.kid_id !== null;
                        tmp.kid_id = registrationNursery.kid_id;
                        tmp.declined = registrationNursery.rejected === true;
                    }
                    output.push(tmp);
                });

                return output;
            },
        },
        watch: {
            '$route.params.registration': 'init',
            'registration': function(registration) {
                this.contract = {
                    id: registration.id,
                    billing_config_id: registration.billing_config_id,
                    started_at: registration.contract_started_at,
                    ended_at: registration.contract_ended_at,
                    recurrent_weeks: registration.contract_recurrent_weeks,
                    contract_recurrent_weeks: registration.contract_recurrent_weeks,
                    dependent_children: registration.contract_dependent_children,
                    handicapped_children: registration.contract_handicapped_children,
                    vacation_days: registration.contract_vacation_days,
                    plannings: registration.plannings,
                    type: 'recurrent',
                    cafpro_resources: registration.first_parent_salary + registration.second_parent_salary,
                    config: registration.config,
                };
                this.kid = {
                    id: 0,
                    first_name: registration.kid_first_name ? registration.kid_first_name : '',
                    last_name: registration.kid_last_name ? registration.kid_last_name : '',
                    hygiene: registration.kid_hygiene ? registration.kid_hygiene : 'baby',
                    meal_type: registration.kid_meal_type ? registration.kid_meal_type : 'milk',
                    birthdate: registration.kid_birthdate,
                };
            },
            'registration.first_parent_role': function(val) {
                this.setParentGenderFromRole(val, 'first_parent_gender');
            },
            'registration.second_parent_role': function(val) {
                this.setParentGenderFromRole(val, 'second_parent_gender');
            },
        },
        mounted() {
            this.init();
        },
        methods: {
            useKidAge,
            parse: useEpoch().parse,
            hasError(errors, inputs) {
                return !!intersection(keys(errors), inputs).length;
            },
            init() {
                this.getRegistration();
            },
            diffBetweenTwoDates(date1, date2) {
                const diff = moment(date1).diff(moment(date2), 'months', true);
                const diffToInt = Math.trunc(diff);

                // If the diff has floating value, we round up otherwise we round down
                return diff > diffToInt ? diffToInt + 1 : diffToInt;
            },
            userCanShowNursery(nursery_id) {
                return !!this.user.nurseries.find(item => item.id === nursery_id);
            },
            getRegistration() {
                this.loading = true;
                useApi().legacy.get(route('registrations.show', {
                    registration: this.$route.params.registration,
                })).then(response => {
                    this.registration = response.data;

                    return this.getRegistrationModel(response.data.id);
                }).then(() => {
                    this.loading = false;
                }).catch(error => {
                    this.loading = false;
                    if (error && error.response && error.response.status === 422) {
                        _forEach(error.response.data.errors, function(value) {
                            useNotification().error(_head(value));
                        });
                    } else {
                        this.$router.push({name: 'registrations.index', params: {nursery: this.nursery.id}});
                    }
                });
            },
            async getRegistrationModel(id) {
                this.registrationModel = await RegistrationModel.query()
                    .with(new RegistrationModel().tags())
                    .find(id);
            },
            remove() {
                swal({
                    title: __('registration:delete_registration'),
                    text: __('registration:registration_will_be_removed_in_all_organizations'),
                    confirmButtonText: __('common:actions.remove'),
                    cancelButtonText: __('common:actions.cancel'),
                }).then(result => {
                    if (result.value) {
                        useApi().legacy.delete(route('registrations.destroy', {
                            registration: this.registration.id,
                        })).then(() => {
                            useNotification().success(
                                __('registration:preregistration_deleted_successfully'));
                            this.$router.push({name: 'registrations.index', params: {nursery: this.nursery.id}});
                        }).catch(error => {
                            if (error && error.response && error.response.status === 422) {
                                _forEach(error.response.data.errors, function(value) {
                                    useNotification().error(_head(value));
                                });
                            } else {
                                useNotification().error(error);
                            }
                        });
                    }
                });
            },
            setAndSaveRegistration(registration) {
                this.registration = registration;
                this.saveRegistration();
            },
            setRegistration(registration) {
                this.registration = registration;
                this.$refs.planning.$refs.modal.hide();
            },
            refreshNurseries(nurseries) {
                this.registration.nurseries = nurseries;
            },
            checkPlaces() {
                this.$refs.checkPlaces.$refs.modal.show();
            },
            estimateInvoice(nurseryRegistration) {
                this.selectedNursery = nurseryRegistration;
                this.$refs.estimateInvoice.$refs.modal.show();
            },
            editPlanning() {
                this.$refs.planning.$refs.modal.show();
            },
            saveRegistration() {
                if (this.saveLoading) {
                    return;
                }

                this.saveLoading = true;
                const data = Object.assign({}, this.registration);

                if (!data.requested_at) {
                    data.requested_at = Epoch.now().toFormat('yyyy-MM-dd');
                }

                // Set dates to null if undefined because api will ignore them if undefined
                data.contract_started_at = data.contract_started_at || null;
                data.contract_ended_at = data.contract_ended_at || null;
                data.kid_birthdate = data.kid_birthdate || null;

                useApi().legacy.put(route('registrations.update', this.registration.id), data).then(response => {
                    this.registration = response.data;
                    this.errors = {};
                    this.$refs.planning.$refs.modal.hide();
                    useNotification().success(__('registration:registration_updated_successfully'));
                    setTimeout(() => {
                        this.saveLoading = false;
                    }, 1500);
                }).catch(error => {
                    this.saveLoading = false;
                    if (error && error.response && error.response.status === 422) {
                        this.errors = error.response.data.errors;
                        _forEach(error.response.data.errors, function(value) {
                            useNotification().error(_head(value));
                        });
                    } else {
                        useNotification().error(error);
                    }
                });
            },
            setParentGenderFromRole(role, registrationParentGenderkey) {
                const females = ['mother', 'sister', 'aunt', 'stepmother', 'legal_custodian_female'];
                if (females.indexOf(role) >= 0) {
                    this.registration[registrationParentGenderkey] = 'female';
                } else {
                    this.registration[registrationParentGenderkey] = 'male';
                }
            },
        },
    };
</script>

<style lang="scss" scoped>
    .h-arrow {
        height: 3px;
    }

    .section-title {
        @apply tw-h-1 tw-opacity-25 tw-bg-blue-700 tw-w-full tw-rounded-full;
    }
</style>
