<template>
    <div>
        <h6 class="h6 mb-1">
            {{ __('request:reservation_for_with_name', {name: `${message.kid_first_name} ${message.kid_last_name}`}) }}
        </h6>
        <div class="content text-primary">
            {{
                __('common:date_format.human_from_date_from_time_to_time', {
                    date: Epoch.fromTimestamp(message.started_at).toLocaleString(Epoch.presets.DATE_SHORT),
                    fromTime: Epoch.fromTimestamp(message.started_at).toLocaleString(Epoch.presets.TIME_SIMPLE),
                    toTime: Epoch.fromTimestamp(message.ended_at).toLocaleString(Epoch.presets.TIME_SIMPLE),
                })
            }}
        </div>
        <MDropdown class="tw-mt-1">
            <MButton
                icon-menu
                :variant="getVariantBtn(message.state)"
            >
                <template #left-icons>
                    <FontAwesomeIcon
                        v-if="message.state === 'accepted'"
                        :fixed-width="true"
                        icon="fas fa-check-circle"
                    />
                    <FontAwesomeIcon
                        v-else-if="message.state === 'rejected'"
                        :fixed-width="true"
                        icon="fas fa-times-circle"
                    />
                    <FontAwesomeIcon
                        v-else
                        :fixed-width="true"
                        icon="fas fa-question-circle"
                    />
                </template>
                <template v-if="message.state === 'accepted'">
                    {{ __('family_member:reservation_validated') }}
                </template>
                <template v-else-if="message.state === 'rejected'">
                    {{ __('family_member:reservation_denied') }}
                </template>
                <template v-else>
                    {{ __('family_member:reservation_pending') }}
                </template>
            </MButton>
            <template #content>
                <CDropdownMenuItem
                    class="tw-w-full"
                    icon="fas fa-check-circle fa-fw"
                    :label="__('family_member:validate_reservation')"
                    @click="accept"
                />
                <CDropdownMenuItem
                    class="tw-w-full"
                    icon="fas fa-question-circle fa-fw"
                    :label="__('common:put_on_hold')"
                    @click="updateState('pending')"
                />
                <CDropdownMenuItem
                    class="tw-w-full"
                    icon="fas fa-times-circle fa-fw"
                    :label="__('family_member:refuse_reservation')"
                    variant="danger"
                    @click="updateState('rejected')"
                />
            </template>
        </MDropdown>
    </div>
</template>

<script>
    import moment from 'moment';
    import route from '@/modules/legacy/libs/ziggy';
    import useApi from '@/modules/app/composables/useApi';
    import _cloneDeep from 'lodash-es/cloneDeep';
    import _head from 'lodash-es/head';
    import _forEach from 'lodash-es/forEach';
    import {Epoch} from '@meekohq/lumos';
    import __ from '@/modules/app/utils/i18n-facade';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';
    import useEditEventModal from '@/modules/planning/composables/core/useEditEventModal';

    export default {
        props: [
            'message',
            'nursery_id',
        ],
        computed: {
            Epoch() {
                return Epoch;
            },
            fullName() {
                return `${this.message.kid_first_name} ${this.message.kid_last_name}`;
            },
        },
        methods: {
            accept() {
                const segment = {};
                segment.started_at = this.message.started_at;
                segment.ended_at = this.message.ended_at;
                segment.start_time = moment.unix(this.message.started_at).clone().format('HH:mm:ss');
                segment.end_time = moment.unix(this.message.ended_at).clone().format('HH:mm:ss');
                segment.kid_id = this.message.kid_id;
                segment.nursery_id = this.message.nursery_id;
                segment.type = 'occasional';
                segment.note = this.message.content;
                segment.contract_hourly_rate = this.message.hourly_rate;
                segment.hourly_rate = this.message.hourly_rate;
                segment.isNew = true;
                segment.unit = 'day';

                const myNotif = _cloneDeep(this.message);
                myNotif.data = {
                    message_id: this.message.id,
                };
                myNotif.referenceable = {
                    id: this.message.id,
                };

                segment.notification = myNotif;
                segment.notification.state = 'accepted';

                useEditEventModal().show(segment);
            },

            updateState(state) {
                useApi().legacy.put(route('nurseries.messages.update', {
                    nurseries: this.message.nursery_id,
                    message: this.message.id,
                }), {
                    state,
                }).then(response => {
                    this.message.state = response.data.state;
                    useNotification().success(__('family_member:reservation_updated_successfully'));
                }).catch(error => {
                    if (error && error.response && error.response.status === 422) {
                        _forEach(error.response.data.errors, value => {
                            useNotification().error(_head(value));
                        });
                    } else {
                        useNotification().error(error);
                    }
                });
            },

            getVariantBtn(state) {
                if (state === 'accepted') {
                    return 'success';
                } else if (state === 'rejected') {
                    return 'danger';
                }

                return 'primary';
            },
        },
    };
</script>

<style lang="scss" scoped>
    .item {
        border-top: 1px solid #bbd3ea;
    }

    .content {
        font-size: 14px;
    }
</style>
