<template>
    <div>
        <div class="tw-flex tw-flex-wrap tw-justify-end tw-items-center tw-gap-2">
            <CButton
                class="tw-mr-2"
                :icon-external-link="true"
                onclick="Intercom('showArticle', 1727881)"
                size="none"
                variant="link"
            >
                {{ __('common:show_guide') }}
            </CButton>
            <MTooltip
                v-if="otherNurseriesSepas.length"
                :label="__('common:import_from_another_organization')"
                placement="top"
            >
                <MButton @click="importSepa">
                    <template #left-icons>
                        <FontAwesomeIcon icon="fas fa-download"/>
                    </template>
                    {{ __('common:actions.import') }}
                </MButton>
            </MTooltip>
            <MButton
                icon-plus
                :label="__('billing_setting:add_sepa')"
                variant="primary"
                @click="sepalModal.show"
            />
            <SepaModal
                v-if="sepalModal.isVisible.value"
                :format="format"
                :loading="loading"
                :modal="sepalModal"
                :new-sepa="newSepa"
                :nursery="nursery"
                :sepas="sepas"
                @addSepa="addSepa"
            />
        </div>

        <MEmptyIndex
            v-if="!filteredSepas.length"
            class="tw-mt-4"
            :title="__('billing_setting:you_do_not_have_sepa')"
        />
        <div
            v-else
            class="tw-mt-4 tw-grid lg:tw-grid-cols-3 md:tw-grid-cols-2 tw-grid-cols-1 tw-gap-4"
        >
            <MBox
                v-for="(sepa, i) in filteredSepas"
                :key="'sepa ' + i"
                class="tw-p-4"
            >
                <CForm>
                    <div v-if="sepa.editing">
                        <CFormGroup>
                            <CLabel>{{ __('billing_setting:creditor_name') }}</CLabel>
                            <MInput
                                id="name"
                                v-model="sepa.name"
                                :placeholder="__('billing_setting:sepa_creditor_name')"
                                type="text"
                            />
                        </CFormGroup>
                    </div>
                    <MHeading
                        v-else
                        class="tw-truncate"
                    >
                        {{ sepa.name }}
                    </MHeading>
                    <CFormGroup>
                        <CLabel>{{ __('common:iban') }}</CLabel>
                        <MInput
                            v-if="sepa.editing"
                            v-model.trim="sepa.iban"
                            :formatter="format"
                            :placeholder="__('common:iban_example')"
                            type="text"
                        />
                        <CBadge v-else>
                            {{ sepa.iban }}
                        </CBadge>
                    </CFormGroup>
                    <CFormGroup>
                        <CLabel>{{ __('common:bic') }}</CLabel>
                        <MInput
                            v-if="sepa.editing"
                            v-model="sepa.bic"
                            :placeholder="__('billing_setting:bic_example')"
                            type="text"
                        />
                        <CBadge v-else>
                            {{ sepa.bic }}
                        </CBadge>
                    </CFormGroup>
                    <CFormGroup>
                        <CLabel>{{ __('billing_setting:creditor_identifier') }}</CLabel>
                        <MInput
                            v-if="sepa.editing"
                            v-model="sepa.ics"
                            :placeholder="__('billing_setting:rum_ics_example')"
                            type="text"
                        />
                        <CBadge v-else>
                            {{ sepa.ics }}
                        </CBadge>
                    </CFormGroup>
                    <CFormGroup>
                        <CLabel>{{ __('billing_setting:payment_delay') }}</CLabel>
                        <CInputGroup v-if="sepa.editing">
                            <MInput
                                v-model.number="sepa.payment_delay"
                                placeholder="5"
                                type="number"
                            />
                            <CInputAddon>
                                {{ __('common:day', {count: sepa.payment_delay}) }}
                            </CInputAddon>
                        </CInputGroup>
                        <CBadge v-else>
                            {{ __('common:day_with_count', {count: sepa.payment_delay}) }}
                        </CBadge>
                    </CFormGroup>
                </CForm>
                <div class="tw-mt-4 tw-flex tw-flex-row tw-items-center">
                    <MTooltip
                        :label="__('common:actions.delete')"
                        placement="top"
                    >
                        <MButton
                            variant="danger"
                            @click="removeSepa(sepa)"
                        >
                            <FontAwesomeIcon icon="fa fa-trash"/>
                        </MButton>
                    </MTooltip>
                    <MTooltip
                        class="tw-ml-2"
                        :label="__('common:remove_from_this_organization')"
                        placement="top"
                    >
                        <MButton @click="detachSepa(sepa)">
                            <FontAwesomeIcon icon="fas fa-unlink"/>
                        </MButton>
                    </MTooltip>
                    <div
                        v-if="sepa.editing"
                        class="tw-ml-auto"
                    >
                        <MTooltip
                            :label="__('common:actions.cancel')"
                            placement="top"
                        >
                            <MButton
                                variant="light"
                                @click="cancelEditSepa(sepa)"
                            >
                                <FontAwesomeIcon icon="fas fa-times"/>
                            </MButton>
                        </MTooltip>
                        <MTooltip
                            :label="__('common:actions.save')"
                            placement="top"
                        >
                            <MButton
                                class="tw-ml-2"
                                variant="primary"
                                @click="updateSepa(sepa)"
                            >
                                <FontAwesomeIcon icon="fas fa-save"/>
                            </MButton>
                        </MTooltip>
                    </div>
                    <MButton
                        v-else
                        class="tw-ml-auto"
                        variant="primary"
                        @click="editSepa(sepa)"
                    >
                        <template #left-icons>
                            <FontAwesomeIcon icon="fas fa-pencil"/>
                        </template>
                        {{ __('common:actions.update') }}
                    </MButton>
                </div>
            </MBox>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';
    import route from '@/modules/legacy/libs/ziggy';
    import useApi from '@/modules/app/composables/useApi';
    import swal from 'sweetalert2/dist/sweetalert2.js';
    import _head from 'lodash-es/head';
    import _forEach from 'lodash-es/forEach';
    import _cloneDeep from 'lodash-es/cloneDeep';
    import _orderBy from 'lodash-es/orderBy';
    import __ from '@/modules/app/utils/i18n-facade';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';
    import MEmptyIndex from '@/modules/meeko-ui/components/MEmptyIndex.vue';
    import useModal from '@/modules/app/composables/useModal';
    import SepaModal from '@/pages/nurseries/[id]/billing/settings/SepaModal.vue';
    import useMagicModal from '@/modules/app/composables/useMagicModal';

    export default {
        components: {
            SepaModal,
            MEmptyIndex,
        },
        props: {
            nursery: {
                type: Object,
                required: true,
            },
            sepas: {
                type: Array,
                required: true,
            },
        },
        data() {
            return {
                nurserySepas: [],
                otherNurseriesSepas: [],
                newSepa: {},
                loading: false,
            };
        },
        computed: {
            filteredSepas() {
                const sepas = _orderBy(this.nurserySepas, 'name');

                return sepas.map(sepa => {
                    sepa.payment_delay = isFinite(parseInt(sepa.payment_delay)) ? parseInt(sepa.payment_delay) : 0;

                    return sepa;
                });
            },

            sepalModal() {
                return useModal();
            },
        },
        mounted() {
            this.getSepas();
        },
        methods: {
            getSepas() {
                this.nurserySepas = [];
                this.otherNurseriesSepas = [];

                if (this.sepas) {
                    this.sepas.forEach(sepa => {
                        Vue.set(sepa, 'editing', false);
                        const nurseryHasSepa = this.nursery.sepaMandates.find(item => item.id === sepa.id);
                        if (nurseryHasSepa) {
                            this.nurserySepas.push(sepa);
                        } else {
                            this.otherNurseriesSepas.push(sepa);
                        }
                    });
                }
            },

            addSepa() {
                this.loading = true;
                useApi().legacy.post(route('sepas.store'), this.newSepa)
                    .then(response => {
                        this.loading = false;
                        const obj = response.data;
                        obj.editing = false;
                        this.sepas.push(obj);
                        this.attachSepa(obj.id);
                    }).catch(error => {
                        this.loading = false;
                        if (error?.response?.status === 422) {
                            _forEach(error.response.data.errors, value => {
                                useNotification().error(_head(value));
                            });
                        } else {
                            useNotification().error(error);
                        }
                    });
            },

            updateSepa(sepa) {
                useApi().legacy.put(route('sepas.update', {sepa: sepa.id}), sepa).then(() => {
                    const sepaToUpdate = this.sepas.find(item => item.id === sepa.id);
                    if (sepaToUpdate) {
                        const index = this.sepas.indexOf(sepaToUpdate);
                        Vue.set(this.sepas, index, sepa);
                    }
                    sepa.editing = false;
                    useNotification().success(__('billing_setting:sepa_updated_successfully'));
                }).catch(error => {
                    if (error?.response?.status === 422) {
                        _forEach(error.response.data.errors, value => {
                            useNotification().error(_head(value));
                        });
                    } else {
                        useNotification().error(error);
                    }
                });
            },

            attachSepa(sepaID) {
                useApi().legacy.post(route('nurseries.sepas.attach', {
                    nurseries: this.nursery.id,
                    sepa: sepaID,
                })).then(response => {
                    this.nurserySepas.push(response.data);
                    this.nursery.sepaMandates.push(response.data);
                    const sepaToRemove = this.otherNurseriesSepas.find(item => item.id === response.data.id);
                    if (sepaToRemove) {
                        const index = this.otherNurseriesSepas.indexOf(sepaToRemove);
                        this.otherNurseriesSepas.splice(index, 1);
                    }

                    this.newSepa = {
                        name: null,
                        iban: null,
                        bic: null,
                        ics: null,
                        payment_delay: null,
                    };
                    this.sepalModal.hide();

                    useNotification().success(__('billing_setting:sepa_added_successfully'));
                }).catch(error => {
                    if (error?.response?.status === 422) {
                        _forEach(error.response.data.errors, value => {
                            useNotification().error(_head(value));
                        });
                    } else {
                        useNotification().error(error);
                    }
                });
            },

            detachSepa(sepa) {
                useMagicModal().confirmationModal({
                    title: __('billing_setting:detach_sepa_confirmation'),
                    text: __('billing_setting:sepa_will_no_longer_visible_on_this_organization'),
                    confirmButtonText: __('common:actions.detach'),
                    onConfirm: async () => {
                        useApi().legacy.post(route('nurseries.sepas.detach', {
                            nurseries: this.nursery.id,
                            sepa: sepa.id,
                        })).then(response => {
                            const sepaToRemove = this.nurserySepas.find(item => item.id === response.data.id);
                            if (sepaToRemove) {
                                const index = this.nurserySepas.indexOf(sepaToRemove);
                                this.nurserySepas.splice(index, 1);
                            }

                            const sepaToRemoveFromNursery = this.nursery.sepaMandates.find(item => item.id === response.data.id);
                            if (sepaToRemoveFromNursery) {
                                const index = this.nursery.sepaMandates.indexOf(sepaToRemoveFromNursery);
                                this.nursery.sepaMandates.splice(index, 1);
                            }

                            this.otherNurseriesSepas.push(response.data);
                            useNotification().success(__('billing_setting:sepa_removed_successfully'));
                        }).catch(error => {
                            if (error?.response?.status === 422) {
                                _forEach(error.response.data.errors, value => {
                                    useNotification().error(_head(value));
                                });
                            } else {
                                useNotification().error(error);
                            }
                        });
                    },
                });
            },

            removeSepa(sepa) {
                useMagicModal().deleteConfirmationModal({
                    title: __('billing_setting:delete_sepa_confirmation'),
                    text: __('billing_setting:sepa_will_be_deleted_on_all_organizations'),
                    onConfirm: async () => {
                        useApi().legacy.delete(route('sepas.destroy', {
                            sepa: sepa.id,
                        })).then(() => {
                            const sepaToDelete = this.nurserySepas.find(item => item.id === sepa.id);
                            if (sepaToDelete) {
                                const index = this.nurserySepas.indexOf(sepaToDelete);
                                this.nurserySepas.splice(index, 1);
                            }

                            const sepaToRemoveFromNursery = this.nursery.sepaMandates.find(item => item.id === sepa.id);
                            if (sepaToRemoveFromNursery) {
                                const index = this.nursery.sepaMandates.indexOf(sepaToRemoveFromNursery);
                                this.nursery.sepaMandates.splice(index, 1);
                            }

                            const sepaToDeleteFromGlobal = this.sepas.find(item => item.id === sepa.id);
                            if (sepaToDeleteFromGlobal) {
                                const index = this.sepas.indexOf(sepaToDeleteFromGlobal);
                                this.sepas.splice(index, 1);
                            }

                            useNotification().success(__('billing_setting:sepa_deleted_successfully'));
                        }).catch(error => {
                            if (error?.response?.status === 422) {
                                _forEach(error.response.data.errors, value => {
                                    useNotification().error(_head(value));
                                });
                            } else {
                                useNotification().error(error);
                            }
                        });
                    },
                });
            },

            importSepa() {
                const options = [];
                this.otherNurseriesSepas.forEach(item => {
                    item.id ? options[item.id] = item.name : null;
                });

                swal({
                    title: __('billing_setting:use_sepa_of_another_organization'),
                    text: null,
                    type: null,
                    confirmButtonClass: 'btn btn-info mr-2',
                    confirmButtonText: __('common:actions.validate'),
                    cancelButtonText: __('common:actions.cancel'),
                    input: 'select',
                    inputOptions: options,
                }).then(result => {
                    if (result.value) {
                        this.attachSepa(result.value);
                    }
                });
            },

            cancelEditSepa(sepa) {
                const originalSepa = this.sepas.find(item => item.id === sepa.id);
                if (originalSepa) {
                    Vue.set(originalSepa, 'editing', false);
                    const sepaToUpdate = this.nurserySepas.find(item => item.id === sepa.id);
                    if (sepaToUpdate) {
                        const index = this.nurserySepas.indexOf(sepaToUpdate);
                        Vue.set(this.nurserySepas, index, _cloneDeep(originalSepa));
                    }
                }
            },

            format(value) {
                return value ? value.toUpperCase().trim() : null;
            },

            editSepa(sepa) {
                Vue.set(sepa, 'editing', true);
            },
        },
    };
</script>
