<script setup lang="ts">
    import useAbility from '@/modules/app/composables/useAbility';
    import {Epoch} from '@meekohq/lumos';
    import useHumanizedEpoch from '@/modules/app/composables/useHumanizedEpoch';

    defineProps({
        nursery: {
            type: Object,
            required: true,
        },
    });

    const emit = defineEmits(['deleteOpeningHour']);
    const {can} = useAbility();
    const {humanizeShortDay} = useHumanizedEpoch();
</script>

<template>
    <CList class="OpeningManagerListOpeningHours">
        <CListRow
            v-for="(openingHour, i) in nursery.openingHours"
            :key="'opening' + i"
            class="OpeningManagerListOpeningHours__item"
            :hover="false"
        >
            <div class="OpeningManagerListOpeningHours__item-header">
                <MHeading level="h3">
                    {{ humanizeShortDay(openingHour.day) }}
                </MHeading>
                <MButton
                    v-if="can('update', 'nursery')"
                    class="tw-shrink-0"
                    variant="link-danger"
                    @click="emit('deleteOpeningHour', i)"
                >
                    <FontAwesomeIcon icon="fa fa-times"/>
                </MButton>
            </div>
            <div class="OpeningManagerListOpeningHours__grid">
                <div class="OpeningManagerListOpeningHours__grid-item">
                    <div class="OpeningManagerListOpeningHours__grid-item-text">
                        {{ __('organization:openings.opening_hours_start') }}
                    </div>
                    {{ Epoch.parse(openingHour.started_at, 'HH:mm:ss').toLocaleString(Epoch.presets.TIME_SIMPLE) }}
                </div>
                <div class="OpeningManagerListOpeningHours__grid-item">
                    <div class="OpeningManagerListOpeningHours__grid-item-text">
                        {{ __('organization:openings.opening_hours_end') }}
                    </div>
                    {{ Epoch.parse(openingHour.ended_at, 'HH:mm:ss').toLocaleString(Epoch.presets.TIME_SIMPLE) }}
                </div>
            </div>
        </CListRow>
    </CList>
</template>

<style lang="scss" scoped>
    .OpeningManagerListOpeningHours__item {
        @apply tw-flex tw-flex-col tw-gap-2;
    }

    .OpeningManagerListOpeningHours__item-header {
        @apply tw-flex tw-flex-wrap tw-justify-between tw-items-center tw-gap-4;
    }

    .OpeningManagerListOpeningHours__grid {
        @apply tw-grid tw-grid-cols-2 tw-gap-4 tw-items-center;
    }

    .OpeningManagerListOpeningHours__grid-item {
        @apply tw-flex tw-flex-wrap tw-gap-1 tw-items-center;
    }

    .OpeningManagerListOpeningHours__grid-item-text {
        @apply tw-text-gray-500 tw-text-sm;
    }
</style>
