<template>
    <div>
        <SelectResource
            ref="selectResourceRef"
            :builder="builder"
            :button-class="buttonClass"
            :dropdown-wrapper-class="dropdownWrapperClass"
            :has-unselect="true"
            :model="AddressModel"
            :value="value"
            @fallback="showModal"
            @input="onModelSelected"
            @search="onSearchValue"
        >
            <template #button>
                <div
                    v-if="value"
                    class="tw-flex tw-truncate"
                >
                    <div class="tw-flex tw-py-1">
                        <div class="tw-mr-4">
                            <CBadge variant="primary">
                                {{ value.attributes.label }}
                            </CBadge>
                        </div>
                        <div class="tw-text-left">
                            <div class="">
                                {{ value.attributes.name }}
                            </div>
                            <div class="">
                                {{ value.attributes.address.line1 }}
                            </div>
                            <div class="">
                                {{ value.attributes.address.line2 }}
                            </div>
                            <div class="">
                                {{ value.attributes.address.postal_code }}, {{ value.attributes.address.city }}
                            </div>
                            <div class="">
                                {{ value.attributes.address.country_code }}
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    v-else
                    class="tw-truncate"
                >
                    {{ __('billing_core:select_address') }}
                </div>
            </template>

            <template #fallback="{searchValue}">
                <i class="fas fa-plus fa-fw tw--ml-1 tw-mr-1"/>{{ __('billing_core:add_address') }}
            </template>

            <template
                v-if="value"
                #unselect-item
            >
                <i class="fad fa-unlink fa-fw tw--ml-1 tw-mr-1"/>{{ __('billing_core:deselect_address') }}
            </template>

            <template #default="{option, searchValue}">
                <div class="tw-flex">
                    <CBadge
                        class="tw-mr-2"
                        variant="primary"
                    >
                        {{ option.attributes.label }}
                    </CBadge>
                    <div class="tw-truncate tw-pr-4">
                        {{ option.fullAddress }}
                    </div>
                </div>
            </template>
        </SelectResource>
        <slot
            :modal="{searchValue}"
            name="modal"
        >
            <CreateAddressModal
                v-if="isCreateAddressModalVisible"
                :customer-model="customerModel"
                :emitter="emitter"
                @created="onModelCreated($event)"
                @hidden="isCreateAddressModalVisible = false"
            />
        </slot>
    </div>
</template>

<script lang="ts">
    import type CustomerModel from '@/modules/cashier/models/CustomerModel';
    import SelectResource from '@/modules/app/components/SelectResource.vue';
    import type {Model} from '@meekohq/lumos';
    import {QueryBuilder} from '@meekohq/lumos';
    import AddressModel from '@/modules/cashier/models/AddressModel';
    import CreateAddressModal from '@/modules/cashier/components/core/address/organisms/CreateAddressModal.vue';
    import type {PropType} from 'vue';
    import {computed, defineComponent, ref} from 'vue';
    import mitt from 'mitt';

    import type {EventType} from '@/modules/meeko-ui/utils/ModalEventType';

    export default defineComponent({
        components: {
            CreateAddressModal,
            SelectResource,
        },
        props: {
            value: {type: Object as PropType<AddressModel>, required: false},
            customerModel: {type: [Object] as PropType<CustomerModel>, required: false},
            extendBuilder: {
                type: Object as PropType<QueryBuilder<any>>,
                default: () => new QueryBuilder(),
                required: false,
            },
            disabled: {type: Boolean, default: false, required: false},
            customModal: {type: Boolean, default: false, required: false},
            dropdownPosition: {},
            dropdownWrapperClass: {},
            dropdownClass: {},
            buttonClass: {},
        },
        emits: ['input', 'hideModal', 'showModal'],
        setup(props, {emit}) {
            const isCreateAddressModalVisible = ref(false);
            const selectResourceRef = ref(null);
            const searchValue = ref();
            const emitter = mitt<EventType>();

            const builder = computed(() => {
                const query = AddressModel.query();

                if (props.customerModel) {
                    query.where('customer_id', props.customerModel.getKey() as string);
                }

                query.inject(props.extendBuilder);

                return query;
            });

            function onSearchValue(event) {
                searchValue.value = event;
            }

            function onModelCreated(model: Model) {
                hideModal();

                onModelSelected(model);
            }

            function onModelSelected(model: Model | undefined) {
                emit('input', model);
            }

            function hideModal() {
                if (props.customModal) {
                    emit('hideModal');
                } else {
                    emitter.emit('hide');
                }
            }

            function showModal() {
                if (props.customModal) {
                    emit('showModal');
                } else {
                    // @ts-ignore
                    isCreateAddressModalVisible.value = true;
                }

                // @ts-ignore
                selectResourceRef.value.hide();
            }

            return {
                selectResourceRef,
                AddressModel,
                searchValue,
                builder,
                onSearchValue,
                onModelSelected,
                onModelCreated,
                hideModal,
                showModal,
                isCreateAddressModalVisible,
                emitter,
            };
        },
    });
</script>
