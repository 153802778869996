import useUserFilters, {OutputType} from '@/modules/app/composables/useUserFilters';

export default function useSimulationFilters(prefix: string) {
    const {selectedOptions: planningTypeFilter, isLoading: isPlanningTypeLoading} =
        useUserFilters(`simulation-${prefix}-planning-types`, OutputType.array, [
            'forecast-recurrent',
        ]);

    const {selectedOptions: tagFilter, isLoading: isTagLoading} =
        useUserFilters(`simulation-${prefix}-tags`, OutputType.array, []);

    const {selectedOptions: groupFilter, isLoading: isGroupLoading} =
        useUserFilters(`simulation-${prefix}-group`, OutputType.value, null);

    const {selectedOptions: supervisionFilter, isLoading: isSupervisionLoading} =
        useUserFilters(`simulation-${prefix}-supervision`, OutputType.value, false);

    const {selectedOptions: daySeparatorFilter, isLoading: isDaySeparatorLoading} =
        useUserFilters(`simulation-${prefix}-day-separator`, OutputType.value, 'day');

    const {selectedOptions: halfdayTimeFilter, isLoading: isHalfdayTimeLoading} =
        useUserFilters(`simulation-${prefix}-halfday-time`, OutputType.value, '12:30');

    return {
        groupFilter,
        isGroupLoading,
        isPlanningTypeLoading,
        isTagLoading,
        isSupervisionLoading,
        isDaySeparatorLoading,
        isHalfdayTimeLoading,
        planningTypeFilter,
        supervisionFilter,
        tagFilter,
        daySeparatorFilter,
        halfdayTimeFilter,
    };
}
