<template>
    <div class="tw-contents">
        <div v-if="$can('create', 'staffs_planning') && $can('update', 'staffs_planning')">
            <MDropdown placement="bottom-end">
                <MButton>
                    <template #left-icons>
                        <FontAwesomeIcon icon="fad fa-calendar-alt"/>
                    </template>
                    {{ __('planning:manage_planning') }}
                </MButton>
                <template #content>
                    <CDropdownMenuItem
                        class="tw-w-full"
                        icon="fad fa-calendar-day"
                        @click="$emit('manageTemplates')"
                    >
                        {{ __('planning:manage_typical_week') }}
                    </CDropdownMenuItem>
                    <CDropdownMenuItem
                        class="tw-w-full"
                        icon="fad fa-copy"
                        @click="$emit('useTemplates')"
                    >
                        {{ __('planning:apply_typical_week') }}
                    </CDropdownMenuItem>
                </template>
            </MDropdown>
        </div>
        <MTooltip placement="top">
            <MDropdown class="print:tw-hidden">
                <MButton variant="gray">
                    <FontAwesomeIcon icon="fa fa-external-link"/>
                </MButton>
                <template #content>
                    <CDropdownMenuItem
                        class="print:!tw-hidden tw-w-full"
                        icon="fa fa-print"
                        @click="printPage"
                    >
                        {{ __('planning:print_planning') }}
                    </CDropdownMenuItem>
                    <CDropdownMenuItem
                        class="print:!tw-hidden tw-w-full"
                        icon="fa fa-calendar-alt"
                        @click="$refs.modal.show()"
                    >
                        {{ __('planning:planning_subscription') }}
                    </CDropdownMenuItem>
                </template>
            </MDropdown>
            <template #content>
                {{ __('planning:print_or_share') }}
            </template>
        </MTooltip>
        <MSettingsButton
            :to="{name:'settings.plannings',params:{nursery:nursery.id}}"
            :tooltip="__('planning:planning_settings')"
        />
        <StaffPlanningSubscriberModal ref="modal"/>
    </div>
</template>

<script>
    import StaffPlanningSubscriberModal
        from '@/modules/human-resources/components/calendar/StaffPlanningSubscriberModal.vue';
    import usePrint from '@/modules/app/composables/usePrint';

    export default {
        components: {
            StaffPlanningSubscriberModal,
        },
        props: {
            nursery: {
                type: Object,
                required: true,
            },
            currentDisplay: {
                type: String,
                default: '',
            },
        },
        methods: {
            resetWeek() {
                this.$emit('resetWeek');
            },

            printPage() {
                usePrint().print();
            },
        },
    };
</script>
