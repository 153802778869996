<template>
    <CModal
        id="useTemplate"
        ref="modal"
        :header-title="__('planning:manage_typical_week')"
        size="5xl"
        :visible="true"
        @hidden="onHidden"
        @shown="onShown"
    >
        <loader
            v-if="weekTemplatesLoading"
            light="true"
            shadow="false"
            size="sm"
            :title="__('common:loading_dots')"
        />
        <div v-else>
            <div>
                <div class="tw-flex tw-flex-wrap">
                    <CFormGroup
                        v-if="filteredWeekTemplates.length"
                        class="tw-w-full sm:tw-w-1/3 tw-mb-3"
                    >
                        <CButton
                            class="tw-w-full tw-capitalize"
                            @click="$refs.weekTemplatesDropdown.toggle()"
                        >
                            <template v-if="selectedWeekTemplate.attributes.staff_id !== staff.id && selectedWeekTemplate.staff().value()">
                                {{ selectedWeekTemplate.staff().value().fullname + ' - ' + selectedWeekTemplate.templateName }}
                            </template>
                            <template v-else>
                                {{ selectedWeekTemplate.templateName }}
                            </template>
                            <i
                                aria-hidden="true"
                                class="fa fa-caret-down tw-ml-1"
                            />
                        </CButton>
                        <CDropdown
                            ref="weekTemplatesDropdown"
                            legacy-mode
                        >
                            <CList class="tw-cursor-pointer">
                                <CListRow
                                    v-for="(weekTemplate, i) in filteredWeekTemplates.filter(item => item.attributes.template_id === null)"
                                    :key="'weekTemplate' + i"
                                    @click="selectedWeekTemplate = weekTemplate"
                                >
                                    <i
                                        v-if="selectedWeekTemplate.id === weekTemplate.id"
                                        class="fa fa-check tw-mr-1"
                                    />
                                    <template v-if="weekTemplate.attributes.staff_id !== staff.id && weekTemplate.staff().value()">
                                        {{ weekTemplate.staff().value().fullname + ' - ' + weekTemplate.attributes.name }}
                                    </template>
                                    <template v-else>
                                        {{ weekTemplate.attributes.name }}
                                    </template>
                                </CListRow>
                                <div
                                    v-if="filteredWeekTemplates.filter(item => item.attributes.template_id !== null).length"
                                    class="tw-text-gray-600 tw-uppercase tw-tracking-wider tw-text-sm tw-pt-4 tw-px-2"
                                >
                                    {{ __('hr_calendar:recovery_from_global_weeks_colon') }}
                                </div>
                                <CListRow
                                    v-for="(weekTemplate, j) in filteredWeekTemplates.filter(item => item.attributes.template_id !== null)"
                                    :key="'weekTemplateFromGlobal' + j"
                                    @click="selectedWeekTemplate = weekTemplate"
                                >
                                    <i
                                        v-if="selectedWeekTemplate.id === weekTemplate.id"
                                        class="fa fa-check tw-mr-1"
                                    />
                                    <template v-if="weekTemplate.attributes.staff_id !== staff.id && weekTemplate.staff().value()">
                                        {{ weekTemplate.staff().value().fullname + ' - ' + weekTemplate.templateName }}
                                    </template>
                                    <template v-else>
                                        {{ weekTemplate.templateName }}
                                    </template>
                                </CListRow>
                            </CList>
                        </CDropdown>
                    </CFormGroup>
                    <div class="tw-ml-2">
                        <CTooltip>
                            <StaffFinder
                                :excluded-ids="[staff.getKey()]"
                                only-user-organizations
                                @input="importTemplatesFromStaff"
                            >
                                <template #trigger="{toggle}">
                                    <CButton @click="toggle">
                                        <i
                                            aria-hidden="true"
                                            class="fad fa-address-book tw-text-blue-500 tw-mr-2"
                                        />
                                        {{ __('common:actions.import') }}
                                    </CButton>
                                </template>
                            </StaffFinder>
                            <template #content>
                                {{ __('hr_calendar:import_and_use_another_person_week') }}
                            </template>
                        </CTooltip>
                    </div>
                </div>

                <div
                    v-if="!filteredWeekTemplates.length"
                    class="tw-p-3"
                >
                    <h5 class="h5 tw-text-center tw-mb-0">
                        {{ __('hr_calendar:no_week_templates') }}
                    </h5>
                </div>

                <template v-else>
                    <loader
                        v-if="calendarLoading"
                        light="true"
                        shadow="false"
                        size="sm"
                        :title="__('common:loading_dots')"
                    />

                    <div
                        v-else
                        class="tw-border tw-border-gray-200 tw-rounded-lg tw-mt-2 tw-p-3"
                    >
                        <div class="tw-text-sm tw-text-gray-600 tw-text-center tw-mb-3">
                            {{ __('hr_calendar:update_typical_week_before_applying_colon') }}
                        </div>
                        <div
                            class="tw-w-full tw-mb-2 tw-hidden sm:tw-block"
                            style="padding-left: 44px; padding-right: 55px"
                        >
                            <hours
                                :closing="closing"
                                :opening="opening"
                            />
                        </div>

                        <div
                            v-for="(day, i) in daysOfWeek"
                            :key="'day_save_week' + i"
                            class="tw-flex tw-flex-wrap sm:tw-flex-nowrap tw-w-full tw-items-center tw-mb-2"
                        >
                            <div class="tw-w-full sm:tw-w-16">
                                <CTooltip placement="top">
                                    <CButton
                                        class="tw-capitalize"
                                        variant="hover"
                                    >
                                        {{ fromMoment(day).weekdayShort }}
                                    </CButton>
                                    <template #content>
                                        <div class="tw-text-gray-600 tw-text-base tw-text-center tw-min-w-36">
                                            {{ __('common:total') }}
                                            <CBadge
                                                class="tw-mr-1"
                                                size="sm"
                                                variant="cyan"
                                            >
                                                {{ totalPlannings(day) }}
                                            </CBadge>
                                        </div>
                                    </template>
                                </CTooltip>
                            </div>

                            <div class="tw-w-11/12 sm:tw-w-full">
                                <PlanningGenerator
                                    :auto-save="false"
                                    :closing="closing"
                                    create-with-modal
                                    :day="day"
                                    is-template
                                    :opening="opening"
                                    :show-clock="false"
                                    show-note
                                    :template-events="events"
                                    :week-template="selectedWeekTemplate.id"
                                    @pushEventTemplate="selectedWeekTemplate.planningEvents().value().all().push($event)"
                                />
                            </div>
                            <div class="tw-w-1/12 tw-pl-2 sm:tw-w-8 sm:tw-ml-2">
                                <CButton
                                    :round="true"
                                    size="sm"
                                    variant="success"
                                    @click="newEvent(day)"
                                >
                                    <i
                                        aria-hidden="true"
                                        class="fa fa-plus"
                                    />
                                </CButton>
                            </div>
                        </div>

                        <CCenter class="tw-mt-3">
                            <EventTotals
                                :contract="staff.activeContract() ? `${staff.activeContract().attributes.hours_per_week}h` : '0h'"
                                :forecast="totalWeekPlannings"
                                :real="null"
                            />
                        </CCenter>
                    </div>

                    <div class="tw-text-gray-600 tw-text-base tw-font-semibold tw-mt-9 tw-mb-3">
                        {{ __('hr_calendar:replace_weeks_by_week_template_colon') }}
                    </div>

                    <SelectWeeks :selected-weeks.sync="selectedWeeks"/>
                    <hr class="hr">
                    <CHStack>
                        <CCheckbox v-model="deleteRealEvents">
                            {{ __('hr_calendar:delete_real_events_in_selected_weeks') }}
                        </CCheckbox>
                    </CHStack>
                </template>
            </div>

            <EventModal
                v-if="selectedEvent"
                ref="modalEditEvent"
                :event="selectedEvent"
                is-template
                soft-update
                @add="onEventAdded"
                @delete="onEventDeleted"
                @edit="onEventEdited"
                @hidden="selectedEvent = null"
            />
        </div>
        <div class="tw-mt-4">
            <FeatureFlag name="enable-new-template-service">
                <InvalidEventErrors :error-handler="invalidEventErrors"/>
                <template #disabled>
                    <FailedEventsAlert :failed-events="failedEvents"/>
                </template>
            </FeatureFlag>
        </div>
        <template #footer>
            <CButton
                class="tw-ml-auto"
                variant="light"
                @click="$refs.modal.hide()"
            >
                {{ __('common:actions.cancel') }}
            </CButton>

            <CButton
                v-if="filteredWeekTemplates.length"
                class="tw-ml-2"
                :loading="saveLoading"
                variant="primary"
                @click="!saveLoading ? useWeek() : null"
            >
                {{ __('common:actions.apply') }}
            </CButton>
        </template>
    </CModal>
</template>

<script lang="ts">
    import type {PropType} from 'vue';
    import {defineComponent} from 'vue';
    import useWeekTemplate from '@/modules/human-resources/composables/calendar/useWeekTemplate';
    import type StaffModel from '@/modules/human-resources/models/StaffModel';
    import Hours from '@/modules/legacy/components/Modules/PlanningGenerator/Hours.vue';
    import Line from '@/modules/human-resources/components/calendar/Line.vue';
    import EventModal from '@/modules/human-resources/components/calendar/EventModal.vue';
    import EventTotals from '@/modules/human-resources/components/calendar/EventTotals.vue';
    import SelectWeeks from '@/modules/human-resources/components/calendar/CalendarTemplate/SelectWeeks.vue';
    import StaffFinder from '@/modules/request/components/Teams/StaffFinder.vue';
    import useEpoch from '@/modules/app/composables/useEpoch';
    import {Epoch} from '@meekohq/lumos';
    import FeatureFlag from '@/modules/app/components/atoms/FeatureFlag.vue';
    import FailedEventsAlert
        from '@/modules/human-resources/components/calendar/CalendarTemplate/FailedEventsAlert.vue';
    import InvalidEventErrors
        from '@/modules/human-resources/apply-template/infrastructure/components/InvalidEventErrors.vue';

    export default defineComponent({
        components: {
            InvalidEventErrors,
            FailedEventsAlert,
            FeatureFlag,
            StaffFinder,
            Hours,
            PlanningGenerator: Line,
            EventModal,
            EventTotals,
            SelectWeeks,
        },
        props: {
            staff: {
                type: Object as PropType<StaffModel>,
                required: true,
            },
        },
        setup(props, {emit}) {
            const {fromMoment} = useEpoch();
            const {
                modal,
                onShown,
                onHide,
                opening,
                closing,
                daysOfWeek,
                filteredWeekTemplates,
                selectedWeekTemplate,
                weekTemplatesLoading,
                calendarLoading,
                importTemplatesFromStaff,
                selectedWeeks,
                saveLoading,
                deleteRealEvents,
                invalidEventErrors,
                failedEvents,
                useWeek,
                events,
                totalWeekPlannings,
                totalPlannings,
                selectedEvent,
                modalEditEvent,
                newEvent,
                onEventAdded,
                editEvent,
                onEventEdited,
                onEventDeleted,
            } = useWeekTemplate(props.staff, undefined, undefined, false);

            const onHidden = function() {
                onHide();
                emit('hidden');
            };

            return {
                Epoch,
                fromMoment,
                modal,
                onShown,
                onHide,
                selectedWeeks,
                saveLoading,
                deleteRealEvents,
                invalidEventErrors,
                failedEvents,
                useWeek,
                opening,
                closing,
                daysOfWeek,
                filteredWeekTemplates,
                selectedWeekTemplate,
                weekTemplatesLoading,
                calendarLoading,
                importTemplatesFromStaff,
                events,
                totalWeekPlannings,
                totalPlannings,
                selectedEvent,
                modalEditEvent,
                newEvent,
                onEventAdded,
                editEvent,
                onEventEdited,
                onEventDeleted,
                onHidden,
            };
        },
    });
</script>
