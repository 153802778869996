<template>
    <div>
        <CButton
            :size="size"
            @click="$refs.contractFilter.toggle()"
        >
            {{ contractStatesText }}
            <i
                aria-hidden="true"
                class="fa fa-caret-down tw-ml-1"
            />
        </CButton>
        <CDropdown
            ref="contractFilter"
            legacy-mode
        >
            <CList class="tw-cursor-pointer">
                <CListRow @click="updateContractStates(null)">
                    <i
                        v-if="
                            !value ||
                                typeof value === 'undefined' ||
                                value.length === 0
                        "
                        class="fa fa-check tw-mr-1"
                    />
                    {{ __('common:every_contract') }}
                </CListRow>
                <template v-if="value && Array.isArray(value)">
                    <CListRow @click="updateContractStates('coming')">
                        <i
                            v-if="value && value.includes('coming')"
                            class="fa fa-check tw-mr-1"
                        />
                        {{ __('common:upcoming_contract') }}
                    </CListRow>
                    <CListRow @click="updateContractStates('current')">
                        <i
                            v-if="value && value.includes('current')"
                            class="fa fa-check tw-mr-1"
                        />
                        {{ __('common:current_contract') }}
                    </CListRow>
                    <CListRow @click="updateContractStates('past')">
                        <i
                            v-if="value && value.includes('past')"
                            class="fa fa-check tw-mr-1"
                        />
                        {{ __('common:past_contract') }}
                    </CListRow>
                </template>
            </CList>
        </CDropdown>
    </div>
</template>

<script lang="ts">
    import {computed, defineComponent} from 'vue';
    import _isEqual from 'lodash-es/isEqual';
    import __ from '@/modules/app/utils/i18n-facade';

    export default defineComponent({
        props: {
            value: {
                type: Array,
                default: () => [],
            },
            size: {type: String, default: 'base'},
        },
        setup(props, {emit}) {
            const contractStatesText = computed(() => {
                let txt = __('common:filter_by_contract');
                if (!props.value || props.value.length === 0) {
                    txt = __('common:every_contract');
                }

                if (props.value && props.value.length === 1) {
                    if (props.value.includes('coming')) {
                        txt = __('common:upcoming_contract');
                    }

                    if (props.value.includes('current')) {
                        txt = __('common:current_contract');
                    }

                    if (props.value.includes('past')) {
                        txt = __('common:past_contract');
                    }
                }

                return txt;
            });

            function updateContractStates(val) {
                let states = props.value ? props.value.slice() : [];

                if (!val) {
                    states = [];
                } else if (states.includes(val)) {
                    states = states.filter(e => e !== val);
                } else {
                    states.push(val);

                    const allStates = ['coming', 'current', 'past'];
                    if (_isEqual(allStates.sort(), states.sort())) {
                        states = [];
                    }
                }

                if (states && states.length) {
                    localStorage.setItem(
                        'staffs:index:contracts',
                        JSON.stringify(states),
                    );
                } else {
                    localStorage.removeItem('staffs:index:contracts');
                }
                emit('input', states);
            }

            return {
                contractStatesText,
                updateContractStates,
            };
        },
    });
</script>
