<template>
    <div>
        <CForm @submit.prevent="saveAddress">
            <CFormGroup>
                <CLabel>{{ __('common:name') }}</CLabel>
                <CInput
                    v-model="customerCopy.name"
                    :has-error="!!(errors.name && errors.name.length)"
                    :placeholder="__('subscription:my_organization')"
                />
                <CFormErrorMessage v-if="errors.name">
                    {{ errors.name[0] }}
                </CFormErrorMessage>
            </CFormGroup>
            <CFormTwoColumns>
                <CFormGroup>
                    <CLabel>{{ __('common:address.country') }}</CLabel>
                    <SelectCountry
                        v-model="customerCopy.country"
                        :countries-filter="countriesFilter"
                        :has-error="errors.country"
                    />
                    <CFormErrorMessage v-if="errors.country">
                        {{ errors.country[0] }}
                    </CFormErrorMessage>
                </CFormGroup>
            </CFormTwoColumns>
            <CFormTwoColumns>
                <CFormGroup>
                    <CLabel>{{ __('common:address.address') }}</CLabel>
                    <CInput
                        v-model="customerCopy.address"
                        :has-error="!!(errors.address && errors.address.length)"
                        :placeholder="__('subscription:example.address')"
                    />
                    <CFormErrorMessage v-if="errors.address">
                        {{ errors.address[0] }}
                    </CFormErrorMessage>
                </CFormGroup>
                <CFormGroup>
                    <CLabel>{{ __('common:address.city') }}</CLabel>
                    <CInput
                        v-model="customerCopy.city"
                        :has-error="!!(errors.city && errors.city.length)"
                        :placeholder="__('subscription:example.city')"
                    />
                    <CFormErrorMessage v-if="errors.city">
                        {{ errors.city[0] }}
                    </CFormErrorMessage>
                </CFormGroup>
            </CFormTwoColumns>
            <CFormTwoColumns>
                <CFormGroup>
                    <CLabel>{{ __('common:address.zip_code') }}</CLabel>
                    <CInput
                        v-model="customerCopy.zipcode"
                        :has-error="!!(errors.zipcode && errors.zipcode.length)"
                        :placeholder="__('subscription:example.zipcode')"
                    />
                    <CFormErrorMessage v-if="errors.zipcode">
                        {{ errors.zipcode[0] }}
                    </CFormErrorMessage>
                </CFormGroup>
                <CFormGroup>
                    <CLabel>{{ __('common:address.region') }}</CLabel>
                    <CInput
                        v-model="customerCopy.state"
                        :has-error="!!(errors.state && errors.state.length)"
                        :placeholder="__('common:address.region')"
                    />
                    <CFormErrorMessage v-if="errors.state">
                        {{ errors.state[0] }}
                    </CFormErrorMessage>
                </CFormGroup>
            </CFormTwoColumns>

            <CFormGroup v-if="allows('support')">
                <CLabel>{{ __('subscription:trial_ends') }}</CLabel>
                <CFormDatepicker
                    v-model="customerCopy.trial_ends_at"
                    format="timestamp"
                    :time="true"
                />
            </CFormGroup>

            <CButton
                :loading="loader.isLoading()"
                size="lg"
                type="submit"
                variant="primary"
            >
                {{ __('common:actions.save') }}
            </CButton>
        </CForm>
    </div>
</template>

<script>
    import route from '@/modules/legacy/libs/ziggy';
    import {cloneDeep} from 'lodash-es';
    import __ from '@/modules/app/utils/i18n-facade';
    import useApi from '@/modules/app/composables/useApi';
    import Loader from '@/modules/legacy/helpers/loader.helper';
    import SelectCountry from '@/modules/legacy/components/Modules/SelectCountry.vue';
    import {AvailableOperatingCountries} from '@/modules/app/utils/AvailableOperatingCountries';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';
    import useAbility from '@/modules/app/composables/useAbility';

    export default {
        components: {SelectCountry},
        props: ['nursery', 'customer'],
        data: () => ({
            customerCopy: [],
            countriesFilter: country => Object.values(AvailableOperatingCountries).includes(country.id),
            errors: [],
            loader: new Loader,
        }),
        computed: {
            allows() {
                return useAbility().allows;
            },
        },
        mounted() {
            this.customerCopy = cloneDeep(this.customer);
            this.customerCopy.country === null ? this.customerCopy.country = this.nursery.country : null;
        },
        methods: {
            saveAddress() {
                window.localStorage.getItem('support-token') ? this.customerCopy.easysupporttoken = window.localStorage.getItem('support-token') : null;
                this.loader.start();
                useApi().legacy.put(route('customers.update', {
                    customer: this.customerCopy.id,
                }), this.customerCopy).then(response => {
                    this.$emit('customerUpdated', response.data);
                    this.errors = [];
                    useNotification().success(__('subscription:billing_address_saved_successfully'));
                }).catch(error => {
                    this.errors = error.response.data.errors;
                }).finally(() => {
                    this.loader.stop();
                });
            },
        },
    };
</script>
