<template>
    <div>
        <div class="tw-mb-2">
            {{ __('subscription:contact_support_to_cancel') }}
        </div>
        <div>
            {{ __('subscription:subscription_will_continue') }}
        </div>
    </div>
</template>

<script lang="ts">
    import swal from 'sweetalert2/dist/sweetalert2.js';
    import route from '@/modules/legacy/libs/ziggy';
    import useApi from '@/modules/app/composables/useApi';
    import Loader from '@/modules/legacy/helpers/loader.helper';
    import {defineComponent, reactive} from 'vue';
    import {useRouter} from 'vue-router/composables';
    import __ from '@/modules/app/utils/i18n-facade';

    const loader = reactive(new Loader);

    export default defineComponent({
        props: {
            customer: {type: Object, required: true},
        },
        setup(props, {emit}) {
            const router = useRouter();

            const cancel = function() {
                loader.start('cancel');
                useApi().legacy.post(route('customers.plans.cancel', {
                    customer: props.customer.id,
                })).then(response => {
                    emit('customerUpdated', response.data);
                    router.push({name: 'subscriptions.plan'});
                    swal({
                        title: __('subscription:renewal_canceled'),
                        text: __('subscription:subscription_remain_active_until_expiration'),
                        type: 'success',
                        showCloseButton: true,
                        showCancelButton: false,
                        confirmButtonClass: 'btn btn-primary mr-2',
                        confirmButtonText: __('common:actions.close'),
                        buttonsStyling: false,
                    });
                }).then(() => {
                    loader.stop('cancel');
                });
            };

            return {
                loader,
                cancel,
            };
        },
    });
</script>
