<template>
    <CForm @submit.prevent="save">
        <CFormGroup>
            <CLabel>{{ __('common:name') }}</CLabel>
            <CInput
                v-model="simulationList.attributes.name"
                :placeholder="__('planning:back_to_nursery_with_years', {currentYear, nextYear})"
            />
            <CFormErrorMessageList :errors="simulationListErrors.getErrors('name')"/>
        </CFormGroup>
        <CFormGroup>
            <CLabel>{{ __('planning:period') }}</CLabel>
            <CFormTwoColumns>
                <CFormGroup>
                    <CFormDatepicker v-model="simulationList.attributes.period.started_at"/>
                    <CFormErrorMessageList :errors="simulationListErrors.getErrors('period.started_at')"/>
                </CFormGroup>
                <CFormGroup>
                    <CFormDatepicker v-model="simulationList.attributes.period.ended_at"/>
                    <CFormErrorMessageList :errors="simulationListErrors.getErrors('period.ended_at')"/>
                </CFormGroup>
            </CFormTwoColumns>
        </CFormGroup>
    </CForm>
</template>

<script lang="ts">
    import type {PropType} from 'vue';
    import {defineComponent, ref} from 'vue';
    import type SimulationListModel from '@/modules/planning/models/SimulationListModel';
    import ErrorHandler from '@/modules/legacy/libs/errors/errorHandler';
    import moment from 'moment/moment';

    export default defineComponent({
        components: {},
        props: {
            simulationList: {
                type: Object as PropType<SimulationListModel>,
                required: true,
            },
        },
        setup(props, {emit}) {
            const simulationListErrors = ref(new ErrorHandler());

            const currentYear = moment().year();
            const nextYear = moment().add(1, 'year').year();

            async function save() {
                try {
                    emit('loading', true);
                    const list = await props.simulationList.save();

                    emit('loading', false);
                    simulationListErrors.value.reset();
                    emit('saved', list);
                } catch (e) {
                    emit('loading', false);
                    simulationListErrors.value.reset(e);
                }
            }

            return {
                simulationListErrors,
                save,
                currentYear,
                nextYear,
            };
        },
    });
</script>
