import moment from 'moment';

/**
 * @returns Contracts in range.
 */
export function scopeInRange(contracts: any[], from: string, to: string) {
    return contracts.filter(contract => {
        const subFilter1 = moment(contract.started_at).isBetween(from, to, 'day', '[]') || moment(contract.ended_at).isBetween(from, to, 'day', '[]');
        const subFilter2 = (!contract.ended_at || moment(contract.ended_at).isSameOrAfter(to, 'day')) && moment(contract.started_at).isSameOrBefore(from, 'day');
        const mainFilter1 = !contract.broked_at && (subFilter1 || subFilter2);

        const mainFilter2 = contract.broked_at !== null && (moment(contract.started_at).isBetween(from, to, 'day', '[]') || moment(contract.broked_at).isBetween(from, to, 'day', '[]'));

        const mainFilter3 = contract.broked_at !== null && (moment(contract.broked_at).isSameOrAfter(to, 'day') && moment(contract.started_at).isSameOrBefore(from, 'day'));

        return mainFilter1 || mainFilter2 || mainFilter3;
    });
}

/**
 * @returns Contracts from date.
 */
export function scopeFrom(contracts: any[], from: string) {
    return contracts.filter(contract => {
        const mainFilter1 = !contract.broked_at && moment(contract.started_at).isSameOrBefore(from, 'day') && (!contract.ended_at || moment(contract.ended_at).isSameOrAfter(from, 'day'));
        const mainFilter2 = contract.broked_at !== null && moment(contract.started_at).isSameOrBefore(from, 'day') && moment(contract.broked_at).isSameOrAfter(from, 'day');

        return mainFilter1 || mainFilter2;
    });
}

/**
 * @returns Contracts coming after date.
 */
export function scopeComing(contracts: any[], date: string = moment().format('YYYY-MM-DD')) {
    return contracts.filter(contract => {
        return moment(contract.started_at).isAfter(date, 'day');
    });
}

/**
 * @returns Contracts from past until date (excluding current contracts).
 */
export function scopeUntil(contracts: any[], date: string = moment().format('YYYY-MM-DD')) {
    return contracts.filter(contract => {
        const mainFilter1 = !contract.broked_at && moment(contract.ended_at).isSameOrBefore(date, 'day');
        const mainFilter2 = contract.broked_at !== null && moment(contract.broked_at).isSameOrBefore(date, 'day');

        return mainFilter1 || mainFilter2;
    });
}
