<template>
    <CVStack gap="6">
        <CHStack
            v-if="can('update', 'registrations')"
            class="tw-p-2 tw-bg-white tw-rounded-full tw-shadow-md tw-w-72 tw-text-lg"
            distribute="between"
            gap="2"
        >
            <CTooltip placement="top">
                <NotePlus
                    class="zoom tw-cursor-pointer"
                    @click.native="newNote"
                />
                <template #content>
                    <span>{{ __('registration:action_button.note') }}</span>
                </template>
            </CTooltip>
            <CTooltip placement="top">
                <CallPlus
                    class="zoom tw-cursor-pointer"
                    @click.native="newCall"
                />
                <template #content>
                    <span>{{ __('registration:action_button.call') }}</span>
                </template>
            </CTooltip>
            <CTooltip placement="top">
                <TaskPlus
                    class="zoom tw-cursor-pointer"
                    @click.native="newTask"
                />
                <template #content>
                    <span>{{ __('common:actions.add_task') }}</span>
                </template>
            </CTooltip>
            <CTooltip placement="top">
                <SchedulePlus
                    class="zoom tw-cursor-pointer"
                    @click.native="newMeeting"
                />
                <template #content>
                    <span>{{ __('registration:action_button.meeting') }}</span>
                </template>
            </CTooltip>
            <MailPlus
                v-if="false"
                :class="{'tw-opacity-25': true, 'zoom tw-cursor-pointer': false}"
            />
            <CTooltip placement="top">
                <DocumentPlus
                    class="zoom tw-cursor-pointer"
                    @click.native="newDocument"
                />
                <template #content>
                    <span>{{ __('common:add_document') }}</span>
                </template>
            </CTooltip>
        </CHStack>
        <CCenter v-if="loading">
            <CLoader size="lg"/>
        </CCenter>
        <transition-group
            v-else
            class="tw-flex tw-flex-col-reverse"
            :duration="1000"
            name="timeline-effect"
            @after-enter="afterEnter"
            @before-leave="beforeLeave"
            @enter="enter"
            @leave="leave"
        >
            <div
                v-for="activity in activitiesList"
                :key="activity.getKey() ? activity.getKey() : activity.uuid()"
                class="tw-pb-4"
            >
                <call-item
                    v-if="isActivityType('call', activity)"
                    :call="activity"
                    :registration="registration"
                    @deleted="removeCall($event)"
                />
                <note-item
                    v-if="isActivityType('note', activity)"
                    :note="activity"
                    :registration="registration"
                    @deleted="removeNote($event)"
                />
                <task-item
                    v-if="isActivityType('task', activity)"
                    :multi-resources="false"
                    :resources="[registrationModel]"
                    :show-resources="false"
                    :task="activity"
                    @completed="onCompletedTask($event)"
                    @deleted="removeTask($event)"
                />
                <document-item
                    v-if="isActivityType('document', activity)"
                    :document="activity"
                    :registration="registrationModel"
                    @deleted="removeDocument($event)"
                />
                <meeting-item
                    v-if="isActivityType('meeting', activity)"
                    :id="activity.id"
                    :meeting="activity"
                    :registration="registration"
                    @deleted="removeMeeting($event)"
                    @meeting:scroll="scrollToMeeting($event)"
                    @reschedule="newMeetingFromRescheduled"
                />
            </div>
        </transition-group>
    </CVStack>
</template>

<script lang="ts">
    import {computed, defineComponent, nextTick, ref, watch} from 'vue';
    import CallModel from '@/modules/activity/models/CallModel';
    import type {HasMany, QueryBuilder} from '@meekohq/lumos';
    import CallItem from '@/modules/registration/components/molecules/Timeline/CallItem.vue';
    import NotePlus from '@/modules/legacy/components/Events/img/note-plus.vue';
    import CallPlus from '@/modules/legacy/components/Events/img/call-plus.vue';
    import TaskPlus from '@/modules/legacy/components/Events/img/task-plus.vue';
    import DocumentPlus from '@/modules/legacy/components/Events/img/document-plus.vue';
    import SchedulePlus from '@/modules/legacy/components/Events/img/schedule-plus.vue';
    import TicketModel from '@/modules/activity/ticket/domain/TicketModel';
    import TicketTypeValue from '@/modules/activity/utils/TicketTypeValue';
    import NoteItem from '@/modules/registration/components/molecules/Timeline/NoteItem.vue';
    import TaskItem from '@/modules/registration/components/molecules/Timeline/TaskItem.vue';
    import useAuth from '@/modules/app/composables/useAuth';
    import type {Moment} from 'moment';
    import moment from 'moment';
    import DocumentModel from '@/modules/document/models/DocumentModel';
    import DocumentItem from '@/modules/registration/components/molecules/Timeline/DocumentItem.vue';
    import useAbility from '@/modules/app/composables/useAbility';
    import useActivitiesTaskList from '@/modules/registration/composables/useActivitiesTaskList';
    import MeetingModel from '@/modules/activity/models/MeetingModel';
    import MeetingItem from '@/modules/registration/components/molecules/Timeline/MeetingItem.vue';
    import useActivitiesMeetingList from '@/modules/registration/composables/useActivitiesMeetingList';
    import useActivitiesNoteList from '@/modules/registration/composables/useActivitiesNoteList';
    import useActivitiesCallList from '@/modules/registration/composables/useActivitiesCallList';
    import useActivitiesDocumentList from '@/modules/registration/composables/useActivitiesDocumentList';
    import MailPlus from '@/modules/legacy/components/Events/img/mail-plus.vue';
    import RegistrationModel from '@/modules/registration/models/RegistrationModel';

    type ActivityType = 'call' | 'note' | 'task' | 'document' | 'meeting';
    type ActivityTypeModel = CallModel | TicketModel | DocumentModel | MeetingModel;

    export default defineComponent({
        components: {
            MailPlus,
            MeetingItem,
            NoteItem,
            TaskItem,
            CallItem,
            DocumentItem,
            NotePlus,
            CallPlus,
            TaskPlus,
            DocumentPlus,
            SchedulePlus,
        },
        props: {
            registration: {
                type: Object,
                required: true,
            },
        },
        setup(props) {
            const loading = ref(false);

            const {can} = useAbility();

            const {user} = useAuth();

            const registrationModel = ref<RegistrationModel>();

            async function getRegistrationModel() {
                registrationModel.value = await RegistrationModel.query().with(new RegistrationModel().organizations()).find(props.registration.id);
            }

            const pivotConstraint = function(query: QueryBuilder<any>, pivotRelation: HasMany<any, any>) {
                query.whereHas(pivotRelation, query1 => {
                    query1.where('resource_id', props.registration.id);
                    query1.where('resource_type', 'registration/registrations');
                });
            };

            const {calls, getCalls, newCall, removeCall} = useActivitiesCallList(pivotConstraint);
            const {documents, getDocuments, newDocument, removeDocument} = useActivitiesDocumentList(pivotConstraint);
            const {getNotes, newNote, notes, removeNote} = useActivitiesNoteList(pivotConstraint);
            const {
                getTasks,
                newTask,
                onCompletedTask,
                removeTask,
                tasks,
            } = useActivitiesTaskList(registrationModel);

            const {
                getMeetings,
                meetings,
                newMeeting,
                newMeetingFromRescheduled,
                removeMeeting,
                scrollToMeeting,
            } = useActivitiesMeetingList(pivotConstraint);

            const getActivityDate = function(activity: ActivityTypeModel): Moment {
                if (activity instanceof CallModel) {
                    return moment(activity.attributes.started_at);
                } else if (activity instanceof TicketModel && activity.attributes.type === TicketTypeValue.task) {
                    return moment(activity.attributes.expired_at);
                } else {
                    return moment(activity.computed.created_at);
                }
            };

            const activitiesList = computed(() => {
                return [
                    ...documents.value,
                    ...calls.value,
                    ...notes.value,
                    ...tasks.value,
                    ...meetings.value,
                ].sort((a: ActivityTypeModel, b: ActivityTypeModel) => {
                    // We sort first by the items in draft from the most recent to the oldest
                    // then by date of the item (date of creation or expiration depending on type) always from the most recent to the oldest
                    if (a.extra.draftDate && !a.exists && b.extra.draftDate && !b.exists) {
                        return a.extra.draftDate.isBefore(b.extra.draftDate) ? -1 : 1;
                    } else if (a.extra.draftDate && !a.exists) {
                        return 1;
                    } else if (b.extra.draftDate && !b.exists) {
                        return -1;
                    } else {
                        return getActivityDate(a).isBefore(getActivityDate(b)) ? -1 : 1;
                    }
                });
            });

            const isActivityType = (type: ActivityType, activity: ActivityTypeModel): boolean => {
                switch (type) {
                    case 'task':
                        return activity.type === new TicketModel().type && activity instanceof TicketModel && activity.attributes.type === TicketTypeValue.task;
                    case 'note':
                        return activity.type === new TicketModel().type && activity instanceof TicketModel && activity.attributes.type === TicketTypeValue.note;
                    case 'meeting':
                        return activity.type === new MeetingModel().type;
                    case 'document':
                        return activity.type === new DocumentModel().type;
                    default:
                        return activity.type === new CallModel().type;
                }
            };

            const enter = function(el) {
                el.style.height = `calc(${el.scrollHeight}px)`;
            };

            const afterEnter = function(el) {
                el.style.height = null;
            };

            const beforeLeave = function(el) {
                el.style.height = el.scrollHeight + 'px';
            };

            const leave = function(el) {
                nextTick(function() {
                    el.style.height = '0';
                });
            };

            watch(() => props.registration.id, async () => {
                loading.value = true;
                await getRegistrationModel();
                await Promise.all([getNotes(), getTasks(), getCalls(), getMeetings(), getDocuments()]);
                loading.value = false;
            }, {immediate: true});

            return {
                TicketTypeValue,
                loading,
                isActivityType,
                registrationModel,
                calls,
                notes,
                tasks,
                onCompletedTask,
                meetings,
                activitiesList,
                newCall,
                removeCall,
                newNote,
                removeNote,
                newTask,
                removeTask,
                newDocument,
                removeDocument,
                newMeeting,
                newMeetingFromRescheduled,
                enter,
                afterEnter,
                beforeLeave,
                leave,
                removeMeeting,
                scrollToMeeting,
                user,
                can,
            };
        },
    });
</script>

<style lang="scss">
    .timeline-effect-enter {
        opacity: 0;
        height: 0;
        overflow: hidden;
        transform: translateY(-50px) scale(0.5);
    }

    .timeline-effect-enter-to {
        opacity: 1;
        transform: translateY(0) scale(1);
    }

    .timeline-effect-enter-active {
        transition: height .3s ease-in-out, opacity .3s, transform .5s, padding .3s;
        pointer-events: none;
    }

    .timeline-effect-leave-active {
        overflow: hidden;
        transition: opacity .3s, transform .5s, padding .3s, height .3s;
    }

    .timeline-effect-leave {
        opacity: 1;
        transform: translateY(0) scale(0);
    }

    .timeline-effect-leave-to {
        opacity: 0;
        transform: translateY(-50px) scale(0.5);
        @apply tw-pb-0;
    }
</style>
