<script setup lang="ts">
    import {inject, ref, watch} from 'vue';
    import type {MCalendarYearsItem} from '@/modules/meeko-ui/components/MCalendar/useMCalendarYears';
    import {keyMCalendarYears} from '@/modules/meeko-ui/components/MCalendar/useMCalendarYears';
    import {useElementHover} from '@vueuse/core';

    const props = defineProps<{
        year: MCalendarYearsItem
    }>();

    const yearsState = inject(keyMCalendarYears);
    if (!yearsState) {
        throw new Error('keyMCalendarYears must be provided');
    }

    const yearElement = ref();
    const isHovered = useElementHover(yearElement);

    const {
        hoveredYear,
        handleYearClick,
    } = yearsState;

    watch(isHovered, newValue => {
        if (newValue) {
            hoveredYear.value = props.year.year;
        }
    });
</script>

<template>
    <button
        ref="yearElement"
        class="MCalendarYearsItem"
        :class="{
            'MCalendarYearsItem--selecting': year.isSelecting,
            'MCalendarYearsItem--in-period': year.isInPeriod,
            'MCalendarYearsItem--hovered': isHovered,
            'MCalendarYearsItem--selected': year.isSelected,
        }"
        @click="handleYearClick(year.year)"
    >
        {{ year.year.year }}
    </button>
</template>

<style scoped lang="scss">
    .MCalendarYearsItem {
        @apply tw-p-2 tw-rounded tw-cursor-pointer tw-select-none;
        @apply tw-transition-all hover:tw-bg-gray-200;
        @apply tw-text-center tw-font-display tw-text-gray-800 tw-capitalize;
    }

    .MCalendarYearsItem--hovered {
        @apply tw-bg-gray-200;
    }

    .MCalendarYearsItem--in-period {
        &:not(.MCalendarYearsItem--selecting) {
            @apply tw-bg-primary-200 tw-text-primary-900;
        }

        &.MCalendarYearsItem--selecting {
            @apply tw-bg-gray-200;
        }
    }

    .MCalendarYearsItem--selected {
        @apply tw-bg-primary-500 tw-text-white #{!important};

        &.MCalendarYearsItem--hovered {
            @apply tw-bg-primary-600 #{!important};
        }
    }
</style>
