<template>
    <CHStack
        align="center"
        class="tw-py-1"
        gap="2"
    >
        <div class="tw-w-1/3">
            <CSkeleton2
                class="tw-h-2"
                random-width
            />
        </div>
        <div class="tw-w-1/3">
            <div class="tw-w-1/2">
                <CSkeleton2
                    class="tw-h-2"
                    random-width
                />
            </div>
        </div>
        <CHStack
            class="tw-w-1/3"
            distribute="start"
        >
            <CSkeleton2
                v-for="(i, index) in random(1, 3)"
                :key="index"
                circle
                class="tw-h-7 tw-w-7 !tw-rounded-full tw-border-2 tw-border-white"
            />
        </CHStack>
        <div class="tw-w-24">
            <CSkeleton2 class="tw-h-5 tw-w-8 tw-ml-auto"/>
        </div>
    </CHStack>
</template>

<script lang="ts">
    import {defineComponent} from 'vue';
    import {random} from 'lodash-es';

    export default defineComponent({
        components: {},
        props: {},
        setup() {
            return {
                random,
            };
        },
    });
</script>
