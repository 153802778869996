<script setup lang="ts">
    import useAbility from '@/modules/app/composables/useAbility';
    import type {DocTemplatesDestination} from '@/modules/organization/utils/DocTemplatesDestinations';
    import DocTemplatesDestinations from '@/modules/organization/utils/DocTemplatesDestinations';
    import __ from '@/modules/app/utils/i18n-facade';
    import {computed} from 'vue';
    import useMagicModal from '@/modules/app/composables/useMagicModal';

    const props = defineProps({
        selectedTemplate: {
            type: Object,
            required: true,
        },
        uploading: {
            type: Boolean,
            required: true,
        },
        templateExists: {
            type: Boolean,
            required: true,
        },
        loading: {
            type: Boolean,
            required: true,
        },
    });

    const emit = defineEmits([
        'removeTemplate',
        'detachTemplate',
        'uploadFile',
        'saveTemplate',
        'duplicateTemplate',
        'updateDestination',
    ]);

    const {can} = useAbility();
    const docTemplatesDestinations = DocTemplatesDestinations();


    const selectedTemplateDestination = computed(() => {
        return docTemplatesDestinations.find(destination => destination.type === props.selectedTemplate?.type);
    });

    function changeDestination(destinationType: string) {
        const docTemplatesDestination = docTemplatesDestinations.find(destination => destination.type === destinationType) as DocTemplatesDestination;
        useMagicModal().confirmationModal({
            text: __('organization:doc_templates_destination_change_confirmation', {type: docTemplatesDestination.name}),
            onConfirm: async () => {
                emit('updateDestination', destinationType);
            },
        });

    }
</script>

<template>
    <div class="DocTemplatesManagerEditorHeader">
        <div class="DocTemplatesManagerEditorHeader__form">
            <div class="DocTemplatesManagerEditorHeader__form-group">
                <CLabel class="DocTemplatesManagerEditorHeader__form-label">
                    {{ __('organization:template_name_colon') }}
                </CLabel>
                <MInput
                    v-model="selectedTemplate.name"
                    :placeholder="__('organization:template_name')"
                />
            </div>
            <div class="DocTemplatesManagerEditorHeader__form-group">
                <CLabel class="DocTemplatesManagerEditorHeader__form-label">
                    {{ __('organization:use_template_for_colon') }}
                </CLabel>
                <MMenu>
                    <MMenuButton>
                        <MButton icon-menu>
                            <template
                                v-if="selectedTemplateDestination"
                                #left-icons
                            >
                                <FontAwesomeIcon
                                    class="tw-text-primary-500"
                                    :fixed-width="true"
                                    :icon="selectedTemplateDestination.icon"
                                />
                            </template>
                            <template v-if="selectedTemplateDestination">
                                {{ selectedTemplateDestination.name }}
                            </template>
                            <template v-else>
                                {{ __('organization:select_from_list_dots') }}
                            </template>
                        </MButton>
                    </MMenuButton>
                    <MMenuItems>
                        <MMenuItem
                            v-for="destination in docTemplatesDestinations"
                            :key="destination.type"
                            :label="destination.name"
                            @click="changeDestination(destination.type)"
                        >
                            <template #icon>
                                <FontAwesomeIcon
                                    :fixed-width="true"
                                    :icon="destination.icon"
                                />
                            </template>
                        </MMenuItem>
                    </MMenuItems>
                </MMenu>
            </div>
        </div>
        <div class="DocTemplatesManagerEditorHeader__actions">
            <template v-if="selectedTemplate.format === 'word'">
                <MButton
                    v-if="can('update', 'doc_templates')"
                    :disabled="loading"
                    :loading="uploading"
                    variant="primary"
                    @click="emit('uploadFile')"
                >
                    <template #left-icons>
                        <FontAwesomeIcon icon="fas fa-save"/>
                    </template>
                    {{ templateExists && selectedTemplate.id !== 0 ? __('common:actions.save') : __('organization:create_this_doc_template') }}
                </MButton>
            </template>
            <template v-else>
                <MButton
                    v-if="can('update', 'doc_templates')"
                    :disabled="loading"
                    variant="primary"
                    @click="emit('saveTemplate')"
                >
                    <template #left-icons>
                        <FontAwesomeIcon icon="fas fa-save"/>
                    </template>
                    {{ selectedTemplate.id !== null ? __('common:actions.save') : __('organization:create_this_doc_template') }}
                </MButton>
            </template>
            <MMenu v-if="templateExists && (can('update', 'doc_templates') || can('delete', 'doc_templates'))">
                <MMenuButton>
                    <MButton
                        :disabled="loading"
                        icon-ellipsis
                    />
                </MMenuButton>
                <MMenuItems>
                    <MMenuItem
                        v-if="can('update', 'doc_templates')"
                        :label="__('common:actions.duplicate')"
                        @click="emit('duplicateTemplate')"
                    >
                        <template #icon>
                            <FontAwesomeIcon
                                :fixed-width="true"
                                icon="fas fa-copy"
                            />
                        </template>
                    </MMenuItem>
                    <MMenuItem
                        v-if="can('update', 'doc_templates')"
                        :label="__('common:actions.detach')"
                        @click="emit('detachTemplate')"
                    >
                        <template #icon>
                            <FontAwesomeIcon
                                :fixed-width="true"
                                icon="fas fa-unlink"
                            />
                        </template>
                    </MMenuItem>
                    <MMenuItem
                        v-if="can('delete', 'doc_templates')"
                        :label="__('common:actions.delete_dots')"
                        variant="danger"
                        @click="emit('removeTemplate')"
                    >
                        <template #icon>
                            <FontAwesomeIcon
                                :fixed-width="true"
                                icon="fas fa-trash"
                            />
                        </template>
                    </MMenuItem>
                </MMenuItems>
            </MMenu>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    .DocTemplatesManagerEditorHeader {
        @apply tw-flex sm:tw-flex-row tw-flex-col tw-items-end tw-justify-between tw-gap-8;
    }

    .DocTemplatesManagerEditorHeader__form {
        @apply tw-flex tw-flex-wrap tw-gap-4;
        @apply tw-w-full;
    }

    .DocTemplatesManagerEditorHeader__form-group {
        @apply sm:tw-max-w-80 tw-w-full;
    }

    .DocTemplatesManagerEditorHeader__form-label {
        @apply tw-mb-1;
    }

    .DocTemplatesManagerEditorHeader__actions {
        @apply sm:tw-w-auto tw-w-full tw-flex tw-flex-row tw-items-center tw-justify-end tw-gap-2;
    }
</style>
