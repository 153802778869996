<script setup lang="ts">
    import {watch} from 'vue';
    import useManager from '@/modules/app/composables/useManager';
    import type PaymentModel from '@/modules/cashier/models/PaymentModel';
    import PaymentAllocationsListItemCreditNote
        from '@/modules/cashier/payment/infrastructure/components/PaymentAllocationsListItemCreditNote.vue';
    import PaymentAllocationsListItemTransaction
        from '@/modules/cashier/payment/infrastructure/components/PaymentAllocationsListItemTransaction.vue';
    import usePaymentAllocationsState
        from '@/modules/cashier/payment/infrastructure/components/usePaymentAllocationsState';
    import {
        TransactionCreditAllocationAggregate,
    } from '@/modules/cashier/payment/domain/TransactionCreditAllocationAggregate';
    import {CreditNoteAllocationAggregate} from '@/modules/cashier/payment/domain/CreditNoteAllocationAggregate';
    import PaymentAllocationsAbstractList
        from '@/modules/cashier/payment/infrastructure/components/PaymentAllocationsAbstractList.vue';
    import type {LegacyInvoiceType} from '@/modules/cashier/utils/billing/invoice/LegacyInvoiceType';

    const props = withDefaults(defineProps<{
        invoice: LegacyInvoiceType;
        payment?: PaymentModel;
        editingAllocations: boolean;
    }>(), {
        payment: undefined,
    });

    const emit = defineEmits<{
        (e: 'refreshInvoice'): void
        (e: 'loaded'): void
        (e: 'editingAllocations', editingAllocations: boolean): void
    }>();

    const activeOrganization = useManager().activeOrganization.value;

    const {
        allocations,
        countAllocatableWithRemaining,
        isFetching,
        fetchForInvoice,
        paymentRemainingAmount,
    } = usePaymentAllocationsState();

    async function prepareRefresh() {
        emit('refreshInvoice');
    }

    watch(() => props.payment, async () => {
        if (props.payment) {
            await fetchForInvoice(props.payment);
        } else {
            allocations.value = [];
        }
        emit('loaded');
    });
</script>
<template>
    <PaymentAllocationsAbstractList
        :allocations="allocations"
        :count-allocatable-with-remaining="countAllocatableWithRemaining"
        :editing-allocations="editingAllocations"
        :invoice="invoice"
        :is-fetching="isFetching"
        :payment="payment"
        :payment-remaining-amount="paymentRemainingAmount"
        @created="prepareRefresh"
        @editingAllocations="emit('editingAllocations', $event)"
        @saved="prepareRefresh"
    >
        <template #item="{allocation}">
            <PaymentAllocationsListItemTransaction
                v-if="allocation instanceof TransactionCreditAllocationAggregate"
                class="PaymentAllocationsListForInvoice__item-content"
                :display-status="allocation.source.isFailed"
                :editing-allocations="editingAllocations"
                :invoice="invoice"
                :organization="activeOrganization"
                :payment="payment"
                :transaction="allocation.source"
                @deleted="prepareRefresh"
                @rejected="prepareRefresh"
                @restored="prepareRefresh"
                @updated="prepareRefresh"
            />
            <PaymentAllocationsListItemCreditNote
                v-if="allocation instanceof CreditNoteAllocationAggregate"
                :allocation="allocation"
                class="PaymentAllocationsListForInvoice__item-content"
                :editing-allocations="editingAllocations"
                @detached="prepareRefresh"
            />
        </template>
    </PaymentAllocationsAbstractList>
</template>

<style lang="scss" scoped>
    .PaymentAllocationsListForInvoice__item-content {
        @apply tw-bg-gray-50 tw-p-4 tw-rounded-lg tw-w-full;
    }
</style>
