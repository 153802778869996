<template>
    <div class="EditInvoiceNameType">
        <div class="EditInvoiceNameType__name">
            <img
                v-if="nursery.logo_url"
                alt="nursery logo"
                :src="nursery.logo_url"
                style="max-width: 100px; max-height: 100px;"
            >
            <MHeading
                v-else
                level="h1"
            >
                {{ nursery.name }}
            </MHeading>
        </div>
        <div class="EditInvoiceNameType__type">
            <CInput
                v-if="invoice.status === 'draft' && can('update', 'invoices')"
                v-model="invoice.name"
                align="right"
                size="lg"
            />
            <MHeading
                v-else
                level="h1"
            >
                {{ invoice.name }}
            </MHeading>
        </div>
    </div>
</template>


<script setup lang="ts">
    import useAbility from '@/modules/app/composables/useAbility';

    defineProps<{ invoice: { status: string, name: string }, nursery: { logo_url: string, name: string } }>();

    const {can} = useAbility();
</script>

<style lang="scss" scoped>
    .EditInvoiceNameType {
        @apply tw-w-full tw-flex sm:tw-flex-row tw-flex-col sm:tw-items-center sm:tw-justify-between tw-gap-4;
    }

    .EditInvoiceNameType__name {
        @apply tw-order-2 sm:tw-order-1;
    }

    .EditInvoiceNameType__type {
        @apply tw-order-1 sm:tw-order-2;
    }
</style>
