<template>
    <SimplePageTemplate>
        <template #title>
            <MPageTitle variant="billing">
                {{ __('app:menu.transactions') }}
            </MPageTitle>
        </template>
        <template #actions>
            <TransactionIndexActions
                :filters-store="filtersStore"
                :organization-id="nursery.id"
            />
        </template>
        <template #content>
            <TransactionList
                :emitter="emitter"
                :filters-store="filtersStore"
                :show-customer-informations="true"
            />
            <router-view @created="onTransactionCreated"/>
        </template>
    </SimplePageTemplate>
</template>

<script lang="ts">
    import {defineComponent} from 'vue';
    import SimplePageTemplate from '@/modules/app/components/templates/SimplePageTemplate.vue';
    import TransactionIndexActions
        from '@/pages/nurseries/[id]/billing/transactions/partials/TransactionIndexActions.vue';
    import type {EventType} from '@/modules/cashier/transaction/infrastructure/components/TransactionList.vue';
    import TransactionList from '@/modules/cashier/transaction/infrastructure/components/TransactionList.vue';
    import useFiltersState from '@/modules/cashier/transaction/infrastructure/components/useFiltersState';
    import {StoragePath} from '@/modules/magic-index/utils/serializers/StoragePath';
    import mitt from 'mitt';

    export default defineComponent({
        components: {
            TransactionList,
            TransactionIndexActions,
            SimplePageTemplate,
        },
        props: ['nursery'],
        setup() {
            const filtersStore = useFiltersState({
                search: {
                    storagePath: StoragePath.filtersTransactionsIndexSearch,
                    queryString: 'search',
                },
                unallocatedOnly: {
                    storagePath: StoragePath.filtersTransactionsIndexUnallocatedOnly,
                    queryString: 'unallocated-only',
                },
                period: {
                    storagePath: StoragePath.filtersTransactionsIndexPeriod,
                    queryString: 'period',
                },
                amount: {
                    storagePath: StoragePath.filtersTransactionsIndexAmount,
                    queryString: 'amount',
                },
                tenants: {
                    storagePath: StoragePath.filtersTransactionsIndexTenants,
                },
                customers: {
                    storagePath: StoragePath.filtersTransactionsIndexCustomers,
                },
                paymentMethod: {
                    storagePath: StoragePath.filtersTransactionsIndexPaymentMethod,
                    queryString: 'payment-method',
                },
                orderBy: {
                    storagePath: StoragePath.filtersTransactionsIndexOrderBy,
                    queryString: 'order-by',
                },
                page: {
                    queryString: 'page',
                },
            });

            const emitter = mitt<EventType>();

            /**
             * Refresh the list when a transaction is created.
             */
            async function onTransactionCreated() {
                emitter.emit('refresh');
            }

            return {
                filtersStore,
                onTransactionCreated,
                emitter,
            };
        },
    });
</script>
