<template>
    <div class="KidTitle">
        <BadgeTasks
            size="sm"
            :tasks="registration.tasks"
        >
            <div
                class="KidTitle__kid-icon-container"
                :class="[
                    {'KidTitle__kid-icon--is-boy': registration.kid_gender === 'male'},
                    {'KidTitle__kid-icon--is-girl': registration.kid_gender === 'female'},
                    {'KidTitle__kid-icon--is-unknow': !registration.kid_gender}
                ]"
            >
                <FontAwesomeIcon
                    v-if="registration.kid_gender === 'male'"
                    icon="fa fa-mars"
                />
                <FontAwesomeIcon
                    v-else-if="registration.kid_gender === 'female'"
                    icon="fa fa-venus"
                />
                <FontAwesomeIcon
                    v-else
                    icon="fa fa-question"
                />
            </div>
        </BadgeTasks>
        <router-link :to="{name:'registrations.show',params:{nursery:nursery.id, registration:registration.id}}">
            <MListItemTitle
                class="tw-ml-2"
                :title="kidFullName"
                :variant="registration.kid_gender === 'male' ? 'male' : registration.kid_gender === 'female' ? 'female' : 'no-gender'"
            />
        </router-link>
    </div>
</template>

<script lang="ts">
    import {computed, defineComponent} from 'vue';
    import BadgeTasks from '@/modules/activity/components/pages/BadgeTasks.vue';
    import MListItemTitle from '@/modules/meeko-ui/components/MListItemTitle.vue';

    export default defineComponent({
        components: {
            MListItemTitle,
            BadgeTasks,
        },
        props: {
            nursery: {
                type: Object,
                required: true,
            },
            registration: {
                type: Object,
                required: true,
            },
        },
        setup(props) {
            const kidFullName = computed(() => {
                return `${props.registration.kid_first_name ?? ''} ${props.registration.kid_last_name}`;
            });

            return {
                kidFullName,
            };
        },
    });
</script>

<style lang="scss" scoped>
    .KidTitle {
        @apply tw-flex tw-items-center;
    }

    .KidTitle__kid-icon--is-boy {
        @apply tw-bg-blue-50 tw-text-boy;
    }

    .KidTitle__kid-icon--is-girl {
        @apply tw-bg-orange-50 tw-text-girl;
    }

    .KidTitle__kid-icon--is-unknow {
        @apply tw-bg-teal-50 tw-text-teal-600;
    }

    .KidTitle__kid-icon-container {
        @apply tw-min-w-8 tw-min-h-8 tw-rounded-full tw-flex tw-items-center tw-justify-center;
    }
</style>
