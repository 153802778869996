import {Model} from '@meekohq/lumos';
import type MedicalTreatmentType from '@/modules/health/models/MedicalTreatmentType';
import type RuleSet from '@/modules/calendar/utils/RuleSet/RuleSet';
import KidModel from '@/modules/family/models/KidModel';
import MedicalActionModel from '@/modules/health/models/MedicalActionModel';

export default class MedicalTreatmentModel extends Model {
    public type = 'health/medical_treatments';

    public attributes: {
        id: string,
        account_id: string | undefined,
        kid_id: string | undefined,
        internal_id: string | null,
        name: string | undefined,
        type: MedicalTreatmentType | undefined,
        started_at: string | undefined
        medical_actions_ruleset: RuleSet | null
    } = {
            id: this.uuid(),
            account_id: undefined,
            kid_id: undefined,
            internal_id: null,
            name: undefined,
            type: undefined,
            started_at: undefined,
            medical_actions_ruleset: null,
        };

    kid() {
        return this.belongsTo('kid', KidModel, 'kid_id');
    }

    medicalActions() {
        return this.hasMany('medicalActions', MedicalActionModel, 'medical_treatment_id');
    }
}
