<template>
    <div class="tw-text-center tw-text-xl tw-p-4">
        <div>
            <i
                aria-hidden="true"
                class="fad fa-book tw-text-blue-300 tw-text-3xl"
            />
        </div>
        <div>{{ __('common:no_match') }}</div>
    </div>
</template>

<script lang="ts">
    import {defineComponent} from 'vue';

    export default defineComponent({
        components: {},
        props: {},
        setup() {
            return {};
        },
    });
</script>

