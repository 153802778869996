<script setup lang="ts">
    import type {PropType} from 'vue';
    import {computed, toRef} from 'vue';
    import type {OutputValueType} from '@/modules/meeko-ui/composables/useMDatePicker';
    import useMDatePicker from '@/modules/meeko-ui/composables/useMDatePicker';
    import MDatePickerInput from '@/modules/meeko-ui/components/MDatePicker/MDatePickerInput.vue';
    import MDatePickerContent from '@/modules/meeko-ui/components/MDatePicker/MDatePickerContent.vue';
    import useHumanizePeriod from '@/modules/app/composables/useHumanizePeriod';
    import __ from '@/modules/app/utils/i18n-facade';
    import usePopover from '@/modules/app/composables/usePopover';

    const props = defineProps({
        value: {type: [String, Object] as PropType<OutputValueType>, default: undefined},
        /**
         * @values dd/MM/yyyy, dd/MM/yyyy HH:mm, iso8601
         */
        format: {
            type: String,
            default: 'iso8601',
        },
        allowUndefined: {
            type: Boolean,
            default: false,
        },
        withTime: {
            type: Boolean,
            default: false,
        },
        buttonSize: {
            type: String,
            default: 'base',
        },
        hasError: {
            type: Boolean,
            default: false,
        },
    });

    const store = useMDatePicker(
        toRef(props, 'value'),
        toRef(props, 'format'),
        toRef(props, 'allowUndefined'),
        toRef(props, 'withTime'),
        {
            from: epoch => epoch?.startOfDay(),
            to: epoch => epoch?.endOfDay(),
        },
    );

    const {isInputEditable, dates} = store;

    const emit = defineEmits<{
        (e: 'input', value: OutputValueType): void
    }>();

    const dateAsJSDate = computed(() => {
        return {from: dates.value.from?.toJSDate(), to: dates.value.to?.toJSDate()};
    });

    const {toHuman} = useHumanizePeriod(dateAsJSDate, __('components:select_period') ?? '');

    const popover = usePopover();
</script>

<template>
    <CPopover
        :popover="popover"
        @hidden="isInputEditable = false"
    >
        <CButton
            icon-menu
            :size="buttonSize"
        >
            <template #left-icons>
                <FontAwesomeIcon icon="fad fa-calendar-range"/>
            </template>
            {{ toHuman }}
        </CButton>
        <template #content>
            <div class="tw-flex tw-flex-col tw-gap-4">
                <MDatePickerInput
                    :has-error="hasError"
                    :store="store"
                    @hide="popover.hide"
                />
                <MDatePickerContent
                    :allow-undefined="allowUndefined"
                    :store="store"
                    @input="emit('input', $event)"
                />
            </div>
        </template>
    </CPopover>
</template>

<style scoped lang="scss">

</style>
