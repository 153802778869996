<template>
    <div
        class="card-chart"
        :class="{'settings': showSettings}"
    >
        <div class="chart-title">
            <div class="d-flex flex-row ml-1 mb-3">
                <div class="d-flex align-items-center tw-text-base tw-font-semibold text-uppercase">
                    <i
                        aria-hidden="true"
                        class="fas fa-users mr-2"
                    />{{ __('common:team_one') }}
                </div>
                <div
                    class="d-flex align-items-center flex-row ml-auto tw-text-sm tw-font-semibold text-uppercase pointer"
                    @click="showSettings = !showSettings"
                >
                    {{ humanizedSelectedDate }}
                    <i
                        aria-hidden="true"
                        class="fas fa-pencil ml-2"
                    />
                </div>
            </div>
            <div class="actions">
                <div class="tw-grid tw-grid-cols-2 tw-gap-2">
                    <MDatePicker
                        ref="datePicker"
                        v-model="selectedDate"
                        class="tw-w-full tw-col-start-2"
                        format="yyyy-MM-dd"
                    />
                </div>
            </div>
        </div>
        <div class="chart-content">
            <div class="chart-container px-1">
                <div class="w-100">
                    <loader
                        v-if="loading"
                        light="true"
                        shadow="false"
                        size="sm"
                    />
                    <template v-else-if="filteredStaffs.length">
                        <div
                            v-for="(staff, i) in filteredStaffs"
                            :key="'staff' + i"
                            class="tw-my-4"
                        >
                            <div class="d-flex flex-row">
                                <div class="d-flex align-items-center">
                                    <StaffAvatar
                                        class="avatar tw-absolute"
                                        :staff-model="staff"
                                    />
                                    <div
                                        class="mr-auto tw-truncate tw-font-grandhotel tw-text-2xl tw-capitalize"
                                        :class="staff.attributes.gender === 'male' ? 'tw-text-boy' : 'tw-text-girl'"
                                        style="margin-left: 40px; width: 120px;"
                                    >
                                        <router-link
                                            class="tw-text-inherit"
                                            :to="{name:'staffs.show',params:{nursery:nursery.id, staff:staff.id}}"
                                        >
                                            {{ $fullName(staff.attributes) }}
                                        </router-link>
                                    </div>
                                </div>
                                <div class="d-flex flex-row ml-auto anomalies tw-gap-2">
                                    <template v-for="(absence, i) in staff.events().value()">
                                        <MTooltip
                                            :key="'absence' + i"
                                            :label="absence.attributes.note ? absence.attributes.note : __('common:absence')"
                                        >
                                            <CBadge variant="danger">
                                                <template v-if="absence.attributes.datetime_event">
                                                    <template v-if="isAbsenceOnOneDay(absence)">
                                                        {{ $formatDate(absence.attributes.datetime_event.started_at, 'HH:mm') }}
                                                        - {{ $formatDate(absence.attributes.datetime_event.ended_at, 'HH:mm') }}
                                                    </template>
                                                    <template v-else>
                                                        {{ $formatDate(absence.attributes.datetime_event.started_at, 'D/MM') }}
                                                        - {{ $formatDate(absence.attributes.datetime_event.ended_at, 'D/MM') }}
                                                    </template>
                                                </template>
                                                <template v-else>
                                                    <template v-if="isAbsenceOnOneDay(absence)">
                                                        {{ __('dashboard:all_day') }}
                                                    </template>
                                                    <template v-else>
                                                        {{ $formatDate(absence.attributes.date_event.started_at, 'D/MM') }}
                                                        - {{ $formatDate(absence.attributes.date_event.ended_at, 'D/MM') }}
                                                    </template>
                                                </template>
                                            </CBadge>
                                        </MTooltip>
                                    </template>
                                </div>
                            </div>
                        </div>
                    </template>
                    <div
                        v-else
                        class="p-2 text-center"
                    >
                        {{ __('dashboard:staffs_no_warning') }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';
    import moment from 'moment';
    import _head from 'lodash-es/head';
    import _forEach from 'lodash-es/forEach';
    import StaffModel from '@/modules/human-resources/models/StaffModel';
    import {Epoch} from '@meekohq/lumos';
    import StaffAvatar from '@/modules/app/components/atoms/avatars/StaffAvatar.vue';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';
    import {isNil} from 'lodash-es';

    export default {
        components: {StaffAvatar},
        props: {
            nursery: {
                type: Object,
            },
        },
        data: () => ({
            staffs: [],
            date: Epoch.now().toFormat('yyyy-MM-dd'),
            selectedDate: Epoch.now().toFormat('yyyy-MM-dd'),
            selectedNursery: {},
            loading: false,
            showSettings: false,
        }),
        computed: {
            epochSelectedDate() {
                return this.selectedDate ? Epoch.parse(this.selectedDate, 'yyyy-MM-dd') : Epoch.now();
            },
            humanizedSelectedDate() {
                return this.epochSelectedDate.toLocaleString(Epoch.presets.WEEKDAY_WITH_DAY_AND_MONTH);
            },
            from() {
                return this.epochSelectedDate.startOfDay();
            },
            to() {
                return this.epochSelectedDate.endOfDay();
            },
            filteredStaffs() {
                return this.staffs?.filter(item => item.extra.hasAnomalies);
            },
        },
        watch: {
            selectedDate(val) {
                if (isNil(val)) {
                    return;
                }

                this.showSettings = false;
                this.$refs.datePicker.hide();
                this.date = val;
                this.getPresences();
            },
            'selectedNursery.id': {
                handler() {
                    if (this.selectedNursery && this.selectedNursery.id) {
                        this.getPresences();
                    }
                },
                deep: true,
            },
            'nursery.id': {
                handler() {
                    this.selectedNursery = this.nursery;
                },
                deep: true,
            },
        },
        mounted() {
            this.selectedNursery = this.nursery;
        },
        methods: {
            isAbsenceOnOneDay(absence) {
                let startedAtDay;
                let endedAtDay;
                if (absence.attributes.datetime_event) {
                    startedAtDay = moment(absence.attributes.datetime_event.started_at).format('DD/MM/YY');
                    endedAtDay = moment(absence.attributes.datetime_event.ended_at).format('DD/MM/YY');
                } else {
                    startedAtDay = moment(absence.attributes.date_event.started_at).format('DD/MM/YY');
                    endedAtDay = moment(absence.attributes.date_event.ended_at).format('DD/MM/YY');
                }

                return startedAtDay === endedAtDay;
            },

            async getPresences() {
                if (this.loading) {
                    return;
                }

                this.loading = true;

                try {
                    this.staffs = (await StaffModel.query()
                        .whereHas('organizations', query => query.scope('active'))
                        .with(new StaffModel().events(), q1 => {
                            return q1.where('factor', -1)
                                .scope('inRange', [moment(this.from.toJSDate()), moment(this.to.toJSDate())])
                                .whereHas('type', q2 => {
                                    // Exclude lunch breaks from the events
                                    return q2.whereNotIn('internal_id', ['13c23750-25a3-4cfd-8087-36a87b720a7a']);
                                });
                        })
                        .get()).all();

                    this.staffs.forEach(staff => {
                        Vue.set(staff.extra, 'hasAnomalies', staff.events().value().count() > 0);
                    });
                    this.loading = false;
                } catch (error) {
                    this.loading = false;
                    if (error && error.response && error.response.status === 422) {
                        _forEach(error.response.data.errors, value => {
                            useNotification().error(_head(value));
                        });
                    } else {
                        useNotification().error(error);
                    }
                }
            },
        },
    };
</script>

<style lang="scss" scoped>
    .avatar {
        height: 30px;
        width: 30px;
    }

    .anomalies {
        flex-wrap: nowrap;
        overflow-x: auto;
        overflow-y: hidden;
    }
</style>
