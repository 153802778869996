import type {Ref} from 'vue';
import {computed} from 'vue';
import type {Moment} from 'moment';
import moment from 'moment';

interface DatePickerStyles {
    dropdownClass: string;
}

/**
 * Provide the date picker styles
 * @param {Ref<Moment>} date
 * @param {Ref<string|undefined>} minDate
 * @param {Ref<string|undefined>} maxDate
 * @param {Ref<boolean>} syncDateByMinOrMax
 * @param {DatePickerStyles} styles
 */
export default function(
    date: Ref<Moment>,
    minDate: Ref<string | undefined>,
    maxDate: Ref<string | undefined>,
    syncDateByMinOrMax: Ref<boolean>,
    styles: DatePickerStyles,
) {
    /**
     * Get the dropdown classes.
     */
    const dropdownClasses = computed(() => {
        const output = [] as string[];

        output.push(styles.dropdownClass);

        return output;
    });

    /**
     * Get the day classes.
     * @param day
     */
    function getDayClasses(day) {
        const output = [] as any[];

        output.push('tw-font-semibold tw-transition tw-duration-200 tw-flex');
        output.push('tw-justify-center tw-items-center tw-rounded tw-w-8 tw-h-5');
        output.push('hover:tw-text-blue-800 hover:tw-bg-blue-200');

        const states = {
            now: 'tw-bg-red-500 tw-text-white tw-cursor-pointer',
            notInMonth: 'tw-text-gray-300 tw-cursor-pointer',
            disabled: 'tw-text-gray-400 hover:tw-text-gray-400 hover:tw-bg-transparent tw-cursor-not-allowed',
            active: 'tw-bg-blue-400 tw-text-white tw-cursor-pointer',
            default: 'tw-cursor-pointer',
        };

        if (!syncDateByMinOrMax.value && minDate.value && day.isBefore(moment(minDate.value), 'day')) {
            output.push(states.disabled);
        } else if (!syncDateByMinOrMax.value && maxDate.value && day.isAfter(moment(maxDate.value), 'day')) {
            output.push(states.disabled);
        } else if (!day.isSame(date.value, 'month')) {
            output.push(states.notInMonth);
        } else if (day.isSame(date.value, 'day')) {
            output.push(states.active);
        } else if (day.isSame(moment(), 'day')) {
            output.push(states.now);
        } else {
            output.push(states.default);
        }

        return output;
    }

    /**
     * Get the month classes.
     * @param {number} monthIndex
     */
    function getMonthClasses(monthIndex: number) {
        const output = [] as any[];

        output.push('tw-transition tw-duration-200 tw-flex tw-justify-center');
        output.push('tw-font-semibold tw-rounded tw-p-2 tw-cursor-pointer');
        output.push('hover:tw-border-blue-200 hover:tw-bg-blue-200');

        const states = {
            default: 'tw-bg-blue-200',
        };

        if (monthIndex === date.value.month()) {
            output.push(states.default);
        }

        return output;
    }

    return {
        dropdownClasses,
        getDayClasses,
        getMonthClasses,
    };
}
