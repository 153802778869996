<script setup lang="ts">
    import {computed} from 'vue';
    import type StaffModel from '@/modules/human-resources/models/StaffModel';
    import StaffAvatar from '@/modules/app/components/atoms/avatars/StaffAvatar.vue';
    import type EventModel from '@/modules/human-resources/models/EventModel';
    import {minutesToHours} from '@/modules/legacy/libs/planning/planning';
    import {EventStatus} from '@/modules/human-resources/utils/calendar/Values/EventStatus';
    import CalculateEventTime from '@/modules/human-resources/utils/statistics/CalculateEventTime';
    import type {ModelCollection} from '@meekohq/lumos';
    import EventBadge from '@/modules/human-resources/components/calendar/EventBadge.vue';

    const props = defineProps<{
        staff: StaffModel;
        showReal: boolean;
        selectedStaff?: StaffModel;
        events?: ModelCollection<EventModel>;
    }>();

    const groupedEventTypes = computed(() => {
        return props.events?.mapToGroups(item => [
            item.attributes.type_id,
            item.attributes.type_id,
        ]).all();
    });

    const filteredValidEvents = computed(() =>
        props.events?.filter(event => event.attributes.status === EventStatus.validated).toArray() || [],
    );

    function getEventsTotals(type: string) {
        const {real, forecast, total} =
            new CalculateEventTime(filteredValidEvents.value).getEventsTimeByType(type);

        return {
            total: minutesToHours(total),
            real: minutesToHours(real),
            forecast: minutesToHours(forecast),
        };
    }
</script>
<template>
    <div class="tw-flex tw-items-center">
        <div class="tw-flex tw-items-center tw-w-7/12">
            <StaffAvatar
                class="tw-shrink-0 tw-w-10"
                :staff-model="staff"
            />
            <div class="tw-ml-2">
                <div
                    class="tw-font-grandhotel tw-text-2xl tw-capitalize"
                    :class="[
                        staff === selectedStaff
                            ? 'tw-text-white'
                            : staff.genderColor,
                    ]"
                >
                    {{ $fullName(staff.attributes) }}
                </div>
                <div
                    class="tw-text-sm tw-truncate"
                    :class="[
                        staff === selectedStaff
                            ? 'tw-text-white'
                            : 'tw-text-gray-500',
                    ]"
                >
                    {{ staff.attributes.job }}
                </div>
            </div>
        </div>
        <div
            v-if="events"
            class="tw-w-5/12 tw-text-right"
        >
            <span
                v-for="(eventType, j) in groupedEventTypes"
                :key="'eventType' + j"
            >
                <CTooltip placement="top">
                    <EventBadge
                        class="!tw-inline-flex tw-my-1 tw-ml-1"
                        display-sum
                        :events="events"
                        :show-real="showReal"
                        size="sm"
                        :type="eventType[0]"
                    />
                    <template #content>
                        <div class="tw-text-sm tw-font-semibold">
                            <div class="tw-mb-2">
                                {{ __('common:forecasted_with_value', {value: getEventsTotals(eventType[0]).forecast}) }}
                            </div>
                            <div>
                                {{ __('common:real_with_value', {value: getEventsTotals(eventType[0]).real}) }}
                            </div>
                        </div>
                    </template>
                </CTooltip>
            </span>
        </div>
    </div>
</template>
