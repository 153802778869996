<script setup lang="ts">
    import type {ModalType} from '@/modules/app/composables/useModal';
    import type PersonalAccessTokenModel from '@/modules/auth/personal-access-token/domain/PersonalAccessTokenModel';
    import {ref} from 'vue';
    import {useSudoMode} from '@/modules/auth/sudo-mode/infrastructure/components/useSudoMode';
    import {app} from '@meekohq/lumos';
    import type {
        DeletePersonalAccessTokenUseCase,
    } from '@/modules/auth/personal-access-token/application/DeletePersonalAccessTokenUseCase';
    import {Types} from '@/types';
    import {
        DeletePersonalAccessTokenRequest,
    } from '@/modules/auth/personal-access-token/application/DeletePersonalAccessTokenRequest';

    const props = defineProps<{
        modal: ModalType,
        item: PersonalAccessTokenModel
    }>();

    const emit = defineEmits<{
        (e: 'deleted'): void
    }>();

    const isDeleting = ref(false);

    async function deletePat() {
        const sudoToken = await useSudoMode().acquireSudoMode();

        if (sudoToken.isDismissed) {
            return;
        }

        isDeleting.value = true;

        try {
            await app().make<DeletePersonalAccessTokenUseCase>(Types.DeletePersonalAccessTokenUseCase).delete(DeletePersonalAccessTokenRequest.make({
                model: props.item,
                sudoToken: sudoToken.token,
            }));
            emit('deleted');
        } finally {
            isDeleting.value = false;
        }
    }
</script>

<template>
    <MModal
        :header-title="__('user:delete_key')"
        :modal="modal"
    >
        <div class="tw-text-gray-500 tw-mb-4">
            <FontAwesomeIcon
                class="tw-text-red-500 tw-mr-1"
                icon="fad fa-exclamation-triangle"
            />
            {{ __('user:delete_key_confirmation') }}
        </div>
        <template #footer-end="{hide}">
            <MButton
                :label="__('common:actions.cancel')"
                @click="hide"
            />
            <MButton
                :label="__('common:actions.delete')"
                :loading="isDeleting"
                variant="danger"
                @click="deletePat"
            />
        </template>
    </MModal>
</template>
