<template>
    <CModal
        ref="modal"
        :header-title="__('hr_balance:update_balance')"
        :shared="shared"
    >
        <BalanceTypeForm
            ref="form"
            :error-form="balanceTypeFormError"
            :model="copy"
            @checkExisting="checkExisting = $event"
            @loading="loader.set($event)"
            @saved="updateModel($event)"
        />
        <template #footer>
            <CButton
                class="tw-ml-auto"
                @click="onClose"
            >
                {{ __('common:actions.close') }}
            </CButton>
            <CButton
                class="tw-ml-2"
                :disabled="hasErrors || checkExisting"
                :loading="loader.isLoading() ||checkExisting"
                variant="primary"
                @click="$refs.form.save()"
            >
                {{ __('common:actions.save') }}
            </CButton>
        </template>
    </CModal>
</template>

<script lang="ts">
    import type {PropType, Ref} from 'vue';
    import {computed, defineComponent, reactive, ref, watch} from 'vue';
    import Loader from '@/modules/legacy/helpers/loader.helper';
    import __ from '@/modules/app/utils/i18n-facade';
    import BalanceTypeForm from '@/modules/human-resources/components/balance-type/BalanceTypeForm.vue';
    import type BalanceTypeModel from '@/modules/human-resources/models/BalanceTypeModel';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';
    import {useLegacyModalBus} from '@/modules/meeko-ui/composables/useLegacyModalBus';
    import useError from '@/modules/app/composables/useError';

    export default defineComponent({
        components: {BalanceTypeForm},
        props: {
            model: {type: Object as PropType<BalanceTypeModel>, required: true},
            shared: {type: Boolean, default: false},
        },
        setup(props, {emit}) {
            const copy = ref() as Ref<BalanceTypeModel>;
            const loader = reactive(new Loader());
            const {modal, show, hide} = useLegacyModalBus();

            const checkExisting = ref(false);
            const balanceTypeFormError = useError();
            const hasErrors = computed(() => {
                return balanceTypeFormError.hasErrors.value;
            });

            watch(() => props.model, value => {
                copy.value = value.clone();
            }, {immediate: true});

            const updateModel = function(balanceType) {
                balanceType.copyTo(props.model);
                emit('updated', balanceType);
                hide();
                useNotification().success(__('hr_balance:balance_type_updated_successfully'));
            };

            const onClose = function() {
                hide();
            };

            return {
                balanceTypeFormError,
                hasErrors,
                checkExisting,
                loader,
                updateModel,
                modal,
                show,
                hide,
                onClose,
                copy,
            };
        },
    });
</script>
