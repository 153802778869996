import {app, config, Container, ServiceProvider, UnauthorizedError} from '@meekohq/lumos';
import type {AxiosInstance} from 'axios';
import useAuth from '@/modules/app/composables/useAuth';
import BasicAuthentication from '@/modules/auth/utils/BasicAuthentication';
import type {AuthenticationContract} from '@/modules/auth/utils/AuthenticationContract';
import {datadogRum} from '@datadog/browser-rum';
import {useLaunchDarkly} from '@/modules/app/composables/useLaunchDarkly';
import useApi from '@/modules/app/composables/useApi';
import useAbility from '@/modules/app/composables/useAbility';
import Manager from '@/modules/legacy/store/manager.store';
import useIntercom from '@/modules/app/composables/useIntercom';
import type {BroadcastContract} from '@/modules/app/utils/BroadcastContract';
import i18next from 'i18next';
import useJobList from '@/modules/human-resources/composables/job/useJobList';
import DataStore from '@/modules/legacy/store/data.store';
import {Types} from '@/types';
import type {
    GeneratePersonalAccessTokenUseCase,
} from '@/modules/auth/personal-access-token/application/GeneratePersonalAccessTokenUseCase';
import {
    GeneratePersonalAccessTokenInteractor,
} from '@/modules/auth/personal-access-token/application/GeneratePersonalAccessTokenInteractor';
import type {
    PaginatePersonalAccessTokenUseCase,
} from '@/modules/auth/personal-access-token/application/PaginatePersonalAccessTokenUseCase';
import {
    PaginatePersonalAccessTokenInteractor,
} from '@/modules/auth/personal-access-token/application/PaginatePersonalAccessTokenInteractor';
import PersonalAccessTokenModel from '@/modules/auth/personal-access-token/domain/PersonalAccessTokenModel';
import type {
    UpdatePersonalAccessTokenUseCase,
} from '@/modules/auth/personal-access-token/application/UpdatePersonalAccessTokenUseCase';
import {
    UpdatePersonalAccessTokenInteractor,
} from '@/modules/auth/personal-access-token/application/UpdatePersonalAccessTokenInteractor';
import type {
    DeletePersonalAccessTokenUseCase,
} from '@/modules/auth/personal-access-token/application/DeletePersonalAccessTokenUseCase';
import {
    DeletePersonalAccessTokenInteractor,
} from '@/modules/auth/personal-access-token/application/DeletePersonalAccessTokenInteractor';
import type {SudoModeUseCase} from '@/modules/auth/sudo-mode/application/SudoModeUseCase';
import {SudoModeInteractor} from '@/modules/auth/sudo-mode/application/SudoModeInteractor';

export default class AuthServiceProvider extends ServiceProvider {
    public async boot() {
        const api = app<AxiosInstance>(Container.contracts.httpMqlAxios);

        this.loadModelsFrom([
            PersonalAccessTokenModel,
        ]);

        api.interceptors.response.use(response => {
            return response;
        }, error => {
            if (error instanceof UnauthorizedError) {
                this.app.make<AuthenticationContract>(Types.Auth).logout();
            }

            return Promise.reject(error);
        });

        await this.app.make<AuthenticationContract>(Types.Auth).loginByCredentials();
    }

    public register() {
        this.app.singleton<AuthenticationContract>(Types.Auth).toCallback(() => {
            return new BasicAuthentication(
                app<AxiosInstance>(Types.LegacyApi),
            );
        });

        this.app.make<AuthenticationContract>(Types.Auth).defineBeforeLoginCallback(async credentials => {
            app<AxiosInstance>(Types.LegacyApi).defaults.headers.common['Authorization'] = `Bearer ${credentials.token}`;
            app<AxiosInstance>(Types.Api).defaults.headers.common['Authorization'] = `Bearer ${credentials.token}`;

            return true;
        });

        this.app.make<AuthenticationContract>(Types.Auth).defineAfterLoginCallback(async (data, credentials) => {
            if (config('services.datadog.enabled')) {
                datadogRum.setUser({
                    id: data.userModel.getKey(),
                });
            }

            if (config('services.launchDarkly.enabled')) {
                try {
                    await useLaunchDarkly().initialize({
                        kind: 'user',
                        key: data.userModel.getKey(),
                        name: data.userModel.fullname,
                        release_channel: data.userModel.attributes.release_channel,
                        account_id: data.userModel.attributes.account_id,
                    });
                } catch (e) {
                    console.warn(e);
                }
            }

            if (config('services.pusher.enabled')) {
                await app<BroadcastContract>(Types.Broadcast).setup(data.userModel.getKey(), credentials.token);
            }

            if (config('services.intercom.enabled') && !window.localStorage.getItem('support-token')) {
                const {setup: setupIntercom} = useIntercom();
                setupIntercom(data.legacyUser);
            }

            useAuth().setToken(credentials.token);
            useAuth().setLegacyToken(credentials.legacy_token);
            useAuth().setUserModel(data.userModel);
            useAuth().setLegacyUser(data.legacyUser);
            useAuth().setIsAuthenticated(true);

            // Load user locale
            await i18next.changeLanguage(data.userModel.attributes.locale);

            this.app.bind<SudoModeUseCase>(Types.SudoModeUseCase)
                .toCallback(() => new SudoModeInteractor());

            this.app.bind<GeneratePersonalAccessTokenUseCase>(Types.GeneratePersonalAccessTokenUseCase)
                .toCallback(() => new GeneratePersonalAccessTokenInteractor());

            this.app.bind<PaginatePersonalAccessTokenUseCase>(Types.PaginatePersonalAccessTokenUseCase)
                .toCallback(() => new PaginatePersonalAccessTokenInteractor());

            this.app.bind<UpdatePersonalAccessTokenUseCase>(Types.UpdatePersonalAccessTokenUseCase)
                .toCallback(() => new UpdatePersonalAccessTokenInteractor());

            this.app.bind<DeletePersonalAccessTokenUseCase>(Types.DeletePersonalAccessTokenUseCase)
                .toCallback(() => new DeletePersonalAccessTokenInteractor());

            return true;
        });

        this.app.make<AuthenticationContract>(Types.Auth).defineAfterLogoutCallback(async () => {
            if (config('services.datadog.enabled')) {
                datadogRum.clearUser();
            }

            if (config('services.launchDarkly.enabled')) {
                useLaunchDarkly().close();
            }

            if (config('services.pusher.enabled')) {
                app<BroadcastContract>(Types.Broadcast).disconnect();
            }

            useAbility().arePermissionsLoaded.value = false;
            Manager.flush();

            useAuth().setToken(null);
            useAuth().setLegacyToken(null);
            useAuth().setUserModel(null);
            useAuth().setLegacyUser(null);
            useAuth().setIsAuthenticated(false);

            useJobList().clearJobs();
            DataStore.flush();
            app<any>(Container.contracts.databaseCache).clear();

            delete useApi().legacy.defaults.headers.common['Authorization'];
            delete useApi().api.defaults.headers.common['Authorization'];
        });
    }
}
