import {ServiceProvider} from '@meekohq/lumos';
import RegistrationModel from '@/modules/registration/models/RegistrationModel';
import RegistrationOrganizationPivot from '@/modules/registration/models/RegistrationOrganizationPivot';
import RegistrationPlanning from '@/modules/registration/models/RegistrationPlanning';

export default class RegistrationServiceProvider extends ServiceProvider {
    public boot() {
        this.loadModelsFrom([
            RegistrationModel,
            RegistrationOrganizationPivot,
            RegistrationPlanning,
        ]);
    }
}
