import type {Ref} from 'vue';
import {computed} from 'vue';
import type {HasMany} from '@meekohq/lumos';
import {type BelongsToMany, type Model} from '@meekohq/lumos';
import {isEmpty} from 'lodash-es';
import useManager from '@/modules/app/composables/useManager';

export interface HasOrganization extends Model {
    organizations(): BelongsToMany<any, any, any>;

    organizationsPivots(): HasMany<any, any>;
}

export interface HasEvents extends Model {
    events(): HasMany<any, any>;
}

export default function <T extends HasOrganization & HasEvents>(model: Ref<T>) {
    const {activeOrganization} = useManager();

    const relation = model.value.organizationsPivots();

    const getPivots = computed(() => {
        return relation.value();
    });

    const activePivot = computed(() => {
        return relation.value().first(item => item.attributes.organization_id === activeOrganization.value.id);
    });

    const isPivotArchived = computed(() => !isEmpty(activePivot.value?.attributes.archived_at));

    const isUsedInAnotherOrganization = computed(() => getPivots.value.count() > 1);

    return {
        relation,
        getPivots,
        activePivot,
        isPivotArchived,
        isUsedInAnotherOrganization,
    };
}
