<template>
    <ResourceFinder
        :builder="queryBuilder"
        :button-class="buttonClass"
        :button-size="buttonSize"
        :button-variant="buttonVariant"
        :hide-fallback-if-no-result="true"
        :multi="multi"
        :multi-minimum="multiMinimum"
        :prevent-default="preventDefault"
        :search-bar="searchBar"
        :stop-propagation="stopPropagation"
        :value="value"
        :wrapper-class="wrapperClass"
        @fallback="onFallBack"
        @input="onChange"
        @search="search = $event"
    >
        <template #button-text-empty>
            {{ withoutTagsOptionIsSelected ? __('tag:without_tags') : 'Tags' }}
        </template>

        <template #button-text="{currentValue}">
            {{ currentValue.attributes.name }}
        </template>
        <template #button-text-multi="{values, langVar}">
            <slot
                :lang-var="langVar"
                name="button-text-multi"
                :values="values"
            >
                {{ __('components:count_item', {value: values[0].attributes.name, count: values.length - 1}) }}
            </slot>
        </template>

        <template #default="{option, searchValue}">
            <div class="tw-flex tw-gap-2 tw-items-center">
                <div class="tw-py-0.5 tw-pl-0.5">
                    <TagPill_v2 :tag="option"/>
                </div>
                <div
                    class="tw-truncate"
                    v-html="highlight(searchValue, option.attributes.name)"
                />
            </div>
        </template>
        <template #empty-result>
            {{ __('tag:no_tags') }}
        </template>
        <template #fallback>
            <div
                class="tw-flex tw-items-center"
                :class="withoutTagsOptionIsSelected ? 'tw-font-semibold' : ''"
            >
                <i
                    aria-hidden="true"
                    class="far fa-ban tw-mr-2"
                />
                {{ __('tag:without_tags') }}
            </div>
        </template>
    </ResourceFinder>
</template>

<script lang="ts">
    import type {PropType} from 'vue';
    import {computed, defineComponent, ref} from 'vue';
    import ResourceFinder from '@/modules/app/components/resource/organisms/ResourceFinder.vue';
    import TagModel from '@/modules/tag/models/TagModel';
    import useHighlighter from '@/modules/legacy/helpers/useHighlighter';
    import type {QueryBuilder} from '@meekohq/lumos';
    import {type Model} from '@meekohq/lumos';
    import TagPill_v2 from '@/modules/tag/components/TagPill_v2.vue';

    export default defineComponent({
        components: {TagPill_v2, ResourceFinder},
        props: {
            value: {type: [Object, Array] as PropType<Model | Model[]>, required: true},
            injectQuery: {type: Object as PropType<QueryBuilder<any>> | null, default: null},
            hasUnselect: {type: Boolean, default: false},
            multi: {type: Boolean, default: false},
            multiMinimum: {type: [Number, String], default: 0},
            wrapperClass: {type: Object, default: () => new Object()},
            buttonClass: {type: Object, default: () => new Object()},
            buttonVariant: {type: String, default: ''},
            searchBar: {type: Boolean, default: true},
            withoutTagsOptionIsSelected: {type: Boolean, default: false},
            onlyActiveOrganization: {type: Boolean, default: false},
            buttonSize: {type: String, default: 'base'},
            stopPropagation: {type: Boolean, default: false},
            preventDefault: {type: Boolean, default: false},
        },
        emits: ['input', 'without-tags-option-is-selected'],
        setup(props, {emit}) {
            const search = ref('');

            const onChange = function(value) {
                emit('without-tags-option-is-selected', false);
                emit('input', value);
            };

            const queryBuilder = computed(() => {
                const builder = TagModel.query()
                    .orderBy('name')
                    .whereLike('name', `*${search.value}*`);

                if (props.onlyActiveOrganization) {
                    builder.whereHas(new TagModel().organizations(), query => {
                        query.scope('active');
                    });
                }

                if (props.injectQuery) {
                    builder.inject(props.injectQuery);
                }

                return builder;
            });

            function onFallBack() {
                emit('without-tags-option-is-selected', !props.withoutTagsOptionIsSelected);
                emit('input', []);
            }

            return {
                onChange,
                onFallBack,
                queryBuilder,
                search,
                ...useHighlighter(),
            };
        },
    });
</script>
