import {ServiceProvider} from '@meekohq/lumos';
import type {AxiosInstance} from 'axios';
import PusherBroadcast from '@/modules/app/utils/PusherBroadcast';
import type {BroadcastContract} from '@/modules/app/utils/BroadcastContract';
import {Types} from '@/types';

export default class BroadcastServiceProvider extends ServiceProvider {
    public register() {
        this.app.singleton<BroadcastContract>(Types.Broadcast).toCallback(app => {
            const broadcast = new PusherBroadcast();

            broadcast.setAxiosSessionId(app.make<AxiosInstance>(Types.Api));
            broadcast.setAxiosSessionId(app.make<AxiosInstance>(Types.LegacyApi));

            return broadcast;
        });
    }
}
