<template>
    <div id="contextMenu">
        <div v-if="showMenu">
            <div
                class="oy-mask-black"
                @click="toggle(false)"
            />
            <div
                class="oy-menu-group"
                :class="{'open':open}"
            >
                <div class="btn-list">
                    <template v-if="event.event_type === 'planning-occasional' || event.event_type === 'planning-adaptation'">
                        <div
                            class="oy-menu-item oy-menu-item_1 btn-danger"
                            @click="deleteEvent()"
                        >
                            <div>
                                <i
                                    aria-hidden="true"
                                    class="fa fa-times fa-lg"
                                /> {{ __('planning:delete_planning') }}
                            </div>
                        </div>
                        <div
                            class="oy-menu-item oy-menu-item_2 btn-warning"
                            @click="openEditModal()"
                        >
                            <div>
                                <i
                                    aria-hidden="true"
                                    class="fa fa-edit fa-lg"
                                /> {{ __('planning:edit_planning') }}
                            </div>
                        </div>
                        <div
                            class="oy-menu-item oy-menu-item_3 btn-success"
                            @click="openAddModal('presence', null)"
                        >
                            <div>
                                <i
                                    aria-hidden="true"
                                    class="fa fa-calendar-check fa-lg"
                                /> {{ __('planning:add_pointing') }}
                            </div>
                        </div>
                        <div
                            class="oy-menu-item oy-menu-item_4 btn-danger"
                            @click="openAddModal('absence', null)"
                        >
                            <div>
                                <i
                                    aria-hidden="true"
                                    class="fa fa-calendar-times fa-lg"
                                /> {{ __('planning:add_absence') }}
                            </div>
                        </div>
                    </template>
                    <template v-else-if="['presence-recurrent', 'presence-adaptation', 'presence-occasional', 'overrun'].includes(event.event_type)">
                        <div
                            class="oy-menu-item oy-menu-item_2 btn-warning"
                            @click="openEditModal()"
                        >
                            <div>
                                <i
                                    aria-hidden="true"
                                    class="fa fa-edit fa-lg"
                                /> {{ __('planning:edit_pointing') }}
                            </div>
                        </div>
                        <div
                            class="oy-menu-item oy-menu-item_1 btn-danger"
                            @click="deleteEvent()"
                        >
                            <div>
                                <i
                                    aria-hidden="true"
                                    class="fa fa-times fa-lg"
                                /> {{ __('planning:delete_pointing') }}
                            </div>
                        </div>
                    </template>
                    <template v-else-if="event.event_type === 'absence'">
                        <div
                            class="oy-menu-item oy-menu-item_2 btn-warning"
                            @click="openEditModal()"
                        >
                            <div>
                                <i
                                    aria-hidden="true"
                                    class="fa fa-edit fa-lg"
                                /> {{ __('planning:edit_absence') }}
                            </div>
                        </div>
                        <div
                            class="oy-menu-item oy-menu-item_1 btn-danger"
                            @click="deleteEvent()"
                        >
                            <div>
                                <i
                                    aria-hidden="true"
                                    class="fa fa-times fa-lg"
                                /> {{ __('planning:delete_absence') }}
                            </div>
                        </div>
                    </template>
                    <template v-else>
                        <div
                            class="oy-menu-item oy-menu-item_1 btn-success"
                            @click="openAddModal('presence', null)"
                        >
                            <div>
                                <i
                                    aria-hidden="true"
                                    class="fa fa-calendar-check fa-lg"
                                /> {{ __('planning:add_pointing') }}
                            </div>
                        </div>
                        <div
                            class="oy-menu-item oy-menu-item_2 btn-danger"
                            @click="openAddModal('absence', null)"
                        >
                            <div>
                                <i
                                    aria-hidden="true"
                                    class="fa fa-calendar-times fa-lg"
                                /> {{ __('planning:add_absence') }}
                            </div>
                        </div>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import _cloneDeep from 'lodash-es/cloneDeep';
    import _head from 'lodash-es/head';
    import _forEach from 'lodash-es/forEach';
    import {nursery} from '@/modules/legacy/mixins/nursery';
    import moment from 'moment';
    import route from '@/modules/legacy/libs/ziggy';
    import useApi from '@/modules/app/composables/useApi';
    import _clone from 'lodash-es/clone';
    import __ from '@/modules/app/utils/i18n-facade';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';
    import useEditEventModal from '@/modules/planning/composables/core/useEditEventModal';

    export default {
        mixins: [nursery],
        props: {
            nursery: {},
            event: Object,
            visible: Boolean,
        },
        data() {
            return {
                open: false,
                myEvent: {},
                newEvent: _cloneDeep(this.event),
                showMenu: false,
            };
        },
        computed: {
            moment() {
                return moment;
            },
        },
        watch: {
            visible() {
                this.toggle(true);
            },
        },
        mounted() {
            this.$bus.$on('clicked:line', line => {
                this.openAddModal('occasional', line);
            });
        },
        beforeDestroy() {
            this.$bus.$off('clicked:line');
        },
        methods: {
            toggle(val) {
                if (!val) {
                    this.open = val;
                    $('.planning-segment.z-index-1001').each((i, element) => {
                        $(element).removeClass('z-index-1001');
                    });
                    setTimeout(() => {
                        this.showMenu = val;
                        $('#contextMenu').appendTo($('#context-parent'));
                    }, 300);
                } else {
                    this.showMenu = val;
                    setTimeout(() => {
                        this.open = val;
                    }, 200);
                }
            },
            openEditModal() {
                this.myEvent = _cloneDeep(this.event);
                this.toggle(false);
                useEditEventModal().show(this.myEvent);
            },
            openAddModal(type, line) {
                if (line) {
                    // Si on ajoute un planning sur une ligne
                    let opening = this.nursery ? this.$getOpening(this.nursery.openingHours, false, true) : null;
                    let closing = this.nursery ? this.$getClosing(this.nursery.openingHours, false, true) : null;
                    opening = opening ? opening : '08:00';
                    closing = closing ? closing : '18:00';

                    this.newEvent = {
                        day: line.day,
                        nursery_id: line.nursery_id,
                        kid_id: line.kid_id,
                        start_time: this.newEvent.start_time ? this.newEvent.start_time : opening,
                        end_time: this.newEvent.end_time ? this.newEvent.end_time : closing,
                        planning_id: null,
                        group_id: null,
                        vacation: type === 'absence' ? false : undefined,
                    };

                    this.newEvent.type = type;
                    const actual = this.newEvent.day.clone().format('YYYY-MM-DD');
                    this.newEvent.started_at = moment(actual + ' ' + this.newEvent.start_time, 'YYYY-MM-DD HH:mm:ss').unix();
                    this.newEvent.ended_at = moment(actual + ' ' + this.newEvent.end_time, 'YYYY-MM-DD HH:mm:ss').unix();
                    this.newEvent.contract_hourly_rate = line.hourly_rate;
                    this.newEvent.hourly_rate = line.hourly_rate;
                    this.newEvent.unit = line.unit;

                } else {
                    // Si on ajoute depuis un planning
                    this.newEvent = _clone(this.event);
                    this.newEvent.type = type;
                    this.newEvent.planning_id = this.event.id;
                    this.newEvent.id = null;
                    if (type === 'absence') {
                        this.newEvent.vacation = false;
                    }
                }

                this.toggle(false);
                this.newEvent.isNew = true;

                useEditEventModal().show(this.newEvent);
            },
            deleteEvent() {
                let eventRoute = undefined;
                switch (this.event.type) {
                    case 'occasional':
                    case 'adaptation':
                        eventRoute = route('kid.plannings.destroy', {planning: this.event.id});
                        break;
                    case 'presence':
                        eventRoute = route('kid.presences.destroy', {presence: this.event.id});
                        break;
                    case 'absence':
                        eventRoute = route('kid.absences.destroy', {absence: this.event.id});
                        break;
                }

                useApi().legacy.delete(eventRoute).then(() => {
                    this.toggle(false);
                    setTimeout(() => {
                        switch (this.event.type) {
                            case 'adaptation':
                            case 'occasional':
                                this.$bus.$emit('deleted:planning', this.event);
                                useNotification().success(__('planning:planning_deleted'));
                                break;
                            case 'absence':
                                this.$bus.$emit('deleted:absence', this.event);
                                useNotification().success(__('planning:absence_deleted'));
                                break;
                            case 'presence':
                                this.$bus.$emit('deleted:presence', this.event);
                                useNotification().success(__('planning:pointing_deleted'));
                                break;
                        }
                    }, 350);
                }).catch(error => {
                    if (error && error.response && error.response.status === 422) {
                        _forEach(error.response.data.errors, function(value) {
                            useNotification().error(_head(value));
                        });
                    } else {
                        useNotification().error(error);
                    }
                });
            },
        },
    };
</script>

<style lang="scss">
    #contextMenu {
        position: absolute;

        .oy-menu-group {
            position: relative;
            z-index: 3060;
            transition: .2s;

            &.open {
                .oy-menu-item {
                    opacity: 1;
                }
            }
        }
    }

    .oy-menu-item {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;

        width: 220px;
        height: 45px;
        line-height: 45px;
        font-size: 14px;
        color: white !important;
        cursor: pointer;
        padding-left: 10px;
        text-align: center;
        border-radius: 30px;
        transition: .2s .1s;

        &:active {
            box-shadow: inset 0 0 1000px rgba(0, 0, 0, .5)
        }

        div {
            .fa, .svg-inline--fa {
                position: absolute;
                left: 18px;
                top: 22px;
            }
        }
    }

    .oy-menu-group.open .btn-list .oy-menu-item.oy-menu-item_1 {
        top: -55px;
    }

    .oy-menu-group.open .btn-list .oy-menu-item.oy-menu-item_2 {
        top: -110px;
    }

    .oy-menu-group.open .btn-list .oy-menu-item.oy-menu-item_3 {
        top: -165px;
    }

    .oy-menu-group.open .btn-list .oy-menu-item.oy-menu-item_4 {
        top: -220px;
    }

    .oy-mask-black {
        position: fixed;
        background: rgba(0, 60, 123, 0.3);
        z-index: 3050;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
    }
</style>
