import TemplatePlanningModel from '@/modules/human-resources/models/TemplatePlanningModel';
import type TemplateModel from '@/modules/human-resources/models/TemplateModel';
import type TemplateEventModel from '@/modules/human-resources/models/TemplateEventModel';
import {EventFactory} from '@/modules/human-resources/template/infrastructure/EventFactory';
import {collect} from '@meekohq/lumos';
import {TemplateFactory} from '@/modules/human-resources/template/infrastructure/TemplateFactory';

export class PlanningFactory {
    public static duplicatePlanning(planning: TemplatePlanningModel, template?: TemplateModel): TemplatePlanningModel {
        // If no template is provided, try to duplicate the template from the planning template relation
        if (!template && planning.attributes.template_id) {
            template = TemplateFactory.duplicateTemplate(planning.template().value());
            planning.setRelation('template', template);
        }

        // Duplicate the planning
        const planningClone = planning.clone();
        planningClone.attributes.id = new TemplatePlanningModel().uuid();
        planningClone.attributes.template_id = template?.getKey() ?? null;
        planningClone.attributes.name = `${planning.attributes.name} (Copy)`;
        planningClone.exists = false;


        // Duplicate the planning events
        const eventClones = collect();

        planningClone.planningEvents().value().each(async (event: TemplateEventModel) => {
            eventClones.push(EventFactory.duplicateEvent(event, planningClone));
        });

        planningClone.setRelation('planningEvents', eventClones);

        return planningClone;
    }
}
