<template>
    <div class="AuthTemplate">
        <h1 class="AuthTemplate__meeko-title">
            meeko
        </h1>
        <h2 class="AuthTemplate__title">
            <slot name="title"/>
        </h2>
        <div class="AuthTemplate__form">
            <slot name="form"/>
        </div>
        <div
            v-if="showLoginButtons === true"
            class="AuthTemplate__login-buttons"
        >
            <slot name="loginButtons"/>
        </div>
        <div
            v-if="showMeekoLogo === true"
            class="AuthTemplate__meeko-logo"
        >
            <img
                alt="Meeko"
                class="animated slideInUp MeekoAvatar"
                src="../../assets/images/Supercoon.png"
            >
        </div>
    </div>
</template>

<script lang="ts">
    import {defineComponent} from 'vue';

    export default defineComponent({
        components: {},
        props: {
            showMeekoLogo: {type: Boolean, required: false, default: true},
            showLoginButtons: {type: Boolean, required: false, default: false},
        },
        setup() {
            return {};
        },
    });
</script>

<style lang="scss" scoped>
    .AuthTemplate {
        @apply tw-h-auto tw-min-h-screen;
        @apply tw-flex tw-flex-col tw-justify-center tw-items-center;
        @apply tw-relative;
        @apply tw-overflow-hidden;
        @apply tw-text-center;

        &::before {
            content: '';
            position: absolute;
            display: block;
            top: -50%;
            bottom: -50%;
            left: 0;
            right: 0;
            background: url("@/assets/images/pattern.png") 0 0 repeat;
            background-size: 652px 700px;
            transform: rotate(-8deg);
            opacity: .1;
            z-index: -1;
        }
    }

    .AuthTemplate__meeko-title {
        @apply tw-font-grandhotel tw-antialiased;
        @apply tw-text-[110px] tw-leading-[3.75rem];
        @apply tw-text-primary-500;
        @apply tw-mb-10;
        text-shadow: rgba(#0060C6, .3) 0 2px 3px;
    }

    .AuthTemplate__title {
        @apply tw-font-grandhotel tw-antialiased;
        @apply tw-text-5xl;
        @apply tw-text-primary-700;
        text-shadow: rgba(#0060C6, .3) 0 2px 3px;
    }

    .AuthTemplate__form {
        @apply tw-z-10;
        @apply sm:tw-w-[500px] tw-w-full;
        @apply tw-py-2 tw-px-4;
    }

    .AuthTemplate__login-buttons {
        @apply tw-px-4;
        @apply sm:tw-w-[500px] tw-w-full;
    }

    .AuthTemplate__meeko-logo {
        @apply tw-absolute;
        @apply tw--bottom-14 tw-right-0 tw--rotate-6;
        @apply tw-z-0;
        @apply lg:tw-w-80 md:tw-w-72 tw-w-52;
    }
</style>
