<template>
    <div>
        <div class="row align-items-center">
            <div class="col-12 col-sm-6">
                <MPageTitle class="tw-mb-4">
                    {{ __('common:my_organizations') }}
                </MPageTitle>
                <MHeading
                    class="tw-mb-4"
                    level="h3"
                >
                    {{ __('organization:to_start_select_one_organization_colon') }}
                </MHeading>
            </div>
            <div
                v-if="user.is_owner"
                class="col-12 col-sm-6 text-sm-right"
            >
                <MButton
                    icon-plus
                    :label="__('organization:add_organization')"
                    variant="primary"
                    @click="createOrganizationModal.show"
                />
            </div>
        </div>

        <transition
            mode="out-in"
            name="fade"
        >
            <div v-if="!loader">
                <div class="row">
                    <div
                        v-for="(nursery, i) in filtered"
                        :key="'nursery' + i"
                        class="col-12 mb-3"
                    >
                        <div class="card card-3d">
                            <div class="card-body">
                                <div class="row align-items-center">
                                    <div class="col-8">
                                        <h4
                                            class="h4 card-title tw-font-grandhotel tw-capitalize text-primary text-capitalize"
                                            style="font-size: 1.8rem; line-height: 1.8rem;"
                                        >
                                            <router-link :to="{name:'nursery',params:{nursery:nursery.id}}">
                                                <i
                                                    aria-hidden="true"
                                                    class="fad fa-home mr-2"
                                                />{{ $capitalize(nursery.name) }}
                                            </router-link>
                                        </h4>
                                        <h6 class="h6 card-subtitle text-muted">
                                            <i
                                                aria-hidden="true"
                                                class="fad fa-map-marker-alt fa-swap-opacity mx-1"
                                            />{{ $capitalize(nursery.city) }} - {{ __('common:place_with_count', {count: nursery.places}) }}
                                        </h6>
                                    </div>
                                    <div class="col-sm-6 col-md-4 mt-2 mt-md-0 text-right">
                                        <router-link
                                            class="btn btn-sm btn-primary float-left float-md-right"
                                            :to="{name:'nursery',params:{nursery:nursery.id}}"
                                        >
                                            {{ __('organization:manage_organization') }}
                                            <i
                                                aria-hidden="true"
                                                class="fa fa-arrow-circle-right ml-2"
                                            />
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <h1
                        v-if="!filtered.length && user.is_owner"
                        class="h1 tw-font-grandhotel text-center mx-auto mt-4"
                    >
                        {{ __('organization:empty_organization_message') }}
                    </h1>
                    <h1
                        v-else-if="!filtered.length && !user.is_owner"
                        class="h1 tw-font-grandhotel text-center mx-auto mt-4"
                    >
                        {{ __('organization:no_access_message') }}
                    </h1>
                </div>
            </div>
            <div
                v-else
                key="loader"
            >
                <loader custom-class="la-2x"/>
            </div>
        </transition>
        <AddOrganizationModal
            v-if="createOrganizationModal.isVisible.value"
            :modal="createOrganizationModal"
            @store:nursery="pushNursery"
        />

        <NurseryWizard
            ref="nurseryWizard"
            :user="user"
            @store-nursery="pushNursery"
        />
    </div>
</template>

<script>
    import _sortBy from 'lodash-es/sortBy';
    import _head from 'lodash-es/head';
    import _forEach from 'lodash-es/forEach';
    import route from '@/modules/legacy/libs/ziggy';
    import useApi from '@/modules/app/composables/useApi';
    import AddOrganizationModal from '@/modules/organization/components/AddOrganizationModal.vue';
    import * as Alert from '@/modules/legacy/libs/Alert';
    import NurseryWizard from '@/modules/legacy/components/OldThemes/Wizards/SimpleNursery/Wizard.vue';
    import __ from '@/modules/app/utils/i18n-facade';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';
    import useModal from '@/modules/app/composables/useModal';

    export default {
        components: {
            AddOrganizationModal,
            NurseryWizard,
        },
        props: {
            user: {
                type: Object,
                required: true,
            },
        },
        data() {
            return {
                loader: false,
                nurseries: [],
            };
        },
        computed: {
            filtered() {
                return _sortBy(this.nurseries, 'name');
            },
            createOrganizationModal() {
                return useModal();
            },
        },
        mounted() {
            if (this.$route.query.welcome === 'true') {
                Alert.success({
                    showCloseButton: true,
                    allowOutsideClick: true,
                    title: __('app:welcome'),
                    text: __('app:welcome.details'),
                    confirmButtonText: __('common:lets_go'),
                });
            }

            this.getNurseries().then(nurseries => {
                if (nurseries.length < 1) {
                    this.$refs.nurseryWizard.show();
                }
            });
        },
        methods: {
            getNurseries() {
                return new Promise((resolve, reject) => {
                    this.loader = true;
                    useApi().legacy.get(route('nurseries.index')).then(response => {
                        this.nurseries = response.data;
                        this.loader = false;
                        resolve(response.data);
                    }).catch(error => {
                        this.loader = false;
                        if (error && error.response && error.response.status === 422) {
                            _forEach(error.response.data.errors, function(value) {
                                useNotification().error(_head(value));
                            });
                        } else {
                            useNotification().error(error);
                        }
                        reject();
                    });
                });
            },
            pushNursery(e) {
                this.nurseries.push(e);
                this.user.nurseries.push(e);
                // let updateUser = _cloneDeep(this.user);
                // updateUser.nurseries.push(e);
                // this.$bus.$emit('update:user', updateUser);
            },
        },
    };
</script>
