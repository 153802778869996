<template>
    <div class="PlanningSettingsDisplayGeneral">
        <MHeading>
            <FontAwesomeIcon
                class="tw-mr-1 tw-text-blue-500"
                icon="fa fa-cog"
            />
            {{ __('common:general') }}
        </MHeading>
        <CForm>
            <CFormGroup>
                <CLabel>{{ __('planning:display_and_order_people_by_colon') }}</CLabel>
                <CButtonGroup>
                    <MButton
                        :label="__('planning:first_names')"
                        :variant="displayNameOrderBy === 'first_name' ? 'primary' : 'light'"
                        @click="displayNameOrderBy = 'first_name'"
                    />
                    <MButton
                        :label="__('planning:last_names')"
                        :variant="displayNameOrderBy === 'last_name' ? 'primary' : 'light'"
                        @click="displayNameOrderBy = 'last_name'"
                    />
                </CButtonGroup>
            </CFormGroup>
        </CForm>
    </div>
</template>


<script lang="ts">
    import {defineComponent, ref, watch} from 'vue';

    export default defineComponent({
        setup(props, {emit}) {
            const legacyKeyOrderBy = 'display:orderBy';
            const displayNameOrderBy = ref(window.localStorage.getItem(legacyKeyOrderBy) ?? 'first_name');
            watch(() => displayNameOrderBy.value, val => {
                if (val === 'last_name') {
                    window.localStorage.setItem(legacyKeyOrderBy, 'last_name');
                } else {
                    window.localStorage.removeItem(legacyKeyOrderBy);
                }
                emit('saveUserPreferences');
            });

            return {
                displayNameOrderBy,
            };
        },
    });
</script>

<style lang="scss" scoped>
    .PlanningSettingsDisplayGeneral {
        @apply tw-flex tw-flex-col tw-gap-4;
    }
</style>
