<script setup lang="ts">
    import {computed} from 'vue';
    import {get} from 'lodash-es';

    const props = withDefaults(defineProps<{
        fontSize?: string;
        showIcon?: boolean;
    }>(), {
        fontSize: 'base',
        showIcon: true,
    });

    const fontSizeValues = {
        xs: 'MErrorMessage--size-xs',
        sm: 'MErrorMessage--size-sm',
        base: 'MErrorMessage--size-base',
    };


    const baseClass = computed(() => {
        const output = [] as any[];

        output.push(get(fontSizeValues, props.fontSize));

        return output;
    });
</script>

<template>
    <div
        class="MErrorMessage"
        :class="baseClass"
    >
        <FontAwesomeIcon
            v-if="showIcon"
            icon="fad fa-fw fa-exclamation-triangle"
        />
        <slot/>
    </div>
</template>

<style lang="scss" scoped>
    .MErrorMessage {
        @apply tw-text-danger-600;
    }

    .MErrorMessage--size-xs {
        @apply tw-text-xs;
    }

    .MErrorMessage--size-sm {
        @apply tw-text-sm;
    }

    .MErrorMessage--size-base {
        @apply tw-text-base;
    }
</style>

