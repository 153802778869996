<template>
    <div>
        <p class="tw-text-gray-600 tw-text-base mb-0">
            <span v-html="__('notification:preregistration_file_uptaded_for_children')"/>
        </p>
        <p
            v-show="notification.data.content"
            class="tw-text-gray-800 tw-text-sm mb-0 mt-1"
        >
            {{ notification.data.content }}
        </p>

        <div class="mt-2">
            <router-link
                class="btn btn-light btn-sm"
                :to="to"
                @click.native="handlerEmit"
            >
                {{ __('common:see_document') }}
            </router-link>
        </div>
    </div>
</template>

<script>
    import moment from 'moment';

    export default {
        props: {
            notification: {type: Object},
        },
        computed: {
            moment() {
                return moment;
            },
            to() {
                return {
                    name: 'registrations.show', params: {
                        nursery: this.notification.data.nursery_id,
                        registration: this.notification.data.registration_id,
                    },
                };
            },
        },
        mounted() {
            //
        },
        methods: {
            handlerEmit() {
                this.$emit('closeModal');
                !this.notification.read_at ? this.notification.read_at = moment().unix() : null;
                this.$bus.$emit('update:notification', this.notification);
            },
        },
    };
</script>
