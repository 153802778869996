<template>
    <div>
        <support-meeko :ressource="selectedConfig"/>
        <div class="tw-flex tw-flex-wrap tw-gap-2 tw-items-center tw-justify-between tw-mb-8">
            <div>
                <CFormGroup v-if="selectedConfig.id === 0">
                    <CLabel>{{ __('common:name_colon') }}</CLabel>
                    <MInput
                        v-model="selectedConfig.name"
                        class="sm:tw-w-96 tw-w-64"
                        :placeholder="__('billing_invoice:configuration_name')"
                    />
                </CFormGroup>
                <CFormGroup v-else-if="nurseryConfigs.length > 1">
                    <CLabel>{{ __('billing_setting:choose_an_configuration_colon') }}</CLabel>
                    <CFormSelect
                        v-model="selectedConfig.id"
                        :options="nurseryConfigs"
                        text-path="name"
                        value-path="id"
                        @input="switchConfig"
                    />
                </CFormGroup>
                <MHeading v-else>
                    {{ __('billing_setting:configuration_colon') }} {{ selectedConfig.name }}
                </MHeading>
                <CCheckbox
                    v-if="nurseryConfigs.length > 1"
                    v-model="selectedConfig.default"
                    class="tw-mt-2"
                >
                    {{ __('billing_setting:default_configuration') }}
                </CCheckbox>
            </div>
            <div class="tw-flex tw-flex-wrap tw-items-center tw-gap-2">
                <MButton
                    v-if="selectedConfig.id"
                    @click="duplicate"
                >
                    <template #left-icons>
                        <FontAwesomeIcon icon="fa fa-copy"/>
                    </template>
                    {{ __('common:actions.duplicate') }}
                </MButton>
                <MTooltip
                    v-if="otherNurseriesConfigs && otherNurseriesConfigs.length"
                    :label="__('common:import_from_another_organization')"
                    placement="top-end"
                >
                    <MButton
                        v-if="otherNurseriesConfigs && otherNurseriesConfigs.length"
                        @click="importConfig"
                    >
                        <template #left-icons>
                            <FontAwesomeIcon icon="fa fa-download"/>
                        </template>
                        {{ __('common:actions.import') }}
                    </MButton>
                </MTooltip>
                <MButton
                    v-if="nurseryConfigs.length"
                    icon-plus
                    :label="__('billing_setting:new_configuration')"
                    variant="primary"
                    @click="addConfig"
                />
            </div>
        </div>

        <template v-if="!selectedConfig.empty">
            <div class="tw-flex tw-flex-col tw-gap-4">
                <BasicCard
                    :config="selectedConfig"
                    :nursery="nursery"
                />
                <PsuPriceCard
                    v-if="selectedConfig.funding === 'psu' || selectedConfig.funding === 'psu_cmg'"
                    :config="selectedConfig"
                    :nursery="nursery"
                />
                <NormalPriceCard
                    v-else
                    :config="selectedConfig"
                    :nursery="nursery"
                />
                <FeesCard
                    :config="selectedConfig"
                    :nursery="nursery"
                />
                <ExtraCard
                    :config="selectedConfig"
                    :nursery="nursery"
                />
            </div>


            <div class="tw-mt-8 tw-flex tw-flex-wrap tw-justify-between tw-gap-2">
                <div class="tw-flex tw-items-center tw-gap-2">
                    <MTooltip
                        v-if="selectedConfig.id"
                        :label="__('common:actions.delete')"
                        placement="top"
                    >
                        <MButton
                            variant="danger"
                            @click="destroyConfig"
                        >
                            <FontAwesomeIcon icon="fa fa-trash"/>
                        </MButton>
                    </MTooltip>
                    <MTooltip
                        v-if="selectedConfig.id"
                        :label="__('common:remove_from_this_organization')"
                        placement="top"
                    >
                        <MButton @click="detachConfig">
                            <FontAwesomeIcon icon="fas fa-link"/>
                        </MButton>
                    </MTooltip>
                </div>

                <div class="tw-flex tw-items-center tw-gap-2">
                    <MButton
                        :label="__('common:actions.reset')"
                        @click="cancel"
                    />
                    <MButton
                        v-if="selectedConfig.id"
                        :label="__('common:actions.save')"
                        :loading="loading"
                        variant="primary"
                        @click="save"
                    />
                    <MButton
                        v-else
                        :label="__('billing_setting:create_this_configuration')"
                        :loading="loading"
                        variant="primary"
                        @click="storeConfig"
                    />
                </div>
            </div>
        </template>
    </div>
</template>

<script>
    import Vue from 'vue';
    import swal from 'sweetalert2/dist/sweetalert2.js';
    import route from '@/modules/legacy/libs/ziggy';
    import useApi from '@/modules/app/composables/useApi';
    import _cloneDeep from 'lodash-es/cloneDeep';
    import _head from 'lodash-es/head';
    import _forEach from 'lodash-es/forEach';
    import _orderBy from 'lodash-es/orderBy';
    import {nursery} from '@/modules/legacy/mixins/nursery';
    import defaultConfig from '@/modules/legacy/contents/defaultConfig';
    import __ from '@/modules/app/utils/i18n-facade';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';
    import useMagicModal from '@/modules/app/composables/useMagicModal';
    import Basic from '@/modules/cashier/components/settings/RulesCards/Basic.vue';
    import Fees from '@/modules/cashier/components/settings/RulesCards/Fees.vue';
    import Extra from '@/modules/cashier/components/settings/RulesCards/Extra.vue';
    import PriceNormal from '@/modules/cashier/components/settings/RulesCards/Price/Normal.vue';
    import PricePSU from '@/modules/cashier/components/settings/RulesCards/Price/PSU.vue';

    export default {
        components: {
            BasicCard: Basic,
            FeesCard: Fees,
            ExtraCard: Extra,
            NormalPriceCard: PriceNormal,
            PsuPriceCard: PricePSU,
        },
        mixins: [nursery],
        props: {
            nursery: {
                type: Object,
                required: true,
            },
            configs: {
                type: Array,
                required: true,
            },
        },
        data() {
            return {
                loading: false,
                nurseryConfigs: [],
                otherNurseriesConfigs: [],
                selectedConfig: _cloneDeep(defaultConfig),
                savedId: null,
                presetModeOptions: [
                    {
                        text: __('common:none'),
                        value: 'free',
                    },
                    {
                        text: 'PAJE',
                        value: 'paje',
                    },
                    {
                        text: 'PSU',
                        value: 'psu',
                    },
                ],
            };
        },
        watch: {
            'selectedConfig.funding': function() {
                if (this.selectedConfig.funding === 'psu' && !this.selectedConfig.id) {
                    this.selectedConfig.billing.cycle = 'hour';
                    this.selectedConfig.smoothing.on = 'contract';
                }
            },
            'selectedConfig.id': function(val, oldVal) {
                this.savedId = oldVal ? oldVal : null;
            },
            'selectedConfig.billing.cycle': function(val) {
                if (val === 'month') {
                    this.selectedConfig.pricing.discount.on_presence = 'none';
                }
            },
        },
        mounted() {
            this.indexConfigs();
        },
        methods: {
            indexConfigs() {
                this.nurseryConfigs = [];
                this.otherNurseriesConfigs = [];

                if (this.configs) {
                    this.configs.forEach(item => {
                        const configBelongsToNursery = this.nursery.billingConfigs.find(doc => doc.id === item.id);
                        if (configBelongsToNursery) {
                            this.nurseryConfigs.push(configBelongsToNursery);
                        } else {
                            this.otherNurseriesConfigs.push(item);
                        }
                    });
                }

                this.nurseryConfigs = _orderBy(this.nurseryConfigs, 'default', 'desc');

                this.nurseryConfigs[0] ? this.selectedConfig = _cloneDeep(this.nurseryConfigs[0]) : this.selectedConfig = _cloneDeep(defaultConfig);
            },

            storeConfig() {
                if (this.loading) {
                    return;
                }

                this.loading = true;
                const data = this.selectedConfig;
                data.nursery_id = this.nursery.id;
                useApi().legacy.post(route('billingConfigs.store'), data)
                    .then(response => {
                        this.configs.push(response.data);

                        return this.attachConfig(response.data.id);
                    }).catch(error => {
                        if (error && error.response && error.response.status === 422) {
                            _forEach(error.response.data.errors, value => {
                                useNotification().error(_head(value));
                            });
                        } else {
                            useNotification().error(error);
                        }
                    }).finally(() => {
                        this.loading = false;
                    });
            },

            save() {
                swal({
                    title: __('billing_setting:save_current_configuration'),
                    text: null,
                    type: null,
                    confirmButtonClass: 'btn btn-primary mr-2',
                    confirmButtonText: __('common:actions.save'),
                    cancelButtonText: __('common:actions.cancel'),
                    input: 'text',
                    inputPlaceholder: __('billing_invoice:configuration_name'),
                    inputValue: this.selectedConfig.name,
                }).then(result => {
                    if (result.value) {
                        this.selectedConfig.name = result.value;
                        this.updateConfig();
                    }
                });
            },

            updateConfig() {
                const data = this.selectedConfig;
                data.nursery_id = this.nursery.id;
                if (data.default === null) {
                    data.default = undefined;
                }
                useApi().legacy.put(route('billingConfigs.update', {
                    billingConfig: this.selectedConfig.id,
                }), data)
                    .then(response => {
                        if (this.selectedConfig.default) {
                            this.nurseryConfigs.forEach(config => {
                                Vue.set(config, 'default', false);
                            });

                            this.nursery.billingConfigs.forEach(config => {
                                Vue.set(config, 'default', false);
                            });
                        }

                        const configToUpdate = this.configs.find(item => item.id === this.selectedConfig.id);
                        if (configToUpdate) {
                            const index = this.configs.indexOf(configToUpdate);
                            Vue.set(this.configs, index, response.data);
                        }

                        const nurseryConfigToUpdate = this.nurseryConfigs.find(item => item.id === this.selectedConfig.id);
                        if (nurseryConfigToUpdate) {
                            const index = this.nurseryConfigs.indexOf(nurseryConfigToUpdate);
                            Vue.set(this.nurseryConfigs, index, response.data);
                        }

                        const configBelongsToNursery = this.nursery.billingConfigs.find(item => item.id === this.selectedConfig.id);
                        if (configBelongsToNursery) {
                            const index = this.nursery.billingConfigs.indexOf(configBelongsToNursery);
                            Vue.set(this.nursery.billingConfigs, index, response.data);
                        }

                        useNotification().success(__('billing_setting:configuration_updated_successfully'));
                    }).catch(error => {
                        if (error && error.response && error.response.status === 422) {
                            _forEach(error.response.data.errors, value => {
                                useNotification().error(_head(value));
                            });
                        } else {
                            useNotification().error(error);
                        }
                    });
            },

            attachConfig(configId) {
                return useApi().legacy.post(route('nurseries.billingConfigs.attach', {
                    nurseries: this.nursery.id,
                    billingConfig: configId,
                })).then(response => {
                    this.nurseryConfigs.push(response.data);
                    this.nursery.billingConfigs.push(response.data);
                    const configToRemove = this.otherNurseriesConfigs.find(item => item.id === response.data.id);
                    if (configToRemove) {
                        const index = this.otherNurseriesConfigs.indexOf(configToRemove);
                        this.otherNurseriesConfigs.splice(index, 1);
                    }
                    this.selectedConfig = _cloneDeep(response.data);
                    useNotification().success(__('billing_setting:configuration_added_successfully'));
                }).catch(error => {
                    if (error && error.response && error.response.status === 422) {
                        _forEach(error.response.data.errors, value => {
                            useNotification().error(_head(value));
                        });
                    } else {
                        useNotification().error(error);
                    }
                });
            },

            detachConfig() {
                useMagicModal().confirmationModal({
                    title: __('billing_setting:detach_configuration_confirmation'),
                    text: __('billing_setting:configuration_will_no_longer_visible_on_this_organization'),
                    confirmButtonText: __('common:actions.detach'),
                    onConfirm: async () => {
                        useApi().legacy.post(route('nurseries.billingConfigs.detach', {
                            nurseries: this.nursery.id,
                            billingConfig: this.selectedConfig.id,
                        })).then(response => {
                            const configToRemove = this.nurseryConfigs.find(item => item.id === response.data.id);
                            if (configToRemove) {
                                const index = this.nurseryConfigs.indexOf(configToRemove);
                                this.nurseryConfigs.splice(index, 1);
                            }

                            const configToRemoveFromNursery = this.nursery.billingConfigs.find(item => item.id === response.data.id);
                            if (configToRemoveFromNursery) {
                                const index = this.nursery.billingConfigs.indexOf(configToRemoveFromNursery);
                                this.nursery.billingConfigs.splice(index, 1);
                            }

                            this.otherNurseriesConfigs.push(response.data);
                            this.nurseryConfigs[0] ? this.selectedConfig = _cloneDeep(this.nurseryConfigs[0]) : this.selectedConfig = _cloneDeep(defaultConfig);
                            useNotification().success(__('billing_setting:configuration_took_off_successfully'));
                        }).catch(error => {
                            if (error && error.response && error.response.status === 422) {
                                _forEach(error.response.data.errors, value => {
                                    useNotification().error(_head(value));
                                });
                            } else {
                                useNotification().error(error);
                            }
                        });
                    },
                });
            },

            destroyConfig() {
                useMagicModal().deleteConfirmationModal({
                    title: __('billing_setting:configuration_delete_confirmation'),
                    text: __('billing_setting:configuration_name_to_delete', {name: this.selectedConfig.name}),
                    onConfirm: async () => {
                        useApi().legacy.delete(route('billingConfigs.destroy', {
                            billingConfig: this.selectedConfig.id,
                        })).then(() => {
                            const configToDelete = this.nurseryConfigs.find(item => item.id === this.selectedConfig.id);
                            if (configToDelete) {
                                const index = this.nurseryConfigs.indexOf(configToDelete);
                                this.nurseryConfigs.splice(index, 1);
                            }

                            const configToDeleteFromGlobal = this.configs.find(item => item.id === this.selectedConfig.id);
                            if (configToDeleteFromGlobal) {
                                const index = this.configs.indexOf(configToDeleteFromGlobal);
                                this.configs.splice(index, 1);
                            }

                            const configBelongsToNursery = this.nursery.billingConfigs.find(item => item.id === this.selectedConfig.id);
                            if (configBelongsToNursery) {
                                const index = this.nursery.billingConfigs.indexOf(configBelongsToNursery);
                                this.nursery.billingConfigs.splice(index, 1);
                            }

                            useNotification().success(__('billing_setting:configuration_deleted_successfully'));
                            this.nurseryConfigs[0] ? this.selectedConfig = _cloneDeep(this.nurseryConfigs[0]) : this.selectedConfig = _cloneDeep(defaultConfig);
                        }).catch(error => {
                            if (error && error.response && error.response.status === 422) {
                                _forEach(error.response.data.errors, value => {
                                    useNotification().error(_head(value));
                                });
                            } else {
                                useNotification().error(error);
                            }
                        });
                    },
                });
            },

            duplicate() {
                const newConfig = _cloneDeep(this.selectedConfig);

                swal({
                    title: __('billing_setting:duplicate_configuration_confirmation'),
                    text: null,
                    type: null,
                    confirmButtonClass: 'btn btn-info mr-2',
                    confirmButtonText: __('common:actions.duplicate'),
                    cancelButtonText: __('common:actions.cancel'),
                    input: 'text',
                    inputPlaceholder: __('billing_invoice:configuration_name'),
                    inputValue: __('billing_setting:configuration_name_copy', {name: this.selectedConfig.name}),
                }).then(result => {
                    if (result.value) {
                        newConfig.id = 0;
                        newConfig.name = result.value;
                        this.selectedConfig = _cloneDeep(newConfig);
                    }
                });
            },

            addConfig() {
                swal({
                    title: __('billing_setting:new_configuration'),
                    text: __('billing_setting:what_is_the_name_of_this_configuration'),
                    type: null,
                    confirmButtonClass: 'btn btn-info mr-2',
                    confirmButtonText: __('common:actions.validate'),
                    cancelButtonText: __('common:actions.cancel'),
                    input: 'text',
                }).then(result => {
                    if (result.value) {
                        const newConfig = _cloneDeep(defaultConfig);
                        newConfig.name = result.value;
                        this.selectedConfig = newConfig;
                    }
                });
            },

            importConfig() {
                const options = [];
                this.otherNurseriesConfigs.forEach(item => {
                    item.id ? options[item.id] = item.name : null;
                });

                swal({
                    title: __('billing_setting:use_configuration_of_another_organization'),
                    text: null,
                    type: null,
                    confirmButtonClass: 'btn btn-info mr-2',
                    confirmButtonText: __('common:actions.validate'),
                    cancelButtonText: __('common:actions.cancel'),
                    input: 'select',
                    inputOptions: options,
                }).then(result => {
                    if (result.value) {
                        this.attachConfig(result.value);
                    }
                });
            },

            switchConfig(val) {
                swal({
                    title: __('billing_setting:update_configuration_confirmation'),
                    text: __('billing_setting:unsaved_settings_of_the_configuration_will_be_lost'),
                    type: 'warning',
                    confirmButtonClass: 'btn btn-info mr-2',
                    confirmButtonText: __('common:actions.confirm'),
                    cancelButtonText: __('common:actions.cancel'),
                }).then(result => {
                    if (result.value) {
                        this.selectedConfig = _cloneDeep(this.nurseryConfigs.find(item => item.id === val));
                    } else {
                        this.selectedConfig.id = this.savedId;
                    }
                });
            },

            cancel() {
                useMagicModal().confirmationModal({
                    title: __('billing_setting:reset_modifications_confirmation'),
                    text: __('billing_setting:unsaved_settings_will_be_lost'),
                    confirmButtonText: __('common:actions.reset'),
                    onConfirm: async () => {
                        if (this.selectedConfig.id) {
                            const oldConfig = this.nurseryConfigs.find(item => item.id === this.selectedConfig.id);
                            if (oldConfig) {
                                this.selectedConfig = _cloneDeep(oldConfig);
                            }
                        } else {
                            this.selectedConfig = this.nurseryConfigs.length ? _cloneDeep(this.nurseryConfigs[0]) : _cloneDeep(
                                defaultConfig);
                        }
                    },
                });
            },
        },
    };
</script>
