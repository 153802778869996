import type DocumentModel from '@/modules/document/models/DocumentModel';
import DocumentPivot from '@/modules/document/models/DocumentPivot';
import type {Contracts} from '@meekohq/lumos';


export default async function attachResource(document: DocumentModel, resourceId: string, resourceType: string, options?: Contracts.Database.OptionsType) {
    const newPivot = new DocumentPivot();
    newPivot.attributes.account_id = document.attributes.account_id;
    newPivot.attributes.document_id = document.id;
    newPivot.attributes.resource_id = resourceId;
    newPivot.attributes.resource_type = resourceType;

    await newPivot.save(options);

    document.resources().value().push(newPivot);

    return newPivot;
}
