<template>
    <WidgetPortal>
        <template #clickable="{toggle}">
            <CButton
                class="tw-relative"
                round
                zoom
                @click.stop.prevent="onClick(toggle)"
            >
                <FontAwesomeIcon
                    class="tw-text-yellow-600"
                    icon="fas fa-bell"
                />
                <div
                    v-if="unread"
                    class="tw-absolute tw--top-2 tw--right-2"
                >
                    <MNotificationBadge :count="unread"/>
                </div>
            </CButton>
        </template>
        <template #default="{toggle}">
            <NotificationsWidget
                class="NotificationsWidgetToggle__widget"
                :nursery="nursery"
                :user="user"
                @close="toggle"
            />
        </template>
    </WidgetPortal>
</template>

<script>
    import route from '@/modules/legacy/libs/ziggy';
    import useApi from '@/modules/app/composables/useApi';
    import moment from 'moment';
    import MNotificationBadge from '@/modules/meeko-ui/components/MNotificationBadge.vue';
    import WidgetPortal from '@/modules/app/components/atoms/WidgetPortal.vue';
    import NotificationsWidget from '@/modules/notification/components/NotificationsWidget.vue';

    export default {
        components: {NotificationsWidget, WidgetPortal, MNotificationBadge},
        props: {
            user: {
                type: Object,
            },
            nursery: {type: Object},
        },
        data: () => ({
            unread: 0,
        }),
        computed: {
            moment() {
                return moment;
            },
        },
        mounted() {
            this.getNotifications();

            this.$bus.$on('create:notification', () => {
                this.unread += 1;
            });

            this.$bus.$on('markAllAsRead', () => {
                this.getNotifications();
            });
        },
        beforeDestroy() {
            this.$bus.$off('markAllAsRead');
            this.$bus.$off('create:notification');
        },
        methods: {
            getNotifications() {
                useApi().legacy.get(route('notifications.index'), {
                    params: {
                        page: 1,
                        types: [
                            'message-request',
                            'occasional-request',
                            'absence-request',
                            'late-request',
                            'invoice-unpaid',
                            'presence-unclocked',
                            'staff-presence-unclocked',
                            'new-registration-form',
                            'new-document', 'new-post',
                            'throttle-login',
                            'updated-document',
                            'updated-registration-form',
                            'task-registration-reminder',
                            'task-vaccine-reminder',
                            'meeting',
                        ],
                    },
                }).then(response => {
                    this.unread = response.data.data.filter(notif => notif.read_at == null).length;
                });
            },
            markAsRead() {
                this.unread = 0;
            },
            onClick(toggle) {
                toggle();
                this.markAsRead();
            },
        },
    };
</script>

<style lang="scss" scoped>
    .NotificationsWidgetToggle__widget {
        @apply tw-flex tw-flex-col tw-w-full sm:tw-w-96;
    }
</style>
