<template>
    <div>
        <div class="tw-mb-4">
            <KidsPlaningFilters
                :contract-states="contractStates"
                :nursery="nursery"
                :selected-group="selectedGroup"
                @contract:updated="contractStates = $event"
                @group:updated="selectedGroup = $event"
                @tags:updated="selectedTags = $event"
            />

            <div class="tw-mt-3 tw-flex tw-flex-wrap tw-gap-2">
                <MButton @click="toggleAll(true)">
                    <template #left-icons>
                        <FontAwesomeIcon
                            class="tw-text-primary-500"
                            icon="fas fa-check-circle"
                        />
                    </template>
                    {{ __('common:actions.select_all') }}
                </MButton>
                <MButton @click="toggleAll(false)">
                    <template #left-icons>
                        <FontAwesomeIcon
                            class="tw-text-danger-500"
                            icon="fas fa-times-circle"
                        />
                    </template>
                    {{ __('common:actions.unselect_all') }}
                </MButton>
            </div>
        </div>

        <div v-if="!loading">
            <template v-if="kids.length">
                <div class="row">
                    <div
                        v-for="(kid, i) in kids"
                        :key="'kid' + i"
                        class="col-6 col-lg-4"
                    >
                        <div
                            class="tw-border tw-rounded-lg tw-p-2 tw-mb-3 pointer zoom-small"
                            :class="[isToggled(kid) ? 'tw-bg-primary-500 tw-border-primary-500' : 'tw-bg-white']"
                            @click="toggleKid(kid)"
                        >
                            <div class="row">
                                <div class="col-4">
                                    <LegacyKidAvatar
                                        class="tw-w-12"
                                        :kid="kid"
                                    />
                                </div>
                                <div
                                    class="col-8 align-self-center pl-sm-1 tw-text-left"
                                    :class="[{
                                        'tw-text-boy': kid.gender === 'male',
                                        'tw-text-girl': kid.gender === 'female',
                                        '!tw-text-white': isToggled(kid),
                                    }]"
                                >
                                    {{ $fullName(kid) }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    v-if="total > perPage"
                    class="tw-flex tw-justify-center tw-mt-4"
                >
                    <MPagination
                        :paginator="paginator"
                        @navigate="currentPage = $event"
                    />
                </div>
            </template>
            <h4
                v-else
                class="h4 text-center"
            >
                {{ __('common:no_kids') }}
            </h4>
        </div>

        <loader
            v-else
            light="true"
            shadow="false"
        />
    </div>
</template>

<script>
    import route from '@/modules/legacy/libs/ziggy';
    import useApi from '@/modules/app/composables/useApi';
    import KidsPlaningFilters from '@/modules/family/components/kid/Index/KidsPlaningFilters.vue';
    import _debounce from 'lodash-es/debounce';
    import _head from 'lodash-es/head';
    import _forEach from 'lodash-es/forEach';
    import _cloneDeep from 'lodash-es/cloneDeep';
    import LegacyKidAvatar from '@/modules/app/components/atoms/avatars/LegacyKidAvatar.vue';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';
    import MPagination from '@/modules/meeko-ui/components/MPagination.vue';
    import {url} from '@/modules/legacy/mixins/url';
    import {LengthAwarePaginator} from '@meekohq/lumos';

    export default {
        components: {
            MPagination,
            LegacyKidAvatar,
            KidsPlaningFilters,
        },
        mixins: [url],
        props: {
            nursery: {},
            selectedKids: {},
        },
        data() {
            return {
                loading: true,
                kids: [],
                selectedKid: null,
                currentPage: 1,
                total: 0,
                perPage: 20,
                search: null,
                contractStates: ['current'],
                selectedGroup: null,
                selectedTags: null,
                paginator: undefined,
            };
        },
        watch: {
            currentPage() {
                this.getKids();
            },
            contractStates() {
                this.currentPage = 1;
                this.getKids();
            },
            selectedGroup() {
                this.currentPage = 1;
                this.getKids();
            },
            selectedTags() {
                this.currentPage = 1;
                this.getKids();
            },
            search: _debounce(function() {
                this.currentPage = 1;
                this.getKids();
            }, 100),
            selectedKid(val) {
                this.$emit('kidChanged', val);
            },
            paginator() {
                this.pushQueryString('p', this.paginator.currentPage());
            },
        },
        mounted() {
            this.getKids();
        },
        methods: {
            getKids() {
                this.loading = true;

                let tags = undefined;
                if (this.selectedTags) {
                    tags = this.selectedTags.length ? this.selectedTags : '';
                }

                useApi().legacy.get(route('kids.index'), {
                    params: {
                        nursery_id: this.nursery.id,
                        contract_states: Array.isArray(this.contractStates)
                            ? this.contractStates
                            : undefined,
                        page: this.currentPage,
                        per_page: this.perPage,
                        search: this.search,
                        group_id: this.selectedGroup,
                        tags,
                        order_by: window.localStorage.getItem('display:orderBy') ? window.localStorage.getItem('display:orderBy') : 'first_name',
                    },
                })
                    .then(response => {
                        this.kids = response.data.data;
                        this.total = parseInt(response.data.total);
                        this.perPage = parseInt(response.data.per_page);
                        this.paginator = new LengthAwarePaginator(
                            response.data.data,
                            parseInt(response.data.total),
                            parseInt(response.data.per_page),
                            parseInt(response.data.current_page),
                            parseInt(response.data.last_page),
                        );
                        this.loading = false;
                    })
                    .catch(error => {
                        if (error.response && error.response.status === 422) {
                            _forEach(error.response.data.errors, value => {
                                useNotification().error(_head(value));
                            });
                        } else {
                            useNotification().error(error);
                        }
                    });
            },
            toggleAll(selectAll) {
                const kids = _cloneDeep(this.selectedKids);
                this.kids.forEach(kid => {
                    const findKid = kids.find(item => item.id === kid.id);
                    if (selectAll) {
                        !findKid ? kids.push(kid) : null;
                    } else {
                        const index = kids.indexOf(findKid);
                        kids.splice(index, 1);
                    }
                });
                this.$emit('update:selectedKids', kids);
            },
            toggleKid(kid) {
                const hasKid = this.selectedKids.find(item => item.id === kid.id);
                if (hasKid) {
                    const index = this.selectedKids.indexOf(hasKid);
                    this.selectedKids.splice(index, 1);
                } else {
                    this.selectedKids.push(kid);
                }
            },
            isToggled(kid) {
                return this.selectedKids.find(item => item.id === kid.id);
            },
        },
    };
</script>
