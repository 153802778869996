<template>
    <textarea-autosize
        v-model="editValue"
        :class="computedClass"
        :disabled="disabled"
        :min-height="50"
        :placeholder="placeholder"
        :readonly="readonly"
    />
</template>

<script>
    import _join from 'lodash-es/join';
    import _cloneDeep from 'lodash-es/cloneDeep';

    export default {
        props: {
            value: {
                default: null,
            },
            placeholder: {
                type: String,
                required: false,
            },
            size: {
                type: String,
                default: null,
                required: false,
            },
            readonly: {
                type: Boolean,
                default: false,
                required: false,
            },
            disabled: {
                type: Boolean,
                default: false,
                required: false,
            },
        },
        data: () => ({
            editValue: null,
        }),
        computed: {
            computedClass() {
                const array = [];

                array.push('c-form-control');

                switch (this.size) {
                    case 'sm':
                        array.push('c-form-control-sm');
                        break;
                    case 'lg':
                        array.push('c-form-control-lg');
                        break;
                }

                return _join(array, ' ');
            },
        },
        watch: {
            value(value) {
                this.editValue = _cloneDeep(value);
            },
        },
        mounted() {
            this.editValue = _cloneDeep(this.value);
        },
        methods: {
            onChange() {
                // this.$emit('input', event.target.value);
            },
            validate() {
                this.$emit('input', this.editValue);
            },
            undo() {
                this.editValue = _cloneDeep(this.value);
            },
        },
    };
</script>

<style
    lang="scss"
    scoped
>
    .c-form-control {
        @apply tw-shadow tw-appearance-none tw-border tw-border-gray-300 tw-rounded-lg tw-w-full tw-text-gray-800;
        @apply tw-py-2 tw-px-3;

        &:not([readonly]):not([disabled]):focus {
            @apply tw-border-blue-500 tw-outline-none tw-ring;
        }

        &:read-only {
            @apply tw-bg-gray-200 tw-text-gray-600;
        }

        &:disabled {
            @apply tw-bg-gray-200 tw-text-gray-600 tw-cursor-default;
        }

        &.c-form-control-sm {
            @apply tw-text-sm tw-py-1 tw-px-2;
        }

        &.c-form-control-lg {
            @apply tw-text-lg;
        }

        &.is-invalid {
            @apply tw-border-red-500;
            &:not([readonly]):not([disabled]):focus {
                @apply tw-border-red-500 tw-ring tw-ring-red-500 tw-ring-opacity-50;
            }
        }
    }
</style>
