<template>
    <CModal
        ref="modal"
        :header-title="__('billing_customer:delete_customer_account')"
        :hide-header-fullscreen="true"
        size="md"
        visible
        @hidden="$emit('hidden')"
    >
        <CAlert
            v-if="invoiceStillExistError"
            class="tw-mb-4"
            variant="danger"
        >
            <template #icon>
                <i class="fas fa-exclamation-circle"/>
            </template>
            <template #title>
                {{ __('billing_customer:errors.unable_delete_customer_account') }}
            </template>
            {{ __('billing_customer:errors.unable_delete_customer_account_as_it_is_linked_to_invoice_or_payment') }}
        </CAlert>
        <h1 class="h1 tw-text-lg tw-text-red-500 tw-font-semibold">
            {{ __('common:warning') }}
        </h1>
        <CParagraph>
            {{ __('billing_customer:you_cannot_delete_a_customer_account_with_billing_issues') }}
        </CParagraph>
        <CParagraph>
            {{ __('billing_customer:pro_forma_invoices_will_be_deleted_and_contracts_will_be_detached') }}
        </CParagraph>
        <CFormGroup>
            <CLabel>{{ __('billing_customer:to_confirm_please_enter_the_customer_account_name') }} ({{ customerModel.attributes.name }})</CLabel>
            <CInput v-model="confirm"/>
        </CFormGroup>
        <template #footer="{closeDialog}">
            <CButton
                class="tw-ml-auto"
                @click="closeDialog"
            >
                {{ __('common:actions.cancel') }}
            </CButton>
            <CButton
                class="tw-ml-2"
                :disabled="confirm.toLowerCase() !== customerModel.attributes.name.toLowerCase()"
                :loading="loading"
                variant="danger"
                @click="deleteModel()"
            >
                {{ __('common:actions.delete') }}
            </CButton>
        </template>
    </CModal>
</template>

<script lang="ts">
    import type CustomerModel from '@/modules/cashier/models/CustomerModel';
    import {catcher, ForbiddenError} from '@meekohq/lumos';
    import type {PropType} from 'vue';
    import {defineComponent, ref} from 'vue';
    import mitt from 'mitt';

    export default defineComponent({
        props: {
            customerModel: {
                type: Object as PropType<CustomerModel>,
                required: true,
            },
        },
        setup(props, {emit}) {
            const loading = ref(false);
            const confirm = ref('');
            const invoiceStillExistError = ref(false);
            const bus = mitt<{ hide: void }>();

            function deleteModel() {
                loading.value = true;

                props.customerModel
                    .delete()
                    .then(() => {
                        emit('deleted', props.customerModel);
                        bus.emit('hide');
                    })
                    .catch(errors => {
                        catcher().on(ForbiddenError, () => {
                            invoiceStillExistError.value = true;
                        }).catch(errors);
                    }).finally(() => {
                        loading.value = false;
                    });
            }

            return {
                loading,
                confirm,
                invoiceStillExistError,
                deleteModel,
            };
        },
    });

</script>
