<template>
    <CModal
        ref="modal"
        :header-title="__('hr:update_job')"
        @show="onShow"
    >
        <JobForm
            ref="form"
            :job-model="mirror.value"
            @loading="loader.set($event)"
            @saved="onJobUpdated"
        />
        <template #footer>
            <div class="tw-w-full">
                <CInline align-x="between">
                    <div>
                        <CButton
                            v-if=" isContractsExist && !jobModel.attributes.archived_at"
                            class="tw-ml-auto"
                            :disabled="loader.isLoading()"
                            :loading="loader.isLoading('archiveJob')"
                            variant="orange"
                            @click="archiveJob(mirror.value)"
                        >
                            <CTooltip placement="left">
                                <i
                                    aria-hidden="true"
                                    class="fas fa-archive"
                                />
                                <template #content>
                                    <span>
                                        {{ __('common:actions.archive') }}
                                    </span>
                                </template>
                            </CTooltip>
                        </CButton>
                        <CButton
                            v-else-if="jobModel.attributes.archived_at"
                            class="tw-ml-auto"
                            :disabled="loader.isLoading()"
                            :loading="loader.isLoading('unarchiveJob')"
                            variant="primary"
                            @click="unarchiveJob(mirror.value)"
                        >
                            {{ __('common:actions.unarchive') }}
                        </CButton>
                        <CButton
                            v-else
                            class="tw-ml-auto"
                            :disabled="loader.isLoading() || loader.isLoading('detachJob')"
                            :loading="loader.isLoading('deleteJob')"
                            variant="danger"
                            @click="deleteJob(jobModel)"
                        >
                            <i
                                aria-hidden="true"
                                class="fas fa-trash-alt"
                            />
                        </CButton>
                        <CButton
                            class="tw-ml-2"
                            :disabled="loader.isLoading() || loader.isLoading('deleteJob')"
                            :loading="loader.isLoading('detachJob')"
                            @click="detachJob(jobModel)"
                        >
                            <CTooltip>
                                <i
                                    aria-hidden="true"
                                    class="fas fa-unlink"
                                />
                                <template #content>
                                    <span>
                                        {{ __('common:actions.detach') }}
                                    </span>
                                </template>
                            </CTooltip>
                        </CButton>
                    </div>
                    <div>
                        <CButton
                            class="tw-ml-auto"
                            @click="$refs.modal.hide()"
                        >
                            {{ __('common:actions.close') }}
                        </CButton>
                        <CButton
                            class="tw-ml-2"
                            :disabled="loader.isLoading('deleteJob') || loader.isLoading('detachJob') || loader.isLoading('unarchiveJob') || loader.isLoading('archiveJob')"
                            :loading="loader.isLoading()"
                            variant="primary"
                            @click="$refs.form.save()"
                        >
                            {{ __('common:actions.save') }}
                        </CButton>
                    </div>
                </CInline>
            </div>
        </template>
    </CModal>
</template>

<script>
    import Loader from '@/modules/legacy/helpers/loader.helper';
    import Mirror from '@/modules/legacy/helpers/mirror.helper';
    import JobForm from '@/modules/human-resources/components/job/Forms/JobForm.vue';
    import moment from 'moment';
    import swal from 'sweetalert2/dist/sweetalert2.js';
    import JobsOrganizationsPivot from '@/modules/human-resources/models/JobOrganizationPivot';
    import {collect} from '@meekohq/lumos';
    import Manager from '@/modules/legacy/store/manager.store';
    import __ from '@/modules/app/utils/i18n-facade';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';

    export default {
        components: {
            JobForm,
        },

        props: {
            jobModel: {
                required: true,
            },
            isContractsExist: {
                required: true,
            },
        },

        data() {
            return {
                loader: new Loader(),
                mirror: new Mirror(this.jobModel),
                pivotsCollection: collect(),
                manager: Manager,
            };
        },

        watch: {
            jobModel: {
                deep: true,
                handler(jobModel) {
                    this.mirror = new Mirror(jobModel);
                },
            },
        },

        methods: {
            onShow() {
                JobsOrganizationsPivot.query()
                    .where('organization_id', this.manager.activeOrganization.id)
                    .get()
                    .then(response => this.pivotsCollection = response);
            },

            onJobUpdated() {
                this.hide();
                useNotification().success(__('hr:job_updated_successfully'));
                this.mirror.commit();
            },

            async deleteJob(jobModel) {
                const confirm = await swal({
                    title: __('hr:delete_job_confirmation'),
                    text: __('hr:job_will_be_deleted_from_all_organizations'),
                    type: 'warning',
                    confirmButtonClass: 'btn btn-danger mr-2',
                    confirmButtonText: __('common:actions.delete'),
                    cancelButtonText: __('common:actions.cancel'),
                });

                if (confirm.value) {
                    this.loader.start('deleteJob');
                    try {
                        await jobModel.delete();
                        this.$emit('jobDeleted', jobModel);
                        this.loader.stop('deleteJob');
                        useNotification().success(__('hr:job_deleted_successfully'));
                    } catch (e) {
                        this.loader.stop('deleteJob');
                        useNotification().error(__('hr:errors.job_delete'));
                    }
                }
            },

            async detachJob(jobModel) {
                this.loader.start('detachJob');
                try {
                    const pivot = this.pivotsCollection.first(item => item.attributes.job_id === jobModel.id);
                    await pivot.delete();

                    this.$emit('pivotDeleted', pivot);
                    this.loader.stop('detachJob');
                    this.hide();
                    useNotification().success(__('hr:job_detached_successfully'));
                } catch (e) {
                    this.loader.stop('detachJob');
                    useNotification().error(__('hr:errors.job_detache'));
                }
            },

            async archiveJob(jobModel) {
                this.loader.start('archiveJob');
                try {
                    jobModel.attributes.archived_at = moment().toISOString(true);
                    this.save(jobModel);
                    this.loader.stop('archiveJob');
                    useNotification().success(__('hr:job_archived_successfully'));
                } catch (e) {
                    this.loader.stop('archiveJob');
                    useNotification().error(__('hr:errors.job_archive'));
                }
            },

            async unarchiveJob(jobModel) {
                this.loader.start('unarchiveJob');
                try {
                    jobModel.attributes.archived_at = null;
                    this.save(jobModel);
                    this.loader.stop('unarchiveJob');
                    useNotification().success(__('hr:job_unarchived_successfully'));
                } catch (e) {
                    this.loader.stop('unarchiveJob');
                    useNotification().error(__('hr:errors.job_unarchive'));
                }
            },

            save(jobModel) {
                this.loader.start();
                try {
                    jobModel.save();
                    this.onJobUpdated();
                    this.loader.stop();
                } catch (e) {
                    this.loader.stop();
                }
            },

            show() {
                this.$refs.modal.show();
            },

            hide() {
                this.$refs.modal.hide();
            },
        },
    };
</script>

