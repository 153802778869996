<script setup lang="ts">

    import {computed, ref} from 'vue';

    const props = defineProps({
        year: {
            type: Number,
            required: true,
        },
    });

    const emit = defineEmits<{
        (e: 'selected', value: number): void
    }>();

    const startFromYear = ref(props.year);

    const yearsGrid = computed(() => {
        return [
            startFromYear.value + 2,
            startFromYear.value + 1,
            startFromYear.value,
            startFromYear.value - 1,
            startFromYear.value - 2,
        ] as number[];
    });

    function previous() {
        startFromYear.value -= 5;
    }

    function next() {
        startFromYear.value += 5;
    }
</script>

<template>
    <div class="MDatePickerYearPicker">
        <button
            class="MDatePickerYearPicker__button"
            @click="next"
        >
            <FontAwesomeIcon icon="fa fa-chevron-up"/>
        </button>
        <div class="MDatePickerYearPicker__years">
            <button
                v-for="y in yearsGrid"
                :key="y"
                class="MDatePickerYearPicker__button"
                :class="{
                    'MDatePickerYearPicker__button--active': y === year,
                }"
                @click="emit('selected', y)"
            >
                {{ y }}
            </button>
        </div>
        <button
            class="MDatePickerYearPicker__button"
            @click="previous"
        >
            <FontAwesomeIcon icon="fa fa-chevron-down"/>
        </button>
    </div>
</template>

<style scoped lang="scss">
    .MDatePickerYearPicker {
        @apply tw-flex tw-flex-col tw-items-center;
    }

    .MDatePickerYearPicker__years {
        @apply tw-flex-1 tw-w-full tw-flex tw-flex-col tw-gap-1;
    }

    .MDatePickerYearPicker__button {
        @apply tw-p-2 tw-rounded tw-text-primary-500 tw-font-display tw-cursor-pointer tw-select-none;
        @apply tw-transition-all hover:tw-bg-primary-100 hover:tw-text-primary-600;
        @apply tw-capitalize tw-w-full;
        @apply tw-flex tw-justify-center;
    }

    .MDatePickerYearPicker__button--active {
        @apply tw-bg-primary-100 tw-text-primary-600;
    }
</style>
