import type {Ref} from 'vue';
import {computed, ref, watch} from 'vue';
import {collect, now} from '@meekohq/lumos';
import __ from '@/modules/app/utils/i18n-facade';
import useUserFilters, {OutputType} from '@/modules/app/composables/useUserFilters';
import useManager from '@/modules/app/composables/useManager';
import CustomerPersonValue from '@/modules/cashier/utils/core/customer/CustomerPersonValue';
import DocTemplateTypeValues from '@/modules/organization/utils/Values/DocTemplateTypeValues';
import type {PeriodFilterType} from '@/modules/legacy/components/Filter/PeriodFilterType';
import type {CustomerListFilters} from '@/modules/cashier/composables/tax/tax-certificate/useCustomersList';
import type {
    SendTaxCertificatesParameters,
} from '@/modules/cashier/composables/tax/tax-certificate/useSendTaxCertificates';
import TenantModel from '@/modules/cashier/models/TenantModel';
import DocTemplateModel from '@/modules/organization/models/DocTemplateModel';

export type CustomersFilters = CustomerListFilters
& Omit<SendTaxCertificatesParameters, 'selectedCustomers' | 'organization' | 'email'>;

interface CustomersFiltersReturn {
    isLoading: Ref<boolean>;
    waitForFiltersToBeLoaded: () => Promise<void>;
    filters: CustomersFilters;
    customerPersonSelectorOptions: Array<{ value: string; text: string }>;
    initFiltersValues: () => Promise<void>;
    initWatchers: () => void;
}

/**
 * Composable to manage the filters of the tax certificates send component
 *
 * @returns {CustomersFiltersReturn}
 */
export default function(): CustomersFiltersReturn {
    const {activeOrganization} = useManager();

    const isLoading = ref(true);

    // Tenants selector
    const tenantsFilter = ref<TenantModel[]>([]);

    // Get all the organizations of the selected tenants
    const organizationsByTenantsFilter = computed(() => collect(tenantsFilter.value)
        .flatMap(tenant => tenant.organizations().value().pluck('attributes.id')).unique().toArray(),
    );

    // Doc template selector
    const docTemplateFilter = ref<DocTemplateModel>();

    // Customer person selector
    const customerPersonSelectorOptions = [
        {value: CustomerPersonValue.natural, text: __('billing_customer:entity_natural')},
        {value: CustomerPersonValue.legal, text: __('billing_customer:entity_legal')},
    ];

    const {
        selectedOptions: customerPersonFilter,
        waitForIsLoading: waitForIsLoadingCustomerPerson,
    } = useUserFilters<string>('tax-certificates:filters:customer-status', OutputType.value, CustomerPersonValue.natural);

    // Period selector
    const {
        selectedOptions: periodFilter,
        waitForIsLoading: waitForIsLoadingPeriod,
    } = useUserFilters<PeriodFilterType>('tax-certificates:filters:period', OutputType.value, {
        from: now().subYears(1).startOfYear().toISOString(),
        to: now().subYears(1).endOfYear().toISOString(),
    });

    /**
     * Wait for all the filters to be loaded
     */
    async function waitForFiltersToBeLoaded() {
        await Promise.all([
            waitForIsLoadingPeriod(),
            waitForIsLoadingCustomerPerson(),
        ]);

        isLoading.value = false;
    }

    /**
     * Initialize the filters values
     */
    async function initFiltersValues() {
        isLoading.value = true;

        // Preselect the first tenant of the organization
        const firstTenantOfOrganization = await getFirstTenantOfOrganization(activeOrganization.value?.getKey());
        (tenantsFilter.value as TenantModel[]).push(firstTenantOfOrganization);

        // Preselect the first doc template of the organization
        docTemplateFilter.value = await getFirstDocTemplateOfOrganization(activeOrganization.value?.getKey());

        isLoading.value = false;
    }

    /**
     * Get the first tenant of the organization
     *
     * @param {string} organizationId
     */
    async function getFirstTenantOfOrganization(organizationId: string) {
        return TenantModel.query()
            .whereHas(new TenantModel().organizations(), query => query.where('id', organizationId))
            .with(new TenantModel().organizations())
            .first();
    }

    /**
     * Get the first doc template of the organization
     *
     * @param {string} organizationId
     */
    async function getFirstDocTemplateOfOrganization(organizationId: string) {
        return DocTemplateModel.query()
            .whereHas('organizations', query => query.where('nursery_id', organizationId))
            .where('type', DocTemplateTypeValues.taxCertificate)
            .first();
    }

    /**
     * Initialize the watchers
     */
    function initWatchers() {
        watch(tenantsFilter, () => {
            // If the user removes all the tenants, remove the doc template
            if (organizationsByTenantsFilter.value.length === 0) {
                docTemplateFilter.value = undefined;
            }
        });
    }

    const filters: CustomersFilters = {
        period: periodFilter as Ref<{ from: string; to: string; }>,
        docTemplate: docTemplateFilter as Ref<DocTemplateModel>,
        customerPerson: customerPersonFilter as Ref<CustomerPersonValue>,
        tenants: tenantsFilter as Ref<TenantModel[]>,
    };

    return {
        customerPersonSelectorOptions,
        filters,
        waitForFiltersToBeLoaded,
        isLoading,
        initFiltersValues,
        initWatchers,
    };
}
