<template>
    <CForm @submit.prevent="save">
        <CFormGroup>
            <CLabel v-if="company">
                {{ __('common:company_one') }}
            </CLabel>
            <CLabel v-else>
                {{ __('common:recipient') }}*
            </CLabel>
            <CInput
                v-model="addressModel.attributes.name"
                v-focus="focus"
                :has-error="addressError.hasError('name')"
                :placeholder="company ? __('common:company_one') : __('common:name')"
            />
            <CFormErrorMessageList :errors="addressError.getErrors('name')"/>
        </CFormGroup>
        <AddressFields
            address-path="address"
            :model="addressModel"
            :model-error="addressError"
            pointer-path="address."
        />
        <CDisclosure
            class="tw-mt-4"
            :open="false"
            size="sm"
            :title="__('common:additional_information')"
        >
            <CFormGroup>
                <CLabel>
                    <template>{{ __('common:accounting') }}</template>
                </CLabel>
                <CInput
                    v-model="addressModel.attributes.label"
                    :has-error="addressError.hasError('label')"
                    :placeholder="__('common:accounting')"
                />
                <CFormErrorMessageList :errors="addressError.getErrors('label')"/>
            </CFormGroup>
        </CDisclosure>
        <CFormSubmitTrigger/>
    </CForm>
</template>

<script lang="ts">
    import type {PropType} from 'vue';
    import {defineComponent, ref} from 'vue';
    import AddressModel from '@/modules/cashier/models/AddressModel';
    import AddressFields from '@/modules/cashier/components/core/address/molecules/AddressFields.vue';
    import ErrorHandler from '@/modules/legacy/libs/errors/errorHandler';

    export default defineComponent({
        components: {
            AddressFields,
        },
        props: {
            focus: {
                type: Boolean,
                default: true,
            },
            company: {
                type: Boolean,
                default: false,
            },
            addressModel: {
                type: Object as PropType<AddressModel>,
                default: () => new AddressModel(),
            },
        },
        setup(props, {emit}) {
            const addressError = ref(new ErrorHandler());

            function save() {
                emit('loading', true);
                props.addressModel.save().then(() => {
                    emit('loading', false);
                    addressError.value.reset();
                    emit('saved', props.addressModel);
                }).catch(response => {
                    emit('loading', false);
                    addressError.value.reset(response);
                });
            }

            return {
                save,
                addressError,
            };
        },
    });
</script>
