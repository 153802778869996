<template>
    <div class="MagicIndexLoader">
        <RingLoaderIcon/>
    </div>
</template>

<script lang="ts">
    import RingLoaderIcon from '@/modules/app/components/atoms/RingLoaderIcon.vue';

    export default {
        components: {RingLoaderIcon},
    };
</script>

<style lang="scss" scoped>
    .MagicIndexLoader {
        @apply tw-flex tw-items-center tw-justify-center tw-py-6;

        svg {
            @apply tw-h-12 tw-w-12 tw-stroke-gray-200;
        }
    }
</style>

