import {reactive} from 'vue';
import type OrganizationModel from '@/modules/organization/models/OrganizationModel';

class Manager {
    public _activeOrganization: OrganizationModel | undefined = undefined;
    legacyCustomer: any | undefined = undefined;
    legacyNursery: any | undefined = undefined;
    legacyAccount: any | undefined = undefined;
    language: string | undefined = undefined;
    onTrial: boolean | undefined = undefined;
    subscribed: boolean | undefined = undefined;

    public get activeOrganization(): OrganizationModel {
        return this._activeOrganization as OrganizationModel;
    }

    public set activeOrganization(value: OrganizationModel) {
        this._activeOrganization = value;
    }

    public flush() {
        this._activeOrganization = undefined;
        this.legacyCustomer = undefined;
        this.legacyNursery = undefined;
        this.legacyAccount = undefined;
        this.language = undefined;
        this.onTrial = undefined;
        this.subscribed = undefined;
    }
}

export default reactive(new Manager());
