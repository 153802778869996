import type {ComputedRef, Ref} from 'vue';
import {computed, onMounted, onUnmounted, ref, set} from 'vue';
import type {Moment} from 'moment';
import moment from 'moment';
import ManagerStore from '@/modules/legacy/store/manager.store';
import {EventBus} from '@/modules/legacy/utils/bus';
import TemplateEventModel from '@/modules/human-resources/models/TemplateEventModel';
import type TemplatePlanningModel from '@/modules/human-resources/models/TemplatePlanningModel';
import useAuth from '@/modules/app/composables/useAuth';

export default function(
    opening: Ref<string> | Function,
    closing: Ref<string> | Function,
    planningTemplate: Ref<TemplatePlanningModel> | null,
    planningTemplates: ComputedRef<TemplatePlanningModel[] | undefined> | null,
    autoSave: boolean | undefined = true,
) {
    onMounted(() => {
        EventBus.$on('calendar:staff:create:eventTemplate', (event: TemplateEventModel) => {
            addEvent(event);
        });
    });
    onUnmounted(() => {
        EventBus.$off('calendar:staff:create:eventTemplate');
    });

    const organization = computed(() => {
        return ManagerStore.legacyNursery;
    });
    const {legacyUser: user} = useAuth();

    const selectedEvent = ref<TemplateEventModel | null>(null);
    const modalEditEvent = ref<null | { modal: { show: () => null; hide: () => null } }>(null);

    function newEvent(day: Moment, selectedPlanningTemplate: TemplatePlanningModel | null) {
        const openingHours = typeof opening === 'function' ? opening(day) : opening.value;
        const closingHours = typeof closing === 'function' ? closing(day) : closing.value;

        const startTime =
            moment(openingHours, 'HH:mm:ss').hours() * 3600 +
            moment(openingHours, 'HH:mm:ss').minutes() * 60;
        const endTime =
            moment(closingHours, 'HH:mm:ss').hours() * 3600 +
            moment(closingHours, 'HH:mm:ss').minutes() * 60 -
            startTime;

        const event = new TemplateEventModel();
        if (organization.value && organization.value.id) {
            event.attributes.organization_id = organization.value.id.toString();
        }
        event.attributes.account_id = `${user.value.account_id}`;
        event.attributes.kids_group_id = null;
        event.attributes.planning_template_id = planningTemplate?.value ? planningTemplate?.value.id : selectedPlanningTemplate?.id;
        event.attributes.day = day.clone().day();
        event.attributes.week = 1;
        event.attributes.start_time = startTime;
        event.attributes.timelapse = endTime;
        event.attributes.note = null;
        event.attributes.forecast = true; // TODO : Get default parameters
        event.attributes.supervise_kid = null;

        addEvent(event);
    }

    function addEvent(event: TemplateEventModel) {
        selectedEvent.value = event;
        modalEditEvent.value?.modal.show();
    }

    async function onEventAdded(event: TemplateEventModel) {
        const events = eventsFromPlanningTemplate(event.attributes.planning_template_id);

        // Prevent multiple draft events
        if (!autoSave && events.find(item => item.id === event.id)) {
            await onEventEdited(event);

            return;
        }

        autoSave ? await event.save() : null;

        events.push(event);
        modalEditEvent.value?.modal.hide();
    }

    function editEvent(event: TemplateEventModel) {
        selectedEvent.value = event;
        modalEditEvent.value?.modal.show();
    }

    async function onEventEdited(event: TemplateEventModel) {
        autoSave ? await event.save() : null;

        const events = eventsFromPlanningTemplate(event.attributes.planning_template_id);

        const eventToEdit = events.find(
            item => item.id === event.id,
        );
        if (eventToEdit) {
            const index = events.indexOf(eventToEdit);
            set(events, index, event);
        }
        modalEditEvent.value?.modal.hide();
    }

    async function onEventDeleted(event: TemplateEventModel) {
        const events = eventsFromPlanningTemplate(event.attributes.planning_template_id);

        const eventToDelete = events.find(
            item => item.id === event.id,
        );
        if (eventToDelete) {
            const index = events.indexOf(eventToDelete);
            events.splice(index, 1);
        }
        modalEditEvent.value?.modal.hide();
    }

    function eventsFromPlanningTemplate(id: string | null | undefined): TemplateEventModel[] {
        if (planningTemplate?.value) {
            return planningTemplate.value.planningEvents().value().all();
        }

        return planningTemplates?.value?.find(item => item.id === id)?.planningEvents().value().all() || [];
    }

    return {
        selectedEvent,
        modalEditEvent,

        newEvent,
        addEvent,
        onEventAdded,
        editEvent,
        onEventEdited,
        onEventDeleted,
    };
}
