<template>
    <SimplePageTemplate @click="$root.$emit('bv::hide::popover')">
        <template #title>
            <MPageTitle>
                {{ __('app:menu.kids_planning') }}
            </MPageTitle>
        </template>
        <template #actions>
            <top-actions
                :current-display="__('common:monthly')"
                :date="date"
                :nursery="nursery"
            />
        </template>
        <template #content>
            <TopActionsSwitcher
                class="d-print-none"
                :current-display="__('common:monthly')"
                :date="date"
                :nursery="nursery"
                print
            />
            <div class="card card-3d tw-mt-3 monthly !tw-flex-row">
                <div class="card-body">
                    <div class="row mb-2">
                        <div class="col-6 col-sm-4 order-2 order-sm-1 text-left">
                            <button
                                class="btn btn-success btn-sm d-print-none"
                                @click="date = date.clone().subtract(1, 'months')"
                            >
                                <i
                                    aria-hidden="true"
                                    class="fa fa-angle-left mr-1"
                                />
                                {{
                                    ucfirst(fromMoment(date).subMonths(1).toLocaleString({
                                        month: 'long',
                                        year: 'numeric',
                                    }))
                                }}
                            </button>
                        </div>
                        <div class="col-12 col-sm-4 order-1 order-sm-2 text-center mb-3">
                            <MHeading class="mb-2">
                                {{
                                    ucfirst(fromMoment(date).toLocaleString({
                                        month: 'long',
                                        year: 'numeric',
                                    }))
                                }}
                            </MHeading>
                            <CCenter>
                                <CText>
                                    {{ __('planning:monthly_rate_colon') }}
                                </CText>
                                <OccupationBadge
                                    class="tw-ml-1"
                                    :enable-count="false"
                                    :loading="occupationLoading"
                                    :occupations="occupations"
                                />
                            </CCenter>
                        </div>
                        <div class="col-6 col-sm-4 order-3 order-sm-3 text-right">
                            <button
                                class="btn btn-success btn-sm d-print-none"
                                @click="date = date.clone().add(1, 'months')"
                            >
                                {{
                                    ucfirst(fromMoment(date).addMonths(1).toLocaleString({
                                        month: 'long',
                                        year: 'numeric',
                                    }))
                                }}<i
                                    aria-hidden="true"
                                    class="fa fa-angle-right ml-1"
                                />
                            </button>
                        </div>
                    </div>
                    <div
                        v-for="(week, w) in numberOfWeeks"
                        :key="'week' + w"
                        class="d-flex justify-content-center mb-2"
                    >
                        <router-link
                            class="day rounded text-center p-2 weekinfo"
                            tag="div"
                            :to="{name:'planning.kids',params:{nursery:nursery.id, date:firstDayOfWeek(week).format('DD/MM/Y')}}"
                        >
                            <h6 class="h6 text-uppercase mt-3">
                                {{ __('common:week_abbreviated') }}
                            </h6>
                            <h3 class="h3 mb-0">
                                {{ fromMoment(firstDayOfWeek(week)).weekNumber }}
                            </h3>
                        </router-link>
                        <template v-for="(day, i) in enumerateDaysInWeek(week)">
                            <router-link
                                :key="'day' + i"
                                class="day rounded text-center p-2 tw-relative tw-bg-gray-100 hover:tw-shadow-md hover:tw-bg-blue-500"
                                :class="{'active': moment().isBetween(day.clone().startOf('day'), day.clone().endOf('day')), 'inactive': !date.isBetween(day.clone().startOf('month'), day.clone().endOf('month'), null, '[]')}"
                                tag="div"
                                :to="{name:'planning.kids',params:{nursery:nursery.id, date:day.clone().format('DD/MM/Y')}}"
                                @mouseenter.native="loadDetails(weeksObject[w] ? weeksObject[w].days[i] : null)"
                                @mouseleave.native="unloadDetails(weeksObject[w] ? weeksObject[w].days[i] : null)"
                            >
                                <h3 class="h3 mb-0">
                                    {{ fromMoment(day).day }}
                                </h3>
                                <h6 class="h6 text-uppercase">
                                    {{ fromMoment(day).weekdayShort.replace('.', '') }}
                                </h6>
                                <OccupationBadge
                                    class="tw-mt-2 tw-mb-1"
                                    :day="day"
                                    enable-half-day
                                    :loading="occupationLoading"
                                    :occupations="occupations"
                                />
                                <transition name="list-bottom">
                                    <div
                                        v-if="weeksObject[w] && weeksObject[w].days[i] && weeksObject[w].days[i].showDetail"
                                        class="tw-absolute tw-z-[2000] tw-bg-white tw-rounded-lg tw-border tw-border-gray tw-shadow tw-py-3 tw-px-5"
                                        style="bottom: 100%; width: 700px;"
                                        :style="i >= ((enumerateDaysInWeek(week).length - 2) / 2) ? 'right: 0px;' : 'left: 0px;'"
                                    >
                                        <div class="tw-text-center tw-text-blue-500 tw-capitalize tw-mb-3">
                                            {{ fromMoment(day).toLocaleString(Epoch.presets.DATE_HUGE) }}
                                        </div>
                                        <div class="tw-table tw-w-full tw-mb-2">
                                            <div class="tw-w-full tw--ml-4">
                                                <hours
                                                    :closing="getSummaryClosing(day)"
                                                    :opening="getSummaryOpening(day)"
                                                />
                                            </div>
                                        </div>
                                        <div class="tw-table tw-w-full tw-mb-2">
                                            <summary-bar
                                                class="tw-mb-1 tw-w-full"
                                                :closing="getSummaryClosing(day)"
                                                :day="day.clone()"
                                                :loading="occupationLoading"
                                                :nursery="nursery"
                                                :opening="getSummaryOpening(day)"
                                                :summary="getWeekOccupationRateBar(day)"
                                            />
                                            <summary-bar-staff
                                                class="tw-w-full"
                                                :closing="getSummaryClosing(day)"
                                                :day="day.clone()"
                                                :jobs="jobs"
                                                :loading="detailLoading"
                                                :nursery="nursery"
                                                :opening="getSummaryOpening(day)"
                                                :summary="detailLoading ? [] : getWeekOccupationRateBarStaff(day)"
                                            />
                                        </div>
                                    </div>
                                </transition>
                            </router-link>
                        </template>
                    </div>
                </div>
            </div>
        </template>
    </SimplePageTemplate>
</template>

<script>
    import route from '@/modules/legacy/libs/ziggy';
    import useApi from '@/modules/app/composables/useApi';
    import moment from 'moment';
    import _debounce from 'lodash-es/debounce';
    import _find from 'lodash-es/find';
    import _head from 'lodash-es/head';
    import _forEach from 'lodash-es/forEach';
    import _filter from 'lodash-es/filter';
    import {nursery} from '@/modules/legacy/mixins/nursery';
    import * as ProcessedData from '@/modules/legacy/libs/ProcessedData';
    import TopActions from '@/modules/planning/components/kid/TopActions.vue';
    import Hours from '@/modules/legacy/components/Modules/PlanningGenerator/Hours.vue';
    import SummaryBar from '@/modules/legacy/components/Modules/PlanningGenerator/Summary.vue';
    import SummaryBarStaff from '@/modules/legacy/components/Modules/PlanningGenerator/StaffSummary.vue';
    import OccupationBadge from '@/modules/planning/components/core/OccupationBadge.vue';
    import JobModel from '@/modules/human-resources/models/JobModel';
    import useBroadcast from '@/modules/app/composables/useBroadcast';
    import {Epoch} from '@meekohq/lumos';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';
    import SimplePageTemplate from '@/modules/app/components/templates/SimplePageTemplate.vue';
    import TopActionsSwitcher from '@/modules/planning/components/kid/TopActionsSwitcher.vue';

    export default {
        components: {
            TopActionsSwitcher,
            SimplePageTemplate,
            TopActions,
            Hours,
            SummaryBar,
            SummaryBarStaff,
            OccupationBadge,
        },
        mixins: [nursery],
        props: ['nursery', 'user'],
        data: () => ({
            loading: false,
            occupationLoading: false,
            detailLoading: false,
            date: moment(),
            kids: [],
            currentGroup: {},
            occupations: [],
            supervision: [],
            segment: {},
            toggleMenu: false,
            weeksObject: [],
            planningDisplayPreference: window.localStorage.getItem('planningDisplayPreference'),
            jobs: [],
            Epoch,
        }),
        computed: {
            moment() {
                return moment;
            },
            window() {
                return window;
            },
            fromMonth() {
                return this.date.clone().startOf('month').startOf('week');
            },
            toMonth() {
                return this.date.clone().endOf('month').endOf('week').add(1, 'day').startOf('day');
            },
            numberOfWeeks() {
                return this.toMonth.diff(this.fromMonth.clone(), 'weeks');
            },
        },
        watch: {
            fromMonth(val, oldVal) {
                if (val.format('YYYY-MM-DD') !== oldVal.format('YYYY-MM-DD')) {
                    this.occupationLoading = true;
                    this.debounceDate();
                    this.initWeeks();
                }
            },
            numberOfWeeks: 'initWeeks',
        },
        mounted() {
            JobModel.query().get().then(data => {
                this.jobs = data.all();
            });

            if (this.$route.params.date) {
                this.date = moment(this.$route.params.date, 'DD/MM/Y');
            }

            useBroadcast().sessionChannel.bind('occupation', response => {
                ProcessedData.retrieve(response.processedDataUrl, response => {
                    this.occupations = response.data;
                    this.occupationLoading = false;
                });
            });

            useBroadcast().sessionChannel.bind('supervision', response => {
                ProcessedData.retrieve(response.processedDataUrl, response => {
                    this.supervision = response.data;
                    this.detailLoading = false;
                });
            });

            this.getOccupation();

            this.initWeeks();
        },
        beforeDestroy() {
            useBroadcast().sessionChannel.unbind('occupation');
            useBroadcast().sessionChannel.unbind('supervision');
        },
        methods: {
            fromMoment: Epoch.fromMoment,
            debounceDate: _debounce(function() {
                this.nursery.places && this.nursery.openingHours.length ? this.getOccupation() : null;
            }, 1000),
            enumerateDaysInWeek(week) {
                const dates = [];

                const currDate = this.date.clone().startOf('month').add(week - 1, 'weeks').startOf('week');
                const lastDate = this.date.clone().startOf('month').add(week - 1, 'weeks').endOf('week');

                dates.push(currDate.clone());
                while (currDate.add(1, 'days').diff(lastDate) <= 0) {
                    const day = currDate.clone().locale('en').format('ddd').toLowerCase();
                    if (day === 'sat' || day === 'sun') {
                        const hasDay = _find(this.nursery.openingHours, {day});
                        hasDay ? dates.push(currDate.clone()) : null;
                    } else {
                        dates.push(currDate.clone());
                    }
                }

                return dates;
            },
            initWeeks() {
                this.weeksObject = [];
                for (let i = 1; i <= this.numberOfWeeks; i++) {
                    const days = [];
                    this.enumerateDaysInWeek(i).forEach(day => {
                        days.push({
                            day,
                            showDetail: false,
                        });
                    });
                    this.weeksObject.push({
                        week: i,
                        days,
                    });
                }
            },
            firstDayOfWeek(week) {
                return this.date.clone().startOf('month').add(week - 1, 'weeks').startOf('week');
            },
            getOccupation() {
                this.occupationLoading = true;
                useApi().legacy.get(route('nurseries.occupation', {nurseries: this.nursery.id}), {
                    params: {
                        from: this.fromMonth.unix(),
                        to: this.toMonth.unix(),
                        group_id: null,
                        interval: 15,
                    },
                }).catch(error => {
                    this.occupationLoading = false;
                    if (error && error.response && error.response.status === 422) {
                        _forEach(error.response.data.errors, function(value) {
                            useNotification().error(_head(value));
                        });
                    } else {
                        useNotification().error(error);
                    }
                });
            },
            loadDetails(day) {
                if (day) {
                    day.showDetail = true;
                    this.detailLoading = true;
                    this.getSupervision(day.day.clone().startOf('day'), day.day.clone().endOf('day'));
                }
            },
            unloadDetails(day) {
                if (day) {
                    day.showDetail = false;
                    this.supervision = [];
                }
            },
            getSupervision: _debounce(function(fromDay, toDay) {
                this.detailLoading = true;
                useApi().legacy.get(route('nurseries.supervision', {nurseries: this.nursery.id}), {
                    params: {
                        from: fromDay.unix(),
                        to: toDay.unix(),
                        group_id: null,
                        interval: 15,
                    },
                }).catch(error => {
                    this.detailLoading = false;
                    if (error && error.response && error.response.status === 422) {
                        _forEach(error.response.data.errors, function(value) {
                            useNotification().error(_head(value));
                        });
                    } else {
                        useNotification().error(error);
                    }
                });
            }, 1000),

            getSummaryOpening(day) {
                let hours = this.getWeekOccupationRateBar(day).filter(item => item.kids > 0);
                this.getWeekOccupationRateBarStaff(day) ? hours = hours.concat(this.getWeekOccupationRateBarStaff(day).filter(item => item.staffs != 0)) : null;

                return this.$getOpening(this.nursery.openingHours, true, false, hours);
            },
            getSummaryClosing(day) {
                let hours = this.getWeekOccupationRateBar(day).filter(item => item.kids > 0);
                this.getWeekOccupationRateBarStaff(day) ? hours = hours.concat(this.getWeekOccupationRateBarStaff(day).filter(item => item.staffs != 0)) : null;

                return this.$getClosing(this.nursery.openingHours, true, false, hours);
            },

            getWeekOccupationRateBar(day) {
                const filter = _filter(this.occupations, occupation => {
                    return occupation.date === moment(day).format('YYYY-MM-DD');
                });

                if (filter.length) {
                    return _head(filter).summary;
                }

                return [];
            },
            getWeekOccupationRateBarStaff(day) {
                const filter = _filter(this.supervision, supervision => {
                    return supervision.date === moment(day).format('YYYY-MM-DD');
                });

                if (filter.length) {
                    return _head(filter).summary;
                }

                return [];
            },
            ucfirst(string) {
                return string.charAt(0).toUpperCase() + string.slice(1);
            },
        },
    };
</script>

<style lang="scss" scoped>
    @import "bootstrap/scss/functions";
    @import "@/assets/_bootstrap/variables";
    @import "bootstrap/scss/mixins/breakpoints";

    @include media-breakpoint-up(sm) {
        .kid {
            width: 200px;
        }

        .planning {
            width: calc(100% - 200px - 35px);
        }
    }

    @media print {
        @page {
            size: auto;
            max-height: 100%;
            max-width: 100%;
        }
        body {
            writing-mode: tb-rl; //landscape
        }
        .btn, .badge, .day {
            -webkit-print-color-adjust: exact !important; /* Chrome, Safari */
            print-color-adjust: exact !important; /*Firefox*/
            border: 0 !important;
        }
        .monthly {
            margin-top: 0 !important;
        }
        .day {
            width: 120px;
            height: 100px;

            h5 {
                margin-top: 0 !important;
            }
        }
        .weekinfo {
            border: 1px solid #cfd8e0 !important;
        }
    }

    .day {
        width: 130px;
        display: inline-block;
        padding: 1.25rem 0;
        margin: 0 5px;
        cursor: pointer;
        transition: all 500ms;

        h3, h6 {
            transition: all 500ms;
            color: $gray-600;
            font-weight: 400;
        }

        &:first-of-type {
            margin-left: 0;
        }

        &:last-of-type {
            margin-right: 0;
        }

        &:hover:not(.weekinfo) {
            h1, h3, h5, h6 {
                color: white;
            }
        }

        &.active {
            $color: theme-color('primary');

            h3, h6 {
                color: $color;
            }

            box-shadow: 0 0 0 1px $color;
        }

        &.weekinfo {
            width: 90px;
            background-color: white;
            box-shadow: 0 0 0 3px #f6f9fc;
        }

        &.inactive {
            background-color: #f6f9fc;

            &:hover {
                background-color: $primary;
            }

            h3, h6 {
                color: rgb(221, 221, 221);
            }

            box-shadow: 0 0 0 3px #f6f9fc;
        }

        @include media-breakpoint-down(sm) {
            h3 {
                font-size: 2rem;
            }

            h6 {
                font-size: 1rem;
            }
        }
    }

    .badge {
        font-weight: 500;
        padding: 0.4em 0.7em;

        &.badge-warning {
            color: white;
        }

        &.badge-danger {
            background-color: #ff4b4a;
        }
    }

    .nav-link {
        font-size: 15px;
        padding: 8px 15px;
    }
</style>
