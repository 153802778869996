<script setup lang="ts">
    import {provide, toRef, watch} from 'vue';
    import useMStepPanelState, {keyMStepPanelState} from '@/modules/meeko-ui/components/MStepPanel/useMStepPanelState';

    const props = withDefaults(defineProps<{
        step?: number;
    }>(), {
        step: undefined,
    });

    const emit = defineEmits<{
        (e: 'update:step', value: number | undefined): void;
        (e: 'has-previous-step', value: boolean): void;
        (e: 'has-next-step', value: boolean): void;
    }>();

    const state = useMStepPanelState(toRef(props, 'step'));
    provide(keyMStepPanelState, state);

    const {
        activeStep,
        previousStep,
        nextStep,
        firstStep,
        lastStep,
    } = state;

    watch(activeStep, value => {
        emit('update:step', value);
        emit('has-previous-step', !firstStep.value ? false : value !== firstStep.value.step);
        emit('has-next-step', !lastStep.value ? false : value !== lastStep.value.step);
    }, {immediate: true});

    defineExpose({
        nextStep,
        previousStep,
    });
</script>

<template>
    <div class="MStepPanel">
        <slot/>
    </div>
</template>
