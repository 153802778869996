import {ref} from 'vue';
import {collect, type Collection, type Model, MqlTransaction} from '@meekohq/lumos';
import type {TransactionStateType} from '@/modules/cashier/composables/issuing/transaction/useTransactionState';
import type TenantModel from '@/modules/cashier/models/TenantModel';
import type TransactionModel from '@/modules/cashier/transaction/domain/TransactionModel';
import type CustomerModel from '@/modules/cashier/models/CustomerModel';
import type PaymentMethodModel from '@/modules/cashier/models/PaymentMethodModel';
import type CurrencyModel from '@/modules/cashier/models/CurrencyModel';
import type AllocationModel from '@/modules/cashier/models/AllocationModel';
import StoreAllocationAction
    from '@/modules/cashier/components/core/address/organisms/Allocation/libs/StoreAllocationAction';

export type StoreAndAssociateParamsType = {
    mqlRunner: MqlTransaction;
    paymentMethod: PaymentMethodModel | undefined;
    currency: CurrencyModel | undefined;
    invoiceAllocation: AllocationModel | undefined;
    tenant: TenantModel | undefined;
    transaction: TransactionModel;
    customer: CustomerModel | undefined
};
/**
 * Full application logic for saving transactions.
 */
export default function useSaveTransaction(transactionStore: TransactionStateType) {
    const loading = ref(false);

    const {
        bus,
        transaction,
        currency,
        customer,
        invoiceAllocation,
        paymentMethod,
        tenant,
    } = transactionStore;

    async function save() {
        loading.value = true;

        bus.emit('reset-error');

        const mqlRunner = new MqlTransaction();

        try {
            // Store the transaction
            const promises = storeAndAssociate({
                currency: currency.value,
                customer: customer.value,
                invoiceAllocation: invoiceAllocation?.value,
                mqlRunner,
                paymentMethod: paymentMethod.value,
                tenant: tenant.value,
                transaction: transaction.value,
            });


            promises.each(promise => {
                promise.catch(e => {
                    bus.emit('error', e as Error);
                });
            });

            // Run transaction
            await mqlRunner.run();

            if (mqlRunner.failed) {
                loading.value = false;

                return;
            }


            // Wait for allocations to be saved
            await Promise.all(promises.all());

            // Refresh the transaction to get the new computed attributes
            await transaction.value.refresh();

            // Emit the saved transaction
            bus.emit('saved', transaction.value);
        } catch (e) {
            bus.emit('error', e as Error);
            loading.value = false;
        }
    }

    return {
        loading,
        save,
    };
}

function storeAndAssociate(storeAndAssociateParams: StoreAndAssociateParamsType): Collection<Promise<Model>>
{
    const {
        transaction,
        tenant,
        customer,
        paymentMethod,
        invoiceAllocation,
        currency,
        mqlRunner,
    } = storeAndAssociateParams;

    let allocationsAlreadySaved = false;
    const promises: Collection<Promise<Model>> = collect();

    // If the transaction doesn't exist, associate it with the tenant and customer,
    // Because it's not possible to change the tenant or customer of an existing transaction
    if (!transaction.exists) {
        transaction.tenant().associate(tenant);

        if (customer) {
            transaction.customer().associate(customer);
        }
    }

    if (paymentMethod) {
        transaction.paymentMethod().associate(paymentMethod);
    }

    if (currency) {
        transaction.currency().associate(currency);
    }

    // Store transaction allocations first if the transaction amount is decreased
    // This is needed to avoid a database constraint error
    if (invoiceAllocation
        && transaction.isDirty('amount')
        && transaction.attributes.amount !== undefined
        && transaction.original.amount > transaction.attributes.amount
    ) {
        prepareStoreAllocations(promises, storeAndAssociateParams);
        allocationsAlreadySaved = true;
    }

    promises.push(transaction.save({mqlRunner}));

    // Store transaction allocations
    if (invoiceAllocation && !allocationsAlreadySaved) {
        prepareStoreAllocations(promises, storeAndAssociateParams);
    }

    return promises;
}

function prepareStoreAllocations(
    promises: Collection<Promise<Model>>,
    storeAndAssociateParams: StoreAndAssociateParamsType,
) {

    const storeAllocationAction = new StoreAllocationAction();
    const {
        tenant,
        customer,
        currency,
        mqlRunner,
        invoiceAllocation,
    } = storeAndAssociateParams;

    if (invoiceAllocation) {
        promises.push(storeAllocationAction.storeAndAssociate(
            invoiceAllocation,
            tenant!,
            customer,
            currency,
            mqlRunner,
        ));
    }
}
