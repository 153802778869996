<template>
    <div
        v-if="balanceTypes.isNotEmpty() && !loading"
        class="tw-grid tw-grid-cols-2 tw-gap-4"
    >
        <template v-for="(balanceType, index) in balanceTypes">
            <BalanceType
                :key="'balanceType-' + balanceType.getKey()"
                :balance-type="balanceType"
                :source="source"
                :staff="staff"
            />
        </template>
    </div>
    <div v-else-if="!loading">
        <CParagraph>
            {{ __('hr_balance:no_balance_for_planning') }}
            <CButton
                icon-external-link
                :label="__('hr_balance:balance_management')"
                :to="{name: 'humanresources/settings.calendar.balanceType'}"
                variant="link"
            />
        </CParagraph>
    </div>
</template>

<script lang="ts">
    import type {PropType} from 'vue';
    import {defineComponent, ref, watch} from 'vue';
    import type StaffModel from '@/modules/human-resources/models/StaffModel';
    import type EventTypeModel from '@/modules/human-resources/models/EventTypeModel';
    import {collect} from '@meekohq/lumos';
    import BalanceTypeModel from '@/modules/human-resources/models/BalanceTypeModel';
    import BalanceType from '@/modules/human-resources/components/balance/BalanceType.vue';
    import type EventModel from '@/modules/human-resources/models/EventModel';
    import {isEqual} from 'lodash-es';
    import BalanceAllocationModel from '@/modules/human-resources/models/BalanceAllocationModel';

    export default defineComponent({
        components: {BalanceType},
        props: {
            eventTypes: {type: Array as PropType<EventTypeModel[]>, default: () => [], required: true},
            source: {type: Object as PropType<EventModel>, required: true},
            staff: {type: Object as PropType<StaffModel>, required: true},
        },
        setup(props, {emit}) {
            const loading = ref(false);

            const balanceTypes = ref(collect<BalanceTypeModel>());

            watch(loading, state => emit('loading', state), {immediate: true});

            const getBalanceType = async function() {
                loading.value = true;

                const ids = collect(props.eventTypes).keyBy('id').keys().toArray<string>();

                balanceTypes.value = await BalanceTypeModel.query()
                    .where(query => {
                        if (props.source.getKey()) {
                            query.where(query2 => {
                                query2.whereHas('eventTypes', query3 => query3.whereIn('id', ids));
                                BalanceTypeModel.activeOrganizationBalanceTypeUnarchivedScope(query);
                            }).orWhere(query2 => {
                                query2.whereHas(new BalanceTypeModel().balanceAllocations(), query3 => {
                                    query3.where('staff_id', props.staff.getKey())
                                        .where('source_id', props.source.getKey())
                                        .where('source_type', props.source.getType())
                                        .where(query4 => BalanceAllocationModel.restrictToConfirmedScope(query4));
                                });
                            });
                        } else {
                            query.whereHas('eventTypes', query3 => query3.whereIn('id', ids));
                            BalanceTypeModel.activeOrganizationBalanceTypeUnarchivedScope(query);
                        }
                    })
                    .with(new BalanceTypeModel().balanceAllocations(), query => {
                        query.where('staff_id', props.staff.getKey())
                            .where(query2 => BalanceAllocationModel.restrictToConfirmedScope(query2));
                    })
                    .get();

                loading.value = false;
            };

            watch(() => props.eventTypes, async (value, old) => {
                if (value?.length > 0 && !isEqual(value, old)) {
                    await getBalanceType();
                }
            }, {immediate: true});

            watch(balanceTypes, models => {
                let allocationsToSave = [];
                models.each(balanceType => {
                    const allocations = balanceType.balanceAllocations().value();

                    const dirtyAllocations = allocations
                        .each(item => {
                            if (item.exist && item.attributes.value === 0) {
                                item.markForDeletion();
                            }
                        })
                        .filter(item => {
                            if (item.exist && item.markedForDeletion && item.attributes.value === 0) {
                                return true;
                            }

                            return item.isDirty() && item.attributes.value !== 0;
                        });

                    allocationsToSave = allocationsToSave.concat(dirtyAllocations.toArray());
                });

                emit('changed', allocationsToSave);
            }, {deep: true});

            return {
                balanceTypes,
                loading,
            };
        },
    });
</script>

