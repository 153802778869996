<template>
    <div class="OpeningManager">
        <loader
            v-if="loading"
            custom-class="la-2x"
        />
        <div
            v-else
            class="OpeningManager__grid"
        >
            <div class="OpeningManager__grid-column">
                <OpeningManagerCategoryHeader
                    icon="far fa-alarm-clock"
                    :title="__('organization:openings.opening_hours')"
                />
                <OpeningManagerFormOpeningHours
                    v-if="$can('update', 'nursery')"
                    :new-opening-hour="newOpeningHour"
                    @addOpeningHour="addOpeningHour"
                />
                <div class="OpeningManager__list-container">
                    <OpeningManagerEmptyList
                        v-if="nursery.openingHours.length === 0"
                        :title="__('organization:openings.no_opening_hours')"
                    />
                    <OpeningManagerListOpeningHours
                        v-else
                        :nursery="nursery"
                        @deleteOpeningHour="deleteOpeningHour"
                    />
                </div>
            </div>

            <div class="OpeningManager__grid-column">
                <OpeningManagerCategoryHeader
                    icon="far fa-calendar-exclamation"
                    :title="__('organization:openings.public_holidays')"
                />
                <OpeningManagerFormPublicHolidays
                    v-if="$can('update', 'nursery')"
                    :holidays="holidays"
                    @addHoliday="addHoliday"
                />
                <div class="OpeningManager__list-container">
                    <OpeningManagerEmptyList
                        v-if="nursery.holidays.length === 0"
                        :title="__('organization:openings.no_public_holidays')"
                    />
                    <OpeningManagerListPublicHolidays
                        v-else
                        :nursery="nursery"
                        @deleteHoliday="deleteHoliday"
                    />
                </div>
            </div>

            <div class="OpeningManager__grid-column">
                <OpeningManagerCategoryHeader
                    :help-text="__('organization:openings.end_date_included')"
                    icon="far fa-calendar-times"
                    :title="__('organization:openings.closed_periods')"
                />
                <OpeningManagerFormClosedPeriods
                    v-if="$can('update', 'nursery')"
                    :new-closed-period="newClosedPeriod"
                    @addClosedPeriod="addClosedPeriod"
                />
                <OpeningManagerPastClosedPeriods
                    v-if="pastClosedPeriod.length"
                    :past-closed-period="pastClosedPeriod"
                    @deleteClosedPeriod="deleteClosedPeriod"
                />
                <div class="OpeningManager__list-container">
                    <OpeningManagerEmptyList
                        v-if="futureClosedPeriod.length === 0"
                        :title="__('organization:openings.no_closed_periods')"
                    />
                    <OpeningManagerListClosedPeriods
                        v-else
                        :future-closed-period="futureClosedPeriod"
                        @deleteClosedPeriod="deleteClosedPeriod"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import _filter from 'lodash-es/filter';
    import _find from 'lodash-es/find';
    import _head from 'lodash-es/head';
    import _forEach from 'lodash-es/forEach';
    import _sortBy from 'lodash-es/sortBy';
    import moment from 'moment';
    import Vue from 'vue';
    import route from '@/modules/legacy/libs/ziggy';
    import useApi from '@/modules/app/composables/useApi';
    import {Epoch} from '@meekohq/lumos';
    import __ from '@/modules/app/utils/i18n-facade';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';
    import useMagicModal from '@/modules/app/composables/useMagicModal';
    import {isNil} from 'lodash-es';
    import _cloneDeep from 'lodash-es/cloneDeep.js';
    import OpeningManagerEmptyList from '@/modules/organization/components/OpeningManager/OpeningManagerEmptyList.vue';
    import OpeningManagerCategoryHeader
        from '@/modules/organization/components/OpeningManager/OpeningManagerCategoryHeader.vue';
    import OpeningManagerPastClosedPeriods
        from '@/modules/organization/components/OpeningManager/OpeningManagerPastClosedPeriods.vue';
    import OpeningManagerFormOpeningHours
        from '@/modules/organization/components/OpeningManager/OpeningManagerFormOpeningHours.vue';
    import OpeningManagerFormPublicHolidays
        from '@/modules/organization/components/OpeningManager/OpeningManagerFormPublicHolidays.vue';
    import OpeningManagerFormClosedPeriods
        from '@/modules/organization/components/OpeningManager/OpeningManagerFormClosedPeriods.vue';
    import OpeningManagerListClosedPeriods
        from '@/modules/organization/components/OpeningManager/OpeningManagerListClosedPeriods.vue';
    import OpeningManagerListPublicHolidays
        from '@/modules/organization/components/OpeningManager/OpeningManagerListPublicHolidays.vue';
    import OpeningManagerListOpeningHours
        from '@/modules/organization/components/OpeningManager/OpeningManagerListOpeningHours.vue';

    export default {
        components: {
            OpeningManagerEmptyList,
            OpeningManagerCategoryHeader,
            OpeningManagerPastClosedPeriods,
            OpeningManagerFormClosedPeriods,
            OpeningManagerFormPublicHolidays,
            OpeningManagerFormOpeningHours,
            OpeningManagerListOpeningHours,
            OpeningManagerListPublicHolidays,
            OpeningManagerListClosedPeriods,
        },
        props: {
            nursery: {
                type: Object,
                required: true,
            },
        },
        data: () => ({
            loading: false,
            holidays: {
                list: [],
                selected: null,
            },
            newOpeningHour: {
                day: 'mon',
                started_at: Epoch.parse('07:00', 'HH:mm'),
                ended_at: Epoch.parse('18:00', 'HH:mm'),
            },
            newClosedPeriod: {},
            today: moment().startOf('day').unix(),
        }),
        computed: {
            pastClosedPeriod() {
                const periods = this.filterClosedPeriod(true);

                return _sortBy(periods, 'started_at');
            },

            futureClosedPeriod() {
                const periods = this.filterClosedPeriod();

                return _sortBy(periods, 'started_at');
            },
        },
        mounted() {
            this.getListHolidays();
        },
        methods: {
            filterClosedPeriod(past = false) {
                return this.nursery.closedPeriods.filter(period => {
                    if (past) {
                        return period.ended_at < this.today;
                    }

                    return period.ended_at >= this.today;
                });
            },

            addHoliday(id) {
                useApi().legacy.post(route('nurseries.holidays.store', {nurseries: this.nursery.id}), {holiday_id: id}).then(response => {
                    this.nursery.holidays.push(response.data);
                    const holiday = this.holidays.list.find(item => item.id === id);
                    const index = this.holidays.list.indexOf(holiday);
                    this.holidays.list.splice(index, 1);
                    this.holidays.selected = _head(this.holidays.list).id;
                }).catch(error => {
                    if (error && error.response && error.response.status === 422) {
                        _forEach(error.response.data.errors, function(value) {
                            useNotification().error(_head(value));
                        });
                    } else {
                        useNotification().error(error);
                    }
                });
            },

            deleteHoliday(holiday, index) {
                useMagicModal().deleteConfirmationModal({
                    title: __('organization:openings.delete_public_holidays'),
                    onConfirm: async () => {
                        useApi().legacy.delete(route('nurseries.holidays.destroy', {
                            nurseries: this.nursery.id,
                            holiday: holiday.id,
                        })).then(() => {
                            this.nursery.holidays.splice(index, 1);
                            this.holidays.list.push(holiday);
                        }).catch(error => {
                            useNotification().error(error);
                        });
                    },
                });
            },

            getListHolidays() {
                useApi().legacy.get(route('holidays.index', {nurseries: this.nursery.id})).then(response => {
                    this.loading = true;
                    this.holidays.list = response.data;
                    const self = this;
                    this.holidays.list = _filter(this.holidays.list, function(item) {
                        return !(_find(self.nursery.holidays, {id: item.id}));
                    });
                    this.holidays.selected = _head(this.holidays.list).id;
                }).catch(error => {
                    useNotification().error(error);
                }).finally(() => {
                    this.loading = false;
                });
            },

            addClosedPeriod() {
                const data = {...this.newClosedPeriod};
                data.started_at = moment(moment(data.started_at).format()).unix();

                if (isNil(data.ended_at)) {
                    data.ended_at = moment(moment(data.started_at).endOf('day').format()).unix();
                } else {
                    data.ended_at = moment(moment(data.ended_at).endOf('day').format()).unix();
                }

                useApi().legacy.post(route('nurseries.closedPeriods.index', {nurseries: this.nursery.id}), data).then(response => {
                    this.nursery.closedPeriods.push(response.data);
                    this.newClosedPeriod.name = null;
                    this.newClosedPeriod.started_at = null;
                    this.newClosedPeriod.ended_at = null;
                }).catch(error => {
                    if (error && error.response && error.response.status === 422) {
                        _forEach(error.response.data.errors, function(value) {
                            useNotification().error(_head(value));
                        });
                    }
                });
            },

            deleteClosedPeriod(id) {
                useMagicModal().deleteConfirmationModal({
                    title: __('organization:openings.delete_closing_period'),
                    onConfirm: async () => {
                        useApi().legacy.delete(route('nurseries.closedPeriods.destroy', {
                            nurseries: this.nursery.id,
                            closedPeriod: id,
                        })).then(() => {
                            this.nursery.closedPeriods = this.nursery.closedPeriods.filter(period => period.id !== id);
                        }).catch(error => {
                            useNotification().error(error);
                        });
                    },
                });
            },

            addOpeningHour() {
                const copyOpeningHours = _cloneDeep(this.nursery.openingHours);

                copyOpeningHours.push({
                    day: this.newOpeningHour.day,
                    started_at: this.newOpeningHour.started_at.toFormat('HH:mm:ss'),
                    ended_at: this.newOpeningHour.ended_at.toFormat('HH:mm:ss'),
                });

                useApi().legacy.post(route('nurseries.openingHours.store', {nurseries: this.nursery.id}), {opening_hours: copyOpeningHours})
                    .then(response => {
                        this.nursery.openingHours = response.data;
                    }).catch(error => {
                        if (error?.response?.status === 422) {
                            _forEach(error.response.data.errors, function(value) {
                                useNotification().error(_head(value));
                            });
                        } else {
                            useNotification().error(error);
                        }
                    });
            },

            deleteOpeningHour(i) {
                useMagicModal().deleteConfirmationModal({
                    title: __('organization:openings.delete_opening_period'),
                    onConfirm: async () => {
                        this.nursery.openingHours.splice(i, 1);
                        useApi().legacy.post(route('nurseries.openingHours.store', {nurseries: this.nursery.id}), {opening_hours: this.nursery.openingHours}).then(response => {
                            Vue.set(this.nursery.openingHours, response.data);
                        }).catch(error => {
                            useNotification().error(error);
                        });
                    },
                });
            },
        },
    };
</script>

<style lang="scss" scoped>
    .OpeningManager__grid {
        @apply tw-grid xl:tw-grid-cols-3 lg:tw-grid-cols-2 tw-grid-cols-1 tw-gap-8;
    }

    .OpeningManager__grid-column {
        @apply tw-flex tw-flex-col tw-gap-4;
    }

    .OpeningManager__list-container {
        @apply tw-border tw-border-gray-200 tw-rounded-xl tw-p-2;
    }
</style>
