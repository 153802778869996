import {Model} from '@meekohq/lumos';

export default class FamilyOrganizationPivot extends Model {
    public type = 'family/families_organizations_pivot';

    public attributes: {
        id: string;
        account_id: string | undefined;
        family_id: string | undefined;
        organization_id: string | undefined;
    } = {
            id: this.uuid(),
            account_id: undefined,
            family_id: undefined,
            organization_id: undefined,
        };
}
