<script lang="ts" setup>
    import {ref} from 'vue';
    import useUrl from '@/modules/app/composables/useUrl';
    import {useRoute} from 'vue-router/composables';
    import SimplePageTemplate from '@/modules/app/components/templates/SimplePageTemplate.vue';
    import SettingsPlannings
        from '@/modules/planning/components/settings/PlanningSettingsDisplay/pages/PlanningSettingsDisplay.vue';
    import SettingsGroups
        from '@/modules/planning/components/settings/PlanningSettingsGroup/pages/PlanningSettingsGroup.vue';

    defineProps({
        nursery: {
            type: Object,
            required: true,

        },
    });

    const route = useRoute();
    const selectedTab = ref(route.query.nav ?? 'plannings');
    const {pushQueryString} = useUrl();

    function changeSelectedTab(tab: string) {
        selectedTab.value = tab;
        pushQueryString('nav', tab);
    }
</script>

<template>
    <SimplePageTemplate>
        <template #title>
            <MPageTitle>
                {{ __('app:menu.plannings_settings') }}
            </MPageTitle>
        </template>
        <template #content>
            <CTabMenu class="tw-mb-4">
                <CTabMenuItem
                    :active="selectedTab === 'plannings'"
                    @click="changeSelectedTab('plannings')"
                >
                    <FontAwesomeIcon
                        class="tw-mr-2"
                        icon="fas fa-calendar-alt"
                    />
                    {{ __('common:display') }}
                </CTabMenuItem>
                <CTabMenuItem
                    :active="selectedTab === 'groups'"
                    @click="changeSelectedTab('groups')"
                >
                    <FontAwesomeIcon
                        class="tw-mr-2"
                        icon="fas fa-users"
                    />
                    {{ __('planning:groups_ands_supervision') }}
                </CTabMenuItem>
            </CTabMenu>
            <MBox class="tw-p-4">
                <SettingsPlannings
                    v-if="selectedTab === 'plannings'"
                    :nursery="nursery"
                />
                <SettingsGroups
                    v-if="selectedTab === 'groups'"
                    :nursery="nursery"
                />
            </MBox>
        </template>
    </SimplePageTemplate>
</template>
