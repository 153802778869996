import type {ConsumerType} from '@/modules/auth/personal-access-token/domain/ConsumerType';

type PaginatePersonalAccessTokenRequestMakeType = {
    perPage?: number,
    page?: number,
    consumerType: ConsumerType,
    userId: string,
};

export class PaginatePersonalAccessTokenRequest {
    constructor(
        public readonly perPage: number,
        public readonly page: number,
        public readonly consumer: ConsumerType,
        public readonly userId: string,
    ) {}

    public static make(makeType: PaginatePersonalAccessTokenRequestMakeType): PaginatePersonalAccessTokenRequest {
        return new PaginatePersonalAccessTokenRequest(
            makeType.perPage ?? 20,
            makeType.page ?? 1,
            makeType.consumerType,
            makeType.userId,
        );
    }
}
