<template>
    <div class="CFormGroup">
        <slot/>
    </div>
</template>

<script>
    export default {
        name: 'FormGroup',
    };
</script>

