<template>
    <CPopover
        ref="popover"
        placement="bottom"
    >
        <CButton
            :disabled="!(simulationList && can('update', 'registrations'))"
            variant="light"
            :zoom="true"
        >
            <i
                aria-hidden="true"
                class="fad fa-pencil"
            />
        </CButton>
        <template #content>
            <CVStack
                v-if="simulationList && can('update', 'registrations')"
                class="tw-w-64 tw-p-2"
                gap="4"
            >
                <SimulationListForm
                    ref="form"
                    :simulation-list="editedSimulationList"
                    @loading="loading = $event"
                    @saved="updateSimulationList"
                />
                <CHStack
                    distribute="end"
                    gap="2"
                >
                    <CButton @click="cancel">
                        {{ __('common:actions.cancel') }}
                    </CButton>
                    <CButton
                        :loading="loading"
                        variant="primary"
                        @click="$refs.form.save()"
                    >
                        {{ __('common:actions.save') }}
                    </CButton>
                </CHStack>
            </CVStack>
        </template>
    </CPopover>
</template>

<script lang="ts">
    import type {PropType, Ref} from 'vue';
    import {defineComponent, ref, watch} from 'vue';
    import SimulationListForm from '@/modules/planning/components/simulation/List/SimulationListForm.vue';
    import type SimulationListModel from '@/modules/planning/models/SimulationListModel';
    import useAbility from '@/modules/app/composables/useAbility';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';
    import __ from '@/modules/app/utils/i18n-facade';

    export default defineComponent({
        components: {SimulationListForm},
        props: {
            simulationList: {
                type: Object as PropType<SimulationListModel>,
                default: undefined,
            },
        },
        setup(props) {
            const editedSimulationList = ref() as Ref<SimulationListModel>;
            const popover = ref();
            const loading = ref(false);

            const {can} = useAbility();
            const {success} = useNotification();

            function cancel() {
                editedSimulationList.value.rollback();
                popover.value.hide();
            }

            function updateSimulationList(list) {
                list.registrations().mutate(() => props.simulationList.registrations().value());
                list.copyTo(props.simulationList as SimulationListModel);
                popover.value.hide();
                success(__('planning:list_edited_successfully'));
            }

            watch(() => props.simulationList, value => {
                if (value) {
                    editedSimulationList.value = value.clone();
                }
            }, {immediate: true});

            return {
                popover,
                editedSimulationList,
                cancel,
                updateSimulationList,
                loading,
                can,
            };
        },
    });
</script>
