<template>
    <div class="pt-1">
        <div
            v-if="message.manager_only"
            class="tw-text-sm tw-mr-auto tw-mb-2 tw--mt-1 tw-pb-1"
            :class="[message.is_note ? 'tw-text-gray-700' : 'tw-text-white']"
        >
            <i class="far fa-lock-alt mr-1"/>{{ __('request:manager_only') }}
        </div>
        <h6
            class="h6"
            :class="message.is_note ? 'text-dark' : 'text-white'"
        >
            <i
                v-if="message.loading"
                class="fa fa-spinner fa-spin"
            />
            {{ message.content }}
        </h6>
        <AttachedFiles
            v-if="message.documents && message.documents.length"
            class="tw-border-t tw-border-gray-200 tw-mt-2 tw-pt-1"
            :files="message.documents"
            :light-content="true"
            :member-id="message.family_member_id"
            :small="true"
        />
    </div>
</template>

<script>
    import moment from 'moment';
    import AttachedFiles from '@/modules/document/components/organisms/AttachedFiles.vue';

    export default {
        components: {
            AttachedFiles,
        },
        props: ['message'],
        computed: {
            moment() {
                return moment;
            },
        },
    };
</script>

<style
    lang="scss"
    scoped
>
    .content {
        color: #40638a;
    }
</style>
