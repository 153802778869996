<template>
    <CCard
        v-if="customerModel"
        body-size="xs"
        :border="false"
        :shadow="true"
    >
        <template #header>
            <div class="tw-flex tw-flex-wrap tw-items-center tw-justify-between tw-items-center">
                <CCardTitle>
                    <i
                        aria-hidden="true"
                        class="fad fa-map-marked-alt tw-text-blue-500 tw-mr-2"
                    />{{ __('common:billing_address_other') }}
                </CCardTitle>
                <CButton
                    :icon-plus="true"
                    @click="isCreateAddressModalVisible = true"
                >
                    {{ __('common:actions.add') }}
                </CButton>
                <CreateAddressModal
                    v-if="isCreateAddressModalVisible"
                    ref="modal"
                    :customer-model="customerModel"
                    @created="onAddressCreated"
                    @hidden="isCreateAddressModalVisible = false"
                />
            </div>
        </template>
        <AddressList
            ref="list"
            :customer-model="customerModel"
        />
    </CCard>
</template>

<script lang="ts">
    import type CustomerModel from '@/modules/cashier/models/CustomerModel';
    import type AddressModel from '@/modules/cashier/models/AddressModel';
    import CreateAddressModal from '@/modules/cashier/components/core/address/organisms/CreateAddressModal.vue';
    import AddressList from '@/modules/cashier/components/core/address/organisms/AddressList.vue';
    import type {PropType} from 'vue';
    import {defineComponent, ref} from 'vue';

    export default defineComponent({
        components: {AddressList, CreateAddressModal},
        props: {
            customerModel: {type: Object as PropType<CustomerModel>, required: true},
        },
        setup() {
            const isCreateAddressModalVisible = ref(false);
            const list = ref(null);

            function onAddressCreated(addressModel: AddressModel) {
                // @ts-ignore
                list.value.addToResult(addressModel);
            }

            return {
                list,
                onAddressCreated,
                isCreateAddressModalVisible,
            };
        },
    });
</script>

