import __ from '@/modules/app/utils/i18n-facade';

export type DevicePolicyType = {
    value: string;
    label: string;
    tooltip?: string;
};

export function kidDevicePolicies(): DevicePolicyType[] {
    return [
        {
            value: 'kid_attendances',
            label: __('common:clocking_in_other'),
        },
        {
            value: 'kid_kiosk',
            label: __('organization:clocking_in_parent'),
            tooltip: __('organization:code_required_to_clock_children'),
        },
        {
            value: 'meals',
            label: __('common:meal'),
        },
        {
            value: 'activities',
            label: __('common:activity_other'),
        },
        {
            value: 'naps',
            label: __('common:naps'),
        },
        {
            value: 'health',
            label: __('common:health'),
        },
        {
            value: 'diapers',
            label: __('common:diaper'),
        },
        {
            value: 'photos',
            label: __('common:photos'),
        },
        {
            value: 'observations',
            label: __('common:observation_other'),
        },
    ];
}

export function staffDevicePolicies(): DevicePolicyType[] {
    return [
        {
            value: 'staff_attendances',
            label: __('common:clocking_in_other'),
        },
        {
            value: 'staff_kiosk',
            label: __('organization:locked_clocking_in'),
        },
        {
            value: 'rooms',
            label: __('common:room_other'),
        },
        {
            value: 'equipment',
            label: __('common:equipment_one'),
        },
        {
            value: 'news',
            label: __('common:news'),
        },
        {
            value: 'documents',
            label: __('common:document_other'),
        },
        {
            value: 'staff_notes',
            label: __('organization:teams_notes'),
        },
    ];
}
