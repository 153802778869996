import type TemplateModel from '@/modules/human-resources/models/TemplateModel';
import type {WeeksDto} from '@/modules/human-resources/apply-template/application/dto/WeeksDto';
import type TemplatePlanningModel from '@/modules/human-resources/models/TemplatePlanningModel';
import {collect, type Collection, MqlOperation, ValidationError, ValidationErrorItem} from '@meekohq/lumos';
import type {
    ApplyTemplateRepositoryPort,
} from '@/modules/human-resources/apply-template/application/ports/ApplyTemplateRepositoryPort';
import type {CustomObject} from '@meekohq/lumos/dist/Http/ResponseObject';
import InvalidEvent from '@/modules/human-resources/apply-template/domain/InvalidEvent';

export class ApplyTemplateRepositoryAdapter implements ApplyTemplateRepositoryPort {
    public async applyTemplate(template: TemplateModel, weeks: WeeksDto, deleteRealEvents: boolean, fallbackOrganizationId: string): Promise<Collection<InvalidEvent>> {
        let response = collect();

        await new MqlOperation<CustomObject>('hr/calendar/template/apply', {
            template_id: template.getKey(),
            weeks: weeks.value,
            delete_real_events: deleteRealEvents,
            fallback_organization_id: fallbackOrganizationId,
        }).run().then(result => {
            response = this.parseResultToInvalidEvents(result.content);
        });

        return response;
    }

    public async applyTemplatePlanning(planning: TemplatePlanningModel, weeks: WeeksDto, deleteRealEvents: boolean, fallbackOrganizationId: string): Promise<Collection<InvalidEvent>> {
        let response = collect();

        await new MqlOperation<CustomObject>('hr/calendar/template/planning/apply', {
            planning_id: planning.getKey(),
            weeks: weeks.value,
            delete_real_events: deleteRealEvents,
            fallback_organization_id: fallbackOrganizationId,
        }).run().then(result => {
            response = this.parseResultToInvalidEvents(result.content);
        });

        return response;
    }

    private parseResultToInvalidEvents(result: CustomObject): Collection<InvalidEvent> {
        return collect(result.data).map(invalidEvent => {
            const validationItems = collect(invalidEvent.exception).map(error => {
                return new ValidationErrorItem(error.message, error.code, error.detail, error.source);
            });

            return new InvalidEvent(invalidEvent.event, new ValidationError(validationItems));
        });
    }
}
