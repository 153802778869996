import type {Ref} from 'vue';
import {computed} from 'vue';
import type MeetingParticipantModel from '@/modules/activity/models/MeetingParticipantModel';
import type {CustomSelectType} from '@/modules/activity/utils/CustomSelectType';
import type {ModelCollection} from '@meekohq/lumos';

export default function(customSelect: CustomSelectType, excludeParticipants: Ref<ModelCollection<MeetingParticipantModel>>) {
    const customSelectLabel = computed(() => customSelect.label);
    // Filter options not already selected (to exclude)
    const customSelectOptions = computed(() => {
        return customSelect.options.filter(option => {
            return excludeParticipants.value
                .where('attributes.first_name', option.value.firstname)
                .where('attributes.last_name', option.value.lastname)
                .where('attributes.email', option.value.email)
                .where('attributes.resource_id', option.value.resource_id)
                .where('attributes.resource_type', option.value.resource_type)
                .isEmpty();
        });
    });

    return {
        customSelectLabel,
        customSelectOptions,
    };
}
