<template>
    <CInput
        ref="input"
        align="right"
        :class="{'CInput--is-readonly': readonly}"
        v-bind="inputProps"
        :value="renderedValue"
        @blur="emitNumberValue"
        @input="emitNumberValue"
    />
</template>

<script lang="ts">
    import {defineComponent, onMounted, ref, watch} from 'vue';
    import useFormatNumber from '@/modules/cashier/composables/useFormatNumber';
    import {isNil} from 'lodash-es';

    export default defineComponent({
        components: {},
        props: {
            value: {
                type: Number,
                default: undefined,
            },
            currency: {
                type: String,
                required: true,
            },
            min: {
                type: Number,
                default: undefined,
            },
            max: {
                type: Number,
                default: undefined,
            },
            inputProps: {
                type: Object,
                default: () => ({}),
            },
            readonly: {
                type: Boolean,
                default: false,
            },
            variant: {
                type: String,
                default: 'border',
            },
            size: {
                type: String,
                default: '',
            },
            allowUndefined: {
                type: Boolean,
                default: true,
            },
        },
        setup(props, {emit}) {
            const input = ref();

            const renderedValue = ref<string | number>(props.value);

            const {bindToInput, set, getNumber, setReadonly, getInstance} = useFormatNumber(props.currency);

            onMounted(() => {
                // Set up CurrencyInput with the correct preset for the country code and bind with the template ref.
                bindToInput(input.value.$el, props.value);
                setReadonly(props.readonly);
                resetRenderedValue();
            });

            function resetRenderedValue() {
                renderedValue.value = getInstance().getFormatted();
            }

            /**
             * Watch for changes to the value prop and update the input only if the value prop is different
             * from the stored value in the input to avoid reactivity issues.
             */
            watch(() => props.value, newValue => {
                if (newValue !== getNumber()) {
                    set(newValue);
                    resetRenderedValue();
                }
            });

            watch(() => props.readonly, val => setReadonly(val));

            /**
             * Emit the current value of the input as a number.
             */
            function emitNumberValue() {
                const newValue = getNumber();

                if (isNil(newValue)) {
                    const min = props.min ?? 0;
                    set(props.allowUndefined ? null : min);
                }

                if (!isNil(props.min) && !isNil(newValue) && newValue < props.min) {
                    set(props.min);
                }

                if (!isNil(props.max) && !isNil(newValue) && newValue > props.max) {
                    set(props.max);
                }

                emit('input', getNumber());
                resetRenderedValue();
            }

            return {
                input,
                emitNumberValue,
                renderedValue,
            };
        },
    });
</script>
