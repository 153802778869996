import {app} from '@meekohq/lumos';
import type {AxiosInstance} from 'axios';
import {Types} from '@/types';

export default function() {
    return {
        api: app<AxiosInstance>(Types.Api),
        legacy: app<AxiosInstance>(Types.LegacyApi),
    };
}
