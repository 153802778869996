<template>
    <div class="tw-flex tw-flex-col tw-gap-8">
        <div class="tw-grid xl:tw-grid-cols-2 tw-grid-cols-1 tw-gap-12">
            <div>
                <MHeading class="tw-mb-4">
                    {{ __('billing_setting:general_settings') }}
                </MHeading>
                <CForm>
                    <CFormTwoColumns>
                        <CFormGroup>
                            <CLabel>{{ __('billing_setting:late_morning') }}</CLabel>
                            <MTimePicker
                                v-model="morningEndAt"
                                :modifier="startOfMinute"
                            />
                        </CFormGroup>
                        <CFormGroup>
                            <CLabel>{{ __('billing_setting:early_afternoon') }}</CLabel>
                            <MTimePicker
                                v-model="afternoonStartAt"
                                :modifier="startOfMinute"
                            />
                        </CFormGroup>
                    </CFormTwoColumns>
                    <CFormTwoColumns>
                        <CFormGroup>
                            <CLabel>{{ __('billing_setting:delay_health_deficiency') }}</CLabel>
                            <CInputGroup class="tw-w-full">
                                <CInput
                                    id="absence_waiting_period"
                                    v-model.number="nursery.absence_waiting_period"
                                    placeholder="3"
                                    type="number"
                                />
                                <CInputAddon class="tw-lowercase">
                                    <template v-if="nursery.absence_waiting_period && nursery.absence_waiting_period > 1">
                                        {{ __('common:day_other') }}
                                    </template>
                                    <template v-else>
                                        {{ __('common:day_one') }}
                                    </template>
                                </CInputAddon>
                            </CInputGroup>
                        </CFormGroup>
                        <CFormGroup>
                            <CLabel>{{ __('billing_setting:hours_number_in_a_day') }}</CLabel>
                            <CInputGroup class="tw-w-full">
                                <CInput
                                    id="hours_in_day"
                                    v-model.number="nursery.hours_in_day"
                                    placeholder="12"
                                    type="number"
                                />
                                <CInputAddon class="tw-lowercase">
                                    {{ __('common:hour', {count: nursery?.hours_in_day ?? 0}) }}
                                </CInputAddon>
                            </CInputGroup>
                        </CFormGroup>
                    </CFormTwoColumns>
                </CForm>
            </div>
            <div>
                <MHeading class="tw-mb-4">
                    {{ __('billing_setting:additional_parameters') }}
                </MHeading>
                <CForm>
                    <CFormTwoColumns v-if="showCAF()">
                        <CFormGroup>
                            <CLabel>{{ __('billing_setting:do_you_have_mandate_caf') }}</CLabel>
                            <CButtonGroup class="tw-w-full">
                                <MButton
                                    v-for="option in cafMandateOptions"
                                    :key="option.value"
                                    class="tw-w-1/3"
                                    :label="option.text"
                                    :variant="nursery.caf_mandate === option.value ? 'primary' : 'light'"
                                    @click="nursery.caf_mandate = option.value"
                                />
                            </CButtonGroup>
                        </CFormGroup>
                        <CFormGroup>
                            <CLabel>{{ __('billing_setting:paje_certificate_template') }}</CLabel>
                            <CFormSelect
                                v-model="nursery.cmg_certif_id"
                                :options="pdfDocuments"
                                :search-bar="false"
                                text-path="name"
                                value-path="id"
                            />
                        </CFormGroup>
                    </CFormTwoColumns>
                    <CFormTwoColumns>
                        <CFormGroup>
                            <CLabel>{{ __('billing_setting:hourly_rate_not_to_be_exceeded') }}</CLabel>
                            <CInputGroup class="tw-w-full">
                                <CInput
                                    id="hour_price_limit"
                                    v-model.number="nursery.hour_price_limit"
                                    placeholder="10"
                                    type="number"
                                />
                                <CInputAddon class="tw-lowercase tw-shrink-0">
                                    {{ __('common:currency_per_hour', {currency: nursery.currency}) }}
                                </CInputAddon>
                            </CInputGroup>
                        </CFormGroup>
                        <CFormGroup>
                            <CLabel>{{ __('billing_setting:number_hour_desired_per_month') }}</CLabel>
                            <CInputGroup class="tw-w-full">
                                <CInput
                                    id="min_hours_in_month"
                                    v-model.number="nursery.min_hours_in_month"
                                    placeholder="16"
                                    type="number"
                                />
                                <CInputAddon class="tw-lowercase">
                                    {{ __('common:hour', {count: nursery?.min_hours_in_month ?? 0}) }}
                                </CInputAddon>
                            </CInputGroup>
                        </CFormGroup>
                    </CFormTwoColumns>
                    <CFormTwoColumns>
                        <CFormGroup>
                            <CLabel>{{ __('billing_setting:does_he_include_meals') }}</CLabel>
                            <CButtonGroup class="tw-w-full">
                                <MButton
                                    v-for="option in booleanOptions"
                                    :key="option.value"
                                    class="tw-w-1/2"
                                    :label="option.text"
                                    :variant="nursery.incl_meals_in_price === option.value ? 'primary' : 'light'"
                                    @click="nursery.incl_meals_in_price = option.value"
                                />
                            </CButtonGroup>
                        </CFormGroup>
                        <CFormGroup>
                            <CLabel>{{ __('billing_setting:does_he_include_health_care') }}</CLabel>
                            <CButtonGroup class="tw-w-full">
                                <MButton
                                    v-for="option in booleanOptions"
                                    :key="option.value"
                                    class="tw-w-1/2"
                                    :label="option.text"
                                    :variant="nursery.incl_health_in_price === option.value ? 'primary' : 'light'"
                                    @click="nursery.incl_health_in_price = option.value"
                                />
                            </CButtonGroup>
                        </CFormGroup>
                    </CFormTwoColumns>
                </CForm>
            </div>
        </div>
        <MButton
            class="tw-self-end"
            variant="primary"
            @click="save"
        >
            {{ __('common:actions.save') }}
        </MButton>
    </div>
</template>

<script>
    import _head from 'lodash-es/head';
    import _forEach from 'lodash-es/forEach';
    import route from '@/modules/legacy/libs/ziggy';
    import useApi from '@/modules/app/composables/useApi';
    import {nursery} from '@/modules/legacy/mixins/nursery';
    import __ from '@/modules/app/utils/i18n-facade';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';
    import {Epoch} from '@meekohq/lumos';
    import {useDateTimeModifiersPresets} from '@/modules/meeko-ui/composables/useDateTimeModifiers';

    export default {
        mixins: [nursery],
        props: {
            pdfDocuments: {
                type: Array,
                required: true,
            },
            nursery: {
                type: Object,
                required: true,
            },
        },
        data() {
            return {
                booleanOptions: [{
                    text: __('common:yes'),
                    value: true,
                }, {
                    text: __('common:no'),
                    value: false,
                }],
                cafMandateOptions: [{
                    text: __('common:no'),
                    value: 'none',
                }, {
                    text: __('common:individual'),
                    value: 'individual',
                }, {
                    text: __('common:collective'),
                    value: 'collective',
                }],
                morningEndAt: this.nursery.morning_end_at ? Epoch.parse(this.nursery.morning_end_at, 'HH:mm:ss') : Epoch.now(),
                afternoonStartAt: this.nursery.afternoon_start_at ? Epoch.parse(this.nursery.afternoon_start_at, 'HH:mm:ss') : Epoch.now(),
            };
        },
        methods: {
            save() {
                useApi().legacy.put(route('nurseries.update', {nurseries: this.nursery.id}), {
                    morning_end_at: this.morningEndAt.toFormat('HH:mm:ss'),
                    afternoon_start_at: this.afternoonStartAt.toFormat('HH:mm:ss'),
                    hours_in_day: this.nursery.hours_in_day,
                    min_hours_in_month: this.nursery.min_hours_in_month,
                    incl_meals_in_price: this.nursery.incl_meals_in_price,
                    incl_health_in_price: this.nursery.incl_health_in_price,
                    hour_price_limit: this.nursery.hour_price_limit,
                    caf_mandate: this.nursery.caf_mandate,
                    cmg_certif_id: this.nursery.cmg_certif_id,
                    absence_waiting_period: this.nursery.absence_waiting_period,
                }).then(() => {
                    useNotification().success(__('common:parameters_updated_successfully'));
                }).catch(error => {
                    if (error.response && error.response.status === 422) {
                        _forEach(error.response.data.errors, value => {
                            useNotification().error(_head(value));
                        });
                    } else {
                        useNotification().error(error);
                    }
                });
            },

            showCAF() {
                return this.$showCAF(this.nursery.country);
            },

            startOfMinute: useDateTimeModifiersPresets().startOfMinute,
        },
    };
</script>
