<template>
    <div class="position-relative">
        <div class="tw-bg-blue-50 tw-p-4 tw-text-center">
            <MHeading>
                {{ __('family_member:latest_exchanges') }}
            </MHeading>
        </div>

        <div
            v-show="!loading"
            id="chat-container"
        >
            <div
                v-if="timelineLoading"
                class="text-center"
                style="height: 60px;"
            >
                <transition
                    mode="out-in"
                    name="zoom"
                >
                    <img
                        v-show="timelineLoading"
                        :alt="__('common:loading_dots')"
                        height="20"
                        :src="DualRingImage"
                        style="width: 15%;"
                        with="20"
                    >
                </transition>
            </div>
            <div
                v-if="messages && messages.data && messages.data.length === 0"
                class="tw-flex tw-h-full tw-px-4 tw-items-center tw-justify-center"
            >
                <div class="tw-text-gray-600">
                    {{ __('family_member:chat.empty_message') }}
                </div>
            </div>
            <div
                v-else
                class="container timeline m-0"
            >
                <div class="row">
                    <div class="w-100">
                        <article
                            v-for="(message, i) in messages.data"
                            :key="i"
                            class="timeline-entry"
                        >
                            <div class="timeline-entry-inner">
                                <div class="timeline-time text-capitalize">
                                    <MTooltip
                                        :label="$formatDate(moment.unix(message.created_at), 'DD MMMM YYYY')"
                                        placement="top"
                                    >
                                        <span>{{ $formatDate(moment.unix(message.created_at), 'ddd DD MMM') }}</span>
                                    </MTooltip>
                                    <span>{{ moment.unix(message.created_at).format('HH:mm') }}</span>
                                </div>
                                <!-- bg-primary / bg-warning / etc -->
                                <div :class="'timeline-icon bg-' + getBgColor(message)">
                                    <i
                                        v-if="message.loading"
                                        class="position-absolute fa fa-spinner fa-spin"
                                        style="top: 2px; left: 2px;"
                                    />
                                </div>
                                <div
                                    class="timeline-label"
                                    :class="[message.sent_by === 'nursery' ? message.is_note ? 'note' : 'sent' : 'recieve', message.type]"
                                >
                                    <p>
                                        <MessageRequest
                                            v-if="message.type === 'message'"
                                            :key="'messages-' + message.id"
                                            :message="message"
                                            :style="message.sent_by === 'family' ? 'text-align:left;' : 'text-align:right;'"
                                        />
                                        <AbsenceRequest
                                            v-if="message.type === 'absence'"
                                            :key="'absences-' + message.id"
                                            :message="message"
                                            :style="message.sent_by === 'family' ? 'text-align:left;' : 'text-align:right;'"
                                        />
                                        <OccasionalRequest
                                            v-if="message.type === 'occasional'"
                                            :key="'occasional-' + message.id"
                                            :message="message"
                                            :style="message.sent_by === 'family' ? 'text-align:left;' : 'text-align:right;'"
                                        />
                                        <LateRequest
                                            v-if="message.type === 'late'"
                                            :key="'late-' + message.id"
                                            :message="message"
                                            :style="message.sent_by === 'family' ? 'text-align:left;' : 'text-align:right;'"
                                        />
                                    </p>
                                </div>
                                <div
                                    v-if="message.sent_by === 'nursery' && message.seen_at"
                                    class="timeline-seen text-right text-muted ml-auto mr-4"
                                >
                                    <span>
                                        {{
                                            __('family_member:chat.seen_at_with_date', {
                                                date: epoch.fromTimestamp(message.seen_at).toLocaleString(epoch.presets.DATETIME_SHORT),
                                            })
                                        }}
                                    </span>
                                </div>
                                <div
                                    v-if="message.is_note"
                                    class="timeline-seen text-right text-muted ml-auto mr-4"
                                >
                                    <span><i
                                        aria-hidden="true"
                                        class="fa fa-pencil"
                                    /> {{ __('common:note_one') }}</span>
                                </div>
                            </div>
                        </article>
                    </div>
                </div>
            </div>
        </div>
        <div
            v-if="loading"
            key="loader"
        >
            <loader
                custom-class="la-2x"
                light="true"
                shadow="false"
                size="sm"
            />
        </div>
        <div
            v-if="$can('create', 'family_members_chat')"
            class="tw-flex tw-flex-col tw-gap-2 tw-p-4 tw-bg-blue-50"
        >
            <CButtonGroup class="tw-w-full">
                <MButton
                    class="tw-w-1/2"
                    :variant="newMessage.is_note === false ? 'primary' : 'light'"
                    @click="newMessage.is_note = false"
                >
                    {{ __('common:message_one') }}
                </MButton>
                <MButton
                    class="tw-w-1/2"
                    :variant="newMessage.is_note === true ? 'primary' : 'light'"
                    @click="newMessage.is_note = true"
                >
                    {{ __('common:note_one') }}
                </MButton>
            </CButtonGroup>
            <CButtonGroup class="tw-w-full tw-my-3">
                <CFormTextArea
                    v-model="newMessage.content"
                    :class="{'!tw-bg-yellow-100': newMessage.is_note}"
                    :placeholder="newMessage.is_note ? __('family_member:chat.add_private_note') : __('family_member:chat.write_message_dots')"
                    rows="2"
                />
                <MButton
                    class="tw-shrink-0"
                    :label="newMessage.is_note ? __('common:actions.add') : __('common:actions.send')"
                    :loading="sending"
                    variant="primary"
                    @click="store"
                />
            </CButtonGroup>
            <MTooltip
                v-if="$can('create', 'family_members_chat_manager_only')"
                class="tw-w-fit"
                :label="__('family_member:check_this_box_if_you_do_not_want_your_message_to_appear_on_meeko_team')"
                placement="top"
            >
                <CCheckbox v-model="newMessage.manager_only">
                    {{ __('family_member:chat.visible_for_management_only') }}
                </CCheckbox>
            </MTooltip>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';
    import route from '@/modules/legacy/libs/ziggy';
    import useApi from '@/modules/app/composables/useApi';
    import moment from 'moment';
    import _cloneDeep from 'lodash-es/cloneDeep';
    import _concat from 'lodash-es/concat';
    import _head from 'lodash-es/head';
    import _forEach from 'lodash-es/forEach';
    import DualRingImage from '@/assets/images/DualRing-Dark.svg';
    import MessageRequest from './Types/MessageRequest.vue';
    import AbsenceRequest from './Types/AbsenceRequest.vue';
    import OccasionalRequest from './Types/OccasionalRequest.vue';
    import LateRequest from './Types/LateRequest.vue';
    import useBroadcast from '@/modules/app/composables/useBroadcast';
    import useNotificationStore from '@/modules/app/composables/useNotificationStore';
    import {Epoch} from '@meekohq/lumos';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';

    export default {
        components: {
            MessageRequest,
            AbsenceRequest,
            OccasionalRequest,
            LateRequest,
        },
        props: {
            familyMember: {
                type: Object,
                required: true,
            },
            nursery: {
                type: Object,
                required: true,
            },
            user: {
                type: Object,
                required: true,
            },
        },
        data: () => ({
            DualRingImage,
            messages: [],
            newMessage: {
                type: 'message',
                content: null,
                is_note: false,
                family_member_id: null,
            },
            newMessageFetching: false,
            loading: false,
            sending: false,
            timelineLoading: false,
            last_loaded_page: 0,
            last_page: null,
            messageOptions: [
                {
                    text: 'Message',
                    value: false,
                },
                {
                    text: 'Note',
                    value: true,
                },
            ],
            epoch: Epoch,
        }),
        computed: {
            moment() {
                return moment;
            },
            notificationStore() {
                return useNotificationStore();
            },
        },
        watch: {
            'familyMember.id': function() {
                this.last_loaded_page = 0;
                this.newMessage.family_member_id = this.familyMember.id;
                this.getMessages(false);
                this.notification = this.notificationStore.messagePending.value ?? null;
            },
        },
        mounted() {
            const self = this;

            this.$bus.$on('refreshChatMessage', notification => {
                const notif = this.messages.data.find(item => item.id === notification.referenceable.id);
                if (notif) {
                    const index = this.messages.data.indexOf(notif);
                    Vue.set(this.messages.data, index, notification.referenceable);
                }
            });

            if (this.notificationStore.messagePending.value) {
                this.notification = this.notificationStore.messagePending.value;
            }

            $('#chat-container').on('scroll', function() {
                if (!self.timelineLoading && ($(this).scrollTop() <= 0)) {
                    self.getMessages(true);
                }
            });

            this.newMessage.family_member_id = this.familyMember.id;

            this.getMessages(false);

            useBroadcast().privateChannel.bind('Meeko\\Family\\Events\\MessageSent', e => {
                if (e.family_member_id === this.familyMember.id) {
                    this.messages.data.push(e);
                    this.showMessage(e);
                    setTimeout(() => {
                        this.resetScroll();
                    }, 300);
                }
            });
        },
        beforeDestroy() {
            this.$bus.$off('refreshChatMessage');
            this.notificationStore.setMessagePending(undefined);
        },
        destroyed() {
            $('#chat-container').off('scroll');
        },
        methods: {
            getMessages(newPage) {
                if (this.loading || (newPage && this.last_loaded_page === this.last_page)) {
                    return;
                }

                if (newPage) {
                    this.timelineLoading = true;
                } else {
                    this.loading = true;
                }

                useApi().legacy.get(route('nurseries.familyMembers.messages.index', {
                    nurseries: this.familyMember.nursery_id,
                    familyMember: this.familyMember.id,
                }), {
                    params: {
                        page: this.last_loaded_page + 1,
                    },
                }).then(response => {
                    if (response.data.current_page === 1) {
                        this.messages = response.data;
                        this.messages.data.reverse();
                        const lastMessage = this.messages && this.messages.data ? this.messages.data[this.messages.data.length - 1] : null;
                        this.newMessage.manager_only = (lastMessage && lastMessage.manager_only && this.$can('create', 'family_members_chat_manager_only'));
                    } else {
                        const chatContainer = $('#chat-container');
                        const sizeBefore = chatContainer && chatContainer[0] ? chatContainer[
                            0].scrollHeight : 0;
                        this.messages.data = _concat(response.data.data.reverse(), this.messages.data);
                        // KEEP SCROLL IN POSITION
                        setTimeout(() => {
                            if (chatContainer && chatContainer[0]) {
                                chatContainer[0].scrollTop = chatContainer[0].scrollHeight -
                                    (sizeBefore + 40);
                            }
                        }, 200);
                    }

                    this.last_loaded_page = response.data.current_page;
                    this.last_page = response.data.last_page;

                    if (newPage) {
                        this.timelineLoading = false;
                    } else {
                        this.loading = false;
                        this.resetScroll(100);
                    }
                }).catch(error => {
                    this.loading = false;
                    if (error && error.response && error.response.status === 422) {
                        _forEach(error.response.data.errors, function(value) {
                            useNotification().error(_head(value));
                        });
                    } else {
                        useNotification().error(error);
                    }
                });
            },

            showMessage(message) {
                Vue.set(message, 'loading', true);
                useApi().legacy.get(route('nurseries.messages.show', {
                    nurseries: this.familyMember.nursery_id,
                    message: message.id,
                })).then(response => {
                    Vue.set(message, 'loading', false);
                    const messageToUpdate = this.messages.data.find(item => item.id === message.id);
                    if (messageToUpdate) {
                        const index = this.messages.data.indexOf(messageToUpdate);
                        Vue.set(this.messages.data, index, response.data);
                    }
                }).catch(error => {
                    Vue.set(message, 'loading', false);
                    if (error && error.response && error.response.status === 422) {
                        _forEach(error.response.data.errors, function(value) {
                            useNotification().error(_head(value));
                        });
                    } else {
                        useNotification().error(error);
                    }
                });
            },

            store() {
                this.sending = true;

                useApi().legacy.post(route('nurseries.messages.store', {
                    nurseries: this.familyMember.nursery_id,
                }), this.newMessage)
                    .then(response => {
                        this.messages.data.push(response.data);
                        this.newMessage.content = '';
                        if (this.notification) {
                            const myNotif = _cloneDeep(this.notification);
                            myNotif.state = 'answered';
                            this.$bus.$emit('update:notification', myNotif);
                            this.notification = null;
                            this.notificationStore.setMessagePending(undefined);
                        }
                        this.sending = false;
                        this.resetScroll();
                    })
                    .catch(error => {
                        this.sending = false;
                        if (error && error.response && error.response.status === 422) {
                            _forEach(error.response.data.errors, function(value) {
                                useNotification().error(_head(value));
                            });
                        } else {
                            useNotification().error(error);
                        }
                    });
            },

            getBgColor(message) {
                switch (message.type) {
                    case 'absence':
                        return 'danger';
                    case 'occasional':
                        return 'primary';
                    case 'late':
                        return 'warning';
                    case 'message':
                        return message.is_note ? 'light' : 'info';
                    default:
                        return 'bg-success';
                }
            },

            resetScroll(time) {
                const delay = time || 600;

                setTimeout(() => {
                    const chatContainer = $('#chat-container');

                    if (chatContainer && chatContainer[0]) {
                        chatContainer.animate({
                            scrollTop: chatContainer[0].scrollHeight,
                        }, delay);
                    }
                }, 300);
            },
        },
    };
</script>

<style
    lang="scss"
    scoped
>
    .title {
        background: #f4faff;
    }

    .textarea {
        background: #fef5d8;
    }

    #chat-container {
        height: calc(80vh - 100px);
        overflow-y: auto;
    }

    .timeline {
        position: relative;

        &:before {
            content: '';
            position: absolute;
            display: block;
            width: 4px;
            height: 100%;
            background: #f5f5f6;
            left: 25%;
            top: 20px;
            bottom: 20px;
            margin-left: 8px;
        }

        .timeline-entry {
            position: relative;
            width: 75%;
            float: right;
            margin-bottom: 30px;

            .timeline-entry-inner {
                .timeline-time {
                    position: absolute;
                    left: -90px;
                    text-align: right;
                    padding: 3px 0;

                    > span {
                        display: block;
                    }

                    > span:first-child {
                        font-size: 12px;
                        font-weight: bold;
                    }

                    > span:last-child {
                        font-size: 12px;
                        opacity: .8;
                    }
                }

                .timeline-icon {
                    position: relative;
                    background: #fff;
                    color: white;
                    display: block;
                    width: 20px;
                    height: 20px;
                    border-radius: 14px;
                    box-shadow: 0 0 0 5px #f5f5f6;
                    margin-top: 5px;
                    float: left;
                }

                .timeline-label {
                    position: relative;
                    margin-left: 40px;
                    width: 80%;
                    padding: 5px 0;

                    p > div {
                        color: #40638a;
                    }

                    &.message {
                        p > div {
                            color: white;
                        }

                        background: #17a2b8;
                        padding: 10px 20px;
                        border-radius: 14px;
                        max-width: 70%;

                        &::after {
                            content: '';
                            display: block;
                            position: absolute;
                            width: 0;
                            height: 0;
                            border-style: solid;
                            border-width: 15px 17px 15px 0;
                            border-color: transparent #17a2b8 transparent transparent;
                        }

                        &.recieve {
                            &::after {
                                left: -7px;
                                top: 1px;
                                transform: rotate(15deg);
                            }
                        }

                        &.sent {
                            background: #1bc6ce;
                            margin-right: 20px;
                            margin-left: auto;

                            &::after {
                                border-color: transparent #1bc6ce transparent transparent;
                                right: -6px;
                                transform: rotate(200deg);
                                bottom: -2px;
                            }
                        }

                        &.note {
                            background: #fdecb2;
                            margin-right: 20px;
                            margin-left: auto;

                            &::after {
                                border-color: transparent #fdecb2 transparent transparent;
                                right: -6px;
                                transform: rotate(200deg);
                                bottom: -2px;
                            }
                        }
                    }

                    h2,
                    p {
                        color: white;
                        font-size: 12px;
                        margin: 0;
                        line-height: 1.4;
                    }

                    p + p {
                        margin-top: 15px;
                    }

                    h2 {
                        font-size: 16px;
                        margin-bottom: 10px;
                    }
                }

                .timeline-seen {
                    span {
                        font-size: 11px;
                        font-weight: bold;
                    }
                }
            }
        }
    }
</style>
