import type {ModelAvailableAttributesType, ValidationError} from '@meekohq/lumos';
import type EventModel from '@/modules/human-resources/models/EventModel';

export default class InvalidEvent {
    protected event: ModelAvailableAttributesType<EventModel>;
    protected exception: ValidationError;

    constructor(event: ModelAvailableAttributesType<EventModel>, exception: ValidationError) {
        this.event = event;
        this.exception = exception;
    }

    public getEvent(): ModelAvailableAttributesType<EventModel> {
        return this.event;
    }

    public getException(): ValidationError {
        return this.exception;
    }
}
