import {computed, type ComputedRef, ref, type Ref} from 'vue';
import mitt, {type Emitter} from 'mitt';
import {collect, type Collection} from '@meekohq/lumos';
import TransactionModel from '@/modules/cashier/transaction/domain/TransactionModel';
import TenantModel from '@/modules/cashier/models/TenantModel';
import CurrencyModel from '@/modules/cashier/models/CurrencyModel';
import CustomerModel from '@/modules/cashier/models/CustomerModel';
import PaymentModel from '@/modules/cashier/models/PaymentModel';
import AllocationModel from '@/modules/cashier/models/AllocationModel';
import InvoiceModel from '@/modules/cashier/models/InvoiceModel';
import PaymentMethodModel from '@/modules/cashier/models/PaymentMethodModel';
import useError from '@/modules/app/composables/useError';
import _round from 'lodash-es/round';
import {isNil} from 'lodash-es';

export type TransactionEmitter = {
    error: Error,
    'reset-error': void,
    saved: TransactionModel,
    deleted: TransactionModel,
    hide: void
};

export type TransactionStateType = {
    loading: Ref<boolean>,
    bus: Emitter<TransactionEmitter>,
    transaction: Ref<TransactionModel>,
    tenant: Ref<TenantModel | undefined>,
    customer: Ref<CustomerModel | undefined>,
    paymentMethod: Ref<PaymentMethodModel | undefined>,
    currency: Ref<CurrencyModel | undefined>,
    allocations: Ref<Collection<AllocationModel>>,
    invoiceAllocation: Ref<AllocationModel | undefined>
    transactionError: ReturnType<typeof useError>,
    sumOtherAllocations: ComputedRef<number>,
    isOverAllocated: ComputedRef<boolean>,
    isInsufficientlyAllocated: ComputedRef<boolean>,
};

export default function useTransactionState(): TransactionStateType {
    const loading = ref(false);
    /**
     * States
     */
    const bus = mitt<TransactionEmitter>();
    const transaction = ref(new TransactionModel()) as Ref<TransactionModel>;
    const tenant = ref<TenantModel | undefined>();
    const customer = ref<CustomerModel | undefined>();
    const paymentMethod = ref<PaymentMethodModel | undefined>();
    const currency = ref<CurrencyModel | undefined>();
    const allocations = ref(collect()) as Ref<Collection<AllocationModel>>;
    const invoiceAllocation = ref<AllocationModel | undefined>();
    const transactionError = useError();

    const sumOtherAllocations = computed(() => {
        return allocations.value.filter(allocation => !allocation.markedForDeletion)
            .sum(allocation => allocation.attributes.amount ?? 0);
    });

    const isOverAllocated = computed(() => {
        if (!isNil(transaction.value.attributes.amount)
            && (sumOtherAllocations.value || invoiceAllocation.value)
        ) {
            const totalAllocated = (sumOtherAllocations.value + (invoiceAllocation.value?.attributes.amount ?? 0));

            return transaction.value.attributes.amount > _round(totalAllocated, 2);
        }

        return false;
    });

    const isInsufficientlyAllocated = computed(() => {
        if (!isNil(transaction.value.attributes.amount)
            && (sumOtherAllocations.value || invoiceAllocation.value)
        ) {
            const totalAllocated = (sumOtherAllocations.value + (invoiceAllocation.value?.attributes.amount ?? 0));

            return transaction.value.attributes.amount < _round(totalAllocated, 2);
        }

        return false;
    });

    return {
        loading,
        bus,
        transaction,
        tenant,
        customer,
        paymentMethod,
        currency,
        allocations,
        invoiceAllocation,
        transactionError,
        sumOtherAllocations,
        isOverAllocated,
        isInsufficientlyAllocated,
    };
}

/**
 * Set the state up to create a new transaction with every data it needs to create the model and the payment flow
 */
export async function setupStoreForCreation(
    transactionStore: TransactionStateType,
    tenantId: string,
    customerId: string,
    transactionAttributes: Partial<TransactionModel['attributes']>,
) {
    transactionStore.loading.value = true;

    // Init a new transaction
    transactionStore.transaction.value = new TransactionModel();
    transactionStore.transaction.value.attributes.amount = 0;
    transactionStore.transaction.value.fill(transactionAttributes);

    // Associate it with the tenant, tenant currency, customer and payment method
    if (customerId) {
        transactionStore.customer.value = await CustomerModel.find(customerId);
    }

    transactionStore.tenant.value = await TenantModel.find(tenantId);

    transactionStore.currency.value = await CurrencyModel.query()
        .where('tenant_id', tenantId)
        .first();

    // We associate the 'transfer' payment method by default
    transactionStore.paymentMethod.value = await PaymentMethodModel.query()
        .where('tenant_id', tenantId)
        .where('internal_id', PaymentMethodModel.TRANSFER_INTERNAL_ID)
        .first();

    transactionStore.loading.value = false;
}

/**
 * Set the state up to update a transaction with every relationship it needs to update the model and the payment flow.
 * Clone the transaction and its relations to avoid updating the original transaction
 */
export async function setupStoreForEdition(
    transactionStore: TransactionStateType,
    transactionId: string,
    paymentId?: string,
) {
    transactionStore.loading.value = true;

    transactionStore.transaction.value = await TransactionModel.query()
        .with(new TransactionModel().tenant())
        .with(new TransactionModel().customer())
        .with(new TransactionModel().paymentMethod())
        .with(new TransactionModel().currency())
        .with(new TransactionModel().allocationsAsSource(), query2 => {
            query2.with(new AllocationModel().destination(), query3 => {
                query3.with(new PaymentModel().invoices(), query4 => {
                    query4.with(new InvoiceModel().payments());
                });
            });
        })
        .with(new TransactionModel().allocationsAsDestination(), query2 => {
            query2.with(new AllocationModel().source(), query3 => {
                query3.with(new PaymentModel().creditNotes(), query4 => {
                    query4.with(new InvoiceModel().refunds());
                });
            });
        }).find(transactionId);

    // Clone the transaction and its relations for update
    transactionStore.transaction.value = transactionStore.transaction.value.clone();
    transactionStore.tenant.value = transactionStore.transaction.value.tenant().value().clone();
    transactionStore.customer.value = transactionStore.transaction.value.customer().value().clone();
    transactionStore.paymentMethod.value = transactionStore.transaction.value.paymentMethod().value()?.clone();
    transactionStore.currency.value = transactionStore.transaction.value.currency().value().clone();

    if (transactionStore.transaction.value.isDebit) {
        transactionStore.allocations.value = transactionStore.transaction.value?.allocationsAsDestination().value().map(allocation => {
            mapPaymentToAllocation(allocation);
            allocation.setRelation('source', allocation.extra.payment);

            return allocation;
        }) as Collection<AllocationModel>;
    } else {
        transactionStore.allocations.value = transactionStore.transaction.value?.allocationsAsSource().value().map(allocation => {
            mapPaymentToAllocation(allocation);
            allocation.setRelation('destination', allocation.extra.payment);

            return allocation;
        }) as Collection<AllocationModel>;
    }

    // If paymentId is set, we filter allocations to find the allocation related to the payment
    if (paymentId) {
        transactionStore.allocations.value = transactionStore.allocations.value.filter(allocation => {
            if (allocation.extra.payment.getKey() === paymentId) {
                transactionStore.invoiceAllocation.value = allocation;
            }

            return allocation.extra.payment.getKey() !== paymentId;
        });
    }

    transactionStore.transaction.value.extra.originalAllocationsCount = transactionStore.allocations.value.count();
    transactionStore.loading.value = false;
}

// Map payment to allocation data needed for the UI pruposes
export function mapPaymentToAllocation(allocation: AllocationModel) {
    const payment = allocation.source().value() ? allocation.source().value() : allocation.destination().value();

    // A payment can only have one destination or source invoice
    const invoice = payment.invoicesRelation.first();
    const hasInvoiceMultiPayments = invoice.paymentsRelation.count() > 1;

    allocation.extra.payment = payment.clone();
    allocation.extra.invoice = invoice.clone();
    allocation.extra.hasInvoiceMultiPayments = hasInvoiceMultiPayments;
    allocation.extra.invoicePayments = invoice.paymentsRelation;

    return allocation;
}
