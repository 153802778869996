<template>
    <CModal
        ref="modal"
        :header-title="title"
        @hidden="hide"
        @shown="onShown"
    >
        <loader
            v-if="loader.isLoading('modal')"
            light="false"
            shadow="false"
            :title="__('common:loading_dots')"
        />
        <template v-else>
            <template v-if="showResult">
                <CHStack
                    v-if="sendByEmail"
                    align="center"
                    distribute="center"
                    gap="4"
                >
                    <CInline
                        align-x="around"
                        align-y="center"
                        class="tw-w-64"
                        space="lg"
                    >
                        <i
                            aria-hidden="true"
                            class="tw-text-4xl fa fa-check-circle tw-text-green-500"
                        />
                        <CText size="md">
                            {{ __('hr_calendar:receiver_email_with_instruction', {email: email}) }}
                        </CText>
                    </CInline>
                </CHStack>
                <CVStack
                    v-else
                    gap="4"
                >
                    <CParagraph margin="0">
                        {{ __('hr_calendar:can_subscribe_to_planning_using_urls_below_colon') }}
                    </CParagraph>
                    <div
                        v-for="(staff, index) in selectedStaffs"
                        class="tw-flex tw-flex-col tw-gap-4"
                    >
                        <div
                            :key="index"
                            class="tw-flex sm:tw-flex-row tw-flex-col sm:tw-items-center sm:tw-gap-4 tw-gap-1"
                        >
                            <div class="tw-flex-1 tw-truncate">
                                {{ staff.fullname }}
                            </div>
                            <div class="tw-flex tw-flex-row tw-items-center tw-gap-4">
                                <CInput
                                    :readonly="true"
                                    :value="staff.extra.url"
                                    width="1/2"
                                />
                                <CButton
                                    class="tw-shrink-0"
                                    :label="__('common:actions.copy')"
                                    variant="primary"
                                    @click="copyUrlToClipboard(staff.extra.url)"
                                >
                                    <template #left-icons>
                                        <FontAwesomeIcon icon="fas fa-clipboard"/>
                                    </template>
                                </CButton>
                            </div>
                        </div>
                    </div>
                </CVStack>
            </template>
            <template v-else>
                <CVStack
                    align="stretch"
                    gap="4"
                >
                    <CList v-if="!oneStaffModel">
                        <CParagraph
                            margin="3"
                            size="lg"
                        >
                            {{ __('hr_calendar:which_calendars_subscribe') }}
                        </CParagraph>
                        <CCheckbox
                            v-for="(staff, staffIndex) in staffModelCollection"
                            :key="'staffId' + staffIndex"
                            v-model="staff.extra.checked"
                        >
                            {{ staff.fullname }}
                        </CCheckbox>
                    </CList>

                    <CVStack gap="4">
                        <CButtonGroup class="tw-w-full">
                            <CButton
                                class="tw-w-1/2"
                                :variant="sendByEmail ? 'primary' : 'light'"
                                @click="toggleSendingMethod(true)"
                            >
                                {{ __('hr_calendar:send_by_mail') }}
                            </CButton>
                            <CButton
                                class="tw-w-1/2"
                                :variant="!sendByEmail ? 'primary' : 'light'"
                                @click="toggleSendingMethod(false)"
                            >
                                {{ __('hr_calendar:get_urls') }}
                            </CButton>
                        </CButtonGroup>
                        <CFormGroup v-if="sendByEmail">
                            <CLabel>
                                {{ __('common:email') }}*
                            </CLabel>
                            <CInput
                                v-model="email"
                                :has-error="staffError.hasError('emails.0')"
                            />
                            <CFormErrorMessageList
                                :errors="staffError.getErrors('emails.0')"
                            />
                        </CFormGroup>
                        <CFormGroup v-else>
                            <CLabel>
                                {{ __('common:recipient') }}
                                <CHelpTooltip>
                                    {{ __('hr_calendar:to_remember_who_you_sent_these_schedules_to') }}
                                </CHelpTooltip>
                            </CLabel>
                            <CInput v-model="description"/>
                        </CFormGroup>
                        <CDisclosure
                            :open="false"
                            :title="__('hr_calendar:manage_access_subscription')"
                        >
                            <div
                                v-for="(calendar, index) in calendarModels"
                                :key="calendar.id + index"
                            >
                                <div class="tw-w-full tw-flex sm:tw-flex-row tw-flex-col sm:tw-items-center tw-mb-2">
                                    <div class="tw-w-28 tw-shrink-0 tw-truncate sm:tw-mb-0 tw-mb-1">
                                        <CText>{{ calendar.attributes.name }}</CText>
                                    </div>
                                    <div class="tw-flex sm:tw-flex-row tw-flex-col sm:tw-items-center tw-w-full">
                                        <CCheckbox
                                            v-model="calendar.extra.calendar_access"
                                            class="sm:tw-w-1/2 tw-w-full"
                                            @input="event => !event ? calendar.extra.calendar_access_detailed = false : null"
                                        >
                                            {{ __('hr_calendar:view_calendar') }}
                                        </CCheckbox>
                                        <CTooltip class="sm:tw-w-1/2 tw-w-full">
                                            <CCheckbox
                                                v-model="calendar.extra.calendar_access_detailed"
                                                :disabled="!calendar.extra.calendar_access"
                                            >
                                                {{ __('common:view_details') }}
                                            </CCheckbox>
                                            <template #content>
                                                <div class="tw-w-60">
                                                    <CText
                                                        as="div"
                                                        font-weight="semibold"
                                                    >
                                                        {{ __('hr_calendar:unselecting_details_colon') }}
                                                    </CText>
                                                    <CText
                                                        as="div"
                                                        font-size="sm"
                                                    >
                                                        {{ __('hr_calendar:unselecting_details_example') }}
                                                    </CText>
                                                    <CText
                                                        as="div"
                                                        font-size="sm"
                                                    >
                                                        {{ __('hr_calendar:unselecting_details_notes') }}
                                                    </CText>
                                                    <CText
                                                        as="div"
                                                        font-size="sm"
                                                    >
                                                        {{ __('hr_calendar:unselecting_details_balance') }}
                                                    </CText>
                                                </div>
                                            </template>
                                        </CTooltip>
                                    </div>
                                </div>
                            </div>
                        </CDisclosure>
                    </CVStack>
                </CVStack>
            </template>
        </template>
        <template #footer>
            <CButton
                v-if="showResult"
                class="tw-ml-auto"
                @click="hide"
            >
                {{ __('common:actions.close') }}
            </CButton>
            <CButton
                v-else
                class="tw-ml-auto"
                :disabled="!isButtonEnabled"
                :loading="loader.isLoading()"
                variant="primary"
                @click="send"
            >
                {{ sendByEmail === true ? __('hr_calendar:send_by_mail') : __('hr_calendar:get_urls') }}
            </CButton>
        </template>
    </CModal>
</template>

<script lang="ts">
    import type {PropType} from 'vue';
    import {computed, defineComponent, ref, set} from 'vue';
    import {collect, type Collection, JsonAPISchema, ValidationError, ValidationErrorItem} from '@meekohq/lumos';
    import StaffModel from '@/modules/human-resources/models/StaffModel';
    import useApi from '@/modules/app/composables/useApi';
    import route from '@/modules/legacy/libs/ziggy';
    import Loader from '@/modules/legacy/helpers/loader.helper';
    import ErrorHandler from '@/modules/legacy/libs/errors/errorHandler';
    import CalendarModel from '@/modules/human-resources/models/CalendarModel';
    import __ from '@/modules/app/utils/i18n-facade';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';

    export default defineComponent({
        props: {
            staffModel: {type: Object as PropType<StaffModel | undefined>},
        },
        setup(props) {
            const title = ref('');
            const modal = ref();
            const email = ref<string>('');
            const description = ref<string>('');
            const sendByEmail = ref<boolean>(true);
            const showResult = ref<boolean>(false);
            const loader = ref(new Loader());
            const emailValue = ref('');
            const staffModelCollection = ref<Collection<StaffModel>>(collect());
            const staffError = ref(new ErrorHandler());
            const calendarModels = ref<Collection<CalendarModel>>(collect());

            const isButtonEnabled = computed(() => {
                return selectedStaffs.value.count() && (email.value.length || description.value.length) && selectedCalendar.value.count();
            });

            const selectedStaffs = computed(() => {
                return staffModelCollection.value.filter(item => item.extra.checked);
            });

            const selectedCalendar = computed(() => {
                return calendarModels.value.filter(item => item.extra.calendar_access);
            });

            const toggleSendingMethod = function(value) {
                sendByEmail.value = value;
                email.value = '';

                if (value && props.staffModel) {
                    email.value = props.staffModel.attributes.email || '';
                } else {
                    description.value = '';
                }
            };

            const oneStaffModel = computed(() => {
                return props.staffModel !== undefined;
            });

            const getCalendars = async () => {
                //on récupère les calendriers du compte pour la gestion des accès
                await CalendarModel.query().get()
                    .then(response => {
                        calendarModels.value = response.map(model => {
                            model.extra = {
                                calendar_access: true,
                                calendar_access_detailed: true,
                            };

                            return model;
                        });
                    });
            };

            const setupData = async () => {
                if (props.staffModel) {
                    title.value = __('hr_calendar:subscription_to', {name: props.staffModel.fullname});
                    const staffModel = props.staffModel.clone();
                    email.value = staffModel.attributes.email || '';
                    set(staffModel.extra, 'checked', true);
                    staffModelCollection.value = collect([staffModel]);
                } else {
                    title.value = __('planning:planning_subscription');
                    staffModelCollection.value = await StaffModel.query().whereHas('organizations', q => {
                        q.scope('active');
                    }).get();

                    //checkbox que l'on met a false pour que l'on puisse selectionner les staffs
                    staffModelCollection.value.each(staffModel => {
                        set(staffModel.extra, 'checked', false);
                    });
                }
            };

            const show = function() {
                modal.value.show();
            };

            const onShown = async function() {
                loader.value.start('modal');
                await getCalendars();
                await setupData();
                loader.value.stop('modal');
            };

            const send = async function() {
                loader.value.start();
                try {
                    //on peut modifier email pour que la valeur soit une collection dans le cadre de l envoie pour plusieurs emails
                    const response = await useApi().legacy.post(route('hr/calendar.staff.export-ics'), new JsonAPISchema.JsonApiDocument(
                        new JsonAPISchema.JsonApiData({
                            staffs: selectedStaffs.value.map(item => item.id).all(),
                            emails: email.value === '' ? null : [email.value],
                            description: sendByEmail.value ? email.value : description.value,
                            calendar_access: calendarModels.value.filter(item => item.extra.calendar_access && !item.extra.calendar_access_detailed).map(item => item.id).toArray(),
                            calendar_access_detailed: calendarModels.value.filter(item => item.extra.calendar_access && item.extra.calendar_access_detailed).map(item => item.id).toArray(),
                        })),
                    );

                    //on collecte les resultats pour la page suivante
                    const items = collect(response.data.data.data);
                    if (items.isNotEmpty()) {
                        if (!sendByEmail.value) {
                            selectedStaffs.value.each((model: StaffModel) => {
                                model.extra.url = items.get(model.id);
                            });
                        }
                        showResult.value = true;
                    }
                } catch (error: any) {
                    const errors = collect(error.response.data.errors).map((e: any) => {
                        return new ValidationErrorItem(e.title, e.code, e.detail, e.source);
                    });

                    staffError.value.reset(new ValidationError(errors));
                } finally {
                    loader.value.stop();
                }
            };

            //on reset les valeurs et on ferme la modal
            const hide = function() {
                showResult.value = false;
                description.value = '';
                email.value = '';
                staffError.value.reset();
                loader.value.stop();
                modal.value.hide();
            };

            //native way to copy an url on click
            const copyUrlToClipboard = function(str) {
                const el = document.createElement('textarea');
                el.value = str;
                document.body.appendChild(el);
                el.select();
                document.execCommand('copy');
                document.body.removeChild(el);
                useNotification().success(__('hr_calendar:link_copied'));
            };

            return {
                title,
                modal,
                email,
                description,
                sendByEmail,
                showResult,
                isButtonEnabled,
                oneStaffModel,
                loader,
                emailValue,
                staffModelCollection,
                selectedStaffs,
                staffError,
                calendarModels,
                show,
                send,
                onShown,
                hide,
                copyUrlToClipboard,
                toggleSendingMethod,
            };
        },
    });

</script>
