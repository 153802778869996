import type {Ref} from 'vue';
import {computed, onMounted, onUnmounted, ref, watch} from 'vue';
import type {Moment} from 'moment';
import moment from 'moment';
import _concat from 'lodash-es/concat';
import _orderBy from 'lodash-es/orderBy';
import _union from 'lodash-es/union';
import ManagerStore from '@/modules/legacy/store/manager.store';
import {base as swal} from '@/modules/legacy/libs/Alert';
import {EventBus} from '@/modules/legacy/utils/bus';
import {collect, ModelCollection, MqlTransaction} from '@meekohq/lumos';
import {daysBetweenDates, getClosing, getOpening, minutesToHours} from '@/modules/legacy/libs/planning/planning';
import useEventTemplate from '@/modules/human-resources/composables/calendar/useEventTemplate';
import StaffModel from '@/modules/human-resources/models/StaffModel';
import TemplateModel from '@/modules/human-resources/models/TemplateModel';
import TemplatePlanningModel from '@/modules/human-resources/models/TemplatePlanningModel';
import TemplateEventModel from '@/modules/human-resources/models/TemplateEventModel';
import EventModel from '@/modules/human-resources/models/EventModel';
import type OrganizationModel from '@/modules/organization/models/OrganizationModel';
import TemplateOrganizationPivotModel from '@/modules/human-resources/models/TemplateOrganizationPivotModel';
import TemplatedWorkingTime from '@/modules/human-resources/utils/calendar/Services/TemplatedWorkingTime';
import useAuth from '@/modules/app/composables/useAuth';
import {debounce} from 'lodash-es';
import __ from '@/modules/app/utils/i18n-facade';
import useNotification from '@/modules/meeko-ui/composables/useNotification';
import {useFeatureFlag} from '@/modules/app/composables/useFeatureFlag';
import {WeeksDto} from '@/modules/human-resources/apply-template/application/dto/WeeksDto';
import useApplyTemplate from '@/modules/human-resources/apply-template/infrastructure/components/useApplyTemplate';
import useEventWithError from '@/modules/human-resources/composables/calendar/useEventWithError';
import usePromises from '@/modules/app/composables/usePromises';
import EventTypeModel from '@/modules/human-resources/models/EventTypeModel';
import {calendarTypes} from '@/modules/human-resources/models/CalendarModel';
import BalanceAllocationModel from '@/modules/human-resources/models/BalanceAllocationModel';

export default function(staffsFromCurrentWeek: Ref<StaffModel[] | null> = ref(null), autoSave: boolean | undefined = true) {
    onMounted(() => {
        EventBus.$on(
            'calendar:staff:update:templateEvent',
            (event: TemplateEventModel) => {
                editEvent(event);
            },
        );
    });
    onUnmounted(() => {
        EventBus.$off('calendar:staff:update:templateEvent');
    });

    const organization = computed(() => {
        return ManagerStore.legacyNursery;
    });
    const {legacyUser: user} = useAuth();

    const modal = ref();

    function onShown() {
        getTemplates();
    }

    function onHide() {
        deleteRealEvents.value = false;
    }

    /* CALENDAR */

    const calendarLoading = ref(false);

    function opening(selectedDate: Moment, exactHours = true) {
        const hoursFromEvents = eventsForCurrentDay(selectedDate).map(item =>
            moment(item.startedAt).format('HH:mm:ss'),
        );

        return getOpening(
            ManagerStore.legacyNursery.openingHours,
            true,
            exactHours,
            hoursFromEvents,
        );
    }

    function closing(selectedDate: Moment, exactHours = true) {
        const hoursFromEvents = eventsForCurrentDay(selectedDate)
            .filter(item => item.endedAt !== null)
            .map(item => moment(item.endedAt).format('HH:mm:ss'));

        return getClosing(
            ManagerStore.legacyNursery.openingHours,
            true,
            exactHours,
            hoursFromEvents,
        );
    }

    const daysOfWeek = computed(() => {
        return daysBetweenDates(
            moment().startOf('week'),
            moment().endOf('week'),
            organization.value.openingHours,
        );
    });


    /* MANAGE TEMPLATES */

    const templates = ref<TemplateModel[]>([]);
    const filteredTemplates = computed(() => {
        return _orderBy(templates.value, template => {
            return template.attributes.name;
        });
    });
    const selectedTemplate = ref<TemplateModel | null>(null);
    const weekTemplatesLoading = ref(false);

    const staffFinderExceptionIds = computed(() => {
        const staffIds: string[] = [];
        planningsTemplates.value?.forEach((planning: TemplatePlanningModel) => {
            const staff = planning.staff().value();
            if (staff) {
                staffIds.push(staff.getKey());
            }
        });

        return staffIds;
    });

    async function getTemplates() {
        weekTemplatesLoading.value = true;

        const indexQuery = TemplateModel.query()
            .whereHas(new TemplateModel().organizations(), query2 => {
                query2.whereIn('id', [organization.value.id]);
            })
            .with(new TemplateModel().organizations());

        const response = await indexQuery.get();
        templates.value = collect(response).all() as TemplateModel[];
        if (filteredTemplates.value.length) {
            selectedTemplate.value = filteredTemplates.value[0];
        } else {
            selectedTemplate.value = null;
        }
        weekTemplatesLoading.value = false;
    }

    watch(selectedTemplate, async () => {
        calendarLoading.value = true;

        await selectedTemplate.value?.planningTemplates().setQuery(query => {
            query.orderBy('created_at');
            query.with(new TemplatePlanningModel().staff(), query2 => {
                query2.with(new StaffModel().contracts());
            });
            query.with(new TemplatePlanningModel().planningEvents(), query2 => {
                query2.orderBy('start_time');
                query2.with(new TemplateEventModel().eventType());
                query2.with(new TemplateEventModel().kidsGroup());
            });
        }).fresh();

        await selectedTemplate.value?.organizations().load();

        calendarLoading.value = false;
    });

    function newWeekTemplate() {
        swal({
            title: __('hr_calendar:new_workweek'),
            text: __('hr_calendar:workweek_explanation'),
            type: null,
            confirmButtonClass: 'btn btn-primary tw-mr-2',
            confirmButtonText: __('common:actions.save'),
            cancelButtonText: __('common:actions.cancel'),
            input: 'text',
            inputPlaceholder: __('hr_calendar:week_name'),
            inputValue: '',
        }).then(result => {
            if (result.value) {
                const newWeek = new TemplateModel();
                newWeek.attributes.name = result.value;
                newWeek.attributes.account_id = `${user.value.account_id}`;

                newWeek.save().then(response => {
                    const activeOrganization = ManagerStore.activeOrganization as OrganizationModel;
                    const pivot = new TemplateOrganizationPivotModel();
                    pivot.attributes.account_id = activeOrganization.attributes.account_id;
                    pivot.organization().associate(activeOrganization);
                    pivot.template().associate(response);

                    pivot.save().then(() => {
                        templates.value.push(response);
                        selectedTemplate.value = response;
                        selectedTemplate.value?.organizations().value().push(activeOrganization);
                        useNotification().success(__('hr_calendar:workweek_added'));
                    });
                });
            }
        });
    }

    const selectedTemplateOrganizations = ref();

    watch(selectedTemplate, value => {
        if (value) {
            selectedTemplateOrganizations.value = value.organizations().value().all();
        }
    });

    const isLoadingAttachOrganizations = ref(false);
    const attachOrganizationsToTemplate = async function(organizations: OrganizationModel[]) {
        isLoadingAttachOrganizations.value = true;

        const organizationsToRemovePromises: Array<Promise<any>> = [];

        // Filter organizations to be removed that are not in the list passed in parameter from the template
        const organizationsToRemove = selectedTemplate.value?.organizations().value().reject(item => {
            return organizations.find(orga => orga.getKey() === item.getKey()) !== undefined;
        });

        if (organizationsToRemove?.isNotEmpty()) {
            organizationsToRemove?.each(organization => {
                // Add promise to delete pivot in array
                organizationsToRemovePromises.push(organization.pivot().delete());
                // Remove organization from template
                selectedTemplate.value?.organizations()
                    .mutate(value => value.reject(item => item.getKey() === organization.getKey()));
            });
        }

        await Promise.all(organizationsToRemovePromises);

        const pivotsToSavePromises: Array<Promise<TemplateOrganizationPivotModel>> = [];
        organizations.forEach(organization => {
            // If organization is not already in the template, create a pivot and add it to the template
            if (!selectedTemplate.value?.organizations().value().first(item => item.getKey() === organization.getKey())) {
                const pivot = new TemplateOrganizationPivotModel();
                pivot.attributes.account_id = organization.attributes.account_id;
                pivot.organization().associate(organization);
                pivot.template().associate(selectedTemplate.value as TemplateModel);
                pivotsToSavePromises.push(pivot.save());
                selectedTemplate.value?.organizations().value().push(organization);
                organization.setRelation('pivot', pivot);
            }
        });

        if (pivotsToSavePromises.length) {
            await Promise.all(pivotsToSavePromises);
        }

        isLoadingAttachOrganizations.value = false;
    };

    const debouncedAttachOrganizationsToTemplate = debounce(attachOrganizationsToTemplate, 500);


    function saveWeekTemplate() {
        swal({
            title: __('hr_calendar:update_workweek'),
            text: null,
            type: null,
            confirmButtonClass: 'btn btn-primary tw-mr-2',
            confirmButtonText: __('common:actions.save'),
            cancelButtonText: __('common:actions.cancel'),
            input: 'text',
            inputPlaceholder: __('hr_calendar:week_name'),
            inputValue: selectedTemplate.value?.attributes.name,
        }).then(result => {
            if (result.value && selectedTemplate.value) {
                selectedTemplate.value.attributes.name = result.value;

                selectedTemplate.value.save().then(() => {
                    useNotification().success(__('hr_calendar:workweek_updated'));
                });
            }
        });
    }

    function removeWeekTemplate() {
        swal({
            title: __('hr_calendar:delete_workweek'),
            text: __('hr_calendar:delete_workweek_confirmation'),
            type: 'warning',
            confirmButtonClass: 'btn btn-danger tw-mr-2',
            confirmButtonText: __('common:actions.delete'),
            cancelButtonText: __('common:actions.cancel'),
        }).then(result => {
            if (result.value) {
                selectedTemplate.value?.delete().then(() => {
                    const weekToRemove = templates.value.find(
                        item => item.id === selectedTemplate.value?.getKey(),
                    );
                    if (weekToRemove) {
                        const index = templates.value.indexOf(weekToRemove);
                        templates.value.splice(index, 1);
                    }

                    if (filteredTemplates.value.length) {
                        selectedTemplate.value =
                            filteredTemplates.value[0];
                    } else {
                        selectedTemplate.value = null;
                    }
                    useNotification().success(__('hr_calendar:workweek_deleted_successfully'));
                });
            }
        });
    }

    function fillEventsWithCurrentWeek() {
        swal({
            title: __('hr_calendar:use_current_week'),
            text: __('hr_calendar:use_current_week_explanation'),
            type: 'warning',
            confirmButtonClass: 'btn btn-primary tw-mr-2',
            confirmButtonText: __('common:actions.validate'),
            cancelButtonText: __('common:actions.cancel'),
        }).then(async result => {
            if (result.value) {
                calendarLoading.value = true;

                const mqlRunner = new MqlTransaction();

                // Remove all plannings from weekTemplate
                planningsTemplates.value?.forEach(item => {
                    item.delete({mqlRunner});
                });
                selectedTemplate.value?.planningTemplates().set(new ModelCollection());

                // Add planningsTemplate for each staffs
                staffsFromCurrentWeek.value?.forEach(staff => {
                    const planningTemplate = new TemplatePlanningModel();
                    planningTemplate.attributes.account_id = `${user.value.account_id}`;
                    planningTemplate.attributes.template_id = selectedTemplate.value?.id;
                    planningTemplate.staff().associate(staff);

                    planningTemplate.save({mqlRunner});
                    selectedTemplate.value?.planningTemplates().value().all().push(planningTemplate);

                    // Transform EventModel into TemplateEventModel from current week
                    staff.events().value().all().filter(item => !item.isFullDay && item.attributes.forecast).forEach(event => {
                        const eventTemplate = eventIntoEventTemplate(event);
                        eventTemplate.attributes.planning_template_id = planningTemplate.getKey();
                        eventTemplate.save({mqlRunner});

                        planningTemplate.planningEvents().value().all().push(eventTemplate);
                    });
                });

                await mqlRunner.run();

                calendarLoading.value = false;
            }
        });
    }


    /* USE WEEK TEMPLATE */

    const selectedWeeks = ref<any[]>([]);
    const saveLoading = ref(false);
    const deleteRealEvents = ref(false);
    const {invalidEventErrors, applyTemplate} = useApplyTemplate();

    async function useWeek() {
        if (useFeatureFlag('enable-new-template-service').value === false) {
            useLegacyWeek();

            return;
        }

        if (selectedWeeks.value.length === 0 || !selectedTemplate.value) {
            return;
        }

        saveLoading.value = true;

        const weeks = selectedWeeks.value.map(week => {
            return `${week.date.year()}-${week.date.isoWeek()}`;
        });


        applyTemplate(selectedTemplate.value as TemplateModel, new WeeksDto(weeks), deleteRealEvents.value, organization.value.id).then(() => {
            EventBus.$emit('calendar:staffs:refresh', true);

            if (invalidEventErrors.getAll().isEmpty()) {
                modal.value?.hide();
            }
        }).finally(() => {
            saveLoading.value = false;
        });
    }

    /*****************************************/
    /********* LEGACY APPLY TEMPLATE *********/
    /*****************************************/

    const {failedEvents, getEventsWithError} = useEventWithError();

    function useLegacyWeek() {
        if (selectedWeeks.value.length === 0) {
            return;
        }

        saveLoading.value = true;

        const eventsToCreate: EventModel[] = [];

        const filteredPlannings = planningsTemplates.value?.filter(item => item.attributes.staff_id !== null);

        selectedWeeks.value.forEach(week => {
            // Get days for selected week
            const daysForWeek = daysBetweenDates(
                week.date,
                week.date.clone().endOf('week'),
                organization.value.openingHours,
            );
            daysForWeek.forEach(day => {
                // Get eventTemplates for each day
                filteredPlannings?.forEach(planning => {
                    const eventTemplates = planning.planningEvents().value().all().filter(
                        item => item.attributes.day === day.day(),
                    );
                    eventTemplates.forEach(eventTemplate => {
                        // Transform eventTemplate into EventModel
                        const event = eventTemplateIntoEvent(
                            eventTemplate,
                            day,
                            planning.staff().value(),
                        );
                        eventsToCreate.push(event);
                    });
                });
            });
        });

        deleteEventsFromWeeks(selectedWeeks.value, eventsToCreate)
            .then(() => {
                createEvents(eventsToCreate).then(() => {
                    saveLoading.value = false;
                    // Prevent closing modal if events are on error
                    EventBus.$emit('calendar:staffs:refresh', true);
                    if (failedEvents.value.isEmpty()) {
                        modal.value?.hide();
                    }
                }).catch(() => {
                    saveLoading.value = false;
                });
            })
            .catch(() => {
                saveLoading.value = false;
            });
    }

    async function deleteEventsFromWeeks(selectedWeeks: any[], eventsToCreate: EventModel[]) {
        const eventRemoveQuery = EventModel.query();
        const allocationRemoveQuery = BalanceAllocationModel.query();

        if (selectedTemplate.value) {
            const staffs = selectedTemplate.value.planningTemplates().value().map(item => item.staff().value()?.getKey()).all();
            eventRemoveQuery.whereIn('staff_id', staffs);

            let organizations = selectedTemplate.value.organizations().value().pluck('id').all();
            organizations = _union(organizations, eventsToCreate.map(item => item.attributes.organization_id));
            eventRemoveQuery.whereIn('organization_id', organizations as string[]);
        }

        eventRemoveQuery.whereHas('type', query1 => {
            query1.whereDoesntHave(new EventTypeModel().calendar(), query2 => {
                query2.where('internal_id', '=', calendarTypes.absence);
            });
        });

        if (!deleteRealEvents.value) {
            eventRemoveQuery.where('forecast', '=', true);
        }

        const event = new EventModel();

        eventRemoveQuery.where(subquery => {
            selectedWeeks.forEach(week => {
                const startOfWeek = week.date.clone();
                const endOfWeek = week.date.clone().endOf('week');

                // TODO : VueModel -> merging scope bug
                // eventRemoveQuery.scope('inRange', [startOfWeek, endOfWeek]);
                subquery.orWhere(query2 => {
                    event.scopeInPeriod(query2, [startOfWeek, endOfWeek]);
                });
            });
        })
            .where(subquery => {
                subquery.whereDoesntHave(new EventModel().balanceAllocations(), query2 => {
                    query2.whereNotNull('confirmed_at');
                });
            });


        const mqlRunner = new MqlTransaction();

        // Delete allocations for events, run them in a single transaction to rollback if one fails
        allocationRemoveQuery.whereNull('confirmed_at')
            .whereHas('event', query => {
                // We constrain the confirmed_at to be null to keep them for the reports
                query.inject(eventRemoveQuery);
            });

        allocationRemoveQuery.delete({mqlRunner});
        eventRemoveQuery.delete({mqlRunner});

        return await mqlRunner.run(true);
    }

    async function createEvents(events: EventModel[]) {
        const promises: Array<Promise<EventModel>> = [];
        events.forEach(value => {
            promises.push(value.save());
        });
        try {
            const {rejected} = await usePromises(promises);
            getEventsWithError(rejected.value);

            return events;
        } catch (e) {
            return Promise.reject(e);
        }
    }

    function eventTemplateIntoEvent(
        templateEvent: TemplateEventModel,
        day: any = null,
        staff: StaffModel | null = null,
    ): EventModel {
        const event = new EventModel();

        event.attributes.account_id = templateEvent.attributes.account_id;
        event.attributes.staff_id = staff ? staff.id : null;
        event.attributes.organization_id = templateEvent.attributes.organization_id;
        event.attributes.kids_group_id = templateEvent.attributes.kids_group_id;
        event.attributes.type_id = templateEvent.attributes.type_id;
        event.attributes.datetime_event = {
            started_at: templateEvent.startedAt.format(),
            ended_at: templateEvent.endedAt.format(),
        };
        event.attributes.date_event = null;
        event.attributes.note = templateEvent.attributes.note;
        event.attributes.supervise_kid = templateEvent.attributes.supervise_kid;
        event.attributes.forecast = templateEvent.attributes.forecast;
        event.eventType().associate(templateEvent.eventType().value());
        event.attributes.factor = event.eventType().value().factor;

        if (templateEvent.exists) {
            event.attributes.template_event_id = templateEvent.id;
        }

        if (day) {
            event.attributes.datetime_event = {
                started_at: day.clone().startOf('day').add(templateEvent.attributes.start_time, 'seconds').format(),
                ended_at: day.clone().startOf('day').add(templateEvent.attributes.start_time! + templateEvent.attributes.timelapse!, 'seconds').format(),
            };
        }

        return event;
    }

    /* PLANNING TEMPLATE */

    const planningsTemplates = computed(() => {
        return selectedTemplate.value?.planningTemplates().value().all();
    });

    const planningTemplateLoading = ref(false);

    async function addPlanningTemplate(staff: StaffModel | null = null) {
        if (planningTemplateLoading.value) {
            return;
        }

        planningTemplateLoading.value = true;

        const planningTemplate = new TemplatePlanningModel();
        planningTemplate.attributes.account_id = `${user.value.account_id}`;
        planningTemplate.attributes.template_id = selectedTemplate.value?.getKey();

        if (staff) {
            // Fetch fresh contracts because the staff may not have the relation
            await staff.contracts().fresh();
            planningTemplate.staff().associate(staff);
        }

        autoSave ? await planningTemplate.save() : null;
        selectedTemplate.value?.planningTemplates().value().all().push(planningTemplate);
        planningTemplateLoading.value = false;
    }

    async function switchStaffPlanning(planningTemplate: TemplatePlanningModel, staff: StaffModel | null = null) {
        if (!staff) {
            // If staff is null we dissociate but we keed the planning for future association with another staff
            planningTemplate.staff().dissociate();
        } else {
            // Fetch fresh contracts because the staff may not have the relation and associate the staff with the planning
            await staff.contracts().fresh();
            planningTemplate.staff().associate(staff);
        }

        if (autoSave) {
            await planningTemplate.save();
        }
    }

    function confirmRemovePlanningTemplate(planningTemplate: TemplatePlanningModel) {
        swal({
            title: __('hr_calendar:delete_week'),
            text: __('hr_calendar:delete_week_confirmation'),
            type: 'warning',
            confirmButtonClass: 'btn btn-danger tw-mr-2',
            confirmButtonText: __('common:actions.delete'),
            cancelButtonText: __('common:actions.cancel'),
        }).then(result => {
            if (result.value) {
                removePlanningTemplate(planningTemplate);
            }
        });
    }

    async function removePlanningTemplate(planningTemplate: TemplatePlanningModel) {
        autoSave ? await planningTemplate.delete() : null;

        const planningTemplates = selectedTemplate.value?.planningTemplates().value().all();
        if (planningTemplates) {
            const planningToRemove = planningTemplates.find(item => item.getKey() === planningTemplate.getKey());
            if (planningToRemove) {
                const index = planningTemplates.indexOf(planningToRemove);
                planningTemplates.splice(index, 1);
            }
        }
    }

    /* EVENTS */

    function totalHours(planningTemplate: TemplatePlanningModel) {
        // Init workingTimeInPeriod
        const templatedWorkingTime = new TemplatedWorkingTime();
        templatedWorkingTime.setEvents(planningTemplate.planningEvents().value().all());

        return minutesToHours(templatedWorkingTime.getWorkingTime());
    }

    function eventsForCurrentDay(selectedDate: Moment, planningTemplate: TemplatePlanningModel | null = null) {
        let events: TemplateEventModel[] = [];
        if (planningTemplate) {
            events = planningTemplate.planningEvents().value().all();
        } else {
            selectedTemplate?.value?.planningTemplates().value().all().forEach(planning => {
                events = _concat(events, planning.planningEvents().value().all());
            });
        }

        return events.filter(item => item.startedAt.isSame(selectedDate, 'day'));
    }

    function eventIntoEventTemplate(
        event: EventModel,
    ): TemplateEventModel {
        const templateEvent = new TemplateEventModel();

        const startTime =
            moment(event.attributes.datetime_event?.started_at).hours() * 3600 +
            moment(event.attributes.datetime_event?.started_at).minutes() * 60;
        const timelapse = moment(event.attributes.datetime_event?.ended_at)
            .diff(event.attributes.datetime_event?.started_at, 'seconds');

        templateEvent.attributes.account_id = event.attributes.account_id;
        templateEvent.attributes.organization_id = event.attributes.organization_id;
        templateEvent.attributes.kids_group_id = event.attributes.kids_group_id;
        templateEvent.attributes.type_id = event.attributes.type_id;
        templateEvent.attributes.day = moment(event.attributes.datetime_event?.started_at).day();
        templateEvent.attributes.week = 1;
        templateEvent.attributes.start_time = startTime;
        templateEvent.attributes.timelapse = timelapse;
        templateEvent.attributes.note = event.attributes.note;
        templateEvent.attributes.supervise_kid = event.attributes.supervise_kid;
        templateEvent.attributes.forecast = event.attributes.forecast;
        templateEvent.eventType().associate(event.eventType().value());

        return templateEvent;
    }

    function resetEvents(planningTemplate: TemplatePlanningModel | null = null) {
        swal({
            title: __('hr_calendar:reset_week'),
            text: __('hr_calendar:reset_week_confirmation'),
            type: 'warning',
            confirmButtonClass: 'btn btn-danger tw-mr-2',
            confirmButtonText: __('common:actions.delete'),
            cancelButtonText: __('common:actions.cancel'),
        }).then(result => {
            if (result.value) {
                deleteEvents(planningTemplate);
            }
        });
    }

    async function deleteEvents(planningTemplate: TemplatePlanningModel | null = null) {
        calendarLoading.value = true;
        const mqlRunner = new MqlTransaction();

        // Delete all events for a specific planningTemplate
        if (planningTemplate) {
            const eventsToRemove = planningTemplate.planningEvents().value().all();
            eventsToRemove.forEach(event => {
                event.delete({mqlRunner});
            });
            // Delete all events for the entire weekTemplate
        } else {
            selectedTemplate.value?.planningTemplates().value().all().forEach(planning => {
                const eventsToRemove = planning.planningEvents().value().all();
                eventsToRemove.forEach(event => {
                    event.delete({mqlRunner});
                });
            });
        }

        return mqlRunner.run()
            .then(() => {
                if (planningTemplate) {
                    planningTemplate.planningEvents().set(new ModelCollection());
                } else {
                    planningsTemplates.value?.forEach(item => {
                        item.planningEvents().set(new ModelCollection());
                    });
                }
            })
            .finally(() => {
                calendarLoading.value = false;
            });
    }

    const {
        selectedEvent,
        modalEditEvent,
        newEvent,
        addEvent,
        onEventAdded,
        editEvent,
        onEventEdited,
        onEventDeleted,
    } = useEventTemplate(opening, closing, null, planningsTemplates, autoSave);

    return {
        modal,
        onShown,
        onHide,

        /* CALENDAR */
        calendarLoading,
        opening,
        closing,
        daysOfWeek,

        /* MANAGE TEMPLATES */
        staffFinderExceptionIds,
        filteredTemplates,
        selectedTemplate,
        weekTemplatesLoading,
        selectedTemplateOrganizations,
        newWeekTemplate,
        isLoadingAttachOrganizations,
        debouncedAttachOrganizationsToTemplate,
        attachOrganizationsToTemplate,
        saveWeekTemplate,
        removeWeekTemplate,
        fillEventsWithCurrentWeek,

        /* USE TEMPLATE */
        selectedWeeks,
        saveLoading,
        deleteRealEvents,
        failedEvents,
        invalidEventErrors,
        useWeek,

        /* PLANNINGTEMPLATES */
        planningsTemplates,
        planningTemplateLoading,
        addPlanningTemplate,
        switchStaffPlanning,
        confirmRemovePlanningTemplate,

        /* EVENTS */
        totalHours,
        eventsForCurrentDay,
        resetEvents,
        deleteEvents,
        selectedEvent,
        modalEditEvent,
        newEvent,
        addEvent,
        onEventAdded,
        editEvent,
        onEventEdited,
        onEventDeleted,
    };
}
