<template>
    <CVStack>
        <loader
            v-if="loading"
            light="true"
            shadow="false"
            size="md"
            :title="__('hr:teams_loading_dots')"
        />
        <template v-else>
            <template v-if="teamModels.count()">
                <CHeading level="h4"/>
                <CListHeader space="xs">
                    <div class="tw-w-2/5"/>
                    <div class="tw-w-2/5"/>
                    <div class="tw-w-1/5"/>
                </CListHeader>
                <CList>
                    <TeamListItem
                        v-for="teamModel in sortedTeamModels"
                        :key="teamModel.id"
                        :team-model="teamModel"
                        @onRemoveTeamModel="removeTeamModel"
                        @ownerRemoveTeamModel="removeTeamModel"
                    />
                </CList>
            </template>
            <div
                v-else
                class="tw-text-center tw-text-disabled tw-p-4"
            >
                {{ __('hr:no_team_saved') }}
            </div>
        </template>
    </CVStack>
</template>


<script lang="ts">
    import type {Ref} from 'vue';
    import {computed, defineComponent, ref, toRef, watch} from 'vue';
    import type {Collection} from '@meekohq/lumos';
    import {collect} from '@meekohq/lumos';
    import TeamModel from '@/modules/human-resources/models/TeamModel';
    import Manager from '@/modules/legacy/store/manager.store';
    import TeamListItem from '@/modules/human-resources/components/team/List/TeamListItem.vue';
    import OrganizationModel from '@/modules/organization/models/OrganizationModel';
    import {isNil} from 'lodash-es';
    import useManager from '@/modules/app/composables/useManager';
    import TeamOrganizationPivot from '@/modules/human-resources/models/TeamOrganizationPivot';
    import __ from '@/modules/app/utils/i18n-facade';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';

    export default defineComponent(
        {
            components: {TeamListItem},
            props: {},

            setup() {
                const loading = ref(false);
                const teamModels = ref(collect<TeamModel>());
                const manager = ref(Manager);
                const renderTeams = ref();
                const {activeOrganization} = useManager();

                const fetchData = async function() {
                    loading.value = true;
                    teamModels.value = await TeamModel.query()
                        .whereHas('organizations', q => q.scope('active'))
                        .with(new TeamModel().organizations())
                        .with(new TeamModel().staffs())
                        .with(new TeamModel().organizationsPivots())
                        .with(new TeamModel().organizationsPivots(), query => query.scope('activeOrganization'), 'activeOrganizationPivot')
                        .with(new TeamModel().staffsPivots())
                        .orderBy('name')
                        .get();
                    loading.value = false;
                };

                watch(toRef(manager.value, 'activeOrganization'), () => {
                    fetchData();
                }, {immediate: true});

                const {
                    removeTeamModel,
                    addToResults,
                    importTeams,
                } = teamModelActions(teamModels as Ref<Collection<TeamModel>>, manager);

                async function importAndRefreshData(organizationModel: OrganizationModel) {
                    await importTeams(organizationModel);
                    await fetchData();
                }

                const sortedTeamModels = computed(() => {
                    const filteredTeams = teamModels.value.sortBy('attributes.name').map((item: TeamModel) => {
                        const pivot = item.organizationsPivots().value().where('attributes.organization_id', activeOrganization.value.id).first();
                        if (isNil(pivot.attributes.archived_at)) {
                            return item;
                        }
                    }).reject(item => item === undefined);

                    const archivedTeamModel = teamModels.value.diff(filteredTeams as Collection<TeamModel>);
                    archivedTeamModel.each(archivedEvent => filteredTeams.push(archivedEvent));

                    return filteredTeams;
                });

                return {
                    loading,
                    teamModels,
                    addToResults,
                    renderTeams,
                    importTeams,
                    Manager,
                    removeTeamModel,
                    importAndRefreshData,
                    sortedTeamModels,
                };
            },
        },
    );

    export function teamModelActions(teamModels: Ref<Collection<TeamModel>>, manager: Ref<typeof Manager>) {
        function removeTeamModel(event) {
            teamModels.value = teamModels.value.reject(item => item.id === event.id);
        }

        function addToResults(teamModel) {
            teamModels.value.push(teamModel);
        }

        async function importTeams(organizationModel) {
            if (organizationModel) {
                try {
                    const teamIds = teamModels.value.pluck('id').all();
                    const teamResult = await TeamModel.query()
                        .whereHas('organizations', query => {
                            query.where('id', '=', organizationModel.id);
                        })
                        .whereNotIn('id', teamIds as string[])
                        .all();
                    const activeOrganization = (await OrganizationModel.query().where('id', '=', manager.value.activeOrganization.id).get()).first();

                    if (teamResult.isNotEmpty()) {
                        const pivotsToSavePromises: Array<Promise<TeamOrganizationPivot>> = [];

                        teamResult.map(async (teamModel: TeamModel) => {
                            const pivot = new TeamOrganizationPivot();
                            pivot.attributes.account_id = teamModel.attributes.account_id;
                            pivot.organization().associate(activeOrganization);
                            pivot.team().associate(teamModel);
                            pivotsToSavePromises.push(pivot.save());
                        });

                        await Promise.all(pivotsToSavePromises);
                    }
                    useNotification().success(__('hr:teams_imported_successfully'));
                } catch (e) {
                    useNotification().error(__('hr:errors:team_import'));
                }
            }
        }

        return {
            removeTeamModel,
            addToResults,
            importTeams,
        };
    }
</script>

