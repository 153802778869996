<template>
    <CText
        class="tw-max-w-52 md:tw-max-w-xs"
        ellipsis
        font-size="lg"
        font-weight="semibold"
        uppercase
        :variant="registration.attributes.kid_gender ? registration.attributes.kid_gender : 'neutral'"
    >
        <i
            aria-hidden="true"
            class="fa tw-mr-1"
            :class="genderIcon"
        />
        {{ kidName }}
    </CText>
</template>

<script lang="ts">
    import type {PropType} from 'vue';
    import {computed, defineComponent} from 'vue';
    import type RegistrationModel from '@/modules/registration/models/RegistrationModel';

    export default defineComponent({
        components: {},
        props: {
            registration: {
                type: Object as PropType<RegistrationModel>,
                required: true,
            },
        },
        setup(props) {
            /**
             * Get the full name of the kid, handles the case when there is no kid_first_name filled
             */
            const kidName = computed(() => {
                return [
                    props.registration.attributes.kid_first_name ?? '',
                    props.registration.attributes.kid_last_name,
                ].filter(substring => substring?.length).join(' ');
            });

            /**
             * Compute the gender icon. Hanldes the case when the gender is not defined
             */
            const genderIcon = computed(() => {
                if (!props.registration.attributes.kid_gender) {
                    return 'fa-question';
                }

                return props.registration.attributes.kid_gender === 'male' ? 'fa-mars' : 'fa-venus';
            });

            return {
                kidName,
                genderIcon,
            };
        },
    });
</script>
