import type {Ref} from 'vue';
import {computed, onMounted, onUnmounted, ref} from 'vue';
import moment from 'moment';
import ManagerStore from '@/modules/legacy/store/manager.store';
import {EventBus} from '@/modules/legacy/utils/bus';
import {getClosing, getOpening, minutesToHours} from '@/modules/legacy/libs/planning/planning';
import {OpeningHourValue} from '@/modules/human-resources/utils/calendar/OpeningHourValue';
import WorkingTimeInPeriod from '@/modules/human-resources/utils/calendar/Services/WorkingTimeInPeriod';
import EventModel from '@/modules/human-resources/models/EventModel';
import type StaffModel from '@/modules/human-resources/models/StaffModel';
import {EventStatus, eventStatusPending} from '@/modules/human-resources/utils/calendar/Values/EventStatus';
import useAbility from '@/modules/app/composables/useAbility';

export default function(
    staff: StaffModel | null,
    staffs: Ref<StaffModel[]> | null = null,
) {
    onMounted(() => {
        EventBus.$on('calendar:staff:create:event', (event: EventModel) => {
            showEventOnModal(event);
        });

        EventBus.$on('calendar:staff:update:event', (event: EventModel) => {
            editEvent(event);
        });

        EventBus.$on('calendar:staff:delete:event', (event: EventModel) => {
            onEventDeleted(event);
        });
    });
    onUnmounted(() => {
        EventBus.$off('calendar:staff:create:event');
        EventBus.$off('calendar:staff:update:event');
        EventBus.$off('calendar:staff:delete:event');
    });

    const modal = ref<null | {
        modal: {
            show: () => null;
            hide: () => null
        }
    }>(
                null,
                );

    const organization = computed(() => {
        return ManagerStore.legacyNursery;
    });
    const account = computed(() => {
        return ManagerStore.legacyAccount;
    });

    const events = ref<EventModel[]>([]);
    const selectedEvent = ref<EventModel | null>(null);
    const eventsLoading = ref(false);

    const {can} = useAbility();

    async function getEvents(from: string, to: string) {
        try {
            eventsLoading.value = true;
            events.value = [];

            const indexQuery = EventModel.query()
                .orderBy('started_at')
                .with(new EventModel().eventType())
                .with(new EventModel().balanceAllocations())
                .with(new EventModel().kidsGroup())
                .scope('inRange', [from, to]);

            if (staff) {
                indexQuery.where('staff_id', '=', `${staff.getKey()}`);
            }

            if (can('read', 'hr_request')) {
                indexQuery.whereIn('status', eventStatusPending.concat(EventStatus.validated));
            } else {
                indexQuery.where('status', EventStatus.validated);
            }

            const response = await indexQuery.get(200);
            events.value = response.all();
            eventsLoading.value = false;
        } catch (e) {
            eventsLoading.value = false;
        }
    }

    function newEvent(
        date: any = null,
        currentStaff: StaffModel,
        openModal = false,
        fullDay = false,
    ): EventModel {
        const currentDate = date ? date.format('MM/DD/YYYY') : moment().format('MM/DD/YYYY');

        const event = new EventModel();
        event.attributes.account_id = account.value.id.toString();
        event.attributes.staff_id = currentStaff.getKey();
        event.attributes.organization_id = organization.value.id.toString();
        if (fullDay) {
            event.attributes.date_event = {
                started_at: moment(`${currentDate}`, 'MM/DD/YYYY').format(),
                ended_at: moment(`${currentDate}`, 'MM/DD/YYYY').format(),
            };
            event.attributes.datetime_event = null;
        } else {
            const startTime = getOpening(organization.value.openingHours, false, true);
            const endTime = getClosing(organization.value.openingHours, false, true);
            event.attributes.datetime_event = {
                started_at: moment(`${currentDate} ${startTime}`, 'MM/DD/YYYY HH:mm').format(),
                ended_at: moment(`${currentDate} ${endTime}`, 'MM/DD/YYYY HH:mm').format(),
            };
            event.attributes.date_event = null;
        }
        event.attributes.note = null;
        event.attributes.forecast = !fullDay; // TODO : Get default parameters
        event.attributes.supervise_kid = null;

        if (openModal) {
            showEventOnModal(event);
        }

        return event;
    }

    function showEventOnModal(event: EventModel) {
        selectedEvent.value = event;
    }

    function onEventAdded(event: EventModel) {
        if (staff) {
            events.value.push(event);
        } else {
            const staffToUpdate = staffs?.value.find(item => item.id === event.attributes.staff_id);
            staffToUpdate?.events().value().push(event);
        }
        selectedEvent.value = null;
    }

    function editEvent(event: EventModel) {
        selectedEvent.value = event;
    }

    function onEventDeleted(event: EventModel) {
        if (staff) {
            const eventToDelete = events.value.find(
                item => item.id === event.getKey(),
            );
            if (eventToDelete) {
                const index = events.value.indexOf(eventToDelete);
                events.value.splice(index, 1);
            }
        } else if (staffs?.value) {
            const staffToUpdate = staffs.value.find(
                item => item.getKey() === event.attributes.staff_id,
            );
            const staffEvents = staffToUpdate
                ?.events()
                .value()
                .all();
            const eventToDelete = staffEvents?.find(
                item => item.id === event.getKey(),
            );
            if (staffEvents && eventToDelete) {
                const index = staffEvents.indexOf(eventToDelete);
                staffEvents?.splice(index, 1);
            }
        }
    }

    function totalPlanned(start, end) {
        return totalHoursBetweenDates(
            events.value.filter(
                item => item.attributes.forecast,
            ) as EventModel[],
            start.unix(),
            end.unix(),
        );
    }

    function totalAchieved(start, end) {
        return totalHoursBetweenDates(
            events.value.filter(
                item => !item.attributes.forecast
                    || (item.attributes.forecast && item.attributes.factor && item.attributes.factor < 0),
            ) as EventModel[],
            start.unix(),
            end.unix(),
        );
    }

    function totalHoursBetweenDates(events: EventModel[], from: number, to: number) {
        // Generete opening hours data
        const openingHours: OpeningHourValue[] = [];

        for (const openingHour of organization.value.openingHours) {
            openingHours.push(new OpeningHourValue({
                day: openingHour.day,
                open: moment(openingHour.started_at, 'HH:mm:ss'),
                close: moment(openingHour.ended_at, 'HH:mm:ss'),
            }));
        }

        // Init workingTimeInPeriod
        const workingTimeInPeriod = new WorkingTimeInPeriod(openingHours, organization.value.timezone);
        workingTimeInPeriod.setEvents(events);

        const total = workingTimeInPeriod.getWorkingTimeInPeriod(from, to);

        return minutesToHours(total);
    }

    return {
        modal,

        events,
        selectedEvent,
        eventsLoading,
        getEvents,
        totalPlanned,
        totalAchieved,

        newEvent,
        addEvent: showEventOnModal,
        onEventAdded,
        onEventDeleted,
    };
}
