<template>
    <div class="TransactionListItemInvoicePopoverTotal">
        <div class="TransactionListItemInvoicePopoverTotal__item">
            {{ __('common:total_colon') }}
            <div class="tw-font-semibold">
                {{ useFormatNumber(allocation.extra.invoice.computed.currency_iso_code).format(allocation.extra.invoice.attributes.grand_total) }}
            </div>
        </div>
        <div
            v-if="allocation.extra.invoice.computed.remaining_amount > 0"
            class="TransactionListItemInvoicePopoverTotal__item"
        >
            {{ __('billing_invoice:outstanding_balance_colon') }}
            <div class="tw-font-semibold">
                {{ format(allocation.extra.invoice.computed.remaining_amount) }}
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import type {PropType} from 'vue';
    import {defineComponent} from 'vue';
    import type AllocationModel from '@/modules/cashier/models/AllocationModel';
    import useFormatNumber from '@/modules/cashier/composables/useFormatNumber';

    export default defineComponent({
        props: {
            allocation: {
                type: Object as PropType<AllocationModel>,
                required: true,
            },
        },
        setup(props) {
            const {format} = useFormatNumber(props.allocation.extra.payment.computed.currency_iso_code);

            return {
                format,
                useFormatNumber,
            };
        },
    });
</script>

<style lang="scss" scoped>
    .TransactionListItemInvoicePopoverTotal {
        @apply tw-border-t tw-border-gray-200;
        @apply tw-flex tw-flex-col tw-items-end tw-gap-1;
    }

    .TransactionListItemInvoicePopoverTotal__item {
        @apply tw-flex tw-flex-row tw-items-baseline tw-gap-1;
    }
</style>
