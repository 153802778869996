import {Model} from '@meekohq/lumos';
import TenantModel from '@/modules/cashier/models/TenantModel';
import CustomerModel from '@/modules/cashier/models/CustomerModel';
import CurrencyModel from '@/modules/cashier/models/CurrencyModel';

export default class AllocationModel extends Model {
    public type = 'cashier/allocations';

    public attributes: {
        id: string,
        tenant_id: string | undefined,
        customer_id: string | undefined,
        currency_id: string | undefined,
        date: string | undefined,
        amount: number | undefined,
        source_id: string | undefined,
        source_type: string | undefined,
        destination_id: string | undefined,
        destination_type: string | undefined,
    } = {
            id: this.uuid(),
            tenant_id: undefined,
            customer_id: undefined,
            currency_id: undefined,
            date: undefined,
            amount: undefined,
            source_id: undefined,
            source_type: undefined,
            destination_id: undefined,
            destination_type: undefined,
        };

    public computed: {
        currency_iso_code: string
    } = {
            currency_iso_code: '',
        };

    tenant() {
        return this.belongsTo('tenant', TenantModel, 'tenant_id');
    }

    customer() {
        return this.belongsTo('customer', CustomerModel, 'customer_id');
    }

    currency() {
        return this.belongsTo('currency', CurrencyModel, 'currency_id');
    }

    source() {
        return this.morphTo('source');
    }

    destination() {
        return this.morphTo('destination');
    }
}
