<script setup lang="ts">
    import {computed, inject} from 'vue';
    import {keyMStepPanelState} from '@/modules/meeko-ui/components/MStepPanel/useMStepPanelState';
    import MStepPanelDivider from '@/modules/meeko-ui/components/MStepPanel/MStepPanelDivider.vue';
    import MStepPanelHeaderItemCircle from '@/modules/meeko-ui/components/MStepPanel/MStepPanelHeaderItemCircle.vue';

    const props = withDefaults(defineProps<{
        step: number;
        title: string;
        isCompleted?: boolean;
        hasError?: boolean;
    }>(), {
        isCompleted: false,
        hasError: false,
    });

    const state = inject(keyMStepPanelState);
    if (!state) {
        throw new Error('keyMStepPanelState must be provided');
    }

    const {
        activeStep,
        setActiveStep,
        registerStep,
        lastStep,
    } = state;

    registerStep(props);

    const isActive = computed(() => activeStep.value === props.step);
    const isLastStep = computed(() => lastStep.value!.step === props.step);

    function handleClick() {
        setActiveStep(props.step);
    }
</script>

<template>
    <div
        class="MStepPanelHeaderItem"
        @click="handleClick"
    >
        <div class="tw-group MStepPanelHeaderItem__container">
            <div class="MStepPanelHeaderItem__sub-container">
                <MStepPanelHeaderItemCircle
                    :has-error="hasError"
                    :is-completed="isCompleted"
                    :step="step"
                >
                    <template #icon>
                        <slot name="icon"/>
                    </template>
                </MStepPanelHeaderItemCircle>
                <div
                    class="MStepPanelHeaderItem__title"
                    :class="{'MStepPanelHeaderItem__title--is-active': isActive}"
                >
                    {{ title }}
                </div>
            </div>
        </div>
        <MStepPanelDivider v-if="!isLastStep"/>
    </div>
</template>

<style lang="scss" scoped>
    .MStepPanelHeaderItem {
        @apply tw-relative md:tw-flex md:tw-flex-1;
    }

    .MStepPanelHeaderItem__container {
        @apply tw-flex tw-w-full tw-items-center;
        @apply tw-cursor-pointer;
    }

    .MStepPanelHeaderItem__sub-container {
        @apply tw-flex tw-items-center;
        @apply tw-px-6 tw-py-4;
        @apply tw-text-sm tw-font-medium;
    }

    .MStepPanelHeaderItem__title {
        @apply tw-ml-4;
        @apply tw-text-base tw-font-normal tw-font-display;
        @apply tw-transition-all tw-ease-in-out tw-duration-300;
        @apply tw-text-gray-500 group-hover:tw-text-gray-900;
        @apply tw-line-clamp-2;

        &--is-active {
            @apply tw-text-primary-500 group-hover:tw-text-primary-700;
        }
    }
</style>
