<script lang="ts" setup>
    import {inject} from 'vue';
    import {keyMCalendarState} from '@/modules/meeko-ui/components/MCalendar/useMCalendarState';
    import {keyMCalendarPresets} from '@/modules/meeko-ui/components/MCalendar/useMCalendarPresets';

    const state = inject(keyMCalendarState);
    if (!state) {
        throw new Error('keyMCalendarState must be provided');
    }

    const presetsState = inject(keyMCalendarPresets);
    if (!presetsState) {
        throw new Error('keyMCalendarPresets must be provided');
    }

    const {mode} = state;

    const {
        presets,
        isPresetEnabled,
        activatePreset,
    } = presetsState;
</script>

<template>
    <div
        class="MCalendarPresets"
        :class="{
            'MCalendarPresets-time-mode': mode === 'time'
        }"
    >
        <button
            v-for="preset in presets"
            :key="preset.label"
            class="MCalendarPresets__preset-button"
            :class="{
                'MCalendarPresets__preset-button--active': isPresetEnabled(preset),
            }"
            @click="activatePreset(preset)"
        >
            {{ preset.label }}
        </button>
    </div>
</template>

<style scoped lang="scss">
    .MCalendarPresets {
        @apply tw-flex tw-flex-col tw-gap-1 tw-min-w-24 tw-overflow-y-auto tw-h-[280px];
    }

    .MCalendarPresets-time-mode {
        @apply tw-h-[140px] #{!important};
    }

    .MCalendarPresets__preset-button {
        @apply tw-p-2 tw-py-1.5 tw-rounded tw-text-gray-500 tw-font-display tw-text-sm tw-cursor-pointer tw-select-none;
        @apply tw-transition-all hover:tw-bg-primary-100 hover:tw-text-primary-600 tw-text-left;
    }

    .MCalendarPresets__preset-button--active {
        @apply tw-bg-primary-100 tw-text-primary-600;
    }
</style>
