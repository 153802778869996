<template>
    <div class="tw-bg-black d-flex align-items-center flex-container">
        <div class="w-100">
            <div class="container-fluid">
                <div class="row">
                    <div
                        class="col-12 ml-auto mr-auto mt-5 mb-5"
                        style="max-width: 430px;"
                    >
                        <code
                            v-for="line in lines"
                            :class="{'error': line.includes('error')}"
                        >
                            > {{ line }}<br>
                        </code>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {app} from '@meekohq/lumos';
    import {Types} from '@/types';

    export default {
        data: () => ({
            lines: [],
        }),
        computed: {
            email() {
                return this.$route.params.email;
            },
            token() {
                return window.localStorage.getItem('support-token');
            },
        },
        mounted() {
            this.lines.push('Logout from previous session...');
            app(Types.Auth).logout();

            this.lines.push('Try to login...');
            app(Types.Auth).supportLoginByEmail(this.email, this.token).then(() => {
                this.lines.push('Login OK.');
                this.lines.push('Saving credentials.');
                this.lines.push('Redirect to dashboard in 3 seconds...');
                setTimeout(() => {
                    this.$router.push({
                        name: 'dashboard',
                    });
                }, 3000);
            }).catch(error => {
                this.lines.push('Login Error: ' + JSON.stringify(error.response.data));
            });
        },
    };
</script>

<style
    lang="scss"
    scoped
>
    code {
        color: theme('colors.pink.500');

        &.error {
            color: #ffc000;
        }
    }
</style>

