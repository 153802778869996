<template>
    <div class="NotificationsListItem">
        <div class="NotificationsListItem__content">
            <div v-if="model.fullname">
                {{ model.fullname }}
            </div>
            <div v-else-if="model.job">
                {{ model.job }}
            </div>
            <div
                v-if="model.attributes.email"
                class="NotificationsListItem__mail"
            >
                {{ model.attributes.email }}
            </div>
            <div
                v-else
                class="NotificationsListItem__mail--is-missing"
            >
                <i class="fad fa-exclamation-triangle tw-mr-2"/>{{ __('common:emails_count_zero') }}
                <CHelpTooltip>
                    {{ __('billing_core:errors.email_required_for_send_invoice') }}
                </CHelpTooltip>
            </div>
        </div>
        <div class="NotificationsListItem__toggle-container">
            <slot/>
        </div>
    </div>
</template>

<script lang="ts">
    import {defineComponent} from 'vue';
    import ContactModel from '@/modules/cashier/models/ContactModel';
    import MemberModel from '@/modules/family/models/MemberModel';

    export default defineComponent({
        props: {
            model: {
                type: ContactModel || MemberModel,
                required: true,
            },
        },

        setup() {
            return {};
        },
    });
</script>

<style lang="scss" scoped>
    .NotificationsListItem {
        @apply tw-flex;
    }

    .NotificationsListItem__content {
        @apply tw-flex tw-space-x-2 tw-items-baseline;
    }

    .NotificationsListItem__mail {
        @apply tw-text-sm tw-text-gray-700;
    }

    .NotificationsListItem__mail--is-missing {
        @apply tw-text-sm tw-text-yellow-700;
    }

    .NotificationsListItem__toggle-container {
        @apply tw-flex tw-space-x-6 tw-items-center tw-ml-auto;
    }
</style>

