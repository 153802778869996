<template>
    <CVStack>
        <MHeading class="tw-mb-2">
            <i
                aria-hidden="true"
                class="fad fa-syringe tw-text-yellow-500 tw-mr-2 tw-text-3xl"
            />
            {{ __('health:vaccines') }}
        </MHeading>
        <CVStack v-if="isLoading">
            <CSkeleton
                class="tw-w-full lg:tw-w-1/3 tw-mb-3"
                :count="1"
                layout="grid"
            />
            <CSkeleton
                class="tw-w-full lg:tw-w-2/3 tw-mb-2"
                :count="3"
                layout="grid"
            />
            <CSkeleton
                class="tw-w-full lg:tw-w-2/3"
                :count="3"
                layout="grid"
            />
        </CVStack>
        <CVStack
            v-else
            gap="4"
        >
            <AddTreatment
                v-if="can('update', 'kids_health')"
                class="md:tw-max-w-sm"
                :kid="kid"
                :treatments="treatments"
                @add="addTreatmentToList"
            />
            <div
                v-if="columnsNeededCount > 0"
                class="tw-flex tw-overflow-x-auto tw-rounded-lg"
            >
                <div class="tw-bg-blue-100 tw-rounded-lg">
                    <div class="tw-flex">
                        <div class="tw-flex tw-align-middle tw-bg-blue-200 tw-w-72">
                            <CText
                                class="tw-p-2"
                                font-weight="semibold"
                            >
                                {{ __('common:name') }}
                            </CText>
                        </div>
                        <div class="tw-flex tw-bg-blue-200 tw-rounded-tr-lg">
                            <div class="tw-grid tw-grid-flow-col tw-auto-cols-max">
                                <div
                                    v-for="(number, index) in columnsNeededCount"
                                    :key="index"
                                    class="tw-text-center tw-p-2 tw-min-w-36"
                                >
                                    <CText font-weight="semibold">
                                        {{ __('health:shot_with_count', {count: number}) }}
                                    </CText>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        v-for="(treatment, i) in sortedTreatments"
                        :key="treatment.getKey()"
                        class="tw-flex tw-items-center tw-min-h-14"
                        :class="{'tw-border-t tw-border-blue-200': i > 0}"
                    >
                        <div class="tw-w-72 tw-p-2">
                            <CText font-weight="semibold">
                                {{ treatment.attributes.name }}
                            </CText>
                        </div>
                        <TreatmentItem
                            class="tw-grid tw-grid-flow-col tw-auto-cols-max"
                            :kid="kid"
                            :treatment="treatment"
                            @deleted:action="onMedicalActionDeleted(treatment)"
                        />
                    </div>
                </div>
            </div>
        </CVStack>
    </CVStack>
</template>

<script lang="ts">
    import {computed, defineComponent, ref, watch} from 'vue';
    import MedicalTreatmentModel from '@/modules/health/models/MedicalTreatmentModel';
    import {collect} from '@meekohq/lumos';
    import TreatmentItem from '@/modules/health/components/TreatmentItem.vue';
    import AddTreatment from '@/modules/health/components/AddTreatment.vue';
    import MedicalTreatmentType from '@/modules/health/models/MedicalTreatmentType';
    import useAbility from '@/modules/app/composables/useAbility';
    import MedicalActionModel from '@/modules/health/models/MedicalActionModel';
    import KidModel from '@/modules/family/models/KidModel';
    import {useToggle} from '@vueuse/core';

    export default defineComponent({
        components: {AddTreatment, TreatmentItem},
        props: {
            kidId: {
                type: String,
                required: true,
            },
        },
        setup(props) {
            const {can} = useAbility();

            const kid = ref<KidModel>();

            watch(() => props.kidId, async id => {
                kid.value = await KidModel.query().findOrFail(id);
            }, {immediate: true});

            const [isLoading, setIsLoading] = useToggle(false);

            // Treatments collection
            const treatments = ref(collect<MedicalTreatmentModel>());

            // Treatments sorted by name
            const sortedTreatments = computed(() => treatments.value.sortBy('attributes.name'));

            /**
             * Add a treatment to the list.
             * @param treatment
             */
            function addTreatmentToList(treatment: MedicalTreatmentModel) {
                treatments.value.push(treatment);
            }

            /**
             * Fetch medical treatments for the kid.
             */
            async function fetchMedicalTreatments(kidId: string) {
                setIsLoading(true);

                const query = MedicalTreatmentModel.query()
                    .where('kid_id ', kidId)
                    .where('type', MedicalTreatmentType.vaccine)
                    .with(new MedicalTreatmentModel().kid())
                    .orderBy('name');

                const medicalActionsRelationship = new MedicalTreatmentModel().medicalActions();
                const medicalTreatmentRelationship = new MedicalActionModel().medicalTreatment();

                query.with(medicalActionsRelationship, query => query.with(medicalTreatmentRelationship));

                treatments.value = await query.all();

                setIsLoading(false);
            }

            /**
             * Reject a treatment from the list if it doesn't contain any actions.
             */
            function rejectTreatmentsWithoutDoses() {
                treatments.value = treatments.value.reject(treatment => {
                    return treatment.medicalActions().value().count() === 0;
                });
            }

            /**
             * Each time a shot is deleted, remove treatments without doses.
             */
            function onMedicalActionDeleted() {
                rejectTreatmentsWithoutDoses();
            }

            const columnsNeededCount = computed(() => {
                return treatments.value.map(treatment => treatment.medicalActions().value().count()).max() + 1;
            });

            watch(() => props.kidId, value => fetchMedicalTreatments(value.toString()), {immediate: true});

            return {
                can,
                treatments,
                sortedTreatments,
                onMedicalActionDeleted,
                columnsNeededCount,
                isLoading,
                addTreatmentToList,
                kid,
            };
        },
    });
</script>
