<template>
    <CForm>
        <CFormGroup>
            <CLabel>
                <i
                    aria-hidden="true"
                    class="fad fa-address-book tw-mr-2 tw-text-blue-500"
                />{{ __('common:family') }}
            </CLabel>
            <select-family
                v-model="localFamilyModel"
                button-class="tw-w-full"
                :create-option-enabled="false"
                :disabled="familyModel !== undefined"
                :organization-model="organizationModel"
                @input="onFamilyChange"
            />
        </CFormGroup>
        <CFormTwoColumns>
            <CFormGroup>
                <CLabel>
                    {{ __('common:first_name') }}*
                </CLabel>
                <CInput
                    ref="first_name"
                    v-model="localMemberModel.attributes.first_name"
                    v-focus
                    :has-error="memberError.hasError('first_name')"
                    :placeholder="__('common:first_name')"
                />
                <CFormErrorMessageList
                    v-if="memberError.hasError('first_name')"
                    :errors="memberError.getErrors('first_name')"
                />
            </CFormGroup>
            <CFormGroup>
                <CLabel>
                    {{ __('common:last_name') }}*
                </CLabel>
                <CInput
                    v-model="localMemberModel.attributes.last_name"
                    :has-error="memberError.hasError('last_name')"
                    :placeholder="__('common:last_name')"
                />
                <CFormErrorMessageList
                    v-if="memberError.hasError('last_name')"
                    :errors="memberError.getErrors('last_name')"
                />
            </CFormGroup>
        </CFormTwoColumns>
        <CFormTwoColumns>
            <CFormGroup>
                <CLabel>
                    <i
                        aria-hidden="true"
                        class="fas fa-venus-mars tw-mr-2 tw-text-blue-500"
                    />{{ __('common:gender_one') }}
                </CLabel>
                <CFormSelect
                    v-model="localMemberModel.attributes.gender"
                    :has-error="memberError.hasError('gender')"
                    list-class="tw-max-h-32"
                    :options="[{value:'male',text:__('common:gender.mr')},{value:'female',text:__('common:gender.ms')}]"
                    :search-bar="false"
                />
                <CFormErrorMessageList :errors="memberError.getErrors('gender')"/>
            </CFormGroup>
            <CFormGroup>
                <CLabel>
                    <i
                        aria-hidden="true"
                        class="fas fa-birthday-cake tw-mr-2 tw-text-blue-500"
                    />{{ __('common:birthdate') }}*
                </CLabel>
                <CFormDatepicker
                    v-model="localMemberModel.attributes.birthdate"
                    dropdown-class="tw-origin-top-left"
                    format="YYYY-MM-DD"
                    :has-error="memberError.hasError('birthdate')"
                />
                <CFormErrorMessageList :errors="memberError.getErrors('birthdate')"/>
            </CFormGroup>
        </CFormTwoColumns>
        <CFormGroup>
            <CLabel>
                <i
                    aria-hidden="true"
                    class="fas fa-briefcase tw-mr-2 tw-text-blue-500"
                />{{ __('common:job') }}
            </CLabel>
            <CInput
                v-model="localMemberModel.attributes.job"
                :has-error="memberError.hasError('job')"
                :placeholder="__('common:job')"
            />
            <CFormErrorMessageList :errors="memberError.getErrors('job')"/>
        </CFormGroup>
        <CFormGroup>
            <CLabel>
                <CafIcon class="tw-h-4 tw-w-4 tw-mr-2 inline-block tw-fill-current tw-text-blue-500"/>
                {{ __('family_member:beneficiary_number') }}
            </CLabel>
            <CInput
                v-model="localMemberModel.attributes.allocator_no"
                :has-error="memberError.hasError('allocator_no')"
                :placeholder="__('family_member:beneficiary_number_short')"
            />
            <CFormErrorMessageList :errors="memberError.getErrors('allocator_no')"/>
        </CFormGroup>
        <CFormGroup>
            <CLabel>
                <i
                    aria-hidden="true"
                    class="far fa-at tw-mr-2 tw-text-blue-500"
                />{{ __('common:email_address') }}
            </CLabel>
            <CInput
                v-model="localMemberModel.attributes.email"
                :has-error="memberError.hasError('email')"
                :placeholder="__('common:email_example')"
            />
            <CFormErrorMessageList :errors="memberError.getErrors('email')"/>
        </CFormGroup>
        <CDisclosure
            class="tw-mb-2"
            :open="false"
            size="sm"
            :title="__('common:phone.general')"
        >
            <CForm tag="div">
                <CFormGroup>
                    <CLabel>
                        <i
                            aria-hidden="true"
                            class="fas fa-phone tw-mr-2 tw-text-blue-500"
                        />{{ __('common:phone.general') }}
                    </CLabel>
                    <CInput
                        v-model="localMemberModel.attributes.phone"
                        :has-error="memberError.hasError('phone')"
                        :placeholder="__('common:phone.example.general')"
                    />
                    <CFormErrorMessageList :errors="memberError.getErrors('phone')"/>
                </CFormGroup>
                <CFormTwoColumns>
                    <CFormGroup>
                        <CLabel>
                            <i
                                aria-hidden="true"
                                class="fas fa-mobile-alt tw-mr-2 tw-text-blue-500"
                            />{{ __('common:phone.cellular') }}
                        </CLabel>
                        <CInput
                            v-model="localMemberModel.attributes.mobile_phone"
                            :has-error="memberError.hasError('mobile_phone')"
                            :placeholder="__('common:phone.example.cellular')"
                        />
                        <CFormErrorMessageList :errors="memberError.getErrors('mobile_phone')"/>
                    </CFormGroup>
                    <CFormGroup custom-class="tw-mb-0">
                        <CLabel>
                            <i
                                aria-hidden="true"
                                class="fas fa-phone-office tw-mr-2 tw-text-blue-500"
                            />{{ __('common:phone.work') }}
                        </CLabel>
                        <CInput
                            v-model="localMemberModel.attributes.office_phone"
                            :has-error="memberError.hasError('office_phone')"
                            :placeholder="__('common:phone.example.general')"
                        />
                        <CFormErrorMessageList :errors="memberError.getErrors('office_phone')"/>
                    </CFormGroup>
                </CFormTwoColumns>
            </CForm>
        </CDisclosure>
        <CDisclosure
            class="tw-mb-4"
            :open="false"
            size="sm"
            :title="__('common:address.address')"
            :visible="memberError.hasError('address')"
        >
            <AddressFields
                address-path="address"
                :model="localMemberModel"
                :model-error="memberError"
                pointer-path="/data/attributes/address"
            />
        </CDisclosure>
        <CFormGroup>
            <CLabel>
                <i
                    aria-hidden="true"
                    class="fad fa-envelope-open-text tw-mr-2 tw-text-blue-500"
                />{{ __('common:send_account_bills_by_email') }}
            </CLabel>
            <CButtonGroup class="tw-w-full">
                <CButton
                    class="tw-w-full"
                    :variant="!memberWillReceiveBillingEmail ? 'primary' : undefined"
                    @click="memberWillReceiveBillingEmail = false"
                >
                    {{ __('common:no') }}
                </CButton>
                <CButton
                    class="tw-w-full"
                    :variant="memberWillReceiveBillingEmail ? 'primary' : undefined"
                    @click="memberWillReceiveBillingEmail = true"
                >
                    {{ __('common:yes') }}
                </CButton>
            </CButtonGroup>
        </CFormGroup>
        <CFormGroup>
            <CLabel>
                <i
                    aria-hidden="true"
                    class="fad fa-note tw-mr-2 tw-text-blue-500"
                />{{ __('common:note_one') }}
            </CLabel>
            <CFormTextArea
                v-model="localMemberModel.attributes.note"
                :has-error="memberError.hasError('note')"
                :placeholder="__('common:note_one')"
            />
            <CFormErrorMessageList :errors="memberError.getErrors('note')"/>
        </CFormGroup>
        <CFormSubmitTrigger/>
    </CForm>
</template>

<script setup lang="ts">
    import type {PropType} from 'vue';
    import {onBeforeMount, ref} from 'vue';
    import SelectFamily from '@/modules/family/components/family/SelectFamily.vue';
    import type FamilyModel from '@/modules/family/models/FamilyModel';
    import type OrganizationModel from '@/modules/organization/models/OrganizationModel';
    import MemberModel from '@/modules/family/models/MemberModel';
    import MemberError from '@/modules/family/utils/member/memberError';
    import AddressFields from '@/modules/cashier/components/core/address/molecules/AddressFields.vue';
    import CafIcon from '@/modules/legacy/components/icons/CafIcon.vue';
    import MemberOrganizationPivot from '@/modules/family/models/MemberOrganizationPivot';
    import FamilyMemberPivot from '@/modules/family/models/FamilyMemberPivot';

    defineExpose({save});

    const props = defineProps({
        organizationModel: {
            type: Object as PropType<OrganizationModel>,
            required: true,
        },
        memberModel: {
            type: Object as PropType<MemberModel>,
            default: undefined,
        },
        familyModel: {
            type: Object as PropType<FamilyModel>,
            default: undefined,
        },
    });

    const emit = defineEmits(['loading', 'saved']);

    const localMemberModel = ref(new MemberModel());
    const localFamilyModel = ref<FamilyModel>();
    const memberError = ref(new MemberError());
    const memberWillReceiveBillingEmail = ref(false);

    onBeforeMount(() => {
        localFamilyModel.value = props.familyModel;
        localMemberModel.value.attributes.last_name = props.familyModel.attributes.name;
        localMemberModel.value.attributes.nursery_id = props.organizationModel.id;
        localMemberModel.value.attributes.address.country_code = props.organizationModel.attributes.address.country_code ?? 'FR';
    });

    function onFamilyChange(familyModel: FamilyModel | undefined) {
        if (familyModel) {
            localMemberModel.value.attributes.last_name = familyModel.attributes.name;
        }
    }

    function save() {
        emit('loading', true);
        localMemberModel.value.save().then(() => {
            if (localMemberModel.value.wasRecentlyCreated) {
                const promises = [] as Array<Promise<any>>;

                if (props.familyModel) {
                    // Attach to family
                    const familyPivot = new FamilyMemberPivot();
                    familyPivot.attributes.account_id = localMemberModel.value.attributes.account_id;
                    familyPivot.attributes.member_id = localMemberModel.value.id;
                    familyPivot.attributes.family_id = props.familyModel.id;
                    promises.push(familyPivot.save());
                }

                // Attach to organization
                const pivot = new MemberOrganizationPivot();
                pivot.attributes.account_id = localMemberModel.value.attributes.account_id;
                pivot.attributes.member_id = localMemberModel.value.id;
                pivot.attributes.organization_id = props.organizationModel.id;
                promises.push(pivot.save());

                Promise.all(promises).then(() => {
                    emit('saved', localMemberModel.value);
                    emit('loading', false);
                });
            } else {
                emit('saved', localMemberModel.value);
                emit('loading', false);
            }
        }).catch(error => {
            emit('loading', false);
            memberError.value.reset(error);
        });
    }

</script>
