<template>
    <CForm>
        <div
            class="tw-grid tw-gap-4"
            :class="!selectedTag.exists ? 'sm:tw-grid-cols-2 tw-grid-cols-1' : 'tw-grid-cols-1'"
        >
            <CFormGroup v-if="!selectedTag.exists">
                <CLabel>
                    {{ __('tag:category_one') }}
                </CLabel>
                <MDropdown ref="dropdown">
                    <MButton
                        class="tw-w-full tw-justify-between"
                        icon-menu
                    >
                        <template v-if="getCategoryByType(selectedTag.attributes.type)">
                            <FontAwesomeIcon
                                class="tw-mr-1 tw-text-primary-500"
                                :icon="getCategoryByType(selectedTag.attributes.type).icon"
                            />
                            {{ getCategoryByType(selectedTag.attributes.type).name }}
                        </template>
                        <template v-else>
                            {{ __('tag:category_one') }}
                        </template>
                    </MButton>
                    <template #content>
                        <div class="TagsManagerModalForm__categories-list">
                            <CDropdownMenuItem
                                v-for="(cat, i) in categories"
                                :key="'selectCategory' + i"
                                class="tw-w-full"
                                @click="selectCategory(cat)"
                            >
                                <FontAwesomeIcon
                                    class="tw-mr-2 tw-text-primary-500"
                                    :icon="cat.icon"
                                />
                                {{ cat.name }}
                            </CDropdownMenuItem>
                        </div>
                    </template>
                </MDropdown>
            </CFormGroup>
            <MInputGroup
                v-model="selectedTag.attributes.name"
                :errors="errorHandler.getErrors('name')"
                :label="__('tag:tag_name')"
                :placeholder="__('tag:tag_name')"
            />
        </div>
        <div class="tw-grid tw-grid-cols-2 tw-gap-4">
            <CFormGroup>
                <CLabel>{{ __('common:icon') }}</CLabel>
                <TagSelector v-model="selectedTag.attributes"/>
            </CFormGroup>
            <CFormGroup>
                <CLabel>{{ __('common:color') }}</CLabel>
                <ColorSwatches
                    v-model="selectedTag.attributes.color"
                    :colors="swatchesColors"
                    :row-length="6"
                    show-border
                    show-fallback
                    :swatch-size="28"
                    :trigger-style="{width: '32px', height: '32px'}"
                />
            </CFormGroup>
        </div>
    </CForm>
</template>

<script lang="ts">
    import {defineComponent, type PropType, ref} from 'vue';
    import type ErrorHandler from '@/modules/legacy/libs/errors/errorHandler';
    import type TagModel from '@/modules/tag/models/TagModel';
    import TagSelector from '@/modules/tag/components/TagSelector.vue';
    import ColorSwatches from 'vue-swatches';
    import 'vue-swatches/dist/vue-swatches.min.css';

    export default defineComponent({
        components: {
            TagSelector,
            ColorSwatches,
        },
        props: {
            selectedTag: {
                type: Object as PropType<TagModel>,
                required: true,
            },
            errorHandler: {
                type: Object as PropType<ErrorHandler>,
                required: true,
            },
            categories: {
                type: Array,
                required: true,
            },
            getCategoryByType: {
                type: Function,
                required: true,
            },
        },
        setup(props) {
            const dropdown = ref();
            const swatchesColors = [
                '#F44336',
                '#E91E63',
                '#9C27B0',
                '#673AB7',
                '#3F51B5',
                '#2196F3',
                '#03A9F4',
                '#00BCD4',
                '#009688',
                '#4CAF50',
                '#8BC34A',
                '#CDDC39',
                '#FFEB3B',
                '#FFC107',
                '#FF9800',
                '#FF5722',
                '#795548',
                '#9E9E9E',
                '#607D8B',
                '#000000',
            ];

            function selectCategory(cat: { value: string }) {
                props.selectedTag.attributes.type = cat.value;
                dropdown.value.hide();
            }

            return {
                dropdown,
                swatchesColors,
                selectCategory,
            };
        },
    });
</script>

<style lang="scss" scoped>
    .TagsManagerModalForm__categories-list {
        @apply tw-overflow-auto tw-max-h-60;
    }
</style>
