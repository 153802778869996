<script setup lang="ts">
    import {onMounted} from 'vue';
    import type PaymentModel from '@/modules/cashier/models/PaymentModel';
    import {
        usePaymentSuggestionsState,
    } from '@/modules/cashier/payment/infrastructure/components/usePaymentSuggestionsState';
    import PaymentSuggestionsListItem
        from '@/modules/cashier/payment/infrastructure/components/PaymentSuggestionsListItem.vue';
    import useSaveAllocations from '@/modules/cashier/payment/infrastructure/components/useSaveAllocations';
    import type {AbstractAllocationAggregate} from '@/modules/cashier/payment/domain/AbstractAllocationAggregate';

    const props = defineProps<{ payment: PaymentModel, suggestionType: 'transaction' | 'credit_note' }>();

    const emit = defineEmits<{
        (e: 'saved'): void;
    }>();

    const {
        isFetching,
        fetch,
        allocations,
    } = usePaymentSuggestionsState(props.payment);

    const {
        save,
        isSaving,
    } = useSaveAllocations();

    onMounted(() => {
        fetch(props.suggestionType);
    });

    async function onAddedAllocation(allocationAggregate: AbstractAllocationAggregate) {
        if (await save(allocationAggregate)) {
            emit('saved');
        }
    }
</script>

<template>
    <div class="PaymentSuggestionsList">
        <loader v-if="isFetching"/>
        <MHeading
            v-else-if="allocations.length === 0"
            class="PaymentSuggestionsList__empty"
        >
            <template v-if="suggestionType === 'transaction' && payment.isRefund">
                {{ __('billing_transaction:no_refundable_suggestions_available_transaction_debit') }}
            </template>
            <template v-else-if="suggestionType === 'transaction' && !payment.isRefund">
                {{ __('billing_transaction:no_refundable_suggestions_available_transaction_credit') }}
            </template>
            <template v-else>
                {{ __('billing_transaction:no_refundable_suggestions_available_credit_note') }}
            </template>
        </MHeading>
        <div
            v-else
            class="PaymentSuggestionsList__container"
        >
            <template v-for="allocation in allocations">
                <PaymentSuggestionsListItem
                    :key="allocation.allocation?.getKey()"
                    :allocation="allocation"
                    :saving="isSaving"
                    @add-allocation="onAddedAllocation"
                />
            </template>
        </div>
    </div>
</template>
<style lang="scss" scoped>
    .PaymentSuggestionsList__container {
        @apply tw-flex tw-flex-col tw-gap-2;
    }

    .PaymentSuggestionsList__empty {
        @apply tw-text-center;
    }
</style>
