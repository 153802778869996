import {Epoch} from '@meekohq/lumos';
import __ from '@/modules/app/utils/i18n-facade';
import type {Ref} from 'vue';
import {computed} from 'vue';
import {capitalize} from 'lodash-es';

/**
 * Returns a human readable period name
 * @param period
 * @param fallbackText
 */
export default function(period: Ref<{ from?: Date, to?: Date }>, fallbackText = '') {
    const toHuman = computed(() => {
        if (!period.value.from && !period.value.to) {
            return fallbackText;
        }

        const epochFrom = new Epoch(period.value.from);
        const epochTo = new Epoch(period.value.to);

        // If from is set and to is not, return the from date in the human format
        if (period.value.from && !period.value.to) {
            return __('common:date_format.human_starting_from', {from: epochFrom.toLocaleString(Epoch.presets.DATE_SHORT)});
        }

        // If to is set and from is not, return the to date in the human format
        if (!period.value.from && period.value.to) {
            return __('common:date_format.human_until', {to: epochTo.toLocaleString(Epoch.presets.DATE_SHORT)});
        }

        let name = `${capitalize(epochFrom.toLocaleString(Epoch.presets.DATE_SHORT))} - ${capitalize(epochTo.toLocaleString(Epoch.presets.DATE_SHORT))}`;

        // If the from and to dates diff is one or several full months, return the dates in the short month format
        if (epochFrom.equalTo(epochFrom.startOfMonth()) && epochTo.equalTo(epochTo.endOfMonth())) {
            const fromText = epochFrom.toLocaleString({
                month: 'short',
                year: 'numeric',
            });
            const toText = epochTo.toLocaleString({
                month: 'short',
                year: 'numeric',
            });
            name = `${capitalize(fromText)} - ${capitalize(toText)}`;

            // If the from and to dates are in the same month and same year, return the date combined in the long month format
            if (epochFrom.getYear() === epochTo.getYear() && epochFrom.getMonth() === epochTo.getMonth()) {
                name = capitalize(
                    epochFrom.toLocaleString({
                        month: 'long',
                        year: 'numeric',
                    }),
                );
            }
        }

        return name as string;
    });

    return {
        toHuman,
    };
}
