import DocumentModel from '@/modules/document/models/DocumentModel';
import type {Collection, Model, ModelCollection} from '@meekohq/lumos';
import {type Contracts} from '@meekohq/lumos';
import type OrganizationModel from '@/modules/organization/models/OrganizationModel';
import {ref} from 'vue';
import _startWith from 'lodash-es/startsWith';
import {upload} from '@/modules/legacy/libs/usercontent';
import type DocumentOrganizationPivot from '@/modules/document/models/DocumentOrganizationPivot';
import useAuth from '@/modules/app/composables/useAuth';

export type SaveDocumentTransport = {
    document: DocumentModel,
    file: File,
    organizationsToAttach: OrganizationModel[],
    organizationsToDetach: OrganizationModel[],
    organizationsPivotsToUpdate: ModelCollection<DocumentOrganizationPivot> | Collection<DocumentOrganizationPivot>,
    documentVisibility: {
        staffs_access: boolean,
        parents_access: boolean,
        web_access: boolean,
    },
    resource: Model,
};
export default function() {
    const loading = ref(false);
    const {user} = useAuth();

    async function saveDocument(document: SaveDocumentTransport['document'], file?: SaveDocumentTransport['file'], options?: Contracts.Database.OptionsType) {
        // If the document is not a link, we upload the file to the usercontent service
        // and save the key and hash to the model. We also remove the link from the model to avoid bugs
        if (file) {
            document.attributes.link = null;

            const uploadedFile: { key: any; hash: any; } = await upload(file);

            document.attributes.original_filename = file ? file.name : '';

            document.attributes.filename = JSON.stringify({
                key: uploadedFile.key,
                hash: uploadedFile.hash,
            });
        }

        // If the document is a link, we set the file related attributes to null in the model to avoid bugs
        if (document.attributes.link) {
            document.attributes.filename = null;
            document.attributes.original_filename = null;

            // If the link does not start with http or https, we add https to the link
            if (!_startWith(document.attributes.link, 'http')) {
                document.attributes.link = 'https://' + document.attributes.link;
            }
        }

        // If the document is new, we set the account_id to the current user's account_id
        if (!document.exists) {
            document.attributes.account_id = user.value.attributes.account_id;
        }

        // We save the document
        await document.save(options);

        return document;
    }

    function afterAttachDocumentToOrganizations(
        organizationsToAttach: OrganizationModel[],
        organizationsPivots: DocumentOrganizationPivot[],
        document: DocumentModel,
    ) {
        // We add the organizations to the document's organizations collection
        organizationsToAttach.forEach((organization: OrganizationModel) => {
            const organizationPivot = organizationsPivots.find(pivot => {
                return pivot.attributes.organization_id === organization.getKey();
            });

            organization.setRelation('pivot', organizationPivot);

            document.organizations().value().push(organization);
        });
    }

    async function attachDocumentToOrganizations(
        document: SaveDocumentTransport['document'],
        organizationsToAttach: SaveDocumentTransport['organizationsToAttach'],
        documentVisibility: SaveDocumentTransport['documentVisibility']) {
        const organizationsPromises: Array<Promise<DocumentOrganizationPivot>> = [];

        // We attach the document to the organizations with the visibilty provided in the options
        organizationsToAttach.forEach((organization: OrganizationModel) => {
            //TODO: use a runner when the document table has an uuid id
            organizationsPromises.push(document.organizations().attach(organization, {
                account_id: document.attributes.account_id,
                ...documentVisibility,
            }));
        });

        const organizationsPivots = await Promise.all(organizationsPromises);

        afterAttachDocumentToOrganizations(organizationsToAttach, organizationsPivots, document);
    }

    async function detachDocumentFromOrganizations(
        document: SaveDocumentTransport['document'],
        organizationsToDetach: SaveDocumentTransport['organizationsToDetach']) {
        const organizationsPromises: Array<Promise<number>> = [];

        // We detach the document from the organizations provided
        organizationsToDetach.forEach((organization: OrganizationModel) => {
            //TODO: use a runner when the document table has an uuid id
            organizationsPromises.push(document.organizations().detach(organization));
        });

        await Promise.all(organizationsPromises);

        // Remove the organizations from the document's organizations collection
        organizationsToDetach.forEach((organizationToDetach: OrganizationModel) => {
            document.organizations().mutate(organizations => {
                return organizations.reject(o => o.getKey() === organizationToDetach.getKey());
            });
        });
    }

    async function updateDocumentOrganizationPivots(
        pivots: SaveDocumentTransport['organizationsPivotsToUpdate'],
        visibility: SaveDocumentTransport['documentVisibility'],
    ) {
        const pivotPromises: Array<Promise<DocumentOrganizationPivot>> = [];

        // Update the visibility of the document for the organizations
        pivots.each((pivot: DocumentOrganizationPivot) => {
            pivot.attributes.staffs_access = visibility.staffs_access;
            pivot.attributes.parents_access = visibility.parents_access;
            pivot.attributes.web_access = visibility.web_access;
            pivotPromises.push(pivot.save());
        });

        await Promise.all(pivotPromises);
    }

    async function attachDocumentToResource(
        document: SaveDocumentTransport['document'],
        resource: SaveDocumentTransport['resource'],
        options?: Contracts.Database.OptionsType) {
        // We attach the document to the resource provided (e.g. a child, a staff, etc.)
        await DocumentModel.attachResource(document, resource.getKey(), resource.getType(), options);
    }

    /*
        * Runs the complete flow to save a document, attaching it to the organizations provided,
        * detaching it from the organizations provided, updating the visibility of the document
        * and attaching it to the resource provided
     */
    async function saveAndAttachDocument(
        document: SaveDocumentTransport['document'],
        file?: SaveDocumentTransport['file'],
        organizationsToAttach?: SaveDocumentTransport['organizationsToAttach'],
        organizationsToDetach?: SaveDocumentTransport['organizationsToDetach'],
        organizationsPivotsToUpdate?: SaveDocumentTransport['organizationsPivotsToUpdate'],
        documentVisibility?: SaveDocumentTransport['documentVisibility'],
        resource?: SaveDocumentTransport['resource'],
    ) {
        //TODO: uncomment lines when the document table has an uuid id and remove await for the methods
        // //If no options are provided, we instanciate a new transaction
        //options = options ?? {mqlRunner: new Mql.Transaction()};

        const documentExists = document.exists;
        await saveDocument(document, file);

        const pivotPromises: Array<Promise<any>> = [];

        if (organizationsToDetach) {
            pivotPromises.push(detachDocumentFromOrganizations(document, organizationsToDetach));
        }

        if (organizationsToAttach && documentVisibility) {
            pivotPromises.push(attachDocumentToOrganizations(document, organizationsToAttach, documentVisibility));
        }

        if (organizationsPivotsToUpdate && documentVisibility) {
            pivotPromises.push(updateDocumentOrganizationPivots(organizationsPivotsToUpdate, documentVisibility));
        }

        if (!documentExists && resource) {
            pivotPromises.push(attachDocumentToResource(document, resource));
        }

        await Promise.all(pivotPromises);

        //options.mqlRunner?.run();

        return document;
    }

    return {
        loading,
        saveAndAttachDocument,
    };
}
