<template>
    <div class="Maintenance__wrapper">
        <div class="Maintenance__logo">
            <img
                alt="logo"
                :src="MeekoMaintenance"
            >
        </div>
        <div class="Maintenance__content">
            <div>
                {{ __('app:meeko_s_team_is_making_a_few_adjustments') }}
            </div>
            <div>
                {{ __('app:we_ll_be_back_in_a_few_moments_dots') }}
            </div>
        </div>
    </div>
</template>


<script lang="ts">
    import {defineComponent} from 'vue';
    import MeekoMaintenance from '@/assets/images/MeekoMaintenance.png';

    export default defineComponent({
        setup() {
            return {
                MeekoMaintenance,
            };
        },
    });
</script>

<style lang="scss" scoped>
    .Maintenance__wrapper {
    }

    .Maintenance__logo {
        img {
            @apply tw-absolute tw-rotate-[10deg] tw-transition-all tw-ease-in-out tw-duration-300;
            z-index: 0;
            left: -40px;
            bottom: -50px;
            width: 500px;

            @screen md {
                left: -60px;
                width: 650px;
            }

            @screen 2xl {
                left: -80px;
                width: 750px;
            }
        }
    }

    .Maintenance__content {
        @apply tw-absolute tw-z-10 tw-inset-x-8 tw-inset-y-0 tw-flex tw-flex-col tw-justify-start tw-text-center tw-mt-48;
        @apply tw-bg-clip-text tw-text-transparent tw-bg-gradient-to-br tw-from-blue-600 tw-to-cyan-500 tw-text-4xl tw-font-display;

        @screen md {
            @apply tw-mt-32 md:tw-text-5xl;
        }

        @screen xl {
            @apply tw-justify-center tw-mt-0 tw-text-left;
            left: 45%;
        }

        @screen xl {
            @apply tw-justify-center tw-mt-0 tw-text-left;
            left: 45%;
        }
    }
</style>

<style lang="scss">
    body {
        //@apply tw-bg-gradient-to-br tw-from-[#fff] tw-from-50% tw-to-primary-600 tw-to-50%
    }
</style>
