import {AbstractAllocationAggregate} from '@/modules/cashier/payment/domain/AbstractAllocationAggregate';
import type  {SharedRemaingAmount} from '@/modules/cashier/payment/domain/SharedRemaingAmount';
import type  AllocationModel from '@/modules/cashier/models/AllocationModel';
import {Epoch} from '@meekohq/lumos';
import type  InvoiceModel from '@/modules/cashier/models/InvoiceModel';
import type  PaymentModel from '@/modules/cashier/models/PaymentModel';

export class CreditNoteAllocationAggregate extends AbstractAllocationAggregate<InvoiceModel, PaymentModel> {
    public constructor(
        source: InvoiceModel,
        destination: PaymentModel,
        sharedRemainingAmount: SharedRemaingAmount,
        allocation?: AllocationModel,
    ) {
        super(source, destination, sharedRemainingAmount, allocation);
    }

    get allocatableRemainingAmount(): number {
        return this._source.computed.remaining_amount;
    }

    get allocatableAmount(): number {
        return this._source.computed.unsigned_grand_total;
    }

    get allocatableDate(): Epoch {
        return this._source.attributes.date ? Epoch.parse(this._source.attributes.date, 'yyyy-MM-dd') : Epoch.now();
    }

    get allocatableReference(): string | undefined {
        return this._source.attributes.no;
    }

    get allocationMustBeIgnored(): boolean {
        return false;
    }
}
