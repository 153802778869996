<template>
    <label
        class="tw-uppercase tw-text-sm tw-font-semibold tw-leading-5 tw-m-0"
        :rounded="true"
        size="sm"
    >
        <slot/>
    </label>
</template>

<script lang="ts">
    import {defineComponent} from 'vue';

    export default defineComponent({
        components: {},
        props: {},
        setup() {
            return {};
        },
    });
</script>

<style
    lang="scss"
    scoped
>
</style>
