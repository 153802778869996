import {config, ServiceProvider} from '@meekohq/lumos';
import useAbility from '@/modules/app/composables/useAbility';

export default class ModuleAbilitiesServiceProvider extends ServiceProvider {
    public async boot() {
        const {defineAbility} = useAbility();

        defineAbility('support', () => {
            return !!window.localStorage.getItem('support-token')
                || config('app.env') === 'development';
        });

        defineAbility('access-to-family-module', (permissions?: any) => {
            const {anyOfAll} = useAbility(permissions);

            return anyOfAll('kids_details')
                || anyOfAll('kids_dailies')
                || anyOfAll('kids_contracts')
                || anyOfAll('kids_health')
                || anyOfAll('kids_food')
                || anyOfAll('kids_authorizations')
                || anyOfAll('kids_documents')
                || anyOfAll('family_members_details')
                || anyOfAll('family_members_chat')
                || anyOfAll('family_members_chat_manager_only')
                || anyOfAll('family_members_documents');
        });

        defineAbility('access-to-dashboard-module', (permissions?: any) => {
            const {anyOfAll} = useAbility(permissions);

            return anyOfAll('dashboard')
                || anyOfAll('export');
        });

        defineAbility('access-to-tasks-module', (permissions?: any) => {
            const {anyOfAll} = useAbility(permissions);

            return anyOfAll('tasks');
        });

        defineAbility('access-to-plannings-module', (permissions?: any) => {
            const {anyOfAll} = useAbility(permissions);

            return anyOfAll('kids_planning')
                || anyOfAll('staffs_planning')
                || anyOfAll('groups');
        });

        defineAbility('access-to-kids-module', (permissions?: any) => {
            const {anyOfAll} = useAbility(permissions);

            return anyOfAll('kids_details')
                || anyOfAll('kids_dailies')
                || anyOfAll('kids_contracts')
                || anyOfAll('kids_health')
                || anyOfAll('kids_food')
                || anyOfAll('kids_authorizations')
                || anyOfAll('kids_documents');
        });

        defineAbility('access-to-family-members-module', (permissions?: any) => {
            const {anyOfAll} = useAbility(permissions);

            return anyOfAll('family_members_details')
                || anyOfAll('family_members_chat')
                || anyOfAll('family_members_chat_manager_only')
                || anyOfAll('family_members_documents');
        });

        defineAbility('access-to-families-module', (permissions?: any) => {
            const {anyOfAll} = useAbility(permissions);

            return anyOfAll('kids_details')
                || anyOfAll('kids_dailies')
                || anyOfAll('kids_contracts')
                || anyOfAll('kids_health')
                || anyOfAll('kids_food')
                || anyOfAll('kids_authorizations')
                || anyOfAll('kids_documents');
        });

        defineAbility('access-to-registrations-module', (permissions?: any) => {
            const {anyOfAll} = useAbility(permissions);

            return anyOfAll('registrations');
        });

        defineAbility('access-to-billings-module', (permissions?: any) => {
            const {anyOfAll} = useAbility(permissions);

            return anyOfAll('invoices')
                || anyOfAll('transactions');
        });

        defineAbility('access-to-hr-module', (permissions?: any) => {
            const {anyOfAll} = useAbility(permissions);

            return anyOfAll('staffs_details')
                || anyOfAll('staffs_contracts')
                || anyOfAll('staffs_documents')
                || anyOfAll('staffs_statistics')
                || anyOfAll('staffs_balances')
                || anyOfAll('hr_request');
        });

        defineAbility('access-to-photos-module', (permissions?: any) => {
            const {anyOfAll} = useAbility(permissions);

            return anyOfAll('photos');
        });

        defineAbility('access-to-documents-module', (permissions?: any) => {
            const {anyOfAll} = useAbility(permissions);

            return anyOfAll('documents');
        });

        defineAbility('access-to-communication-module', (permissions?: any) => {
            const {anyOfAll} = useAbility(permissions);

            return anyOfAll('posts')
                || anyOfAll('nursery_website');
        });

        defineAbility('access-to-equipments-module', (permissions?: any) => {
            const {anyOfAll} = useAbility(permissions);

            return anyOfAll('equipments')
                || anyOfAll('rooms');
        });

        defineAbility('access-to-settings-module', (permissions?: any) => {
            const {anyOfAll} = useAbility(permissions);

            return anyOfAll('nursery')
                || anyOfAll('devices')
                || anyOfAll('tags')
                || anyOfAll('activities')
                || anyOfAll('users');
        });
    }
}
