<template>
    <transition
        mode="out-in"
        name="transition-fade"
    >
        <slot/>
    </transition>
</template>

<script lang="ts">
    import {defineComponent} from 'vue';

    export default defineComponent({
        props: {},
        setup() {
            return {};
        },
    });

</script>

<style lang="scss" scoped>

</style>
