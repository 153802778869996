import type {Ref} from 'vue';
import {computed, getCurrentInstance, reactive, ref, watch} from 'vue';
import moment from 'moment';
import swal from 'sweetalert2/dist/sweetalert2.js';
import __ from '@/modules/app/utils/i18n-facade';
import _orderBy from 'lodash-es/orderBy';
import _debounce from 'lodash-es/debounce';
import Mirror from '@/modules/legacy/helpers/mirror.helper';
import {upload} from '@/modules/legacy/libs/usercontent';
import {collect, Epoch, now} from '@meekohq/lumos';
import usePaginator from '@/modules/legacy/utils/usePaginator';
import StaffModel from '@/modules/human-resources/models/StaffModel';
import StaffError from '@/modules/human-resources/utils/staff/StaffError';
import StaffsOrganizationsPivot from '@/modules/human-resources/models/StaffsOrganizationsPivot';
import useManager from '@/modules/app/composables/useManager';
import {calendarTypes} from '@/modules/human-resources/models/CalendarModel';
import type OrganizationModel from '@/modules/organization/models/OrganizationModel';
import ContractModel from '@/modules/human-resources/models/ContractModel';
import type TeamModel from '@/modules/human-resources/models/TeamModel';
import DocumentModel from '@/modules/document/models/DocumentModel';
import type DocumentOrganizationPivot from '@/modules/document/models/DocumentOrganizationPivot';
import useAuth from '@/modules/app/composables/useAuth';
import useNotification from '@/modules/meeko-ui/composables/useNotification';

type StaffFilters = { teams: TeamModel[] };

const defaultFilters = ref<StaffFilters>({teams: []});

export default function(
    defaultOrganization: Ref<OrganizationModel> | null = null,
    staff: StaffModel | null = null,
    staffModal: any = null,
    period: Ref<{ from: null | string; to: null | string }> | null = null,
    savePaginator = true,
    filters = defaultFilters,
    shouldCreateStaffOnShown = false,
) {
    const {activeOrganization} = useManager();
    const {legacyUser} = useAuth();
    const organization = defaultOrganization ? defaultOrganization : activeOrganization;
    const getEventsWithoutOrganizations = ref(false);
    const staffs: Ref<StaffModel[]> = ref([]);
    const filteredStaffs = computed(() => {
        return _orderBy(
            staffs.value,
            staff => {
                if (
                    window.localStorage.getItem('display:orderBy') ==
                    'first_name'
                ) {
                    return staff.attributes.first_name;
                }

                return staff.attributes.last_name;
            },
            'asc',
        );
    });

    const selectedStaff = ref<StaffModel>();
    const loading = ref(true);
    const saveLoading = ref(false);

    const staffError = reactive(new StaffError());

    // FILTERS

    const selectedTags = ref(
        JSON.parse(localStorage.getItem('staffs:index:tags') as string),
    );

    const withoutTags = ref(JSON.parse(localStorage.getItem('staffs:index:withoutTags') as string) as boolean);

    watch(selectedTags, val => {
        if (val) {
            localStorage.setItem('staffs:index:tags', JSON.stringify(val));
        } else {
            localStorage.removeItem('staffs:index:tags');
        }
        getStaffs(1);
    });

    watch(withoutTags, val => {
        localStorage.setItem('staffs:index:withoutTags', JSON.stringify(val || false));
        getStaffs(1);
    });

    const selectedContracts = ref(
        localStorage.getItem('staffs:index:contracts')
            ? JSON.parse(
                localStorage.getItem('staffs:index:contracts') as string,
            )
            : [],
    );

    watch(selectedContracts, () => {
        getStaffs(1);
    });

    const selectedOrganizations = ref<OrganizationModel[]>([organization.value]);
    const selectedOrganizationsIds = computed(() => collect(selectedOrganizations.value).keyBy(m => m.getKey()).keys().all());

    watch(selectedOrganizations, () => {
        getStaffs(1);
    });

    watch(() => period?.value, (val, oldVal) => {
        onPeriodChange(val, oldVal);
    });

    function onPeriodChange(newPeriod, oldPeriod) {
        if (newPeriod?.from) {
            localStorage.setItem('staffs:statistics:from', newPeriod.from);
        }

        if (newPeriod?.to) {
            localStorage.setItem('staffs:statistics:to', newPeriod.to);
        }

        if (newPeriod?.from
            && newPeriod?.to
            && (
                !moment(newPeriod?.from).isSame(oldPeriod?.from, 'day')
                || !moment(newPeriod?.to).isSame(oldPeriod?.to, 'day')
            )
        ) {
            getStaffs(1);
        }
    }

    function initFilters() {
        selectedTags.value = JSON.parse(
            localStorage.getItem('staffs:index:tags') as string,
        );

        const localContracts = localStorage.getItem(
            'staffs:index:contracts',
        ) as string;

        selectedContracts.value = localContracts
            ? JSON.parse(localContracts)
            : [];
    }

    // STAFF QUERY

    const responsePaginator = ref();

    const {paginator, currentPage, setPaginator} = usePaginator(
        getStaffs,
        savePaginator,
    );

    function getStaffs(page: number = currentPage.value || 1) {
        loading.value = true;
        debounceStaffs(page);
    }

    const debounceStaffs = _debounce((page: number = currentPage.value || 1) => {
        staffs.value = [];

        const indexQuery = StaffModel.query()
            .with(new StaffModel().contracts())
            .with(new StaffModel().tagsPivots())
            .with(new StaffModel().tags())
            .with(new StaffModel().organizationsPivots())
            .whereHas(new StaffModel().organizationsPivots(), query => {
                query.whereIn('organization_id', selectedOrganizationsIds.value);
            })
            .orderBy(
                window.localStorage.getItem('display:orderBy')
                    ? window.localStorage.getItem('display:orderBy') as string
                    : 'first_name',
            );

        if (staff) {
            indexQuery.where('id', '=', staff.id);
        }

        if (selectedContracts.value && selectedContracts.value.length) {
            indexQuery.where(query => {
                if (selectedContracts.value.includes('coming')) {
                    query.whereHas(new StaffModel().contracts(), query1 => query1.scope('coming'));
                }
                if (selectedContracts.value.includes('current')) {
                    query.orWhereHas(new StaffModel().contracts(), query1 => query1.scope('active'));
                }
                if (selectedContracts.value.includes('past')) {
                    query.orWhereHas(new StaffModel().contracts(), query1 =>
                        query1.scope('until',
                            moment().subtract(1, 'day').format('YYYY-MM-DD'),
                        ),
                    );
                }
            });
        }

        if (filters.value?.teams?.length) {
            indexQuery.whereHas(new StaffModel().teams(), query => {
                query.whereIn('id', collect(filters.value?.teams).pluck('id').all() as string[]);
            });
        }

        if (selectedTags.value) {
            indexQuery.whereHas(new StaffModel().tags(), query => {
                query.whereIn('id', selectedTags.value);
            });
        }

        if (withoutTags.value) {
            indexQuery.whereDoesntHave(new StaffModel().tags());
        }

        indexQuery
            .paginate(20, page)
            .then(response => {
                currentPage.value = page;
                responsePaginator.value = response;
                setPaginator({
                    current_page: response.currentPage(),
                    last_page: response.lastPage(),
                });

                staffs.value = response.items().all();
                selectedStaff.value = staffs.value[0];
                loading.value = false;
            })
            .catch(() => {
                loading.value = false;
            });
    }, 1000, {leading: true, trailing: true});

    function getStaff(staffId: string) {
        loading.value = true;
        // Add EagerLoad.
        const allowedOrga = collect(legacyUser.value.nurseries).keyBy('id').keys().all();
        const query = StaffModel.query()
            .with(new StaffModel().contracts())
            .with(new StaffModel().contracts(), q => {
                q.with(new ContractModel().organizations(), q1 => q1.whereIn('id', allowedOrga));
                q.with(new ContractModel().job());
                q.with(new ContractModel().contractType());
            })
            .with(new StaffModel().tags())
            .with(new StaffModel().organizationsPivots());

        query
            .find(staffId)
            .then(response => {
                selectedStaff.value = response;
                loading.value = false;
            })
            .catch(() => {
                loading.value = false;
            });
    }

    async function saveNewStaff(staff: StaffModel) {
        saveLoading.value = true;

        try {
            const savedStaff: StaffModel = await staff.save();

            const pivot = staff
                .organizationsPivots()
                .value()!
                .first();

            savedStaff
                .organizationsPivots()
                .value()
                .push(pivot);
            pivot.staff().associate(savedStaff);
            await pivot.save();

            staffs.value.push(savedStaff);

            useNotification().success(__('hr_staff:staff_added_successfully'));
            saveLoading.value = false;
            staffModal.value?.$refs.modal?.hide();
        } catch (e) {
            saveLoading.value = false;
            staffError.reset(e);
        }
    }

    function attachStaff(staff: StaffModel) {
        saveLoading.value = true;

        const organizationsPivots = new StaffsOrganizationsPivot();
        // organizationsPivots.staff().associate(staff);
        organizationsPivots.attributes.account_id = staff.attributes.account_id;
        organizationsPivots.attributes.staff_id = staff.id;
        organizationsPivots.attributes.organization_id = `${organization.value.id}`;
        organizationsPivots.attributes.visible_on_team = true;
        organizationsPivots.attributes.visible_on_family = false;
        organizationsPivots.attributes.visible_on_website = false;
        staff
            .organizationsPivots()
            .value()
            .push(organizationsPivots);

        organizationsPivots
            .save()
            .then(async () => {
                staffs.value.push(staff);

                const documents = await DocumentModel.query()
                    .scope('notInOrganization', organization.value)
                    .scope('morphConstraint', {resourceId: staff.id, resourceType: staff.type})
                    .with('organizationsPivots')
                    .all();

                const promises: Array<Promise<DocumentOrganizationPivot>> = [];
                documents.each(async document => {
                    const firstOrganizationPivot = document.organizationsPivots().value().first();

                    promises.push(document.organizations().attach(organization.value, {
                        account_id: firstOrganizationPivot.attributes.account_id,
                        parents_access: firstOrganizationPivot.attributes.parents_access,
                        staffs_access: firstOrganizationPivot.attributes.staffs_access,
                        web_access: firstOrganizationPivot.attributes.web_access,
                    }));
                });

                await Promise.all(promises);

                useNotification().success(__('hr_staff:staff_added_successfully'));
                staffModal.value?.$refs.modal?.hide();
                saveLoading.value = false;
            })
            .catch(() => {
                saveLoading.value = false;
            });
    }


    return {
        staffError,
        filteredStaffs,
        selectedStaff,
        selectedTags,
        withoutTags,
        selectedContracts,
        selectedOrganizations,
        loading,
        saveLoading,
        paginator,
        responsePaginator,
        getEventsWithoutOrganizations,
        selectedOrganizationsIds,
        initFilters,
        getStaffs,
        getStaff,
        saveNewStaff,
        attachStaff,
        ...useEditStaff(organization, staff, saveLoading, staffError, shouldCreateStaffOnShown),
    };
}

export function useEditStaff(
    organization,
    staff: StaffModel | null,
    saveLoading: Ref<boolean>,
    staffError,
    shouldCreateStaffOnShown = false,
) {
    const staffCopy = ref<Mirror | null>(null);
    const organizationPivot = ref<StaffsOrganizationsPivot | null>(null);
    const modal = ref<null | { show: () => null; hide: () => null }>(null);
    const {activeOrganization} = useManager();
    const tomorrowDate = ref(now().addDays(1).startOfDay());
    const vm = getCurrentInstance()?.proxy;

    function onHidden() {
        staffError.reset();
    }

    async function onShown() {
        if (shouldCreateStaffOnShown) {
            staff = new StaffModel();
            staff.attributes.account_id = `${organization.value.attributes.account_id}`;
            staff.attributes.gender = 'female';
            staff.attributes.address!.country_code = organization.value.attributes.address.country_code
                ? organization.value.attributes.address.country_code
                : 'FR';
            staff.attributes.nationality = organization.value.attributes.address.country_code
                ? organization.value.attributes.address.country_code
                : 'FR';
            const organizationsPivots = new StaffsOrganizationsPivot();
            organizationsPivots.attributes.account_id = staff.attributes.account_id;
            organizationsPivots.attributes.organization_id = `${organization.value.id}`;
            organizationsPivots.attributes.visible_on_team = true;
            organizationsPivots.attributes.visible_on_family = false;
            organizationsPivots.attributes.visible_on_website = false;

            staff
                .organizationsPivots()
                .value()
                .push(organizationsPivots);
        }

        staffCopy.value = new Mirror(staff!);

        organizationPivot.value = (await staffCopy.value.value
            .organizationsPivots()
            .load())
            .first(
                item =>
                    item.attributes.organization_id ===
                    `${organization.value.id}`,
            ) as StaffsOrganizationsPivot;
    }

    async function save() {
        saveLoading.value = true;
        const pivotPromises: Array<Promise<any>> = [];

        try {
            staffCopy?.value?.value.teams().value().each(item => {
                pivotPromises.push(item.pivot().save());
            });

            await Promise.all(pivotPromises);
            await staffCopy?.value?.value.save(undefined, true);
            await organizationPivot.value?.save();

            staffCopy.value!.commit();

            useNotification().success(__('hr_staff:staff_updated_successfully'));
            saveLoading.value = false;
            modal.value?.hide();
        } catch (e) {
            saveLoading.value = false;
            staffError.reset(e);
        }
    }

    function remove() {
        swal({
            title: __('hr_staff:delete_staff'),
            text: __('hr_staff:delete_staff_warning'),
            confirmButtonText: __('common:actions.delete'),
            cancelButtonText: __('common:actions.cancel'),

        }).then(result => {
            if (result.value) {
                staff?.delete().then(() => {
                    modal.value?.hide();
                    useNotification().success(__('hr_staff:staff_deleted_successfully'));
                    vm?.$router.push({
                        name: 'staffs.index',
                        params: {nursery: organization.value.id},
                    });
                });
            }
        });
    }

    function detachStaff() {
        const detachStaffDocuments = async (staffModel: StaffModel) => {
            const documents = await DocumentModel.query()
                .scope('inOrganization', organization.value)
                .scope('morphConstraint', {resourceId: staffModel.id, resourceType: staffModel.type})
                .all();

            const promises: Array<Promise<number>> = [];

            documents.each(document => {
                promises.push(document.organizations().detach(organization.value));
            });

            await Promise.all(promises);
        };

        swal({
            width: '40rem',
            title: __('hr_staff:detach_staff_ask'),
            text: __('hr_staff:file_will_not_be_deleted'),
            input: 'checkbox',
            inputPlaceholder: __('hr_staff:delete_event_as_the_same_time_ask', {date: tomorrowDate.value.toLocaleString(Epoch.presets.DATE_FULL)}),
            confirmButtonText: __('common:actions.detach'),
            cancelButtonText: __('common:actions.cancel'),
            confirmButtonClass: 'btn btn-dark tw-mr-2',
        }).then(async result => {
            if (result.value === 1) {
                try {
                    const pivot = organizationPivot.value as StaffsOrganizationsPivot;
                    await pivot.delete();

                    if (staff) {
                        await detachStaffDocuments(staff);
                    }

                    // Unless event with a calendarType absence, every event after the tomorrowDate of a staff are deleted.
                    await (staff as StaffModel).events()
                        .setQuery(query => {
                            query
                                .whereHas('organization', query1 => query1.where('id', activeOrganization.value.id))
                                .whereHas('type', q => {
                                    q.whereDoesntHave('calendar', q1 => {
                                        q1.where('internal_id', calendarTypes.absence);
                                    });
                                })
                                .where(query1 => {
                                    query1.whereDateTime('started_at', '>', tomorrowDate.value.toISOString())
                                        .orWhereDate('started_at_date', '>', tomorrowDate.value.toISOString());
                                });
                        })
                        .delete();


                    modal.value?.hide();
                    useNotification().success(__('hr_staff:staff_detached_and_event_deleted_successfully'));
                    vm?.$router.push({
                        name: 'staffs.index',
                        params: {nursery: organization.value.id},
                    });
                } catch (error) {
                    useNotification().error(__('hr_staff:errors.detach_staff_and_delete_events'));
                }
            }
            if (result.value === 0) {

                organizationPivot.value?.delete().then(async () => {
                    if (staff) {
                        await detachStaffDocuments(staff);
                    }

                    modal.value?.hide();
                    useNotification().success(__('hr_staff:staff_detached_successfully'));
                    vm?.$router.push({
                        name: 'staffs.index',
                        params: {nursery: organization.value.id},
                    });
                });
            }
            staffCopy.value?.value.organizationsPivots().mutate(value => value.reject(item => item.id === organizationPivot.value!.id as string));
            staffCopy.value?.commit();
        });
    }

    const fileInput = ref();
    const avatarLoading = ref(false);

    function editAvatar() {
        fileInput.value.click();
    }

    function updateAvatar(event) {
        avatarLoading.value = true;
        upload(event.target.files[0]).then(async response => {
            const staff = staffCopy.value?.value as StaffModel;
            staff.attributes.avatar = JSON.stringify({
                key: response.key,
                hash: response.hash,
            });

            try {
                await staff.save();
                staff.attributes.avatar = undefined;
                staffCopy.value!.commit();

                useNotification().success(__('hr_staff:staff_updated_successfully'));
                avatarLoading.value = false;
            } catch (e) {
                avatarLoading.value = false;
            }
        });
    }

    async function deleteAvatar() {
        const staff = staffCopy.value?.value as StaffModel;
        staff.attributes.avatar = null;
        await staff.save();
        staffCopy.value!.commit();
        useNotification().success(__('common:profile_picture_deleted'));
    }

    return {
        staffCopy,
        organizationPivot,
        modal,

        onShown,
        onHidden,
        save,
        remove,
        detachStaff,

        fileInput,
        avatarLoading,
        editAvatar,
        updateAvatar,
        deleteAvatar,
    };
}
