<template>
    <CButton
        class="MToggleFilter"
        :label="label"
        size="sm"
        @click="toggle"
    >
        <template #left-icons>
            <FontAwesomeIcon
                v-if="value"
                icon="fa-solid fa-circle-check"
            />
            <FontAwesomeIcon
                v-else
                icon="fa-regular fa-circle"
            />
        </template>
    </CButton>
</template>

<script lang="ts">
    import {defineComponent} from 'vue';

    export default defineComponent({
        props: {
            value: {
                type: Boolean,
                required: true,
            },
            label: {
                type: String,
                required: true,
            },
        },
        setup(props, {emit}) {
            function toggle() {
                emit('input', !props.value);
            }

            return {
                toggle,
            };
        },
    });
</script>
