<script setup lang="ts">
    import {nextTick, ref, toRef, watch} from 'vue';
    import type {DateTimeSingle} from '@/modules/meeko-ui/types/DateTime';
    import useMCalendarInputFieldState from '@/modules/meeko-ui/components/MCalendarInput/useMCalendarInputFieldState';
    import {useFocus} from '@vueuse/core/index';

    const props = defineProps<{
        value: DateTimeSingle;
        tabIndex: number;
        focus: boolean;
        disabled: boolean;
    }>();

    const emit = defineEmits<{
        (e: 'focus', value: boolean): void
        (e: 'input', value: DateTimeSingle): void
    }>();

    const {
        formattedDate,
        formattedTime,
        handleInput,
        handleKeydownTab,
        handleVirtualInputClick,
        datePlaceholder,
        timePlaceholder,
        placeholder,
        showDate,
        showTime,
        textValue,
    } = useMCalendarInputFieldState(
        toRef(props, 'value'),
        props.tabIndex,
        toRef(props, 'disabled'),
        emit,
    );

    const inputEl = ref<HTMLInputElement>();
    const {focused: inputElFocused} = useFocus(inputEl);


    watch(() => props.focus, newValue => {
        nextTick(() => {
            inputElFocused.value = newValue;
        });
    });
</script>

<template>
    <div class="MCalendarInputField__wrapper">
        <input
            v-show="focus"
            ref="inputEl"
            class="MCalendarInputField__input"
            :disabled="disabled"
            :placeholder="placeholder"
            :tabindex="tabIndex"
            :value="textValue"
            @input="handleInput($event.target.value)"
            @keydown.tab="handleKeydownTab"
        >
        <div
            v-show="!focus"
            class="MCalendarInputField__virtual-input"
            :class="{
                'MCalendarInputField__virtual-input--disabled': disabled
            }"
            @click="handleVirtualInputClick"
        >
            <template v-if="showDate && showTime">
                <span
                    class="MCalendarInputField__virtual-input-date"
                    :class="{
                        'MCalendarInputField__virtual-input-date--disabled': !value
                    }"
                >{{ formattedDate ?? datePlaceholder }}</span>
                <span
                    class="MCalendarInputField__virtual-input-hour"
                    :class="{ 'MCalendarInputField__virtual-input-hour--empty': !formattedTime }"
                >{{ formattedTime ?? timePlaceholder }}</span>
            </template>
            <template v-else>
                <span
                    class="MCalendarInputField__virtual-input-date"
                    :class="{
                        'MCalendarInputField__virtual-input-date--disabled': !value
                    }"
                >
                    <template v-if="showDate">
                        {{ formattedDate ?? datePlaceholder }}
                    </template>
                    <template v-else>
                        {{ formattedTime ?? timePlaceholder }}
                    </template>
                </span>
            </template>
        </div>
    </div>
</template>

<style scoped lang="scss">
    .MCalendarInputField__wrapper {
        @apply tw-flex tw-flex-1 tw-min-w-0 tw-items-baseline tw-gap-2;
    }

    .MCalendarInputField__input {
        @apply tw-outline-0 tw-flex-1 tw-min-w-0;
    }

    .MCalendarInputField__virtual-input {
        @apply tw-outline-0 tw-flex-1 tw-min-w-0 tw-flex tw-items-center tw-gap-2 tw-cursor-text;
    }

    .MCalendarInputField__virtual-input--disabled {
        @apply tw-cursor-not-allowed;
    }

    .MCalendarInputField__virtual-input-date {
        @apply tw-whitespace-nowrap;
    }

    .MCalendarInputField__virtual-input-date--disabled {
        @apply tw-text-gray-400;
    }

    .MCalendarInputField__virtual-input-hour {
        @apply tw-px-1 tw-py-0.5 tw-bg-primary-500 tw-text-sm tw-rounded tw-text-white;
    }

    .MCalendarInputField__virtual-input-hour--empty {
        @apply tw-bg-gray-400;
    }
</style>
