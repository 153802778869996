import {computed, ref} from 'vue';
import {app} from '@meekohq/lumos';
import type JobListContract from '@/modules/human-resources/utils/job/JobListContract';
import {Names} from '@/modules/human-resources/utils/Names';

export default function useJobList() {
    const jobList = ref(app<JobListContract>(Names.JobList));

    return {
        jobs: computed(() => jobList.value.jobs),
        loadJobs: () => jobList.value.loadJobs(),
        clearJobs: () => jobList.value.clear(),
    };
}
