import {ref, watch} from 'vue';
import {app} from '@meekohq/lumos';
import useManager from '@/modules/app/composables/useManager';
import type OrganizationOpeningContract from '@/modules/organization/utils/OrganizationOpeningContract';
import type {LegacyNurseryInterface} from '@/modules/organization/utils/LegacyNurseryOpening';
import type {Moment} from 'moment';
import {Names} from '@/modules/organization/utils/Names';

export default function(organization?: LegacyNurseryInterface) {
    const {legacyNursery} = useManager();
    const opening = ref(app<OrganizationOpeningContract>(Names.Opening));

    // Instanciate Opening and refresh it if organization change
    watch(legacyNursery, () => {
        // If orga is not loaded we don't try to init Opening
        if (legacyNursery.value) {
            opening.value = app<OrganizationOpeningContract>(Names.Opening);
            opening.value.forOrganization(organization ?? legacyNursery.value);
        }
    }, {immediate: true});

    return {
        isOpenedOn: (date: Moment) => opening.value.isOpenedOn(date),
        isClosedOn: (date: Moment) => opening.value.isClosedOn(date),
        getOpeningHoursOn: (date: Moment) => opening.value.getOpeningHoursOn(date),
    };
}
