<template>
    <router-link
        v-if="customerModels.length === 1"
        v-slot="{ href, navigate }"
        :to="{ name: 'cashier/customers.show.summary', params: { customer: head(customerModels).id }}"
    >
        <a
            :href="href"
            @click="navigate"
        >
            <slot/>
        </a>
    </router-link>
    <CDropdown
        v-else
        ref="customersChoice"
        placement="right"
        :popover="popover"
        prevent-default
    >
        <slot/>
        <template #content>
            <CList :striped="true">
                <router-link
                    v-for="customerModel in customerModels"
                    :key="customerModel.id"
                    v-slot="{ href, navigate }"
                    custom
                    :to="{ name: 'cashier/customers.show.summary', params: { customer: customerModel.id }}"
                >
                    <a
                        :href="href"
                        @click.stop="e => onClick(e, navigate)"
                    >
                        <CListRow
                            class="!tw-color-inherit"
                            :clickable="true"
                        >
                            {{ customerModel.attributes.name }}
                        </CListRow>
                    </a>
                </router-link>
            </CList>
        </template>
    </CDropdown>
</template>

<script lang="ts">
    import type CustomerModel from '@/modules/cashier/models/CustomerModel';
    import {head} from 'lodash-es';
    import type {PropType} from 'vue';
    import {defineComponent} from 'vue';
    import usePopover from '@/modules/app/composables/usePopover';

    export default defineComponent({
        components: {},
        props: {
            customerModels: {type: Array as PropType<CustomerModel[]>, required: true},
        },
        setup() {
            const popover = usePopover();

            function onClick(e, navigate) {
                popover.hide();
                navigate(e);
            }

            return {
                head,
                onClick,
                popover,
            };
        },
    });
</script>

