import moment from 'moment';
import type {QueryBuilder} from '@meekohq/lumos';

/**
 * @returns Contracts in range.
 */
export function scopeInRange(
    query: QueryBuilder<any>,
    from: string,
    to: string,
): QueryBuilder<any> {
    return query.where(query1 => {
        query1
            .where(query2 => {
                query2
                    .whereNull('broked_at')
                    .where(query3 => {
                        query3
                            .where(query4 => {
                                query4
                                    .whereBetween('started_at', [from, to])
                                    .orWhereBetween('ended_at', [from, to]);
                            })
                            .orWhere(query4 => {
                                query4
                                    .where(query5 => {
                                        query5
                                            .whereNull('ended_at')
                                            .orWhere('ended_at', '>=', to);
                                    })
                                    .where('started_at', '<=', from);
                            });
                    });
            })
            .orWhere(query2 => {
                query2
                    .whereNotNull('broked_at')
                    .where(query3 => {
                        query3
                            .whereBetween('started_at', [from, to])
                            .orWhereBetween('broked_at', [from, to]);
                    })
                    .orWhere(query3 => {
                        query3
                            .where('broked_at', '>=', to)
                            .where('started_at', '<=', from);
                    });
            });
    });
}

/**
 * @returns Contracts from date.
 */
export function scopeFrom(query: QueryBuilder<any>, from: string): QueryBuilder<any> {
    return query.where(query1 => {
        query1.where(query2 => {
            query2.whereNull('broked_at')
                .where('started_at', '<=', from)
                .where(query3 => {
                    query3.whereNull('ended_at')
                        .orWhere('ended_at', '>=', from);
                });
        }).orWhere(query2 => {
            query2.whereNotNull('broked_at')
                .where('started_at', '<=', from)
                .where('broked_at', '>=', from);
        });
    });
}

/**
 * @returns Contracts coming after date.
 */
export function scopeComing(query: QueryBuilder<any>, date: string = moment().format('YYYY-MM-DD')): QueryBuilder<any> {
    return query.where('started_at', '>', date);
}

/**
 * @returns Contracts from past until date.
 */
export function scopeUntil(query: QueryBuilder<any>, date: string): QueryBuilder<any> {
    return query.where(query => {
        query.where(query1 => {
            query1.whereNull('broked_at')
                .where('ended_at', '<=', date);
        }).orWhere(query1 => {
            query1.whereNotNull('broked_at')
                .where('broked_at', '<=', date);
        });
    });
}

/**
 * @returns Active contract.
 */
export function scopeActive(query: QueryBuilder<any>, args: [from: string, to: string | null, organizations: Array<string | number> | null]): QueryBuilder<any> {
    let newQuery = query;

    const from = args ? args[0] : moment().format('YYYY-MM-DD');
    const to = args ? args[1] || null : null;
    if (to) {
        newQuery = scopeInRange(query, from, to);
    } else {
        newQuery = scopeFrom(query, from);
    }

    const organizations = args ? args[2] || null : null;
    if (organizations) {
        newQuery = newQuery.whereHas('organizations', query2 => query2.whereIn('id', organizations));
    }

    return newQuery;
}
