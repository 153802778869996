import type {
    DeletePersonalAccessTokenUseCase,
} from '@/modules/auth/personal-access-token/application/DeletePersonalAccessTokenUseCase';
import type {
    DeletePersonalAccessTokenRequest,
} from '@/modules/auth/personal-access-token/application/DeletePersonalAccessTokenRequest';
import {SudoModeRequiredError} from '@/modules/auth/sudo-mode/application/SudoModeError';

export class DeletePersonalAccessTokenInteractor implements DeletePersonalAccessTokenUseCase {
    async delete(request: DeletePersonalAccessTokenRequest): Promise<void> {
        try {
            await request.model.delete({
                params: {
                    sudo_token: request.sudoToken,
                },
            });
        } catch (err) {
            if (SudoModeRequiredError.isSudoModeRequiredError(err)) {
                throw new SudoModeRequiredError();
            }
            throw err;
        }
    }
}
