<template>
    <div>
        <h6 class="h6 mb-1">
            {{ __('family_member:i_am_late_message_with_message', {message: getLateContent(message.late)}) }}
        </h6>
        <div class="content">
            {{ message.content }}
        </div>
    </div>
</template>

<script>
    import moment from 'moment';
    import __ from '@/modules/app/utils/i18n-facade';

    export default {
        props: [
            'message',
        ],
        computed: {
            moment() {
                return moment;
            },
        },
        mounted() {
            //
        },
        methods: {
            getLateContent(late) {
                if (late >= 60) {
                    return __('family_member:one_hour_or_more');
                }

                return __('family_member:from_minutes_with_minute', {minute: late});
            },
        },
    };
</script>

<style lang="scss" scoped>
    .item {
        //padding: 0 15px;
        border-top: 1px solid #bbd3ea;
    }

    h6 {

    }

    .content {
        color: #40638a;
    }
</style>
