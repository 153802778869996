import type {LegacyKidContract} from '@/modules/family/utils/contract/LegacyKidContractType';

export interface LegacyContractErrors {
    company: boolean;
}

export function useLegacyKidContractErrors() {
    function hasCompanyContractErrors(contract: LegacyKidContract) {
        return !!contract.company_invoices?.some(
            invoice =>
                invoice.date > invoice.due_date ||
                invoice.start_period_date > invoice.end_period_date,
        );
    }

    function getLegacyContractErrors(contract: LegacyKidContract): LegacyContractErrors {
        return {
            company: hasCompanyContractErrors(contract),
        };
    }

    function resetErrors() {
        return {
            company: false,
        };
    }

    return {
        getLegacyContractErrors,
        hasCompanyContractErrors,
        resetErrors,
    };
}
