<template>
    <div>
        <p class="tw-text-gray-600 tw-text-base tw-mb-0">
            <span class="tw-font-semibold">{{ notification.data.name }}</span>.
        </p>
        <p class="tw-text-sm tw-mb-0 tw-mt-1">
            {{ notification.data.description }}
        </p>
        <ResourceList
            v-if="notification.data.resources && notification.data.resources.length > 1"
            class="tw-mt-2"
            :resources="notification.data.resources"
            resources-are-links
            @delete="$emit('delete', $event)"
        />
    </div>
</template>

<script lang="ts">
    import {defineComponent} from 'vue';
    import ResourceList from '@/modules/app/components/resource/organisms/ResourceList.vue';

    export default defineComponent({
        components: {
            ResourceList,
        },
        props: {
            notification: {type: Object, required: true},
        },
    });
</script>
