<template>
    <div
        class="tw-w-full"
        @mouseleave="dropdownEl?.hide(200)"
        @mousemove="dropdownEl?.show(200)"
    >
        <router-link :to="{name:'familyMembers.show',params:{nursery:activeOrganization.getKey(), familyMember:memberModel.getKey()}}">
            <div
                class="tw-relative tw-flex tw-items-center"
                :class="baseClass"
            >
                <MemberAvatar
                    class="tw-min-w-10 tw-mr-2"
                    :member-model="memberModel"
                />
                <div
                    class="tw-truncate tw-font-display tw-text-base"
                    :class="{'tw-text-boy': memberModel.isBoy, 'tw-text-girl': memberModel.isGirl}"
                >
                    {{ memberModel.fullname }}
                </div>
                <slot/>
            </div>
        </router-link>
        <CDropdown
            ref="dropdownEl"
            legacy-mode
            no-padding
        >
            <MemberProfile :member-model="memberModel"/>
        </CDropdown>
    </div>
</template>

<script setup lang="ts">
    import MemberProfile from '@/modules/family/components/member/MemberProfile.vue';
    import type {PropType} from 'vue';
    import {computed, ref} from 'vue';
    import useManager from '@/modules/app/composables/useManager';
    import type MemberModel from '@/modules/family/models/MemberModel';
    import MemberAvatar from '@/modules/app/components/atoms/avatars/MemberAvatar.vue';

    defineProps({
        memberModel: {
            type: Object as PropType<MemberModel>,
            required: true,
        },
    });

    const dropdownEl = ref<{ show: (delay: number) => void, hide: (delay: number) => void }>();
    const {activeOrganization} = useManager();

    const baseClass = computed(() => {
        const output = [] as any[];

        output.push([
            'tw-p-3',
            'tw-rounded-lg',
            'tw-cursor-pointer',
            'tw-shadow',
            'hover:tw-scale-105',
            'tw-bg-white',
            'tw-border tw-border-gray-200',
        ]);
        output.push([
            'tw-transition',
            'tw-duration-150',
            'tw-ease-out',
        ]);

        return output;
    });
</script>
