<template>
    <div
        v-if="isDashboardReady && isAuthenticated"
        id="dashboard"
        class="Dashboard__wrapper"
    >
        <main class="Dashboard__main">
            <notification-toast
                :nursery="legacyNursery"
                :user="legacyUser"
            />
            <TopBar
                class="Dashboard__topbar"
                :customer="legacyCustomer"
                :nursery="legacyNursery"
                :user="legacyUser"
            />
            <template v-if="pathStartWithNurseries">
                <router-view
                    v-if="isOrganizationReady"
                    id="main-content"
                    :allergies="DataStore.allergies"
                    :billing-configs="legacyAccount.billingConfigs"
                    class="Dashboard__content"
                    :customer="legacyCustomer"
                    :diseases="DataStore.diseases"
                    :doc-templates="legacyAccount.docTemplates"
                    :foods="DataStore.foods"
                    :holidays="DataStore.holidays"
                    :nursery="legacyNursery"
                    :pdf-documents="DataStore.pdfDocuments"
                    :sepa-mandates="legacyAccount.sepaMandates"
                    :tags="legacyAccount.tags"
                    :user="legacyUser"
                    :vaccines="DataStore.vaccines"
                />
            </template>
            <router-view
                v-else
                id="main-content"
                class="Dashboard__content"
                :user="legacyUser"
            />
        </main>
        <Sidebar
            class="Dashboard__sidebar"
            :disabled="route.name === 'nurseries'"
            :nursery="legacyNursery"
            :ready="wasReadyAtLeastOnce"
            :user="legacyUser"
        />
        <MMask/>
        <portal-target
            multiple
            name="overlay"
        />
        <ModalManager/>
    </div>
    <CCenter
        v-else
        key="loader"
        class="tw-min-h-screen"
    >
        <DashboardLoader/>
    </CCenter>
</template>

<script setup lang="ts">
    import ziggyRoute from '@/modules/legacy/libs/ziggy';
    import {useRoute, useRouter} from 'vue-router/composables';
    import {computed, getCurrentInstance, onBeforeUnmount, onMounted, ref, watch} from 'vue';
    import useAuth from '@/modules/app/composables/useAuth';
    import useManager from '@/modules/app/composables/useManager';
    import useApi from '@/modules/app/composables/useApi';
    import OrganizationModel from '@/modules/organization/models/OrganizationModel';
    import {set, startsWith} from 'lodash-es';
    import IconModel from '@/modules/app/models/IconModel';
    import useJobList from '@/modules/human-resources/composables/job/useJobList';
    import {Epoch} from '@meekohq/lumos';
    import moment from 'moment';
    import NotificationToast from '@/modules/notification/components/Toast.vue';
    import Sidebar from '@/modules/app/components/layouts/Sidebar.vue';
    import DataStore from '@/modules/legacy/store/data.store';
    import DashboardLoader from '@/modules/app/components/molecules/DashboardLoader.vue';
    import TopBar from '@/modules/app/components/layouts/TopBar.vue';
    import {whenever} from '@vueuse/core';
    import ModalManager from '@/modules/app/components/organisms/ModalManager.vue';
    import useAccessRoute from '@/modules/app/composables/useAccessRoute';
    import {permissionsRessources} from '@/modules/app/static/legacyPermissions';
    import useIntercom from '@/modules/app/composables/useIntercom';
    import useAbility from '@/modules/app/composables/useAbility';

    // We need isAuthentified to prevent error on logout
    const {isAuthenticated, legacyUser} = useAuth();
    const {
        activeOrganization,
        legacyNursery,
        legacyCustomer,
        legacyAccount,
        subscribed,
        onTrial,
    } = useManager();
    const {arePermissionsLoaded} = useAbility();
    const route = useRoute();
    const router = useRouter();
    const {authorize: authorizeRoute} = useAccessRoute(router);
    const {update: updateIntercom} = useIntercom();

    const instance = getCurrentInstance();
    const isDashboardReady = ref(false);
    const isOrganizationReady = ref(false);
    const wasReadyAtLeastOnce = ref(false);

    const pathStartWithNurseries = computed(() => {
        return startsWith(route.path, '/nurseries/');
    });

    /**
     * Fetch account
     */
    async function fetchAccountById(id: string) {
        legacyAccount.value = (await useApi().legacy.get(
            ziggyRoute('accounts.show', {account: id}),
        )).data;
    }

    /**
     * Fetch organization
     * @param id
     */
    async function fetchOrganizationById(id: string) {
        // We fetch the organization form legacy api
        const response = await useApi().legacy.get(ziggyRoute('nurseries.show', {nursery_id: id}));
        legacyNursery.value = response.data;

        // Then we fetch the organization with the model
        activeOrganization.value = await OrganizationModel.query()
            .with(new OrganizationModel().tags())
            .with(new OrganizationModel().tenants())
            .find(response.data.id);

        // Set organization's groups in extra.legacy_groups
        set(activeOrganization.value.extra, 'legacy_groups', legacyNursery.value.groups);
    }

    /**
     * Fetch customer
     */
    async function fetchCustomerById(id: string) {
        const response = await useApi().legacy.get(ziggyRoute('customers.show', {customer: id}));

        legacyCustomer.value = response.data;
        subscribed.value = response.data.subscribed;
        onTrial.value = response.data.on_trial;
    }

    /**
     * Fetch data
     */
    async function fetchData() {
        const dataService = await useApi().legacy.get(ziggyRoute('data'));

        DataStore.foods = dataService.data.foods;
        DataStore.allergies = dataService.data.allergies;
        DataStore.pdfDocuments = dataService.data.pdfDocuments;
        DataStore.vaccines = dataService.data.vaccines;
        DataStore.diseases = dataService.data.diseases;
        DataStore.holidays = dataService.data.holidays;

        DataStore.icons = (await IconModel.query().get()).toArray();

        await useJobList().loadJobs();
    }

    /**
     * Load an organization
     * @param id
     */
    async function switchOrganizationById(id: string) {
        arePermissionsLoaded.value = false;
        isOrganizationReady.value = false;

        await fetchOrganizationById(id);
        await fetchCustomerById(legacyNursery.value.customer_id);
        syncPermissions(legacyNursery.value, legacyUser.value);

        // Set timezone
        moment.tz.setDefault(legacyNursery.value.timezone);
        Epoch.setTz(legacyNursery.value.timezone);

        isOrganizationReady.value = true;
        wasReadyAtLeastOnce.value = true;
        arePermissionsLoaded.value = true;

        await authorizeRoute(route);
    }

    /**
     * Setup legacy events
     */
    function setupLegacyEvents() {
        // Legacy events
        const $bus = (instance?.proxy as any).$bus;

        $bus.$on('refresh:nursery', switchOrganizationById);

        $bus.$on('update:customer', (customer: any) => {
            legacyCustomer.value = customer;
            subscribed.value = customer.subscribed;
            onTrial.value = customer.on_trial;
        });
    }

    onBeforeUnmount(() => {
        const $bus = (instance?.proxy as any).$bus;

        $bus.$off('refresh:nursery');
        $bus.$off('update:customer');
    });

    onMounted(async () => {
        // Dashboard only need account and data to work properly, organization will be loaded on route change
        isDashboardReady.value = false;
        await fetchAccountById(legacyUser.value.account_id);
        await fetchData();
        isDashboardReady.value = true;
        // Setup legacy events for compatibility
        setupLegacyEvents();
    });

    /**
     * Redirect to nurseries if user is on dashboard route
     */
    whenever(() => route.name === 'dashboard', () => router.push({
        name: 'nurseries',
        query: route.query,
    }), {immediate: true});

    /**
     * Reset wasReadyAtLeastOnce when user is on nurseries index route, fix to prevent empty sidebar
     */
    whenever(() => route.name === 'nurseries', () => {
        wasReadyAtLeastOnce.value = false;
    }, {immediate: true});

    /**
     * Sync Intercom with current nursery, customer and account
     */
    watch([legacyNursery, legacyCustomer, legacyAccount], () => {
        if (legacyNursery.value && legacyCustomer.value && legacyAccount.value) {
            updateIntercom(legacyNursery.value, legacyCustomer.value, legacyAccount.value);
        }
    }, {deep: true});

    /**
     * Switch organization when route changes
     */
    whenever(() => route.params.nursery, switchOrganizationById, {immediate: true});

    // START - Permissions
    /**
     * Legacy permissions updater
     * @param nursery
     * @param user
     */
    function syncPermissions(nursery: any, user: any) {
        if (user?.nurseries) {
            if (user.is_owner) {
                user.nurseries.forEach((item: any) => {
                    const allRessources: any = [];

                    permissionsRessources().forEach((res: any) => {
                        allRessources.push({
                            name: res.name,
                            actions: res.actions,
                        });
                    });

                    item.permissions = allRessources;
                });
            }
        }
    }
</script>

<style
    lang="scss"
    scoped
>
    .Dashboard__wrapper {
        @apply tw-flex tw-flex-col sm:tw-flex-row-reverse tw-min-h-screen;
    }

    .Dashboard__main {
        @apply tw-flex tw-flex-col tw-flex-grow tw-min-w-0 tw-pb-2;

        @screen sm {
            padding-bottom: 80px;
        }

        @media print {
            padding: 0 !important;
            margin: 0 !important;
        }
    }

    $sidebar-size-desktop: 220px;
    $sidebar-size-tablet: 100px;

    .Dashboard__sidebar {
        z-index: 1010;

        @screen sm {
            width: $sidebar-size-tablet;
        }

        @screen lg {
            width: $sidebar-size-desktop;
        }

        @media print {
            display: none !important;
        }

        @apply tw-transition-all;
    }

    .Dashboard__topbar {
        @apply tw-mx-2 sm:tw-mx-4;

        z-index: 1020;
        @screen lg {
            z-index: 1000;
        }
    }

    .Dashboard__content {
        @apply tw-mx-2 sm:tw-mx-4 tw-pt-0.5;
    }
</style>
