<template>
    <CVStack>
        <CHStack>
            <div class="tw-grid tw-grid-cols-12 tw-gap-4 tw-text-sm tw-font-semibold tw-uppercase tw-flex-1">
                <div class="tw-col-span-2 tw-self-center">
                    <span>{{ __('common:full_name') }}</span>
                </div>
                <div class=" tw-col-span-1 tw-place-self-center">
                    <span>{{ __('common:gender_one') }}</span>
                </div>
                <div class=" tw-col-span-2 tw-place-self-center">
                    <span>{{ __('common:birthdate') }}</span>
                </div>
                <div class=" tw-col-span-2 tw-place-self-center">
                    <span>{{ __('common:nationality') }}</span>
                </div>
                <div class=" tw-col-span-1 tw-place-self-center">
                    <span>{{ __('common:entry') }}</span>
                </div>
                <div class=" tw-col-span-1 tw-place-self-center">
                    <span>{{ __('common:exit') }}</span>
                </div>
                <div class=" tw-col-span-2 tw-place-self-center">
                    <span>{{ __('common:note_other') }}</span>
                </div>
                <div class=" tw-col-span-1 tw-place-self-center">
                    <span>{{ __('common:contract_other') }}</span>
                </div>
            </div>
        </CHStack>
        <div
            class="tw-rounded-full tw-bg-gray-300 tw-mb-3 tw-w-full"
            style="height: 1px;"
        />
        <loader
            v-if="loading"
            light="true"
            shadow="false"
            size="md"
            :title="__('common:loading_dots')"
        />
        <template v-else>
            <div
                v-if="showEmployeesList"
                class="tw-h-full"
            >
                <StaffRegisterListItem
                    v-for="model in employees"
                    :key="model.id"
                    :selected-organizations-ids="selectedOrganizationsIds"
                    :staff-model="model"
                />
            </div>
            <div
                v-else
                class="tw-h-full"
            >
                <StaffRegisterListItem
                    v-for="model in interns"
                    :key="model.id"
                    :selected-organizations-ids="selectedOrganizationsIds"
                    :staff-model="model"
                />
            </div>
        </template>
    </CVStack>
</template>


<script lang="ts">
    import {computed, defineComponent, ref, watch} from 'vue';
    import StaffRegisterListItem from '@/modules/human-resources/components/registry/List/StaffRegisterListItem.vue';
    import type {Collection} from '@meekohq/lumos';
    import {collect} from '@meekohq/lumos';
    import StaffModel from '@/modules/human-resources/models/StaffModel';
    import type OrganizationModel from '@/modules/organization/models/OrganizationModel';
    import useContracts from '@/modules/human-resources/composables/staff-register/useContracts';
    import useStaffList from '@/modules/human-resources/composables/staff-register/useStaffList';
    import useMoment from '@/modules/app/composables/useMoment';
    import {jobName} from '@/modules/human-resources/models/JobModel';
    import ContractModel from '@/modules/human-resources/models/ContractModel';
    import useManager from '@/modules/app/composables/useManager';

    export default defineComponent({
        components: {StaffRegisterListItem},
        props: {},

        setup(props, {emit}) {
            const loading = ref(false);
            const {organizationIds} = useManager();
            const {formatDate} = useMoment();
            const selectedOrganizationsIds = ref(organizationIds.value);
            const staffs = ref(collect<StaffModel>());

            const {
                showEmployeesList,
                addConstraint,
            } = useStaffList();

            const fetchData = async function() {
                loading.value = true;
                emit('loading', true);

                // Fetch staff with constraints and add eagerLoad for children.
                staffs.value = await StaffModel.query(query => addConstraint(query))
                    .with(new StaffModel().contracts(), query =>
                        query.with(new ContractModel().job()).with(new ContractModel().organizations()).with(new ContractModel().contractType()),
                    )
                    .with(new StaffModel().organizationsPivots())
                    .get();

                emit('loading', false);
                loading.value = false;
            };

            fetchData();

            // Receive selected organizations from the filter.
            const fetchSelectedOrganization = function(organizations: Collection<OrganizationModel>) {
                if (organizations.isNotEmpty()) {
                    selectedOrganizationsIds.value = organizations.map(organization => organization.id).all();
                } else {
                    selectedOrganizationsIds.value = organizationIds.value;
                }
            };

            // Ordered staff by starting date.
            const orderedStaffs = computed(() => {
                staffs.value.each(staff => {
                    // Get the entry date for each staff.
                    const {getFirstDate} = useContracts(staff);
                    //  Attribute the entry date to the key 'firstContract' in extra object of each staff.
                    staff.extra.firstContract = getFirstDate();
                    staff.attributes.birthdate = formatDate(staff.attributes.birthdate as string | undefined);
                });

                // Return a sorted collection of staffs by they're entry in the company.
                return staffs.value
                    .sortBy(item => item.extra.firstContract)
                    .each(staff => staff.extra.firstContract = formatDate(staff.extra.firstContract));
            });

            const employees = computed(() => filterOrderedStaffs(false));

            const interns = computed(() => filterOrderedStaffs(true));

            // Return a collection of employees or interns.
            const filterOrderedStaffs = function(everyInternsContracts: boolean) {
                return orderedStaffs.value.filter(staff => {
                    // Get every staff's contracts.
                    const contracts = staff.contracts().value();

                    // Check if every contracts are intern contracts by returning a boolean.
                    const areEveryContractsInternContract = contracts.every(contract => {
                        // Get the job of each contract.
                        const job = contract.job().value();

                        // If there is a job, return true if the internal_id of the job is 'intern'.
                        if (job) {
                            return job.attributes.internal_id === jobName.intern;
                        }

                        // If not return false.
                        return false;
                    });

                    // If everyInternsContracts is true, return staff with ONLY intern contract.
                    // If everyInternsContracts is false, return staff with NOT ONLY intern contracts.
                    return everyInternsContracts ? areEveryContractsInternContract : !areEveryContractsInternContract;
                });
            };

            // Emit selectedOrganizationsIds to the parent each time its value changed.
            watch(selectedOrganizationsIds, () => {
                emit('selectedOrganizationsIds', selectedOrganizationsIds.value);
            }, {deep: true, immediate: true});
            // Emit if interns is not empty each time it's value changed.
            watch(interns, value => {
                emit('hasInterns', value.isNotEmpty());
            });

            return {
                loading,
                selectedOrganizationsIds,
                fetchSelectedOrganization,
                showEmployeesList,
                employees,
                interns,
            };
        },
    });
</script>
