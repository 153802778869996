<template>
    <CListRow>
        <CInline align-y="baseline">
            <div class="tw-w-2/4">
                {{ jobModel.attributes.name }}
            </div>
            <div class="tw-w-1/4"/>
            <div class="tw-w-1/4 tw-flex">
                <JobsListItemMenu
                    class="tw--my-1 tw-ml-auto"
                    :job-model="jobModel"
                    :job-pivot-in-other-organization="isJobExistInOtherOrganization"
                >
                    <template #update>
                        <CButton
                            class="tw-ml-auto tw--my-1"
                            :icon-edit="true"
                            :job-model="jobModel"
                            variant="link"
                            :zoom="true"
                            @click="$refs.updateModal.show()"
                        />
                    </template>
                </JobsListItemMenu>
                <UpdateJobModal
                    ref="updateModal"
                    :is-contracts-exist="isContractsExist"
                    :job-model="jobModel"
                    @jobDeleted="$emit('removeJobInArray', $event)"
                    @pivotDeleted="$emit('removePivotInArray', $event)"
                />
            </div>
        </CInline>
    </CListRow>
</template>

<script>
    import UpdateJobModal from '@/modules/human-resources/components/job/Modals/UpdateJobModal.vue';
    import Manager from '@/modules/legacy/store/manager.store';
    import JobsListItemMenu from '@/modules/human-resources/components/job/List/JobsListItemMenu.vue';
    import {isEmpty} from 'lodash-es';

    export default {
        components: {JobsListItemMenu, UpdateJobModal},

        props: {
            jobModel: {
                required: true,
            },
            pivots: {
                required: true,
            },
        },

        data() {
            return {
                Manager,
            };
        },

        computed: {
            isContractExistInOrganization() {
                return !!(this.jobModel.relationships.contracts.all().find(item => item.attributes.organization_id === Manager.activeOrganization.id));
            },

            isContractsExist() {
                return !isEmpty(this.jobModel?.relationships?.contracts);
            },

            isContractExistInOtherOrganization() {
                return this.jobModel.relationships.contracts.all().filter(item => item.attributes.organization_id !== Manager.activeOrganization.id);
            },

            isJobExistInOtherOrganization() {
                return this.pivots.reject(item => item.attributes.organization_id === Manager.activeOrganization.id)
                    .filter(pivot => pivot.attributes.job_id === this.jobModel.id);
            },
        },
    };
</script>

