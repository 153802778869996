<template>
    <div>
        <SimplePageTemplate v-if="kid.id">
            <template #title>
                <MPageTitle variant="family">
                    {{ $fullName(kid) }}
                    -
                    <template v-if="kid.birthdate">
                        {{ useKidAge().getByBirthdate(epoch.fromISOString(kid.birthdate)) }}
                    </template>
                    <template v-else>
                        {{ __('common:not_filled') }}
                    </template>
                </MPageTitle>
            </template>
            <template #actions>
                <TagsSelector
                    v-if="kidModel"
                    :editable="can('update', 'kids_details')"
                    :taggable-model="kidModel"
                    taggable-type="kid"
                    :tags="kidModel.tags().value().toArray()"
                    @attached="kidModel.tags().value().push($event)"
                    @detached="kidModel.tags().mutate(v => v.filter(c => c.getKey() !== $event.getKey()))"
                />
                <DownloadTemplate
                    :additional-items="additionalItems"
                    doc-type="kid"
                    :item="kid"
                    :nursery="nursery"
                    right
                    sm
                    :user="user"
                    variant="light"
                />
                <MDropdown
                    v-if="familyModel"
                    :hoverable="true"
                >
                    <MButton
                        :to="{name:'families.show',params:{nursery: nursery.id, family: kid.family_id}}"
                        variant="purple"
                    >
                        <template #left-icons>
                            <FontAwesomeIcon icon="fad fa-address-book"/>
                        </template>
                        {{ __('common:family') }}
                    </MButton>
                    <template #content>
                        <FamilyProfile :family-model="familyModel"/>
                    </template>
                </MDropdown>

                <div v-if="allows('access-to-billings-module') && customerModels.length">
                    <CButton
                        v-if="customerModels.length === 1"
                        :to="{name:'cashier/customers.show.invoices',params:{nursery: nursery.id, customer: customerModels[0].id}}"
                        variant="orange"
                    >
                        <template #left-icons>
                            <FontAwesomeIcon icon="fa fa-wallet"/>
                        </template>
                        {{ __('common:customer_one') }}
                    </CButton>
                    <MDropdown v-else>
                        <MButton variant="orange">
                            <template #left-icons>
                                <FontAwesomeIcon icon="fa fa-wallet"/>
                            </template>
                            {{ __('common:customer_one') }}
                        </MButton>
                        <template #content>
                            <CList :striped="true">
                                <CListRow
                                    v-for="(customerModel, i) in customerModels"
                                    :key="'customer' + i"
                                    :clickable="true"
                                    @click="$router.push({ name: 'cashier/customers.show.invoices', params: { customer: customerModel.id }})"
                                >
                                    <FontAwesomeIcon
                                        v-if="customerModel.attributes.person === 'legal'"
                                        class="tw-mr-1"
                                        icon="fad fa-city fa-swap-opacity"
                                    />
                                    <FontAwesomeIcon
                                        v-else
                                        class="tw-mr-1"
                                        icon="fa fa-user"
                                    />
                                    {{ customerModel.attributes.name }}
                                </CListRow>
                            </CList>
                        </template>
                    </MDropdown>
                </div>
            </template>
            <template #content>
                <CTabMenu
                    class="print:tw-hidden"
                    variant="family"
                >
                    <CTabMenuItem
                        :active="selectedTab === 'summary'"
                        variant="family"
                        @click="changeSelectedTab('summary')"
                    >
                        <FontAwesomeIcon
                            class="tw-mr-2"
                            icon="fa fa-child-reaching"
                        />
                        {{ __('common:summary') }}
                    </CTabMenuItem>
                    <CTabMenuItem
                        v-if="can('read', 'kids_dailies')"
                        :active="selectedTab === 'timeline'"
                        variant="family"
                        @click="changeSelectedTab('timeline')"
                    >
                        <FontAwesomeIcon
                            class="tw-mr-2"
                            icon="fas fa-clipboard-list"
                        />
                        {{ __('family_kid:tabs.timeline') }}
                    </CTabMenuItem>
                    <CTabMenuItem
                        v-if="can('read', 'family_members_details')"
                        :active="selectedTab === 'parents'"
                        variant="family"
                        @click="changeSelectedTab('parents')"
                    >
                        <FontAwesomeIcon
                            class="tw-mr-2"
                            icon="fa fa-user-tie"
                        />
                        {{ __('common:parent_other') }}
                    </CTabMenuItem>
                    <CTabMenuItem
                        v-if="can('read', 'kids_contracts')"
                        :active="selectedTab === 'contract'"
                        variant="family"
                        @click="changeSelectedTab('contract')"
                    >
                        <FontAwesomeIcon
                            class="tw-mr-2"
                            icon="fa fa-handshake"
                        />
                        {{ __('common:contract_other') }}
                    </CTabMenuItem>
                    <CTabMenuItem
                        v-if="can('read', 'kids_health')"
                        :active="selectedTab === 'health'"
                        variant="family"
                        @click="changeSelectedTab('health')"
                    >
                        <FontAwesomeIcon
                            class="tw-mr-2"
                            icon="fa fa-heart"
                        />
                        {{ __('common:health') }}
                    </CTabMenuItem>
                    <CTabMenuItem
                        v-if="can('read', 'kids_food')"
                        :active="selectedTab === 'food'"
                        variant="family"
                        @click="changeSelectedTab('food')"
                    >
                        <FontAwesomeIcon
                            class="tw-mr-2"
                            icon="fas fa-utensils"
                        />
                        {{ __('family_kid:foods') }}
                    </CTabMenuItem>
                    <CTabMenuItem
                        v-if="can('read', 'kids_authorizations')"
                        :active="selectedTab === 'authorizations'"
                        variant="family"
                        @click="changeSelectedTab('authorizations')"
                    >
                        <FontAwesomeIcon
                            class="tw-mr-2"
                            icon="fa fa-check-circle"
                        />
                        {{ __('common:authorizations') }}
                    </CTabMenuItem>
                    <CTabMenuItem
                        v-if="can('read', 'kids_documents')"
                        :active="selectedTab === 'documents'"
                        variant="family"
                        @click="changeSelectedTab('documents')"
                    >
                        <FontAwesomeIcon
                            class="tw-mr-2"
                            icon="fas fa-file-alt"
                        />
                        {{ __('common:document_other') }}
                    </CTabMenuItem>
                </CTabMenu>
                <MBox
                    v-if="selectedTab !== 'documents'"
                    class="tw-p-4 tw-mt-4"
                >
                    <ModuleSummary
                        v-if="selectedTab === 'summary'"
                        :kid="kid"
                        :nursery="nursery"
                        @weightClicked="changeSelectedTab('health')"
                    />
                    <ModuleTransmissions
                        v-if="selectedTab === 'timeline'"
                        :kid="kid"
                        :nursery="nursery"
                    />
                    <ModuleFamilyMember
                        v-if="selectedTab === 'parents'"
                        :kid="kid"
                        :nursery="nursery"
                    />
                    <ModuleContract
                        v-if="selectedTab === 'contract'"
                        :kid="kid"
                        :nursery="nursery"
                        :user="user"
                    />
                    <ModuleHealth
                        v-if="selectedTab === 'health'"
                        :allergies="allergies"
                        :diseases="diseases"
                        :kid="kid"
                    />
                    <ModuleFood
                        v-if="selectedTab === 'food'"
                        :allergies="allergies"
                        :foods="foods"
                        :kid="kid"
                        :nursery="nursery"
                    />
                    <ModuleAuthorizations
                        v-if="selectedTab === 'authorizations'"
                        :kid="kid"
                        :nursery="nursery"
                    />
                </MBox>
                <SimpleSubPageTemplate v-if="selectedTab === 'documents' && kidModel">
                    <template #title>
                        <MHeading>
                            {{ __('common:documents_of', {name: kidModel.fullname}) }}
                        </MHeading>
                    </template>
                    <template #actions>
                        <MButton
                            v-if="can('create', 'kids_documents')"
                            :icon-plus="true"
                            :label="__('common:add_document')"
                            :to="{name: 'kids.show.documents.create', query: {nav:'documents'}}"
                            variant="primary"
                        />
                    </template>
                    <template #content>
                        <DocumentList
                            :bus="bus"
                            :edit-item-fn="editItemFn"
                            permission-key="kids_documents"
                            :related-resource="kidModel"
                            :show-visibilities="{
                                staffs_access: true,
                                parents_access: true,
                                web_access: false,
                            }"
                        />
                        <router-view
                            :kid-model="kidModel"
                            @created="bus.emit('documentCreated', $event)"
                            @deleted="bus.emit('documentDeleted', $event)"
                            @updated="bus.emit('documentUpdated', $event)"
                        />
                    </template>
                </SimpleSubPageTemplate>
            </template>
        </SimplePageTemplate>
        <div
            v-else
            key="loader"
        >
            <loader custom-class="la-2x"/>
        </div>
    </div>
</template>

<script>
    import _head from 'lodash-es/head';
    import _forEach from 'lodash-es/forEach';
    import route from '@/modules/legacy/libs/ziggy';
    import useApi from '@/modules/app/composables/useApi';
    import useKidAge from '@/modules/family/composables/kid/useKidAge';
    import moment from 'moment';
    import Summary from '@/modules/family/components/kid/Kid/Summary.vue';
    import Health from '@/modules/family/components/kid/Kid/Health.vue';
    import Food from '@/modules/family/components/kid/Kid/Food/Food.vue';
    import AuthorizationsTab from '@/modules/family/components/kid/Kid/Authorizations/AuthorizationsTab.vue';
    import FamilyMembers from '@/modules/family/components/member/FamilyMembers.vue';
    import Contracts from '@/modules/family/components/contract/Contracts.vue';
    import DownloadTemplate from '@/modules/legacy/components/Modules/DownloadTemplate.vue';
    import Transmission from '@/modules/legacy/components/Modules/Transmission/Transmission.vue';
    import FamilyProfile from '@/modules/family/components/family/FamilyProfile.vue';
    import CustomerModel from '@/modules/cashier/models/CustomerModel';
    import FamilyModel from '@/modules/family/models/FamilyModel';
    import KidModel from '@/modules/family/models/KidModel';
    import DocumentList from '@/modules/document/components/organisms/DocumentList.vue';
    import mitt from 'mitt';
    import useAbility from '@/modules/app/composables/useAbility';
    import {Epoch} from '@meekohq/lumos';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';
    import {EventBus} from '@/modules/legacy/utils/bus';
    import TagsSelector from '@/modules/tag/components/TagsSelector.vue';
    import SimpleSubPageTemplate from '@/modules/app/components/templates/SimpleSubPageTemplate.vue';
    import SimplePageTemplate from '@/modules/app/components/templates/SimplePageTemplate.vue';

    export default {
        components: {
            SimplePageTemplate,
            SimpleSubPageTemplate,
            TagsSelector,
            DocumentList,
            ModuleSummary: Summary,
            ModuleHealth: Health,
            ModuleFood: Food,
            ModuleAuthorizations: AuthorizationsTab,
            ModuleFamilyMember: FamilyMembers,
            ModuleContract: Contracts,
            ModuleTransmissions: Transmission,
            DownloadTemplate,
            FamilyProfile,
        },
        props: {
            nursery: {
                type: Object,
                required: true,
            },
            diseases: {
                type: Array,
                required: true,
            },
            allergies: {
                type: Array,
                required: true,
            },
            foods: {
                type: Array,
                required: true,
            },
            user: {
                type: Object,
                required: true,
            },
        },
        data: () => ({
            kid: {},
            customerModels: [],
            kidModel: null,
            familyModel: null,
            additionalItems: [],
            selectedTab: 'summary',
            bus: mitt(),
            can: useAbility().can,
        }),
        computed: {
            epoch() {
                return Epoch;
            },
            allows() {
                return useAbility().allows;
            },
            moment() {
                return moment;
            },
            companyOrderIds() {
                const arr = [];
                if (this.kid && this.kid.contracts) {
                    this.kid.contracts.forEach(contract => {
                        contract.company_order_id !== null
                            ? arr.push(contract.company_order_id)
                            : null;
                    });
                }

                return arr;
            },
        },
        watch: {
            '$route.params.kid': 'init',

            '$route.query.nav': function(value) {
                this.changeSelectedTab(value);
            },
            'companyOrderIds': function() {
                this.getCustomers();
            },
        },
        mounted() {
            this.init();
            this.selectedTab = this.$route.query.nav || 'summary';
        },
        beforeDestroy() {
            EventBus.$off('refresh-kid');
        },
        methods: {
            useKidAge,

            changeSelectedTab(tab) {
                if (this.selectedTab === tab) {
                    return;
                }

                this.selectedTab = tab;
                this.$router.push({name: 'kids.show', query: {nav: tab}});
            },

            init() {
                this.getKid();

                EventBus.$on('refresh-kid', () => {
                    this.getKid();
                });
            },
            formatDate(date) {
                return moment(date).format('DD/MM/YYYY');
            },
            redirectUser(kidOrganizationIds = null) {
                // If kid attached to an organization, redirect user on show page with the first organization attached.

                if (kidOrganizationIds && kidOrganizationIds.length) {
                    this.$router.push({
                        name: 'kids.show',
                        params: {nursery: kidOrganizationIds[0]},
                    });
                } else {
                    // If kid is not attached redirect on the kid index page.
                    this.$router.push({
                        name: 'kids.index',
                        params: {nursery: this.nursery.id},
                    });
                }
            },
            getKid() {
                this.kid.tags = null;

                useApi().legacy.get(
                    route('kids.show', {
                        kid: this.$route.params.kid,
                    }),
                )
                    .then(response => {
                        const isKidAttachedToOrganization = response.data.organization_ids.includes(this.nursery.id);

                        if (isKidAttachedToOrganization) {
                            this.kid = response.data;
                            this.getActiveContract();
                            this.getFamily(response.data.family_id);
                            this.getCustomers();
                        } else {
                            this.redirectUser(response.data.organization_ids);
                        }
                    })
                    .catch(error => {
                        if (
                            error &&
                            error.response &&
                            error.response.status === 422
                        ) {
                            _forEach(error.response.data.errors, function(value) {
                                useNotification().error(_head(value));
                            });
                        } else {
                            this.redirectUser();
                        }
                    });
            },
            async getFamily(familyId) {
                this.familyModel = await FamilyModel.query()
                    .with(new FamilyModel().kids(), query => {
                        query.with(new KidModel().tags());
                    })
                    .with(new FamilyModel().members())
                    .find(familyId);

                this.kidModel = this.familyModel.kids().value().first(kid => kid.getKey() === `${this.kid.id}`);
            },
            getCustomers() {
                CustomerModel.query().where(query => {
                    query.whereHas(new CustomerModel().families(), query1 => {
                        query1.whereHas(new FamilyModel().kids(), query2 => {
                            query2.where('id', this.kid.id);
                        });
                    }).orWhereHas(new CustomerModel().orders(), query1 => {
                        query1.whereIn('id', this.companyOrderIds);
                    });
                }).get().then(response => {
                    this.customerModels = response.all();
                });
            },
            getActiveContract() {
                const activeContract = this.kid.contracts.filter(item => !item.draft).find(contract => {
                    const end = contract.broked_at ? contract.broked_at : contract.ended_at;

                    return moment().isBetween(contract.started_at, end, '[]');
                });

                if (activeContract) {
                    this.additionalItems.push({
                        name: 'contract_id',
                        value: activeContract.id,
                    });
                    if (activeContract.config) {
                        this.additionalItems.push({
                            name: 'config_id',
                            value: activeContract.config.id,
                        });
                    }
                }
            },
            editItemFn(document) {
                this.$router.replace({
                    name: 'kids.show.documents.edit',
                    query: {nav: 'documents'},
                    params: {document: document.getKey()},
                });
            },
        },
    };
</script>
