<template>
    <div>
        <CFormSelect
            v-model="status"
            button-size="sm"
            :fallback-text="__('billing_customer:all_entities')"
            multi
            :options="options"
            :search-bar="false"
        />
    </div>
</template>

<script lang="ts">
    import type {PropType} from 'vue';
    import {defineComponent, ref, watch} from 'vue';
    import __ from '@/modules/app/utils/i18n-facade';

    export default defineComponent({
        components: {},
        props: {
            value: {type: Array as PropType<string[]>, default: () => []},
        },
        setup(props, {emit}) {
            const status = ref(props.value);
            const options = [
                {
                    text: __('billing_customer:entity_legal'),
                    value: 'legal',
                },
                {
                    text: __('billing_customer:entity_natural'),
                    value: 'natural',
                },
            ];

            watch(() => props.value, v => {
                status.value = v;
            });

            watch(status, val => {
                emit('value:updated', val);
            });

            return {
                status,
                options,
            };
        },
    });
</script>
