<template>
    <MModal
        :modal="modal"
        size="5xl"
    >
        <template #header-text>
            {{ __('user:choose_access') }} - {{ $fullName(user) }}
        </template>

        <template v-if="user && user.id && user.nurseries">
            <div
                class="tw-flex md:tw-flex-row tw-flex-col md:tw-items-center tw-gap-2 tw-border tw-border-gray-200 tw-rounded-xl tw-p-2"
                :class="{'tw-border-none': !user.nurseries.length}"
            >
                <div
                    v-if="user.nurseries.length"
                    class="tw-order-2 md:tw-order-1 tw-w-full tw-flex tw-flex-row tw-overflow-x-auto"
                >
                    <div
                        v-for="(nursery, i) in user.nurseries"
                        :key="'nursery' + i"
                        class="nursery border border-primary rounded d-inline-flex flex-wrap align-items-center tw-text-blue-800 my-2 mr-2 p-2 pointer position-relative"
                        :class="{'border-0 bg-primary text-white': nursery.id === selectedNursery.id}"
                        @click="switchNursery(nursery)"
                    >
                        <div class="tw-font-semibold w-100 tw-text-sm tw-truncate">
                            {{ nursery.name }}
                        </div>
                        <div
                            v-if="nursery.city"
                            class="tw-text-sm tw-truncate"
                        >
                            {{ nursery.city }}
                        </div>
                        <div
                            v-if="selectedNursery.id === nursery.id"
                            class="remove-nursery rounded-circle bg-danger text-center d-flex align-items-center zoom"
                            @click="detachUser(nursery)"
                        >
                            <MTooltip
                                class="tw-flex tw-items-center tw-justify-center tw-w-full"
                                :label="__('user:detach_from_this_organization')"
                                placement="top"
                            >
                                <FontAwesomeIcon
                                    class="tw-text-white tw-text-sm"
                                    fixed-width
                                    icon="fa fa-trash-alt"
                                />
                            </MTooltip>
                        </div>
                    </div>
                </div>
                <div
                    v-if="nurseries.length !== user.nurseries.length"
                    class="tw-order-1 md:tw-order-2 tw-ml-auto"
                >
                    <MButton
                        icon-plus
                        :label="__('organization:add_organization')"
                        @click="attachUser"
                    />
                </div>
            </div>

            <hr class="hr mx-1 mb-4 tw-border-2">

            <div
                v-if="selectedNursery && selectedNursery.permissions"
                class="row mx-1"
            >
                <div class="permissions-menu rounded col-12 col-md-4 col-lg-3">
                    <div class="mt-2">
                        <MButton
                            class="tw-mr-4"
                            variant="link"
                            @click="toggleFullAccess(true)"
                        >
                            <template #left-icons>
                                <FontAwesomeIcon icon="fa fa-check-double"/>
                            </template>
                            {{ __('common:actions.check_all') }}
                        </MButton>
                        <MButton
                            variant="link"
                            @click="toggleFullAccess(false)"
                        >
                            <template #left-icons>
                                <FontAwesomeIcon icon="fa fa-times-circle"/>
                            </template>
                            {{ __('common:actions.uncheck_all') }}
                        </MButton>
                    </div>
                    <hr class="hr mt-2">
                    <div
                        v-for="(menu, i) in menus"
                        :key="'menu' + i"
                        class="menu mt-3 mb-4"
                        :class="[{'selectable': isUniqueMenu(menu)}, {'active': selectedRessource.name === menu.name}]"
                        @click="selectRessource(menu.name, isUniqueMenu(menu))"
                    >
                        <div class="mb-2 d-flex align-items-center">
                            <i
                                class="icon bg-transparent mr-1"
                                :class="[menu.icon, menu.class]"
                                style="width: 23px;"
                            />{{ menu.displayName }}
                            <i
                                v-if="selectedRessource.name === menu.name && isUniqueMenu(menu)"
                                class="fa fa-angle-right tw-text-2xl mr-2 ml-auto"
                            />
                        </div>
                        <template v-if="!isUniqueMenu(menu)">
                            <div
                                v-for="(ressource, i) in menu.ressources"
                                :key="'ressource' + i"
                                class="ressource rounded py-2 pl-4 my-1"
                                :class="{'active': selectedRessource.name === ressource}"
                                @click="selectRessource(ressource, true)"
                            >
                                <div
                                    v-if="ressourceDisplayName(ressource)"
                                    class="tw-text-base d-flex align-items-center"
                                >
                                    {{ ressourceDisplayName(ressource) }}
                                    <i
                                        v-if="selectedRessource.name === ressource"
                                        class="fa fa-angle-right tw-text-2xl mr-2 ml-auto"
                                    />
                                </div>
                            </div>
                        </template>
                    </div>
                </div>

                <div class="permissions-actions rounded col-12 col-md-8 col-lg-9">
                    <div class="tw-flex lg:tw-flex-row tw-flex-col tw-gap-2 lg:tw-justify-between lg:tw-items-center">
                        <h5 class="h5 tw-text-blue-800 my-1">
                            {{ selectedRessource.displayName }}
                        </h5>
                        <div class="tw-flex tw-flex-wrap tw-gap-2">
                            <MButton @click="toggleAll(selectedRessource, true)">
                                <template #left-icons>
                                    <FontAwesomeIcon
                                        class="tw-text-success-500"
                                        icon="fas fa-check-circle"
                                    />
                                </template>
                                {{ __('user:check_all_actions') }}
                            </MButton>
                            <MButton @click="toggleAll(selectedRessource, false)">
                                <template #left-icons>
                                    <FontAwesomeIcon
                                        class="tw-text-black"
                                        icon="fas fa-eye-slash"
                                    />
                                </template>
                                {{ __('user:uncheck_all_actions') }}
                            </MButton>
                        </div>
                    </div>
                    <hr class="hr">

                    <div
                        v-for="(action, i) in actions"
                        :key="'action' + i"
                    >
                        <template v-if="ressourceHasAction(selectedRessource, action.name)">
                            <div
                                class="action rounded pointer d-flex flex-row align-items-center p-3"
                                :class="{'active': userHasAction(selectedRessource, action.name)}"
                                @click="toggleAction(selectedRessource, action.name)"
                            >
                                <div class="permission-icon bg-primary rounded-circle text-center d-flex align-items-center">
                                    <i
                                        class="w-100 tw-text-2xl tw-text-white"
                                        :class="action.icon"
                                    />
                                </div>
                                <div class="p-1 ml-2">
                                    <div class="tw-font-semibold">
                                        {{ action.title }}
                                    </div>
                                    <div class="small mt-1">
                                        {{ action.description }}
                                    </div>
                                </div>
                                <div class="ml-auto">
                                    <i
                                        class="tw-text-2xl"
                                        :class="[userHasAction(selectedRessource, action.name) ? 'fas fa-check-circle text-success' : 'fa fa-eye-slash']"
                                    />
                                </div>
                            </div>
                            <hr class="hr">
                        </template>
                    </div>
                </div>
            </div>
            <div v-else>
                <h5 class="h5 w-100 text-center p-3">
                    {{ __('user:empty_organization_warning') }}
                </h5>
            </div>
        </template>

        <template #footer-end="{hide}">
            <MButton
                :label="__('common:actions.close')"
                @click="hide"
            />
        </template>
    </MModal>
</template>

<script>
    import Vue from 'vue';
    import route from '@/modules/legacy/libs/ziggy';
    import useApi from '@/modules/app/composables/useApi';
    import swal from 'sweetalert2/dist/sweetalert2.js';
    import __ from '@/modules/app/utils/i18n-facade';
    import _includes from 'lodash-es/includes';
    import _debounce from 'lodash-es/debounce';
    import _head from 'lodash-es/head';
    import _forEach from 'lodash-es/forEach';
    import _differenceBy from 'lodash-es/differenceBy';
    import _cloneDeep from 'lodash-es/cloneDeep';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';
    import {
        menusWithRessources,
        permissionsActions,
        permissionsRessources,
    } from '@/modules/app/static/legacyPermissions';

    export default {
        props: {
            user: {
                type: Object,
                required: true,
            },
            nursery: {
                type: Object,
                required: true,
            },
            nurseries: {
                type: Array,
                required: true,
            },
            modal: {
                type: Object,
                required: true,
            },
        },
        data: () => ({
            selectedRessource: {},
            selectedNursery: {},
            menus: [],
            actions: [],
            canUpdate: false,
        }),
        watch: {
            'user.id': function() {
                this.initUserPermissions();
            },
            'selectedNursery.permissions': {
                handler: _debounce(function() {
                    this.saveUserPermissions();
                }, 400),
                deep: true,
            },
        },
        mounted() {
            this.menus = menusWithRessources();
            this.actions = permissionsActions();
            this.selectedRessource = permissionsRessources().find(item => item.name === 'kids_planning');

            this.selectRessource();
            if (this.user.id) {
                this.initUserPermissions();
            }
        },
        methods: {
            initUserPermissions() {
                this.canUpdate = false;

                if (this.user.id && this.user?.nurseries?.length) {
                    const currentNursery = this.user.nurseries.find(item => item.id === this.nursery.id);
                    this.selectedNursery = currentNursery || this.user.nurseries[0];
                } else {
                    this.selectedNursery = {};
                }

                setTimeout(() => {
                    this.canUpdate = true;
                }, 1000);
            },

            saveUserPermissions() {
                if (!this.canUpdate || !this.selectedNursery.permissions) {
                    return;
                }

                useApi().legacy.put(route('nurseries.users.update', {
                    nurseries: this.selectedNursery.id,
                    user: this.user.id,
                }), {
                    permissions: this.selectedNursery.permissions,
                }).then(
                    response => {
                        this.$emit('edit', response.data);
                        useNotification().success(__('user:access_updated_successfully'));
                    }).catch(error => {
                    if (error?.response?.status === 422) {
                        _forEach(error.response.data.errors, value => {
                            useNotification().error(_head(value));
                        });
                    } else {
                        useNotification().error(error);
                    }
                });
            },

            attachUser() {
                const options = [];

                const diffNurseries = _differenceBy(this.nurseries, this.user.nurseries, 'id');
                diffNurseries.forEach(nursery => {
                    options[nursery.id] = nursery.city ? nursery.name + ' - ' + nursery.city : nursery.name;
                });

                swal({
                    title: __('user:add_user_to_organization_colon'),
                    type: null,
                    confirmButtonClass: 'btn btn-primary mr-2',
                    confirmButtonText: __('common:actions.confirm'),
                    cancelButtonText: __('common:actions.cancel'),
                    input: 'select',
                    inputOptions: options,
                }).then(result => {
                    if (result.value) {
                        const data = {};
                        data.permissions = this.user.permissions ? this.user.permissions : [];
                        useApi().legacy.put(route('nurseries.users.attach', {
                            nurseries: result.value,
                            user: this.user.id,
                        }), data)
                            .then(response => {
                                this.user.nurseries = response.data.nurseries;
                                this.$emit('update', response.data);
                                useNotification().success(__('user:organization_attached_successfully'));
                            }).catch(error => {
                                if (error?.response?.status === 422) {
                                    _forEach(error.response.data.errors, value => {
                                        useNotification().error(_head(value));
                                    });
                                } else {
                                    useNotification().error(error);
                                }
                            });
                    }
                });
            },

            detachUser(nursery) {
                swal({
                    title: __('user:detach_user_from_this_organization_ask'),
                    text: __('user:user_retains_access_to_other_organization'),
                    type: 'info',
                    confirmButtonClass: 'btn btn-danger mr-2',
                    confirmButtonText: __('common:actions.detach'),
                    cancelButtonText: __('common:actions.cancel'),
                }).then(result => {
                    if (result.value) {
                        useApi().legacy.put(route('nurseries.users.detach', {
                            nurseries: nursery.id,
                            user: this.user.id,
                        }), this.user).then(() => {
                            this.user.nurseries = this.user.nurseries.filter(item => item.id !== nursery.id);
                            this.initUserPermissions();
                            // TODO : Update user in list
                            useNotification().success(__('user:user_detached_to_organization_successfully'));
                        }).catch(error => {
                            if (error?.response?.status === 422) {
                                _forEach(error.response.data.errors, value => {
                                    useNotification().error(_head(value));
                                });
                            } else {
                                useNotification().error(error);
                            }
                        });
                    }
                });
            },

            switchNursery(nursery) {
                this.selectedNursery = _cloneDeep(nursery);

                this.canUpdate = false;
                setTimeout(() => {
                    this.canUpdate = true;
                }, 1000);
            },

            toggleFullAccess(active) {
                let userPermissions = this.selectedNursery.permissions;

                if (active) {
                    userPermissions = [];
                    permissionsRessources().forEach(res => {
                        userPermissions.push({
                            name: res.name,
                            actions: res.actions,
                        });
                    });
                } else {
                    userPermissions = [{name: 'kids_planning', actions: []}];
                }

                Vue.set(this.selectedNursery, 'permissions', userPermissions);
            },

            toggleAll(ressource, active) {
                const userPermissions = this.selectedNursery.permissions;

                if (active) {
                    const defaultRessource = permissionsRessources().find(item => item.name === ressource.name);
                    const userRessource = userPermissions.find(item => item.name === ressource.name);
                    if (defaultRessource) {
                        if (userRessource) {
                            userRessource.actions = defaultRessource.actions;
                        } else {
                            userPermissions.push({
                                name: defaultRessource.name,
                                actions: defaultRessource.actions,
                            });
                        }
                    }
                } else {
                    const userRessource = userPermissions.find(item => item.name === ressource.name);
                    if (userRessource) {
                        userRessource.actions = [];
                    }
                }

                Vue.set(this.selectedNursery, 'permissions', userPermissions);
            },

            toggleAction(ressource, action) {
                const userPermissions = _cloneDeep(this.selectedNursery.permissions);

                // User already has action in the given ressource
                if (this.userHasAction(ressource, action)) {
                    const userRessource = userPermissions.find(item => item.name === ressource.name);
                    if (userRessource) {
                        const userAction = userRessource.actions.find(item => item === action);
                        if (userAction) {
                            const index = userRessource.actions.indexOf(userAction);
                            userRessource.actions.splice(index, 1);
                        }
                    }
                }

                // User has ressource but not the given action
                else {
                    const userRessource = userPermissions.find(item => item.name === ressource.name);
                    if (userRessource) {
                        userRessource.actions.push(action);
                    } else {
                        // User doesn't even have this ressource
                        const newRessource = {
                            name: ressource.name,
                            actions: [action],
                        };
                        userPermissions.push(newRessource);
                    }
                }

                Vue.set(this.selectedNursery, 'permissions', userPermissions);

                const nurseryUser = this.user.nurseries.find(item => item.id === this.selectedNursery.id);
                if (nurseryUser) {
                    Vue.set(nurseryUser, 'permissions', userPermissions);
                }
            },

            selectRessource(ressourceName, selectable) {
                if (selectable) {
                    const ressource = permissionsRessources().find(item => item.name === ressourceName);
                    if (ressource) {
                        this.selectedRessource = ressource;
                    }
                }
            },

            userHasAction(ressource, action) {
                const permissions = this.selectedNursery.permissions;
                const userRessource = permissions.find(item => item.name === ressource.name);

                return userRessource ? this.ressourceHasAction(userRessource, action) : false;
            },

            ressourceHasAction(ressource, action) {
                return _includes(ressource.actions, action);
            },

            ressourceDisplayName(ressource) {
                const res = permissionsRessources().find(item => item.name === ressource);

                return res ? res.displayName : null;
            },

            isUniqueMenu(menu) {
                let isUnique = false;
                menu.ressources.forEach(ressource => {
                    isUnique = (menu.ressources.length === 1 && ressource === menu.name);
                });

                return isUnique;
            },
        },
    };
</script>

<style
    lang="scss"
    scoped
>
    @import "bootstrap/scss/functions";
    @import "@/assets/_bootstrap/variables";

    .nursery {
        min-width: 140px;
        max-width: 200px;
        min-height: 60px;
        max-height: 70px;

        .remove-nursery {
            position: absolute;
            right: -5px;
            top: -5px;
            width: 22px;
            height: 22px;
        }
    }

    .permissions-menu {
        background: #f1f8ff;
        max-height: 700px;
        overflow: scroll;

        .menu {
            color: $gray-tint-2;
            font-weight: 600;

            &.selectable {
                color: $blue-tint-1;

                &:hover {
                    cursor: pointer;
                    color: $blue-tint-2;
                    font-weight: 700;
                }

                &.active {
                    color: $blue-tint-3;
                    font-weight: 700;
                }
            }
        }

        .ressource {
            color: $gray-tint-1;
            font-weight: 600;

            &:hover {
                cursor: pointer;
                background: white;
            }

            &.active {
                color: $blue-tint-3;
                font-weight: 700;
                background: white;

            }
        }
    }

    .permissions-actions {
        .action {
            opacity: .6;

            .permission-icon {
                width: 50px;
                height: 50px;
                opacity: .5;
            }

            &.active {
                opacity: 1;

                .permission-icon {
                    opacity: 1;
                }
            }

            &:hover {
                background: rgb(243, 249, 255);
            }
        }
    }
</style>
