import {Model} from '@meekohq/lumos';
import OrganizationModel from '@/modules/organization/models/OrganizationModel';
import ContractTypeOrganizationPivot from '@/modules/human-resources/models/ContractTypeOrganizationPivot';

export default class ContractTypeModel extends Model {
    public type = 'hr/contract_types';

    public attributes: {
        id: string;
        account_id: string | undefined;
        name: string | undefined;
        internal_id: string | undefined;
        created_at: string | undefined;
        updated_at: string | undefined;
    } = {
            id: this.uuid(),
            account_id: undefined,
            name: undefined,
            internal_id: undefined,
            created_at: undefined,
            updated_at: undefined,
        };

    organizations() {
        return this.belongsToMany('organizations', OrganizationModel, ContractTypeOrganizationPivot, 'contract_type_id', 'organization_id');
    }
}
