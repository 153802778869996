import {ServiceProvider} from '@meekohq/lumos';
import RuleModel from '@/modules/calendar/models/RuleModel';
import RulesetModel from '@/modules/calendar/models/RulesetModel';

export default class CalendarServiceProvider extends ServiceProvider {
    public boot() {
        this.loadModelsFrom([
            RuleModel,
            RulesetModel,
        ]);
    }
}
