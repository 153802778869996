<template>
    <div class="tw-flex tw-rounded-lg tw-border tw-border-gray-200 tw-text-center tw-font-semibold">
        <div
            v-if="real !== null"
            class="tw-flex-1"
            :class="{'tw-border-r tw-border-gray-200': contract || forecast}"
        >
            <CCenter class="tw-h-full tw-px-2 tw-py-1">
                <div class="tw-text-sm tw-text-gray-500 tw-uppercase tw-tracking-wide tw-mr-2">
                    {{ __('common:real_one') }}
                </div>
                <CBadge
                    size="sm"
                    variant="cyan"
                >
                    {{ real }}
                </CBadge>
            </CCenter>
        </div>
        <div
            v-if="forecast !== null"
            class="tw-flex-1"
            :class="{'tw-border-r tw-border-gray-200': contract}"
        >
            <CCenter class="tw-h-full tw-px-2 tw-py-1">
                <div class="tw-text-sm tw-text-gray-500 tw-uppercase tw-tracking-wide tw-mr-2">
                    {{ __('common:forecasted_male_one') }}
                </div>
                <CBadge
                    size="sm"
                    variant="cyan"
                >
                    {{ forecast }}
                </CBadge>
            </CCenter>
        </div>
        <div
            v-if="contract"
            class="tw-flex-1"
        >
            <CCenter class="tw-h-full tw-px-2 tw-py-1">
                <div class="tw-text-sm tw-text-gray-500 tw-uppercase tw-tracking-wide tw-mr-2">
                    {{ __('common:contract_one') }}
                </div>
                <CBadge
                    size="sm"
                    variant="cyan"
                >
                    {{ contract }}
                </CBadge>
            </CCenter>
        </div>
    </div>
</template>

<script lang="ts">
    import {defineComponent} from 'vue';

    export default defineComponent({
        props: {
            real: {
                type: String,
            },
            forecast: {
                type: String,
            },
            contract: {
                type: String,
            },
        },
        setup() {
            return {};
        },
    });
</script>
