import type {
    ApplyTemplateUseCase,
} from '@/modules/human-resources/apply-template/application/use-cases/ApplyTemplateUseCase';
import type {
    ApplyTemplateRepositoryPort,
} from '@/modules/human-resources/apply-template/application/ports/ApplyTemplateRepositoryPort';
import type TemplateModel from '@/modules/human-resources/models/TemplateModel';
import type {WeeksDto} from '@/modules/human-resources/apply-template/application/dto/WeeksDto';
import type TemplatePlanningModel from '@/modules/human-resources/models/TemplatePlanningModel';
import type {Collection} from '@meekohq/lumos';
import type InvalidEvent from '@/modules/human-resources/apply-template/domain/InvalidEvent';
import type {TemplateRepositoryPort} from '@/modules/human-resources/template/application/TemplateRepositoryPort';
import {TemplateFactory} from '@/modules/human-resources/template/infrastructure/TemplateFactory';
import {PlanningFactory} from '@/modules/human-resources/template/infrastructure/PlanningFactory';

export class ApplyTemplateInteractor implements ApplyTemplateUseCase {
    private readonly applyTemplateRepositoryAdapter: ApplyTemplateRepositoryPort;
    private readonly templateRepositoryAdapter: TemplateRepositoryPort;

    constructor(applyTemplateAdapter: ApplyTemplateRepositoryPort, templateRepositoryAdapter: TemplateRepositoryPort) {
        this.applyTemplateRepositoryAdapter = applyTemplateAdapter;
        this.templateRepositoryAdapter = templateRepositoryAdapter;
    }

    public async applyTemplate(
        template: TemplateModel,
        weeks: WeeksDto,
        deleteRealEvents: boolean,
        fallbackOrganizationId: string,
    ): Promise<Collection<InvalidEvent>> {
        let templateClone = TemplateFactory.duplicateTemplate(template);

        templateClone = await this.templateRepositoryAdapter.saveTemplate(templateClone);

        const response = await this.applyTemplateRepositoryAdapter.applyTemplate(templateClone, weeks, deleteRealEvents, fallbackOrganizationId);

        await this.templateRepositoryAdapter.deleteTemplate(templateClone);

        return response;
    }

    public async applyTemplatePlanning(
        planning: TemplatePlanningModel,
        weeks: WeeksDto,
        deleteRealEvents: boolean,
        fallbackOrganizationId: string,
    ): Promise<Collection<InvalidEvent>> {
        let planningClone = PlanningFactory.duplicatePlanning(planning);
        planningClone = await this.templateRepositoryAdapter.savePlanning(planningClone);

        const response = await this.applyTemplateRepositoryAdapter.applyTemplatePlanning(planningClone, weeks, deleteRealEvents, fallbackOrganizationId);

        await this.templateRepositoryAdapter.deletePlanning(planningClone);

        return response;
    }
}
