<template>
    <div class="tw-border tw-border-dashed tw-border-gray-300 tw-p-2 tw-rounded-lg">
        <slot/>
    </div>
</template>

<script>
    export default {
        name: 'GroupInPlaceInputs',
    };
</script>

