<template>
    <CDropdownMenu>
        <template #button>
            <CButton icon-ellipsis/>
        </template>
        <CDropdownMenuItem
            class="tw-whitespace-nowrap"
            icon="fa fa-pen"
            @click="$refs.updateFamily.show()"
        >
            {{ __('family_family:update_family') }}
        </CDropdownMenuItem>
        <CDropdownMenuItem
            class="tw-whitespace-nowrap"
            :clckable="true"
            icon="fa fa-compress-alt"
            @click="mergeModal.show"
        >
            {{ __('family_family:merge_family_dots') }}
        </CDropdownMenuItem>
        <CTooltip
            v-if="familyHasKids"
            class="tw-w-full"
        >
            <CDropdownMenuItem
                class="tw-whitespace-nowrap tw-w-full"
                :disabled="true"
                icon="fa fa-trash"
                variant="danger"
            >
                {{ __('family_family:delete_family_dots') }}
            </CDropdownMenuItem>
            <template #content>
                {{ __('family_family:cannot_delete_family') }}
            </template>
        </CTooltip>
        <CDropdownMenuItem
            v-else
            class="tw-whitespace-nowrap"
            icon="fa fa-trash"
            variant="danger"
            @click="onFamilyDeleted"
        >
            {{ __('family_family:delete_family_dots') }}
        </CDropdownMenuItem>
        <template #extra>
            <UpdateFamilyModal
                ref="updateFamily"
                :family-model="familyModel"
            />
        </template>
    </CDropdownMenu>
</template>

<script lang="ts">
    import type FamilyModel from '@/modules/family/models/FamilyModel';
    import MergeFamilyModal from '@/modules/family/components/family/MergeFamilyModal.vue';
    import UpdateFamilyModal from '@/modules/family/components/family/UpdateFamilyModal.vue';
    import {computed, defineComponent, type PropType} from 'vue';
    import __ from '@/modules/app/utils/i18n-facade';
    import useMagicModal from '@/modules/app/composables/useMagicModal';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';
    import useModal from '@/modules/app/composables/useModal';

    export default defineComponent({
        components: {UpdateFamilyModal},
        props: {
            familyModel: {type: Object as PropType<FamilyModel>, required: true},
        },
        setup(props, {emit}) {
            const mergeModal = useModal({
                component: MergeFamilyModal,
                props: {
                    familyModel: computed(() => props.familyModel.clone()),
                },
                listeners: () => ({
                    merged: (family: FamilyModel) => emit('merged', family),
                }),
            });

            const onFamilyDeleted = async function() {
                const result = await useMagicModal().deleteConfirmationModal({
                    text: __('family_family:delete_family_warning'),
                });

                if (result.isConfirmed) {
                    await props.familyModel.delete();
                    useNotification().success(__('family_family:family_deleted_successfully'));
                    emit('deleted', props.familyModel);
                }
            };

            const familyHasKids = computed(() => props.familyModel.kids().value().isNotEmpty());

            return {
                mergeModal,
                onFamilyDeleted,
                familyHasKids,
            };
        },
    });
</script>
