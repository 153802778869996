<template>
    <div
        class="card-chart"
        :class="{'settings': showSettings}"
    >
        <div class="chart-title">
            <div class="ml-1 mb-3">
                <div class="d-flex flex-row ml-1 mb-3">
                    <div class="d-flex align-items-center tw-text-base tw-font-semibold text-uppercase">
                        <i
                            aria-hidden="true"
                            class="fas fa-calendar-check mr-2"
                        />{{ __('common:supervision') }}
                    </div>
                    <div
                        class="d-flex align-items-center flex-row ml-auto tw-text-sm tw-font-semibold text-uppercase pointer"
                        @click="showSettings = !showSettings"
                    >
                        {{ humanizedSelectedDate }}
                        <i
                            aria-hidden="true"
                            class="fas fa-pencil ml-2"
                        />
                    </div>
                </div>
            </div>
            <div class="actions">
                <div class="tw-grid tw-grid-cols-2 tw-gap-2">
                    <MMenu class="tw-w-full">
                        <MMenuButton class="tw-w-full">
                            <MButton
                                class="tw-w-full"
                                icon-menu
                                :label="selectedGroup.name"
                            />
                        </MMenuButton>
                        <MMenuItems>
                            <MMenuItem @click="selectedGroup = {id: null, name: __('common:all_groups')}">
                                {{ __('common:all_groups') }}
                            </MMenuItem>
                            <MMenuDivider/>
                            <MMenuItem
                                v-for="(group, i) in selectedNursery.groups"
                                :key="'nursery' + i"
                                @click="selectedGroup = group"
                            >
                                {{ group.name }}
                            </MMenuItem>
                        </MMenuItems>
                    </MMenu>
                    <MDatePicker
                        ref="datePickerRef"
                        v-model="selectedDate"
                        class="tw-w-full"
                        format="yyyy-MM-dd"
                    />
                </div>
            </div>
        </div>
        <div class="chart-content">
            <div class="chart-container p-2">
                <div class="w-100">
                    <hours
                        class="tw-mb-2 tw--ml-3"
                        :closing="closing"
                        :opening="opening"
                        style="padding-right: 5px;"
                    />
                    <kid-summary
                        :closing="closing"
                        :day="momentDate"
                        :loading="occupationLoading"
                        :opening="opening"
                        :summary="weekOccupationRateBar"
                    />
                    <staff-summary
                        class="mt-2"
                        :closing="closing"
                        :day="momentDate"
                        :loading="supervisionLoading"
                        :nursery="nursery"
                        :opening="opening"
                        :summary="weekOccupationRateBarStaff"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import __ from '@/modules/app/utils/i18n-facade';
    import route from '@/modules/legacy/libs/ziggy';
    import useApi from '@/modules/app/composables/useApi';
    import moment from 'moment';
    import _filter from 'lodash-es/filter';
    import _head from 'lodash-es/head';
    import _forEach from 'lodash-es/forEach';
    import {nursery} from '@/modules/legacy/mixins/nursery';
    import * as ProcessedData from '@/modules/legacy/libs/ProcessedData';
    import Hours from '@/modules/legacy/components/Modules/PlanningGenerator/Hours.vue';
    import KidSummary from '@/modules/legacy/components/Modules/PlanningGenerator/Summary.vue';
    import StaffSummary from '@/modules/legacy/components/Modules/PlanningGenerator/StaffSummary.vue';
    import useBroadcast from '@/modules/app/composables/useBroadcast';
    import {Epoch} from '@meekohq/lumos';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';
    import {isNil} from 'lodash-es';

    export default {
        components: {
            Hours,
            KidSummary,
            StaffSummary,
        },
        mixins: [nursery],
        props: {
            nursery: {},
            nurseries: {},
        },
        data: () => ({
            date: Epoch.now().toFormat('yyyy-MM-dd'),
            selectedDate: Epoch.now().toFormat('yyyy-MM-dd'),
            selectedGroup: {},
            selectedNursery: {},
            occupations: [],
            supervision: [],
            showSettings: false,
            supervisionLoading: false,
            occupationLoading: false,
        }),
        computed: {
            epochSelectedDate() {
                return this.selectedDate ? Epoch.parse(this.selectedDate, 'yyyy-MM-dd') : Epoch.now();
            },
            humanizedSelectedDate() {
                return this.epochSelectedDate.toLocaleString(Epoch.presets.WEEKDAY_WITH_DAY_AND_MONTH);
            },
            momentDate() {
                return moment(Epoch.parse(this.date, 'yyyy-MM-dd').toJSDate());
            },
            from() {
                return this.epochSelectedDate.startOfDay();
            },
            to() {
                return this.epochSelectedDate.endOfDay();
            },
            opening() {
                let hours = this.supervision[0] ? this.supervision[0].summary : [];
                this.occupations[0] ? hours = hours.concat(this.occupations[0].summary) : null;

                return this.$getOpening(this.nursery.openingHours, true, true, hours);
            },
            closing() {
                let hours = this.supervision[0] ? this.supervision[0].summary : [];
                this.occupations[0] ? hours = hours.concat(this.occupations[0].summary) : null;

                return this.$getClosing(this.nursery.openingHours, true, true, hours);
            },
            weekOccupationRateBar() {
                const filter = _filter(this.occupations, occupation => {
                    return occupation.date === this.date;
                });

                if (filter.length) {
                    return _head(filter).summary;
                }

                return [];
            },
            weekOccupationRateBarStaff() {
                const filter = _filter(this.supervision, supervision => {
                    return supervision.date === this.date;
                });

                if (filter.length) {
                    return _head(filter).summary;
                }

                return [];
            },
        },
        watch: {
            selectedDate(val) {
                if (isNil(val)) {
                    return;
                }
                this.showSettings = false;
                this.$refs.datePickerRef.hide();
                this.date = val;
            },
            from(val, oldVal) {
                if (
                    (val.toFormat('yyyy-MM-dd') !== oldVal.toFormat('yyyy-MM-dd')) &&
                    (this.nursery.places && this.nursery.openingHours.length)
                ) {
                    this.getOccupation();
                    this.getSupervision();
                }
            },
            'selectedNursery.id': {
                handler() {
                    if (this.selectedNursery && this.selectedNursery.id) {
                        this.switchGroup();
                        this.getOccupation();
                        this.getSupervision();
                    }
                },
                deep: true,
            },
            'nursery.id': {
                handler() {
                    this.selectedNursery = this.nursery;
                },
                deep: true,
            },
            'selectedGroup.id': {
                handler() {
                    this.getOccupation();
                    this.getSupervision();
                },
            },
        },
        mounted() {
            this.selectedNursery = this.nursery;

            useBroadcast().sessionChannel.bind('occupation', response => {
                ProcessedData.retrieve(response.processedDataUrl, response => {
                    this.occupations = response.data;
                    this.occupationLoading = false;
                });
            });

            useBroadcast().sessionChannel.bind('supervision', response => {
                ProcessedData.retrieve(response.processedDataUrl, response => {
                    this.supervision = response.data;
                    this.supervisionLoading = false;
                });
            });

            this.switchGroup();
        },
        beforeDestroy() {
            useBroadcast().sessionChannel.unbind('occupation');
            useBroadcast().sessionChannel.unbind('supervision');
        },
        methods: {
            getSupervision() {
                if (this.supervisionLoading) {
                    return;
                }

                this.supervisionLoading = true;
                useApi().legacy.get(route('nurseries.supervision', {nurseries: this.selectedNursery.id}), {
                    params: {
                        from: this.from.toTimestamp(),
                        to: this.to.toTimestamp(),
                        group_id: this.selectedGroup.id ? this.selectedGroup.id : null,
                        interval: 15,
                    },
                }).catch(error => {
                    this.supervisionLoading = false;
                    if (error && error.response && error.response.status === 422) {
                        _forEach(error.response.data.errors, value => {
                            useNotification().error(_head(value));
                        });
                    } else {
                        useNotification().error(error);
                    }
                });
            },
            getOccupation() {
                if (this.occupationLoading) {
                    return;
                }

                this.occupationLoading = true;
                useApi().legacy.get(route('nurseries.occupation', {nurseries: this.selectedNursery.id}), {
                    params: {
                        from: this.from.toTimestamp(),
                        to: this.to.toTimestamp(),
                        group_id: this.selectedGroup.id ? this.selectedGroup.id : null,
                        interval: 15,
                    },
                }).catch(error => {
                    this.occupationLoading = false;
                    if (error && error.response && error.response.status === 422) {
                        _forEach(error.response.data.errors, value => {
                            useNotification().error(_head(value));
                        });
                    } else {
                        useNotification().error(error);
                    }
                });
            },
            switchGroup(group) {
                if (!group) {
                    this.selectedGroup = {
                        id: null,
                        name: __('common:all_groups'),
                    };
                } else {
                    this.selectedGroup = group;
                }

                if (this.selectedNursery && this.selectedNursery.id) {
                    this.getOccupation();
                    this.getSupervision();
                }
            },
        },
    };
</script>
