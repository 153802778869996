import type {QueryBuilder} from '@meekohq/lumos';
import {collect, Model} from '@meekohq/lumos';
import TeamModel from '@/modules/human-resources/models/TeamModel';
import TeamOrganizationPivot from '@/modules/human-resources/models/TeamOrganizationPivot';
import Manager from '@/modules/legacy/store/manager.store';
import StaffModel from '@/modules/human-resources/models/StaffModel';
import StaffsOrganizationsPivot from '@/modules/human-resources/models/StaffsOrganizationsPivot';
import KidsGroupModel from '@/modules/organization/models/KidsGroupModel';
import TagModel from '@/modules/tag/models/TagModel';
import TagsOrganizationsPivot from '@/modules/tag/models/TagsOrganizationsPivot';
import useAuth from '@/modules/app/composables/useAuth';
import RegistrationModel from '@/modules/registration/models/RegistrationModel';
import RegistrationOrganizationPivot from '@/modules/registration/models/RegistrationOrganizationPivot';
import OrganizationTenantPivot from '@/modules/organization/models/OrganizationTenantPivot';
import TenantModel from '@/modules/cashier/models/TenantModel';
import type {CountryCode} from 'libphonenumber-js/types';

export default class OrganizationModel extends Model {
    public type = 'organization/organizations';
    public attributes: {
        id: string;
        legacy_id: number | undefined;
        account_id: string | undefined;
        name: string | undefined;
        timezone: string | undefined;
        places: number | null;
        occ_max_rate: number | null;
        occ_min_rate: number | null;
        address: {
            line1: string | undefined;
            line2: string | undefined;
            postal_code: string | undefined;
            city: string | undefined;
            state: string | undefined;
            country_code: CountryCode | undefined;
        };
        currency: string | undefined;
        locale: string | undefined;
    } = {
            id: this.uuid(),
            legacy_id: undefined,
            account_id: undefined,
            name: undefined,
            timezone: 'Europe/Paris',
            places: null,
            occ_max_rate: null,
            occ_min_rate: null,
            address: {
                line1: undefined,
                line2: undefined,
                postal_code: undefined,
                city: undefined,
                state: undefined,
                country_code: undefined,
            },
            currency: undefined,
            locale: undefined,
        };

    get addressLine(): string | undefined {
        let address = this.attributes.address.line1;

        if (this.attributes.address.line2) {
            address += `, ${this.attributes.address.line2}`;
        }

        address += ` - ${this.attributes.address.postal_code} ${this.attributes.address.city}`;

        return address;
    }

    /**
     * Scope to constrain the query to only get the current user organizations
     * @param query
     */
    public static belongingToAuthUserScope(query: QueryBuilder<OrganizationModel>) {
        const organizationIds = collect(useAuth().legacyUser.value.nurseries).keyBy('id').keys().all();
        query.whereIn('id', organizationIds);
    }

    teams() {
        return this.belongsToMany('teams', TeamModel, TeamOrganizationPivot, 'organization_id', 'team_id')
            .setPivotQuery(query => query.scope('notArchived'));
    }

    staffs() {
        return this.belongsToMany('staffs', StaffModel, StaffsOrganizationsPivot, 'organization_id', 'staff_id');
    }

    kidGroups() {
        return this.hasMany('kidGroups', KidsGroupModel, 'organization_id');
    }

    tags() {
        return this.belongsToMany('tags', TagModel, TagsOrganizationsPivot, 'organization_id', 'tag_id');
    }

    registrations() {
        return this.belongsToMany('registrations', RegistrationModel, RegistrationOrganizationPivot, 'organization_id', 'registration_form_id').setApiRelationName('registrationForms');
    }

    tenants() {
        return this.belongsToMany('tenants', TenantModel, OrganizationTenantPivot, 'organization_id', 'tenant_id');
    }

    /**
     * Scope to constrain the query to only get the current user organizations
     * @param query
     *
     * @deprecated Use the static method instead
     */
    scopeUserOrganizations(query: QueryBuilder<any>) {
        const organizationIds = collect(useAuth().legacyUser.value.nurseries).keyBy('id').keys().all();
        query.whereIn('id', organizationIds);
    }

    scopeNotActive(query: QueryBuilder<any>) {
        query.where('id', '!=', Manager.activeOrganization.id);
    }

    scopeActive(query: QueryBuilder<any>) {
        query.where('id', Manager.activeOrganization.id);
    }
}
