import {stringify} from 'qs';

class UrlBuilder {
    constructor(name, absolute, ziggyObject) {

        this.name = name;
        this.ziggy = ziggyObject;
        this.route = this.ziggy.namedRoutes[this.name];

        if (typeof this.name === 'undefined') {
            throw new Error('Ziggy Error: You must provide a route name');
        } else if (typeof this.route === 'undefined') {
            throw new Error(`Ziggy Error: route '${this.name}' is not found in the route list`);
        }

        this.absolute = typeof absolute === 'undefined' ? true : absolute;
        this.domain = this.setDomain();
        this.path = this.route.uri.replace(/^\//, '');
    }

    setDomain() {
        if (!this.absolute)
            return '/';

        if (!this.route.domain)
            return this.ziggy.baseUrl.replace(/\/?$/, '/');

        let host = (this.route.domain || this.ziggy.baseDomain).replace(/\/+$/, '');

        if (this.ziggy.basePort && (host.replace(/\/+$/, '') === this.ziggy.baseDomain.replace(/\/+$/, '')))
            host = this.ziggy.baseDomain + ':' + this.ziggy.basePort;

        return this.ziggy.baseProtocol + '://' + host + '/';
    }

    construct() {
        return this.domain + this.path;
    }
}

class Router extends String {
    constructor(name, params, absolute, customZiggy = null) {
        super();

        this.name = name;
        this.absolute = absolute;
        this.ziggy = customZiggy ? customZiggy : Ziggy;
        this.urlBuilder = this.name ? new UrlBuilder(name, absolute, this.ziggy) : null;
        this.template = this.urlBuilder ? this.urlBuilder.construct() : '';
        this.urlParams = this.normalizeParams(params);
        this.queryParams = {};
        this.hydrated = '';
    }

    normalizeParams(params) {
        if (typeof params === 'undefined') return {};

        // If you passed in a string or integer, wrap it in an array
        params = typeof params !== 'object' ? [params] : params;

        // If the tags object contains an ID and there isn't an ID param in the
        // url template, they probably passed in a single model object and we should
        // wrap this in an array. This could be slightly dangerous and I want to find
        // a better solution for this rare case.

        if (
            params.hasOwnProperty('id') &&
            this.template.indexOf('{id}') == -1
        ) {
            params = [params.id];
        }

        this.numericParamIndices = Array.isArray(params);

        return Object.assign({}, params);
    }

    with(params) {
        this.urlParams = this.normalizeParams(params);

        return this;
    }

    withQuery(params) {
        Object.assign(this.queryParams, params);

        return this;
    }

    hydrateUrl() {
        if (this.hydrated) return this.hydrated;

        let hydrated = this.template.replace(
            /{([^}]+)}/gi,
            (tag, i) => {
                const keyName = this.trimParam(tag);
                let defaultParameter;
                let tagValue;

                if (this.ziggy.defaultParameters.hasOwnProperty(keyName)) {
                    defaultParameter = this.ziggy.defaultParameters[keyName];
                }

                // If a default parameter exists, and a value wasn't
                // provided for it manually, use the default value
                if (defaultParameter && !this.urlParams[keyName]) {
                    delete this.urlParams[keyName];

                    return defaultParameter;
                }

                // We were passed an array, shift the value off the
                // object and return that value to the route
                if (this.numericParamIndices) {
                    this.urlParams = Object.values(this.urlParams);

                    tagValue = this.urlParams.shift();
                } else {
                    tagValue = this.urlParams[keyName];
                    delete this.urlParams[keyName];
                }

                // The type of the value is undefined; is this param
                // optional or not
                if (typeof tagValue === 'undefined') {
                    if (tag.indexOf('?') === -1) {
                        throw new Error(
                            'Ziggy Error: \'' +
                            keyName +
                            '\' key is required for route \'' +
                            this.name +
                            '\'',
                        );
                    } else {
                        return '';
                    }
                }

                // If an object was passed and has an id, return it
                if (tagValue.id) {
                    return encodeURIComponent(tagValue.id);
                }

                return encodeURIComponent(tagValue);
            },
        );

        if (this.urlBuilder != null && this.urlBuilder.path !== '') {
            hydrated = hydrated.replace(/\/+$/, '');
        }

        this.hydrated = hydrated;

        return this.hydrated;
    }

    matchUrl() {
        const windowUrl =
            window.location.hostname +
            (window.location.port ? ':' + window.location.port : '') +
            window.location.pathname;

        // Strip out optional parameters
        const optionalTemplate = this.template
            .replace(/(\/\{[^\}]*\?\})/g, '/')
            .replace(/(\{[^\}]*\})/gi, '[^/?]+')
            .replace(/\/?$/, '')
            .split('://')[1];

        const searchTemplate = this.template
            .replace(/(\{[^\}]*\})/gi, '[^/?]+')
            .split('://')[1];
        const urlWithTrailingSlash = windowUrl.replace(/\/?$/, '/');

        const regularSearch = new RegExp('^' + searchTemplate + '/$').test(
            urlWithTrailingSlash,
        );
        const optionalSearch = new RegExp('^' + optionalTemplate + '/$').test(
            urlWithTrailingSlash,
        );

        return regularSearch || optionalSearch;
    }

    constructQuery() {
        if (
            Object.keys(this.queryParams).length === 0 &&
            Object.keys(this.urlParams).length === 0
        ) {
            return '';
        }

        const remainingParams = Object.assign(this.urlParams, this.queryParams);

        return stringify(remainingParams, {
            encodeValuesOnly: true,
            skipNulls: true,
            addQueryPrefix: true,
            arrayFormat: 'indices',
        });
    }

    current(name = null) {
        const routeNames = Object.keys(this.ziggy.namedRoutes);

        const currentRoute = routeNames.filter(name => {
            if (this.ziggy.namedRoutes[name].methods.indexOf('GET') === -1) {
                return false;
            }

            return new Router(
                name,
                undefined,
                undefined,
                this.ziggy,
            ).matchUrl();
        })[0];

        if (name) {
            const pattern = new RegExp(
                '^' + name.replace('*', '.*').replace('.', '.') + '$',
                'i',
            );

            return pattern.test(currentRoute);
        }

        return currentRoute;
    }

    check(name) {
        const routeNames = Object.keys(this.ziggy.namedRoutes);

        return routeNames.includes(name);
    }

    extractParams(uri, template, delimiter) {
        const uriParts = uri.split(delimiter);
        const templateParts = template.split(delimiter);

        return templateParts.reduce(
            (params, param, i) =>
                param.indexOf('{') === 0 &&
                param.indexOf('}') !== -1 &&
                uriParts[i]
                    ? Object.assign(params, {
                        [this.trimParam(param)]: uriParts[i],
                    })
                    : params,
            {},
        );
    }

    get params() {
        const namedRoute = this.ziggy.namedRoutes[this.current()];

        return Object.assign(
            this.extractParams(
                window.location.hostname,
                namedRoute.domain || '',
                '.',
            ),
            this.extractParams(
                window.location.pathname.slice(1),
                namedRoute.uri,
                '/',
            ),
        );
    }

    parse() {
        this.return = this.hydrateUrl() + this.constructQuery();
    }

    url() {
        this.parse();

        return this.return;
    }

    toString() {
        return this.url();
    }

    trimParam(param) {
        return param.replace(/{|}|\?/g, '');
    }

    valueOf() {
        return this.url();
    }
}

export default function route(name, params, absolute, customZiggy) {
    return new Router(name, params, absolute, customZiggy);
}
