<template>
    <div
        ref="staffsListItemEl"
        class="StaffsListItem"
    >
        <div class="StaffsListItem__container">
            <MagicIndexItemColumn
                class="StaffsListItem__column-name"
                :grid-enabled="false"
            >
                <div class="tw-flex tw-gap-3 tw-items-center tw-min-w-0">
                    <BadgeTasks :tasks="tasksByStaffId[staff.getKey()]">
                        <StaffAvatar
                            class="tw-w-10"
                            :staff-model="staff"
                        />
                    </BadgeTasks>
                    <MListItemTitle
                        :title="staff.fullname"
                        :variant="staff.attributes.gender === 'male' ? 'male' : 'female'"
                    />
                </div>
            </MagicIndexItemColumn>
            <MagicIndexItemColumn
                :class="staff.attributes.job ? 'StaffsListItem__column-phone' : 'StaffsListItem__column-phone--expanded'"
                :container-width="width"
                :grid-title="__('common:phone.general')"
                :is-empty="!hasAtLeastOnePhone(staff)"
            >
                <div class="tw-flex tw-flex-col tw-gap-2">
                    <MListIcon
                        v-if="staff.attributes.phone"
                        icon="fa fa-mobile"
                        :title="staff.attributes.phone"
                    />
                    <MListIcon
                        v-if="staff.attributes.mobile_phone"
                        icon="fa fa-phone"
                        :title="staff.attributes.mobile_phone"
                    />
                </div>
            </MagicIndexItemColumn>
            <MagicIndexItemColumn
                :class="hasAtLeastOnePhone(staff) ? 'StaffsListItem__column-job' : 'StaffsListItem__column-job--expanded'"
                :container-width="width"
                :grid-title="__('hr:job_one')"
                :is-empty="!staff.attributes.job"
            >
                {{ staff.attributes.job }}
            </MagicIndexItemColumn>
            <MagicIndexItemColumn
                class="StaffsListItem__column-tags"
                :container-width="width"
                :grid-title="__('common:tags')"
            >
                <TagsSelector
                    :editable="can('update', 'staffs_details')"
                    prevent-default
                    size="sm"
                    :taggable-model="staff"
                    taggable-type="staff"
                    :tags="staff.tags().value().toArray()"
                    @attached="staff.tags().value().push($event)"
                    @detached="staff.tags().mutate(v => v.filter(c => c.getKey() !== $event.getKey()))"
                />
            </MagicIndexItemColumn>
        </div>
    </div>
</template>

<script lang="ts">
    import {defineComponent, type PropType, ref} from 'vue';
    import BadgeTasks from '@/modules/activity/components/pages/BadgeTasks.vue';
    import MListItemTitle from '@/modules/meeko-ui/components/MListItemTitle.vue';
    import StaffAvatar from '@/modules/app/components/atoms/avatars/StaffAvatar.vue';
    import MagicIndexItemColumn from '@/modules/magic-index/components/atoms/MagicIndexItemColumn.vue';
    import TagsSelector from '@/modules/tag/components/TagsSelector.vue';
    import useAbility from '@/modules/app/composables/useAbility';
    import type StaffModel from '@/modules/human-resources/models/StaffModel';
    import {useElementSize} from '@vueuse/core';
    import MListIcon from '@/modules/meeko-ui/components/MListIcon.vue';

    export default defineComponent({
        components: {
            MListIcon,
            TagsSelector,
            MagicIndexItemColumn,
            StaffAvatar,
            MListItemTitle,
            BadgeTasks,
        },
        props: {
            staff: {
                type: Object as PropType<StaffModel>,
                required: true,
            },
            tasksByStaffId: {
                type: Object,
                required: true,
            },
        },
        setup() {
            const {can} = useAbility();
            const staffsListItemEl = ref(null);
            const {width} = useElementSize(staffsListItemEl);

            function hasAtLeastOnePhone(staff: StaffModel) {
                return !!(staff.attributes.mobile_phone || staff.attributes.phone);
            }

            return {
                can,
                staffsListItemEl,
                width,
                hasAtLeastOnePhone,
            };
        },
    });
</script>

<style lang="scss" scoped>
    .StaffsListItem {
        container-type: inline-size;
        container-name: StaffsListItem;
    }

    .StaffsListItem__container {
        @apply tw-grid tw-grid-cols-24 tw-gap-3;

        @container StaffsListItem (min-width: 48rem) {
            @apply tw-items-center;
        }
    }

    .StaffsListItem__column-name {
        @apply tw-col-span-20;

        @container StaffsListItem (min-width: 48rem) {
            @apply tw-col-span-10;
        }
    }

    .StaffsListItem__column-phone {
        @apply tw-col-span-12;

        @container StaffsListItem (min-width: 48rem) {
            @apply tw-col-span-4;
        }
    }

    .StaffsListItem__column-phone--expanded {
        @apply tw-col-span-full;

        @container StaffsListItem (min-width: 48rem) {
            @apply tw-col-span-4;
        }
    }

    .StaffsListItem__column-job {
        @apply tw-col-span-12;

        @container StaffsListItem (min-width: 48rem) {
            @apply tw-col-span-6;
        }
    }

    .StaffsListItem__column-job--expanded {
        @apply tw-col-span-full;

        @container StaffsListItem (min-width: 48rem) {
            @apply tw-col-span-6;
        }
    }

    .StaffsListItem__column-tags {
        @apply tw-col-span-full;

        @container StaffsListItem (min-width: 48rem) {
            @apply tw-col-span-4;
        }
    }
</style>
