import {Model} from '@meekohq/lumos';
import TagsOrganizationsPivot from '@/modules/tag/models/TagsOrganizationsPivot';
import OrganizationModel from '@/modules/organization/models/OrganizationModel';

export default class TagModel extends Model {
    public type = 'tags/tags';

    public attributes: {
        id: string;
        account_id: string | undefined;
        icon_id: string | undefined;
        name: string | undefined;
        slug: string | null;
        color: string | undefined;
        type: string | undefined;
        archived_at: string | undefined | null;
        created_at: string | undefined;
        updated_at: string | undefined;
    } = {
            id: this.uuid(),
            account_id: undefined,
            icon_id: undefined,
            name: undefined,
            slug: null,
            color: undefined,
            type: undefined,
            archived_at: null,
            created_at: undefined,
            updated_at: undefined,
        };

    async attachToOrga(organizationModel: OrganizationModel) {
        this.attributes.account_id = organizationModel.attributes.account_id;

        await this.save();

        const pivot = new TagsOrganizationsPivot();
        pivot.attributes.account_id = organizationModel.attributes.account_id;
        pivot.attributes.organization_id = organizationModel.id;
        pivot.tag().associate(this);
        await pivot.save();

        return pivot;
    }

    organizations() {
        return this.belongsToMany('organizations', OrganizationModel, TagsOrganizationsPivot, 'tag_id', 'organization_id');
    }
}
