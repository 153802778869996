<template>
    <div class="tw-relative">
        <div
            class="tw-h-16 tw-absolute tw-top-0 tw-w-full tw-z-0 tw-rounded-t-lg"
            :class="topBackgroundBaseClass"
        >
            <div class="puzzle tw-absolute tw-inset-0"/>
        </div>
        <div class="tw-p-4 tw-relative">
            <div class="tw-flex tw-justify-center">
                <div class="tw-flex tw-flex-col tw-items-center">
                    <MemberAvatar
                        class="tw-w-16"
                        :member-model="memberModel"
                    />
                    <div
                        class="tw-font-semibold"
                        :class="{'tw-text-boy': memberModel.isBoy, 'tw-text-girl': memberModel.isGirl}"
                    >
                        {{ memberModel.fullname }}
                    </div>
                </div>
            </div>
            <CPropertyList class="tw-mt-2">
                <CPropertyListItem
                    v-if="memberModel.attributes.email"
                    title-class="tw-w-32"
                >
                    <template #title>
                        <i
                            aria-hidden="true"
                            class="far fa-at fa-fw tw-mr-2 tw-text-gray-500"
                        />{{ __('common:email') }}
                    </template>
                    {{ memberModel.attributes.email }}
                </CPropertyListItem>
                <CPropertyListItem
                    v-if="memberModel.attributes.phone"
                    title-class="tw-w-32"
                >
                    <template #title>
                        <i
                            aria-hidden="true"
                            class="fas fa-phone fa-fw tw-mr-2 tw-text-gray-500"
                        />{{ __('common:phone.general') }}
                    </template>
                    {{ memberModel.attributes.phone }}
                </CPropertyListItem>
                <CPropertyListItem
                    v-if="memberModel.attributes.mobile_phone"
                    title-class="tw-w-32"
                >
                    <template #title>
                        <i
                            aria-hidden="true"
                            class="fas fa-mobile-alt fa-fw tw-mr-2 tw-text-gray-500"
                        />{{ __('family_member:mobile_phone') }}
                    </template>
                    {{ memberModel.attributes.mobile_phone }}
                </CPropertyListItem>
                <CPropertyListItem
                    v-if="memberModel.attributes.office_phone"
                    title-class="tw-w-32"
                >
                    <template #title>
                        <i
                            aria-hidden="true"
                            class="fas fa-phone-office fa-fw tw-mr-2 tw-text-gray-500"
                        />{{ __('family_member:work_phone') }}
                    </template>
                    {{ memberModel.attributes.office_phone }}
                </CPropertyListItem>
            </CPropertyList>
        </div>
    </div>
</template>

<script setup lang="ts">
    import type {PropType} from 'vue';
    import {computed} from 'vue';
    import type MemberModel from '@/modules/family/models/MemberModel';
    import MemberAvatar from '@/modules/app/components/atoms/avatars/MemberAvatar.vue';

    const props = defineProps({
        memberModel: {
            type: Object as PropType<MemberModel>,
            required: true,
        },
    });

    const topBackgroundBaseClass = computed(() => {
        const output = [] as any[];

        const variants = {
            male: 'boy-gradient',
            female: 'girl-gradient',
        };
        output.push(variants[props.memberModel.attributes.gender as string]);

        return output;
    });
</script>

<style
    lang="scss"
    scoped
>
    .boy-gradient {
        background: linear-gradient(to bottom right, rgba(16, 165, 224, .4) 0, rgba(109, 125, 142, .4) 40%, rgba(109, 125, 142, .6))
    }

    .girl-gradient {
        background: linear-gradient(to bottom right, rgba(254, 111, 97, .2) 0, rgba(109, 125, 142, .4) 40%, rgba(109, 125, 142, .6))
    }

    .puzzle {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='30' height='48' viewBox='0 0 60 96'%3E%3Cg fill-rule='evenodd'%3E%3Cg fill='%23000' fill-opacity='0.05'%3E%3Cpath d='M36 10a6 6 0 0 1 12 0v12a6 6 0 0 1-6 6 6 6 0 0 0-6 6 6 6 0 0 1-12 0 6 6 0 0 0-6-6 6 6 0 0 1-6-6V10a6 6 0 1 1 12 0 6 6 0 0 0 12 0zm24 78a6 6 0 0 1-6-6 6 6 0 0 0-6-6 6 6 0 0 1-6-6V58a6 6 0 1 1 12 0 6 6 0 0 0 6 6v24zM0 88V64a6 6 0 0 0 6-6 6 6 0 0 1 12 0v12a6 6 0 0 1-6 6 6 6 0 0 0-6 6 6 6 0 0 1-6 6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    }
</style>
