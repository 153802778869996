<template>
    <SimplePageTemplate>
        <template #title>
            <MPageTitle variant="billing">
                {{ __('app:menu.invoices') }}
            </MPageTitle>
        </template>
        <template #actions>
            <MTooltip>
                <MButton
                    :loading="exportLoading"
                    @click="getXlsFile"
                >
                    <template #left-icons>
                        <FontAwesomeIcon icon="fa fa-download"/>
                    </template>
                    {{ __('billing_invoice:excel_export') }}
                </MButton>
                <template #content>
                    {{ __('billing_invoice:download_summary_of_invoices') }}
                </template>
            </MTooltip>
            <MButton
                v-if="can('read', 'invoices')"
                @click="switchToColumnView"
            >
                <template #left-icons>
                    <FontAwesomeIcon icon="fa fa-line-columns"/>
                </template>
                {{ __('billing_invoice:column') }}
            </MButton>
            <MSettingsButton
                v-if="can('settings', 'invoices')"
                :to="{name:'settings.billings.rules',params:{nursery:nursery.id}}"
                :tooltip="__('billing_invoice:invoices_settings')"
            />
        </template>
        <template #content>
            <div class="row mb-3">
                <div class="col-12 col-md-8 align-self-center">
                    <div class="d-sm-inline-block d-block">
                        <TagsResourceFinder
                            v-model="selectedTagsModels"
                            class="tw-mr-2"
                            has-unselect
                            :inject-query="tagFinderQuery"
                            multi
                            only-active-organization
                            :search-bar="false"
                            :without-tags-option-is-selected="withoutTags"
                            @without-tags-option-is-selected="withoutTags = $event"
                        />

                        <FilterInvoiceStatus
                            v-model="selectedStatus"
                            class="tw-inline-block tw-mr-2"
                        />

                        <FilterInvoiceOrder
                            class="tw-inline-block"
                            :order.sync="order"
                            :order-by.sync="orderBy"
                        />
                    </div>
                </div>
            </div>

            <div class="card card-3d">
                <div class="card-body">
                    <div class="row">
                        <div class="col-6 col-sm-4 order-2 order-sm-1 text-left">
                            <button
                                class="btn btn-success btn-sm text-capitalize"
                                @click="goPreviousMonth"
                            >
                                <i class="fa fa-angle-left mr-1"/>
                                {{ currentMonth.subMonths(1).toLocaleString({month: 'long', year: 'numeric'}) }}
                            </button>
                        </div>
                        <div class="col-12 col-sm-4 order-1 order-sm-2 text-center align-self-center">
                            <MHeading class="tw-capitalize">
                                {{ currentMonth.toLocaleString({month: 'long', year: 'numeric'}) }}
                            </MHeading>
                        </div>
                        <div class="col-6 col-sm-4 order-3 order-sm-3 text-right">
                            <button
                                class="btn btn-success btn-sm text-capitalize"
                                @click="goNextMonth"
                            >
                                {{ currentMonth.addMonths(1).toLocaleString({month: 'long', year: 'numeric'}) }}
                                <i class="fa fa-angle-right ml-1"/>
                            </button>
                        </div>
                    </div>
                    <hr class="hr">
                    <transition
                        mode="out-in"
                        name="transition-fade"
                    >
                        <div v-if="!loading">
                            <div class="row mb-3">
                                <div class="col">
                                    <h6 class="h6">
                                        {{ __('billing_invoice:month_total_colon') }}
                                        {{ useFormatNumber(CurrencySymbolToIsoCode[nursery.currency]).format(parseFloat(totalAmount)) }}
                                    </h6>
                                </div>
                            </div>
                            <div class="tw-border tw-border-gray-200 tw-rounded-lg">
                                <InvoicesListItem
                                    v-for="invoice in invoicesLegaciesWithModels"
                                    :key="'invoice' + invoice.model.getKey()"
                                    class="tw-border-b tw-border-gray-300 last:tw-border-b-0 tw-p-3 lg:tw-py-2"
                                    :customer="invoice.model.customer().value()"
                                    inverse-date-kid
                                    :invoice="invoice.legacy"
                                    :invoice-model="invoice.model"
                                    :nursery="nursery"
                                    show-by-list
                                    @deleteInvoice="deleteInvoice(invoice.legacy)"
                                    @duplicateInvoice="show($event, invoice.model.customer().value())"
                                    @showInvoice="show(invoice.legacy, invoice.model.customer().value(), $event)"
                                    @updateInvoices="getInvoices"
                                />
                            </div>
                            <div
                                v-if="!filteredInvoices.length"
                                class="tw-mt-5 tw-text-center"
                            >
                                <h4 class="h4">
                                    {{ __('billing_core:no_invoice_with_selected_filters') }}
                                </h4>
                            </div>
                            <div
                                v-if="paginator.total() > paginator.perPage()"
                                class="tw-flex tw-justify-center tw-mt-4"
                            >
                                <MPagination
                                    :paginator="paginator"
                                    @navigate="getInvoices"
                                />
                            </div>
                        </div>

                        <div
                            v-else
                            key="loader"
                        >
                            <loader custom-class="la-2x"/>
                        </div>
                    </transition>
                </div>
            </div>
        </template>
    </SimplePageTemplate>
</template>

<script lang="ts">
    import type {Ref} from 'vue';
    import {computed, defineComponent, onMounted, onUnmounted, ref, watch} from 'vue';
    import useApi from '@/modules/app/composables/useApi';
    import route from '@/modules/legacy/libs/ziggy';
    import _sumBy from 'lodash-es/sumBy';
    import _cloneDeep from 'lodash-es/cloneDeep';
    import _forEach from 'lodash-es/forEach';
    import {Epoch, LengthAwarePaginator} from '@meekohq/lumos';
    import {invoice} from '@/modules/legacy/mixins/invoice';
    import {url} from '@/modules/legacy/mixins/url';
    import EditInvoice from '@/modules/cashier/components/billing/invoice/organisms/EditInvoice.vue';
    import InvoicesListItem from '@/modules/cashier/components/billing/invoice/molecules/InvoicesListItem.vue';
    import FilterInvoiceStatus from '@/modules/cashier/components/billing/invoice/FilterInvoiceStatus.vue';
    import FilterInvoiceOrder from '@/modules/cashier/components/billing/invoice/FilterInvoiceOrder.vue';
    import useBroadcast from '@/modules/app/composables/useBroadcast';
    import SimplePageTemplate from '@/modules/app/components/templates/SimplePageTemplate.vue';
    import useMetrics from '@/modules/app/composables/useRum';
    import {debounce, forEach} from 'lodash-es';
    import OrganizationModel from '@/modules/organization/models/OrganizationModel';
    import UserModel from '@/modules/user/models/UserModel';
    import {useRoute, useRouter} from 'vue-router/composables';
    import useAbility from '@/modules/app/composables/useAbility';
    import useUrl from '@/modules/app/composables/useUrl';
    import useFormatNumber from '@/modules/cashier/composables/useFormatNumber';
    import CurrencySymbolToIsoCode from '@/modules/cashier/utils/core/currency/CurrencySymbolToIsoCode';
    import __ from '@/modules/app/utils/i18n-facade';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';
    import useModal from '@/modules/app/composables/useModal';
    import InvoiceModel from '@/modules/cashier/models/InvoiceModel';
    import TagModel from '@/modules/tag/models/TagModel';
    import TagsResourceFinder from '@/modules/tag/components/TagsResourceFinder.vue';
    import useTagLegacyModelBridge from '@/modules/app/composables/useTagLegacyModelBridge';
    import MPagination from '@/modules/meeko-ui/components/MPagination.vue';
    import type CustomerModel from '@/modules/cashier/models/CustomerModel';

    export default defineComponent({
        components: {
            MPagination,
            TagsResourceFinder,
            SimplePageTemplate,
            InvoicesListItem,
            FilterInvoiceStatus,
            FilterInvoiceOrder,
        },
        mixins: [invoice, url],
        props: {
            nursery: {
                type: OrganizationModel,
                required: true,
            },
            user: {
                type: UserModel,
                required: true,
            },
        },
        setup(props) {
            const routeParam = useRoute();
            const router = useRouter();
            const {can} = useAbility();
            const {pushQueryString} = useUrl();

            const invoices: Ref<any[]> = ref([]);
            const invoicesModels = ref<InvoiceModel[]>();
            const currentInvoice = ref({});
            const currentCustomer = ref();
            const loading = ref(true);

            const {
                getTagsModelsFromIds,
                tagsModelsAndWithoutTagsToLegacyFilter,
            } = useTagLegacyModelBridge();

            const editInvoiceModal = useModal({
                component: EditInvoice,
                props: {
                    nursery: props.nursery,
                    user: props.user,
                    invoice: currentInvoice,
                    customer: currentCustomer,
                },
                listeners: () => ({
                    updateInvoices: () => getInvoices(),
                }),
            });

            const paginator = ref<LengthAwarePaginator<InvoiceModel>>();
            const orderBy = ref(
                window.localStorage.getItem('filter:invoice:orderBy')
                    ? window.localStorage.getItem('filter:invoice:orderBy')
                    : 'no',
            );
            const order = ref(
                window.localStorage.getItem('filter:invoice:order')
                    ? window.localStorage.getItem('filter:invoice:order')
                    : 'asc',
            );
            const defaultView = ref('invoices:defaultView');

            const currentMonth = ref(Epoch.now());

            const selectedStatus = ref([]);
            const exportLoading: Ref<boolean> = ref(false);
            const selectedTagsModels = ref([]) as Ref<TagModel[]>;
            const withoutTags = ref(false);

            const tagFinderQuery = TagModel.query().where('type', 'invoice');

            onMounted(() => {
                loading.value = true;
                getSelectedTagsModels();

                selectMonth(routeParam.query.from);
                useBroadcast().sessionChannel.bind('export_data', response => {
                    const link = document.createElement('a');
                    link.href = response.url;
                    link.dispatchEvent(
                        new MouseEvent('click', {
                            bubbles: true,
                            cancelable: true,
                            view: window,
                        }),
                    );
                    exportLoading.value = false;
                });
                window.localStorage.setItem(defaultView.value, 'list');
                useMetrics().addAction('M_Invoices_Display', {value: 'list'});
            });

            onUnmounted(() => {
                useBroadcast().sessionChannel.unbind('export_data');
            });

            if (localStorage.getItem('without_tags:invoices:index')) {
                const withoutTagsInStorage = localStorage.getItem('without_tags:invoices:index') as string;
                withoutTags.value = JSON.parse(withoutTagsInStorage) || false as boolean;
            }

            const filteredInvoices = computed(() => {
                return invoices.value;
            });

            const invoicesLegaciesWithModels = computed(() => {
                return filteredInvoices.value.map(legacy => {
                    const model = invoicesModels.value?.find(item => item.getKey() === legacy.id);

                    return {
                        legacy,
                        model,
                    };
                });
            });

            const totalAmount = computed(() => {
                const total = _sumBy(filteredInvoices.value, item => Number(item.grand_total));

                return total.toFixed(2);
            });

            watch([currentMonth, order, orderBy, selectedStatus], () => {
                getInvoices();
            });

            watch(() => routeParam.query.from, from => {
                selectMonth(from);
            });

            watch([() => selectedTagsModels.value, () => withoutTags.value], debounce(function([selectedTagsModelsVal, withoutTagsVal]) {
                if (selectedTagsModelsVal) {
                    localStorage.setItem(
                        'tags:invoices:index',
                        JSON.stringify(selectedTagsModelsVal.map(item => item.getKey())),
                    );
                } else {
                    localStorage.removeItem('tags:invoices:index');
                }
                localStorage.setItem('without_tags:invoices:index', JSON.stringify(withoutTagsVal));

                getInvoices();
            }, 500));

            async function getSelectedTagsModels() {
                const selectedTagIds = JSON.parse(localStorage.getItem('tags:invoices:index') as string);
                selectedTagsModels.value = await getTagsModelsFromIds(selectedTagIds);
            }

            function getInvoices(page = 1) {
                loading.value = true;

                const tags = tagsModelsAndWithoutTagsToLegacyFilter(selectedTagsModels.value, withoutTags.value);

                useApi().legacy.get(
                    route('nurseries.invoices.index', {
                        nurseries: props.nursery.id,
                    }),
                    {
                        params: {
                            page,
                            per_page: 500, // Solution provisiore pour afficher toutes les factures
                            with: ['lines', 'customer'],
                            from: currentMonth.value
                                .startOfMonth()
                                .toFormat('y-MM-dd'),
                            to: currentMonth.value
                                .endOfMonth()
                                .toFormat('y-MM-dd'),
                            order_by: orderBy.value,
                            order: order.value,
                            status: selectedStatus.value,
                            tags,
                        },
                    },
                )
                    .then(response => {
                        paginator.value = new LengthAwarePaginator(
                            response.data.data,
                            parseInt(response.data.total),
                            parseInt(response.data.per_page),
                            parseInt(response.data.current_page),
                            parseInt(response.data.last_page),
                        );

                        return getInvoicesModels(response.data.data);
                    }).catch(error => {
                        if (error?.response && error.response.status === 422) {
                            _forEach(error.response.data.errors, value => {
                                useNotification().error(value);
                            });
                        } else {
                            useNotification().error(error);
                        }
                    }).finally(() => loading.value = false);
            }

            async function getInvoicesModels(newInvoices: any[]) {
                if (!newInvoices.length) {
                    invoicesModels.value = [];
                    invoices.value = [];

                    return;
                }

                invoicesModels.value = (await InvoiceModel.query()
                    .with(new InvoiceModel().tags())
                    .with(new InvoiceModel().payments())
                    .with(new InvoiceModel().refunds())
                    .with(new InvoiceModel().customer())
                    .whereIn('id', newInvoices.map(item => item.id))
                    .get()
                ).all();
                invoices.value = newInvoices;
            }

            function deleteInvoice(invoice) {
                useApi().legacy.delete(
                    route('nurseries.invoices.destroy', {
                        nurseries: invoice.nursery_id,
                        invoice: invoice.id,
                    }),
                )
                    .then(() => {
                        useNotification().success(__('billing_invoice:invoice_deleted_successfully'));
                        getInvoices();
                    })
                    .catch(error => {
                        if (error?.response && error.response.status === 422) {
                            _forEach(error.response.data.errors, function(value) {
                                useNotification().error(value);
                            });
                        } else {
                            useNotification().error(error);
                        }
                    });
            }

            function show(invoice, customer: CustomerModel, scrollToPaymentAllocations = false) {
                currentInvoice.value = _cloneDeep(invoice);
                currentCustomer.value = customer.clone();
                editInvoiceModal.modal.value.props!.scrollToPaymentAllocations = scrollToPaymentAllocations;
                editInvoiceModal.show();
            }

            function selectMonth(from) {
                if (from) {
                    currentMonth.value = Epoch.fromTimestamp(from);
                }
            }

            function getXlsFile() {
                exportLoading.value = true;
                useApi().legacy.post(route('export'), {
                    nurseries: [props.nursery.id],
                    resources: [
                        {
                            name: 'invoices',
                            filters: {
                                from: currentMonth.value
                                    .startOfMonth()
                                    .toFormat('y-MM-dd'),
                                to: currentMonth.value
                                    .endOfMonth()
                                    .toFormat('y-MM-dd'),
                            },
                        },
                        {
                            name: 'proformas',
                            filters: {
                                from: currentMonth.value
                                    .startOfMonth()
                                    .toFormat('y-MM-dd'),
                                to: currentMonth.value
                                    .endOfMonth()
                                    .toFormat('y-MM-dd'),
                            },
                        },
                    ],
                })
                    .catch(error => {
                        exportLoading.value = false;

                        if (
                            error &&
                            error.response &&
                            error.response.status === 422
                        ) {
                            forEach(error.response.data.errors, value => {
                                useNotification().error(value);
                            });
                        } else {
                            useNotification().error(error);
                        }
                    });
            }

            function switchToColumnView() {
                window.localStorage.removeItem(defaultView.value);
                router.push({
                    name: 'billings.index',
                    params: {nursery: props.nursery.id},
                    query: routeParam.query,
                });
            }

            function goPreviousMonth() {
                pushQueryString('from', currentMonth.value.subMonths(1).startOfMonth().toTimestamp());
            }

            function goNextMonth() {
                pushQueryString('from', currentMonth.value.addMonths(1).startOfMonth().toTimestamp());
            }

            return {
                useFormatNumber,
                can,
                currentInvoice,
                currentMonth,
                defaultView,
                deleteInvoice,
                exportLoading,
                filteredInvoices,
                getInvoices,
                getXlsFile,
                goNextMonth,
                goPreviousMonth,
                invoices,
                invoicesLegaciesWithModels,
                loading,
                order,
                orderBy,
                paginator,
                tagFinderQuery,
                selectedTagsModels,
                withoutTags,
                selectedStatus,
                selectMonth,
                show,
                switchToColumnView,
                totalAmount,
            };
        },
        computed: {
            CurrencySymbolToIsoCode() {
                return CurrencySymbolToIsoCode;
            },
        },
    });
</script>
