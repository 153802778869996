<script lang="ts" setup>
    import {ref, watch} from 'vue';
    import _debounce from 'lodash-es/debounce';
    import {Epoch} from '@meekohq/lumos';
    import type MDropdown from '@/modules/meeko-ui/components/MDropdown.vue';

    const props = defineProps<{
        daySeparator: 'day' | 'halfday';
        halfdayTime: string;
    }>();

    const emit = defineEmits<{
        (e: 'update:halfdayTime', value: string): void;
        (e: 'update:daySeparator', value: 'day' | 'halfday'): void;
    }>();

    const dropdownHalfDay = ref<InstanceType<typeof MDropdown>>();
    const halfdayTimeEpoch = ref(Epoch.parse(props.halfdayTime, 'HH:mm'));

    const debounceUpdateHalfdayTime = _debounce((time: string) => {
        emit('update:halfdayTime', time);
    }, 1000, {trailing: true});

    function updateDaySeparator(value: 'day' | 'halfday') {
        emit('update:daySeparator', value);
        if (value === 'halfday') {
            dropdownHalfDay.value?.show();
        }
    }

    watch(halfdayTimeEpoch, value => debounceUpdateHalfdayTime(value.toFormat('HH:mm')));
</script>

<template>
    <div>
        <CButtonGroup>
            <CButton
                :variant="daySeparator === 'day' ? 'primary' : 'light'"
                @click="updateDaySeparator('day')"
            >
                {{ __('common:day_female_one') }}
            </CButton>
            <CButton
                :variant="daySeparator === 'halfday' ? 'primary' : 'light'"
                @click="updateDaySeparator('halfday')"
            >
                {{ __('common:halfday_one') }}
            </CButton>
        </CButtonGroup>
        <CDropdown
            ref="dropdownHalfDay"
            class="tw-max-w-80"
            legacy-mode
        >
            <div>
                <CTooltip>
                    <CLabel>
                        <i
                            aria-hidden="true"
                            class="fad fa-circle-info tw-text-blue-500 tw-mr-1"
                        />
                        {{ __('planning:halfday_time') }}
                    </CLabel>
                    <template #content>
                        {{ __('planning:halfday_time_tips') }}
                    </template>
                </CTooltip>
                <MTimePicker
                    v-model="halfdayTimeEpoch"
                    class="tw-w-full"
                />
            </div>
        </CDropdown>
    </div>
</template>

