import {Model} from '@meekohq/lumos';
import EventTypeModel from '@/modules/human-resources/models/EventTypeModel';

export default class CalendarModel extends Model {

    public type = 'hr/calendar/calendars';

    public attributes: {
        id: string;
        account_id: string | undefined;
        internal_id: string | undefined;
        name: string | undefined;
        archived_at: string | undefined;
    } = {
            id: this.uuid(),
            account_id: undefined,
            internal_id: undefined,
            name: undefined,
            archived_at: undefined,
        };

    get factor() {
        switch (this.attributes.internal_id) {
            case calendarTypes.absence:
            case calendarTypes.pause:
                return -1;
            case calendarTypes.presence :
            case calendarTypes.training :
                return 1;
            case calendarTypes.other :
                return 0;
            default:
                break;
        }
    }

    eventTypes() {
        return this.hasMany('eventTypes', EventTypeModel, 'calendar_id');
    }
}

export enum calendarTypes {
    absence = '683302b3-c1fd-49e1-b823-21e2383b3677',
    other = '92717e51-83ac-47e3-a53a-aa836889ce00',
    presence = 'f90c5da0-42ba-457f-9737-ee63f63cddfc',
    pause = '7b2f07ee-a6dc-4e4d-8027-8ac4b7f8ffd4',
    training = 'c36db6cd-8909-4459-93c5-d8f74dceb14e'
}
