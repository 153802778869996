<template>
    <div
        class="TagPill"
        :class="{'TagPill--shadowed': tag.attributes.icon_id, 'TagPill--sm': size === 'sm'}"
        :style="baseStyle"
    >
        <FontAwesomeIcon
            v-if="iconPath"
            :icon="iconPath"
            :style="iconStyle"
        />
    </div>
</template>

<script lang="ts">
    import DataStore from '@/modules/legacy/store/data.store';
    import type {PropType} from 'vue';
    import {computed, defineComponent} from 'vue';
    import type TagModel from '@/modules/tag/models/TagModel';

    export default defineComponent({
        components: {},
        props: {
            tag: {
                type: Object as PropType<TagModel>,
                required: true,
            },
            size: {
                type: String,
                default: 'base',
            },
            more: {
                type: Boolean,
                default: false,
            },
        },
        setup(props) {
            const iconPath = computed(() => {
                const path = DataStore.iconPath(props.tag.attributes.icon_id as string);

                if (!path) {
                    return undefined;
                }

                const provider = path.attributes.provider?.replace('fontawesome-', 'fa-');

                return `${provider} ${path.attributes.path}`;
            });

            const baseStyle = computed(() => {
                if (props.tag.attributes.icon_id) {
                    return {
                        backgroundColor: '#FFFFFF',
                    };
                }

                return {
                    backgroundColor: props.tag.attributes.color,
                };
            });

            const iconStyle = computed(() => {
                if (props.tag.attributes.color) {
                    return {
                        color: props.tag.attributes.color,
                    };
                }
            });

            return {
                iconPath,
                baseStyle,
                iconStyle,
            };
        },
    });
</script>

<style lang="scss" scoped>
    .TagPill {
        @apply tw-flex tw-justify-center tw-items-center;
        @apply tw-aspect-square tw-rounded-lg tw-shrink-0;
        width: 22px;
        font-size: 13px;
        @apply tw-shadow-sm;
    }

    .TagPill--shadowed {
        @apply tw-ring-1 tw-ring-blue-800/20;
    }

    .TagPill--sm {
        @apply tw-rounded-md;
        width: 20px;
        font-size: 11px;
    }
</style>
