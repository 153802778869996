import {toNumber} from 'lodash-es';
import type {SerializerType} from '@/modules/app/composables/useMagicFilter';

function toStorableValue(value: any) {
    if (value === 'null') {
        return null;
    }

    return toNumber(value);
}

export default {
    read: async (value: string) => {
        const [min, max] = value.split(',');

        return {
            min: toStorableValue(min),
            max: toStorableValue(max),
        };
    },
    write: (value: { min: number | null, max: number | null }) => {
        return value.min + ',' + value.max;
    },
} as SerializerType;
