import type {FilterOptions, FilterType} from '@/modules/app/composables/useMagicFilter';
import useMagicFilter, {setupFilter} from '@/modules/app/composables/useMagicFilter';
import type {Model} from '@meekohq/lumos';
import type {ComputedRef, Ref} from 'vue';
import ModelSerializer from '@/modules/magic-index/utils/serializers/ModelSerializer';
import {merge} from 'lodash-es';
import {StoragePath} from '@/modules/magic-index/utils/serializers/StoragePath';
import NumberSerializer from '@/modules/magic-index/utils/serializers/NumberSerializer';

type Filters =
    | 'search'
    | 'tags'
    | 'page';

export interface OutputType {
    searchFilter: FilterType<string>,
    tagsFilter: FilterType<Model[]>,
    pageFilter: FilterType<number>,
    activeCount: Ref<number>,
    isReady: ComputedRef<boolean>,
    reset: () => void,
    allFilters: Ref<any[]>
}

export default function useDocumentsFiltersStore(options: Record<Filters, FilterOptions>): OutputType {
    const defaultOptions: Record<Filters, FilterOptions> = {
        search: {
            defaultValue: '',
            debounceDelay: 500,
        },
        tags: {
            defaultValue: [],
            serializer: ModelSerializer,
            storagePath: StoragePath.filtersDocumentsIndexTags,
        },
        page: {
            defaultValue: 1,
            serializer: NumberSerializer,
            queryString: 'p',
        },
    };

    const optionsWithDefaults = merge({}, defaultOptions, options);

    const searchFilter = setupFilter<string>(optionsWithDefaults.search);
    const tagsFilter = setupFilter<Model[]>(optionsWithDefaults.tags);
    const pageFilter = setupFilter<number>(optionsWithDefaults.page);

    const {activeCount, isReady, reset, allFilters} = useMagicFilter(
        [
            searchFilter,
            tagsFilter,
        ],
        pageFilter,
    );

    return {
        searchFilter,
        tagsFilter,
        pageFilter,
        activeCount,
        isReady,
        reset,
        allFilters,
    };
}
