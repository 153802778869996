<template>
    <i
        aria-hidden="true"
        :class="correctPath"
    />
</template>

<script lang="ts">
    import type {ComputedRef} from 'vue';
    import {computed, defineComponent} from 'vue';

    export default defineComponent({
        components: {},
        props: {
            color: {type: String, default: '#2196f3'},
            size: {type: String, default: 'base'},
            padding: {type: String, default: ''},
            path: {type: String, default: ''},
            category: {type: String, default: ''},
            description: {type: String, default: ''},
            provider: {type: String, default: ''},
        },
        setup(props) {
            const correctPath = useCorrectPath(props);

            return {
                correctPath,
            };
        },
    });

    function useCorrectPath(props): ComputedRef<string> {
        return computed(() => {
            const fontAwesomePath = props.provider.includes('fontawesome');

            if (fontAwesomePath) {
                const pathLetter = props.provider.split('-')[1].charAt(0);

                return `fa${pathLetter} ${props.path}`;
            }

            return '';
        });
    }

</script>

