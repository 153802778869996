import {Model} from '@meekohq/lumos';
import PhotoModel from '@/modules/photo/models/PhotoModel';
import KidModel from '@/modules/family/models/KidModel';

export default class PhotoKidPivot extends Model {
    public type = 'media/photos_kids_pivot';

    public attributes: {
        id: string | undefined;
        account_id: string | undefined;
        photo_id: string | undefined;
        kid_id: string | undefined;
    } = {
            id: undefined,
            account_id: undefined,
            photo_id: undefined,
            kid_id: undefined,
        };

    photo() {
        return this.belongsTo('photo', PhotoModel, 'photo_id');
    }

    kid() {
        return this.belongsTo('kid', KidModel, 'kid_id');
    }
}
