<template>
    <div
        class="MagicIndexSelector"
        :class="{
            'MagicIndexSelector--visible': visible,
            'MagicIndexSelector--selected': selected,
        }"
    >
        <div class="MagicIndexSelector__shape-wrapper">
            <div class="MagicIndexSelector__shape"/>
            <FontAwesomeIcon
                class="MagicIndexSelector__check-icon"
                icon="fa-kit fa-m-check"
            />
        </div>
    </div>
</template>

<script lang="ts">
    import {defineComponent} from 'vue';

    export default defineComponent({
        props: {
            selected: {
                type: Boolean,
                default: false,
            },
            visible: {
                type: Boolean,
                default: false,
            },
        },
    });
</script>

<style lang="scss" scoped>
    .MagicIndexSelector {
        @apply tw-relative;
        @apply tw-cursor-pointer;
        @apply tw-w-8 tw-h-8 tw-opacity-0;
        @apply tw-transition-all tw-ease-in-out;
        border-bottom-right-radius: 100%;
    }

    .MagicIndexSelector:hover {
        .MagicIndexSelector__check-icon {
            @apply tw-text-gray-600;
        }

        .MagicIndexSelector__shape {
            @apply tw-bg-gray-400;
        }

        &.MagicIndexSelector--selected {
            .MagicIndexSelector__shape {
                background-color: theme('colors.green.600') !important;
            }
        }
    }

    .MagicIndexSelector__shape-wrapper {
        @apply tw-absolute tw-top-0 tw-left-0 tw-transition-all tw-scale-50;
    }

    .MagicIndexSelector--visible {
        @apply tw-opacity-100;

        .MagicIndexSelector__shape-wrapper {
            @apply tw-scale-100;
        }
    }

    .MagicIndexSelector--selected {
        @apply tw-opacity-100;

        .MagicIndexSelector__check-icon {
            color: white !important;
        }

        .MagicIndexSelector__shape {
            background-color: theme('colors.green.500') !important;
        }
    }

    .MagicIndexSelector__check-icon {
        @apply tw-transition-all;
        @apply tw-text-gray-400 tw-text-sm;
        @apply tw-absolute tw-top-0.5 tw-left-1;
    }

    .MagicIndexSelector__shape {
        @apply tw-transition-all;
        @apply tw-bg-gray-300;
        @apply tw-relative;
        filter: drop-shadow(-2px 2px 2px rgb(0 0 0 / .1));
    }

    .MagicIndexSelector__shape:before,
    .MagicIndexSelector__shape:after {
        content: "";
        @apply tw-absolute tw-bg-inherit;
    }

    .MagicIndexSelector__shape,
    .MagicIndexSelector__shape:before,
    .MagicIndexSelector__shape:after {
        @apply tw-w-4 tw-aspect-square;
    }

    .MagicIndexSelector__shape {
        transform: rotate(-90deg) skewX(0deg) scale(1, 1);
        @apply tw-rounded-tr-md;
    }

    .MagicIndexSelector__shape:before {
        transform: rotate(-135deg) skewX(-45deg) scale(1.414, 0.707) translate(0, -50%);
        @apply tw-rounded-tr-md;
    }

    .MagicIndexSelector__shape:after {
        transform: rotate(135deg) skewY(-45deg) scale(0.707, 1.414) translate(50%);
        @apply tw-rounded-tr-md;
    }
</style>
