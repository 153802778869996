<template>
    <CInput v-model="amount"/>
</template>

<script lang="ts">
    import {defineComponent, ref, watch} from 'vue';
    import NumberFormatter from '@/modules/app/utils/NumberFormatter';
    import {debounce} from 'lodash-es';

    export default defineComponent({
        components: {},
        props: {
            value: {
                type: Number,
                default: 0,
            },
            minimumFractionDigits: {
                type: Number,
                default: 0,
            },
            maximumFractionDigits: {
                type: Number,
                default: undefined,
            },
        },
        setup(props, {emit}) {
            const formatter = new NumberFormatter({
                minimumFractionDigits: props.minimumFractionDigits,
                maximumFractionDigits: props.maximumFractionDigits,
            });

            const amount = ref<string>(formatter.parse(props.value).asString);

            const updateAmount = debounce((value: string) => {
                amount.value = value;
            }, 2000);

            watch(amount, value => {
                const parsed = formatter.parse(value);

                if (parsed.isValid && parsed.asNumber !== props.value) {
                    emit('input', parsed.asNumber);
                }

                if (parsed.isValid) {
                    updateAmount(parsed.asString);
                }
            }, {immediate: true});

            return {
                amount,
            };
        },
    });
</script>
