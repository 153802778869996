<template>
    <div>
        <div class="form-group">
            <div class="row">
                <div class="col">
                    <div class="form-group">
                        <label class="label">
                            {{ __('common:beginning') }}*
                        </label>
                        <MDatePicker
                            v-model="contract.started_at"
                            class="tw-w-full"
                            :disabled="disabled || !$can('update', 'kids_contracts')"
                            format="yyyy-MM-dd"
                        />
                    </div>
                    <div class="form-group">
                        <label class="label">
                            {{ __('common:end') }}*
                        </label>
                        <MDatePicker
                            v-model="contract.ended_at"
                            class="tw-w-full"
                            :disabled="disabled || !$can('update', 'kids_contracts')"
                            format="yyyy-MM-dd"
                        />
                    </div>
                    <div
                        v-if="contract.broked_at"
                        class="form-group"
                    >
                        <label class="label">
                            {{ __('common:broken') }}
                        </label>
                        <MDatePicker
                            v-model="contract.broked_at"
                            class="tw-w-full"
                            format="yyyy-MM-dd"
                        />
                        <small class="small"><i class="fad fa-info-circle mr-1"/>
                            {{ __('family_contract:let_empty_to_cancel_the_break') }}
                        </small>
                    </div>
                </div>
                <div class="col">
                    <div class="form-group">
                        <label
                            class="label"
                            for="first_family_member_id"
                        >
                            <i
                                aria-hidden="true"
                                class="fa fa-user mr-2 text-primary"
                            />
                            {{ __('common:first_parent') }}
                        </label>
                        <select
                            id="first_family_member_id"
                            v-model="contract.first_family_member_id"
                            class="form-control"
                            :disabled="!$can('update', 'kids_contracts')"
                        >
                            <option :value="null">
                                {{ __('common:none') }}
                            </option>
                            <option
                                v-for="(familyMember, i) in kid.familyMembers"
                                :key="'fam1-' + i"
                                :value="familyMember.id"
                            >
                                {{ familyMember.first_name }} {{ familyMember.last_name }} ({{ role(familyMember.role, familyMember.gender) }})
                            </option>
                        </select>
                    </div>
                    <div class="form-group">
                        <label
                            class="label"
                            for="second_family_member_id"
                        >
                            <i
                                aria-hidden="true"
                                class="fa fa-user mr-2 text-primary"
                            />
                            {{ __('common:second_parent') }}
                        </label>
                        <select
                            id="second_family_member_id"
                            v-model="contract.second_family_member_id"
                            class="form-control"
                            :disabled="!$can('update', 'kids_contracts')"
                        >
                            <option :value="null">
                                {{ __('common:none') }}
                            </option>
                            <option
                                v-for="(familyMember, i) in kid.familyMembers"
                                :key="'fam2-' + i"
                                :value="familyMember.id"
                            >
                                {{ familyMember.first_name }} {{ familyMember.last_name }} ({{ role(familyMember.role, familyMember.gender) }})
                            </option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="row mt-2">
                <div class="form-group col-12 col-md-6">
                    <label class="label">
                        {{ __('common:contract_type') }}
                    </label>
                    <div class="row mt-1">
                        <div class="col-12">
                            <MButton
                                v-if="disabled"
                                disabled
                                variant="primary"
                            >
                                <template v-if="contract.type === 'occasional'">
                                    {{ __('family_contract:occasional_contract') }}
                                </template>
                                <template v-else>
                                    {{ __('family_contract:regular_contract') }}
                                </template>
                            </MButton>
                            <MButtonSelect
                                v-else
                                v-model="contract.type"
                                :options="[{ text: __('common:recurrent'), value: 'recurrent' },{ text: __('common:occasional_other'), value: 'occasional' }]"
                            />
                        </div>
                        <div class="col-12 mt-3">
                            <CCheckbox
                                v-model="contract.company"
                                :disabled="!$can('update', 'kids_contracts')"
                            >
                                <div class="tw-flex tw-items-center tw-pt-0.5">
                                    <div>{{ __('common:company_contract') }}</div>
                                    <MHelpTooltip class="tw-ml-2">
                                        <template #default>
                                            <div class="tw-mb-2">
                                                {{ __('family_contract:checkbox_activates_a_new_tab_in_contract_to_manage_the_company_part_of_the_contract_colon') }}
                                            </div>
                                            <div v-html="__('family_contract:family_get_reduced_price_my_organization_get_benefits_and_annual_subscription_reimbursed')"/>
                                        </template>
                                    </MHelpTooltip>
                                </div>
                            </CCheckbox>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-6">
                    <label class="label">
                        {{ __('common:note_one') }}
                    </label>
                    <textarea
                        v-model="contract.note"
                        class="form-control"
                        :disabled="!$can('update', 'kids_contracts')"
                        :placeholder="__('common:note_one')"
                        rows="2"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import __ from '@/modules/app/utils/i18n-facade';

    export default {
        props: ['contract', 'kid', 'disabled', 'nursery'],
        data: () => ({}),
        watch: {
            contract: {
                handler() {
                    if (this.nursery.docTemplates.length && !this.contract.contract_template_id) {
                        this.contract.contract_template_id = this.nursery.docTemplates[0].id;
                    }
                },
                deep: true,
            },
        },
        methods: {
            role(role, gender) {
                if (!role || !gender) {
                    return __('common:other_one');
                }

                switch (role) {
                    case 'legal_custodian_female':
                    case 'legal_custodian_male':
                        return __('common:relationship.legal_custodian', {context: gender});
                    case 'mother':
                    case 'father':
                        return __('common:relationship.parent', {context: gender});
                    case 'sister':
                    case 'brother':
                        return __('common:relationship.sibling', {context: gender});
                    case 'aunt':
                    case 'uncle':
                        return __('common:relationship.pibling', {context: gender});
                    case 'grandparent':
                        return __('common:relationship.grandparent');
                    case 'nanny':
                        return __('common:relationship.nanny');
                    case 'stepmother':
                    case 'stepfather':
                        return __('common:relationship.parent_in_law', {context: gender});
                    case 'cousin':
                        return __('common:relationship.cousin', {context: gender});
                    default:
                        return __('common:other_one');

                }
            },
        },
    };
</script>
