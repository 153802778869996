import {Model} from '@meekohq/lumos';
import OrganizationModel from '@/modules/organization/models/OrganizationModel';
import UserModel from '@/modules/user/models/UserModel';

export default class OrganizationUserPivot extends Model {
    public type = 'organization/organizations_users_pivot';

    public attributes: {
        id: string;
        account_id: string | undefined;
        organization_id: string | undefined;
        permissions: string[] | undefined;
    } = {
            id: this.uuid(),
            account_id: undefined,
            organization_id: undefined,
            permissions: undefined,
        };

    organization() {
        return this.belongsTo('organization', OrganizationModel, 'organization_id');
    }

    user() {
        return this.belongsTo('user', UserModel, 'user_id');
    }

}
