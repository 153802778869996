<template>
    <CVStack gap="4">
        <div v-if="customer.has_subscription">
            <CHeading>{{ __('subscription:your_subscription_meeko') }}</CHeading>
            <CVStack gap="2">
                <div>
                    {{ __('subscription:plan_type_colon') }}
                    <CBadge
                        v-if="customer.plan === 'monthly-99-2018'"
                        variant="primary"
                    >
                        {{ __('subscription:monthly_plan') }}
                    </CBadge>
                    <CBadge
                        v-else-if="customer.plan === 'yearly-1188-2018'"
                        variant="primary"
                    >
                        {{ __('subscription:yearly_plan') }}
                    </CBadge>
                </div>
                <div>
                    {{ __('subscription:state_colon') }}
                    <CBadge
                        v-if="customer.subscription_status === 'active' && !customer.on_grace_period"
                        variant="success"
                    >
                        {{ __('subscription:active') }}
                    </CBadge>
                    <template v-if="customer.subscription_status === 'active' && customer.on_grace_period">
                        <CBadge
                            class="tw-mr-1"
                            variant="danger"
                        >
                            {{ __('subscription:renewal_canceled') }}
                        </CBadge>
                        <CBadge>{{ __('subscription:expires_on_date', {date: Epoch.fromTimestamp(customer.ends_at).toLocaleString(Epoch.presets.DATE_FULL)}) }}</CBadge>
                    </template>
                    <CBadge v-if="customer.subscription_status === 'canceled'">
                        {{ __('subscription:canceled_male') }}
                    </CBadge>
                    <CBadge
                        v-if="customer.subscription_status === 'incomplete'"
                        variant="danger"
                    >
                        {{ __('common:unpaid_one') }}
                    </CBadge>
                    <CBadge
                        v-if="customer.subscription_status === 'unpaid'"
                        variant="danger"
                    >
                        {{ __('common:unpaid_one') }}
                    </CBadge>
                    <CBadge
                        v-if="customer.subscription_status === 'past_due'"
                        variant="danger"
                    >
                        {{ __('subscription:past_due') }}
                    </CBadge>
                    <CBadge
                        v-if="customer.subscription_status === 'incomplete_expired'"
                        variant="danger"
                    >
                        {{ __('subscription:expired') }}
                    </CBadge>
                    <CBadge
                        v-if="customer.subscription_status === 'trialing'"
                        variant="success"
                    >
                        {{ __('subscription:trial_period') }}
                    </CBadge>
                </div>
                <!-- On utilise pas ce bouton pour le moment -->
                <!-- <div>
                    <CButton
                        v-if="customer.subscription_status === 'active' && customer.on_grace_period"
                        :loading="loader.isLoading('resume')"
                        size="lg"
                        variant="primary"
                        @click="resume"
                    >
                        {{ __('subscription:reactivate_subscription') }}
                    </CButton>
                </div> -->
            </CVStack>
        </div>
        <DefaultPlan
            v-else
            class="tw-self-center"
            :customer="customer"
            :nursery="nursery"
        />
    </CVStack>
</template>

<script lang="ts">
    import useApi from '@/modules/app/composables/useApi';
    import moment from 'moment';
    import route from '@/modules/legacy/libs/ziggy';
    import {Epoch} from '@meekohq/lumos';
    import __ from '@/modules/app/utils/i18n-facade';
    import DefaultPlan from '@/modules/subscription/components/Plan/DefaultPlan.vue';
    import swal from 'sweetalert2/dist/sweetalert2.js';
    import Loader from '@/modules/legacy/helpers/loader.helper';
    import {defineComponent, reactive, ref} from 'vue';

    const loader = reactive(new Loader);

    export default defineComponent({
        components: {
            DefaultPlan,
        },
        props: {
            customer: {type: Object, required: true},
            nursery: {type: Object, required: true},
        },
        setup(props, {emit}) {
            const resume = function() {
                loader.start('resume');
                useApi().legacy.post(route('customers.plans.resume', {
                    customer: props.customer.id,
                })).then(response => {
                    emit('customerUpdated', response.data);
                    swal({
                        title: __('subscription:subscription_reactivated'),
                        type: 'success',
                        showCloseButton: true,
                        showCancelButton: false,
                        confirmButtonClass: 'btn btn-primary mr-2',
                        confirmButtonText: __('common:actions.close'),
                        buttonsStyling: false,
                    });
                }).then(() => {
                    loader.stop('resume');
                });
            };

            return {
                Epoch,
                loader,
                moment: ref(moment),
                resume,
            };
        },
    });
</script>
