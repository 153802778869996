import {ServiceProvider} from '@meekohq/lumos';
import DocumentModel from '@/modules/document/models/DocumentModel';
import DocumentPivot from '@/modules/document/models/DocumentPivot';
import DocumentOrganizationPivot from '@/modules/document/models/DocumentOrganizationPivot';

export default class DocumentsServiceProvider extends ServiceProvider {
    public boot() {
        this.loadModelsFrom([
            DocumentModel,
            DocumentPivot,
            DocumentOrganizationPivot,
        ]);
    }
}
