<template>
    <div>
        <SegmentBar
            :halfday-display="halfdayDisplay"
            :hide-hours="hideHours"
            :hide-tooltip="hideTooltip"
            :loading="loading"
            :segments="visiblesSupervisions"
            :styling-segment-fn="style"
            :tooltip-text-fn="tooltipText"
        />
    </div>
</template>

<script lang="ts">
    import type {PropType} from 'vue';
    import {computed, defineComponent} from 'vue';
    import type OrganizationModel from '@/modules/organization/models/OrganizationModel';
    import SegmentBar from '@/modules/planning/components/simulation/Summary/SegmentBar.vue';
    import type {SupervisionInterface} from '@/modules/planning/components/simulation/Summary/SummaryTypes';
    import useSegmentSizing from '@/modules/planning/composables/core/useSegmentSizing';
    import useJobList from '@/modules/human-resources/composables/job/useJobList';
    import __ from '@/modules/app/utils/i18n-facade';
    import _maxBy from 'lodash-es/maxBy';
    import _minBy from 'lodash-es/minBy';
    import moment from 'moment';

    export default defineComponent({
        components: {SegmentBar},
        props: {
            loading: {
                type: Boolean,
                default: false,
            },
            supervisions: {
                type: Array as PropType<SupervisionInterface[]>,
                required: true,
            },
            organization: {
                type: Object as PropType<OrganizationModel>,
                required: true,
            },
            opening: {
                type: String,
                required: true,
            },
            closing: {
                type: String,
                required: true,
            },
            hideTooltip: {
                type: Boolean,
                default: false,
            },
            hideHours: {
                type: Boolean,
                default: true,
            },
            halfdayDisplay: {
                type: Boolean,
                default: false,
            },
        },
        setup(props) {
            const supervisionDate = computed(() => moment.unix(props.supervisions[0]?.started_at));
            const {unixToPercent} = useSegmentSizing();

            // Get max staffs necessary for the day
            const maxStaffs = computed(() => {
                const maxSupervision = _maxBy(props.supervisions, 'count');
                const minSupervision = _minBy(props.supervisions, 'count');

                if (maxSupervision) {
                    const max = maxSupervision.count;
                    if (minSupervision) {
                        const min = Math.abs(minSupervision.count);
                        if (max > min) {
                            return max;
                        } else if (max < min) {
                            return min;
                        } else {
                            return max;
                        }
                    }

                    return max;
                }

                return 0;
            });

            const {jobs} = useJobList();

            // Get visible supervision segments
            const visiblesSupervisions = computed(() => props.supervisions.map(supervision => {
                const segmentLeftPercent = supervision.segmentLeftPercent ?? unixToPercent(supervision.started_at, supervisionDate.value, props.opening, props.closing, true);
                const segmentRightPercent = supervision.segmentRightPercent ?? (100 - unixToPercent(supervision.ended_at, supervisionDate.value, props.opening, props.closing, true));

                return {
                    ...supervision,
                    segmentRightPercent,
                    segmentLeftPercent,
                };
            }).filter(supervision => {
                if (supervision.segmentLeftPercent === 0) {
                    return supervision.segmentRightPercent < 100;
                } else if (supervision.segmentLeftPercent === 100) {
                    return supervision.segmentRightPercent > 0;
                } else {
                    return true;
                }
            }));

            // Generate style of segment bar for specific supervision
            function style(supervision: SupervisionInterface) {
                let color: string;
                let opacity = 0;

                if (maxStaffs.value) {
                    opacity = (Math.abs(supervision.count) * 100) / maxStaffs.value;
                }

                if (supervision.count < 0) {
                    color = 'rgba(252,13,28,' + opacity / 100 + ')';
                } else {
                    color = 'rgba(0,122,255,' + opacity / 100 + ')';
                }

                return {
                    'left': supervision.segmentLeftPercent.toFixed(2) + '%',
                    'right': supervision.segmentRightPercent.toFixed(2) + '%',
                    'background-color': color,
                };
            }

            function tooltipText(supervision: SupervisionInterface) {
                let text = '';

                if (supervision && supervision.anomalies && supervision.anomalies.length) {
                    const extra = supervision.anomalies.filter(item => item.count > 0);
                    if (extra.length) {

                        text += __('planning:additional_staff_colon') + '<br>';
                        extra.forEach(item => {
                            text += `${getJobName(item.job_id, Math.abs(item.count))} : ${Math.abs(item.count)}<br>`;
                        });
                    }

                    const missing = supervision.anomalies.filter(item => item.count < 0);
                    if (missing.length) {
                        text += __('planning:missing_staff_colon') + '<br>';
                        missing.forEach(item => {
                            text += `${getJobName(item.job_id, Math.abs(item.count))} : ${Math.abs(item.count)}<br>`;
                        });
                    }
                }

                return text;
            }

            function getJobName(jobId: number | null, count: number) {
                if (jobId) {
                    const job = jobs.value.find(job => job.getKey() === jobId);
                    if (job) {
                        return job.attributes.name;
                    }
                }

                return __('common:employee', {count});
            }

            return {
                supervisionDate,
                style,
                tooltipText,
                visiblesSupervisions,
            };
        },
    });
</script>
