<script setup lang="ts">
    import type {PopoverType} from '@/modules/app/composables/usePopover';
    import usePopover from '@/modules/app/composables/usePopover';
    import type {PropType} from 'vue';
    import type {PopoverElement} from '@/modules/meeko-ui/components/CPopover.vue';

    const props = defineProps({
        popover: {
            type: Object as PropType<PopoverType>,
            default: () => usePopover(),
            required: false,
        },
        legacyMode: {
            type: Boolean,
            default: false,
        },
        reference: {type: [] as PropType<PopoverElement>, default: undefined},
        /**
         * @values top-start, top, top-end, bottom-start, bottom, bottom-end, left-start, left, left-end, right-start, right, right-end
         */
        placement: {type: String, default: undefined},
        overlay: {type: Boolean, default: false},
        contentClass: {type: [String, Array, Object], default: undefined},
        preventHideOnClick: {type: Boolean as PropType<boolean>, default: false},
        hoverable: {type: Boolean, default: false},
        clickable: {type: Boolean, default: true},
        stopPropagation: {type: Boolean, default: false},
        preventDefault: {type: Boolean, default: false},
        // Legacy props
        wrapperClass: {type: [String, Array, Object], default: undefined},
        noPadding: {type: Boolean, default: false},
        arrow: {type: Boolean, default: true},
    });

    // Add compatibility with old CDropdown
    const emit = defineEmits(['shown', 'hidden', 'clickOutside']);

    props.popover.bus.on('shown', () => {
        emit('shown');
    });

    props.popover.bus.on('hidden', () => {
        emit('hidden');
    });

    props.popover.bus.on('clickOutside', () => {
        emit('clickOutside');
    });

    defineExpose({
        show: props.popover.show,
        hide: props.popover.hide,
        toggle: props.popover.toggle,
    });
</script>

<template>
    <CPopover
        :arrow="arrow"
        class="MDropdown"
        :class="[
            {
                'tw-block tw-absolute': legacyMode
            },
            legacyMode ? wrapperClass : undefined
        ]"
        :clickable="clickable"
        :content-class="contentClass"
        :hoverable="hoverable"
        :no-padding="noPadding"
        :overlay="overlay"
        :placement="placement"
        :popover="popover"
        :prevent-default="preventDefault"
        :prevent-hide-on-click="preventHideOnClick"
        :reference="reference"
        :stop-propagation="stopPropagation"
        variant="dropdown"
    >
        <slot v-if="!legacyMode"/>
        <template #content>
            <slot v-if="legacyMode"/>
            <slot
                v-else
                name="content"
            />
        </template>
    </CPopover>
</template>
