<template>
    <SimplePageTemplate>
        <template #title>
            <MPageTitle variant="billing">
                {{ __('app:menu.customers') }}
            </MPageTitle>
        </template>
        <template #actions>
            <CustomersAccountingExport v-if="showAccountingExport"/>
            <CButton
                class="md:tw-ml-auto"
                variant="light"
                @click="showTaxCertificatesModal = true"
            >
                <FontAwesomeIcon
                    class="tw-text-blue-500 tw-mr-2"
                    icon="fad fa-paper-plane"
                />
                {{ __('common:tax_certificate_other') }}
            </CButton>
            <TaxCertificatesModal
                v-if="showTaxCertificatesModal"
                @hide="showTaxCertificatesModal = false"
            />
            <CButton
                :disable="!tenantModel"
                icon-plus
                variant="primary"
                @click="showCreateCustomerWithAddressModal = true"
            >
                {{ __('billing_customer:add_customer_account') }}
            </CButton>
            <CustomerModalCreateWithAddress
                v-if="showCreateCustomerWithAddressModal"
                ref="createCustomerWithAddressModal"
                :tenant-model="tenantModel"
                @created="onCustomerCreated"
                @hide="showCreateCustomerWithAddressModal = false"
            />
        </template>
        <template #content>
            <MagicIndex
                v-if="filtersStore.isReady.value"
                :filter-count="filtersStore.activeCount.value"
                :loading="isLoading"
                :paginator="paginator"
                :router-link-fn="customerRouterLinkCallback"
                variant="billing"
                with-mass-select
                @change="setPage"
                @reset-filters="filtersStore.reset"
            >
                <template #mass-select-actions="{massSelect}">
                    <CButton
                        class="tw-text-red-500"
                        size="sm"
                        variant="link"
                        @click="archiveCustomerQuery(massSelect)"
                    >
                        <template #left-icons>
                            <FontAwesomeIcon
                                class="tw-text-red-500"
                                icon="fa fa-box-archive"
                            />
                        </template>
                        {{ __('common:actions.archive') }} ({{ massSelect.selectedItemsCount.value }})
                    </CButton>
                    <CButton
                        class="tw-text-red-500"
                        size="sm"
                        variant="link"
                        @click="unarchiveCustomerQuery(massSelect)"
                    >
                        <template #left-icons>
                            <FontAwesomeIcon
                                class="tw-text-red-500"
                                icon="fa fa-box-archive"
                            />
                        </template>
                        {{ __('common:actions.unarchive') }} ({{ massSelect.selectedItemsCount.value }})
                    </CButton>
                </template>
                <template #filters>
                    <CustomersIndexFilters
                        :filters="filtersStore"
                        :show-tenants-filter="showTenantsFilter"
                    />
                </template>
                <template #header>
                    <CustomersListHeader :show-organization="hasForeignOrganization"/>
                </template>
                <template #item="{item, massSelect}">
                    <CustomerListItem
                        :customer="item"
                        :mass-select="massSelect"
                        :show-organization="hasForeignOrganization"
                        :tasks="tasksByCustomerId[item.getKey()]"
                        @archived="onCustomerArchived"
                    />
                </template>
                <template #empty-list>
                    {{ __('common:empty_result') }}
                </template>
                <template #empty-list-action>
                    {{ __('common:try_modifying_filters_or_adding_data') }}
                </template>
            </MagicIndex>
        </template>
    </SimplePageTemplate>
</template>

<script lang="ts">
    import {computed, defineComponent, ref} from 'vue';
    import {StoragePath} from '@/modules/magic-index/utils/serializers/StoragePath';
    import __ from '@/modules/app/utils/i18n-facade';
    import {until} from '@vueuse/core/index';
    import type {CustomerQueryOptionsType} from '@/modules/cashier/composables/core/customer/usePaginateCustomer';
    import usePaginateCustomer from '@/modules/cashier/composables/core/customer/usePaginateCustomer';
    import useAuth from '@/modules/app/composables/useAuth';
    import useManager from '@/modules/app/composables/useManager';
    import useGetTasks from '@/modules/activity/composables/useGetTasks';
    import useCustomerFiltersStore from '@/modules/cashier/composables/core/customer/useCustomerFiltersStore';
    import useArchiveCustomer from '@/modules/cashier/composables/core/customer/useArchiveCustomer';
    import type {MagicMassSelectType} from '@/modules/app/composables/useMagicMassSelect';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';
    import type TagModel from '@/modules/tag/models/TagModel';
    import CustomerModel from '@/modules/cashier/models/CustomerModel';
    import type TenantModel from '@/modules/cashier/models/TenantModel';
    import CustomerModalCreateWithAddress
        from '@/modules/cashier/components/core/customer/pages/CustomerModalCreateWithAddress.vue';
    import CustomersAccountingExport
        from '@/modules/cashier/components/core/customer/organisms/CustomersAccountingExport.vue';
    import SimplePageTemplate from '@/modules/app/components/templates/SimplePageTemplate.vue';
    import CustomerListItem from '@/modules/cashier/components/core/customer/molecules/CustomersListItem.vue';
    import MagicIndex from '@/modules/magic-index/components/organisms/MagicIndex.vue';
    import TaxCertificatesModal
        from '@/modules/cashier/components/tax/tax-certificate/organisms/TaxCertificatesModal.vue';
    import CustomersIndexFilters from '@/modules/cashier/components/core/customer/organisms/CustomersIndexFilters.vue';
    import {collect} from '@meekohq/lumos';
    import CustomersListHeader from '@/pages/nurseries/[id]/billing/customers/CustomersListHeader.vue';

    export default defineComponent({
        components: {
            CustomersListHeader,
            CustomersIndexFilters,
            CustomerListItem,
            CustomerModalCreateWithAddress,
            CustomersAccountingExport,
            MagicIndex,
            SimplePageTemplate,
            TaxCertificatesModal,
        },
        setup() {
            // Actions modal visibles
            const showCreateCustomerWithAddressModal = ref(false);
            const showTaxCertificatesModal = ref(false);

            const {activeTenant} = useManager();
            const {error} = useNotification();

            const tenantModel = computed(() => activeTenant.value);

            /**
             * Accounting export
             */
            const {user} = useAuth();

            // TODO: Remove computed when beta testing is over
            const showAccountingExport = computed(() => {
                // 5e4854b2-a786-4eeb-b9e2-455553448009 = Meeko
                // 82fec72c-8de0-4c57-9bfc-945679b0fdab = UB4Kids
                // a467e66c-1a87-4e51-a568-1a938e9c0960 = My little crèche
                // 3b235846-ca5e-4812-a831-c98e9845b5e0 = Loona
                // e01c3ccd-1619-4d82-9bb8-525dc1e80e5f = Bulles de crèches
                // 7541d1ad-60b1-4ecf-85a9-33a145335338 = Cap
                // 15be86b2-4623-4e7f-8e58-83600903d942 = Koala Kids
                // e851f575-e014-4ad7-93d6-02564ffe4567 = Les Bout'Chous
                // 157a9ff3-4f89-492f-a366-69b8d48c308b = Sarl Au Fur et à Mesure
                // 24fb17d3-d938-4541-8a20-997a9c1a85e9 = Les Minis Pandas
                // 7582ade3-3a60-4986-a7a7-14a2765c7578 = Irisia
                // deb32955-afe5-47f5-8b7e-185182dc3aae = Bambi Fox
                // dc6ca834-40fc-4cb4-b31d-3a4ed5a2db76 = Tralali
                // ae1b6c7d-29f6-4941-9a51-e0ce40eb22f6 = Tambourin et Castagnettes
                // 54b21507-be96-49ba-ab87-f2389754ec48 = Baby panda
                // 412bc215-eadf-4ec1-a60e-365dacc7583e = Barbouille et Gribouilles
                // 54b21507-be96-49ba-ab87-f2389754ec48 = Les Petites Rousettes
                // bc353761-a1e2-43ff-817c-21dd733d0879 = Ô P'tites Tulipes
                // c260f5ee-f118-45bb-8ca1-74d6b2a0a2de = Laventie
                // 12e46463-12c0-4228-89ec-9330b2129c92 = Ô P'tit Môme
                // 4a36986d-4653-4618-9003-16dd313cfaa0 = Le Monde des Doudous
                // 36764e7d-18fb-4073-b7ad-66a44750546d = Multi-Accueil Les Ecureuils
                // 2b9790be-08e2-4da8-a540-a77aa04e8e15 = Lunazen
                // 60e2bd77-be5e-47f7-b79f-2c8e16fb3a83 = Au Rythme des Frimousses + Au Rythme des Explorateurs
                // ce1cbd9c-99e0-44c8-87dd-a5711e21c667 = Little Pious
                // f05d3c63-a8ca-4cb0-87f3-8aab4d10f5d9 = Crèche Petits Bonheurs
                // 9818d43f-f5be-4ea2-be87-b2b7406ee123 = Bebolito
                // 513bdef6-5f8a-4c28-a3ad-8dfa6a5c87ec = Histoires De Momes
                // 6683d7c7-797c-4601-a0bd-5afb041cf53f = Minido
                // 9897eea3-6b6e-4f22-8417-11e9f8dc97f7 = Bébé Chou
                // 7d38d406-e91c-44ea-ad8a-111ada6a829e = La bande à Bilou
                const accountIds = [
                    '5e4854b2-a786-4eeb-b9e2-455553448009',
                    '82fec72c-8de0-4c57-9bfc-945679b0fdab',
                    'a467e66c-1a87-4e51-a568-1a938e9c0960',
                    '3b235846-ca5e-4812-a831-c98e9845b5e0',
                    'e01c3ccd-1619-4d82-9bb8-525dc1e80e5f',
                    '7541d1ad-60b1-4ecf-85a9-33a145335338',
                    '15be86b2-4623-4e7f-8e58-83600903d942',
                    'e851f575-e014-4ad7-93d6-02564ffe4567',
                    '157a9ff3-4f89-492f-a366-69b8d48c308b',
                    '24fb17d3-d938-4541-8a20-997a9c1a85e9',
                    '7582ade3-3a60-4986-a7a7-14a2765c7578',
                    'deb32955-afe5-47f5-8b7e-185182dc3aae',
                    'dc6ca834-40fc-4cb4-b31d-3a4ed5a2db76',
                    'ae1b6c7d-29f6-4941-9a51-e0ce40eb22f6',
                    '54b21507-be96-49ba-ab87-f2389754ec48',
                    '412bc215-eadf-4ec1-a60e-365dacc7583e',
                    '54b21507-be96-49ba-ab87-f2389754ec48',
                    'bc353761-a1e2-43ff-817c-21dd733d0879',
                    'c260f5ee-f118-45bb-8ca1-74d6b2a0a2de',
                    '12e46463-12c0-4228-89ec-9330b2129c92',
                    '4a36986d-4653-4618-9003-16dd313cfaa0',
                    '36764e7d-18fb-4073-b7ad-66a44750546d',
                    '2b9790be-08e2-4da8-a540-a77aa04e8e15',
                    '60e2bd77-be5e-47f7-b79f-2c8e16fb3a83',
                    'ce1cbd9c-99e0-44c8-87dd-a5711e21c667',
                    'f05d3c63-a8ca-4cb0-87f3-8aab4d10f5d9',
                    '9818d43f-f5be-4ea2-be87-b2b7406ee123',
                    '513bdef6-5f8a-4c28-a3ad-8dfa6a5c87ec',
                    '6683d7c7-797c-4601-a0bd-5afb041cf53f',
                    '9897eea3-6b6e-4f22-8417-11e9f8dc97f7',
                    '7d38d406-e91c-44ea-ad8a-111ada6a829e',
                ];
                const isAccountedAllowed = user.value.attributes.account_id && accountIds.includes(user.value.attributes.account_id);

                return isAccountedAllowed || user.value.releaseChannel.onDev;
            });

            /**
             * Get customers index with filters
             */
            const filtersStore = useCustomerFiltersStore({
                tenants: {
                    storagePath: StoragePath.filtersCustomersIndexTenants,
                },
                tags: {
                    storagePath: StoragePath.filtersCustomersIndexTags,
                },
                withoutTag: {
                    storagePath: StoragePath.filtersCustomersIndexWithout,
                },
                personTypes: {
                    storagePath: StoragePath.filtersCustomersIndexPersonTypes,
                    queryString: 'person-types',
                },
                states: {
                    storagePath: StoragePath.filtersCustomersIndexStates,
                    queryString: 'states',
                },
                includeArchived: {
                    storagePath: StoragePath.filtersCustomersIndexIncludeArchived,
                    queryString: 'include-archived',
                },
                orderBy: {
                    storagePath: StoragePath.filtersCustomersIndexOrderBy,
                    queryString: 'order-by',
                },
                page: {
                    queryString: 'page',
                },
            });

            /**
             * The options, merged with defaults, to pass to the usePaginateCustomer.
             */
            const options = computed<CustomerQueryOptionsType>(() => {
                return {
                    tenants: filtersStore.tenantsFilter.data.value as TenantModel[],
                    tags: filtersStore.tagsFilter.data.value as TagModel[],
                    withoutTag: filtersStore.withoutTagFilter.data.value,
                    personTypes: filtersStore.personTypesFilter.data.value,
                    states: filtersStore.statesFilter.data.value,
                    includeArchived: filtersStore.includeArchivedFilter.data.value,
                    orderBy: filtersStore.orderByFilter.data.value.orderBy,
                    queryDirection: filtersStore.orderByFilter.data.value.queryDirection,
                    page: filtersStore.pageFilter.data.value,
                    with: [new CustomerModel().tags().getRelationName(), new CustomerModel().tenant().getRelationName()],
                };
            });

            const {isLoading, paginator, paginate, watchOptions} = usePaginateCustomer(options);

            // Wait for the isReady to be true before starting to paginate the customers.
            until(filtersStore.isReady).toBe(true).then(() => {
                watchOptions(() => {
                    // Get tasks related to customers to display the tasks badge
                    getTasksRelatedToCustomers(paginator.value?.items().map(customer => customer.getKey()).all())
                        .then(tasksRelatedToCustomers => tasks.value = tasksRelatedToCustomers);
                });

                // If the active organization changes and active organization not already selected, reset the tenants filter.
                if (
                    collect(filtersStore.tenantsFilter.data.value)
                        .where('attributes.id', '==', activeTenant.value.getKey())
                        .isEmpty()
                ) {
                    filtersStore.tenantsFilter.reset();
                }
            });

            const showTenantsFilter = computed(() => user.value.organizations().value().count() > 1);

            /**
             * Set the current page to the given page.
             * @param page
             */
            function setPage(page: number) {
                filtersStore.pageFilter.data.value = page;
            }

            /**
             * Get tasks related to customers
             */
            const {
                tasks,
                tasksGroupByModelId: tasksByCustomerId,
                getTasks: getTasksRelatedToCustomers,
            } = useGetTasks({
                constrainToResourceModel: CustomerModel,
            });

            function customerRouterLinkCallback(customer: CustomerModel) {
                return {
                    name: 'cashier/customers.show.summary', params: {
                        nursery: customer.tenant().value().organizations().value().first()?.getKey() || activeOrganization.value.getKey(),
                        customer: customer.getKey(),
                    },
                };
            }

            /**
             * Mass select actions
             */
            async function archiveCustomerQuery(massSelect: MagicMassSelectType) {
                try {
                    await useArchiveCustomer().archiveByQuery(options.value, massSelect);
                    massSelect.reset();
                    await paginate();
                } catch (e) {
                    error(__('common:errors.generic'));
                }
            }

            async function unarchiveCustomerQuery(massSelect: MagicMassSelectType) {
                try {
                    await useArchiveCustomer().unarchiveByQuery(options.value, massSelect);
                    massSelect.reset();
                    await paginate();
                } catch (e) {
                    error(__('common:errors.generic'));
                }
            }

            /**
             * Hooks to keep data updated on actions
             */
            function onCustomerCreated() {
                paginate();
            }

            function onCustomerArchived(customer: CustomerModel) {
                // Remove the customer from the paginator items if the archived filter is not enabled.
                if (!filtersStore.includeArchivedFilter.data.value) {
                    const customerIndex = paginator.value?.items().search((item: CustomerModel) => {
                        return item.getKey() === customer.getKey();
                    });

                    // Mutate the paginator items to remove the transaction.
                    paginator.value?.items().forget(customerIndex);
                }
            }

            const {activeOrganization} = useManager();

            const hasForeignOrganization = computed(() => {
                return filtersStore.tenantsFilter.data.value.length > 1;
            });

            return {
                hasForeignOrganization,
                archiveCustomerQuery,
                customerRouterLinkCallback,
                filtersStore,
                isLoading,
                onCustomerArchived,
                onCustomerCreated,
                paginator,
                setPage,
                showAccountingExport,
                showCreateCustomerWithAddressModal,
                showTaxCertificatesModal,
                tasksByCustomerId,
                tenantModel,
                showTenantsFilter,
                unarchiveCustomerQuery,
            };
        },
    });

</script>
