<template>
    <SimplePageTemplate>
        <template #title>
            <MPageTitle variant="dashboard">
                {{ __('app:menu.dashboard') }}
            </MPageTitle>
        </template>
        <template #actions>
            <div class="tw-flex tw-flex-row tw-items-center tw-flex-wrap tw-gap-2">
                <MDropdown v-if="$can('read', 'dashboard')">
                    <MButton :label="__('dashboard:choose_filters')">
                        <template #left-icons>
                            <FontAwesomeIcon icon="fas fa-filter"/>
                        </template>
                    </MButton>
                    <template #content>
                        <div class="tw-flex tw-flex-col tw-gap-2">
                            <MMenu
                                class="tw-w-full"
                                :portal="false"
                            >
                                <MMenuButton class="tw-w-full">
                                    <MButton
                                        class="tw-w-full tw-justify-between"
                                        icon-menu
                                        :label="selectedInvoiceType.text"
                                        variant="primary"
                                    />
                                </MMenuButton>
                                <MMenuItems>
                                    <MMenuItem
                                        v-for="(item, i) in invoiceTypeOptions"
                                        :key="'dataOption' + i"
                                        @click="selectedInvoiceType = item"
                                    >
                                        {{ item.text }}
                                    </MMenuItem>
                                </MMenuItems>
                            </MMenu>
                            <MMonthPicker v-model="fromDate"/>
                            <MMonthPicker v-model="toDate"/>
                        </div>
                    </template>
                </MDropdown>
                <CButton
                    v-if="$can('read', 'export')"
                    :label="__('common:actions.export')"
                    @click="openExportsModal"
                >
                    <template #left-icons>
                        <FontAwesomeIcon icon="fa fa-download"/>
                    </template>
                </CButton>
            </div>
        </template>
        <template #content>
            <div
                v-if="$can('read', 'dashboard')"
                class="row"
            >
                <loader
                    v-if="loading"
                    class="text-center w-100"
                    light="true"
                    shadow="false"
                    :title="__('common:loading_in_progress_dots')"
                />
                <template v-else>
                    <div
                        v-if="allowedNurseries && allowedNurseries.length"
                        class="col-12 mb-2"
                    >
                        <sparklines
                            v-if="selectedInvoiceType"
                            :from-date="fromDate.toFormat('yyyy-MM')"
                            :nurseries="allowedNurseries"
                            :nursery="selectedNursery"
                            :selected-invoice-type="selectedInvoiceType.value"
                            :to-date="toDate.toFormat('yyyy-MM')"
                            @nurseryChanged="switchNursery"
                        />
                    </div>

                    <div
                        v-if="canWithNursery('read', 'invoices', selectedNursery)"
                        class="col-12 col-lg-6"
                    >
                        <div class="row">
                            <div class="col-12 mt-3">
                                <revenue-module
                                    :nurseries="allowedNurseries"
                                    :nursery="selectedNursery"
                                />
                            </div>
                            <div class="col-12 mt-3">
                                <revenue-details-module
                                    :nursery="selectedNursery"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-lg-6">
                        <div class="row">
                            <div
                                v-if="canWithNursery('read', 'kids_planning', selectedNursery)"
                                class="col-12 mt-3"
                            >
                                <occupation-module
                                    :nursery="selectedNursery"
                                />
                            </div>
                            <div
                                v-if="canWithNursery('read', 'staffs_planning', selectedNursery)"
                                class="col-12 mt-3"
                            >
                                <supervision-module :nursery="selectedNursery"/>
                            </div>
                            <div
                                v-if="canWithNursery('read', 'kids_planning', selectedNursery)"
                                class="col-12 mt-3"
                            >
                                <kid-presences-module
                                    :nursery="selectedNursery"
                                />
                            </div>
                            <div
                                v-if="canWithNursery('read', 'staffs_planning', selectedNursery)"
                                class="col-12 mt-3"
                            >
                                <staff-presences-module
                                    :nursery="selectedNursery"
                                />
                            </div>
                        </div>
                    </div>
                </template>
            </div>
        </template>
    </SimplePageTemplate>
</template>

<script>
    import useApi from '@/modules/app/composables/useApi';
    import route from '@/modules/legacy/libs/ziggy';
    import __ from '@/modules/app/utils/i18n-facade';
    import _cloneDeep from 'lodash-es/cloneDeep';
    import _forEach from 'lodash-es/forEach';
    import _head from 'lodash-es/head';
    import Sparklines from '@/modules/dashboard/components/Sparklines.vue';
    import RevenueModule from '@/modules/dashboard/components/Revenue.vue';
    import RevenueDetailsModule from '@/modules/dashboard/components/RevenueDetails.vue';
    import OccupationModule from '@/modules/dashboard/components/Occupation.vue';
    import SupervisionModule from '@/modules/dashboard/components/Supervision.vue';
    import KidPresencesModule from '@/modules/dashboard/components/KidPresences.vue';
    import StaffPresencesModule from '@/modules/dashboard/components/StaffPresences.vue';
    import ExportsModal from '@/modules/dashboard/components/ExportsModal.vue';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';
    import SimplePageTemplate from '@/modules/app/components/templates/SimplePageTemplate.vue';
    import useModal from '@/modules/app/composables/useModal';
    import {Epoch} from '@meekohq/lumos';

    export default {
        components: {
            SimplePageTemplate,
            Sparklines,
            RevenueModule,
            RevenueDetailsModule,
            OccupationModule,
            SupervisionModule,
            KidPresencesModule,
            StaffPresencesModule,
        },
        props: {
            nursery: {},
            user: {},
        },
        data: () => ({
            nurseries: [],
            selectedNursery: {},
            loading: false,

            fromDate: Epoch.now(),
            toDate: Epoch.now(),
            invoiceTypeOptions: [{
                text: __('dashboard:invoice_and_pro_format'),
                value: undefined,
            }, {
                text: __('common:invoice_other'),
                value: 'billed',
            }, {
                text: __('common:draft_invoice'),
                value: 'estimated',
            }],
            selectedInvoiceType: {},
        }),
        computed: {
            allowedNurseries() {
                return this.nurseries.filter(nursery => {
                    const userNursery = this.user.nurseries.find(item => item.id === nursery.id);

                    return userNursery ? this.$can('read', 'invoices', userNursery.permissions) : false;
                });
            },
            exportsModal() {
                return useModal({
                    component: ExportsModal,
                    props: {
                        user: this.user,
                        nursery: this.nursery,
                    },
                });
            },
        },
        mounted() {
            if (this.$can('read', 'dashboard')) {
                this.getNurseries();
            }
        },
        methods: {
            getNurseries() {
                this.loading = true;
                useApi().legacy.get(route('nurseries.index'), {
                    params: {
                        resources: ['groups', 'openingHours'],
                    },
                })
                    .then(response => {
                        this.nurseries = response.data;
                        const currentNursery = this.nurseries.find(item => item.id === this.nursery.id);
                        if (currentNursery) {
                            this.switchNursery(currentNursery);
                        }
                        this.initFilters();
                        this.loading = false;
                    })
                    .catch(error => {
                        this.loading = false;
                        if (error && error.response && error.response.status === 422) {
                            _forEach(error.response.data.errors, value => {
                                useNotification().error(_head(value));
                            });
                        } else {
                            useNotification().error(error);
                        }
                    });
            },

            openExportsModal() {
                this.exportsModal.show();
            },

            canWithNursery(action, ressource, nursery) {
                const userNursery = this.user.nurseries.find(item => item.id === nursery.id);

                return userNursery ? this.$can(action, ressource, userNursery.permissions) : false;
            },

            switchNursery(nursery) {
                const currentNursery = this.nurseries.find(item => item.id === nursery.id);
                if (currentNursery) {
                    this.selectedNursery = _cloneDeep(currentNursery);
                }
            },

            initFilters() {
                const savedInvoiceType = this.invoiceTypeOptions.find(item => item.value === window.localStorage.getItem('sparklinesInvoiceType'));
                const savedFromDate = window.localStorage.getItem('sparklinesFromDate');
                const savedToDate = window.localStorage.getItem('sparklinesToDate');

                this.selectedInvoiceType = savedInvoiceType ? savedInvoiceType : this.invoiceTypeOptions[0];

                if (savedFromDate) {
                    this.fromDate = Epoch.parse(savedFromDate, 'yyyy-MM');
                } else {
                    this.fromDate = Epoch.now().startOfYear();
                }

                if (savedToDate) {
                    this.toDate = Epoch.parse(savedToDate, 'yyyy-MM');
                } else {
                    this.toDate = Epoch.now().startOfYear().addMonths(11);
                }
            },

            hidePopovers() {
                this.$root.$emit('bv::hide::popover');
            },
        },
    };
</script>
