<template>
    <div>
        <MPageTitle
            class="tw-mb-2"
            variant="communication"
        >
            {{ __('app:menu.communication_settings') }}
        </MPageTitle>
        <div class="card card-3d mt-2">
            <div class="card-body">
                <div class="tab-content">
                    <button
                        v-if="$can('update', 'nursery')"
                        class="btn btn-primary mb-4 float-right"
                        type="button"
                        @click="save"
                    >
                        {{ __('common:actions.save') }}
                    </button>
                    <div>
                        <MHeading>
                            <FontAwesomeIcon
                                class="tw-text-primary-500"
                                fixed-width
                                icon="fa fa-mobile-alt"
                            />
                            {{ __('news:family_app') }}
                        </MHeading>
                        <div class="row mt-4">
                            <div class="col-12 px-sm-0">
                                <div class="row">
                                    <div class="col-12 col-md-6 col-lg-4">
                                        <label class="label">{{ __('news:phone_number_to_display_colon') }} </label>
                                        <input
                                            v-model="nursery.second_phone"
                                            class="form-control"
                                            :disabled="!$can('update', 'nursery')"
                                            :placeholder="__('common:phone_number')"
                                        >
                                    </div>
                                    <div class="col-12 col-md-6 col-lg-4 mt-3 mt-md-0">
                                        <label class="label">{{ __('news:mail_to_display_colon') }} </label>
                                        <input
                                            v-model="nursery.second_email"
                                            class="form-control"
                                            :disabled="!$can('update', 'nursery')"
                                            :placeholder="__('common:email')"
                                        >
                                    </div>
                                    <div class="col-12 col-md-6 col-lg-4 mt-3 mt-md-0">
                                        <label class="label">{{ __('news:current_website_colon') }} </label>
                                        <input
                                            v-model="nursery.website"
                                            class="form-control"
                                            :disabled="!$can('update', 'nursery')"
                                            :placeholder="__('common:website')"
                                        >
                                    </div>
                                </div>
                            </div>
                        </div>

                        <template v-if="$can('update', 'nursery')">
                            <h6 class="h6 mt-4">
                                {{ __('news:transmission_visibility') }}
                            </h6>
                            <div class="text-muted">
                                <i
                                    aria-hidden="true"
                                    class="fa fa-warning mr-1"
                                />{{ __('news:apply_change_will_be_apply_next_transmissions') }}
                            </div>
                            <div class="row mt-2">
                                <div class="col-12 col-sm-6 col-lg-3">
                                    <div
                                        class="reporting reporting-presences"
                                        :class="{'active': !nursery.hide_presences}"
                                        @click="nursery.hide_presences = !nursery.hide_presences"
                                    >
                                        <i
                                            class="fa fa-clock mr-2"
                                            style="color: #584ee0"
                                        />{{ __('common:clocking_in_other') }}
                                    </div>

                                    <div
                                        class="reporting reporting-meals"
                                        :class="{'active': !nursery.hide_meals}"
                                        @click="nursery.hide_meals = !nursery.hide_meals"
                                    >
                                        <img
                                            :alt="__('common:meal')"
                                            class="mr-2 pb-1"
                                            :src="TabletAppleImage"
                                            width="15px"
                                        >{{ __('common:meal') }}
                                    </div>

                                    <div
                                        class="reporting reporting-activities"
                                        :class="{'active': !nursery.hide_activities}"
                                        @click="nursery.hide_activities = !nursery.hide_activities"
                                    >
                                        <img
                                            :alt="__('common:activity_one')"
                                            class="mr-2 pb-1"
                                            :src="TabletActivityImage"
                                            width="17px"
                                        >{{ __('common:activity_other') }}
                                    </div>

                                    <div
                                        class="reporting reporting-naps"
                                        :class="{'active': !nursery.hide_naps}"
                                        @click="nursery.hide_naps = !nursery.hide_naps"
                                    >
                                        <img
                                            :alt="__('common:naps')"
                                            class="mr-2 pb-1"
                                            :src="TabletMoonImage"
                                            width="17px"
                                        >{{ __('common:naps') }}
                                    </div>
                                </div>

                                <div class="col-12 col-sm-6 col-lg-3">
                                    <div
                                        class="reporting reporting-temperatures"
                                        :class="{'active': !nursery.hide_temperatures}"
                                        @click="nursery.hide_temperatures = !nursery.hide_temperatures"
                                    >
                                        <img
                                            :alt="__('common:temperatures')"
                                            class="mr-2 pl-1 mb-1"
                                            :src="TabletThermometerImage"
                                            style="height:22px;"
                                        >{{ __('common:temperatures') }}
                                    </div>

                                    <div
                                        class="reporting reporting-weights"
                                        :class="{'active': !nursery.hide_weights}"
                                        @click="nursery.hide_weights = !nursery.hide_weights"
                                    >
                                        <img
                                            :alt="__('common:weight')"
                                            class="mr-2 pb-1"
                                            :src="TabletBalanceImage"
                                            width="17px"
                                        >{{ __('common:weight') }}
                                    </div>

                                    <div
                                        class="reporting reporting-drugs"
                                        :class="{'active': !nursery.hide_drugs}"
                                        @click="nursery.hide_drugs = !nursery.hide_drugs"
                                    >
                                        <img
                                            :alt="__('common:drugs')"
                                            class="mr-2 pb-1"
                                            :src="TabletPillsImage"
                                            width="17px"
                                        >{{ __('common:drugs') }}
                                    </div>
                                </div>

                                <div class="col-12 col-sm-6 col-lg-3">
                                    <div
                                        class="reporting reporting-diapers"
                                        :class="{'active': !nursery.hide_diapers}"
                                        @click="nursery.hide_diapers = !nursery.hide_diapers"
                                    >
                                        <img
                                            :alt="__('news:diapers')"
                                            class="mr-2 pb-1"
                                            :src="TabletBabyDiaperImage"
                                            width="14px"
                                        >{{ __('news:diapers') }}
                                    </div>

                                    <div
                                        class="reporting reporting-photos"
                                        :class="{'active': !nursery.hide_photos}"
                                        @click="nursery.hide_photos = !nursery.hide_photos"
                                    >
                                        <i
                                            class="fa fa-image mr-2"
                                            style="color: #f54ea2"
                                        />{{ __('common:photos') }}
                                    </div>

                                    <div
                                        class="reporting reporting-observations"
                                        :class="{'active': !nursery.hide_observations}"
                                        @click="nursery.hide_observations = !nursery.hide_observations"
                                    >
                                        <img
                                            :alt="__('common:observation_other')"
                                            class="mr-2 pb-1"
                                            :src="TabletObservationImage"
                                            width="15px"
                                        >{{ __('common:observation_other') }}
                                    </div>
                                </div>
                            </div>

                            <div class="mt-2 px-1">
                                <CCheckbox
                                    :value="!hide_reporting_note"
                                    @input="hide_reporting_note = !$event"
                                >
                                    {{ __('news:display_additional_observations_for_selected') }}
                                </CCheckbox>
                                <div class="small">
                                    <i
                                        aria-hidden="true"
                                        class="fa fa-info-circle text-info mr-1"
                                    />{{ __('news:by_checking_additional_observations_will_bevisible_to_parents') }}
                                </div>
                            </div>
                        </template>
                    </div>
                    <div class="mt-5">
                        <MHeading class="tw-mb-4">
                            <FontAwesomeIcon
                                class="tw-text-primary-500"
                                fixed-width
                                icon="fa fa-globe"
                            />
                            {{ __('news:website_generated_by_meeko') }}
                            <MHelpTooltip placement="top">
                                <template #default>
                                    {{ __('news:meeko_generate_your_website') }}
                                </template>
                            </MHelpTooltip>
                        </MHeading>
                        <div
                            v-for="(website, i) in nursery.websites"
                            :key="'website' + i"
                            class="tw-flex tw-flex-col tw-gap-4"
                        >
                            <div class="tw-flex tw-flex-wrap tw-gap-2 tw-items-end">
                                <CFormGroup>
                                    <CLabel>
                                        {{ __('news:website_link') }}
                                    </CLabel>
                                    <CInputGroup>
                                        <CInputAddon>
                                            https://
                                        </CInputAddon>
                                        <MInput
                                            v-model="website.slug"
                                            :disabled="!$can('update', 'nursery')"
                                            :formatter="sanitizeUrl"
                                            :placeholder="__('news:my_dash_organization')"
                                            type="url"
                                        />
                                        <CInputAddon>
                                            .meeko.site
                                        </CInputAddon>
                                    </CInputGroup>
                                </CFormGroup>
                                <template v-if="$can('update', 'nursery')">
                                    <MButton
                                        :loading="websiteLoading"
                                        variant="primary"
                                        @click="updateWebsite(website)"
                                    >
                                        <template #left-icons>
                                            <FontAwesomeIcon icon="fa fa-sync"/>
                                        </template>
                                        {{ __('news:update_link') }}
                                    </MButton>
                                    <MButton
                                        v-if="i > 0"
                                        variant="danger"
                                        @click="destroyWebsite(website)"
                                    >
                                        {{ __('news:delete_website') }}
                                    </MButton>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import _head from 'lodash-es/head';
    import _forEach from 'lodash-es/forEach';
    import TabletAppleImage from '@/assets/images/tablet/apple_1.svg';
    import TabletActivityImage from '@/assets/images/tablet/activity.svg';
    import TabletMoonImage from '@/assets/images/tablet/moon_1.svg';
    import TabletThermometerImage from '@/assets/images/tablet/thermometer.svg';
    import TabletBalanceImage from '@/assets/images/tablet/balance.svg';
    import TabletPillsImage from '@/assets/images/tablet/pills.svg';
    import TabletBabyDiaperImage from '@/assets/images/tablet/baby_diaper.svg';
    import TabletObservationImage from '@/assets/images/tablet/observation.svg';
    import route from '@/modules/legacy/libs/ziggy';
    import useApi from '@/modules/app/composables/useApi';
    import __ from '@/modules/app/utils/i18n-facade';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';

    export default {
        props: ['nursery'],
        data() {
            return {
                hide_reporting_note: null,
                TabletAppleImage,
                TabletActivityImage,
                TabletMoonImage,
                TabletThermometerImage,
                TabletBalanceImage,
                TabletPillsImage,
                TabletBabyDiaperImage,
                TabletObservationImage,
                new_website: {},
                websiteLoading: false,
            };
        },
        watch: {
            hide_reporting_note(val) {
                this.nursery.hide_presences_note = val;
                this.nursery.hide_meals_note = val;
                this.nursery.hide_activities_note = val;
                this.nursery.hide_naps_note = val;
                this.nursery.hide_weights_note = val;
                this.nursery.hide_temperatures_note = val;
                this.nursery.hide_drugs_note = val;
                this.nursery.hide_diapers_note = val;
                this.nursery.hide_photos_note = false;
            },
        },
        mounted() {
            this.nursery.hide_meals_note ? this.hide_reporting_note = true : this.hide_reporting_note = false;
        },
        methods: {
            save() {
                useApi().legacy.put(route('nurseries.update', {nurseries: this.nursery.id}), {
                    second_phone: this.nursery.second_phone,
                    second_email: this.nursery.second_email,
                    slug: this.nursery.slug,
                    website: this.nursery.website,

                    hide_presences: this.nursery.hide_presences,
                    hide_presences_note: this.nursery.hide_presences_note,

                    hide_meals: this.nursery.hide_meals,
                    hide_meals_note: this.nursery.hide_meals_note,

                    hide_activities: this.nursery.hide_activities,
                    hide_activities_note: this.nursery.hide_activities_note,

                    hide_naps: this.nursery.hide_naps,
                    hide_naps_note: this.nursery.hide_naps_note,

                    hide_weights: this.nursery.hide_weights,
                    hide_weights_note: this.nursery.hide_weights_note,

                    hide_temperatures: this.nursery.hide_temperatures,
                    hide_temperatures_note: this.nursery.hide_temperatures_note,

                    hide_drugs: this.nursery.hide_drugs,
                    hide_drugs_note: this.nursery.hide_drugs_note,

                    hide_diapers: this.nursery.hide_diapers,
                    hide_diapers_note: this.nursery.hide_diapers_note,

                    hide_photos: this.nursery.hide_photos,
                    hide_photos_note: this.nursery.hide_photos_note,

                    hide_observations: this.nursery.hide_observations,
                }).then(() => {
                    useNotification().success(__('common:parameters_updated_successfully'));
                }).catch(error => {
                    if (error && error.response && error.response.status === 422) {
                        _forEach(error.response.data.errors, function(value) {
                            useNotification().error(_head(value));
                        });
                    }
                });
            },
            updateWebsite(website) {
                this.websiteLoading = true;
                useApi().legacy.put(route('websites.update', {website: website.id}), {
                    slug: this.sanitizeUrl(website.slug),
                }).then(() => {
                    this.websiteLoading = false;
                    useNotification().success(__('news:website_updated_successfully'));
                }).catch(error => {
                    this.websiteLoading = false;
                    if (error && error.response && error.response.status === 422) {
                        _forEach(error.response.data.errors, function(value) {
                            useNotification().error(_head(value));
                        });
                    }
                });
            },
            destroyWebsite(website) {
                useApi().legacy.delete(route('websites.destroy', {website: website.id})).then(() => {
                    this.nursery.websites = this.nursery.websites.filter(function(item) {
                        return item.id !== website.id;
                    });
                    useNotification().success(__('news:website_deleted_successfully'));
                }).catch(error => {
                    if (error && error.response && error.response.status === 422) {
                        _forEach(error.response.data.errors, function(value) {
                            useNotification().error(_head(value));
                        });
                    }
                });
            },
            sanitizeUrl(url) {
                if (url && typeof url === 'string') {
                    return url.replace(/ /g, '');
                }

                return url;
            },
        },
    };
</script>

<style
    lang="scss"
    scoped
>
    .reporting {
        height: 35px;
        padding-inline: 10px;
        display: flex;
        align-items: center;
        border: 1px solid #DDD;
        border-radius: 6px;
        margin: 7px 0;
        opacity: .5;
        cursor: pointer;
        transition: all .2s ease-in;

        &:hover {
            opacity: .8;
        }

        &.active {
            background: transparent;
            opacity: 1;
        }

        &.reporting-presences {
            &.active {
                color: #584ee0;
                border-color: #584ee0;
            }
        }

        &.reporting-meals {
            &.active {
                color: #03c081;
                border-color: #03c081;
            }
        }

        &.reporting-activities {
            &.active {
                color: #F7931E;
                border-color: #F7931E;
            }
        }

        &.reporting-naps {
            &.active {
                color: #4e2fdd;
                border-color: #4e2fdd;
            }
        }

        &.reporting-weights {
            &.active {
                color: #3580f1;
                border-color: #3580f1;
            }
        }

        &.reporting-temperatures {
            &.active {
                color: #f73c3c;
                border-color: #f73c3c;
            }
        }

        &.reporting-drugs {
            &.active {
                color: #fa2b47;
                border-color: #fa2b47;
            }
        }

        &.reporting-diapers {
            &.active {
                color: #c32ff0;
                border-color: #c32ff0;
            }
        }

        &.reporting-photos {
            &.active {
                color: #f54ea2;
                border-color: #f54ea2;
            }
        }

        &.reporting-observations {
            &.active {
                color: #05ace2;
                border-color: #05ace2;
            }
        }
    }
</style>
