<template>
    <ResourceFinder
        :always-open="alwaysOpen"
        :builder="queryBuilder"
        :button-class="buttonClass"
        :button-variant="buttonVariant"
        :has-unselect="hasUnselect"
        :multi="multi"
        :multi-minimum="multiMinimum"
        :popover-class="popoverClass"
        :prevent-hide-on-click="preventHideOnClick"
        :value="value"
        @fallback="onFallback"
        @input="onChange"
        @search="search = $event"
    >
        <template #trigger="{toggle}">
            <slot
                name="trigger"
                :toggle="toggle"
                :value="value"
            />
        </template>
        <template #button-text-empty>
            <slot name="button-text-empty">
                {{ __('billing_payment:means_payment') }}
            </slot>
        </template>

        <template #button-text="{value}">
            {{ value.attributes.name }}
        </template>

        <template #button-text-multi="{values, langVar}">
            <slot
                :lang-var="langVar"
                name="button-text-multi"
                :values="values"
            >
                {{ __('components:count_item', {count: values.length - 1, value: values[0].attributes.name}) }}
            </slot>
        </template>

        <template
            v-if="$slots['fallback']"
            #fallback
        >
            <slot name="fallback"/>
        </template>

        <template #default="{option, searchValue}">
            <div class="tw-flex tw-pr-4 tw-items-center">
                <CVStack>
                    <div
                        class="tw-truncate"
                        v-html="highlight(searchValue, option.attributes.name)"
                    />
                    <CText
                        v-if="option.attributes.account_number"
                        font-size="sm"
                        variant="gray"
                    >
                        {{ highlight(searchValue, option.attributes.account_number) }}
                    </CText>
                </CVStack>
            </div>
        </template>
    </ResourceFinder>
</template>

<script lang="ts">
    import type {PropType} from 'vue';
    import {computed, defineComponent, ref} from 'vue';
    import type {Model, QueryBuilder} from '@meekohq/lumos';
    import ResourceFinder from '@/modules/app/components/resource/organisms/ResourceFinder.vue';
    import useHighlighter from '@/modules/legacy/helpers/useHighlighter';
    import PaymentMethodModel from '@/modules/cashier/models/PaymentMethodModel';
    import useManager from '@/modules/app/composables/useManager';

    export default defineComponent({
        components: {ResourceFinder},
        props: {
            // !!! DO NOT EDIT !!!
            value: {type: [Object, Array] as PropType<Model | Model[]>},
            injectQuery: {type: Object as PropType<QueryBuilder<any>>},
            hasUnselect: {type: Boolean, default: false},
            multi: {type: Boolean, default: false},
            multiMinimum: {type: [Number, String], default: 0},
            popoverClass: {},
            buttonClass: {},
            buttonVariant: {},
            onlyUserOrganizations: {type: Boolean, default: false},
            hasActiveContract: {type: Boolean, default: false},
            alwaysOpen: {type: Boolean, default: false},
            preventHideOnClick: {type: Boolean as PropType<boolean>, default: false},

        },
        emits: ['input', 'fallback'],
        setup(props, {emit}) {
            // !!! START: DO NOT EDIT !!
            const search = ref('');
            const onChange = function(value) {
                emit('input', value);
            };
            // !!! END: DO NOT EDIT !!

            const {activeOrganization} = useManager();
            const organizationTenant = activeOrganization.value?.tenants().value().first();

            const queryBuilder = computed(() => {
                let builder = PaymentMethodModel.query()
                    .whereHas(new PaymentMethodModel().tenant(), query => query.where('id', organizationTenant?.getKey()))
                    .orderBy('name');

                if (search.value) {
                    builder = builder.where(query => {
                        query.whereLike('name', `*${search.value}*`);
                    });
                }

                if (props.injectQuery) {
                    builder.inject(props.injectQuery);
                }

                return builder;
            });

            const onFallback = function() {
                emit('fallback');
            };

            return {
                search,
                onChange,
                onFallback,
                queryBuilder,
                ...useHighlighter(),
            };
        },
    });
</script>
