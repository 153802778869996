<script setup lang="ts">
    import MagicIndex from '@/modules/magic-index/components/organisms/MagicIndex.vue';
    import PersonalAccessTokensListItem
        from '@/modules/auth/personal-access-token/infrastructure/components/PersonalAccessTokensListItem.vue';
    import PersonalAccessTokensListHeader
        from '@/modules/auth/personal-access-token/infrastructure/components/PersonalAccessTokensListHeader.vue';
    import {onMounted, provide} from 'vue';
    import {
        injectKey,
        usePersonalAccessTokensListState,
    } from '@/modules/auth/personal-access-token/infrastructure/components/usePersonalAccessTokensListState';

    const state = usePersonalAccessTokensListState();
    const {isFetching, paginator, paginate} = state;

    onMounted(async () => {
        await state.paginate();
    });

    provide(injectKey, state);

    defineExpose({
        paginate,
    });
</script>

<template>
    <MagicIndex
        :key-function="item => item.getKey()"
        :loading="isFetching"
        :paginator="paginator"
        @change="paginate"
    >
        <template #header>
            <PersonalAccessTokensListHeader/>
        </template>
        <template #item="{item}">
            <PersonalAccessTokensListItem :item="item"/>
        </template>
        <template #empty-list>
            {{ __('common:empty_result') }}
        </template>
        <template #empty-list-action>
            {{ __('common:try_modifying_filters_or_adding_data') }}
        </template>
    </MagicIndex>
</template>
