<template>
    <div>
        <loader
            v-if="loading"
            light="true"
            shadow="false"
            size="sm"
        />
        <div
            v-else-if="photos.length"
            class="tw-grid tw-grid-cols-2 md:tw-grid-cols-3 tw-gap-1"
        >
            <div
                v-for="(photo, i) in photos"
                :key="'image' + i"
                class="tw-relative tw-min-h-32 tw-mb-6"
            >
                <div
                    v-if="photo.video"
                    class="tw-flex tw-absolute tw-rounded-full tw-bg-black tw-items-center tw-justify-center"
                    style="width: 40px; height: 40px; top: 50%; left: 50%; transform: translate(-50%, -50%); opacity: .8;"
                >
                    <i class="fa fa-play tw-text-white tw-text-xl tw-pl-1"/>
                </div>
                <div
                    class="tw-border-2 tw-border-transparent hover:tw-border-blue-300 tw-rounded-lg pointer"
                    @click="$emit('select-photo', photo)"
                >
                    <div
                        class="tw-relative tw-w-full tw-h-32 tw-bg-cover tw-bg-center tw-rounded-lg"
                        :style="{'background-image': `url('${photo.permanent_photo_url ? photo.permanent_photo_url : photo.photo_url}')`}"
                    />
                </div>
            </div>
        </div>
        <div v-else>
            {{ __('news:no_image_on_library') }}
        </div>
        <div
            v-if="total > per_page && photos.length"
            class="tw-flex tw-justify-center tw-mt-4"
        >
            <MPagination
                :paginator="paginator"
                @navigate="currentPage = $event"
            />
        </div>
    </div>
</template>

<script>
    import route from '@/modules/legacy/libs/ziggy';
    import _head from 'lodash-es/head';
    import _forEach from 'lodash-es/forEach';
    import Manager from '@/modules/legacy/store/manager.store';
    import useApi from '@/modules/app/composables/useApi';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';
    import MPagination from '@/modules/meeko-ui/components/MPagination.vue';
    import {url} from '@/modules/legacy/mixins/url';
    import {LengthAwarePaginator} from '@meekohq/lumos';

    export default {
        components: {MPagination},
        mixins: [url],
        data: () => ({
            photos: [],
            loading: false,
            per_page: 6,
            currentPage: 1,
            page: 1,
            total: 0,
            paginator: undefined,
        }),
        watch: {
            currentPage() {
                this.getPhotos();
            },
        },
        mounted() {
            this.getPhotos();
        },
        methods: {
            getPhotos() {
                this.loading = true;
                useApi().legacy.get(
                    route('nurseries.photos.index', {
                        nurseries: Manager.legacyNursery.id,
                    }),
                    {
                        params: {
                            page: this.currentPage,
                            per_page: this.per_page,
                            permanent_photo_url: 1,
                        },
                    },
                )
                    .then(response => {
                        this.photos = response.data.data;
                        this.total = response.data.total;
                        this.paginator = new LengthAwarePaginator(
                            response.data.data,
                            parseInt(response.data.total),
                            parseInt(response.data.per_page),
                            parseInt(response.data.current_page),
                            parseInt(response.data.last_page),
                        );
                        this.loading = false;
                    })
                    .catch(error => {
                        this.loading = false;
                        if (
                            error &&
                            error.response &&
                            error.response.status === 422
                        ) {
                            _forEach(error.response.data.errors, function(value) {
                                useNotification().error(_head(value));
                            });
                        } else {
                            useNotification().error(error);
                        }
                    });
            },
        },
    };
</script>
