import type {SudoModeUseCase} from '@/modules/auth/sudo-mode/application/SudoModeUseCase';
import {collect, Epoch, MqlOperation, MqlUnprocessableEntityError, ValidationError} from '@meekohq/lumos';
import {isNil} from 'lodash-es';
import type {SudoModePayloadType} from '@/modules/auth/sudo-mode/domain/SudoModePayloadType';

export class SudoModeInteractor implements SudoModeUseCase {
    private static readonly storageKey = 'auth:sudo_token';

    public getSudoModeToken(): string | null {
        const storageValue = localStorage.getItem(SudoModeInteractor.storageKey);

        if (isNil(storageValue)) {
            return null;
        }

        const parsedValue = JSON.parse(storageValue) as SudoModePayloadType;

        // Token has expired, remove it from storage and return null
        if  (Epoch.now().greaterThan(Epoch.fromTimestamp(parsedValue.expire))) {
            localStorage.removeItem(SudoModeInteractor.storageKey);

            return null;
        }

        return parsedValue.token;
    }

    public async enterSudoMode(password: string): Promise<string> {
        try {
            const result = await new MqlOperation<SudoModePayloadType>('auth/acquire-sudo-token', {
                password,
            }).run();

            localStorage.setItem(SudoModeInteractor.storageKey, JSON.stringify(result.content));

            return result.content.token;
        } catch (err) {
            if (err instanceof MqlUnprocessableEntityError) {
                const resultContent = err.operation.result.content as { data: any } | null | undefined;

                if (!resultContent) {
                    throw err;
                }

                throw new ValidationError(collect(resultContent.data));
            }
            throw err;
        }
    }
}
