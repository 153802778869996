<script setup lang="ts">
    import useSaveTransaction from '@/modules/cashier/composables/issuing/transaction/useSaveTransaction';
    import type {TransactionStateType} from '@/modules/cashier/composables/issuing/transaction/useTransactionState';
    import {computed} from 'vue';

    const props = defineProps<{
        transactionState: TransactionStateType;
    }>();

    const emit = defineEmits<{
        (e: 'hide'): void
    }>();

    const hasError = computed(() => {
        return props.transactionState.isInsufficientlyAllocated.value;
    });

    // Get save action
    const {save, loading: isSaving} = useSaveTransaction(props.transactionState);
</script>

<template>
    <div class="TransactionModalFooter">
        <MButton
            :label="__('common:actions.cancel')"
            :loading="isSaving"
            @click="emit('hide')"
        />
        <MButton
            :disabled="hasError"
            :label="__('common:actions.save')"
            :loading="isSaving"
            variant="primary"
            @click="save"
        />
    </div>
</template>

<style scoped lang="scss">
    .TransactionModalFooter {
        @apply tw-contents;
    }
</style>
