import {ref} from 'vue';
import {app, ValidationError} from '@meekohq/lumos';
import __ from '@/modules/app/utils/i18n-facade';
import type ContractModel from '@/modules/human-resources/models/ContractModel';
import type StaffModel from '@/modules/human-resources/models/StaffModel';
import type ContractTrialPeriodModel from '@/modules/human-resources/models/ContractTrialPeriodModel';
import type BalanceAllocationModel from '@/modules/human-resources/models/BalanceAllocationModel';
import type {SaveContractUseCase} from '@/modules/human-resources/contract/application/SaveContractUseCase';
import {ContractAggregateError} from '@/modules/human-resources/contract/domain/errors/ContractAggregateError';
import {ContractErrorMapper} from '@/modules/human-resources/contract/infrastructure/ContractErrorMapper';
import useNotification from '@/modules/meeko-ui/composables/useNotification';
import {Types} from '@/types';
import useError from '@/modules/app/composables/useError';

export function useSaveContract() {
    const isSaving = ref(false);
    const savingErrors = useError(ContractErrorMapper.CUSTOM_ERROR_MESSAGES);

    /**
     *  Saving Contract
     */
    async function save(staff: StaffModel, contract: ContractModel, trialPeriods: ContractTrialPeriodModel[], allocations: BalanceAllocationModel[]) {
        isSaving.value = true;
        savingErrors.reset();

        try {
            await app().make<SaveContractUseCase>(Types.SaveContractUseCase).save(staff, contract, trialPeriods, allocations);

            // Display success message
            if (contract.exists) {
                useNotification().success(__('hr_contract:contract_saved_successfully'));
            } else {
                staff.contracts().value().push(contract);
                useNotification().success(__('hr_contract:contract_added_successfully'));
            }

            return true;
        } catch (error) {
            if (error instanceof ValidationError) {
                savingErrors.addValidationError(ContractErrorMapper.mapFromValidationError(error));

                return false;
            } else if (error instanceof ContractAggregateError) {
                savingErrors.addValidationError(ContractErrorMapper.mapFromContractAggregateError(error));

                return false;
            } else {
                throw error;
            }
        } finally {
            isSaving.value = false;
        }
    }

    return {
        save,
        isSaving,
        savingErrors,
    };
}
