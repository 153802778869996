import type {Epoch} from '@meekohq/lumos';
import {computed, inject, ref} from 'vue';
import {keyMCalendarState} from '@/modules/meeko-ui/components/MCalendar/useMCalendarState';
import {keyMCalendarNavigation} from '@/modules/meeko-ui/components/MCalendar/useMCalendarNavigation';
import injectableKey from '@/modules/app/utils/injectableKey';
import type {MCalendarSelectionItem} from '@/modules/meeko-ui/components/MCalendar/useMCalendarSelection';
import useMCalendarSelection from '@/modules/meeko-ui/components/MCalendar/useMCalendarSelection';

export const keyMCalendarMonths = injectableKey<ReturnType<typeof useMCalendarMonths>>();

export type MCalendarMonthsItem = MCalendarSelectionItem & {
    month: Epoch;
};

export default function useMCalendarMonths() {
    const state = inject(keyMCalendarState)!;
    if (!state) {
        throw new Error('keyMCalendarState must be provided');
    }

    const navigation = inject(keyMCalendarNavigation)!;
    if (!navigation) {
        throw new Error('keyMCalendarNavigation must be provided');
    }

    const hoveredMonth = ref<Epoch>();

    const {
        isDatePeriodStart,
        isDatePeriodEnd,
        isDateSelected,
        isDateSelecting,
        isDateInPeriod,
        handleDateClick,
        isDateHovered,
    } = useMCalendarSelection(hoveredMonth, 'month', state.modifiers);

    const monthsInActiveYear = computed(() => {
        const months: MCalendarMonthsItem[] = [];

        let monthIterator = navigation.activeMonth.value.startOfYear();
        while (monthIterator.year === navigation.activeMonth.value.year) {
            months.push({
                month: monthIterator,
                isSelected: isDateSelected(monthIterator),
                isSelecting: isDateSelecting(monthIterator),
                isPeriodStart: isDatePeriodStart(monthIterator),
                isPeriodEnd: isDatePeriodEnd(monthIterator),
                isInPeriod: isDateInPeriod(monthIterator),
                isHovered: isDateHovered(monthIterator),
            });
            monthIterator = monthIterator.addMonths(1);
        }

        return months;
    });

    function handleMonthClick(month: Epoch) {
        if (navigation.showDay) {
            navigation.setActiveMonthFromDate(navigation.activeMonth.value.setMonth(month.month));
            navigation.toggleActiveView('day');

            return;
        }

        handleDateClick(month);
    }

    return {
        hoveredMonth,
        monthsInActiveYear,
        handleMonthClick,
    };
}
