<template>
    <CForm tag="div">
        <CFormGroup>
            <CLabel>{{ __('common:address.address') }}*</CLabel>
            <div>
                <CInput
                    v-model="model.attributes[addressPath].line1"
                    class="!tw-rounded-b-none"
                    :has-error="modelError.hasError('line1', pointerPath)"
                    :placeholder="__('common:address.address')"
                />
                <CInput
                    v-model="model.attributes[addressPath].line2"
                    class="!tw-rounded-t-none tw--mt-px"
                    :has-error="modelError.hasError('line2', pointerPath)"
                    is-nullable
                    :placeholder="__('common:address.extra_adress')"
                />
            </div>
            <CFormErrorMessageList :errors="modelError.getErrors('line1', pointerPath)"/>
            <CFormErrorMessageList :errors="modelError.getErrors('line2', pointerPath)"/>
        </CFormGroup>
        <CFormTwoColumns>
            <CFormGroup>
                <CLabel>{{ __('common:address.zip_code') }}*</CLabel>
                <CInput
                    v-model="model.attributes[addressPath].postal_code"
                    :has-error="modelError.hasError('postal_code', pointerPath)"
                    :placeholder="__('common:address.zip_code')"
                />
                <CFormErrorMessageList :errors="modelError.getErrors('postal_code', pointerPath)"/>
            </CFormGroup>
            <CFormGroup>
                <CLabel>{{ __('common:address.city') }}*</CLabel>
                <CInput
                    v-model="model.attributes[addressPath].city"
                    :has-error="modelError.hasError('city', pointerPath)"
                    :placeholder="__('common:address.city')"
                />
                <CFormErrorMessageList :errors="modelError.getErrors('city', pointerPath)"/>
            </CFormGroup>
        </CFormTwoColumns>
        <CFormGroup>
            <CLabel>{{ __('common:address.region') }}</CLabel>
            <CInput
                v-model="model.attributes[addressPath].state"
                :has-error="modelError.hasError('state', pointerPath)"
                :placeholder="__('common:address.region')"
            />
            <CFormErrorMessageList :errors="modelError.getErrors('state', pointerPath)"/>
        </CFormGroup>
        <CFormGroup>
            <CLabel>{{ __('common:address.country') }}</CLabel>
            <SelectCountry
                v-model="model.attributes[addressPath].country_code"
                :has-error="modelError.hasError('country_code', pointerPath)"
                max-height="128px"
            />
            <CFormErrorMessageList :errors="modelError.getErrors('country_code', pointerPath)"/>
        </CFormGroup>
    </CForm>
</template>

<script lang="ts">
    import type {PropType} from 'vue';
    import {defineComponent} from 'vue';
    import type {Model} from '@meekohq/lumos';
    import type ErrorHandler from '@/modules/legacy/libs/errors/errorHandler';
    import SelectCountry from '@/modules/legacy/components/Modules/SelectCountry.vue';

    export default defineComponent({
        components: {
            SelectCountry,
        },
        props: {
            pointerPath: {
                type: String,
                required: true,
            },
            focus: {
                type: Boolean,
                default: true,
            },
            company: {
                type: Boolean,
                default: true,
            },
            model: {
                type: Object as PropType<Model>,
                required: true,
            },
            addressPath: {
                type: String,
                required: true,
            },
            modelError: {
                type: Object as PropType<ErrorHandler>,
                required: true,
            },
        },
        setup() {
            return {};
        },
    });
</script>
