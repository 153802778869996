<template>
    <CListRow>
        <CInline align-y="center">
            <div>
                <CBadge
                    class="tw-mr-2 tw-whitespace-nowrap"
                    variant="blue"
                >
                    {{ addressModel.attributes.label }}
                </CBadge>
            </div>
            <div>
                {{ addressModel.fullAddress }}
                <CBadge
                    v-if="customerModel.attributes.default_billing_address_id === addressModel.id"
                    class="tw-ml-2"
                >
                    {{ __('common:by_default') }}
                </CBadge>
            </div>
            <div class="tw-ml-auto tw--my-1">
                <AddressListItemAction
                    :address-model="addressModel"
                    :customer-model="customerModel"
                    @deleted="onAddressDeleted"
                />
            </div>
        </CInline>
    </CListRow>
</template>

<script lang="ts">
    import type CustomerModel from '@/modules/cashier/models/CustomerModel';
    import type AddressModel from '@/modules/cashier/models/AddressModel';
    import AddressListItemAction from './AddressListItemAction.vue';
    import type {PropType} from 'vue';
    import {defineComponent} from 'vue';

    export default defineComponent({
        components: {AddressListItemAction},
        props: {
            addressModel: {type: Object as PropType<AddressModel>, required: true},
            customerModel: {type: Object as PropType<CustomerModel>, required: true},
        },
        setup(props, {emit}) {
            function onAddressDeleted(addressModel: AddressModel) {
                emit('deleted', addressModel);
            }

            return {
                onAddressDeleted,
            };
        },
    });
</script>

