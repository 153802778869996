import type RuleSetInterface from '@/modules/calendar/utils/RuleSet/RuleSetInterface';
import Rule from '@/modules/calendar/utils/RuleSet/Rule';
import _isEqual from 'lodash-es/isEqual';
import _cloneDeep from 'lodash-es/cloneDeep';

export default class RuleSet implements RuleSetInterface {
    public dtstart?: string;
    public until?: string;
    public rules: Rule[] = [];
    public exrules: Rule[] = [];
    public version = 1;

    public static fromJSON(rulesetJson: RuleSetInterface): RuleSet {
        if (!rulesetJson) {
            return new RuleSet();
        }

        const jsonClone = _cloneDeep(rulesetJson);

        const ruleset = new RuleSet();
        ruleset.version = jsonClone.version || 1;
        ruleset.dtstart = jsonClone.dtstart;
        ruleset.until = jsonClone.until;

        jsonClone.rules.forEach(ruleJson => {
            const rule = Rule.fromJSON(ruleJson);
            ruleset.addRule(rule);
        });

        jsonClone.exrules.forEach(exruleJson => {
            const exrule = Rule.fromJSON(exruleJson);
            ruleset.addExRule(exrule);
        });

        return ruleset;
    }

    public addRule(rule: Rule) {
        this.rules.push(rule);
    }

    public removeRule(rule: Rule) {
        this.rules = this.rules.filter(value => !_isEqual(value, rule));
    }

    public addExRule(rule: Rule) {
        this.exrules.push(rule);
    }

    public removeExRule(rule: Rule) {
        this.exrules = this.exrules.filter(value => !_isEqual(value, rule));
    }

    public copy() {
        return RuleSet.fromJSON(this);
    }
}
