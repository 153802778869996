import MedicalTreatmentModel from '@/modules/health/models/MedicalTreatmentModel';
import MedicalActionModel from '@/modules/health/models/MedicalActionModel';
import {ServiceProvider} from '@meekohq/lumos';

export default class HealthServiceProvider extends ServiceProvider {
    public boot() {
        this.loadModelsFrom([
            MedicalTreatmentModel,
            MedicalActionModel,
        ]);
    }
}
