<template>
    <CList>
        <slot/>
    </CList>
</template>

<script lang="ts">
    import {defineComponent} from 'vue';

    export default defineComponent({
        components: {},
        props: {},
        setup() {
            return {};
        },
    });
</script>

<style lang="scss" scoped>
    //
</style>
