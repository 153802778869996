<template>
    <div>
        <div class="tw-text-center">
            <MButton
                v-if="editable"
                class="tw-text-info-500 tw-mb-4"
                variant="link"
                @click="updateFromCAF"
            >
                <template #left-icons>
                    <FontAwesomeIcon icon="fa fa-sync"/>
                </template>
                {{ __('billing_setting:update_with_latest_data') }}
            </MButton>
        </div>

        <CForm class="tw-mb-4">
            <CFormTwoColumns>
                <CFormGroup>
                    <CLabel>{{ __('common:kids_number') }}</CLabel>
                    <MInput
                        v-model="kid"
                        :placeholder="__('common:kids_number')"
                        size="lg"
                    />
                </CFormGroup>
                <CFormGroup>
                    <CLabel>{{ __('common:income') }}</CLabel>
                    <MInput
                        v-model="income"
                        :placeholder="__('common:income')"
                        size="lg"
                    />
                </CFormGroup>
            </CFormTwoColumns>
        </CForm>
        <div class="tw-overflow-scroll">
            <table class="table">
                <thead>
                    <tr>
                        <th scope="col">
                            {{ __('billing_setting:slice') }}
                        </th>
                        <th scope="col">
                            {{ __('common:income') }}
                        </th>
                        <th
                            v-for="i in maxKids"
                            :key="'kid' + i"
                            scope="col"
                        >
                            {{ i > 1 ? i + ' ' + __('common:kid_one') : i + ' ' + __('common:kid_other') }}
                        </th>
                        <th scope="col">
                            {{ __('billing_setting:more_than_children_value', {value: maxKids}) }}
                            <MHelpTooltip placement="top">
                                <template #default>
                                    {{ __('billing_setting:amount_of_the_bracket_will_increase') }}
                                </template>
                            </MHelpTooltip>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        v-for="(price, priceKey) in prices"
                        :key="'price ' + priceKey"
                    >
                        <th scope="row">
                            {{ priceKey + 1 }}
                        </th>
                        <td v-if="editable">
                            <MMenu>
                                <MMenuButton>
                                    <MButton
                                        icon-menu
                                        :label="priceRuleToText(price.rule)"
                                        variant="primary"
                                    />
                                </MMenuButton>
                                <MMenuItems>
                                    <MMenuItem
                                        :label="priceRuleToText('&lt;')"
                                        @click="price.rule = '<'"
                                    />
                                    <MMenuItem
                                        :label="priceRuleToText('&lt;=')"
                                        @click="price.rule = '<='"
                                    />
                                    <MMenuDivider/>
                                    <MMenuItem
                                        :label="priceRuleToText('>')"
                                        @click="price.rule = '>'"
                                    />
                                    <MMenuItem
                                        :label="priceRuleToText('>=')"
                                        @click="price.rule = '>='"
                                    />
                                </MMenuItems>
                            </MMenu>
                        </td>
                        <td
                            v-else
                            class="text-primary"
                        >
                            {{ priceRuleToText(price.rule) }}
                        </td>
                        <td
                            v-for="(level, levelKey) in price.levels"
                            :key="'level' + levelKey"
                            :class="{'highlighted': finder && highlight.levelKey === levelKey && highlight.priceKey === priceKey}"
                        >
                            <MInput
                                v-model="level.income"
                                :placeholder="__('common:income')"
                                :readonly="!editable"
                            />
                        </td>
                        <td
                            class="bg-light"
                            :class="{'highlighted': finder && highlight.priceKey === priceKey && kid > maxKids}"
                        >
                            <CInputGroup>
                                <CInputAddon>
                                    +
                                </CInputAddon>
                                <MInput
                                    v-model="price.more"
                                    :placeholder="__('common:income')"
                                    :readonly="!editable"
                                />
                            </CInputGroup>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div
            v-if="editable"
            class="tw-flex tw-flex-row tw-items-center tw-gap-2 tw-my-2"
        >
            <MHeading level="h4">
                {{ __('billing_setting:slice') }} :
            </MHeading>
            <MButton
                round
                size="xs"
                variant="success"
                @click="addPrice"
            >
                <FontAwesomeIcon icon="fa fa-plus"/>
            </MButton>
            <MButton
                round
                size="xs"
                variant="danger"
                @click="deletePrice"
            >
                <FontAwesomeIcon icon="fa fa-times"/>
            </MButton>
        </div>
        <div
            v-if="editable"
            class="tw-flex tw-flex-row tw-items-center tw-gap-2"
        >
            <MHeading level="h4">
                {{ __('common:kid_other') }} :
            </MHeading>
            <MButton
                round
                size="xs"
                variant="success"
                @click="addLevel"
            >
                <FontAwesomeIcon icon="fa fa-plus"/>
            </MButton>
            <MButton
                round
                size="xs"
                variant="danger"
                @click="deleteLevel"
            >
                <FontAwesomeIcon icon="fa fa-times"/>
            </MButton>
        </div>
    </div>
</template>

<script>
    import _max from 'lodash-es/max';
    import _last from 'lodash-es/last';
    import _head from 'lodash-es/head';
    import _forEach from 'lodash-es/forEach';
    import _filter from 'lodash-es/filter';
    import _cloneDeep from 'lodash-es/cloneDeep';
    import defaultConfig from '@/modules/legacy/contents/defaultConfig';
    import __ from '@/modules/app/utils/i18n-facade';

    export default {
        props: ['prices', 'editable', 'finder'],
        data() {
            return {
                kid: 0,
                income: 0,
            };
        },
        computed: {
            maxKids() {
                const maxes = [];
                _forEach(this.prices, price => {
                    maxes.push(price.levels.length);
                });

                return _max(maxes);
            },
            highlight() {
                if (!this.kid || !this.income) {
                    return {
                        levelKey: undefined,
                        priceKey: undefined,
                    };
                }
                let filter = _filter(this.flatPrice, item => {
                    return item.levelKey + 1 === parseInt(this.kid);
                });
                filter = _filter(filter, item => {
                    const income = parseInt(this.income);

                    if (item.rule === '>') {
                        return income > item.income;
                    }

                    if (item.rule === '<') {
                        return income < item.income;
                    }

                    if (item.rule === '>=') {
                        return income >= item.income;
                    }

                    if (item.rule === '<=') {
                        return income <= item.income;
                    }

                    return item.income === income;
                });
                if (filter.length) {
                    return _head(filter);
                }

                return {
                    levelKey: undefined,
                    priceKey: undefined,
                };
            },
            flatPrice() {
                const array = [];
                _forEach(this.prices, (price, priceKey) => {
                    _forEach(price.levels, (level, levelKey) => {
                        array.push({
                            priceKey,
                            levelKey,
                            income: level.income,
                            rule: price.rule,
                            more: price.more,
                        });
                    });

                    const levelsCount = price.levels.length;
                    const lastLevelsIndex = levelsCount - 1;

                    if (parseInt(this.kid) > levelsCount) {
                        const delta = parseInt(this.kid) - levelsCount;
                        for (let i = 1; i <= delta; i++) {
                            array.push({
                                priceKey,
                                levelKey: lastLevelsIndex + i,
                                income: _last(price.levels).income + (i * price.more),
                                rule: price.rule,
                                more: price.more,
                            });
                        }
                    }
                });

                return array;
            },
        },
        mounted() {

        },
        methods: {
            addPrice() {
                const newPrice = {
                    more: 0,
                    rule: '<=',
                    levels: [],
                };

                Array(this.maxKids).fill().map(() => {
                    newPrice.levels.push({
                        income: 0,
                    });
                });

                this.prices.push(newPrice);
            },
            deletePrice() {
                this.prices.splice(this.prices.length - 1, 1);
            },
            addLevel() {
                _forEach(this.prices, price => {
                    price.levels.push({income: 0});
                });
            },
            deleteLevel() {
                _forEach(this.prices, price => {
                    price.levels.splice(price.levels.length - 1, 1);
                });
            },
            priceRuleToText(rule) {
                if (rule === '&lt;' || rule === '<') {
                    return __('billing_setting:less_than');
                }

                if (rule === '&lt;=' || rule === '<=') {
                    return __('billing_setting:less_than_or_equal_to');
                }

                if (rule === '>') {
                    return __('billing_setting:greater_than');
                }

                if (rule === '>=') {
                    return __('billing_setting:greater_than_or_equal_to');
                }
            },
            updateFromCAF() {
                const myConfig = _cloneDeep(defaultConfig);
                this.$emit('update:prices', myConfig.pricing.discount.brackets);
            },
        },
    };
</script>

<style
    lang="scss"
    scoped
>
    .highlighted {
        background-color: #a5e0b2 !important;
    }

    th {
        vertical-align: middle !important;
    }
</style>
