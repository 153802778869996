import {app, Container, type Model} from '@meekohq/lumos';

export default function() {
    const sync = function <T extends Model>(model: T) {
        app<any>(Container.contracts.databaseCache).syncModel(model);
        app<any>(Container.contracts.databaseCache).syncRelationships(model);
    };

    return {
        sync,
    };
}
