import {datadogRum} from '@datadog/browser-rum';
import {config, ServiceProvider} from '@meekohq/lumos';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginVue from '@bugsnag/plugin-vue';
import useAuth from '@/modules/app/composables/useAuth';

export default class MonitoringServiceProvider extends ServiceProvider {
    public register() {
        if (config('services.bugsnag.enabled')) {
            Bugsnag.start({
                apiKey: config('services.bugsnag.key'),
                plugins: [new BugsnagPluginVue()],
                enabledReleaseStages: ['production', 'staging'],
                releaseStage: config('app.env'),
                appVersion: config('app.version'),
                onError: function(event) {
                    const user = useAuth().user.value;

                    if (user) {
                        event.setUser(user.getKey(), user.attributes.email, user.fullname);
                        event.addMetadata('accountId', {accountId: user.attributes.account_id});
                    }
                },
            });
        }

        if (config('services.datadog.enabled')) {
            datadogRum.init({
                applicationId: config('services.datadog.appId'),
                clientToken: config('services.datadog.clientToken'),
                site: 'datadoghq.eu',
                service: 'manager',
                env: config('app.env'),
                version: config('app.version'),
                enableExperimentalFeatures: ['feature_flags'],
                traceSampleRate: 50,
                sessionSampleRate: 100,
                sessionReplaySampleRate: 0,
                trackResources: true,
                trackLongTasks: true,
                trackUserInteractions: true,
                actionNameAttribute: 'data-action-name',
                defaultPrivacyLevel: 'mask',
                allowedTracingUrls: ['https://api.meeko.app', 'https://meeko.app', 'https://staging-manager.meeko.app'],
            });
        }
    }
}
