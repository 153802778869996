<template>
    <CListRow
        v-if="pivot && loader.isDone()"
        :class="{'progress-bar-striped': pivot.attributes.archived_at}"
        :variant="pivot.attributes.archived_at ? 'disabled' : 'default'"
    >
        <CInline
            v-if="teamModel"
            align-y="baseline"
        >
            <div class="tw-w-3/12 tw-flex tw-items-center">
                <span>{{ teamModel.attributes.name }}</span>
            </div>
            <div class="tw-w-3/12 tw-flex tw-items-center">
                <span v-if="getStaffInTeam">{{ __('hr:member', {count: getStaffInTeam}) }}</span>
            </div>
            <div class="tw-w-1/12"/>
            <div class="tw-w-5/12 tw-flex tw-justify-end tw-items-center">
                <CBadge
                    v-if="!isOnlyInOrganization"
                    class="tw-mr-2"
                    variant="blue"
                >
                    <span>{{ __('hr:shared_team') }}</span>
                </CBadge>
                <CTooltip
                    v-if="(pivot && pivot.attributes.archived_at) || teamModel.attributes.archived_at"
                    placement="top"
                >
                    <CBadgeArchived
                        class="tw-mr-2"
                        variant="orange-tooltip"
                        :zoom="true"
                    />
                    <template #content>
                        {{ __('common:archived') }}
                    </template>
                </CTooltip>
                <TeamListItemMenu
                    :is-only-in-organization="isOnlyInOrganization"
                    :pivot="pivot"
                    :team-model="teamModel"
                    @ownerDelete="$refs.deleteModal.show()"
                    @teamModelToDelete="$emit('onRemoveTeamModel', $event)"
                >
                    <template #update>
                        <CDropdownMenuItem
                            v-if="!teamModel.attributes.internal_id"
                            icon="fa fa-pen"
                            variant="light"
                            @click="$refs.updateModal.show()"
                        >
                            {{ __('common:actions.update') }}
                        </CDropdownMenuItem>
                    </template>
                </TeamListItemMenu>
                <DeleteTeamModal
                    ref="deleteModal"
                    :team-model="teamModel"
                    @removeTeam="removeTeam"
                />
                <UpdateTeamModal
                    ref="updateModal"
                    :team-model="teamModel"
                />
            </div>
        </CInline>
    </CListRow>
</template>

<script lang="ts">
    import type {Ref} from 'vue';
    import {defineComponent, ref, toRef, watch} from 'vue';
    import Loader from '@/modules/legacy/helpers/loader.helper';
    import TeamModel from '@/modules/human-resources/models/TeamModel';
    import TeamListItemMenu from '@/modules/human-resources/components/team/List/TeamListItemMenu.vue';
    import Manager from '@/modules/legacy/store/manager.store';
    import type TeamOrganizationPivot from '@/modules/human-resources/models/TeamOrganizationPivot';
    import CBadgeArchived from '@/modules/coherence-ui/components/Tools/CBadgeArchived.vue';
    import type TeamStaffPivot from '@/modules/human-resources/models/TeamStaffPivot';
    import type {Collection} from '@meekohq/lumos';
    import DeleteTeamModal from '@/modules/human-resources/components/team/Modals/DeleteTeamModal.vue';
    import UpdateTeamModal from '@/modules/human-resources/components/team/Modals/UpdateTeamModal.vue';
    import useManager from '@/modules/app/composables/useManager';

    export default defineComponent(
        {
            components: {UpdateTeamModal, DeleteTeamModal, TeamListItemMenu, CBadgeArchived},
            props: {
                teamModel: {
                    type: TeamModel,
                    required: true,
                },
            },

            setup(props, {emit}) {
                const loader = ref<Loader>(new Loader());
                const teamModel = toRef(props, 'teamModel');
                const isOnlyInOrganization: Ref<boolean> = ref(false);
                const pivot = ref<TeamOrganizationPivot>();
                const getStaffInTeam: Ref<number> = ref(0);
                const {activeOrganization} = useManager();

                function removeTeam(model) {
                    emit('ownerRemoveTeamModel', model);
                }

                watch(teamModel, async value => {
                    loader.value.start();
                    const pivots: Collection<TeamOrganizationPivot> = value.organizationsPivots().value();
                    isOnlyInOrganization.value = pivots.count() === 1;

                    pivot.value = value.organizationsPivots().value().where('attributes.organization_id', activeOrganization.value.id).first();

                    const fetchStaffs: TeamStaffPivot[] = (await value.staffsPivots().load()).all();
                    getStaffInTeam.value = fetchStaffs.length;

                    loader.value.stop();
                }, {immediate: true});

                return {
                    loader,
                    Manager,
                    pivot,
                    isOnlyInOrganization,
                    getStaffInTeam,
                    removeTeam,
                };
            },
        },
    );
</script>

