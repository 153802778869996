<template>
    <form-wizard
        ref="wizard"
        :back-button-text="__('common:actions.previous')"
        class="step-wizard"
        :color="color"
        :finish-button-text="__('common:actions.finish')"
        :next-button-text="__('common:actions.next')"
        :step-size="size"
        :subtitle="null"
        :title="null"
    >
        <tab-content
            v-for="(tab, i) in tabs"
            :key="i"
            :icon="getIcon(i)"
            :title="tab"
        >
            <slot :name="'tab' + (i + 1)"/>
        </tab-content>

        <template #footer="props">
            <div :class="footerClass">
                <div class="wizard-footer-left mr-auto">
                    <span
                        v-if="props.activeTabIndex !== 0"
                        @click="props.prevTab()"
                    >
                        <slot name="button-previous"><button class="btn btn-primary">{{ __('common:actions.previous') }}</button></slot>
                    </span>
                    <slot name="footer-left"/>
                </div>
                <div class="wizard-footer-right">
                    <slot name="footer-right"/>
                    <span
                        v-if="!props.isLastStep"
                        @click="props.nextTab()"
                    >
                        <slot name="button-next"><button class="btn btn-primary">{{ __('common:actions.next') }}</button></slot>
                    </span>
                    <span v-else>
                        <slot name="button-done"><button class="btn btn-primary">{{ __('common:actions.finish') }}</button></slot>
                    </span>
                </div>
            </div>
        </template>
    </form-wizard>
</template>

<script>
    import {FormWizard, TabContent} from 'vue-form-wizard';
    import 'vue-form-wizard/dist/vue-form-wizard.min.css';

    export default {
        components: {
            FormWizard,
            TabContent,
        },
        props: {
            tabs: {},
            icons: {},
            footerClass: {},
            size: {
                default: 'sm',
            },
            step: {
                default: 0,
            },
            color: {
                default: '#007FF8',
            },
        },
        mounted() {
            this.tabs.forEach((tab, i) => {
                this.$refs.wizard.changeTab(0, i);
            });
            this.$refs.wizard.navigateToTab(this.step);
        },
        methods: {
            navigateToTab(tab) {
                this.$refs.wizard.navigateToTab(tab);
            },

            getIcon(i) {
                return this.icons[i] ? 'fa ' + this.icons[i] : null;
            },
        },
    };
</script>

<style lang="scss">
    .step-wizard {
        .wizard-header {
            padding: 0;
        }

        .wizard-nav {
            a {
                color: #007FF8 !important;
            }
        }
    }
</style>
