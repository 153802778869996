<template>
    <div class="TransactionListItemInvoiceItem">
        <InvoiceIcon :invoice="allocation.extra.invoice"/>
        <div class="tw-truncate">
            {{ allocation.extra.invoice.attributes.no }}
        </div>
    </div>
</template>

<script lang="ts">
    import type {PropType} from 'vue';
    import {defineComponent} from 'vue';
    import type AllocationModel from '@/modules/cashier/models/AllocationModel';
    import InvoiceIcon from '@/modules/cashier/components/billing/invoice/atoms/InvoiceIcon.vue';

    export default defineComponent({
        components: {
            InvoiceIcon,
        },
        props: {
            allocation: {
                type: Object as PropType<AllocationModel>,
                required: true,
            },
        },
    });
</script>

<style lang="scss" scoped>
    .TransactionListItemInvoiceItem {
        @apply tw-flex tw-items-center tw-gap-1;
        @apply tw-py-1 tw-px-2;
        @apply tw-rounded-lg;
        @apply tw-text-sm tw-font-medium;
        @apply tw-transition-all tw-border tw-border-dashed tw-border-gray-500/30 tw-text-gray-700;
    }
</style>
