<template>
    <div
        id="line-transmission"
        class="pb-5"
    >
        <div class="d-table w-100 mb-2 mt-4">
            <div
                class="d-none d-sm-block"
                style="padding-left: 100px; padding-right: 50px;"
            >
                <hours
                    :closing="closing"
                    :opening="opening"
                />
            </div>
        </div>
        <div v-if="weeklySummaries">
            <div
                v-for="(day, i) in enumerateDaysBetweenDates"
                :key="'day' + i"
                class="d-table w-100"
                style="margin-top: 3.5rem"
            >
                <div class="day text-capitalize text-sm-right pr-sm-4 mb-1 mb-sm-0 tw-font-display float-none float-sm-left ml-2">
                    {{ Epoch.fromISOString(day.toISOString()).toLocaleString(Epoch.presets.WEEKDAY_WITH_DAY_AND_MONTH) }}
                </div>
                <div class="planning float-none float-sm-left pr-sm-3 ml-2">
                    <planning-generator
                        :closing="closing"
                        :day="day"
                        :nursery="nursery"
                        :opening="opening"
                        :show-clock="false"
                        small
                        :user="user"
                        :weekly-summaries="weeklySummaries"
                    />
                </div>
            </div>
        </div>
        <MEmptyIndex
            v-else
            :title="__('family_kid:empty_index')"
        />
    </div>
</template>

<script>

    import moment from 'moment';
    import _find from 'lodash-es/find';
    import Hours from '@/modules/legacy/components/Modules/PlanningGenerator/Hours.vue';
    import {nursery} from '@/modules/legacy/mixins/nursery';
    import {Epoch} from '@meekohq/lumos';
    import MEmptyIndex from '@/modules/meeko-ui/components/MEmptyIndex.vue';

    export default {
        name: 'WeeklyTransmission',
        components: {
            MEmptyIndex,
            hours: Hours,
        },
        mixins: [nursery],
        props: {
            date: {},
            nursery: {type: Object},
            user: {type: Object},
            weeklySummaries: {type: Array},
        },
        computed: {
            Epoch() {
                return Epoch;
            },
            moment() {
                return moment;
            },
            from() {
                return moment(this.date).clone().startOf('week');
            },
            to() {
                return moment(this.date).clone().endOf('week');
            },
            opening() {
                return this.$getOpening(this.nursery.openingHours, true, false);
            },
            closing() {
                return this.$getClosing(this.nursery.openingHours, true, false);
            },
            enumerateDaysBetweenDates() {
                const dates = [];

                const currDate = moment(this.from).startOf('day');
                const lastDate = moment(this.to).startOf('day');

                dates.push(currDate.clone());
                while (currDate.add(1, 'days').diff(lastDate) <= 0) {
                    const day = currDate.clone().locale('en').format('ddd').toLowerCase();
                    if (day === 'sat' || day === 'sun') {
                        const hasDay = _find(this.nursery.openingHours, {day});
                        hasDay ? dates.push(currDate.clone()) : null;
                    } else {
                        dates.push(currDate.clone());
                    }
                }

                return dates;
            },
        },
    };
</script>

<style
    scoped
    lang="scss"
>
    @import "bootstrap/scss/functions";
    @import "@/assets/_bootstrap/variables";
    @import "bootstrap/scss/mixins/breakpoints";

    #line-transmission {
        &.daily {
            background-color: white;
            padding: 2rem;
            border-radius: 10px;
            margin: 2rem 0;

            .hours {
                margin-right: 20px !important;
            }

            .planning {
                width: 100%;
            }

            .kid-lines {
                margin-top: 1.5rem;

                .kid {
                    margin-bottom: 2.5rem;
                    width: 220px;
                }
            }

            @include media-breakpoint-up(sm) {
                .kid-lines {
                    margin-top: 3.5rem;

                    .kid {
                        margin-bottom: 0;
                    }
                }
                .planning {
                    width: calc(100% - 100px - 130px);
                }
            }
        }

        .day {
            font-size: 14px;
        }

        .kid {
            font-size: 22px;
        }

        @include media-breakpoint-up(sm) {
            .day {
                width: 100px;
                font-size: 14px;
            }

            .planning {
                width: calc(100% - 100px - 35px);
            }
        }
    }


</style>
