<template>
    <div>
        <CButton
            :disabled="disabled"
            :icon-sort="true"
            @click="$refs.dropdown.show()"
        >
            <CIcon
                v-if="iconModel"
                :path="iconModel.attributes.path"
                :provider="iconModel.attributes.provider"
            />
            <template v-else>
                {{ __('components:without_icone') }}
            </template>
        </CButton>
        <CDropdown
            ref="dropdown"
            content-class="tw-overflow-y-scroll tw-max-w-xs tw-max-h-80"
            legacy-mode
        >
            <div
                class="tw-flex tw-items-center tw-cursor-pointer tw-text-base"
                @click="changeIcon(null)"
            >
                <i
                    aria-hidden="true"
                    class="fas fa-square tw-text-2xl mr-2"
                />
                <span class="tw-text-gray-800">{{ __('components:without_icone') }}</span>
            </div>
            <hr class="hr tw-mt-3 tw-mb-2">
            <div
                v-for="(category, index) in categories"
                :key="'cat' + index"
            >
                <div
                    v-if="index === 'people'"
                    class="tw-text-gray-600"
                >
                    <template v-if="index === 'people'">
                        {{ __('common:people') }}
                    </template>
                    <template v-else-if="index === 'communication'">
                        {{ __('common:communication') }}
                    </template>
                    <template v-else-if="index === 'files'">
                        {{ __('common:files') }}
                    </template>
                    <template v-else-if="index === 'objects'">
                        {{ __('common:objects') }}
                    </template>
                    <template v-else-if="index === 'others'">
                        {{ __('common:other_other') }}
                    </template>
                </div>
                <div class="tw-grid tw-grid-cols-12 tw-grid-flow-row tw-gap-4 tw-px-1">
                    <div
                        v-for="(icon, i) in category"
                        :key="'cat' + index + 'icon' + i"
                        class="tw-col-span-3 tw-cursor-pointer tw-text-center tw-mt-2 tw-text-2xl"
                        @click="changeIcon(icon)"
                    >
                        <CIcon
                            :path="icon.attributes.path"
                            :provider="icon.attributes.provider"
                        />
                    </div>
                </div>
            </div>
        </CDropdown>
    </div>
</template>

<script>
    import DataStore from '@/modules/legacy/store/data.store';
    import {groupBy} from 'lodash-es';

    export default {

        props: {
            iconId: {
                required: true,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
        },

        data() {
            return {
                icons: null,
                categories: [],
                iconModel: '',
            };
        },

        watch: {
            iconId(value) {
                if (value) {
                    this.iconModel = DataStore.iconPath(value);
                }
            },
        },

        mounted() {
            this.icons = DataStore.store.data.icons;
            this.categories = groupBy(this.icons, icon => icon.attributes.category);

            if (this.iconId) {
                this.iconModel = DataStore.iconPath(this.iconId);
            }
        },

        methods: {
            changeIcon(icon) {
                this.iconModel = icon;
                this.$refs.dropdown.hide();
                this.$emit('updateIcon', icon ? icon.id : null);
            },
        },

    };
</script>
