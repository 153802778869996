<template>
    <CForm
        v-if="loader.isDone('FetchData')"
        @submit.prevent="save"
    >
        <CInline
            align-x="between"
            space="xl"
        >
            <CFormGroup>
                <CLabel>
                    {{ __('common:name') }}*
                    <CHelpTooltip>
                        <p class="tw-w-56 tw-mb-0">
                            {{ __('hr:the_name_of_the_event_type_as_it_will_appear_on_calendars') }}
                        </p>
                    </CHelpTooltip>
                </CLabel>
                <CInput
                    v-model="model.attributes.name"
                    v-focus="true"
                    :has-error="eventTypeError.hasError()"
                />
                <CFormErrorMessageList
                    :errors="eventTypeError.getErrors('name')"
                />
            </CFormGroup>
            <CFormGroup :class="{'tw-opacity-50': model.attributes.internal_id}">
                <CLabel>
                    {{ __('common:icon') }}
                </CLabel>
                <SelectIcon
                    :disabled="!!model.attributes.internal_id"
                    :icon-id="model.attributes.icon_id"
                    @updateIcon="model.attributes.icon_id = $event"
                />
            </CFormGroup>
            <CFormGroup :class="{'tw-opacity-50': model.attributes.internal_id}">
                <CLabel>
                    {{ __('common:color') }}
                </CLabel>
                <color-swatches
                    v-model="model.attributes.color"
                    :colors="swatchColor"
                    :disabled="!!model.attributes.internal_id"
                    :row-length="6"
                    show-border
                    show-fallback
                    :swatch-size="28"
                    :trigger-style="{width: '32px', height: '32px'}"
                />
                <CFormErrorMessageList
                    v-if="eventTypeError.hasError('color')"
                    :errors="eventTypeError.getErrors('color')"
                />
            </CFormGroup>
        </CInline>
        <CInline>
            <CFormGroup :class="{'tw-opacity-50': model.attributes.internal_id}">
                <CLabel>{{ __('hr:spervision_children_colon') }}</CLabel>
                <CLabel>
                    <CCheckbox
                        v-model="model.attributes.enable_supervision_for_forecast"
                        :disabled="!!model.attributes.internal_id"
                    >
                        {{ __('hr:assign_to_forecast') }}
                    </CCheckbox>
                </CLabel>
                <CLabel>
                    <CCheckbox
                        v-model="model.attributes.enable_supervision_for_actual"
                        :disabled="!!model.attributes.internal_id"
                    >
                        {{ __('hr:assign_to_real') }}
                    </CCheckbox>
                </CLabel>
            </CFormGroup>
        </CInline>
        <slot
            name="importItemAlert"
            :value="model.attributes.name"
        />
    </CForm>
</template>

<script lang="ts">
    import Loader from '@/modules/legacy/helpers/loader.helper';
    import ColorSwatches from 'vue-swatches';
    import 'vue-swatches/dist/vue-swatches.min.css';
    import type EventTypeModel from '@/modules/human-resources/models/EventTypeModel';
    import SelectIcon from '@/modules/app/components/Icon/SelectIcon.vue';
    import ErrorHandler from '@/modules/legacy/libs/errors/errorHandler';
    import type {PropType} from 'vue';
    import {computed, defineComponent, reactive} from 'vue';
    import useManager from '@/modules/app/composables/useManager';
    import EventTypeOrganizationModel from '@/modules/human-resources/models/EventTypeOrganizationModel';

    export default defineComponent({
        components: {SelectIcon, ColorSwatches},
        props: {
            model: {
                required: true,
                type: Object as PropType<EventTypeModel>,
            },
        },
        setup(props, {emit}) {
            const {activeOrganization} = useManager();
            const loader = reactive(new Loader());
            const eventTypeError = reactive(new ErrorHandler());
            const swatchColor = ['#21A3FF', '#AE58FD', '#FF4444', '#F941AF', '#FD9740', '#1653C3', '#0FD0AC', '#C7345D', '#A098A2', '#000000'];

            // computed to react on props.model
            // return the relation for using it when i want the value
            const organizationsPivotRelations = computed(() => props.model.organizationsPivots());

            async function save() {
                try {
                    emit('loading', true);

                    await props.model.save();

                    // create a boolean which represents if there is a pivot for the active organization or not
                    const activeOrganizationPivotExist = organizationsPivotRelations.value.value()
                        .filter(pivot => pivot.attributes.organization_id === activeOrganization.value.id)
                        .isNotEmpty();
                    if (!activeOrganizationPivotExist) {
                        // if pivot dont exist we create, associate and save the new pivot
                        const pivot = await savePivot();
                        organizationsPivotRelations.value.value().push(pivot);
                    }
                    eventTypeError.reset();
                    emit('loading', false);
                    emit('saved', props.model);
                } catch (error) {
                    emit('loading', false);
                    eventTypeError.reset(error);
                }
            }

            async function savePivot() {
                const pivot = new EventTypeOrganizationModel();
                pivot.attributes.account_id = props.model.attributes.account_id;
                pivot.eventType().associate(props.model, false);
                pivot.organization().associate(activeOrganization.value, false);

                return pivot.save();
            }

            return {
                loader,
                eventTypeError,
                swatchColor,
                save,
            };
        },
    });
</script>
