<template>
    <div class="EditInvoiceRateMade">
        <div class="EditInvoiceRateMade__rate-container">
            <template v-if="editable || nursery.invoice_hours">
                <strong
                    v-if="invoice.type === 'presence' && getTotalHours(invoice.hours_in_day ? invoice.hours_in_day : nursery.hours_in_day, invoice.lines) > 0"
                    :class="[rateWarning(nursery, invoice) || hoursWarning(nursery, invoice) ? 'text-warning' : 'text-info']"
                >
                    <FontAwesomeIcon
                        v-if="rateWarning(nursery, invoice)"
                        icon="fa fa-exclamation-triangle"
                    />
                    <FontAwesomeIcon
                        v-else-if="hoursWarning(nursery, invoice)"
                        icon="fa fa-exclamation-triangle"
                    />
                    <span>
                        {{ __('billing_invoice:hourly_rate_colon_with_money_and_hour', {
                            money: format(getRate(nursery, invoice)),
                            hours: getTotalHours(invoice.hours_in_day ? invoice.hours_in_day : nursery.hours_in_day, invoice.lines),
                        }) }}
                    </span>
                </strong>
            </template>
        </div>
        <div class="EditInvoiceRateMade__made-at-container">
            <template v-if="editable && can('update', 'invoices')">
                {{ __('billing_invoice:madin_in_on', {city: nursery.city}) }}
                <CFormDatepicker
                    v-model="invoice.date"
                    class="EditInvoiceRateMade__datepicker"
                    format="YYYY-MM-DD"
                />
            </template>
            <template v-else>
                {{
                    __('billing_invoice:madin_in_on_date', {
                        city: nursery.city,
                        date: Epoch.parse(invoice.date, 'yyyy-MM-dd').toLocaleString(Epoch.presets.DATE_SHORT),
                    })
                }}
            </template>
        </div>
    </div>
</template>


<script lang="ts">
    import {defineComponent} from 'vue';
    import {Epoch} from '@meekohq/lumos';
    import {invoice} from '@/modules/legacy/mixins/invoice';
    import {nursery} from '@/modules/legacy/mixins/nursery';
    import useFormatNumber from '@/modules/cashier/composables/useFormatNumber';
    import useAbility from '@/modules/app/composables/useAbility';


    export default defineComponent({
        mixins: [invoice, nursery],
        props: {
            invoice: {
                type: Object,
                required: true,
            },
            nursery: {
                type: Object,
                required: true,
            },
            editable: {
                type: Boolean,
                required: true,
            },
        },

        setup(props) {
            const {format} = useFormatNumber(props.invoice.currency_iso_code);
            const {can} = useAbility();

            return {
                Epoch,
                format,
                can,
            };
        },
    });
</script>

<style lang="scss" scoped>
    .EditInvoiceRateMade {
        @apply tw-flex md:tw-flex-row tw-flex-col md:tw-items-center md:tw-justify-between tw-gap-2;
    }

    .EditInvoiceRateMade__made-at-container {
        @apply tw-flex sm:tw-flex-row tw-flex-col sm:tw-items-center tw-gap-1;
    }

    .EditInvoiceRateMade__datepicker {
        @apply sm:tw-w-28 tw-w-full;
    }
</style>
