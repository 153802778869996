import type SplittableEvent from '@/modules/human-resources/utils/calendar/SplittableEvent';
import SplitEvent from '@/modules/legacy/libs/periodSplitter/SplitEvent';
import WorkingTimeInPeriod from '@/modules/human-resources/utils/calendar/Services/WorkingTimeInPeriod';

export default class AllEventsTime extends WorkingTimeInPeriod {
    public getWorkingTime(): number {
        const events = this.mapEventModelsToSplittableEvents();

        const inPeriodEvents = this.constrainEventsInPeriod(events);

        const mergedEvents = this.mergeEventsWithFilter(inPeriodEvents);

        const splittedEvents = this.splitEventsWithStrategy(mergedEvents);

        // Calculate sum of events time
        let total = 0;
        splittedEvents.forEach(event => {
            total += (event.endedAt - event.startedAt) / 60;
        });

        return total;
    }

    protected splitEventsWithStrategy(events: SplittableEvent[]): SplittableEvent[] {
        const splitter = new SplitEvent(events).setStrategyCallback(splitStrategyCallback);

        return splitter.split();
    }
}

// Callback for split fonction
const splitStrategyCallback = (
    baseEvent: SplittableEvent,
): SplittableEvent[] => {
    return [baseEvent];
};
