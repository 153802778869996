<template>
    <SimpleSubPageTemplate>
        <template #title>
            <MHeading>
                {{ __('common:room_other') }}
            </MHeading>
        </template>
        <template #actions>
            <CButton
                icon-plus
                variant="primary"
                @click="editRoom()"
            >
                {{ __('equipment:add_room') }}
            </CButton>
            <RoomModal
                v-if="showModal"
                :loading="updating"
                :selected-room="selectedRoom"
                @add="saveRoom()"
                @closed="showModal = false"
            />
        </template>
        <template #content>
            <MagicIndex
                :loading="loading"
                :paginator="paginator"
                variant="equipment"
                @change="getRooms($event)"
            >
                <template #header>
                    <MagicIndexHeader>
                        <MagicIndexHeaderColumn
                            :label="__('common:name')"
                            :size="{lg: 2}"
                        />
                    </MagicIndexHeader>
                </template>
                <template #item="{item}">
                    <RoomItem
                        :room="item"
                        @delete="removeRoom(item)"
                        @edit="editRoom(item)"
                    />
                </template>
                <template #empty-list>
                    {{ __('equipment:empty_room_index') }}
                </template>
                <template #empty-list-action>
                    {{ __('equipment:try_modifying_filters_or_adding_a_part') }}
                </template>
            </MagicIndex>
        </template>
        <div/>
    </SimpleSubPageTemplate>
</template>

<script>
    import Vue from 'vue';
    import useApi from '@/modules/app/composables/useApi';
    import route from '@/modules/legacy/libs/ziggy';
    import swal from 'sweetalert2/dist/sweetalert2.js';
    import _forEach from 'lodash-es/forEach';
    import _head from 'lodash-es/head';
    import _cloneDeep from 'lodash-es/cloneDeep';
    import MagicIndex from '@/modules/magic-index/components/organisms/MagicIndex.vue';
    import {url} from '@/modules/legacy/mixins/url';
    import {LengthAwarePaginator} from '@meekohq/lumos';
    import RoomModal from '@/modules/equipment/components/molecules/RoomModal.vue';
    import RoomItem from '@/modules/equipment/components/molecules/RoomItem.vue';
    import __ from '@/modules/app/utils/i18n-facade';
    import SimpleSubPageTemplate from '@/modules/app/components/templates/SimpleSubPageTemplate.vue';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';
    import MagicIndexHeaderColumn from '@/modules/magic-index/components/atoms/MagicIndexHeaderColumn.vue';
    import MagicIndexHeader from '@/modules/magic-index/components/atoms/MagicIndexHeader.vue';

    export default {
        components: {MagicIndexHeader, MagicIndexHeaderColumn, SimpleSubPageTemplate, RoomItem, RoomModal, MagicIndex},
        mixins: [url],
        props: {
            nursery: {
                type: Object,
                required: true,
            },
        },
        data: () => ({
            rooms: [],
            selectedRoom: {},
            loading: false,
            updating: false,
            paginator: undefined,
            showModal: false,
        }),
        watch: {
            paginator() {
                this.pushQueryString('p', this.paginator.currentPage());
            },
        },
        beforeMount() {
            this.getRooms(this.$route.query.p);
        },
        methods: {
            getRooms(page = 1, perPage = 20) {
                this.loading = true;
                useApi().legacy.get(route('rooms.index', {
                    nursery_id: this.nursery.id,
                    page,
                    per_page: perPage,
                })).then(response => {
                    this.rooms = response.data.data;
                    this.paginator = new LengthAwarePaginator(
                        response.data.data,
                        parseInt(response.data.total),
                        parseInt(response.data.per_page),
                        parseInt(response.data.current_page),
                        parseInt(response.data.last_page),
                    );
                }).catch(error => {
                    if (error && error.response && error.response.status === 422) {
                        _forEach(error.response.data.errors, value => {
                            useNotification().error(_head(value));
                        });
                    } else {
                        useNotification().error(error);
                    }
                }).finally(() => this.loading = false);
            },

            editRoom(room) {
                if (room) {
                    this.selectedRoom = _cloneDeep(room);
                } else {
                    this.selectedRoom = {
                        id: null,
                        name: null,
                        nurseries: [{
                            id: this.nursery.id,
                            pivot: {},
                        }],
                    };
                }
                this.showModal = true;
            },

            removeRoom(room) {
                swal({
                    title: __('equipment:delete_room_ask'),
                    text: __('equipment:delete_room_explanation'),
                    type: 'warning',
                    confirmButtonClass: 'btn btn-danger mr-2',
                    confirmButtonText: __('common:actions.delete'),
                    cancelButtonText: __('common:actions.cancel'),
                }).then(result => {
                    if (result.value) {
                        useApi().legacy.delete(route('rooms.destroy', {
                            room: room.id,
                        })).then(() => {
                            const roomToDelete = this.rooms.find(item => item.id === room.id);
                            if (roomToDelete) {
                                const index = this.rooms.indexOf(roomToDelete);
                                this.rooms.splice(index, 1);
                                useNotification().success(__('equipment:room_deleted_successfully'));
                            }
                        }).catch(error => {
                            if (error && error.response && error.response.status === 422) {
                                _forEach(error.response.data.errors, value => {
                                    useNotification().error(_head(value));
                                });
                            } else {
                                useNotification().error(error);
                            }
                        });
                    }
                });
            },

            saveRoom() {
                this.updating = true;

                if (this.selectedRoom.id) {
                    this.selectedRoom.nursery_id = this.nursery.id;
                    useApi().legacy.put(route('rooms.update', {
                        id: this.selectedRoom.id,
                    }), this.selectedRoom)
                        .then(response => {
                            const roomToUpdate = this.rooms.find(item => item.id === this.selectedRoom.id);
                            if (roomToUpdate) {
                                const index = this.rooms.indexOf(roomToUpdate);
                                Vue.set(this.rooms, index, response.data);
                                useNotification().success(__('equipment:room_updated_successfully'));
                            }

                            this.updating = false;
                            this.showModal = false;
                        }).catch(error => {
                            this.updating = false;
                            if (error && error.response && error.response.status === 422) {
                                _forEach(error.response.data.errors, value => {
                                    useNotification().error(_head(value));
                                });
                            } else {
                                useNotification().error(error);
                            }
                        });
                } else {
                    useApi().legacy.post(route('rooms.store'), this.selectedRoom)
                        .then(response => {
                            this.rooms.push(response.data);
                            this.updating = false;
                            useNotification().success(__('equipment:room_added_successfully'));
                            this.showModal = false;
                        }).catch(error => {
                            this.updating = false;
                            if (error && error.response && error.response.status === 422) {
                                _forEach(error.response.data.errors, value => {
                                    useNotification().error(_head(value));
                                });
                            } else {
                                useNotification().error(error);
                            }
                        });
                }
            },
        },
    };
</script>
