import type {Ref} from 'vue';
import {readonly, ref} from 'vue';
import type UserModel from '@/modules/user/models/UserModel';

const userModel = ref<UserModel>();
const legacyUser = ref();
const apiToken = ref();
const legacyToken = ref();
const isAuthenticated = ref(false);

export default function() {
    function setUserModel(user: UserModel | null) {
        userModel.value = user ?? undefined;
    }

    function setLegacyUser(user: any | null) {
        legacyUser.value = user ?? undefined;
    }

    function setToken(token: string | null) {
        apiToken.value = token;
    }

    function setLegacyToken(token: string | null) {
        legacyToken.value = token;
    }

    function setIsAuthenticated(value: boolean) {
        isAuthenticated.value = value;
    }

    return {
        setUserModel,
        setLegacyUser,
        setToken,
        setLegacyToken,
        setIsAuthenticated,
        isAuthenticated: readonly(isAuthenticated),
        user: userModel as Ref<UserModel>,
        legacyUser,
        apiToken: readonly(apiToken),
        legacyToken: readonly(legacyToken),
    };
}
