<template>
    <div
        class="planning-generator w-100 rounded"
        :class="{'small': small}"
    >
        <clock
            v-if="clockEnabled() && showClock"
            :closing="closing"
            :day="day"
            :opening="opening"
        />
        <div v-if="weeklySummaries">
            <transmission-clock
                v-for="(summary, i) in summaries"
                :key="'summary' + i"
                :closing="closing"
                :day="day"
                :opening="opening"
                :summary="summary"
            >
                <template #image/>
                <template #hover-image>
                    <div v-if="summary.type === 'photos'">
                        <img
                            :src="summary.photo_url"
                            @click="fullsize = !fullsize"
                        >
                        <transition
                            v-if="fullsize"
                            mode="in-out"
                            name="fade"
                        >
                            <div
                                v-if="fullsize"
                                class="fullsize"
                                @click="fullsize = false"
                            >
                                <div
                                    class="img"
                                    :style="originalImage(summary, summary.description)"
                                />
                                <div
                                    v-if="summary.description"
                                    class="description"
                                >
                                    {{ summary.description }}
                                </div>
                                <div class="close-button">
                                    <i
                                        aria-hidden="true"
                                        class="fa fa-times-circle"
                                    />
                                </div>
                            </div>
                        </transition>
                    </div>
                </template>
                <template #hover-description>
                    <div v-if="summary.type === 'photos' && summary.description">
                        <div>
                            <span class="font-weight-bold">
                                {{ $formatDate(summary.done_at, 'HH:mm') }}
                            </span>
                        </div>
                        <span>{{ summary.description }}</span>
                    </div>
                </template>
            </transmission-clock>
        </div>
        <div v-else-if="kid">
            <transmission-clock
                v-for="(summary, i) in kid.reportings"
                :key="'summary' + i"
                :closing="closing"
                :day="day"
                :opening="opening"
                :summary="summary"
            />
        </div>
        <divider
            v-if="showDivider"
            :closing="closing"
            :opening="opening"
        />
        <template v-if="events">
            <div
                v-for="(event, i) in events"
                v-if="!outOfDay(event) && !weeklySummaries"
                :key="'segment-weekly' + i"
            >
                <SegmentKids
                    :key="'segment ' + i"
                    :closing="closing"
                    :day="day"
                    :event="event"
                    :inner-text="innerText"
                    :nursery="nursery"
                    :opening="opening"
                    :show-menu="showMenu"
                    :show-note="showNote"
                    :user="user"
                />
            </div>
        </template>
    </div>
</template>

<script>
    import moment from 'moment';
    import SegmentKids from './SegmentKids.vue';
    import Divider from './Divider.vue';
    import Clock from './Clock.vue';
    import TransmissionClock from '@/modules/legacy/components/Modules/Transmission/TransmissionClock.vue';
    import {useIntervalFn} from '@vueuse/core';

    export default {
        components: {
            TransmissionClock,
            SegmentKids,
            divider: Divider,
            clock: Clock,
        },
        props: {
            user: {},
            nursery: {},
            day: {},
            opening: {},
            closing: {},
            events: {},
            showMenu: {},
            showClock: {
                type: Boolean,
                default: true,
            },
            showDivider: {
                type: Boolean,
                default: true,
            },
            innerText: {
                type: Boolean,
                default: false,
            },
            showNote: {
                type: Boolean,
                default: false,
            },
            small: {
                type: Boolean,
                default: false,
            },
            weeklySummaries: {type: Array},
            kid: {type: Object},
        },
        data: () => ({
            now: moment().unix(),
            fullsize: false,
        }),
        computed: {
            summaries() {
                return this.weeklySummaries?.filter(summary => this.onDay(summary));
            },
        },
        watch: {
            fullsize(val) {
                const topBar = document.getElementById('topBar');
                if (val) {
                    topBar.style.zIndex = '50';
                } else {
                    topBar.style.zIndex = '100';
                }
            },
        },
        mounted() {
            useIntervalFn(() => {
                this.now = moment().unix();
            }, 1000 * 60);
        },
        methods: {

            clockEnabled() {
                return this.unixToPercent(this.now) >= 0 && this.unixToPercent(this.now) <= 100;
            },

            unixToPercent(unix, limit = false) {
                const openingTimestamp = moment(this.day).clone().hours(moment.duration(this.opening).hours())
                    .minutes(moment.duration(this.opening).minutes())
                    .seconds(moment.duration(this.opening).seconds())
                    .unix();

                const closingTimestamp = moment(this.day).clone().hours(moment.duration(this.closing).hours())
                    .minutes(moment.duration(this.closing).minutes())
                    .seconds(moment.duration(this.closing).seconds())
                    .unix();

                const percent = ((unix - openingTimestamp) * 100) / (closingTimestamp - openingTimestamp);

                if (limit) {
                    if (percent > 100) {
                        return 100;
                    } else if (percent < 0) {
                        return 0;
                    }
                }

                return percent;
            },

            outOfDay(item) {
                const percentStartedAt = this.unixToPercent(item.started_at, true);
                const percentEndedAt = this.unixToPercent(item.ended_at, true);

                return percentStartedAt === 100 || percentEndedAt === 0;
            },

            onDay(summary) {
                return this.unixToPercent(summary.done_at) > 0 && this.unixToPercent(summary.done_at) < 100;
            },

            originalImage(photo, description) {
                const url = 'background: url("' + photo.photo_url + '") center center no-repeat; background-size: contain;';
                if (description) {
                    return url + 'bottom: 60px;';
                }

                return url;
            },
        },
    };
</script>

<style
    lang="scss"
    scoped
>
    .planning-generator {
        @apply tw-bg-gray-50;
        position: relative;
        display: block;
        height: 30px;
        -webkit-print-color-adjust: exact !important; /* Chrome, Safari */
        print-color-adjust: exact !important; /*Firefox*/

        &.small {
            height: 25px;
        }
    }

    .fullsize {
        cursor: pointer;
        z-index: 2010;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, .9);

        .img {
            z-index: 2011;
            position: fixed;
            top: 20px;
            left: 20px;
            right: 20px;
            bottom: 20px;
        }

        .description {
            z-index: 2012;
            position: fixed;
            left: 20px;
            right: 20px;
            bottom: 20px;
            color: white;
            text-align: center;
        }

        .close-button {
            z-index: 2013;
            position: fixed;
            top: 20px;
            right: 20px;
            color: rgba(255, 255, 255, .5);

            &:hover {
                color: white;

            }

            .fa, .svg-inline--fa {
                font-size: 50px;
            }
        }
    }
</style>
