<template>
    <MBox class="TagsManagerListItem">
        <template v-if="tagsWithCategory(category.value).count()">
            <CListRow
                v-for="(tag, j) in tagsWithCategory(category.value)"
                :key="'tag' + j + 'cat' + index"
                class="TagsManagerListItem__container"
                :hover="false"
            >
                <div class="TagsManagerListItem__header">
                    <TagPill :tag="tag.attributes"/>
                    <div class="tw-truncate">
                        {{ tag.attributes.name }}
                    </div>
                </div>
                <MButton
                    v-if="can('update', 'tags')"
                    class="tw-shrink-0"
                    variant="link"
                    @click="$emit('copyTagAndShowModal', tag)"
                >
                    <FontAwesomeIcon icon="fas fa-edit"/>
                </MButton>
            </CListRow>
        </template>
        <div
            v-else
            class="TagsManagerListItem__empty"
        >
            <h4 class="tw-text-gray-500">
                {{ __('tag:there_is_no_tag') }}
            </h4>
        </div>
    </MBox>
</template>

<script lang="ts">
    import {defineComponent} from 'vue';
    import TagPill from '@/modules/tag/components/TagPill.vue';
    import useAbility from '@/modules/app/composables/useAbility';

    export default defineComponent({
        components: {
            TagPill,
        },
        props: {
            category: {
                type: Object,
                required: true,
            },
            tagsWithCategory: {
                type: Function,
                required: true,
            },
            index: {
                type: Number,
                required: true,
            },
        },
        setup() {
            const {can} = useAbility();

            return {
                can,
            };
        },
    });
</script>

<style lang="scss" scoped>
    .TagsManagerListItem {
        @apply tw-p-4 tw-max-h-60 tw-h-full tw-overflow-auto;
    }

    .TagsManagerListItem__container {
        @apply tw-flex tw-flex-row tw-justify-between tw-items-center tw-gap-2;
    }

    .TagsManagerListItem__header {
        @apply tw-flex tw-flex-row tw-items-center tw-gap-2 tw-min-w-0;
    }

    .TagsManagerListItem__empty {
        @apply tw-h-full tw-flex tw-items-center tw-justify-center tw-text-center;
    }
</style>
