export type DocTemplatesVariablesByType = {
    type: string;
    variables: string[];
};

export default function(): DocTemplatesVariablesByType[] {
    return [
        {
            type: 'contract',
            variables: ['nursery_variables', 'kid_variables', 'contract_variables', 'billing_variables', 'caf_variables', 'deposit_variables', 'company_variables', 'family1_variables', 'family2_variables', 'other_variables'],
        },
        {
            type: 'staff_contract',
            variables: ['nursery_variables', 'staff_contract_variables', 'staff_variables', 'other_variables'],
        },
        {
            type: 'kid',
            variables: ['nursery_variables', 'kid_variables', 'contract_variables', 'billing_variables', 'caf_variables', 'deposit_variables', 'company_variables', 'family1_variables', 'family2_variables', 'other_variables'],
        },
        {
            type: 'family_member',
            variables: ['nursery_variables', 'contract_variables', 'billing_variables', 'caf_variables', 'deposit_variables', 'company_variables', 'other_variables'],
        },
        {
            type: 'staff',
            variables: ['nursery_variables', 'staff_variables', 'staff_contract_variables', 'other_variables'],
        },
        {
            type: 'transaction',
            variables: ['nursery_variables', 'transaction_variables', 'other_variables'],
        },
        {
            type: 'sepa_mandate',
            variables: ['sepa_mandate_variables', 'nursery_variables', 'family1_variables', 'family2_variables', 'customer_account_variables', 'other_variables'],
        },
        {
            type: 'certificate',
            variables: ['nursery_variables', 'kid_variables', 'customer_account_variables', 'other_variables'],
        },
        {
            type: 'default',
            variables: ['nursery_variables', 'kid_variables', 'contract_variables', 'billing_variables', 'caf_variables', 'deposit_variables', 'company_variables', 'family1_variables', 'family2_variables', 'other_variables', 'sepa_mandate_variables'],
        },
    ];
}
