<template>
    <h1
        class="h1 tw-font-grandhotel tw-text-4xl tw-mb-0"
        :class="baseClass"
    >
        <slot/>
    </h1>
</template>

<script lang="ts">
    import {computed, defineComponent} from 'vue';

    export default defineComponent({
        components: {},
        props: {
            variant: {type: String, default: 'primary'},
        },
        setup(props) {
            const baseClass = computed(() => {
                const output = [] as any[];

                switch (props.variant) {
                    case 'primary':
                        output.push('tw-text-blue-500');
                        break;
                    case 'purple':
                        output.push('tw-text-purple-600');
                        break;
                    case 'pink':
                        output.push('tw-text-pink-600');
                        break;
                }

                return output;
            });

            return {
                baseClass,
            };
        },
    });
</script>

