<template>
    <SimplePageTemplate>
        <template #title>
            <MPageTitle variant="family">
                {{ __('app:pages.documents') }}
            </MPageTitle>
        </template>
        <template
            v-if="can('create', 'documents')"
            #actions
        >
            <CButton
                :icon-plus="true"
                :to="{name: 'documents.create'}"
                variant="primary"
            >
                {{ __('common:add_document') }}
            </CButton>
        </template>
        <template #content>
            <DocumentList
                :bus="bus"
                :edit-item-fn="editItemFn"
                permission-key="documents"
                show-organizations
                :show-visibilities="{
                    staffs_access: true,
                    parents_access: true,
                    web_access: true,
                }"
            />
            <router-view
                @created="bus.emit('documentCreated', $event)"
                @deleted="bus.emit('documentDeleted', $event)"
                @updated="bus.emit('documentUpdated', $event)"
            />
        </template>
    </SimplePageTemplate>
</template>

<script lang="ts">
    import SimplePageTemplate from '@/modules/app/components/templates/SimplePageTemplate.vue';
    import {defineComponent} from 'vue';
    import useAbility from '@/modules/app/composables/useAbility';
    import type {BusType} from '@/modules/document/components/organisms/DocumentList.vue';
    import DocumentList from '@/modules/document/components/organisms/DocumentList.vue';
    import mitt from 'mitt';
    import {useRouter} from 'vue-router/composables';
    import type DocumentModel from '@/modules/document/models/DocumentModel';

    export default defineComponent({
        components: {
            DocumentList,
            SimplePageTemplate,
        },
        setup() {
            const bus = mitt<BusType>();
            const router = useRouter();

            const {can} = useAbility();

            function editItemFn(document: DocumentModel) {
                router?.push({name: 'documents.edit', params: {document: document.getKey()}});
            }

            return {
                can,
                bus,
                editItemFn,
            };
        },
    });
</script>
