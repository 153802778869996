<template>
    <SimplePageTemplate>
        <template #title>
            <MPageTitle variant="equipment">
                {{ __('app:pages.equipments') }}
            </MPageTitle>
        </template>
        <template #content>
            <CTabMenu
                class="tw-mb-4"
                variant="equipment"
            >
                <CTabMenuItem
                    :active="selectedTab === 'equipment'"
                    variant="equipment"
                    @click="selectTab('equipment')"
                >
                    {{ __('common:equipment_other') }}
                </CTabMenuItem>
                <CTabMenuItem
                    :active="selectedTab === 'room'"
                    variant="equipment"
                    @click="selectTab('room')"
                >
                    {{ __('common:room_other') }}
                </CTabMenuItem>
            </CTabMenu>
            <EquipmentIndex
                v-if="selectedTab === 'equipment'"
                :nursery="nursery"
            />
            <RoomsIndex
                v-if="selectedTab === 'room'"
                :nursery="nursery"
            />
        </template>
    </SimplePageTemplate>
</template>

<script lang="ts">
    import {defineComponent, ref} from 'vue';
    import EquipmentIndex from '@/modules/equipment/components/organisms/EquipmentIndex.vue';
    import RoomsIndex from '@/modules/equipment/components/organisms/RoomsIndex.vue';
    import SimplePageTemplate from '@/modules/app/components/templates/SimplePageTemplate.vue';
    import useUrl from '@/modules/app/composables/useUrl';
    import {useRoute} from 'vue-router/composables';

    export default defineComponent({
        components: {
            SimplePageTemplate,
            EquipmentIndex,
            RoomsIndex,
        },
        props: {
            nursery: {
                type: Object,
                required: true,
            },
        },
        setup() {
            const route = useRoute();
            const selectedTab = ref(route.query.nav ?? 'equipment');
            const {pushQueryString} = useUrl();

            function selectTab(tab: string) {
                selectedTab.value = tab;
                pushQueryString('nav', tab);
            }

            return {
                selectedTab,
                selectTab,
            };
        },
    });
</script>
