<script lang="ts" setup>
    import {computed} from 'vue';
    import type useStaffsStatistics from '@/modules/human-resources/composables/statistics/useStaffsStatistics';
    import type StaffModel from '@/modules/human-resources/models/StaffModel';
    import StaffAvatar from '@/modules/app/components/atoms/avatars/StaffAvatar.vue';

    const props = withDefaults(
        defineProps<{
            staffStatistics: ReturnType<typeof useStaffsStatistics>
            staff: StaffModel;
            from?: string;
            to?: string;
        }>(), {
            from: undefined,
            to: undefined,
        });

    const computedCalendars = computed(() => {
        return props.staffStatistics.calendars.value.all().map(calendar => {
            return {
                model: calendar,
                real: props.staffStatistics.totalEventsForCalendar.value(calendar).real,
                forecast: props.staffStatistics.totalEventsForCalendar.value(calendar).forecast,
                total: props.staffStatistics.totalEventsForCalendar.value(calendar).total,
            };
        });
    });
</script>

<template>
    <MBox class="tw-p-4">
        <div class="lg:tw-flex tw-items-center tw-w-full">
            <div class="tw-flex tw-items-center lg:tw-w-2/3">
                <StaffAvatar
                    class="tw-shrink-0 tw-w-16"
                    :staff-model="staff"
                />
                <div class="tw-ml-3">
                    <div
                        class="tw-font-grandhotel !tw-text-3xl tw-capitalize"
                        :class="staff.genderColor"
                    >
                        <router-link
                            class="!tw-text-inherit !tw-text-3xl"
                            :to="{
                                name: 'staffs.show',
                                params: {nursery: $route.params.nursery, staff: staff.id},
                            }"
                        >
                            {{ $fullName(staff.attributes) }}
                        </router-link>
                    </div>
                    <div class="tw-text-gray-500 tw-text-sm">
                        <div v-if="staff.attributes.job">
                            {{ staff.attributes.job }}
                        </div>
                        <div
                            v-if="staff.activeContract(from, to)"
                            class="tw-font-semibold"
                        >
                            {{
                                __('hr:time.contract_with_time', {
                                    time: staff.activeContract(from, to).attributes.hours_per_week,
                                })
                            }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="lg:tw-w-1/3 tw-text-gray-500 tw-text-sm lg:tw-text-right tw-mt-5 lg:tw-mt-0 tw-truncate">
                <div
                    v-if="staff.attributes.no"
                    class="tw-mb-2"
                >
                    {{ staff.attributes.no }}
                </div>
                <div
                    v-if="staff.attributes.birthdate"
                    class="tw-mb-2"
                >
                    {{ $formatDate(staff.attributes.birthdate) }}
                </div>
                <div v-if="staff.attributes.mobile_phone || staff.attributes.phone">
                    {{ staff.attributes.mobile_phone ? staff.attributes.mobile_phone : staff.attributes.phone }}
                </div>
                <div v-if="staff.attributes.email">
                    {{ staff.attributes.email }}
                </div>
            </div>
        </div>
        <div
            v-if="computedCalendars.length"
            class="tw-border-t tw-border-gray-200 tw-pt-2 tw-mt-2"
        >
            <MHeading
                class="tw-mb-2"
                level="h4"
            >
                {{ __('hr:time.real_hours_by_calendar') }}
            </MHeading>
            <div class="tw-flex tw-items-center tw-w-full tw-overflow-auto">
                <CTooltip
                    v-for="(calendar, c) in computedCalendars"
                    :key="'calendar' + calendar.model.getKey()"
                    class="tw-flex-1"
                    placement="top"
                >
                    <div
                        class="tw-bg-blue-100 tw-p-2 tw-rounded-md tw-text-center"
                        :class="{'tw-ml-3': c > 0}"
                    >
                        <div class="tw-uppercase tw-text-blue-800/60 tw-text-sm tw-tracking-wider tw-font-semibold tw-mb-1">
                            {{ calendar.model.attributes.name }}
                        </div>
                        <div class="tw-uppercase tw-text-blue-900/70 tw-text-base tw-font-semibold">
                            {{ calendar.real }}
                        </div>
                    </div>
                    <template #content>
                        <div class="tw-text-sm tw-font-semibold">
                            <div class="tw-mb-1">
                                {{
                                    __('common:forecasted_with_value', {value: calendar.forecast})
                                }}
                            </div>
                            <div class="tw-mb-2">
                                {{
                                    __('common:real_with_value', {value: calendar.real})
                                }}
                            </div>
                            <div class="tw-border-t tw-border-gray-200 tw-pt-2">
                                {{
                                    __('hr:time.total_with_time', {time: calendar.total})
                                }}
                            </div>
                        </div>
                    </template>
                </CTooltip>
            </div>
        </div>
    </MBox>
</template>

