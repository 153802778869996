<script setup lang="ts">
    import {nextTick, onMounted, type PropType, provide, ref, toRef, watch} from 'vue';
    import MFloatingContext from '@/modules/meeko-ui/components/MFloatingContext';
    import type {Placement} from '@floating-ui/vue';
    import MFloating from '@/modules/meeko-ui/components/MFloating.vue';
    import {useElementHover, whenever} from '@vueuse/core';

    const props = defineProps({
        placement: {
            type: String as PropType<Placement>,
            default: 'bottom-start',
            validator: (value: string) => [
                'top',
                'top-start',
                'top-end',
                'right',
                'right-start',
                'right-end',
                'bottom',
                'bottom-start',
                'bottom-end',
                'left',
                'left-start',
                'left-end',
            ].includes(value),
        },
        dontInteractWithPanel: {
            type: Boolean,
            default: false,
        },
        label: {
            type: String,
            default: 'Tooltip',
        },
        hoverable: {
            type: Boolean,
            default: true,
        },
    });

    const context = MFloatingContext({
        placement: toRef(props, 'placement'),
        trigger: ref('hover'),
        arrow: ref(true),
        arrowPadding: ref(6),
        offset: ref(8),
    });

    provide('MFloatingContext', context);

    const {
        buttonEl,
        arrowEl,
        panelEl,
        panelId,
        isOpen,
        finalPlacement,
        isReady,
        middlewareData,
        setHovering,
        isInjected,
    } = context;

    const myButton = ref();
    const myArrow = ref();
    const myPanel = ref();

    onMounted(() => {
        buttonEl.value = myButton.value;
    });

    whenever(isInjected, () => {
        nextTick(() => {
            panelEl.value = myPanel.value;
            arrowEl.value = myArrow.value;
        });
    });

    const isButtonHovered = useElementHover(myButton);
    watch(isButtonHovered, setHovering);

    const isPanelHovered = useElementHover(myPanel);
    watch(isPanelHovered, setHovering);
</script>

<template>
    <div
        ref="myButton"
        class="MTooltip"
    >
        <slot :context="context"/>
        <MFloating>
            <section
                v-if="hoverable"
                :id="panelId"
                ref="myPanel"
                :aria-hidden="!isOpen"
                class="MTooltipPanel"
                :class="{'MTooltipPanel--prevent-interactions': dontInteractWithPanel}"
                role="tooltip"
                tabindex="-1"
            >
                <div
                    ref="myArrow"
                    class="MTooltipPanel__arrow"
                    :class="['MTooltipPanel__arrow--'+finalPlacement, {'MTooltipPanel__arrow--animated': isReady}]"
                    :style="{
                        left: middlewareData.arrow?.x != null ? `${middlewareData.arrow.x}px` : '',
                        top: middlewareData.arrow?.y != null ? `${middlewareData.arrow.y}px` : '',
                    }"
                >
                    <svg
                        viewBox="0 0 14 6"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M4.7 1a3.1 3.1 0 0 1 4.6 0L14 6H0l4.7-5Z"
                            fill="currentcolor"
                        />
                    </svg>
                </div>
                <div>
                    <slot
                        :ctx="context"
                        name="content"
                    >
                        {{ label }}
                    </slot>
                </div>
            </section>
        </MFloating>
    </div>
</template>

<style scoped lang="scss">
    .MTooltip {
        @apply tw-inline-flex;
    }

    .MTooltipPanel {
        @apply tw-px-2 tw-py-1 tw-text-sm tw-font-display tw-font-normal tw-shadow-md tw-rounded-md tw-bg-primary-600 tw-text-white;
        @apply print:tw-hidden;
    }

    .MTooltipPanel--prevent-interactions {
        @apply tw-pointer-events-none;
    }

    .MTooltipPanel__arrow {
        width: 14px;
        height: 14px;
        @apply tw-absolute tw-text-primary-600;
    }

    .MTooltipPanel__arrow--animated {
        transition: all 0.65s cubic-bezier(0.43, 0.33, 0.14, 1.01) 0s;
        transition-property: top, right, bottom, left;
    }

    .MTooltipPanel__arrow--top, .MTooltipPanel__arrow--top-start, .MTooltipPanel__arrow--top-end {
        bottom: -12px;
        @apply tw--rotate-180;
    }

    .MTooltipPanel__arrow--right, .MTooltipPanel__arrow--right-start, .MTooltipPanel__arrow--right-end {
        left: -12px;
        @apply tw--rotate-90;
    }

    .MTooltipPanel__arrow--bottom, .MTooltipPanel__arrow--bottom-start, .MTooltipPanel__arrow--bottom-end {
        top: -12px;
    }

    .MTooltipPanel__arrow--left, .MTooltipPanel__arrow--left-start, .MTooltipPanel__arrow--left-end {
        right: -12px;
        @apply tw-rotate-90;
    }
</style>
