<template>
    <h1
        class="MPageTitle"
        :class="baseClass"
    >
        <span>
            <slot/>
        </span>
    </h1>
</template>

<script lang="ts">
    import {computed, defineComponent} from 'vue';

    export default defineComponent({
        components: {},
        props: {
            variant: {type: String, default: 'primary'},
        },
        setup(props) {
            const baseClass = computed(() => {
                const output = [] as any[];

                switch (props.variant) {
                    case 'dashboard':
                        output.push('MPageTitle--variant-dashboard');
                        break;
                    case 'family':
                        output.push('MPageTitle--variant-family');
                        break;
                    case 'billing':
                        output.push('MPageTitle--variant-billing');
                        break;
                    case 'staff':
                        output.push('MPageTitle--variant-staff');
                        break;
                    case 'registration':
                        output.push('MPageTitle--variant-registration');
                        break;
                    case 'communication':
                        output.push('MPageTitle--variant-communication');
                        break;
                    case 'settings':
                        output.push('MPageTitle--variant-settings');
                        break;
                    case 'equipment':
                        output.push('MPageTitle--variant-equipment');
                        break;
                    case 'primary':
                    case 'base':
                    default:
                        output.push('MPageTitle--variant-primary');
                }

                return output;
            });

            return {
                baseClass,
            };
        },
    });
</script>

<style lang="scss" scoped>
    .MPageTitle {
        @apply tw-font-grandhotel tw-antialiased tw-text-5xl tw-leading-none;

        &--variant-primary {
            span {
                @apply tw-bg-clip-text tw-text-transparent tw-bg-gradient-to-br tw-from-[#5cafff] tw-to-[#1979d7];
            }
        }

        &--variant-dashboard {
            span {
                @apply tw-bg-clip-text tw-text-transparent tw-bg-gradient-to-br tw-from-[#00bcd4] tw-to-[#00a5bb];
            }
        }

        &--variant-family {
            span {
                @apply tw-bg-clip-text tw-text-transparent tw-bg-gradient-to-br tw-from-[#a96aea] tw-to-[#5323f1];
            }
        }

        &--variant-billing {
            span {
                @apply tw-bg-clip-text tw-text-transparent tw-bg-gradient-to-br tw-from-[#ff8901] tw-to-[#ff5200];
            }
        }

        &--variant-staff {
            span {
                @apply tw-bg-clip-text tw-text-transparent tw-bg-gradient-to-br tw-from-[#f34680] tw-to-[#e10054];
            }
        }

        &--variant-registration {
            span {
                @apply tw-bg-clip-text tw-text-transparent tw-bg-gradient-to-br tw-from-[#07aadb] tw-to-[#249fac];
            }
        }

        &--variant-communication {
            span {
                @apply tw-bg-clip-text tw-text-transparent tw-bg-gradient-to-br tw-from-yellow-600 tw-to-yellow-700;
            }
        }

        &--variant-settings {
            span {
                @apply tw-bg-clip-text tw-text-transparent tw-bg-gradient-to-br tw-from-[#7c90a4] tw-to-[#2f5b86];
            }
        }

        &--variant-equipment {
            span {
                @apply tw-bg-clip-text tw-text-transparent tw-bg-gradient-to-br tw-from-module-equipment-500 tw-to-module-equipment-600;
            }
        }
    }
</style>

