import type {TemplateRepositoryPort} from '@/modules/human-resources/template/application/TemplateRepositoryPort';
import type TemplateModel from '@/modules/human-resources/models/TemplateModel';
import {collect, MqlTransaction, MqlTransactionError} from '@meekohq/lumos';
import type TemplatePlanningModel from '@/modules/human-resources/models/TemplatePlanningModel';
import {
    ConflictedPlanningEventsError,
} from '@/modules/human-resources/template/domain/errors/ConflictedPlanningEventsError';
import {
    ConflictedTemplateEventsError,
} from '@/modules/human-resources/template/domain/errors/ConflictedTemplateEventsError';

export class TemplateRepositoryAdapter implements TemplateRepositoryPort {
    public async saveTemplate(template: TemplateModel): Promise<TemplateModel> {
        const mqlTransaction = new MqlTransaction();

        const promises = collect();

        promises.push(template.save({mqlRunner: mqlTransaction}));

        template.organizationsPivots().value().each(pivot => {
            promises.push(pivot.save({mqlRunner: mqlTransaction}));
        });

        template.planningTemplates().value().each(planning => {
            promises.push(planning.save({mqlRunner: mqlTransaction}));

            planning.planningEvents().value().each(event => {
                promises.push(event.save({mqlRunner: mqlTransaction}));
            });
        });

        try {
            await mqlTransaction.run(true);
        } catch (e) {
            if (e instanceof MqlTransactionError && this.hasValidationErrors(e)) {
                throw new ConflictedTemplateEventsError();
            }

            throw e;
        }

        return template;
    }

    public async savePlanning(planning: TemplatePlanningModel): Promise<TemplatePlanningModel> {
        const mqlTransaction = new MqlTransaction();

        const promises = collect();

        if (planning.template().value()) {
            promises.push(planning.template().value().save({mqlRunner: mqlTransaction}));
        }

        promises.push(planning.save({mqlRunner: mqlTransaction}));

        planning.planningEvents().value().each(event => {
            promises.push(event.save({mqlRunner: mqlTransaction}));
        });

        try {
            await mqlTransaction.run(true);
        } catch (e) {
            if (e instanceof MqlTransactionError && this.hasValidationErrors(e)) {
                throw new ConflictedPlanningEventsError();
            }

            throw e;
        }

        return planning;
    }

    public async deleteTemplate(template: TemplateModel): Promise<boolean> {
        return template.delete();
    }

    public async deletePlanning(planning: TemplatePlanningModel): Promise<boolean> {
        return planning.delete();
    }

    private hasValidationErrors(e: MqlTransactionError): boolean {
        const operations = e.transaction.operations;

        return operations.some(operation => {
            return operation.result.unprocessable;
        });
    }
}
