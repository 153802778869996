<template>
    <CForm @submit.prevent="save">
        <CFormGroup>
            <CLabel>
                {{ __('billing_customer:entity') }}
            </CLabel>
            <CButtonGroup class="tw-w-full">
                <CButton
                    class="tw-w-full"
                    :disabled="customerModel.exists"
                    :variant="!customerModel.isCompany ? 'primary' : undefined"
                    @click="customerModel.attributes.person = 'natural'"
                >
                    <i
                        aria-hidden="true"
                        class="fas fa-user tw-mr-2"
                    />{{ __('billing_customer:entity_natural') }}
                </CButton>
                <CButton
                    class="tw-w-full"
                    :disabled="customerModel.exists"
                    :variant="customerModel.isCompany ? 'primary' : undefined"
                    @click="customerModel.attributes.person = 'legal'"
                >
                    <i
                        aria-hidden="true"
                        class="fad fa-city tw-mr-2"
                    />{{ __('billing_customer:entity_legal') }}
                </CButton>
            </CButtonGroup>
        </CFormGroup>
        <CFormGroup v-if="!customerModel.isCompany && !customerFamily && !familyModel">
            <CLabel>{{ __('common:family') }} *</CLabel>
            <FamilyFinder
                v-model="selectedFamily"
                button-class="tw-w-full"
                only-user-organizations
            >
                <template #button-text-empty>
                    {{ __('billing_customer:select_a_family') }}
                </template>
            </FamilyFinder>
            <CFormErrorMessageList :errors="familyPivotError.get('family_id')"/>
        </CFormGroup>
        <CFormGroup>
            <CLabel>{{ __('billing_customer:customer_name') }} *</CLabel>
            <CInput
                ref="customerName"
                v-model="customerModel.attributes.name"
                v-focus
                :has-error="customerError.has('name')"
                :placeholder="__('billing_customer:mr_and_mrs_dupont')"
                size="lg"
            />
            <CFormErrorMessageList :errors="customerError.get('name')"/>
        </CFormGroup>
        <CFormGroup v-if="customerModel.isCompany">
            <CLabel>{{ __('billing_customer:tva_number') }}</CLabel>
            <CInput
                v-model="customerModel.attributes.vat_number"
                :has-error="customerError.has('vat_number')"
                :placeholder="__('billing_customer:tva_country_example')"
            />
            <CFormErrorMessageList :errors="customerError.get('vat_number')"/>
        </CFormGroup>
        <CDisclosure
            :open="false"
            :title="__('billing_customer:accounting_information_other')"
            variant="gray"
        >
            <CFormGroup>
                <CLabel>
                    {{ __('billing_customer:customer_number') }}
                    <CHelpTooltip
                        class="tw-ml-1"
                        position="right"
                    >
                        <div v-html="__('billing_customer:for_accounting')"/>
                    </CHelpTooltip>
                </CLabel>
                <CInput
                    ref="customerName"
                    v-model="customerModel.attributes.account_number"
                    :has-error="customerError.has('account_number')"
                    :placeholder="__('billing_customer:customer_number_xxxx')"
                />
                <CFormErrorMessageList :errors="customerError.get('account_number')"/>
            </CFormGroup>
        </CDisclosure>
        <CFormSubmitTrigger/>
        <AlertModal
            v-if="isSameAccountWarningModalVisible"
            button-variant="primary"
            :confirm-text="__('common:actions.confirm')"
            :title="__('billing_customer:account_number_exists')"
            @confirmed="confirmDuplicationAndSave"
            @hidden="isSameAccountWarningModalVisible = false"
        >
            <Properties
                :customer-model="customerWithSameAccountNumber"
                :family-model-collection="[]"
            />
            <div class="pt-4">
                <slot>
                    {{ __('billing_customer:do_you_really_want_to_duplicate_account_number_ask') }}
                </slot>
            </div>
        </AlertModal>
    </CForm>
</template>

<script lang="ts">
    import type {PropType} from 'vue';
    import {computed, defineComponent, onMounted, onUnmounted, ref, toRef, watch} from 'vue';
    import useSaveCustomer from '@/modules/cashier/composables/core/customer/useSaveCustomer';
    import {catcher, ValidationError} from '@meekohq/lumos';
    import CustomerModel from '@/modules/cashier/models/CustomerModel';
    import type FamilyModel from '@/modules/family/models/FamilyModel';
    import FamilyFinder from '@/modules/family/components/family/FamilyFinder.vue';
    import mitt from 'mitt';
    import AlertModal from '@/modules/app/components/organisms/AlertModal.vue';
    import Properties
        from '@/modules/cashier/components/core/customer/CustomerHero/molecules/CustomerHeroProperties.vue';
    import useError from '@/modules/app/composables/useError';

    export default defineComponent({
        components: {Properties, AlertModal, FamilyFinder},
        props: {
            customerModel: {
                type: Object as PropType<CustomerModel>,
                default: () => new CustomerModel(),
            },
            familyModel: {
                type: Object as PropType<FamilyModel> || null,
                default: null,
            },
        },
        setup: function(props, {emit}) {
            const customerError = useError();

            // Create error handler for the pivot between customer and family.
            const familyPivotError = useError();

            // Get the family of the customer
            const customerFamily = computed(() => props.customerModel?.families().value().first());

            // Init the family passed to the family selector
            const selectedFamily = ref<FamilyModel>();
            selectedFamily.value = props.familyModel ? props.familyModel as FamilyModel : customerFamily.value as FamilyModel;

            const customerWithSameAccountNumber = ref<CustomerModel>();

            const isSameAccountWarningModalVisible = ref(false);

            const isConfirmedDuplicateAccount = ref(false);

            const bus = mitt<{ stored: void, error: Error }>();

            const {saveCustomer, loading} = useSaveCustomer({
                bus,
                customer: toRef(props, 'customerModel'),
                customerWithSameAccountNumber,
                selectedFamily,
                isSameAccountWarningModalVisible,
                isConfirmedDuplicateAccount,
            });

            watch(loading, loading => emit('loading', loading));

            watch(() => props.customerModel.attributes.person, person => {
                if (person === 'natural') {
                    props.customerModel.attributes.vat_number = '';
                }
            });

            onMounted(() => {
                bus.on('stored', onStored);
                bus.on('error', error => {
                    if (error instanceof ValidationError) {
                        catcher()
                            .on(ValidationError, error => {
                                if (error.model instanceof CustomerModel) {
                                    customerError.addValidationError(error);
                                } else {
                                    familyPivotError.addValidationError(error);
                                }
                            })
                            .catch(error);
                    }
                });
            });

            onUnmounted(() => {
                bus.off('stored');
                bus.off('error');
            });

            function save() {
                // Reset both error handler
                customerError.reset();
                familyPivotError.reset();
                customerWithSameAccountNumber.value = undefined;
                saveCustomer();
            }

            function onStored() {
                emit('saved', props.customerModel);
            }

            function confirmDuplicationAndSave() {
                isConfirmedDuplicateAccount.value = true;
                save();
            }

            return {
                isConfirmedDuplicateAccount,
                isSameAccountWarningModalVisible,
                customerWithSameAccountNumber,
                selectedFamily,
                customerFamily,
                customerError,
                familyPivotError,
                confirmDuplicationAndSave,
                save,
            };
        },
    });
</script>
