import {Model} from '@meekohq/lumos';
import RequestModel from '@/modules/request/models/RequestModel';
import RequestTypeStageModel from '@/modules/request/models/RequestTypeStageModel';

export default class RequestStageModel extends Model {
    public type = 'request/stages';

    attributes: {
        id: string,
        account_id: string | undefined,
        request_id: string | undefined,
        source_id: string | undefined,
        source_type: string | undefined,
        request_type_stage_id: string | undefined,
        explanation: string | undefined,
        created_at: string | undefined,
    } = {
            id: this.uuid(),
            account_id: undefined,
            request_id: undefined,
            source_id: undefined,
            source_type: undefined,
            request_type_stage_id: undefined,
            explanation: undefined,
            created_at: undefined,
        };

    request() {
        return this.belongsTo('request', RequestModel, 'request_id');
    }

    source() {
        return this.morphTo('source');
    }

    requestTypeStage() {
        return this.belongsTo('requestTypeStage', RequestTypeStageModel, 'request_type_stage_id');
    }

}
