import app from '@/bootstrap/app';
import '@/assets/main.scss';
import '@/bootstrap/jquery';
import '@/bootstrap/moment';
import '@/bootstrap/popper';
import '@/bootstrap/pusher';
import type {Contracts} from '@meekohq/lumos';
import {Container} from '@meekohq/lumos';

const kernel = app.make<Contracts.Foundation.KernelContract>(Container.contracts.kernel);

kernel.bootstrap();
