<template>
    <CModal
        id="saveTemplateModal"
        ref="modal"
        :header-title="__('planning:manage_typical_week')"
        size="7xl"
        :visible="true"
        @hidden="$emit('hidden')"
        @shown="onShown"
    >
        <loader
            v-if="weekTemplatesLoading"
            light="true"
            shadow="false"
            size="sm"
            :title="__('common:loading_dots')"
        />
        <template v-else-if="selectedTemplate || filteredTemplates.length">
            <div class="tw-flex tw-flex-wrap tw-mb-7">
                <div class="tw-w-full tw-mb-3 sm:!tw-w-1/2 sm:tw-mb-0 sm:tw-ml-3">
                    <CFormGroup>
                        <CLabel>{{ __('hr_calendar:update_typical_week') }}</CLabel>
                        <CInline>
                            <div class="tw-w-1/2 tw-h-full tw-mr-3">
                                <CButton
                                    class="tw-w-full"
                                    @click="
                                        $refs.weekTemplatesDropdown.toggle()
                                    "
                                >
                                    {{ selectedTemplate.attributes.name }}
                                    <i
                                        aria-hidden="true"
                                        class="fa fa-caret-down tw-ml-1"
                                    />
                                </CButton>
                                <CDropdown
                                    ref="weekTemplatesDropdown"
                                    legacy-mode
                                >
                                    <CList class="tw-cursor-pointer">
                                        <CListRow
                                            v-for="(weekTemplate,
                                                    i) in filteredTemplates"
                                            :key="'weekTemplate' + i"
                                            @click="
                                                selectedTemplate = weekTemplate
                                            "
                                        >
                                            <i
                                                v-if="
                                                    selectedTemplate.id === weekTemplate.id
                                                "
                                                class="fa fa-check tw-mr-1"
                                            />
                                            {{ weekTemplate.attributes.name }}
                                        </CListRow>
                                    </CList>
                                </CDropdown>
                            </div>
                            <CTooltip placement="top">
                                <CButton
                                    ref="updateWeekNameBtn"
                                    variant="primary"
                                    @click="saveWeekTemplate"
                                >
                                    <i
                                        aria-hidden="true"
                                        class="fas fa-pencil-alt"
                                    />
                                </CButton>
                                <template #content>
                                    {{ __('hr_calendar:update_name_of_week') }}
                                </template>
                            </CTooltip>

                            <CTooltip placement="top">
                                <CButton
                                    class="tw-ml-2"
                                    variant="danger"
                                    @click="removeWeekTemplate"
                                >
                                    <i
                                        aria-hidden="true"
                                        class="fas fa-trash-alt"
                                    />
                                </CButton>
                                <template #content>
                                    {{ __('hr_calendar:delete_typical_week') }}
                                </template>
                            </CTooltip>
                        </CInline>
                    </CFormGroup>
                </div>

                <div class="tw-mt-3 sm:tw-mt-0 sm:tw-self-end sm:tw-ml-auto">
                    <CButton
                        variant="primary"
                        @click="newWeekTemplate"
                    >
                        <i
                            aria-hidden="true"
                            class="fa fa-plus tw-mr-2"
                        />{{ __('hr_calendar:new_week') }}
                    </CButton>
                </div>
            </div>

            <loader
                v-if="calendarLoading"
                light="true"
                shadow="false"
                size="sm"
                :title="__('common:loading_dots')"
            />
            <div
                v-else
                class="tw-border-t tw-border-gray-200 tw-pt-3"
            >
                <div class="tw-flex tw-items-center tw-flex-wrap tw-gap-2 tw-mb-2">
                    <div class="tw-flex-1">
                        <OrganizationFinder
                            v-model="selectedTemplateOrganizations"
                            button-class="sm:tw-w-min tw-w-full"
                            :disabled="isLoadingAttachOrganizations"
                            multi
                            multi-minimum="1"
                            only-user-organizations
                            @input="debouncedAttachOrganizationsToTemplate"
                        />
                    </div>
                    <CTooltip
                        class="sm:tw-w-min tw-w-full"
                        placement="top"
                    >
                        <CButton
                            class="tw-w-full"
                            size="sm"
                            variant="gray"
                            @click="fillEventsWithCurrentWeek"
                        >
                            <template #left-icons>
                                <FontAwesomeIcon icon="fas fa-calendar-plus"/>
                            </template>
                            {{ __('hr_calendar:use_template_number_weeks', {number: currentWeek}) }}
                        </CButton>
                        <template #content>
                            {{ __('hr_calendar:add_week_in_copying_week_template', {number: currentWeek}) }}
                        </template>
                    </CTooltip>
                    <CButton
                        class="sm:tw-w-min tw-w-full"
                        size="sm"
                        variant="danger"
                        @click="resetEvents(null)"
                    >
                        <template #left-icons>
                            <FontAwesomeIcon icon="fas fa-trash-alt"/>
                        </template>
                        {{ __('common:actions.delete_all_dots') }}
                    </CButton>
                </div>

                <CText
                    v-if="eventLoading"
                    variant="gray"
                >
                    <FontAwesomeIcon
                        class="tw-mr-1"
                        icon="fas fa-circle-notch fa-spin"
                    />
                    {{ __('common:saving_dots') }}
                </CText>
                <CText
                    v-else
                    variant="success"
                >
                    <FontAwesomeIcon
                        class="tw-mr-1"
                        icon="fas fa-check"
                    />
                    {{ __('hr_calendar:week_saved_successfully') }}
                </CText>

                <div class="tw-flex tw-mt-4">
                    <div class="tw-w-24 tw-mt-4">
                        <div class="tw-h-9"/>
                        <div
                            v-for="(planningTemplate, i) in planningsTemplates"
                            :key="'planning_template_staff' + i"
                            class="tw-flex tw-items-center tw-w-full tw-h-9 tw-mb-1"
                        >
                            <StaffFinder
                                :allowed-organizations="selectedTemplateOrganizations"
                                class="tw-w-full"
                                :excluded-ids="planningTemplate.staff().value() ? [planningTemplate.staff().value().getKey()] : null"
                                @fallback="switchStaffPlanning(planningTemplate)"
                                @input="switchStaffPlanning(planningTemplate, $event)"
                            >
                                <template #fallback>
                                    {{ __('hr_calendar:not_allocated') }}
                                </template>
                                <template #trigger="{toggle}">
                                    <div
                                        class="tw-h-full tw-w-full tw-rounded hover:tw-bg-gray-50 hover:tw-border tw-border-dashed tw-border-blue-200 tw-cursor-pointer tw-p-1"
                                        @click="toggle"
                                        @mouseleave="$refs['staffDetail' + i][0].hide()"
                                        @mouseover="$refs['staffDetail' + i][0].show()"
                                    >
                                        <div
                                            v-if="planningTemplate.staff().value()"
                                            class="tw-flex tw-items-center tw-h-full"
                                        >
                                            <StaffAvatar
                                                class="tw-mr-1 tw-shrink-0 tw-w-6"
                                                :staff-model="planningTemplate.staff().value()"
                                            />
                                            <div
                                                class="tw-text-base tw-truncate"
                                                :class="planningTemplate.staff().value().genderColor"
                                            >
                                                {{ planningTemplate.staff().value().fullname }}
                                            </div>
                                        </div>
                                        <div
                                            v-else
                                            class="tw-flex tw-items-center tw-h-full"
                                        >
                                            <div class="tw-w-6 tw-h-6 tw-bg-gray-300 tw-rounded-full tw-mr-1 tw-shrink-0 tw-flex tw-items-center">
                                                <i
                                                    aria-hidden="true"
                                                    class="fas fa-question tw-text-gray-500 tw-w-full tw-text-center tw-text-sm"
                                                />
                                            </div>
                                            <div class="tw-text-gray-600 tw-text-base tw-truncate">
                                                N/A
                                            </div>
                                        </div>
                                        <CDropdown
                                            :ref="'staffDetail' + i"
                                            :clickable="false"
                                            hoverable
                                            legacy-mode
                                            placement="top-start"
                                        >
                                            <div class="tw-capitalize">
                                                <div
                                                    v-if="planningTemplate.staff().value()"
                                                    :class="planningTemplate.staff().value().genderColor"
                                                >
                                                    {{ planningTemplate.staff().value().fullname }}
                                                </div>
                                                <div
                                                    v-else
                                                    class="tw-text-gray-600"
                                                >
                                                    {{ __('hr_calendar:not_allocated') }}
                                                </div>
                                            </div>
                                            <div class="tw-my-2">
                                                <EventTotals
                                                    :contract="planningTemplate.staff().value() && planningTemplate.staff().value().activeContract() ? `${planningTemplate.staff().value().activeContract().attributes.hours_per_week}h` : null"
                                                    :forecast="totalHours(planningTemplate)"
                                                    :real="null"
                                                />
                                            </div>
                                            <div class="tw-flex tw-border-t tw-border-gray-200 tw-mt-1 tw-pt-2">
                                                <CButton
                                                    class="tw-mr-2"
                                                    size="xs"
                                                    variant="link"
                                                    @click.stop="toggle"
                                                >
                                                    <i
                                                        aria-hidden="true"
                                                        class="fa fa-retweet tw-mr-1"
                                                    />{{ __('common:actions.change') }}
                                                </CButton>
                                                <CButton
                                                    size="xs"
                                                    variant="link-danger"
                                                    @click.stop="confirmRemovePlanningTemplate(planningTemplate)"
                                                >
                                                    <i
                                                        aria-hidden="true"
                                                        class="fa fa-trash-alt tw-mr-1"
                                                    />{{ __('common:actions.delete_dots') }}
                                                </CButton>
                                            </div>
                                        </CDropdown>
                                    </div>
                                </template>
                            </StaffFinder>
                        </div>
                    </div>

                    <div class="tw-flex tw-justify-between tw-w-full tw-overflow-x-scroll">
                        <div
                            v-for="(dayOfWeek,
                                    dayIndex) in daysOfWeek"
                            :key="'currentDay' + dayIndex"
                            class="tw-w-full tw-min-w-48 tw-ml-3 showDayHoursTrigger"
                        >
                            <div class="tw-relative tw-h-5">
                                <div class="tw-capitalize tw-text-blue-500 tw-w-full tw-text-center showDay">
                                    {{ dayOfWeek.format('dddd') }}
                                </div>
                            </div>

                            <div class="tw-h-7 tw--ml-2 tw-pr-3.5 tw-mt-1">
                                <Hours
                                    class="showDayHours"
                                    :closing="closing(dayOfWeek, true)"
                                    grid-on-hover
                                    :opening="opening(dayOfWeek, true)"
                                    simple
                                    small
                                />
                            </div>

                            <div
                                v-for="(planningTemplate, i) in planningsTemplates"
                                :key="'planning_template' + i"
                                class="tw-flex tw-items-center tw-h-9 tw-mb-1"
                            >
                                <PlanningGenerator
                                    always-show-ghost
                                    :closing="closing(dayOfWeek, true)"
                                    create-with-modal
                                    :day="dayOfWeek"
                                    is-template
                                    :opening="opening(dayOfWeek, true)"
                                    :show-clock="false"
                                    :show-divider="false"
                                    show-note
                                    size="md"
                                    :template-events="eventsForCurrentDay(dayOfWeek, planningTemplate)"
                                    :week-template="planningTemplate.id"
                                    @pushEventTemplate="planningTemplate.planningEvents().value().all().push($event)"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <StaffFinder
                    :allowed-organizations="selectedTemplateOrganizations"
                    class="tw-w-full"
                    :excluded-ids="staffFinderExceptionIds"
                    @fallback="addPlanningTemplate"
                    @input="addPlanningTemplate"
                >
                    <template #trigger="{toggle}">
                        <CVStack
                            align="center"
                            class="tw-border-2 tw-border-dashed tw-rounded-xl tw-py-1 tw-cursor-pointer tw-duration-300 tw-transition-alltw-border-gray-300 hover:tw-border-blue-500 hover:tw-bg-blue-100 tw-group"
                            distribute="center"
                            @click="toggle"
                        >
                            <CText
                                class="tw-text-gray-700 group-hover:tw-text-black"
                                font-weight="semibold"
                            >
                                <template v-if="planningTemplateLoading">
                                    <i
                                        aria-hidden="true"
                                        class="fa fa-circle-notch fa-spin tw-text-blue-500 tw-mr-2"
                                    />
                                    {{ __('common:adding_in_progress_dots') }}
                                </template>
                                <template v-else>
                                    <i
                                        aria-hidden="true"
                                        class="fad fa-plus-circle tw-text-blue-500 tw-mr-2"
                                    />
                                    {{ __('common:actions.add_line') }}
                                </template>
                            </CText>
                        </CVStack>
                    </template>
                    <template #fallback>
                        {{ __('hr_calendar:not_allocated') }}
                    </template>
                </StaffFinder>
            </div>

            <EventModal
                v-if="selectedEvent"
                ref="modalEditEvent"
                :event="selectedEvent"
                is-template
                @add="onEventAdded"
                @delete="onEventDeleted"
                @edit="onEventEdited"
                @hidden="selectedEvent = null"
            />
        </template>
        <div
            v-else
            class="tw-p-5 tw-bg-blue-100 tw-text-blue-500 tw-rounded-md tw-text-center"
        >
            <div class="tw-mb-3">
                <CButton
                    :icon-external-link="true"
                    onclick="Intercom('showArticle', 5538054)"
                    size="none"
                    variant="link"
                >
                    {{ __('common:show_guide') }}
                </CButton>
            </div>
            <CButton
                variant="primary"
                @click="newWeekTemplate"
            >
                <i
                    aria-hidden="true"
                    class="fa fa-plus tw-mr-2"
                />{{ __('hr_calendar:new_week_dots') }}
            </CButton>
        </div>

        <template #footer>
            <CButton
                class="tw-ml-auto"
                @click="$refs.modal.hide()"
            >
                {{ __('common:actions.close') }}
            </CButton>
        </template>
    </CModal>
</template>

<script lang="ts">
    import type {PropType} from 'vue';
    import {defineComponent, ref, set, toRef, watch} from 'vue';
    import useGlobalWeekTemplate from '@/modules/human-resources/composables/calendar/useGlobalWeekTemplate';
    import type StaffModel from '@/modules/human-resources/models/StaffModel';
    import Hours from '@/modules/legacy/components/Modules/PlanningGenerator/Hours.vue';
    import Line from '@/modules/human-resources/components/calendar/Line.vue';
    import EventModal from '@/modules/human-resources/components/calendar/EventModal.vue';
    import EventTotals from '@/modules/human-resources/components/calendar/EventTotals.vue';
    import OrganizationFinder from '@/modules/organization/components/OrganizationFinder.vue';
    import StaffFinder from '@/modules/request/components/Teams/StaffFinder.vue';
    import StaffAvatar from '@/modules/app/components/atoms/avatars/StaffAvatar.vue';

    export default defineComponent({
        components: {
            StaffAvatar,
            OrganizationFinder,
            Hours,
            PlanningGenerator: Line,
            EventModal,
            EventTotals,
            StaffFinder,
        },
        props: {
            staffsFromCurrentWeek: {
                type: Array as PropType<StaffModel[]>,
                required: true,
            },
            currentWeek: {
                type: Number,
                required: true,
            },
        },
        setup(props) {
            const {
                modal,
                onShown,

                calendarLoading,
                opening,
                closing,
                daysOfWeek,

                staffFinderExceptionIds,
                filteredTemplates,
                selectedTemplate,
                weekTemplatesLoading,
                newWeekTemplate,
                selectedTemplateOrganizations,
                isLoadingAttachOrganizations,
                debouncedAttachOrganizationsToTemplate,
                saveWeekTemplate,
                removeWeekTemplate,
                fillEventsWithCurrentWeek,

                planningsTemplates,
                planningTemplateLoading,
                addPlanningTemplate,
                switchStaffPlanning,
                confirmRemovePlanningTemplate,

                totalHours,
                eventsForCurrentDay,
                resetEvents,
                deleteEvents,
                selectedEvent,
                modalEditEvent,
                onEventAdded,
                onEventEdited,
                onEventDeleted,
            } = useGlobalWeekTemplate(toRef(props, 'staffsFromCurrentWeek'));
            const eventLoading = ref(false);

            watch(() => selectedTemplate.value?.planningTemplates().value(), () => {
                eventLoading.value = true;
                setTimeout(() => {
                    eventLoading.value = false;
                }, 300);
            }, {
                deep: true,
            });

            return {
                set,
                eventLoading,
                staffFinderExceptionIds,
                modal,
                onShown,
                selectedTemplateOrganizations,
                isLoadingAttachOrganizations,
                debouncedAttachOrganizationsToTemplate,

                calendarLoading,
                opening,
                closing,
                daysOfWeek,

                filteredTemplates,
                selectedTemplate,
                weekTemplatesLoading,
                newWeekTemplate,
                saveWeekTemplate,
                removeWeekTemplate,
                fillEventsWithCurrentWeek,

                planningsTemplates,
                planningTemplateLoading,
                addPlanningTemplate,
                switchStaffPlanning,
                confirmRemovePlanningTemplate,

                totalHours,
                eventsForCurrentDay,
                resetEvents,
                deleteEvents,
                selectedEvent,
                modalEditEvent,
                onEventAdded,
                onEventEdited,
                onEventDeleted,
            };
        },
    });
</script>

<style lang="scss" scoped>
    .showDayHours {
        @apply tw-opacity-0 tw-transition-all tw-ease-in-out tw-duration-300;
    }

    .showDay {
        @apply tw-transition-all tw-ease-in-out tw-absolute tw-top-5;
    }

    .showDayHoursTrigger:hover {
        .showDayHours {
            @apply tw-opacity-100;
        }

        .showDay {
            @apply tw-top-0;
        }
    }
</style>
