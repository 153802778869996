<template>
    <CDropdownMenu arrow>
        <template #button>
            <CButton
                icon-ellipsis
                size="sm"
                variant="ghost"
            />
        </template>
        <CDropdownMenuItem
            icon="fa fa-check"
            @click="setDefaultBillingAddress"
        >
            {{ __('billing_core:set_as_default') }}
        </CDropdownMenuItem>
        <CDropdownMenuItem
            icon="fa fa-pen"
            @click="editModal.show"
        >
            {{ __('billing_setting:update_address') }}
        </CDropdownMenuItem>
        <CDropdownMenuItem
            :disabled="orders.isNotEmpty()"
            icon="fa fa-trash"
            variant="danger"
            @click="tryToDelete"
        >
            {{ __('billing_core:delete_address_dots') }}
        </CDropdownMenuItem>
        <template #extra>
            <DeleteAddressModal
                ref="deleteModal"
                :address-model="addressModel"
                @deleted="$emit('deleted', addressModel)"
            />
        </template>
    </CDropdownMenu>
</template>

<script lang="ts">
    import type {PropType} from 'vue';
    import {computed, defineComponent, ref, toRef} from 'vue';
    import type {Collection} from '@meekohq/lumos';
    import type CustomerModel from '@/modules/cashier/models/CustomerModel';
    import type AddressModel from '@/modules/cashier/models/AddressModel';
    import type OrderModel from '@/modules/cashier/models/OrderModel';
    import DeleteAddressModal from '@/modules/cashier/components/core/address/organisms/DeleteAddressModal.vue';
    import UpdateAddressModal from '@/modules/cashier/components/core/address/organisms/UpdateAddressModal.vue';
    import useModal from '@/modules/app/composables/useModal';

    export default defineComponent({
        components: {DeleteAddressModal},
        props: {
            customerModel: {
                type: Object as PropType<CustomerModel>,
                required: true,
            },
            addressModel: {
                type: Object as PropType<AddressModel>,
                required: true,
            },
        },

        setup(props) {
            const menu = ref();
            const deleteModal = ref();
            const customerModel = toRef(props, 'customerModel');
            const addressModel = toRef(props, 'addressModel');

            const orders = computed((): Collection<OrderModel> => {
                return props.addressModel.orders().value();
            });

            function setDefaultBillingAddress() {
                customerModel.value.attributes.default_billing_address_id = addressModel.value.id;
                customerModel.value.save();
            }

            function tryToDelete() {
                if (orders.value.isEmpty()) {
                    deleteModal.value.show();
                }
            }

            const editModal = useModal({
                component: UpdateAddressModal,
                props: {
                    addressModel: computed(() => addressModel.value.clone()),
                },
                listeners: () => ({
                    update: (newAddressModel: AddressModel) => {
                        newAddressModel.copyTo(props.addressModel);
                    },
                }),
            });

            return {
                menu,
                deleteModal,
                orders,
                setDefaultBillingAddress,
                tryToDelete,
                editModal,
            };
        },
    });
</script>

