import type SplittableEvent from '@/modules/human-resources/utils/calendar/SplittableEvent';
import {collect} from '@meekohq/lumos';
import MergeEvent from '@/modules/legacy/libs/periodSplitter/MergeEvent';
import type {ISplittedEvent} from '@/modules/legacy/libs/periodSplitter/SplitEvent';
import SplitEvent from '@/modules/legacy/libs/periodSplitter/SplitEvent';
import type TemplateEventModel from '@/modules/human-resources/models/TemplateEventModel';

// Get working time of template events
export default class TemplatedWorkingTime {
    private events: TemplateEventModel[] = [];
    private day: number | null = null;

    public setEvents(events: TemplateEventModel[]): this {
        this.events = events;

        return this;
    }

    public setDay(day: number | null): this {
        this.day = day;

        return this;
    }

    public getWorkingTime(): number {
        const events = this.mapEventModelsToSplittableEvents();

        const constrainEvents = this.constrainEvents(events);

        const mergedEvents = this.mergeEventsWithFilter(constrainEvents);

        const splittedEvents = this.splitEventsWithStrategy(mergedEvents);

        // Calculate sum of events time
        let total = 0;
        splittedEvents.forEach(event => {
            const factor = event.sources[0].eventType().value().attributes.factor;
            total += (event.endedAt - event.startedAt) * factor / 60;
        });

        return total;
    }

    protected constrainEvents(events: SplittableEvent[]): SplittableEvent[] {
        if (this.day) {
            return collect(events).filter(item => {
                return item.sources[0].attributes.day === this.day;
            }).toArray();
        }

        return events;
    }


    protected mergeEventsWithFilter(events: SplittableEvent[]): SplittableEvent[] {
        const merger = new MergeEvent(events).setFilterCallback(mergeFilterCallback);

        return merger.merge();
    }

    protected splitEventsWithStrategy(events: SplittableEvent[]): SplittableEvent[] {
        const splitter = new SplitEvent(events).setStrategyCallback(splitStrategyCallback);

        return splitter.split();
    }

    protected mapEventModelsToSplittableEvents(): SplittableEvent[] {
        return this.events.map(event => {
            return event.mapToSplittableEvent();
        });
    }
}

// Callback for merge fonction
const mergeFilterCallback = (event: SplittableEvent, overlappingEvents: SplittableEvent[]): SplittableEvent[] => {
    return collect(overlappingEvents).filter(overlappingEvent => {
        const overlappingOrigin = collect(overlappingEvent.sources).first();
        const eventOrigin = collect(event.sources).first();

        return overlappingOrigin.eventType().value().attributes.factor === eventOrigin.eventType().value().attributes.factor;
    }).toArray();
};

// Callback for split fonction
const splitStrategyCallback = (
    baseEvent: SplittableEvent,
    overlappingEvent: SplittableEvent | null,
    splittedEvents: ISplittedEvent<SplittableEvent>,
): SplittableEvent[] => {
    const {currentEvent} = splittedEvents;
    const sourceBase = baseEvent?.sources[0];
    const sourceCurrent = currentEvent?.sources[0];
    const sourceOverlap = overlappingEvent?.sources[0];

    if (sourceBase.eventType().value().attributes.factor === 0) {
        return [];
    }

    if (sourceBase.eventType().value().attributes.factor === 1) {
        return [baseEvent];
    }

    if (
        currentEvent
        && overlappingEvent
        && sourceOverlap.eventType().value().attributes.factor === 1
        && sourceCurrent.eventType().value().attributes.factor === -1
    ) {
        return [currentEvent];
    }

    return [];
};
