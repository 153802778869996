<template>
    <CDisclosure
        class="tw-my-3 tw-rounded-lg tw-border tw-border-gray-200"
        :open="false"
    >
        <template #title>
            <div class="tw-w-11/12 tw-flex tw-items-center">
                <div class="tw-flex-1 tw-pr-2">
                    <MTooltip placement="top">
                        <CCheckbox
                            v-model="checkedAllocations"
                            :disabled="staff.balanceAllocations().value().isEmpty()"
                            :half-checked="areSomeAllocationsConfirmed && !areAllAllocationsConfirmed"
                            @click.stop="updateEveryAllocations"
                        >
                            {{ staff.fullname }}
                        </CCheckbox>
                        <template #content>
                            <template v-if="areAllAllocationsConfirmed && staff.balanceAllocations().value().isNotEmpty()">
                                {{ __('hr_balance:period_confirmed_at', {date: lastAllocationConfirmedDateTime}) }}
                            </template>
                            <template v-else-if="staff.balanceAllocations().value().isEmpty()">
                                {{ __('hr_balance:no_allocation_to_confirm', {fullname: staff.fullname}) }}
                            </template>
                            <template v-else>
                                {{ __('hr_balance:pending_allocation_to_confirm', {fullname: staff.fullname}) }}
                            </template>
                        </template>
                    </MTooltip>
                </div>
                <div
                    v-for="balance in balances"
                    :key="balance.type_id"
                    class="tw-flex-1 tw-flex tw-px-2"
                >
                    <CBadge
                        v-if="balance.total"
                        class="tw-mr-2"
                        size="sm"
                    >
                        <CText font-weight="semibold">
                            {{ __('hr_balance:available', {context: balance.unit, count: Number(balance.total)}) }}
                        </CText>
                    </CBadge>

                    <CBadge
                        v-if="balance.value"
                        class="tw-ml-auto tw-self-center"
                        size="sm"
                        variant="primary"
                    >
                        <CText font-weight="semibold">
                            {{
                                __('hr_balance:available', {
                                    context: balance.unit,
                                    count: Number(balance.value),
                                })
                            }}
                        </CText>
                    </CBadge>
                </div>
            </div>
        </template>
        <slot>
            <StaffAllocationsSummary
                :staff="staff"
                @showEvent="$emit('showEvent', $event)"
            />
        </slot>
    </CDisclosure>
</template>

<script lang="ts">
    import type {PropType} from 'vue';
    import {computed, defineComponent, ref, watch} from 'vue';
    import type StaffModel from '@/modules/human-resources/models/StaffModel';
    import type BalanceTypeModel from '@/modules/human-resources/models/BalanceTypeModel';
    import {balanceUnitValue} from '@/modules/human-resources/utils/balance/BalanceUnitValue';
    import StaffAllocationsSummary from '@/modules/human-resources/components/balance/List/StaffAllocationSummary.vue';
    import type {Collection} from '@meekohq/lumos';
    import {collect} from '@meekohq/lumos';
    import moment from 'moment/moment';
    import ContractModel from '@/modules/human-resources/models/ContractModel';
    import NumberValue from '@/modules/app/utils/Values/NumberValue';
    import __ from '@/modules/app/utils/i18n-facade';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';

    export default defineComponent({
        name: 'StaffAllocationsListItem',
        components: {StaffAllocationsSummary},
        props: {
            staff: {type: Object as PropType<StaffModel>, required: true},
            balanceTypes: {type: Object as PropType<Collection<BalanceTypeModel>>, required: true},
        },
        setup(props) {
            const balances = ref<any[]>([]);
            const checkedAllocations = ref(false);

            async function generateBalances(types: Collection<BalanceTypeModel>) {
                balances.value = [];

                types.each(type => {
                    let balance = {};

                    if (type.attributes.show_balance) {
                        const allAllocations = getAllAllocations(type.attributes.id);
                        // Calculating the sum of all allocations
                        const allAllocationsSum = allAllocations.sum(allocation => allocation.attributes.value);
                        // Round the sum to 2 decimals to avoid floating point errors
                        const allAllocationsSumNumber = new NumberValue(allAllocationsSum as number).round(2);

                        balance = {
                            total: allAllocationsSumNumber.getValue(),
                        };
                    }

                    // Calculating the sum of all allocations that are not contracts
                    const sum = props.staff.balanceAllocations().value()
                        .where('attributes.balance_type_id', type.attributes.id)
                        .where('attributes.source_type', '!=', new ContractModel().type)
                        .sum(allocation => allocation.attributes.value);

                    // Round the sum to 2 decimals to avoid floating point errors
                    const sumNumber = new NumberValue(sum as number).round(2);

                    balance = {
                        ...balance,
                        value: sumNumber.getValue(),
                        name: type.attributes.name,
                        type_id: type.attributes.id,
                        unit: balanceUnitValue[type.attributes.unit as string],
                    };

                    balances.value.push(balance);
                });
            }

            function getAllAllocations(balanceType) {
                return props.staff.balanceAllocations()
                    .setContainer('allBalanceAllocations')
                    .value()
                    .where('attributes.balance_type_id', balanceType);
            }

            const areSomeAllocationsConfirmed = computed(() =>
                props.staff.balanceAllocations().value().contains(item => item.attributes.confirmed_at !== null),
            );

            const areAllAllocationsConfirmed = computed(() => {
                checkedAllocations.value = props.staff.balanceAllocations().value().isNotEmpty()
                    && props.staff.balanceAllocations().value().whereNull('attributes.confirmed_at').isEmpty();

                return checkedAllocations.value;
            });

            const updateEveryAllocations = async function() {
                const promises: Array<Promise<any>> = [];

                if (checkedAllocations.value) {
                    props.staff.balanceAllocations().mutate(value => value.each(item => item.attributes.confirmed_at = null));
                } else {
                    props.staff.balanceAllocations().mutate(value => value.each(item => {
                        if (item.attributes.confirmed_at === null) {
                            item.attributes.confirmed_at = moment().toISOString(true);
                        }
                    }));
                }
                props.staff.balanceAllocations().value().each(allocation => promises.push(allocation.save()));

                await Promise.all(promises);
                useNotification().success(__('hr_balance:successfully_updated'));
            };

            const lastAllocationConfirmedDateTime = computed(() =>
                moment(
                    props.staff.balanceAllocations().value()
                        .sortByDesc('attributes.confirmed_at')
                        .first()?.attributes.confirmed_at,
                ).format('DD/MM/YYYY HH:mm'),
            );

            // Generate balances data on each change
            if (props.balanceTypes) {
                const types = collect(Object.assign({}, props.balanceTypes.all()));
                generateBalances(types);
            }

            // Listen all props because balance type and staff change needed balance regeneration
            watch(() => props, () => {
                if (props.balanceTypes) {
                    const types = collect(Object.assign({}, props.balanceTypes.all()));
                    generateBalances(types);
                }
            }, {deep: true});

            return {
                areAllAllocationsConfirmed,
                checkedAllocations,
                areSomeAllocationsConfirmed,
                updateEveryAllocations,
                balances,
                lastAllocationConfirmedDateTime,
                moment,
            };
        },
    });
</script>

<style lang="scss" scoped>
    .CDisclosureButton {
        @apply tw-my-3 tw-rounded-lg tw-border tw-border-gray-200;

        > div > div {
            @apply tw-border-r-2 tw-border-gray-300 tw-border-opacity-60;
        }

        > div > div:last-child {
            @apply tw-border-r-0;
        }
    }
</style>
