<template>
    <div class="tw-flex tw-flex-col tw-gap-8">
        <div>
            <MHeading class="tw-mb-4">
                <FontAwesomeIcon
                    class="tw-mr-1 tw-text-primary-500"
                    icon="fa fa-file"
                />
                {{ __('billing_setting:preset_format_proforma') }}
            </MHeading>
            <CForm>
                <CFormThreeColumns>
                    <CFormGroup>
                        <CLabel>{{ __('billing_setting:proforma_default_name') }}</CLabel>
                        <MInput
                            id="proforma_name"
                            v-model="nursery.proforma_name"
                            :placeholder="__('billing_setting:proformat_default_name_example')"
                            @keyup.enter="save"
                        />
                    </CFormGroup>
                    <CFormGroup>
                        <CLabel>
                            {{ __('billing_setting:proformat_reference') }}
                            <MHelpTooltip class="tw-ml-2">
                                {{ __('billing_setting:yyyy_and_mm_will_be_replaced_by_current_month_and_year') }}
                            </MHelpTooltip>
                        </CLabel>
                        <MInput
                            id="proforma_ref"
                            v-model="nursery.proforma_ref"
                            class="sm:tw-text-right"
                            :placeholder="__('billing_setting:proformat_reference_example')"
                            @keyup.enter="save"
                        />
                    </CFormGroup>
                    <CFormGroup>
                        <CLabel>{{ __('common:increment') }}</CLabel>
                        <MInput
                            id="proforma_num"
                            v-model="nursery.proforma_num"
                            placeholder="1"
                            @keyup.enter="save"
                        />
                    </CFormGroup>
                </CFormThreeColumns>
            </CForm>
            <MHeading class="tw-mb-4 tw-mt-5">
                <FontAwesomeIcon
                    class="tw-mr-1 tw-text-primary-500"
                    icon="fa fa-file-invoice"
                />
                {{ __('billing_setting:preset_format_invoice') }}
            </MHeading>
            <CForm>
                <CFormThreeColumns>
                    <CFormGroup>
                        <CLabel>{{ __('billing_setting:default_invoice_name') }}</CLabel>
                        <input
                            id="name"
                            v-model="nursery.invoice_name"
                            class="form-control form-control-sm"
                            :placeholder="__('billing_setting:monthly_invoice')"
                            @keyup.enter="save"
                        >
                    </CFormGroup>
                    <CFormGroup>
                        <CLabel>
                            {{ __('billing_setting:invoice_reference') }}
                            <MHelpTooltip class="tw-ml-2">
                                {{ __('billing_setting:yyyy_and_mm_will_be_replaced_by_current_month_and_year') }}
                            </MHelpTooltip>
                        </CLabel>
                        <MInput
                            id="invoice_ref"
                            v-model="nursery.invoice_ref"
                            class="sm:tw-text-right"
                            :placeholder="__('billing_setting:invoice_reference_example')"
                            @keyup.enter="save"
                        />
                    </CFormGroup>
                    <CFormGroup>
                        <CLabel>{{ __('common:increment') }}</CLabel>
                        <MInput
                            id="invoice_num"
                            v-model="nursery.invoice_num"
                            placeholder="1"
                        />
                    </CFormGroup>
                </CFormThreeColumns>
                <CFormThreeColumns>
                    <CFormGroup>
                        <CLabel>{{ __('billing_setting:invoice_payable_within') }}</CLabel>
                        <CInputGroup>
                            <CInput
                                v-model.number="nursery.invoice_due_at"
                                placeholder="30"
                                type="number"
                            />
                            <CInputAddon class="tw-lowercase">
                                <template v-if="nursery.invoice_due_at && nursery.invoice_due_at > 1">
                                    {{ __('common:day_other') }}
                                </template>
                                <template v-else>
                                    {{ __('common:day_one') }}
                                </template>
                            </CInputAddon>
                        </CInputGroup>
                    </CFormGroup>
                    <CFormGroup>
                        <CLabel>{{ __('billing_setting:default_vat') }}</CLabel>
                        <CInputGroup>
                            <CInput
                                v-model.number="nursery.invoice_tva"
                                placeholder="20"
                                type="number"
                            />
                            <CInputAddon>
                                %
                            </CInputAddon>
                        </CInputGroup>
                    </CFormGroup>
                    <CFormGroup>
                        <CLabel>{{ __('common:curency_one') }}</CLabel>
                        <CFormSelect
                            v-model="nursery.currency"
                            :options="currencies"
                            text-path="text"
                            value-path="value"
                        />
                    </CFormGroup>
                </CFormThreeColumns>
                <CFormGroup>
                    <CCheckbox v-model="nursery.invoice_hours">
                        {{ __('billing_setting:display_hourly_rate_in_pdf_invoice') }}
                    </CCheckbox>
                </CFormGroup>
                <CFormTwoColumns>
                    <CFormGroup>
                        <CLabel>{{ __('billing_invoice:terms_and_conditions') }}</CLabel>
                        <CFormTextArea
                            v-model="nursery.invoice_conditions"
                            :placeholder="__('billing_setting:cash_cheque_payable_to_dots')"
                            rows="4"
                        />
                    </CFormGroup>
                    <CFormGroup>
                        <CLabel>{{ __('billing_setting:footer') }}</CLabel>
                        <CFormTextArea
                            v-model="nursery.invoice_footer"
                            :placeholder="`${nursery.name}..`"
                            rows="4"
                        />
                    </CFormGroup>
                </CFormTwoColumns>
            </CForm>
        </div>
        <CDisclosure
            :open="false"
            :title="__('common:additional_information')"
        >
            <CFormGroup class="md:tw-w-1/2">
                <CLabel>
                    {{ __('billing_setting:pdf_invoice_name') }}
                    <MHelpTooltip class="tw-ml-2">
                        {{ __('billing_setting:number_will_be_replaced_by_invoice_number') }}
                    </MHelpTooltip>
                </CLabel>
                <CInputGroup>
                    <CInput
                        v-model="nursery.invoice_filename"
                        :placeholder="__('billing_setting:pdf_invoice_name')"
                    />
                    <CInputAddon class="tw-lowercase">
                        {{ __('common:pdf_extension') }}
                    </CInputAddon>
                </CInputGroup>
            </CFormGroup>
        </CDisclosure>
        <MButton
            class="tw-self-end"
            variant="primary"
            @click="save"
        >
            {{ __('common:actions.save') }}
        </MButton>
    </div>
</template>

<script>
    import _head from 'lodash-es/head';
    import _forEach from 'lodash-es/forEach';
    import route from '@/modules/legacy/libs/ziggy';
    import useApi from '@/modules/app/composables/useApi';
    import __ from '@/modules/app/utils/i18n-facade';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';

    export default {
        props: {
            nursery: {
                type: Object,
                required: true,
            },
        },
        data() {
            return {
                currencies: [{
                                 value: null,
                                 text: __('billing_setting:choose_devise'),
                             },
                             {
                                 value: '€',
                                 text: __('billing_setting:devise.euro'),
                             },
                             {
                                 value: '$',
                                 text: __('billing_setting:devise.united_states_dollars'),
                             },
                             {
                                 value: '£',
                                 text: __('billing_setting:devise.pound_sterling'),
                             },
                             {
                                 value: '₪',
                                 text: __('billing_setting:devise.shekel'),
                             },
                             {
                                 value: 'CHF',
                                 text: __('billing_setting:devise.swiss_franc'),
                             },
                             {
                                 value: 'DJF',
                                 text: __('billing_setting:devise.djiboutian_franc'),
                             },
                             {
                                 value: 'XAF',
                                 text: __('billing_setting:devise.west_afrikan_cfa_franc'),
                             },
                             {
                                 value: 'XOF',
                                 text: __('billing_setting:devise.central_africa_cfa_franc'),
                             },
                             {
                                 value: 'KMF',
                                 text: __('billing_setting:devise.comorian_franc'),
                             },
                             {
                                 value: 'XPF',
                                 text: __('billing_setting:devise.pacific_franc'),
                             },
                             {
                                 value: 'MUR',
                                 text: __('billing_setting:devise.mauritian_rupee'),
                             },
                             {
                                 value: 'MAD',
                                 text: __('billing_setting:devise.moroccan_dirham'),
                             },
                ],
            };
        },
        methods: {
            save() {
                useApi().legacy.put(route('nurseries.update', {
                    nurseries: this.nursery.id,
                }), {
                    proforma_name: this.nursery.proforma_name,
                    proforma_ref: this.nursery.proforma_ref,
                    proforma_num: this.nursery.proforma_num,
                    invoice_name: this.nursery.invoice_name,
                    invoice_ref: this.nursery.invoice_ref,
                    invoice_num: this.nursery.invoice_num,
                    invoice_due_at: this.nursery.invoice_due_at,
                    invoice_tva: this.nursery.invoice_tva,
                    currency: this.nursery.currency,
                    invoice_conditions: this.nursery.invoice_conditions,
                    invoice_footer: this.nursery.invoice_footer,
                    invoice_hours: this.nursery.invoice_hours,
                    invoice_filename: this.nursery.invoice_filename,
                }).then(response => {
                    this.nursery.invoice_num = response.data.invoice_num;
                    useNotification().success(__('common:parameters_updated_successfully'));
                }).catch(error => {
                    if (error && error.response && error.response.status === 422) {
                        _forEach(error.response.data.errors, value => {
                            useNotification().error(_head(value));
                        });
                    } else {
                        useNotification().error(error);
                    }
                });
            },
        },
    };
</script>
