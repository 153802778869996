import {datadogRum} from '@datadog/browser-rum';
import {config} from '@meekohq/lumos';

export default function() {
    function addAction(name: string, context?: object | undefined) {
        if (config('services.datadog.enabled')) {
            datadogRum.addAction(name, context);
        }
    }

    return {
        addAction,
    };
}
