import type TicketModel from '@/modules/activity/ticket/domain/TicketModel';
import swal from 'sweetalert2/dist/sweetalert2.js';
import __ from '@/modules/app/utils/i18n-facade';
import useMagicModal from '@/modules/app/composables/useMagicModal';
import {MqlOperation} from '@meekohq/lumos';

export default function() {
    // Detach all occurrences from parent of recurrence
    async function detachAllOccurrences(task: TicketModel) {
        // If task is not an occurrence, skip
        if (!task.attributes.parent_id) {
            return;
        }

        const promises = [] as any[];

        task.occurrences().value().each(occurence => {
            occurence.parent().dissociate();
            promises.push(occurence.save());
        });

        await Promise.all(promises);
    }

    // Move the recurrence rules on first occurrence
    async function moveRecurrenceOnFirstOccurrence(task: TicketModel) {
        if (task.attributes.parent_id) {
            return;
        }

        const firstOccurrence = task.occurrences().value().sortBy('attributes.expired_at').first();

        if (firstOccurrence) {
            // Update parent of occurrences by firstOccurrence
            const promises = [] as any[];

            task.occurrences().value().filter(occ => occ.getKey() !== firstOccurrence.getKey()).each(occurence => {
                occurence.parent().associate(firstOccurrence);
                promises.push(occurence.save());
            });

            await Promise.all(promises);

            // Set recurrence rules in firstOccurrence
            firstOccurrence.parent().dissociate();
            firstOccurrence.attributes.recurrence_ruleset = task.attributes.recurrence_ruleset;

            await firstOccurrence.save();

        }
    }

    function askDeleteTask(task: TicketModel, deleteCallback: CallableFunction) {
        // If task is recurring, ask if we keep the recurrence or not
        if (
            (task.attributes.recurrence_ruleset && task.lastOccurrence().value())
            || (task.attributes.parent_id && task.isLastOccurrence)
        ) {
            swal({
                title: __('activity:task_name'),
                text: __('activity:do_you_really_want_to_delete_this_recurring_task_ask'),
                confirmButtonText: __('common:actions.remove'),
                cancelButtonText: __('common:actions.cancel'),
                input: 'checkbox',
                inputPlaceholder: __('activity:delete_upcoming_tasks_as_well'),
            }).then(async result => {
                if (result.value === 1) {
                    // If user want to remove parant task and recurrence, we detach all this occurrences
                    if (task.attributes.recurrence_ruleset && task.occurrences().value().isNotEmpty()) {
                        await detachAllOccurrences(task);
                    }
                    // Delete task without keeping recurrence
                    await deleteCallback();
                } else if (result.value === 0) {
                    // If user want to remove parant task and keep recurrence, we replace parent by first occurrence
                    // If user want to remove last task and keep recurrence, we delete it and create next occurence on API hook
                    if (task.attributes.recurrence_ruleset && task.occurrences().value().isNotEmpty()) {
                        await moveRecurrenceOnFirstOccurrence(task);
                        await deleteCallback();
                    } else {
                        // If recurring task and want to keep reccurence, create next occurrence before remove last
                        await new MqlOperation('activity/tickets/recurrence/next', {
                            id: task.getKey(),
                        }).run();

                        await deleteCallback();
                    }
                } else {
                    return;
                }
            });
        } else {
            useMagicModal().deleteConfirmationModal({
                text: task.extra.deleteConfirmationMessage ?? __('activity:do_you_really_want_to_remove_this_task_ask'),
            }).then(async result => {
                if (result.isConfirmed) {
                    deleteCallback();
                }
            });
        }
    }

    return {
        askDeleteTask,
    };
}
