import {ValidationError} from '@meekohq/lumos';
import {
    AmountToAllocateIsGreaterThanSourceRemainingError,
} from '@/modules/cashier/payment/domain/errors/AmountToAllocateIsGreaterThanSourceRemainingError';
import type {
    AmountToAllocateMustBePositiveError,
} from '@/modules/cashier/payment/domain/errors/AmountToAllocateMustBePositiveError';
import {
    AmountToAllocateIsGreaterThanDestinationRemainingError,
} from '@/modules/cashier/payment/domain/errors/AmountToAllocateIsGreaterThanDestinationRemainingError';
import type {AmountToAllocateIsRequired} from '@/modules/cashier/payment/domain/errors/AmountToAllocateIsRequired';

export class TransactionCreditAllocationErrorInterceptor {
    public static intercept(error: Error): ValidationError | AmountToAllocateIsGreaterThanSourceRemainingError | AmountToAllocateMustBePositiveError | AmountToAllocateIsRequired | AmountToAllocateIsGreaterThanDestinationRemainingError {
        if (error instanceof ValidationError) {
            const validationErrorItems = error.errors.all();

            if (validationErrorItems.find(error => error.code === AmountToAllocateIsGreaterThanSourceRemainingError.code && error.source.pointer === 'amount')) {
                return new AmountToAllocateIsGreaterThanSourceRemainingError();
            }

            if (validationErrorItems.find(error => error.code === AmountToAllocateIsGreaterThanDestinationRemainingError.code && error.source.pointer === 'amount')) {
                return new AmountToAllocateIsGreaterThanDestinationRemainingError();
            }

            return error;
        }

        throw error;
    }
}
