<template>
    <MMenuItems>
        <template v-for="language in availableLanguages">
            <MMenuItem
                :key="language.value"
                @click="changeLanguage(language.value)"
            >
                <template #icon>
                    <FontAwesomeIcon
                        v-if="authUser.attributes.locale === language.value"
                        icon="fa fa-circle-check"
                    />
                    <FontAwesomeIcon
                        v-else
                        icon="far fa-circle"
                    />
                </template>
                <img
                    :alt="language.text"
                    class="UserLanguageSelector__flag tw-mr-2"
                    :src="languageFlagIcons[language.value]"
                >
                <span>{{ language.text }}</span>
            </MMenuItem>
        </template>
    </MMenuItems>
</template>

<script setup lang="ts">
    import useAuth from '@/modules/app/composables/useAuth';
    import {useRouter} from 'vue-router/composables';
    import FlagFR from '@/assets/images/flags/flag-fr.svg';
    import FlagEN from '@/assets/images/flags/flag-en.svg';
    import FlagDE from '@/assets/images/flags/flag-de.svg';
    import useLocale from '@/modules/app/composables/useLocale';

    const router = useRouter();
    const {user: authUser} = useAuth();
    const {availableLanguages} = useLocale();

    const languageFlagIcons = {
        fr: FlagFR,
        en: FlagEN,
        de: FlagDE,
    };

    async function changeLanguage(language: 'de' | 'en' | 'fr') {
        if (authUser.value.attributes.locale === language) {
            return;
        }

        authUser.value.attributes.locale = language;
        await authUser.value.save();

        router.go(0);
    }
</script>

<style scoped lang="scss">
    .UserLanguageSelector__flag {
        @apply tw-w-6 tw-h-auto tw-rounded;
    }
</style>
