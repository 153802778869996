<template>
    <CModal
        :emitter="bus"
        :header-title="__('billing_customer:delete_contact')"
        :hide-header-fullscreen="true"
        size="xs"
        visible
        @hidden="$emit('hidden')"
    >
        <p class="tw-mb-0">
            {{ __('billing_customer:action_will_permanently_delete_contact_action_is_irreversible') }}
        </p>
        <template #footer>
            <CButton
                class="tw-ml-auto"
                @click="$emit('cancel')"
            >
                {{ __('common:actions.cancel') }}
            </CButton>
            <CButton
                class="tw-ml-2"
                :loading="loader.isLoading()"
                variant="danger"
                @click="deleteModel()"
            >
                {{ __('common:actions.delete') }}
            </CButton>
        </template>
    </CModal>
</template>

<script lang="ts">
    import Loader from '@/modules/legacy/helpers/loader.helper';
    import type ContactModel from '@/modules/cashier/models/ContactModel';
    import type {PropType} from 'vue';
    import {defineComponent, ref} from 'vue';
    import mitt from 'mitt';

    export default defineComponent({
        props: {
            contactModel: {
                type: Object as PropType<ContactModel>,
                required: true,
            },
        },
        setup(props, {emit}) {
            const loader = ref(new Loader());

            const bus = mitt<{ hide: void }>();

            function deleteModel() {
                loader.value.start();
                props.contactModel.delete().then(() => {
                    bus.emit('hide');
                    emit('deleted', props.contactModel);
                })
                    .finally(() => {
                        loader.value.stop();
                    });
            }

            return {
                bus,
                loader,
                deleteModel,
            };
        },
    });
</script>

