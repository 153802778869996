<template>
    <div class="TransactionAmountFilter">
        <CButton
            icon-menu
            :size="size"
            @click="openDropdown"
        >
            <template #left-icons>
                <FontAwesomeIcon icon="fa-regular fa-money-bill-wave"/>
            </template>
            {{ rangeName }}
        </CButton>
        <CDropdown
            ref="dropdownSelectPeriod"
            legacy-mode
        >
            <div class="TransactionAmountFilter__title">
                {{ __('billing_transaction:select_amounts_range') }}
            </div>
            <CFormRangePicker
                :first-value="firstValue"
                :range-max="rangeMax"
                :range-min="rangeMin"
                :second-value="secondValue"
                :step="step"
                @change="updatePaymentAmountRange"
            />
            <CVStack
                class="TransactionAmountFilter__actions"
                gap="2"
                justify="center"
            >
                <CButton
                    variant="light"
                    @click="$emit('update:firstValue', 0); $emit('update:secondValue', resetValue)"
                >
                    {{ __('billing_transaction:positive_amounts_only') }}
                </CButton>
                <CButton
                    variant="light"
                    @click="$emit('update:firstValue', resetValue); $emit('update:secondValue', 0)"
                >
                    {{ __('billing_transaction:negative_amounts_only') }}
                </CButton>
                <CButton
                    variant="light"
                    @click="resetPaymentAmountRange"
                >
                    {{ __('common:actions.reset') }}
                </CButton>
            </CVStack>
        </CDropdown>
    </div>
</template>

<script lang="ts">
    import type {PropType} from 'vue';
    import {computed, defineComponent, ref} from 'vue';
    import CFormRangePicker from '@/modules/coherence-ui/components/Forms/CFormRangePicker.vue';
    import __ from '@/modules/app/utils/i18n-facade';
    import {isNil} from 'lodash-es';
    import useFormatNumber from '@/modules/cashier/composables/useFormatNumber';

    export default defineComponent({
        components: {CFormRangePicker},
        props: {
            firstValue: {
                type: [] as PropType<number | null>,
                required: true,
            },
            secondValue: {
                type: [] as PropType<number | null>,
                required: true,
            },
            rangeMin: {
                type: Number,
                required: true,
            },
            rangeMax: {
                type: Number,
                required: true,
            },
            step: {
                type: Number,
                default: 100,
            },
            resetValue: {
                type: [],
                default: null,
                required: false,
            },
            size: {type: String, default: 'base'},
            currency: {type: String, required: true},
        },
        setup(props, {emit}) {
            const dropdownSelectPeriod = ref();

            function updatePaymentAmountRange({rangeMin, rangeMax}) {
                if (rangeMin !== props.firstValue) {
                    emit('update:firstValue', rangeMin);
                }
                if (rangeMax !== props.secondValue) {
                    emit('update:secondValue', rangeMax);
                }
            }

            function resetPaymentAmountRange() {
                emit('update:firstValue', props.resetValue);
                emit('update:secondValue', props.resetValue);
            }

            const rangeName = computed(() => {
                if (isNil(props.firstValue) && isNil(props.secondValue)) {
                    return __('common:amount_other');
                }

                return `${__('billing_transaction:amounts_with_currency_range', {
                    minValue: isNil(props.firstValue) ? '∞' : props.firstValue,
                    maxValue: isNil(props.secondValue) ? '∞' : props.secondValue,
                    currency: useFormatNumber(props.currency).getSymbol(),
                })}`;
            });

            function openDropdown() {
                dropdownSelectPeriod.value.toggle();
            }

            return {
                dropdownSelectPeriod,
                rangeName,
                updatePaymentAmountRange,
                resetPaymentAmountRange,
                openDropdown,
            };
        },
    });
</script>

<style lang="scss" scoped>
    .TransactionAmountFilter__title {
        @apply tw-uppercase tw-text-sm tw-font-semibold;
        @apply tw-text-gray-600;
        @apply tw-border-b tw-border-gray-200;
        @apply tw-pb-1 tw-mb-4;
    }

    .TransactionAmountFilter__actions {
        @apply tw-mt-4;
    }
</style>
