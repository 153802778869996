<template>
    <CButtonGroup>
        <CPopover>
            <CButton :icon-menu="true">
                {{ __('request:filter_with_count', {value: countEnabledFilters}) }}
            </CButton>
            <template #content>
                <CForm class="tw-min-w-96">
                    <CFormTwoColumns>
                        <CFormGroup v-if="hasManyOrganizations">
                            <CLabel>
                                {{ __('request:by_organization_colon') }}
                            </CLabel>
                            <OrganizationFinder
                                v-model="selectedOrganizations"
                                button-class="tw-w-full"
                                multi
                            />
                        </CFormGroup>
                        <CFormGroup>
                            <CLabel>
                                {{ __('request:by_child_group_other_colon') }}
                            </CLabel>
                            <KidGroupFinder
                                v-model="kidsGroup"
                                button-class="tw-w-full"
                                :inject-query="kidGroupQueryBuilder"
                                multi
                                :show-organization="true"
                            />
                        </CFormGroup>
                    </CFormTwoColumns>
                    <CFormGroup>
                        <CLabel>
                            {{ __('request:by_request_date_colon') }}
                        </CLabel>
                        <MDatePeriodFilter
                            ref="filter"
                            v-model="periodFilter"
                        />
                    </CFormGroup>
                    <CFormTwoColumns>
                        <CFormGroup>
                            <CLabel>
                                {{ __('request:by_request_type_colon') }}
                            </CLabel>
                            <RequestTypesFinder
                                v-model="requestTypes"
                                button-class="tw-w-full"
                                multi
                            />
                        </CFormGroup>
                        <CFormGroup>
                            <CLabel>
                                {{ __('request:by_person_concerned_colon') }}
                            </CLabel>
                            <StaffFinder
                                v-model="selectedStaffs"
                                button-class="tw-w-full"
                                multi
                            />
                        </CFormGroup>
                    </CFormTwoColumns>
                    <CButton
                        class="tw-flex-1"
                        variant="primary"
                        @click="reset"
                    >
                        {{ __('common:actions.reset') }}
                    </CButton>
                </CForm>
            </template>
        </CPopover>
        <CButton
            class="tw-w-10"
            :variant="selectedStatus.contains(Status.rejected) ? 'primary' : 'light'"
            @click="toggleStatus(Status.rejected)"
        >
            <i
                aria-hidden="true"
                class="fa fa-ban"
                :class="{'tw-text-red-500': !selectedStatus.contains(Status.rejected)}"
            />
        </CButton>
        <CButton
            class="tw-w-10"
            :variant="selectedStatus.contains(Status.pending) ? 'primary' : 'light'"
            @click="toggleStatus(Status.pending)"
        >
            <i
                aria-hidden="true"
                class="fa fa-hourglass-half"
                :class="{'tw-text-yellow-600': !selectedStatus.contains(Status.pending)}"
            />
        </CButton>
        <CButton
            class="tw-w-10"
            :variant="selectedStatus.contains(Status.done) ? 'primary' : 'light'"
            @click="toggleStatus(Status.done)"
        >
            <i
                aria-hidden="true"
                class="fa fa-check"
                :class="{'tw-text-green-500': !selectedStatus.contains(Status.done)}"
            />
        </CButton>
    </CButtonGroup>
</template>

<script lang="ts">
    import {computed, defineComponent, ref, watch} from 'vue';
    import OrganizationFinder from '@/modules/organization/components/OrganizationFinder.vue';
    import MDatePeriodFilter from '@/modules/meeko-ui/components/MDatePeriodFilter.vue';
    import StaffFinder from '@/modules/request/components/Teams/StaffFinder.vue';
    import RequestTypesFinder from '@/modules/request/components/RequestTypesFinder.vue';
    import useManager from '@/modules/app/composables/useManager';
    import {collect} from '@meekohq/lumos';
    import {Status} from '@/modules/request/utils/Status';
    import KidGroupFinder from '@/modules/organization/components/KidGroupFinder.vue';
    import KidsGroupModel from '@/modules/organization/models/KidsGroupModel';
    import type OrganizationModel from '@/modules/organization/models/OrganizationModel';

    export default defineComponent({
        components: {MDatePeriodFilter, KidGroupFinder, RequestTypesFinder, StaffFinder, OrganizationFinder},
        props: {},
        setup(props, {emit}) {
            const filter = ref();
            const selectedOrganizations = ref([]);
            const selectedStaffs = ref([]);
            const requestTypes = ref([]);
            const kidsGroup = ref([]);
            const typeStatus = ref([]);

            const periodFilter = ref({
                from: null,
                to: null,
            });

            const selectedStatus = ref(collect<string>([]));

            const toggleStatus = function(status) {
                if (selectedStatus.value.contains(status)) {
                    selectedStatus.value = collect<string>([]);
                } else {
                    selectedStatus.value = collect([status]);
                }

                emit('selected-type-status', selectedStatus.value.toArray());
            };

            const kidGroupQueryBuilder = computed(() => {
                const query = KidsGroupModel.query();

                if (selectedOrganizations.value.length) {
                    query.whereHas(new KidsGroupModel().organization(), query => query.whereIn('id', collect(selectedOrganizations.value).pluck('id').all() as string[]));
                }

                return query;
            });

            watch(selectedStaffs, value => {
                emit('selected-staffs', value);
            });

            watch(selectedOrganizations, value => {
                // Filter only if there are selected organization
                if (value.length) {
                    // Filtering selected kids group who are only in selected organization
                    kidsGroup.value = kidsGroup.value.filter((group: KidsGroupModel) => {
                        return value.find((organization: OrganizationModel) => organization.id === group.attributes.organization_id);
                    });
                }
                emit('selected-organizations', value);
            });

            watch(periodFilter, value => {
                emit('selected-periods', value);
            }, {deep: true});

            watch(requestTypes, value => {
                emit('selected-request-types', value);
            });

            watch(kidsGroup, value => {
                emit('selected-kids-groups', value);
            });

            const reset = function() {
                selectedOrganizations.value = [];
                selectedStaffs.value = [];
                requestTypes.value = [];
                kidsGroup.value = [];
                periodFilter.value = {from: undefined, to: undefined};
            };

            const countEnabledFilters = computed(() => {
                let count = 1;

                if (selectedStaffs.value.length) {
                    count++;
                }

                if (requestTypes.value.length) {
                    count++;
                }

                if (selectedStatus.value.toArray().length) {
                    count++;
                }

                if (kidsGroup.value.length) {
                    count++;
                }

                return count;
            });

            const {hasManyOrganizations} = useManager();

            return {
                filter,
                selectedOrganizations,
                periodFilter,
                selectedStaffs,
                requestTypes,
                typeStatus,
                kidsGroup,
                reset,
                countEnabledFilters,
                hasManyOrganizations,
                toggleStatus,
                selectedStatus,
                Status,
                kidGroupQueryBuilder,
            };
        },
    });
</script>
