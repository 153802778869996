<template>
    <div>
        <div>
            <CHStack
                class="tw-w-full"
                gap="2"
                wrap
            >
                <CButton
                    :size="['sm','base']"
                    @click="selectAllWeeks(true)"
                >
                    <template #left-icons>
                        <font-awesome-icon icon="fa-solid fa-circle-check"/>
                    </template>
                    {{ __('common:actions.select_all') }}
                </CButton>
                <CButton
                    :size="['sm','base']"
                    @click="selectAllWeeks(true, 'odd')"
                >
                    <template #left-icons>
                        <font-awesome-icon icon="fa-regular fa-circle-check"/>
                    </template>
                    {{ __('common:odd') }}
                </CButton>
                <CButton
                    :size="['sm','base']"
                    @click="selectAllWeeks(true, 'even')"
                >
                    <template #left-icons>
                        <font-awesome-icon icon="fa-regular fa-circle-check"/>
                    </template>
                    {{ __('common:even') }}
                </CButton>
                <CButton
                    class="!tw-text-danger-500"
                    :size="['sm','base']"
                    @click="selectAllWeeks(false)"
                >
                    <template #left-icons>
                        <font-awesome-icon
                            class="tw-text-danger-500"
                            icon="fa-solid fa-circle-check"
                        />
                    </template>
                    {{ __('common:actions.unselect_all') }}
                </CButton>
            </CHStack>
        </div>
        <hr class="hr">

        <div class="tw-flex tw-flex-wrap">
            <CFormGroup class="tw-w-full sm:tw-w-1/3 tw-mb-3">
                <CLabel>{{ __('common:display_date_from') }}</CLabel>
                <CFormDatepicker v-model="from"/>
            </CFormGroup>
            <CFormGroup class="tw-w-full sm:tw-w-1/3 sm:tw-pl-2 tw-mb-3">
                <CLabel>{{ __('common:date_to') }}</CLabel>
                <CFormDatepicker v-model="to"/>
            </CFormGroup>
        </div>

        <div class="tw-mt-5">
            <div
                v-for="(year, i) in years"
                :key="'year' + i"
                class="tw-mb-3"
            >
                <h5 class="h5 tw-w-full tw-mb-2">
                    {{ year.year }}
                </h5>
                <div class="tw-grid tw-grid-cols-2 sm:tw-grid-cols-6 tw-gap-4">
                    <div
                        v-for="(week, j) in year.weeks"
                        :key="'week' + j"
                        class="tw-text-center"
                        @click="week.selected = !week.selected"
                    >
                        <div
                            class="tw-border tw-border-gray-200 tw-rounded-lg tw-p-1 tw-cursor-pointer"
                            :class="{
                                'tw-bg-blue-500 tw-border-blue-500':
                                    week.selected,
                            }"
                        >
                            <h6
                                class="h6 tw-mb-0"
                                :class="{
                                    'tw-text-white': week.selected,
                                }"
                            >
                                {{ __('common:longweek_with_number', {week: week.date.week()}) }}
                            </h6>
                            <div
                                class="tw-text-sm"
                                :class="[
                                    week.selected
                                        ? 'tw-text-white'
                                        : 'tw-text-gray-500',
                                ]"
                            >
                                {{
                                    week.date
                                        .clone()
                                        .startOf('week')
                                        .format('DD/MM')
                                }}
                                -
                                {{
                                    week.date
                                        .clone()
                                        .endOf('week')
                                        .format('DD/MM')
                                }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import {defineComponent, watch} from 'vue';
    import useYear from '@/modules/human-resources/composables/calendar/useYear';

    export default defineComponent({
        props: {
            selectedWeeks: {
                type: Array,
            },
        },
        setup(props, {emit}) {
            const {from, to, years, selectAllWeeks} = useYear();

            watch(() => years.value, val => {
                const filteredWeeks = val
                    .flatMap(year => year.weeks)
                    .filter(week => week.selected);
                emit('update:selectedWeeks', filteredWeeks);
            }, {
                deep: true,
            });

            return {
                from,
                to,
                years,
                selectAllWeeks,
            };
        },
    });
</script>
