<script lang="ts" setup>
    import MagicIndexItemColumnTitle from '@/modules/magic-index/components/atoms/MagicIndexItemColumnTitle.vue';
    import {computed} from 'vue';

    const props = defineProps({
        // On récupère la width du conteneur parent pour déclencher le mode grille
        containerWidth: {
            type: Number,
            default: undefined,
        },
        // Permet d'afficher un petit titre en mode grille
        gridTitle: {
            type: String,
            default: undefined,
        },
        // Permet d'avoir ou non le style grille
        gridEnabled: {
            type: Boolean,
            default: true,
        },
        // Permet de cacher l'élément s'il est vide
        isEmpty: {
            type: Boolean,
            default: false,
        },
    });

    const baseClass = computed(() => {
        const output = [] as string[];

        if (props.gridEnabled && props.containerWidth < 768) {
            output.push('MagicIndexItemColumn--grid-enabled');
        }

        if (props.isEmpty && props.containerWidth < 768) {
            output.push('MagicIndexItemColumn--hidden');
        }

        return output;
    });

    const titleClass = computed(() => {
        const output = [] as string[];

        if (props.containerWidth >= 768) {
            output.push('MagicIndexItemColumn__title--hidden');
        }

        return output;
    });
</script>

<template>
    <div :class="baseClass">
        <MagicIndexItemColumnTitle
            v-if="gridTitle"
            :class="titleClass"
            :title="gridTitle"
        />
        <slot/>
    </div>
</template>

<style lang="scss" scoped>
    .MagicIndexItemColumn--grid-enabled {
        @apply tw-rounded-lg tw-p-2 tw-bg-gray-500/5;
        @apply tw-flex tw-flex-col tw-gap-2;
    }

    .MagicIndexItemColumn--hidden {
        @apply tw-hidden;
    }

    .MagicIndexItemColumn__title--hidden {
        @apply tw-hidden;
    }
</style>
