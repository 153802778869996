import type {
    UpdatePersonalAccessTokenUseCase,
} from '@/modules/auth/personal-access-token/application/UpdatePersonalAccessTokenUseCase';
import type {
    UpdatePersonalAccessTokenNameRequest,
} from '@/modules/auth/personal-access-token/application/UpdatePersonalAccessTokenRequest';
import type PersonalAccessTokenModel from '@/modules/auth/personal-access-token/domain/PersonalAccessTokenModel';
import {SudoModeRequiredError} from '@/modules/auth/sudo-mode/application/SudoModeError';

export class UpdatePersonalAccessTokenInteractor implements UpdatePersonalAccessTokenUseCase {
    public async updateName(request: UpdatePersonalAccessTokenNameRequest): Promise<PersonalAccessTokenModel> {
        const {model, name} = request;

        model.attributes.name = name;

        try {
            await model.save({
                params: {
                    sudo_token: request.sudoToken,
                },
            });
        } catch (err) {
            model.rollback();

            if (SudoModeRequiredError.isSudoModeRequiredError(err)) {
                throw new SudoModeRequiredError();
            }
            throw err;
        }

        return model;
    }
}
