<template>
    <div
        class="item"
        :class="[{'unread' : !notification.read_at}, {'tw-cursor-pointer hover:!tw-bg-blue-100': resourceUrl !== null}]"
        @click="handleNotificationClick()"
    >
        <div class="mb-2 d-flex justify-content-between align-items-center">
            <p
                v-if="haveManyNurseries && nursery"
                class="tw-text-gray-400 small mb-0 mr-5"
            >
                {{ nursery.name }} - {{ nursery.city }}
            </p>
            <slot
                v-else
                name="tag"
            >
                <span
                    v-if="$getNotificationCharacteristics(notification.data.notification_type)"
                    class="tw-rounded-lg tw-font-medium"
                    :style="$getNotificationCharacteristics(notification.data.notification_type).style"
                >
                    <i
                        class="mr-1"
                        :class="$getNotificationCharacteristics(notification.data.notification_type).icon"
                    />
                    {{ $getNotificationCharacteristics(notification.data.notification_type).title }}
                </span>
            </slot>
            <div class="tw-whitespace-nowrap">
                <span
                    v-if="showDate"
                    class="tw-text-gray-400 small"
                >
                    {{ moment.unix(notification.created_at).fromNow() }}
                </span>
                <MDropdown :stop-propagation="true">
                    <MButton
                        icon-ellipsis
                        size="sm"
                        variant="ghost"
                    />
                    <template #content>
                        <CDropdownMenuItem
                            v-if="!notification.read_at"
                            class="tw-w-full"
                            icon="fa fa-eye fa-fw"
                            :label="__('notification:mark_as_read')"
                            @click="updateNotification(moment().unix())"
                        />
                        <CDropdownMenuItem
                            v-if="notification.read_at"
                            class="tw-w-full"
                            icon="fa fa-eye-slash fa-fw"
                            :label="__('notification:mark_as_unread')"
                            @click="updateNotification(null)"
                        />
                        <CDropdownMenuItem
                            class="tw-w-full"
                            icon="fas fa-trash fa-fw"
                            :label="__('common:actions.delete')"
                            variant="danger"
                            @click="deleteNotification"
                        />
                        <CDropdownMenuDivider
                            v-if="notification.data.nursery_id && (notification.data.family_member_id || notification.data.kid_id)"
                            class="tw-my-1"
                        />
                        <CDropdownMenuItem
                            v-if="notification.data.family_member_id && notification.data.nursery_id"
                            class="tw-w-full"
                            icon="fa fa-comments fa-fw"
                            :label="__('notification:see_conversation')"
                            :to="{name:'familyMembers.show', params: {nursery: notification.data.nursery_id, familyMember: notification.data.family_member_id}}"
                        />
                        <CDropdownMenuItem
                            v-if="notification.data.kid_id && notification.data.nursery_id"
                            class="tw-w-full"
                            icon="fa fa-child-reaching fa-fw"
                            :label="__('notification:see_kid')"
                            :to="{name:'kids.show', params: {nursery: notification.data.nursery_id, kid: notification.data.kid_id}}"
                        />
                    </template>
                </MDropdown>
                <span
                    v-if="showHideButton"
                    class="tw-text-gray-400 small pointer"
                    @click.stop="hideNotification"
                >
                    <i
                        aria-hidden="true"
                        class="fa fa-times-circle"
                    />
                </span>
            </div>
        </div>

        <slot
            v-if="haveManyNurseries && nursery"
            name="tag"
        >
            <span
                v-if="$getNotificationCharacteristics(notification.data.notification_type)"
                class="tw-rounded-full tw-text-semibold"
                :style="$getNotificationCharacteristics(notification.data.notification_type).style"
            >
                <i
                    class="mr-1"
                    :class="$getNotificationCharacteristics(notification.data.notification_type).icon"
                />
                {{ $getNotificationCharacteristics(notification.data.notification_type).title }}
            </span>
        </slot>

        <slot name="content">
            <component
                :is="$getNotificationCharacteristics(notification.data.notification_type).component"
                :class="{'mt-2' : haveManyNurseries}"
                :notification="notification"
            />
        </slot>
    </div>
</template>

<script lang="ts">
    import {computed, defineComponent, onMounted, ref, toRef} from 'vue';
    import moment from 'moment';
    import {notification} from '@/modules/legacy/mixins/notification';
    import useResource from '@/modules/app/composables/useResource';
    import useMetrics from '@/modules/app/composables/useRum';
    import useMarkAsReadNotification from '@/modules/notification/composables/useMarkAsReadNotification';
    import MessageRequest from '@/modules/notification/components/Types/MessageRequest.vue';
    import AbsenceRequest from '@/modules/notification/components/Types/AbsenceRequest.vue';
    import OccasionalRequest from '@/modules/notification/components/Types/OccasionalRequest.vue';
    import LateRequest from '@/modules/notification/components/Types/LateRequest.vue';
    import NewRegistrationForm from '@/modules/notification/components/Types/NewRegistrationForm.vue';
    import UpdatedRegistrationForm from '@/modules/notification/components/Types/UpdatedRegistrationForm.vue';
    import PresenceUnclocked from '@/modules/notification/components/Types/PresenceUnclocked.vue';
    import StaffPresenceUnclocked from '@/modules/notification/components/Types/StaffPresenceUnclocked.vue';
    import InvoiceUnpaid from '@/modules/notification/components/Types/InvoiceUnpaid.vue';
    import NewPost from '@/modules/notification/components/Types/NewPost.vue';
    import NewDocument from '@/modules/notification/components/Types/NewDocument.vue';
    import ThrottleLogin from '@/modules/notification/components/Types/ThrottleLogin.vue';
    import TaskReminder from '@/modules/notification/components/Types/TaskReminder.vue';
    import Meeting from '@/modules/notification/components/Types/Meeting.vue';
    import OrganizationModel from '@/modules/organization/models/OrganizationModel';
    import {useRouter} from 'vue-router/composables';
    import {Lang} from '@meekohq/lumos';

    export default defineComponent({
        components: {
            MessageRequest,
            AbsenceRequest,
            OccasionalRequest,
            LateRequest,
            NewRegistrationForm,
            UpdatedRegistrationForm,
            PresenceUnclocked,
            StaffPresenceUnclocked,
            InvoiceUnpaid,
            NewPost,
            NewDocument,
            ThrottleLogin,
            TaskReminder,
            Meeting,
        },
        mixins: [notification],
        props: {
            notification: {
                type: Object,
                required: true,
            },
            user: {
                type: Object,
                required: true,
            },
            showDate: {
                type: Boolean,
                default: true,
            },
            showHideButton: {
                type: Boolean,
                default: false,
            },
        },
        setup(props, {emit}) {
            const nursery = ref<any>(null);

            const {addAction} = useMetrics();

            onMounted(async () => {
                await getNursery();
            });

            const {computedResources} = useResource(toRef(props.notification.data, 'resources'));
            const resourceUrl = computed(() => {
                if (props.notification.data.resources && props.notification.data.resources.length) {
                    return computedResources.value[0].url;
                }

                return null;
            });

            const {markAsRead} = useMarkAsReadNotification();

            const router = useRouter();

            function handleNotificationClick() {
                if (resourceUrl.value && computedResources.value.length < 2) {
                    if (!props.notification.read_at) {
                        markAsRead(props.notification);
                    }
                    router.push(resourceUrl.value).catch(() => {
                        return;
                    });
                }
            }

            const haveManyNurseries = computed(() => {
                if (props.user && props.user.nurseries) {
                    return props.user.nurseries.length > 1;
                }

                return false;
            });

            function updateNotification(readAt) {
                props.notification.read_at = readAt;
                emit('updateNotification', props.notification);
            }

            function deleteNotification() {
                emit('deleteNotification', props.notification);
            }

            function hideNotification() {
                emit('hideNotification', props.notification);
            }

            async function getNursery() {
                if (props.user
                    && props.notification
                    && (props.notification.data.nursery_id
                        || props.notification.data.nursery_ids)
                ) {
                    // Replace legacy id by new uuid if needed
                    if (
                        (props.notification.data.nursery_id && Lang.isNumeric(props.notification.data.nursery_id))
                        || props.notification.data.nursery_ids?.filter(id => Lang.isNumeric(id)).length
                    ) {
                        // Get organizations from legacy ids
                        const query = OrganizationModel.query();

                        if (props.notification.data.nursery_id) {
                            query.where('legacy_id', props.notification.data.nursery_id);
                        } else {
                            const numericIds = props.notification.data.nursery_ids.filter(id => Lang.isNumeric(id));
                            const uuidIds = props.notification.data.nursery_ids.filter(id => !Lang.isNumeric(id));
                            query.whereIn('legacy_id', numericIds).orWhereIn('id', uuidIds);
                        }

                        const organizations = await query.get();

                        // Replace legacy id by new uuid
                        if (props.notification.data.nursery_id) {
                            props.notification.data.nursery_id = organizations.first().getKey();
                        } else {
                            props.notification.data.nursery_ids = organizations.pluck('attributes.id').all();
                        }

                        // Add metric to track legacy id usage
                        addAction('M_Notification_Legacy_Id', {
                            nursery_id: props.notification.data.nursery_id,
                            nursery_ids: props.notification.data.nursery_ids,
                        });
                    }

                    // Get nursery depending on notification
                    nursery.value = props.user.nurseries.find(
                        nursery => props.notification.data.nursery_id
                            ? nursery.id === props.notification.data.nursery_id
                            : props.notification.data.nursery_ids.includes(nursery.id),
                    );
                }
            }

            return {
                moment,
                nursery,
                resourceUrl,
                haveManyNurseries,
                handleNotificationClick,
                updateNotification,
                deleteNotification,
                hideNotification,
                getNursery,
            };
        },
    });
</script>

<style
    lang="scss"
    scoped
>
    .item {
        border-bottom: 1px solid #d8dee4;

        &:first-of-type {
            border-top: 1px solid #d8dee4;
        }

        &:last-of-type {
            border-bottom: 0;
        }

        &.unread {
            background-color: rgba(233, 244, 255, 0.7);
        }
    }
</style>
