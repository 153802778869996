import {ServiceProvider} from '@meekohq/lumos';
import OrganizationModel from '@/modules/organization/models/OrganizationModel';
import OrganizationTenantPivot from '@/modules/organization/models/OrganizationTenantPivot';
import KidsGroupModel from '@/modules/organization/models/KidsGroupModel';
import LegacyNurseryOpening from '@/modules/organization/utils/LegacyNurseryOpening';
import DocTemplateModel from '@/modules/organization/models/DocTemplateModel';
import OrganizationUserPivot from '@/modules/organization/models/OrganizationUserPivot';
import {Names} from '@/modules/organization/utils/Names';

export default class OrganizationServiceProvider extends ServiceProvider {
    public boot() {
        this.loadModelsFrom([
            KidsGroupModel,
            OrganizationModel,
            OrganizationTenantPivot,
            DocTemplateModel,
            OrganizationUserPivot,
        ]);
    }

    public register() {
        this.app.bind(Names.Opening).toClass(LegacyNurseryOpening);
    }
}
