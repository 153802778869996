import type {QueryBuilder} from '@meekohq/lumos';
import {Epoch, Model} from '@meekohq/lumos';
import OrganizationModel from '@/modules/organization/models/OrganizationModel';
import RegistrationOrganizationPivot from '@/modules/registration/models/RegistrationOrganizationPivot';
import RegistrationPlanning from '@/modules/registration/models/RegistrationPlanning';
import TagModel from '@/modules/tag/models/TagModel';
import TagPivot from '@/modules/tag/models/TagPivot';
import type {Moment} from 'moment/moment';
import moment from 'moment/moment';

export default class RegistrationModel extends Model {
    public type = 'registration/registrations';

    public attributes: {
        id: string,
        account_id: string | undefined,
        uuid: string | undefined,
        contract_date_period: {
            started_at: string | undefined;
            ended_at: string | undefined;
        },
        kid_gender: string | null,
        kid_first_name: string | null,
        kid_last_name: string | undefined,
        kid_birthdate: string | undefined,
        kid_birthplace: string | null,
        first_parent_first_name: string | null,
        first_parent_last_name: string | null,
        first_parent_salary: number | null,
        second_parent_first_name: string | null,
        second_parent_last_name: string | null,
        second_parent_salary: number | null,
        contract_recurrent_week: number | null,
        requested_at: string | undefined,
    } = {
            id: this.uuid(),
            account_id: undefined,
            uuid: this.uuid(),
            contract_date_period: {
                started_at: undefined,
                ended_at: undefined,
            },
            kid_gender: null,
            kid_first_name: null,
            kid_last_name: undefined,
            kid_birthdate: undefined,
            kid_birthplace: null,
            first_parent_first_name: null,
            first_parent_last_name: null,
            first_parent_salary: null,
            second_parent_first_name: null,
            second_parent_last_name: null,
            second_parent_salary: null,
            contract_recurrent_week: null,
            requested_at: undefined,
        };


    get kidFullname() {
        return this.attributes.kid_first_name + ' ' + this.attributes.kid_last_name;
    }

    organizations() {
        return this.belongsToMany('organizations', OrganizationModel, RegistrationOrganizationPivot, 'registration_id', 'organization_id');
    }

    organizationsPivots() {
        return this.hasMany('organizationsPivots', RegistrationOrganizationPivot, 'registration_id');
    }

    plannings() {
        return this.hasMany('plannings', RegistrationPlanning, 'registration_id');
    }

    tags() {
        return this.morphToMany('tags', TagModel, TagPivot, 'resource', undefined, 'tag_id');
    }

    scopeInRange(mainQuery: QueryBuilder<any>, dates: { from: string | Moment, to: string | Moment }) {
        const fromFormated = moment.isMoment(dates.from) ? dates.from : moment(dates.from);
        const toFormated = moment.isMoment(dates.to) ? dates.to : moment(dates.to);

        mainQuery.where(query => {
            query.where(query2 => {
                query2.whereDate('contract_started_at', '<=', fromFormated)
                    .whereDate('contract_ended_at', '>', fromFormated);
            }).orWhere(q => {
                q.whereDate('contract_started_at', '>=', fromFormated);
                if (dates.to) {
                    q.whereDate('contract_started_at', '<=', toFormated);
                }
            });
        });
    }

    scopeOngoingRegistrationsByOrganizationIds(mainQuery: QueryBuilder<any>, options: {
        organizationIds: string | string[]
    }) {
        return mainQuery.whereHas('organizationsPivots', query2 => {
            if (Array.isArray(options.organizationIds)) {
                query2.whereIn('organization_id', options.organizationIds);
            } else {
                query2.where('organization_id', options.organizationIds);
            }

            query2.whereNull('kid_id').where('rejected', false);
        }).scope('inRange', {dates: {from: new Epoch().toISOString()}});
    }
}
