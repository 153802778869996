import {Model} from '@meekohq/lumos';

export default class MemberOrganizationPivot extends Model {
    public type = 'family/members_organizations_pivot';

    public attributes: {
        id: string;
        account_id: string | undefined;
        member_id: string | undefined;
        organization_id: string | undefined;
    } = {
            id: this.uuid(),
            account_id: undefined,
            member_id: undefined,
            organization_id: undefined,
        };
}
