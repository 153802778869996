import useLegacyRegistration from '@/modules/registration/composables/useLegacyRegistration';
import {computed} from 'vue';
import type {CustomSelectType} from '@/modules/activity/utils/CustomSelectType';
import __ from '@/modules/app/utils/i18n-facade';

export default function(registration) {
    const {
        getFirstParentAttr,
        getFirstParentFullname,
        getSecondParentAttr,
        getSecondParentFullname,
    } = useLegacyRegistration(registration);

    const meetingParticipantsCustomSelect = computed(() => {
        const select: CustomSelectType = {
            label: __('registration:select_parent'),
            options: [],
        };

        if (getFirstParentAttr('first_name') || getFirstParentAttr('last_name') || getFirstParentAttr('email')) {
            select.options.push({
                value: {
                    firstname: getFirstParentAttr('first_name'),
                    lastname: getFirstParentAttr('last_name'),
                    email: getFirstParentAttr('email'),
                    resource_id: registration.id.toString(),
                    resource_type: 'registration/registrations',
                },
                text: getFirstParentFullname(),
            });
        }

        if (getSecondParentAttr('first_name') || getSecondParentAttr('last_name') || getSecondParentAttr('email')) {
            select.options.push({
                value: {
                    firstname: getSecondParentAttr('first_name'),
                    lastname: getSecondParentAttr('last_name'),
                    email: getSecondParentAttr('email'),
                    resource_id: registration.id.toString(),
                    resource_type: 'registration/registrations',
                },
                text: getSecondParentFullname(),
            });
        }

        return select;
    });

    return {
        meetingParticipantsCustomSelect,
    };
}
