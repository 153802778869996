<template>
    <div>
        <p
            class="tw-text-gray-600 tw-text-base mb-0"
            v-html="__('hr:missing_derparture_checkin_for_name_at', {
                name: `${notification.data.staff_first_name} ${notification.data.staff_last_name}`,
                date: date,
                expectedAt: expectedAt,
            })"
        />
        <p class="tw-text-gray-800 tw-text-sm mb-0 mt-1">
            {{ __('hr:automatically_checked_in_at_scheduled_time') }}
        </p>

        <div class="mt-2">
            <router-link
                class="btn btn-light btn-sm"
                :to="{name:'staffs.show', params: {nursery: notification.data.nursery_id, staff:notification.data.staff_id}}"
                @click.native="handlerEmit"
            >
                {{ __('hr:see_planning') }}
            </router-link>
        </div>
    </div>
</template>

<script lang="ts">
    import moment from 'moment';
    import {computed, defineComponent} from 'vue';
    import {EventBus} from '@/modules/legacy/utils/bus';

    export default defineComponent({
        props: {
            notification: {
                type: Object,
                required: true,
            },
        },
        setup(props, {emit}) {
            const date = computed(() => {
                // If the notification is from before signature change use date property
                if (props.notification.data.date) {
                    return props.notification.data.date;
                }

                return moment(props.notification.data.ended_at).format('DD/MM/YYYY');
            });

            const expectedAt = computed(() => {
                // If the notification is from before signature change use expected_at property
                if (props.notification.data.expected_at) {
                    const expectedAtDateTime = `${props.notification.data.date} ${props.notification.data.expected_at}`;
                    const format = 'DD/MM/YYYY HH:mm';

                    // Instanciate a moment object from a utc date with the format and update to local time
                    return moment.utc(expectedAtDateTime, format).local().format('HH:mm');
                }

                return moment(props.notification.data.ended_at).format('HH:mm');
            });

            function handlerEmit() {
                emit('closeModal');

                if (!props.notification.read_at) {
                    props.notification.read_at = moment().unix();
                }

                EventBus.$emit('update:notification', props.notification);
            }

            return {
                date,
                expectedAt,
                handlerEmit,
            };
        },
    });
</script>
