import {ServiceProvider} from '@meekohq/lumos';
import PhotoModel from '@/modules/photo/models/PhotoModel';
import PhotoKidPivot from '@/modules/photo/models/PhotoKidPivot';

export default class PhotosServiceProvider extends ServiceProvider {
    public boot() {
        this.loadModelsFrom([
            PhotoModel,
            PhotoKidPivot,
        ]);
    }
}
