<template>
    <CButtonGroup :class="style">
        <CInput
            v-model="inputValue"
            :disabled="disabled"
            :has-error="hasError"
            :placeholder="placeholder"
            :prevent-browser-styling="preventBrowserStyling"
            :readonly="readonly"
            :rounded="rounded"
            :size="size"
            :type="type"
            :variant="variant"
            :width="width"
            @input="$emit('input', type === 'number' ? Number(inputValue) : inputValue)"
        />
        <CFormSelect
            v-if="suggestions"
            v-model="selectedSuggestion"
            force-fallback
            :options="suggestions.options"
            :search-bar="false"
        >
            <template #fallback>
                <i
                    v-if="suggestions.icon"
                    aria-hidden="true"
                    :class="suggestions.icon"
                />
                <CText v-else>
                    {{ suggestions.text }}
                </CText>
            </template>
        </CFormSelect>
    </CButtonGroup>
</template>

<script lang="ts">
    import type {PropType} from 'vue';
    import {computed, defineComponent, ref, watch} from 'vue';

    export default defineComponent({
        components: {
            //
        },
        props: {
            value: {type: [String, Number]},
            placeholder: {type: String},
            readonly: {type: Boolean, default: false},
            disabled: {type: Boolean, default: false},
            hasError: {type: Boolean, default: false},
            type: {type: String, default: 'text'},
            variant: {type: String, default: 'border'},
            size: {type: String, default: 'default'},
            width: {type: String, default: 'expand'},
            rounded: {type: String, default: 'both'},
            preventBrowserStyling: {type: Boolean, default: false},
            suggestions: {
                type: Object as PropType<{
                    icon?: string,
                    text?: string,
                    options: Array<{ text: string, value: string }>
                }>,
                default: null,
            },
        },
        setup(props, {emit}) {
            const inputValue = ref(props.value);
            const selectedSuggestion = ref(null);

            watch(selectedSuggestion, value => {
                if (value) {
                    emit('input', props.type === 'number' ? Number(value) : value);
                    inputValue.value = value;
                }
            });

            const style = computed(() => {
                const output = [] as any[];

                const widths = {
                    'expand': 'tw-w-full',
                    '1/2': 'tw-w-1/2',
                    '24': 'tw-w-24',
                    '': '',
                };
                output.push(widths[props.width]);

                return output;
            });

            return {
                inputValue,
                selectedSuggestion,
                style,
            };
        },
    });
</script>
