<template>
    <div class="CInputAddon">
        <slot/>
    </div>
</template>

<script lang="ts">
    import {defineComponent} from 'vue';

    export default defineComponent({
        props: {},
        setup() {
            return {};
        },
    });

</script>

<style lang="scss" scoped>
    .CInputAddon {
        @apply tw-flex tw-justify-center tw-items-center;
        @apply tw-text-black;
        @apply tw-bg-white tw-rounded-md tw-px-3;
        @apply tw-shadow-sm;
        @apply tw-border-0 tw-outline-0;
        @apply tw-ring-0 tw-ring-offset-1;
        @apply tw-ring-transparent tw-ring-offset-gray-500/30;
    }
</style>
