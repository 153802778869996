<template>
    <div class="toast-notification-container">
        <transition-group name="list-right">
            <template v-for="(notification, i) in notifications">
                <div
                    :key="'toast-notification' + i"
                    class="mt-2"
                    @mouseleave="mouseLeave(notification)"
                    @mouseover="mouseOver(notification)"
                >
                    <notification
                        class="notification-item rounded px-2 pt-1 pb-2"
                        :class="[{'fading': !notification.keepOnScreen}, {'read': notification.read_at}]"
                        :notification="notification"
                        :show-date="false"
                        show-hide-button
                        :user="user"
                        @deleteNotification="deleteNotification"
                        @hideNotification="hideNotification"
                        @updateNotification="updateNotification"
                    />
                </div>
            </template>
        </transition-group>
    </div>
</template>

<script>
    import Vue from 'vue';
    import route from '@/modules/legacy/libs/ziggy';
    import useApi from '@/modules/app/composables/useApi';
    import _forEach from 'lodash-es/forEach';
    import _head from 'lodash-es/head';
    import Notification from '@/modules/notification/components/Notification.vue';
    import useBroadcast from '@/modules/app/composables/useBroadcast';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';

    export default {
        components: {
            Notification,
        },
        props: {
            user: {
                type: Object,
            },
        },
        data: () => ({
            notifications: [],
        }),
        mounted() {
            useBroadcast().privateChannel.bind(useBroadcast().notificationEvent, notification => {
                this.getNotification(notification.id);
            });

            this.$bus.$on('update:notification', notification => {
                this.updateNotification(notification);
            });

            this.$bus.$on('updateMessageNotification', notification => {
                this.updateMessage(notification);
            });

            this.$bus.$on('notificationsModalShown', () => {
                this.hideAllNotifications();
            });
        },

        beforeDestroy() {
            this.$bus.$off('update:notification');
            this.$bus.$off('updateMessageNotification');
            this.$bus.$off('notificationsModalShown');
            useBroadcast().privateChannel.unbind(useBroadcast().notificationEvent);
        },
        methods: {
            getNotification(id) {
                useApi().legacy.get(route('notifications.show', {
                    notification: id,
                })).then(response => {
                    if (response.data.data.notification_type !== 'new-request-v1') {
                        this.$bus.$emit('create:notification', response.data);
                        this.addNotification(response.data);
                    }
                });
            },

            addNotification(notification) {
                Vue.set(notification, 'keepOnScreen', true);
                setTimeout(() => {
                    Vue.set(notification, 'keepOnScreen', false);
                    setTimeout(() => {
                        this.hideNotification(notification, true);
                    }, 5000);
                }, 1000);

                this.notifications.push(notification);
            },

            updateNotification(notification) {
                useApi().legacy.put(
                    route('notifications.update', {
                        notification: notification.id,
                    }),
                    {
                        state: notification.state,
                        read_at: notification.read_at,
                    },
                )
                    .then(response => {
                        const notifToUpdate = this.notifications.find(
                            item => item.id === notification.id,
                        );
                        if (notifToUpdate) {
                            const index = this.notifications.indexOf(notifToUpdate);
                            Vue.set(this.notifications, index, response.data);
                        }
                    })
                    .catch(error => {
                        if (
                            error &&
                            error.response &&
                            error.response.status === 422
                        ) {
                            _forEach(error.response.data.errors, function(value) {
                                useNotification().error(_head(value));
                            });
                        } else {
                            useNotification().error(error);
                        }
                    });
            },

            deleteNotification(notification) {
                useApi().legacy.delete(
                    route('notifications.destroy', {
                        notification: notification.id,
                    }),
                )
                    .then(() => {
                        this.hideNotification(notification);
                    })
                    .catch(error => {
                        if (
                            error &&
                            error.response &&
                            error.response.status === 422
                        ) {
                            _forEach(error.response.data.errors, function(value) {
                                useNotification().error(_head(value));
                            });
                        } else {
                            useNotification().error(error);
                        }
                    });
            },

            updateMessage(notification) {
                useApi().legacy.put(
                    route('nurseries.messages.update', {
                        nurseries: notification.data.nursery_id,
                        message: notification.data.message_id,
                    }),
                    {
                        state: notification.state,
                    },
                )
                    .then(() => {
                        const notifToUpdate = this.notifications.find(
                            item => item.id === notification.id,
                        );
                        if (notifToUpdate) {
                            const index = this.notifications.indexOf(notifToUpdate);
                            Vue.set(this.notifications, index, notification);
                        }
                    })
                    .catch(error => {
                        if (
                            error &&
                            error.response &&
                            error.response.status === 422
                        ) {
                            _forEach(error.response.data.errors, function(value) {
                                useNotification().error(_head(value));
                            });
                        } else {
                            useNotification().error(error);
                        }
                    });
            },

            mouseOver(notification) {
                Vue.set(notification, 'keepOnScreen', true);
            },

            mouseLeave(notification) {
                Vue.set(notification, 'keepOnScreen', false);
                setTimeout(() => {
                    this.hideNotification(notification, true);
                }, 6000);
            },

            hideNotification(notification, fading) {
                if (notification.keepOnScreen && fading) {
                    return;
                }

                const notifToRemove = this.notifications.find(
                    item => item.id === notification.id,
                );
                if (notifToRemove) {
                    const index = this.notifications.indexOf(notifToRemove);
                    this.notifications.splice(index, 1);
                }
            },

            hideAllNotifications() {
                this.notifications.forEach(notification => {
                    this.hideNotification(notification);
                });
            },
        },
    };
</script>

<style lang="scss" scoped>
    @import "bootstrap/scss/functions";
    @import "@/assets/_bootstrap/variables";
    @import "bootstrap/scss/mixins/breakpoints";

    .toast-notification-container {
        position: fixed;
        top: 95px;
        left: 30px;
        right: 30px;
        z-index: 101;
        border-radius: 4px;

        .notification-item {
            background: #f0f7fe;
            opacity: 1;
            transition-property: opacity;
            transition-duration: 0.2s;
            transition-delay: 0;

            box-shadow: 0 7px 14px 0 rgba(50, 50, 93, 0.1),
            0 3px 6px 0 rgba(0, 0, 0, 0.07);

            &.fading {
                transition-duration: 8s;
                transition-delay: 1s;
                opacity: 0.3;
            }

            &.read {
                background: white;
            }
        }

        @include media-breakpoint-up(md) {
            top: 58px;
            left: auto;
            right: 30px;
            width: 400px;
            max-height: calc(100vh - 80px);
        }
    }
</style>
