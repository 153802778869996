import {LengthAwarePaginator} from '@meekohq/lumos';

export default function usePaginatorHelpers<T>(paginator: LengthAwarePaginator<T>) {
    function newFilteredPaginator(compareFn: (item: T) => boolean): LengthAwarePaginator<T> {
        const newItems = paginator.items().all().filter(item => {
            return !compareFn(item);
        });

        if (newItems.length === paginator.total()) {
            return new LengthAwarePaginator<T>(
                newItems,
                paginator.total(),
                paginator.perPage(),
                paginator.currentPage(),
                paginator.lastPage(),
            );
        }

        const perPage = paginator.perPage();
        const currentPage = paginator.currentPage();
        const lastPage = paginator.lastPage();

        const newTotal = newItems.length;
        const newCurrentPage = (currentPage * perPage) - perPage >= newTotal ? Math.max(1, currentPage - 1) : currentPage;
        const newLastPage = (lastPage * perPage) - perPage >= newTotal ? Math.max(1, lastPage - 1) : lastPage;

        return new LengthAwarePaginator<T>(
            newItems,
            newTotal,
            perPage,
            newCurrentPage,
            newLastPage,
        );
    }

    /**
     * Return true if the new paginator is desynchronized from the current paginator.
     * A desynchronization means that a refresh of the data is needed to have the correct data.
     * The reasons of desynchronization are:
     * - The current page has changed
     * - The last page has changed
     * - The current page is not the last page
     */
    function isNewPaginatorDesynchronized(newPaginator: LengthAwarePaginator<T>): boolean {
        return newPaginator.currentPage() !== paginator.currentPage()
            || newPaginator.lastPage() !== paginator.lastPage()
            || newPaginator.currentPage() !== newPaginator.lastPage();
    }

    return {
        newFilteredPaginator,
        isNewPaginatorDesynchronized,
    };
}
