import type {QueryBuilder} from '@meekohq/lumos';
import {Model} from '@meekohq/lumos';
import OrganizationModel from '@/modules/organization/models/OrganizationModel';
import EventTypeOrganizationModel from '@/modules/human-resources/models/EventTypeOrganizationModel';
import CalendarModel, {calendarTypes} from '@/modules/human-resources/models/CalendarModel';
import DataStore from '@/modules/legacy/store/data.store';
import BalanceTypeModel from '@/modules/human-resources/models/BalanceTypeModel';
import BalanceTypeEventTypePivot from '@/modules/human-resources/models/BalanceTypeEventTypePivot';
import type {HasEvents, HasOrganization} from '@/modules/app/composables/useSharedList';
import RequestTypeModel from '@/modules/request/models/RequestTypeModel';
import IconModel from '@/modules/app/models/IconModel';
import EventModel from '@/modules/human-resources/models/EventModel';

export default class EventTypeModel extends Model implements HasOrganization, HasEvents {
    public type = 'hr/calendar/event_types';

    public attributes: {
        id: string;
        account_id: string | undefined;
        internal_id: string | undefined;
        calendar_id: string | undefined;
        request_type_id: string | undefined;
        factor: number | undefined;
        name: string | undefined;
        color: string | undefined;
        icon_id: string | undefined;
        enable_supervision_for_forecast: boolean | undefined;
        enable_supervision_for_actual: boolean | undefined;
        archived_at: string | undefined;
    } = {
            id: this.uuid(),
            account_id: undefined,
            internal_id: undefined,
            calendar_id: undefined,
            request_type_id: undefined,
            factor: undefined,
            name: undefined,
            color: undefined,
            icon_id: undefined,
            enable_supervision_for_forecast: false,
            enable_supervision_for_actual: false,
            archived_at: undefined,
        };

    get icon() {
        return DataStore.iconPath(`${this.attributes.icon_id}`);
    }

    get factor() {
        return this.attributes.factor;
    }

    balanceTypes() {
        return this.belongsToMany('balanceTypes', BalanceTypeModel, BalanceTypeEventTypePivot, 'event_type_id', 'balance_type_id');
    }

    organizations() {
        return this.belongsToMany('organizations', OrganizationModel, EventTypeOrganizationModel, 'event_type_id', 'organization_id');
    }

    organizationsPivots() {
        return this.hasMany('organizationsPivots', EventTypeOrganizationModel, 'event_type_id');
    }

    calendar() {
        return this.belongsTo('calendar', CalendarModel, 'calendar_id');
    }

    requestType() {
        return this.belongsTo('requestType', RequestTypeModel, 'request_type_id');
    }

    iconModel() {
        return this.belongsTo('iconModel', IconModel, 'icon_id');
    }

    events() {
        return this.hasMany('events', EventModel, 'type_id');
    }

    scopeDefaultType(query: QueryBuilder<any>) {
        return query.where('internal_id', '=', '5783f6e3-a602-40d8-b696-a2d8d944f116');
    }

    scopeWithoutAbsences(query: QueryBuilder<any>) {
        return query.whereHas(new EventTypeModel().calendar(), q1 => {
            q1.where('internal_id', '<>', calendarTypes.absence);
        });
    }
}
