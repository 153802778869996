import {computed, watch} from 'vue';
import type StaffModel from '@/modules/human-resources/models/StaffModel';
import type OrganizationModel from '@/modules/organization/models/OrganizationModel';
import StaffsOrganizationsPivot from '@/modules/human-resources/models/StaffsOrganizationsPivot';

export default function(staff: StaffModel) {
    // Create computed of each relation needed for staff.
    const staffOrganizationsPivotRelation = computed(() => staff.organizationsPivots());
    const staffOrganizationsRelation = computed(() => staff.organizations());

    const addStaffInOrganization = function(organization: OrganizationModel) {
        // Get the matching pivot in staff's pivot organization relation.
        const matchingPivot = staffOrganizationsPivotRelation.value.value().first(item => item.attributes.organization_id === organization.id);

        // If there is no matching pivot, create, attach and push it in staff's pivot organization relation.
        if (!matchingPivot) {
            const pivot = new StaffsOrganizationsPivot();
            pivot.attributes.account_id = staff.attributes.account_id;
            pivot.organization().associate(organization, false);
            pivot.staff().associate(staff, false);
            staffOrganizationsPivotRelation.value.value().push(pivot);
        }
    };

    watch(() => staff, () => {
        staffOrganizationsPivotRelation.value.load();
        staffOrganizationsRelation.value.load();
    }, {immediate: true});

    return {
        staffOrganizationsPivotRelation,
        staffOrganizationsRelation,
        addStaffInOrganization,
    };
}
