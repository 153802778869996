<script lang="ts" setup>
    import {ref} from 'vue';
    import useUrl from '@/modules/app/composables/useUrl';
    import {useRoute} from 'vue-router/composables';
    import UpdateContractsModal
        from '@/modules/family/components/contract/legacy/UpdateContracts/UpdateContractsModal.vue';
    import SimplePageTemplate from '@/modules/app/components/templates/SimplePageTemplate.vue';
    import {default as ModuleGeneral} from './settings/general.vue';
    import {default as ModuleRules} from './settings/rules.vue';
    import {default as ModuleInvoices} from './settings/invoices.vue';
    import {default as ModuleSepa} from './settings/sepa.vue';
    import useModal from '@/modules/app/composables/useModal';

    defineProps({
        nursery: {
            type: Object,
            required: true,
        },
        pdfDocuments: {
            type: Array,
            required: true,
        },
        billingConfigs: {
            type: Array,
            required: true,
        },
        sepaMandates: {
            type: Array,
            required: true,
        },
    });

    const updateContractsModal = useModal();
    const route = useRoute();
    const selectedTab = ref(route.query.nav ?? 'rules');
    const {pushQueryString} = useUrl();

    function changeSelectedTab(tab: string) {
        selectedTab.value = tab;
        pushQueryString('nav', tab);
    }
</script>

<template>
    <SimplePageTemplate>
        <template #title>
            <MPageTitle variant="billing">
                {{ __('app:menu.billing_settings') }}
            </MPageTitle>
        </template>
        <template #actions>
            <MButton @click="updateContractsModal.show">
                <template #left-icons>
                    <FontAwesomeIcon icon="far fa-sync"/>
                </template>
                {{ __('family_kid:update_contracts') }}
            </MButton>
        </template>
        <template #content>
            <CTabMenu
                class="tw-mb-4"
                variant="orange"
            >
                <CTabMenuItem
                    :active="selectedTab === 'rules'"
                    variant="orange"
                    @click="changeSelectedTab('rules')"
                >
                    <FontAwesomeIcon
                        class="tw-mr-2"
                        icon="fa fa-cogs"
                    />
                    {{ __('billing_setting:my_configurations') }}
                </CTabMenuItem>
                <CTabMenuItem
                    :active="selectedTab === 'general'"
                    variant="orange"
                    @click="changeSelectedTab('general')"
                >
                    <FontAwesomeIcon
                        class="tw-mr-2"
                        icon="fa fa-wrench"
                    />
                    {{ __('common:general') }}
                </CTabMenuItem>
                <CTabMenuItem
                    :active="selectedTab === 'invoices'"
                    variant="orange"
                    @click="changeSelectedTab('invoices')"
                >
                    <FontAwesomeIcon
                        class="tw-mr-2"
                        icon="fa fa-file-alt"
                    />
                    {{ __('billing_setting:invoice_template') }}
                </CTabMenuItem>
                <CTabMenuItem
                    :active="selectedTab === 'sepa'"
                    variant="orange"
                    @click="changeSelectedTab('sepa')"
                >
                    <FontAwesomeIcon
                        class="tw-mr-2"
                        icon="fa fa-university"
                    />
                    {{ __('billing_payment:payment_methods.sepa') }}
                </CTabMenuItem>
            </CTabMenu>
            <MBox class="tw-p-4">
                <ModuleRules
                    v-if="selectedTab === 'rules'"
                    :configs="billingConfigs"
                    :nursery="nursery"
                />
                <ModuleGeneral
                    v-if="selectedTab === 'general'"
                    :nursery="nursery"
                    :pdf-documents="pdfDocuments"
                />
                <ModuleInvoices
                    v-if="selectedTab === 'invoices'"
                    :nursery="nursery"
                />
                <ModuleSepa
                    v-if="selectedTab === 'sepa'"
                    :nursery="nursery"
                    :sepas="sepaMandates"
                />
            </MBox>
            <UpdateContractsModal
                v-if="updateContractsModal.isVisible.value"
                :modal="updateContractsModal"
                :nursery="nursery"
            />
        </template>
    </SimplePageTemplate>
</template>
