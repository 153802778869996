<template>
    <CModal
        ref="modal"
        :header-title="__('photo:add_media')"
        size="4xl"
        @shown="onShown"
    >
        <CButton
            class="tw-w-full"
            variant="primary"
            @click="browseFiles"
        >
            <FontAwesomeIcon
                class="tw-mr-2"
                icon="fa fa-upload"
            />
            {{ __('photo:add_from_my_library') }}
        </CButton>
        <input
            id="browseFile"
            accept="image/*, video/*"
            class="tw-hidden"
            multiple
            type="file"
            @change="fileSelected"
        >

        <div class="PhotoAddModal__grid">
            <div
                v-for="(photo, i) in photos"
                :key="'attachedFile' + i"
                class="PhotoAddModal__item"
            >
                <div class="PhotoAddModal__container">
                    <div
                        v-if="photo.video"
                        class="PhotoAddModal__media--is-video"
                    >
                        <PhotoVideoPlayer
                            class="PhotoAddModal__video-player"
                            :extension-type="photo.type"
                            :url="photo.photo_url"
                        />
                        <CButton
                            class="PhotoAddModal__delete-media-button"
                            @click="removeFile(photo)"
                        >
                            <FontAwesomeIcon icon="fa fa-trash"/>
                        </CButton>
                    </div>
                    <div
                        v-else
                        class="PhotoAddModal__media--is-photo"
                        :style="{'background-image': `url('${photo.photo_url}')`}"
                    >
                        <CButton
                            class="PhotoAddModal__delete-media-button"
                            @click="removeFile(photo)"
                        >
                            <FontAwesomeIcon icon="fa fa-trash"/>
                        </CButton>
                    </div>
                    <div
                        v-if="photo.uploading"
                        class="PhotoAddModal__media--is-uploading"
                    >
                        <FontAwesomeIcon
                            class="AddMedia__cloud-icon animated rubberBand infinite slow"
                            icon="fad fa-cloud-upload-alt"
                        />
                    </div>
                    <div
                        v-else-if="photo.uploaded"
                        class="PhotoAddModal__media--is-uploaded"
                    >
                        <FontAwesomeIcon
                            class="AddMedia__check-icon"
                            icon="fad fa-check-circle"
                        />
                    </div>
                    <div
                        v-else
                        class="PhotoAddModal__informations"
                    >
                        <div class="PhotoAddModal__filename">
                            {{ photo.filename + ' - ' + filesize(photo.file.size) }}
                        </div>
                        <CFormGroup>
                            <CLabel>
                                {{ __('common:date') }}
                            </CLabel>
                            <CFormDatepicker
                                v-model="photo.taken_at"
                                :time="true"
                            />
                        </CFormGroup>
                        <CFormGroup>
                            <CLabel>
                                {{ __('common:description') }}
                            </CLabel>
                            <CFormTextArea v-model="photo.description"/>
                        </CFormGroup>
                        <div
                            v-if="!photo.video"
                            class="PhotoAddModal__checkbox-container"
                        >
                            <CCheckbox v-model="photo.website"/>
                            <div class="PhotoAddModal__checkbox-label">
                                {{ __('photo:available_in_library_of_website') }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <template #footer>
            <div class="PhotoAddModal__footer">
                <CButton
                    class="tw-mr-2"
                    @click="$refs.modal.hide()"
                >
                    {{ __('common:actions.cancel') }}
                </CButton>
                <CButton
                    :disabled="loading"
                    variant="primary"
                    @click="storePhotos"
                >
                    <template v-if="loading">
                        <FontAwesomeIcon
                            class="fa-spin tw-mr-2"
                            icon="fa fa-circle-notch"
                        />
                        {{ __('common:loading_dots') }}
                    </template>
                    <template v-else>
                        <FontAwesomeIcon
                            class="tw-mr-2"
                            icon="fa fa-save"
                        />
                        {{ __('common:actions.add') }}
                    </template>
                </CButton>
            </div>
        </template>
    </CModal>
</template>

<script>
    import moment from 'moment';
    import {filesize} from 'filesize';
    import route from '@/modules/legacy/libs/ziggy';
    import useApi from '@/modules/app/composables/useApi';
    import {upload} from '@/modules/legacy/libs/usercontent';
    import PhotoVideoPlayer from '@/modules/photo/components/atoms/PhotoVideoPlayer.vue';
    import _forEach from 'lodash-es/forEach';
    import _head from 'lodash-es/head';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';

    export default {
        components: {
            PhotoVideoPlayer,
        },
        props: {
            nursery: {},
        },
        data: () => ({
            photos: [],
        }),
        computed: {
            moment() {
                return moment;
            },
            filesize() {
                return filesize;
            },
            loading() {
                return this.photos.some(photo => {
                    return photo.uploading;
                });
            },
            processing() {
                return this.photos.some(photo => {
                    return photo.uploading || !photo.uploaded;
                });
            },
        },
        methods: {
            storePhotos() {
                this.photos.forEach(photo => {
                    photo.uploading = true;
                    upload(photo.file)
                        .then(response => {
                            const extension = photo.filename
                                ? photo.filename.split('.').pop().toLowerCase()
                                : '';
                            const data = {
                                description: photo.description,
                                taken_at: moment(photo.taken_at).unix(),
                                website: photo.website,
                                video: photo.video,
                                extension: photo.extension,
                                file: {
                                    key: response.key,
                                    hash: response.hash,
                                    name: photo.filename,
                                    extension,
                                },
                            };

                            useApi().legacy.post(
                                route('nurseries.photos.store', {
                                    nurseries: this.nursery.id,
                                }),
                                data,
                            )
                                .then(() => {
                                    photo.uploading = false;
                                    photo.uploaded = true;
                                    if (!this.processing) {
                                        this.$emit('refresh');
                                        this.$refs.modal.hide();
                                    }
                                })
                                .catch(error => {
                                    photo.uploading = false;
                                    if (
                                        error &&
                                        error.response &&
                                        error.response.status === 422
                                    ) {
                                        _forEach(
                                            error.response.data.errors,
                                            value => {
                                                useNotification().error(_head(value));
                                            },
                                        );
                                    } else {
                                        useNotification().error(error);
                                    }
                                });
                        })
                        .catch(() => {
                            photo.uploading = false;
                        });
                });
            },

            fileSelected() {
                const files = document.getElementById('browseFile').files;
                const videoExtensions = ['m4v', 'avi', 'mpg', 'mp4', 'mov'];
                for (let i = 0; i < files.length; i++) {
                    if (files[i].size > 100 * 1024 * 1024) {
                        useNotification().error(`Le fichier ${files[i].name} est trop volumineux (max 100mb).`);
                    } else {
                        const extension = files[i].name ? files[i].name.split('.').pop().toLowerCase() : 'jpeg';
                        const reader = new FileReader();
                        reader.onload = e => {
                            const photo = {
                                id: null,
                                filename: files[i].name ? files[i].name : 'Photo',
                                taken_at: files[i].lastModified
                                    ? moment(files[i].lastModified).format(
                                        'DDMMYYYYHHmm',
                                    )
                                    : moment().format(),
                                description: null,
                                photo_url: e.target.result,
                                thumbnail_url: e.target.result,
                                kids: [],
                                website: 0,
                                file: files[i],
                                extension,
                                video: videoExtensions.includes(extension),
                                uploading: false,
                                uploaded: false,
                            };
                            this.photos.push(photo);
                        };
                        reader.readAsDataURL(files[i]);
                    }
                }
            },

            removeFile(file) {
                const index = this.photos.indexOf(file);
                this.photos.splice(index, 1);
            },

            browseFiles() {
                document.getElementById('browseFile').click();
            },

            onShown() {
                if (document.getElementById('browseFile')) {
                    document.getElementById('browseFile').value = '';
                }
                this.photos = [];
            },
        },
    };
</script>

<style lang="scss" scoped>
    .PhotoAddModal__grid {
        @apply tw-grid tw-grid-cols-1 sm:tw-grid-cols-2 md:tw-grid-cols-3 tw-gap-4 tw-mt-5;
    }

    .PhotoAddModal__item {
        @apply tw-min-h-56 tw-mb-6;
    }

    .PhotoAddModal__container {
        @apply tw-border tw-border-gray-300 tw-rounded-lg;
    }

    .PhotoAddModal__video-player {
        @apply tw-rounded-t-lg tw-w-full tw-h-48;
    }

    .PhotoAddModal__media--is-video {
        @apply tw-relative tw-bg-cover tw-bg-center tw-rounded-t-lg tw-border-b tw-border-gray-500;
    }

    .PhotoAddModal__media--is-photo {
        @apply tw-relative tw-w-full tw-h-48 tw-bg-cover tw-bg-center tw-rounded-t-lg tw-border-b tw-border-gray-500;
    }

    .PhotoAddModal__media--is-uploading {
        @apply tw-flex tw-bg-gray-50 tw-text-center tw-items-center;
    }

    .PhotoAddModal__media--is-uploaded {
        @apply tw-flex tw-bg-gray-50 tw-text-center tw-items-center;
    }

    .PhotoAddModal__delete-media-button {
        @apply tw-absolute tw-top-1 tw-right-1 tw-cursor-pointer hover:tw-scale-110 tw-text-red-500;
    }

    .PhotoAddModal__informations {
        @apply tw-p-2 tw-flex tw-flex-col tw-gap-3;
    }

    .PhotoAddModal__filename {
        @apply tw-text-gray-700 tw-italic tw-break-words;
    }

    .PhotoAddModal__checkbox-container {
        @apply tw-flex tw-flex-row tw-items-start;
    }

    .PhotoAddModal__checkbox-label {
        @apply tw-text-gray-800 tw-text-sm tw-font-semibold;
    }

    .AddMedia__cloud-icon {
        @apply tw-text-5xl tw-text-blue-500 tw-w-full tw-py-6;
    }

    .AddMedia__check-icon {
        @apply tw-text-5xl tw-text-green-500 tw-w-full tw-py-6;
    }

    .PhotoAddModal__footer {
        @apply tw-ml-auto;
    }
</style>
