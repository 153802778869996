import type {Epoch} from '@meekohq/lumos';
import {Lang} from '@meekohq/lumos';
import type {DateTimePeriod, DateTimeSingle, DateTimeValue} from '@/modules/meeko-ui/types/DateTime';


export type DateTimeModifiers = DateTimeModifierSingle | DateTimeModifiersPeriod;

export type DateTimeModifierSingle = (value: Epoch) => Epoch;
export type DateTimeModifiersPeriod = { from?: DateTimeModifierSingle; to?: DateTimeModifierSingle };

export default function useDateTimeModifiers(modifiers: DateTimeModifiers | undefined) {
    function modify(value: DateTimeValue): DateTimeValue {
        if (!modifiers || Lang.isFunction(modifiers)) {
            return modifySingle(value as DateTimeSingle);
        }

        return modifyPeriod(value as DateTimePeriod);
    }

    function modifyPeriod(value: DateTimePeriod): DateTimePeriod {
        if (!modifiers || !validatePeriodModifiers(modifiers)) {
            return {
                from: value.from,
                to: value.to,
            };
        }

        return {
            from: value.from && modifiers.from ? modifiers.from(value.from) : undefined,
            to: value.to && modifiers.to ? modifiers.to(value.to) : undefined,
        };
    }

    function modifySingle(value: DateTimeSingle): DateTimeSingle {
        if (!validateSingleModifier(modifiers) || !modifiers) {
            return value;
        }

        return value ? modifiers(value) : undefined;
    }

    function validatePeriodModifiers(modifiersValue: DateTimeModifiers | undefined): modifiersValue is DateTimeModifiersPeriod {
        if (!modifiersValue) {
            return true;
        }

        if (!Lang.isObject(modifiersValue)) {
            throw new Error('Modifiers must be an object in period mode');
        }

        if ('from' in modifiersValue && modifiersValue.from && !Lang.isFunction(modifiersValue.from)) {
            throw new Error('modifiers.from must be a function in period mode');
        }

        if ('to' in modifiersValue && modifiersValue.to && !Lang.isFunction(modifiersValue.to)) {
            throw new Error('modifiers.to must be a function in period mode');
        }

        return true;
    }

    function validateSingleModifier(modifierValue: DateTimeModifiers | undefined): modifierValue is DateTimeModifierSingle {
        if (!modifierValue) {
            return true;
        }

        if (!Lang.isFunction(modifierValue)) {
            throw new Error('Modifier must be a function');
        }

        return true;
    }

    return {
        modify,
        modifyPeriod,
        modifySingle,
    };
}

export function useDateTimeModifiersPresets() {
    const startOfYear: DateTimeModifierSingle = (value: Epoch) => value.startOfYear();
    const endOfYear: DateTimeModifierSingle = (value: Epoch) => value.endOfYear();
    const startOfMonth: DateTimeModifierSingle = (value: Epoch) => value.startOfMonth();
    const endOfMonth: DateTimeModifierSingle = (value: Epoch) => value.endOfMonth();
    const startOfWeek: DateTimeModifierSingle = (value: Epoch) => value.startOfWeek();
    const endOfWeek: DateTimeModifierSingle = (value: Epoch) => value.endOfWeek();
    const startOfWeekNoTime: DateTimeModifierSingle = (value: Epoch) => {
        const valueStartOfWeek = value.startOfWeek();

        return value.set({
            year: valueStartOfWeek.year,
            month: valueStartOfWeek.month,
            day: valueStartOfWeek.day,
        });
    };
    const endOfWeekNoTime: DateTimeModifierSingle = (value: Epoch) => {
        const valueEndOfWeek = value.endOfWeek();

        return value.set({
            year: valueEndOfWeek.year,
            month: valueEndOfWeek.month,
            day: valueEndOfWeek.day,
        });
    };
    const startOfDay: DateTimeModifierSingle = (value: Epoch) => value.startOfDay();
    const endOfDay: DateTimeModifierSingle = (value: Epoch) => value.endOfDay();
    const startOfHour: DateTimeModifierSingle = (value: Epoch) => value.startOfHour();
    const endOfHour: DateTimeModifierSingle = (value: Epoch) => value.endOfHour();
    const startOfMinute: DateTimeModifierSingle = (value: Epoch) => value.startOfMinute();
    const endOfMinute: DateTimeModifierSingle = (value: Epoch) => value.endOfMinute();

    return {
        startOfYear,
        endOfYear,
        startOfWeek,
        endOfWeek,
        startOfWeekNoTime,
        endOfWeekNoTime,
        startOfMonth,
        endOfMonth,
        startOfDay,
        endOfDay,
        startOfHour,
        endOfHour,
        startOfMinute,
        endOfMinute,
    };
}
