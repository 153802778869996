<template>
    <i
        aria-hidden="true"
        :class="baseClass"
    />
</template>

<script lang="ts">
    import {computed, defineComponent} from 'vue';

    export default defineComponent({
        props: {
            icon: {type: String, required: true},
            spin: {type: Boolean, default: false},
            fixedWidth: {type: Boolean, default: false},
            size: {type: String, default: ''},
        },
        setup(props) {
            const baseClass = computed(() => {
                const output: string[] = [];

                output.push(props.icon);

                if (props.spin) {
                    output.push('fa-spin');
                }

                if (props.fixedWidth) {
                    output.push('fa-fw');
                }

                if (props.size) {
                    output.push(`fa-${props.size}`);
                }

                return output;
            });

            return {
                baseClass,
            };
        },
    });

</script>

<style lang="scss" scoped>

</style>
