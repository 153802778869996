<template>
    <div class="TasksTabActions">
        <div class="TasksTabActions__actions-container">
            <div class="tw-flex tw-items-center tw-gap-2">
                <CText
                    font-size="xl"
                    font-weight="semibold"
                >
                    {{ __('activity:manage_my_tasks') }}
                </CText>
                <CText
                    v-if="filteredTasksCount"
                    font-size="lg"
                >
                    ({{ __('common:result_with_count', {count: filteredTasksCount}) }})
                </CText>
            </div>
            <CButton
                v-if="can('create', 'tasks')"
                class="sm:tw-ml-auto"
                :icon-plus="true"
                :label="__('common:actions.add_task')"
                variant="primary"
                @click="$emit('create-task')"
            />
        </div>
        <div class="TasksTabActions__filters-container">
            <CFormSelect
                button-size="sm"
                :fallback-text="__('activity:task_type')"
                multi
                :options="taskCategories"
                :search-bar="false"
                :value="categories"
                @input="$emit('update:categories', $event)"
            />
            <StaffFinder
                v-if="userStaff"
                button-size="sm"
                class="TasksTabActions__selector"
                has-active-contract
                multi
                :options-callback="staffFinderOptionsCallback"
                size="sm"
                :user-staff="userStaff"
                :value="assignees"
                @input="emitAssignees($event)"
            >
                <template #button-text-empty>
                    {{ __('activity:assigned_person') }}
                </template>
            </StaffFinder>
            <div class="TasksTabActions__switch-container">
                <div class="TasksTabActions__switch-text">
                    {{ __('activity:see_the_finished_ones_female_other') }}
                </div>
                <CSwitch
                    :value="completed"
                    @input="$emit('update:completed', $event)"
                />
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import type {PropType} from 'vue';
    import {defineComponent, onBeforeMount, ref} from 'vue';
    import __ from '@/modules/app/utils/i18n-facade';
    import StaffFinder from '@/modules/request/components/Teams/StaffFinder.vue';
    import StaffModel from '@/modules/human-resources/models/StaffModel';
    import OrganizationModel from '@/modules/organization/models/OrganizationModel';
    import KidModel from '@/modules/family/models/KidModel';
    import MemberModel from '@/modules/family/models/MemberModel';
    import RegistrationModel from '@/modules/registration/models/RegistrationModel';
    import useAbility from '@/modules/app/composables/useAbility';
    import CustomerModel from '@/modules/cashier/models/CustomerModel';
    import MedicalActionModel from '@/modules/health/models/MedicalActionModel';
    import useTaskFilters from '@/modules/activity/composables/useTaskFilters';
    import useNotificationStore from '@/modules/app/composables/useNotificationStore';

    export default defineComponent({
        components: {
            StaffFinder,
        },
        props: {
            categories: {
                type: Array,
                required: true,
            },
            assignees: {
                type: Array as PropType<Array<StaffModel | {
                    id: string,
                    fullname: string
                }>>,
                required: true,
            },
            completed: {
                type: Boolean,
                required: true,
            },
            userStaff: {
                type: Object as PropType<StaffModel> | null,
                default: null,
            },
            filteredTasksCount: {
                type: Number,
                default: null,
            },
        },
        setup(props, {emit}) {
            const {can} = useAbility();

            const {
                waitForIsLoading,
            } = useTaskFilters();

            // In this context the `showVaccines` option mean that the user want to display medical treatments
            const {showVaccines: displayMedicalTreatments} = useNotificationStore();

            const taskCategories = ref([
                {
                    value: new OrganizationModel().getType(),
                    text: __('common:organization_other'),
                },
                {
                    value: new KidModel().getType(),
                    text: __('common:kid_other'),
                },
                {
                    value: new MemberModel().getType(),
                    text: __('common:parent_other'),
                },
                {
                    value: new StaffModel().getType(),
                    text: __('common:staff_other'),
                },
                {
                    value: new RegistrationModel().getType(),
                    text: __('common:registration_other'),
                },
                {
                    value: new CustomerModel().getType(),
                    text: __('common:customer_other'),
                },
            ]);

            onBeforeMount(async () => {
                await waitForIsLoading();
                if (displayMedicalTreatments.value) {
                    taskCategories.value.push({
                        value: new MedicalActionModel().getType(),
                        text: __('activity:medical_action_other'),
                    });
                }
            });

            function emitAssignees(assignees: Array<StaffModel | {
                id: string,
                fullname: string
            }>) {
                emit('update:assignees', assignees);
            }

            function staffFinderOptionsCallback(models: any[]) {
                models.unshift({
                    id: 'anyone',
                    fullname: __('activity:unassigned_female'),
                });

                return models;
            }

            return {
                can,
                taskCategories,
                emitAssignees,
                staffFinderOptionsCallback,
            };
        },
    });
</script>

<style lang="scss" scoped>
    .TasksTabActions {
        @apply tw-w-full tw-flex tw-flex-col tw-gap-3;
    }

    .TasksTabActions__filters-container {
        @apply tw-w-full tw-flex tw-flex-wrap tw-items-center tw-bg-blue-50 tw-rounded-xl;
        @apply tw-p-2 tw-gap-2;
    }

    .TasksTabActions__switch-container {
        @apply tw-flex tw-items-center tw-gap-2 lg:tw-ml-auto;
    }

    .TasksTabActions__switch-text {
        @apply tw-text-sm tw-font-semibold tw-mr-1;
    }

    .TasksTabActions__actions-container {
        @apply tw-w-full tw-flex sm:tw-flex-row tw-flex-col sm:tw-items-center tw-gap-2;
    }
</style>
