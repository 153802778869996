<template>
    <CButtonGroup>
        <CButton
            :disabled="disabled"
            @click="ruleDropdown.toggle()"
        >
            {{ ruleDetails }}
        </CButton>
        <CDropdown
            ref="ruleDropdown"
            legacy-mode
            @clickOutside="resetChanges"
        >
            <div class="tw-max-h-96 tw-overflow-y-auto tw-p-0.5">
                <CFormGroup class="tw-mb-4">
                    <CLabel>{{ __('common:frequency') }}</CLabel>
                    <Frequency
                        v-model="ruleCopy.freq"
                        @input="ruleCopy.reset()"
                    />
                </CFormGroup>
                <component
                    :is="ruleCopy.freq"
                    :rule="ruleCopy"
                />
                <CFormGroup class="tw-mt-8">
                    <CLabel>{{ __('common:end_repeat') }}</CLabel>
                    <End :rule="ruleCopy"/>
                </CFormGroup>

                <CHStack
                    class="tw-mt-8"
                    distribute="end"
                >
                    <CButton @click="cancel">
                        {{ __('common:actions.cancel') }}
                    </CButton>
                    <CButton
                        class="tw-ml-2"
                        variant="primary"
                        @click="validate"
                    >
                        {{ __('common:actions.validate') }}
                    </CButton>
                </CHStack>
            </div>
        </CDropdown>
        <CButton
            :disabled="disabled"
            @click="remove"
        >
            <i
                aria-hidden="true"
                class="fas fa-trash"
            />
        </CButton>
    </CButtonGroup>
</template>

<script lang="ts">
    import type {PropType, Ref} from 'vue';
    import {computed, defineComponent, ref, watch} from 'vue';
    import Rule from '@/modules/calendar/utils/RuleSet/Rule';
    import Daily from '@/modules/calendar/components/RuleSet/Rule/Daily.vue';
    import Weekly from '@/modules/calendar/components/RuleSet/Rule/Weekly.vue';
    import Monthly from '@/modules/calendar/components/RuleSet/Rule/Monthly.vue';
    import Yearly from '@/modules/calendar/components/RuleSet/Rule/Yearly.vue';
    import Frequency from '@/modules/calendar/components/RuleSet/Rule/Frequency.vue';
    import End from '@/modules/calendar/components/RuleSet/Rule/End.vue';
    import useRuleSetHumanDetails from '@/modules/calendar/composables/RuleSet/useRuleSetHumanDetails';

    export default defineComponent({
        components: {
            Frequency,
            End,
            day: Daily,
            week: Weekly,
            month: Monthly,
            year: Yearly,
        },
        props: {
            rule: {
                type: Object as PropType<Rule>,
                required: true,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
        },
        setup(props, {emit}) {
            const ruleCopy: Ref<Rule> = ref(props.rule ? Rule.fromJSON(props.rule) : new Rule());
            const ruleDropdown = ref();

            const {getRecurrenceRuleDetails} = useRuleSetHumanDetails();

            // Translation rule details
            const ruleDetails = computed(() => {
                return getRecurrenceRuleDetails(ruleCopy.value);
            });

            // Validation of cnange
            function validate() {
                emit('validate', ruleCopy.value);
                ruleDropdown.value.hide();
            }

            function resetChanges() {
                ruleCopy.value = Rule.fromJSON(props.rule);
            }

            function cancel() {
                resetChanges();
                emit('cancel', props.rule);
                ruleDropdown.value.hide();
            }

            function remove() {
                emit('remove', props.rule);
                ruleDropdown.value.hide();
            }

            watch(() => props.rule, () => {
                resetChanges();
            }, {deep: true});

            return {
                cancel,
                resetChanges,
                remove,
                ruleCopy,
                ruleDetails,
                ruleDropdown,
                validate,
            };
        },
    });
</script>
