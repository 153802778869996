<template>
    <MModal
        :header-title="__('organization:add_organization')"
        :modal="modal"
        size="xl"
    >
        <CForm>
            <CFormTwoColumns>
                <MInputGroup
                    v-model="newNursery.name"
                    :errors="errors.get('name')"
                    :label="__('common:last_name') + '*'"
                    :placeholder="__('common:name_of_organization')"
                />
                <CFormGroup>
                    <CLabel>{{ __('common:opening_date') }}</CLabel>
                    <MDatePicker
                        v-model="newNursery.opening_date"
                        class="tw-w-full"
                        format="yyyy-MM-dd"
                    />
                </CFormGroup>
            </CFormTwoColumns>
            <CFormTwoColumns>
                <MInputGroup
                    v-model="newNursery.email"
                    :errors="errors.get('email')"
                    :label="__('common:email') + '*'"
                    :placeholder="__('organization:email_of_organizations')"
                />
                <MInputGroup
                    v-model="newNursery.phone"
                    :errors="errors.get('phone')"
                    :label="__('common:phone.general') + '*'"
                    :placeholder="__('common:phone.general')"
                />
            </CFormTwoColumns>
            <CFormTwoColumns>
                <CFormGroup>
                    <CLabel>{{ __('common:address.country') }}</CLabel>
                    <SelectCountry v-model="newNursery.country"/>
                    <CFormErrorMessageList
                        v-if="errors.has('country')"
                        :errors="errors.get('country')"
                    />
                </CFormGroup>
                <CFormGroup>
                    <CLabel>{{ __('common:language') }}</CLabel>
                    <CFormSelect
                        v-model="newNursery.locale"
                        class="tw-w-full"
                        :options="availableLanguages"
                        :search-bar="false"
                    />
                    <CFormErrorMessageList
                        v-if="errors.has('locale')"
                        :errors="errors.get('locale')"
                    />
                </CFormGroup>
            </CFormTwoColumns>
            <MInputGroup
                v-model="newNursery.address"
                :errors="errors.get('address')"
                :label="__('common:address.address') + '*'"
                :placeholder="__('common:address.address')"
            />
            <CFormTwoColumns>
                <MInputGroup
                    v-model="newNursery.city"
                    :errors="errors.get('city')"
                    :label="__('common:address.city') + '*'"
                    :placeholder="__('common:address.city')"
                />
                <MInputGroup
                    v-model="newNursery.zipcode"
                    :errors="errors.get('zipcode')"
                    :label="__('common:address.zip_code') + '*'"
                    :placeholder="__('common:address.zip_code')"
                />
            </CFormTwoColumns>
            <CFormTwoColumns>
                <CFormGroup>
                    <CLabel>{{ __('common:number_of_places') + '*' }}</CLabel>
                    <CInputGroup>
                        <MInput
                            v-model.number="newNursery.places"
                            placeholder="12"
                        />
                        <CInputAddon>
                            {{ __('common:place', {count: newNursery.places}) }}
                        </CInputAddon>
                    </CInputGroup>
                    <CFormErrorMessageList
                        v-if="errors.has('places')"
                        :errors="errors.get('places')"
                    />
                </CFormGroup>
                <CFormGroup>
                    <CLabel>{{ __('organization:surface') }}</CLabel>
                    <CInputGroup>
                        <MInput
                            v-model.number="newNursery.surface"
                            :placeholder="__('organization:one_hundred_and_forty')"
                        />
                        <CInputAddon>
                            m²
                        </CInputAddon>
                    </CInputGroup>
                    <CFormErrorMessageList
                        v-if="errors.has('surface')"
                        :errors="errors.get('surface')"
                    />
                </CFormGroup>
            </CFormTwoColumns>
        </CForm>
        <template #footer-end="{hide}">
            <MButton
                :label="__('common:actions.cancel')"
                @click="hide"
            />
            <MButton
                :disabled="loading"
                :label="__('common:actions.add')"
                variant="primary"
                @click="save"
            />
        </template>
    </MModal>
</template>

<script>
    import route from '@/modules/legacy/libs/ziggy';
    import useApi from '@/modules/app/composables/useApi';
    import moment from 'moment';
    import SelectCountry from '@/modules/legacy/components/Modules/SelectCountry.vue';
    import __ from '@/modules/app/utils/i18n-facade';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';
    import useLocale from '@/modules/app/composables/useLocale.ts';
    import {collect} from '@meekohq/lumos';

    export default {
        components: {
            SelectCountry,
        },
        props: {
            modal: {
                type: Object,
                required: true,
            },
        },
        data() {
            return {
                loading: false,
                errors: collect(),
                newNursery: {},
            };
        },
        computed: {
            availableLanguages() {
                return useLocale().availableLanguages;
            },
        },
        methods: {
            collect,

            prepareNursery() {
                this.newNursery = {
                    id: null,
                    name: null,
                    email: null,
                    phone: null,
                    website: null,
                    address: null,
                    zipcode: null,
                    city: null,
                    state: null,
                    country: 'FR',
                    places: null,
                    surface: null,
                    timezone: 'Europe/Paris',
                    locale: 'fr',
                };
            },

            save() {
                this.loading = true;
                this.newNursery.timezone = moment.tz.guess();

                useApi().legacy.post(route('nurseries.store'), this.newNursery)
                    .then(response => {
                        this.$emit('store:nursery', response.data);
                        this.resetModal();
                        useNotification().success(__('organization:organization_added_successfully'));
                        this.modal.hide();
                    })
                    .catch(error => {
                        if (error?.response?.status === 422) {
                            // On map les erreurs pour les afficher dans les champs avec la signature adaptée
                            this.errors = collect(error.response.data.errors).map(fieldErrors => {
                                return collect(fieldErrors).map(errorMessage => {
                                    return {
                                        message: errorMessage,
                                    };
                                });
                            });
                        } else {
                            useNotification().error(error);
                        }
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            },

            resetModal() {
                this.errors = collect();
                this.prepareNursery();
            },

            getDefaultErrorsCollection() {
                return collect(
                    {
                        name: collect(),
                        email: collect(),
                        phone: collect(),
                        address: collect(),
                        zipcode: collect(),
                        city: collect(),
                        places: collect(),
                        surface: collect(),
                    },
                );
            },
        },
    };
</script>
