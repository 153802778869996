<template>
    <div>
        <div class="tw-flex tw-justify-between">
            <div class="tw-flex tw-items-center tw-mb-3">
                <MDatePeriodFilter v-model="periodFilter"/>
                <OrganizationsFilter
                    v-if="hasManyOrganizationsInAccount"
                    v-model="organizationsFilter"
                    class="tw-ml-3"
                    multi
                />
                <BalanceTypesFilter
                    v-model="balanceTypesFilter"
                    class="tw-ml-3"
                    :organizations-filter="organizationsFilter"
                />
                <TeamFinder
                    v-model="teamsFilter"
                    class="tw-ml-3"
                    multi
                    show-icon
                />
            </div>
            <div class="tw-flex tw-items-center tw-mb-3">
                <CButton
                    :loading="loading"
                    variant="primary"
                    @click="exportAllocations"
                >
                    {{ __('common:actions.export') }}
                </CButton>
            </div>
        </div>

        <StaffAllocationsList
            :balance-types-filter="balanceTypesFilter"
            :organizations-filter="organizationsFilter"
            :period-filter="periodFilter"
            :teams-filter="teamsFilter"
        />
    </div>
</template>

<script lang="ts">
    import {defineComponent, ref, watch} from 'vue';
    import StaffAllocationsList from '@/modules/human-resources/components/balance/List/StaffAllocationsList.vue';
    import MDatePeriodFilter from '@/modules/meeko-ui/components/MDatePeriodFilter.vue';
    import BalanceTypesFilter from '@/modules/human-resources/components/balance/Filter/BalanceTypesFilter.vue';
    import TeamFinder from '@/modules/human-resources/components/team/TeamFinder.vue';
    import AccountModel from '@/modules/app/models/AccountModel';
    import useManager from '@/modules/app/composables/useManager';
    import useApi from '@/modules/app/composables/useApi';
    import __ from '@/modules/app/utils/i18n-facade';
    import {useLocalStorage} from '@/modules/legacy/helpers/useLocalStorage';
    import route from '@/modules/legacy/libs/ziggy';
    import {collect, JsonAPISchema} from '@meekohq/lumos';
    import moment from 'moment/moment';
    import useUserFilters, {OutputType} from '@/modules/app/composables/useUserFilters';
    import _cloneDeep from 'lodash-es/cloneDeep';
    import OrganizationsFilter from '@/modules/human-resources/components/balance/Filter/OrganizationsFilter.vue';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';

    export default defineComponent({
        name: 'StaffAllocationsReport',
        components: {
            BalanceTypesFilter,
            MDatePeriodFilter,
            OrganizationsFilter,
            StaffAllocationsList,
            TeamFinder,
        },
        setup() {
            const {activeOrganization} = useManager();
            const {getLocalStorage, setLocalStorage, removeLocalStorage} = useLocalStorage();
            const loading = ref(false);

            const periodFilter = ref({
                from: getLocalStorage<string>('staff:allocations:from') || moment().startOf('month').toISOString(true),
                to: getLocalStorage<string>('staff:allocations:to') || moment().endOf('month').toISOString(true),
            });

            const balanceTypesFilter = ref(getLocalStorage('staff:allocations:types') || []);

            const {selectedOptions: organizationsFilter} =
                useUserFilters('staff:allocations:organizations', OutputType.array, [_cloneDeep(activeOrganization.value)]);

            const {selectedOptions: teamsFilter} =
                useUserFilters('staffs:allocations:teams', OutputType.array, []);

            const {legacyAccount} = useManager();

            const hasManyOrganizationsInAccount = ref(false);
            new AccountModel(legacyAccount.value.id).hasManyOrganizations().then(response => {
                hasManyOrganizationsInAccount.value = response;
            });

            watch(periodFilter, val => {
                if (val?.from) {
                    setLocalStorage('staff:allocations:from', val.from);
                } else {
                    removeLocalStorage('staff:allocations:from');
                }

                if (val?.to) {
                    setLocalStorage('staff:allocations:to', val.to);
                } else {
                    removeLocalStorage('staff:allocations:to');
                }
            });

            watch(balanceTypesFilter, val => {
                if (val) {
                    setLocalStorage('staff:allocations:types', val);
                } else {
                    removeLocalStorage('staff:allocations:types');
                }
            });

            const exportAllocations = async function() {
                loading.value = true;

                useApi().legacy.post(route('hr/staff/report/allocations'), new JsonAPISchema.JsonApiDocument(
                    // Instanciation of the JsonApiData for the data of the JsonApiDocument.
                    new JsonAPISchema.JsonApiData({
                        // Pass an object with key/value of what we want to post.
                        startedAt: moment(periodFilter.value.from).format('Y-MM-DD'),
                        endedAt: moment(periodFilter.value.to).format('Y-MM-DD'),
                        organizations: collect(organizationsFilter.value).pluck('id').all(),
                        balanceTypes: balanceTypesFilter.value,

                    })), {responseType: 'blob'}, // Set the response type on blob.
                ).then(response => {
                    // Create a Blob and pass the data's response to it.
                    const blob = new Blob([response.data]);
                    // Create an anchor Node.
                    const link = document.createElement('a');
                    // Set the title of the downloaded file.
                    link.download = 'Compteurs_du_personnel.xlsx';
                    // Set the URL loading to another window.
                    link.target = '_blank';
                    // Create an URL wich represent the blob passed in parameter.
                    link.href = window.URL.createObjectURL(blob);

                    // Dispatch a bubbling mouse event click on window.
                    link.dispatchEvent(new MouseEvent('click', {bubbles: true, cancelable: true, view: window}));
                    useNotification().success(__('hr_balance:export_downloaded_successfully'));
                    loading.value = false;
                }).catch(error => {
                    if (!(error && error.response && error.response.status === 422)) {
                        useNotification().error(error);
                    }
                    loading.value = false;
                });
            };

            return {
                balanceTypesFilter,
                exportAllocations,
                loading,
                periodFilter,
                organizationsFilter,
                hasManyOrganizationsInAccount,
                teamsFilter,
            };
        },
    });
</script>
