<template>
    <CModal
        ref="modal"
        :header-title="title"
        visible
        @hide="$emit('hide')"
    >
        <slot/>
        <template #footer>
            <CButton
                class="tw-ml-auto"
                @click="$refs.modal.hide()"
            >
                {{ __('common:actions.close') }}
            </CButton>
            <CButton
                class="tw-ml-2"
                variant="danger"
                @click="confirm()"
            >
                {{ __('common:actions.delete') }}
            </CButton>
        </template>
    </CModal>
</template>

<script lang="ts">
    import {defineComponent} from 'vue';
    import {useLegacyModalBus} from '@/modules/meeko-ui/composables/useLegacyModalBus';

    export default defineComponent({
        components: {},
        props: {
            title: {
                type: String,
                required: true,
            },
        },
        setup(props, {emit}) {
            const {modal, show, hide} = useLegacyModalBus();

            function confirm() {
                hide();
                emit('confirmed');
            }

            return {
                show,
                hide,
                confirm,
                modal,
            };
        },
    });
</script>
