<template>
    <div>
        <CVStack gap="10">
            <support-meeko :ressource="nursery"/>

            <CCard
                body-size="none"
                :border="false"
                class="tw-border-t tw-border-blue-400"
                :header-border="false"
                :shadow="true"
            >
                <template #header>
                    <div class="tw-w-full tw-flex tw-items-center tw-justify-between">
                        <div class="tw-flex tw-items-baseline">
                            <div class="tw-bg-blue-200 tw-mr-2 tw-rounded-full tw-h-10 tw-w-10 tw-flex tw-items-center tw-justify-center">
                                <i
                                    aria-hidden="true"
                                    class="fad fa-shopping-cart tw-text-blue-600 tw-text-xl"
                                />
                            </div>
                            <CCardHeroTitle class="tw-text-blue-500 tw-mr-2">
                                {{ __('subscription:manage_subscription') }}
                            </CCardHeroTitle>
                        </div>
                    </div>
                </template>
            </CCard>

            <CVStack gap="4">
                <CAlert
                    v-if="!customer.subscribed && !customer.on_trial"
                    class="tw-shadow-md"
                    :close-button="false"
                    variant="warning"
                >
                    {{ __('subscription:you_need_a_meeko_subscription_to_continue_using_the_application') }}
                </CAlert>

                <CAlert
                    v-if="!customer.subscribed && customer.on_trial"
                    class="tw-shadow-md"
                    :close-button="false"
                    variant="warning"
                >
                    {{ __('subscription:trial_period_warning') }}
                </CAlert>

                <CTabMenu v-if="legacyUser.is_owner">
                    <CTabMenuItem :to="{ name: 'subscriptions.plan' }">
                        <i
                            aria-hidden="true"
                            class="fas fa-sync-alt tw-mr-2"
                        />{{ __('common:subscription') }}
                    </CTabMenuItem>
                    <CTabMenuItem
                        :exact="true"
                        :to="{ name: 'subscriptions.paymentsMethods' }"
                    >
                        <i
                            aria-hidden="true"
                            class="fas fa-credit-card tw-mr-2"
                        />{{ __('subscription:payment_method') }}
                    </CTabMenuItem>
                    <CTabMenuItem
                        :exact="true"
                        :to="{ name: 'subscriptions.address' }"
                    >
                        <i
                            aria-hidden="true"
                            class="fas fa-address-card tw-mr-2"
                        />{{ __('common:billing_address_one') }}
                    </CTabMenuItem>
                    <CTabMenuItem
                        :exact="true"
                        :to="{ name: 'subscriptions.invoices' }"
                    >
                        <i
                            aria-hidden="true"
                            class="fas fa-file-alt tw-mr-2"
                        />{{ __('common:invoice_other') }}
                    </CTabMenuItem>
                    <CTabMenuItem
                        v-if="customer.has_stripe && (customer.subscribed || false) && !customer.on_grace_period"
                        :exact="true"
                        :to="{ name: 'subscriptions.cancel' }"
                    >
                        <i
                            aria-hidden="true"
                            class="fas fa-ban tw-mr-2"
                        />{{ __('subscription:unsubscribe') }}
                    </CTabMenuItem>
                </CTabMenu>

                <div class="tw-bg-white tw-rounded-lg tw-shadow-md tw-p-4">
                    <router-view
                        :customer="customer"
                        :nursery="nursery"
                        :user="user"
                        @customerUpdated="updateCustomer"
                    />
                </div>
            </CVStack>
        </CVStack>
    </div>
</template>

<script lang="ts">
    import {defineComponent, getCurrentInstance} from 'vue';
    import useAuth from '@/modules/app/composables/useAuth';

    export default defineComponent({
        props: {
            nursery: {required: true},
            customer: {required: true},
            user: {required: true},
        },
        setup() {
            const updateCustomer = function(customer) {
                // @ts-ignore
                getCurrentInstance().$bus.$emit('update:customer', customer);
            };
            const {legacyUser} = useAuth();

            return {
                updateCustomer,
                legacyUser,
            };
        },
    });
</script>
