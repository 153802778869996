<template>
    <div class="CustomerHeroHeader">
        <div class="CustomerHeroHeader__actions-container">
            <MHeading
                level="h4"
                variant="billing"
            >
                <FontAwesomeIcon
                    class="tw-mr-1"
                    icon="fad fa-file-user"
                />
                {{ __('common:customer_one') }}
            </MHeading>
            <CButtonGroup v-if="customer && !loading">
                <CButton @click="$refs.taxCertificatePopup.toggle()">
                    {{ __('common:tax_certificate_one') }}
                </CButton>
                <CDropdown
                    ref="taxCertificatePopup"
                    legacy-mode
                    placement="auto"
                >
                    <TaxCertificate
                        :customer-model="customer"
                        :nursery="legacyNursery"
                        :user="legacyUser"
                    />
                </CDropdown>
                <ActionButton
                    :customer-model="customer"
                    :parent="$refs.action"
                    @deleted="$router.push({name: 'cashier/customers.index', params: {nursery: activeOrganization.getKey()}})"
                />
            </CButtonGroup>
        </div>
        <div class="CustomerHeroHeader__name-container">
            <template v-if="customer && !loading">
                <MHeading class="tw-truncate">
                    {{ customer.attributes.name }}
                </MHeading>
                <CustomerBillingStatus :customer="customer"/>
                <TagsSelector
                    class="tw-ml-auto"
                    :taggable-model="customer"
                    taggable-type="customer"
                    :tags="customer.tags().value().toArray()"
                    @attached="customer.tags().value().push($event)"
                    @detached="customer.tags().mutate(v => v.filter(c => c.getKey() !== $event.getKey()))"
                />
            </template>
            <template v-else>
                <CSkeletonItem class="tw-w-56 tw-h-6"/>
            </template>
        </div>
        <CustomerModalUpdate
            v-if="isModalVisible"
            :customer-model="customer"
            @closed="toggleModal(false)"
            @updated="$emit('updated')"
        />
    </div>
</template>

<script lang="ts">
    import type {PropType} from 'vue';
    import {computed, defineComponent} from 'vue';
    import useManager from '@/modules/app/composables/useManager';
    import {useToggle} from '@vueuse/core';
    import type CustomerModel from '@/modules/cashier/models/CustomerModel';
    import ActionButton from '@/modules/cashier/components/core/customer/CustomerHero/molecules/ActionButton.vue';
    import CustomerBillingStatus from '@/modules/cashier/components/core/customer/atoms/CustomerBillingStatus.vue';
    import CustomerModalUpdate from '@/modules/cashier/components/core/customer/pages/CustomerModalUpdate.vue';
    import TaxCertificate from '@/modules/cashier/components/core/customer/CustomerHero/molecules/TaxCertificate.vue';
    import TagsSelector from '@/modules/tag/components/TagsSelector.vue';

    export default defineComponent({
        components: {
            TagsSelector,
            TaxCertificate, CustomerBillingStatus, ActionButton, CustomerModalUpdate,
        },
        props: {
            customer: {type: Object as PropType<CustomerModel | undefined>, default: undefined},
            loading: {type: Boolean, default: false, required: true},
            legacyNursery: {type: Object, required: true},
            legacyUser: {type: Object, required: true},
        },
        setup() {
            const {activeOrganization} = useManager();
            const [isModalVisible, toggleModal] = useToggle(false);

            const activeOrganizationTags = computed(() => activeOrganization.value.tags().value());

            return {
                activeOrganization,
                activeOrganizationTags,
                isModalVisible,
                toggleModal,
            };
        },
    });
</script>

<style lang="scss" scoped>
    .CustomerHeroHeader {
        @apply tw-flex tw-flex-col tw-gap-4;
    }

    .CustomerHeroHeader__actions-container {
        @apply tw-flex tw-flex-row tw-gap-2 tw-items-center tw-justify-between;
    }

    .CustomerHeroHeader__name-container {
        @apply tw-flex tw-flex-row tw-gap-6 tw-flex-wrap tw-items-center tw-justify-between sm:tw-justify-start;
    }
</style>

