<template>
    <MBox
        class="tw-p-4"
        @click="closePopover"
    >
        <div>
            <div
                class="tw-flex tw-flex-wrap tw-items-center tw-justify-between tw-gap-2 tw-cursor-pointer"
                @click="showCard = !showCard"
            >
                <div>
                    <MHeading>
                        <FontAwesomeIcon
                            class="tw-text-primary-500 tw-mr-2"
                            icon="fa fa-money-bill"
                        />
                        {{ __('common:contract_related_fess') }}
                    </MHeading>
                    <div class="tw-text-gray-500 tw-text-sm tw-mt-1">
                        {{ __('billing_setting:type_of_fees') }}
                    </div>
                </div>
                <MButton
                    icon-menu
                    @click.stop="showCard = !showCard"
                >
                    <template v-if="showCard">
                        {{ __('common:actions.close') }}
                    </template>
                    <template v-else>
                        {{ __('common:actions.open') }}
                    </template>
                </MButton>
            </div>
            <div
                v-if="showCard"
                class="mb-3"
            >
                <div class="tw-flex tw-flex-col tw-gap-4 tw-mt-4">
                    <div
                        v-for="(fee, i) in config.fees"
                        :key="'config' + i"
                        class="tw-bg-gray-50 tw-rounded-lg tw-p-4"
                    >
                        <FeesItemMeal
                            v-if="fee.type === 'meals'"
                            :fee="fee"
                            :nursery="nursery"
                            @removeFees="removeFees"
                        />
                        <FeesItemHealth
                            v-else-if="fee.type === 'health'"
                            :fee="fee"
                            :nursery="nursery"
                            @removeFees="removeFees"
                        />
                        <FeesItemOther
                            v-else
                            :fee="fee"
                            :nursery="nursery"
                            @removeFees="removeFees"
                        />
                    </div>
                    <div class="tw-flex sm:tw-flex-row tw-flex-col tw-items-center tw-justify-center tw-gap-4">
                        <MButton
                            v-if="config.funding !== 'psu'"
                            class="sm:tw-w-auto tw-w-full"
                            icon-plus
                            :label="__('billing_setting:fees_meal')"
                            variant="primary"
                            @click="addCustomFees('meals')"
                        />
                        <MButton
                            v-if="config.funding !== 'psu'"
                            class="sm:tw-w-auto tw-w-full"
                            icon-plus
                            :label="__('billing_setting:fees_health')"
                            variant="primary"
                            @click="addCustomFees('health')"
                        />
                        <MButton
                            class="sm:tw-w-auto tw-w-full"
                            icon-plus
                            :label="__('billing_setting:other_fees')"
                            variant="primary"
                            @click="addCustomFees('custom')"
                        />
                    </div>
                </div>

                <hr class="hr">

                <div class="form-row mt-4">
                    <div class="col-12 col-sm-6">
                        <div>{{ __('common:registration_fees') }}</div>
                        <small class="small text-muted">{{ __('billing_setting:registration_fees_explanation') }}</small>
                    </div>
                    <div class="col-12 col-sm-6 col-lg-4 mt-2 mt-sm-0 align-self-center">
                        <CurrencyInput
                            v-model="config.registration.amount"
                            :currency="nursery.currency"
                        />
                    </div>
                </div>

                <div class="form-row mt-4">
                    <div class="col-12 col-sm-6">
                        <div>{{ __('billing_setting:ask_deposit') }}</div>
                        <small class="small text-muted">{{ __('billing_setting:ask_deposit_explanation') }}</small>
                        <div>
                            <CText
                                v-if="config.deposit.amount > 0 || config.deposit.type === 'month'"
                                as="button"
                                font-size="sm"
                                font-weight="semibold"
                                variant="primary"
                                @click.native="showAdvancedConfigForDeposit = !showAdvancedConfigForDeposit"
                            >
                                <i
                                    aria-hidden="true"
                                    class="fa fa-gear tw-mr-1"
                                />{{ __('billing_setting:advanced_config') }}
                            </CText>
                        </div>
                    </div>
                    <div class="col-12 col-sm-6 col-lg-5 mt-2 mt-sm-0 align-self-center">
                        <MButtonSelect
                            v-model="config.deposit.type"
                            :options="depositTypeOptions"
                        />
                    </div>
                </div>
                <div
                    v-if="config.deposit.type === 'fixed'"
                    class="form-row mt-4"
                >
                    <div class="col-12 col-sm-6">
                        <div>{{ __('billing_setting:ask_deposit_amount') }}</div>
                        <small class="small text-muted">{{ __('billing_setting:ask_deposit_amount_explanation') }}</small>
                    </div>
                    <div class="col-12 col-sm-6 col-lg-4 mt-2 mt-sm-0 align-self-center">
                        <CurrencyInput
                            v-model="config.deposit.amount"
                            :currency="nursery.currency"
                        />
                    </div>
                </div>

                <div
                    v-if="config.deposit.type === 'month' && showAdvancedConfigForDeposit"
                    class="form-row mt-4"
                >
                    <div class="col-12 col-sm-6">
                        <div>{{ __('billing_setting:ask_deposit_number_of_month') }}</div>
                        <small class="small text-muted">{{ __('billing_setting:ask_deposit_number_of_month_explanation') }}</small>
                    </div>
                    <div class="col-12 col-sm-6 col-lg-4 mt-2 mt-sm-0 align-self-center">
                        <CInputGroup>
                            <MInput
                                v-model.number="config.deposit.month_coef"
                                v-float
                                type="text"
                            />
                            <CInputAddon>
                                {{ config.deposit.month_coef > 1 ? __('billing_setting:ask_deposit_number_of_month_append_other') : __('billing_setting:ask_deposit_number_of_month_append_one') }}
                            </CInputAddon>
                        </CInputGroup>
                    </div>
                </div>
                <div
                    v-if="(config.deposit.amount > 0 || config.deposit.type === 'month') && showAdvancedConfigForDeposit"
                    class="form-row mt-4"
                >
                    <div class="col-12 col-sm-6">
                        <div>{{ __('billing_setting:auto_bill_deposit') }}</div>
                        <small class="small text-muted">{{ __('billing_setting:auto_bill_deposit_explanation') }}</small>
                    </div>
                    <div class="col-12 col-sm-6 col-lg-4 mt-2 mt-sm-0 align-self-center">
                        <MButtonSelect
                            v-model="config.deposit.auto_charge"
                            :options="booleanOptions"
                            @input="config.deposit.refund = $event"
                        />
                    </div>
                </div>
            </div>
        </div>
    </MBox>
</template>

<script>
    import __ from '@/modules/app/utils/i18n-facade';
    import FeesItemHealth from '@/modules/cashier/components/settings/RulesCards/FeesItemHealth.vue';
    import FeesItemMeal from '@/modules/cashier/components/settings/RulesCards/FeesItemMeal.vue';
    import FeesItemOther from '@/modules/cashier/components/settings/RulesCards/FeesItemOther.vue';
    import CurrencyInput from '@/modules/app/components/atoms/CurrencyInput.vue';

    export default {
        components: {
            CurrencyInput,
            FeesItemOther,
            FeesItemMeal,
            FeesItemHealth,
        },
        props: {
            config: {},
            nursery: {},
        },
        data() {
            return {
                showCard: false,
                showAdvancedConfigForDeposit: false,
                booleanOptions: [{
                    text: __('common:yes'),
                    value: true,
                }, {
                    text: __('common:no'),
                    value: false,
                }],
                cycleOptions: [{
                                   text: __('common:hour_other'),
                                   value: 'hour',
                               },
                               {
                                   text: __('common:halfday_other'),
                                   value: 'halfday',
                               },
                               {
                                   text: __('common:day_other'),
                                   value: 'day',
                               },
                ],
                depositTypeOptions: [{
                    text: __('billing_setting:ask_deposit_per_month'),
                    value: 'month',
                }, {
                    text: __('billing_setting:fixed_amount_deposit'),
                    value: 'fixed',
                }],
                overrunTypeOptions: [{
                    text: __('billing_setting:contract_rate'),
                    value: null,
                }, {
                    text: __('billing_setting:custom'),
                    value: 10,
                }],
            };
        },
        methods: {
            addCustomFees(feeType) {
                switch (feeType) {
                    case 'meals':
                        this.config.fees.push({
                            type: feeType,
                            name: __('billing_setting:fees_meal'),
                            period: 'day',
                            amount: {
                                milk: 4,
                                mix: 2,
                                normal: 1,
                            },
                            hour: '14:00:00',
                            in_deposit: true,
                            smoothing: 'none',
                            invoices: 'all',
                        });
                        break;
                    case 'health':
                        this.config.fees.push({
                            type: feeType,
                            name: __('billing_setting:fees_health'),
                            period: 'day',
                            amount: {
                                baby: 4,
                                mixed: 2,
                                potty: 1,
                            },
                            hour: null,
                            in_deposit: true,
                            smoothing: 'none',
                        });
                        break;
                    default:
                        this.config.fees.push({
                            type: 'custom',
                            name: __('common:additional_fees'),
                            period: 'day',
                            amount: 0,
                            hour: null,
                            in_deposit: true,
                            smoothing: 'none',
                        });
                        break;
                }
            },
            removeFees(fee) {
                this.config.fees = this.config.fees.filter(item => item !== fee);
            },
            closePopover() {
                this.$root.$emit('bv::hide::popover');
            },
        },
    };
</script>
