<template>
    <svg
        viewBox="0 0 512 512"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g
            fill="none"
            fill-rule="nonzero"
        >
            <path
                d="M0 0h512v512H0z"
                :fill="bgClass"
            />
            <path
                v-if="variant === 'boy'"
                d="M310.5449 62c-25.531 0-46.228 20.6838-46.228 46.1985s20.697 46.1985 46.228 46.1985c25.531 0 46.228-20.6838 46.228-46.1985S336.0759 62 310.5449 62zm-52.5181 155.304l-18.2986 82.1408c-13.898-10.549-30.8962-16.4522-48.5454-16.4522h-7.825c-21.3074 0-41.7606 8.4796-56.822 23.5504l-11.7977 11.7601c-4.0255 3.8625-5.647 9.598-4.2393 14.9946 1.4077 5.3967 5.6248 9.611 11.0249 11.018 5.4 1.4068 11.1392-.2138 15.0041-4.2367l11.0755-11.0383-6.5309 91.4646c-.3887 5.492 2.1844 10.7737 6.7498 13.8552 4.5654 3.0815 10.4291 3.4944 15.3817 1.083 4.9526-2.4114 8.2413-7.2805 8.627-12.7726l.0902-1.1129 5.929-41.6568c1.5564-10.9029 17.314-10.9029 18.8704 0l5.8688 41.2357.1204 1.534c.3856 5.492 3.6743 10.3612 8.627 12.7726 4.9525 2.4114 10.8163 1.9986 15.3817-1.083 4.5653-3.0814 7.1385-8.3633 6.7498-13.8552l-6.531-91.4947 10.7746 10.7676c.0791.0912.1594.1814.2407.2707l.0602.0301c.1474.1535.2979.3039.4515.4512 4.132 3.9458 10.1097 5.2824 15.5302 3.4725 5.4206-1.81 9.3938-6.4691 10.3225-12.1047v89.1788c0 8.7777 7.1377 15.9108 15.921 15.9108 8.3826 0 15.3361-6.4962 15.8908-14.8581l5.1766-77.539h18.4792l5.1765 77.539c.5548 8.3465 7.5082 14.8581 15.891 14.8581 8.7832 0 15.9209-7.133 15.9209-15.9108v-92.0361c5.2524 7.6725 9.73 12.0194 13.4325 13.0407 5.5538 1.532 11.0719.3893 13.9973-3.2535a15.3973 15.3973 0 003.0277-12.9754L363.063 217.3041c-3.0201-13.5596-6.2901-41.8975-24.947-41.8975-10.6205 0-48.2585.0161-49.005 0-25.1165 0-25.6237 17.3814-31.0842 41.8975zm-70.7565-11.309c-17.0207 0-30.8187 13.7892-30.8187 30.799 0 17.0099 13.798 30.799 30.8187 30.799 17.0207 0 30.8186-13.7891 30.8186-30.799 0-17.0098-13.798-30.799-30.8186-30.799z"
                :fill="iconClass"
            />
            <path
                v-else
                d="M310.5449 62c-25.531 0-46.228 20.6838-46.228 46.1985s20.697 46.1985 46.228 46.1985c25.531 0 46.228-20.6838 46.228-46.1985S336.0759 62 310.5449 62zm46.258 61.628s-1.1865 21.8534 11.557 34.5888c12.7436 12.7354 34.6109 11.5497 34.6109 11.5497s1.1865-21.8534-11.557-34.5888c-12.7435-12.7354-34.6108-11.5496-34.6108-11.5496zm-46.258 51.568c-25.1165 0-47.0576 17.592-52.5181 42.108l-18.2986 82.1408c-13.898-10.549-30.8962-16.4522-48.5454-16.4522h-7.825c-21.3074 0-41.7606 8.4796-56.822 23.5504l-11.7977 11.7601c-4.0255 3.8625-5.647 9.598-4.2393 14.9946 1.4077 5.3967 5.6248 9.611 11.0249 11.018 5.4 1.4068 11.1392-.2138 15.0041-4.2367l11.0755-11.0383-6.5309 91.4646c-.3887 5.492 2.1844 10.7737 6.7498 13.8552 4.5654 3.0815 10.4291 3.4944 15.3817 1.083 4.9526-2.4114 8.2413-7.2805 8.627-12.7726l.0902-1.1129 5.929-41.6568c1.5564-10.9029 17.314-10.9029 18.8704 0l5.8688 41.2357.1204 1.534c.3856 5.492 3.6743 10.3612 8.627 12.7726 4.9525 2.4114 10.8163 1.9986 15.3817-1.083 4.5653-3.0814 7.1385-8.3633 6.7498-13.8552l-6.531-91.4947 10.7746 10.7676c.0791.0912.1594.1814.2407.2707l.0602.0301c.1474.1535.2979.3039.4515.4512 4.132 3.9458 10.1097 5.2824 15.5302 3.4725 5.4206-1.81 9.3938-6.4691 10.3225-12.1047v89.1788c0 8.7777 7.1377 15.9108 15.921 15.9108 8.3826 0 15.3361-6.4962 15.8908-14.8581l5.1766-77.539h18.4792l5.1765 77.539c.5548 8.3465 7.5082 14.8581 15.891 14.8581 8.7832 0 15.9209-7.133 15.9209-15.9108v-76.4862h15.4093c4.6738-.0004 9.095-2.12 12.0205-5.7627a15.3973 15.3973 0 003.0277-12.9754L363.063 217.3041c-3.0201-13.5596-11.4857-24.045-22.6023-30.8893-7.5427-6.449-17.1095-10.4969-27.6887-11.0082a15.4204 15.4204 0 00-2.2271-.2106zm-123.2746 30.799c-17.0207 0-30.8187 13.7892-30.8187 30.799 0 17.0099 13.798 30.799 30.8187 30.799 17.0207 0 30.8186-13.7891 30.8186-30.799 0-17.0098-13.798-30.799-30.8186-30.799z"
                :fill="iconClass"
            />
        </g>
    </svg>
</template>

<script lang="ts">
    import {computed, defineComponent} from 'vue';

    export default defineComponent({
        components: {},
        props: {
            variant: {type: String, default: 'default'},
        },
        setup(props) {
            const bgClass = computed(() => {
                const output = [] as string[];

                const variants = {
                    default: '#e2e8f0',
                    boy: '#DAEDF5',
                    girl: '#F5DBD8',
                };
                output.push(variants[props.variant]);

                return output;
            });

            const iconClass = computed(() => {
                const output = [] as string[];

                const variants = {
                    default: '#94a3b8',
                    boy: '#61B9DB',
                    girl: '#f1856c',
                };
                output.push(variants[props.variant]);

                return output;
            });

            return {
                bgClass, iconClass,
            };
        },
    });
</script>

