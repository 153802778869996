<template>
    <div v-if="balanceTypes.isNotEmpty()">
        <CButton @click="$refs.organizationsFilter.toggle()">
            <i
                aria-hidden="true"
                class="fad fa-stopwatch tw-text-blue-500 tw-mr-2"
            />
            <template v-if="value.length">
                {{ __('hr_balance:filter.by_type') }}
            </template>
            <template v-else>
                {{ __('hr_balance:filter.all') }}
            </template>
            <i
                aria-hidden="true"
                class="fa fa-caret-down tw-ml-1"
            />
        </CButton>
        <CDropdown
            ref="organizationsFilter"
            legacy-mode
        >
            <CList class="tw-cursor-pointer">
                <CListRow @click="toggleBalanceType()">
                    <i
                        v-if="!value.length"
                        aria-hidden="true"
                        class="fa fa-check tw-mr-1"
                    />
                    {{ __('hr_balance:filter.all') }}
                </CListRow>
                <CListRow
                    v-for="balanceType in balanceTypes.all()"
                    :key="balanceType.attributes.id"
                    @click="toggleBalanceType(balanceType.attributes.id)"
                >
                    <i
                        v-if="value.find(type => type === balanceType.attributes.id)"
                        aria-hidden="true"
                        class="fa fa-check tw-mr-1"
                    />
                    {{ balanceType.attributes.name }}
                </CListRow>
            </CList>
        </CDropdown>
    </div>
</template>

<script lang="ts">
    import type {PropType} from 'vue';
    import {computed, defineComponent, ref, watch} from 'vue';
    import type {Collection} from '@meekohq/lumos';
    import {collect} from '@meekohq/lumos';
    import BalanceTypeModel from '@/modules/human-resources/models/BalanceTypeModel';
    import type OrganizationModel from '@/modules/organization/models/OrganizationModel';

    export default defineComponent({
        name: 'BalanceTypesFilter',
        props: {
            value: {type: Array as PropType<string[]>, required: true},
            organizationsFilter: {type: Array as PropType<OrganizationModel[]>, required: true},
        },
        setup(props, {emit}) {
            const balanceTypes = ref<Collection<BalanceTypeModel>>(collect());
            const organizationIds = computed(() => collect(props.organizationsFilter).pluck('id').all() as string[]);

            const fetchData = async function() {
                balanceTypes.value = await BalanceTypeModel.query()
                    .whereHas('organizations', query1 => {
                        query1.whereIn('id', organizationIds.value);
                    })
                    .get();
            };

            fetchData();

            function toggleBalanceType(balanceType: string | null = null) {
                // If select all => not filter by balance type so it's empty
                if (!balanceType) {
                    emit('input', []);

                    return;
                }

                // If balance type already selected => remove type  else add type
                if (props.value?.find(type => type === balanceType)) {
                    emit('input', props.value?.filter(type => type !== balanceType));
                } else {
                    emit('input', [...props.value, balanceType]);
                }
            }

            watch(() => props.organizationsFilter, () => {
                fetchData().then(() => {
                    balanceTypes.value.pluck('id');
                    emit('input', props.value?.filter(
                        type => balanceTypes.value.pluck('id').toArray().includes(type),
                    ));
                });
            });

            return {
                balanceTypes,
                toggleBalanceType,
            };
        },
    });
</script>
