<template>
    <CPopover
        :clickable="!summaries.isClosed"
        container-class="tw-h-full"
        :hoverable="!summaries.isClosed"
        placement="top"
    >
        <CVStack
            class="tw-h-full"
            distribute="center"
            gap="1"
        >
            <template v-if="!summaries.isClosed">
                <OccupationBar
                    class="tw-flex-1"
                    :class="{'tw-max-h-6': !showSupervision}"
                    :closing="closing"
                    :halfday-display="daySeparator === 'halfday'"
                    :hide-tooltip="true"
                    :occupations="globalOccupations"
                    :opening="opening"
                    :organization="organization"
                />
                <SupervisionBar
                    v-if="showSupervision"
                    class="tw-flex-1"
                    :closing="closing"
                    :halfday-display="daySeparator === 'halfday'"
                    :hide-tooltip="true"
                    :opening="opening"
                    :organization="organization"
                    :supervisions="globalSupervisions"
                />
            </template>
            <template v-else>
                <CText align="center">
                    {{ __('common:closed') }}
                </CText>
            </template>
        </CVStack>
        <template #content>
            <!-- Hourly summaries on hover -->
            <CVStack
                align="center"
                class="tw-h-full tw-w-full tw-p-4"
                distribute="center"
            >
                <CText
                    class="tw-mb-4"
                    font-size="lg"
                    variant="primary"
                >
                    {{ date.format('dddd D MMMM Y') }}
                </CText>
                <div class="tw-w-full tw--ml-5">
                    <SegmentHours
                        :closing="closing"
                        :opening="opening"
                        small
                    />
                </div>
                <OccupationBar
                    class="tw-w-96 tw-h-6"
                    :class="{'tw-mb-1': showSupervision}"
                    :closing="closing"
                    :hide-hours="false"
                    :occupations="summaries.occupations"
                    :opening="opening"
                    :organization="organization"
                />
                <SupervisionBar
                    v-if="showSupervision"
                    class="tw-w-96 tw-h-6"
                    :closing="closing"
                    :opening="opening"
                    :organization="organization"
                    :supervisions="summaries.supervisions"
                />
            </CVStack>
        </template>
    </CPopover>
</template>

<script lang="ts">
    import type {PropType} from 'vue';
    import {computed, defineComponent} from 'vue';
    import type OrganizationModel from '@/modules/organization/models/OrganizationModel';
    import OccupationBar from '@/modules/planning/components/simulation/Summary/OccupationBar.vue';
    import SupervisionBar from '@/modules/planning/components/simulation/Summary/SupervisionBar.vue';
    import SegmentHours from '@/modules/planning/components/simulation/Summary/SegmentHours.vue';
    import useOrganizationOpening from '@/modules/organization/composables/useOrganizationOpening';
    import type {
        OccupationInterface,
        SummaryInterface,
        SupervisionInterface,
    } from '@/modules/planning/components/simulation/Summary/SummaryTypes';
    import _maxBy from 'lodash-es/maxBy';
    import _minBy from 'lodash-es/minBy';
    import moment from 'moment';

    export default defineComponent({
        components: {
            SegmentHours,
            OccupationBar,
            SupervisionBar,
        },
        props: {
            summaries: {
                type: Object as PropType<SummaryInterface>,
                required: true,
            },
            organization: {
                type: Object as PropType<OrganizationModel>,
                required: true,
            },
            showSupervision: {
                type: Boolean,
                default: () => false,
            },
            year: {
                type: Number,
                required: true,
            },
            week: {
                type: Number,
                required: true,
            },
            day: {
                type: Number,
                required: true,
            },
            daySeparator: {
                type: String,
                default: 'day',
            },
            halfdayTime: {
                type: String,
                default: '12:30',
            },
        },
        setup(props) {
            const date = computed(() => moment().year(props.year).isoWeek(props.week).day(props.day));

            // Opening hours for the day in params of active organization
            const organizationOpeningHours = useOrganizationOpening().getOpeningHoursOn(date.value);
            const opening = organizationOpeningHours?.value.startedAt ?? '07:00:00';
            const closing = organizationOpeningHours?.value.endedAt ?? '18:00:00';

            const globalOccupations = computed(() => {
                if (props.daySeparator === 'halfday') {
                    return getHalfdayOccupations();
                } else {
                    return getDailyOccupations();
                }
            });
            const globalSupervisions = computed(() => {
                if (props.daySeparator === 'halfday') {
                    return getHalfdaySupervisions();
                } else {
                    return getDailySupervisions();
                }
            });

            function getDailyOccupations(): OccupationInterface[] {
                return [{
                    count: _maxBy(props.summaries.occupations, 'count')?.count ?? 0,
                    started_at: _minBy(props.summaries.occupations, 'started_at')?.started_at ?? 0,
                    ended_at: _maxBy(props.summaries.occupations, 'ended_at')?.ended_at ?? 0,
                    segmentLeftPercent: 0,
                    segmentRightPercent: 0,
                }];
            }

            function getHalfdayOccupations(): OccupationInterface[] {
                const halfdayDateTime = moment(`${date.value.format('YYYY-MM-DD')} ${props.halfdayTime}`);

                const firstHalf: OccupationInterface[] = [];
                const secondHalf: OccupationInterface[] = [];
                props.summaries.occupations.forEach(occupation => {
                    // Le segment d'occupation commence à partir de l'heure de milieu, on l'ajoute que le soir
                    if (occupation.started_at >= halfdayDateTime.unix()) {
                        secondHalf.push(occupation);
                        // Si le segment d'occupation englobe l'heure de milieu de journée, on l'ajoute matin et soir
                    } else if (halfdayDateTime.isBetween(moment.unix(occupation.started_at), moment.unix(occupation.ended_at), 'minutes', '[]')) {
                        firstHalf.push(occupation);
                        secondHalf.push(occupation);
                    } else {
                        firstHalf.push(occupation);
                    }
                });

                return [{
                    count: _maxBy(firstHalf, 'count')?.count ?? 0,
                    started_at: _minBy(firstHalf, 'started_at')?.started_at ?? 0,
                    ended_at: _maxBy(firstHalf, 'ended_at')?.ended_at ?? 0,
                    segmentLeftPercent: 0,
                    segmentRightPercent: 50,
                }, {
                    count: _maxBy(secondHalf, 'count')?.count ?? 0,
                    started_at: _minBy(secondHalf, 'started_at')?.started_at ?? 0,
                    ended_at: _maxBy(secondHalf, 'ended_at')?.ended_at ?? 0,
                    segmentLeftPercent: 50,
                    segmentRightPercent: 0,
                }];
            }

            function getDailySupervisions(): SupervisionInterface[] {
                // Get occuptations range for the day to have same segment size
                return [{
                    count: _minBy(props.summaries.supervisions, 'count')?.count ?? 0,
                    started_at: _minBy(props.summaries.occupations, 'started_at')?.started_at ?? 0,
                    ended_at: _maxBy(props.summaries.occupations, 'ended_at')?.ended_at ?? 0,
                    anomalies: [],
                    segmentLeftPercent: 0,
                    segmentRightPercent: 0,
                }];
            }

            function getHalfdaySupervisions(): SupervisionInterface[] {
                const halfdayDateTime = moment(`${date.value.format('YYYY-MM-DD')} ${props.halfdayTime}`);

                const firstHalf: SupervisionInterface[] = [];
                const secondHalf: SupervisionInterface[] = [];
                props.summaries.supervisions.forEach(supervision => {
                    // Si le segment d'occupation englobe l'heure de milieu de journée, on l'ajoute matin et soir
                    if (halfdayDateTime.isBetween(moment.unix(supervision.started_at), moment.unix(supervision.ended_at), 'minutes', '[]')) {
                        firstHalf.push(supervision);
                        secondHalf.push(supervision);
                        // Le segment d'occupation commence après l'heure de milieu, on l'ajoute que le soir
                    } else if (supervision.started_at > halfdayDateTime.unix()) {
                        secondHalf.push(supervision);
                    } else {
                        firstHalf.push(supervision);
                    }
                });

                return [{
                    count: _minBy(firstHalf, 'count')?.count ?? 0,
                    started_at: _minBy(firstHalf, 'started_at')?.started_at ?? 0,
                    ended_at: _maxBy(firstHalf, 'ended_at')?.ended_at ?? 0,
                    anomalies: [],
                    segmentLeftPercent: 0,
                    segmentRightPercent: 50,
                }, {
                    count: _minBy(secondHalf, 'count')?.count ?? 0,
                    started_at: _minBy(secondHalf, 'started_at')?.started_at ?? 0,
                    ended_at: _maxBy(secondHalf, 'ended_at')?.ended_at ?? 0,
                    anomalies: [],
                    segmentLeftPercent: 50,
                    segmentRightPercent: 0,
                }];
            }

            return {
                closing,
                date,
                opening,
                globalOccupations,
                globalSupervisions,
            };
        },
    });
</script>
