<template>
    <div :class="baseClass">
        <slot>
            <i
                aria-hidden="true"
                class="fas fa-archive"
            />
        </slot>
    </div>
</template>

<script lang="ts">
    import {computed, defineComponent} from 'vue';

    export default defineComponent({
        components: {},
        props: {
            variant: {type: String, default: 'default', required: false},
            rounded: {type: Boolean, default: false, required: false},
            size: {type: String, default: 'default', required: false},
            zoom: {type: Boolean, default: false, required: false},
        },
        setup(props) {
            const baseClass = computed(() => {
                const output = [] as string[];

                output.push('tw-inline-block tw-font-semibold tw-leading-normal');

                if (props.rounded) {
                    output.push('tw-rounded-full');
                } else {
                    output.push('tw-rounded');
                }

                const zoomValues = {
                    1: 'CBadge-zoom',
                    0: '',
                };
                output.push(zoomValues[Number(props.zoom)]);

                const sizes = {
                    default: 'tw-px-1 tw-text-base',
                    lg: 'tw-px-2 tw-text-lg',
                    sm: 'tw-px-1 tw-text-sm',
                };
                output.push(sizes[props.size]);

                const variants = {
                    'gray': 'tw-bg-gray-200 tw-text-gray-700',
                    'light': 'tw-bg-gray-200 tw-text-gray-600',
                    'cyan': 'tw-bg-cyan-200 tw-text-cyan-950',
                    'blue': 'tw-bg-blue-200 tw-text-blue-800',
                    'purple': 'tw-bg-purple-200 tw-text-purple-800',
                    'pink': 'tw-bg-pink-200 tw-text-pink-800',
                    'red': 'tw-bg-red-200 tw-text-red-800',
                    'orange': 'tw-bg-orange-200 tw-text-orange-800',
                    'yellow': 'tw-bg-yellow-300 tw-text-yellow-900',
                    'green': 'tw-bg-green-300 tw-text-green-800',
                    'orange-tooltip': 'tw-text-orange-500',
                };

                variants['primary'] = variants.blue;
                variants['success'] = variants.green;
                variants['danger'] = variants.red;
                variants['warning'] = variants.orange;
                variants['default'] = variants.gray;

                output.push(variants[props.variant]);

                return output;
            });

            return {
                baseClass,
            };
        },
    });
</script>

<style
    lang="scss"
    scoped
>
    .CBadge-zoom {
        @apply tw-scale-100 tw-ease-in-out;
        @apply hover:tw-scale-110;
    }

</style>
