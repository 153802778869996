import type ContractTrialPeriodModel from '@/modules/human-resources/models/ContractTrialPeriodModel';
import type ContractModel from '@/modules/human-resources/models/ContractModel';
import {Epoch} from '@meekohq/lumos';
import {BaseRule} from '@/modules/app/utils/rules/BaseRule';

export class TrialPeriodEndedBetweenContractDatesRule extends BaseRule {
    isValid(trialPeriod: ContractTrialPeriodModel, contract: ContractModel): boolean {
        if (!trialPeriod.attributes.ended_at || !contract.attributes.started_at) {
            return true;
        }

        const contractStartedAt = Epoch.parse(contract.attributes.started_at, 'yyyy-MM-dd').startOfDay();
        const contractEndedAt = contract.attributes.ended_at ? Epoch.parse(contract.attributes.ended_at, 'yyyy-MM-dd').startOfDay() : null;

        const trialPeriodEndedAt = Epoch.parse(trialPeriod.attributes.ended_at, 'yyyy-MM-dd').startOfDay();

        if (!contractEndedAt) {
            return trialPeriodEndedAt.greaterThanOrEqualTo(contractStartedAt);
        }

        return trialPeriodEndedAt.greaterThanOrEqualTo(contractStartedAt)
            && trialPeriodEndedAt.lessThanOrEqualTo(contractEndedAt);
    }
}
