import {computed, inject, ref} from 'vue';
import type {Epoch} from '@meekohq/lumos';
import injectableKey from '@/modules/app/utils/injectableKey';
import {keyMCalendarState} from '@/modules/meeko-ui/components/MCalendar/useMCalendarState';
import {keyMCalendarNavigation} from '@/modules/meeko-ui/components/MCalendar/useMCalendarNavigation';
import type {MCalendarSelectionItem} from '@/modules/meeko-ui/components/MCalendar/useMCalendarSelection';
import useMCalendarSelection from '@/modules/meeko-ui/components/MCalendar/useMCalendarSelection';

export const keyMCalendarDays = injectableKey<ReturnType<typeof useMCalendarDays>>();

export type MCalendarDaysItem = MCalendarSelectionItem & {
    day: Epoch;
    // The day is today
    isToday: boolean;
    // The day is out of the current month
    isOutOfMonth: boolean;
};

export default function useMCalendarDays() {
    const state = inject(keyMCalendarState)!;
    if (!state) {
        throw new Error('keyMCalendarState must be provided');
    }

    const navigation = inject(keyMCalendarNavigation)!;
    if (!navigation) {
        throw new Error('keyMCalendarNavigation must be provided');
    }

    const hoveredDay = ref<Epoch>();

    const {
        isDatePeriodStart,
        isDatePeriodEnd,
        isDateSelected,
        isDateSelecting,
        isDateInPeriod,
        isDateHovered,
        handleDateClick,
    } = useMCalendarSelection(hoveredDay, 'day', state.modifiers);

    const daysInActiveMonth = computed<MCalendarDaysItem[]>(() => {
        const days: MCalendarDaysItem[] = [];
        const daysToDisplay = 6 * 7;

        const startOfCalendarMonth = navigation.activeMonth.value.startOfMonth().startOfWeek();
        const endOfCalendarMonth = navigation.activeMonth.value.endOfMonth().endOfWeek();

        // Add before days of month to fill first week
        let dayIterator = startOfCalendarMonth;
        while (dayIterator.between(startOfCalendarMonth, endOfCalendarMonth) || days.length < daysToDisplay) {
            days.push({
                day: dayIterator,
                isToday: dayIterator.isToday,
                isOutOfMonth: dayIterator.month !== navigation.activeMonth.value.month,
                isSelected: isDateSelected(dayIterator),
                isSelecting: isDateSelecting(dayIterator),
                isPeriodStart: isDatePeriodStart(dayIterator),
                isPeriodEnd: isDatePeriodEnd(dayIterator),
                isInPeriod: isDateInPeriod(dayIterator),
                isHovered: isDateHovered(dayIterator),
            });
            dayIterator = dayIterator.addDays();
        }

        return days;
    });

    const weekDays = computed(() => {
        return daysInActiveMonth.value
            .slice(0, 7)
            .map(day => day.day.toLocaleString({weekday: 'short'}).slice(0, 3));
    });

    function handleDayClick(day: Epoch) {
        handleDateClick(day);
    }

    return {
        hoveredDay,
        daysInActiveMonth,
        weekDays,
        handleDayClick,
    };
}
