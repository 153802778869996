<template>
    <CForm
        class="LoginForm"
        @submit.prevent="login"
    >
        <transition name="fade">
            <div
                v-if="hasError"
                class="LoginForm__error"
            >
                <ul>
                    <template v-for="error in errors">
                        <li :key="error.value">
                            {{ _head(error) }}
                        </li>
                    </template>
                </ul>
            </div>
        </transition>
        <CFormGroup class="LoginForm__input-container--is-bordered">
            <FontAwesomeIcon
                class="LoginForm__input-icon"
                icon="far fa-user"
            />
            <input
                v-model="email"
                autofocus
                class="LoginForm__input"
                :placeholder="__('common:email_address')"
                required
                type="email"
            >
        </CFormGroup>
        <CFormGroup class="LoginForm__input-container">
            <FontAwesomeIcon
                class="LoginForm__input-icon"
                icon="far fa-lock"
            />
            <input
                v-model="password"
                class="LoginForm__input"
                :placeholder="__('common:password')"
                required
                type="password"
            >
        </CFormGroup>
        <CFormGroup>
            <AuthButton
                :is-loading="isLoading"
                :label="__('auth:to_log_in')"
                @click.native="login"
            />
        </CFormGroup>
    </CForm>
</template>

<script setup lang="ts">
    import _head from 'lodash-es/head';
    import AuthButton from '@/modules/auth/components/atoms/AuthButton.vue';
    import {useRoute, useRouter} from 'vue-router/composables';
    import {computed, ref} from 'vue';
    import {app, catcher} from '@meekohq/lumos';
    import type {AuthenticationContract} from '@/modules/auth/utils/AuthenticationContract';
    import {AxiosError} from 'axios';
    import {Types} from '@/types';

    const isLoading = ref(false);
    const email = ref('');
    const password = ref('');
    const errors = ref({});
    const hasError = computed(() => {
        return Object.keys(errors.value).length > 0;
    });
    const router = useRouter();
    const route = useRoute();

    async function login() {
        isLoading.value = true;

        const auth = app<AuthenticationContract>(Types.Auth);

        try {
            await auth.loginByEmail(email.value, password.value);
            if (route.query.redirect) {
                await router.push({path: route.query.redirect as string});
            } else {
                await router.push({name: 'dashboard'});
            }
        } catch (e) {
            catcher().on(AxiosError, (error: any) => {
                errors.value = error.response.data.errors;
            }).catch(e);
        }

        isLoading.value = false;
    }
</script>

<style lang="scss" scoped>
    .LoginForm {
        @apply tw-bg-white tw-rounded-xl tw-overflow-hidden tw-shadow-lg tw-gap-0;
    }

    .LoginForm__error {
        @apply tw-bg-red-100 tw-rounded-lg tw-shadow-sm;
        @apply tw-py-2 tw-px-4 tw-m-4;
        @apply tw-text-left tw-text-red-500 tw-font-semibold;

    }

    .LoginForm__input-container {
        @apply tw-flex tw-items-center tw-justify-center;
        @apply tw-px-4;
    }

    .LoginForm__input-container--is-bordered {
        @apply tw-flex tw-items-center tw-justify-center;
        @apply tw-px-4;
        @apply tw-border-b-2 tw-border-dashed tw-border-primary-200;
    }

    .LoginForm__input-icon {
        @apply tw-text-primary-500 tw-text-2xl tw-px-2;
    }

    .LoginForm__input {
        @apply tw-w-full tw-h-16;
        @apply tw-outline-none tw-tracking-tight;
        @apply tw-px-4;
        @apply tw-text-primary-500 tw-text-2xl tw-antialiased;

        &::placeholder {
            @apply tw-font-grandhotel tw-text-4xl;
            @apply tw-text-primary-300;
        }
    }
</style>
