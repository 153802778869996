import type PaymentModel from '@/modules/cashier/models/PaymentModel';
import type {CreditNoteAllocationAggregate} from '@/modules/cashier/payment/domain/CreditNoteAllocationAggregate';
import type {
    TransactionCreditAllocationAggregate,
} from '@/modules/cashier/payment/domain/TransactionCreditAllocationAggregate';
import type {
    InvoiceAllocationAggregatePort,
} from '@/modules/cashier/payment/application/ports/InvoiceAllocationAggregatePort';
import type {GetAllocationsForInvoiceUseCase} from '@/modules/cashier/payment/application/GetAllocationsForInvoiceUseCase';

export class GetAllocationsForInvoiceInteractor implements GetAllocationsForInvoiceUseCase
{
    private readonly _invoiceAllocationsAggregateRepository: InvoiceAllocationAggregatePort;

    constructor(
        invoiceAllocationsAggregateRepository: InvoiceAllocationAggregatePort,
    ) {
        this._invoiceAllocationsAggregateRepository = invoiceAllocationsAggregateRepository;
    }

    public async fetch(payment: PaymentModel): Promise<Array<CreditNoteAllocationAggregate|TransactionCreditAllocationAggregate>> {
        return this._invoiceAllocationsAggregateRepository.getAllocations(payment);
    }
}
