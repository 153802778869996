<template>
    <div>
        <SelectResource
            ref="SelectResource"
            :builder="builder"
            :button-class="buttonClass"
            class="tw-block"
            :dropdown-class="dropdownClass"
            :dropdown-position="dropdownPosition"
            :has-unselect="true"
            :model="FamilyModel"
            :value="value"
            @fallback="showCreateModal = true"
            @input="onModelSelected"
            @search="onSearchValue"
        >
            <template #button>
                <template v-if="value">
                    {{ value.attributes.name }}
                </template>
                <template v-else>
                    {{ __('family_family:find_or_add_family') }}
                </template>
            </template>

            <template
                v-if="createOptionEnabled"
                #fallback="{searchValue}"
            >
                <template v-if="searchValue">
                    <i
                        aria-hidden="true"
                        class="fas fa-plus fa-fw tw--ml-1 tw-mr-1"
                    />{{ __('family_family:add_the_family_with_name', {name: searchValue}) }}
                </template>
                <template v-else>
                    <i
                        aria-hidden="true"
                        class="fas fa-plus fa-fw tw--ml-1 tw-mr-1"
                    />{{ __('family_family:add_family') }}
                </template>
            </template>

            <template
                v-if="value"
                #unselect-item
            >
                <i
                    aria-hidden="true"
                    class="fad fa-unlink fa-fw tw--ml-1 tw-mr-1"
                />
                {{ __('family_family:unselect_with_name', {name: value.attributes.name}) }}
            </template>

            <template #default="{option, searchValue}">
                <div class="tw-flex tw-pr-4">
                    <div
                        class="tw-truncate"
                        v-html="highlight(searchValue, option.attributes.name)"
                    />
                </div>
            </template>
        </SelectResource>
        <CreateFamilyModal
            v-if="showCreateModal"
            :name="searchValue"
            :organization-model="organizationModel"
            @cancel="showCreateModal = false"
            @close="showCreateModal = false"
            @created="onModelCreated($event)"
        />
    </div>
</template>

<script lang="ts">
    import Loader from '@/modules/legacy/helpers/loader.helper';
    import FamilyModel from '@/modules/family/models/FamilyModel';
    import TextHighlighter from '@/modules/legacy/helpers/text-highlighter.helper';
    import CreateFamilyModal from '@/modules/family/components/family/CreateFamilyModal.vue';
    import type OrganizationModel from '@/modules/organization/models/OrganizationModel';
    import SelectResource from '@/modules/app/components/SelectResource.vue';
    import {QueryBuilder} from '@meekohq/lumos';
    import type {PropType} from 'vue';
    import {computed, defineComponent, ref} from 'vue';
    import useHighlighter from '@/modules/legacy/helpers/useHighlighter';

    export default defineComponent({
        components: {
            SelectResource,
            CreateFamilyModal,
        },
        props: {
            organizationModel: {
                type: Object as PropType<OrganizationModel>,
                required: true,
            },
            extendBuilder: {
                type: Object as PropType<QueryBuilder<FamilyModel>>,
                default: () => new QueryBuilder(),
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            createOptionEnabled: {
                type: Boolean,
                default: true,
            },
            dropdownPosition: {
                type: String,
                default: undefined,
            },
            dropdownClass: {
                type: String,
                default: undefined,
            },
            buttonClass: {
                type: String,
                default: undefined,
            },
            value: {
                type: Object as PropType<FamilyModel>,
                default: null,
            },
        },
        setup(props, {emit}) {
            const loader = ref(new Loader());
            const searchValue = ref('');

            const showCreateModal = ref(false);

            const builder = computed(() => FamilyModel.query()
                .whereLike('name', `*${searchValue.value}*`)
                .whereHas(new FamilyModel().organizations(), query => query.where('id', props.organizationModel.id as string))
                .orderBy('name')
                .inject(props.extendBuilder));

            const onSearchValue = event => {
                searchValue.value = event;
            };

            const onModelCreated = (model: FamilyModel) => {
                showCreateModal.value = false;

                onModelSelected(model);
            };

            const onModelSelected = (model: FamilyModel | undefined) => {
                emit('input', model);

                return model;
            };

            return {
                FamilyModel,
                loader,
                TextHighlighter,
                searchValue,
                builder,
                onSearchValue,
                onModelCreated,
                onModelSelected,
                showCreateModal,
                ...useHighlighter(),
            };
        },
    });
</script>
