<template>
    <div
        class="card-chart"
        :class="{'settings': showSettings}"
    >
        <div class="chart-title">
            <div class="d-flex flex-row ml-1 mb-3">
                <div class="d-flex align-items-center tw-text-base tw-font-semibold text-uppercase">
                    <i
                        aria-hidden="true"
                        class="fas fa-child-reaching mr-2"
                    />{{ __('common:kid_other') }}
                </div>
                <div
                    class="d-flex align-items-center flex-row ml-auto tw-text-sm tw-font-semibold text-uppercase pointer"
                    @click="showSettings = !showSettings"
                >
                    {{ humanizedSelectedDate }}
                    <i
                        aria-hidden="true"
                        class="fas fa-pencil ml-2"
                    />
                </div>
            </div>
            <div class="actions">
                <div class="tw-grid tw-grid-cols-2 tw-gap-2">
                    <MMenu class="tw-w-full">
                        <MMenuButton class="tw-w-full">
                            <MButton
                                class="tw-w-full"
                                icon-menu
                                :label="selectedGroup.name"
                            />
                        </MMenuButton>
                        <MMenuItems>
                            <MMenuItem @click="selectedGroup = {id: null, name: __('common:all_groups')}">
                                {{ __('common:all_groups') }}
                            </MMenuItem>
                            <MMenuDivider/>
                            <MMenuItem
                                v-for="(group, i) in selectedNursery.groups"
                                :key="'nursery' + i"
                                @click="selectedGroup = group"
                            >
                                {{ group.name }}
                            </MMenuItem>
                        </MMenuItems>
                    </MMenu>
                    <MDatePicker
                        ref="datePickerRef"
                        v-model="selectedDate"
                        class="tw-w-full"
                        format="yyyy-MM-dd"
                    />
                </div>
            </div>
        </div>
        <div class="chart-content">
            <div class="chart-container px-1">
                <loader
                    v-if="loading"
                    light="true"
                    shadow="false"
                    size="sm"
                />
                <div
                    v-else-if="filteredKids.length"
                    class="KidPresences__list"
                >
                    <div
                        v-for="(kid, index) in filteredKids"
                        :key="'kid' + index"
                        class="KidPresences__item"
                    >
                        <div class="KidPresences__header">
                            <LegacyKidAvatar
                                class="KidPresences__avatar"
                                :kid="kid"
                            />
                            <div
                                class="KidPresences__name"
                                :class="kid.gender === 'male' ? 'tw-text-boy' : 'tw-text-girl'"
                            >
                                <router-link
                                    class="tw-text-inherit"
                                    :to="{name:'kids.show',params:{nursery:nursery.id, kid:kid.id}}"
                                >
                                    {{ $fullName(kid) }}
                                </router-link>
                            </div>
                        </div>
                        <div class="KidPresences__container">
                            <div class="KidPresences__sub-container">
                                <MTooltip
                                    v-for="(occasional, i) in kid.occasionals"
                                    :key="'occasional' + i"
                                    class="tw-shrink-0"
                                    :label="occasional.note ? occasional.note : __('common:occasional')"
                                >
                                    <CBadge variant="primary">
                                        {{ $formatDate(occasional.started_at, 'HH:mm') }} - {{ $formatDate(occasional.ended_at, 'HH:mm') }}
                                    </CBadge>
                                </MTooltip>
                                <MTooltip
                                    v-for="(adaptation, i) in kid.adaptations"
                                    :key="'adaptation' + i"
                                    class="tw-shrink-0"
                                    :label="adaptation.note ? adaptation.note : __('common:adaptation')"
                                >
                                    <CBadge variant="warning">
                                        {{ $formatDate(adaptation.started_at, 'HH:mm') }} - {{ $formatDate(adaptation.ended_at, 'HH:mm') }}
                                    </CBadge>
                                </MTooltip>
                                <MTooltip
                                    v-for="(absence, i) in kid.absences"
                                    :key="'absence' + i"
                                    class="tw-shrink-0"
                                    :label="absence.note ? absence.note : __('common:absence')"
                                >
                                    <CBadge variant="danger">
                                        {{ $formatDate(absence.started_at, 'HH:mm') }} - {{ $formatDate(absence.ended_at, 'HH:mm') }}
                                    </CBadge>
                                </MTooltip>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    v-else
                    class="tw-p-2 tw-text-center"
                >
                    {{ __('dashboard:kids_no_warning') }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';
    import moment from 'moment';
    import _head from 'lodash-es/head';
    import _forEach from 'lodash-es/forEach';
    import {Epoch} from '@meekohq/lumos';
    import __ from '@/modules/app/utils/i18n-facade';
    import useApi from '@/modules/app/composables/useApi';
    import route from '@/modules/legacy/libs/ziggy';
    import {getPlanningEventsFromKidContracts} from '@/modules/legacy/libs/Planning.ts';
    import * as ProcessedData from '@/modules/legacy/libs/ProcessedData';
    import useBroadcast from '@/modules/app/composables/useBroadcast';
    import LegacyKidAvatar from '@/modules/app/components/atoms/avatars/LegacyKidAvatar.vue';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';
    import {isNil} from 'lodash-es';

    export default {
        components: {LegacyKidAvatar},
        props: {
            nursery: {
                type: Object,
                required: true,
            },
        },
        data: () => ({
            kids: [],
            date: Epoch.now().toFormat('yyyy-MM-dd'),
            selectedDate: Epoch.now().toFormat('yyyy-MM-dd'),
            selectedNursery: {},
            selectedGroup: {},
            loading: false,
            showSettings: false,
        }),
        computed: {
            epochSelectedDate() {
                return this.selectedDate ? Epoch.parse(this.selectedDate, 'yyyy-MM-dd') : Epoch.now();
            },
            humanizedSelectedDate() {
                return this.epochSelectedDate.toLocaleString(Epoch.presets.WEEKDAY_WITH_DAY_AND_MONTH);
            },
            from() {
                return this.epochSelectedDate.startOfDay();
            },
            to() {
                return this.epochSelectedDate.endOfDay();
            },
            filteredKids() {
                return this.kids.filter(item => item.hasAnomalies);
            },
        },
        watch: {
            selectedDate(val) {
                if (isNil(val)) {
                    return;
                }
                this.showSettings = false;
                this.$refs.datePickerRef.hide();
                this.date = val;
                this.getPresences();
            },
            'selectedNursery.id': {
                handler() {
                    if (this.selectedNursery && this.selectedNursery.id) {
                        this.getPresences();
                    }
                },
                deep: true,
            },
            'nursery.id': {
                handler() {
                    this.selectedNursery = this.nursery;
                },
                deep: true,
            },
            'selectedGroup.id': {
                handler() {
                    this.getPresences();
                },
            },
        },
        mounted() {
            useBroadcast().sessionChannel.bind('kid_plannings', response => {
                ProcessedData.retrieve(response.processedDataUrl, response => {
                    this.kids = response.data;

                    this.kids.forEach(kid => {
                        Vue.set(kid, 'adaptations', []);
                        Vue.set(kid, 'occasionals', []);
                        const plannings = getPlanningEventsFromKidContracts(kid.contracts, moment(this.from.toJSDate()));
                        plannings.forEach(item => {
                            if (item.type === 'planning-adaptation') {
                                kid.adaptations.push(item);
                            } else if (item.type === 'planning-occasional') {
                                kid.occasionals.push(item);
                            }
                        });
                        const hasAnomalies = (kid.occasionals.length > 0) || (kid.adaptations.length > 0) || (kid.absences.length > 0);
                        Vue.set(kid, 'hasAnomalies', hasAnomalies);
                    });
                    this.loading = false;
                });
            });

            this.selectedNursery = this.nursery;
            this.switchGroup();
        },
        beforeDestroy() {
            useBroadcast().sessionChannel.unbind('kid_plannings');
        },
        methods: {
            getPresences() {
                if (this.loading) {
                    return;
                }

                this.loading = true;

                useApi().legacy.get(route('nurseries.planning.kids', {nurseries: this.nursery.id}), {
                    params: {
                        from: this.from.toTimestamp(),
                        to: this.to.toTimestamp(),
                        group_id: this.selectedGroup.id ? this.selectedGroup.id : null,
                    },
                }).catch(error => {
                    this.loading = false;
                    if (error && error.response && error.response.status === 422) {
                        _forEach(error.response.data.errors, value => {
                            useNotification().error(_head(value));
                        });
                    } else {
                        useNotification().error(error);
                    }
                });
            },
            switchGroup(group) {
                if (!group) {
                    this.selectedGroup = {
                        id: null,
                        name: __('common:all_groups'),
                    };
                } else {
                    this.selectedGroup = group;
                }

                if (this.selectedNursery && this.selectedNursery.id) {
                    this.getPresences();
                }
            },
        },
    };
</script>

<style lang="scss" scoped>
    .KidPresences__list {
        @apply tw-flex tw-flex-col tw-gap-4 tw-my-4;
    }

    .KidPresences__item {
        @apply tw-grid tw-grid-cols-2 tw-gap-4;
    }

    .KidPresences__header {
        @apply tw-flex tw-items-center tw-gap-2;
    }

    .KidPresences__avatar {
        @apply tw-w-8 tw-aspect-square tw-shrink-0;
    }

    .KidPresences__name {
        @apply tw-truncate tw-font-grandhotel tw-text-2xl tw-capitalize;
    }

    .KidPresences__container {
        @apply tw-flex tw-items-center;
    }

    .KidPresences__sub-container {
        @apply tw-ml-auto tw-w-fit tw-flex tw-items-center tw-gap-2 tw-overflow-x-auto;
    }
</style>
