<template>
    <CPopover
        ref="dropdown"
        class="tw-block"
        :class="[dropdownClasses]"
        :clickable="!disabled"
        @click="setIsEditing(true)"
        @shown="onShown"
    >
        <CInput
            v-if="isEditing"
            v-model="textDate"
            :disabled="disabled"
            :has-error="hasError"
            :placeholder="time ? 'jj/mm/aaaa --:--' : 'jj/mm/aaaa'"
            :readonly="disabled"
            type="input"
            @change="parseOnBlur"
            @keydown.enter.prevent="dropdown.show()"
            @keydown.space.prevent="preventSpace"
            @keydown.tab="dropdown.hide()"
        />
        <CHStack
            v-else
            align="center"
            class="CInput CInput--light"
            gap="2"
        >
            <CText :variant="value && !disabled ? '' : 'disabled'">
                {{ value ? date.format('DD/MM/YYYY') : 'jj/mm/aaaa' }}
            </CText>
            <CBadge
                v-if="time"
                class="tw-leading-normal"
                size="sm"
                :variant="value && !disabled ? 'blue' : 'gray'"
            >
                {{ value ? date.format('HH:mm') : '--:--' }}
            </CBadge>
            <FontAwesomeIcon
                v-if="showClearValueButton && value"
                class="CFormDatePicker__reset-icon"
                icon="fa fa-xmark"
                @click.native.stop="$emit('input',emptyValueIsUndefined ? undefined : null)"
            />
        </CHStack>
        <template #content>
            <div class="tw-flex tw-justify-between">
                <div class="tw-flex tw-items-center">
                    <div class="tw-flex tw-text-lg tw-text-gray-500">
                        <div
                            class="tw-text-base tw-px-1 hover:tw-text-blue-500 tw-cursor-pointer"
                            @click="previous"
                        >
                            <FontAwesomeIcon icon="fas fa-chevron-left"/>
                        </div>
                        <div
                            class="tw-text-base tw-px-1 hover:tw-text-blue-500 tw-cursor-pointer"
                            @click="next"
                        >
                            <FontAwesomeIcon icon="fas fa-chevron-right"/>
                        </div>
                    </div>
                    <div
                        v-if="view === 'month'"
                        class="hover:tw-bg-blue-200 hover:tw-text-blue-600 tw-rounded tw-cursor-pointer tw-px-1"
                        @click="view = 'year'"
                    >
                        <span class="tw-text-xl tw-text-black tw-font-semibold tw-mr-1 tw-capitalize">{{ currentMonthName }}</span>
                        <span class="tw-text-xl tw-text-gray-700 tw-ml-1">{{ date.format('Y') }}</span>
                    </div>
                    <div
                        v-else
                        class="tw-px-1 tw-text-xl tw-text-black tw-font-semibold"
                    >
                        {{ date.format('Y') }}
                    </div>
                </div>
                <CButton
                    size="sm"
                    variant="link"
                    @click="setNow()"
                >
                    Aujourd'hui
                </CButton>
            </div>
            <div
                v-if="view === 'year'"
                class="tw-mt-2 tw-grid tw-grid-flow-row tw-grid-cols-3 tw-grid-rows-3 tw-gap-1"
            >
                <div
                    v-for="(month, index) in monthsInYear"
                    :class="getMonthClasses(index)"
                    @click="selectDate(date.clone().month(index), () => view = 'month')"
                >
                    <span class="tw-text-base tw-capitalize">{{ month }}</span>
                </div>
            </div>

            <div
                v-if="view === 'month'"
                class="tw-mt-2"
            >
                <div
                    class="tw-flex"
                    style="height: 11rem;"
                >
                    <div>
                        <div class="tw-flex tw-items-end tw-h-full">
                            <div>
                                <div class="tw-grid tw-grid-flow-row tw-grid-rows-7 tw-gap-1 tw-mr-2 tw-w-4">
                                    <div
                                        class="tw-h-5"
                                        :style="{'margin-bottom': '3px'}"
                                    />
                                    <div
                                        v-for="number in weekNumbers"
                                        class="tw-text-sm tw-text-gray-500 tw-h-5 tw-leading-4 tw-text-right"
                                    >
                                        {{ number }}
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div class="tw-grid tw-grid-flow-row tw-grid-cols-7 tw-gap-1">
                                    <div
                                        v-for="day in weekDays"
                                        class="tw-h-5 tw-text-center tw-text-sm tw-capitalize tw-font-semibold"
                                    >
                                        {{ day }}
                                    </div>
                                </div>
                                <div class="tw-mt-2 tw-grid tw-grid-flow-row tw-grid-cols-7 tw-gap-1">
                                    <div
                                        v-for="day in daysInMonths"
                                        :class="getDayClasses(day)"
                                        @click="selectDate(day, () => hide())"
                                    >
                                        <span class="tw-text-base">{{ day.format('D') }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        v-if="time"
                        class="tw-ml-4 tw-flex tw-space-x-1"
                    >
                        <CList
                            class="tw-h-full tw-overflow-auto"
                            :striped="true"
                        >
                            <CListRow
                                v-for="(hour) in hours"
                                :key="'h' + hour.hour()"
                                :ref="(el) => setHourRef(el, 'h' + hour.hour())"
                                :active="date.hour() === hour.hour()"
                                size="sm"
                                @click="selectDate(hour)"
                            >
                                <div class="tw-text-center tw-px-2">
                                    {{ hour.format('HH') }}
                                </div>
                            </CListRow>
                        </CList>
                        <CList
                            class="tw-h-full tw-overflow-auto"
                            :striped="true"
                        >
                            <CListRow
                                v-for="(minute) in minutes"
                                :key="'m' + minute.minute()"
                                :ref="(el) => setMinuteRef(el, 'm' + minute.minute())"
                                :active="date.minute() === minute.minute()"
                                size="sm"
                                @click="selectDate(minute)"
                            >
                                <div class="tw-text-center tw-px-2">
                                    {{ minute.format('mm') }}
                                </div>
                            </CListRow>
                        </CList>
                    </div>
                </div>
            </div>
        </template>
    </CPopover>
</template>

<script lang="ts">
    import {defineComponent, nextTick, onBeforeUpdate, ref, toRef, toRefs, watch} from 'vue';
    import useDatePickerProvider from '@/modules/coherence-ui/hooks/useDatePickerProvider';
    import useDatePickerState from '@/modules/coherence-ui/hooks/useDatePickerState';
    import useDatePickerStyle from '@/modules/coherence-ui/hooks/useDatePickerStyle';
    import Scroller from '@/modules/legacy/helpers/scroller.helper';
    import {onClickOutside} from '@vueuse/core';

    export default defineComponent({
        props: {
            value: {
                type: String,
                default: undefined,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            placeholder: {
                type: String,
                default: '',
            },
            time: {
                type: Boolean,
                default: false,
            },
            hasError: {
                type: Boolean,
                default: false,
            },
            dropdownClass: {
                type: String,
                default: '',
            },
            hoursStep: {
                type: Number,
                default: 1,
            },
            minutesStep: {
                type: Number,
                default: 1,
            },
            format: {
                type: String,
                default: 'iso8601',
            },
            modifier: {
                type: String,
                default: 'startOfDay',
            },
            minDate: {
                type: String,
                default: undefined,
            },
            maxDate: {
                type: String,
                default: undefined,
            },
            syncDateByMinOrMax: {
                type: Boolean,
                default: false,
            },
            emptyValueIsUndefined: {
                type: Boolean,
                default: false,
            },
            showClearValueButton: {
                type: Boolean,
                default: false,
            },
        },
        setup(props, {emit}) {
            const propsValue = toRef(props, 'value');
            const propsRefs = toRefs(props);

            // If the prop emptyValueIsUndefined is true, the empty value will be undefined instead of null
            const emptyValue = props.emptyValueIsUndefined ? undefined : null;

            const hourElements = ref<Record<string, Element>>({});
            const minuteElements = ref<Record<string, Element>>({});

            onBeforeUpdate(() => {
                hourElements.value = {};
                minuteElements.value = {};
            });

            function setHourRef(el: Element, key: string) {
                if (el) {
                    hourElements.value[key] = el;
                }
            }

            function setMinuteRef(el: Element, key: string) {
                if (el) {
                    minuteElements.value[key] = el;
                }
            }

            const {
                date,
                textDate,
                isEditing,
                setIsEditing,
                parseDate,
                setNow,
                selectDate,
            } = useDatePickerState(propsValue, propsRefs, emit, scrollToRef, emptyValue);

            const {
                dropdown,
                view,
                weeksPerMonth,
                daysInMonths,
                weekNumbers,
                monthsInYear,
                currentMonthName,
                countDayInMonth,
                weekDays,
                hours,
                minutes,
                previous,
                next,
            } = useDatePickerProvider(date, {
                time: props.time,
                hoursStep: props.hoursStep,
                minutesStep: props.minutesStep,
            });

            onClickOutside(dropdown, () => setIsEditing(false));

            const {
                dropdownClasses,
                getDayClasses,
                getMonthClasses,
            } = useDatePickerStyle(
                date,
                toRef(props, 'minDate'),
                toRef(props, 'maxDate'),
                toRef(props, 'syncDateByMinOrMax'),
                {dropdownClass: props.dropdownClass},
            );

            function onShown() {
                parseDate(propsValue.value);
                scrollToRef();
                setIsEditing(true);
            }

            function hide() {
                setIsEditing(false);
                dropdown.value?.hide();
            }

            function parseOnBlur(event) {
                parseDate(event.target.value);
                setIsEditing(false);
            }

            function preventSpace(event) {
                parseDate(event.target.value);
                dropdown.value?.show();
            }

            function scrollToRef() {
                Scroller.scrollToRef(hourElements.value['h' + date.value.hour()], {block: 'center'});
                Scroller.scrollToRef(minuteElements.value['m' + date.value.minute()], {block: 'center'});
            }

            watch(() => view.value, () => {
                nextTick(() => {
                    scrollToRef();
                });
            });

            return {
                date,
                isEditing,
                setIsEditing,
                parseDate,
                setNow,
                selectDate,

                dropdown,
                view,
                weeksPerMonth,
                textDate,
                daysInMonths,
                weekNumbers,
                monthsInYear,
                currentMonthName,
                countDayInMonth,
                weekDays,
                hours,
                minutes,
                previous,
                next,

                dropdownClasses,
                getDayClasses,
                getMonthClasses,

                hide,
                onShown,
                parseOnBlur,
                preventSpace,

                hourElements,
                minuteElements,
                setHourRef,
                setMinuteRef,
            };
        },
    });
</script>

<style
    lang="scss"
    scoped
>
    .CInput {
        @apply tw-px-3 tw-h-[28px];
    }

    .CFormDatePicker__reset-icon {
        @apply tw-ml-auto tw--mr-1.5;
        @apply tw-flex tw-items-center tw-justify-center tw-w-4 tw-h-4 tw-rounded tw-text-gray-400 tw-text-sm;
        @apply tw-transition-all hover:tw-text-gray-500 hover:tw-bg-gray-200 hover:tw-cursor-pointer;
    }
</style>
