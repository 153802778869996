<template>
    <CDropdownMenu arrow>
        <template #button>
            <CButton
                icon-ellipsis
                size="sm"
                variant="ghost"
            />
        </template>
        <CDropdownMenuItem
            icon="fa fa-pen"
            @click="showEditContactModal = true"
        >
            {{ __('billing_core:modify_contact') }}
        </CDropdownMenuItem>
        <CDropdownMenuItem
            icon="fa fa-trash"
            variant="danger"
            @click="showDeleteContactModal = true"
        >
            {{ __('billing_core:delete_contact_dots') }}
        </CDropdownMenuItem>
        <template #extra>
            <UpdateContactModal
                v-if="showEditContactModal"
                :contact-model="contactModel"
                @hidden="showEditContactModal = false"
                @updated="onContactUpdated"
            />
            <DeleteContactModal
                v-if="showDeleteContactModal"
                :contact-model="contactModel"
                @deleted="onContactDeleted"
                @hidden="showDeleteContactModal = false"
            />
        </template>
    </CDropdownMenu>
</template>

<script lang="ts">
    import type ContactModel from '@/modules/cashier/models/ContactModel';
    import DeleteContactModal from '@/modules/cashier/components/core/contact/organisms/DeleteContactModal.vue';
    import UpdateContactModal from '@/modules/cashier/components/core/contact/organisms/UpdateContactModal.vue';
    import type {PropType} from 'vue';
    import {defineComponent, ref} from 'vue';

    export default defineComponent({
        components: {UpdateContactModal, DeleteContactModal},
        props: {
            contactModel: {type: Object as PropType<ContactModel>, required: true},
        },
        setup(props, {emit}) {

            const showEditContactModal = ref(false);
            const showDeleteContactModal = ref(false);

            function onContactDeleted(contactModel: ContactModel) {
                emit('deleted', contactModel);
            }

            function onContactUpdated(contactModel: ContactModel) {
                emit('updated', contactModel);
            }

            return {
                showEditContactModal,
                showDeleteContactModal,
                onContactDeleted,
                onContactUpdated,
            };
        },
    });
</script>

