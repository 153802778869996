<template>
    <div class="tw-text-center print:tw-hidden">
        <MDropdown>
            <MButton
                icon-menu
                variant="light"
            >
                <template #left-icons>
                    <FontAwesomeIcon icon="fad fa-calendar-alt"/>
                </template>
                {{ currentDisplay }}
            </MButton>
            <template #content>
                <CDropdownMenuItem :to="{name:'planning.staffs.daily',params:{nursery:nursery.id}, query: $route.query}">
                    {{ __('planning:display_daily') }}
                </CDropdownMenuItem>
                <CDropdownMenuItem :to="{name:'planning.staffs.weekly',params:{nursery:nursery.id}, query: $route.query}">
                    {{ __('planning:display_weekly') }}
                </CDropdownMenuItem>
            </template>
        </MDropdown>
    </div>
</template>

<script>
    export default {
        props: {
            nursery: {
                type: Object,
                required: true,
            },
            currentDisplay: {
                type: String,
                default: '',
            },
        },
    };
</script>
