<template>
    <CModal
        ref="modal"
        :header-title="__('planning:planning_details')"
        size="5xl"
        @hidden="modalHidden"
        @shown="modalShown"
    >
        <div
            v-if="kid.id !== null"
            class="tw-flex tw-flex-wrap tw--mx-3"
        >
            <div class="tw-w-full md:tw-w-1/2 tw-px-3">
                <div class="tw-py-2 tw-px-3 tw-rounded-lg tw-border-gray-300 tw-border tw-h-full">
                    <h5 class="h5 mb-3 mt-2 text-center d-print-none">
                        {{ __('components:choose_period') }}
                    </h5>
                    <div class="row mt-3">
                        <div class="col-6">
                            <label class="label mb-1">{{ __('common:beginning') }}</label>
                            <MDatePicker
                                v-model="fromDate"
                                class="tw-w-full"
                                format="yyyy-MM-dd"
                            />
                        </div>
                        <div class="col-6">
                            <label class="label mb-1">{{ __('common:end') }}</label>
                            <MDatePicker
                                v-model="toDate"
                                class="tw-w-full"
                                format="yyyy-MM-dd"
                            />
                        </div>
                    </div>
                    <div class="mt-3 d-print-none">
                        <CButtonGroup class="tw-w-full">
                            <MButton
                                class="tw-w-1/2"
                                :label="__('common:previous_month')"
                                :variant="previousMonthSelected ? 'primary' : 'light'"
                                @click="setPreviousMonth"
                            />
                            <MButton
                                class="tw-w-1/2"
                                :label="__('common:current_month_other')"
                                :variant="thisMonthSelected ? 'primary' : 'light'"
                                @click="setThisMonth"
                            />
                        </CButtonGroup>
                    </div>
                    <hr class="hr mt-4 d-print-none">
                    <div class="print:tw-hidden tw-flex tw-flex-col tw-gap-1">
                        <div>
                            {{ __('common:show_by_colon') }}
                        </div>
                        <CButtonGroup class="tw-w-full">
                            <MButton
                                class="tw-w-1/3"
                                :label="__('common:hour_other')"
                                :variant="billingDisplay === 'hour' ? 'primary' : 'light'"
                                @click="switchDayFilter('hour')"
                            />
                            <MButton
                                class="tw-w-1/3"
                                :label="__('common:day_other')"
                                :variant="billingDisplay === 'day' ? 'primary' : 'light'"
                                @click="switchDayFilter('day')"
                            />
                            <MButton
                                class="tw-w-1/3"
                                :label="__('common:halfday_short')"
                                :variant="billingDisplay === 'halfDay' ? 'primary' : 'light'"
                                @click="switchDayFilter('halfDay')"
                            />
                        </CButtonGroup>
                    </div>
                </div>
            </div>
            <div
                v-if="can('read', 'kids_planning')"
                class="tw-w-full tw-mt-2 md:tw-mt-0 md:tw-w-1/2 tw-px-3"
            >
                <div class="tw-py-2 tw-px-3 tw-rounded-lg tw-border-gray-300 tw-border tw-h-full">
                    <h5 class="h5 mt-2 text-center">
                        {{ __('planning:total_of_period') }}
                    </h5>
                    <div
                        class="mx-auto"
                        style="max-width: 350px; max-height: 220px;"
                    >
                        <stack-chart
                            :billing-display="billingDisplay"
                            :chart-data="getData(summary, billingDisplay)"
                        />
                    </div>
                </div>
            </div>
        </div>

        <template v-if="can('read', 'kids_planning')">
            <div class="mt-3">
                <div
                    v-if="!loading"
                    class="row"
                >
                    <div class="col-12">
                        <table-summary
                            :billing-display="billingDisplay"
                            :nursery="nursery"
                            :summary="summary"
                        />
                    </div>
                </div>
                <div v-else>
                    <loader
                        light="true"
                        shadow="false"
                        :title="__('common:loading_dots')"
                    />
                </div>
            </div>
        </template>
    </CModal>
</template>

<script>
    import route from '@/modules/legacy/libs/ziggy';
    import useApi from '@/modules/app/composables/useApi';
    import _head from 'lodash-es/head';
    import _forEach from 'lodash-es/forEach';
    import {getData, getTotal} from '@/modules/legacy/libs/Summary.ts';
    import StackChart from '@/modules/legacy/components/Modules/PlanningSummary/StackChart.vue';
    import TableSummary from '@/modules/legacy/components/Modules/PlanningSummary/TableSummary.vue';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';
    import useAbility from '@/modules/app/composables/useAbility';
    import {Epoch} from '@meekohq/lumos';
    import {isNil} from 'lodash-es';

    export default {
        components: {
            StackChart,
            TableSummary,
        },
        props: {
            kid: {
                type: Object,
                required: true,
            },
            nursery: {
                type: Object,
                required: true,
            },
        },
        data: () => ({
            summary: [],
            fromDate: null,
            toDate: null,
            billingDisplay: window.localStorage.getItem('billingDisplay'),
            thisMonthSelected: false,
            previousMonthSelected: false,
            loading: false,
            isShown: false,
            can: useAbility().can,
        }),
        computed: {
            total() {
                return getTotal(this.summary, false);
            },
        },
        watch: {
            fromDate(val) {
                if (isNil(val)) {
                    return;
                }
                window.localStorage.setItem('filterBillingFrom', val);
                this.checkCurrentDates();
                this.getSummary();
            },
            toDate(val) {
                if (isNil(val)) {
                    return;
                }
                window.localStorage.setItem('filterBillingTo', val);
                this.checkCurrentDates();
                this.getSummary();
            },
        },
        mounted() {
            this.billingDisplay = window.localStorage.getItem('billingDisplay')
                ? window.localStorage.getItem('billingDisplay')
                : 'hour';
            this.refreshDates();
        },
        methods: {
            getSummary() {
                if (
                    !this.kid.id ||
                    this.loading ||
                    !this.isShown
                ) {
                    return;
                }

                this.loading = true;
                useApi().legacy.get(
                    route('nurseries.kids.plannings.summary', {
                        nurseries: this.kid.nursery_id,
                        kid: this.kid.id,
                    }),
                    {
                        params: {
                            from: Epoch.parse(this.fromDate, 'yyyy-MM-dd').startOfDay().toTimestamp(),
                            to: Epoch.parse(this.toDate, 'yyyy-MM-dd').endOfDay().toTimestamp(),
                        },
                    },
                )
                    .then(response => {
                        this.summary = response.data;
                        this.loading = false;
                    })
                    .catch(error => {
                        this.loading = false;
                        if (error?.response?.status === 422) {
                            _forEach(error.response.data.errors, function(value) {
                                useNotification().error(_head(value));
                            });
                        } else {
                            useNotification().error(error);
                        }
                    });
            },

            modalShown() {
                this.isShown = true;
                this.refreshDates();
                this.getSummary();
            },

            modalHidden() {
                this.isShown = false;
            },

            switchDayFilter(val) {
                this.billingDisplay = val;
                window.localStorage.setItem('billingDisplay', val);
            },
            setThisMonth() {
                this.fromDate = Epoch.now().startOfMonth().toFormat('yyyy-MM-dd');
                this.toDate = Epoch.now().endOfMonth().toFormat('yyyy-MM-dd');
            },
            setPreviousMonth() {
                const oneMonthAgo = Epoch.now().subMonths(1);

                this.fromDate = oneMonthAgo.startOfMonth().toFormat('yyyy-MM-dd');
                this.toDate = oneMonthAgo.endOfMonth().toFormat('yyyy-MM-dd');
            },
            checkCurrentDates() {
                const oneMonthAgo = Epoch.now().subMonths(1);

                this.thisMonthSelected = false;
                this.previousMonthSelected = false;

                if (
                    this.fromDate === Epoch.now().startOfMonth().toFormat('yyyy-MM-dd') &&
                    this.toDate === Epoch.now().endOfMonth().toFormat('yyyy-MM-dd')
                ) {
                    this.thisMonthSelected = true;
                } else if (
                    this.fromDate === oneMonthAgo.startOfMonth().toFormat('yyyy-MM-dd') &&
                    this.toDate === oneMonthAgo.endOfMonth().toFormat('yyyy-MM-dd')
                ) {
                    this.previousMonthSelected = true;
                }
            },
            getData(summary, billingDisplay) {
                return getData(summary, billingDisplay);
            },
            refreshDates() {
                const savedFrom = window.localStorage.getItem('filterBillingFrom');
                const savedTo = window.localStorage.getItem('filterBillingTo');

                this.fromDate = savedFrom || Epoch.now().startOfMonth().toFormat('yyyy-MM-dd');
                this.toDate = savedTo || Epoch.now().endOfMonth().toFormat('yyyy-MM-dd');
                this.checkCurrentDates();
            },
        },
    };
</script>
