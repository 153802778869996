<template>
    <ResourceFinder
        ref="resourceFinderRef"
        :always-open="alwaysOpen"
        :builder="queryBuilder"
        :button-class="buttonClass"
        :button-disabled="buttonDisabled"
        :button-size="buttonSize"
        :button-variant="buttonVariant"
        :has-unselect="hasUnselect"
        :hide-selected-option="hideSelectedOption"
        :multi="multi"
        :multi-minimum="multiMinimum"
        :options-callback="staffFinderOptionsCallback"
        :prevent-hide-on-click="preventHideOnClick"
        :value="value"
        :wrapper="wrapper"
        :wrapper-class="wrapperClass"
        @fallback="onFallback"
        @input="onChange"
        @search="search = $event"
    >
        <template #trigger="{toggle}">
            <slot
                name="trigger"
                :toggle="toggle"
                :value="value"
            />
        </template>
        <template #button-text-empty>
            <slot name="button-text-empty"/>
        </template>

        <template #button-text="{value}">
            {{ value.fullname }}
        </template>

        <template #button-text-multi="{values, langVar}">
            <slot
                :lang-var="langVar"
                name="button-text-multi"
                :values="values"
            >
                {{ __('components:count_item', {count: values.length - 1, value: values[0].fullname}) }}
            </slot>
        </template>

        <template
            v-if="$slots['fallback']"
            #fallback
        >
            <slot name="fallback"/>
        </template>

        <template #default="{option, searchValue}">
            <slot
                :option="option"
                :search-value="searchValue"
            >
                <div class="tw-flex tw-pr-4 tw-items-center">
                    <div class="tw-mr-2">
                        <StaffAvatar
                            class="tw-w-6"
                            :staff-model="option"
                        />
                    </div>
                    <div
                        class="tw-truncate"
                        v-html="highlight(searchValue, option.fullname)"
                    />
                    <CText
                        v-if="userStaff && option.id === userStaff.getKey()"
                        class="tw-ml-1"
                        font-weight="bold"
                    >
                        ({{ __('common:myself') }})
                    </CText>
                </div>
            </slot>
        </template>
    </ResourceFinder>
</template>

<script lang="ts">
    import type {PropType} from 'vue';
    import {computed, defineComponent, ref} from 'vue';
    import type {QueryBuilder} from '@meekohq/lumos';
    import {type Model} from '@meekohq/lumos';
    import ResourceFinder from '@/modules/app/components/resource/organisms/ResourceFinder.vue';
    import useHighlighter from '@/modules/legacy/helpers/useHighlighter';
    import StaffModel from '@/modules/human-resources/models/StaffModel';
    import moment from 'moment';
    import type {StaffFinderOptionType} from '@/modules/request/components/Teams/StaffFinderOptionType';
    import StaffAvatar from '@/modules/app/components/atoms/avatars/StaffAvatar.vue';
    import type OrganizationModel from '@/modules/organization/models/OrganizationModel';

    export default defineComponent({
        components: {StaffAvatar, ResourceFinder},
        props: {
            // !!! DO NOT EDIT !!!
            value: {type: [Object, Array] as PropType<Model | Model[] | StaffFinderOptionType | StaffFinderOptionType[]>},
            injectQuery: {type: Object as PropType<QueryBuilder<any>>},
            optionsCallback: {
                type: Function as PropType<(models: Model[] | StaffFinderOptionType[]) => any[]>,
                default: undefined,
                required: false,
            },
            hasUnselect: {type: Boolean, default: false},
            multi: {type: Boolean, default: false},
            multiMinimum: {type: [Number, String], default: 0},
            wrapperClass: {},
            buttonClass: {},
            buttonSize: {},
            buttonVariant: {},
            onlyUserOrganizations: {type: Boolean, default: false},
            allowedOrganizations: {type: Array as PropType<OrganizationModel[]>, default: undefined},
            hasActiveContract: {type: Boolean, default: false},
            excludedIds: {
                type: Array as PropType<string[]>,
            },
            alwaysOpen: {type: Boolean, default: false},
            hideSelectedOption: {type: Boolean, default: false},
            preventHideOnClick: {type: Boolean as PropType<boolean>, default: false},
            withRelatedUserCount: {type: Boolean as PropType<boolean>, default: false},
            userStaff: {type: Object as PropType<StaffModel>, default: undefined},
            buttonDisabled: {type: Boolean as PropType<boolean>, default: false},
            wrapper: {type: String as PropType<'CPopover' | 'ResourceFinderWrapper'>, default: 'CPopover'},

        },
        emits: ['input', 'fallback'],
        setup(props, {emit}) {
            // !!! START: DO NOT EDIT !!
            const search = ref('');
            const onChange = function(value) {
                emit('input', value);
            };
            // !!! END: DO NOT EDIT !!

            const resourceFinderRef = ref();

            const queryBuilder = computed(() => {
                let builder = StaffModel.query().orderBy('last_name');

                if (search.value) {
                    builder = builder.where(query => {
                        query.whereLike('last_name', `*${search.value}*`);
                        query.orWhereLike('first_name', `*${search.value}*`);
                    });
                }

                // Exclude staffs with these ids from the query
                if (props.excludedIds?.length) {
                    builder.whereNotIn('id', props.excludedIds);
                }

                // Only get staffs that are related to the current user organizations
                if (props.onlyUserOrganizations) {
                    builder.whereHas(new StaffModel().organizations(), query => {
                        return query.scope('userOrganizations');
                    });
                }

                // Only get staffs that are related to the allowed organizations
                if (props.allowedOrganizations) {
                    builder.whereHas(new StaffModel().organizations(), query => {
                        return query.whereIn('id', props.allowedOrganizations.map(org => org.getKey()));
                    });
                }

                if (props.hasActiveContract && !search.value) {
                    const from = moment().startOf('day').format('YYYY-MM-DD');

                    // Contrats actifs niveau métier = contrats actifs + contrats à venir
                    builder.whereHas(new StaffModel().contracts(), query => {
                        return query.scope('active', [from])
                            .orWhere(query1 => query1.scope('coming', from));
                    });
                }

                if (props.withRelatedUserCount) {
                    builder.withCount('user');
                }

                if (props.userStaff) {
                    // If the user staff is set, we exclude its id from the query to avoid duplicate entries
                    builder.where('id', '!=', props.userStaff.getKey());
                }

                if (props.injectQuery) {
                    builder.inject(props.injectQuery);
                }

                return builder;
            });

            const onFallback = function() {
                emit('fallback');
            };

            /**
             * Callback to add the user staff to the options and forward the optionsCallback
             * function to the ResourceFinder component
             * @param models
             */
            function staffFinderOptionsCallback(models: any[]) {
                if (props.userStaff) {
                    models.unshift(props.userStaff);
                }

                return props.optionsCallback ? props.optionsCallback(models) : models;
            }

            return {
                resourceFinderRef,
                search,
                onChange,
                onFallback,
                queryBuilder,
                staffFinderOptionsCallback,
                ...useHighlighter(),
            };
        },
    });
</script>
