<template>
    <CVStack
        align="start"
        class="tw-flex-wrap"
        gap="1"
    >
        <CHStack
            align="center"
            class="tw-flex-wrap"
            gap="2"
        >
            <div>
                <CBadge
                    class="tw-whitespace-nowrap"
                    variant="primary"
                >
                    <template v-if="sum.type === 'naps'">
                        <template v-if="sum.ended_at">
                            {{
                                __('family_kid:nap_time_duration', {
                                    startTime: fromTimestamp(sum.started_at).toLocaleString(Epoch.presets.TIME_SIMPLE),
                                    endTime: fromTimestamp(sum.ended_at).toLocaleString(Epoch.presets.TIME_SIMPLE) ?? fromTimestamp(sum.started_at).toLocaleString(Epoch.presets.TIME_SIMPLE),
                                    duration: getDuration(sum.started_at, sum.ended_at),
                                })
                            }}
                        </template>
                        <template v-else>
                            {{
                                __('family_kid:from_to_without_to', {
                                    startTime: fromTimestamp(sum.started_at).toLocaleString(Epoch.presets.TIME_SIMPLE),
                                })
                            }}
                        </template>
                    </template>
                    <template v-else>
                        {{ Epoch.fromJSDate(new Date(sum.done_at * 1000)).toLocaleString(Epoch.presets.TIME_SIMPLE) }}
                    </template>
                </CBadge>
            </div>
            <CText>
                <div v-if="sum.type !== 'notes'">
                    <template v-if="sum.type === 'arrival'">
                        {{ __('family_kid:arrival') }}
                    </template>
                    <template v-if="sum.type === 'departure'">
                        {{ __('family_kid:departure') }}
                    </template>
                    <template v-if="sum.type === 'milk'">
                        <span class="badge badge-primary">{{ sum.milk }} ml</span>
                    </template>
                    <template v-if="sum.type === 'meals' || sum.type === 'milk'">
                        <template v-if="sum.type === 'meals' && sum.rating === null">
                            {{ __('family_kid:eaten_none') }}
                        </template>
                        <template v-else-if="sum.rating === 0">
                            {{ __('family_kid:eaten_few') }}
                        </template>
                        <template v-else-if="sum.rating === 1">
                            {{ __('family_kid:eaten_well') }}
                        </template>
                        <template v-else-if="sum.rating === 2">
                            {{ __('family_kid:eaten_very_well') }}
                        </template>
                    </template>
                    <template v-if="sum.type === 'naps'">
                        <template v-if="!sum.ended_at">
                            {{ __('family_kid:sleeping_onomatopoeia') }}
                        </template>
                        <template v-else-if="sum.rating === null">
                            {{ __('family_kid:sleep_rest_only') }}
                        </template>
                        <template v-else-if="sum.rating === 0">
                            {{ __('family_kid:sleep_few') }}
                        </template>
                        <template v-else-if="sum.rating === 1">
                            {{ __('family_kid:sleep_well') }}
                        </template>
                        <template v-else>
                            {{ __('family_kid:sleep_very_well') }}
                        </template>
                    </template>
                    <template v-if="sum.type === 'weights'">
                        <span class="badge badge-primary">{{ sum.kilogram }} kg</span>
                    </template>
                    <template v-if="sum.type === 'temperatures'">
                        <span class="badge badge-primary tw-mr-1">{{ sum.degree }} °C</span>
                        <template v-if="sum.temperature_type === 'auricular'">
                            ({{ __('family_kid:temperature_type.auricular') }})
                        </template>
                        <template v-else-if="sum.temperature_type === 'front'">
                            ({{ __('family_kid:temperature_type.front') }})
                        </template>
                        <template v-else-if="sum.temperature_type === 'axillary'">
                            ({{ __('family_kid:temperature_type.axillary') }})
                        </template>
                        <template v-else-if="sum.temperature_type === 'temple'">
                            ({{ __('family_kid:temperature_type.temple') }})
                        </template>
                        <template v-else-if="sum.temperature_type === 'oral'">
                            ({{ __('family_kid:temperature_type.oral') }})
                        </template>
                        <template v-else-if="sum.temperature_type === 'rectal'">
                            ({{ __('family_kid:temperature_type.rectal') }})
                        </template>
                    </template>
                    <template v-if="sum.type === 'drugs'">
                        <span class="tw-mr-1">{{ sum.name }}</span>
                        <template v-if="sum.drug_type === 'suppository'">
                            ({{ __('family_kid:drug_type.suppository') }})
                        </template>
                        <template v-else-if="sum.drug_type === 'syrup'">
                            ({{ __('family_kid:drug_type.syrup') }})
                        </template>
                        <template v-else-if="sum.drug_type === 'other'">
                            ({{ __('family_kid:drug_type.other') }})
                        </template>
                        <template v-else>
                            ({{ __('family_kid:drug_type.pill') }})
                        </template>
                    </template>
                    <template v-if="sum.type === 'diapers'">
                        <template v-if="sum.diaper_type !== null">
                            {{ __('family_kid:pee_colon') }}
                            <template v-if="sum.pee">
                                {{ __('common:yes') }}
                            </template>
                            <template v-else>
                                {{ __('common:no') }}
                            </template>
                            | {{ __('family_kid:stools_colon') }}
                            <template v-if="sum.poop === 'hard'">
                                {{ __('family_kid:stools_type.hard') }}
                            </template>
                            <template v-else-if="sum.poop === 'normal'">
                                {{ __('family_kid:stools_type.normal') }}
                            </template>
                            <template v-else-if="sum.poop === 'soft'">
                                {{ __('family_kid:stools_type.soft') }}
                            </template>
                            <template v-else-if="sum.poop === 'liquid'">
                                {{ __('family_kid:stools_type.liquid') }}
                            </template>
                            <template v-else>
                                {{ __('common:no') }}
                            </template>
                        </template>
                        <template v-if="sum.nose || sum.eyes || sum.ear || sum.cream">
                            <br>
                            {{ __('family_kid:health_other_colon') }}
                            <img
                                v-if="sum.nose"
                                :alt="__('transmission:nose')"
                                src="@/assets/images/tablet/nose.svg"
                                style="margin-right: 3px;"
                                :width="[isHover ? '14px' : '20px']"
                            >
                            <img
                                v-if="sum.eyes"
                                :alt="__('transmission:eyes')"
                                class="pb-1"
                                src="@/assets/images/tablet/eyes.svg"
                                style="margin-right: 3px;"
                                :width="[isHover ? '19px' : '26px']"
                            >
                            <img
                                v-if="sum.ear"
                                :alt="__('transmission:ear')"
                                src="@/assets/images/tablet/ear.svg"
                                style="margin-right: 3px;"
                                :width="[isHover ? '13px' : '17px']"
                            >
                            <img
                                v-if="sum.cream"
                                :alt="__('transmission:cream')"
                                src="@/assets/images/tablet/cream.svg"
                                style="margin-right: 3px; padding-bottom: 4px;"
                                :width="[isHover ? '12px' : '16px']"
                            >
                        </template>
                    </template>
                    <template v-if="sum.type === 'activities'">
                        {{ sum.name }}
                    </template>
                    <template v-if="sum.type === 'photos' || sum.type === 'video'">
                        {{ sum.name }}
                        <a
                            class="text-primary pointer"
                            @click="showMedia(sum)"
                        >({{ __('family_kid:picture_preview') }})</a>
                    </template>
                </div>
                <span
                    v-if="sum.type === 'notes'"
                    class="observations"
                >{{ sum.note }}</span>
                <span
                    v-else-if="sum.note"
                    class="observations"
                >{{ ' • ' + sum.note }}</span>
            </CText>
        </CHStack>
        <div v-if="sum.staff">
            <CBadge>
                <CHStack align="center">
                    <MAvatar
                        class="avatar tw-w-4 tw-my-1 tw-mr-2"
                        fallback-staff
                        :gender="sum.staff.gender"
                        :name="sum.staff.first_name"
                        :url="sum.staff.avatar_url"
                    />
                    <CText>
                        {{ $fullName(sum.staff) }}
                    </CText>
                </CHStack>
            </CBadge>
        </div>
    </CVStack>
</template>

<script>
    import useEpoch from '@/modules/app/composables/useEpoch';
    import {Epoch, EpochDuration} from '@meekohq/lumos';

    export default {
        name: 'SummaryDescription',
        props: {
            sum: {type: Object},
            selectedMedia: {type: Object},
            isHover: {
                type: Boolean,
                default: false,
            },
        },
        computed: {
            Epoch() {
                return Epoch;
            },
        },
        methods: {
            fromJSDate: useEpoch().fromJSDate,
            fromTimestamp: useEpoch().fromTimestamp,
            showMedia(media) {
                this.$emit('update:selected-media', media);
            },

            getDuration(start, end) {
                const time1 = Number.isInteger(start) ? this.fromTimestamp(start) : this.fromJSDate(new Date(start));
                const time2 = Number.isInteger(end) ? this.fromTimestamp(end) : this.fromJSDate(new Date(end));
                const tempTime = time1.startOfMinute().difference(time2.startOfMinute());
                const totalMinutes = tempTime ? tempTime.as('minutes') : 0;

                if (totalMinutes !== 0) {
                    return EpochDuration.fromMinutes(totalMinutes).rescale('hours', 'minutes').toLocalString();
                } else {
                    return 'Zzz';
                }
            },
        },
    };
</script>
