import type {QueryBuilder} from '@meekohq/lumos';
import {Model} from '@meekohq/lumos';
import TeamModel from '@/modules/human-resources/models/TeamModel';
import Manager from '@/modules/legacy/store/manager.store';
import OrganizationModel from '@/modules/organization/models/OrganizationModel';


export default class TeamOrganizationPivot extends Model {

    public type = 'hr/teams_organizations_pivot';

    public attributes: {
        id: string;
        account_id: string | undefined;
        team_id: string | undefined;
        organization_id: string | undefined;
        archived_at: string | undefined | null;
    } = {
            id: this.uuid(),
            account_id: undefined,
            team_id: undefined,
            organization_id: undefined,
            archived_at: undefined,
        };

    organization() {
        return this.belongsTo('organization', OrganizationModel, 'organization_id');
    }

    team() {
        return this.belongsTo('team', TeamModel, 'team_id');
    }

    scopeActiveOrganization(query: QueryBuilder<any>) {
        query.where('organization_id', '=', Manager.activeOrganization.id);
    }

    scopeActiveAndNotArchive(query: QueryBuilder<any>) {
        query.where('organization_id', '=', Manager.activeOrganization.id).whereNull('archived_at');
    }

    scopeNotArchived(query: QueryBuilder<any>) {
        query.whereNull('archived_at');
    }

    getPivotsByOrganization() {
        return this.hasMany('getPivotsByOrganization', TeamOrganizationPivot, 'id', 'organization_id')
            .setQuery(query => query.where('organization_id', Manager.activeOrganization.id));
    }
}
