import __ from '@/modules/app/utils/i18n-facade';

export default function() {
    return [
        {
            name: __('billing_payment:payment_methods.transfer'),
            value: 'transfer',
        },
        {
            name: __('billing_payment:payment_methods.check'),
            value: 'check',
        },
        {
            name: __('billing_payment:payment_methods.debit'),
            value: 'debit',
        },
        {
            name: __('billing_payment:payment_methods.bank_card'),
            value: 'bank_card',
        },
        {
            name: __('billing_payment:payment_methods.cash'),
            value: 'cash',
        },
        {
            name: __('billing_payment:payment_methods.sepa'),
            value: 'sepa',
        },
        {
            name: __('billing_payment:payment_methods.cesu'),
            value: 'cesu',
        },
        {
            name: __('billing_payment:payment_methods.marmaille_check'),
            value: 'marmaille_check',
        },
        {
            name: __('billing_payment:payment_methods.credit_note'),
            value: 'credit_note',
        },
        {
            name: __('common:other_one'),
            value: 'other',
        },
    ];
}
