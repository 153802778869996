import type {Ref} from 'vue';
import {computed, ref} from 'vue';
import type {Epoch, Model, ModelConstructorType, QueryBuilder} from '@meekohq/lumos';
import {collect, ModelCollection, now} from '@meekohq/lumos';
import TicketModel from '@/modules/activity/ticket/domain/TicketModel';
import TicketPivot from '@/modules/activity/ticket/domain/TicketPivot';
import useNotification from '@/modules/meeko-ui/composables/useNotification';
import type StaffModel from '@/modules/human-resources/models/StaffModel';
import MedicalActionModel from '@/modules/health/models/MedicalActionModel';
import useUserStaff from '@/modules/human-resources/composables/staff/useUserStaff';
import type {StaffFinderOptionType} from '@/modules/request/components/Teams/StaffFinderOptionType';
import useNotificationStore from '@/modules/app/composables/useNotificationStore';

type GetTasksOptionType = {
    assigneesToConstrainTo: Ref<Array<StaffModel | StaffFinderOptionType>>,
    resourceTypesToConstrainTo: Ref<string[]>,
    constrainToCompletedTasks: Ref<boolean>,
    constrainToResourceModel: ModelConstructorType,
    constrainByExpiredDateLimit: Ref<Epoch | null>
    constrainToStaffUser: Ref<boolean>,
    withResourceOrganizations: Ref<boolean>,
    showVaccines: Ref<boolean>,
};

const defaultOptions: Partial<GetTasksOptionType> = {
    assigneesToConstrainTo: ref([]),
    resourceTypesToConstrainTo: ref([]),
    constrainToCompletedTasks: ref(false),
    constrainToStaffUser: ref(true),
};
export default function(options = defaultOptions) {

    const {showVaccines, taskExpiredDaysLimit} = useNotificationStore();

    const constrainByExpiredDateLimit = computed(() => {
        return now().endOfDay().addDays(taskExpiredDaysLimit.value);
    });

    // These options are dynamic as they depend on the store.
    // They can be overridden by the options passed to the function
    const dynamicDefaultOptions = {
        constrainByExpiredDateLimit,
        showVaccines,
    };

    options = {...defaultOptions, ...dynamicDefaultOptions, ...options};

    const tasks = ref<ModelCollection<TicketModel>>(new ModelCollection<TicketModel>());
    const {error} = useNotification();

    const {userStaff, retrieveUserStaff} = useUserStaff();

    const resourceIdsToConstrainTo = ref<string | string[]>([]);

    // The groupTasks function is extracted to allow more flexibility like in options API components
    // where computed from composition-api cannot be imported
    const tasksGroupByModelId = computed(() => groupTasks());

    function groupTasks() {
        const groups = {};
        if (options.constrainToResourceModel) {
            const resourceTypeConstraint = new options.constrainToResourceModel().getType();

            tasks.value.each(task => {
                task.ticketPivots().value().each(ticketPivot => {
                    if (ticketPivot.attributes.resource_id && (ticketPivot.attributes.resource_type === resourceTypeConstraint)) {
                        if (groups[ticketPivot.attributes.resource_id]) {
                            groups[ticketPivot.attributes.resource_id].push(task);
                        } else {
                            groups[ticketPivot.attributes.resource_id] = collect([task]);
                        }
                    }
                });
            });
        }

        return groups;
    }

    const ticketQuery = ref();

    /**
     * NB: This fonction will be replaced by the use of the TicketRepositoryPort later
     */
    function computeTicketQuery() {
        const query = TicketModel.query().where('type', 'task');

        if (options.constrainToStaffUser?.value) {
            // Only get tasks where the user is the assignee or where the assignee is null
            if (userStaff.value) {
                query.where(query2 => query2.where('assignee_id', userStaff.value?.getKey()).orWhereNull('assignee_id'));
            } else {
                query.whereNull('assignee_id');
            }
        }

        if (options.resourceTypesToConstrainTo?.value.length) {
            // Only get tasks related to the resources passed in the options like kids or staffs
            filterResourceTypes(query, options.resourceTypesToConstrainTo.value);
        }

        if (options.assigneesToConstrainTo) {
            // Filter tasks having their assignee in the array
            filterAssignees(query, options.assigneesToConstrainTo.value);
        }

        if (options.constrainToCompletedTasks) {
            filterCompleted(query, options.constrainToCompletedTasks.value);
        }

        if (options.constrainToResourceModel && resourceIdsToConstrainTo.value.length) {
            const resourceTypeConstraint = new options.constrainToResourceModel().getType();

            // Constrain only the task related to a single resource like a specific kid or staff
            TicketModel.tasksFilteredByResourcesScope(query, resourceIdsToConstrainTo.value, resourceTypeConstraint);
        }

        if (options.constrainByExpiredDateLimit?.value) {
            // Only get tasks that expire before the date limit
            query.whereDateTime('expired_at', '<', options.constrainByExpiredDateLimit?.value.toISOString());
        }

        // Only get tasks that have at least a pivot where the user is allowed to see the resource
        query.whereHas(new TicketModel().ticketPivots(), query2 => {
            query2.scope('onlyAllowed');
        });

        // If the showVaccines option is false, we filter out the tasks that are vaccines
        if (!options.showVaccines?.value) {
            query.whereDoesntHave(new TicketModel().ticketPivots(), query2 => {
                query2.where('resource_type', new MedicalActionModel().getType());
            });
        }

        query.with(new TicketModel().parent(), query2 => {
            query2.with(new TicketModel().lastOccurrence()); // On récupére le parent et la dernière occurence pour calculer si ce Ticket est la dernière occurence
        })
            .with(new TicketModel().lastOccurrence()) // Pareil ici, on récupère pour comparer si la dernière occurence de la récurrence est ce ticket
            .with(new TicketModel().assignee())
            .with(new TicketModel().reporter())
            .with(new TicketModel().completedBy());

        query.with(new TicketModel().ticketPivots(), query2 => {
            query2.scope('onlyAllowed');
            if (options.withResourceOrganizations) {
                TicketPivot.withResourceOrganizations(query2);
            } else {
                query2.with(new TicketPivot().resource());
            }
        });

        query.orderBy('expired_at');

        ticketQuery.value = query;

        return query;
    }

    function waitForQueryToBeReady() {
        return retrieveUserStaff();
    }

    async function getTasks(modelsIds?: string | string[]) {
        // If no value compatible with the scope is passed we avoid querying for nothing
        if (!modelsIds || (Array.isArray(modelsIds) && !modelsIds.length)) {
            return Promise.resolve(collect<TicketModel>([]));
        }

        try {
            await waitForQueryToBeReady();

            // Enable the user to filter only some ids when scoping to a certain TicketPivot resource type
            resourceIdsToConstrainTo.value = modelsIds;

            computeTicketQuery();

            const result = await ticketQuery.value.get();

            tasks.value = result;

            return result;
        } catch (e) {
            error(e);

            throw e;
        }
    }

    function filterResourceTypes<T extends Model>(query: QueryBuilder<T>, categories: string[]) {
        // If we don't have MedicalActionModel in our filter, we filter out tickets that have any pivot related
        // to it to avoid retrieving vaccines that are attached to a KidModel and a MedicalActionModel
        if (!categories.includes(new MedicalActionModel().getType())) {
            query.where(query2 => {
                query2.whereDoesntHave(new TicketModel().ticketPivots(), query3 => {
                    query3.where('resource_type', new MedicalActionModel().getType());
                }).whereHas(new TicketModel().ticketPivots(), query3 => {
                    query3.whereIn('resource_type', categories);
                });
            });
        } else {
            query.whereHas(new TicketModel().ticketPivots(), query3 => {
                query3.whereIn('resource_type', categories);
            });
        }
    }

    function filterAssignees<T extends Model>(query: QueryBuilder<T>, assignees: Array<StaffModel | StaffFinderOptionType>) {
        const userStaffIsInAssigneesFilter = !!assignees.find(assignee => assignee.id === userStaff.value?.getKey());

        const anyoneOptionIsInAssigneesFilter = !!assignees.find(assignee => assignee.id === 'anyone');

        const assigneesWithoutUserStaffAndAnyoneOption = assignees.filter(assignee => {
            return ![userStaff.value?.getKey(), 'anyone'].includes(assignee.id);
        }) as StaffModel[];

        // If the filter is empty we fetch all the tasks the user can see, otherwise we apply each filter conditionally
        // by the filter values
        query.where(query2 => {
            // If the user has no staff we only show tasks without assignee
            if (!userStaff.value) {
                query2.whereNull('assignee_id');

                return;
            }

            if (!assignees.length || assigneesWithoutUserStaffAndAnyoneOption.length) {
                query2.where(query3 => {
                    query3.where('reporter_id', userStaff.value?.getKey())
                        .whereIn('assignee_id', assigneesWithoutUserStaffAndAnyoneOption.map(assignee => assignee.getKey()));
                });
            }

            if (!assignees.length || userStaffIsInAssigneesFilter) {
                query2.orWhere(query3 => {
                    query3.where('assignee_id', userStaff.value?.getKey());
                });
            }

            if (!assignees.length || anyoneOptionIsInAssigneesFilter) {
                query2.orWhereNull('assignee_id');
            }
        });
    }

    function filterCompleted<T extends Model>(query: QueryBuilder<T>, isCompleted: boolean) {
        if (isCompleted) {
            query.whereNotNull('completed_at');
        } else {
            query.whereNull('completed_at');
        }
    }

    return {
        tasks,
        groupTasks,
        tasksGroupByModelId,
        getTasks,
        ticketQuery,
        waitForQueryToBeReady,
        computeTicketQuery,
    };
}
