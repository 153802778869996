<template>
    <CModal
        :id="modalId"
        ref="modal"
        :header-title="__('photo:update_picture')"
        size="4xl"
        @shown="onShow"
    >
        <CForm>
            <CFormGroup>
                <CLabel>{{ __('family_kid:children_in_the_photo') }}</CLabel>
                <div class="PhotoEditModal__kid-list">
                    <template v-for="kid in kids">
                        <div
                            :key="kid.id"
                            class="PhotoEditModal__list-item tw-group"
                        >
                            <KidCard :kid-model="kid"/>
                            <MButton
                                class="PhotoEditModal__unlink-button"
                                round
                                size="xs"
                                variant="danger"
                                zoom
                                @click="removeKid(kid)"
                            >
                                <FontAwesomeIcon icon="fa fa-times"/>
                            </MButton>
                        </div>
                    </template>
                    <KidFinder
                        v-model="kids"
                        has-active-contract
                        :inject-query="kidQuery"
                        multi
                    >
                        <template #trigger="{toggle}">
                            <MButton
                                class="tw-h-full tw-w-full"
                                variant="dashed-light"
                                @click="toggle"
                            >
                                <FontAwesomeIcon
                                    class="tw-text-3xl tw-text-primary-500"
                                    icon="fa fa-plus"
                                />
                            </MButton>
                        </template>
                    </KidFinder>
                </div>
            </CFormGroup>
            <CFormGroup>
                <CLabel>{{ __('common:description') }}</CLabel>
                <MInput
                    v-model="description"
                    :placeholder="__('common:description')"
                />
            </CFormGroup>
            <CFormGroup>
                <CLabel>{{ __('photo:taken_on') }}</CLabel>
                <MDatePicker
                    v-model="taken_at"
                    class="tw-w-full"
                    format="yyyy-MM-dd HH:mm"
                    with-time
                />
            </CFormGroup>
            <CFormGroup v-if="!photo.video">
                <CLabel>{{ __('photo:available_in_library_of_website_ask') }}</CLabel>
                <CFormSelect
                    v-model="website"
                    :options="[{value: 1, text: __('common:yes')}, {value: 0, text: __('common:no')}]"
                    :placeholder="__('common:website')"
                    :search-bar="false"
                    text-path="text"
                    value-path="value"
                />
            </CFormGroup>
        </CForm>
        <template #footer>
            <div class="tw-w-full tw-flex tw-justify-end">
                <MButton
                    class="tw-mr-2"
                    @click="hideModal"
                >
                    {{ __('common:actions.cancel') }}
                </MButton>
                <MButton
                    variant="primary"
                    @click="update"
                >
                    {{ __('common:actions.save') }}
                </MButton>
            </div>
        </template>
    </CModal>
</template>

<script>
    import _head from 'lodash-es/head';
    import _forEach from 'lodash-es/forEach';
    import NoAvatarImage from '@/assets/images/no-avatar.png';
    import route from '@/modules/legacy/libs/ziggy';
    import useApi from '@/modules/app/composables/useApi';
    import __ from '@/modules/app/utils/i18n-facade';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';
    import {Epoch} from '@meekohq/lumos';
    import KidFinder from '@/modules/family/components/kid/KidFinder.vue';
    import KidModel from '@/modules/family/models/KidModel';
    import KidCard from '@/modules/family/components/kid/KidCard.vue';

    export default {
        components: {
            KidCard,
            KidFinder,
        },
        props: {
            nursery: {
                type: Object,
                required: true,
            },
            modalId: {
                type: String,
                required: true,
            },
            photo: {
                type: Object,
                required: true,
            },
            photoModel: {
                type: Object,
                required: true,
            },
        },
        data: () => ({
            description: undefined,
            taken_at: undefined,
            website: undefined,
            NoAvatarImage,
            kids: [],
        }),
        computed: {
            kidQuery() {
                return KidModel.query().whereHas(new KidModel().organization(), query => {
                    return query.where('id', this.nursery.id);
                });
            },
        },
        methods: {
            update() {
                useApi().legacy.put(route('nurseries.photos.update', {
                    nurseries: this.nursery.id,
                    photo: this.photo.id,
                }), {
                    description: this.description,
                    kids: JSON.stringify(this.kids.map(kid => kid.attributes)),
                    website: this.website,
                    taken_at: this.taken_at ? Epoch.parse(this.taken_at, 'yyyy-MM-dd HH:mm').toTimestamp() : null,
                }).then(response => {
                    useNotification().success(__('photo:photo_updated'));
                    this.photo.description = response.data.description;
                    this.photo.taken_at = response.data.taken_at;
                    this.photo.website = response.data.website;

                    this.photoModel.kids().fresh();

                    this.hideModal();
                }).catch(error => {
                    if (error?.response?.status === 422) {
                        _forEach(error.response.data.errors, value => {
                            useNotification().error(_head(value));
                        });
                    } else {
                        useNotification().error(error);
                    }
                });
            },

            async onShow() {
                this.description = this.photo.description;
                this.website = this.photo.website;
                this.taken_at = Epoch.fromTimestamp(this.photo.taken_at).toFormat('yyyy-MM-dd HH:mm');

                this.kids = this.photoModel.kids().value().toArray();
            },

            hideModal() {
                this.$refs.modal.hide();
            },

            removeKid(kidToRemove) {
                this.kids = this.kids.filter(kid => kid.id !== kidToRemove.id);
            },
        },
    };
</script>

<style lang="scss" scoped>
    .PhotoEditModal__kid-list {
        @apply tw-grid lg:tw-grid-cols-5 md:tw-grid-cols-4 sm:tw-grid-cols-3 tw-grid-cols-2 tw-gap-3;
    }

    .PhotoEditModal__list-item {
        @apply tw-relative;
    }

    .PhotoEditModal__unlink-button {
        @apply tw-absolute tw--top-2 tw--right-2 group-hover:tw-opacity-100 tw-opacity-0;
    }
</style>
