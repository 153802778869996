<template>
    <div>
        <CForm>
            <CFormTwoColumns>
                <CFormGroup>
                    <CLabel>
                        {{ __('common:what_do_you_like_to_update') }}
                    </CLabel>
                    <CFormSelect
                        multi
                        :options="typeOptions"
                        :value="selectedTypes"
                        @input="switchTypes"
                    />
                    <div
                        v-if="selectedTypes.includes('bookings')"
                        class="tw-text-sm tw-text-gray-500 tw-mt-2"
                    >
                        <FontAwesomeIcon
                            class="tw-mr-0.5"
                            icon="fad fa-info-circle"
                        />
                        {{ __('family_contract:update_occasionals_price_info') }}
                    </div>
                </CFormGroup>
                <CFormGroup v-if="selectedTypes.includes('contracts')">
                    <CLabel>
                        {{ __('common:configuration_to_apply') }}
                    </CLabel>
                    <CFormSelect
                        :options="configs"
                        text-path="name"
                        :value="selectedConfig.id"
                        value-path="id"
                        @input="switchConfig"
                    />
                </CFormGroup>
            </CFormTwoColumns>
        </CForm>

        <div v-if="selectedTypes.includes('contracts')">
            <hr class="hr">
            <div class="tw-flex tw-flex-wrap tw-items-center tw-justify-end tw-gap-2 tw-mb-4">
                <MButton @click="toggleAll(true)">
                    <template #left-icons>
                        <FontAwesomeIcon
                            class="tw-text-primary-500"
                            icon="fas fa-check-circle"
                        />
                    </template>
                    {{ __('common:actions.select_all') }}
                </MButton>
                <MButton @click="toggleAll(false)">
                    <template #left-icons>
                        <FontAwesomeIcon
                            class="tw-text-danger-500"
                            icon="fas fa-times-circle"
                        />
                    </template>
                    {{ __('common:actions.unselect_all') }}
                </MButton>
            </div>

            <div class="tw-flex tw-flex-col tw-gap-4">
                <MBox class="tw-p-4">
                    <MHeading class="tw-mb-4">
                        <FontAwesomeIcon
                            class="tw-text-primary-500 tw-mr-2"
                            icon="fas fa-sliders-v"
                        />
                        {{ __('family_contract:basic_settings') }}
                    </MHeading>
                    <div class="tw-flex tw-flex-wrap tw-gap-2">
                        <div
                            v-for="(setting, i) in filterSettings('basic')"
                            :key="'basicSetting' + i"
                            class="tw-rounded-lg tw-border tw-p-2 tw-cursor-pointer tw-transition-all tw-ease-in-out"
                            :class="setting.selected ? 'tw-bg-primary-500' : 'tw-bg-white'"
                            @click="toggleSetting(setting)"
                        >
                            <div class="tw-flex tw-flex-row tw-items-center tw-gap-1">
                                <MHeading
                                    level="h4"
                                    :variant="setting.selected ? 'white' : 'primary'"
                                >
                                    {{ setting.name }}
                                </MHeading>
                            </div>
                        </div>
                    </div>
                </MBox>
                <MBox class="tw-p-4">
                    <MHeading class="tw-mb-4">
                        <FontAwesomeIcon
                            class="tw-text-primary-500 tw-mr-2"
                            icon="fas fa-chart-bar"
                        />
                        {{ __('common:pricing_calculation_method') }}
                    </MHeading>
                    <div class="tw-flex tw-flex-wrap tw-gap-2">
                        <div
                            v-for="(setting, i) in filterSettings('price')"
                            :key="'basicSetting' + i"
                            class="tw-rounded-lg tw-border tw-p-2 tw-cursor-pointer tw-transition-all tw-ease-in-out"
                            :class="setting.selected ? 'tw-bg-primary-500' : 'tw-bg-white'"
                            @click="toggleSetting(setting)"
                        >
                            <div class="tw-flex tw-flex-row tw-items-center tw-gap-1">
                                <MHeading
                                    level="h4"
                                    :variant="setting.selected ? 'white' : 'primary'"
                                >
                                    {{ setting.name }}
                                </MHeading>
                            </div>
                        </div>
                    </div>
                </MBox>
                <MBox class="tw-p-4">
                    <MHeading class="tw-mb-4">
                        <FontAwesomeIcon
                            class="tw-text-primary-500 tw-mr-2"
                            icon="fas fa-money-bill"
                        />
                        {{ __('common:contract_related_fess') }}
                    </MHeading>
                    <div class="tw-flex tw-flex-wrap tw-gap-2">
                        <div
                            v-for="(setting, i) in filterSettings('fees')"
                            :key="'basicSetting' + i"
                            class="tw-rounded-lg tw-border tw-p-2 tw-cursor-pointer tw-transition-all tw-ease-in-out"
                            :class="setting.selected ? 'tw-bg-primary-500' : 'tw-bg-white'"
                            @click="toggleSetting(setting)"
                        >
                            <div class="tw-flex tw-flex-row tw-items-center tw-gap-1">
                                <MHeading
                                    level="h4"
                                    :variant="setting.selected ? 'white' : 'primary'"
                                >
                                    {{ setting.name }}
                                </MHeading>
                            </div>
                        </div>
                    </div>
                </MBox>
                <MBox class="tw-p-4">
                    <MHeading class="tw-mb-4">
                        <FontAwesomeIcon
                            class="tw-text-primary-500 tw-mr-2"
                            icon="fas fa-calendar-plus"
                        />
                        {{ __('common:additional_fees') }}
                    </MHeading>
                    <div class="tw-flex tw-flex-wrap tw-gap-2">
                        <div
                            v-for="(setting, i) in filterSettings('extra')"
                            :key="'basicSetting' + i"
                            class="tw-rounded-lg tw-border tw-p-2 tw-cursor-pointer tw-transition-all tw-ease-in-out"
                            :class="setting.selected ? 'tw-bg-primary-500' : 'tw-bg-white'"
                            @click="toggleSetting(setting)"
                        >
                            <div class="tw-flex tw-flex-row tw-items-center tw-gap-1">
                                <MHeading
                                    level="h4"
                                    :variant="setting.selected ? 'white' : 'primary'"
                                >
                                    {{ setting.name }}
                                </MHeading>
                            </div>
                        </div>
                    </div>
                </MBox>
            </div>
        </div>
        <MHeading
            v-else
            class="tw-py-8 tw-text-center"
        >
            {{ __('family_contract:they_will_be_no_change_to_contracts') }}
        </MHeading>
    </div>
</template>

<script>
    import Vue from 'vue';
    import _cloneDeep from 'lodash-es/cloneDeep';
    import _includes from 'lodash-es/includes';
    import __ from '@/modules/app/utils/i18n-facade';

    export default {
        props: {
            nursery: {
                type: Object,
                required: true,
            },
            configs: {
                type: Array,
                required: true,
            },
            selectedConfig: {
                type: Object,
                required: true,
            },
            selectedSettings: {
                type: Array,
                required: true,
            },
            selectedTypes: {
                type: Array,
                required: true,
            },
        },
        data: () => ({
            settings: [{
                name: __('family_contract:billing_cycle'),
                value: 'billing',
                type: 'basic',
                hide_when: [],
            }, {
                name: __('family_contract:smoothing'),
                value: 'smoothing',
                type: 'basic',
                hide_when: [],
            }, {
                name: __('family_contract:invoice_line'),
                value: 'line_name',
                type: 'basic',
                hide_when: [],
            }, {
                name: __('family_contract:terms_condition'),
                value: 'terms',
                type: 'basic',
                hide_when: [],
            }, {
                name: __('family_contract:paje_certificate'),
                value: 'cmg_certif',
                type: 'basic',
                hide_when: [],
            }, {
                name: __('family_contract:tranche_table'),
                value: 'brackets',
                type: 'price',
                hide_when: [],
            }, {
                name: __('family_contract:price_table'),
                value: 'pricing',
                type: 'price',
                hide_when: [],
            }, {
                name: __('family_contract:cmg_table'),
                value: 'cmg',
                type: 'price',
                hide_when: [],
            }, {
                name: __('family_contract:effort_rate'),
                value: 'effort_rate',
                type: 'price',
                hide_when: [],
            }, {
                name: __('family_contract:rounding'),
                value: 'precision',
                type: 'price',
                hide_when: [],
            }, {
                name: __('family_contract:tax_credit'),
                value: 'tax_credit',
                type: 'price',
                hide_when: [],
            }, {
                name: __('family_contract:fees'),
                value: 'fees',
                type: 'fees',
                hide_when: [],
            }, {
                name: __('common:deposit_one'),
                value: 'deposit',
                type: 'fees',
                hide_when: [],
            }, {
                name: __('common:registration_fees'),
                value: 'registration',
                type: 'fees',
                hide_when: [],
            }, {
                name: __('common:overrun_other'),
                value: 'overruns',
                type: 'extra',
                hide_when: [],
            }, {
                name: __('common:occasional_other'),
                value: 'occasionals',
                type: 'extra',
                hide_when: [],
            }, {
                name: __('common:adaptation_other'),
                value: 'adaptations',
                type: 'extra',
                hide_when: [],
            }, {
                name: __('common:absence_other'),
                value: 'absences',
                type: 'extra',
                hide_when: [],
            }],
            typeOptions: [
                {
                    text: __('common:contract_other'),
                    value: 'contracts',
                }, {
                    text: __('common:invoice_other'),
                    value: 'invoices',
                },
                {
                    text: __('common:booking_other'),
                    value: 'bookings',
                },
            ],
        }),
        watch: {
            selectedConfig: {
                handler() {
                    this.toggleAll(false);
                    this.settings.forEach(setting => {
                        setting.visible = true;
                        if (_includes(setting.hide_when, 'psu') && this.selectedConfig.funding === 'psu') {
                            setting.visible = false;
                        }
                    });
                },
                deep: true,
            },
        },
        mounted() {
            this.settings.forEach(setting => {
                Vue.set(setting, 'selected', false);
                Vue.set(setting, 'visible', true);
            });
        },
        methods: {
            filterSettings(type) {
                return this.settings.filter(item => item.type === type && item.visible);
            },

            toggleAll(selected) {
                this.settings.forEach(setting => {
                    Vue.set(setting, 'selected', selected);
                    selected ? this.addToSelectedSettings(setting) : this.removeFromSelectedSettings(setting);
                });
            },

            toggleSetting(setting) {
                if (setting.selected) {
                    Vue.set(setting, 'selected', false);
                    this.removeFromSelectedSettings(setting);
                } else {
                    Vue.set(setting, 'selected', true);
                    this.addToSelectedSettings(setting);
                }
            },

            addToSelectedSettings(setting) {
                const alreadySelected = this.selectedSettings.find(item => item === setting.value);
                !alreadySelected ? this.selectedSettings.push(setting.value) : null;
            },

            removeFromSelectedSettings(setting) {
                const settingToRemove = this.selectedSettings.find(item => item === setting.value);
                if (settingToRemove) {
                    const index = this.selectedSettings.indexOf(settingToRemove);
                    this.selectedSettings.splice(index, 1);
                }
            },

            switchConfig(id) {
                const newConfig = this.configs.find(item => item.id === id);
                if (newConfig) {
                    this.$emit('update:selectedConfig', _cloneDeep(newConfig));
                }
            },

            switchTypes(types) {
                this.$emit('update:selectedTypes', types);
            },
        },
    };
</script>

<style
    lang="scss"
    scoped
>
    .spacing {
        letter-spacing: .04em;
    }
</style>
