<script lang="ts" setup>
    const props = defineProps<{
        brackets: {
            charged_from: Array<{ value: number }>;
            charged_hours: Array<{ value: number }>;
        };
        nursery: Object;
    }>();

    function addHour() {
        props.brackets.charged_from.push({value: 10});
        props.brackets.charged_hours.push({value: 10});
    }

    function subHour() {
        props.brackets.charged_from.splice(props.brackets.charged_from.length - 1, 1);
        props.brackets.charged_hours.splice(props.brackets.charged_hours.length - 1, 1);
    }
</script>

<template>
    <div class="table-responsive">
        <table class="table table-sm">
            <thead>
                <tr>
                    <th scope="col">
                        {{ __('common:date_format.human_to_time') }}
                    </th>
                    <th
                        v-for="(hour, i) in brackets.charged_from"
                        :key="'hour ' + i"
                        scope="col"
                        style="min-width: 120px"
                    >
                        <CInputGroup class="tw-w-full tw-font-normal">
                            <CInputAddon>
                                ≤
                            </CInputAddon>
                            <MInput
                                v-model.number="hour.value"
                                class="tw-min-w-12"
                                placeholder="15"
                            />
                            <CInputAddon>
                                {{ __('common:minimum_short') }}
                            </CInputAddon>
                        </CInputGroup>
                    </th>
                    <th
                        class="text-nowrap text-right"
                        scope="col"
                    >
                        <MButton
                            class="mr-1"
                            variant="success"
                            @click="addHour"
                        >
                            <FontAwesomeIcon icon="fa fa-plus-circle"/>
                        </MButton>
                        <MButton
                            v-if="brackets.charged_from.length > 2"
                            variant="danger"
                            @click="subHour"
                        >
                            <FontAwesomeIcon icon="fa fa-times-circle"/>
                        </MButton>
                    </th>
                </tr>
            </thead>
            <tbody>
                <th scope="row">
                    {{ __('billing_setting:bill') }}
                </th>
                <th
                    v-for="(hour, i) in brackets.charged_hours"
                    :key="'hours' + i"
                    style="min-width: 120px"
                >
                    <CInputGroup class="tw-w-full tw-font-normal">
                        <MInput
                            v-model.number="hour.value"
                            class="tw-min-w-12"
                            placeholder="9"
                        />
                        <CInputAddon>
                            {{ __('common:minimum_short') }}
                        </CInputAddon>
                    </CInputGroup>
                </th>
            </tbody>
        </table>
    </div>
</template>

<style lang="scss" scoped>
    th {
        vertical-align: middle !important;
    }
</style>
