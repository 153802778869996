<template>
    <SimplePageTemplate @click="hidePopovers">
        <template #title>
            <MPageTitle variant="billing">
                {{ __('app:menu.invoices') }}
            </MPageTitle>
        </template>
        <template #actions>
            <MMenu v-if="$can('update', 'invoices')">
                <MMenuButton>
                    <MButton
                        :label="__('billing_invoice:batch_processing')"
                        variant="primary"
                    >
                        <template #left-icons>
                            <FontAwesomeIcon icon="fa fa-copy"/>
                        </template>
                    </MButton>
                </MMenuButton>
                <MMenuItems>
                    <MMenuItem
                        :label="__('billing_invoice:lock_invoices')"
                        @click="convertInvoiceModal"
                    >
                        <template #icon>
                            <FontAwesomeIcon
                                fixed-width
                                icon="fas fa-lock-alt"
                            />
                        </template>
                    </MMenuItem>
                    <MMenuItem
                        :label="__('billing_invoice:send_invoices')"
                        @click="sendInvoiceModal"
                    >
                        <template #icon>
                            <FontAwesomeIcon
                                fixed-width
                                icon="fas fa-paper-plane"
                            />
                        </template>
                    </MMenuItem>
                    <MMenuItem
                        :label="__('billing_invoice:relaunch_invoices')"
                        @click="sendRemindersModal"
                    >
                        <template #icon>
                            <FontAwesomeIcon
                                fixed-width
                                icon="fas fa-alarm-exclamation"
                            />
                        </template>
                    </MMenuItem>
                    <MMenuDivider/>
                    <MMenuItem
                        :label="__('billing_invoice:mandates_sepa')"
                        @click="sepaInvoiceModal"
                    >
                        <template #icon>
                            <FontAwesomeIcon
                                fixed-width
                                icon="fas fa-university"
                            />
                        </template>
                    </MMenuItem>
                    <MMenuItem
                        v-if="$showCAF(nursery.country)"
                        :label="__('billing_invoice:caf_certificate_other')"
                        @click="sendCAFModal"
                    >
                        <template #icon>
                            <FontAwesomeIcon
                                fixed-width
                                icon="fas fa-file-check"
                            />
                        </template>
                    </MMenuItem>
                </MMenuItems>
            </MMenu>
            <router-link
                v-if="$can('read', 'invoices')"
                :to="{name:'invoices.index',params:{nursery:nursery.id}, query: $route.query}"
            >
                <MButton>
                    <template #left-icons>
                        <FontAwesomeIcon icon="fa fa-list"/>
                    </template>
                    {{ __('common:list') }}
                </MButton>
            </router-link>
            <MSettingsButton
                v-if="$can('settings', 'invoices')"
                :to="{name:'settings.billings.rules',params:{nursery:nursery.id}}"
                :tooltip="__('billing_invoice:invoices_settings')"
            />
        </template>
        <template #content>
            <div class="tw-flex tw-flex-wrap tw-mb-4">
                <div>
                    <CButton
                        class="tw-mr-2"
                        @click="$refs.accountTypeDropdown.toggle()"
                    >
                        <span v-if="person === null">{{ __('billing_invoice:all_account') }}</span>
                        <span v-if="person === 'natural'">
                            <i class="fa fa-user tw-mr-1"/>{{ __('billing_invoice:phyisical_entities') }}
                        </span>
                        <span v-if="person === 'legal'">
                            <i class="fad fa-city fa-swap-opacity tw-mr-1"/>{{ __('billing_invoice:legal_entities') }}
                        </span>
                        <i class="fa fa-caret-down tw-ml-1"/>
                    </CButton>
                    <CDropdown
                        ref="accountTypeDropdown"
                        legacy-mode
                    >
                        <CList>
                            <CListRow
                                :clickable="true"
                                @click="person = null; $refs.accountTypeDropdown.hide()"
                            >
                                {{ __('billing_invoice:all_account') }}
                            </CListRow>
                            <CListRow
                                :clickable="true"
                                @click="person = 'natural'; $refs.accountTypeDropdown.hide()"
                            >
                                <i class="fa fa-user tw-w-6 tw-mr-1 tw-text-center tw-text-gray-600"/>{{ __('billing_invoice:phyisical_entities') }}
                            </CListRow>
                            <CListRow
                                :clickable="true"
                                @click="person = 'legal'; $refs.accountTypeDropdown.hide()"
                            >
                                <i class="fad fa-city fa-swap-opacity tw-w-6 tw-mr-1 tw-text-center tw-text-gray-600"/>{{ __('billing_invoice:legal_entities') }}
                            </CListRow>
                        </CList>
                    </CDropdown>
                </div>

                <FilterInvoiceStatus v-model="selectedStatus"/>
            </div>

            <div class="card card-3d">
                <div class="card-body">
                    <div class="row align-items-center">
                        <div class="col-6 col-sm-4 order-2 order-sm-1 text-left">
                            <button
                                class="btn btn-success btn-sm text-capitalize"
                                @click="goPrevMonth"
                            >
                                <i class="fa fa-angle-left mr-1"/>
                                {{ currentMonth.clone().subtract(1, 'months').format('MMMM Y') }}
                            </button>
                        </div>
                        <div class="col-12 col-sm-4 order-1 order-sm-2 text-center">
                            <MHeading class="tw-capitalize">
                                {{ currentMonth.clone().format('MMMM Y') }}
                            </MHeading>
                        </div>
                        <div class="col-6 col-sm-4 order-3 order-sm-3 text-right">
                            <button
                                class="btn btn-success btn-sm text-capitalize"
                                @click="goNextMonth"
                            >
                                {{ currentMonth.clone().add(1, 'months').format('MMMM Y') }}
                                <i class="fa fa-angle-right ml-1"/>
                            </button>
                        </div>
                    </div>
                    <hr class="hr">
                    <transition
                        mode="out-in"
                        name="transition-fade"
                    >
                        <div v-if="!loading">
                            <div class="tw-flex tw-flex-wrap tw--ml-4">
                                <div
                                    v-for="(customer, i) in filteredCustomers"
                                    :key="'customer' + i"
                                    class="tw-w-full lg:tw-w-1/2 tw-mb-4"
                                >
                                    <div class="tw-pl-4 tw-flex tw-flex-col tw-gap-2">
                                        <router-link
                                            class="tw-text-sm tw-tracking-wide tw-uppercase tw-font-semibold tw-text-gray-500 hover:tw-text-orange-500"
                                            :to="{name:'cashier/customers.show.invoices',params:{nursery:nursery.id, customer:customer.id}}"
                                        >
                                            <span class="tw-text-sm tw-tracking-wide tw-uppercase tw-font-semibold">
                                                <i
                                                    v-if="customer.person === 'legal'"
                                                    class="fad fa-city fa-swap-opacity"
                                                />
                                                <i
                                                    v-else
                                                    class="fas fa-user"
                                                />
                                            </span>
                                            {{ customer.name }}
                                        </router-link>
                                        <div
                                            v-if="customer.invoices.length === 0"
                                            class="tw-flex tw-bg-white tw-rounded-lg tw-shadow tw-border tw-border-gray-200 tw-cursor-pointer hover:tw-bg-gray-200 tw-px-3 tw-py-4"
                                        >
                                            <router-link
                                                class="tw-text-center tw-text-gray-500 tw-text-sm tw-w-full tw-self-center"
                                                :to="{name:'cashier/customers.show.invoices',params:{nursery:nursery.id, customer:customer.id}}"
                                            >
                                                {{ __('billing_invoice:nothing_to_invoice') }}
                                            </router-link>
                                        </div>
                                        <InvoicesListItem
                                            v-for="(invoice, j) in customer.invoices"
                                            v-else
                                            :key="'customer' + i + '_invoice' + j"
                                            :customer="invoice.model.customer().value()"
                                            :invoice="invoice"
                                            :invoice-model="invoice.model"
                                            :nursery="nursery"
                                            @showInvoice="showInvoice(invoice, $event)"
                                            @updateInvoices="getCustomers"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div
                                v-if="total > perPage"
                                class="tw-flex tw-justify-center tw-mt-4"
                            >
                                <MPagination
                                    :paginator="paginator"
                                    @navigate="currentPage = $event"
                                />
                            </div>
                        </div>

                        <div
                            v-else
                            key="loader"
                        >
                            <loader custom-class="la-2x"/>
                        </div>
                    </transition>
                </div>
            </div>
        </template>
    </SimplePageTemplate>
</template>

<script>
    import Vue from 'vue';
    import moment from 'moment';
    import route from '@/modules/legacy/libs/ziggy';
    import useApi from '@/modules/app/composables/useApi';
    import _debounce from 'lodash-es/debounce';
    import _cloneDeep from 'lodash-es/cloneDeep';
    import _head from 'lodash-es/head';
    import _forEach from 'lodash-es/forEach';
    import {invoice} from '@/modules/legacy/mixins/invoice';
    import {nursery} from '@/modules/legacy/mixins/nursery';
    import {url} from '@/modules/legacy/mixins/url';
    import EditInvoice from '@/modules/cashier/components/billing/invoice/organisms/EditInvoice.vue';
    import InvoicesListItem from '@/modules/cashier/components/billing/invoice/molecules/InvoicesListItem.vue';
    import ActionsSend from '@/modules/cashier/components/billing/invoice/Actions/Send.vue';
    import ActionsSendReminders from '@/modules/cashier/components/billing/invoice/Actions/SendReminders.vue';
    import ActionsConvert from '@/modules/cashier/components/billing/invoice/Actions/Convert.vue';
    import ActionsCAF from '@/modules/cashier/components/billing/invoice/Actions/CAF.vue';
    import ActionsSEPA from '@/modules/cashier/components/billing/invoice/Actions/SEPA.vue';
    import FilterInvoiceStatus from '@/modules/cashier/components/billing/invoice/FilterInvoiceStatus.vue';
    import SimplePageTemplate from '@/modules/app/components/templates/SimplePageTemplate.vue';
    import useMetrics from '@/modules/app/composables/useRum';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';
    import OrganizationModel from '@/modules/organization/models/OrganizationModel';
    import useModal from '@/modules/app/composables/useModal';
    import MPagination from '@/modules/meeko-ui/components/MPagination.vue';
    import {LengthAwarePaginator} from '@meekohq/lumos';
    import InvoiceModel from '@/modules/cashier/models/InvoiceModel';

    export default {
        components: {
            MPagination,
            SimplePageTemplate,
            InvoicesListItem,
            FilterInvoiceStatus,
        },
        mixins: [invoice, nursery, url],
        props: {
            nursery: {
                type: OrganizationModel,
                required: true,
            },
            user: {
                type: Object,
                required: true,
            },
            sepaMandates: {
                type: Array,
                required: false,
                default: null,
            },
        },
        data: () => ({
            loading: false,
            customers: [],
            currentInvoice: {},
            currentPage: 1,
            total: 0,
            perPage: 20,
            person: localStorage.getItem('customers:index:person'),
            selectedStatus: [],
            currentMonth: moment(),
            paginator: undefined,
        }),
        computed: {
            moment() {
                return moment;
            },
            filteredCustomers() {
                return this.customers;
            },
            editInvoiceModal() {
                return useModal({
                    component: EditInvoice,
                    props: {
                        nursery: this.nursery,
                        user: this.user,
                        invoice: this.currentInvoice,
                        customer: this.customers.find(item => item.id === this.currentInvoice.customer_id),
                    },
                    listeners: () => ({
                        updateInvoices: this.getCustomers,
                    }),
                });
            },
        },
        watch: {
            currentPage() {
                this.pushQueryString('p', this.currentPage);
                this.getCustomers();
            },
            person(val) {
                localStorage.setItem('customers:index:person', val),
                (this.currentPage = 1);
                this.getCustomers();
            },
            selectedStatus() {
                this.currentPage = 1;
                this.getCustomers();
            },
            currentMonth: _debounce(function() {
                this.currentPage = 1;
                this.getCustomers();
            }, 500),
            paginator() {
                this.pushQueryString('p', this.paginator.currentPage());
            },
        },
        mounted() {
            if (this.$route.query.p) {
                this.currentPage = parseInt(this.$route.query.p);
            }

            if (localStorage.getItem('customers:index:person') === 'null') {
                this.person = null;
            }

            this.selectMonth(this.$route.query.from);

            this.getCustomers();

            useMetrics().addAction('M_Invoices_Display', {value: 'column'});
        },
        methods: {

            convertInvoiceModal() {
                useModal({
                    component: ActionsConvert,
                    props: {
                        nursery: this.nursery,
                        date: this.currentMonth,
                    },
                    listeners: modal => ({
                        updated: invoices => {
                            this.updateInvoices(invoices);
                            modal.hide();
                        },
                    }),
                }).show();
            },

            sendInvoiceModal() {
                useModal({
                    component: ActionsSend,
                    props: {
                        nursery: this.nursery,
                        date: this.currentMonth,
                    },
                    listeners: modal => ({
                        updated: invoices => {
                            this.updateInvoices(invoices);
                            modal.hide();
                        },
                    }),
                }).show();
            },

            sepaInvoiceModal() {
                useModal({
                    component: ActionsSEPA,
                    props: {
                        nursery: this.nursery,
                        date: this.currentMonth,
                        sepas: this.sepaMandates,
                    },
                    listeners: modal => ({
                        updated: invoices => {
                            this.updateInvoices(invoices);
                            modal.hide();
                        },
                    }),
                }).show();
            },

            sendRemindersModal() {
                useModal({
                    component: ActionsSendReminders,
                    props: {
                        nursery: this.nursery,
                        date: this.currentMonth,
                    },
                    listeners: modal => ({
                        updated: invoices => {
                            this.updateInvoices(invoices);
                            modal.hide();
                        },
                    }),
                }).show();
            },

            sendCAFModal() {
                useModal({
                    component: ActionsCAF,
                    props: {
                        nursery: this.nursery,
                        date: this.currentMonth,
                    },
                    listeners: modal => ({
                        updated: invoices => {
                            this.updateInvoices(invoices);
                            modal.hide();
                        },
                    }),
                }).show();
            },

            async getCustomersInvoicesModels(customers) {
                const invoices = customers.reduce(
                    (acc, customer) => acc.concat(customer.invoices),
                    [],
                );

                if (!invoices.length) {
                    this.invoicesModels = [];
                    this.invoices = [];

                    return;
                }

                this.invoicesModels = (await InvoiceModel.query()
                    .with(new InvoiceModel().tags())
                    .with(new InvoiceModel().payments())
                    .with(new InvoiceModel().customer())
                    .with(new InvoiceModel().refunds())
                    .whereIn('id', invoices.map(invoice => invoice.id))
                    .get()
                ).all();

                _forEach(customers, customer => {
                    customer.invoices = customer.invoices.map(invoice => {
                        const invoiceModel = this.invoicesModels.find(
                            item => item.id === invoice.id,
                        );

                        return {
                            ...invoice,
                            model: invoiceModel,
                        };
                    });
                });

                this.customers = customers;
            },

            getCustomers() {
                this.loading = true;

                useApi().legacy.get(
                    route('nurseries.customers.billing', {
                        nurseries: this.nursery.id,
                    }),
                    {
                        params: {
                            person: this.person,
                            page: this.currentPage,
                            per_page: this.perPage,
                            from: moment(this.currentMonth)
                                .startOf('month')
                                .format('YYYY-MM-DD'),
                            to: moment(this.currentMonth)
                                .endOf('month')
                                .format('YYYY-MM-DD'),
                            status: this.selectedStatus,
                        },
                    },
                )
                    .then(async response => {
                        this.total = response.data.total;
                        this.paginator = new LengthAwarePaginator(
                            response.data.data,
                            parseInt(response.data.total),
                            parseInt(response.data.per_page),
                            parseInt(response.data.current_page),
                            parseInt(response.data.last_page),
                        );

                        await this.getCustomersInvoicesModels(response.data.data);

                        this.loading = false;
                    })
                    .catch(error => {
                        this.loading = false;
                        if (
                            error &&
                            error.response &&
                            error.response.status === 422
                        ) {
                            _forEach(error.response.data.errors, function(value) {
                                useNotification().error(_head(value));
                            });
                        } else {
                            useNotification().error(error);
                        }
                    });
            },

            showInvoice(invoice, scrollToPaymentAllocations = false) {
                this.currentInvoice = _cloneDeep(invoice);
                this.editInvoiceModal.modal.value.props.scrollToPaymentAllocations = scrollToPaymentAllocations;
                this.editInvoiceModal.show();
            },

            updateInvoices(invoices, updateAll) {
                invoices.forEach(invoice => {
                    const customer = this.customers.find(
                        item => item.id === invoice.customer_id,
                    );

                    if (customer) {
                        const invoiceToUpdate = customer.invoices.find(
                            item => item.id === invoice.id,
                        );

                        if (invoiceToUpdate) {
                            if (updateAll) {
                                const invoiceIndex = customer.invoices.indexOf(invoiceToUpdate);
                                if (
                                    moment(invoice.date).isSame(
                                        this.currentMonth,
                                        'month',
                                    )
                                ) {
                                    Vue.set(customer.invoices, invoiceIndex, invoice);
                                } else {
                                    customer.invoices.splice(invoiceIndex, 1);
                                }
                            } else {
                                invoiceToUpdate.no = invoice.no;
                                invoiceToUpdate.sent_at = invoice.sent_at;
                                invoiceToUpdate.dunning_dates =
                                    invoice.dunning_dates;
                                invoiceToUpdate.caf_sent_at = invoice.caf_sent_at;
                                invoiceToUpdate.sepa_done_at = invoice.sepa_done_at;
                                invoiceToUpdate.name = invoice.name;
                                invoiceToUpdate.status = invoice.status;
                            }
                        }

                        this.customers = this.customers.map(item => {
                            if (item.id === customer.id) {
                                return customer;
                            }

                            return item;
                        });
                    }
                });
            },

            selectMonth(from) {
                this.currentMonth = from ? moment.unix(from) : moment();
            },

            hidePopovers() {
                this.$root.$emit('bv::hide::popover');
            },
            goNextMonth() {
                this.currentMonth = this.currentMonth.clone().add(1, 'months');
                this.pushQueryString('from', moment(this.currentMonth).startOf('month').unix());
            },
            goPrevMonth() {
                this.currentMonth = this.currentMonth.clone().subtract(1, 'months');
                this.pushQueryString('from', moment(this.currentMonth).startOf('month').unix());
            },
        },
    };
</script>
