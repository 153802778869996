<template>
    <div
        class="position-relative range-bg tw-rounded"
        :class="{'small': small}"
    >
        <div
            v-if="loading"
            class="progress h-100"
        >
            <div
                class="progress-bar progress-bar-striped progress-bar-animated tw-w-full !tw-bg-gray-400"
                role="progressbar"
            />
        </div>
        <MTooltip
            v-for="(range, i) in summary"
            v-else
            :key="'range' + i"
            class="align-items-center d-flex justify-content-center range"
            :label="__('common:kid_with_count', {count: range.kids ?? 0})"
            placement="top"
            :style="style(range)"
        >
            {{ range.kids ? range.kids : 0 }}
        </MTooltip>
    </div>
</template>

<script>
    import _minBy from 'lodash-es/minBy';
    import _maxBy from 'lodash-es/maxBy';
    import moment from 'moment';

    export default {
        props: {
            nursery: {},
            opening: {},
            closing: {},
            day: {},
            summary: {},
            small: {
                type: Boolean,
                default: false,
            },
            loading: {
                type: Boolean,
                default: false,
            },
        },
        computed: {
            maxKids() {
                if (_maxBy(this.summary, 'kids')) {
                    return _maxBy(this.summary, 'kids').kids;
                }

                return 0;
            },

            minKids() {
                if (_minBy(this.summary, 'kids')) {
                    return _minBy(this.summary, 'kids').kids;
                }

                return 0;
            },
        },
        methods: {
            unixToPercent(unix) {
                const openingTimestamp = this.day.clone().hours(moment.duration(this.opening).hours())
                    .minutes(moment.duration(this.opening).minutes())
                    .seconds(moment.duration(this.opening).seconds())
                    .unix();

                const closingTimestamp = this.day.clone().hours(moment.duration(this.closing).hours())
                    .minutes(moment.duration(this.closing).minutes())
                    .seconds(moment.duration(this.closing).seconds())
                    .unix();

                const percent = ((unix - openingTimestamp) * 100) / (closingTimestamp - openingTimestamp);

                if (percent > 100) {
                    return 100;
                } else if (percent < 0) {
                    return 0;
                }

                return percent;
            },
            style(range) {
                const startedAt = range.started_at;
                const endedAt = range.ended_at;

                const opacity = range.kids
                    ? ((range.kids * 100) / this.maxKids) / 100
                    : 0.12;

                let tooManyKids = false;
                if (this.nursery) {
                    const nbPercent = Math.ceil(this.nursery.places * this.nursery.occ_max_rate / 100);
                    tooManyKids = (range.kids > nbPercent);
                }

                return {
                    'left': this.unixToPercent(startedAt).toFixed(2) + '%',
                    'right': (100 - this.unixToPercent(endedAt)).toFixed(2) + '%',
                    'background-color': tooManyKids ? '#ff4b4a' : `rgba(0,122,255,${opacity})`,
                };
            },
        },
    };
</script>

<style lang="scss" scoped>
    .range {
        height: 100%;
        position: absolute;
        background-color: #007aff;
        color: white;
        text-shadow: 0 1px 1px rgba(20, 117, 236, .5);
        font-weight: 600;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        -webkit-print-color-adjust: exact !important; /* Chrome, Safari */
        print-color-adjust: exact !important; /*Firefox*/

        //border: 1px solid white;
        //margin-right: 1px;
    }

    .range-bg {
        height: 30px;
        font-size: 14px;
        background-color: rgba(#007aff, .1);
        overflow: hidden;

        -webkit-print-color-adjust: exact !important; /* Chrome, Safari */
        print-color-adjust: exact !important; /*Firefox*/

        &.small {
            height: 20px;
            font-size: 13px;
        }
    }
</style>
