<script setup lang="ts">
    import __ from '@/modules/app/utils/i18n-facade';
    import type {ModalType} from '@/modules/app/composables/useModal';
    import type {
        GeneratePersonalAccessTokenResponse,
    } from '@/modules/auth/personal-access-token/application/GeneratePersonalAccessTokenResponse';
    import useError from '@/modules/app/composables/useError';
    import {ref} from 'vue';
    import {useSudoMode} from '@/modules/auth/sudo-mode/infrastructure/components/useSudoMode';
    import {app, catcher, ValidationError} from '@meekohq/lumos';
    import type {
        GeneratePersonalAccessTokenUseCase,
    } from '@/modules/auth/personal-access-token/application/GeneratePersonalAccessTokenUseCase';
    import {Types} from '@/types';
    import {
        GeneratePersonalAccessTokenRequest,
    } from '@/modules/auth/personal-access-token/application/GeneratePersonalAccessTokenRequest';
    import {ConsumerType} from '@/modules/auth/personal-access-token/domain/ConsumerType';

    defineProps<{ modal: ModalType}>();

    const emit = defineEmits<{
        (e: 'created', resonse: GeneratePersonalAccessTokenResponse): void,
    }>();

    const errorHandler = useError();

    const isCreating = ref(false);
    const keyName = ref('');

    async function createPersonalAccessToken() {
        const sudoToken = await useSudoMode().acquireSudoMode();
        if (sudoToken.isDismissed) {
            return;
        }

        isCreating.value = true;
        errorHandler.reset();

        try {
            const response = await app().make<GeneratePersonalAccessTokenUseCase>(Types.GeneratePersonalAccessTokenUseCase).generate(
                GeneratePersonalAccessTokenRequest.make({
                    tokenName: keyName.value,
                    consumer: ConsumerType.third,
                    sudoToken: sudoToken.token,
                }),
            );

            emit('created', response);
        } catch (err) {
            catcher()
                .on(ValidationError, validationError => {
                    errorHandler.addValidationError(validationError);
                })
                .catch(err);
        } finally {
            isCreating.value = false;
        }
    }
</script>

<template>
    <MModal
        :header-title="__('user:add_key')"
        :modal="modal"
    >
        <CForm>
            <MInputGroup
                v-model="keyName"
                :errors="errorHandler.get('token_name')"
                :label="__('common:last_name') + '*'"
                :placeholder="__('common:last_name')"
            />
        </CForm>
        <template #footer-end="{hide}">
            <MButton
                :label="__('common:actions.cancel')"
                @click="hide"
            />
            <MButton
                :label="__('common:actions.add')"
                :loading="isCreating"
                variant="primary"
                @click="createPersonalAccessToken"
            />
        </template>
    </MModal>
</template>
