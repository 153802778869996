<template>
    <CModal
        ref="modalEl"
        :header-title="__('family_member:add_family_member')"
        size="md"
    >
        <SelectMember
            button-class="tw-w-full"
            :create-option-enabled="false"
            :has-unselect="false"
            :organization-model="organizationModel"
            @change="onMemberSelected($event)"
        >
            <template #button>
                {{ __('family_member:add_existing_parent') }}
            </template>
        </SelectMember>
        <MemberForm
            ref="formEl"
            class="tw-mt-4"
            :family-model="familyModel"
            :organization-model="organizationModel"
            @loading="loading = $event"
            @saved="onMemberCreated"
        />
        <template #footer>
            <CButton
                class="tw-ml-auto"
                @click="modalEl?.hide()"
            >
                {{ __('common:actions.close') }}
            </CButton>
            <CButton
                class="tw-ml-2"
                :loading="loading || loadingAttachPivot"
                variant="primary"
                @click="formEl?.save()"
            >
                {{ __('common:actions.save') }}
            </CButton>
        </template>
    </CModal>
</template>

<script setup lang="ts">
    import type OrganizationModel from '@/modules/organization/models/OrganizationModel';
    import type FamilyModel from '@/modules/family/models/FamilyModel';
    import type MemberModel from '@/modules/family/models/MemberModel';
    import MemberForm from '@/modules/family/components/member/MemberForm.vue';
    import SelectMember from '@/modules/family/components/member/SelectMember.vue';
    import type {PropType} from 'vue';
    import {ref} from 'vue';
    import FamilyMemberPivot from '@/modules/family/models/FamilyMemberPivot';

    const props = defineProps({
        organizationModel: {
            type: Object as PropType<OrganizationModel>,
            required: true,
        },
        familyModel: {
            type: Object as PropType<FamilyModel>,
            required: true,
        },
    });

    const emit = defineEmits<{
        (e: 'selected', value: MemberModel): void
        (e: 'created', value: MemberModel): void
    }>();

    defineExpose({
        show,
        hide,
    });

    const modalEl = ref<{ show: () => void, hide: () => void }>();
    const formEl = ref<{ save: () => void }>();
    const loading = ref(false);
    const loadingAttachPivot = ref(false);

    function onMemberCreated(memberModel: MemberModel) {
        hide();

        emit('created', memberModel);
    }

    async function onMemberSelected(memberModel: MemberModel) {
        loadingAttachPivot.value = true;

        // Attach to family
        const familyPivot = new FamilyMemberPivot();
        familyPivot.attributes.account_id = memberModel.attributes.account_id;
        familyPivot.attributes.member_id = memberModel.getKey();
        familyPivot.attributes.family_id = props.familyModel.getKey();
        await familyPivot.save();
        loadingAttachPivot.value = false;
        hide();

        emit('selected', memberModel);
    }

    function show() {
        modalEl.value?.show();
    }

    function hide() {
        modalEl.value?.hide();
    }
</script>

