import {computed, onMounted, onUnmounted, ref} from 'vue';
import moment from 'moment';
import _cloneDeep from 'lodash-es/cloneDeep';
import {unixToPercent} from '@/modules/legacy/libs/planning/planning';
import {EventBus} from '@/modules/legacy/utils/bus';
import type EventModel from '@/modules/human-resources/models/EventModel';
import type TemplateEventModel from '@/modules/human-resources/models/TemplateEventModel';
import {useIntervalFn} from '@vueuse/core';

export default function(day, opening, closing) {
    const latestEvent = ref<EventModel | TemplateEventModel | null>(null);
    onMounted(() => {
        EventBus.$on('calendar:staff:event:copy', (clickedEvent: EventModel | TemplateEventModel) => {
            latestEvent.value = _cloneDeep(clickedEvent);
        });
        EventBus.$on('calendar:staff:event:clearCopy', () => {
            latestEvent.value = null;
        });
    });

    onUnmounted(() => {
        EventBus.$off('calendar:staff:event:copy');
        EventBus.$off('calendar:staff:event:clearCopy');
    });

    const now = ref(moment().unix());
    useIntervalFn(() => {
        now.value = moment().unix();
    }, 1000 * 60);

    const clockEnabled = computed(() => {
        return (
            unixToPercent(now.value, day, opening, closing) >= 0 &&
            unixToPercent(now.value, day, opening, closing) <= 100
        );
    });

    function outOfDay(startedAt: number, endedAt: number) {
        const percentStartedAt = unixToPercent(
            startedAt,
            day,
            opening,
            closing,
            true,
        );
        const percentEndedAt = unixToPercent(
            endedAt,
            day,
            opening,
            closing,
            true,
        );

        return percentStartedAt > 100 || percentEndedAt < 0;
    }

    return {
        latestEvent,
        clockEnabled,
        outOfDay,
    };
}
