<template>
    <div class="RoomItem">
        <div class="RoomItem__header">
            <div class="RoomItem__icon-container">
                <FontAwesomeIcon icon="fas fa-moon-stars"/>
            </div>
            <MListItemTitle
                class="tw-truncate"
                :title="room.name"
            />
        </div>
        <CDropdownMenu v-if="$can('update', 'rooms') || (room.id && $can('delete', 'rooms'))">
            <template #button>
                <CButton icon-ellipsis/>
            </template>
            <CDropdownMenuItem
                v-if="$can('update', 'rooms')"
                icon="fa fa-pen"
                @click="$emit('edit')"
            >
                {{ __('common:actions.update') }}
            </CDropdownMenuItem>
            <CDropdownMenuItem
                v-if="room.id && $can('delete', 'rooms')"
                icon="fa fa-trash"
                variant="danger"
                @click="$emit('delete')"
            >
                {{ __('common:actions.delete_dots') }}
            </CDropdownMenuItem>
        </CDropdownMenu>
    </div>
</template>

<script lang="ts">
    import {defineComponent} from 'vue';
    import MListItemTitle from '@/modules/meeko-ui/components/MListItemTitle.vue';

    export default defineComponent({
        components: {
            MListItemTitle,
        },
        props: {
            room: {
                type: Object,
                required: true,
            },
        },
    });
</script>

<style lang="scss">
    .RoomItem {
        @apply tw-w-full tw-flex tw-items-center tw-justify-between tw-gap-6;
    }

    .RoomItem__header {
        @apply tw-flex tw-items-center tw-gap-2 tw-truncate;
    }

    .RoomItem__icon-container {
        @apply tw-w-12 tw-h-12;
        @apply tw-rounded-full tw-bg-blue-100 tw-text-2xl;
        @apply tw-flex tw-items-center tw-justify-center tw-shrink-0;
    }
</style>
