import {Container, ServiceProvider} from '@meekohq/lumos';
import Vue from 'vue';
import PortalVue from 'portal-vue';
import i18next from 'i18next';
import I18NextVue from 'i18next-vue';
import Toasted from 'vue-toasted';
import VueTextareaAutosize from 'vue-textarea-autosize';
import router from '@/router';
import App from '@/App.vue';
import authMiddleware from '@/modules/auth/middleware/authMiddleware';
import migrateToUuidMiddleware from '@/modules/app/middleware/migrateToUuidMiddleware';
import __ from '@/modules/app/utils/i18n-facade';
import subscribeToMeekoMiddleware from '@/modules/subscription/middleware/subscribeToMeekoMiddleware';
import authorizationMiddleware from '@/modules/app/middleware/authorizationMiddleware';
import useAbility from '@/modules/app/composables/useAbility';
import '@/modules/legacy/directives/float';
import '@/modules/legacy/directives/focus';
import '@/modules/legacy/utils/bus';
import '@/modules/legacy/directives/click-outside';
import '@/modules/legacy/filters/filters';
import {capitalize, formatDate, fullName, refToDate, valueFromOptions} from '@/modules/legacy/filters/filters';

export default class VueServiceProvider extends ServiceProvider {
    public async boot() {
        Vue.use(PortalVue);
        Vue.use(VueTextareaAutosize);
        Vue.use(Toasted, {
            duration: 4000,
            theme: 'meeko',
            iconPack: 'custom-class',
            keepOnHover: true,
        });
        Vue.use(I18NextVue, {i18next});

        this.loadMixins();
        this.loadComponents();

        authMiddleware(router);
        migrateToUuidMiddleware(router);
        subscribeToMeekoMiddleware(router);
        authorizationMiddleware(router);

        // Mount Vue
        if (!this.app.isTest) {
            this.app.vmExtra = {router};
            this.app.vm = new Vue({
                el: '#app',
                router,
                render: h => h(App),
            });
        }
    }

    public register(): void {
        this.app.singleton(Container.contracts.reactivitySetFn).toCallback(() => {
            return Vue.set;
        });

        this.app.singleton(Container.contracts.reactivityDeleteFn).toCallback(() => {
            return Vue.delete;
        });
    }

    private loadMixins() {
        Vue.mixin({
            methods: {
                $formatDate: formatDate,
                $capitalize: capitalize,
                $refToDate: refToDate,
                $fullName: fullName,
                $valueFromOptions: valueFromOptions,
                $isRole(role, user, nursery) {
                    let isRole = false;
                    if (user && user.nurseries) {
                        const userNursery = user.nurseries.find(item => item.id === nursery.id);
                        if (userNursery) {
                            userNursery.role === role ? isRole = true : null;
                        }

                        return isRole;
                    }

                    return false;
                },
                $can(action, subject, userPermissions) {
                    const ability = useAbility(userPermissions);

                    switch (action) {
                        case 'any':
                            return ability.anyOfAll(subject);
                        case 'all':
                            return ability.all(subject);
                        default:
                            return ability.can(action, subject);
                    }
                },
                // eslint-disable-next-line @typescript-eslint/naming-convention
                __(key: string, options?: any) {
                    return __(key, options);
                },
            },
        });
    }

    private loadComponents() {
        Vue.component('CardListEditBtn', () => import('@/modules/legacy/components/OldThemes/Smooth/List/EditButton.vue'));
        Vue.component('CardListGoBtn', () => import('@/modules/legacy/components/OldThemes/Smooth/List/GoButton.vue'));
        Vue.component('FontAwesomeIcon', () => import('@/modules/app/components/atoms/FontAwesomeIcon.vue'));
        Vue.component('Loader', () => import('@/modules/legacy/components/Modules/Loader.vue'));
        Vue.component('List', () => import('@/modules/legacy/components/lists/List.vue'));
        Vue.component('ListItem', () => import('@/modules/legacy/components/lists/ListItem.vue'));
        Vue.component('ListWrapper', () => import('@/modules/legacy/components/lists/ListWrapper.vue'));
        Vue.component('MFormGroup', () => import('@/modules/legacy/components/OldThemes/Framework/Form/Group.vue'));
        Vue.component('MFormGroupInplaceInputs', () => import('@/modules/legacy/components/OldThemes/Framework/Form/GroupInPlaceInputs.vue'));
        Vue.component('MFormInplaceEditor', () => import('@/modules/legacy/components/OldThemes/Framework/Form/InPlaceEditor.vue'));
        Vue.component('MFormInput', () => import('@/modules/legacy/components/OldThemes/Framework/Form/Input.vue'));
        Vue.component('MFormTextarea', () => import('@/modules/legacy/components/OldThemes/Framework/Form/Textarea.vue'));
        Vue.component('MLayoutBox', () => import('@/modules/legacy/components/OldThemes/Framework/Layout/Box.vue'));
        Vue.component('MLayoutDivider', () => import('@/modules/legacy/components/OldThemes/Framework/Layout/Divider.vue'));
        Vue.component('PlanningGenerator', () => import('@/modules/legacy/components/Modules/PlanningGenerator/Line.vue'));
        Vue.component('ResourceFinderWrapper', () => import('@/modules/app/components/resource/templates/ResourceFinderWrapper.vue'));
        Vue.component('StepForm', () => import('@/modules/legacy/components/Modules/StepForm.vue'));
        Vue.component('SupportMeeko', () => import('@/modules/legacy/components/Modules/SupportMeeko.vue'));
        Vue.component('Tags', () => import('@/modules/tag/components/TagsLegacy.vue'));
    }
}
