<template>
    <AuthTemplate :show-meeko-logo="false">
        <template #title>
            {{ __('auth:having_trouve_connecting_ask') }}
        </template>
        <template #form>
            <EmailForm/>
        </template>
    </AuthTemplate>
</template>

<script lang="ts">
    import {defineComponent} from 'vue';
    import AuthTemplate from '@/modules/auth/components/templates/AuthTemplate.vue';
    import EmailForm from '@/modules/auth/components/organisms/EmailForm.vue';

    export default defineComponent({
        components: {AuthTemplate, EmailForm},
        props: {},
        setup() {
            return {};
        },
    });
</script>
