<template>
    <div class="tw-bg-black d-flex align-items-center flex-container">
        <div class="w-100">
            <div class="container-fluid">
                <div class="row">
                    <div
                        class="col-12 ml-auto mr-auto mt-5 mb-5"
                        style="max-width: 430px;"
                    >
                        <code
                            v-for="line in lines"
                            :class="{'error': line.includes('error')}"
                        >
                            > {{ line }}<br>
                        </code>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data: () => ({
            lines: [],
        }),
        computed: {
            token() {
                return this.$route.params.token;
            },
        },
        mounted() {
            this.lines.push('Saving token to localstorage...');
            window.localStorage.setItem('support-token', this.token);
            this.lines.push('Done.');
        },
    };
</script>

<style
    lang="scss"
    scoped
>
    code {
        color: theme('colors.pink.400');
        /*color: #3ee86d;*/

        &.error {
            color: #ffc000;
        }
    }
</style>
