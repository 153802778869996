<script setup lang="ts">
    import TransactionModalForm from '@/modules/cashier/transaction/infrastructure/components/TransactionModalForm.vue';
    import TransactionModalSubHeaderInvoice
        from '@/modules/cashier/transaction/infrastructure/components/TransactionModalSubHeaderInvoice.vue';
    import type {LegacyInvoiceType} from '@/modules/cashier/utils/billing/invoice/LegacyInvoiceType';
    import type {TransactionStateType} from '@/modules/cashier/composables/issuing/transaction/useTransactionState';
    import useManager from '@/modules/app/composables/useManager';
    import NumberFormatter from '@/modules/app/utils/NumberFormatter';
    import useTransactionAllocationsState
        from '@/modules/cashier/composables/issuing/transaction/useTransactionAllocationsState';
    import type PaymentModel from '@/modules/cashier/models/PaymentModel';
    import type CustomerModel from '@/modules/cashier/models/CustomerModel';
    import {computed, onMounted} from 'vue';
    import __ from '@/modules/app/utils/i18n-facade';

    const props = defineProps<{
        canSelectCustomer: boolean;
        invoice: LegacyInvoiceType;
        payment: PaymentModel;
        transactionState: TransactionStateType;
        customer?: CustomerModel;
    }>();

    const {activeOrganization} = useManager();

    const {
        transaction,
        sumOtherAllocations,
        currency,
        isOverAllocated,
        isInsufficientlyAllocated,
    } = props.transactionState;

    const formatter = new NumberFormatter({
        countryCode: activeOrganization.value.attributes.address.country_code,
        currencyCode: currency.value?.attributes.code,
    });

    const goingToBeDeleteInvoiceAllocation = computed(() => {
        return !!transaction.value.attributes.amount
            && transaction.value.attributes.amount === sumOtherAllocations.value;
    });

    const invoiceType = computed(() => {
        if (props.invoice) {
            return props.invoice.invoice_type;
        }

        return transaction.value.isCredit ? 'invoice' : 'credit_note';
    });

    const {
        prefillInvoiceAllocation,
        transactionRemainingAmount,
    } = useTransactionAllocationsState(props.transactionState, props.payment);

    // Prefill invoice allocation if the transaction is not yet created and the payment has a remaining amount
    if (!transaction.value.exists) {
        onMounted(async () => {
            if (props.payment && props.payment.computed.remaining_amount > 0) {
                await prefillInvoiceAllocation();
            }
        });
    }
</script>

<template>
    <CForm>
        <template v-if="invoice && customer">
            <CFormGroup>
                <CLabel>
                    {{ __('billing_transaction:for_invoice_colon') }}
                </CLabel>
                <TransactionModalSubHeaderInvoice
                    :customer="customer"
                    :invoice="invoice"
                />
            </CFormGroup>
            <CFormGroup class="tw-text-end">
                <MHeading level="h3">
                    {{ __('billing_invoice:outstanding_balance_amount_colon', {amount: formatter.parse(invoice.computed.remaining_amount).asString}) }}
                </MHeading>
            </CFormGroup>
            <hr class="hr tw-my-0">
        </template>
        <TransactionModalForm
            :can-select-customer="canSelectCustomer"
            :state="transactionState"
        />
        <CFormGroup>
            <div
                v-if="isOverAllocated"
                class="tw-text-gray-500"
            >
                <FontAwesomeIcon
                    class="tw-mr-1"
                    icon="fad fa-info-square"
                />
                {{
                    __('billing_transaction:warning.amount_is_overed', {
                        context: invoiceType,
                        amount: formatter.parse(transactionRemainingAmount).asString,
                    })
                }}
            </div>
            <div
                v-if="goingToBeDeleteInvoiceAllocation && payment"
                class="tw-text-gray-500"
            >
                <FontAwesomeIcon
                    class="tw-mr-1"
                    icon="fad fa-info-square"
                />
                {{
                    __('billing_transaction:warning.allocation_going_to_be_delete', {
                        amount: formatter.parse(sumOtherAllocations).asString,
                    })
                }}
            </div>
            <CFormErrorMessage v-if="isInsufficientlyAllocated">
                {{
                    __('billing_transaction:errors.the_amount_must_be_greater_or_equals_thant_sum_other_allocations', {
                        context: transaction.attributes.type,
                        amount: formatter.parse(sumOtherAllocations).asString,
                    })
                }}
            </CFormErrorMessage>
        </CFormGroup>
    </CForm>
</template>
