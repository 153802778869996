import type {Ref} from 'vue';
import {computed} from 'vue';
import type MeetingModel from '@/modules/activity/models/MeetingModel';
import MeetingPivot from '@/modules/activity/models/MeetingPivot';

export default function useAttachMeeting(meeting: Ref<MeetingModel>, resourceId: string, resourceType: string) {
    const pivot = computed(() =>
        meeting.value.meetingPivots().value()
            .where('attributes.resource_id', resourceId)
            .where('attributes.resource_type', resourceType)
            .first(),
    );

    // Attach resource to meeting
    async function attach() {
        if (pivot.value) {
            return;
        }

        const newPivot = new MeetingPivot();
        newPivot.attributes.account_id = meeting.value.attributes.account_id;
        newPivot.attributes.meeting_id = meeting.value.id;
        newPivot.attributes.resource_id = resourceId;
        newPivot.attributes.resource_type = resourceType;

        await newPivot.save();

        meeting.value.meetingPivots().value().push(newPivot);
    }

    // Detach resource from meeting
    async function detach() {
        if (pivot.value) {
            await pivot.value.delete();
        }
    }

    return {attach, detach};
}
