<template>
    <CDropdownMenu>
        <template #button>
            <CButton icon-ellipsis/>
        </template>
        <CDropdownMenuItem
            class="tw-whitespace-nowrap"
            icon="fa fa-pen"
            @click="toggleUpdateModal(true)"
        >
            {{ __('billing_customer:update_customer_account') }}
        </CDropdownMenuItem>
        <CDropdownMenuItem
            class="tw-whitespace-nowrap"
            icon="fa fa-compress-alt"
            @click="mergeModal.show"
        >
            {{ __('billing_customer:merge_customer_account_dots') }}
        </CDropdownMenuItem>
        <CDropdownMenuDivider/>
        <CDropdownMenuItem
            class="tw-whitespace-nowrap"
            icon="fa fa-trash"
            variant="danger"
            @click="toggleDeleteModal(true)"
        >
            {{ __('billing_customer:delete_customer_account_dots') }}
        </CDropdownMenuItem>
        <template #extra>
            <CustomerModalUpdate
                v-if="isUpdateModalVisible"
                :customer-model="customerModel"
                @closed="toggleUpdateModal(false)"
            />
            <CustomerModalDelete
                v-if="isDeleteModalVisible"
                ref="deleteModal"
                :customer-model="customerModel"
                @deleted="onCustomerDeleted"
                @hidden="toggleDeleteModal(false)"
            />
        </template>
    </CDropdownMenu>
</template>

<script lang="ts">
    import type CustomerModel from '@/modules/cashier/models/CustomerModel';
    import CustomerModalDelete from '@/modules/cashier/components/core/customer/pages/CustomerModalDelete.vue';
    import CustomerModalMerge from '@/modules/cashier/components/core/customer/pages/CustomerModalMerge.vue';
    import CustomerModalUpdate from '@/modules/cashier/components/core/customer/pages/CustomerModalUpdate.vue';
    import type {PropType} from 'vue';
    import {defineComponent} from 'vue';
    import {useToggle} from '@vueuse/core';
    import useModal from '@/modules/app/composables/useModal';
    import __ from '@/modules/app/utils/i18n-facade';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';

    export default defineComponent({
        components: {
            CustomerModalUpdate,
            CustomerModalDelete,
        },
        props: {
            customerModel: {type: Object as PropType<CustomerModel>, required: true},
            parent: {default: undefined},
        },
        setup(props, {emit}) {
            const [isUpdateModalVisible, toggleUpdateModal] = useToggle(false);
            const [isDeleteModalVisible, toggleDeleteModal] = useToggle(false);

            function onCustomerDeleted() {
                useNotification().success(__('billing_customer:customer_account_deleted'));
                emit('deleted', props.customerModel);
            }

            const mergeModal = useModal({
                component: CustomerModalMerge,
                props: {
                    customerModel: props.customerModel,
                },
            });

            return {
                onCustomerDeleted,
                isUpdateModalVisible,
                isDeleteModalVisible,
                toggleUpdateModal,
                toggleDeleteModal,
                mergeModal,
            };
        },
    });
</script>

