import {ref} from 'vue';
import type {HasMany, QueryBuilder} from '@meekohq/lumos';
import {collect} from '@meekohq/lumos';
import DocumentModel from '@/modules/document/models/DocumentModel';
import useManager from '@/modules/app/composables/useManager';
import moment from 'moment';

export default function(pivotConstraint: (query: QueryBuilder<any>, relation: HasMany<any, any>) => void) {
    const documents = ref(collect<DocumentModel>());

    const {activeOrganization} = useManager();

    const newDocument = function() {
        const document = new DocumentModel();
        document.attributes.account_id = activeOrganization.value.attributes.account_id;
        document.extra.draftDate = moment();

        documents.value.push(document);
    };

    // List documents related to the registration without constraint on the organization pivot for a coherent behaviour
    // with other registration activities.
    const getDocuments = async function() {
        documents.value = await DocumentModel.query(query => pivotConstraint(query, new DocumentModel().resources()))
            .with(new DocumentModel().resources())
            .with(new DocumentModel().organizations(), query => query.scope('userOrganizations'))
            .get();
    };

    const removeDocument = function(deletedDocument: DocumentModel) {
        documents.value = documents.value.reject(document => document.id === deletedDocument.id);
    };

    return {
        documents,
        getDocuments,
        newDocument,
        removeDocument,
    };
}
