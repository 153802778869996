<script lang="ts" setup>
    import {useFeatureFlag} from '@/modules/app/composables/useFeatureFlag';

    const props = defineProps({
        name: {
            type: String,
            required: true,
        },
        default: {
            type: Boolean,
            default: false,
        },
    });

    const flagValue = useFeatureFlag(props.name, props.default);
</script>

<template>
    <div class="FeatureFlag">
        <template v-if="flagValue">
            <slot/>
        </template>
        <template v-else>
            <slot name="disabled"/>
        </template>
    </div>
</template>

<style lang="scss" scoped>
    .FeatureFlag {
        @apply tw-contents;
    }
</style>
