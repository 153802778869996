import {Model} from '@meekohq/lumos';
import TemplateModel from '@/modules/human-resources/models/TemplateModel';
import TemplateEventModel from '@/modules/human-resources/models/TemplateEventModel';
import StaffModel from '@/modules/human-resources/models/StaffModel';

export default class TemplatePlanningModel extends Model {
    public type = 'hr/calendar/template/plannings';

    public attributes: {
        id: string;
        account_id: string | undefined;
        template_id: string | undefined;
        staff_id: string | null | undefined;
        name: string | undefined;
        description: string | undefined;
        archived_at: string | undefined;
    } = {
            id: this.uuid(),
            account_id: undefined,
            template_id: undefined,
            staff_id: undefined,
            name: undefined,
            description: undefined,
            archived_at: undefined,
        };

    // Get name from template (needs to load relation)
    get templateName(): string | undefined {
        return this.attributes.template_id ? this.template().value().attributes.name : this.attributes.name;
    }

    template() {
        return this.belongsTo('template', TemplateModel, 'template_id');
    }

    planningEvents() {
        return this.hasMany('planningEvents', TemplateEventModel, 'planning_template_id');
    }

    staff() {
        return this.belongsTo('staff', StaffModel, 'staff_id');
    }
}
