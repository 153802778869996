import {ref} from 'vue';

export default async function <T>(promises: Array<Promise<T>>) {
    const resolvedPromises = await Promise.allSettled(promises);

    // Get only the rejected Promises.
    const rejected = ref(resolvedPromises.filter(result => 'reason' in result) as PromiseRejectedResult[]);

    // Get only fulfilled Promises.
    const fulfilled = ref(resolvedPromises.filter(result => 'value' in result) as Array<PromiseFulfilledResult<T>>);

    return {
        rejected,
        fulfilled,
    };
}
