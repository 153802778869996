<template>
    <div class="planning float-none float-sm-left">
        <planning-generator
            :closing="closing"
            :day="day"
            :events="kid.events"
            inner-text
            :nursery="nursery"
            :opening="opening"
            :show-clock="false"
            :show-divider="false"
            :show-menu="showMenu"
            small
            :user="user"
        />
    </div>
</template>

<script>
    export default {
        props: ['nursery', 'kid', 'date', 'day', 'opening', 'closing', 'user', 'showMenu'],
    };
</script>


<style lang="scss" scoped>
    @import "bootstrap/scss/functions";
    @import "@/assets/_bootstrap/variables";
    @import "bootstrap/scss/mixins/breakpoints";

    @include media-breakpoint-up(sm) {
        .planning {
            width: 100%;
        }
    }
</style>
