import {ServiceProvider} from '@meekohq/lumos';
import UserModel from '@/modules/user/models/UserModel';
import UserSettingModel from '@/modules/user/models/UserSettingModel';
import LegacyUserGate from '@/modules/user/utils/LegacyUserGate';
import {Types} from '@/types';

export default class UserServiceProvider extends ServiceProvider {
    public boot() {
        this.loadModelsFrom([
            UserModel,
            UserSettingModel,
        ]);
    }

    public register() {
        this.app.bind(Types.Gate).toClass(LegacyUserGate);
    }
}
