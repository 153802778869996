import type {
    PaginateTransactionsUseCase,
} from '@/modules/cashier/transaction/application/use-cases/PaginateTransactionsUseCase';
import type TransactionModel from '@/modules/cashier/transaction/domain/TransactionModel';
import {collect, type Epoch, type LengthAwarePaginator, ModelCollection} from '@meekohq/lumos';
import type {TransactionFilterDto} from '@/modules/cashier/transaction/application/dto/TransactionFilterDto';
import type {
    MassDeleteTransactionsByFiltersUseCase,
} from '@/modules/cashier/transaction/application/use-cases/MassDeleteTransactionsByFiltersUseCase';
import type {
    RejectAndRestoreTransactionsUseCase,
} from '@/modules/cashier/transaction/application/use-cases/RejectAndRestoreTransactionsUseCase';
import type {
    TransactionRepositoryPort,
} from '@/modules/cashier/transaction/application/ports/TransactionRepositoryPort';
import type {
    SumTransactionsByCurrenciesUseCase,
} from '@/modules/cashier/transaction/application/use-cases/SumTransactionsByCurrenciesUseCase';
import type CurrencyModel from '../../models/CurrencyModel';

export class TransactionManagerService implements PaginateTransactionsUseCase,
    MassDeleteTransactionsByFiltersUseCase,
    RejectAndRestoreTransactionsUseCase,
    SumTransactionsByCurrenciesUseCase {
    private readonly transactionRepository: TransactionRepositoryPort;

    constructor(transactionRepository: TransactionRepositoryPort) {
        this.transactionRepository = transactionRepository;
    }

    public async sumByCurrenciesAndFilters(currencies: CurrencyModel[], filters: TransactionFilterDto): Promise<Array<{
        code: string,
        amount: number
    }>> {
        const promises: Array<Promise<{ code: string, amount: number }>> = [];

        // Group currencies by code
        const currencyCodes = collect(currencies).groupBy<ModelCollection<CurrencyModel>>('attributes.code');

        // For each currency code, get the sum of the transactions
        currencyCodes.each((currencies2, code) => {
            const getSum = async () => {
                const sum = await this.transactionRepository.sumByCurrenciesAndFilters(currencies2.toArray(), filters);

                return {code: code as string, amount: sum};
            };

            promises.push(getSum());
        });

        return Promise.all(promises);
    }

    public async rejectTransactionsAtDate(collection: ModelCollection<TransactionModel>, failureDate: Epoch): Promise<boolean> {
        await this.transactionRepository.rejectTransactionsByIds(
            collection.pluck('id').toArray(),
            failureDate,
        );

        await this.transactionRepository.refreshTransactionsCollection(collection);

        await this.transactionRepository.loadTransactionsRelations(new ModelCollection<TransactionModel>(collection.all()));

        return true;
    }

    public async restoreTransactions(collection: ModelCollection<TransactionModel>): Promise<boolean> {
        await this.transactionRepository.restoreTransactionsByIds(
            collection.pluck('id').toArray(),
        );

        await this.transactionRepository.refreshTransactionsCollection(collection);

        await this.transactionRepository.loadTransactionsRelations(new ModelCollection<TransactionModel>(collection.all()));

        return true;
    }

    /**
     * Mass delete transactions by filters.
     * @param ids
     * @param filters
     */
    public massDeleteByFilters(ids: string[], filters: TransactionFilterDto): Promise<number> {
        return this.transactionRepository.queryDeleteByFilters(ids, filters);
    }

    public async paginateByFilters(filters: TransactionFilterDto): Promise<LengthAwarePaginator<TransactionModel>> {
        const paginator = await this.transactionRepository.paginateByFilters(filters);

        await this.transactionRepository.loadTransactionsRelations(paginator.items() as ModelCollection<TransactionModel>);

        return paginator;
    }
}
