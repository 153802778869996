import type {ConsumerType} from '@/modules/auth/personal-access-token/domain/ConsumerType';
import {collect, ValidationError, ValidationErrorItem} from '@meekohq/lumos';

export type GeneratePersonalAccessTokenRequestMakeType = {
    tokenName: string,
    consumer: ConsumerType,
    sudoToken: string,
};

export class GeneratePersonalAccessTokenRequest {
    constructor(
        public readonly tokenName: string,
        public readonly consumer: ConsumerType,
        public readonly sudoToken: string,
    ) {}

    public static make(makeType: GeneratePersonalAccessTokenRequestMakeType): GeneratePersonalAccessTokenRequest {
        if (!makeType.tokenName) {
            throw new ValidationError(collect([
                new ValidationErrorItem('Value must be filled', '0x2EAA809FB3', [], {pointer: 'token_name'}),
            ]));
        }

        return new GeneratePersonalAccessTokenRequest(
            makeType.tokenName,
            makeType.consumer,
            makeType.sudoToken,
        );
    }
}
