<template>
    <div class="EditInvoiceDueDate">
        {{ __('billing_invoice:amount_due_by') }}
        <CFormDatepicker
            v-if="editable && can('update', 'invoices')"
            v-model="invoice.due_date"
            class="EditInvoiceDueDate__datepicker"
            format="YYYY-MM-DD"
        />
        <strong v-else>
            {{ Epoch.fromISOString(invoice.due_date).toLocaleString(Epoch.presets.DATE_SHORT) }}
        </strong>
    </div>
</template>

<script lang="ts">
    import {defineComponent} from 'vue';
    import useAbility from '@/modules/app/composables/useAbility';
    import {Epoch} from '@meekohq/lumos';

    export default defineComponent({
        props: {
            invoice: {
                type: Object,
                required: true,
            },
            editable: {
                type: Boolean,
                required: true,
            },
        },

        setup() {
            const {can} = useAbility();

            return {
                Epoch,
                can,
            };
        },
    });
</script>

<style lang="scss" scoped>
    .EditInvoiceDueDate {
        @apply tw-flex tw-flex-row tw-items-center tw-flex-wrap tw-gap-1;
    }

    .EditInvoiceDueDate__datepicker {
        @apply sm:tw-w-28 tw-w-full;
    }
</style>
