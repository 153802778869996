import {Model} from '@meekohq/lumos';
import type DocTemplateTypeValues from '@/modules/organization/utils/Values/DocTemplateTypeValues';
import type DocTemplateFormatValues from '@/modules/organization/utils/Values/DocTemplateFormatValues';

export default class DocTemplateModel extends Model {
    public type = 'doc_template/doc_templates';

    public attributes: {
        id: string;
        account_id: string | undefined;
        name: string | undefined;
        type: DocTemplateTypeValues | undefined;
        content: string | null;
        format: DocTemplateFormatValues | undefined;
        filename: string | null;
        original_filename: string | null;
        extension: string | null;

    } = {
            id: this.uuid(),
            account_id: undefined,
            name: undefined,
            type: undefined,
            content: null,
            format: undefined,
            filename: null,
            original_filename: null,
            extension: null,
        };
}
