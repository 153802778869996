import type {Ref} from 'vue';
import {computed, onMounted, reactive, ref, watch} from 'vue';
import moment from 'moment';
import interact from 'interactjs';
import Mirror from '@/modules/legacy/helpers/mirror.helper';
import {percentToUnix, readableHoursFromDates, unixToPercent} from '@/modules/legacy/libs/planning/planning';
import {EventBus} from '@/modules/legacy/utils/bus';
import useEventStyle from '@/modules/human-resources/composables/calendar/useEventStyle';
import EventModel from '@/modules/human-resources/models/EventModel';
import type TemplateEventModel from '@/modules/human-resources/models/TemplateEventModel';
import EventError from '@/modules/human-resources/utils/calendar/EventError';
import useAuth from '@/modules/app/composables/useAuth';
import useNotification from '@/modules/meeko-ui/composables/useNotification';
import {useIntervalFn} from '@vueuse/core';

export default function(
    event: Ref<EventModel | TemplateEventModel>,
    day,
    opening: Ref,
    closing: Ref,
    size: 'sm' | 'md' | 'lg',
    resizable: boolean,
    autoSave: boolean | undefined = true,
) {
    const {legacyUser} = useAuth();
    const eventCopy = ref(new Mirror(event.value));
    const errorForm = reactive(new EventError());
    watch(event, val => {
        eventCopy.value = new Mirror(val);
    }, {
        deep: true,
    });

    const eventSegment = ref();
    const now = ref(moment().format());
    const loading = ref(false);

    onMounted(() => {
        useIntervalFn(() => {
            now.value = moment().format();
        }, 1000 * 60);

        resizable ? initResize() : null;
    });

    const start = computed(() => {
        return eventCopy.value.value.startedAt;
    });
    const end = computed(() => {
        return eventCopy.value.value.endedAt;
    });

    const eventType = computed(() => {
        return event.value.eventType().value();
    });

    const color = computed(() => {
        return eventType.value.attributes.color || '';
    });
    const forecast = computed(() => {
        return event.value.attributes.forecast || false;
    });

    const pending = computed(() => {
        return eventCopy.value.value.isPending;
    });

    const {eventClass, eventStyle, iconClass, iconStyle} = useEventStyle(
        color,
        forecast,
        size,
        true,
        loading,
    );

    const style = computed(() => {
        const left =
            unixToPercent(
                start.value.unix(),
                day,
                opening.value,
                closing.value,
                false,
            ) + '%';
        const right =
            100 -
            unixToPercent(end.value.unix(), day, opening.value, closing.value, true) +
            '%';

        return {
            ...eventStyle.value,
            left,
            right,
        };
    });

    const segmentWidth = computed(() => {
        const left = unixToPercent(start.value.unix(), day, opening.value, closing.value);
        const right = unixToPercent(end.value.unix(), day, opening.value, closing.value);

        return right - left;
    });

    const displayText = computed(() => {
        let hasEnoughSpace = true;
        switch (size) {
            case 'sm':
                hasEnoughSpace = segmentWidth.value >= 20;
                break;
            case 'md':
                hasEnoughSpace = segmentWidth.value >= 20;
                break;
            case 'lg':
                hasEnoughSpace = segmentWidth.value >= 6;
                break;
        }

        return hasEnoughSpace || (!hasEnoughSpace && !eventType.value.attributes.icon_id);
    });

    const displayIcon = computed(() => {
        let hasEnoughSpace = true;
        switch (size) {
            case 'sm':
                hasEnoughSpace = (segmentWidth.value > 13 && segmentWidth.value < 20) || segmentWidth.value > 30;
                break;
            case 'md':
                hasEnoughSpace = (segmentWidth.value > 13 && segmentWidth.value < 20) || segmentWidth.value > 30;
                break;
            case 'lg':
                hasEnoughSpace = segmentWidth.value > 3;
                break;
        }

        return hasEnoughSpace && eventType.value.attributes.icon_id;
    });

    const displayAsCompact = computed(() => {
        switch (size) {
            case 'sm':
                return segmentWidth.value < 48;
            case 'md':
                return segmentWidth.value < 48;
            case 'lg':
                return segmentWidth.value < 15;
        }
    });

    const titleHours = computed(() => {
        return readableHoursFromDates(start.value, end.value);
    });

    const nurseryName = computed(() => {
        let text = 'Autre structure'; // In case user doesn't have credentials in other organizations.
        if (!event.value.attributes.organization_id) {
            text = 'Aucune structure';
        }
        if (legacyUser.value) {
            const nursery = legacyUser.value.nurseries.find(
                item =>
                    `${item.id}` === `${event.value.attributes.organization_id}`,
            );
            if (nursery) {
                text = nursery.city
                    ? nursery.name + ' - ' + nursery.city
                    : nursery.name;
            }
        }

        return text;
    });

    const subtitle = computed(() => {
        let text: string = eventType.value.attributes.name || '';

        // User has multiple organizations or event is in unauthorized organization
        const userNurseries = legacyUser.value.nurseries.map(item => `${item.id}`);
        if (legacyUser.value.nurseries.length > 1 || !userNurseries.includes(event.value.attributes.organization_id)) {
            text = nurseryName.value;
        }

        return text;
    });

    function defaultPercent(min: number | undefined = 5) {
        const start = moment(day)
            .clone()
            .hours(moment.duration(opening.value).hours())
            .minutes(min)
            .seconds(0)
            .unix();

        return unixToPercent(start, day, opening.value, closing.value);
    }

    function percentToDate(percent: number, format: string | null) {
        const unix = percentToUnix(percent, day, opening.value, closing.value);

        return format
            ? moment.unix(unix).format(format)
            : moment.unix(unix).format();
    }

    function title() {
        return (
            start.value.format('D MMM HH:mm') +
            ' au ' +
            end.value.format('D MMM HH:mm')
        );
    }

    function titlePresence() {
        let text = '';
        text += start.value.format('HH:mm') + ' à ' + end.value.format('HH:mm');
        event.value.attributes.note
            ? (text += '\nNote à l\'arrivée : ' + event.value.attributes.note)
            : null;
        event.value.attributes.note
            ? (text += '\nNote au départ : ' + event.value.attributes.note)
            : null;

        return text;
    }

    const resizing = ref(false);
    const mouseVelocity = computed(() => {
        const width =
            window.innerWidth ||
            document.documentElement.clientWidth ||
            document.body.clientWidth;
        const widthDifference = width / 1440 / 1.0;

        return size === 'lg' ? 10 / widthDifference : 2 / widthDifference;
    });

    function initResize() {
        const eventInteract = interact(eventSegment.value);
        const modifiers = [
            interact.modifiers.snapSize({
                targets: [
                    interact.snappers.grid({
                        width: defaultPercent() * mouseVelocity.value,
                        height: defaultPercent() * mouseVelocity.value,
                    }),
                ],
            }),
        ];

        eventInteract.draggable({
            startAxis: 'x',
            lockAxis: 'x',
            cursorChecker() {
                return 'pointer';
            },
            modifiers,
            listeners: {
                end() {
                    onResizeEnd();
                },
                move(e) {
                    onResize(e, e.dx, e.dx);
                },
            },
        });
        eventInteract.resizable({
            edges: {
                top: false,
                left: '.resizer-left',
                bottom: false,
                right: '.resizer-right',
            },
            modifiers,
            listeners: {
                end() {
                    onResizeEnd();
                },
                move(e) {
                    onResize(e, e.deltaRect.left, e.deltaRect.right);
                },
            },
        });
    }

    function onResize(e, deltaLeft: number, deltaRight: number) {
        const left = parseFloat(e.target.style.left.replace('%', ''));
        const right = parseFloat(e.target.style.right.replace('%', ''));

        const newLeft = left + deltaLeft / mouseVelocity.value;
        const newRight = right - deltaRight / mouseVelocity.value;

        Object.assign(e.target.style, {
            left: `${newLeft}%`,
            right: `${newRight}%`,
        });

        eventCopy.value.value.startedAt = percentToDate(newLeft, null);
        eventCopy.value.value.endedAt = percentToDate(100 - newRight, null);
        resizing.value = true;
    }

    function onResizeEnd() {
        EventBus.$emit('segment:dragend');

        updateMirrorEvent();

        setTimeout(() => {
            resizing.value = false;
        }, 300);
    }

    const dateError = computed(() => {
        return errorForm.getErrors('/domain/date');
    });

    async function updateMirrorEvent() {
        loading.value = true;
        try {
            if (event.value instanceof EventModel && event.value.attributes.datetime_event?.ended_at === null) {
                eventCopy.value.value.attributes.datetime_event.ended_at = null;
            }
            autoSave ? await eventCopy.value.value.save() : null;
            eventCopy.value.commit();
            EventBus.$emit('calendar:staffs:refresh');
            loading.value = false;
        } catch (e) {
            errorForm.reset(e);

            if (dateError.value.isNotEmpty()) {
                useNotification().error(dateError.value.all().join('\n'));
            }
        }

    }

    return {
        eventCopy,
        eventSegment,
        loading,
        eventType,
        eventStyle: style,
        eventClass,
        iconClass,
        iconStyle,
        displayText,
        displayIcon,
        displayAsCompact,

        titleHours,
        nurseryName,
        subtitle,
        defaultPercent,
        percentToDate,
        title,
        titlePresence,

        resizing,
        pending,
    };
}
