import {collect} from '@meekohq/lumos';
import type {Ref} from 'vue';
import {computed, ref} from 'vue';
import KidModel from '@/modules/family/models/KidModel';
import __ from '@/modules/app/utils/i18n-facade';
import MemberModel from '@/modules/family/models/MemberModel';
import StaffModel from '@/modules/human-resources/models/StaffModel';
import RegistrationModel from '@/modules/registration/models/RegistrationModel';
import CustomerModel from '@/modules/cashier/models/CustomerModel';
import OrganizationModel from '@/modules/organization/models/OrganizationModel';
import useAbility from '@/modules/app/composables/useAbility';

export interface AskedCategory {
    type: string;
    finderProps: {
        [key: string]: any;
    };
}

export interface AvailableCategory extends AskedCategory {
    allowed: boolean;
    label: string;
    icon: string;
    disabled: boolean;
    finder: string;
}

export default function(askedCategories: Ref<AskedCategory[]>) {
    const {can} = useAbility();

    const kidModelType = new KidModel().getType();
    const memberModelType = new MemberModel().getType();
    const staffModelType = new StaffModel().getType();
    const registrationModelType = new RegistrationModel().getType();
    const customerModelType = new CustomerModel().getType();
    const organizationModelType = new OrganizationModel().getType();

    // List of available categories
    const availableCategories = ref([
        {
            type: kidModelType,
            allowed: can('read', 'kids_details') && isAskedCategory(kidModelType),
            label: __('common:kid_one'),
            icon: 'fa fa-child-reaching',
            disabled: false,
            finder: 'KidFinder',
            finderProps: getAskedCategoryFinderProps(kidModelType) ?? {hasActiveContract: true},
        },
        {
            type: memberModelType,
            allowed: can('read', 'family_members_details') && isAskedCategory(memberModelType),
            label: __('common:parent'),
            icon: 'fa fa-user-tie',
            disabled: false,
            finder: 'MemberFinder',
            finderProps: getAskedCategoryFinderProps(memberModelType) ?? {hasActiveContract: true},
        },
        {
            type: staffModelType,
            allowed: can('read', 'staffs_details') && isAskedCategory(staffModelType),
            label: __('common:staff_one'),
            icon: 'fa fa-users',
            disabled: false,
            finder: 'StaffFinder',
            finderProps: getAskedCategoryFinderProps(staffModelType) ?? {hasActiveContract: true},
        },
        {
            type: registrationModelType,
            allowed: can('read', 'registrations') && isAskedCategory(registrationModelType),
            label: __('common:registration_one'),
            icon: 'fa fa-pen-alt',
            disabled: false,
            finder: 'RegistrationFinder',
            finderProps: getAskedCategoryFinderProps(registrationModelType) ?? {ongoingRegistrations: true},
        },
        {
            type: customerModelType,
            allowed: can('read', 'transactions') && isAskedCategory(customerModelType),
            label: __('common:customer_one'),
            icon: 'fa fa-file-user',
            disabled: false,
            finder: 'CustomerFinder',
            finderProps: getAskedCategoryFinderProps(customerModelType) ?? {hasActiveContract: true},
        },
        {
            type: organizationModelType,
            allowed: isAskedCategory(organizationModelType),
            label: __('common:organization_one'),
            icon: 'fa fa-house',
            disabled: false,
            finder: 'OrganizationFinder',
            finderProps: getAskedCategoryFinderProps(organizationModelType) ?? {},
        },
    ]);

    // List of allowed categories
    const allowedCategories = computed(() => {
        return availableCategories.value.filter(category => category.allowed);
    });

    const selectedCategory = ref<AvailableCategory>(allowedCategories.value[0]);

    // Check if a category is asked
    function isAskedCategory(categoryType: string) {
        return collect(askedCategories.value).where('type', categoryType).count() > 0;
    }

    // Get finder props for a category
    function getAskedCategoryFinderProps(categoryType: string) {
        return collect(askedCategories.value).where('type', categoryType).first()?.finderProps ?? null;
    }

    function selectCategory(category) {
        selectedCategory.value = category;
    }

    return {
        allowedCategories,
        selectedCategory,
        selectCategory,
    };
}
