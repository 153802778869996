<template>
    <div class="TaxCertificates__filters-container">
        <CFormGroup class="TaxCertificates__date-form">
            <CLabel>{{ __('common:start_date') }}</CLabel>
            <CFormDatepicker
                v-model="filters.period.from"
                :max-date="filters.period.to"
                :sync-date-by-min-or-max="true"
            />
        </CFormGroup>
        <CFormGroup class="TaxCertificates__date-form">
            <CLabel>{{ __('common:end_date') }}</CLabel>
            <CFormDatepicker
                v-model="filters.period.to"
                :min-date="filters.period.from"
                :sync-date-by-min-or-max="true"
            />
        </CFormGroup>
        <CFormGroup class="TaxCertificates__date-form">
            <CLabel>{{ __('billing_customer:entity') }}</CLabel>
            <CFormSelect
                v-model="filters.customerPerson"
                :options="customerPersonOptions"
                :search-bar="false"
            />
        </CFormGroup>

        <CFormGroup
            v-if="showTenantsFilter"
            class="TaxCertificates__date-form"
        >
            <CLabel>{{ __('common:organization_other') }}</CLabel>
            <TenantFinder
                v-model="filters.tenants"
                button-class="tw-w-full"
                multi
                :multi-minimum="1"
                only-user-organizations
                :unselect-value="[activeTenant]"
            />
        </CFormGroup>
        <CFormGroup class="TaxCertificates__date-form">
            <CLabel>{{ __('billing_core:tax_certificate_template') }}</CLabel>
            <DocTemplateFinder
                v-model="filters.docTemplate"
                button-class="tw-w-full"
                :inject-query="docTemplateFinderQuery"
                only-user-organizations
            />
        </CFormGroup>
        <slot/>
    </div>
</template>

<script lang="ts">
    import type {PropType, UnwrapRef} from 'vue';
    import {computed, defineComponent} from 'vue';
    import TenantFinder from '@/modules/cashier/components/tax/tax-certificate/organisms/TenantFinder.vue';
    import DocTemplateFinder from '@/modules/cashier/components/tax/tax-certificate/organisms/DocTemplateFinder.vue';
    import type {CustomersFilters} from '@/modules/cashier/composables/tax/tax-certificate/useCustomersFilters';
    import DocTemplateModel from '@/modules/organization/models/DocTemplateModel';
    import DocTemplateTypeValues from '@/modules/organization/utils/Values/DocTemplateTypeValues';
    import useManager from '@/modules/app/composables/useManager';

    export default defineComponent({
        components: {DocTemplateFinder, TenantFinder},
        props: {
            filters: {
                type: Object as PropType<UnwrapRef<CustomersFilters>>,
                required: true,
            },
            customerPersonOptions: {
                type: Array as PropType<Array<{ value: string, text: string }>>,
                required: true,
            },
            showTenantsFilter: {
                type: Boolean,
                default: false,
            },
        },
        setup() {
            const {activeOrganization, activeTenant} = useManager();

            /**
             * Filter query for doc template finder component
             * @type {ComputedRef<QueryBuilder<DocTemplateModel>>}
             */
            const docTemplateFinderQuery = computed(() => {
                return DocTemplateModel.query()
                    .whereHas('organizations', query =>
                        query.where('nursery_id', '=', activeOrganization.value.getKey()),
                    )
                    .where('type', DocTemplateTypeValues.taxCertificate);
            });

            return {
                activeTenant,
                docTemplateFinderQuery,
            };
        },
    });
</script>

<style lang="scss" scoped>
    .TaxCertificates__filters-container {
        @apply tw-flex tw-flex-wrap tw-gap-x-3;
        @apply tw-border-b tw-border-gray-100;
        @apply tw-pb-5;
    }

    .TaxCertificates__date-form {
        @apply tw-mb-3 lg:tw-mb-0;
    }
</style>
