<script setup lang="ts">
    import {computed, inject, ref} from 'vue';
    import type PersonalAccessTokenModel from '@/modules/auth/personal-access-token/domain/PersonalAccessTokenModel';
    import MagicIndexItemColumn from '@/modules/magic-index/components/atoms/MagicIndexItemColumn.vue';
    import {Epoch} from '@meekohq/lumos';
    import {useElementSize} from '@vueuse/core';
    import useModal from '@/modules/app/composables/useModal';
    import PersonalAccessTokensModalEdit
        from '@/modules/auth/personal-access-token/infrastructure/components/PersonalAccessTokensModalEdit.vue';
    import PersonalAccessTokensModalDelete
        from '@/modules/auth/personal-access-token/infrastructure/components/PersonalAccessTokensModalDelete.vue';
    import {
        injectKey,
    } from '@/modules/auth/personal-access-token/infrastructure/components/usePersonalAccessTokensListState';
    import __ from '@/modules/app/utils/i18n-facade';

    const props = defineProps<{
        item: PersonalAccessTokenModel,
    }>();

    const state = inject(injectKey);

    const formatedKeyId = computed(
        () => props.item.getKey().slice(0, 9) + '∗'.repeat(6),
    );
    const formatedLastUsedAt = computed(
        () => {
            return props.item.attributes.last_used_at ?
                Epoch.fromISOString(props.item.attributes.last_used_at).toLocaleString(Epoch.presets.DATE_FULL)
                : __('common:never_used');
        },
    );

    const personalAccessTokensListItemEl = ref(null);
    const {width} = useElementSize(personalAccessTokensListItemEl);

    function showEditModal() {
        useModal({
            component: PersonalAccessTokensModalEdit,
            props: {
                item: props.item,
            },
            listeners: modal => ({
                updated: () => {
                    state?.onUpdated(props.item);
                    modal.hide();
                },
            }),
        }).show();
    }

    function showDeleteModal() {
        useModal({
            component: PersonalAccessTokensModalDelete,
            props: {
                item: props.item,
            },
            listeners: modal => ({
                deleted: async () => {
                    await state?.onDeleted(props.item);
                    modal.hide();
                },
            }),
        }).show();
    }
</script>

<template>
    <div
        ref="personalAccessTokensListItemEl"
        class="PersonalAccessTokensListItem"
    >
        <div class="PersonalAccessTokensListItem__container">
            <MagicIndexItemColumn
                class="PersonalAccessTokensListItem__column-name"
                :grid-enabled="false"
            >
                {{ item.attributes.name }}
            </MagicIndexItemColumn>
            <MagicIndexItemColumn
                class="PersonalAccessTokensListItem__column-actions"
                :grid-enabled="false"
            >
                <MDropdown>
                    <MButton
                        icon-ellipsis
                        variant="ghost"
                    />
                    <template #content>
                        <CDropdownMenuItem
                            class="tw-w-full"
                            icon="fa fa-pen"
                            @click="showEditModal"
                        >
                            {{ __('common:actions.update') }}
                        </CDropdownMenuItem>
                        <CDropdownMenuItem
                            class="tw-w-full"
                            icon="fa fa-trash"
                            variant="danger"
                            @click="showDeleteModal"
                        >
                            {{ __('common:actions.delete_dots') }}
                        </CDropdownMenuItem>
                    </template>
                </MDropdown>
            </MagicIndexItemColumn>
            <MagicIndexItemColumn
                class="PersonalAccessTokensListItem__column-code"
                :container-width="width"
                grid-title="Numéro"
            >
                <MTooltip placement="top">
                    <MInput
                        disabled
                        :value="formatedKeyId"
                    />
                    <template #content>
                        {{ __('auth:full_key_only_visible_on_creation') }}
                    </template>
                </MTooltip>
            </MagicIndexItemColumn>
            <MagicIndexItemColumn
                class="PersonalAccessTokensListItem__column-last-activity"
                :container-width="width"
                :grid-title="__('user:last_usage')"
            >
                {{ formatedLastUsedAt }}
            </MagicIndexItemColumn>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    .PersonalAccessTokensListItem {
        container-type: inline-size;
        container-name: PersonalAccessTokensListItem;
    }

    .PersonalAccessTokensListItem__container {
        @apply tw-grid tw-grid-cols-12 tw-gap-3;

        @container PersonalAccessTokensListItem (min-width: 48rem) {
            @apply tw-items-center;
        }
    }

    .PersonalAccessTokensListItem__column-name {
        @apply tw-col-span-11;

        @container PersonalAccessTokensListItem (min-width: 48rem) {
            @apply tw-col-span-7;
        }
    }

    .PersonalAccessTokensListItem__column-actions {
        @apply tw-col-span-1 tw-order-none tw-text-right;

        @container PersonalAccessTokensListItem (min-width: 48rem) {
            @apply tw-order-last;
        }
    }

    .PersonalAccessTokensListItem__column-code {
        @apply tw-col-span-6;

        @container PersonalAccessTokensListItem (min-width: 48rem) {
            @apply tw-col-span-2;
        }
    }

    .PersonalAccessTokensListItem__column-last-activity {
        @apply tw-col-span-6;

        @container PersonalAccessTokensListItem (min-width: 48rem) {
            @apply tw-col-span-2;
        }
    }
</style>
