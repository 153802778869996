import type {Moment} from 'moment';
import moment from 'moment';

const dateRegex = /((?:19|20)[0-9][0-9])-(0?[1-9]|1[012])-(0?[1-9]|[12][0-9]|3[01])/;

export default class DateValue {
    protected date: string;

    public constructor(date: string) {
        if (!DateValue.validate(date)) {
            throw new Error('Bad date format');
        }
        this.date = date;
    }

    get moment(): Moment {
        return moment.utc(this.date);
    }

    public static validate(date: string): boolean {
        let valid = true;

        if (!(dateRegex.test(date))) {
            valid = false;
        }

        return valid;
    }
}
