<template>
    <component
        :is="as"
        class="CCenter"
        v-on="$listeners"
    >
        <slot/>
    </component>
</template>

<script lang="ts">
    import {defineComponent} from 'vue';

    export default defineComponent({
        props: {
            as: {type: String, default: 'div'},
        },
        setup() {
            //
        },
    });
</script>
