<script setup lang="ts">
    import CurrencyInput from '@/modules/app/components/atoms/CurrencyInput.vue';
    import useFormatNumber from '@/modules/cashier/composables/useFormatNumber';
    import usePaymentSuggestionsListItem
        from '@/modules/cashier/payment/infrastructure/components/usePaymentSuggestionsListItem';
    import {computed, toRef, watch} from 'vue';
    import type {AbstractAllocationAggregate} from '@/modules/cashier/payment/domain/AbstractAllocationAggregate';
    import {InvoiceAllocationAggregate} from '@/modules/cashier/payment/domain/InvoiceAllocationAggregate';
    import {
        TransactionCreditAllocationAggregate,
    } from '@/modules/cashier/payment/domain/TransactionCreditAllocationAggregate';
    import {
        TransactionDebitAllocationAggregate,
    } from '@/modules/cashier/payment/domain/TransactionDebitAllocationAggregate';
    import type {
        SaveAllocationResultFailed,
    } from '@/modules/cashier/payment/application/SaveAllocationAggregateResponseModel';
    import {AmountToAllocateIsRequired} from '@/modules/cashier/payment/domain/errors/AmountToAllocateIsRequired';
    import {
        AmountToAllocateMustBePositiveError,
    } from '@/modules/cashier/payment/domain/errors/AmountToAllocateMustBePositiveError';
    import {
        AmountToAllocateIsGreaterThanSourceRemainingError,
    } from '@/modules/cashier/payment/domain/errors/AmountToAllocateIsGreaterThanSourceRemainingError';
    import {
        AmountToAllocateIsGreaterThanDestinationRemainingError,
    } from '@/modules/cashier/payment/domain/errors/AmountToAllocateIsGreaterThanDestinationRemainingError';
    import {ValidationError} from '@meekohq/lumos';
    import {isNil} from 'lodash-es';

    const props = defineProps<{
        allocation: AbstractAllocationAggregate,
        currency: string,
        editable: boolean,
        savingError: SaveAllocationResultFailed[] | null,
    }>();

    const {format} = useFormatNumber(props.currency);

    const invoiceType = computed(() => {
        if (props.allocation instanceof TransactionDebitAllocationAggregate || props.allocation instanceof InvoiceAllocationAggregate) {
            return 'credit_note';
        } else {
            return 'invoice';
        }
    });

    const isCanceled = computed(() => {
        if (props.allocation instanceof TransactionCreditAllocationAggregate) {
            return props.allocation.source.isFailed;
        } else if (props.allocation instanceof TransactionDebitAllocationAggregate) {
            return props.allocation.destination.isFailed;
        } else {
            return false;
        }
    });

    const {
        errors,
        maxAllocatableAmount,
        setAllocationAmount,
    } = usePaymentSuggestionsListItem(toRef(props, 'allocation'));

    watch(() => props.savingError, newValue => {
        if (isNil(newValue)) {
            return;
        }

        newValue.forEach((error: SaveAllocationResultFailed) => {
            if (error.reason instanceof AmountToAllocateIsRequired) {
                errors.addErrorCode('amount', AmountToAllocateIsRequired.code);
            } else if (error.reason instanceof AmountToAllocateMustBePositiveError) {
                errors.addErrorCode('amount', AmountToAllocateMustBePositiveError.code);
            } else if (error.reason instanceof AmountToAllocateIsGreaterThanSourceRemainingError) {
                errors.addErrorCode('amount', AmountToAllocateIsGreaterThanSourceRemainingError.code);
            } else if (error.reason instanceof AmountToAllocateIsGreaterThanDestinationRemainingError) {
                errors.addErrorCode('amount', AmountToAllocateIsGreaterThanDestinationRemainingError.code);
            } else if (error.reason instanceof ValidationError) {
                errors.addValidationError(error.reason);
            } else {
                throw new AggregateError(
                    newValue.map(r => r.reason),
                    'Failed to save allocations.',
                );
            }
        });
    });

    watch(() => props.editable, () => {
        errors.reset();
    });

    watch(() => props.allocation.sharedRemainingAmount, () => {
        if (props.allocation.sharedRemainingAmount >= 0 && errors.has('amount')) {
            setAllocationAmount(props.allocation.amount);
        }
    });
</script>
<template>
    <div
        class="PaymentAllocationsListAbstractItem"
        :class="{'PaymentAllocationsListAbstractItem--has-error': errors.hasErrors.value}"
    >
        <slot name="item"/>
        <div
            v-if="!editable || allocation.allocationMustBeIgnored"
            class="PaymentAllocationsListAbstractItem__allocation"
        >
            <MTooltip
                :hoverable="editable && allocation.allocationMustBeIgnored"
                placement="top"
            >
                <div
                    class="PaymentAllocationsListAbstractItem__allocation-amount"
                    :class="{'PaymentAllocationsListAbstractItem__allocation-amount--rejected': isCanceled}"
                >
                    {{ format(allocation.allocationAmount) }}
                </div>
                <template #content>
                    {{ __('billing_invoice:for_editing_allocation_disable_reject') }}
                </template>
            </MTooltip>
            <div class="PaymentAllocationsListAbstractItem__allocation-label">
                {{ __('billing_invoice:on_this', {context: invoiceType}) }}
            </div>
        </div>
        <div
            v-else
            class="PaymentAllocationsListAbstractItem__allocation-edition"
        >
            <CurrencyInput
                class="tw-text-lg tw-font-semibold"
                :currency="currency"
                :min="0"
                :value="allocation.amount"
                @input="setAllocationAmount($event)"
            />
            <MButton
                :label="__('billing_transaction:actions.use_maximum_amount')"
                size="sm"
                @click="setAllocationAmount(maxAllocatableAmount)"
            />
            <MErrorMessageList
                v-if="errors.has('amount')"
                class="PaymentAllocationsListAbstractItem__allocation-error-message"
                :errors="errors.get('amount')"
                font-size="xs"
                :show-icon="false"
            />
        </div>
    </div>
</template>

<style scoped lang="scss">
    .PaymentAllocationsListAbstractItem {
        @apply tw-flex sm:tw-flex-row tw-flex-col tw-items-center tw-gap-4;
    }

    .PaymentAllocationsListAbstractItem--has-error {
        @apply tw-items-start;
    }

    .PaymentAllocationsListAbstractItem__allocation {
        @apply sm:tw-w-48 tw-w-full tw-flex sm:tw-flex-col tw-justify-end tw-items-baseline sm:tw-items-end sm:tw-gap-0 tw-gap-2;
    }

    .PaymentAllocationsListAbstractItem__allocation-amount {
        @apply tw-text-xl tw-font-semibold;

        &--rejected {
            @apply tw-line-through;
        }
    }

    .PaymentAllocationsListAbstractItem__allocation-label {
        @apply tw-text-gray-500 tw-text-sm;
    }

    .PaymentAllocationsListAbstractItem__allocation-edition {
        @apply sm:tw-w-48 tw-w-full;
        @apply tw-grid sm:tw-grid-cols-1 tw-grid-cols-2 tw-gap-2;
        @apply tw-text-start;
    }

    .PaymentAllocationsListAbstractItem__allocation-error-message {
        @apply tw-col-span-full;
    }
</style>
