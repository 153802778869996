<template>
    <CVStack gap="4">
        <div class="tw-w-3/4 tw-self-end">
            <CSkeletonItem class="tw-ml-2  tw-h-5"/>
        </div>
        <CSkeletonItem class="tw-w-full tw-h-6"/>
        <CHStack
            align="center"
            class="tw-w-full"
            gap="2"
        >
            <CHStack
                class="tw-w-1/4 tw-self-stretch"
                gap="2"
            >
                <CVStack
                    class="tw-w-2/3"
                    gap="1"
                >
                    <CSkeletonItem class="tw-w-full tw-h-4"/>
                    <CSkeletonItem class="tw-w-full tw-h-4"/>
                </CVStack>
                <CSkeletonItem class="tw-h-full tw-flex-1"/>
            </CHStack>
            <CSkeletonItem class="tw-h-6 tw-flex-1"/>
            <CSkeletonItem class="tw-h-6 tw-flex-1"/>
            <CSkeletonItem class="tw-h-6 tw-flex-1"/>
            <CSkeletonItem class="tw-h-6 tw-flex-1"/>
            <CSkeletonItem class="tw-h-6 tw-flex-1"/>
        </CHStack>
        <CHStack
            align="center"
            class="tw-w-full"
            gap="2"
        >
            <CHStack
                class="tw-w-1/4 tw-self-stretch"
                gap="2"
            >
                <CVStack
                    class="tw-w-2/3"
                    gap="1"
                >
                    <CSkeletonItem class="tw-w-full tw-h-4"/>
                    <CSkeletonItem class="tw-w-full tw-h-4"/>
                </CVStack>
                <CSkeletonItem class="tw-h-full tw-flex-1"/>
            </CHStack>
            <CSkeletonItem class="tw-h-6 tw-flex-1"/>
            <CSkeletonItem class="tw-h-6 tw-flex-1"/>
            <CSkeletonItem class="tw-h-6 tw-flex-1"/>
            <CSkeletonItem class="tw-h-6 tw-flex-1"/>
            <CSkeletonItem class="tw-h-6 tw-flex-1"/>
        </CHStack>
        <CHStack
            align="center"
            class="tw-w-full"
            gap="2"
        >
            <CHStack
                class="tw-w-1/4 tw-self-stretch"
                gap="2"
            >
                <CVStack
                    class="tw-w-2/3"
                    gap="1"
                >
                    <CSkeletonItem class="tw-w-full tw-h-4"/>
                    <CSkeletonItem class="tw-w-full tw-h-4"/>
                </CVStack>
                <CSkeletonItem class="tw-h-full tw-flex-1"/>
            </CHStack>
            <CSkeletonItem class="tw-h-6 tw-flex-1"/>
            <CSkeletonItem class="tw-h-6 tw-flex-1"/>
            <CSkeletonItem class="tw-h-6 tw-flex-1"/>
            <CSkeletonItem class="tw-h-6 tw-flex-1"/>
            <CSkeletonItem class="tw-h-6 tw-flex-1"/>
        </CHStack>
        <CHStack
            align="center"
            class="tw-w-full"
            gap="2"
        >
            <CHStack
                class="tw-w-1/4 tw-self-stretch"
                gap="2"
            >
                <CVStack
                    class="tw-w-2/3"
                    gap="1"
                >
                    <CSkeletonItem class="tw-w-full tw-h-4"/>
                    <CSkeletonItem class="tw-w-full tw-h-4"/>
                </CVStack>
                <CSkeletonItem class="tw-h-full tw-flex-1"/>
            </CHStack>
            <CSkeletonItem class="tw-h-6 tw-flex-1"/>
            <CSkeletonItem class="tw-h-6 tw-flex-1"/>
            <CSkeletonItem class="tw-h-6 tw-flex-1"/>
            <CSkeletonItem class="tw-h-6 tw-flex-1"/>
            <CSkeletonItem class="tw-h-6 tw-flex-1"/>
        </CHStack>
    </CVStack>
</template>

<script>
    export default {
        name: 'SimulationListSkeleton',
    };
</script>

<style scoped>

</style>
