import type {
    InvoiceAllocationAggregatePort,
} from '@/modules/cashier/payment/application/ports/InvoiceAllocationAggregatePort';
import {type ModelCollection, type QueryBuilderCallbackType} from '@meekohq/lumos';
import TransactionModel from '@/modules/cashier/transaction/domain/TransactionModel';
import InvoiceModel from '@/modules/cashier/models/InvoiceModel';
import PaymentModel from '@/modules/cashier/models/PaymentModel';
import AllocationModel from '@/modules/cashier/models/AllocationModel';
import {SharedRemaingAmount} from '@/modules/cashier/payment/domain/SharedRemaingAmount';
import type {AbstractAllocationAggregate} from '@/modules/cashier/payment/domain/AbstractAllocationAggregate';
import {
    TransactionCreditAllocationAggregate,
} from '@/modules/cashier/payment/domain/TransactionCreditAllocationAggregate';
import {CreditNoteAllocationAggregate} from '@/modules/cashier/payment/domain/CreditNoteAllocationAggregate';

export class MQLInvoiceAllocationAggregateRepository implements InvoiceAllocationAggregatePort {

    public async getAllocations(payment: PaymentModel): Promise<Array<CreditNoteAllocationAggregate | TransactionCreditAllocationAggregate>> {
        // Get the transactions that have relation with the payment
        const transactionsPromise = this.getInvoiceTransactionQuery(
            payment,
            query => {
                // Relation avec le payment existante
                query
                    .whereHas(new TransactionModel().payments(), query1 => {
                        query1.where('id', payment.getKey());
                    })
                    .whereHas(new TransactionModel().allocationsAsSource(), query1 => {
                        query1.where('destination_id', payment.getKey());
                    });
            },
            true);

        // Get the credit notes that have relation with the payment
        const creditNotesPromise = this.getCreditNoteQuery(
            payment, query => {
                // Relation avec le payment existante
                query
                    .whereHas(new InvoiceModel().refunds(), query1 => {
                        query1.where('id', payment.getKey());
                    })
                    .whereHas(new TransactionModel().allocationsAsSource(), query1 => {
                        query1.where('destination_id', payment.getKey());
                    });
            });

        const sharedRemainingAmount = new SharedRemaingAmount(payment.computed.remaining_amount, payment.computed.currency_iso_code);

        return this.loadAndMergeAllocations(
            payment,
            transactionsPromise,
            creditNotesPromise,
            sharedRemainingAmount,
        );
    }

    public async getTransactionSuggestions(payment: PaymentModel): Promise<TransactionCreditAllocationAggregate[]> {
        // Get the credit transactions that have remaining amount to distribute
        const transactionsPromise = this.getInvoiceTransactionQuery(
            payment, query => {
                query.where('remaining_to_distribute_amount', '>', 0);
                query.where('status', '!=', 'failed');
                query.whereDoesntHave(new TransactionModel().payments(), query1 => {
                    query1.where('id', payment.getKey());
                });
            });

        const transactions = await transactionsPromise;
        const sharedRemainingAmount = new SharedRemaingAmount(payment.computed.remaining_amount, payment.computed.currency_iso_code);

        return transactions.map<AbstractAllocationAggregate>(transaction => {
            return new TransactionCreditAllocationAggregate(
                transaction,
                payment,
                sharedRemainingAmount,
                transaction.allocationsAsSource().value()?.first(),
            );
        }).toArray();
    }

    public async getCreditNoteSuggestions(payment: PaymentModel): Promise<CreditNoteAllocationAggregate[]> {
        // Get the credit notes that have remaining amount
        const creditNotesPromise = this.getCreditNoteQuery(
            payment, query => {
                query.where('remaining_amount', '>', 0);
                query.whereDoesntHave(new InvoiceModel().refunds(), query1 => {
                    query1.where('id', payment.getKey());
                });
            });

        const creditNotes = await creditNotesPromise;
        const sharedRemainingAmount = new SharedRemaingAmount(payment.computed.remaining_amount, payment.computed.currency_iso_code);

        // Convert the transactions and credit notes to SourceAllocationAggregate
        return creditNotes.map(creditNote => {
            return new CreditNoteAllocationAggregate(
                creditNote,
                payment,
                sharedRemainingAmount,
                creditNote.allocationsAsSource().value()?.first(),
            );
        }).toArray();
    }

    private getInvoiceTransactionQuery(
        payment: PaymentModel,
        conditionCallback: QueryBuilderCallbackType<TransactionModel>,
        loadAllocationRelations: boolean = false,
    ): Promise<ModelCollection<TransactionModel>> {
        return TransactionModel
            .query()
            .where('type', 'credit')
            .where('customer_id', payment.attributes.customer_id)
            .where(conditionCallback)
            .with(new TransactionModel().allocationsAsSource(), query => {
                if (loadAllocationRelations) {
                    query.with(new AllocationModel().destination(), query1 => {
                        query1.with(new PaymentModel().invoices());
                    });
                }
                query.where('destination_id', payment.getKey());
            })
            .with(new TransactionModel().paymentMethod())
            .get();
    }

    private getCreditNoteQuery(
        payment: PaymentModel,
        conditionCallback: QueryBuilderCallbackType<InvoiceModel>,
    ): Promise<ModelCollection<InvoiceModel>> {
        return InvoiceModel
            .query()
            .where('invoice_type', 'credit_note')
            .where('customer_id', payment.attributes.customer_id)
            .where(conditionCallback)
            .with(new InvoiceModel().allocationsAsSource(), query => {
                query.where('destination_id', payment.getKey());
            })
            .with(new InvoiceModel().refunds(), query => {
                query.whereHas(new PaymentModel().allocationsAsSource());
            })
            .with(new InvoiceModel().customer())
            .get();
    }

    private async loadAndMergeAllocations(
        payment: PaymentModel,
        transactionsPromises: Promise<ModelCollection<TransactionModel>>,
        invoicesPromises: Promise<ModelCollection<InvoiceModel>>,
        sharedRemainingAmount: SharedRemaingAmount,
    ): Promise<Array<CreditNoteAllocationAggregate | TransactionCreditAllocationAggregate>> {
        const [transactions, invoices] = await Promise.all([transactionsPromises, invoicesPromises]);

        // Convert the transactions and credit notes to SourceAllocationAggregate
        return transactions.map<AbstractAllocationAggregate>(transaction => {
            return new TransactionCreditAllocationAggregate(
                transaction,
                payment,
                sharedRemainingAmount,
                transaction.allocationsAsSource().value()?.first(),
            );
        }).merge<AbstractAllocationAggregate>(invoices.all().map(invoice => {
            return new CreditNoteAllocationAggregate(
                invoice,
                payment,
                sharedRemainingAmount,
                invoice.allocationsAsSource().value()?.first(),
            );
        })).toArray();
    }
}
