import moment from 'moment/moment';
import {useRoute, useRouter} from 'vue-router/composables';

export default function() {
    const route = useRoute();
    const router = useRouter();

    function computeQueryString(newQueries: Array<{ key: string, value: string | number | undefined }>) {
        const queries = JSON.parse(JSON.stringify(route.query));
        newQueries.forEach(newQuery => {
            queries[newQuery.key] = newQuery.value;
            if (Object.keys(queries).includes(newQuery.key) && queries[newQuery.key] === undefined) {
                delete queries[newQuery.key];
            }
        });

        return queries;
    }

    function pushQueryString(key, value) {
        pushQueryStrings([{key, value}]);
    }

    function pushQueryStrings(queriesToPush: Array<{ key: string, value: string | number | undefined }>) {
        if (route && route.name) {
            const queries = computeQueryString(queriesToPush);

            if (JSON.stringify(route.query) !== JSON.stringify(queries)) {
                router?.push({name: route.name, query: queries});
            }
        }
    }

    function pushQueryDate(date, format) {
        if (format === 'day') {
            pushQueryString('date', moment(date).startOf(format).unix());
        } else {
            pushQueryString('date', moment(date).unix());
        }
    }

    function getCurrentPage() {
        return route.query.p ? parseInt(route.query.p as string) : 1;
    }

    return {
        pushQueryString,
        pushQueryStrings,
        pushQueryDate,
        getCurrentPage,
    };
}
