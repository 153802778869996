import type {Epoch} from '@meekohq/lumos';
import __ from '@/modules/app/utils/i18n-facade';

export type HumanizeParamsType = {
    dateFrom?: Epoch,
    dateTo?: Epoch,
    options?: {
        autoSuffix?: boolean,
        customPrefix?: string,
        customSuffix?: string,
        forKids?: boolean
    }
    fallBack?: string,
};
export default function useDuration() {

    function toHuman(humanizeParams: HumanizeParamsType): string {
        const thresholds = {};
        const {
            dateFrom,
            dateTo,
            options,
            fallBack,
        } = humanizeParams;

        if (options?.forKids) {
            Object.assign(thresholds, {M: 36});
        }

        if (!dateFrom || !dateTo) {
            return fallBack ?? '';
        }

        if (options && (options.customSuffix || options.customPrefix)) {
            return `${options.customPrefix ?? ''} ${dateFrom.difference(dateTo).toHuman(thresholds)} ${options.customSuffix ?? ''}`;
        }

        if (!options?.autoSuffix) {
            return dateFrom.difference(dateTo).toHuman(thresholds);
        }

        if (dateFrom.greaterThan(dateTo)) {
            return __('common:date_format.from_with_duration', {duration: dateFrom.difference(dateTo).toHuman(thresholds)});
        }

        return __('common:date_format.in_with_duration', {duration: dateFrom.difference(dateTo).toHuman(thresholds)});


    }

    return {
        toHuman,
    };
}
