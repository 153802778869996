<template>
    <MHoverable>
        <CDropdownMenu
            arrow
            content-class="tw-max-h-96 tw-overflow-y-auto"
            placement="auto"
            target-class="tw-h-full"
        >
            <template #button>
                <div
                    class="SwapOrganizationButton"
                    :class="{
                        'SwapOrganizationButton--small': small
                    }"
                >
                    <div>
                        <MAvatar
                            class="tw-w-8"
                            fallback-initials
                            :name="nursery.name"
                            :url="nursery.logo_url"
                        />
                    </div>
                    <div class="SwapOrganizationButton__organization">
                        <div class="SwapOrganizationButton__organization-name">
                            {{ nursery.name }}
                        </div>
                        <div class="SwapOrganizationButton__organization-city">
                            {{ nursery.city }}
                        </div>
                    </div>
                    <div>
                        <div
                            class="SwapOrganizationButton__button"
                            :class="{
                                'SwapOrganizationButton__button--alt': alt,
                                'SwapOrganizationButton__button--clickable': orderedNurseries.length > 1,
                                'SwapOrganizationButton__button--small': small
                            }"
                        >
                            <font-awesome-icon
                                v-if="orderedNurseries.length > 1"
                                icon="fas fa-right-left"
                            />
                        </div>
                    </div>
                </div>
            </template>
            <CDropdownMenuItem
                v-for="item in orderedNurseries"
                :key="'swap-nursery-' + item.id"
                even-child
                :to="getSwitchedRoute(item)"
            >
                <CInline
                    align-y="baseline"
                    space="sm"
                >
                    <CBadge
                        v-if="item.city"
                        class="tw-whitespace-nowrap"
                        size="sm"
                        variant="primary"
                    >
                        {{ item.city }}
                    </CBadge>
                    <div class="tw-whitespace-nowrap">
                        {{ item.name }}
                    </div>
                </CInline>
            </CDropdownMenuItem>
        </CDropdownMenu>
    </MHoverable>
</template>

<script lang="ts">
    import type {PropType} from 'vue';
    import {computed, defineComponent} from 'vue';
    import {orderBy} from 'lodash-es';
    import {useRoute} from 'vue-router/composables';

    type NurseryType = {
        city: string,
        name: string
    };

    type UserType = {
        nurseries: any[]
    };

    export default defineComponent({
        props: {
            user: {type: Object as PropType<UserType>, required: true},
            nursery: {type: Object as PropType<NurseryType>, required: true},
            buttonClass: {type: [String, Object, Array], default: undefined, required: false},
            alt: {type: Boolean, default: false, required: false},
            small: {type: Boolean, default: false, required: false},
        },
        setup(props) {
            const orderedNurseries = computed(() => {
                return orderBy(props.user.nurseries, 'name');
            });

            function getSwitchedRoute(nursery) {
                if (useRoute().meta && useRoute().meta?.redirectOnSwap) {
                    return {
                        name: useRoute().meta?.redirectOnSwap,
                        params: {nursery: nursery.id},
                        query: useRoute().query,
                    };
                }

                return {name: useRoute().name, params: {nursery: nursery.id}, query: useRoute().query};
            }

            return {
                orderedNurseries,
                getSwitchedRoute,
            };
        },
    });
</script>

<style lang="scss" scoped>
    .SwapOrganizationButton {
        @apply tw-gap-x-2 tw-cursor-pointer;
        @apply tw-flex #{!important};
    }

    .SwapOrganizationButton__button {
        @apply tw-p-1.5 tw-bg-primary-800/20 tw-text-primary-950/50 tw-w-full tw-rounded-lg tw-justify-center;
        @apply tw-flex tw-items-center tw-h-full;
    }

    .SwapOrganizationButton__button--clickable {
        @apply hover:tw-bg-primary-800/30;
        @apply tw-transition-all tw-duration-200 tw-ease-in-out;
    }

    .SwapOrganizationButton__button--small {
        @apply tw-rounded-lg;

        .SwapOrganizationButton__organization {
            @apply tw-gap-0.5;
        }

        .SwapOrganizationButton__logo {
            @apply tw-rounded-md tw-w-8;
        }
    }

    .SwapOrganizationButton__button--alt {
        @apply tw-bg-primary-800/20 hover:tw-bg-primary-700/30;
        //@apply tw-shadow tw-bg-white;
        @apply tw-ring tw-ring-0;
    }

    .SwapOrganizationButton__logo {
        @apply tw-w-10 tw-aspect-square tw-rounded-lg tw-bg-contain tw-bg-center tw-bg-no-repeat tw-self-start;
    }

    .SwapOrganizationButton__organization {
        @apply tw-flex tw-flex-1 tw-flex-col;
        @apply tw-gap-1;
    }

    .SwapOrganizationButton__organization-name {
        @apply tw-text-base tw-font-display tw-font-medium tw-leading-none;
        @apply tw-text-primary-950;
    }

    .SwapOrganizationButton__organization-city {
        @apply tw-text-sm tw-font-display tw-font-normal tw-leading-none;
        @apply tw-text-gray-500;
    }
</style>
