<template>
    <div>
        <transition>
            <div v-if="!loading">
                <MHeading class="tw-mb-4">
                    {{ __('common:the_kids') }}
                </MHeading>
                <div
                    v-for="(kid, i) in kids"
                    :key="'kid' + i"
                    class="d-flex flex-column flex-lg-row"
                    style="margin: 0 -20px; padding: 15px 20px; border-top: 1px solid #ecf0f4;"
                >
                    <div class="d-flex align-items-center order-lg-1">
                        <LegacyKidAvatar
                            class="tw-w-12"
                            :kid="kid"
                        />
                        <div class="tw-ml-4 mr-auto tw-font-grandhotel tw-text-3xl tw-capitalize">
                            <router-link
                                :class="kid.gender === 'male' ? 'tw-text-boy' : 'tw-text-girl'"
                                :to="{name:'kids.show',params:{nursery:nursery.id, kid:kid.id}}"
                            >
                                {{ $fullName(kid) }}
                            </router-link>
                        </div>
                    </div>
                    <div class="meta d-flex flex-column flex-lg-row align-items-lg-center order-lg-2 ml-lg-auto mt-3 mt-lg-0 mr-lg-4">
                        <div class="mr-3 text-lg-right tw-text-lg mb-0">
                            <template v-if="kid.permissions && kid.permissions.length">
                                <div class="lg:tw-block tw-hidden">
                                    <MTooltip
                                        :label="__('family_member:access_parent_application_enabled')"
                                        placement="top"
                                    >
                                        <FontAwesomeIcon
                                            class="tw-text-green-500"
                                            fixed-width
                                            icon="fa fa-lg fa-mobile"
                                        />
                                    </MTooltip>
                                </div>
                                <span class="lg:tw-hidden tw-block">
                                    <FontAwesomeIcon
                                        class="tw-text-green-500"
                                        fixed-width
                                        icon="fa fa-lg fa-mobile"
                                    />
                                    {{ __('family_member:access_parent_application_enabled') }}
                                </span>
                            </template>
                            <template v-else>
                                <div class="lg:tw-block tw-hidden">
                                    <MTooltip
                                        :label="__('family_member:access_parent_application_disabled')"
                                        placement="top"
                                    >
                                        <FontAwesomeIcon
                                            class="tw-text-gray-500"
                                            fixed-width
                                            icon="fa fa-lg fa-mobile"
                                        />
                                    </MTooltip>
                                </div>
                                <span class="lg:tw-hidden tw-block">
                                    <FontAwesomeIcon
                                        class="tw-text-gray-500"
                                        fixed-width
                                        icon="fa fa-lg fa-mobile"
                                    />
                                    {{ __('family_member:access_parent_application_disabled') }}
                                </span>
                            </template>
                        </div>
                        <div class="mr-3 text-lg-right tw-text-lg mb-0">
                            <template v-if="kid.can_pickup_kid">
                                <div class="lg:tw-block tw-hidden">
                                    <MTooltip
                                        :label="__('family_member:pick_up_child.authorized')"
                                        placement="top"
                                    >
                                        <FontAwesomeIcon
                                            class="tw-text-green-500"
                                            fixed-width
                                            icon="fa fa-lg fa-hands-helping"
                                        />
                                    </MTooltip>
                                </div>
                                <span class="lg:tw-hidden tw-block">
                                    <FontAwesomeIcon
                                        class="tw-text-green-500"
                                        fixed-width
                                        icon="fa fa-lg fa-hands-helping"
                                    />
                                    {{ __('family_member:pick_up_child.authorized') }}
                                </span>
                            </template>
                            <template v-else>
                                <div class="lg:tw-block tw-hidden">
                                    <MTooltip
                                        :label="__('family_member:pick_up_child.unauthorized')"
                                        placement="top"
                                    >
                                        <FontAwesomeIcon
                                            class="tw-text-gray-500"
                                            fixed-width
                                            icon="fa fa-lg fa-hands-helping"
                                        />
                                    </MTooltip>
                                </div>
                                <span class="lg:tw-hidden tw-block">
                                    <FontAwesomeIcon
                                        class="tw-text-gray-500"
                                        fixed-width
                                        icon="fa fa-lg fa-hands-helping"
                                    />
                                    {{ __('family_member:pick_up_child.unauthorized') }}
                                </span>
                            </template>
                        </div>
                    </div>
                    <div class="align-self-center order-lg-4 lg:tw-mt-0 tw-mt-2">
                        <MDropdown placement="left">
                            <MButton
                                :label="__('family_member:update_rights')"
                                variant="primary"
                            />
                            <template #content>
                                <CForm>
                                    <CFormGroup>
                                        <MemberPermissionsInput
                                            v-model="kid.permissions"
                                            @input="updateKidPivot(kid, i)"
                                        />
                                    </CFormGroup>
                                    <CFormGroup>
                                        <CLabel icon="fa fa-hands-helping">
                                            {{ __('family_member:pick_up_child') }}
                                        </CLabel>
                                        <CFormSelect
                                            v-model="kid.can_pickup_kid"
                                            :options="[{value: 1, text: __('common:authorized')}, {value: 0, text: __('common:unauthorized')}]"
                                        />
                                    </CFormGroup>
                                </CForm>
                            </template>
                        </MDropdown>
                    </div>
                </div>
                <h1
                    v-if="!Object.keys(kids).length"
                    class="h1 tw-font-grandhotel text-center"
                >
                    {{ __('common:no_kids') }}
                </h1>
            </div>
            <div
                v-else
                key="loader"
            >
                <loader custom-class="la-2x"/>
            </div>
        </transition>
        <!--<store-family-member :nursery="nursery" :kid="kid" :familyMember="newFamilyMember" @add="storeFamilyMember"></store-family-member>
            <edit-family-member :nursery="nursery" :kid="kid" :familyMember="selectedFamilyMember" :withPivot="true" @update="updateFamilyMember" @delete="deleteFamilyMember" @detach="detachFamilyMember"></edit-family-member>
        -->
    </div>
</template>

<script>
    import Vue from 'vue';
    import route from '@/modules/legacy/libs/ziggy';
    import useApi from '@/modules/app/composables/useApi';
    import moment from 'moment';
    import MemberPermissionsInput from '@/modules/family/components/member/MemberPermissionsInput.vue';
    import LegacyKidAvatar from '@/modules/app/components/atoms/avatars/LegacyKidAvatar.vue';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';

    export default {
        components: {LegacyKidAvatar, MemberPermissionsInput},
        props: ['nursery', 'familyMember'],
        data: () => ({
            loading: 1,
            kids: [],
            selectedKid: {},
            newKid: {},
            defaultPermissions: {
                all: ['photos', 'meals', 'temperatures', 'drugs', 'diapers', 'naps', 'weights', 'presences', 'activities', 'observations', 'messages', 'invoices', 'transactions', 'staffs', 'posts', 'documents'],
                intermediary: ['photos', 'messages', 'invoices', 'transactions', 'staffs', 'posts', 'documents'],
            },
        }),
        computed: {
            moment() {
                return moment;
            },
        },
        watch: {
            'familyMember.id': function() {
                this.getKids();
            },
        },
        mounted() {
            this.getKids();
        },
        methods: {
            getKids() {
                this.loading = 1;
                useApi().legacy.get(route('nurseries.familyMembers.kids.index', {
                    nurseries: this.nursery.id,
                    familyMember: this.familyMember.id,
                })).then(response => {
                    this.loading = 0;
                    this.kids = response.data;
                }).catch(error => {
                    useNotification().error(error);
                });
            },
            updateKidPivot(kid, i) {
                Vue.set(this.kids, i, kid);
                useApi().legacy.put(route('nurseries.familyMembers.kids.update', {
                    nurseries: this.nursery.id,
                    familyMember: this.familyMember.id,
                    kid: kid.id,
                }), kid).then(() => {
                }).catch(error => {
                    useNotification().error(error);
                });
            },
            getAge(birthdate) {
                const age = this.moment().diff(this.moment(birthdate), 'years');
                if (age <= 1) {
                    return this.moment().diff(this.moment(birthdate), 'months') + ' mois';
                }

                return age + ' ans';
            },
        },
    };
</script>

<style
    lang="scss"
    scoped
>
    @import "bootstrap/scss/functions";
    @import "@/assets/_bootstrap/variables";
    @import "bootstrap/scss/mixins/breakpoints";

    .name {
        @include media-breakpoint-up(lg) {
            width: calc(100% - 520px) !important;
        }

        a {
            color: inherit;
        }
    }

    .avatar {
        height: 50px;
        width: 50px;
    }

    .meta {
        //.group {
        //    @include media-breakpoint-up(lg) {
        //        width: 200px !important;
        //    }
        //}
        .date {
            @include media-breakpoint-up(lg) {
                width: 100px !important;
            }
        }
    }

</style>
