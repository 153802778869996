import type {QueryBuilder} from '@meekohq/lumos';
import {Model} from '@meekohq/lumos';
import useAuth from '@/modules/app/composables/useAuth';
import KidModel from '@/modules/family/models/KidModel';
import MemberModel from '@/modules/family/models/MemberModel';
import StaffModel from '@/modules/human-resources/models/StaffModel';
import RegistrationModel from '@/modules/registration/models/RegistrationModel';
import CustomerModel from '@/modules/cashier/models/CustomerModel';
import TenantModel from '@/modules/cashier/models/TenantModel';
import MedicalActionModel from '@/modules/health/models/MedicalActionModel';

export default class TicketPivot extends Model {

    public type = 'activity/tickets_pivot';

    public attributes: {
        id: string,
        account_id: string | undefined,
        ticket_id: string | undefined,
        resource_id: string | undefined,
        resource_type: string | undefined,

    } = {
            id: this.uuid(),
            account_id: undefined,
            ticket_id: undefined,
            resource_id: undefined,
            resource_type: undefined,
        };

    public static withResourceOrganizations<T extends Model>(query: QueryBuilder<T>) {
        return query.with(new TicketPivot().resource(), (query2, relation) => {
            relation.morphWith(KidModel, 'organizations');
            relation.morphWith(MemberModel, 'organizations');
            relation.morphWith(RegistrationModel, 'organizations');
            relation.morphWith(StaffModel, 'organizations');
            relation.morphWith(MedicalActionModel, 'kid', query3 => {
                query3.with('organizations');
            });
            relation.morphWith(CustomerModel, 'tenant', query3 => {
                query3.with('organizations');
            });
        });
    }

    resource() {
        return this.morphTo('resource');
    }

    scopeOnlyAllowed<T extends Model>(query: QueryBuilder<T>) {
        const nurseries = useAuth().legacyUser.value.nurseries;

        // Get the ids of nurseries where the user has the read permission on the passed permission name
        const allowedNurseriesWhenReadPermission = permissionName => {
            return nurseries.filter(nursery => {
                return nursery.permissions.find(nurseryPermission => {
                    return nurseryPermission.name === permissionName && nurseryPermission.actions.includes('read');
                });
            }).map(nursery => nursery.id);
        };

        query.where(query2 => {
            query2.whereHas('organization', query3 => {
                query3.whereIn('organizations.id', nurseries.map(nursery => nursery.id));
            })
                .orWhereHas('kid', query3 => {
                    query3.whereHas(new KidModel().organizations(), query4 => query4.whereIn('organizations.id', allowedNurseriesWhenReadPermission('kids_details')));
                })
                .orWhereHas('member', query3 => {
                    query3.whereHas(new MemberModel().organizations(), query4 => query4.whereIn('organizations.id', allowedNurseriesWhenReadPermission('family_members_details')));
                })
                .orWhereHas('staff', query3 => {
                    query3.whereHas(new StaffModel().organizations(), query4 => query4.whereIn('organizations.id', allowedNurseriesWhenReadPermission('staffs_details')));
                })
                .orWhereHas('registration', query3 => {
                    query3.whereHas(new RegistrationModel().organizations(), query4 => query4.whereIn('organizations.id', allowedNurseriesWhenReadPermission('registrations')));
                })
                .orWhereHas('customer', query3 => {
                    query3.whereHas(new CustomerModel().tenant(), query4 => {
                        query4.whereHas(new TenantModel().organizations(), query5 => query5.whereIn('organizations.id', allowedNurseriesWhenReadPermission('transactions')));
                    });
                });
        });
    }
}
