import Vue from 'vue';
import type {Route, RouteConfig} from 'vue-router';
import Router from 'vue-router';
import useAbility from '@/modules/app/composables/useAbility';

Vue.use(Router);

const {can, allows} = useAbility();

const routes: RouteConfig[] = [
    {
        path: '/login',
        name: 'login',
        component: () => import('@/pages/login.vue'),
        meta: {requiresGuest: true},
    },
    {
        path: '/register',
        name: 'register',
        component: () => import('@/pages/register.vue'),
        meta: {requiresGuest: true},
    },
    {
        path: '/password/reset',
        name: 'password.email',
        component: () => import('@/pages/password/reset/[token]/[email].vue'),
        meta: {requiresGuest: true},
    },
    {
        path: '/password/reset/:token/:email',
        name: 'password.reset',
        component: () => import('@/pages/password/reset.vue'),
        meta: {requiresGuest: true},
    },
    {
        path: '/support/login/:email',
        name: 'support-login',
        component: () => import('@/pages/support/login/[email].vue'),
    },
    {
        path: '/support/installer/:token',
        name: 'support-installer',
        component: () => import('@/pages/support/installer/[token].vue'),
    },
    {
        path: '/maintenance',
        name: 'maintenance',
        component: () => import('@/pages/maintenance.vue'),
    },
    {
        path: '/validate/email-verified',
        name: 'validate.email-verified',
        component: () => import('@/pages/validate/email-verified.vue'),
    },
    {
        path: '/validate/email-verified-failure',
        name: 'validate.email-verified-failure',
        component: () => import('@/pages/validate/email-verified-failure.vue'),
    },
    {
        path: '/',
        name: 'dashboard',
        component: () => import('@/pages/index.vue'),
        meta: {
            requiresAuth: true,
            requiresSubscribe: true,
        },
        children: [
            // *** Redirections ***
            {
                path: 'nurseries/:nursery/kids/:any*',
                redirect: (to: Route) => ({
                    path: to.path.replace('/kids', '/family/kids'),
                    query: to.query,
                }),
            },
            {
                path: 'nurseries/:nursery/familyMembers/:any*',
                redirect: (to: Route) => ({
                    path: to.path.replace('/familyMembers', '/family/members'),
                    query: to.query,
                }),
            },
            {
                path: 'nurseries/:nursery/families/:any*',
                redirect: (to: Route) => ({
                    path: to.path.replace('/families', '/family/families'),
                    query: to.query,
                }),
            },
            {
                path: 'nurseries/:nursery/staffs/:any*',
                redirect: (to: Route) => ({
                    path: to.path.replace('/staffs', '/hr/staffs'),
                    query: to.query,
                }),
            },
            {
                path: 'nurseries/:nursery/humanresources/:any*',
                redirect: (to: Route) => ({
                    path: to.path.replace('/humanresources', '/hr'),
                    query: to.query,
                }),
            },
            {
                path: 'nurseries/:nursery/hr/reports/register',
                redirect: (to: Route) => ({
                    path: to.path.replace('/hr/reports/register', '/hr/reports/registry'),
                    query: to.query,
                }),
            },
            {
                path: 'nurseries/:nursery/cashier/:any*',
                redirect: (to: Route) => ({
                    path: to.path.replace('/cashier', '/billing'),
                    query: to.query,
                }),
            },
            {
                path: 'nurseries/:nursery/billings/:any*',
                redirect: (to: Route) => ({
                    path: to.path.replace('/billings', '/billing'),
                    query: to.query,
                }),
            },
            {
                path: 'nurseries/:nursery/billings/customers/payments/:any*',
                redirect: (to: Route) => ({
                    path: to.path.replace('/billings/customers/payments', '/billing/customers/transactions'),
                    query: to.query,
                }),
            },
            {
                path: 'nurseries/:nursery/billings/payments/:any*',
                redirect: (to: Route) => ({
                    path: to.path.replace('/billings/payments', '/billing/transactions'),
                    query: to.query,
                }),
            },
            {
                path: 'nurseries/:nursery/settings/billings/:any*',
                redirect: (to: Route) => ({
                    path: to.path.replace('/settings/billings', '/billing/settings'),
                    query: to.query,
                }),
            },
            {
                path: 'nurseries/:nursery/settings/communication/:any*',
                redirect: (to: Route) => ({
                    path: to.path.replace('/settings/communication', '/communication/settings'),
                    query: to.query,
                }),
            },
            {
                path: 'nurseries/:nursery/settings/plannings/:any*',
                redirect: (to: Route) => ({
                    path: to.path.replace('/settings/plannings', '/planning/settings'),
                    query: to.query,
                }),
            },
            // *** End Redirections ***
            {
                path: 'nurseries',
                name: 'nurseries',
                component: () => import('@/pages/nurseries.vue'),
            },
            {
                path: 'nurseries/:nursery',
                redirect: (to: Route) => ({name: 'nursery', params: to.params}),
            },
            {
                path: 'nurseries/:nursery/unauthorized',
                name: 'unauthorized',
            },
            {
                path: 'nurseries/:nursery/dashboard',
                name: 'dashboard.show',
                component: () => import('@/pages/nurseries/[id]/dashboard.vue'),
                meta: {
                    access() {
                        return allows('access-to-dashboard-module');
                    },
                },
            },
            {
                path: 'nurseries/:nursery/planning',
                name: 'module.planning',
                beforeEnter(to, from, next) {
                    if (can('read', 'kids_planning')) {
                        return next({name: 'nursery', params: to.params});
                    }

                    if (can('read', 'staffs_planning')) {
                        return next({name: 'planning.staffs', params: to.params});
                    }

                    return next({name: 'nursery', params: to.params});
                },
                meta: {
                    access() {
                        return can('read', 'kids_planning')
                            || can('read', 'staffs_planning');
                    },
                },
            },
            {
                path: 'nurseries/:nursery/planning/kids',
                name: 'nursery',
                component: () => import('@/pages/nurseries/[id]/planning/kids/daily.vue'),
                meta: {
                    access() {
                        return allows('access-to-plannings-module')
                            && can('read', 'kids_planning');
                    },
                },
                beforeEnter(to, from, next) {
                    const latestKidsPlanning = window.localStorage.getItem('latestKidsPlanning');
                    switch (latestKidsPlanning) {
                        case 'daily':
                            next({
                                name: 'planning.kids',
                                params: to.params,
                                query: to.query,
                            });
                            break;
                        case 'weekly':
                            next({
                                name: 'planning.kids.weekly',
                                params: to.params,
                                query: to.query,
                            });
                            break;
                        case 'monthly':
                            next({
                                name: 'planning.kids.monthly',
                                params: to.params,
                                query: to.query,
                            });
                            break;
                        case 'yearly':
                            next({
                                name: 'planning.kids.yearly',
                                params: to.params,
                                query: to.query,
                            });
                            break;
                        default:
                            next({
                                name: 'planning.kids',
                                params: to.params,
                                query: to.query,
                            });
                    }
                },
            },
            {
                path: 'nurseries/:nursery/planning/kids/daily',
                name: 'planning.kids',
                component: () => import('@/pages/nurseries/[id]/planning/kids/daily.vue'),
                meta: {
                    access() {
                        return allows('access-to-plannings-module')
                            && can('read', 'kids_planning');
                    },
                },
                beforeEnter(to, from, next) {
                    window.localStorage.setItem('latestKidsPlanning', 'daily');
                    next();
                },
            },
            {
                path: 'nurseries/:nursery/planning/kids/weekly',
                name: 'planning.kids.weekly',
                component: () => import('@/pages/nurseries/[id]/planning/kids/weekly.vue'),
                meta: {
                    access() {
                        return allows('access-to-plannings-module')
                            && can('read', 'kids_planning');
                    },
                },
                beforeEnter(to, from, next) {
                    window.localStorage.setItem('latestKidsPlanning', 'weekly');
                    next();
                },
            },
            {
                path: 'nurseries/:nursery/planning/kids/monthly/:date?',
                name: 'planning.kids.monthly',
                component: () => import('@/pages/nurseries/[id]/planning/kids/monthly.vue'),
                meta: {
                    access() {
                        return allows('access-to-plannings-module')
                            && can('read', 'kids_planning');
                    },
                },
                beforeEnter(to, from, next) {
                    window.localStorage.setItem('latestKidsPlanning', 'monthly');
                    next();
                },
            },
            {
                path: 'nurseries/:nursery/planning/kids/yearly/:date?',
                name: 'planning.kids.yearly',
                component: () => import('@/pages/nurseries/[id]/planning/kids/yearly.vue'),
                meta: {
                    access() {
                        return allows('access-to-plannings-module')
                            && can('read', 'kids_planning');
                    },
                },
                beforeEnter(to, from, next) {
                    window.localStorage.setItem('latestKidsPlanning', 'yearly');
                    next();
                },
            },
            {
                path: 'nurseries/:nursery/planning/staffs',
                name: 'planning.staffs',
                component: () => import('@/pages/nurseries/[id]/planning/staffs/daily.vue'),
                meta: {
                    access() {
                        return allows('access-to-plannings-module')
                            && can('read', 'staffs_planning');
                    },
                },
                beforeEnter(to, from, next) {
                    const latestStaffsPlanning = window.localStorage.getItem('latestStaffsPlanning');
                    switch (latestStaffsPlanning) {
                        case 'daily':
                            next({
                                name: 'planning.staffs.daily',
                                params: to.params,
                                query: to.query,
                            });
                            break;
                        case 'weekly':
                            next({
                                name: 'planning.staffs.weekly',
                                params: to.params,
                                query: to.query,
                            });
                            break;
                        default:
                            next({
                                name: 'planning.staffs.daily',
                                params: to.params,
                                query: to.query,
                            });
                    }
                },
            },
            {
                path: 'nurseries/:nursery/planning/staffs/daily',
                name: 'planning.staffs.daily',
                component: () => import('@/pages/nurseries/[id]/planning/staffs/daily.vue'),
                meta: {
                    access() {
                        return allows('access-to-plannings-module')
                            && can('read', 'staffs_planning');
                    },
                },
                beforeEnter(to, from, next) {
                    window.localStorage.setItem('latestStaffsPlanning', 'daily');
                    next();
                },
            },
            {
                path: 'nurseries/:nursery/planning/staffs/weekly',
                name: 'planning.staffs.weekly',
                component: () => import('@/pages/nurseries/[id]/planning/staffs/weekly.vue'),
                meta: {
                    access() {
                        return allows('access-to-plannings-module')
                            && can('read', 'staffs_planning');
                    },
                },
                beforeEnter(to, from, next) {
                    window.localStorage.setItem('latestStaffsPlanning', 'weekly');
                    next();
                },
            },
            {
                path: 'nurseries/:nursery/family',
                name: 'module.family',
                beforeEnter(to, from, next) {
                    if (allows('access-to-kids-module')) {
                        return next({name: 'kids.index', params: to.params});
                    }

                    if (allows('access-to-family-members-module')) {
                        return next({name: 'familyMembers.index', params: to.params});
                    }

                    return next({name: 'kids.index', params: to.params});
                },
                meta: {
                    access() {
                        return allows('access-to-kids-module')
                            || allows('access-to-family-members-module');
                    },
                },
            },
            {
                path: 'nurseries/:nursery/family/kids',
                name: 'kids.index',
                component: () => import('@/pages/nurseries/[id]/family/kids/index.vue'),
                meta: {
                    access() {
                        return allows('access-to-kids-module');
                    },
                },
            },
            {
                path: 'nurseries/:nursery/family/kids/:kid',
                name: 'kids.show',
                component: () => import('@/pages/nurseries/[id]/family/kids/[id].vue'),
                meta: {
                    access() {
                        return allows('access-to-kids-module');
                    },
                    redirectOnSwap: 'kids.index',
                },
                children: [
                    {
                        path: 'documents/create',
                        name: 'kids.show.documents.create',
                        component: () => import('@/pages/nurseries/[id]/family/kids/[id]/documents/create.vue'),
                        meta: {
                            access(inherited) {
                                return inherited
                                    && can('read', 'kids_details')
                                    && can('create', 'kids_documents');
                            },
                            redirectOnSwap: 'kids.index',
                        },
                    },
                    {
                        path: 'documents/:document/edit',
                        name: 'kids.show.documents.edit',
                        component: () => import('@/pages/nurseries/[id]/family/kids/[id]/documents/[id]/edit.vue'),
                        meta: {
                            access(inherited) {
                                return inherited
                                    && can('read', 'kids_details')
                                    && can('update', 'kids_documents');
                            },
                            redirectOnSwap: 'kids.index',
                        },
                    },
                ],
            },
            {
                path: 'nurseries/:nursery/family/members',
                name: 'familyMembers.index',
                component: () => import('@/pages/nurseries/[id]/family/members/index.vue'),
                meta: {
                    access() {
                        return allows('access-to-family-members-module');
                    },
                },
            },
            {
                path: 'nurseries/:nursery/family/members/:familyMember',
                name: 'familyMembers.show',
                component: () => import('@/pages/nurseries/[id]/family/members/[id].vue'),
                meta: {
                    access() {
                        return allows('access-to-family-members-module');
                    },
                    redirectOnSwap: 'familyMembers.index',
                },
                children: [
                    {
                        path: 'documents/create',
                        name: 'familyMembers.show.documents.create',
                        component: () => import('@/pages/nurseries/[id]/family/members/[id]/documents/create.vue'),
                        meta: {
                            access(inherited) {
                                return inherited
                                    && can('read', 'family_members_details')
                                    && can('create', 'family_members_documents');
                            },
                            redirectOnSwap: 'familyMembers.index',
                        },
                    },
                    {
                        path: 'documents/:document/edit',
                        name: 'familyMembers.show.documents.edit',
                        component: () => import('@/pages/nurseries/[id]/family/members/[id]/documents/[id]/edit.vue'),
                        meta: {
                            access(inherited) {
                                return inherited
                                    && can('read', 'family_members_details')
                                    && can('update', 'family_members_documents');
                            },
                            redirectOnSwap: 'familyMembers.index',
                        },
                    },
                ],
            },
            {
                path: 'nurseries/:nursery/family/families',
                name: 'families.index',
                component: () => import('@/pages/nurseries/[id]/family/families/index.vue'),
                meta: {
                    access() {
                        return allows('access-to-families-module');
                    },
                },
            },
            {
                path: 'nurseries/:nursery/family/families/:family',
                name: 'families.show',
                component: () => import('@/pages/nurseries/[id]/family/families/[id].vue'),
                meta: {
                    access() {
                        return allows('access-to-families-module');
                    },
                },
            },
            {
                path: 'nurseries/:nursery/registrations',
                name: 'registrations.index',
                component: () => import('@/pages/nurseries/[id]/registrations/index.vue'),
                meta: {
                    access() {
                        return allows('access-to-registrations-module');
                    },
                },
            },
            {
                path: 'nurseries/:nursery/registrations/:registration',
                name: 'registrations.show',
                component: () => import('@/pages/nurseries/[id]/registrations/[id].vue'),
                meta: {
                    access() {
                        return allows('access-to-registrations-module');
                    },
                    redirectOnSwap: 'registrations.index',
                },
            },
            {
                path: 'nurseries/:nursery/billing',
                name: 'module.billing',
                beforeEnter(to, from, next) {
                    if (can('read', 'invoices')) {
                        return next({name: 'billings.index', params: to.params});
                    }

                    if (can('read', 'transactions')) {
                        return next({name: 'payments.index', params: to.params});
                    }

                    return next({name: 'billings.index', params: to.params});
                },
                meta: {
                    access() {
                        return allows('access-to-billings-module')
                            && (
                                can('read', 'invoices')
                                || can('read', 'transactions')
                            );
                    },
                },
            },
            {
                path: 'nurseries/:nursery/billing/invoices',
                name: 'billings.index',
                component: () => import('@/pages/nurseries/[id]/billing/invoices/index.vue'),
                meta: {
                    access() {
                        return allows('access-to-billings-module')
                            && can('read', 'invoices');
                    },
                },
                beforeEnter(to, from, next) {
                    const defaultView = window.localStorage.getItem('invoices:defaultView');
                    if (defaultView && defaultView === 'list') {
                        next({
                            name: 'invoices.index',
                            params: to.params,
                            query: to.query,
                        });
                    } else {
                        next();
                    }
                },
            },
            {
                path: 'nurseries/:nursery/billing/invoices/list',
                name: 'invoices.index',
                component: () => import('@/pages/nurseries/[id]/billing/invoices/list.vue'),
                meta: {
                    access() {
                        return allows('access-to-billings-module')
                            && can('read', 'invoices');
                    },
                },
            },
            {
                path: 'nurseries/:nursery/billing/transactions',
                name: 'payments.index',
                component: () => import('@/pages/nurseries/[id]/billing/transactions/index.vue'),
                meta: {
                    access() {
                        return allows('access-to-billings-module')
                            && can('read', 'transactions');
                    },
                },
                children: [
                    {
                        path: 'create',
                        name: 'payments.create',
                        component: () => import('@/pages/nurseries/[id]/billing/transactions/create.vue'),
                        meta: {
                            access(inherited) {
                                return inherited.value
                                    && can('create', 'transactions');
                            },
                        },
                    },
                    {
                        path: ':transaction/edit',
                        name: 'payments.edit',
                        component: () => import('@/pages/nurseries/[id]/billing/transactions/edit-[id].vue'),
                        meta: {
                            access(inherited) {
                                return inherited.value
                                    && can('update', 'transactions');
                            },
                        },
                    },
                ],
            },
            {
                path: 'nurseries/:nursery/billing/customers',
                name: 'cashier/customers.index',
                component: () => import('@/pages/nurseries/[id]/billing/customers/index.vue'),
                meta: {
                    access() {
                        return allows('access-to-billings-module');
                    },
                },
            },
            {
                path: 'nurseries/:nursery/billing/customers/:customer',
                name: 'cashier/customers.show',
                component: () => import('@/pages/nurseries/[id]/billing/customers/[id].vue'),
                meta: {
                    access() {
                        return allows('access-to-billings-module');
                    },
                },
                children: [
                    {
                        path: 'summary',
                        name: 'cashier/customers.show.summary',
                        component: () => import('@/pages/nurseries/[id]/billing/customers/[id]/summary.vue'),
                    },
                    {
                        path: 'invoices',
                        name: 'cashier/customers.show.invoices',
                        component: () => import('@/pages/nurseries/[id]/billing/customers/[id]/invoices.vue'),
                        meta: {
                            access(inherited) {
                                return inherited
                                    && can('read', 'invoices');
                            },
                            redirectOnSwap: 'cashier/customers.show',
                        },
                    },
                    {
                        path: 'transactions',
                        name: 'cashier/customers.show.payments',
                        component: () => import('@/pages/nurseries/[id]/billing/customers/[id]/transactions.vue'),
                        meta: {
                            access(inherited) {
                                return inherited && can('read', 'transactions');
                            },
                            redirectOnSwap: 'cashier/customers.show',
                        },
                        children: [
                            {
                                path: 'create',
                                name: 'cashier/customers.show.payments.create',
                                component: () => import('@/pages/nurseries/[id]/billing/customers/[id]/transactions/create.vue'),
                                meta: {
                                    access(inherited) {
                                        return inherited
                                            && can('create', 'transactions');
                                    },
                                },
                            },
                        ],
                    },
                ],
            },
            {
                path: 'nurseries/:nursery/billing/settings',
                name: 'settings.billings',
                component: () => import('@/pages/nurseries/[id]/billing/settings.vue'),
                meta: {
                    access() {
                        return allows('access-to-billings-module')
                            && can('settings', 'invoices');
                    },
                },
                children: [
                    {
                        path: 'general',
                        name: 'settings.billings.general',
                        component: () => import('@/pages/nurseries/[id]/billing/settings/general.vue'),
                    },
                    {
                        path: 'rules',
                        name: 'settings.billings.rules',
                        component: () => import('@/pages/nurseries/[id]/billing/settings/rules.vue'),
                    },
                    {
                        path: 'invoices',
                        name: 'settings.billings.invoices',
                        component: () => import('@/pages/nurseries/[id]/billing/settings/invoices.vue'),
                    },
                    {
                        path: 'sepa',
                        name: 'settings.billings.sepa',
                        component: () => import('@/pages/nurseries/[id]/billing/settings/sepa.vue'),
                    },
                ],
            },
            {
                path: 'nurseries/:nursery/hr',
                name: 'module.hr',
                beforeEnter(to, from, next) {
                    if (can('read', 'staffs_details')) {
                        return next({name: 'staffs.index', params: to.params});
                    }

                    if (
                        can('read', 'staffs_statistics')
                        || can('read', 'staffs_balances')
                        || can('read', 'staffs_contracts')
                    ) {
                        return next({name: 'humanresources/reports', params: to.params});
                    }

                    return next({name: 'staffs.index', params: to.params});
                },
                meta: {
                    access() {
                        return allows('access-to-hr-module');
                    },
                },
            },
            {
                path: 'nurseries/:nursery/hr/staffs',
                name: 'staffs.index',
                component: () => import('@/pages/nurseries/[id]/hr/staffs/index.vue'),
                meta: {
                    access() {
                        return allows('access-to-hr-module')
                            && can('read', 'staffs_details');
                    },
                },
            },
            {
                path: 'nurseries/:nursery/hr/staffs/:staff',
                name: 'staffs.show',
                component: () => import('@/pages/nurseries/[id]/hr/staffs/[id].vue'),
                meta: {
                    access() {
                        return allows('access-to-hr-module')
                            && can('read', 'staffs_details');
                    },
                    redirectOnSwap: 'staffs.index',
                },
                children: [
                    {
                        path: 'documents/create',
                        name: 'staffs.show.documents.create',
                        component: () => import('@/pages/nurseries/[id]/hr/staffs/[id]/documents/create.vue'),
                        meta: {
                            access(inherited) {
                                return inherited
                                    && can('create', 'staffs_documents');
                            },
                            redirectOnSwap: 'staffs.index',
                        },
                    },
                    {
                        path: 'documents/:document/edit',
                        name: 'staffs.show.documents.edit',
                        component: () => import('@/pages/nurseries/[id]/hr/staffs/[id]/documents/[id]/edit.vue'),
                        meta: {
                            access(inherited) {
                                return inherited
                                    && can('update', 'staffs_documents');
                            },
                            redirectOnSwap: 'staffs.index',
                        },
                    },
                ],
            },
            {
                path: 'nurseries/:nursery/hr/reports',
                name: 'humanresources/reports',
                beforeEnter(to, from, next) {
                    // As the component is used as router, beforeEnter is called for each child
                    // So we need to check if the route is the parent one to avoid infinite loop
                    if (to.name === 'humanresources/reports') {
                        if (can('read', 'staffs_statistics')) {
                            return next({name: 'humanresources/reports.statistics', params: to.params});
                        }

                        if (can('read', 'staffs_balances')) {
                            return next({name: 'humanresources/reports.balances', params: to.params});
                        }

                        return next({name: 'humanresources/reports.register', params: to.params});
                    }

                    return next();
                },
                component: () => import('@/pages/nurseries/[id]/hr/reports.vue' ),
                meta: {
                    access() {
                        return allows('access-to-hr-module')
                            && (
                                can('read', 'staffs_statistics')
                                || can('read', 'staffs_balances')
                                || can('read', 'staffs_contracts')
                            );
                    },
                },
                children: [
                    {
                        path: 'statistics',
                        name: 'humanresources/reports.statistics',
                        component: () => import('@/pages/nurseries/[id]/hr/reports/statistics.vue' ),
                        meta: {
                            access(inherited) {
                                return inherited
                                    && can('read', 'staffs_statistics');
                            },
                            redirectOnSwap: 'staffs.index',
                        },
                    },
                    {
                        path: 'balances',
                        name: 'humanresources/reports.balances',
                        component: () => import('@/pages/nurseries/[id]/hr/reports/balances.vue' ),
                        meta: {
                            access(inherited) {
                                return inherited
                                    && can('read', 'staffs_balances');
                            },
                            redirectOnSwap: 'staffs.index',
                        },
                    },
                    {
                        path: 'registry',
                        name: 'humanresources/reports.register',
                        component: () => import('@/pages/nurseries/[id]/hr/reports/registry.vue' ),
                        meta: {
                            redirectOnSwap: 'staffs.index',
                        },
                    },
                ],
            },
            {
                path: 'nurseries/:nursery/hr/settings',
                name: 'humanresources/settings',
                redirect: {name: 'humanresources/settings.common'},
                component: () => import('@/pages/nurseries/[id]/hr/settings.vue' ),
                meta: {
                    access() {
                        return allows('access-to-hr-module')
                            && (
                                can('settings', 'staffs_details')
                                || can('settings', 'staffs_planning')
                                || can('settings', 'staffs_balances')
                            );
                    },
                },
                children: [
                    {
                        path: 'common',
                        name: 'humanresources/settings.common',
                        redirect: 'common/jobs',
                        component: () => import('@/pages/nurseries/[id]/hr/settings/common.vue' ),
                        meta: {
                            access(inherited) {
                                return inherited
                                    && can('settings', 'staffs_details');
                            },
                        },
                        children: [
                            {
                                path: 'jobs',
                                name: 'humanresources/settings.common.jobs',
                                component: () => import('@/pages/nurseries/[id]/hr/settings/common/jobs.vue'),
                            },
                            {
                                path: 'teams',
                                name: 'humanresources/settings.common.teams',
                                component: () => import('@/pages/nurseries/[id]/hr/settings/common/teams.vue'),
                            },
                        ],
                    },
                    {
                        path: 'calendar',
                        name: 'humanresources/settings.calendar',
                        redirect: 'calendar/event-type',
                        component: () => import('@/pages/nurseries/[id]/hr/settings/calendar.vue' ),
                        meta: {
                            access(inherited) {
                                return inherited
                                    && (
                                        can('settings', 'staffs_planning')
                                        || can('settings', 'staffs_balances')
                                    );
                            },
                        },
                        children: [
                            {
                                path: 'event-type',
                                name: 'humanresources/settings.calendar.eventType',
                                component: () => import('@/pages/nurseries/[id]/hr/settings/calendar/event-type.vue'),
                                meta: {
                                    access(inherited) {
                                        return inherited
                                            && can('settings', 'staffs_planning');
                                    },
                                },
                            },
                            {
                                path: 'balance-type',
                                name: 'humanresources/settings.calendar.balanceType',
                                component: () => import('@/pages/nurseries/[id]/hr/settings/calendar/balance-type.vue'),
                                meta: {
                                    access(inherited) {
                                        return inherited
                                            && can('settings', 'staffs_balances');
                                    },
                                },
                            },
                        ],
                    },
                ],
            },
            {
                path: 'nurseries/:nursery/photos',
                name: 'photos.index',
                component: () => import('@/pages/nurseries/[id]/photos/index.vue'),
                meta: {
                    access() {
                        return allows('access-to-photos-module');
                    },
                },
            },
            {
                path: 'nurseries/:nursery/documents',
                name: 'documents.index',
                component: () => import('@/pages/nurseries/[id]/documents/index.vue'),
                meta: {
                    access() {
                        return allows('access-to-documents-module');
                    },
                },
                children: [
                    {
                        path: 'create',
                        name: 'documents.create',
                        component: () => import('@/pages/nurseries/[id]/documents/create.vue'),
                        meta: {
                            access(inherited) {
                                return inherited
                                    && can('create', 'documents');
                            },
                        },
                    },
                    {
                        path: ':document/edit',
                        name: 'documents.edit',
                        component: () => import('@/pages/nurseries/[id]/documents/[id]/edit.vue'),
                        meta: {
                            access(inherited) {
                                return inherited
                                    && can('update', 'documents');
                            },
                        },
                    },
                ],
            },
            {
                path: 'nurseries/:nursery/communication',
                name: 'module.communication',
                beforeEnter(to, from, next) {
                    return next({name: 'communication.index', params: to.params});
                },
            },
            {
                path: 'nurseries/:nursery/communication/news',
                name: 'communication.index',
                component: () => import('@/pages/nurseries/[id]/communication/news/index.vue'),
                meta: {
                    access() {
                        return allows('access-to-communication-module');
                    },
                },
            },
            {
                path: 'nurseries/:nursery/communication/settings',
                name: 'settings.communication',
                component: () => import('@/pages/nurseries/[id]/communication/settings.vue'),
                meta: {
                    access() {
                        return allows('access-to-communication-module')
                            && (
                                can('settings', 'posts')
                                || can('settings', 'nursery_website')
                            );
                    },
                },
            },
            {
                path: 'nurseries/:nursery/equipment',
                name: 'equipment.index',
                component: () => import('@/pages/nurseries/[id]/equipment/index.vue'),
                meta: {
                    access() {
                        return allows('access-to-equipments-module');
                    },
                },
            },
            {
                path: 'nurseries/:nursery/settings',
                name: 'settings',
                component: () => import('@/pages/nurseries/[id]/settings.vue'),
                meta: {
                    access() {
                        return allows('access-to-settings-module');
                    },
                },
            },
            {
                path: 'nurseries/:nursery/planning/settings',
                name: 'settings.plannings',
                component: () => import('@/pages/nurseries/[id]/planning/settings.vue'),
                meta: {
                    access() {
                        return allows('access-to-plannings-module');
                    },
                },
            },
            {
                path: 'nurseries/:nursery/settings/profile',
                name: 'settings.profile',
                component: () => import('@/pages/nurseries/[id]/settings/profile.vue'),
            },
            {
                path: 'nurseries/:nursery/settings/subscriptions',
                name: 'subscriptions',
                component: () => import('@/pages/nurseries/[id]/settings/subscriptions.vue'),
                meta: {
                    requiresSubscribe: false,
                },
                children: [
                    {
                        path: 'subscribe/:plan',
                        name: 'subscriptions.subscribe',
                        component: () => import('@/pages/nurseries/[id]/settings/subscriptions/subscribe/[plan].vue'),
                    },
                    {
                        path: 'plan',
                        name: 'subscriptions.plan',
                        component: () => import('@/pages/nurseries/[id]/settings/subscriptions/plan.vue'),
                    },
                    {
                        path: 'payments-methods',
                        name: 'subscriptions.paymentsMethods',
                        component: () => import('@/pages/nurseries/[id]/settings/subscriptions/payments-methods.vue'),
                    },
                    {
                        path: 'address',
                        name: 'subscriptions.address',
                        component: () => import('@/pages/nurseries/[id]/settings/subscriptions/address.vue'),
                    },
                    {
                        path: 'invoices',
                        name: 'subscriptions.invoices',
                        component: () => import('@/pages/nurseries/[id]/settings/subscriptions/invoices.vue'),
                    },
                    {
                        path: 'cancel',
                        name: 'subscriptions.cancel',
                        component: () => import('@/pages/nurseries/[id]/settings/subscriptions/cancel.vue'),
                    },
                ],
            },
        ],
    },
];

export default new Router({
    mode: 'history',
    base: import.meta.env.BASE_URL,
    routes,
});
