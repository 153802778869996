import {Model} from '@meekohq/lumos';
import MeetingParticipantAvailabilityValue from '@/modules/activity/utils/MeetingParticipantAvailabilityValue';
import MeetingModel from '@/modules/activity/models/MeetingModel';


export default class MeetingParticipantModel extends Model {

    public type = 'activity/meetings_participants';

    public attributes: {
        id: string,
        account_id: string | undefined,
        meeting_id: string | undefined,
        first_name: string | null,
        last_name: string | null,
        email: string | null,
        availability: MeetingParticipantAvailabilityValue,
        token: string | undefined,
        resource_id: string | null,
        resource_type: string | null,

    } = {
            id: this.uuid(),
            account_id: undefined,
            meeting_id: undefined,
            first_name: null,
            last_name: null,
            email: null,
            availability: MeetingParticipantAvailabilityValue.pending,
            token: undefined,
            resource_id: null,
            resource_type: null,
        };

    meeting() {
        return this.belongsTo('meeting', MeetingModel, 'meeting_id');
    }

    resource() {
        return this.morphTo('resource');
    }
}
