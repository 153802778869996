export function useLocalStorage() {

    const getLocalStorage = function <T = string>(key: string, defaultValue: T | null = null): T | null {
        const value = window.localStorage.getItem(key);
        const parsedValue = value !== null && isJson(value) ? JSON.parse(value) : value;

        return value !== null
            ? parsedValue
            : defaultValue;
    };

    const setLocalStorage = function(key: string, value) {
        window.localStorage.setItem(key, JSON.stringify(value));
    };

    const removeLocalStorage = function(key: string) {
        window.localStorage.removeItem(key);
    };

    const isJson = function(str: string): boolean {
        try {
            return JSON.parse(str);
        } catch (e) {
            return false;
        }
    };

    return {
        getLocalStorage,
        setLocalStorage,
        removeLocalStorage,
    };
}
