<template>
    <CForm>
        <CFormGroup>
            <CLabel>{{ __('common:comment') }}</CLabel>
            <CFormTextArea
                v-model="note.attributes.description"
                autofocus
            />
            <CFormErrorMessageList
                v-if="noteErrors.hasError('description')"
                :errors="noteErrors.getErrors('description')"
            />
        </CFormGroup>
        <CFormGroup>
            <CLabel>{{ __('activity:reporter') }}</CLabel>
            <StaffFinder
                button-class="tw-w-full"
                has-active-contract
                :value="note.reporter().value()"
                @input="note.reporter().associate($event)"
            />
            <CFormErrorMessageList
                v-if="noteErrors.hasError('reporter_id')"
                :errors="noteErrors.getErrors('reporter_id')"
            />
        </CFormGroup>
        <CHStack
            distribute="end"
            gap="2"
        >
            <CButton
                :label="__('common:actions.cancel')"
                @click="undo"
            />
            <CButton
                :disabled="disableSaveButton"
                :label="__('common:actions.save')"
                :loading="loading"
                variant="primary"
                @click="save"
            />
        </CHStack>
    </CForm>
</template>

<script lang="ts">
    import type {PropType} from 'vue';
    import {defineComponent, onMounted, ref} from 'vue';
    import ErrorHandler from '@/modules/legacy/libs/errors/errorHandler';
    import type TicketModel from '@/modules/activity/models/TicketModel';
    import useAuth from '@/modules/app/composables/useAuth';
    import StaffModel from '@/modules/human-resources/models/StaffModel';
    import StaffFinder from '@/modules/request/components/Teams/StaffFinder.vue';

    export type Events = {
        save: undefined;
    };

    export default defineComponent({
        components: {StaffFinder},
        props: {
            note: {
                type: Object as PropType<TicketModel>,
                required: true,
            },
            disableSaveButton: {
                type: Boolean,
                default: false,
            },
        },
        setup(props, {emit}) {
            const noteErrors = ref(new ErrorHandler());
            const loading = ref(false);
            const {user} = useAuth();

            onMounted(() => {
                const isNoteHasntReporter = !props.note.exist && !props.note.reporter().value();

                if (isNoteHasntReporter && user.value.attributes.email) {
                    associateReporter(user.value.attributes.email);
                }
            });

            async function associateReporter(email: string) {
                const staff = await StaffModel.query().where('email', email).first();
                if (staff) {
                    props.note.reporter().associate(staff);
                }
            }

            async function save() {
                loading.value = true;

                try {
                    await props.note.save();
                    noteErrors.value.reset();

                    emit('saved', props.note);
                } catch (e) {
                    noteErrors.value.reset(e);
                } finally {
                    loading.value = false;
                }
            }

            const undo = function() {
                emit('undo');
            };

            return {
                loading,
                noteErrors,
                save,
                undo,
            };
        },
    });
</script>
