<template>
    <div class="table-responsive">
        <table class="table table-sm">
            <thead>
                <tr>
                    <th scope="col"/>
                    <th
                        v-for="(bracket, i) in brackets"
                        :key="'bracket ' + i"
                        scope="col"
                    >
                        {{ __('billing_setting:t_value', {value: bracket}) }}
                    </th>
                </tr>
            </thead>
            <tbody v-if="config.cmg.brackets && config.cmg.brackets.length">
                <tr>
                    <th scope="row">
                        {{ __('billing_setting:less_than_three_years') }}
                    </th>
                    <th
                        v-for="(price, i) in config.cmg.brackets"
                        :key="'price ' + i"
                    >
                        <CurrencyInput
                            v-model="price[0].value"
                            class="tw-font-normal"
                            :currency="nursery.currency"
                            placeholder="9"
                        />
                    </th>
                </tr>
                <tr>
                    <th scope="row">
                        {{ __('billing_setting:from_three_to_six_years') }}
                    </th>
                    <th
                        v-for="(price, i) in config.cmg.brackets"
                        :key="'price' + i"
                    >
                        <CurrencyInput
                            v-model="price[1].value"
                            class="tw-font-normal"
                            :currency="nursery.currency"
                            placeholder="9"
                        />
                    </th>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
    import CurrencyInput from '@/modules/app/components/atoms/CurrencyInput.vue';

    export default {
        components: {CurrencyInput},
        props: ['config', 'brackets', 'nursery'],
        data() {
            return {};
        },
        computed: {},
        watch: {
            brackets() {
                this.reset();
            },
        },
        mounted() {
            if (!this.config.cmg.brackets || !this.config.cmg.brackets.length) {
                this.reset();
            }
        },
        methods: {
            reset() {
                const cmg = [];
                for (let i = 0; i < this.brackets; i++) {
                    switch (i) {
                        case 0 :
                            cmg.push([
                                {value: 854.70},
                                {value: 427.35},
                            ]);
                            break;
                        case 1 :
                            cmg.push([
                                {value: 736.78},
                                {value: 368.39},
                            ]);
                            break;
                        case 2 :
                            cmg.push([
                                {value: 618.90},
                                {value: 309.45},
                            ]);
                            break;
                        default :
                            cmg.push([
                                {value: 1},
                                {value: 1},
                            ]);
                            break;
                    }
                }
                this.config.cmg.brackets = cmg;
            },
        },
    }
    ;
</script>

<style lang="scss" scoped>
    th {
        vertical-align: middle !important;
    }
</style>
