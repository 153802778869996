<template>
    <component
        :is="tag"
        :class="baseClass"
    >
        <slot/>
    </component>
</template>

<script lang="ts">
    import {computed, defineComponent} from 'vue';
    import {get} from 'lodash-es';

    export default defineComponent({
        components: {},
        props: {
            tag: {type: String, default: 'p'},
            size: {type: String, default: 'md'},
            margin: {type: String, default: '6'},
        },
        setup(props) {
            const baseClass = computed(() => {
                const output = [] as any[];

                output.push('last:tw-mb-0 tw-leading-normal');

                const margins = {
                    0: 'tw-mb-0',
                    1: 'tw-mb-1',
                    2: 'tw-mb-2',
                    3: 'tw-mb-3',
                    4: 'tw-mb-4',
                    5: 'tw-mb-5',
                    6: 'tw-mb-6',
                };

                output.push(margins[props.margin]);
                output.push(get(variantValues, props.size));

                return output;
            });

            return {
                baseClass,
            };
        },
    });

    export const variantValues = {
        sm: 'tw-text-sm tw-font-normal',
        md: 'tw-text-base tw-font-normal',
        lg: 'tw-text-lg tw-font-normal',
        intro: 'tw-text-3xl tw-font-thin',
    };
</script>

