<script setup lang="ts">
    import {type PropType, provide, toRef} from 'vue';
    import MFloatingContext from '@/modules/meeko-ui/components/MFloatingContext';
    import type {Placement} from '@floating-ui/vue';

    const props = defineProps({
        trigger: {
            type: String,
            default: 'click',
            validator: (value: string) => ['click', 'hover'].includes(value),
        },
        placement: {
            type: String as PropType<Placement>,
            default: 'bottom-start',
            validator: (value: string) => [
                'top',
                'top-start',
                'top-end',
                'right',
                'right-start',
                'right-end',
                'bottom',
                'bottom-start',
                'bottom-end',
                'left',
                'left-start',
                'left-end',
            ].includes(value),
        },
        initialFocusRef: {
            type: Object,
            default: null,
        },
        portal: {
            type: Boolean,
            default: true,
        },
    });

    const context = MFloatingContext({
        placement: toRef(props, 'placement'),
        trigger: toRef(props, 'trigger'),
        initialFocusRefEl: toRef(props, 'initialFocusRef'),
        portal: toRef(props, 'portal'),
    });

    provide('MFloatingContext', context);
</script>

<template>
    <div class="MPopover">
        <MPopoverButton>
            <slot/>
        </MPopoverButton>
        <MPopoverPanel>
            <slot name="content"/>
        </MPopoverPanel>
    </div>
</template>

<style scoped lang="scss">
    .MPopover {
        @apply tw-inline-flex;
    }
</style>
