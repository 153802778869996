<template>
    <div
        class="tw-absolute tw-bg-gray-200 hover:tw-bg-gray-300 tw-rounded-l-md tw-h-full tw-cursor-pointer"
        :class="segmentClass"
        :style="size === 'lg' ? 'margin-left: -22px;' : 'margin-left: -16px;'"
    >
        <div class="tw-flex tw-items-center tw-justify-evenly tw-w-full tw-h-full">
            <div
                v-for="(event, i) in events"
                :key="'staffSegmentFullDay' + event.id"
                :class="getEventClass(event, i)"
                :style="getEventStyle(event)"
            />
        </div>

        <CDropdown
            ref="fullDayTooltip"
            class="tw-z-50 tw-min-w-56 tw-max-w-80 tw-w-80"
            :clickable="false"
            hoverable
            legacy-mode
            placement="right"
        >
            <CText
                as="div"
                class="tw-w-full tw-border-b tw-border-gray-200 tw-pb-1 tw-mb-2"
                font-weight="semibold"
            >
                {{ __('hr_calendar:whole_day_long_colon') }}
            </CText>
            <div
                v-if="$can('create', 'staffs_planning')"
                class="tw-p-2 tw-mb-2"
            >
                <CButton
                    class="tw-w-full"
                    icon-plus
                    size="sm"
                    variant="primary"
                    @click="$emit('add')"
                >
                    {{ __('hr_calendar:add_event') }}
                </CButton>
            </div>
            <div
                v-for="event in events"
                :key="'staffSegmentFullDayDetails' + event.id"
                class="tw-border-t tw-border-gray-200 hover:tw-rounded-lg hover:tw-bg-gray-50 tw-p-2 tw-my-1"
                @click="$can('update', 'staffs_planning') ? updateEvent(event) : null"
            >
                <div class="tw-flex tw-items-center">
                    <div
                        v-if="getEventType(event).attributes.icon_id"
                        class="tw-flex tw-shrink-0 tw-items-center tw-justify-center tw-rounded-full tw-shadow tw-mr-3"
                        :class="getIconClass(event)"
                        :style="getIconStyle(event)"
                    >
                        <CIcon
                            :path="getEventType(event).icon.attributes.path"
                            :provider="getEventType(event).icon.attributes.provider"
                        />
                    </div>
                    <div class="tw-mr-3">
                        <div class="tw-font-semibold tw-opacity-90 tw-text-sm">
                            <i
                                v-if="event.isPending"
                                aria-hidden="true"
                                class="fad fa-hourglass-half tw-text-gray-700 tw-text-sm"
                            />
                            {{ event.attributes.note ? getEventType(event).attributes.name + ' • ' + event.attributes.note : getEventType(event).attributes.name }}
                        </div>

                        <div
                            v-if="getNurseryName(event) || event.kidsGroup().value()"
                            class="tw-opacity-90 tw-text-sm"
                        >
                            <template v-if="getNurseryName(event) && event.kidsGroup().value()">
                                {{ getNurseryName(event) }} • {{ event.kidsGroup().value().attributes.name }}
                            </template>
                            <template v-else-if="getNurseryName(event)">
                                {{ getNurseryName(event) }}
                            </template>
                            <template v-else-if="event.kidsGroup().value()">
                                {{ event.kidsGroup().value().attributes.name }}
                            </template>
                        </div>
                    </div>
                </div>

                <CDropdown
                    v-if="can('update', 'hr_request')"
                    ref="segmentMenus"
                    content-class="tw-min-w-48 tw-w-60 tw-p-3"
                    legacy-mode
                    overlay
                    placement="top-start"
                    @shown="onSegmentMenusShown(event)"
                >
                    <Loader
                        v-if="isLoadingEventRequest"
                        size="sm"
                    />
                    <SegmentRequestMenu
                        v-else-if="event.request().value()"
                        :request="event.request().value()"
                        @stageSaved="onRequestStageSaved(event)"
                    />
                </CDropdown>
            </div>
        </CDropdown>
    </div>
</template>

<script lang="ts">
    import type {PropType} from 'vue';
    import {computed, defineComponent, ref} from 'vue';
    import __ from '@/modules/app/utils/i18n-facade';
    import {EventBus} from '@/modules/legacy/utils/bus';
    import useEventStyle from '@/modules/human-resources/composables/calendar/useEventStyle';
    import type EventModel from '@/modules/human-resources/models/EventModel';
    import type CDropdown from '@/modules/meeko-ui/components/MDropdown.vue';
    import SegmentRequestMenu from '@/modules/human-resources/components/calendar/SegmentRequestMenu.vue';
    import useAbility from '@/modules/app/composables/useAbility';
    import RequestModel from '@/modules/request/models/RequestModel';
    import RequestStageModel from '@/modules/request/models/RequestStageModel';
    import useAuth from '@/modules/app/composables/useAuth';

    export default defineComponent({
        components: {
            SegmentRequestMenu,
        },
        props: {
            events: {
                type: Array as PropType<EventModel[]>,
                required: true,
            },
            size: {
                type: String as PropType<'sm' | 'md' | 'lg'>,
                default: 'lg',
            },
        },
        setup(props) {
            const {can} = useAbility();

            const segmentMenus = ref<CDropdown[]>();
            const isLoadingEventRequest = ref(false);
            const {legacyUser} = useAuth();

            async function onSegmentMenusShown(event: EventModel) {
                // If the event has an associated request and is pending, we need to load the request if not already loaded
                if (can('update', 'hr_request') && event.isPending && event.attributes.request_id && !event.request().value()) {
                    isLoadingEventRequest.value = true;

                    const request = await RequestModel.query()
                        .with(new RequestModel().requestType())
                        .with(new RequestModel().source())
                        .with(new RequestModel().requestStages(), query => {
                            query.with(new RequestStageModel().requestTypeStage());
                        }).find(event.attributes.request_id);

                    event.request().set(request);

                    isLoadingEventRequest.value = false;
                }
            }

            function updateEvent(event: EventModel) {
                hideMenus();
                if (event.isPending && can('update', 'hr_request')) {
                    if (segmentMenus.value) {
                        segmentMenus.value[props.events.indexOf(event)].show();
                    }
                } else if (!event.isPending || !event.request().value()) {
                    EventBus.$emit('calendar:staff:update:event', event);
                }
            }

            function hideMenus() {
                segmentMenus.value?.forEach(item => {
                    item.hide();
                });
            }

            const segmentClass = computed(() => {
                return props.size === 'lg' ? 'tw-w-6' : 'tw-w-4';
            });

            function getEventClass(event: EventModel, index: Number) {
                let eventClass = getEventStyleAndClass(event).fullDayStickClass.value;
                if (props.events.length > 2 && index > 0) {
                    eventClass += props.size === 'lg' ? ' tw--ml-2' : ' tw--ml-1';
                }

                return eventClass;
            }

            function getEventStyle(event: EventModel) {
                return getEventStyleAndClass(event).fullDayStickStyle.value;
            }

            function getIconClass(event: EventModel) {
                return getEventStyleAndClass(event, 'lg').iconClass.value;
            }

            function getIconStyle(event: EventModel) {
                return getEventStyleAndClass(event, 'lg').iconStyle.value;
            }

            function getEventStyleAndClass(event: EventModel, size = props.size) {
                const color = getEventType(event).attributes.color || '';
                const forecast = event.attributes.forecast || false;

                return useEventStyle(ref(color), ref(forecast), size, false, ref(false));
            }

            function getEventType(event: EventModel) {
                return event.eventType().value();
            }

            function getNurseryName(event: EventModel) {
                let text = __('common:other_organization'); // In case user doesn't have credentials in other organizations.
                if (legacyUser.value) {
                    const nursery = legacyUser.value.nurseries.find(item => `${item.id}` === `${event.attributes.organization_id}`);
                    if (nursery) {
                        text = nursery.city ? nursery.name + ' - ' + nursery.city : nursery.name;
                    }
                }

                return text;
            }

            async function onRequestStageSaved(event: EventModel) {
                await event.fresh();

                hideMenus();
            }

            return {
                can,
                segmentMenus,
                onSegmentMenusShown,
                isLoadingEventRequest,
                hideMenus,
                updateEvent,
                onRequestStageSaved,

                segmentClass,

                getEventClass,
                getEventStyle,

                getIconClass,
                getIconStyle,

                getEventType,
                getNurseryName,
            };
        },
    });
</script>
