<template>
    <CVStack gap="4">
        <CInline
            align-x="between"
            align-y="baseline"
        >
            <MHeading level="h3">
                {{ __('common:team_other') }}
            </MHeading>
            <CInline space="sm">
                <MTooltip
                    :label="__('common:import_from_another_organization')"
                    placement="top"
                >
                    <MButton @click="$refs.confirmModal.show()">
                        <template #left-icons>
                            <FontAwesomeIcon icon="fas fa-download"/>
                        </template>
                        {{ __('common:actions.import') }}
                    </MButton>
                </MTooltip>
                <CButton
                    :icon-plus="true"
                    variant="primary"
                    @click="$refs.createModal.show()"
                >
                    {{ __('hr:add_team') }}
                </CButton>
            </CInline>
            <CreateTeamModal
                ref="createModal"
                @attached="addToTeams"
                @created="addToTeams"
            />
            <ConfirmModal
                ref="confirmModal"
                :orga="orga"
                :title="__('hr:import_event_type')"
                @organizationSelected="importTeams($event)"
            >
                <template #label>
                    <CLabel>
                        {{ __('hr_staff:import_team_from_which_organization') }}
                    </CLabel>
                </template>
            </ConfirmModal>
        </CInline>
        <TeamList ref="list"/>
    </CVStack>
</template>

<script lang="ts">
    import {defineComponent, ref} from 'vue';
    import CreateTeamModal from '@/modules/human-resources/components/team/Modals/CreateTeamModal.vue';
    import TeamList from '@/modules/human-resources/components/team/List/TeamList.vue';
    import OrganizationModel from '@/modules/organization/models/OrganizationModel';
    import ConfirmModal from '@/modules/app/components/ConfirmModal.vue';


    export default defineComponent(
        {
            components: {CreateTeamModal, TeamList, ConfirmModal},
            setup() {
                const list = ref();
                const orga = OrganizationModel;

                function importTeams(organizationModel) {
                    list.value.importAndRefreshData(organizationModel);
                }

                function addToTeams(teamModel) {
                    list.value.addToResults(teamModel);
                }

                return {
                    list,
                    addToTeams,
                    importTeams,
                    orga,
                };
            },
        },
    );
</script>

