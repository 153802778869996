<script setup lang="ts">
    import {inject, ref, watch} from 'vue';
    import {useElementHover} from '@vueuse/core';
    import type {MCalendarDaysItem} from '@/modules/meeko-ui/components/MCalendar/useMCalendarDays';
    import {keyMCalendarDays} from '@/modules/meeko-ui/components/MCalendar/useMCalendarDays';

    const props = defineProps<{
        day: MCalendarDaysItem;
    }>();

    const daysState = inject(keyMCalendarDays);
    if (!daysState) {
        throw new Error('keyMCalendarDays must be provided');
    }

    const {
        hoveredDay,
        handleDayClick,
    } = daysState;

    const dayElement = ref();
    const isHovered = useElementHover(dayElement);

    watch(isHovered, newValue => {
        if (newValue) {
            hoveredDay.value = props.day.day;
        }
    });
</script>

<template>
    <div
        ref="dayElement"
        class="MCalendarDayItem"
        @click="handleDayClick(day.day)"
    >
        <div
            class="MCalendarDayItem__inner"
            :class="{
                'MCalendarDayItem__inner--out-of-current-month': day.isOutOfMonth,
                'MCalendarDayItem__inner--today': day.isToday,
                'MCalendarDayItem__inner--selecting': day.isSelecting,
                'MCalendarDayItem__inner--in-period': day.isInPeriod,
                'MCalendarDayItem__inner--hovered': day.isHovered,
                'MCalendarDayItem__inner--selected': day.isSelected,
            }"
        >
            {{ day.day.toFormat('dd') }}
        </div>
    </div>
</template>

<style scoped lang="scss">
    .MCalendarDayItem {
        @apply tw-w-9 tw-aspect-square tw-flex tw-p-0.5 tw-cursor-pointer tw-select-none;
    }

    .MCalendarDayItem__inner {
        @apply tw-relative;
        @apply tw-w-full tw-h-full tw-rounded-md tw-flex tw-flex-col tw-justify-center tw-items-center;
        @apply tw-transition-all tw-font-display tw-text-gray-800;
    }

    .MCalendarDayItem__inner--hovered {
        @apply tw-bg-gray-200;
    }

    .MCalendarDayItem__inner--out-of-current-month {
        @apply tw-text-gray-400;
    }

    .MCalendarDayItem__inner--today {
        &:before {
            @apply tw-content-[''] tw-absolute tw--top-0.5 tw--right-0.5;
            @apply tw-w-2 tw-aspect-square tw-rounded-full tw-bg-red-500;
        }
    }

    .MCalendarDayItem__inner--in-period {
        &:not(.MCalendarDayItem__inner--selecting) {
            @apply tw-bg-primary-200 tw-text-primary-900;
        }

        &.MCalendarDayItem__inner--selecting {
            @apply tw-bg-gray-200;
        }
    }

    .MCalendarDayItem__inner--selected {
        @apply tw-bg-primary-500 tw-text-white #{!important};

        &.MCalendarDayItem__inner--hovered {
            @apply tw-bg-primary-600 #{!important};
        }
    }
</style>
