<template>
    <CAlert
        v-if="matches.count()"
        :close-button="false"
        :title-text="__('components:item_already_exists')"
    >
        <ul class="tw-mb-1">
            <CHStack
                v-for="(model, index) in matches"
                :key="index"
                align="baseline"
                distribute="between"
            >
                <CHStack
                    align="baseline"
                    gap="1"
                >
                    <slot
                        :model="model"
                        name="item"
                    >
                        <strong>{{ model.attributes[fieldToCompare] }}</strong>
                    </slot>
                    <CText>{{ __('components:is_used_in') }}</CText>
                    <CTooltip
                        class="tw-cursor-pointer"
                        placement="bottom"
                    >
                        <CText>
                            <span>
                                {{
                                    __('components:organization_with_name', {
                                        count: getOrganizationRelation(model).value().count(),
                                        name: getOrganizationRelation(model).value().first().attributes.name,
                                    })
                                }}
                            </span>
                        </CText>
                        <template #content>
                            <ul>
                                <CHeading is="h6">
                                    {{ __('common:list_of_organizations') }}
                                </CHeading>
                                <li
                                    v-for="(organization, index1) in getOrganizationRelation(model).value()"
                                    :key="index1"
                                >
                                    - {{ organization.attributes.name }}
                                </li>
                            </ul>
                        </template>
                    </CTooltip>
                </CHStack>
                <div>
                    <CButton
                        :key="index"
                        class="tw-mt-1.5 tw-mr-1"
                        :loading="loading"
                        variant="primary"
                        @click="onSelectedModel(model)"
                    >
                        {{ __('common:actions.import') }}
                    </CButton>
                </div>
            </CHStack>
        </ul>
    </CAlert>
</template>

<script lang="ts">
    import type {PropType} from 'vue';
    import {defineComponent, ref, watch} from 'vue';
    import {debounce, isUndefined} from 'lodash-es';
    import type {QueryBuilder} from '@meekohq/lumos';
    import {collect} from '@meekohq/lumos';
    import type {HasOrganization} from '@/modules/app/composables/useSharedList';

    export default defineComponent({
        components: {},
        props: {
            model: {type: Function as PropType<HasOrganization>, required: true},
            value: {type: String as PropType<string>, default: ''},
            fieldToCompare: {type: String as PropType<string>, required: true},
        },
        setup(props, {emit}) {
            const loading = ref(false);
            const matches = ref(collect<typeof props.model>());

            // Add constraint to query by filtering others allowed organization
            const addConstraints = function(builder: QueryBuilder<any>) {
                builder.whereHas('organizations', query => query.scope('userOrganizations'))
                    .whereDoesntHave('organizations', query => query.scope('active'));
            };

            const getOrganizationRelation = function <T extends HasOrganization>(model: T) {
                return model.organizations();
            };

            const fetchResults = async function(value: string) {
                if (!isUndefined(value)) {
                    // @ts-ignore
                    const query = props.model.query(query => addConstraints(query))
                        .where(props.fieldToCompare, value)
                        .with('organizations')
                        .with('organizationsPivots');

                    matches.value = await query.get();
                }
            };

            const debounceQuery = debounce(fetchResults, 300);

            watch(() => props.value, async value => {
                debounceQuery(value);
            });

            const onSelectedModel = function(model) {
                loading.value = true;
                emit('selected', model);
            };

            return {
                matches,
                getOrganizationRelation,
                onSelectedModel,
                loading,
            };
        },
    });
</script>
