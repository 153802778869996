import {Model} from '@meekohq/lumos';
import SubscriptionModel from '@/modules/cashier/models/SubscriptionModel';

export default class EndpointModel extends Model {
    static readonly RESOURCE_TYPE = {
        CUSTOMER_CONTACT: 'cashier/customer_contacts',
        FAMILY_MEMBER: 'family/members',
        USER: 'user/users',
        STAFF: 'staff/staffs',
    };
    static readonly TYPE = {
        RESOURCE: 'resource',
        EMAIL: 'email',
        SLACK: 'slack',
        WEBHOOK: 'webhook',
    };

    public type = 'cashier/notification_endpoints';

    public attributes: {
        id: string;
        tenant_id: string | undefined;
        type: string | undefined;
        resource_type: string | undefined;
        resource_id: string | undefined;
    } = {
            id: this.uuid(),
            tenant_id: undefined,
            type: undefined,
            resource_type: undefined,
            resource_id: undefined,
        };

    subscriptions() {
        return this.hasMany('subscriptions', SubscriptionModel, 'endpoint_id');
    }
}
