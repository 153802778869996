<template>
    <div :class="baseClass">
        <slot/>
    </div>
</template>

<script lang="ts">
    import {computed, defineComponent} from 'vue';

    export default defineComponent({
        components: {},
        props: {
            variant: {type: String, default: 'default'},
            rounded: {type: Boolean, default: false},
            size: {type: String, default: 'default'},
        },
        setup(props) {
            const baseClass = computed(() => {
                const output = [] as string[];

                output.push('tw-inline-flex tw-justify-center tw-items-baseline tw-font-display');

                if (props.rounded) {
                    output.push('tw-rounded-full');
                } else {
                    output.push('tw-rounded');
                }

                const sizes = {
                    default: 'tw-px-2 tw-py-1 tw-text-sm',
                    sm: 'tw-px-1.5 tw-py-0.5 tw-text-sm',
                };
                output.push(sizes[props.size] ?? sizes['default']);

                const variants = {
                    'black': 'tw-bg-gray-700 tw-text-gray-200',
                    'gray': 'tw-bg-gray-200 tw-text-gray-700',
                    'light': 'tw-bg-gray-200 tw-text-gray-600',
                    'cyan': 'tw-bg-cyan-200 tw-text-cyan-950',
                    'blue': 'tw-bg-blue-100 tw-text-blue-800',
                    'purple': 'tw-bg-purple-200 tw-text-purple-800',
                    'pink': 'tw-bg-pink-200 tw-text-pink-800',
                    'red': 'tw-bg-red-200 tw-text-red-800',
                    'red-light': 'tw-bg-red-200 tw-text-red-600',
                    'orange': 'tw-bg-orange-200 tw-text-orange-800',
                    'yellow': 'tw-bg-yellow-300 tw-text-yellow-900',
                    'green': 'tw-bg-green-300 tw-text-green-800',
                };

                variants['primary'] = variants.blue;
                variants['success'] = variants.green;
                variants['danger'] = variants.red;
                variants['warning'] = variants.orange;
                variants['default'] = variants.gray;

                output.push(variants[props.variant]);

                return output;
            });

            return {
                baseClass,
            };
        },
    });

</script>

