import {Model} from '@meekohq/lumos';
import MeetingStateValue from '@/modules/activity/utils/MeetingStateValue';
import StaffModel from '@/modules/human-resources/models/StaffModel';
import MeetingParticipantModel from '@/modules/activity/models/MeetingParticipantModel';
import MeetingPivot from '@/modules/activity/models/MeetingPivot';


export default class MeetingModel extends Model {

    public type = 'activity/meetings';

    public attributes: {
        id: string,
        account_id: string | undefined,
        name: string | undefined,
        description: string | null,
        location: string | null,
        datetime_period: {
            started_at: string | undefined;
            ended_at: string | undefined;
        },
        state: MeetingStateValue,
        reporter_id: string | null,
        internal_note: string | null,
        rescheduled_meeting_id: string | null,
    } = {
            id: this.uuid(),
            account_id: undefined,
            name: undefined,
            description: null,
            location: null,
            datetime_period: {
                started_at: undefined,
                ended_at: undefined,
            },
            state: MeetingStateValue.scheduled,
            reporter_id: null,
            internal_note: null,
            rescheduled_meeting_id: null,
        };

    reporter() {
        return this.belongsTo('reporter', StaffModel, 'reporter_id');
    }

    participants() {
        return this.hasMany('participants', MeetingParticipantModel, 'meeting_id');
    }

    rescheduledMeeting() {
        return this.belongsTo('rescheduledMeeting', MeetingModel, 'rescheduled_meeting_id');
    }

    meetingPivots() {
        return this.hasMany('meetingPivots', MeetingPivot, 'meeting_id');
    }
}
