import {Model} from '@meekohq/lumos';
import BalanceTypeModel from '@/modules/human-resources/models/BalanceTypeModel';
import EventTypeModel from '@/modules/human-resources/models/EventTypeModel';

export default class BalanceTypeEventTypePivot extends Model {
    public type = 'hr/balance/types_event_types_pivot';

    public attributes: {
        id: string;
        account_id: string | undefined;
        balance_type_id: string | undefined;
        event_type_id: string | undefined;
    } = {
            id: this.uuid(),
            account_id: undefined,
            balance_type_id: undefined,
            event_type_id: undefined,
        };

    balanceType() {
        return this.belongsTo('balanceType', BalanceTypeModel, 'balance_type_id');
    }

    eventType() {
        return this.belongsTo('eventType', EventTypeModel, 'event_type_id');
    }
}
