import useAuth from '@/modules/app/composables/useAuth';
import type {ActionsType, SubjectsType} from '@/modules/user/utils/ActionsType';
import {app} from '@meekohq/lumos';
import useManager from '@/modules/app/composables/useManager';
import {Types} from '@/types';
import type GateContract from '@/modules/user/utils/GateContract';
import {ref} from 'vue';

const abilities: Record<string, (...args: any[]) => boolean> = {};

const {legacyUser} = useAuth();
const {activeOrganization} = useManager();
const arePermissionsLoaded = ref(false);


export default function useAbility(permissions?: any) {
    function instanciate() {
        if (permissions) {
            return app<GateContract>(Types.Gate, [permissions]);
        }

        if (!legacyUser.value || !activeOrganization.value) {
            return app<GateContract>(Types.Gate);
        }

        return app<GateContract>(Types.Gate).for(legacyUser.value, activeOrganization.value);
    }

    function can(action: ActionsType | ActionsType[], subject: SubjectsType) {
        return instanciate().check(action, subject);
    }

    function cant(action: ActionsType | ActionsType[], subject: SubjectsType) {
        return !can(action, subject);
    }

    function any(actions: ActionsType | ActionsType[], subject: SubjectsType) {
        return instanciate().any(actions, subject);
    }

    function none(actions: ActionsType | ActionsType[], subject: SubjectsType) {
        return !any(actions, subject);
    }

    function all(subject: SubjectsType) {
        return instanciate().check([
            'read',
            'create',
            'update',
            'delete',
            'settings',
        ], subject);
    }

    function anyOfAll(subject: SubjectsType) {
        return instanciate().any([
            'read',
            'create',
            'update',
            'delete',
            'settings',
        ], subject);
    }

    /**
     * Determine if a given ability has been defined.
     * @param ability
     */
    function has(ability: string) {
        return abilities[ability] !== undefined;
    }

    /**
     * Determine if the given ability should be granted for the current user.
     * @param ability
     * @param args
     */
    function allows(ability: string, ...args: any[]) {
        const fn = abilities[ability];

        if (!fn) {
            throw new Error(`Ability ${ability} is not defined`);
        }

        return fn(...args);
    }

    /**
     * Determine if the given ability should be denied for the current user.
     * @param ability
     * @param args
     */
    function denies(ability: string, ...args: any[]) {
        return !allows(ability, ...args);
    }

    function defineAbility(name: string, callback: (...args: any[]) => boolean) {
        abilities[name] = callback;
    }

    async function waitUntilPermissionsLoaded() {
        // TODO: use event instead of polling
        while (!arePermissionsLoaded.value) {
            await new Promise(resolve => setTimeout(resolve, 100));
        }
    }

    return {
        can,
        cant,
        any,
        none,
        all,
        anyOfAll,
        has,
        allows,
        denies,
        defineAbility,
        arePermissionsLoaded,
        waitUntilPermissionsLoaded,
    };
}
