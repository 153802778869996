import __ from '@/modules/app/utils/i18n-facade';

export function menusWithRessources() {
    return [{
        name: 'dashboard',
        ability: 'access-to-dashboard-module',
        displayName: __('common:dashboard'),
        icon: 'fa fa-tachometer-alt',
        class: 'menu-color1',
        ressources: ['dashboard', 'export'],
    }, {
        name: 'tasks',
        ability: 'access-to-tasks-module',
        displayName: __('common:task_other'),
        icon: 'fas fa-list-check',
        class: 'menu-color1',
        ressources: ['tasks'],
    }, {
        name: 'plannings',
        ability: 'access-to-plannings-module',
        displayName: __('common:planning_other'),
        icon: 'fas fa-calendar-alt',
        class: 'menu-color2',
        ressources: ['kids_planning', 'staffs_planning', 'groups'],
    }, {
        name: 'kids',
        ability: 'access-to-family-module',
        displayName: __('common:the_kids'),
        icon: 'fas fa-child-reaching',
        class: 'menu-color3',
        ressources: ['kids_details', 'kids_dailies', 'kids_contracts', 'kids_health', 'kids_food', 'kids_authorizations', 'kids_documents'],
    }, {
        name: 'family_members',
        ability: 'access-to-family-members-module',
        displayName: __('common:the_parents'),
        icon: 'fas fa-user-tie',
        class: 'menu-color3',
        ressources: ['family_members_details', 'family_members_chat', 'family_members_chat_manager_only', 'family_members_documents'],
    }, {
        name: 'registrations',
        ability: 'access-to-registrations-module',
        displayName: __('common:registration_one'),
        icon: 'fas fa-pen-alt',
        class: 'menu-color1',
        ressources: ['registrations'],
    }, {
        name: 'billings',
        ability: 'access-to-billings-module',
        displayName: __('common:billing'),
        icon: 'fas fa-wallet',
        class: 'menu-color6',
        ressources: ['invoices', 'transactions'],
    }, {
        name: 'staffs',
        ability: 'access-to-hr-module',
        displayName: __('common:the_staff'),
        icon: 'fas fa-users',
        class: 'menu-color5',
        ressources: ['staffs_details', 'staffs_contracts', 'staffs_documents', 'staffs_statistics', 'staffs_balances', 'hr_request'],
    }, {
        name: 'photos',
        ability: 'access-to-photos-module',
        displayName: __('common:photos'),
        icon: 'fas fa-images',
        class: 'menu-color2',
        ressources: ['photos'],
    }, {
        name: 'documents',
        ability: 'access-to-documents-module',
        displayName: __('common:document_other'),
        icon: 'fas fa-file-invoice',
        class: 'menu-color3',
        ressources: ['documents'],
    }, {
        name: 'communication',
        ability: 'access-to-communication-module',
        displayName: __('common:communication'),
        icon: 'fas fa-bullhorn',
        class: 'menu-color9',
        ressources: ['posts', 'nursery_website'],
    }, {
        name: 'equipments',
        ability: 'access-to-equipments-module',
        displayName: __('common:equipment_other'),
        icon: 'fas fa-cubes',
        class: 'menu-color8',
        ressources: ['equipments', 'rooms'],
    }, {
        name: 'settings',
        ability: 'access-to-settings-module',
        displayName: __('common:settings'),
        icon: 'fas fa-cogs',
        class: 'menu-color10',
        ressources: ['nursery', 'devices', 'doc_templates', 'tags', 'activities', 'users'],
    }];
}

export function permissionsRessources() {
    return [{
        name: 'dashboard',
        displayName: __('common:statistic_other'),
        actions: ['read'],
        menu: 'dashboard',
    }, {
        name: 'export',
        displayName: __('user:data_export'),
        actions: ['read'],
        menu: 'dashboard',
    }, {
        name: 'tasks',
        displayName: __('common:task_other'),
        actions: ['create', 'read', 'update', 'delete'],
        menu: 'tasks',
    }, {
        name: 'kids_planning',
        displayName: __('planning:kids_planning'),
        actions: ['create', 'read', 'update', 'delete'],
        menu: 'plannings',
    }, {
        name: 'staffs_planning',
        displayName: __('common:staff_planning'),
        actions: ['create', 'read', 'update', 'delete', 'settings'],
        menu: 'plannings',
    }, {
        name: 'groups',
        displayName: __('user:groups_and_supervision'),
        actions: ['create', 'read', 'update', 'delete'],
        menu: 'plannings',
    }, {
        name: 'kids_details',
        displayName: __('common:summary'),
        actions: ['create', 'read', 'update', 'delete'],
        menu: 'kids',
    }, {
        name: 'kids_dailies',
        displayName: __('user:transmission_other'),
        actions: ['create', 'read', 'update', 'delete'],
        menu: 'kids',
    }, {
        name: 'kids_contracts',
        displayName: __('common:contract_other'),
        actions: ['create', 'read', 'update', 'delete'],
        menu: 'kids',
    }, {
        name: 'kids_health',
        displayName: __('common:health'),
        actions: ['create', 'read', 'update', 'delete'],
        menu: 'kids',
    }, {
        name: 'kids_food',
        displayName: __('family_kid:foods'),
        actions: ['create', 'read', 'update', 'delete'],
        menu: 'kids',
    }, {
        name: 'kids_authorizations',
        displayName: __('common:authorizations'),
        actions: ['create', 'read', 'update', 'delete'],
        menu: 'kids',
    }, {
        name: 'kids_documents',
        displayName: __('common:document_other'),
        actions: ['create', 'read', 'update', 'delete'],
        menu: 'kids',
    }, {
        name: 'family_members_details',
        displayName: __('common:summary'),
        actions: ['create', 'read', 'update', 'delete'],
        menu: 'familyMembers',
    }, {
        name: 'family_members_chat',
        displayName: __('user:chat'),
        actions: ['create', 'read', 'update', 'delete'],
        menu: 'familyMembers',
    }, {
        name: 'family_members_chat_manager_only',
        displayName: __('common:direction_messages'),
        actions: ['create', 'read'],
        menu: 'familyMembers',
    }, {
        name: 'family_members_documents',
        displayName: __('common:document_other'),
        actions: ['create', 'read', 'update', 'delete'],
        menu: 'familyMembers',
    }, {
        name: 'registrations',
        displayName: __('common:registration_one'),
        actions: ['create', 'read', 'update', 'delete'],
        menu: 'registrations',
    }, {
        name: 'invoices',
        displayName: __('common:invoice_other'),
        actions: ['create', 'read', 'update', 'delete', 'settings'],
        menu: 'billings',
    }, {
        name: 'transactions',
        displayName: __('common:transactions'),
        actions: ['create', 'read', 'update', 'delete'],
        menu: 'billings',
    }, {
        name: 'staffs_details',
        displayName: __('common:summary'),
        actions: ['create', 'read', 'update', 'delete', 'settings'],
        menu: 'staffs',
    }, {
        name: 'staffs_contracts',
        displayName: __('common:contract_other'),
        actions: ['create', 'read', 'update', 'delete'],
        menu: 'staffs',
    }, {
        name: 'staffs_documents',
        displayName: __('common:document_other'),
        actions: ['create', 'read', 'update', 'delete'],
        menu: 'staffs',
    }, {
        name: 'staffs_statistics',
        displayName: __('common:statistic_other'),
        actions: ['read'],
        menu: 'staffs',
    }, {
        name: 'staffs_balances',
        displayName: __('common:balances'),
        actions: ['read', 'update', 'settings'],
        menu: 'staffs',
    }, {
        name: 'hr_request',
        displayName: __('user:staff_request'),
        actions: ['create', 'read', 'update', 'delete'],
        menu: 'staffs',
    }, {
        name: 'photos',
        displayName: __('common:photos'),
        actions: ['create', 'read', 'update', 'delete'],
        menu: 'photos',
    }, {
        name: 'documents',
        displayName: __('common:document_other'),
        actions: ['create', 'read', 'update', 'delete'],
        menu: 'documents',
    }, {
        name: 'posts',
        displayName: __('common:news'),
        actions: ['create', 'read', 'update', 'delete', 'settings'],
        menu: 'communication',
    }, {
        name: 'nursery_website',
        displayName: __('common:website'),
        actions: ['settings'],
        menu: 'communication',
    }, {
        name: 'equipments',
        displayName: __('common:equipment_other'),
        actions: ['create', 'read', 'update', 'delete'],
        menu: 'equipments',
    }, {
        name: 'rooms',
        displayName: __('common:room_other'),
        actions: ['create', 'read', 'update', 'delete'],
        menu: 'equipments',
    }, {
        name: 'nursery',
        displayName: __('common:organization_one'),
        actions: ['read', 'update', 'delete'],
        menu: 'settings',
    }, {
        name: 'devices',
        displayName: __('common:devices'),
        actions: ['create', 'read', 'update', 'delete'],
        menu: 'settings',
    }, {
        name: 'doc_templates',
        displayName: __('user:document_templates'),
        actions: ['create', 'read', 'update', 'delete'],
        menu: 'settings',
    }, {
        name: 'tags',
        displayName: __('common:tags'),
        actions: ['create', 'read', 'update', 'delete'],
        menu: 'settings',
    }, {
        name: 'activities',
        displayName: __('common:activity_other'),
        actions: ['create', 'read', 'update', 'delete'],
        menu: 'settings',
    }, {
        name: 'users',
        displayName: __('common:user_other'),
        actions: ['create', 'read', 'update', 'delete'],
        menu: 'settings',
    }];
}

export function permissionsActions() {
    return [{
        name: 'read',
        title: __('common:actions.show'),
        description: __('user:to_display_data_without_modifying_them'),
        icon: 'fa fa-eye',
    }, {
        name: 'create',
        title: __('common:actions.add'),
        description: __('user:to_add_new_data'),
        icon: 'fa fa-plus',
    }, {
        name: 'update',
        title: __('common:actions.update'),
        description: __('user:to_update_data'),
        icon: 'fa fa-pencil-alt',
    }, {
        name: 'delete',
        title: __('common:actions.delete'),
        description: __('user:to_delete_data'),
        icon: 'fa fa-trash-alt',
    }, {
        name: 'settings',
        title: __('user:setup'),
        description: __('user:to_access_and_modify_settings'),
        icon: 'fa fa-cogs',
    }];
}
