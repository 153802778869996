<template>
    <div
        class="CSkeleton"
        :class="style"
        :style="randomWidth ? 'width: ' + width + '%' : ''"
    >
        <slot/>
    </div>
</template>

<script lang="ts">
    import {computed, defineComponent} from 'vue';
    import {random} from 'lodash-es';

    export default defineComponent({
        components: {},
        props: {
            randomWidth: {type: Boolean, default: false, required: false},
        },
        setup(props) {
            const style = computed(() => {
                return [];
            });

            const width = computed(() => {
                if (!props.randomWidth) {
                    return 100;
                }

                return random(2, 10) * 10;
            });

            return {
                style,
                width,
            };
        },
    });
</script>

<style
    lang="scss"
    scoped
>
    @keyframes animation-pulse {
        50% {
            @apply tw-bg-gray-50;
        }
    }

    .CSkeleton {
        @apply tw-rounded tw-bg-gray-300;
        animation: animation-pulse 1.6s cubic-bezier(.4, 0, .6, 1) infinite;

        > * {
            opacity: 0;
        }
    }
</style>
