<script setup lang="ts">
    import {computed, ref, watch} from 'vue';
    import route from '@/modules/legacy/libs/ziggy';
    import useApi from '@/modules/app/composables/useApi';
    import __ from '@/modules/app/utils/i18n-facade';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';
    import {useRoute} from 'vue-router/composables';

    const props = defineProps<{
        kid: { id: string, first_name: string },
        foods: Array<{ id: string, name: string }>
    }>();

    const kidRoute = useRoute();

    const search = ref<string>('');
    const kidFoods = ref<Array<{ id: string, name: string }>>([]);
    const loading = ref(false);

    function getKidsFoods() {
        loading.value = true;
        useApi().legacy.get(
            route('kids.foods.index', {
                kid: props.kid.id,
            }),
        ).then(response => {
            kidFoods.value = response.data;
        }).catch(error => {
            handleError(error);
        }).finally(() => {
            loading.value = false;
        });
    }

    function toggleFood(food: { id: string, name: string }) {
        if (hasFood(food)) {
            removeFood(food);
        } else {
            addFood(food);
        }
    }

    function handleError(error: any) {
        if (error?.response && error.response.status === 422) {
            Object.values(error.response.data.errors).forEach((value: any) => {
                useNotification().error(value[0]);
            });
        } else {
            useNotification().error(error);
        }
    }

    function hasFood(food: { id: string, name: string }) {
        return kidFoods.value.some(item => item.id === food.id);
    }

    function addFood(food: { id: string, name: string }) {
        useApi().legacy.post(
            route('kids.foods.store', {
                kid: props.kid.id,
                food_id: food.id,
            }),
        ).then(() => {
            kidFoods.value.push(food);
            useNotification().success(__('family_kid:foods.added_successfully', {
                kid_name: props.kid.first_name,
                food_name: __(`foods:${food.name}`),
            }));
        }).catch(handleError);
    }

    function removeFood(food: { id: string, name: string }) {
        useApi().legacy.delete(
            route('kids.foods.destroy', {
                kid: props.kid.id,
                food: food.id,
            }),
        ).then(() => {
            const index = kidFoods.value.findIndex(item => item.id === food.id);
            if (index !== -1) kidFoods.value.splice(index, 1);
            useNotification().info(__('family_kid:food_has_been_removed'));
        }).catch(handleError);
    }

    const filteredFoods = computed(() => {
        const filtered = props.foods.filter(food => {
            const foodName = __(`foods:${food.name}`).toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
            const searchNormalized = search.value.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');

            return foodName.includes(searchNormalized);
        });

        return filtered.sort((a, b) => __(`foods:${a.name}`).localeCompare(__(`foods:${b.name}`)));
    });

    watch(() => kidRoute.params.kid, () => {
        getKidsFoods();
    }, {immediate: true});

</script>

<template>
    <loader
        v-if="loading || !foods"
        class="p-3"
        light="true"
        shadow="false"
        size="sm"
        :title="__('family_kid:loading_food_colon_other_dots')"
    />
    <div v-else>
        <div class="row">
            <div class="col-12">
                <MInput
                    v-model="search"
                    :placeholder="__('family_kid:food_search_dots')"
                />
            </div>
            <div class="col-12 p-3">
                <div class="food-category p-3 h-100">
                    <div class="row px-2">
                        <div
                            v-for="(food, i) in filteredFoods"
                            :key="'food' + i"
                            class="col-6 col-md-4 col-lg-3 pointer food zoom-small my-2 d-flex align-items-end tw-text-base"
                            :class="[hasFood(food) ? 'text-info tw-font-semibold' : 'tw-text-gray-600']"
                            @click="toggleFood(food)"
                        >
                            <i
                                class="mr-2 d-inline-flex"
                                :class="'fd-' + food.name"
                            />
                            <div class="d-inline-flex">
                                {{ __(`foods:${food.name}`) }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    .food-category {
        background: #f8fcff;
        border-radius: 10px;
    }

    .category {
        font-weight: 600;
        letter-spacing: .8px;
    }
</style>
