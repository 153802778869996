import Vue from 'vue';

Vue.directive('click-outside', {
    bind(el: any, binding, vnode) {
        el.clickOutsideEvent = function(event) {
            // here I check that click was outside the el and his childrens
            if (!(el === event.target || el.contains(event.target))) {
                // and if it did, call method provided in attribute value
                // @ts-ignore
                vnode.context[binding.expression](event);
            }
        };
        window.addEventListener('click', el.clickOutsideEvent);
    },
    unbind(el: any) {
        window.removeEventListener('click', el.clickOutsideEvent);
    },
});
