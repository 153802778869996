<template>
    <div>
        <div class="tw-flex tw-flex-wrap d-print-none">
            <div class="tw-w-full lg:tw-w-1/2">
                <StaffAvatar
                    class="tw-w-16 tw-float-left tw-mr-3"
                    :staff-model="staff"
                />
                <div class="tw-flex tw-flex-wrap tw-items-center tw-my-2">
                    <h4 class="h4 tw-w-full md:tw-w-auto tw-text-gray-600 tw-font-semibold tw-inline-block tw-capitalize tw-mb-0 tw-mr-2">
                        {{ $fullName(staff.attributes) }}
                    </h4>
                    <CButton
                        v-if="$can('update', 'staffs_details')"
                        class="tw-mt-2 md:tw-mt-1"
                        size="sm"
                        variant="primary"
                        @click="$refs.staffModal.$refs.modal.show()"
                    >
                        <i class="fa fa-pencil tw-mr-2"/>{{ __('common:actions.update') }}
                    </CButton>
                </div>
                <div>
                    <div class="tw-text-gray-500 tw-inline-block">
                        <template v-if="staff.attributes.job">
                            {{ staff.attributes.job }}&nbsp;-&nbsp;
                        </template>
                        {{ __('common:clockin_code') }}
                        <CBadge variant="cyan">
                            {{ staff.attributes.code ? staff.attributes.code : __('common:n_a') }}
                        </CBadge>
                    </div>
                </div>
            </div>

            <div class="tw-w-full lg:tw-w-1/2 tw-mt-3 lg:tw-mt-0">
                <CFormGroup>
                    <CFormTextArea
                        v-model="staff.attributes.note"
                        class="tw-bg-blue-100 tw-resize-none"
                        disabled
                        :placeholder="__('common:note_one')"
                        rows="3"
                    />
                </CFormGroup>
            </div>
        </div>

        <div class="tw-bg-blue-100 tw-mb-5 tw-mt-3 tw--mx-5 d-print-none">
            <div class="tw-flex tw-flex-wrap md:tw-text-center tw-px-5">
                <div class="tw-w-full tw-my-2 md:tw-w-1/3">
                    <template v-if="staff.attributes.mobile_phone">
                        <i class="fa fa-mobile-alt tw-mr-2 tw-text-blue-500"/>
                        {{ staff.attributes.mobile_phone }}
                    </template>
                </div>
                <div class="tw-w-full tw-my-2 md:tw-w-1/3">
                    <template v-if="staff.attributes.phone">
                        <i class="fa fa-phone tw-mr-2 tw-text-blue-500"/>
                        {{ staff.attributes.phone }}
                    </template>
                </div>
                <div class="tw-w-full tw-my-2 md:tw-w-1/3">
                    <template v-if="staff.attributes.email">
                        <i class="fa fa-at tw-mr-2 tw-text-blue-500"/>
                        {{ staff.attributes.email }}
                    </template>
                </div>
            </div>
        </div>

        <Planning
            v-if="$can('read', 'staffs_planning')"
            :staff="staff"
        />

        <StaffModal
            ref="staffModal"
            :staff="staff"
        />
    </div>
</template>

<script lang="ts">
    import {defineComponent} from 'vue';
    import Planning from '@/modules/human-resources/components/staff/Summary/Planning/Show.vue';
    import StaffModal from '@/modules/human-resources/components/staff/StaffModal.vue';
    import StaffAvatar from '@/modules/app/components/atoms/avatars/StaffAvatar.vue';

    export default defineComponent({
        components: {
            StaffAvatar,
            Planning,
            StaffModal,
        },
        props: ['staff'],
    });
</script>
