<template>
    <MModal
        :header-title="__('family_kid:manage_authorizations')"
        :modal="modal"
        @show="onShow"
    >
        <div class="tw-flex tw-flex-col tw-gap-4">
            <div class="tw-text-gray-500">
                <FontAwesomeIcon
                    class="tw-mr-1 tw-text-red-500"
                    icon="fad fa-exclamation-triangle"
                />
                {{ __('family_kid:changes_will_be_applied_to_all_children') }}
            </div>

            <div class="tw-flex tw-flex-col tw-gap-2 tw-w-full">
                <CButtonGroup
                    v-for="(authorization, i) in authorizations"
                    :key="'auth' + i"
                >
                    <MInput
                        v-model="authorization.name"
                        :placeholder="__('family_kid:authorization_name')"
                    />
                    <MButton
                        variant="danger"
                        @click="remove(i)"
                    >
                        <FontAwesomeIcon icon="fas fa-times"/>
                    </MButton>
                </CButtonGroup>
            </div>
            <MButton
                icon-plus
                :label="__('family_kid:new_authorization')"
                variant="link"
                @click="add"
            />
        </div>
        <template #footer-end="{hide}">
            <MButton
                :label="__('common:actions.cancel')"
                @click="hide"
            />
            <MButton
                :label="__('common:actions.save')"
                :loading="loading"
                variant="primary"
                @click="save"
            />
        </template>
    </MModal>
</template>

<script>
    import _cloneDeep from 'lodash-es/cloneDeep';
    import _head from 'lodash-es/head';
    import _forEach from 'lodash-es/forEach';
    import route from '@/modules/legacy/libs/ziggy';
    import useApi from '@/modules/app/composables/useApi';
    import __ from '@/modules/app/utils/i18n-facade';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';

    export default {
        props: {
            nursery: {
                type: Object,
                required: true,
            },
            modal: {
                type: Object,
                required: true,
            },
        },
        data() {
            return {
                authorizations: [],
                loading: false,
            };
        },
        methods: {
            save() {
                this.loading = true;
                useApi().legacy.put(route('nurseries.authorizations.update', {
                    nurseries: this.nursery.id,
                }), {
                    authorizations: this.authorizations,
                }).then(response => {
                    this.nursery.authorizations = _cloneDeep(response.data);
                    this.loading = false;
                    this.modal.hide();
                    useNotification().success(__('family_kid:authorization_updated_successfully_other'));
                }).catch(error => {
                    this.loading = false;
                    if (error.response && error.response.status === 422) {
                        _forEach(error.response.data.errors, value => {
                            useNotification().error(_head(value));
                        });
                    } else {
                        useNotification().error(error);
                    }
                });
            },

            add() {
                this.authorizations.push({
                    id: null,
                    nursery_id: this.nursery.id,
                    name: '',
                    description: '',
                    type: 'other',
                });
            },

            remove(i) {
                this.authorizations.splice(i, 1);
            },

            onShow() {
                this.authorizations = _cloneDeep(this.nursery.authorizations);
            },
        },
    };
</script>
