<template>
    <fieldset class="CFormGroup">
        <slot/>
    </fieldset>
</template>

<script lang="ts">
    import {defineComponent} from 'vue';

    export default defineComponent({
        components: {},
        props: {},
        setup() {
            return {};
        },
    });
</script>

<style lang="scss">
    .CFormGroup {
        > .CLabel {
            @apply tw-mb-1 #{!important};
        }

        > .CFormErrorMessageList {
            @apply tw-mt-2 #{!important};
        }

        > .CPopover {
            @apply tw-block #{!important};
        }
    }
</style>
