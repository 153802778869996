<template>
    <svg
        viewBox="0 0 512 512"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g
            fill="none"
            fill-rule="nonzero"
        >
            <path
                d="M0 0h512v512H0z"
                :fill="bgClass"
            />
            <path
                d="M253 92c-42.4795 0-76.909 34.6828-76.909 77.4745v12.9124c0 42.7918 34.4295 77.4746 76.909 77.4746 42.4795 0 76.909-34.6828 76.909-77.4746v-12.9124C329.909 126.6828 295.4796 92 253 92zm-.0125 206.303c-51.352 0-117.2405 27.7656-136.1995 52.4067C105.0717 365.9454 116.225 388 135.4404 388h235.1192c19.2154 0 30.3687-22.0546 18.6524-37.2903-18.959-24.6282-84.8725-52.4067-136.2245-52.4067z"
                :fill="iconClass"
            />
        </g>
    </svg>
</template>

<script lang="ts">
    import {computed, defineComponent} from 'vue';

    export default defineComponent({
        components: {},
        props: {
            variant: {type: String, default: 'default'},
        },
        setup(props) {
            const bgClass = computed(() => {
                const output = [] as string[];

                const variants = {
                    default: '#e2e8f0',
                    boy: '#DAEDF5',
                    girl: '#F5DBD8',
                };
                output.push(variants[props.variant]);

                return output;
            });

            const iconClass = computed(() => {
                const output = [] as string[];

                const variants = {
                    default: '#94a3b8',
                    boy: '#61B9DB',
                    girl: '#f1856c',
                };
                output.push(variants[props.variant]);

                return output;
            });

            return {
                bgClass, iconClass,
            };
        },
    });
</script>

