<template>
    <div class="EditInvoiceDetailsEditorLine">
        <div class="EditInvoiceDetailsEditorLine__item-type">
            <label>
                {{ __('common:type') }}
            </label>
            <invoiceType
                v-model="line.type"
                editable
                @manualChange="line.unit = null"
            />
        </div>
        <div class="EditInvoiceDetailsEditorLine__item-description">
            <label>
                {{ __('common:description') }}
            </label>
            <CFormTextArea
                v-model="line.name"
                :placeholder="__('billing_invoice:package_dots')"
                rows="2"
            />
        </div>
        <div
            v-if="withTva"
            class="EditInvoiceDetailsEditorLine__item-tva"
        >
            <label>
                {{ __('common:vat') }}
            </label>
            <CInputGroup>
                <CInput
                    v-model.number="line.tva"
                    class="!tw-w-32 md:!tw-w-12"
                    placeholder="20"
                />
                <CInputAddon class="!tw-w-8">
                    <FontAwesomeIcon icon="fa fa-percent"/>
                </CInputAddon>
            </CInputGroup>
        </div>
        <div class="EditInvoiceDetailsEditorLine__item-price">
            <label>
                {{ __('billing_invoice:price_with_vat') }}
            </label>
            <NumberInput
                v-model="line.price"
                class="tw-text-right tw-w-40 md:!tw-w-28"
                :maximum-fraction-digits="3"
                :minimum-fraction-digits="2"
            />
        </div>
        <div class="EditInvoiceDetailsEditorLine__item-quantity">
            <label>
                {{ __('billing_invoice:quantity') }}
            </label>
            <CButtonGroup>
                <NumberInput
                    v-model="line.qty"
                    class="!tw-w-24 tw-text-right"
                    :maximum-fraction-digits="3"
                    :minimum-fraction-digits="2"
                />
                <CFormSelect
                    v-model="line.unit"
                    button-class="!tw-w-16"
                    :options="quantityUnitFilters"
                    :search-bar="false"
                    text-path="text"
                    :truncate="false"
                    value-path="value"
                />
            </CButtonGroup>
        </div>
        <div class="EditInvoiceDetailsEditorLine__item-total">
            <label>
                {{ __('billing_invoice:total_with_vat') }}
            </label>
            {{ format(getLineTotalRounded(line.qty, line.price)) }}
        </div>
        <div
            class="EditInvoiceDetailsEditorLine__delete"
            @click="$emit('removeLine')"
        >
            <FontAwesomeIcon
                class="tw-text-red-500"
                icon="fa fa-xmark"
            />
        </div>
    </div>
</template>

<script lang="ts">
    import {computed, defineComponent, ref, watch} from 'vue';
    import InvoiceType from '@/modules/cashier/components/billing/invoice/InvoiceType.vue';
    import {invoice} from '@/modules/legacy/mixins/invoice';
    import useFormatNumber from '@/modules/cashier/composables/useFormatNumber';
    import NumberInput from '@/modules/app/components/atoms/NumberInput.vue';

    export default defineComponent({
        components: {NumberInput, InvoiceType},
        mixins: [invoice],
        props: {
            line: {
                type: Object,
                required: true,
            },
            withTva: {
                type: Boolean,
                required: true,
            },
            grandTotal: {
                type: String,
                required: true,
            },
            nursery: {
                type: Object,
                required: true,
            },
            currency: {
                type: String,
                default: undefined,
            },
        },
        setup(props) {
            const {format} = useFormatNumber(props.currency);

            const quantityUnit = ref([
                {
                    value: 'hour',
                    text: 'H.',
                },
                {
                    value: 'day',
                    text: 'J.',
                },
                {
                    value: 'halfday',
                    text: 'D.J.',
                },
                {
                    value: 'pack',
                    text: 'Forf.',
                },
                {
                    value: 'unit',
                    text: 'Unit.',
                },
            ]);

            const quantityUnitFilters = computed(() => {
                return quantityUnit.value.filter(unit => {
                    return showUnit(props.line.type, unit.value);
                });
            });

            watch(quantityUnitFilters, () => {
                const quantityUnitIsInFilter = quantityUnitFilters.value.find(unit => unit.value === props.line.unit);

                if (quantityUnitIsInFilter) {
                    return;
                }
                props.line.unit = quantityUnitFilters.value[0].value;
            }, {deep: true, immediate: true});

            function getLineTotalRounded(lineQty, linePrice) {
                const sign = lineQty * linePrice >= 0 ? 1 : -1;
                const absoluteTotal = (lineQty * linePrice) * sign;

                return absoluteTotal * sign;
            }

            function showUnit(lineType, unit) {
                switch (unit) {
                    case 'hour':
                        return !(lineType === 'meals' || lineType === 'health');
                    case 'day':
                        return !(lineType === 'overrun');
                    case 'halfday':
                        return !(lineType === 'overrun');
                    case 'pack':
                        return lineType === 'recurrent' || lineType === 'cmg';
                    case 'unit':
                        return (
                            lineType === 'custom' ||
                            lineType === 'deposit' ||
                            lineType === 'registration'
                        );
                }
            }

            return {
                quantityUnit,
                quantityUnitFilters,
                getLineTotalRounded,
                showUnit,
                format,
            };
        },
    });
</script>

<style lang="scss" scoped>
    .EditInvoiceDetailsEditorLine {
        @apply tw-relative tw-flex tw-flex-row md:tw-flex-nowrap tw-flex-wrap tw-items-center tw-gap-2;
        @apply md:even:tw-bg-transparent even:tw-bg-blue-50 odd:tw-bg-blue-50;
        @apply tw-rounded-lg;
        @apply tw-p-2;
    }

    .EditInvoiceDetailsEditorLine__item {
        @apply tw-flex tw-flex-row tw-items-center tw-justify-between;

        label {
            @apply md:tw-hidden tw-block;
            @apply tw-text-gray-500 tw-font-display;
            @apply tw-shrink-0;
            @apply tw-w-20;
        }
    }

    .EditInvoiceDetailsEditorLine__item-type {
        @apply md:tw-w-12 tw-w-full;
        @apply tw-flex tw-flex-row tw-items-center md:tw-justify-between;

        label {
            @apply md:tw-hidden tw-block;
            @apply tw-text-gray-500 tw-font-display;
            @apply tw-shrink-0;
            @apply md:tw-w-24 tw-w-auto;
            @apply tw-pr-4;
        }
    }

    .EditInvoiceDetailsEditorLine__item-description {
        @apply tw-flex md:tw-flex-row tw-flex-col md:tw-items-center md:tw-justify-between;
        @apply md:tw-flex-1 md:tw-w-24 tw-w-full;

        label {
            @apply md:tw-hidden tw-block;
            @apply tw-text-gray-500 tw-font-display;
            @apply tw-shrink-0;
            @apply tw-pb-1;
        }
    }

    .EditInvoiceDetailsEditorLine__item-tva {
        @extend .EditInvoiceDetailsEditorLine__item;
        @apply md:tw-w-20 tw-w-full;
    }

    .EditInvoiceDetailsEditorLine__item-price {
        @extend .EditInvoiceDetailsEditorLine__item;
        @apply md:tw-w-28 tw-w-full;

    }

    .EditInvoiceDetailsEditorLine__item-quantity {
        @extend .EditInvoiceDetailsEditorLine__item;
        @apply md:tw-w-40 tw-w-full;
    }

    .EditInvoiceDetailsEditorLine__quantity-input-group {
        @apply tw-w-full sm:tw-w-40;
    }

    .EditInvoiceDetailsEditorLine__item-total {
        @extend .EditInvoiceDetailsEditorLine__item;

        @apply md:tw-justify-end;
        @apply tw-text-gray-800;
        @apply md:tw-w-24 tw-w-full;
    }

    .EditInvoiceDetailsEditorLine__delete {
        @apply tw-absolute tw-m-auto hover:tw-cursor-pointer;
        @apply md:tw-top-0 md:tw-bottom-0 md:tw--right-5 tw--top-2 tw--right-2;
        @apply tw-bg-red-200 tw-rounded-full md:tw-w-4 tw-w-5 md:tw-h-4 tw-h-5;
        @apply tw-flex tw-items-center tw-justify-center;
        @apply tw-text-sm;
    }
</style>
