<template>
    <CTabMenu variant="orange">
        <CTabMenuItem
            :exact="true"
            :to="{ name: 'cashier/customers.show.summary'}"
            variant="orange"
        >
            <i
                aria-hidden="true"
                class="fas fa-tachometer-alt tw-mr-2"
            />{{ __('common:summary') }}
        </CTabMenuItem>
        <CTabMenuItem
            v-if="$can('read', 'invoices')"
            :to="{ name: 'cashier/customers.show.invoices'}"
            variant="orange"
        >
            <i
                aria-hidden="true"
                class="fas fa-file-invoice tw-mr-2"
            />{{ __('common:invoice_other') }}
        </CTabMenuItem>
        <CTabMenuItem
            v-if="$can('read', 'transactions')"
            :to="{ name: 'cashier/customers.show.payments'}"
            variant="orange"
        >
            <i
                aria-hidden="true"
                class="fas fa-money-check-edit-alt tw-mr-2"
            />{{ __('common:transactions') }}
        </CTabMenuItem>
    </CTabMenu>
</template>

<script lang="ts">
    import {defineComponent} from 'vue';

    export default defineComponent({
        components: {},
        props: {},
        setup() {
            return {};
        },
    });
</script>

