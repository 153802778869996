<template>
    <CPopover>
        <CButton>
            <template #left-icons>
                <FontAwesomeIcon icon="fad fa-file-plus"/>
            </template>
            {{ title }}
        </CButton>
        <template #content>
            <div>
                <div
                    v-if="kids.length === 1"
                    class="CustomerTabInvoicesActionsPopover__container"
                >
                    <CButton
                        v-if="customer.isCompany"
                        @click="initInvoice('company')"
                    >
                        <template #left-icons>
                            <FontAwesomeIcon icon="fad fa-city fa-swap-opacity"/>
                        </template>
                        <template v-if="setInvoiceTypePopover === 'credit_note'">
                            {{ __('billing_invoice:type.presence_credit_note') }}
                        </template>
                        <template v-else>
                            {{ __('billing_invoice:type.presence') }}
                        </template>
                    </CButton>
                    <CButton
                        v-else
                        @click="initInvoice('presence')"
                    >
                        <template #left-icons>
                            <FontAwesomeIcon icon="fa fa-child-reaching"/>
                        </template>
                        <template v-if="setInvoiceTypePopover === 'credit_note'">
                            {{ __('billing_invoice:type.presence_credit_note') }}
                        </template>
                        <template v-else>
                            {{ __('billing_invoice:type.presence') }}
                        </template>
                    </CButton>
                    <CButton
                        @click="initInvoice('custom')"
                    >
                        <template #left-icons>
                            <FontAwesomeIcon icon="fad fa-file-invoice"/>
                        </template>
                        <template v-if="setInvoiceTypePopover === 'credit_note'">
                            {{ __('billing_invoice:type.custom_credit_note') }}
                        </template>
                        <template v-else>
                            {{ __('billing_invoice:type.custom') }}
                        </template>
                    </CButton>
                </div>
                <template v-else>
                    <CFormGroup>
                        <CLabel class="tw-block tw-text-sm tw-font-semibold tw-mb-1">
                            {{ __('billing_invoice:for_child_colon') }}
                        </CLabel>
                        <SelectKid
                            v-model="selectedKid"
                            :add-option-enabled="false"
                            :create-organization-model="activeOrganization"
                            :extend-builder="selectKidConstrainQuery"
                            :fallback-text="__('billing_invoice:select_child')"
                            :filter-organization-model="activeOrganization"
                            :has-unselect="false"
                        />
                    </CFormGroup>
                    <CFormGroup v-if="!customer.isCompany">
                        <CLabel class="tw-block tw-text-sm tw-font-semibold tw-mb-1 tw-mt-3">
                            <template v-if="setInvoiceTypePopover === 'invoice'">
                                {{ __('billing_invoice:invoice_type_colon') }}
                            </template>
                            <template v-else>
                                {{ __('billing_invoice:credit_note_type_colon') }}
                            </template>
                        </CLabel>
                        <CFormSelect
                            v-model="selectedType"
                            :options="setInvoiceTypePopover === 'invoice' ? invoiceTypeOptions : creditNoteTypeOptions"
                            :search-bar="false"
                        />
                    </CFormGroup>
                    <CButton
                        class="tw-block tw-w-full tw-mt-4"
                        variant="primary"
                        @click="initInvoice(customer.isCompany ? 'company' : null)"
                    >
                        {{ __('common:actions.validate') }}
                    </CButton>
                </template>
            </div>
        </template>
    </CPopover>
</template>

<script lang="ts">
    import type {PropType} from 'vue';
    import {computed, defineComponent, ref} from 'vue';
    import moment from 'moment/moment';
    import __ from '@/modules/app/utils/i18n-facade';
    import useManager from '@/modules/app/composables/useManager';
    import KidModel from '@/modules/family/models/KidModel';
    import type CustomerModel from '@/modules/cashier/models/CustomerModel';
    import type InvoiceTypeValue from '@/modules/cashier/utils/billing/invoice/InvoiceTypeValue';
    import SelectKid from '@/modules/family/components/kid/SelectKid.vue';

    export default defineComponent({
        components: {
            SelectKid,
        },
        props: {
            customer: {
                type: Object as PropType<CustomerModel>,
                required: true,
            },
            nursery: {
                type: Object,
                required: true,
            },
            kids: {
                type: Array as PropType<KidModel[]>,
                default: () => [],
            },
            title: {
                type: String,
                required: true,
            },
            setInvoiceTypePopover: {
                type: String as PropType<InvoiceTypeValue>,
                required: true,
            },
        },
        setup(props, {emit}) {
            const {activeOrganization} = useManager();

            const invoiceTypeOptions = [
                {value: 'presence', text: __('billing_invoice:type.presence')},
                {value: 'custom', text: __('billing_invoice:type.custom')},
            ];

            const creditNoteTypeOptions = [
                {value: 'presence', text: __('billing_invoice:type.presence_credit_note')},
                {value: 'custom', text: __('billing_invoice:type.custom_credit_note')},
            ];

            // Selector values
            const selectedType = ref('presence'); // Invoice type
            const selectedKid = ref<KidModel | undefined>(props.kids ? props.kids[0] : undefined);

            // Constrain kids select if not a company
            const kidIds = computed(() => props.kids?.map(kid => kid.getKey()));
            const selectKidConstrainQuery = computed(() => {
                if (!props.customer.isCompany) {
                    return KidModel.query().whereIn('id', kidIds.value);
                }

                return undefined;
            });

            // Init new invoice
            function initInvoice(type: string | null = null) {
                const lineType = selectedType.value === 'presence' ? 'recurrent' : 'custom';

                if (type) {
                    selectedType.value = type;
                }

                const invoice = {
                    id: null,
                    customer_id: props.customer.getKey(),
                    kid_id: selectedKid.value
                        ? selectedKid.value?.getKey()
                        : null,
                    kid_name: selectedKid.value
                        ? selectedKid.value.attributes.first_name + ' ' + selectedKid.value.attributes.last_name
                        : null,
                    kid_birthdate: selectedKid.value
                        ? selectedKid.value.attributes.birthdate
                        : null,
                    status: 'draft',
                    lines: [
                        {
                            id: null,
                            name: '',
                            tva: props.nursery.invoice_tva,
                            price: props.setInvoiceTypePopover === 'invoice' ? 1 : -1,
                            qty: 1,
                            unit: 'hour',
                            type: lineType,
                        },
                    ],
                    type: selectedType.value,
                    invoice_type: props.setInvoiceTypePopover,
                    conditions: props.nursery.invoice_conditions,
                    date: moment().format('YYYY-MM-DD'),
                    due_date: moment()
                        .add(30, 'days')
                        .format('YYYY-MM-DD'),
                    start_period_date: moment()
                        .startOf('month')
                        .format('YYYY-MM-DD'),
                    end_period_date: moment()
                        .endOf('month')
                        .format('YYYY-MM-DD'),
                    recipient: null,
                };

                emit('init', invoice);
            }

            return {
                activeOrganization,
                initInvoice,
                invoiceTypeOptions,
                creditNoteTypeOptions,
                selectedKid,
                selectedType,
                selectKidConstrainQuery,
            };
        },
    });
</script>

<style lang="scss" scoped>
    .CustomerTabInvoicesActionsPopover__container {
        @apply tw-flex tw-flex-col tw-gap-2;
    }
</style>
