<template>
    <div class="DocumentItem">
        <div class="DocumentItem__header-wrapper">
            <div class="DocumentItem__header">
                <FontAwesomeIcon
                    class="DocumentItem__header-icon"
                    :class="{'DocumentItem__header-icon--file': document.computed.document_url, 'DocumentItem__header-icon--link': !document.computed.document_url}"
                    :icon="document.computed.document_url ? 'fad fa-file' : 'fa fa-globe'"
                />
                <MListItemTitle :title="document.attributes.name"/>
            </div>
            <div class="tw-ml-auto">
                <TagsSelector
                    :editable="can('update', permissionKey)"
                    size="sm"
                    :taggable-model="document"
                    taggable-type="document"
                    :tags="document.tags().value().toArray()"
                    @attached="document.tags().value().push($event)"
                    @detached="document.tags().mutate(v => v.filter(c => c.getKey() !== $event.getKey()))"
                />
            </div>
        </div>
        <div v-if="isVisibilitiesVisible && activeOrganizationPivot">
            <div class="tw-font-semibold tw-mb-1">
                {{ __('document:visibility_colon') }}
            </div>
            <ul class="tw-list-none">
                <li
                    v-if="showVisibilities.staffs_access"
                    :class="activeOrganizationPivot.attributes.staffs_access ? 'text-primary' : 'text-muted'"
                >
                    <FontAwesomeIcon
                        class="tw-mr-1"
                        :icon="activeOrganizationPivot.attributes.staffs_access ? 'fa fa-eye' : 'fa fa-eye-slash'"
                    />
                    {{ __('common:for_team') }}
                </li>
                <li
                    v-if="showVisibilities.parents_access"
                    :class="activeOrganizationPivot.attributes.parents_access ? 'text-primary' : 'text-muted'"
                >
                    <FontAwesomeIcon
                        class="tw-mr-1"
                        :icon="activeOrganizationPivot.attributes.parents_access ? 'fa fa-eye' : 'fa fa-eye-slash'"
                    />
                    {{ __('common:for_parents') }}
                </li>
                <li
                    v-if="showVisibilities.web_access"
                    :class="activeOrganizationPivot.attributes.web_access ? 'text-primary' : 'text-muted'"
                >
                    <FontAwesomeIcon
                        class="tw-mr-1"
                        :icon="activeOrganizationPivot.attributes.web_access ? 'fa fa-eye' : 'fa fa-eye-slash'"
                    />
                    {{ __('document:meeko_website') }}
                </li>
            </ul>
            <template v-if="showOrganizations && user.organizations().value().count() > 1">
                <template v-if="user.organizations().value().count() < 5">
                    <div class="tw-font-semibold tw-mt-3 tw-mb-1">
                        {{ __('common:other_organizations_colon') }}
                    </div>
                    <DocumentListItemOrganizationsList
                        :document="document"
                        :organization="organization"
                        :user="user"
                    />
                </template>
                <template v-else>
                    <CDisclosure
                        class="tw-mt-3 tw-mb-1"
                        :open="false"
                        size="sm"
                        :title="__('common:other_organization')"
                    >
                        <DocumentListItemOrganizationsList
                            :document="document"
                            :organization="organization"
                            :user="user"
                        />
                    </CDisclosure>
                </template>
            </template>
        </div>
        <div class="DocumentItem__footer">
            <CButton
                v-if="can('update', permissionKey)"
                icon-edit
                @click="$emit('update', document)"
            >
                {{ __('common:actions.update') }}
            </CButton>
            <a
                :href="document.attributes.link ? document.attributes.link : document.computed.document_url"
                target="_blank"
            >
                <CButton
                    v-if="document.computed.document_url"
                    variant="primary"
                >
                    <template #right-icons>
                        <font-awesome-icon icon="fa fa-download"/>
                    </template>
                    {{ __('common:actions.download') }}
                </CButton>
                <CButton
                    v-else
                    icon-external-link
                    variant="primary"
                >
                    {{ __('common:actions.open') }}
                </CButton>
            </a>
        </div>
    </div>
</template>

<script lang="ts">
    import type {PropType} from 'vue';
    import {computed, defineComponent} from 'vue';
    import type DocumentModel from '@/modules/document/models/DocumentModel';
    import type OrganizationModel from '@/modules/organization/models/OrganizationModel';
    import useAbility from '@/modules/app/composables/useAbility';
    import MListItemTitle from '@/modules/meeko-ui/components/MListItemTitle.vue';
    import type {VisibilitiesType} from '@/modules/document/stores/useDocumentStore';
    import DocumentListItemOrganizationsList
        from '@/modules/document/components/molecules/DocumentListItemOrganizationsList.vue';
    import TagsSelector from '@/modules/tag/components/TagsSelector.vue';
    import type {SubjectsType} from '@/modules/user/utils/ActionsType';

    export default defineComponent({
        components: {
            TagsSelector,
            DocumentListItemOrganizationsList,
            MListItemTitle,
        },
        props: {
            document: {
                type: Object as PropType<DocumentModel>,
                required: true,
            },
            organization: {
                type: Object as PropType<OrganizationModel>,
                required: true,
            },
            user: {
                type: Object,
                required: true,
            },
            permissionKey: {
                type: String as PropType<SubjectsType>,
                default: 'documents',
            },
            showVisibilities: {
                type: Object as PropType<VisibilitiesType>,
                required: false,
                default: () => ({
                    staff_access: false,
                    pareweb_access: false,
                    web_access: false,
                }),
            },
            showOrganizations: {
                type: Boolean,
                default: false,
            },
        },
        setup(props) {
            const {can} = useAbility();

            // Compute the user organizations that are not the current organization
            const filteredUserOrganizations = computed(() => {
                return props.user.organizations().value().filter(item => item.getKey() !== props.organization.getKey());
            });

            const documentOrganizationsIds = computed(() => {
                return props.document.organizations().value().map(organization => organization.getKey());
            });

            // Compute the organizations pivots from the document
            const documentOrganizationsPivots = computed(() => {
                return props.document.organizations().value().map(organization => organization.pivot());
            });

            // Compute the current organization pivot
            const activeOrganizationPivot = computed(() => {
                return documentOrganizationsPivots.value.first(pivot => pivot.attributes.organization_id === props.organization.getKey());
            });

            const isVisibilitiesVisible = computed(() => {
                return props.showVisibilities?.staffs_access
                    || props.showVisibilities?.parents_access
                    || props.showVisibilities?.web_access;
            });

            return {
                can,
                filteredUserOrganizations,
                documentOrganizationsIds,
                documentOrganizationsPivots,
                activeOrganizationPivot,
                isVisibilitiesVisible,
            };
        },
    });
</script>

<style lang="scss">
    .DocumentItem {
        @apply tw-rounded-xl tw-shadow tw-p-4;
        @apply tw-flex tw-flex-col tw-gap-4;
        @apply tw-ring-1 tw-ring-gray-800 tw-ring-opacity-20;
    }

    .DocumentItem__header-wrapper {
        @apply tw-flex tw-items-start;
    }

    .DocumentItem__header {
        @apply tw-flex tw-items-center tw-ml-0.5 tw-gap-2;
        @apply tw-min-w-0;
    }

    .DocumentItem__header-icon {
        @apply tw-text-xl;
    }

    .DocumentItem__header-icon--file {
        @apply tw-text-cyan-700;
    }

    .DocumentItem__header-icon--link {
        @apply tw-text-blue-600;
    }

    .DocumentItem__footer {
        @apply tw-mt-auto tw-flex tw-justify-between;
    }
</style>
