<template>
    <CVStack
        gap="1"
        @mousemove.self="mousemoveThrottled"
    >
        <slot/>
        <CListHasMoreTrigger
            v-if="hasMore"
            class="CListHasMoreTrigger tw-h-0"
            @isVisible="wantMore"
        />
    </CVStack>
</template>

<script lang="ts">
    import {defineComponent} from 'vue';
    import {throttle} from 'lodash-es';

    export default defineComponent({
        components: {},
        props: {
            hasMore: {type: Boolean, default: false},
        },
        setup(props, {emit}) {
            const mousemove = function(e) {
                emit('mousemove', e);
            };

            const mousemoveThrottled = throttle(mousemove, 100, {trailing: false});

            const wantMore = function(e) {
                emit('wantMore', e);
            };

            return {
                mousemoveThrottled,
                wantMore,
            };
        },
    });
</script>

<style
    lang="scss"
    scoped
>
</style>
