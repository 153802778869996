<template>
    <div class="tw-flex-col tw-space-y-1">
        <div class="tw-sticky tw-top-0 tw-z-10">
            <div class="tw-bg-white tw-rounded-b-xl tw-p-1 tw-inline-flex tw-items-center">
                <div :class="baseTitleClass">
                    <slot name="title">
                        {{ __('common:section') }}
                    </slot>
                </div>
            </div>
        </div>
        <slot/>
    </div>
</template>

<script lang="ts">
    import {computed, defineComponent} from 'vue';

    export default defineComponent({
        components: {},
        props: {
            variant: {type: String, default: 'default'},
        },
        setup(props) {
            const baseTitleClass = computed(() => {
                const output = [] as any[];

                output.push('tw-sticky tw-top-0 tw-rounded-full tw-px-2 tw-text-base tw-font-semibold tw-inline-block');

                const variants = {
                    default: 'tw-bg-gray-200 tw-text-black',
                    blue: 'tw-bg-blue-100 tw-text-blue-700',
                    orange: 'tw-bg-orange-100 tw-text-orange-700',
                    purple: 'tw-bg-purple-100 tw-text-purple-700',
                };
                output.push(variants[props.variant]);

                return output;
            });

            return {
                baseTitleClass,
            };
        },
    });
</script>

