<template>
    <list-wrapper
        :title="__('hr:event_type')"
    >
        <template
            v-if="loader.isDone()"
            #action
        >
            <MTooltip
                :label="__('common:import_from_another_organization')"
                placement="top"
            >
                <MButton @click="$refs.importModal.show()">
                    <template #left-icons>
                        <FontAwesomeIcon icon="fas fa-download"/>
                    </template>
                    {{ __('common:actions.import') }}
                </MButton>
            </MTooltip>
            <CButton
                ref="createButton"
                :icon-plus="true"
                variant="primary"
                @click="$refs.calendarTypeMenu.toggle()"
            >
                {{ __('hr:add_event_type') }}
            </CButton>
        </template>
        <EventTypeList
            ref="list"
            @loading="loader.set($event)"
        >
            <template #categoryList="{value}">
                <CDropdownMenu
                    ref="calendarTypeMenu"
                    :parent="$refs.createButton"
                >
                    <CDropdownMenuItem
                        v-for="(calendar, index) in value"
                        :key="index"
                        @click="showCreateModal(calendar)"
                    >
                        {{ calendar.attributes.name }}
                    </CDropdownMenuItem>
                </CDropdownMenu>
            </template>
        </EventTypeList>
        <CreateEventTypeModal
            v-if="loader.isDone()"
            ref="createModal"
            :calendar-model="selectedCalendar"
            @created="$refs.list.addEventType($event)"
        />
        <ImportEventTypeModal
            ref="importModal"
            @imported="$refs.list.fetchData()"
        />
    </list-wrapper>
</template>

<script lang="ts">
    import {defineComponent, reactive, ref} from 'vue';
    import CreateEventTypeModal from '@/modules/human-resources/components/event-type/Modals/CreateEventTypeModal.vue';
    import CalendarModel from '@/modules/human-resources/models/CalendarModel';
    import Loader from '@/modules/legacy/helpers/loader.helper';
    import EventTypeList from '@/modules/human-resources/components/event-type/List/EventTypeList.vue';
    import ImportEventTypeModal from '@/modules/human-resources/components/event-type/Modals/ImportEventTypeModal.vue';

    export default defineComponent({
        components: {
            ImportEventTypeModal,
            EventTypeList,
            CreateEventTypeModal,
        },
        props: {},
        setup() {
            const loader = reactive(new Loader());
            const createModal = ref();
            const selectedCalendar = ref<CalendarModel>(new CalendarModel());

            function showCreateModal(calendar) {
                selectedCalendar.value = calendar;
                createModal.value.show();
            }

            return {
                showCreateModal,
                loader,
                createModal,
                selectedCalendar,
            };
        },
    });
</script>

