import type {ComputedRef} from 'vue';
import {computed} from 'vue';
import {isArray, isObject} from 'lodash-es';
import {width} from '@/modules/coherence-ui/hooks/useWindowSizeDebounced';

type ResponsivePropObjectType<T> = {
    base?: T,
    sm?: T,
    md?: T,
    lg?: T,
    xl?: T,
    '2xl'?: T,
};

export type ResponsivePropType<T> = ResponsivePropObjectType<T> | T[] | T;

const screens = {
    'sm': 640,
    'md': 768,
    'lg': 1024,
    'xl': 1280,
    '2xl': 1536,
};

export default function <T>(prop: ResponsivePropType<T>, defaultValue?: T) {
    const responsiveProp = computed(() => {
        if (isArray(prop)) {
            if (width.value >= screens['2xl'] && prop[5]) {
                return prop[5];
            } else if (width.value >= screens.xl && prop[4]) {
                return prop[4];
            } else if (width.value >= screens.lg && prop[3]) {
                return prop[3];
            } else if (width.value >= screens.md && prop[2]) {
                return prop[2];
            } else if (width.value >= screens.sm && prop[1]) {
                return prop[1];
            } else {
                return prop[0] || defaultValue;
            }
        }

        if (isObject(prop)) {
            const objectProp = prop as ResponsivePropObjectType<T>;
            if (width.value >= screens['2xl'] && objectProp['2xl'] !== undefined) {
                return objectProp['2xl'];
            } else if (width.value >= screens.xl && objectProp.xl !== undefined) {
                return objectProp.xl;
            } else if (width.value >= screens.lg && objectProp.lg !== undefined) {
                return objectProp.lg;
            } else if (width.value >= screens.md && objectProp.md !== undefined) {
                return objectProp.md;
            } else if (width.value >= screens.sm && objectProp.sm !== undefined) {
                return objectProp.sm;
            } else {
                return objectProp.base || defaultValue;
            }
        }

        return prop || defaultValue;
    }) as ComputedRef<T>;

    return {
        prop: responsiveProp,
    };
}
