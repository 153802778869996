import {useLaunchDarkly} from '@/modules/app/composables/useLaunchDarkly';
import {computed, watch} from 'vue';
import {datadogRum} from '@datadog/browser-rum';
import Bugsnag from '@bugsnag/js';
import {config} from '@meekohq/lumos';

export function useFeatureFlag(key: string, defaultValue = false) {
    const {allFlags} = useLaunchDarkly();

    const value = computed(() => {
        return allFlags.value[key] ?? defaultValue;
    });

    watch(value, flagValue => {
        datadogRum.addFeatureFlagEvaluation(key, flagValue);

        if (config('services.bugsnag.enabled')) {
            if (flagValue) {
                Bugsnag.addFeatureFlag(key);
            } else {
                Bugsnag.clearFeatureFlag(key);
            }
        }
    }, {immediate: true});

    return value;
}
