<template>
    <div class="tw-inline-block">
        <CButton @click="$refs.filterOrder.toggle()">
            <span v-if="order_by === 'date'">
                <i
                    class="fa tw-mr-2"
                    :class="defaultOrder === 'asc' ? 'fa-sort-alpha-down' : 'fa-sort-alpha-up'"
                />{{ __('billing_core:by_date') }}
            </span>
            <span v-else-if="order_by === 'kid_name'">
                <i
                    class="fa tw-mr-2"
                    :class="defaultOrder === 'asc' ? 'fa-sort-alpha-down' : 'fa-sort-alpha-up'"
                />{{ __('billing_core:by_kid') }}
            </span>
            <span v-else-if="order_by === 'no'">
                <i
                    class="fa tw-mr-2"
                    :class="defaultOrder === 'asc' ? 'fa-sort-alpha-down' : 'fa-sort-alpha-up'"
                />{{ __('billing_invoice:by_number') }}
            </span>
            <i class="fa fa-caret-down tw-ml-1"/>
        </CButton>
        <CDropdown
            ref="filterOrder"
            legacy-mode
        >
            <div @click="$refs.filterOrder.hide()">
                <CList class="tw-cursor-pointer">
                    <div
                        class="tw-bg-white hover:tw-bg-blue-200 tw-px-3 tw-py-2 tw-rounded-md tw-text-base tw-text-blue-950 hover:tw-text-blue-900"
                        @click="defaultOrder = 'asc'"
                    >
                        <i class="fa fa-sort-alpha-down tw-mr-2"/>{{ __('common:ascending') }}
                    </div>
                    <div
                        class="tw-bg-white hover:tw-bg-blue-200 tw-px-3 tw-py-2 tw-rounded-md tw-text-base tw-text-blue-950 hover:tw-text-blue-900"
                        @click="defaultOrder = 'desc'"
                    >
                        <i class="fa fa-sort-alpha-up tw-mr-2"/>{{ __('common:descending') }}
                    </div>
                    <hr class="hr tw-my-1">
                    <div
                        class="tw-bg-white hover:tw-bg-blue-200 tw-px-3 tw-py-2 tw-rounded-md tw-text-base tw-text-blue-950 hover:tw-text-blue-900"
                        @click="order_by = 'date'"
                    >
                        <i
                            v-if="order_by === 'date'"
                            class="fa fa-check tw-mr-2"
                        />{{ __('billing_core:order_by_date') }}
                    </div>
                    <div
                        class="tw-bg-white hover:tw-bg-blue-200 tw-px-3 tw-py-2 tw-rounded-md tw-text-base tw-text-blue-950 hover:tw-text-blue-900"
                        @click="order_by = 'no'"
                    >
                        <i
                            v-if="order_by === 'no'"
                            class="fa fa-check tw-mr-2"
                        />{{ __('billing_invoice:order_by_number') }}
                    </div>
                    <div
                        v-if="kidOptionEnabled"
                        class="tw-bg-white hover:tw-bg-blue-200 tw-px-3 tw-py-2 tw-rounded-md tw-text-base tw-text-blue-950 hover:tw-text-blue-900"
                        @click="order_by = 'kid_name'"
                    >
                        <i
                            v-if="order_by === 'kid_name'"
                            class="fa fa-check tw-mr-2"
                        />{{ __('billing_core:order_by_kid') }}
                    </div>
                </CList>
            </div>
        </CDropdown>
    </div>
</template>

<script>
    export default {
        props: {
            orderBy: {
                type: String,
                required: true,
                default: 'no',
            },
            order: {
                type: String,
                required: true,
                default: 'asc',
            },
            kidOptionEnabled: {
                type: Boolean,
                default: true,
            },
        },
        data: () => ({
            order_by: 'no',
            defaultOrder: 'asc',
        }),
        watch: {
            order_by(val) {
                this.$emit('update:orderBy', val);
                window.localStorage.setItem('filter:invoice:orderBy', val);
            },
            defaultOrder(val) {
                this.$emit('update:order', val);
                window.localStorage.setItem('filter:invoice:order', val);
            },
        },
        mounted() {
            this.order_by = this.orderBy;
            this.defaultOrder = this.order;
        },
    };
</script>
