import EventModel from '@/modules/human-resources/models/EventModel';
import type SplittableEvent from '@/modules/human-resources/utils/calendar/SplittableEvent';
import {collect} from '@meekohq/lumos';
import SliceEvent from '@/modules/legacy/libs/periodSplitter/SliceEvent';
import MergeEvent from '@/modules/legacy/libs/periodSplitter/MergeEvent';
import type {ISplittedEvent} from '@/modules/legacy/libs/periodSplitter/SplitEvent';
import SplitEvent from '@/modules/legacy/libs/periodSplitter/SplitEvent';
import _flatten from 'lodash-es/flatten';
import ConstrainEventsInOpeningHours
    from '@/modules/human-resources/utils/calendar/Services/ConstrainEventsInOpeningHours';
import ExcludeEvent from '@/modules/legacy/libs/periodSplitter/ExcludeEvent';

export default class WorkingTimeInPeriod extends ConstrainEventsInOpeningHours {
    protected events: EventModel[] = [];

    /**
     * @param events
     */
    public setEvents(events: EventModel[]): this {
        this.events = events;
        this.setFilterCallback(constrainFilterCallback);

        return this;
    }

    /**
     * Get working time in minutes
     *
     * @param from
     * @param to
     */
    public getWorkingTimeInPeriod(from: number | null = null, to: number | null = null): number {
        const events = this.mapEventModelsToSplittableEvents();

        const inPeriodEvents = this.constrainEventsInPeriod(events, from, to);

        const filteredEvents = this.excludeEventsWithStrategy(this.excludeForecastWithNegativeFactorEventsReferencedByRealEvent(inPeriodEvents));

        const mergedEvents = this.mergeEventsWithFilter(filteredEvents);

        const splittedEvents = this.splitEventsWithStrategy(mergedEvents);

        //Calculate sum of events time
        let total = 0;
        splittedEvents.forEach(event => {
            const factor = event.sources[0].attributes.factor;
            total += (event.endedAt - event.startedAt) * factor / 60;
        });

        return total;
    }

    /**
     *
     * @param events
     */
    protected excludeForecastWithNegativeFactorEventsReferencedByRealEvent(events: SplittableEvent[]) {
        return events.filter(event => {
            if (!event.sources.filter(source => source instanceof EventModel).length) {
                return true;
            }

            const currentEvent = event.sources[0] as EventModel;

            if (currentEvent && currentEvent.attributes.forecast && currentEvent.attributes.factor && currentEvent.attributes.factor < 0) {
                const referenceEventIds = collect(events).map(event => event.sources[0].attributes.reference_event_id);

                return !referenceEventIds.contains(currentEvent.getKey());
            }

            return true;
        });
    }

    /**
     *
     * @param events
     * @param from
     * @param to
     */
    protected constrainEventsInPeriod(events: SplittableEvent[], from: number | null = null, to: number | null = null): SplittableEvent[] {
        const constrainedEvents = (from && to) ? (new SliceEvent(events, from, to)).slice() : events;

        return this.getEvents(constrainedEvents);
    }

    /**
     * @param events
     */
    protected excludeEventsWithStrategy(events: SplittableEvent[]): SplittableEvent[] {
        const excluder = (new ExcludeEvent(events).setStrategyCallback(excludeFilterCallback));

        return excluder.exclude();
    }

    /**
     * @param events
     */
    protected mergeEventsWithFilter(events: SplittableEvent[]): SplittableEvent[] {
        const merger = new MergeEvent(events).setFilterCallback(mergeFilterCallback);

        return merger.merge();
    }

    /**
     * @param events
     */
    protected splitEventsWithStrategy(events: SplittableEvent[]): SplittableEvent[] {
        const splitter = new SplitEvent(events).setStrategyCallback(splitStrategyCallback);

        return splitter.split();
    }

    protected mapEventModelsToSplittableEvents(): SplittableEvent[] {
        return _flatten(this.events.map(event => {
            return event.mapToSplittableEvent();
        }));
    }
}

/**
 * Callback for opening hours filter
 * The split by opening hours is only apply for the presence events on full day
 *
 * @param event
 */
const constrainFilterCallback = (event: SplittableEvent): boolean => {
    return event.sources[0].attributes.factor === 1 && event.isFullDay;
};

/**
 * Callback for exclude fonction
 *
 * @param event
 * @param overlappingEvent
 */
const excludeFilterCallback = (event: SplittableEvent, overlappingEvent: SplittableEvent): SplittableEvent[] => {
    const sourceEvent = event?.sources[0];
    const sourceOverlap = overlappingEvent?.sources[0];

    if (
        sourceEvent.attributes.factor === -1
        && sourceOverlap.attributes.factor === -1
        && sourceEvent.attributes.forecast
        && !sourceOverlap.attributes.forecast
    ) {
        return [event];
    }

    return [];
};

/**
 * Callback for merge fonction
 *
 * @param event
 * @param overlappingEvents
 */
const mergeFilterCallback = (event: SplittableEvent, overlappingEvents: SplittableEvent[]): SplittableEvent[] => {
    return collect(overlappingEvents).filter(overlappingEvent => {
        const overlappingOrigin = collect(overlappingEvent.sources).first();
        const eventOrigin = collect(event.sources).first();

        return overlappingOrigin.attributes.factor === eventOrigin.attributes.factor;
    }).toArray();
};

/**
 * Callback for split fonction
 *
 * @param baseEvent
 * @param overlappingEvent
 * @param splittedEvents
 */
const splitStrategyCallback = (
    baseEvent: SplittableEvent,
    overlappingEvent: SplittableEvent | null,
    splittedEvents: ISplittedEvent<SplittableEvent>,
): SplittableEvent[] => {
    const {currentEvent} = splittedEvents;
    const sourceBase = baseEvent?.sources[0];
    const sourceCurrent = currentEvent?.sources[0];
    const sourceOverlap = overlappingEvent?.sources[0];

    if (sourceBase.attributes.factor === 0) {
        return [];
    }

    if (sourceBase.attributes.factor === 1) {
        return [baseEvent];
    }

    if (currentEvent && overlappingEvent && sourceOverlap.attributes.factor === 1 && sourceCurrent.attributes.factor === -1) {
        return [currentEvent];
    }

    return [];
};
