import {Model} from '@meekohq/lumos';

export default class NumberFormatModel extends Model {
    public type = 'cashier/number_formats';

    public attributes: {
        id: string;
        tenant_id: string | undefined;
        name: string | undefined;
        pattern: string | undefined;
        interval: number | undefined;
        value: number | undefined | null;
        archived_at: string | undefined | null;
        created_at: string | undefined;
        updated_at: string | undefined;
    } = {
            id: this.uuid(),
            tenant_id: undefined,
            name: undefined,
            pattern: undefined,
            interval: undefined,
            value: undefined,
            archived_at: undefined,
            created_at: undefined,
            updated_at: undefined,
        };
}
