<template>
    <MModal
        :header-title="__('family_contract:update_contracts_and_invoices')"
        :modal="modal"
        size="5xl"
    >
        <MStepPanel
            ref="stepper"
            @has-next-step="hasNextStep = $event"
            @has-previous-step="hasPreviousStep = $event"
        >
            <MStepPanelHeader>
                <MStepPanelHeaderItem
                    :is-completed="isStepOneCompleted"
                    :step="1"
                    :title="__('common:contract_other')"
                />
                <MStepPanelHeaderItem
                    :is-completed="isStepTwoCompleted"
                    :step="2"
                    :title="__('common:settings')"
                />
            </MStepPanelHeader>
            <MStepPanelContent v-slot="{activeStep}">
                <SelectContracts
                    v-show="activeStep === 1"
                    ref="selectContract"
                    :configs="nursery.billingConfigs"
                    :nursery="nursery"
                    :selected-contracts="selectedContracts"
                    @configChanged="changeSelectedConfig"
                />

                <SelectSettings
                    v-show="activeStep === 2"
                    :configs="nursery.billingConfigs"
                    :nursery="nursery"
                    :selected-config.sync="selectedConfig"
                    :selected-settings.sync="selectedSettings"
                    :selected-types.sync="selectedTypes"
                />
            </MStepPanelContent>
        </MStepPanel>

        <template #footer-start>
            <MButton
                v-if="hasPreviousStep"
                variant="primary"
                @click="$refs.stepper.previousStep()"
            >
                <template #left-icons>
                    <FontAwesomeIcon icon="fa fa-arrow-left"/>
                </template>
                {{ __('common:actions.previous') }}
            </MButton>
        </template>

        <template #footer-end>
            <MButton
                class="tw-mr-2"
                :label="__('common:actions.cancel')"
                variant="light"
                @click="hideModal"
            />

            <MButton
                v-if="hasNextStep"
                variant="primary"
                @click="$refs.stepper.nextStep()"
            >
                {{ __('common:actions.next') }}
                <template #right-icons>
                    <FontAwesomeIcon icon="fa fa-arrow-right"/>
                </template>
            </MButton>
            <MButton
                v-else
                :label="__('common:actions.save')"
                :loading="adding"
                variant="primary"
                @click="save"
            />
        </template>
    </MModal>
</template>

<script>
    import useApi from '@/modules/app/composables/useApi';
    import route from '@/modules/legacy/libs/ziggy';
    import _head from 'lodash-es/head';
    import _forEach from 'lodash-es/forEach';
    import _cloneDeep from 'lodash-es/cloneDeep';
    import SelectContracts from './SelectContracts.vue';
    import SelectSettings from './SelectSettings.vue';
    import __ from '@/modules/app/utils/i18n-facade';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';
    import {MqlOperation} from '@meekohq/lumos';

    export default {
        components: {
            SelectContracts,
            SelectSettings,
        },
        props: {
            nursery: {
                type: Object,
                required: true,
            },
            modal: {
                type: Object,
                required: true,
            },
        },
        data: () => ({
            hasPreviousStep: false,
            hasNextStep: false,
            selectedContracts: [],
            selectedConfig: {
                id: null,
            },
            selectedSettings: [],
            selectedTypes: [],
            loading: false,
            adding: false,
        }),
        computed: {
            isStepOneCompleted() {
                return this.selectedContracts.length > 0;
            },
            isStepTwoCompleted() {
                return this.selectedSettings.length > 0 && !!this.selectedConfig?.id;
            },
        },
        watch: {
            selectedTypes() {
                if (this.selectedTypes.length === 1 && this.selectedTypes.includes('invoices')) {
                    this.selectedConfig = {
                        id: null,
                    };

                    return;
                }

                if (
                    this.selectedTypes.includes('contracts')
                    && this.selectedConfig.id === null
                    && this.nursery.billingConfigs?.length
                ) {
                    this.selectedConfig = _cloneDeep(this.nursery.billingConfigs[0]);
                }
            },
        },
        mounted() {
            this.selectedTypes = ['contracts'];
        },
        methods: {
            async save() {
                this.adding = true;
                if (this.selectedTypes.includes('contracts')) {
                    try {
                        await this.updateConfigs();
                    } catch (error) {
                        this.adding = false;

                        return Promise.reject(error);
                    }
                }

                if (this.selectedTypes.includes('invoices')) {
                    try {
                        await this.generateInvoices();
                    } catch (error) {
                        this.adding = false;

                        return Promise.reject(error);
                    }
                }

                if (this.selectedTypes.includes('bookings')) {
                    try {
                        await this.syncOccasionalPlannings();
                    } catch (error) {
                        this.adding = false;

                        return Promise.reject(error);
                    }
                }

                this.adding = false;
                this.hideModal();
            },

            async updateConfigs() {
                await useApi().legacy.post(route('kid.contracts.updateConfigs'), {
                    contracts: this.selectedContracts,
                    settings: this.selectedSettings,
                    config_id: this.selectedConfig.id,
                }).then(async () => {
                    useNotification().success(__('family_contract:contracts_updated_successfully'));

                    return Promise.resolve();
                }).catch(error => {
                    if (error && error.response && error.response.status === 422) {
                        _forEach(error.response.data.errors, value => {
                            useNotification().error(_head(value));
                        });
                    } else {
                        useNotification().error(error);
                    }

                    return Promise.reject(error);
                });
            },

            async generateInvoices() {
                await useApi().legacy.post(route('kid.contracts.generateInvoices'), {
                    contracts: this.selectedContracts,
                }).then(() => {
                    useNotification().success(__('family_contract:invoices_created_successfully'));

                    return Promise.resolve();
                }).catch(error => {
                    if (error && error.response && error.response.status === 422) {
                        _forEach(error.response.data.errors, value => {
                            useNotification().error(_head(value));
                        });
                    } else {
                        useNotification().error(error);
                    }

                    return Promise.reject(error);
                });
            },

            async syncOccasionalPlannings() {
                await new MqlOperation('family/kidContract/sync_occasional_plannings_price', {
                    contract_ids: this.selectedContracts,
                }).run()
                    .then(() => {
                        useNotification().success(__('family_contract:update_occasionals_success'));

                        return Promise.resolve();
                    })
                    .catch(error => {
                        useNotification().error(error);

                        return Promise.reject(error);
                    });
            },

            changeSelectedConfig(id) {
                if (id) {
                    this.selectedConfig.id = id;
                }
            },

            hideModal() {
                this.modal.hide();
            },
        },
    };
</script>
