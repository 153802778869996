<template>
    <CVStack
        class="tw-p-2 tw-transition-all tw-ease-in-out"
        :class="{'tw-cursor-pointer': !isAllWeekClosed, 'tw-cursor-default': isAllWeekClosed}"
        gap="2"
        @click="openWeekPlanning"
    >
        <CHStack class="tw-w-full tw-flex-wrap">
            <CHStack
                class="tw-w-full md:tw-w-auto tw-mb-2 md:tw-mb-0 md:tw-mr-2"
                distribute="between"
                gap="2"
            >
                <CVStack
                    class="tw-w-32"
                    gap="1"
                >
                    <CHStack gap="1">
                        <CCenter class="tw-bg-gray-100 tw-rounded tw-flex-1">
                            <CText
                                ellipsis
                                font-size="sm"
                                font-weight="semibold"
                                variant="gray"
                            >
                                S{{ weekDetail.from }}
                            </CText>
                        </CCenter>
                        <template v-if="weekDetail.to">
                            <CCenter>
                                <CText
                                    ellipsis
                                    font-size="sm"
                                    font-weight="semibold"
                                    variant="gray"
                                >
                                    →
                                </CText>
                            </CCenter>
                            <CCenter class="tw-bg-gray-100 tw-rounded tw-flex-1">
                                <CText
                                    ellipsis
                                    font-size="sm"
                                    font-weight="semibold"
                                    variant="gray"
                                >
                                    S{{ weekDetail.to }}
                                </CText>
                            </CCenter>
                        </template>
                    </CHStack>
                    <CText
                        ellipsis
                        font-size="sm"
                        variant="gray"
                    >
                        {{
                            __('common:date_format.human_from_to_short', {
                                from: startOfWeekDate.toLocaleString(Epoch.presets.DATE_SHORT),
                                to: endOfPeriodDate.toLocaleString(Epoch.presets.DATE_SHORT),
                            })
                        }}
                    </CText>
                </CVStack>
                <CCenter
                    class="tw-rounded-md tw-w-16"
                    :class="percentageClass"
                >
                    <div class="tw-text-xl tw-font-semibold">
                        {{ roundedRate ? roundedRate : '0' }}%
                    </div>
                </CCenter>
            </CHStack>
            <div class="tw-flex-1">
                <CHStack
                    class="tw-h-full"
                    gap="2"
                >
                    <SummaryDay
                        v-for="day in days"
                        :key="'summaries-' + day + ' - ' + week"
                        class="tw-w-full tw-h-10"
                        :day="Number(day)"
                        :day-separator="daySeparator"
                        :halfday-time="halfdayTime"
                        :organization="activeOrganization"
                        :show-supervision="showSupervision"
                        :summaries="summaries[day]"
                        :week="week"
                        :year="year"
                    />
                </CHStack>
            </div>
        </CHStack>
        <CVStack
            v-if="isOpen"
            distribute="between"
            gap="3"
        >
            <div class="tw-border-t tw-border-b tw-border-dashed tw-border-gray-200 tw--mx-2 tw-px-2 tw-py-2">
                <WeeklyRegistrationPlanning
                    :from="startOfWeekDate.toTimestamp()"
                    :organization="activeOrganization"
                    :registrations="registrations.toArray()"
                    :to="endOfWeekDate.toTimestamp()"
                />
            </div>
            <WeeklyKidPlanning
                class="tw-mt-2"
                :from="startOfWeekDate.toTimestamp()"
                :kids-group-id="kidsGroupId"
                :kids-planning-types="kidsPlanningTypes"
                :kids-tag-ids="kidsTagIds"
                :organization="activeOrganization"
                :registrations="registrations.toArray()"
                :to="endOfWeekDate.toTimestamp()"
            />
        </CVStack>
    </CVStack>
</template>

<script lang="ts">
    import type {PropType} from 'vue';
    import {computed, defineComponent, ref} from 'vue';
    import SummaryDay from '@/modules/planning/components/simulation/Summary/SummaryDay.vue';
    import WeeklyKidPlanning from '@/modules/planning/components/simulation/Planning/WeeklyKidPlanning.vue';
    import WeeklyRegistrationPlanning
        from '@/modules/planning/components/simulation/Planning/WeeklyRegistrationPlanning.vue';
    import type RegistrationModel from '@/modules/registration/models/RegistrationModel';
    import type {SummariesInterface} from '@/modules/planning/components/simulation/Summary/SummaryTypes';
    import useManager from '@/modules/app/composables/useManager';
    import type {ModelCollection} from '@meekohq/lumos';
    import {collect, Epoch} from '@meekohq/lumos';
    import _max from 'lodash-es/max';
    import {round} from 'lodash-es';

    export default defineComponent({
        components: {SummaryDay, WeeklyKidPlanning, WeeklyRegistrationPlanning},
        props: {
            year: {
                type: Number,
                required: true,
            },
            week: {
                type: Number,
                required: true,
            },
            rate: {
                type: Number,
                required: true,
            },
            registrations: {
                type: Object as PropType<ModelCollection<RegistrationModel>>,
                required: true,
            },
            summaries: {
                type: Array as PropType<SummariesInterface>,
                required: true,
            },
            sameWeeks: {
                type: Array as PropType<number[]>,
                default: () => [],
            },
            kidsGroupId: {
                type: String,
                default: () => null,
            },
            kidsTagIds: {
                type: Array as PropType<string[]>,
                default: () => [],
            },
            kidsPlanningTypes: {
                type: Array as PropType<number[]>,
                default: () => [],
            },
            showSupervision: {
                type: Boolean,
                default: () => false,
            },
            daySeparator: {
                type: String,
                default: 'day',
            },
            halfdayTime: {
                type: String,
                default: '12:30',
            },
        },
        setup(props) {
            const days = Object.keys(props.summaries);
            const planningDisclosure = ref(false);
            const {activeOrganization} = useManager();

            // Get last week with same occupations and supervisons
            const lastSameWeek = computed(() => {
                const maxWeek = _max(props.sameWeeks);

                if (maxWeek === undefined) {
                    return null;
                }

                return maxWeek > 9 ? maxWeek : `0${maxWeek}`;
            });

            const isoWeek = computed(() => {
                return props.week > 9 ? props.week : `0${props.week}`;
            });


            // Get last day of period if same week
            const endOfPeriodDate = computed(() => Epoch.fromISOString(`${props.year}-W${lastSameWeek.value ?? isoWeek.value}`).endOfWeek());

            const startOfWeekDate = computed(() => Epoch.fromISOString(`${props.year}-W${isoWeek.value}`).startOfWeek());
            const endOfWeekDate = computed(() => Epoch.fromISOString(`${props.year}-W${isoWeek.value}`).endOfWeek());

            const isAllWeekClosed = computed(() => collect(Object.values(props.summaries)).where('isClosed', false).isEmpty());

            // Render data
            const weekDetail = computed(function() {
                return {
                    from: props.week,
                    to: lastSameWeek.value,
                };
            });

            const isOpen = ref(false);

            function openWeekPlanning() {
                if (isAllWeekClosed.value) {
                    return;
                }

                isOpen.value = !isOpen.value;
            }

            const roundedRate = computed(() => {
                return round(props.rate);
            });

            const percentageClass = computed(() => {
                const output: any[] = [];

                output.push('percentage');

                if (Number(props.rate) > 100) {
                    output.push('over');
                } else if (Number(props.rate) >= (activeOrganization.value.attributes.occ_min_rate ?? 80)) {
                    output.push('perfect');
                }

                return output;
            });

            return {
                Epoch,
                activeOrganization,
                days,
                endOfPeriodDate,
                endOfWeekDate,
                isAllWeekClosed,
                isOpen,
                openWeekPlanning,
                planningDisclosure,
                startOfWeekDate,
                weekDetail,
                percentageClass,
                roundedRate,
            };
        },
    });
</script>

<style lang="scss" scoped>
    .percentage {
        @apply tw-bg-blue-500 tw-bg-opacity-10 tw-text-blue-600;

        &.perfect {
            @apply tw-bg-green-500 tw-bg-opacity-10 tw-text-green-600;
        }

        &.over {
            @apply tw-bg-red-500 tw-bg-opacity-10 tw-text-red-600;
        }
    }
</style>
