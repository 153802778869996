<script setup lang="ts">
    import {provide, ref, watch} from 'vue';
    import useMCalendarMonths, {keyMCalendarMonths} from '@/modules/meeko-ui/components/MCalendar/useMCalendarMonths';
    import MCalendarMonthsItem from '@/modules/meeko-ui/components/MCalendar/MCalendarMonthsItem.vue';
    import {useElementHover} from '@vueuse/core';

    const monthsState = useMCalendarMonths();
    provide(keyMCalendarMonths, monthsState);

    const {
        monthsInActiveYear,
        hoveredMonth,
    } = monthsState;

    const monthsElement = ref();
    const isHovered = useElementHover(monthsElement);

    watch(isHovered, newValue => {
        if (!newValue) {
            hoveredMonth.value = undefined;
        }
    });
</script>

<template>
    <div
        ref="monthsElement"
        class="MCalendarMonths"
    >
        <MCalendarMonthsItem
            v-for="month in monthsInActiveYear"
            :key="month.month.toTimestamp()"
            :month="month"
        />
    </div>
</template>

<style scoped lang="scss">
    .MCalendarMonths {
        @apply tw-grow;
        @apply tw-grid tw-grid-flow-row tw-grid-cols-3 tw-gap-1.5;
    }
</style>
