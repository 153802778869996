<template>
    <div class="Tools">
        <transition-group
            class="Tools__container"
            name="list-complete"
            tag="div"
        >
            <template v-for="item in menu">
                <div
                    v-if="item.type === 'group'"
                    :key="item.id"
                    class="Tools_group-name list-complete-item"
                >
                    {{ item.name }}
                </div>
                <router-link
                    v-if="item.type === 'item'"
                    :key="item.id"
                    class="Tools_item tw-group list-complete-item"
                    :class="item.colorClass"
                    :to="item.to"
                    @mouseenter.native="onItemHovered(item.id)"
                    @mouseleave.native="onItemUnhovered"
                >
                    <FontAwesomeIcon
                        class="Tools__item-icon"
                        fixed-width
                        :icon="item.icon"
                        :style="{'color': item.color}"
                    />
                    <span class="Tools__item-name">{{ item.text }}</span>
                    <router-link
                        v-if="item.hasSettingsButton"
                        class="Tools_item-settings"
                        :data-action-name="item.id+'-settings-button'"
                        :to="item.settingsTo"
                    >
                        <FontAwesomeIcon icon="fa fa-cog"/>
                    </router-link>
                </router-link>
                <router-link
                    v-if="item.type === 'subitem'"
                    :key="item.id"
                    class="Tools__subitem list-complete-item"
                    :class="item.colorClass"
                    :to="item.to"
                    @mouseenter.native="onItemHovered(item.parent_id)"
                    @mouseleave.native="onItemUnhovered"
                >
                    <FontAwesomeIcon
                        class="Tools__subitem-icon"
                        :icon="item.icon"
                        :style="{'color': item.color}"
                    />
                    <span class="Tools__subitem-name">{{ item.text }}</span>
                </router-link>
            </template>
        </transition-group>
    </div>
</template>

<script lang="ts">
    import {computed, defineComponent, ref} from 'vue';
    import __ from '@/modules/app/utils/i18n-facade';
    import {useRoute, useRouter} from 'vue-router/composables';
    import useAccessRoute from '@/modules/app/composables/useAccessRoute';
    import {computedEager} from '@vueuse/core';

    export default defineComponent({
        props: {
            user: {type: Object, required: true},
            nursery: {type: Object, required: true},
        },
        setup(props) {
            const hoverTimeout = ref();
            const hoveredItem = ref<string | undefined>('');
            const route = useRoute();
            const router = useRouter();
            const {checkAccessToRouteName} = useAccessRoute(router);

            const planningModuleVisible = computedEager(() => modulePathActive('planning'));
            const familyModuleVisible = computedEager(() => modulePathActive('family'));
            const billingModuleVisible = computedEager(() => modulePathActive('billing'));
            const hrModuleVisible = computedEager(() => modulePathActive('hr'));

            function removeNullUndefined(obj: any) {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null));
            }

            const menu = computed(() => {
                const items = [] as any[];

                if (checkAccessToRouteName('dashboard.show')) {
                    items.push({
                        type: 'item',
                        id: 'dashboard',
                        text: __('app:menu.dashboard'),
                        icon: 'fad fa-tachometer-alt fa-swap-opacity',
                        colorClass: 'color-cyan',
                        to: {name: 'dashboard.show', params: {nursery: props.nursery.id}},
                    });
                }

                if (
                    checkAccessToRouteName('module.planning')
                    || checkAccessToRouteName('module.family')
                    || checkAccessToRouteName('registrations.index')
                    || checkAccessToRouteName('module.billing')
                    || checkAccessToRouteName('module.hr')
                ) {
                    items.push({
                        type: 'group',
                        id: 'manage',
                        name: __('app:menu.manage'),
                    });
                }

                if (checkAccessToRouteName('module.planning')) {
                    items.push({
                        type: 'item',
                        id: 'plannings',
                        text: __('app:menu.plannings'),
                        icon: 'fad fa-calendar-alt',
                        colorClass: 'color-blue',
                        to: {name: 'module.planning', params: {nursery: props.nursery.id}},
                        hasSettingsButton: checkAccessToRouteName('settings.plannings'),
                        settingsTo: {name: 'settings.plannings', params: {nursery: props.nursery.id}},
                    });
                }

                if (hoveredItem.value === 'plannings' || planningModuleVisible.value) {
                    if (checkAccessToRouteName('nursery')) {
                        items.push({
                            id: 'plannings-kid',
                            parent_id: 'plannings',
                            type: 'subitem',
                            text: __('app:menu.kids_planning'),
                            icon: 'fas fa-child-reaching',
                            colorClass: 'color-blue',
                            to: {
                                name: 'nursery', params: {nursery: props.nursery.id}, query: removeNullUndefined({
                                    date: route.query.date,
                                    from: route.query.from,
                                    to: route.query.to,
                                }),
                            },
                        });
                    }

                    if (checkAccessToRouteName('planning.staffs')) {
                        items.push({
                            id: 'plannings-staff',
                            parent_id: 'plannings',
                            type: 'subitem',
                            text: __('app:menu.staffs_planning'),
                            icon: 'fas fa-users',
                            colorClass: 'color-blue',
                            to: {
                                name: 'planning.staffs',
                                params: {nursery: props.nursery.id},
                                query: removeNullUndefined({
                                    date: route.query.date,
                                    from: route.query.from,
                                    to: route.query.to,
                                }),
                            },
                        });
                    }
                }

                if (checkAccessToRouteName('module.family')) {
                    items.push({
                        type: 'item',
                        id: 'family',
                        text: __('app:menu.family'),
                        icon: 'fad fa-family-pants',
                        colorClass: 'color-purple',
                        to: {name: 'module.family', params: {nursery: props.nursery.id}},
                    });
                }

                if (hoveredItem.value === 'family' || familyModuleVisible.value) {
                    if (checkAccessToRouteName('kids.index')) {
                        items.push({
                            id: 'family-kids',
                            parent_id: 'family',
                            type: 'subitem',
                            text: __('app:menu.kids'),
                            icon: 'fas fa-child-reaching',
                            to: {name: 'kids.index', params: {nursery: props.nursery.id}},
                            colorClass: 'color-purple',
                        });
                    }
                    if (checkAccessToRouteName('familyMembers.index')) {
                        items.push({
                            id: 'family-members',
                            parent_id: 'family',
                            type: 'subitem',
                            text: __('app:menu.members'),
                            icon: 'fas fa-user-tie',
                            to: {name: 'familyMembers.index', params: {nursery: props.nursery.id}},
                            colorClass: 'color-purple',
                        });
                    }
                    if (checkAccessToRouteName('families.index')) {
                        items.push({
                            id: 'family-families',
                            parent_id: 'family',
                            type: 'subitem',
                            text: __('app:menu.families'),
                            icon: 'fad fa-address-book',
                            to: {name: 'families.index', params: {nursery: props.nursery.id}},
                            colorClass: 'color-purple',
                        });
                    }
                }

                if (checkAccessToRouteName('registrations.index')) {
                    items.push({
                        type: 'item',
                        id: 'registrations',
                        text: __('app:menu.registrations'),
                        icon: 'fad fa-pen-alt',
                        colorClass: 'color-cyan',
                        to: {name: 'registrations.index', params: {nursery: props.nursery.id}},
                    });
                }

                if (checkAccessToRouteName('module.billing')) {
                    items.push({
                        type: 'item',
                        id: 'billings',
                        text: __('app:menu.billing'),
                        icon: 'fad fa-wallet',
                        colorClass: 'color-orange',
                        to: {name: 'module.billing', params: {nursery: props.nursery.id}},
                        hasSettingsButton: checkAccessToRouteName('settings.billings'),
                        settingsTo: {name: 'settings.billings.rules', params: {nursery: props.nursery.id}},
                    });
                }

                if (hoveredItem.value === 'billings' || billingModuleVisible.value) {
                    if (checkAccessToRouteName('billings.index')) {
                        items.push({
                            id: 'billings-invoices',
                            parent_id: 'billings',
                            type: 'subitem',
                            colorClass: 'color-orange',
                            text: __('app:menu.invoices'),
                            icon: 'fad fa-file-invoice',
                            to: {name: 'billings.index', params: {nursery: props.nursery.id}},
                        });
                    }

                    if (checkAccessToRouteName('payments.index')) {
                        items.push({
                            id: 'billings-payments',
                            parent_id: 'billings',
                            type: 'subitem',
                            colorClass: 'color-orange',
                            text: __('app:menu.transactions'),
                            icon: 'fad fa-money-check-edit-alt !tw-text-base',
                            to: {name: 'payments.index', params: {nursery: props.nursery.id}},
                        });
                    }

                    if (checkAccessToRouteName('cashier/customers.index')) {
                        items.push({
                            id: 'billings-customers',
                            parent_id: 'billings',
                            type: 'subitem',
                            colorClass: 'color-orange',
                            text: __('app:menu.customers'),
                            icon: 'fad fa-file-user',
                            to: {name: 'cashier/customers.index', params: {nursery: props.nursery.id}},
                        });
                    }
                }

                if (checkAccessToRouteName('module.hr')) {
                    items.push({
                        type: 'item',
                        id: 'staffs',
                        text: __('app:menu.team'),
                        icon: 'fad fa-users',
                        colorClass: 'color-pink',
                        to: {name: 'module.hr', params: {nursery: props.nursery.id}},
                        hasSettingsButton: checkAccessToRouteName('humanresources/settings'),
                        settingsTo: {name: 'humanresources/settings', params: {nursery: props.nursery.id}},
                    });
                }

                if (hoveredItem.value === 'staffs' || hrModuleVisible.value) {
                    if (checkAccessToRouteName('staffs.index')) {
                        items.push({
                            id: 'staffs-index',
                            parent_id: 'staffs',
                            type: 'subitem',
                            text: __('app:menu.staffs'),
                            icon: 'fad fa-id-badge',
                            colorClass: 'color-pink',
                            to: {name: 'staffs.index', params: {nursery: props.nursery.id}},
                        });
                    }
                    if (checkAccessToRouteName('humanresources/reports')) {
                        items.push({
                            id: 'staffs-reports',
                            parent_id: 'staffs',
                            type: 'subitem',
                            text: __('app:menu.reports'),
                            icon: 'fad fa-file-chart-pie',
                            colorClass: 'color-pink',
                            to: {name: 'humanresources/reports', params: {nursery: props.nursery.id}},
                        });
                    }
                }

                if (
                    checkAccessToRouteName('photos.index')
                    || checkAccessToRouteName('documents.index')
                    || checkAccessToRouteName('communication.index')
                ) {
                    items.push({
                        type: 'group',
                        id: 'communicate',
                        name: __('app:menu.communicate'),
                    });
                }

                if (checkAccessToRouteName('photos.index')) {
                    items.push({
                        type: 'item',
                        id: 'photos',
                        text: __('app:menu.photos'),
                        icon: 'fad fa-image',
                        colorClass: 'color-blue',
                        to: {name: 'photos.index', params: {nursery: props.nursery.id}},
                    });
                }

                if (checkAccessToRouteName('documents.index')) {
                    items.push({
                        type: 'item',
                        id: 'documents',
                        text: __('app:menu.documents'),
                        icon: 'fad fa-file',
                        colorClass: 'color-purple',
                        to: {name: 'documents.index', params: {nursery: props.nursery.id}},
                    });
                }

                if (checkAccessToRouteName('communication.index')) {
                    items.push({
                        type: 'item',
                        id: 'communication',
                        text: __('app:menu.news'),
                        icon: 'fad fa-bullhorn',
                        colorClass: 'color-yellow',
                        to: {name: 'module.communication', params: {nursery: props.nursery.id}},
                        hasSettingsButton: checkAccessToRouteName('settings.communication'),
                        settingsTo: {name: 'settings.communication', params: {nursery: props.nursery.id}},
                    });
                }

                if (
                    checkAccessToRouteName('equipment.index')
                    || checkAccessToRouteName('settings')
                ) {
                    items.push({
                        type: 'group',
                        id: 'settings-group',
                        name: __('app:menu.tools'),
                    });
                }

                if (checkAccessToRouteName('equipment.index')) {
                    items.push({
                        type: 'item',
                        id: 'equipments',
                        text: __('app:menu.equipments'),
                        icon: 'fad fa-boxes-alt',
                        colorClass: 'color-light-green',
                        to: {name: 'equipment.index', params: {nursery: props.nursery.id}},
                    });
                }

                if (checkAccessToRouteName('settings')) {
                    items.push({
                        type: 'item',
                        id: 'settings',
                        text: __('app:menu.settings'),
                        icon: 'fas fa-cog',
                        colorClass: 'color-settings',
                        to: {name: 'settings', params: {nursery: props.nursery.id}},
                    });
                }

                return items;
            });

            function modulePathActive(name: string) {
                if (route.path.startsWith('/nurseries')) {
                    const url = route.path.split('/');
                    // Remove '/', 'nurseries' and ':id'
                    url.shift();
                    url.shift();
                    url.shift();

                    return url[0] === name;
                }

                return false;
            }

            function onItemHovered(item: any) {
                clearTimeout(hoverTimeout.value);
                hoverTimeout.value = setTimeout(() => {
                    hoveredItem.value = item;
                }, 300);
            }

            function onItemUnhovered() {
                clearTimeout(hoverTimeout.value);
                hoverTimeout.value = setTimeout(() => {
                    hoveredItem.value = undefined;
                }, 300);
            }

            return {
                hoveredItem,
                menu,
                onItemHovered,
                onItemUnhovered,
            };
        },
    });
</script>

<style
    lang="scss"
    scoped
>
    .Tools__container {
        @apply tw-flex tw-gap-1;
        @screen sm {
            @apply tw-flex-col;
        }
    }

    .Tools_group-name {
        @apply tw-hidden;

        @screen lg {
            @apply tw-mt-1 tw-px-2 tw-block tw-text-xs tw-font-display tw-font-semibold tw-uppercase tw-text-primary-950/40 tw-truncate;
        }
    }

    .Tools_item {
        @apply tw-min-h-[38px];
        @apply tw-flex tw-flex-col tw-items-center tw-gap-1;
        @apply tw-p-1.5 sm:tw-px-0.5 tw-shrink-0 tw-rounded-lg;
        @apply tw-text-primary-950/70 hover:tw-bg-primary-800/10 hover:tw-text-primary-950/90;

        //@apply sm:tw-py-2;
        @screen lg {
            @apply tw-p-2 tw-rounded-xl tw-leading-6 tw-flex-row tw-justify-center tw-gap-2;
        }
    }

    .Tools__item-name {
        @apply tw-text-xs tw-flex-1 tw-truncate tw-font-display;
        @apply tw-w-full tw-text-center tw-truncate tw-leading-tight;

        //@apply sm:tw-hidden;
        @apply lg:tw-block lg:tw-text-base lg:tw-text-left;
    }

    .Tools__item-icon {
        @apply tw-shrink-0 tw-w-6 tw-text-xl tw-leading-none;
        @apply tw-flex tw-justify-center tw-items-center;
        color: var(--color);
        //clip-path: url(#squircleClip);
        //@apply tw-bg-primary-800/10 tw-text-primary-900/50 hover:tw-text-inherit #{!important};
    }

    .Tools_item-settings {
        @apply tw-hidden lg:tw-flex;
        @apply tw-ml-auto;
        @apply tw-justify-center tw-items-center tw-w-6 tw-h-6;
        @apply tw-rounded-lg tw-opacity-0 tw-bg-primary-900/20 tw-text-primary-900/60 tw-text-sm;
        @apply tw-transition-all hover:tw-bg-primary-900/40 hover:tw-text-primary-900/80 group-hover:tw-opacity-100;
    }

    .Tools_item.router-link-active .Tools_item-settings {
        @apply tw-bg-white/20 tw-text-white;

        &:hover {
            @apply tw-bg-white;
            color: var(--color);
        }

        @apply group-hover:tw-opacity-100;
    }

    .Tools_item.router-link-active .Tools_item-settings.router-link-active {
        @apply tw-bg-white/20 tw-text-white/80 tw-opacity-100;

        @apply tw-bg-white;
        color: var(--color);
    }

    .Tools_item.router-link-active {
        @apply tw-font-medium;

        .Tools__item-name, .Tools__item-icon {
            @apply tw-text-white;
        }

        background: var(--color) linear-gradient(to bottom, var(--color) 0%, var(--color-to) 100%);
    }

    .Tools__subitem {
        @apply tw-min-h-[32px];
        @apply tw-flex tw-flex-col tw-items-center tw-gap-1 tw-font-display;
        @apply tw-p-1.5 sm:tw-px-0.5 tw-shrink-0 tw-rounded-lg;
        @apply tw-text-primary-950/70 hover:tw-bg-primary-800/10 hover:tw-text-primary-950/90;

        //@apply sm:tw-py-2;
        @screen lg {
            @apply tw-flex-row tw-justify-center;
            @apply tw-rounded-lg tw-leading-6;
            @apply tw-ml-4 tw-px-2 tw-py-0.5;
        }
    }

    .Tools__subitem-name {
        @apply tw-text-xs tw-flex-1 tw-truncate;
        @apply tw-w-full tw-text-center tw-truncate tw-leading-tight;

        //@apply sm:tw-hidden;
        @apply lg:tw-block lg:tw-text-base lg:tw-text-left;
    }

    .Tools__subitem.router-link-active {
        @apply tw-font-medium tw-bg-primary-800/10;
        color: var(--color);
    }

    .Tools__subitem-icon {
        color: var(--color);
        @apply tw-shrink-0 tw-w-6 tw-text-center tw-text-2xl lg:tw-text-xl tw-leading-none;
    }

    .color-cyan {
        --color: #00bcd4;
        --color-to: #00a5bb;
    }

    .color-blue {
        --color: #2196f3;
        --color-to: #0d8aee;
    }

    .color-purple {
        --color: #9446ED;
        --color-to: #872feb;
    }

    .color-yellow {
        --color: #eabe33;
        --color-to: #de9f18;
    }

    .color-orange {
        --color: #f8771c;
        --color-to: #ef6809;
    }

    .color-pink {
        --color: #e91e63;
        --color-to: #d81558;
    }

    .color-green {
        --color: #009688;
        --color-to: #007d71;
    }

    .color-light-green {
        --color: #65c300;
        --color-to: #45ad00;
    }

    .color-settings {
        --color: #7790a7;
        --color-to: #68839d;
    }

    .list-complete-item {
        transition: opacity 500ms, transform 500ms;
    }

    .list-complete-enter {
        opacity: 0;
    }

    .list-complete-leave-to {
        opacity: 0;
    }

    .list-complete-leave-active {
        display: none;
    }
</style>
