import type FrequencyValue from '@/modules/calendar/utils/RuleSet/values/FrequencyValue';
import DayValue from '@/modules/calendar/utils/RuleSet/values/DayValue';
import type MonthValue from '@/modules/calendar/utils/RuleSet/values/MonthValue';
import type OptionValue from '@/modules/calendar/utils/RuleSet/values/OptionValue';
import type RuleInterface from '@/modules/calendar/utils/RuleSet/RuleInterface';
import _cloneDeep from 'lodash-es/cloneDeep';
import type {Range} from '@/modules/calendar/utils/RuleSet/Range';

export type Enumerate<N extends number, Acc extends number[] = []> = Acc['length'] extends N
    ? Acc[number]
    : Enumerate<N, [...Acc, Acc['length']]>;

export default class Rule implements RuleInterface {
    public dtstart?: string;
    public until?: string;

    public count?: number;
    public freq?: FrequencyValue;
    public interval?: number;

    public wkst: DayValue = DayValue.monday;

    public bysetpos?: number[];
    public byweekday?: DayValue[];
    public bymonth?: MonthValue[];
    public bymonthday?: Array<Range<1, 31>>;
    public byyearday?: Array<Range<1, 366>>;
    public byweekno?: Array<Range<1, 53>>;
    public byhour?: Array<Range<1, 23>>;
    public byminute?: Array<Range<1, 60>>;
    public bysecond?: Array<Range<1, 60>>;

    public options?: OptionValue[];

    public static fromJSON(ruleJson: RuleInterface): Rule {

        const rule = new Rule();

        if (!ruleJson) {
            return rule;
        }

        const jsonClone = _cloneDeep(ruleJson);

        rule.dtstart = jsonClone.dtstart;
        rule.until = jsonClone.until;
        rule.count = jsonClone.count;
        rule.freq = jsonClone.freq;
        rule.interval = jsonClone.interval;
        rule.wkst = jsonClone.wkst || DayValue.monday;
        rule.bysetpos = jsonClone.bysetpos;
        rule.byweekday = jsonClone.byweekday;
        rule.bymonth = jsonClone.bymonth;
        rule.bymonthday = jsonClone.bymonthday;
        rule.byyearday = jsonClone.byyearday;
        rule.byweekno = jsonClone.byweekno;
        rule.byhour = jsonClone.byhour;
        rule.byminute = jsonClone.byminute;
        rule.bysecond = jsonClone.bysecond;
        rule.options = jsonClone.options;

        return rule;
    }

    public reset() {
        this.count = undefined;
        this.interval = undefined;
        this.wkst = DayValue.monday;
        this.bysetpos = undefined;
        this.byweekday = undefined;
        this.bymonth = undefined;
        this.bymonthday = undefined;
        this.byyearday = undefined;
        this.byweekno = undefined;
        this.byhour = undefined;
        this.byminute = undefined;
        this.bysecond = undefined;
        this.options = undefined;
    }

    public copy() {
        return Rule.fromJSON(this);
    }
}
