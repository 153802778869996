<template>
    <div>
        <div class="row">
            <div class="col-12 col-md-7">
                <div>
                    <h2
                        class="h2 tw-font-grandhotel tw-capitalize d-inline-block"
                        :class="nursery.gender === 'male' ? 'boy' : 'girl'"
                    >
                        {{ nursery.name }}
                    </h2>
                    <button
                        class="btn btn-sm btn-outline-primary d-inline-block mb-2 ml-2 rounded"
                        data-target="#edit-summary"
                        data-toggle="modal"
                    >
                        <i
                            aria-hidden="true"
                            class="fa fa-pencil"
                        />&nbsp;{{ __('common:actions.update') }}
                    </button>
                </div>
                <div>
                    <p
                        v-if="nursery.city"
                        class="text-muted mb-0 d-inline-block"
                    >
                        &nbsp;<i
                            aria-hidden="true"
                            class="fa fa-map-marker text-info"
                        />&nbsp;{{ nursery.city }} {{ nursery.state }}
                    </p>
                </div>
            </div>
        </div>
        <div class="informations mt-3 mb-3">
            <div class="row">
                <div class="col-4 text-center">
                    <i
                        v-if="nursery.places"
                        class="fa fa-users mr-2 text-primary text-center"
                        style="width: 20px;"
                    />{{ __('common:place_with_count', {count: nursery.places}) }}
                </div>
                <div class="col-4 text-center">
                    <i
                        v-if="nursery.phone"
                        class="fa fa-phone mr-2 text-primary text-center"
                        style="width: 20px;"
                    />{{ nursery.phone }}
                </div>
                <div class="col-4 text-center">
                    <i
                        v-if="nursery.email"
                        class="fa fa-envelope mr-2 text-primary text-center"
                        style="width: 20px;"
                    />{{ nursery.email }}
                </div>
            </div>
        </div>
        <div class="row"/>
    </div>
</template>

<script>
    import _head from 'lodash-es/head';
    import _forEach from 'lodash-es/forEach';
    import route from '@/modules/legacy/libs/ziggy';
    import useApi from '@/modules/app/composables/useApi';
    import moment from 'moment';
    import swal from 'sweetalert2/dist/sweetalert2.js';
    import __ from '@/modules/app/utils/i18n-facade';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';

    export default {
        components: {},
        props: ['nursery'],
        data: () => ({
            newGroup: {
                name: null,
            },
        }),
        computed: {
            moment() {
                return moment;
            },
        },
        methods: {
            formatDate(date) {
                return moment(date).format('DD/MM/YYYY');
            },
            addGroup(data) {
                useApi().legacy.post(route('groups.store')
                                     , data).then(response => {
                                         this.nursery.groups.push(response.data);
                                         this.newGroup.name = null;
                                     }).catch(error => {
                        if (error && error.response && error.response.status === 422) {
                            _forEach(error.response.data.errors, function(value) {
                                useNotification().error(_head(value));
                            });
                        } else {
                            useNotification().error(error);
                        }
                    });
            },
            deleteGroup(id, index) {
                swal({
                    title: `<h1 class='h1 tw-font-grandhotel'>${__('common:delete_group')}</h1>`,
                    confirmButtonText: __('common:actions.remove'),
                    cancelButtonText: __('common:actions.cancel'),
                }).then(result => {
                    if (result.value) {
                        useApi().legacy.delete(route('groups.destroy', {
                            group: id,
                        })).then(() => {
                            this.nursery.groups.splice(index, 1);
                        }).catch(error => {
                            useNotification().error(error);
                        });
                    }
                });
            },
            save() {
                useApi().legacy.put(route('nurseries.update', {nurseries: this.nursery.id}), this.nursery).then(() => {
                    useNotification().success(__('news:presentation_updated'));
                }).catch(error => {
                    if (error && error.response && error.response.status === 422) {
                        _forEach(error.response.data.errors, function(value) {
                            useNotification().error(_head(value));
                        });
                    } else {
                        useNotification().error(error);
                    }
                });
            },
            changeNursery(newNursery) {
                this.$emit('nurseryChanged', newNursery);
            },
        },
    };
</script>

<style lang="scss" scoped>
    .avatar {
        height: 80px;
        width: 80px;
        float: left;
        margin-left: -10px;
        margin-top: -8px;
        margin-right: 10px;
    }

    .informations {
        background-color: #E3F2FD;
        padding: 8px 20px;
        margin: 0 -20px;
        color: #2b4c65;
    }

    .general-equipments {
        > div {
            display: inline-block;
            text-align: center;
            font-size: 0.8rem;
        }

        img {
            height: 50px;
            width: 50px;
        }
    }
</style>
