<script setup lang="ts">
    import useAbility from '@/modules/app/composables/useAbility';
    import MListItemTitle from '@/modules/meeko-ui/components/MListItemTitle.vue';
    import MagicIndexItemColumn from '@/modules/magic-index/components/atoms/MagicIndexItemColumn.vue';
    import {ref} from 'vue';
    import {useElementSize} from '@vueuse/core';

    defineProps({
        userItem: {
            type: Object,
            required: true,
        },
        userMenus: {
            type: Function,
            required: true,
        },
    });

    const {can} = useAbility();
    const usersManagerListItem = ref(null);
    const {width} = useElementSize(usersManagerListItem);
</script>

<template>
    <div
        ref="usersManagerListItem"
        class="UsersManagerListItem"
    >
        <MagicIndexItemColumn
            class="UsersManagerListItem__column-name"
            :grid-enabled="false"
        >
            <MListItemTitle
                class="tw-truncate"
                :title="$fullName(userItem)"
            />
        </MagicIndexItemColumn>
        <MagicIndexItemColumn
            class="UsersManagerListItem__column-actions"
            :grid-enabled="false"
        >
            <MDropdown v-if="can('update', 'users')">
                <MButton
                    icon-ellipsis
                    variant="ghost"
                />
                <template #content>
                    <CDropdownMenuItem
                        class="tw-w-full"
                        icon="fa fa-pencil"
                        :label="__('common:actions.update')"
                        @click="$emit('editUser', userItem)"
                    />
                    <CDropdownMenuItem
                        v-if="!userItem.is_owner"
                        class="tw-w-full"
                        icon="fa fa-user-lock"
                        :label="__('user:manage_permissions_dots')"
                        @click="$emit('openPermissions', userItem)"
                    />
                </template>
            </MDropdown>
        </MagicIndexItemColumn>
        <MagicIndexItemColumn
            class="UsersManagerListItem__column-permissions"
            :container-width="width"
            :grid-title="__('common:permissions')"
        >
            <div class="UsersManagerListItem__permissions">
                <CBadge
                    v-if="userItem.is_owner"
                    variant="green"
                >
                    {{ __('user:admin_full_permissions') }}
                </CBadge>
                <CBadge
                    v-else-if="userMenus(userItem).length === 0"
                    variant="gray"
                >
                    {{ __('user:no_access_to_the_current_organization') }}
                </CBadge>
                <div
                    v-else
                    class="tw-flex tw-flex-wrap tw-gap-2"
                >
                    <MTooltip
                        v-for="(menu, index) in userMenus(userItem)"
                        :key="'menu-permission' + index"
                        :label="menu.displayName"
                        placement="top"
                    >
                        <div
                            class="tw-w-6 tw-aspect-square tw-rounded-full tw-flex tw-items-center tw-justify-center"
                            :class="menu.class"
                        >
                            <FontAwesomeIcon
                                class="tw-text-white tw-text-sm"
                                :icon="menu.icon"
                            />
                        </div>
                    </MTooltip>
                </div>
            </div>
        </MagicIndexItemColumn>
    </div>
</template>

<style lang="scss" scoped>
    .UsersManagerListItem {
        @apply tw-grid tw-grid-cols-24 tw-items-center tw-gap-3;
        container-type: inline-size;
        container-name: UsersManagerListItem;
    }

    .UsersManagerListItem__column-name {
        @apply tw-col-span-20;

        @container UsersManagerListItem (min-width: 48rem) {
            @apply tw-col-span-12;
        }
    }

    .UsersManagerListItem__column-actions {
        @apply tw-col-span-4;
        @apply tw-order-none;
        @apply tw-text-right;

        @container UsersManagerListItem (min-width: 48rem) {
            @apply tw-col-span-1 tw-order-last;
        }
    }

    .UsersManagerListItem__column-permissions {
        @apply tw-col-span-full tw-order-last;

        @container UsersManagerListItem (min-width: 48rem) {
            @apply tw-col-span-11 tw-order-none;
        }
    }
</style>
