<template>
    <CCenter class="tw-relative tw-bg-yellow-300 tw-rounded-full tw-h-9 tw-w-9">
        <font-awesome-icon
            class="tw-text-yellow-700"
            icon="fa-solid fa-memo-pad"
        />
        <div class="tw-flex tw-absolute tw--top-1 tw--right-1 tw-text-yellow-600">
            <font-awesome-icon icon="fa-solid fa-circle-plus"/>
        </div>
    </CCenter>
</template>

<script lang="ts">
    import {defineComponent} from 'vue';

    export default defineComponent({});
</script>

<style lang="scss" scoped>

</style>
