<template>
    <MModal
        :header-title="title"
        :modal="modal"
        :size="event.type && !event.kid_id ? '4xl' : 'lg'"
        tabindex="-1"
    >
        <support-meeko :ressource="event"/>

        <template v-if="!event.kid_id">
            <SelectKid
                :nursery="nursery"
                @kidChanged="setSelectedKid"
            />
        </template>
        <template v-else>
            <div
                v-if="event.rangeAbsence && event.type === 'absence'"
                class="row"
            >
                <div class="col-6">
                    <label class="label">{{ __('common:beginning') }}</label>
                    <MDatePicker
                        v-model="event.started_at"
                        class="tw-w-full"
                        with-time
                    />
                </div>
                <div class="col-6">
                    <label class="label">{{ __('common:end') }}</label>
                    <MDatePicker
                        v-model="event.ended_at"
                        class="tw-w-full"
                        with-time
                    />
                </div>
            </div>
            <div
                v-else
                class="row"
            >
                <div
                    v-if="event.type !== 'absence'"
                    class="col-12 mb-2"
                >
                    <label class="label">{{ __('common:the') }}</label>
                    <MDatePicker
                        v-model="event.started_at"
                        class="tw-w-full"
                    />
                </div>
                <div class="col-6">
                    <label class="label">{{ __('common:time_from') }}</label>
                    <MTimePicker
                        v-model="event.start_time"
                        class="tw-w-full"
                        :modifier="startOfMinute"
                    />
                </div>
                <div class="col-6">
                    <label class="label">{{ __('common:time_to') }}</label>
                    <MTimePicker
                        v-model="event.end_time"
                        :allow-undefined="event.type === 'presence'"
                        class="tw-w-full"
                        :modifier="startOfMinute"
                    />
                </div>
            </div>

            <template v-if="event.type === 'absence'">
                <div class="row mt-3">
                    <div class="col-12">
                        <textarea
                            v-model="event.note"
                            class="form-control form-control-sm"
                            :placeholder="__('common:comment_dots')"
                            rows="2"
                        />
                    </div>
                </div>

                <hr class="hr mt-4">
                <div class="row mt-3">
                    <CForm class="col-12">
                        <CFormGroup>
                            <CLabel>{{ __('planning:how_to_deduct') }}</CLabel>
                            <MButtonSelect
                                v-model="event.unit"
                                :options="unitAbsOptions"
                            />
                        </CFormGroup>
                        <CFormGroup>
                            <CCheckbox v-model="event.vacation">
                                {{ __('planning:take_a_vacation_day_off') }}
                            </CCheckbox>
                        </CFormGroup>
                    </CForm>
                </div>
            </template>


            <template v-if="event.type === 'presence'">
                <div class="row mt-2">
                    <div class="col-12">
                        <label class="label">
                            <i
                                aria-hidden="true"
                                class="fa fa-pen-square mr-2"
                            />{{ __('planning:arrival_note') }}
                        </label>
                        <textarea
                            v-model="event.drop_note"
                            class="form-control form-control-sm"
                            :placeholder="__('planning:arrival_note_dots')"
                            rows="2"
                        />
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-12">
                        <label class="label">
                            <i
                                aria-hidden="true"
                                class="fa fa-pen-square mr-2"
                            />{{ __('planning:departure_note') }}
                        </label>
                        <textarea
                            v-model="event.pick_up_note"
                            class="form-control form-control-sm"
                            :placeholder="__('planning:departure_note_dots')"
                            rows="2"
                        />
                    </div>
                </div>
            </template>


            <template v-if="event.type === 'occasional' || event.type === 'adaptation'">
                <div class="row mt-3">
                    <div class="col-12">
                        <textarea
                            v-model="event.note"
                            class="form-control form-control-sm"
                            :placeholder="__('common:comment_dots')"
                            rows="2"
                        />
                    </div>
                </div>

                <hr class="hr mt-4">
                <div class="row my-3">
                    <CForm class="col-12">
                        <CFormGroup>
                            <CLabel>{{ __('planning:how_to_bill_presence') }}</CLabel>
                            <MButtonSelect
                                v-model="event.unit"
                                :options="unitOptions"
                            />
                        </CFormGroup>
                    </CForm>
                </div>
                <div
                    v-if="event.unit !== 'free'"
                    class="row mt-2"
                >
                    <div
                        v-if="event.unit === 'hour'"
                        class="col"
                    >
                        <CInputGroup>
                            <MInput
                                v-model.number="event.hours"
                                v-float
                            />
                            <CInputAddon>
                                {{ __('common:hour_short') }}
                            </CInputAddon>
                        </CInputGroup>
                    </div>
                    <div class="col">
                        <CInputGroup>
                            <MInput
                                v-model.number="event.hourly_rate"
                                v-float
                            />
                            <CInputAddon class="tw-shrink-0">
                                {{ hourlyRateLoading ? '...' : nursery.currency + __('common:per_hour_short') }}
                            </CInputAddon>
                        </CInputGroup>
                    </div>
                    <div class="col align-self-center">
                        <i>{{ __('planning:event_line_total_price', {value: totalPrice + ' ' + nursery.currency}) }}</i>
                    </div>
                    <div
                        v-if="!hourlyRateLoading && !event.family_order_id && !event.id"
                        class="col-12 text-sm text-muted mt-2"
                    >
                        <i
                            aria-hidden="true"
                            class="fad fa-exclamation-triangle mr-1"
                        />{{ __('planning:no_billing_account_warning') }}
                    </div>
                </div>
            </template>
        </template>
        <template #footer-end="{closeDialog}">
            <CButton @click="closeDialog">
                {{ __('common:actions.cancel') }}
            </CButton>
            <CButton
                v-if="event.isNew"
                :loading="loading"
                variant="primary"
                @click="add"
            >
                {{ __('common:actions.add') }}
            </CButton>
            <CButton
                v-else
                :loading="loading"
                variant="primary"
                @click="update"
            >
                {{ __('common:actions.update') }}
            </CButton>
        </template>
    </MModal>
</template>

<script>
    import _cloneDeep from 'lodash-es/cloneDeep';
    import _head from 'lodash-es/head';
    import _forEach from 'lodash-es/forEach';
    import Vue from 'vue';
    import route from '@/modules/legacy/libs/ziggy';
    import useApi from '@/modules/app/composables/useApi';
    import SelectKid from './SelectKid.vue';
    import __ from '@/modules/app/utils/i18n-facade';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';
    import {Epoch} from '@meekohq/lumos';
    import {useDateTimeModifiersPresets} from '@/modules/meeko-ui/composables/useDateTimeModifiers';

    export default {
        components: {
            SelectKid,
        },
        props: ['myEvent', 'nursery', 'user', 'modal'],
        data() {
            return {
                event: {},
                hoursInDay: null,
                hourlyRateLoading: false,
                unitOptions: [{
                    value: 'day',
                    text: __('common:the_day'),
                }, {
                    value: 'halfday',
                    text: __('common:the_halfday'),
                }, {
                    value: 'hour',
                    text: __('planning:units.by_the_hour'),
                }, {
                    value: 'free',
                    text: __('planning:units.free_female'),
                }],
                unitAbsOptions: [{
                    value: 'day',
                    text: __('common:day_female_one'),
                }, {
                    value: 'halfday',
                    text: __('common:halfday_one'),
                }, {
                    value: 'hour',
                    text: __('planning:units.by_the_hour'),
                }, {
                    value: 'free',
                    text: __('planning:units.non_deducted'),
                }],
                loading: false,
                titleOptions: {
                    adaptation: {
                        create: __('planning:add_adaptation'),
                        edit: __('planning:edit_adaptation'),
                    },
                    occasional: {
                        create: __('planning:add_reservation'),
                        edit: __('planning:edit_reservation'),
                    },
                    presence: {
                        create: __('planning:add_pointing'),
                        edit: __('planning:edit_pointing'),
                    },
                    absence: {
                        create: __('planning:add_absence'),
                        edit: __('planning:edit_absence'),
                    },
                    fallback: {
                        create: __('planning:add_planning'),
                        edit: __('planning:edit_planning'),
                    },
                },
            };
        },
        computed: {
            totalPrice() {
                let total = this.event.hourly_rate;
                const hoursInDay = this.hoursInDay ? this.hoursInDay : this.nursery.hours_in_day;
                switch (this.event.unit) {
                    case 'day':
                        total = total * hoursInDay;
                        this.setEventHours(hoursInDay);
                        break;
                    case 'halfday':
                        total = total * hoursInDay / 2;
                        this.setEventHours(hoursInDay / 2);
                        break;
                    case 'hour':
                        total = total * this.event.hours;
                        break;
                }

                return Math.round(total * 100) / 100;
            },
            title() {
                const optionKey = this.myEvent.isNew ? 'create' : 'edit';

                if (this.titleOptions[this.myEvent.type]) {
                    return this.titleOptions[this.myEvent.type][optionKey];
                }

                return this.titleOptions[this.myEvent.type]['fallback'];
            },
        },
        watch: {
            'event.started_at': function(val) {
                if (this.event.kid_id && this.event.isNew && (this.event.type === 'occasional' || this.event.type === 'adaptation') && val) {
                    this.getBillingConfig();
                }
            },
            'event.kid_id': function() {
                if (this.event.kid_id && this.event.isNew && (this.event.type === 'occasional' || this.event.type === 'adaptation') && this.event.started_at) {
                    this.getBillingConfig();
                }
            },
            'event.start_time': function() {
                if (this.event.unit === 'hour') {
                    this.event.hours = this.getHours();
                }
            },
            'event.end_time': function() {
                if (this.event.unit === 'hour') {
                    this.event.hours = this.getHours();
                }
            },
            'event.unit': function(val) {
                if (val === 'hour') {
                    this.event.hours = this.getHours();
                }
            },
        },
        mounted() {
            if (Number.isInteger(this.myEvent.started_at)) {
                this.myEvent.started_at = Epoch.fromTimestamp(this.myEvent.started_at).toISOString();
            }

            const copy = _cloneDeep(this.myEvent);

            if (this.myEvent.start_time) {
                copy.start_time = Epoch.parse(this.myEvent.start_time, 'HH:mm:ss');
            } else {
                copy.start_time = Epoch.now().startOfDay();
            }

            if (this.myEvent.end_time) {
                copy.end_time = Epoch.parse(this.myEvent.end_time, 'HH:mm:ss');
            } else {
                copy.start_time = Epoch.now().endOfDay();
            }

            this.event = copy;

            // If validate by notification, need to add vacation because not in notif data and is not nullable
            if (typeof this.myEvent.vacation === 'undefined' || this.myEvent.vacation === null) {
                Vue.set(this.event, 'vacation', false);
            }
            Vue.set(this.event, 'hours', this.getHours());

            if (this.event.kid_id && !this.event.hourly_rate && (this.event.type === 'occasional' || this.event.type === 'adaptation')) {
                this.getBillingConfig();
            }
        },
        methods: {
            add() {
                this.loading = true;
                const event = _cloneDeep(this.event);

                this.prepareDatesForSave(event);

                let eventRoute = undefined;
                switch (event.type) {
                    case 'occasional':
                    case 'adaptation':
                        eventRoute = route('kid.plannings.store');
                        break;
                    case 'presence':
                        eventRoute = route('kid.presences.store');
                        event.droped_at = event.started_at;
                        event.picked_up_at = event.ended_at;
                        break;
                    case 'absence':
                        eventRoute = route('kid.absences.store');
                        break;
                }

                useApi().legacy.post(eventRoute, event).then(response => {
                    switch (event.type) {
                        case 'occasional':
                        case 'adaptation':
                            this.$bus.$emit('created:planning', response.data);
                            useNotification().success(__('planning:planning_added'));
                            break;
                        case 'presence':
                            this.$bus.$emit('created:presence', response.data);
                            useNotification().success(__('planning:presence_added'));
                            break;
                        case 'absence':
                            this.$bus.$emit('created:absence', response.data);
                            useNotification().success(__('planning:absence_added'));
                            break;
                    }

                    if (event.notification) {
                        if (this.$route.name === 'kids.show') {
                            this.$bus.$emit('refresh:kid');
                        } else if (this.$route.name === 'nursery' || this.$route.name === 'planning.kids') {
                            this.$bus.$emit('refresh:kids');
                        }

                        if (event.notification.referenceable.id) {
                            useApi().legacy.put(route('nurseries.messages.update', {
                                nurseries: event.nursery_id,
                                message: event.notification.referenceable.id,
                            }), {
                                state: event.notification.state,
                            }).then(response => {
                                event.notification.state = response.data.state;
                                event.notification.referenceable = response.data;

                                this.$bus.$emit('refreshNotification', event.notification);
                                this.$bus.$emit('refreshChatMessage', event.notification);
                            }).catch(error => {
                                if (error && error.response && error.response.status === 422) {
                                    _forEach(error.response.data.errors, value => {
                                        useNotification().error(_head(value));
                                    });
                                } else {
                                    useNotification().error(error);
                                }
                            });
                        } else {
                            this.$bus.$emit('update:notification', event.notification);
                        }

                    }

                    this.loading = false;
                    this.modal.hide();
                }).catch(error => {
                    this.loading = false;
                    if (error && error.response && error.response.status === 422) {
                        _forEach(error.response.data.errors, value => {
                            useNotification().error(_head(value));
                        });
                    } else {
                        useNotification().error(error);
                    }
                });
            },

            update() {
                this.loading = true;
                const event = _cloneDeep(this.event);

                this.prepareDatesForSave(event);

                if (event.type === 'presence') {
                    event.droped_at = event.started_at;
                    event.picked_up_at = event.ended_at;
                }

                let eventRoute = undefined;
                switch (event.type) {
                    case 'occasional':
                    case 'adaptation':
                        eventRoute = route('kid.plannings.update', {planning: event.id});
                        break;
                    case 'presence':
                        eventRoute = route('kid.presences.update', {presence: event.id});
                        break;
                    case 'absence':
                        eventRoute = route('kid.absences.update', {absence: event.id});
                        break;
                }

                useApi().legacy.put(eventRoute, event).then(response => {
                    switch (this.event.type) {
                        case 'occasional':
                        case 'adaptation':
                            this.$bus.$emit('updated:planning', response.data);
                            useNotification().success(__('planning:planning_updated'));
                            break;
                        case 'absence':
                            this.$bus.$emit('updated:absence', response.data);
                            useNotification().success(__('planning:absence_updated'));
                            break;
                        case 'presence':
                            this.$bus.$emit('updated:presence', response.data);
                            useNotification().success(__('planning:presence_updated'));
                            break;
                    }
                    this.loading = false;
                    this.modal.hide();
                }).catch(error => {
                    this.loading = false;
                    if (error && error.response && error.response.status === 422) {
                        _forEach(error.response.data.errors, value => {
                            useNotification().error(_head(value));
                        });
                    } else {
                        useNotification().error(error);
                    }
                });
            },

            getBillingConfig() {
                this.hourlyRateLoading = true;

                useApi().legacy.get(route('nurseries.kids.billing.config', {
                    nurseries: this.event.nursery_id,
                    kid: this.event.kid_id,
                }), {
                    params: {
                        from: Epoch.fromISOString(this.event.started_at).startOfDay().toTimestamp(),
                        to: Epoch.fromISOString(this.event.started_at).endOfDay().toTimestamp(),
                    },
                }).then(response => {
                    switch (this.event.type) {
                        case 'adaptation':
                            this.event.unit = response.data.adaptation_unit;
                            this.event.hourly_rate = response.data.adaptation_hourly_rate;
                            break;
                        case 'recurrent':
                            this.event.unit = response.data.recurrent_unit;
                            this.event.hourly_rate = response.data.recurrent_hourly_rate;
                            break;
                        case 'occasional':
                            this.event.unit = response.data.occasional_unit;
                            this.event.hourly_rate = response.data.occasional_hourly_rate;
                            break;
                    }
                    this.event.family_order_id = response.data.family_order_id;
                    this.hoursInDay = response.data.hours_in_day;
                    this.hourlyRateLoading = false;
                }).catch(error => {
                    this.hourlyRateLoading = false;
                    if (error && error.response && error.response.status === 422) {
                        _forEach(error.response.data.errors, value => {
                            useNotification().error(_head(value));
                        });
                    } else {
                        useNotification().error(error);
                    }
                });
            },

            setEventHours(hours) {
                this.event.hours = hours;
            },

            getHours() {
                if (!this.event.start_time || !this.event.end_time) {
                    return 0;
                }

                return this.event.start_time.difference(this.event.end_time).as('hour');
            },
            setSelectedKid(val) {
                this.event.kid_id = val.id;
            },
            prepareDatesForSave(event) {
                if (event.type === 'absence' && event.rangeAbsence) {
                    event.started_at = Epoch.fromISOString(event.started_at).toTimestamp();
                    event.ended_at = Epoch.fromISOString(event.ended_at).toTimestamp();
                } else {
                    const startedAtToDate = Epoch.fromISOString(event.started_at);

                    event.started_at = startedAtToDate.set({
                        hour: event.start_time.hour,
                        minute: event.start_time.minute,
                        second: event.start_time.second,
                    }).toTimestamp();

                    if (event.end_time) {
                        event.ended_at = startedAtToDate.set({
                            hour: event.end_time.hour,
                            minute: event.end_time.minute,
                            second: event.end_time.second,
                        }).toTimestamp();
                    } else {
                        event.ended_at = null;
                    }
                }
            },

            startOfMinute: useDateTimeModifiersPresets().startOfMinute,
        },
    };
</script>
