import {Model} from '@meekohq/lumos';

export default class ContractTypeOrganizationPivot extends Model {
    public type = 'hr/contract_types_organizations_pivot';

    public attributes: {
        id: string;
        account_id: string | undefined;
        contract_type_id: string | number | undefined;
        organization_id: string | undefined;
        archived_at: string | undefined;
        created_at: string | undefined;
        updated_at: string | undefined;
    } = {
            id: this.uuid(),
            account_id: undefined,
            contract_type_id: undefined,
            organization_id: undefined,
            archived_at: undefined,
            created_at: undefined,
            updated_at: undefined,
        };
}
