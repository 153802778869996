import type {ContractRepositoryPort} from '@/modules/human-resources/contract/application/ports/ContractRepositoryPort';
import type {ContractAggregate} from '@/modules/human-resources/contract/domain/ContractAggregate';
import ContractModel from '@/modules/human-resources/models/ContractModel';
import type {Model} from '@meekohq/lumos';
import {collect, MqlTransaction} from '@meekohq/lumos';
import type StaffsOrganizationsPivot from '@/modules/human-resources/models/StaffsOrganizationsPivot';
import type BalanceAllocationModel from '@/modules/human-resources/models/BalanceAllocationModel';
import type ContractTrialPeriodModel from '@/modules/human-resources/models/ContractTrialPeriodModel';
import type OrganizationModel from '@/modules/organization/models/OrganizationModel';
import type StaffModel from '@/modules/human-resources/models/StaffModel';

export default class ContractRepositoryAdapter implements ContractRepositoryPort {
    constructor(
        private readonly mqlTransaction = new MqlTransaction(),
    ) {
    }

    public async save(contractAggregate: ContractAggregate): Promise<Model[]> {
        const savingResults: Array<Promise<Model>> = [];

        // Save contract
        savingResults.push(this.saveModel(contractAggregate.contract));

        // Save staff organization pivot
        savingResults.push(
            ...this.saveStaffOrganizationPivots(contractAggregate.staffOrganizationsPivots),
        );

        // Save contract organizations
        savingResults.push(
            ...this.saveContractOrganizations(
                contractAggregate.organizations, contractAggregate.contract,
            ),
        );

        // Save contract trial periods
        savingResults.push(
            ...this.saveContractTrialPeriods(
                contractAggregate.trialPeriods, contractAggregate.contract,
            ),
        );

        // Save contract balance allocations
        savingResults.push(
            ...this.saveContractBalanceAllocations(
                contractAggregate.balanceAllocations, contractAggregate.contract,
            ),
        );

        await this.mqlTransaction.run();

        return await Promise.all(savingResults);
    }

    public async getOverlappingContractsByContract(
        contract: ContractModel,
        staff: StaffModel,
    ): Promise<ContractModel[]> {
        let overlappedContracts = collect();

        const startedAt = contract.attributes.started_at;
        const endedAt = contract.attributes.ended_at || contract.attributes.broked_at;

        if (startedAt && endedAt) {
            await Promise.all([
                this.getOverlappingContractsByContractAndRange(contract, staff, startedAt),
                this.getOverlappingContractsByContractAndRange(contract, staff, endedAt),
                this.getOverlappingContractsByContractAndRange(contract, staff, startedAt, endedAt),
            ]).then(contracts => {
                overlappedContracts = contracts[0].concat(contracts[1]).concat(contracts[2]).unique('attributes.id');
            });
        } else if (startedAt) {
            overlappedContracts = await this.getOverlappingContractsByContractAndRange(contract, staff, startedAt);
        }

        return overlappedContracts.all();
    }

    private getOverlappingContractsByContractAndRange(contract: ContractModel, staff: StaffModel, date: string, endDate: string | null = null) {
        const contractOrganizations = contract.organizations().value();

        const query = ContractModel.query();

        if (contract.exists) {
            query.where('id', '!=', contract.getKey());
        }

        query
            .where('staff_id', staff.getKey())
            .whereHas(
                'organizations',
                q1 =>
                    q1.whereIn('id', contractOrganizations.pluck('id').all()),
            )
            .where(q1 => {
                q1.where(q2 => {
                    q2.whereNull('broked_at')
                        .where(q3 => {
                            q3.where(q4 => {
                                q4.whereDate('started_at', endDate ? '>=' : '<=', date)
                                    .whereDate('ended_at', endDate ? '<=' : '>=', endDate || date);
                            });
                            q3.orWhere(q4 => {
                                q4.whereDate('started_at', '<=', date)
                                    .whereNull('ended_at');
                            });
                        });
                }).orWhere(q2 => {
                    q2.whereNotNull('broked_at')
                        .whereDate('started_at', endDate ? '>=' : '<=', date)
                        .whereDate('broked_at', endDate ? '<=' : '>=', endDate || date);
                });

            });

        return query.with(new ContractModel().organizations()).get();
    }

    private saveStaffOrganizationPivots(staffOrganizationPivots: StaffsOrganizationsPivot[]): Array<Promise<Model>> {
        const promises: Array<Promise<Model>> = [];

        staffOrganizationPivots.forEach(staffOrganizationPivot => {
            if (!staffOrganizationPivot.exists) {
                promises.push(this.saveModel(staffOrganizationPivot));
            }
        });

        return promises;
    }

    private saveContractOrganizations(
        organizations: OrganizationModel[],
        contract: ContractModel,
    ): Array<Promise<Model>> {
        const promises: Array<Promise<Model>> = [];

        organizations.forEach(contractOrganization => {
            // If organization's pivot don't exist in database, associate the saved contract to the pivot.
            if (!contractOrganization.pivot().exists) {
                contractOrganization.pivot<any>().contract().associate(contract, false);
            }

            // Stack all entities states (add or delete) in promises
            promises.push(this.saveModel(contractOrganization.pivot()));
        });

        return promises;
    }

    private saveContractTrialPeriods(
        trialPeriods: ContractTrialPeriodModel[],
        contract: ContractModel,
    ): Array<Promise<Model>> {
        const promises: Array<Promise<Model>> = [];

        trialPeriods.forEach(trialPeriod => {
            if (!trialPeriod.exists) {
                trialPeriod.contract().associate(contract, false);
            }

            // Stack all entities states (add, update, delete) in promises
            promises.push(this.saveModel(trialPeriod));
        });

        return promises;
    }

    private saveContractBalanceAllocations(
        allocations: BalanceAllocationModel[],
        contract: ContractModel,
    ): Array<Promise<Model>> {
        const promises: Array<Promise<Model>> = [];

        allocations.forEach(allocation => {
            allocation.attributes.source_id = contract.getKey();
            promises.push(this.saveModel(allocation));
        });

        return promises;
    }

    private saveModel(model: Model): Promise<Model> {
        return model.save({mqlRunner: this.mqlTransaction});
    }
}
