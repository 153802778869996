<template>
    <CModal
        ref="modal"
        :header-title="__('hr:add_team')"
        @hide="onHide"
        @show="onShow"
    >
        <CVStack gap="4">
            <TeamForm
                ref="form"
                :organization-model="organizationModel"
                :team-model="teamModel"
                @loading="loading = $event"
                @saved="onSaved"
                @teamAttached="onAttached"
            />
            <ImportItemAlert
                v-if="teamModelToImport.isNotEmpty()"
                :model-in-other-organization="teamWithHisOrganization"
                @attachModel="$refs.form.attach($event)"
            >
                <template #item="slotProps">
                    <span class="tw-mr-1">
                        <strong>{{ slotProps.model.attributes.name }}</strong>
                    </span>
                </template>
            </ImportItemAlert>
        </CVStack>
        <template #footer>
            <CButton
                class="tw-ml-auto"
                @click="hide()"
            >
                {{ __('common:actions.close') }}
            </CButton>
            <CButton
                class="tw-ml-2"
                :loading="loading"
                variant="primary"
                @click="$refs.form.save()"
            >
                {{ __('common:actions.save') }}
            </CButton>
        </template>
    </CModal>
</template>

<script lang="ts">
    import type {Ref} from 'vue';
    import {computed, defineComponent, ref, watch} from 'vue';
    import TeamForm from '@/modules/human-resources/components/team/Forms/TeamForm.vue';
    import TeamModel from '@/modules/human-resources/models/TeamModel';
    import OrganizationModel from '@/modules/organization/models/OrganizationModel';
    import type {Collection} from '@meekohq/lumos';
    import {collect} from '@meekohq/lumos';
    import TeamOrganizationPivot from '@/modules/human-resources/models/TeamOrganizationPivot';
    import ImportItemAlert from '@/modules/app/components/ImportItemAlert.vue';
    import {debounce} from 'lodash-es';
    import useManager from '@/modules/app/composables/useManager';
    import __ from '@/modules/app/utils/i18n-facade';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';
    import {useLegacyModalBus} from '@/modules/meeko-ui/composables/useLegacyModalBus';

    export default defineComponent(
        {
            components: {ImportItemAlert, TeamForm},
            props: {},

            setup(props, {emit}) {
                const {modal, show, hide} = useLegacyModalBus();
                const loading = ref(false);
                const teamModelToImport = ref(collect<TeamModel>());

                const {
                    teamModel,
                    onShow,
                    onHide,
                    allowedOrganizations,
                    pivots,
                    organizationModel,
                    teamModelsIds,
                } = modalSetup(teamModelToImport);
                const {teamWithHisOrganization} = matchingModel(
                    allowedOrganizations as Ref<Collection<OrganizationModel>>,
                    pivots as Ref<Collection<TeamOrganizationPivot>>,
                    teamModel as Ref<TeamModel>,
                    teamModelToImport as Ref<Collection<TeamModel>>,
                    teamModelsIds,
                );

                function onSaved(value: TeamModel) {
                    emit('created', value.clone());
                    hide();
                    useNotification().success(__('hr:team_created_successfully'));
                }

                function onAttached(value: TeamModel) {
                    hide();
                    emit('attached', value);
                    useNotification().success(__('hr:team_imported_successfully'));
                }

                return {
                    loading,
                    modal,
                    teamModel,
                    organizationModel,
                    onShow,
                    onHide,
                    onSaved,
                    teamWithHisOrganization,
                    teamModelToImport,
                    onAttached,
                    // External Call
                    show,
                    hide,
                };
            },
        },
    );

    export function modalSetup(teamModelToImport) {
        const {legacyAccount} = useManager();
        const allowedOrganizations = ref(collect<OrganizationModel>());
        const pivots = ref(collect<TeamOrganizationPivot>());
        const teamModel = ref<TeamModel>(new TeamModel());
        const organizationModel: Ref<OrganizationModel | undefined> = ref();
        const teamModelsIds: Ref<string[]> = ref([]);

        async function getAllowedOrganizations() {
            allowedOrganizations.value = await OrganizationModel.query().scope('userOrganizations').get();
        }

        async function getData() {
            organizationModel.value = await OrganizationModel.query().scope('active').first();
            pivots.value = await TeamOrganizationPivot.query().all();

            const teams = await TeamModel.query()
                .whereHas('organizations', query => query.scope('active'))
                .get();
            teamModelsIds.value = teams.pluck<string>('id').all();
        }

        async function onShow() {
            teamModel.value = new TeamModel();
            teamModel.value.attributes.account_id = legacyAccount.value.id.toString();
            await getAllowedOrganizations();
            await getData();
        }

        function onHide() {
            teamModel.value.attributes.name = null;
            teamModelToImport.value = collect();
        }

        return {onShow, onHide, teamModel, allowedOrganizations, pivots, organizationModel, teamModelsIds};
    }


    function matchingModel(
        allowedOrganizations: Ref<Collection<OrganizationModel>>,
        pivots: Ref<Collection<TeamOrganizationPivot>>,
        teamModel: Ref<TeamModel>,
        teamModelToImport: Ref<Collection<TeamModel>>,
        teamModelsIds: Ref<string[]>,
    ) {
        function getTeamOrganizations(team: TeamModel): Collection<OrganizationModel> {
            return pivots.value
                .filter(pivot => pivot.attributes.team_id === team.id)
                .map(pivot => {
                    return allowedOrganizations.value.first(organization => pivot.attributes.organization_id === organization.id);
                });
        }

        const teamWithHisOrganization = computed(() => {
            return teamModelToImport.value.map(model => {
                return {
                    model,
                    organizations: getTeamOrganizations(model),
                };
            });
        });

        async function fetchMatchingItem(value: TeamModel) {
            if (value.attributes.name) {
                teamModelToImport.value = await TeamModel.query()
                    .with(new TeamModel().organizationsPivots())
                    .where('name', '=', value.attributes.name)
                    .whereHas('organizations', q => q.scope('userOrganizations').scope('notActive'))
                    .whereNotIn('id', teamModelsIds.value)
                    .get();
            }
        }

        const fetchMatchingItemDebounced = debounce(fetchMatchingItem, 500);

        watch(teamModel, async value => {
            fetchMatchingItemDebounced(value);
        }, {deep: true});

        return {
            teamWithHisOrganization,
        };
    }
</script>

