<template>
    <div :class="{'tw-hidden print:tw-block': !showDivider && showDividerOnPrint}">
        <div
            v-for="(minute, i) in enumerateMinutes"
            v-show="showHour(i)"
            :key="'minute' + minute"
            class="divider tw-z-50"
            :style="computedStyle(minute)"
        />
    </div>
</template>

<script>
    import moment from 'moment';
    import {Epoch} from '@meekohq/lumos';

    export default {
        props: {
            opening: {
                type: String,
            },
            closing: {
                type: String,
            },
            simple: {
                type: Boolean,
                default: false,
            },
            showDivider: {
                type: Boolean,
                default: true,
            },
            showDividerOnPrint: {
                type: Boolean,
                default: true,
            },
        },
        computed: {
            openingDuration() {
                return moment.duration(this.opening);
            },
            openingDurationHours() {
                return this.openingDuration.hours();
            },
            openingDurationMinutes() {
                return this.openingDuration.minutes();
            },
            openingDurationSeconds() {
                return this.openingDuration.seconds();
            },
            closingDuration() {
                return moment.duration(this.closing);
            },
            closingDurationHours() {
                return this.closingDuration.hours();
            },
            closingDurationMinutes() {
                return this.closingDuration.minutes();
            },
            closingDurationSeconds() {
                return this.closingDuration.seconds();
            },
            enumerateMinutes() {
                const minutes = [];

                const currentDate = moment()
                    .hour(this.openingDurationHours)
                    .minute(this.openingDurationMinutes)
                    .second(0).add(30, 'minutes').startOf('hours');
                // Trick to round hours (7:45 become 8.00), not a step.

                const lastDate = moment()
                    .hour(this.closingDurationHours)
                    .minute(this.closingDurationMinutes)
                    .second(0).add(30, 'minutes').startOf('hours');
                // Trick to round hours (7:45 become 8.00), not a step.

                const step = 30;
                minutes.push(currentDate.clone());
                while (currentDate.add(step, 'minutes').diff(lastDate) <= 0) {
                    minutes.push(currentDate.clone());
                }

                return minutes;
            },
        },
        methods: {
            showHour(index) {
                if (this.simple) {
                    return (index + 2) % 4 === 0;
                }

                return true;
            },
            unixToPercent(unix) {
                const openingTimestamp = Epoch.now().set({
                    hour: this.openingDurationHours,
                    minute: this.openingDurationMinutes,
                    second: this.openingDurationSeconds,
                }).toTimestamp();

                const closingTimestamp = Epoch.now().set({
                    hour: this.closingDurationHours,
                    minute: this.closingDurationMinutes,
                    second: this.closingDurationSeconds,
                }).toTimestamp();

                return (
                    ((unix - openingTimestamp) * 100) /
                    (closingTimestamp - openingTimestamp)
                );
            },
            computedStyle(minute) {
                return {
                    left: this.unixToPercent(minute.unix()) + '%',
                };
            },
        },
    };
</script>

<style lang="scss" scoped>
    @import "bootstrap/scss/functions";
    @import "@/assets/_bootstrap/variables";
    @import "bootstrap/scss/mixins/breakpoints";

    .divider {
        position: absolute;
        width: 2px;
        height: 100%;
        margin-left: -1px;
        background-color: black;
        opacity: 0.07;
        pointer-events: none;

        -webkit-print-color-adjust: exact !important; /* Chrome, Safari */
        print-color-adjust: exact !important; /*Firefox*/

        &:nth-child(even) {
            height: 50%;

            display: none;
            @include media-breakpoint-up(lg) {
                display: block;
            }

            @media print {
                display: block;
            }
        }

        &:first-of-type {
            display: none !important;
        }

        &:last-of-type {
            display: none !important;
        }
    }
</style>
