<template>
    <div class="tw-contents">
        <slot
            v-if="isPending"
            name="pending"
        />
        <slot
            v-if="!isPending"
            :value="result"
        />
        <slot
            :is-pending="isPending"
            name="combined"
            :value="result"
        />
    </div>
</template>

<script lang="ts">
    import type {PropType} from 'vue';
    import {defineComponent, ref, watch} from 'vue';

    export default defineComponent({
        components: {},
        props: {
            promise: {type: Promise as PropType<Promise<any>>, required: true},
            pendingDelay: {type: Number, default: 200},
        },
        setup(props) {
            const result = ref();
            const isPending = ref(true);

            watch(() => props.promise, value => {
                isPending.value = true;

                value.then(value => {
                    result.value = value;

                    setTimeout(() => {
                        isPending.value = false;
                    }, props.pendingDelay);
                });
            }, {immediate: true});

            return {
                result,
                isPending,
            };
        },
    });
</script>

<style lang="scss" scoped>
    //
</style>
