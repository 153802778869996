import injectableKey from '@/modules/app/utils/injectableKey';
import type {MCalendarSelectionItem} from '@/modules/meeko-ui/components/MCalendar/useMCalendarSelection';
import useMCalendarSelection from '@/modules/meeko-ui/components/MCalendar/useMCalendarSelection';
import {Epoch} from '@meekohq/lumos';
import {computed, inject, ref} from 'vue';
import {keyMCalendarState} from '@/modules/meeko-ui/components/MCalendar/useMCalendarState';
import {keyMCalendarNavigation} from '@/modules/meeko-ui/components/MCalendar/useMCalendarNavigation';

export const keyMCalendarYears = injectableKey<ReturnType<typeof useMCalendarYears>>();

export type MCalendarYearsItem = MCalendarSelectionItem & {
    year: Epoch;
};

export default function useMCalendarYears() {
    const state = inject(keyMCalendarState)!;
    if (!state) {
        throw new Error('keyMCalendarState must be provided');
    }

    const navigation = inject(keyMCalendarNavigation)!;
    if (!navigation) {
        throw new Error('keyMCalendarNavigation must be provided');
    }

    const hoveredYear = ref<Epoch>();

    const {
        isDatePeriodStart,
        isDatePeriodEnd,
        isDateSelected,
        isDateSelecting,
        isDateInPeriod,
        isDateHovered,
        handleDateClick,
    } = useMCalendarSelection(hoveredYear, 'year', state.modifiers);

    const yearsInSelectedRange = computed(() => {
        const years: MCalendarYearsItem[] = [];

        let yearsIterator = Epoch.now().setYear(navigation.yearsRange.value.from).startOfYear();
        while (yearsIterator.year <= navigation.yearsRange.value.to) {
            years.push({
                year: yearsIterator,
                isSelected: isDateSelected(yearsIterator),
                isSelecting: isDateSelecting(yearsIterator),
                isPeriodStart: isDatePeriodStart(yearsIterator),
                isPeriodEnd: isDatePeriodEnd(yearsIterator),
                isInPeriod: isDateInPeriod(yearsIterator),
                isHovered: isDateHovered(yearsIterator),
            });
            yearsIterator = yearsIterator.addYears(1);
        }

        return years;
    });

    function handleYearClick(year: Epoch) {
        if (navigation.showMonth) {
            navigation.setActiveMonthFromDate(navigation.activeMonth.value.setYear(year.year));
            navigation.toggleActiveView('month');

            return;
        }

        handleDateClick(year);
    }

    return {
        hoveredYear,
        yearsInSelectedRange,
        handleYearClick,
    };
}
