<script setup lang="ts">
    import {computed} from 'vue';
    import SubscriptionModel from '@/modules/cashier/models/SubscriptionModel';
    import {type Model, ModelCollection} from '@meekohq/lumos';
    import type EndpointModel from '@/modules/cashier/models/EndpointModel';
    import {find, findIndex, forEach} from 'lodash-es';

    const {resourceModel, endpointModel} = defineProps<{
        resourceModel: Model,
        endpointModel: EndpointModel
    }>();

    const topics = SubscriptionModel.TOPICS;

    const isSubscribed = computed(() => {
        return isSubscribedToTopic(endpointModel, topics.CASHIER_INVOICE_SENT) ||
            isSubscribedToTopic(endpointModel, topics.CASHIER_INVOICE_PAID) ||
            isSubscribedToTopic(endpointModel, topics.CASHIER_INVOICE_UNPAID) ||
            isSubscribedToTopic(endpointModel, topics.TAX_CERTIFICATE_SENT);
    });

    const isHalfSubscribed = computed(() => {
        return isSubscribed.value && !(isSubscribedToTopic(endpointModel, topics.CASHIER_INVOICE_SENT) &&
            isSubscribedToTopic(endpointModel, topics.CASHIER_INVOICE_PAID) &&
            isSubscribedToTopic(endpointModel, topics.CASHIER_INVOICE_UNPAID) &&
            isSubscribedToTopic(endpointModel, topics.TAX_CERTIFICATE_SENT));
    });

    function isSubscribedToTopic(endpoint: EndpointModel, topic: string): boolean {
        const subscription = find(endpoint.subscriptions().value()?.all(), (existingSubscription: SubscriptionModel) => {
            return existingSubscription.attributes.topic === topic &&
                existingSubscription.attributes.resource_type === resourceModel.type &&
                existingSubscription.attributes.resource_id === resourceModel.getKey();
        });

        return subscription !== undefined;
    }

    function swap(endpoint: EndpointModel) {
        if (!endpoint.hasRelation('subscriptions')) {
            endpoint.subscriptions().set(new ModelCollection());
        }

        if (isSubscribed.value) {
            forEach([
                topics.CASHIER_INVOICE_SENT,
                topics.CASHIER_INVOICE_PAID,
                topics.CASHIER_INVOICE_UNPAID,
                topics.TAX_CERTIFICATE_SENT,
            ], topic => {
                if (isSubscribedToTopic(endpoint, topic)) {
                    unsubscribeFromTopic(endpoint, topic);
                }
            });
        } else {
            endpoint.save().then(() => {
                forEach([
                    topics.CASHIER_INVOICE_SENT,
                    topics.CASHIER_INVOICE_PAID,
                    topics.CASHIER_INVOICE_UNPAID,
                    topics.TAX_CERTIFICATE_SENT,
                ], topic => {
                    if (!isSubscribedToTopic(endpoint, topic)) {
                        subscribeToTopic(endpoint, topic);
                    }
                });
            });
        }

    }

    function unsubscribeFromTopic(endpoint: EndpointModel, topic: string): void {
        const subscriptions = endpoint.subscriptions().value().all();

        const index = findIndex(subscriptions, (subscription: SubscriptionModel) => {
            return subscription.attributes.topic === topic &&
                subscription.attributes.resource_type === resourceModel.type &&
                subscription.attributes.resource_id === resourceModel.getKey();
        });

        const subscription = subscriptions[index];

        if (subscription.exists) {
            subscription.delete();
            subscriptions.splice(index, 1);
        }
    }

    function subscribeToTopic(endpoint: EndpointModel, topic: string): void {
        const subscriptions = endpoint.subscriptions().value().all();

        // Subscription not found
        const newSubscription = new SubscriptionModel();

        newSubscription.attributes.topic = topic;
        newSubscription.attributes.tenant_id = resourceModel.attributes.tenant_id;
        newSubscription.attributes.resource_type = resourceModel.type;
        newSubscription.attributes.resource_id = resourceModel.getKey();
        newSubscription.attributes.endpoint_id = endpoint.getKey();

        subscriptions.push(newSubscription);

        newSubscription.save();
    }
</script>
<template>
    <CSwitch
        :half-checked="isHalfSubscribed"
        :value="isSubscribed"
        @input="swap(endpointModel)"
    />
</template>

