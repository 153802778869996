import {config} from '@meekohq/lumos';

export default function useIntercom() {
    function setup(user) {
        // @ts-ignore
        if (!window.Intercom) {
            return;
        }

        // @ts-ignore
        window.Intercom('boot', {
            app_id: config('services.intercom.appId'),
            uuid: user.id,
            user_id: user.legacy_id,
            meeko_user_id: user.legacy_id,
            email: user.email,
            name: user.first_name + ' ' + user.last_name,
            support: `https://meeko.app/support/login/${user.email}`,
            is_account_owner: user.is_owner,
            user_hash: user.hmac,
            account_id: user.legacy_account_id,
            created_at: user.created_at,
        });
    }

    function update(nursery, customer, account) {
        // @ts-ignore
        window.Intercom('update', {
            company: {
                uuid: nursery.id,
                id: nursery.legacy_id,
                account_id: account.legacy_id,
                name: nursery.name,
                website: nursery.website,
                phone: nursery.phone,
                second_phone: nursery.second_phone,
                address: nursery.address,
                city: nursery.city,
                state: nursery.state,
                country: nursery.country,
                timezone: nursery.timezone,
                currency: nursery.currency,
                email: nursery.email,
                surface: nursery.surface ? nursery.surface : 0,
                meeko_website: `https://${nursery.websites[0]?.slug}.meeko.site/contact`,
                plan: customer.plan,
                trial_ends_at: customer.trial_ends_at,
                remote_created_at: nursery.created_at,
                updated_at: nursery.updated_at,
                retailer: account.retailer,
                stripe: `https://dashboard.stripe.com/customers/${customer.stripe_id}`,
            },
        });
    }

    return {
        setup,
        update,
    };
}
