import type EventModel from '@/modules/human-resources/models/EventModel';
import {catcher, collect, type Collection, ValidationError} from '@meekohq/lumos';
import {ref} from 'vue';
import EventError from '@/modules/human-resources/utils/calendar/EventError';

export interface EventWithError {
    model: EventModel,
    error: EventError,
}

export default function() {
    const failedEvents = ref<Collection<EventWithError>>(collect());

    function getEventsWithError(rejectedPromises: PromiseRejectedResult[]) {
        // Reset failedEvents collection.
        failedEvents.value = collect<EventWithError>();

        // Get a collection of contextualize object between event model and its error.
        rejectedPromises.forEach(rejectedPromise => {
            catcher()
                .on(ValidationError, error => {
                    const eventError = new EventError();
                    eventError.reset(error);

                    failedEvents.value.push({model: error.model, error: eventError});
                })
                .catch(rejectedPromise.reason as Error);

        });
    }

    return {
        failedEvents,
        getEventsWithError,
    };
}
