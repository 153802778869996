import {collect, MqlForbiddenError, MqlUnprocessableEntityError, ValidationError} from '@meekohq/lumos';
import {
    AmountToAllocateIsGreaterThanSourceRemainingError,
} from '@/modules/cashier/payment/domain/errors/AmountToAllocateIsGreaterThanSourceRemainingError';
import type {
    AmountToAllocateMustBePositiveError,
} from '@/modules/cashier/payment/domain/errors/AmountToAllocateMustBePositiveError';
import type {ErrorsObject} from '@meekohq/lumos/dist/Http/ResponseObject';
import type {Operation} from '@meekohq/lumos/dist/Http/Mql';

export class CreditNoteAllocationErrorInterceptor {
    public static intercept(error: Error): ValidationError | AmountToAllocateIsGreaterThanSourceRemainingError | AmountToAllocateMustBePositiveError | Error {
        if (error instanceof MqlUnprocessableEntityError) {
            const resultContent = CreditNoteAllocationErrorInterceptor.getResultContent(error);

            if (!resultContent) {
                return error;
            }

            return new ValidationError(collect(resultContent.data));
        }

        if (error instanceof MqlForbiddenError) {
            const resultContent = CreditNoteAllocationErrorInterceptor.getResultContent(error);
            if (!resultContent) {
                return error;
            }

            if (resultContent.data.find(error => error.code === AmountToAllocateIsGreaterThanSourceRemainingError.code)) {
                return new AmountToAllocateIsGreaterThanSourceRemainingError();
            }

            return error;
        }

        throw error;
    }

    private static getResultContent(error: Error): ErrorsObject | null | undefined {
        if ('operation' in error ) {
            const operation = error.operation as Operation;

            return operation.result.content as ErrorsObject | null | undefined;
        }

        return undefined;
    }
}
