<template>
    <SimplePageTemplate>
        <template #title>
            <MPageTitle variant="communication">
                {{ __('app:pages.news') }}
            </MPageTitle>
        </template>
        <template #actions>
            <a
                v-if="$can('settings', 'nursery_website') && nursery.websites[0]"
                :href="'https://' + nursery.websites[0].slug + '.meeko.site/login/' + temporaryToken"
                target="_blank"
            >
                <MButton>
                    <template #left-icons>
                        <FontAwesomeIcon icon="fa fa-globe"/>
                    </template>
                    {{ __('news:view_website') }}
                </MButton>
            </a>
            <MSettingsButton
                v-if="$can('read', 'nursery')"
                :to="{name:'settings.communication',params:{nursery:nursery.id}}"
            />
        </template>
        <template #content>
            <div class="card card-3d mt-2">
                <div class="card-body">
                    <div
                        id="myTabContent"
                        class="tab-content"
                    >
                        <div
                            id="news"
                            class="tab-pane fade show active"
                            role="tabpanel"
                        >
                            <module-news
                                :nursery="nursery"
                                :user="user"
                            />
                        </div>
                        <div
                            id="website"
                            class="tab-pane fade"
                            role="tabpanel"
                        >
                            <module-website :nursery="nursery"/>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </SimplePageTemplate>
</template>

<script>
    import News from '@/modules/news/components/News.vue';
    import Website from '@/modules/news/components/Website.vue';
    import useAuth from '@/modules/app/composables/useAuth';
    import SimplePageTemplate from '@/modules/app/components/templates/SimplePageTemplate.vue';

    export default {
        components: {
            SimplePageTemplate,
            'module-news': News,
            'module-website': Website,
        },
        props: ['nursery', 'user'],
        computed: {
            temporaryToken() {
                const {legacyToken} = useAuth();

                return legacyToken.value;
            },
        },
    };
</script>
