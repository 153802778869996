<template>
    <form @submit.prevent="save">
        <MInputGroup
            v-model="familyModel.attributes.name"
            :errors="familyError.getErrors('name')"
            :focus="true"
            :label="__('common:last_name') + '*'"
            :placeholder="__('common:last_name')"
        />
    </form>
</template>

<script lang="ts">
    import FamilyModel from '@/modules/family/models/FamilyModel';
    import type OrganizationModel from '@/modules/organization/models/OrganizationModel';
    import FamilyOrganizationPivot from '@/modules/family/models/FamilyOrganizationPivot';
    import ErrorHandler from '@/modules/legacy/libs/errors/errorHandler';
    import type {PropType} from 'vue';
    import {defineComponent, ref} from 'vue';

    export default defineComponent({
        components: {},
        props: {
            organizationModel: {type: Object as PropType<OrganizationModel>, default: undefined, required: false},
            familyModel: {type: Object as PropType<FamilyModel>, default: () => new FamilyModel(), required: false},
            name: {type: String, default: undefined, required: false},
        },
        setup(props, {emit}) {
            const familyError = ref(new ErrorHandler());

            if (props.name) {
                props.familyModel.attributes.name = props.name;
            }

            function save() {
                emit('loading', true);
                if (props.organizationModel) {
                    props.familyModel.attributes.account_id = props.organizationModel.attributes.account_id;
                }
                props.familyModel.save().then(() => {
                    if (props.familyModel.wasRecentlyCreated) {
                        // Attach to organization
                        const pivot = new FamilyOrganizationPivot();
                        pivot.attributes.account_id = props.familyModel.attributes.account_id;
                        pivot.attributes.family_id = props.familyModel.id;
                        pivot.attributes.organization_id = props.organizationModel.id;
                        pivot.save().then(() => {
                            emit('saved', props.familyModel);
                            emit('loading', false);
                        });
                    } else {
                        emit('saved', props.familyModel);
                        emit('loading', false);
                    }
                }).catch(error => {
                    emit('loading', false);
                    familyError.value.reset(error);
                });
            }

            return {
                save,
                familyError,
            };
        },
    });
</script>
