<template>
    <MModal
        :modal="modal"
        size="5xl"
    >
        <template #header-text>
            {{ __('billing_invoice:relaunch_invoices') }}
            -
            <strong class="tw-capitalize">{{ date.clone().format('MMMM Y') }}</strong>
        </template>

        <select-invoices
            action="sendReminders"
            :date="date"
            :nursery="nursery"
            :selected-invoices.sync="selectedInvoices"
        />

        <template #footer-end="{hide}">
            <MButton
                :label="__('common:actions.cancel')"
                @click="hide"
            />
            <MButton
                :loading="loading"
                variant="primary"
                @click="send"
            >
                {{ __('billing_invoice:send_relaunch') }}
                -
                <strong class="tw-capitalize">{{ date.clone().format('MMMM Y') }}</strong>
            </MButton>
        </template>
    </MModal>
</template>

<script>
    import route from '@/modules/legacy/libs/ziggy';
    import useApi from '@/modules/app/composables/useApi';
    import moment from 'moment';
    import _map from 'lodash-es/map';
    import _head from 'lodash-es/head';
    import _forEach from 'lodash-es/forEach';
    import SelectInvoices from './SelectInvoices.vue';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';
    import __ from '@/modules/app/utils/i18n-facade';
    import OrganizationModel from '@/modules/organization/models/OrganizationModel';

    export default {
        components: {
            SelectInvoices,
        },
        props: {
            nursery: {
                type: OrganizationModel,
                required: true,
            },
            date: {
                type: moment,
                required: true,
            },
            modal: {
                type: Object,
                required: true,
            },
        },
        data() {
            return {
                selectedInvoices: [],
                loading: false,
            };
        },
        methods: {
            send() {
                if (this.loading) {
                    return;
                }

                this.loading = true;

                useApi().legacy.post(
                    route('nurseries.invoices.sendreminderbymail', {
                        nurseries: this.nursery.id,
                    }),
                    {
                        invoices: _map(this.selectedInvoices, 'id'),
                    },
                )
                    .then(response => {
                        response.data.forEach(invoice => {
                            invoice.dunning_dates.push(moment().format());
                        });
                        this.$emit('updated', response.data);
                        useNotification().success(__('billing_invoice:invoices_sended_again_successfully', {count: this.selectedInvoices.length}));
                        this.loading = false;
                    })
                    .catch(error => {
                        this.loading = false;
                        if (
                            error &&
                            error.response &&
                            error.response.status === 422
                        ) {
                            _forEach(error.response.data.errors, value => {
                                useNotification().error(_head(value));
                            });
                        } else {
                            useNotification().error(error);
                        }
                    });
            },
        },
    };
</script>
