<template>
    <MModal
        :modal="modal"
        size="5xl"
    >
        <template #header-text>
            {{ __('billing_invoice:send_invoices') }}
            -
            <strong class="tw-capitalize">{{ date.clone().format('MMMM Y') }}</strong>
        </template>

        <SelectInvoices
            action="send"
            :date="date"
            :nursery="nursery"
            :selected-invoices.sync="selectedInvoices"
        />

        <div class="mt-3 px-3">
            <hr class="hr">

            <CForm class="xl:tw-w-1/3 lg:tw-w-1/2 tw-w-full">
                <CFormGroup>
                    <CLabel>{{ __('common:actions.send_selection_to_colon') }}</CLabel>
                    <CButtonGroup>
                        <MButton
                            :label="__('common:each_parent')"
                            :variant="sendToEmail === false ? 'primary' : 'light'"
                            @click="sendToEmail = false"
                        />
                        <MButton
                            :label="__('common:specific_email')"
                            :variant="sendToEmail === true ? 'primary' : 'light'"
                            @click="sendToEmail = true"
                        />
                    </CButtonGroup>
                </CFormGroup>
                <CFormGroup v-if="sendToEmail">
                    <CInputGroup class="tw-w-full">
                        <CInputAddon>
                            <FontAwesomeIcon
                                class="tw-text-primary-500"
                                :fixed-width="true"
                                icon="fas fa-envelope"
                            />
                        </CInputAddon>
                        <MInput
                            v-model="email"
                            :placeholder="__('billing_invoice:receiving_email')"
                        />
                    </CInputGroup>
                </CFormGroup>
            </CForm>
        </div>

        <template v-if="sendToEmail">
            <div class="mt-4 px-3">
                <h6 class="h6">
                    {{ __('billing_invoice:what_is_the_desired_format_ask') }}
                </h6>
                <div class="row">
                    <div
                        v-for="(format, i) in formats"
                        :key="'format' + i"
                        class="col-6 col-lg-3 mt-2"
                    >
                        <div
                            class="text-center border rounded pointer pb-2 pt-3"
                            :class="{'selected tw-text-white bg-info': hasFormat(format)}"
                            @click="selectFormat(format)"
                        >
                            <div>
                                <i
                                    class="fas fa-2x"
                                    :class="format.icon"
                                />
                            </div>
                            <div class="small mt-2">
                                {{ format.title }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>

        <template #footer-end="{hide}">
            <MButton
                :label="__('common:actions.cancel')"
                @click="hide"
            />
            <MButton
                :loading="loading"
                variant="primary"
                @click="send"
            >
                {{ __('common:actions.send') }}
                -
                <strong class="tw-capitalize">{{ date.clone().format('MMMM Y') }}</strong>
            </MButton>
        </template>
    </MModal>
</template>

<script>
    import _map from 'lodash-es/map';
    import _head from 'lodash-es/head';
    import _forEach from 'lodash-es/forEach';
    import route from '@/modules/legacy/libs/ziggy';
    import useApi from '@/modules/app/composables/useApi';
    import SelectInvoices from './SelectInvoices.vue';
    import __ from '@/modules/app/utils/i18n-facade';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';
    import OrganizationModel from '@/modules/organization/models/OrganizationModel';
    import moment from 'moment';

    export default {
        components: {
            SelectInvoices,
        },
        props: {
            nursery: {
                type: OrganizationModel,
                required: true,
            },
            date: {
                type: moment,
                required: true,
            },
            modal: {
                type: Object,
                required: true,
            },
        },
        data() {
            return {
                selectedInvoices: [],
                loading: false,
                sendToEmail: false,
                email: this.nursery.email,
                formats: [{
                    name: 'pdf',
                    title: __('billing_invoice:only_one_pdf'),
                    icon: 'fa-file-pdf',
                }, {
                    name: 'pdfs',
                    title: __('billing_invoice:multiple_pdf'),
                    icon: 'fa-copy',
                }, {
                    name: 'zip',
                    title: __('billing_invoice:compressed_folder'),
                    icon: 'fa-file-archive',
                }, {
                    name: 'xlsx',
                    title: __('billing_invoice:excel_file'),
                    icon: 'fa-file-excel',
                }],
                selectedFormats: ['pdf'],
            };
        },
        methods: {
            send() {
                if (this.loading) {
                    return;
                }

                this.loading = true;

                useApi().legacy.post(route('nurseries.invoices.sendbymail', {
                    nurseries: this.nursery.id,
                }), {
                    invoices: _map(this.selectedInvoices, 'id'),
                    email: this.sendToEmail ? this.email : null,
                    formats: this.selectedFormats,
                }).then(response => {
                    this.$emit('updated', response.data);
                    useNotification().success(__('billing_invoice:invoices_sended_successfully', {count: this.selectedInvoices.length}));
                    this.loading = false;
                }).catch(error => {
                    this.loading = false;
                    if (error && error.response && error.response.status === 422) {
                        _forEach(error.response.data.errors, value => {
                            useNotification().error(_head(value));
                        });
                    } else {
                        useNotification().error(error);
                    }
                });
            },

            selectFormat(format) {
                this.selectedFormats = [];
                this.selectedFormats.push(format.name);
            },

            hasFormat(format) {
                return this.selectedFormats.find(item => item === format.name);
            },
        },
    };
</script>
