<template>
    <transition
        mode="out-in"
        name="fade"
    >
        <div>
            <div class="row tw-mb-6">
                <div class="col-12 col-lg-6 mb-3 mb-lg-0">
                    <MHeading class="tw-mb-2">
                        <img
                            alt="Balance"
                            class="mr-2"
                            :src="ScaleIcon"
                            width="30px"
                        >{{ __('common:weight') }}
                    </MHeading>
                    <KidWeight :kid="kid"/>
                </div>
                <div class="col-12 col-md-6 col-lg-3 mb-3 mb-md-0">
                    <MHeading class="tw-mb-2">
                        <i
                            aria-hidden="true"
                            class="fad fa-head-side-cough text-success mr-2"
                        />{{ __('family_kid:allergy_other') }}
                    </MHeading>
                    <div
                        v-if="$can('create', 'kids_health')"
                        class="form-group"
                    >
                        <div class="tw-flex tw-flex-row tw-gap-2 tw-items-center">
                            <select
                                v-model="selectedAllergy"
                                class="form-control form-control-sm"
                            >
                                <optgroup
                                    v-for="(opt, i) in allergiesTypes"
                                    :key="'allergy ' + i"
                                    :label="opt.name"
                                >
                                    <option
                                        v-for="(allergy, i) in getAllergiesByType(opt.key)"
                                        :key="'allergyOpt ' + i"
                                        :value="allergy.id"
                                    >
                                        {{ allergy.name }}
                                    </option>
                                </optgroup>
                            </select>
                            <MButton
                                class="tw-shrink-0"
                                icon-plus
                                :label="__('common:actions.add')"
                                variant="success"
                                @click="addAllergy(selectedAllergy)"
                            />
                        </div>
                    </div>
                    <ul class="list-group mb-3">
                        <li
                            v-if="kid.allergies.length === 0"
                            class="list-group-item p-2 pl-3 pr-3 text-muted"
                        >
                            <em>{{ __('family_kid:empty_allergy') }}</em>
                        </li>
                        <li
                            v-for="(allergy, i) in _sortBy(kid.allergies, 'name')"
                            :key="'kidAllergy ' + i"
                            class="list-group-item list-group-item-action d-flex justify-content-between align-items-center p-2 pl-3 pr-3"
                        >
                            {{ allergy.name }}
                            <div v-if="$can('delete', 'kids_health')">
                                <button
                                    class="btn btn-link text-danger zoom p-0"
                                    @click="deleteAllergy(allergy.id)"
                                >
                                    <i
                                        aria-hidden="true"
                                        class="fa fa-times-circle"
                                    />
                                </button>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="col-12 col-md-6 col-lg-3 mb-3 mb-md-0">
                    <MHeading class="tw-mb-2">
                        <i
                            aria-hidden="true"
                            class="fad fa-face-thermometer text-info mr-2"
                        />{{ __('family_kid:childhood_disease') }}
                    </MHeading>
                    <div
                        v-if="$can('create', 'kids_health')"
                        class="form-group"
                    >
                        <div class="tw-flex tw-flex-row tw-gap-2 tw-items-center">
                            <select
                                v-model="selectedDisease"
                                class="form-control form-control-sm text-capitalize"
                            >
                                <option
                                    v-for="(disease, i) in _sortBy(diseases, 'name')"
                                    :key="'disease ' + i"
                                    :value="disease.id"
                                >
                                    {{ disease.name }}
                                </option>
                            </select>
                            <MButton
                                class="tw-shrink-0"
                                icon-plus
                                :label="__('common:actions.add')"
                                variant="success"
                                @click="addDisease(selectedDisease)"
                            />
                        </div>
                    </div>
                    <ul class="list-group mb-3">
                        <li
                            v-if="kid.diseases.length === 0"
                            class="list-group-item p-2 pl-3 pr-3 text-muted"
                        >
                            <em>{{ __('family_kid:no_childhood_disease') }}</em>
                        </li>
                        <li
                            v-for="(disease, i) in _sortBy(kid.diseases, 'name')"
                            :key="'kidDisease ' + i"
                            class="list-group-item list-group-item-action d-flex justify-content-between align-items-center text-capitalize p-2 pl-3 pr-3"
                        >
                            {{ disease.name }}
                            <div v-if="$can('delete', 'kids_health')">
                                <button
                                    class="btn btn-link text-danger zoom p-0"
                                    @click="deleteDisease(disease.id)"
                                >
                                    <i
                                        aria-hidden="true"
                                        class="fa fa-times-circle"
                                    />
                                </button>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>

            <TreatmentsList
                class="tw-mb-8"
                :kid-id="kid.id"
            />

            <div class="row">
                <div class="col-12 col-lg-6">
                    <MHeading class="tw-mb-2">
                        <i
                            aria-hidden="true"
                            class="fad fa-hand-holding-heart text-secondary mr-2"
                        />{{ __('family_kid:individualized_welcome_project') }}
                    </MHeading>
                    <textarea
                        v-model="kid.chronicle_treatment"
                        class="form-control"
                        :disabled="!$can('update', 'kids_health')"
                        :placeholder="__('family_kid:chronicpathology_specificlayout_etc')"
                        rows="4"
                    />
                    <div
                        v-if="$can('update', 'kids_health')"
                        class="form-row"
                    >
                        <div class="col-12 text-right">
                            <button
                                class="btn btn-sm btn-success mt-2"
                                @click="saveChronicleTreatment()"
                            >
                                {{ __('common:actions.save') }}
                            </button>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-lg-6">
                    <MHeading class="tw-mb-2">
                        <i
                            aria-hidden="true"
                            class="fa fa-note mr-2"
                        />{{ __('common:note_one') }}
                    </MHeading>
                    <textarea
                        v-model="kid.health_note"
                        class="form-control"
                        :disabled="!$can('update', 'kids_health')"
                        :placeholder="__('family_kid:medicalhistory_additionalnotes_etc')"
                        rows="4"
                    />
                    <div
                        v-if="$can('update', 'kids_health')"
                        class="form-row"
                    >
                        <div class="col-12 text-right">
                            <button
                                class="btn btn-sm btn-success mt-2"
                                @click="saveHealthNote()"
                            >
                                {{ __('common:actions.save') }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
    import swal from 'sweetalert2/dist/sweetalert2.js';
    import route from '@/modules/legacy/libs/ziggy';
    import useApi from '@/modules/app/composables/useApi';
    import moment from 'moment';
    import _sortBy from 'lodash-es/sortBy';
    import _head from 'lodash-es/head';
    import _forEach from 'lodash-es/forEach';
    import TreatmentsList from '@/modules/health/components/TreatmentsList.vue';
    import __ from '@/modules/app/utils/i18n-facade';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';
    import KidWeight from '@/modules/family/components/kid/Kid/KidWeight.vue';
    import ScaleIcon from '@/assets/images/tablet/balance.svg';

    export default {
        components: {
            TreatmentsList,
            KidWeight,
        },
        props: {
            kid: {
                type: Object,
                required: true,
            },
            diseases: {
                type: Array,
                required: true,
            },
            allergies: {
                type: Array,
                required: true,
            },
        },
        data: () => ({
            allergiesTypes: [{
                key: 'venom',
                name: __('family_kid:venom'),
            }, {
                key: 'respiratory',
                name: __('family_kid:respiratory'),
            }, {
                key: 'drug',
                name: __('family_kid:drug'),
            }, {
                key: 'food',
                name: __('family_kid:food'),
            }],
            selectedAllergy: null,
            selectedDisease: null,
            ScaleIcon,
        }),
        computed: {
            _sortBy() {
                return _sortBy;
            },
            moment() {
                return moment;
            },
            name() {
                return this.kid.first_name + ' ' + this.kid.last_name;
            },
        },
        mounted() {
            this.selectedAllergy = _head(_sortBy(this.allergies, 'name')).id;
            this.selectedDisease = _head(_sortBy(this.diseases, 'name')).id;
        },
        methods: {
            addAllergy(id) {
                useApi().legacy.post(route('kids.allergies.store', {
                    kid: this.kid.id,
                }), {allergy_id: id}).then(response => {
                    if (!this.kid.allergies.find(item => item.id === response.data.id)) {
                        this.kid.allergies.push(response.data);
                    }
                }).catch(error => {
                    if (error && error.response && error.response.status === 422) {
                        _forEach(error.response.data.errors, function(value) {
                            useNotification().error(_head(value));
                        });
                    } else {
                        useNotification().error(error);
                    }
                });
            },
            deleteAllergy(id) {
                swal({
                    title: __('family_kid:confirm_the_withdrawal_of_this_allergy'),
                    confirmButtonText: __('common:actions.confirm'),
                    cancelButtonText: __('common:actions.cancel'),
                }).then(result => {
                    if (result.value) {
                        useApi().legacy.delete(route('kids.allergies.destroy', {
                            kid: this.kid.id,
                            allergy: id,
                        })).then(() => {
                            this.kid.allergies = this.kid.allergies.filter(function(item) {
                                return item.id !== id;
                            });
                        }).catch(error => {
                            useNotification().error(error);
                        });
                    }
                });
            },
            getAllergiesByType(type) {
                const arr = this.allergies.filter(item => item.type === type);

                return _sortBy(arr, 'name');
            },
            addDisease(id) {
                useApi().legacy.post(route('kids.diseases.store', {
                    kid: this.kid.id,
                }), {disease_id: id}).then(response => {
                    if (!this.kid.diseases.find(item => item.id === response.data.id)) {
                        this.kid.diseases.push(response.data);
                    }
                }).catch(error => {
                    if (error && error.response && error.response.status === 422) {
                        _forEach(error.response.data.errors, function(value) {
                            useNotification().error(_head(value));
                        });
                    } else {
                        useNotification().error(error);
                    }
                });
            },
            deleteDisease(id) {
                swal({
                    title: __('family_kid:confirming_the_withdrawal_of_this_childhood_disease'),
                    confirmButtonText: __('common:actions.confirm'),
                    cancelButtonText: __('common:actions.cancel'),
                }).then(result => {
                    if (result.value) {
                        useApi().legacy.delete(route('kids.diseases.destroy', {
                            kid: this.kid.id,
                            disease: id,
                        })).then(() => {
                            this.kid.diseases = this.kid.diseases.filter(function(item) {
                                return item.id !== id;
                            });
                        }).catch(error => {
                            useNotification().error(error);
                        });
                    }
                });
            },
            saveChronicleTreatment() {
                useApi().legacy.put(route('kids.update', {
                    kid: this.kid.id,
                }), {chronicle_treatment: this.kid.chronicle_treatment}).then(() => {
                    useNotification().success(__('family_kid:individualized_welcome_project_updated_successfully'));
                }).catch(error => {
                    if (error && error.response && error.response.status === 422) {
                        _forEach(error.response.data.errors, function(value) {
                            useNotification().error(_head(value));
                        });
                    } else {
                        useNotification().error(error);
                    }
                });
            },
            saveHealthNote() {
                useApi().legacy.put(route('kids.update', {
                    kid: this.kid.id,
                }), {health_note: this.kid.health_note}).then(() => {
                    useNotification().success(__('family_kid:childhood_disease.note_updated_successfully'));
                }).catch(error => {
                    if (error && error.response && error.response.status === 422) {
                        _forEach(error.response.data.errors, function(value) {
                            useNotification().error(_head(value));
                        });
                    } else {
                        useNotification().error(error);
                    }
                });
            },
        },
    };
</script>
