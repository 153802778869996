<script setup lang="ts">
    import type {PropType} from 'vue';
    import {computed} from 'vue';
    import {Epoch} from '@meekohq/lumos';
    import IconTeddybear from '@/modules/coherence-ui/components/Tools/Avatar/IconTeddybear.vue';
    import IconUser from '@/modules/coherence-ui/components/Tools/Avatar/IconUser.vue';
    import IconStaff from '@/modules/coherence-ui/components/Tools/Avatar/IconStaff.vue';

    const props = defineProps({
        url: {
            type: String,
            default: undefined,
            required: false,
        },
        name: {
            type: String,
            default: undefined,
            required: false,
        },
        fallbackInitials: {
            type: Boolean,
            default: false,
            required: false,
        },
        fallbackTeddybear: {
            type: Boolean,
            default: false,
            required: false,
        },
        fallbackStaff: {
            type: Boolean,
            default: false,
            required: false,
        },
        fallbackUser: {
            type: Boolean,
            default: false,
            required: false,
        },
        gender: {
            type: String,
            default: undefined,
            required: false,
        },
        birthdate: {
            type: String as PropType<string>,
            default: undefined,
            validator: (value: string) => {
                return Epoch.fromISOString(value).isValid;
            },
        },
    });

    const birthdayInDays = computed(() => {
        if (!props.birthdate) {
            return -1;
        }

        return Epoch.now().startOfDay().differenceInDays(Epoch.fromISOString(props.birthdate).setYear(Epoch.now().year).startOfDay());
    });

    const initials = computed(() => {
        if (!props.name) {
            return '';
        }

        const names = props.name.split(' ');

        if (names.length === 1) {
            return names[0].substr(0, 2).toUpperCase();
        }

        return names[0].substr(0, 1).toUpperCase() + names[names.length - 1].substr(0, 1).toUpperCase();
    });
</script>

<template>
    <div class="MAvatar">
        <div
            v-if="birthdayInDays >= 0 && birthdayInDays <= 10"
            ref="balloon"
            class="MAvatar__birthday"
            :class="{
                'MAvatar__birthday--now': birthdayInDays === 0
            }"
        >
            <div
                v-if="birthdayInDays > 0"
                class="MAvatar__birthday-text"
            >
                {{ __('common:days_before_birthday', {days: birthdayInDays}) }}
            </div>
            <img
                v-if="birthdayInDays > 0"
                alt="balloon"
                src="@/assets/images/balloon.svg"
            >
            <img
                v-else
                alt="balloon"
                src="@/assets/images/balloon-stars.svg"
            >
        </div>
        <div class="MAvatar__content">
            <img
                v-if="url"
                class="tw-object-cover"
                :src="url"
            >
            <div
                v-else-if="fallbackInitials"
                class="MAvatar__initial"
                :class="{
                    'MAvatar__initial--male': gender === 'male',
                    'MAvatar__initial--female': gender === 'female',
                }"
            >
                {{ initials }}
            </div>
            <IconTeddybear
                v-else-if="fallbackTeddybear"
                :variant="gender === 'male' ? 'boy' : gender === 'female' ? 'girl' : 'default'"
            />
            <IconStaff
                v-else-if="fallbackStaff"
                :variant="gender === 'male' ? 'boy' : gender === 'female' ? 'girl' : 'default'"
            />
            <IconUser
                v-else-if="fallbackUser"
                :variant="gender === 'male' ? 'boy' : gender === 'female' ? 'girl' : 'default'"
            />
            <slot v-else>
                <img
                    class="tw-object-cover tw-w-full tw-h-full"
                    src="@/assets/images/no-image.png"
                >
            </slot>
        </div>
    </div>
</template>

<style scoped lang="scss">
    .MAvatar {
        @apply tw-relative tw-aspect-square tw-shrink-0;
        container-type: inline-size;
        container-name: avatar;
    }

    .MAvatar__content {
        @apply tw-w-full tw-h-full;
        @apply tw-rounded-[25%];
        @apply tw-overflow-hidden;

        @container avatar (min-width: 0px) {
            font-size: 40cqw;
        }
    }

    .MAvatar__initial {
        @apply tw-flex tw-justify-center tw-font-display tw-text-white tw-font-semibold tw-items-center tw-w-full tw-h-full;
        @apply tw-bg-gray-200 tw-text-gray-400;
        container-type: inline-size;
    }

    .MAvatar__initial--male {
        color: #61B9DB;
        background-color: #DAEDF5;
    }

    .MAvatar__initial--female {
        color: #f1856c;
        background-color: #F5DBD8;
    }

    .MAvatar__birthday {
        @apply tw-flex tw-flex-col tw-items-center;
        @apply tw-absolute tw-z-10 tw-drop-shadow-md;

        img {
            @container avatar (min-width: 0px) {
                width: 50cqw;
            }
        }

        bottom: 60%;
        left: -10%;
        animation: fly 5s infinite;
        animation-timing-function: cubic-bezier(0.64, 0.57, 0.43, 1.02);
    }

    .MAvatar__birthday--now {
        img {
            @container avatar (min-width: 0px) {
                width: 75cqw;
            }
        }

        bottom: 65%;
        left: -22%;
    }

    .MAvatar__birthday-text {
        @apply tw-font-display tw-text-black tw-leading-none;
        text-shadow: 0 1px 1px #0000004a;

        @container avatar (min-width: 0px) {
            font-size: 25cqw;
        }
    }

    @keyframes fly {
        0%, 100% {
            transform: scale(1.0) translate3d(0px, 0px, 0) rotate(-4deg);
        }

        50% {
            transform: scale(1.1) translate3d(0px, -5px, 0) rotate(4deg);
        }
    }
</style>
