<template>
    <div class="TransactionListItemInvoicePopoverInformations">
        <div class="TransactionListItemInvoicePopoverInformations__item">
            <FontAwesomeIcon
                class="tw-w-4 tw-text-orange-500"
                icon="fad fa-file-user"
            />
            <div class="tw-font-semibold">
                {{ customer.attributes.name }}
            </div>
        </div>
        <div class="TransactionListItemInvoicePopoverInformations__item">
            <FontAwesomeIcon
                class="tw-w-4 tw-text-primary-500"
                icon="fad fa-calendar"
            />
            <div class="tw-font-semibold">
                {{ __('billing_transaction:the_colon') }}
                {{ Epoch.fromISOString(allocation.extra.invoice.attributes.date).toLocaleString(Epoch.presets.DATE_SHORT) }}
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import type {PropType} from 'vue';
    import {defineComponent} from 'vue';
    import {Epoch} from '@meekohq/lumos';
    import type AllocationModel from '@/modules/cashier/models/AllocationModel';
    import type CustomerModel from '@/modules/cashier/models/CustomerModel';

    export default defineComponent({
        props: {
            allocation: {
                type: Object as PropType<AllocationModel>,
                required: true,
            },
            customer: {
                type: Object as PropType<CustomerModel>,
                required: true,
            },
        },
        setup() {
            return {
                Epoch,
            };
        },
    });
</script>

<style lang="scss" scoped>
    .TransactionListItemInvoicePopoverInformations {
        @apply tw-flex tw-flex-col tw-gap-1;
    }

    .TransactionListItemInvoicePopoverInformations__item {
        @apply tw-flex tw-flex-row tw-items-baseline tw-gap-1;
    }
</style>
