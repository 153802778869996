import type {Ref} from 'vue';
import {computed, ref} from 'vue';
import type {LengthAwarePaginator} from '@meekohq/lumos';
import {collect, type Collection} from '@meekohq/lumos';

export type MagicMassSelectType = {
    isEnabled: Ref<boolean>;
    isSelected: (id: string) => boolean;
    queryMode: Ref<boolean>;
    reset: () => void;
    select: (id: string) => void;
    selectAll: () => void;
    selectCurrentPage: () => void;
    selectedIds: Ref<Collection<string>>;
    selectedItemsCount: Ref<number>;
    toggle: (id: string) => void;
    totalItemsCount: Ref<number>;
    itemsOnPage: Ref<number>;
    unselect: (id: string) => void;
    unselectAll: () => void;
};

export default function useMagicMassSelect(paginator: Ref<LengthAwarePaginator<unknown> | undefined>): MagicMassSelectType {
    const queryMode = ref(false);
    const selectedIds = ref<Collection<string>>(collect());

    const totalItemsCount = computed<number>(() => paginator.value?.total() ?? 0);
    const selectedItemsCount = computed<number>(() => {
        if (queryMode.value) {
            return totalItemsCount.value;
        }

        return selectedIds.value.count();
    });

    const isEnabled = computed<boolean>(() => {
        return (queryMode.value || selectedIds.value.count() > 0) && (paginator.value?.total() ?? 0) > 0;
    });

    const itemsOnPage = computed<number>(() => {
        return paginator.value?.items().count() ?? 0;
    });

    /**
     * Check if an item is selected by ID
     * @param {string} id
     * @returns {boolean}
     */
    function isSelected(id: string): boolean {
        return queryMode.value ? true : selectedIds.value.toArray().includes(id);
    }

    /**
     * Select an item by ID
     * @param {string} id
     * @returns {void}
     */
    function select(id: string): void {
        queryMode.value = false;
        selectedIds.value = selectedIds.value.push(id);
    }

    /**
     * Select all items on the current page
     * @returns {void}
     */
    function selectCurrentPage(): void {
        queryMode.value = false;
        selectedIds.value = paginator.value?.items().pluck('id') ?? collect();
    }

    /**
     * Select all items
     * @returns {void}
     */
    function selectAll(): void {
        queryMode.value = true;
        selectedIds.value = collect();
    }

    /**
     * Unselect an item by ID
     * @param {string} id
     * @returns {void}
     */
    function unselect(id: string): void {
        // If we're in query mode, we're selecting all items on the current page
        if (queryMode.value) {
            selectCurrentPage();
        }

        // Then we remove the selected item from the list
        selectedIds.value = selectedIds.value.reject(selectedId => selectedId === id);
    }

    /**
     * Unselect all items
     * @returns {void}
     */
    function unselectAll(): void {
        queryMode.value = false;
        selectedIds.value = collect();
    }

    /**
     * Toggle an item by ID
     * @param id
     */
    function toggle(id: string) {
        // If we're in query mode, we're selecting all items on the current page
        if (queryMode.value) {
            selectCurrentPage();
        }

        if (selectedIds.value.toArray().includes(id)) {
            unselect(id);
        } else {
            select(id);
        }
    }

    /**
     * Remove all ids from list
     */
    function reset() {
        queryMode.value = false;
        selectedIds.value = collect();
    }

    return {
        isSelected,
        isEnabled,
        queryMode,
        reset,
        select,
        selectAll,
        selectCurrentPage,
        selectedIds,
        selectedItemsCount,
        toggle,
        totalItemsCount,
        unselect,
        unselectAll,
        itemsOnPage,
    };
}
