<template>
    <div>
        <CustomerHero
            class="tw-col-span-2"
            :customer="customer"
            :family-model-collection="familyModels"
            :legacy-nursery="customerNursery"
            :legacy-user="legacyUser"
            :loading="loading"
            @updated="fetchCustomer"
        />

        <CustomerTabMenu class="tw-mt-10"/>

        <router-view
            v-if="customer"
            class="tw-mt-2"
            :customer="customer"
            :customer-model="customer"
            :nursery="customerNursery"
            :user="legacyUser"
            @refreshStats="refreshCustomer"
        />
    </div>
</template>

<script lang="ts">
    import CustomerModel from '@/modules/cashier/models/CustomerModel';
    import FamilyModel from '@/modules/family/models/FamilyModel';
    import type OrganizationModel from '@/modules/organization/models/OrganizationModel';
    import TenantModel from '@/modules/cashier/models/TenantModel';
    import CustomerTabMenu from '@/modules/cashier/components/core/customer/molecules/CustomerTabMenu.vue';
    import CustomerHero from '@/modules/cashier/components/core/customer/CustomerHero/organisms/CustomerHero.vue';
    import useApi from '@/modules/app/composables/useApi';
    import useAuth from '@/modules/app/composables/useAuth';
    import {defineComponent, ref, watch} from 'vue';
    import useManager from '@/modules/app/composables/useManager';
    import {useRoute} from 'vue-router/composables';

    export default defineComponent({
        components: {CustomerHero, CustomerTabMenu},
        props: {},
        setup() {
            const loading = ref(false);
            const customer = ref<CustomerModel>();
            const {legacyUser} = useAuth();
            const {legacyNursery} = useManager();
            const customerNursery = ref(legacyNursery.value);
            const familyModels = ref<FamilyModel[]>([]);
            const route = useRoute();

            watch(() => route?.params.customer, () => {
                fetchCustomer();
            });

            fetchCustomer();

            function refreshCustomer() {
                customer.value?.fresh();
            }

            function fetchCustomer() {
                loading.value = true;
                const customerId = route?.params.customer;

                CustomerModel.query()
                    .where('id', customerId)
                    .with(new CustomerModel().tags())
                    .with(new CustomerModel().families(), query => {
                        query.with(new FamilyModel().kids());
                        query.with(new FamilyModel().members());
                        query.with(new FamilyModel().customers());
                    })
                    .with(new CustomerModel().tenant(), query => {
                        query.with(new TenantModel().organizations());
                    }).first().then(response => {
                        loading.value = false;
                        customer.value = response;
                        familyModels.value = customer.value?.families().value().all();

                        findCustomerOrganization(customer.value);
                    });
            }

            function findCustomerOrganization(customerModel: CustomerModel) {
                const tenant = customerModel.tenant().value();
                const organizations = tenant.organizations().value().all();
                if (organizations) {
                    let organizationModel: OrganizationModel | null = null;
                    if (organizations.length > 1) {
                        organizationModel = organizations.find(item => item.getKey() === legacyNursery.value.id) as OrganizationModel;
                    } else {
                        organizationModel = organizations[0];
                    }

                    if (organizationModel) {
                        useApi().legacy.get('v1/nurseries/' + organizationModel.getKey()).then(response => {
                            customerNursery.value = response.data;
                        });
                    }
                }
            }

            return {
                loading,
                legacyUser,
                legacyNursery,
                customer,
                customerNursery,
                familyModels,
                fetchCustomer,
                refreshCustomer,
            };
        },
    });
</script>

