<template>
    <CModal
        :header-title="__('family_family:merge_family')"
        :hide-header-fullscreen="true"
        :modal="modal"
        size="3xl"
    >
        <div class="tw-flex">
            <div class="tw-w-5/12">
                <div
                    class="tw-mb-2"
                    v-html="__('family_family:family_to_be_deleted_colon')"
                />
                <div
                    v-if="familyModelToDelete"
                    class="tw-flex tw-flex-wrap tw-items-center tw-h-20 tw-bg-gray-50 tw-border tw-border-gray-300 tw-rounded tw-p-3"
                >
                    <div class="tw-text-2xl tw-w-full tw-truncate">
                        {{ familyModelToDelete.attributes.name }}
                    </div>
                </div>
            </div>
            <div class="tw-w-2/12 tw-flex tw-flex-wrap tw-justify-center tw-mt-5">
                <i
                    aria-hidden="true"
                    class="fad fa-angle-double-right tw-text-gray-600 tw-text-5xl tw-self-center"
                />
                <a
                    v-if="familyModelToKeep"
                    class="tw-block tw-self-end tw-cursor-pointer tw-text-sm !tw-text-blue-500 tw-text-center tw-w-full"
                    @click="switchFamilies"
                >
                    <i
                        aria-hidden="true"
                        class="fa fa-retweet tw-mr-1"
                    />{{ __('common:actions.reverse') }}
                </a>
            </div>
            <div class="tw-w-5/12">
                <div
                    class="tw-mb-2"
                    v-html="__('family_family:family_to_be_retained_colon')"
                />
                <div class="tw-flex tw-flex-wrap tw-items-center tw-h-20 tw-border tw-border-gray-300 tw-rounded tw-text-orange-600 tw-p-3">
                    <SelectFamily
                        v-model="familyModelToKeep"
                        :extend-builder="familyBuilder"
                        :organization-model="activeOrganization"
                    />
                </div>
            </div>
        </div>

        <hr
            v-if="customersModelToDelete.length"
            class="hr tw-mt-6 tw-mb-3"
        >

        <div
            v-if="!shouldNotMergeCustomers && customersModelToDelete.length && customersModelToKeep.length > 1"
            class="tw-flex"
        >
            <div class="tw-w-5/12">
                <div
                    class="tw-mb-2"
                    v-html="__('family_family:customer_accounts_to_be_merged_colon')"
                />
                <div class="tw-flex tw-flex-wrap tw-items-center tw-min-h-20 tw-bg-gray-50 tw-border tw-border-gray-300 tw-rounded tw-p-3">
                    <div
                        v-for="(customerModelToDelete, i) in customersModelToDelete"
                        :key="'customerDelete' + i"
                        class="tw-w-full"
                        :class="{'tw-mt-2 tw-pt-2 tw-border-t tw-border-gray-200': i > 0}"
                    >
                        <div class="tw-text-lg tw-w-full">
                            {{ customerModelToDelete.attributes.name }}
                        </div>
                        <div
                            v-if="customerModelToDelete.attributes.account_number"
                            class="tw-text-gray-600 tw-text-sm"
                        >
                            {{ __('family_family:number_short_with_number', {number: customerModelToDelete.attributes.account_number}) }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="tw-w-2/12 tw-flex tw-flex-wrap tw-justify-center tw-mt-5">
                <i
                    aria-hidden="true"
                    class="fad fa-angle-double-right tw-text-gray-600 tw-text-5xl tw-self-center"
                />
            </div>
            <div class="tw-w-5/12">
                <div
                    class="tw-mb-2"
                    v-html="__('family_family:customer_accountto_retrieve_data_colon')"
                />
                <div class="tw-flex tw-flex-wrap tw-items-center tw-h-20 tw-border tw-border-gray-300 tw-rounded tw-text-orange-600 tw-p-3">
                    <SelectCustomer
                        v-model="customerModelToKeep"
                        :create-option-enabled="false"
                        :extend-builder="customerBuilder"
                        :tenant-model="activeTenant"
                        :with-legal="false"
                    />
                </div>
            </div>
        </div>

        <CLabel class="tw-mt-2">
            <CCheckbox v-model="shouldNotMergeCustomers">
                {{ __('family_family:do_not_merge_billing_accounts_checkbox') }}
            </CCheckbox>
            <CHelpTooltip
                class="tw-text-lg tw-ml-1"
                dropdown-class="tw-max-w-80"
            >
                {{ __('family_family:accounts_receivable_will_still_be_sent_to_the_family_to_be_kept') }}
            </CHelpTooltip>
        </CLabel>

        <div class="tw-bg-gray-50 tw-rounded tw-p-2 tw-text-sm tw-mt-8">
            <div>{{ __('family_family:the_following_data_will_be_sent_to_the_retained_family_colon') }}</div>
            <ul
                class="tw-mt-2 tw-mb-0"
                v-html="__('family_family:children_records_parents_records_associated_customer_accounts')"
            />
        </div>

        <div
            class="tw-mt-3"
            v-html="__('common:irreversible_action_warning')"
        />

        <template #footer>
            <CButton
                class="tw-ml-auto"
                :loading="isLoading"
                @click="modal.hide"
            >
                {{ __('common:actions.cancel') }}
            </CButton>
            <CButton
                class="tw-ml-2"
                :disabled="!familyModelToKeep"
                :loading="isLoading"
                variant="primary"
                @click="mergeFamilies()"
            >
                {{ __('common:actions.merge') }}
            </CButton>
        </template>
    </CModal>
</template>

<script setup lang="ts">
    import {computed, type PropType, ref, watch} from 'vue';
    import FamilyModel from '@/modules/family/models/FamilyModel';
    import CustomerModel from '@/modules/cashier/models/CustomerModel';
    import useManager from '@/modules/app/composables/useManager';
    import useApi from '@/modules/app/composables/useApi';
    import route from '@/modules/legacy/libs/ziggy';
    import {JsonAPISchema} from '@meekohq/lumos';
    import type {ModalType} from '@/modules/app/composables/useModal';
    import SelectFamily from '@/modules/family/components/family/SelectFamily.vue';
    import SelectCustomer from '@/modules/cashier/components/core/customer/organisms/SelectCustomer.vue';

    const {familyModel, modal} = defineProps({
        modal: {type: Object as PropType<ModalType>, required: true},
        familyModel: {
            type: Object as PropType<FamilyModel>,
            required: true,
        },
    });
    const emit = defineEmits(['merged']);

    const {activeOrganization, activeTenant} = useManager();
    const isLoading = ref(false);

    const familyModelToKeep = ref<FamilyModel | null>(null);
    const familyModelToDelete = ref<FamilyModel | null>(familyModel);
    const shouldNotMergeCustomers = ref(false);
    const customersModelToKeep = ref<CustomerModel[]>([]);
    const customersModelToDelete = ref<CustomerModel[]>([]);
    const customerModelToKeep = ref<CustomerModel | null>(null);


    const familyBuilder = computed(() => {
        if (familyModelToDelete.value) {
            return FamilyModel.query().where('id', '<>', familyModelToDelete.value.getKey());
        }

        return FamilyModel.query();
    });

    const customerBuilder = computed(() => {
        if (familyModelToKeep.value) {
            return CustomerModel.query().whereHas(new CustomerModel().families(), query => {
                query.where('id', familyModelToKeep.value?.getKey());
            });
        }

        return CustomerModel.query();
    });


    watch(familyModelToDelete, value => {
        isLoading.value = true;

        CustomerModel.query().whereHas(new CustomerModel().families(), query => {
            query.where('id', value?.getKey());
        })
            .get().then(response => {
                customersModelToDelete.value = response.all();
            })
            .finally(() => {
                isLoading.value = false;
            });
    });

    watch(familyModelToKeep, value => {
        customersModelToKeep.value = [];
        if (value) {
            isLoading.value = true;

            CustomerModel.query().whereHas(new CustomerModel().families(), query => {
                query.where('id', value.getKey());
            })
                .get().then(response => {
                    customersModelToKeep.value = response.all();
                    customerModelToKeep.value = customersModelToKeep.value[0];
                })
                .finally(() => {
                    isLoading.value = false;
                });
        }
    });

    function mergeFamilies() {
        let customerDestinationId = customerModelToKeep.value ? customerModelToKeep.value.getKey() : null;

        if (shouldNotMergeCustomers.value) {
            customerDestinationId = null;
        }


        isLoading.value = true;
        useApi().legacy.post(
            route('family/families.merge'),
            new JsonAPISchema.JsonApiDocument(new JsonAPISchema.JsonApiData({
                family_source_id: familyModelToDelete.value?.getKey(),
                family_destination_id: familyModelToKeep.value?.getKey(),
                customer_destination_id: customerDestinationId,
            })),
        )
            .then(() => {
                emit('merged', familyModelToKeep.value);
                modal?.hide();
            })
            .finally(() => {
                isLoading.value = false;
            });
    }

    function switchFamilies() {
        const familyModelToKeepClone = familyModelToKeep.value?.clone() as FamilyModel;
        const familyModelToDeleteClone = familyModelToDelete.value?.clone() as FamilyModel;
        familyModelToDelete.value = familyModelToKeepClone;
        familyModelToKeep.value = familyModelToDeleteClone;
    }
</script>
