import type {QueryBuilder} from '@meekohq/lumos';
import {Model} from '@meekohq/lumos';
import Manager from '@/modules/legacy/store/manager.store';
import OrganizationModel from '@/modules/organization/models/OrganizationModel';
import BalanceTypeModel from '@/modules/human-resources/models/BalanceTypeModel';

export default class BalanceTypeOrganizationPivot extends Model {
    public type = 'hr/balance/types_organizations_pivot';

    public attributes: {
        id: string;
        account_id: string | undefined;
        balance_type_id: string | undefined;
        organization_id: string | undefined;
        archived_at: string | null;
    } = {
            id: this.uuid(),
            account_id: undefined,
            balance_type_id: undefined,
            organization_id: undefined,
            archived_at: null,
        };

    balanceType() {
        return this.belongsTo('balanceType', BalanceTypeModel, 'balance_type_id');
    }

    organization() {
        return this.belongsTo('organization', OrganizationModel, 'organization_id');
    }

    scopeActiveOrganization(query: QueryBuilder<any>) {
        query.where('organization_id', '=', Manager.activeOrganization.id);
    }
}
