<template>
    <div>
        <p class="tw-text-gray-600 tw-text-base mb-0">
            <span class="tw-font-semibold">
                <template v-if="notification.data.isUpdate">
                    {{ __('notification:dossier_updated') }}
                </template>
                <template v-else>
                    {{ __('notification:a_new_document_available') }}
                </template>
            </span>.
        </p>
        <p class="tw-text-sm mb-0 mt-1">
            {{ __('notification:document_name_colon_with_name', {name: notification.data.name}) }}
        </p>
    </div>
</template>

<script>
    import moment from 'moment';

    export default {
        props: {
            notification: {type: Object},
        },
        computed: {
            moment() {
                return moment;
            },
        },
    };
</script>
