<template>
    <TaxCertificatesListSkeleton v-if="loading"/>
    <div
        v-else
        class="TaxCertificates__list"
    >
        <template v-for="customer in customers">
            <template v-if="customer.families().value().isNotEmpty()">
                <template v-for="family in customer.families().value()">
                    <template v-if="family.kids().value().isNotEmpty()">
                        <template v-for="kid in family.kids().value()">
                            <TaxCertificatesListKidItem
                                :key="'kid-' + kid.getKey()"
                                :customer="customer"
                                :kid="kid"
                                @toggleKid="toggleKidFn(kid, customer, !kid.extra.selected)"
                            />
                        </template>
                    </template>
                </template>
            </template>
            <template v-else>
                <TaxCertificatesListCustomerItem
                    :key="'customer-' + customer.getKey()"
                    :customer="customer"
                    @toggleCustomer="customer.extra.selected = !customer.extra.selected"
                />
            </template>
        </template>
    </div>
</template>

<script lang="ts">
    import {defineComponent} from 'vue';
    import TaxCertificatesListCustomerItem
        from '@/modules/cashier/components/tax/tax-certificate/molecules/TaxCertificatesListCustomerItem.vue';
    import TaxCertificatesListKidItem
        from '@/modules/cashier/components/tax/tax-certificate/molecules/TaxCertificatesListKidItem.vue';
    import TaxCertificatesListSkeleton
        from '@/modules/cashier/components/tax/tax-certificate/molecules/TaxCertificatesListSkeleton.vue';

    export default defineComponent({
        components: {
            TaxCertificatesListSkeleton,
            TaxCertificatesListKidItem,
            TaxCertificatesListCustomerItem,
        },
        props: {
            loading: {
                type: Boolean,
                required: true,
            },
            customers: {
                type: Object,
                required: true,
            },
            toggleKidFn: {
                type: Function,
                required: true,
            },
        },
    });
</script>

<style lang="scss" scoped>
    .TaxCertificates__list {
        @apply tw-grid lg:tw-grid-cols-4 md:tw-grid-cols-3 sm:tw-grid-cols-2 tw-grid-cols-1 tw-gap-3;
    }
</style>
