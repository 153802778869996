<template>
    <DocumentEditModal
        :active-organization="activeOrganization"
        :bus="bus"
        :displayed-visibilities="{
            staffs_access: false,
            parents_access: true,
            web_access: false
        }"
        :document-id="$route.params.document"
        :related-resource="memberModel"
        :user="user"
        @deleted="documentDeleted"
        @hidden="close"
        @updated="documentUpdated"
    />
</template>

<script lang="ts">
    import type {PropType} from 'vue';
    import {defineComponent} from 'vue';
    import {useRouter} from 'vue-router/composables';
    import useAuth from '@/modules/app/composables/useAuth';
    import useManager from '@/modules/app/composables/useManager';
    import DocumentEditModal from '@/modules/document/components/organisms/DocumentEditModal.vue';
    import type DocumentModel from '@/modules/document/models/DocumentModel';
    import mitt from 'mitt';
    import type MemberModel from '@/modules/family/models/MemberModel';
    import type {EventType} from '@/modules/meeko-ui/utils/ModalEventType';

    export default defineComponent({
        components: {DocumentEditModal},
        props: {
            memberModel: {
                type: Object as PropType<MemberModel>,
                required: true,
            },
        },
        setup(props, {emit}) {
            const {user} = useAuth();
            const {activeOrganization} = useManager();
            const router = useRouter();
            const bus = mitt<EventType>();

            function close() {
                router.push({name: 'familyMembers.show', query: {nav: 'documents'}});
            }

            function documentUpdated(document: DocumentModel) {
                bus.emit('hide');
                emit('updated', document);
            }

            function documentDeleted(document: DocumentModel) {
                bus.emit('hide');
                emit('deleted', document);
            }

            return {
                bus,
                user,
                activeOrganization,
                close,
                documentUpdated,
                documentDeleted,
            };
        },
    });
</script>
