<template>
    <EventResource
        v-if="resources.isNotEmpty()"
        :events="resources"
    />
</template>

<script lang="ts">
    import type {PropType} from 'vue';
    import {defineComponent} from 'vue';
    import type EventModel from '@/modules/human-resources/models/EventModel';
    import type {Collection} from '@meekohq/lumos';
    import type RequestModel from '@/modules/request/models/RequestModel';
    import EventResource from '@/modules/request/components/Resources/Types/EventResource.vue';

    export default defineComponent({
        components: {EventResource},
        props: {
            resources: {
                type: Object as PropType<Collection<EventModel>>,
                default: null,
            },
            request: {
                type: Object as PropType<RequestModel>,
                required: true,
            },
        },
    });
</script>
