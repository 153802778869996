import {config} from '@meekohq/lumos';
import type {AxiosInstance} from 'axios';

export default function(api: AxiosInstance) {
    api.defaults.baseURL = config('app.api_base_url');

    api.interceptors.request.use(config => {
        config.params = config.params || {};

        return config;
    });

    api.interceptors.response.use(response => {
        return response;
    }, error => {
        if (error?.response?.status === 502 || error?.response?.status === 503) {
            window.location.replace('/maintenance');
        }

        return Promise.reject(error);
    });
}
