<template>
    <div
        class="position-relative range-bg tw-rounded"
        :class="{'small': small}"
    >
        <div
            v-if="loading"
            class="progress h-100"
        >
            <div
                class="progress-bar progress-bar-striped progress-bar-animated tw-w-full !tw-bg-gray-400"
                role="progressbar"
            />
        </div>
        <template
            v-for="(range, i) in summary"
            v-else-if="loader.isDone()"
        >
            <MTooltip
                v-if="staffsNbr(range.staffs) !== ''"
                :key="'range' + i"
                class="align-items-center d-flex justify-content-center range"
                :label="titleHours(range)"
                placement="top"
                :style="style(range)"
            >
                {{ staffsNbr(range.staffs) }}
            </MTooltip>
        </template>
    </div>
</template>

<script>
    import _minBy from 'lodash-es/minBy';
    import _maxBy from 'lodash-es/maxBy';
    import moment from 'moment';
    import JobModel from '@/modules/human-resources/models/JobModel';
    import {collect} from '@meekohq/lumos';
    import Loader from '@/modules/legacy/helpers/loader.helper';
    import __ from '@/modules/app/utils/i18n-facade';

    export default {
        props: {
            opening: {},
            closing: {},
            day: {},
            summary: {},
            nursery: {},
            jobs: {type: Array},
            small: {
                type: Boolean,
                default: false,
            },
            loading: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                loader: new Loader(),
                jobModelCollection: collect(),
            };
        },
        computed: {
            maxStaffs() {
                if (
                    _maxBy(this.summary, 'staffs') ||
                    _minBy(this.summary, 'staffs')
                ) {
                    const max = _maxBy(this.summary, 'staffs').staffs;
                    if (_minBy(this.summary, 'staffs')) {
                        const min = Math.abs(_minBy(this.summary, 'staffs').staffs);
                        if (max > min) {
                            return max;
                        } else if (max < min) {
                            return min;
                        }
                    }

                    return max;
                }

                return 0;
            },

            minStaffs() {
                if (_minBy(this.summary, 'staffs')) {
                    return _minBy(this.summary, 'staffs').staffs;
                }

                return 0;
            },
        },
        async mounted() {
            if (this.jobs) {
                this.jobModelCollection = collect(this.jobs);
            } else {
                this.loader.start();
                this.jobModelCollection = await JobModel.query().get();
                this.loader.stop();
            }
        },
        methods: {
            unixToPercent(unix) {
                const openingTimestamp = this.day
                    .clone()
                    .hours(moment.duration(this.opening).hours())
                    .minutes(moment.duration(this.opening).minutes())
                    .seconds(moment.duration(this.opening).seconds())
                    .unix();

                const closingTimestamp = this.day
                    .clone()
                    .hours(moment.duration(this.closing).hours())
                    .minutes(moment.duration(this.closing).minutes())
                    .seconds(moment.duration(this.closing).seconds())
                    .unix();

                const percent =
                    ((unix - openingTimestamp) * 100) /
                    (closingTimestamp - openingTimestamp);

                if (percent > 100) {
                    return 100;
                } else if (percent < 0) {
                    return 0;
                }

                return percent;
            },
            style(range) {
                const startedAt = range.started_at;
                const endedAt = range.ended_at;
                const maxStaff = this.maxStaffs;
                let opacity = 0;

                if (maxStaff) {
                    opacity = (Math.abs(range.staffs) * 100) / this.maxStaffs;
                }

                let color = '';

                if (range.staffs < 0) {
                    color = 'rgba(252,13,28,' + opacity / 100 + ')';
                } else {
                    color = 'rgba(0,122,255,' + opacity / 100 + ')';
                }

                return {
                    'left': this.unixToPercent(startedAt).toFixed(2) + '%',
                    'right': (100 - this.unixToPercent(endedAt)).toFixed(2) + '%',
                    'background-color': color,
                };
            },
            titleHours(range) {
                let text = '';
                if (range && range.anomalies && range.anomalies.length) {
                    const extra = range.anomalies.filter(item => item.staffs > 0);
                    if (extra.length) {
                        text += __('planning:additional_staff_colon') + '\n';
                        extra.forEach(item => {
                            text +=
                                this.jobName(item.job_id, item.staffs) +
                                ' : ' +
                                item.staffs;
                            text += '\n';
                        });
                    }

                    const missing = range.anomalies.filter(item => item.staffs < 0);
                    if (missing.length) {
                        text += __('planning:missing_staff_colon') + '\n';
                        missing.forEach(item => {
                            text +=
                                this.jobName(
                                    item.job_id,
                                    Math.abs(item.staffs),
                                ) +
                                ' : ' +
                                Math.abs(item.staffs);
                            text += '\n';
                        });
                    }
                }

                return text;
            },
            jobName(jobId, count) {
                const jobModel = this.jobModelCollection.first(item => item.id === jobId);
                if (jobModel) {
                    return jobModel.attributes.name;
                } else {
                    return __('common:employee', {count});
                }
            },
            staffsNbr(staffs) {
                if (staffs > 0) {
                    return '+' + staffs;
                } else if (staffs < 0) {
                    return staffs;
                }

                return '';
            },
        },
    };
</script>

<style
    lang="scss"
    scoped
>
    .range {
        height: 100%;
        position: absolute;
        background-color: #007aff;
        color: white;
        text-shadow: 0 1px 1px rgba(20, 117, 236, 0.5);
        font-weight: 600;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        -webkit-print-color-adjust: exact !important; /* Chrome, Safari */
        print-color-adjust: exact !important; /*Firefox*/
    }

    .range-bg {
        height: 30px;
        font-size: 14px;
        background-color: rgba(#007aff, 0.1);
        overflow: hidden;

        -webkit-print-color-adjust: exact !important; /* Chrome, Safari */
        print-color-adjust: exact !important; /*Firefox*/

        &.small {
            height: 20px;
            font-size: 13px;
        }
    }
</style>
