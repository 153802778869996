<template>
    <div class="tw-bg-white tw-rounded-2xl tw-shadow-md">
        <slot/>
    </div>
</template>

<script>
    export default {
        name: 'Box',
    };
</script>
