<template>
    <CDropdownMenu placement="left">
        <template #button>
            <CButton
                icon-ellipsis
                size="sm"
                variant="ghost"
            />
        </template>

        <CDropdownMenuItem
            icon="fas fa-pen"
            @click="$refs.updateBalanceTypeModal.show()"
        >
            {{ __('common:actions.update') }}
        </CDropdownMenuItem>
        <CDropdownMenuDivider/>
        <CDropdownMenuItem
            v-if="isPivotArchived"
            icon="fas fa-box-open"
            @click="toggleArchive"
        >
            {{ __('common:actions.unarchive') }}
        </CDropdownMenuItem>
        <CDropdownMenuItem
            v-else
            icon="fas fa-archive"
            @click="toggleArchive"
        >
            {{ __('common:actions.archive') }}
        </CDropdownMenuItem>
        <template v-if="user.is_owner && user.account_id === model.attributes.account_id && isUsedInAnotherOrganization">
            <CDropdownMenuItem
                v-if="isPivotArchived"
                icon="fas fa-box-open"
                variant="light"
                @click="toggleArchiveForAll"
            >
                <span>{{ __('common:actions.unarchive_of_all_organizations') }}</span>
            </CDropdownMenuItem>
            <CDropdownMenuItem
                v-else
                icon="fas fa-archive"
                variant="light"
                @click="toggleArchiveForAll"
            >
                <span>{{ __('common:actions.archive_of_all_organizations') }}</span>
            </CDropdownMenuItem>
        </template>
        <CDropdownMenuItem
            :disabled="isUsed"
            icon="fas fa-trash"
            variant="danger"
            @click="deleteItem"
        >
            {{ __('common:actions.delete') }}
        </CDropdownMenuItem>
        <template #extra>
            <UpdateBalanceTypeModal
                ref="updateBalanceTypeModal"
                :model="model"
                :shared="isUsedInAnotherOrganization"
                @updated="onUpdatedItem"
            />
        </template>
    </CDropdownMenu>
</template>

<script lang="ts">
    import type {PropType} from 'vue';
    import {defineComponent} from 'vue';
    import UpdateBalanceTypeModal
        from '@/modules/human-resources/components/balance-type/Modals/UpdateBalanceTypeModal.vue';
    import __ from '@/modules/app/utils/i18n-facade';
    import type BalanceTypeModel from '@/modules/human-resources/models/BalanceTypeModel';
    import moment from 'moment';
    import type BalanceTypeOrganizationPivot from '@/modules/human-resources/models/BalanceTypeOrganizationPivot';
    import useAuth from '@/modules/app/composables/useAuth';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';

    export default defineComponent({
        components: {UpdateBalanceTypeModal},
        props: {
            model: {type: Object as PropType<BalanceTypeModel>, required: true},
            organizationPivot: {type: Object as PropType<BalanceTypeOrganizationPivot>, required: true},
            isPivotArchived: {type: Boolean, required: true},
            isUsedInAnotherOrganization: {type: Boolean, required: true},
            isUsed: {type: Boolean, required: true},
        },
        setup(props, {emit}) {
            const {legacyUser: user} = useAuth();
            const organizationsPivot = props.model.organizationsPivots().value();

            const deleteItem = async function() {
                if (props.isUsed) {
                    useNotification().error(__('hr_balance:balance_cannot_be_deleted'));
                } else {
                    if (props.isUsedInAnotherOrganization) {
                        // Used elsewhere, detach from my organization
                        await props.organizationPivot.delete();
                    } else {
                        // Used only in my organization, delete item
                        await props.model.delete();
                    }
                    emit('deleted', props.model);
                }
            };

            const toggleArchive = function() {
                const pivot = props.organizationPivot;
                if (pivot.attributes.archived_at === null) {
                    pivot.attributes.archived_at = moment().toISOString(true);
                } else {
                    pivot.attributes.archived_at = null;
                }
                pivot.save();
            };

            const toggleArchiveForAll = function() {
                organizationsPivot.each(pivot => {
                    if (pivot.attributes.archived_at === null) {
                        pivot.attributes.archived_at = moment().toISOString(true);
                    } else {
                        pivot.attributes.archived_at = null;
                    }
                    pivot.save();
                });
            };

            const onUpdatedItem = function(value) {
                emit('updated', value);
            };

            return {
                emit,
                user,
                toggleArchiveForAll,
                toggleArchive,
                deleteItem,
                onUpdatedItem,
            };
        },
    });
</script>
