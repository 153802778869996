import moment from 'moment';

export const url = {
    methods: {
        pushQueryString(key, value) {
            // @ts-ignore
            const queries = JSON.parse(JSON.stringify(this.$route.query));
            queries[key] = value;
            if (queries[key] === undefined) {
                delete queries[key];
            }
            // @ts-ignore
            this.$router.push({name: this.$route.name, query: queries}).catch(() => {
            });
        },
        pushQueryDate(date, format: 'day' | 'week') {
            if (format === 'day') {
                this.pushQueryString('date', moment(date).startOf(format).unix());
            } else {
                this.pushQueryString('date', moment(date).unix());
            }
        },
    },
};
