<template>
    <div>
        <config
            :computed-hourly-rate.sync="computedHourlyRate"
            :config="contract.config"
            :contract="contract"
            :nursery="nursery"
            :price-loading="loading"
            :response-config="responseConfig"
        />
        <div class="row">
            <div class="col-12 col-lg-4">
                <div class="card">
                    <div class="tw-text-lg card-header text-info">
                        <i
                            aria-hidden="true"
                            class="fas fa-child-reaching mr-2 mt-2"
                        />{{ $fullName(kid) }}
                        <MButton
                            class="float-right"
                            @click="kidAdvanced = !kidAdvanced"
                        >
                            {{ kidAdvanced ? __('common:simple') : __('common:advanced') }}
                        </MButton>
                    </div>
                    <div class="card-body p-3">
                        <kid-planning-summary
                            v-if="contract.plannings && contract.type !== 'occasional'"
                            :nursery="nursery"
                            :plannings="contract.plannings"
                            :weeks="contract.recurrent_weeks"
                        />
                        <div v-else-if="contract.type === 'occasional'">
                            <strong>{{ __('common:occasionnal_contract') }}</strong>
                        </div>
                        <div
                            v-if="contract.config && contract.config.smoothing.on === 'contract'"
                            class="tw-text-gray-700 tw-mt-2"
                        >
                            <div v-if="loading">
                                <i
                                    aria-hidden="true"
                                    class="fa fa-circle-notch fa-spin mr-1"
                                />{{ __('common:loading_dots') }}
                            </div>
                            <template v-else>
                                <template v-if="responseConfig && responseConfig.presence && contract.config && contract.config.billing.cycle !== 'month'">
                                    <div v-if="responseConfig.presence.months">
                                        {{ __('common:month_with_count', {count: responseConfig.presence.months}) }}
                                    </div>
                                    <div v-if="responseConfig.presence.weeks">
                                        {{ __('common:week_with_count', {count: responseConfig.presence.weeks}) }}
                                    </div>
                                    <div v-if="contract.config.billing.cycle === 'halfday' && responseConfig.presence.halfdays">
                                        {{ __('common:halfday_alternative_long', {count: responseConfig.presence.halfdays}) }}
                                    </div>
                                    <div v-else-if="responseConfig.presence.days">
                                        {{ __('common:day_with_count', {count: responseConfig.presence.days}) }}
                                    </div>
                                    <div v-if="responseConfig.presence.hours">
                                        {{ __('common:hour_with_count', {count: responseConfig.presence.hours}) }}
                                    </div>
                                </template>
                                <div class="mt-2">
                                    {{ __('common:holiday', {count: holidays}) }}
                                </div>
                                <div>
                                    {{ __('common:closed_period', {count: closed_periods}) }}
                                </div>
                            </template>
                        </div>
                        <hr class="hr">
                        <CForm>
                            <CFormGroup v-if="contract.config && contract.config.billing.cycle !== 'month' && contract.config.smoothing.on === 'year' && contract.type !== 'occasional'">
                                <CLabel>{{ __('billing_invoice:invoiced_quantity_per_month') }}</CLabel>
                                <CButtonGroup class="tw-w-full">
                                    <MInput
                                        v-model.number="contract.config.billing.qty"
                                        v-float
                                        :disabled="!$can('update', 'invoices')"
                                    />
                                    <CFormSelect
                                        v-model="contract.config.billing.cycle"
                                        :disabled="!$can('update', 'invoices')"
                                        :options="cycleOptions"
                                        :search-bar="false"
                                    />
                                </CButtonGroup>
                            </CFormGroup>
                            <!-- LISSAGE SUR L'ANNÉE UNIQUEMENT -->
                            <template v-if="contract.config && contract.config.smoothing.on === 'year'">
                                <!-- DEJA LISSE -->
                                <CFormGroup v-if="contract.config.smoothing.smooth_on === 'none'">
                                    <CLabel>{{ __('billing_invoice:weeks_in_month') }}</CLabel>
                                    <CInputGroup class="tw-w-full">
                                        <MInput
                                            v-model.number="contract.config.billing.weeks_in_month"
                                            placeholder="4"
                                        />
                                        <CInputAddon>
                                            {{ __('common:week_other') }}
                                        </CInputAddon>
                                    </CInputGroup>
                                </CFormGroup>
                                <!-- LISSAGE PRESENCE OU TARIF -->
                                <CFormGroup v-if="contract.config.smoothing.smooth_on !== 'none' && contract.config.smoothing.months !== undefined && contract.type !== 'occasional'">
                                    <CLabel>{{ __('billing_invoice:months_in_smoothing') }}</CLabel>
                                    <CInputGroup class="tw-w-full">
                                        <MInput v-model.number="contract.config.smoothing.months"/>
                                        <CInputAddon>
                                            {{ __('billing_invoice:month_lower') }}
                                        </CInputAddon>
                                    </CInputGroup>
                                    <div
                                        v-if="!contract.config.smoothing.months && responseConfig.presence && responseConfig.presence.months"
                                        class="tw-text-sm tw-mt-1"
                                    >
                                        {{ __('billing_invoice:computed_months_colon') }}
                                        <strong>{{ loading ? '...' : responseConfig.presence.months }}</strong>
                                    </div>
                                </CFormGroup>
                                <CFormGroup v-if="contract.config.smoothing.smooth_on !== 'none' && contract.type !== 'occasional'">
                                    <CLabel>{{ __('billing_invoice:weeks_in_smoothing') }}</CLabel>
                                    <CInputGroup class="tw-w-full">
                                        <MInput v-model.number="contract.config.smoothing.weeks_in_contract"/>
                                        <CInputAddon>
                                            {{ __('common:week_other') }}
                                        </CInputAddon>
                                    </CInputGroup>
                                    <div
                                        v-if="!contract.config.smoothing.weeks_in_contract && responseConfig.presence && responseConfig.presence.weeks"
                                        class="tw-text-sm tw-mt-1"
                                    >
                                        {{ __('billing_invoice:computed_weeks_colon') }}
                                        <strong>{{ loading ? '...' : responseConfig.presence.weeks }}</strong>
                                    </div>
                                </CFormGroup>
                                <CFormGroup v-if="contract.config.smoothing.smooth_on !== 'none' && contract.config.smoothing.weeks_in_contract !== undefined && contract.type !== 'occasional'">
                                    <CLabel>{{ __('billing_invoice:week_to_be_deducted') }}</CLabel>
                                    <CInputGroup class="tw-w-full">
                                        <MInput v-model.number="contract.config.smoothing.closing_weeks"/>
                                        <CInputAddon>
                                            {{ __('common:week_other') }}
                                        </CInputAddon>
                                    </CInputGroup>
                                    <div class="tw-text-sm tw-mt-1">
                                        <span v-if="!contract.config.smoothing.weeks_in_contract && responseConfig.presence && responseConfig.presence.weeks">
                                            {{ __('billing_invoice:total_weeks_colon') }} <strong>{{ loading ? '...' : (responseConfig.presence.weeks - contract.config.smoothing.closing_weeks) }}</strong>
                                        </span>
                                        <span v-else>
                                            {{ __('billing_invoice:total_weeks_colon') }} <strong>{{ (contract.config.smoothing.weeks_in_contract - contract.config.smoothing.closing_weeks) }}</strong>
                                        </span>
                                    </div>
                                </CFormGroup>
                            </template>
                            <CFormGroup>
                                <CLabel>{{ __('billing_invoice:holidays_other') }}</CLabel>
                                <CInputGroup class="tw-w-full">
                                    <MInput
                                        v-model.number="contract.vacation_days"
                                        :disabled="!$can('update', 'invoices')"
                                    />
                                    <CInputAddon>
                                        {{ __('common:day_other') }}
                                    </CInputAddon>
                                </CInputGroup>
                                <div
                                    v-if="contract.vacation_days && contract.used_vacation_days"
                                    class="tw-text-sm tw-mt-1"
                                >
                                    <strong>{{ __('billing_invoice:used_vacation_days', {count: contract.used_vacation_days}) }}</strong>
                                </div>
                            </CFormGroup>
                            <template v-if="kidAdvanced">
                                <CFormGroup>
                                    <CLabel>{{ __('family_kid:foods.eating_habits') }}</CLabel>
                                    <CFormSelect
                                        v-model="kid.meal_type"
                                        :disabled="!$can('update', 'invoices')"
                                        :options="mealsOptions"
                                        :search-bar="false"
                                    />
                                </CFormGroup>
                                <CFormGroup>
                                    <CLabel>{{ __('family_kid:hygiene') }}</CLabel>
                                    <CFormSelect
                                        v-model="kid.hygiene"
                                        :disabled="!$can('update', 'invoices')"
                                        :options="hygieneOptions"
                                        :search-bar="false"
                                    />
                                </CFormGroup>
                            </template>
                        </CForm>
                    </div>
                </div>
                <div
                    v-if="contract.config && contract.config.deposit"
                    class="card mt-3"
                >
                    <div class="tw-text-lg card-header text-info">
                        <i
                            aria-hidden="true"
                            class="fas fa-money-check-pen mr-2 mt-2"
                        />{{ __('common:deposit_one') }}
                        <MButton
                            class="float-right"
                            @click="depositAdvanced = !depositAdvanced"
                        >
                            {{ depositAdvanced ? __('common:simple') : __('common:advanced') }}
                        </MButton>
                    </div>
                    <div class="card-body p-3">
                        <CFormGroup>
                            <CLabel>
                                {{ __('common:amount_one') }}
                            </CLabel>
                            <div class="tw-flex xl:tw-flex-row lg:tw-flex-col tw-flex-row tw-gap-2">
                                <CButtonGroup class="xl:tw-w-auto lg:tw-w-full tw-w-auto">
                                    <CButton
                                        class="xl:tw-w-auto lg:tw-w-1/2 tw-w-auto"
                                        :variant="contract.config.deposit.type === 'month' ? 'info' : 'light'"
                                        @click="contract.config.deposit.type = 'month'"
                                    >
                                        {{ __('common:per_month_two') }}
                                    </CButton>
                                    <CButton
                                        class="xl:tw-w-auto lg:tw-w-1/2 tw-w-auto"
                                        :variant="contract.config.deposit.type === 'fixed' ? 'info' : 'light'"
                                        @click="contract.config.deposit.type = 'fixed'"
                                    >
                                        {{ __('common:phone.landline') }}
                                    </CButton>
                                </CButtonGroup>
                                <CurrencyInput
                                    v-if="contract.config.deposit.type === 'fixed'"
                                    v-model="contract.config.deposit.amount"
                                    :currency="nursery.currency"
                                />
                                <CInputGroup v-else>
                                    <MInput v-model.number="contract.config.deposit.month_coef"/>
                                    <CInputAddon>
                                        {{ __('billing_invoice:month_lower') }}
                                    </CInputAddon>
                                </CInputGroup>
                            </div>
                        </CFormGroup>
                        <div v-if="depositAdvanced && (contract.config.deposit.amount > 0 || contract.config.deposit.month_coef > 0)">
                            <CFormGroup class="tw-mt-5">
                                <CLabel>
                                    {{ __('billing_invoice:deposited_the') }}
                                </CLabel>
                                <CButtonGroup
                                    class="tw-w-full"
                                    :direction="{xl: 'row', lg: 'column', md: 'row', base: 'column'}"
                                >
                                    <CFormDatepicker
                                        v-model="contract.config.deposit.received_at"
                                        class="xl:tw-w-1/2 tw-w-full"
                                        format="YYYY-MM-DD"
                                        :search-bar="false"
                                    />
                                    <payment-methods
                                        v-model="contract.config.deposit.received_type"
                                        class="xl:tw-w-1/2 tw-w-full"
                                    />
                                </CButtonGroup>
                            </CFormGroup>
                            <CFormGroup class="tw-mt-3">
                                <CLabel>
                                    {{ __('billing_invoice:restituted_the') }}
                                </CLabel>
                                <CButtonGroup
                                    class="tw-w-full"
                                    :direction="{xl: 'row', lg: 'column', md: 'row', base: 'column'}"
                                >
                                    <CFormDatepicker
                                        v-model="contract.config.deposit.refunded_at"
                                        class="xl:tw-w-1/2 tw-w-full"
                                        format="YYYY-MM-DD"
                                    />
                                    <payment-methods
                                        v-model="contract.config.deposit.refunded_type"
                                        class="xl:tw-w-1/2 tw-w-full"
                                    />
                                </CButtonGroup>
                            </CFormGroup>
                        </div>
                    </div>
                </div>
                <div class="card mt-3">
                    <div class="tw-text-lg card-header text-info">
                        <i
                            aria-hidden="true"
                            class="fas fa-users mr-2 mt-2"
                        />{{ __('billing_invoice:the_family') }}
                        <MButton
                            class="float-right"
                            @click="familyAdvanced = !familyAdvanced"
                        >
                            {{ familyAdvanced ? __('common:simple') : __('common:advanced') }}
                        </MButton>
                    </div>
                    <div class="card-body p-3">
                        <template v-if="showCAF()">
                            <div v-if="showIncomeBracket">
                                {{ family.income_bracket !== null ? __('billing_invoice:income_bracket_with_value', {value: family.income_bracket}) : __('billing_invoice:unknow_income_bracket') }}
                            </div>
                            <div
                                v-if="showCmg"
                                v-html="__('billing_invoice:cmg_with_nursery_currency', {cmg: family.cmg ? family.cmg : 0, currency: nursery.currency})"
                            />
                            <div
                                v-if="showEffortRate"
                                v-html="__('billing_invoice:effort_rate_with_rate', {effortRate: family.effort_rate ? family.effort_rate : 0})"
                            />
                            <hr class="hr">
                        </template>
                        <CForm>
                            <CFormGroup>
                                <CLabel>{{ __('billing_core:annual_resources') }}</CLabel>
                                <MInput
                                    v-model.number="contract.cafpro_resources"
                                    :disabled="!contractExists || !$can('update', 'invoices')"
                                />
                            </CFormGroup>
                            <CFormGroup>
                                <CLabel>{{ __('common:dependent_children') }}</CLabel>
                                <MInput
                                    v-model.number="contract.dependent_children"
                                    :disabled="!$can('update', 'invoices')"
                                />
                            </CFormGroup>
                            <CFormGroup v-if="familyAdvanced">
                                <CLabel>{{ __('billing_invoice:handicapped_children') }}</CLabel>
                                <MInput
                                    v-model.number="contract.handicapped_children"
                                    :disabled="!$can('update', 'invoices')"
                                />
                            </CFormGroup>
                        </CForm>
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-8 mt-3 mt-lg-0">
                <div class="card">
                    <div class="tw-text-lg card-header text-info tw-flex tw-items-center">
                        <div class="tw-mr-auto">
                            <i
                                aria-hidden="true"
                                class="fas fa-file-alt mr-2 mt-2"
                            />{{ __('billing_invoice:the_invoices') }}
                        </div>
                        <CTooltip
                            v-if="showResourcesWarning"
                            placement="top"
                        >
                            <CBadge
                                class="tw-px-2 tw-py-1"
                                variant="gray"
                            >
                                <i class="fad fa-exclamation-triangle tw-mr-1"/>{{ __('billing_invoice:missing_resources') }}
                            </CBadge>
                            <template #content>
                                <div v-html="__('billing_invoice:missing_resources_explanation')"/>
                            </template>
                        </CTooltip>
                        <template v-if="!contract.draft && contractExists && contract.id && !contract.broked_at && $can('create', 'invoices')">
                            <MButton
                                class="tw-ml-2"
                                :disabled="updating"
                                :loading="creatingInvoices"
                                variant="orange"
                                @click="generateInvoices"
                            >
                                <template #left-icons>
                                    <FontAwesomeIcon icon="fas fa-file-invoice"/>
                                </template>
                                {{ __('billing_invoice:create_invoices_button') }}
                            </MButton>
                        </template>
                    </div>
                    <div class="card-body p-0">
                        <invoices
                            :invoices="invoices"
                            :loading="loading"
                            :nursery="nursery"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import useApi from '@/modules/app/composables/useApi';
    import route from '@/modules/legacy/libs/ziggy';
    import {nursery} from '@/modules/legacy/mixins/nursery';
    import * as ProcessedData from '@/modules/legacy/libs/ProcessedData';
    import _forEach from 'lodash-es/forEach';
    import _head from 'lodash-es/head';
    import _cloneDeep from 'lodash-es/cloneDeep';
    import _debounce from 'lodash-es/debounce';
    import Config from './Config.vue';
    import Invoices from './Invoices.vue';
    import KidPlanningSummary from '@/modules/legacy/components/Modules/KidPlanningSummary.vue';
    import useBroadcast from '@/modules/app/composables/useBroadcast';
    import PaymentMethods from '@/modules/cashier/components/payment-method/legacy/PaymentMethods.vue';
    import {isEmpty} from 'lodash-es';
    import __ from '@/modules/app/utils/i18n-facade';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';
    import CurrencyInput from '@/modules/app/components/atoms/CurrencyInput.vue';

    export default {
        components: {
            CurrencyInput,
            Config,
            Invoices,
            KidPlanningSummary,
            PaymentMethods,
        },
        mixins: [nursery],
        props: {
            user: {},
            nursery: {},
            contract: {},
            kid: {},
            contractExists: {
                type: Boolean,
                default: true,
            },
            updating: {
                type: Boolean,
                default: false,
            },
        },
        data: () => ({
            invoices: [],
            loading: false,
            isUserUpdatingValues: false,
            creatingInvoices: false,
            kidAdvanced: window.localStorage.getItem('kidEstimateAdvanced') === 'true',
            familyAdvanced: window.localStorage.getItem('familyEstimateAdvanced') === 'true',
            depositAdvanced: window.localStorage.getItem('depositEstimateAdvanced') === 'true',
            computedHourlyRate: null,
            computedMonthlyRate: null,
            holidays: null,
            closed_periods: null,
            responseConfig: {},
            family: {
                income_bracket: null,
                cmg: null,
                effort_rate: null,
            },
            mealsOptions: [
                {
                    text: __('common:meal_types.milk'),
                    value: 'milk',
                },
                {
                    text: __('common:meal_types.mixed'),
                    value: 'mixed',
                },
                {
                    text: __('common:meal_types.crushed'),
                    value: 'crushed',
                },
                {
                    text: __('common:meal_types.mix'),
                    value: 'mix',
                },
                {
                    text: __('common:meal_types.normal'),
                    value: 'normal',
                },
            ],
            hygieneOptions: [
                {
                    text: __('common:hygiene_types.diaper'),
                    value: 'baby',
                },
                {
                    text: __('common:hygiene_types.potty'),
                    value: 'potty',
                },
                {
                    text: __('common:hygiene_types.potty_and_nappy'),
                    value: 'mixed',
                },
            ],
            cycleOptions: [
                {
                    text: __('common:day_other'),
                    value: 'day',
                },
                {
                    text: __('common:halfday_other'),
                    value: 'halfday',
                },
                {
                    text: __('common:hour_other'),
                    value: 'hour',
                },
            ],
        }),
        computed: {
            showIncomeBracket() {
                return this.contract.config && this.contract.config.pricing ? this.contract.config.pricing.discount.on_salary : false;
            },
            showEffortRate() {
                return this.contract.config ? this.contract.config.funding === 'psu' : false;
            },
            showCmg() {
                return this.contract.config ? this.contract.config.funding !== 'psu' : false;
            },
            showResourcesWarning() {
                // If ressources aren't provided
                if (!this.contract.cafpro_resources && this.contract.config) {
                    // Then show warning for specific configurations
                    return this.contract.config.funding === 'psu' || this.contract.config.funding === 'psu_cmg' || this.showIncomeBracket;
                }

                return false;
            },
        },
        watch: {
            contract: {
                handler: function() {
                    this.isUserUpdatingValues = true;
                    this.loading = true;
                    this.getEstimationDebounced();
                },
                deep: true,
            },
            kid: {
                handler: function() {
                    this.isUserUpdatingValues = true;
                    this.loading = true;
                    this.getEstimationDebounced();
                },
                deep: true,
            },
            kidAdvanced(val) {
                window.localStorage.setItem('kidEstimateAdvanced', val);
            },
            familyAdvanced(val) {
                window.localStorage.setItem('familyEstimateAdvanced', val);
            },
            depositAdvanced(val) {
                window.localStorage.setItem('depositEstimateAdvanced', val);
            },
        },
        mounted() {
            useBroadcast().sessionChannel.bind('contract-simulation', response => {
                // If the user is still updating values, we don't want to retrieve the processed data
                if (!this.isUserUpdatingValues) {
                    ProcessedData.retrieve(response.processedDataUrl, response => {
                        this.setEstimationResult(response.data);
                        this.loading = false;
                    });
                }
            });

            this.$bus.$on('invoices:created', () => {
                this.creatingInvoices = false;
            });

            this.getEstimation();
        },
        beforeDestroy() {
            useBroadcast().sessionChannel.unbind('contract-simulation');
            this.$bus.$off('invoices:created');
        },
        methods: {
            getEstimation() {
                // Prevent request if contract is null and from registration or no config exists
                if ((!this.contract.id && !this.contractExists) || typeof this.contract.config == 'undefined' || this.contract.config === null) {
                    return;
                }

                this.isUserUpdatingValues = false;

                // Set cafpro_resources from both parent - Only for contract
                if (this.contractExists) {
                    this.contract.cafpro_resources === null || this.contract.cafpro_resources === '' ? this.contract.cafpro_resources = (this.contract.first_salary + this.contract.second_salary) : null;
                }

                const data = {};
                data.contract = _cloneDeep(this.contract);
                data.config = _cloneDeep(this.contract.config);
                data.config.id = this.contract.config.id;
                data.kid = _cloneDeep(this.kid);

                if (this.contract.type !== 'recurrent') {
                    data.contract.plannings = [];
                }

                useApi().legacy.post(route('nurseries.contracts.simulate', {
                    nurseries: this.nursery.id,
                }), data)
                    .then(response => {
                        if (!isEmpty(response.data)) {
                            this.setEstimationResult(response.data);
                            this.loading = false;
                        }
                    })
                    .catch(error => {
                        if (error && error.response && error.response.status === 422) {
                            _forEach(error.response.data.errors, value => {
                                useNotification().error(_head(value));
                            });
                        } else {
                            useNotification().error(error);
                        }
                    });
            },

            getEstimationDebounced: _debounce(function() {
                this.getEstimation();
            }, 500, {trailing: true, leading: false}),

            generateInvoices() {
                this.creatingInvoices = true;
                this.$bus.$emit('generate:invoices');
            },

            showCAF() {
                return this.$showCAF(this.nursery.country);
            },

            setEstimationResult(data) {
                this.responseConfig = data;

                // Invoices
                this.invoices = data.invoices;

                // Hourly Rate
                this.computedHourlyRate = data.price.computed_hourly_rate;
                this.computedMonthlyRate = data.price.computed_monthly_rate;

                // Pour la famille
                this.family.income_bracket = data.family.income_bracket;
                this.family.effort_rate = data.family.effort_rate;
                this.family.cmg = data.family.cmg;

                // Pour les périodes de fermetures & jours fériés
                this.holidays = data.nursery.holidays;
                this.closed_periods = data.nursery.closed_periods;
            },
        },
    };
</script>
