<template>
    <div
        ref="familiesListItemEl"
        class="FamiliesListItem"
    >
        <MagicIndexItemColumn
            class="FamiliesListItem__column-name"
            :grid-enabled="false"
        >
            <div class="tw-flex tw-gap-3 tw-items-center tw-min-w-0">
                <FamilyAvatar
                    class="tw-w-10"
                    :family-model="familyModel"
                />
                <MListItemTitle :title="familyModel.attributes.name"/>
            </div>
        </MagicIndexItemColumn>
        <MagicIndexItemColumn
            class="FamiliesListItem__column-actions"
            :grid-enabled="false"
        >
            <ShowCustomerButton
                v-if="allows('access-to-billings-module')"
                v-slot="{ navigate }"
                :customer-models="customerModels"
            >
                <MTooltip placement="top-end">
                    <MButton
                        :disabled="!customerModels.length"
                        variant="orange"
                        @click="navigate ? navigate : undefined"
                    >
                        <FontAwesomeIcon icon="fa-duotone fa-wallet"/>
                    </MButton>
                    <template #content>
                        <template v-if="customerModels.length === 0">
                            {{ __('family_family:no_customer_account_attached') }}
                        </template>
                        <template v-else>
                            {{ __('family_family:show_billing') }}
                        </template>
                    </template>
                </MTooltip>
            </ShowCustomerButton>
        </MagicIndexItemColumn>
        <MagicIndexItemColumn
            class="FamiliesListItem__column-kids"
            :container-width="width"
            :grid-title="__('common:kid_other')"
        >
            <div
                v-if="can('read', 'kids_details')"
                class="tw-flex tw-flex-wrap tw-items-center tw-gap-2"
            >
                <MDropdown
                    v-for="kidModel in kidModels"
                    :key="'kid-' + kidModel.getKey()"
                    class="tw-inline-flex"
                    hoverable
                    no-padding
                >
                    <MHoverable>
                        <KidAvatar
                            class="tw-w-8"
                            :kid-model="kidModel"
                        />
                    </MHoverable>
                    <template #content>
                        <KidProfile :kid-model="kidModel"/>
                    </template>
                </MDropdown>
            </div>
        </MagicIndexItemColumn>
        <MagicIndexItemColumn
            class="FamiliesListItem__column-parents"
            :container-width="width"
            :grid-title="__('family_family:parents_and_relatives')"
        >
            <div
                v-if="can('read', 'family_members_details')"
                class="tw-flex tw-flex-wrap tw-items-center tw-gap-2"
            >
                <MDropdown
                    v-for="memberModel in memberModels"
                    :key="'member-' + memberModel.getKey()"
                    class="tw-inline-flex"
                    hoverable
                    no-padding
                >
                    <MHoverable>
                        <MemberAvatar
                            class="tw-w-8"
                            :member-model="memberModel"
                        />
                    </MHoverable>
                    <template #content>
                        <MemberProfile :member-model="memberModel"/>
                    </template>
                </MDropdown>
            </div>
        </MagicIndexItemColumn>
    </div>
</template>

<script lang="ts">
    import type FamilyModel from '@/modules/family/models/FamilyModel';
    import {useElementSize} from '@vueuse/core';
    import useAbility from '@/modules/app/composables/useAbility';
    import {computed, defineComponent, type PropType, ref} from 'vue';
    import MListItemTitle from '@/modules/meeko-ui/components/MListItemTitle.vue';
    import ShowCustomerButton from '@/modules/family/components/family/ShowCustomerButton.vue';
    import KidProfile from '@/modules/family/components/kid/KidProfile.vue';
    import MemberProfile from '@/modules/family/components/member/MemberProfile.vue';
    import FamilyAvatar from '@/modules/app/components/atoms/avatars/FamilyAvatar.vue';
    import MemberAvatar from '@/modules/app/components/atoms/avatars/MemberAvatar.vue';
    import KidAvatar from '@/modules/app/components/atoms/avatars/KidAvatar.vue';
    import MagicIndexItemColumn from '@/modules/magic-index/components/atoms/MagicIndexItemColumn.vue';

    export default defineComponent({
        components: {
            MagicIndexItemColumn,
            KidAvatar,
            MemberAvatar,
            FamilyAvatar,
            MListItemTitle,
            ShowCustomerButton,
            MemberProfile,
            KidProfile,
        },
        props: {
            familyModel: {
                type: Object as PropType<FamilyModel>,
                required: true,
            },
        },
        setup(props) {
            const familiesListItemEl = ref(null);
            const {width} = useElementSize(familiesListItemEl);
            const {allows, can} = useAbility();

            const kidModels = computed(() => {
                return props.familyModel.kids().value().all();
            });

            const memberModels = computed(() => {
                return props.familyModel.members().value().all();
            });

            const customerModels = computed(() => {
                return props.familyModel.customers().value().all();
            });

            return {
                familiesListItemEl,
                width,
                allows,
                can,
                kidModels,
                memberModels,
                customerModels,
            };
        },
    });
</script>

<style lang="scss" scoped>
    .FamiliesListItem {
        @apply tw-grid tw-grid-cols-24 tw-items-center tw-gap-3;
        container-type: inline-size;
        container-name: FamiliesListItem;
    }

    .FamiliesListItem__column-name {
        @apply tw-col-span-20;

        @container FamiliesListItem (min-width: 48rem) {
            @apply tw-col-span-11;
        }
    }

    .FamiliesListItem__column-actions {
        @apply tw-col-span-4 tw-order-none tw-text-right;

        @container FamiliesListItem (min-width: 48rem) {
            @apply tw-col-span-1 tw-order-last;
        }
    }

    .FamiliesListItem__column-kids {
        @apply tw-col-span-full;

        @container FamiliesListItem (min-width: 48rem) {
            @apply tw-col-span-6;
        }
    }

    .FamiliesListItem__column-parents {
        @apply tw-col-span-full;

        @container FamiliesListItem (min-width: 48rem) {
            @apply tw-col-span-6;
        }
    }
</style>
