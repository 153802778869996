import {Model} from '@meekohq/lumos';
import OrganizationModel from '@/modules/organization/models/OrganizationModel';
import RegistrationModel from '@/modules/registration/models/RegistrationModel';
import KidModel from '@/modules/family/models/KidModel';

export default class RegistrationOrganizationPivot extends Model {
    public type = 'registration/registrations_organizations_pivot';

    public attributes: {
        id: string,
        account_id: string | undefined,
        organization_id: string | undefined,
        registration_id: string | undefined,
        kid_id: string | null,
        rejected: boolean | undefined,
        contacted: boolean | undefined,
    } = {
            id: this.uuid(),
            account_id: undefined,
            organization_id: undefined,
            registration_id: undefined,
            kid_id: null,
            rejected: undefined,
            contacted: undefined,
        };

    organization() {
        return this.belongsTo('organization', OrganizationModel, 'organization_id');
    }

    registration() {
        return this.belongsTo('registration', RegistrationModel, 'registration_id');
    }

    kid() {
        return this.belongsTo('kid', KidModel, 'kid_id');
    }
}
