<template>
    <div v-if="allows('support')">
        <label v-if="title">{{ title }}</label>
        <h6 class="h6 tw-font-display">
            <span
                class="tw-cursor-pointer"
                @click="showSupport = !showSupport"
            >
                {{ name }} : {{ ressource.id }}
            </span>
            <div
                v-if="showSupport"
                class="tw-text-left tw-text-blue-700 tw-bg-blue-100 tw-rounded-lg tw-py-2 tw-px-3 tw-mt-2 tw-font-sans tw-leading-loose tw-text-base"
            >
                <CLabel>{{ __('app:search_a_key_colon') }}</CLabel>
                <CInput
                    v-model="search"
                    class="tw-mb-3 tw-w-2/3"
                    size="sm"
                    type="search"
                />
                <hr class="hr tw-my-2">
                <div
                    v-for="(obj, i) in filteredObj"
                    :key="'obj' + i"
                    class="tw-flex tw-text-sm"
                >
                    <div class="tw-mr-1">
                        {{ obj.key + ' : ' }}
                    </div>
                    <div
                        v-if="obj.editing"
                        class="tw-flex"
                    >
                        <CButtonGroup>
                            <CInput
                                v-model="obj.value"
                                size="sm"
                            />
                            <CButton
                                size="sm"
                                variant="light"
                                @click="obj.editing = false;"
                            >
                                <i
                                    aria-hidden="true"
                                    class="fa fa-times tw-px-1"
                                />
                            </CButton>
                        </CButtonGroup>
                    </div>
                    <div
                        v-else-if="obj.editable"
                        class="tw-font-semibold tw-text-green-700 tw-bg-green-100 tw-rounded tw-px-2 hover:tw-bg-green-300 tw-cursor-pointer"
                        @click="obj.editing = true;"
                    >
                        {{ obj.value }}
                    </div>
                    <div
                        v-else
                        class="tw-font-semibold"
                    >
                        {{ obj.value }}
                    </div>
                </div>
            </div>
        </h6>
    </div>
</template>

<script lang="ts">
    import {computed, defineComponent, onMounted, ref, type Ref, watch} from 'vue';
    import _filter from 'lodash-es/filter';
    import useAbility from '@/modules/app/composables/useAbility';

    export default defineComponent({
        props: {
            name: {
                type: String,
                default: 'ID',
            },
            ressource: {
                type: Object,
                required: true,
            },
            title: {
                type: String,
                required: false,
            },
            editableFields: {
                type: Array,
                default: () => [],
            },
        },
        setup(props) {
            const {can, allows} = useAbility();

            const ressourceProps: Ref<any[]> = ref([]);
            const search = ref('');
            const showSupport = ref(false);

            const filteredObj = computed(() => {
                if (search.value) {
                    return _filter(ressourceProps.value, item => {
                        const searchResult = search.value.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');

                        return item.key && item.key.indexOf(searchResult) > -1;
                    });
                } else {
                    return ressourceProps.value;
                }
            });

            watch(() => props.ressource, () => {
                init();
            }, {deep: true});

            onMounted(() => {
                init();
            });

            function init() {
                ressourceProps.value = [];

                // Flattend all props
                const newObj = flatten(props.ressource);

                // Create new array with key / value
                Object.entries(newObj).forEach(key => {
                    ressourceProps.value.push({
                        key: key[0],
                        value: key[1],
                        editing: false,
                        editable: props.editableFields.includes(key[0]),
                    });
                });
            }

            function flatten(obj) {
                const newObj = {};
                dive('', obj, newObj);

                return newObj;
            }

            function dive(currentKey, into, target) {
                for (const i in into) {
                    // eslint-disable-next-line no-prototype-builtins
                    if (into.hasOwnProperty(i)) {
                        let newKey = i;
                        const newVal = into[i];

                        if (currentKey.length > 0) {
                            newKey = currentKey + '.' + i;
                        }

                        if (typeof newVal === 'object') {
                            dive(newKey, newVal, target);
                        } else {
                            target[newKey] = newVal;
                        }
                    }
                }
            }

            return {
                can,
                allows,
                ressourceProps,
                search,
                showSupport,
                filteredObj,
            };
        },
    });
</script>
