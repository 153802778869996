import {StoragePath} from '@/modules/magic-index/utils/serializers/StoragePath';
import BooleanSerializer from '@/modules/magic-index/utils/serializers/BooleanSerializer';
import ArrayOfStringSerializer from '@/modules/magic-index/utils/serializers/ArrayOfStringSerializer';
import TextSerializer from '@/modules/magic-index/utils/serializers/TextSerializer';
import {isString} from 'lodash-es';
import TagModel from '@/modules/tag/models/TagModel';
import ModelSerializer from '@/modules/magic-index/utils/serializers/ModelSerializer';
import type {ModelCollection} from '@meekohq/lumos';

export default class MigrateKidIndexFiltersStorage {
    public async migrateTags(): Promise<void> {
        const tags = localStorage.getItem('tags:kids:index');
        localStorage.removeItem('tags:kids:index');

        if (!tags) {
            return;
        }

        localStorage.setItem('tags:kids:planning', tags);

        if (tags === '[]') {
            // Empty array is the without tag state in the new system
            localStorage.setItem(StoragePath.filtersKidsIndexWithoutTag, BooleanSerializer.write(true) as string);

            return;
        }

        const parsed = this.tryToParseArray(tags);

        if (parsed.length > 0) {
            try {
                const tagModels = (await this.getTagModels(parsed)).toArray();
                localStorage.setItem(StoragePath.filtersKidsIndexTags, ModelSerializer.write(tagModels) as string);
            } catch (e) {
                // Do nothing
            }
        }
    }

    public migrateContracts(): void {
        const contracts = localStorage.getItem('kids:index:contract');
        localStorage.removeItem('kids:index:contract');

        if (!contracts) {
            return;
        }

        const parsed = this.tryToParseArray(contracts);

        if (parsed.length > 0) {
            localStorage.setItem(StoragePath.filtersKidsIndexContractStates, ArrayOfStringSerializer.write(parsed) as string);
        }
    }

    public migrateGroups(): void {
        const groups = localStorage.getItem('kids:index:group');
        localStorage.removeItem('kids:index:group');

        try {
            if (!groups) {
                return;
            }

            const parsed = JSON.parse(groups);
            if (isString(parsed)) {
                localStorage.setItem(StoragePath.filtersKidsIndexSelectedGroup, TextSerializer.write(parsed) as string);
            }
        } catch (e) {
            // Do nothing
        }
    }

    public tryToParseArray(value: string): string[] {
        try {
            const parsed = JSON.parse(value);

            // Check if the value is stored as an array
            if (Array.isArray(parsed)) {
                return parsed;
            }
        } catch (e) {
            return [];
        }

        return [];
    }

    public async getTagModels(ids: string[]): Promise<ModelCollection<TagModel>> {
        return TagModel.query()
            .whereIn('id', ids)
            .get();
    }
}
