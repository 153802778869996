<template>
    <div class="CustomersListHeader">
        <div class="CustomersListHeader__container">
            <div :class="showOrganization ? 'CustomersListHeader__column-name' : 'CustomersListHeader__column-name--expanded'">
                <MagicIndexHeaderColumnTitle :title="__('common:name')"/>
            </div>
            <div class="CustomersListHeader__column-status">
                <MagicIndexHeaderColumnTitle :title="__('billing_customer:status')"/>
            </div>
            <div class="CustomersListHeader__column-account-balance">
                <MagicIndexHeaderColumnTitle :title="__('common:account_balance')"/>
            </div>
            <div class="CustomersListHeader__column-last-activity">
                <MagicIndexHeaderColumnTitle :title="__('billing_customer:last_activity')"/>
            </div>
            <div
                v-if="showOrganization"
                class="CustomersListHeader__column-organization"
            >
                <MagicIndexHeaderColumnTitle :title="__('common:organization_one')"/>
            </div>
            <div class="CustomersListHeader__column-tags">
                <MagicIndexHeaderColumnTitle :title="__('common:tags')"/>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import {defineComponent} from 'vue';
    import MagicIndexHeaderColumnTitle from '@/modules/magic-index/components/atoms/MagicIndexHeaderColumnTitle.vue';

    export default defineComponent({
        components: {
            MagicIndexHeaderColumnTitle,
        },
        props: {
            showOrganization: {
                type: Boolean,
                required: true,
            },
        },
    });
</script>

<style lang="scss" scoped>
    .CustomersListHeader {
        container-type: inline-size;
        container-name: CustomersListHeader;
    }

    .CustomersListHeader__container {
        @apply tw-hidden;

        @container CustomersListHeader (min-width: 48rem) {
            @apply tw-grid tw-grid-cols-24 tw-gap-3;
        }
    }

    .CustomersListHeader__column-name {
        @apply tw-col-span-6;
    }

    .CustomersListHeader__column-name--expanded {
        @apply tw-col-span-10;
    }

    .CustomersListHeader__column-status {
        @apply tw-col-span-4;
    }

    .CustomersListHeader__column-account-balance {
        @apply tw-col-span-3;
    }

    .CustomersListHeader__column-last-activity {
        @apply tw-col-span-3;
    }

    .CustomersListHeader__column-organization {
        @apply tw-col-span-4;
    }

    .CustomersListHeader__column-tags {
        @apply tw-col-span-3;
    }
</style>
