<template>
    <div>
        <CButton
            class="tw-w-full"
            @click="$refs.eventFilter.toggle()"
        >
            <i
                v-if="forecastOnly"
                class="fad fa-calendar tw-mr-1"
            />
            <i
                v-else-if="realOnly"
                class="fad fa-calendar-check tw-mr-1"
            />
            <i
                v-else
                class="fad fa-calendar-alt tw-mr-1"
            />
            {{ filterText }}
            <i
                aria-hidden="true"
                class="fa fa-caret-down tw-ml-1"
            />
        </CButton>
        <CDropdown
            ref="eventFilter"
            legacy-mode
        >
            <CList class="tw-cursor-pointer">
                <CText
                    class="tw-text-gray-500 tw-uppercase tw-tracking-wider tw-px-1 tw-mb-1"
                    font-size="xs"
                >
                    {{ __('common:display') }}
                </CText>

                <CListRow
                    class="tw-flex tw-items-center tw-text-gray-500"
                    :class="{
                        'tw-text-gray-900': filters.includes('forecast'),
                    }"
                    @click="updateFilter('forecast')"
                >
                    <i
                        aria-hidden="true"
                        class="fad fa-calendar tw-mr-2"
                    />
                    <div class="tw-mr-2">
                        {{ __('common:forecasted_male_one') }}
                    </div>
                    <i
                        v-if="filters.includes('forecast')"
                        class="fa fa-check tw-ml-auto"
                    />
                </CListRow>
                <CListRow
                    class="tw-flex tw-items-center tw-text-gray-500"
                    :class="{
                        'tw-text-gray-900': filters.includes('real'),
                    }"
                    @click="updateFilter('real')"
                >
                    <i
                        aria-hidden="true"
                        class="fad fa-calendar-check tw-mr-2"
                    />
                    <div class="tw-mr-2">
                        {{ __('common:real_one') }}
                    </div>
                    <i
                        v-if="filters.includes('real')"
                        class="fa fa-check tw-ml-auto"
                    />
                </CListRow>

                <CText
                    class="tw-text-gray-500 tw-uppercase tw-tracking-wider tw-px-1 tw-pt-2"
                    font-size="xs"
                >
                    {{ __('common:supervision') }}
                </CText>
                <CListRow
                    class="tw-flex tw-items-center tw-text-gray-500"
                    :class="{ 'tw-text-gray-900': filters.includes('supervise') }"
                    @click="updateFilter('supervise')"
                >
                    <div class="tw-mr-2">
                        {{ __('hr_calendar:impact_supervision') }}
                    </div>
                    <i
                        v-if="filters.includes('supervise')"
                        class="fa fa-check tw-ml-auto"
                    />
                </CListRow>
                <CListRow
                    class="tw-flex tw-items-center tw-text-gray-500"
                    :class="{ 'tw-text-gray-900': filters.includes('unsupervise') }"
                    @click="updateFilter('unsupervise')"
                >
                    <div class="tw-mr-2">
                        {{ __('hr_calendar:doesnt_impact_supervision') }}
                    </div>
                    <i
                        v-if="filters.includes('unsupervise')"
                        class="fa fa-check tw-ml-auto"
                    />
                </CListRow>
            </CList>
        </CDropdown>
    </div>
</template>

<script lang="ts">
    import {computed, defineComponent, onBeforeMount, ref} from 'vue';
    import __ from '@/modules/app/utils/i18n-facade';

    export default defineComponent({
        props: {
            eventFilters: {
                type: Array,
            },
            defaultText: {
                type: String,
                default: 'Filtrer',
            },
            localStorageKey: {
                type: String,
                default: 'events',
            },
        },
        setup(props, {emit}) {
            const prefix = 'calendar:staffs:filter:';
            const filters = ref<string[]>(JSON.parse(localStorage.getItem(prefix + props.localStorageKey) as string) || []);

            onBeforeMount(() => {
                if (!localStorage.getItem(prefix + props.localStorageKey)) {
                    filters.value = ['forecast', 'real', 'supervise', 'unsupervise'];
                    localStorage.setItem(prefix + props.localStorageKey, JSON.stringify(filters.value));
                }
                emit('update:eventFilters', filters.value);
            });

            const forecastOnly = computed(() => {
                return filters.value.includes('forecast') && !filters.value.includes('real');
            });
            const realOnly = computed(() => {
                return !filters.value.includes('forecast') && filters.value.includes('real');
            });

            const filterText = computed(() => {
                let txt = props.defaultText;
                if (forecastOnly.value) {
                    txt = __('common:forecasted_male_one');
                } else if (realOnly.value) {
                    txt = __('common:real_one');
                }

                return txt;
            });

            function updateFilter(val: string) {
                if (filters.value.includes(val)) {
                    filters.value = filters.value.filter(item => item !== val);
                } else {
                    filters.value.push(val);
                }
                localStorage.setItem(prefix + props.localStorageKey, JSON.stringify(filters.value));
                emit('update:eventFilters', filters.value);
            }

            return {
                filters,
                forecastOnly,
                realOnly,
                filterText,
                updateFilter,
            };
        },
    });
</script>
