import {Model} from '@meekohq/lumos';

export default class DocumentOrganizationPivot extends Model {
    public type = 'media/documents_organizations_pivot';

    public attributes: {
        id: string;
        account_id: string | undefined;
        organization_id: string | undefined;
        document_id: string | undefined;
        parents_access: boolean | undefined;
        staffs_access: boolean | undefined;
        web_access: boolean | undefined;
    } = {
            id: this.uuid(),
            account_id: undefined,
            organization_id: undefined,
            document_id: undefined,
            parents_access: undefined,
            staffs_access: undefined,
            web_access: undefined,
        };
}
