import {ServiceProvider} from '@meekohq/lumos';
import CustomerModel from '@/modules/cashier/models/CustomerModel';
import ContactModel from '@/modules/cashier/models/ContactModel';
import AddressModel from '@/modules/cashier/models/AddressModel';
import OrderModel from '@/modules/cashier/models/OrderModel';
import TenantModel from '@/modules/cashier/models/TenantModel';
import SubscriptionModel from '@/modules/cashier/models/SubscriptionModel';
import EndpointModel from '@/modules/cashier/models/EndpointModel';
import TaxModel from '@/modules/cashier/models/TaxModel';
import CurrencyModel from '@/modules/cashier/models/CurrencyModel';
import NumberFormatModel from '@/modules/cashier/models/NumberFormatModel';
import PaymentMethodModel from '@/modules/cashier/models/PaymentMethodModel';
import PaymentSchedulerModel from '@/modules/cashier/models/PaymentSchedulerModel';
import PaymentSchedulerRuleModel from '@/modules/cashier/models/PaymentSchedulerRuleModel';
import UnitModel from '@/modules/cashier/models/UnitModel';
import AllocationModel from '@/modules/cashier/models/AllocationModel';
import TransactionModel from '@/modules/cashier/transaction/domain/TransactionModel';
import PaymentModel from '@/modules/cashier/models/PaymentModel';
import InvoiceModel from '@/modules/cashier/models/InvoiceModel';
import {Types} from '@/types';
import {TransactionManagerService} from '@/modules/cashier/transaction/application/TransactionManagerService';
import type {
    PaginateTransactionsUseCase,
} from '@/modules/cashier/transaction/application/use-cases/PaginateTransactionsUseCase';
import {TransactionRepositoryAdapter} from '@/modules/cashier/transaction/infrastructure/TransactionRepositoryAdapter';
import type {
    MassDeleteTransactionsByFiltersUseCase,
} from '@/modules/cashier/transaction/application/use-cases/MassDeleteTransactionsByFiltersUseCase';
import type {
    RejectAndRestoreTransactionsUseCase,
} from '@/modules/cashier/transaction/application/use-cases/RejectAndRestoreTransactionsUseCase';
import type {
    SumTransactionsByCurrenciesUseCase,
} from '@/modules/cashier/transaction/application/use-cases/SumTransactionsByCurrenciesUseCase';
import {MQLAllocationAggregateRepository} from '@/modules/cashier/payment/infrastructure/MQLAllocationAggregateRepository';
import type {DetachCreditNoteToInvoiceUseCase} from '@/modules/cashier/payment/application/DetachCreditNoteToInvoiceUseCase';
import {DetachCreditNoteToInvoiceInteractor} from '@/modules/cashier/payment/application/DetachCreditNoteToInvoiceInteractor';
import {
    MQLCreditNoteAllocationAggregateRepository,
} from '@/modules/cashier/payment/infrastructure/MQLCreditNoteAllocationAggregateRepository';
import {
    MQLInvoiceAllocationAggregateRepository,
} from '@/modules/cashier/payment/infrastructure/MQLInvoiceAllocationAggregateRepository';
import type {SaveAllocationAggregateUseCase} from '@/modules/cashier/payment/application/SaveAllocationAggregateUseCase';
import {SaveAllocationAggregateInteractor} from '@/modules/cashier/payment/application/SaveAllocationAggregateInteractor';
import type {GetAllocationsForInvoiceUseCase} from '@/modules/cashier/payment/application/GetAllocationsForInvoiceUseCase';
import {
    GetAllocationsForInvoiceInteractor,
} from '@/modules/cashier/payment/application/GetAllocationsForInvoiceInteractor';
import type {
    GetAllocationsForCreditNoteUseCase,
} from '@/modules/cashier/payment/application/GetAllocationsForCreditNoteUseCase';
import {
    GetAllocationsForCreditNoteInteractor,
} from '@/modules/cashier/payment/application/GetAllocationsForCreditNoteInteractor';
import type {
    GetAllocationsSuggestionsForPaymentUseCase,
} from '@/modules/cashier/payment/application/GetAllocationsSuggestionsForPaymentUseCase';
import {
    GetAllocationsSuggestionsForPaymentInteractor,
} from '@/modules/cashier/payment/application/GetAllocationsSuggestionsForPaymentInteractor';

export default class CashierServiceProvider extends ServiceProvider {
    public boot() {
        this.loadModelsFrom([
            CustomerModel,
            ContactModel,
            AddressModel,
            OrderModel,
            TenantModel,
            SubscriptionModel,
            EndpointModel,
            TaxModel,
            CurrencyModel,
            NumberFormatModel,
            PaymentMethodModel,
            PaymentSchedulerModel,
            PaymentSchedulerRuleModel,
            UnitModel,
            AllocationModel,
            TransactionModel,
            PaymentModel,
            InvoiceModel,
        ]);
    }

    public register() {
        this.app.bind<SaveAllocationAggregateUseCase>(Types.AllocationAggregateUseCase).toCallback(() => {
            return new SaveAllocationAggregateInteractor(
                new MQLAllocationAggregateRepository(),
            );
        });

        this.app.bind<GetAllocationsSuggestionsForPaymentUseCase>(Types.GetAllocationsSuggestionsForPaymentUseCase).toCallback(() => {
            return new GetAllocationsSuggestionsForPaymentInteractor(
                new MQLInvoiceAllocationAggregateRepository(),
                new MQLCreditNoteAllocationAggregateRepository(),
            );
        });

        this.app.bind<GetAllocationsForInvoiceUseCase>(Types.GetAllocationsForInvoiceUseCase).toCallback(() => {
            return new GetAllocationsForInvoiceInteractor(
                new MQLInvoiceAllocationAggregateRepository(),
            );
        });

        this.app.bind<GetAllocationsForCreditNoteUseCase>(Types.GetAllocationsForCreditNoteUseCase).toCallback(() => {
            return new GetAllocationsForCreditNoteInteractor(
                new MQLCreditNoteAllocationAggregateRepository(),
            );
        });

        this.app.bind<DetachCreditNoteToInvoiceUseCase>(Types.DetachCreditNoteUseCase).toCallback(() => {
            return new DetachCreditNoteToInvoiceInteractor();
        });

        this.app.bind<MassDeleteTransactionsByFiltersUseCase>(Types.MassDeleteTransactionsByFiltersUseCase).toCallback(() => {
            return new TransactionManagerService(new TransactionRepositoryAdapter());
        });

        this.app.bind<PaginateTransactionsUseCase>(Types.PaginateTransactionUseCase).toCallback(() => {
            return new TransactionManagerService(new TransactionRepositoryAdapter());
        });

        this.app.bind<RejectAndRestoreTransactionsUseCase>(Types.RejectAndRestoreTransactionsUseCase).toCallback(() => {
            return new TransactionManagerService(new TransactionRepositoryAdapter());
        });

        this.app.bind<SumTransactionsByCurrenciesUseCase>(Types.SumTransactionsByCurrenciesUseCase).toCallback(() => {
            return new TransactionManagerService(new TransactionRepositoryAdapter());
        });
    }
}
