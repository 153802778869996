<template>
    <CHStack
        gap="3"
        wrap
    >
        <HalfDayFilter
            :day-separator="daySeparatorFilter"
            :halfday-time="halfdayTimeFilter"
            @update:daySeparator="$emit('update-day-separator-filter', $event)"
            @update:halfdayTime="$emit('update-halfday-time-filter', $event)"
        />
        <KidPlanningTypeFilter
            :value="planningTypeFilter"
            @input="$emit('update-planning-type-filter', $event)"
        />
        <TagsResourceFinder
            button-variant="light"
            has-unselect
            multi
            only-active-organization
            :search-bar="false"
            :value="tagFilter"
            @input="$emit('update-tag-filter', $event)"
        />
        <KidGroupFinder
            button-variant="light"
            has-unselect
            :only-active-organization="true"
            :search-bar="false"
            :value="groupFilter"
            @input="$emit('update-group-filter', $event)"
        >
            <template #button-text-empty>
                {{ __('common:group_one') }}
            </template>
        </KidGroupFinder>
        <CHStack>
            <CSwitch
                :value="supervisionFilter"
                @input="$emit('update-supervision-filter', $event)"
            />
            <CLabel>{{ __('planning:show_frame') }}</CLabel>
        </CHStack>
    </CHStack>
</template>

<script lang="ts">
    import type {PropType} from 'vue';
    import {defineComponent} from 'vue';
    import type SimulationListModel from '@/modules/planning/models/SimulationListModel';
    import type KidsGroupModel from '@/modules/organization/models/KidsGroupModel';
    import type TagModel from '@/modules/tag/models/TagModel';
    import TagsResourceFinder from '@/modules/tag/components/TagsResourceFinder.vue';
    import KidGroupFinder from '@/modules/organization/components/KidGroupFinder.vue';
    import KidPlanningTypeFilter from '@/modules/planning/components/simulation/Filter/KidPlanningTypeFilter.vue';
    import HalfDayFilter from '@/modules/planning/components/simulation/Filter/HalfDayFilter.vue';

    export default defineComponent({
        components: {KidGroupFinder, KidPlanningTypeFilter, TagsResourceFinder, HalfDayFilter},
        props: {
            simulation: {
                type: Object as PropType<SimulationListModel>,
                required: true,
            },
            groupFilter: {
                type: Object as PropType<KidsGroupModel>,
                default: () => null,
            },
            planningTypeFilter: {
                type: Array as unknown as PropType<String[]>,
                required: true,
            },
            tagFilter: {
                type: Array as unknown as PropType<TagModel[]>,
                required: true,
            },
            supervisionFilter: {
                type: Boolean,
                default: () => false,
            },
            daySeparatorFilter: {
                type: String as unknown as PropType<'day' | 'halfday'>,
                required: true,
            },
            halfdayTimeFilter: {
                type: String,
                required: true,
            },
        },
    });
</script>
