<template>
    <CForm>
        <CFormTwoColumns>
            <CFormGroup>
                <CLabel>{{ __('common:first_name') }}*</CLabel>
                <CInput
                    v-model="newKid.first_name"
                    :placeholder="__('common:first_name')"
                />
                <CFormErrorMessageList :errors="collect(errors.first_name)"/>
            </CFormGroup>
            <CFormGroup>
                <CLabel>{{ __('common:last_name') }}*</CLabel>
                <CInput
                    v-model="newKid.last_name"
                    :placeholder="__('common:last_name')"
                />
                <CFormErrorMessageList :errors="collect(errors.last_name)"/>
            </CFormGroup>
        </CFormTwoColumns>
        <CFormTwoColumns>
            <CFormGroup>
                <CLabel>
                    <FontAwesomeIcon
                        class="tw-mr-1 tw-text-primary-500"
                        icon="fa fa-venus-mars"
                    />
                    {{ __('common:gender_one') }}
                </CLabel>
                <CFormSelect
                    v-model="newKid.gender"
                    :options="[{gender: 'male', description: __('common:gender.boy')},{gender: 'female', description: __('common:gender.girl')}]"
                    :search-bar="false"
                    text-path="description"
                    value-path="gender"
                />
                <CFormErrorMessageList :errors="collect(errors.gender)"/>
            </CFormGroup>
            <CFormGroup>
                <CLabel>
                    <FontAwesomeIcon
                        class="tw-mr-1 tw-text-primary-500"
                        icon="fa fa-users"
                    />
                    {{ __('common:group_one') }}
                </CLabel>
                <CFormSelect
                    v-model="newKid.group_id"
                    :options="groups"
                    :search-bar="false"
                    text-path="name"
                    value-path="id"
                />
                <CFormErrorMessageList :errors="collect(errors.group_id)"/>
            </CFormGroup>
        </CFormTwoColumns>
        <CFormTwoColumns>
            <CFormGroup>
                <CLabel>
                    <FontAwesomeIcon
                        class="tw-mr-1 tw-text-primary-500"
                        icon="fa fa-birthday-cake"
                    />
                    {{ __('common:birthdate') }}*
                </CLabel>
                <CFormDatepicker
                    v-model="newKid.birthdate"
                    format="YYYY-MM-DD"
                />
                <CFormErrorMessageList :errors="collect(errors.birthdate)"/>
            </CFormGroup>
            <CFormGroup>
                <CLabel>
                    <FontAwesomeIcon
                        class="tw-mr-1 tw-text-primary-500"
                        icon="fa fa-tint"
                    />
                    {{ __('family_kid:hygiene') }}
                </CLabel>
                <CFormSelect
                    v-model="newKid.hygiene"
                    :options="hygieneOptions"
                    :search-bar="false"
                    text-path="description"
                    value-path="hygiene"
                />
                <CFormErrorMessageList :errors="collect(errors.hygiene)"/>
            </CFormGroup>
        </CFormTwoColumns>
        <CFormTwoColumns>
            <CFormGroup>
                <CLabel>
                    <FontAwesomeIcon
                        class="tw-mr-1 tw-text-primary-500"
                        icon="fa fa-clock"
                    />
                    {{ __('common:clockin_code') }}
                </CLabel>
                <CInput
                    v-model="newKid.attendance_code"
                    :placeholder="__('family_kid:kiosk_mode')"
                    type="text"
                />
                <CFormErrorMessageList :errors="collect(errors.custom)"/>
            </CFormGroup>
            <CFormGroup>
                <CLabel>
                    <FontAwesomeIcon
                        class="tw-mr-1 tw-text-primary-500"
                        icon="fa fa-user"
                    />
                    {{ __('family_kid:identification_number') }}
                </CLabel>
                <CInput
                    v-model="newKid.no"
                    :placeholder="__('family_kid:identification_number')"
                    type="text"
                />
                <CFormErrorMessageList :errors="collect(errors.no)"/>
            </CFormGroup>
        </CFormTwoColumns>
        <CFormGroup>
            <CLabel>
                <FontAwesomeIcon
                    class="tw-mr-1 tw-text-primary-500"
                    icon="fa fa-note"
                />
                {{ __('common:note_one') }}
            </CLabel>
            <CFormTextArea
                v-model="newKid.note"
                :placeholder="__('common:note_one')"
                rows="2"
            />
            <CFormErrorMessageList :errors="collect(errors.note)"/>
        </CFormGroup>
    </CForm>
</template>

<script lang="ts">
    import {defineComponent} from 'vue';
    import {collect} from '@meekohq/lumos';
    import __ from '@/modules/app/utils/i18n-facade';

    export default defineComponent({
        props: {
            errors: {
                type: Array,
                required: true,
            },
            newKid: {
                type: Object,
                required: true,
            },
            groups: {
                type: Array,
                required: true,
            },
        },
        setup() {
            const hygieneOptions = [
                {
                    hygiene: 'baby',
                    description: __('common:hygiene_types.diaper'),
                },
                {
                    hygiene: 'potty',
                    description: __('family_kid:potty'),
                },
                {
                    hygiene: 'mixed',
                    description: __('common:learning'),
                },
            ];

            return {
                collect,
                hygieneOptions,
            };
        },
    });
</script>
