<template>
    <div class="tw-bg-gray-300 tw-h-hr tw-my-4 tw-rounded-full"/>
</template>

<script>
    export default {
        name: 'Divider',
    };
</script>

