<template>
    <CModal
        ref="modal"
        :header-title="__('common:statistic_other') "
        size="4xl"
    >
        <StaffStatistics :staff="staff"/>
    </CModal>
</template>

<script lang="ts">
    import type {PropType} from 'vue';
    import {defineComponent} from 'vue';
    import type StaffModel from '@/modules/human-resources/models/StaffModel';
    import StaffStatistics from '@/modules/human-resources/components/statistics/StaffStatistics.vue';

    export default defineComponent({
        components: {
            StaffStatistics,
        },
        props: {
            staff: {
                type: Object as PropType<StaffModel>,
                required: true,
            },
        },
    });
</script>
