<template>
    <ResourceFinder
        :builder="queryBuilder"
        :button-class="buttonClass"
        :button-variant="buttonVariant"
        :multi="multi"
        :multi-minimum="multiMinimum"
        :value="value"
        :wrapper-class="wrapperClass"
        @fallback="onFallback"
        @input="onChange"
        @search="search = $event"
    >
        <template #button-text-empty>
            <slot name="button-text-empty"/>
        </template>

        <template #button-text="{value}">
            {{ value.attributes.name }}
        </template>

        <template #button-text-multi="{values, langVar}">
            <slot
                :lang-var="langVar"
                name="button-text-multi"
                :values="values"
            >
                {{ __('components:count_item', {count: values.length - 1, value: values[0].attributes.name}) }}
            </slot>
        </template>

        <template #default="{option, searchValue}">
            <div class="tw-flex tw-pr-4 tw-items-center">
                <i
                    v-if="option.attributes.icon"
                    aria-hidden="true"
                    :class="[option.attributes.icon]"
                />
                <div
                    class="tw-truncate"
                    v-html="highlight(searchValue, option.attributes.name)"
                />
            </div>
        </template>
    </ResourceFinder>
</template>

<script lang="ts">
    import type {PropType} from 'vue';
    import {computed, defineComponent, ref} from 'vue';
    import type {QueryBuilder} from '@meekohq/lumos';
    import {type Model} from '@meekohq/lumos';
    import ResourceFinder from '@/modules/app/components/resource/organisms/ResourceFinder.vue';
    import useHighlighter from '@/modules/legacy/helpers/useHighlighter';
    import RequestTypeModel from '@/modules/request/models/RequestTypeModel';

    export default defineComponent({
        components: {ResourceFinder},
        props: {
            // !!! DO NOT EDIT !!!
            value: {type: [Object, Array] as PropType<Model | Model[]>},
            injectQuery: {type: Object as PropType<QueryBuilder<any>>},
            hasUnselect: {type: Boolean, default: false},
            multi: {type: Boolean, default: false},
            multiMinimum: {type: [Number, String], default: 0},
            wrapperClass: {},
            buttonClass: {},
            buttonVariant: {},
        },
        emits: ['input'],
        setup(props, {emit}) {
            // !!! START: DO NOT EDIT !!
            const search = ref('');
            const onChange = function(value) {
                emit('input', value);
            };
            // !!! END: DO NOT EDIT !!

            const queryBuilder = computed(() => {
                const builder = RequestTypeModel.query().orderBy('name')
                    .whereLike('name', `*${search.value}*`);

                if (props.injectQuery) {
                    builder.inject(props.injectQuery);
                }

                return builder;
            });

            const onFallback = function() {
                //
            };

            return {
                search,
                onChange,
                onFallback,
                queryBuilder,
                ...useHighlighter(),
            };
        },
    });
</script>
