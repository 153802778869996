<template>
    <MDropdown>
        <MButton
            icon-menu
            :size="size"
        >
            <template #left-icons>
                <FontAwesomeIcon :icon="filters.queryDirection === 'asc' ? 'fa fa-sort-alpha-down' : 'fa fa-sort-alpha-up'"/>
            </template>
            <template v-for="option in options">
                <template v-if="filters.orderBy === option.value">
                    {{ option.label }}
                </template>
            </template>
        </MButton>
        <template #content>
            <CDropdownMenuItem
                class="tw-w-full"
                icon="fa fa-sort-alpha-down"
                @click="filters.queryDirection = 'asc'"
            >
                {{ __('common:ascending') }}
            </CDropdownMenuItem>
            <CDropdownMenuItem
                class="tw-w-full"
                icon="fa fa-sort-alpha-up"
                @click="filters.queryDirection = 'desc'"
            >
                {{ __('common:descending') }}
            </CDropdownMenuItem>
            <CDropdownMenuDivider class="tw-my-2"/>
            <template v-for="option in options">
                <CDropdownMenuItem
                    :key="option.value"
                    class="tw-w-full"
                    :icon="option.icon"
                    @click="filters.orderBy = option.value"
                >
                    {{ option.sortLabel }}
                </CDropdownMenuItem>
            </template>
        </template>
    </MDropdown>
</template>

<script lang="ts">
    import type {PropType} from 'vue';
    import {defineComponent} from 'vue';
    import type {QueryBuilderOrderByDirectionType} from '@meekohq/lumos';

    export default defineComponent({
        props: {
            filters: {
                type: Object as PropType<{ orderBy: string, queryDirection: QueryBuilderOrderByDirectionType }>,
                required: true,
            },
            size: {type: String, default: 'sm'},
            options: {
                type: Array as PropType<Array<{ value: string, label: string, sortLabel: string, icon: string }>>,
                required: true,
            },
        },
    });
</script>
