<template>
    <div class="CDropdownMenuDivider"/>
</template>

<script lang="ts">
    import {defineComponent} from 'vue';

    export default defineComponent({
        components: {},
        props: {},
        setup() {
            return {};
        },
    });
</script>

<style
    lang="scss"
    scoped
>
    .CDropdownMenuDivider {
        @apply tw-bg-gray-200 tw-h-px tw--mx-1;
    }
</style>
