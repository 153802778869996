<script setup lang="ts">
    import {computed, inject} from 'vue';
    import {keyMStepPanelState} from '@/modules/meeko-ui/components/MStepPanel/useMStepPanelState';

    const props = withDefaults(defineProps<{
        step: number;
        isCompleted?: boolean;
        hasError?: boolean;
    }>(), {
        isCompleted: false,
        hasError: false,
    });

    const state = inject(keyMStepPanelState);
    if (!state) {
        throw new Error('keyMStepPanelState must be provided');
    }

    const {activeStep} = state;

    const isActive = computed(() => activeStep.value === props.step);
    const isBeforeActiveStep = computed(() => activeStep.value! > props.step);
</script>

<template>
    <div
        class="MStepPanelHeaderItemCircle"
        :class="{
            'MStepPanelHeaderItemCircle--is-completed': isCompleted,
            'MStepPanelHeaderItemCircle--is-before': isBeforeActiveStep,
            'MStepPanelHeaderItemCircle--has-error': hasError,
            'MStepPanelHeaderItemCircle--is-active': isActive,
        }"
    >
        <FontAwesomeIcon
            v-if="isCompleted"
            class="MStepPanelHeaderItemCircle__icon-success"
            fixed-width
            icon="fa fa-check"
        />
        <FontAwesomeIcon
            v-else-if="hasError"
            class="MStepPanelHeaderItemCircle__icon-error"
            fixed-width
            icon="fa fa-exclamation-triangle"
        />
        <div
            v-else
            class="MStepPanelHeaderItemCircle__content"
            :class="{
                'MStepPanelHeaderItemCircle__content--is-completed-or-before': isCompleted || isBeforeActiveStep,
                'MStepPanelHeaderItemCircle__content--is-active': isActive,
            }"
        >
            <slot name="icon">
                {{ step }}
            </slot>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    .MStepPanelHeaderItemCircle {
        @apply tw-h-10 tw-w-10 tw-flex-shrink-0;
        @apply tw-flex tw-items-center tw-justify-center tw-rounded-full;
        @apply tw-transition-all tw-ease-in-out tw-duration-300;
        @apply tw-border-2 tw-border-gray-300 group-hover:tw-border-gray-400;

        &--is-completed {
            @apply tw-bg-primary-500 group-hover:tw-bg-primary-600 tw-border-none;
        }

        &--is-before {
            @apply tw-border-2 tw-border-gray-300 group-hover:tw-border-gray-400;
        }

        &--has-error {
            @apply tw-bg-danger-500 group-hover:tw-bg-danger-600 tw-border-none;
        }

        &--is-active {
            @apply tw-border-2 tw-border-primary-500 group-hover:tw-border-primary-600;
        }
    }

    .MStepPanelHeaderItemCircle__content {
        @apply tw-text-base tw-font-normal tw-font-display;
        @apply tw-text-gray-500 group-hover:tw-text-gray-600;

        &--is-completed {
            @apply tw-text-white group-hover:tw-text-white;
        }

        &--is-before {
            @apply tw-text-gray-500 group-hover:tw-text-gray-600;
        }

        &--is-active {
            @apply tw-text-primary-500 group-hover:tw-text-primary-600;
        }
    }

    .MStepPanelHeaderItemCircle__icon-success {
        @apply tw-text-xl tw-text-white;
    }

    .MStepPanelHeaderItemCircle__icon-error {
        @apply tw-mb-0.5;
        @apply tw-text-xl tw-text-white;
    }
</style>
