<template>
    <router-link
        class="suggestion"
        :to="to"
    >
        <CHStack
            v-if="userHasManyOrganization"
            align="center"
            class="tw-flex-1 tw-mb-3 tw-text-gray-400"
            gap="1"
        >
            <CText font-size="sm">
                {{ suggestion.bindings.organization_name }}
            </CText>
            <CText font-size="sm">
                -
            </CText>
            <CText font-size="sm">
                {{ suggestion.bindings.organization_city }}
            </CText>
        </CHStack>
        <div class="header tw-flex tw-items-center">
            <LegacyKidAvatar
                class="avatar"
                :kid="{first_name: suggestion.title, gender: suggestion.gender, avatar_url: suggestion.avatar_url}"
            />
            <div
                class="title"
                v-html="highlighted(suggestion.title)"
            />
        </div>
        <div class="additionals">
            <div class="tw-text-sm tw-mt-2">
                <router-link
                    v-if="suggestion.bindings.family_id"
                    class="tw-text-gray-600 hover:tw-text-purple-500 tw-mr-3"
                    :to="{name: 'families.show', params: {nursery: suggestion.bindings.nursery_id, family: suggestion.bindings.family_id}}"
                >
                    <i
                        aria-hidden="true"
                        class="fad fa-address-book mr-1"
                    />{{ __('common:family') }}
                </router-link>
                <router-link
                    class="tw-text-gray-600 hover:tw-text-purple-500 tw-mr-3"
                    :to="{name: 'kids.show', params: {nursery: suggestion.bindings.nursery_id, kid: suggestion.bindings.kid_id}}"
                >
                    <i
                        aria-hidden="true"
                        class="fa fa-child-reaching tw-mr-1"
                    />{{ __('common:summary') }}
                </router-link>
                <router-link
                    v-if="$can('read', 'kids_contracts')"
                    class="tw-text-gray-600 hover:tw-text-purple-500"
                    :to="{name: 'kids.show', params: {nursery: suggestion.bindings.nursery_id, kid: suggestion.bindings.kid_id}, query: {nav: 'contract'}}"
                >
                    <i
                        aria-hidden="true"
                        class="fa fa-handshake tw-mr-1"
                    />{{ __('common:contract_other') }}
                </router-link>
            </div>
        </div>
    </router-link>
</template>

<script>
    import useManager from '@/modules/app/composables/useManager';
    import useHighlighter from '@/modules/legacy/helpers/useHighlighter';
    import LegacyKidAvatar from '@/modules/app/components/atoms/avatars/LegacyKidAvatar.vue';

    export default {
        components: {LegacyKidAvatar},
        props: ['suggestion', 'query'],
        data() {
            return {
                userHasManyOrganization: useManager().hasManyOrganizations.value,
            };
        },
        computed: {
            to() {
                return {
                    name: 'kids.show', params: {
                        nursery: this.suggestion.bindings.nursery_id,
                        kid: this.suggestion.bindings.kid_id,
                    },
                };
            },
        },
        methods: {
            highlighted(value) {
                return useHighlighter().highlight(this.query, value);
            },
        },
    };
</script>
