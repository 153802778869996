import {Model} from '@meekohq/lumos';
import KidModel from '@/modules/family/models/KidModel';
import OrganizationModel from '@/modules/organization/models/OrganizationModel';
import FamilyOrganizationPivot from '@/modules/family/models/FamilyOrganizationPivot';
import MemberModel from '@/modules/family/models/MemberModel';
import FamilyMemberPivot from '@/modules/family/models/FamilyMemberPivot';
import CustomerModel from '@/modules/cashier/models/CustomerModel';
import FamilyCustomerPivot from '@/modules/family/models/FamilyCustomerPivot';

export default class FamilyModel extends Model {
    public type = 'family/families';

    public attributes: {
        id: string;
        account_id: string | undefined;
        name: string | undefined;
    } = {
            id: this.uuid(),
            account_id: undefined,
            name: undefined,
        };

    kids() {
        return this.hasMany('kids', KidModel, 'family_id');
    }

    members() {
        return this.belongsToMany('members', MemberModel, FamilyMemberPivot, 'family_id', 'member_id');
    }

    customers() {
        return this.belongsToMany('customers', CustomerModel, FamilyCustomerPivot, 'family_id', 'customer_id');
    }

    organizations() {
        return this.belongsToMany('organizations', OrganizationModel, FamilyOrganizationPivot, 'family_id', 'organization_id');
    }

    organizationsPivot() {
        return this.hasMany('organizationsPivot', FamilyOrganizationPivot, 'family_id');
    }
}
