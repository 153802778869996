<template>
    <div>
        <CButton
            :disabled="loading"
            :icon-menu="true"
            @click="$refs.dropDown.toggle()"
        >
            <template v-if="selectedOrganizations.isEmpty() || areAllOrganizationsSelected">
                {{ __('components:all_organizations') }}
            </template>
            <template v-else>
                <i
                    aria-hidden="true"
                    class="fa fa-check tw-mr-1"
                />
                {{
                    __('components:count_item', {
                        count: selectedOrganizations.count() - 1,
                        value: selectedOrganizations.first().attributes.name,
                    })
                }}
            </template>
        </CButton>
        <CDropdown
            ref="dropDown"
            legacy-mode
        >
            <CList class="tw-cursor-pointer">
                <CListRow @click="toggleSelectedOrganizations(null)">
                    <i
                        v-if="selectedOrganizations.isEmpty() || areAllOrganizationsSelected"
                        aria-hidden="true"
                        class="fa fa-check tw-mr-1"
                    />
                    {{ __('components:all_organizations') }}
                </CListRow>
                <CListRow
                    v-for="organization in organizationModels"
                    :key="organization.id"
                    @click="toggleSelectedOrganizations(organization)"
                >
                    <i
                        v-if="isOrganizationChecked(organization)"
                        aria-hidden="true"
                        class="fa fa-check tw-mr-2"
                    />
                    <CBadge
                        v-if="organization.attributes.city"
                        class="tw-text-sm tw-font-semibold tw-text-blue-500 tw-whitespace-nowrap tw-mr-1"
                        size="sm"
                        variant="primary"
                    >
                        {{ organization.attributes.city }}
                    </CBadge>
                    {{ organization.attributes.name }}
                </CListRow>
            </CList>
        </CDropdown>
    </div>
</template>

<script lang="ts">
    import {computed, defineComponent, ref, watch} from 'vue';
    import {collect} from '@meekohq/lumos';
    import OrganizationModel from '@/modules/organization/models/OrganizationModel';

    export default defineComponent({
        components: {},
        props: {},
        setup(props, {emit}) {
            const loading = ref(false);
            const organizationModels = ref(collect<OrganizationModel>());
            const selectedOrganizations = ref(collect<OrganizationModel>());

            const fetchData = async function() {
                loading.value = true;
                organizationModels.value = await OrganizationModel.query().scope('userOrganizations').get();
                loading.value = false;
            };

            fetchData();

            // Method to add or remove an organization in the selected organizations collection.
            const toggleSelectedOrganizations = function(organization: OrganizationModel | null) {
                if (organization) {
                    // Match if organization model is already in selectedOrganizations collection.
                    const matchOrganization = selectedOrganizations.value.first(item => item.id === organization.id);

                    if (matchOrganization) {
                        // Delete organization model from selectedOrganizations collection.
                        selectedOrganizations.value = selectedOrganizations.value.reject(item => item.id === organization.id);
                    } else {
                        // Add organization model in selectedOrganizations collection.
                        selectedOrganizations.value.push(organization).unique(item => item.id);
                    }
                } else {
                    // If organization is null, we set the selectedOrganizations collection to organizationModels.
                    selectedOrganizations.value = organizationModels.value;
                }
            };

            const areAllOrganizationsSelected = computed(() => selectedOrganizations.value.count() === organizationModels.value.count());

            const isOrganizationChecked = function(organization: OrganizationModel) {
                return selectedOrganizations.value.contains(item => item.id === organization.id) && !areAllOrganizationsSelected.value;
            };


            watch(selectedOrganizations, value => {
                // If every organization are selected.
                if (areAllOrganizationsSelected.value) {
                    // Reset the selectedOrganizations state to an empty collection.
                    // When selectedOrganizations is empty, every organization are selected.
                    selectedOrganizations.value = collect<OrganizationModel>();
                }
                //Emit selectedOrganizations to fetchSelectedOrganization method in StaffRegisterList.
                emit('selectedOrganizations', value);
            }, {deep: true});

            return {
                loading,
                organizationModels,
                selectedOrganizations,
                toggleSelectedOrganizations,
                isOrganizationChecked,
                areAllOrganizationsSelected,
            };
        },
    });
</script>
