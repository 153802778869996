import {EpochInfo} from '@meekohq/lumos';
import {capitalize} from 'lodash-es';

export default function useHumanizedEpoch() {
    function humanizeShortDay(day: string) {

        const daysShort = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];
        const daysLong = EpochInfo.weekdays();
        const dayIndex = daysShort.indexOf(day);
        let capitalizedDay = day;

        if (dayIndex !== -1) {
            capitalizedDay = capitalize(daysLong[dayIndex]);
        }

        return capitalizedDay;
    }

    return {
        humanizeShortDay,
    };
}

