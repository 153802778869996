<template>
    <CModal
        v-if="editValue"
        ref="modal"
        :header-title="__('registration:planning_modification')"
        size="5xl"
    >
        <template #footer>
            <MButton
                v-if="$can('update', 'registrations')"
                class="ml-auto"
                :label="__('common:actions.save')"
                variant="primary"
                @click="save"
            />
        </template>
        <div class="form-group">
            <div class="form-row">
                <div class="col-12 col-lg-4">
                    <label class="label">{{ __('common:start_contract') }}*</label>
                    <MDatePicker
                        v-model="editValue.contract_started_at"
                        class="tw-w-full"
                        :disabled="!$can('update', 'registrations')"
                        format="yyyy-MM-dd"
                    />
                </div>
                <div class="col-12 col-lg-4">
                    <label class="label">{{ __('common:end_contract') }}*</label>
                    <MDatePicker
                        v-model="editValue.contract_ended_at"
                        class="tw-w-full"
                        :disabled="!$can('update', 'registrations')"
                        format="yyyy-MM-dd"
                    />
                </div>
                <div class="col-12 col-lg-4">
                    <label class="label">{{ __('registration:flexible_planning') }}
                        <MHelpTooltip placement="top">
                            <template #default>
                                {{ __('registration:can_the_planning_be_adjusted') }}
                            </template>
                        </MHelpTooltip>
                    </label>
                    <select
                        v-model="editValue.negotiable"
                        class="form-control form-control-sm"
                        :disabled="!$can('update', 'registrations')"
                        :title="__('registration:flexible_planning')"
                    >
                        <option :value="1">
                            {{ __('common:yes') }}
                        </option>
                        <option :value="0">
                            {{ __('common:no') }}
                        </option>
                    </select>
                </div>
            </div>
        </div>
        <div class="mt-3">
            <h3 class="h3 tw-font-grandhotel text-center">
                {{ __('registration:desired_planning') }}
            </h3>
            <div class="mt-2">
                <div class="text-center small">
                    {{ __('registration:to_modify_a_range_click_on_it') }}
                </div>
                <module-planning
                    :nursery="nursery"
                    :registration="editValue"
                />
            </div>
        </div>
    </CModal>
</template>

<script>
    import _cloneDeep from 'lodash-es/cloneDeep';
    import moment from 'moment';
    import Planning from './Planning.vue';
    import {nursery} from '@/modules/legacy/mixins/nursery';

    export default {
        components: {
            'module-planning': Planning,
        },
        mixins: [nursery],
        props: {
            nursery: {},
            registration: {
                type: Object,
                required: true,
            },
            openingHours: {},
        },
        data: () => ({
            editValue: null,
        }),
        computed: {
            moment() {
                return moment;
            },
        },
        watch: {
            registration(value) {
                this.editValue = _cloneDeep(value);
            },
        },
        mounted() {
            this.editValue = _cloneDeep(this.registration);
        },
        methods: {
            save() {
                this.$emit('registrationUpdated', this.editValue);
            },
        },
    };
</script>

<style
    lang="scss"
    scoped
>
    #edit-registration {
        @media (min-width: 576px) {
            .modal-lg {
                max-width: 80%;
            }
        }
    }
</style>
