<script setup lang="ts">
    import {inject} from 'vue';
    import type MFloatingContext from '@/modules/meeko-ui/components/MFloatingContext';

    const context = inject<ReturnType<typeof MFloatingContext>>('MFloatingContext');

    if (!context) {
        throw new Error('MPopoverPanel must be used inside a MPopover');
    }

    const {
        isOpen,
        middlewareData,
        hasOverlay,
    } = context;

    hasOverlay.value = true;

</script>

<template>
    <portal to="overlay">
        <transition name="overlay">
            <div
                v-if="isOpen && !middlewareData.hide?.referenceHidden"
                class="MPopoverOverlay"
            />
        </transition>
    </portal>
</template>

<style scoped lang="scss">
    .MPopoverOverlay {
        @apply tw-fixed tw-inset-0 tw-bg-gray-500 tw-bg-opacity-75;

        z-index: 3050;
    }

    .overlay {
        &-enter-active, &-leave-active {
            @apply tw-transition-all tw-ease-out;
        }

        &-enter, &-leave-to {
            @apply tw-opacity-0;
        }
    }
</style>
