<template>
    <div v-if="layout === 'line'">
        <div
            v-for="line in lines"
            :class="lineBaseClass"
            :style="{width: line + '%'}"
        />
    </div>
    <div
        v-else-if="layout === 'grid'"
        :class="[gridClass, gridBaseClass]"
    >
        <CVStack
            v-for="(item, index) in gridItems"
            :key="index"
            align="center"
            :class="[gridItemClass, gridItemBaseClass]"
            distribute="center"
        >
            <svg
                v-if="spinner"
                class="tw-animate-spin tw-h-5 tw-w-5 tw-text-gray-700"
                viewBox="0 0 24 24"
            >
                <circle
                    class="tw-opacity-25"
                    cx="12"
                    cy="12"
                    fill="transparent"
                    r="10"
                    stroke="currentColor"
                    stroke-width="4"
                />
                <path
                    class="tw-opacity-75"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    fill="currentColor"
                />
            </svg>
        </CVStack>
    </div>
    <div
        v-else-if="layout === 'custom'"
        :class="[baseClass]"
    >
        <div
            v-for="(count, index) in range(count)"
            :key="index"
            :class="[customItemClass]"
        >
            <slot/>
        </div>
    </div>
</template>

<script lang="ts">
    import {computed, defineComponent} from 'vue';
    import {random, range} from 'lodash-es';

    export default defineComponent({
        components: {},
        props: {
            count: {type: Number, default: 1, required: false},
            spinner: {type: Boolean, default: false, required: false},
            size: {type: String, default: 'default', required: false},
            random: {type: Boolean, default: true, required: false},
            gridClass: {type: String, default: '', required: false},
            gridCol: {type: Number, default: 0, required: false},
            gridItemClass: {type: String, default: '0', required: false},
            customItemClass: {type: String, default: '', required: false},
            layout: {type: String, default: 'line', required: false},

        },
        setup(props) {
            const baseClass = computed(() => {
                const output = [] as string[];

                output.push('tw-animate-pulse');

                return output;
            });

            const lineBaseClass = computed(() => {
                const output = [] as string[];

                output.push('item tw-bg-gray-200 tw-mb-2 tw-rounded-full tw-animate-pulse');

                const sizes = {
                    default: 'tw-h-2',
                    lg: 'tw-h-4',
                    xl: 'tw-h-8',
                };

                output.push(sizes[props.size]);

                return output;
            });

            const gridBaseClass = computed(() => {
                const output = [] as string[];

                output.push('tw-grid tw-gap-2');

                let gridCol = props.gridCol;
                if (props.gridCol === 0) {
                    gridCol = props.count;
                }

                const cols = {
                    1: 'tw-grid-cols-1',
                    2: 'tw-grid-cols-2',
                    3: 'tw-grid-cols-3',
                    4: 'tw-grid-cols-4',
                    5: 'tw-grid-cols-5',
                    6: 'tw-grid-cols-6',
                    7: 'tw-grid-cols-7',
                    8: 'tw-grid-cols-8',
                    9: 'tw-grid-cols-9',
                    10: 'tw-grid-cols-10',
                    11: 'tw-grid-cols-11',
                    12: 'tw-grid-cols-12',
                };

                output.push(cols[gridCol]);

                return output;
            });

            const gridItemBaseClass = computed(() => {
                const output = [] as string[];

                output.push('tw-bg-gray-200 tw-rounded tw-animate-pulse');

                const sizes = {
                    sm: 'tw-h-4',
                    default: 'tw-h-8',
                    lg: 'tw-h-12',
                };

                output.push(sizes[props.size]);

                return output;
            });

            const lines = computed(() => {
                return range(props.count).map((value, key) => {
                    if (props.random) {
                        return 100;
                    }

                    if (key === 0) {
                        return random(6, 10) * 10;
                    }

                    return random(2, 10) * 10;
                });
            });

            const gridItems = computed(() => {
                return range(props.count);
            });

            return {
                baseClass,
                lineBaseClass,
                gridBaseClass,
                gridItemBaseClass,
                lines,
                gridItems,
                range,
            };
        },
    });
</script>

<style
    lang="scss"
    scoped
>
    .item:last-of-type {
        margin-bottom: 0 !important;
    }
</style>
