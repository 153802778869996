import useManager from '@/modules/app/composables/useManager';
import useModal from '@/modules/app/composables/useModal';
import EditEvent from '@/modules/legacy/components/Modules/PlanningGenerator/EditEvent.vue';
import useAuth from '@/modules/app/composables/useAuth';

export default function useEditEventModal() {
    function show(event: object) {
        const {legacyNursery} = useManager();
        const {legacyUser} = useAuth();
        useModal({
            component: EditEvent,
            props: {
                myEvent: event,
                nursery: legacyNursery.value,
                user: legacyUser.value,
            },
        }).show();
    }

    return {
        show,
    };
}
