import Vue from 'vue';

Vue.directive('focus', {
    inserted(el, binding) {
        let focus = binding.value;

        if (binding.value === undefined) {
            focus = true;
        }

        if (focus) {
            el.focus();
        }
    },
});
