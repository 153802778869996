import {computed, ref, watch} from 'vue';
import type TicketModel from '@/modules/activity/ticket/domain/TicketModel';
import swal from 'sweetalert2/dist/sweetalert2.js';
import {cloneDeep, isEqual} from 'lodash-es';
import moment from 'moment';
import __ from '@/modules/app/utils/i18n-facade';

export default function(task: TicketModel) {
    const recurrence = ref(task.parent().value()
        ? cloneDeep(task.parent().value().attributes.recurrence_ruleset)
        : task.attributes.recurrence_ruleset,
    );

    // Check if task recurrence has been updated by user
    const hasRecurrenceChanged = computed(() =>
        !isEqual(recurrence.value, task.parent().value()
            ? task.parent().value().attributes.recurrence_ruleset
            : task.attributes.recurrence_ruleset,
        ),
    );

    // Reset remind at if specific date and recurring task
    watch(() => recurrence.value, (newRecurrence, oldRecurrence) => {
        if (!oldRecurrence && newRecurrence) {
            task.attributes.remind_at = null;
        }
    });

    async function askChangeAllNextOccurences() {
        await swal.fire({
            title: __('activity:you_are_editing_a_recurring_task'),
            text: __('activity:would_you_also_like_to_update_all_upcoming_tasks_ask'),
            type: 'warning',
            confirmButtonClass: 'btn btn-primary mr-2',
            confirmButtonText: __('common:actions.validate'),
            cancelButtonText: __('common:actions.cancel'),
        }).then(async result => {
            if (result.value) {
                if (task.attributes.parent_id) {
                    await stopParentRecurrence();
                }

                moveRecurrenceOnTask();
            }
        });
    }

    function moveRecurrenceOnTask() {
        // Dissociate from parent to begin new recurrence and become the new parent
        if (recurrence.value) {
            task.parent().dissociate();
            task.attributes.recurrence_ruleset = recurrence.value;
        }
    }

    async function stopParentRecurrence() {
        const parent = task.parent().value();
        parent.attributes.next_occurence_at = null;

        const until = moment(task.attributes.expired_at).format();

        if (parent.attributes.recurrence_ruleset) {
            parent.attributes.recurrence_ruleset.until = until;
            parent.attributes.recurrence_ruleset.rules = parent.attributes.recurrence_ruleset.rules.map(rule => {
                rule.until = until;

                return rule;
            });
        }

        await parent.save();
    }

    async function saveTaskRecurrenceChanges() {
        // Lock computed value to avoid reactivity issues
        const lockedHasRecurrenceChanged = hasRecurrenceChanged.value;

        // If task is last occurrence and exists, we apply changes on next occurrences if needed
        if (task.isLastOccurrence && task.exists) {
            // If recurrence or reminder rulesed has been changed
            // we always apply this changes on next occurrences without asking
            if (lockedHasRecurrenceChanged || task.isDirty('remind_ruleset')) {
                // If task has a parent we stop recurrence on parent and move recurrence on current task
                if (task.attributes.parent_id) {
                    await stopParentRecurrence();
                }
                moveRecurrenceOnTask();

                return;
            }

            // If recurrencing task and no recurrence or reminder ruleset changes
            // we ask if user want to apply this changes on next occurrences
            if (!lockedHasRecurrenceChanged && !task.isDirty('remind_ruleset') && task.isDirty()) {
                await askChangeAllNextOccurences();

                return;
            }
        }

        // Create recurrence on task if not exists
        if (!task.exists) {
            task.attributes.recurrence_ruleset = recurrence.value;
        }
    }

    return {
        askChangeAllNextOccurences,
        moveRecurrenceOnTask,
        hasRecurrenceChanged,
        recurrence,
        saveTaskRecurrenceChanges,
        stopParentRecurrence,
    };
}
