<template>
    <div :class="[lightContent ? 'tw-text-white' : 'tw-text-gray-600']">
        <div
            v-for="(file, i) in files"
            :key="'attachedFile' + i"
            class="tw-flex tw-items-center tw-text-sm tw-py-1"
        >
            <a
                class="tw-text-inherit tw-truncate"
                :href="file.file_url"
                target="_blank"
            >{{ file.name }}</a>
            <div class="tw-ml-auto">
                <div
                    v-if="file.created_at !== file.updated_at"
                    class="tw-font-semibold tw-whitespace-nowrap tw-py-1 tw-px-2"
                >
                    <i
                        v-if="small"
                        class="fa fa-check"
                    />
                    <template v-else>
                        <i class="fa fa-check tw-mr-1"/>{{ __('common:saved') }}
                    </template>
                </div>
                <template v-else>
                    <MTooltip
                        v-if="small && lightContent"
                        :label="__('document:save_document')"
                        placement="top"
                    >
                        <button
                            class="tw-text-inherit tw-whitespace-nowrap tw-px-2 zoom"
                            size="sm"
                            @click="$refs.createFile[i].toggle()"
                        >
                            <i
                                v-if="small"
                                class="fa fa-file-download"
                            />
                        </button>
                    </MTooltip>
                    <c-button
                        v-else
                        class="tw-whitespace-nowrap tw-text-sm"
                        size="sm"
                        @click="$refs.createFile[i].toggle()"
                    >
                        <i class="fad fa-file-download tw-text-blue-500 tw-mr-1"/>{{ __('common:actions.save') }}
                    </c-button>
                </template>
                <MDropdown
                    ref="createFile"
                    legacy-mode
                    placement="left"
                    stop-propagation
                >
                    <div
                        v-if="Manager.activeOrganization && Manager.activeOrganization.id"
                        class="tw-p-3"
                    >
                        <div class="tw-font-semibold tw-mb-3">
                            {{ __('document:save_to_colon') }}
                        </div>
                        <CButtonGroup class="tw-mb-3">
                            <c-button
                                class="tw-rounded-r-none"
                                :variant="nav === 'children' ? 'primary' : 'default'"
                                @click="nav = 'children'"
                            >
                                <i class="fa fa-child-reaching tw-mr-1"/>{{ __('common:kid_other') }}
                            </c-button>
                            <c-button
                                class="tw-rounded-l-none"
                                :variant="nav === 'members' ? 'primary' : 'default'"
                                @click="nav = 'members'"
                            >
                                <i class="fa fa-user-tie tw-mr-1"/>{{ __('common:parent_other') }}
                            </c-button>
                        </CButtonGroup>
                        <SelectKid
                            v-if="nav === 'children'"
                            v-model="selectedKid"
                            :add-option-enabled="false"
                            :create-organization-model="Manager.activeOrganization"
                            :extend-builder="extendKidBuilder"
                            :fallback-text="__('document:select_kid')"
                            :filter-organization-model="Manager.activeOrganization"
                            :has-unselect="true"
                            :unselect-text="__('document:unselect_kid')"
                        />
                        <SelectMember
                            v-else
                            v-model="selectedMember"
                            :create-option-enabled="false"
                            :extend-builder="extendMemberBuilder"
                            :fallback-text="__('document:select_member')"
                            :has-unselect="true"
                            :organization-model="Manager.activeOrganization"
                            :unselect-text="__('document:unselect_member')"
                        />
                        <c-button
                            v-if="attaching"
                            class="tw-w-full tw-mt-4"
                            :disabled="true"
                            variant="primary"
                        >
                            <i class="fa fa-circle-notch fa-spin tw-mr-2"/>{{ __('common:saving_dots') }}
                        </c-button>
                        <c-button
                            v-else
                            class="tw-w-full tw-mt-4"
                            variant="primary"
                            @click="attachFile(file, i)"
                        >
                            <i class="fa fa-file-download tw-mr-2"/>{{ __('document:save_document') }}
                        </c-button>
                    </div>
                    <div
                        v-else
                        class="p-3"
                    >
                        <div>{{ __('document:please_select_an_organization_before_save_document') }}</div>
                    </div>
                </MDropdown>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
    import route from '@/modules/legacy/libs/ziggy';
    import _forEach from 'lodash-es/forEach';
    import _head from 'lodash-es/head';
    import KidModel from '@/modules/family/models/KidModel';
    import MemberModel from '@/modules/family/models/MemberModel';
    import SelectKid from '@/modules/family/components/kid/SelectKid.vue';
    import SelectMember from '@/modules/family/components/member/SelectMember.vue';
    import useApi from '@/modules/app/composables/useApi';
    import Manager from '@/modules/legacy/store/manager.store';
    import FamilyModel from '@/modules/family/models/FamilyModel';
    import __ from '@/modules/app/utils/i18n-facade';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';
    import {computed, type PropType, ref, watch} from 'vue';

    const props = defineProps({
        files: {
            type: Array as PropType<Array<{
                id: string,
                name: string,
                file_url: string,
                created_at: string,
                updated_at: string
            }>>,
            default: () => [],
        },
        memberId: {
            type: String,
            required: true,
        },
        small: {
            type: Boolean,
            default: false,
        },
        lightContent: {
            type: Boolean,
            default: false,
        },
    });

    const createFile = ref();
    const nav = ref('children');
    const selectedMember = ref<MemberModel | null>(null);
    const selectedKid = ref<KidModel | null>(null);
    const attaching = ref(false);

    const extendKidBuilder = computed(() => {
        return KidModel.query().whereHas(new KidModel().family(), query => {
            query.whereHas(new FamilyModel().members(), query1 => {
                query1.where('id', props.memberId);
            });
        });
    });

    const extendMemberBuilder = computed(() => {
        return MemberModel.query().whereHas(new MemberModel().families(), query => {
            query.whereHas(new FamilyModel().members(), query1 => {
                query1.where('id', props.memberId);
            });
        });
    });

    watch(nav, () => {
        selectedKid.value = null;
        selectedMember.value = null;
    });


    function attachFile(file: { id: string, updated_at: string }, i: number) {
        attaching.value = true;

        let documentable = {
            documentable_type: '',
            documentable_id: '',
        };

        if (nav.value === 'children' && selectedKid.value) {
            documentable = {
                documentable_type: 'kids',
                documentable_id: selectedKid.value.id as string,
            };
        } else if (nav.value === 'members' && selectedMember.value) {
            documentable = {
                documentable_type: 'familyMembers',
                documentable_id: selectedMember.value.id as string,
            };
        } else {
            attaching.value = false;
            useNotification().error(__('document:please_select_a_person'));

            return;
        }

        useApi().legacy.post(
            route('documents.attach', {
                document: file.id,
            }),
            documentable,
        )
            .then(response => {
                if (createFile.value && createFile.value[i]) {
                    createFile.value[i].hide();
                }

                // Mise à jour de l'updated_at, car le document est considéré comme attaché sur une fiche
                // si l'updated_at a été mis à jour depuis le created_at.
                // A terme, il faudra enregistrer une colonne pour stocker cette information dans la table document
                // pour éviter ce tricks.
                file.updated_at = response.data.updated_at;
                attaching.value = false;
            })
            .catch(error => {
                attaching.value = false;
                if (error && error.response && error.response.status === 422) {
                    _forEach(error.response.data.errors, value => {
                        useNotification().error(_head(value) as string);
                    });
                } else {
                    useNotification().error(error);
                }
            });
    }
</script>
