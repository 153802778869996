<template>
    <SimpleFullPageTemplate>
        <template #title>
            <MPageTitle>
                {{ __('app:pages.photos') }}
            </MPageTitle>
        </template>
        <template #actions>
            <CButton
                variant="primary"
                @click="$refs.photoAddModal.$refs.modal.show()"
            >
                <FontAwesomeIcon
                    class="tw-mr-2"
                    icon="fa fa-upload"
                />
                {{ __('photo:add_media') }}
            </CButton>
            <PhotoAddModal
                ref="photoAddModal"
                :nursery="nursery"
                @refresh="onRefresh"
            />
        </template>
        <template #content>
            <MagicIndex
                class="tw-flex tw-flex-col tw-flex-1"
                container-class="tw-flex tw-flex-col tw-flex-1"
                :paginator="paginator"
                variant="photo"
                @change="getPhotos($event)"
            >
                <template #filters-container>
                    <PhotoFilters
                        :kids="kids"
                        :nursery="nursery"
                        :selected-group.sync="selectedGroup"
                        :selected-kid.sync="selectedKid"
                        :selected-tags-models.sync="selectedTagsModels"
                        variant="photo"
                        :without-tags.sync="withoutTags"
                    />
                </template>
                <template #content="{hasResults}">
                    <div class="PhotoIndexPage__container">
                        <!--                        <PhotoNavbar :loading="loading"/>-->
                        <div class="PhotoIndexPage__content">
                            <transition
                                mode="out-in"
                                name="transition-fade"
                            >
                                <MagicIndexLoader
                                    v-if="loading && !hasResults"
                                    key="loader"
                                    class="tw-flex-1"
                                />
                                <div
                                    v-else-if="hasResults"
                                    class="tw-flex tw-flex-col tw-flex-1 tw-gap-2 tw-relative"
                                >
                                    <PhotoList
                                        :has-more="hasMore"
                                        :loading="loading"
                                        :paginator="paginator"
                                        @load-more="onLoadMore"
                                    >
                                        <template #item="{item}">
                                            <PhotoListItem
                                                :item="item.legacy"
                                                :item-model="item.model"
                                                :nursery="nursery"
                                                :selected-photo="selectedMedia"
                                                @delete="deletePhoto(item.legacy)"
                                                @download="downloadPicture(item.legacy.photo_url)"
                                                @hidden="onItemHidden"
                                                @selected="selectedMedia = item.legacy"
                                                @visible="onItemVisible"
                                            />
                                        </template>
                                    </PhotoList>
                                    <!--                                    <div class="PhotoIndexPage__dates">-->
                                    <!--                                        {{ getDisplayedDate }}-->
                                    <!--                                    </div>-->
                                </div>
                                <MagicIndexEmptyList
                                    v-else
                                    class="tw-flex-1 tw-justify-center"
                                >
                                    <template #empty-list>
                                        {{ __('photo:empty_picture') }}
                                    </template>
                                    <template #empty-list-action>
                                        {{ __('photo:try_changing_the_filters_or_adding_a_photo') }}
                                    </template>
                                </MagicIndexEmptyList>
                            </transition>
                        </div>
                    </div>
                </template>
            </MagicIndex>
            <MMediaFullScreen
                v-if="selectedMedia"
                :selected-media="selectedMedia"
                @close="selectedMedia = undefined"
            />
        </template>
    </SimpleFullPageTemplate>
</template>

<script>
    import swal from 'sweetalert2/dist/sweetalert2.js';
    import route from '@/modules/legacy/libs/ziggy';
    import moment from 'moment';
    import _head from 'lodash-es/head';
    import _forEach from 'lodash-es/forEach';
    import _debounce from 'lodash-es/debounce';
    import useApi from '@/modules/app/composables/useApi';
    import DualRingImage from '@/assets/images/DualRing-Dark.svg';
    import PhotoAddModal from '@/modules/photo/components/organisms/PhotoAddModal.vue';
    import MagicIndex from '@/modules/magic-index/components/organisms/MagicIndex.vue';
    import {LengthAwarePaginator} from '@meekohq/lumos';
    import {url} from '@/modules/legacy/mixins/url';
    import PhotoListItem from '@/modules/photo/components/molecules/PhotoListItem.vue';
    import PhotoFilters from '@/modules/photo/components/organisms/PhotoFilters.vue';
    import PhotoList from '@/modules/photo/components/organisms/PhotoList.vue';
    import __ from '@/modules/app/utils/i18n-facade';
    import MMediaFullScreen from '@/modules/meeko-ui/components/MMediaFullScreen.vue';
    import MagicIndexEmptyList from '@/modules/magic-index/components/atoms/MagicIndexEmptyList.vue';
    import MagicIndexLoader from '@/modules/magic-index/components/atoms/MagicIndexLoader.vue';
    import SimpleFullPageTemplate from '@/modules/app/components/templates/SimpleFullPageTemplate.vue';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';
    import useTagLegacyModelBridge from '@/modules/app/composables/useTagLegacyModelBridge';
    import PhotoModel from '@/modules/photo/models/PhotoModel';

    export default {
        components: {
            SimpleFullPageTemplate,
            MagicIndexLoader,
            MagicIndexEmptyList,
            MMediaFullScreen,
            // PhotoNavbar,
            PhotoFilters,
            PhotoListItem,
            PhotoList,
            PhotoAddModal,
            MagicIndex,
        },
        mixins: [url],
        props: ['nursery'],
        data: () => ({
            DualRingImage,
            photos: [],
            photosModels: [],
            kids: [],
            selectedMedia: undefined,
            selectedKid: undefined,
            selectedGroup: undefined,
            selectedTagsModels: [],
            withoutTags: JSON.parse(localStorage.getItem('without_tags:photos:index')) || false,
            loading: true,
            currentPage: 1,
            hasMore: true,
            total: 0,
            visible: [],
        }),
        computed: {
            moment() {
                return moment;
            },
            // getDisplayedDate() {
            //     // Get min and max date from visible photo array
            //     const minDate = minBy(this.visible, 'taken_at');
            //     const maxDate = maxBy(this.visible, 'taken_at');
            //
            //     if (minDate === undefined || maxDate === undefined) {
            //         return '';
            //     }
            //
            //     const minEpoch = Support.Epoch.fromUnix(minDate.taken_at);
            //     const maxEpoch = Support.Epoch.fromUnix(maxDate.taken_at);
            //
            //     if (minEpoch.getYear() === maxEpoch.getYear()) {
            //         if (minEpoch.getMonth() === maxEpoch.getMonth()) {
            //             if (minEpoch.getDay() === maxEpoch.getDay()) {
            //                 return maxEpoch.format('d MMM yyyy');
            //             }
            //
            //             return minEpoch.format('d') + '–' + maxEpoch.format('d MMM yyyy');
            //         }
            //
            //         return minEpoch.format('d MMM') + '–' + maxEpoch.format('d MMM yyyy');
            //     }
            //
            //     return minEpoch.format('d MMM yyyy') + '–' + maxEpoch.format('d MMM yyyy');
            // },
            paginator() {
                return new LengthAwarePaginator(
                    this.photosLegaciesAndModels,
                );
            },
            selectedKidAndSelectedGroupIds() {
                // Returns the concatenation if kid an group ids to watch changes and avoid
                // requests concurrency
                return `${this.selectedKid?.getKey()}${this.selectedGroup?.getKey()}`;
            },
            tagsFilters() {
                return {
                    selectedTagsModels: this.selectedTagsModels,
                    withoutTags: this.withoutTags,
                };
            },
            photosLegaciesAndModels() {
                return this.photos.map(legacy => {
                    const model = this.photosModels.find(photoModel => photoModel.getKey() === legacy.id);

                    return {
                        legacy,
                        model,
                    };
                });
            },
        },
        watch: {
            selectedGroup() {
                this.getKids();
            },
            selectedKidAndSelectedGroupIds() {
                this.debounceGetPhotos(1);
            },
            tagsFilters({selectedTagsModels, withoutTags}) {
                if (selectedTagsModels) {
                    localStorage.setItem('tags:photos:index', JSON.stringify(selectedTagsModels.map(tag => tag.getKey())));
                } else {
                    localStorage.removeItem('tags:photos:index');
                }
                localStorage.setItem('without_tags:photos:index', JSON.stringify(withoutTags));

                this.debounceGetPhotos(1);
            },
        },
        mounted() {
            this.getSelectedTagsModels();
        },
        methods: {
            reset() {
                this.loading = true;
                this.photos = [];
                this.photosModels = [];
                this.hasMore = true;
                this.currentPage = 1;
            },
            onRefresh() {
                this.reset();
                this.getPhotos(1);
            },
            onLoadMore() {
                this.currentPage++;
                this.getPhotos(this.currentPage);
            },
            onItemVisible(photo) {
                this.visible.push(photo);
            },
            onItemHidden(photo) {
                this.visible = this.visible.filter(item => item.id !== photo.id);
            },
            debounceGetPhotos: _debounce(function(page) {
                this.reset();
                this.getPhotos(page);
            }, 500),
            getPhotos(page, perPage = 20) {
                if (!this.hasMore) {
                    return;
                }

                this.loading = true;

                const tags = useTagLegacyModelBridge().tagsModelsAndWithoutTagsToLegacyFilter(this.selectedTagsModels, this.withoutTags);

                useApi().legacy.get(
                    route('nurseries.photos.index', {nurseries: this.nursery.id}),
                    {
                        params: {
                            page,
                            per_page: perPage,
                            group_id: this.selectedGroup ? this.selectedGroup.id : undefined,
                            kid_id: this.selectedKid
                                ? this.selectedKid.id
                                : this.selectedKid,
                            tags,
                        },
                    },
                )
                    .then(response => {
                        this.hasMore = response.data.next_page_url !== null;

                        this.getPhotosModels(response.data.data);
                    })
                    .catch(error => {
                        if (
                            error &&
                            error.response &&
                            error.response.status === 422
                        ) {
                            _forEach(error.response.data.errors, function(value) {
                                useNotification().error(_head(value));
                            });
                        } else {
                            useNotification().error(error);
                        }
                    });
            },

            async getPhotosModels(photos) {
                if (!photos.length) {
                    this.photos = this.photos.concat([]);
                    this.photosModels = this.photosModels.concat([]);
                    this.loading = false;

                    return;
                }

                this.photosModels = this.photosModels.concat(
                    (await PhotoModel.query()
                        .with(new PhotoModel().tags())
                        .with(new PhotoModel().kids())
                        .whereIn('id', photos.map(photo => photo.id))
                        .get())
                        .all(),
                );
                this.photos = this.photos.concat(photos);
                this.loading = false;
            },

            deletePhoto(photo) {
                swal({
                    title: __('photo:actions.delete_photo'),
                    confirmButtonText: __('common:actions.remove'),
                    cancelButtonText: __('common:actions.cancel'),
                }).then(result => {
                    if (result.value) {
                        useApi().legacy.delete(
                            route('nurseries.photos.destroy', {
                                nurseries: this.nursery.id,
                                photo: photo.id,
                            }),
                        ).then(response => {
                            useNotification().success(response.data);
                            this.photos = this.photos.filter(
                                item => photo.id !== item.id,
                            );
                        });
                    }
                });
            },

            getKids() {
                useApi().legacy.get(route('kids.index'), {
                    params: {
                        nursery_id: this.nursery.id,
                        pagination: 0,
                        group_id: this.selectedGroup ? this.selectedGroup.id : undefined,
                    },
                })
                    .then(response => {
                        this.kids = response.data;
                    })
                    .catch(error => {
                        if (
                            error &&
                            error.response &&
                            error.response.status === 422
                        ) {
                            _forEach(error.response.data.errors, function(value) {
                                useNotification().error(_head(value));
                            });
                        } else {
                            useNotification().error(error);
                        }
                    });
            },
            downloadPicture(url) {
                window.open(url, '_blank');
            },
            async getSelectedTagsModels() {
                const selectedTagIds = JSON.parse(localStorage.getItem('tags:photos:index'));
                this.selectedTagsModels = await useTagLegacyModelBridge().getTagsModelsFromIds(selectedTagIds);
            },
        },
    };
</script>

<style lang="scss" scoped>
    .PhotoIndexPage__container {
        @apply tw-flex tw-flex-1 tw-flex-row;
    }

    .PhotoIndexPage__content {
        @apply tw-w-full tw-flex tw-flex-col tw-p-3 tw-gap-2 tw-relative;
    }

    .PhotoIndexPage__dates {
        @apply tw-text-lg sm:tw-text-3xl;
        @apply tw-font-semibold tw-text-white tw-absolute tw-inset-x-0 tw-p-3;
        pointer-events: none;
        text-shadow: 2px 2px 8px #000000;
    }
</style>
