<template>
    <div>
        <CButton
            class="tw-w-full"
            icon-menu
            :size="size"
            :variant="variant"
            @click="$refs.groupFilter.toggle()"
        >
            {{ groupBtnText }}
        </CButton>
        <CDropdown
            ref="groupFilter"
            legacy-mode
        >
            <CList class="tw-min-w-40 tw-cursor-pointer">
                <CListRow @click="$emit('input', null)">
                    <i
                        v-if="!value"
                        class="fa fa-check tw-mr-2"
                    />
                    {{ __('common:all_groups') }}
                </CListRow>
                <CListRow
                    v-for="(group, i) in nursery.groups"
                    :key="'group' + i"
                    @click="$emit('input', group.id)"
                >
                    <i
                        v-if="value === group.id"
                        class="fa fa-check tw-mr-1"
                    />
                    {{ group.name }}
                </CListRow>
            </CList>
        </CDropdown>
    </div>
</template>

<script>
    import _filter from 'lodash-es/filter';
    import __ from '@/modules/app/utils/i18n-facade';

    export default {
        props: {
            value: {
                required: true,
            },
            nursery: {
                type: Object,
                required: true,
            },
            variant: {
                type: String,
                default: 'light',
            },
            size: {
                type: String,
                default: 'base',
            },
        },
        computed: {
            groupBtnText() {
                if (this.value === null || !this.getGroupById(this.value)) {
                    this.$emit('input', null);

                    return __('common:all_groups');
                } else {
                    return this.getGroupById(this.value).name;
                }
            },
        },
        methods: {
            getGroupById(id) {
                return _filter(this.nursery.groups, group => {
                    return group.id === id;
                })[0];
            },
        },
    };
</script>
