<template>
    <router-link
        v-if="routerLinkFn"
        v-slot="{ href, navigate }"
        custom
        :to="routerLinkFn(item)"
    >
        <a :href="href">
            <li
                ref="element"
                class="MagicIndexItem MagicIndexItem--is-clickable"
                :class="{
                    'MagicIndexItem--is-selectable': massSelect ? massSelect.isEnabled.value : false
                }"
                @click="navigate"
            >
                <slot
                    :item="item"
                    name="item"
                />
                <MMassSelector
                    class="MagicIndexItem__selector"
                    :selected="isSelected"
                    :visible="isVisible"
                    @click.native.stop.prevent="toggle"
                />
            </li>
        </a>
    </router-link>
    <li
        v-else
        ref="element"
        class="MagicIndexItem"
        :class="{
            'MagicIndexItem--is-selectable': massSelect ? massSelect.isEnabled.value : false
        }"
    >
        <MMassSelector
            v-if="withMassSelect"
            class="MagicIndexItem__selector"
            :full-size="massSelect ? massSelect.isEnabled.value : false"
            :selected="isSelected"
            :visible="isVisible"
            @click.native.stop="toggle"
        />
        <slot
            :item="item"
            name="item"
        />
    </li>
</template>

<script lang="ts">
    import type {PropType} from 'vue';
    import {computed, defineComponent, ref} from 'vue';
    import MMassSelector from '@/modules/magic-index/components/atoms/MagicIndexSelector.vue';
    import {useElementHover} from '@vueuse/core';
    import type {MagicMassSelectType} from '@/modules/app/composables/useMagicMassSelect';
    import {Model} from '@meekohq/lumos';

    export default defineComponent({
        components: {MMassSelector},
        props: {
            item: {type: Object as PropType<any>, required: true},
            routerLinkFn: {type: Function, default: undefined, required: false},
            massSelect: {type: Object as PropType<MagicMassSelectType>, required: false},
            withMassSelect: {type: Boolean, default: false, required: false},
        },
        setup(props) {
            const element = ref();
            const isHovered = useElementHover(element);

            const isVisible = computed(() => {
                return props.withMassSelect && (isHovered.value || isSelected.value);
            });

            const isSelected = computed(() => {
                if (props.item instanceof Model) {
                    return props.massSelect?.isSelected(props.item.getKey());
                }

                return false;
            });

            function toggle() {
                if (props.item instanceof Model) {
                    props.massSelect?.toggle(props.item.getKey());
                }
            }

            return {
                element,
                isVisible,
                isSelected,
                toggle,
            };
        },
    });

</script>

<style lang="scss" scoped>
    a {
        color: inherit;
        @apply tw-rounded-t-inherit;

        &:nth-child(even) {
            .MagicIndexItem {
                //@apply tw-bg-primary-50;
            }
        }
    }

    .MagicIndexItem {
        @apply tw-relative;
        //@apply tw-rounded-lg;
        //@apply even:tw-bg-primary-50;
        @apply tw-py-4 tw-px-4;
        @apply tw-border-b tw-border-gray-200 tw-rounded-t-inherit;
    }

    .MagicIndexItem--is-clickable {
        @apply tw-cursor-pointer;
        @apply tw-transition-all;
        @apply hover:tw-bg-gray-100 #{!important};
    }

    .MagicIndexItem__selector {
        @apply tw-absolute tw--top-1.5 tw--left-1.5 tw-bottom-0;
    }
</style>

