<template>
    <div class="table-responsive">
        <table class="table table-sm">
            <thead>
                <tr>
                    <th scope="col"/>
                    <th
                        v-for="i in 14"
                        :key="i"
                        scope="col"
                    >
                        {{ __('common:halfday_short_with_count', {count: i}) }}
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr
                    v-for="(bracket, index) in prices.brackets"
                    :key="'bracket ' + index"
                >
                    <th
                        v-if="prices.brackets.length > 1"
                        scope="row"
                    >
                        {{ __('billing_setting:t_value', {value: index + 1}) }}
                    </th>
                    <th
                        v-else
                        scope="row"
                    />
                    <th
                        v-for="(price, i) in bracket"
                        :key="'price ' + i"
                    >
                        <CInputGroup class="tw-font-normal tw-mt-1">
                            <NumberInput
                                v-model.number="price.value"
                                :minimum-fraction-digits="2"
                                placeholder="9"
                            />
                            <CInputAddon>
                                {{ nursery.currency }}
                            </CInputAddon>
                        </CInputGroup>
                    </th>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>

    import NumberInput from '@/modules/app/components/atoms/NumberInput.vue';

    export default {
        components: {NumberInput},
        props: ['prices', 'brackets', 'nursery'],
        data() {
            return {
                unit: 'halfday',
            };
        },
        computed: {},
        watch: {
            brackets() {
                this.reset();
            },
        },
        mounted() {
            if (this.prices.unit !== this.unit) {
                this.reset();
            }
        },
        methods: {
            reset() {
                const brackets = [];

                for (let i = 0; i < this.brackets; i++) {
                    brackets.push([
                        {value: 9},
                        {value: 9},
                        {value: 9},
                        {value: 9},
                        {value: 9},
                        {value: 9},
                        {value: 9},
                        {value: 9},
                        {value: 9},
                        {value: 9},
                        {value: 9},
                        {value: 9},
                        {value: 9},
                        {value: 9},
                    ]);
                }
                this.$emit('updatePrice', {
                    unit: this.unit,
                    brackets,
                });
            },
        },
    };
</script>

<style lang="scss" scoped>
    th {
        vertical-align: middle !important;
    }
</style>
