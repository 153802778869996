<template>
    <div>
        <p class="tw-text-gray-600 tw-text-base mb-0">
            {{ __('request:reservation_for_with_name', {name: `${notification.data.kid_first_name} ${notification.data.kid_last_name}`}) }}
        </p>
        <p class="tw-text-gray-800 tw-text-sm mb-0 mt-1">
            {{
                __('common:date_format.human_from_date_from_time_to_time', {
                    date: Epoch.fromTimestamp(notification.data.started_at).toLocaleString(Epoch.presets.DATE_SHORT),
                    fromTime: Epoch.fromTimestamp(notification.data.started_at).toLocaleString(Epoch.presets.TIME_SIMPLE),
                    toTime: Epoch.fromTimestamp(notification.data.ended_at).toLocaleString(Epoch.presets.TIME_SIMPLE),
                })
            }}
        </p>

        <MDropdown
            class="tw-mt-1"
            :stop-propagation="true"
        >
            <MButton
                icon-menu
                :variant="getVariantBtn(notification.state)"
            >
                <template #left-icons>
                    <FontAwesomeIcon
                        v-if="notification.state === 'accepted'"
                        :fixed-width="true"
                        icon="fas fa-check-circle"
                    />
                    <FontAwesomeIcon
                        v-else-if="notification.state === 'rejected'"
                        :fixed-width="true"
                        icon="fas fa-times-circle"
                    />
                    <FontAwesomeIcon
                        v-else
                        :fixed-width="true"
                        icon="fas fa-question-circle"
                    />
                </template>
                <template v-if="notification.state === 'accepted'">
                    {{ __('family_member:reservation_validated') }}
                </template>
                <template v-else-if="notification.state === 'rejected'">
                    {{ __('family_member:reservation_denied') }}
                </template>
                <template v-else>
                    {{ __('family_member:reservation_pending') }}
                </template>
            </MButton>
            <template #content>
                <CDropdownMenuItem
                    class="tw-w-full"
                    icon="fas fa-check-circle fa-fw"
                    :label="__('family_member:validate_reservation')"
                    @click="accept"
                />
                <CDropdownMenuItem
                    class="tw-w-full"
                    icon="fas fa-question-circle fa-fw"
                    :label="__('common:put_on_hold')"
                    @click="updateState('pending')"
                />
                <CDropdownMenuItem
                    class="tw-w-full"
                    icon="fas fa-times-circle fa-fw"
                    :label="__('family_member:refuse_reservation')"
                    variant="danger"
                    @click="updateState('rejected')"
                />
            </template>
        </MDropdown>
    </div>
</template>

<script>
    import moment from 'moment';
    import _cloneDeep from 'lodash-es/cloneDeep';
    import {Epoch} from '@meekohq/lumos';
    import useEditEventModal from '@/modules/planning/composables/core/useEditEventModal';

    export default {
        props: {
            notification: {type: Object},
            nursery_id: {type: Number},
        },
        computed: {
            Epoch() {
                return Epoch;
            },
        },
        methods: {
            accept() {
                const event = {};
                event.started_at = this.notification.data.started_at;
                event.ended_at = this.notification.data.ended_at;
                event.start_time = moment.unix(this.notification.data.started_at).clone().format('HH:mm:ss');
                event.end_time = moment.unix(this.notification.data.ended_at).clone().format('HH:mm:ss');
                event.kid_id = this.notification.data.kid_id;
                event.nursery_id = this.notification.data.nursery_id;
                event.type = 'occasional';
                event.note = this.notification.data.content;
                event.contract_hourly_rate = this.notification.data.hourly_rate;
                event.hourly_rate = this.notification.data.hourly_rate;
                event.isNew = true;
                event.unit = 'day';

                event.notification = _cloneDeep(this.notification);
                event.notification.state = 'accepted';

                useEditEventModal().show(event);

                !this.notification.read_at ? this.notification.read_at = moment().unix() : null;
            },

            updateState(state) {
                const myNotif = _cloneDeep(this.notification);
                myNotif.state = state;
                myNotif.referenceable.state = state;

                this.$bus.$emit('updateMessageNotification', myNotif);
                this.$bus.$emit('refreshChatMessage', myNotif);
            },

            getVariantBtn(state) {
                if (state === 'accepted') {
                    return 'success';
                } else if (state === 'rejected') {
                    return 'danger';
                }

                return 'primary';
            },
        },
    };
</script>
