import {ServiceProvider} from '@meekohq/lumos';
import StaffModel from '@/modules/human-resources/models/StaffModel';
import StaffsOrganizationsPivot from '@/modules/human-resources/models/StaffsOrganizationsPivot';
import JobsModel from '@/modules/human-resources/models/JobModel';
import JobsOrganizationsPivot from '@/modules/human-resources/models/JobOrganizationPivot';
import ContractModel from '@/modules/human-resources/models/ContractModel';
import ContractTrialPeriodModel from '@/modules/human-resources/models/ContractTrialPeriodModel';
import ContractOrganizationPivot from '@/modules/human-resources/models/ContractOrganizationPivot';
import ContractTypeModel from '@/modules/human-resources/models/ContractTypeModel';
import ContractTypeOrganizationPivot from '@/modules/human-resources/models/ContractTypeOrganizationPivot';
import CalendarModel from '@/modules/human-resources/models/CalendarModel';
import EventModel from '@/modules/human-resources/models/EventModel';
import EventTypeModel from '@/modules/human-resources/models/EventTypeModel';
import EventTypeOrganizationModel from '@/modules/human-resources/models/EventTypeOrganizationModel';
import TemplateModel from '@/modules/human-resources/models/TemplateModel';
import TemplatePlanningModel from '@/modules/human-resources/models/TemplatePlanningModel';
import TemplateEventModel from '@/modules/human-resources/models/TemplateEventModel';
import TemplateOrganizationPivotModel from '@/modules/human-resources/models/TemplateOrganizationPivotModel';
import TeamModel from '@/modules/human-resources/models/TeamModel';
import TeamStaffPivot from '@/modules/human-resources/models/TeamStaffPivot';
import TeamOrganizationPivot from '@/modules/human-resources/models/TeamOrganizationPivot';
import BalanceTypeModel from '@/modules/human-resources/models/BalanceTypeModel';
import BalanceTypeEventTypePivot from '@/modules/human-resources/models/BalanceTypeEventTypePivot';
import BalancePeriodModel from '@/modules/human-resources/models/BalancePeriodModel';
import BalanceAllocationModel from '@/modules/human-resources/models/BalanceAllocationModel';
import BalanceTypeOrganizationPivot from '@/modules/human-resources/models/BalanceTypeOrganizationPivot';
import JobList from '@/modules/human-resources/utils/job/JobList';
import {Names} from '@/modules/human-resources/utils/Names';
import {Types} from '@/types';
import type {
    ApplyTemplateUseCase,
} from '@/modules/human-resources/apply-template/application/use-cases/ApplyTemplateUseCase';
import {ApplyTemplateInteractor} from '@/modules/human-resources/apply-template/application/ApplyTemplateInteractor';
import {
    ApplyTemplateRepositoryAdapter,
} from '@/modules/human-resources/apply-template/infrastructure/ApplyTemplateRepositoryAdapter';
import {TemplateRepositoryAdapter} from '@/modules/human-resources/template/infrastructure/TemplateRepositoryAdapter';
import type {SaveContractUseCase} from '@/modules/human-resources/contract/application/SaveContractUseCase';
import {SaveContractInteractor} from '@/modules/human-resources/contract/application/SaveContractInteractor';
import ContractRepositoryAdapter from '@/modules/human-resources/contract/infrastructure/ContractRepositoryAdapter';
import type {
    GetOverlappingContractsUseCase,
} from '@/modules/human-resources/contract/application/GetOverlappingContractsUseCase';
import {
    GetOverlappingContractsInteractor,
} from '@/modules/human-resources/contract/application/GetOverlappingContractsInteractor';

export default class HumanResourcesServiceProvider extends ServiceProvider {
    public boot() {
        this.loadModelsFrom([
            StaffModel,
            StaffsOrganizationsPivot,

            JobsModel,
            JobsOrganizationsPivot,
            ContractModel,
            ContractTrialPeriodModel,
            ContractOrganizationPivot,
            ContractTypeModel,
            ContractTypeOrganizationPivot,
            CalendarModel,

            EventModel,
            EventTypeModel,
            EventTypeOrganizationModel,

            TemplateModel,
            TemplatePlanningModel,
            TemplateEventModel,
            TemplateOrganizationPivotModel,

            TeamModel,
            TeamStaffPivot,
            TeamOrganizationPivot,

            BalanceTypeModel,
            BalanceTypeEventTypePivot,
            BalancePeriodModel,
            BalanceAllocationModel,
            BalanceTypeOrganizationPivot,
        ]);
    }

    public register() {
        this.app.singleton(Names.JobList).toClass(JobList);

        this.app.bind<ApplyTemplateUseCase>(Types.ApplyTemplateUseCase).toCallback(() => {
            return new ApplyTemplateInteractor(new ApplyTemplateRepositoryAdapter(), new TemplateRepositoryAdapter());
        });

        this.app.bind<SaveContractUseCase>(Types.SaveContractUseCase).toCallback(() => {
            return new SaveContractInteractor(new ContractRepositoryAdapter());
        });

        this.app.bind<GetOverlappingContractsUseCase>(Types.GetOverlappingContractsUseCase).toCallback(() => {
            return new GetOverlappingContractsInteractor(new ContractRepositoryAdapter());
        });
    }
}
