import type PaymentModel from '@/modules/cashier/models/PaymentModel';
import type {
    GetAllocationsForCreditNoteUseCase,
} from '@/modules/cashier/payment/application/GetAllocationsForCreditNoteUseCase';
import type {ModelCollection} from '@meekohq/lumos';
import type {InvoiceAllocationAggregate} from '@/modules/cashier/payment/domain/InvoiceAllocationAggregate';
import type {
    TransactionDebitAllocationAggregate,
} from '@/modules/cashier/payment/domain/TransactionDebitAllocationAggregate';
import type {
    CreditNoteAllocationAggregatePort,
} from '@/modules/cashier/payment/application/ports/CreditNoteAllocationAggregatePort';

export class GetAllocationsForCreditNoteInteractor implements GetAllocationsForCreditNoteUseCase
{
    private readonly _creditNoteAllocationsAggregateRepository: CreditNoteAllocationAggregatePort;

    constructor(
        creditNoteAllocationsAggregateRepository: CreditNoteAllocationAggregatePort,
    ) {
        this._creditNoteAllocationsAggregateRepository = creditNoteAllocationsAggregateRepository;
    }

    public async fetch(creditNoteId: string, payments: ModelCollection<PaymentModel>): Promise<Array<InvoiceAllocationAggregate|TransactionDebitAllocationAggregate>> {
        return this._creditNoteAllocationsAggregateRepository.getAllocations(creditNoteId, payments);
    }

}
