<template>
    <div>
        <div>
            <div class="tw-flex tw-flex-wrap tw-items-center">
                <CButton
                    v-if="$can('create', 'staffs_contracts')"
                    class="md:tw-ml-auto tw-mb-4"
                    variant="primary"
                    @click="openContractModal"
                >
                    <i
                        aria-hidden="true"
                        class="fa fa-plus tw-mr-2"
                    />
                    {{ __('common:add_contract_button') }}
                </CButton>
            </div>

            <div
                v-for="(contract, i) in filteredContracts"
                :key="'contract' + i"
                class="tw-flex tw-flex-col lg:tw-flex-row lg:tw-items-center tw-border-t tw-border-gray-200 tw-pt-3 tw-mt-3"
            >
                <div class="tw-flex tw-items-center lg:tw-w-1/4">
                    <div
                        class="tw-mr-auto tw-font-grandhotel tw-text-3xl tw-capitalize"
                        @click="openContractModal(contract)"
                    >
                        <span
                            class="tw-cursor-pointer hover:tw-underline"
                            :class="[
                                contract.attributes.broked_at
                                    ? 'tw-text-gray-600 hover:tw-text-gray-800'
                                    : 'tw-text-blue-500 hover:tw-text-blue-700',
                            ]"
                        >
                            <i
                                aria-hidden="true"
                                class="fa fa-file-alt tw-mr-2 tw-text-2xl"
                            />
                            {{ contract.attributes.broked_at ? __('common:broken_contract') : __('common:contract_one') }}
                        </span>
                    </div>
                </div>

                <div class="tw-flex tw-flex-col lg:tw-flex-row lg:tw-w-2/4">
                    <dl class="tw-mb-0 md:tw-mr-4 lg:tw-text-center md:tw-w-28">
                        <dt class="tw-text-gray-400 tw-font-normal tw-mr-1 tw-inline lg:tw-block">
                            {{ __('common:beginning') }}
                        </dt>
                        <dd class="tw-text-gray-600 tw-inline-block tw-mb-0">
                            {{ parse(contract.attributes.started_at, 'yyyy-MM-dd').toLocaleString(Epoch.presets.DATE_SHORT) }}
                        </dd>
                    </dl>
                    <dl class="tw-mb-0 md:tw-mr-4 lg:tw-text-center md:tw-w-28">
                        <dt class="tw-text-gray-400 tw-font-normal tw-mr-1 tw-inline lg:tw-block">
                            {{ __('common:end') }}
                        </dt>
                        <dd class="tw-text-gray-600 tw-inline-block tw-mb-0">
                            <template v-if="contract.attributes.ended_at">
                                {{ parse(contract.attributes.ended_at, 'yyyy-MM-dd').toLocaleString(Epoch.presets.DATE_SHORT) }}
                            </template>
                            <template v-else>
                                {{ __('common:not_filled') }}
                            </template>
                        </dd>
                    </dl>
                    <dl class="tw-mb-0 md:tw-mr-4 lg:tw-text-center md:tw-w-28">
                        <template v-if="contract.attributes.broked_at">
                            <dt class="tw-text-gray-400 tw-font-normal tw-mr-1 tw-inline lg:tw-block">
                                {{ __('hr_contract:contract_broken_at') }}
                            </dt>
                            <dd class="tw-text-gray-600 tw-inline-block tw-mb-0">
                                <template v-if="contract.attributes.broked_at">
                                    {{ parse(contract.attributes.broked_at, 'yyyy-MM-dd').toLocaleString(Epoch.presets.DATE_SHORT) }}
                                </template>
                                <template v-else>
                                    {{ __('hr_contract:contract_no_broken') }}
                                </template>
                            </dd>
                        </template>
                    </dl>
                    <dl class="tw-mb-0 md:tw-mr-4 lg:tw-text-center md:tw-w-28">
                        <template v-if="contract.organizations().value().count()">
                            <dt class="tw-text-gray-400 tw-font-normal tw-mr-1 tw-inline lg:tw-block">
                                {{ __('common:organization_other') }}
                            </dt>
                            <dd class="tw-text-gray-600 tw-inline-block tw-mb-0">
                                <CTooltip>
                                    {{ contract.organizations().value().count() }}
                                    <template #content>
                                        <CVStack gap="3">
                                            <h5 class="h5 tw-mb-4 tw-text-gray-900 tw-uppercase tw-font-semibold tw-text-sm">
                                                {{ __('hr_contract:organizations_attached_to_contract') }}
                                            </h5>
                                            <CVStack gap="2">
                                                <CText
                                                    v-for="organization in contract.organizations().value()"
                                                    :key="organization.id"
                                                    font-size="lg"
                                                    font-weight="semibold"
                                                    variant="primary"
                                                >
                                                    {{ organization.attributes.name }}
                                                </CText>
                                            </CVStack>
                                        </CVStack>
                                    </template>
                                </CTooltip>
                            </dd>
                        </template>
                    </dl>
                </div>

                <div
                    class="tw-flex tw-mt-2 lg:tw-mt-0 lg:tw-w-1/4 lg:tw-justify-end"
                >
                    <!--                    TODO: Cacher le bouton Le temps de corriger le ticket associé : MK-2877-->
                    <!--                    <CTooltip-->
                    <!--                        v-if="$can('create', 'staffs_contracts')"-->
                    <!--                        class="zoom-small tw-mr-2"-->
                    <!--                        placement="top"-->
                    <!--                    >-->
                    <!--                        <template #content>-->
                    <!--                            {{ __('common:actions.duplicate') }}-->
                    <!--                        </template>-->
                    <!--                        <CButton-->
                    <!--                            round-->
                    <!--                            variant="info"-->
                    <!--                            @click="openContractModal(contract, true)"-->
                    <!--                        >-->
                    <!--                            <i-->
                    <!--                                aria-hidden="true"-->
                    <!--                                class="fa fa-copy"-->
                    <!--                            />-->
                    <!--                        </CButton>-->
                    <!--                    </CTooltip>-->

                    <CTooltip
                        v-if="!contract.attributes.broked_at && $can('update', 'staffs_contracts')"
                        class="zoom-small tw-mr-2"
                        placement="top"
                    >
                        <template #content>
                            {{ __('common:actions.break') }}
                        </template>
                        <CButton
                            round
                            variant="light"
                            @click="openBrokeModal(contract)"
                        >
                            <i
                                aria-hidden="true"
                                class="fa fa-page-break"
                            />
                        </CButton>
                    </CTooltip>

                    <CTooltip
                        v-if="$can('delete', 'staffs_contracts')"
                        class="zoom-small"
                        placement="top"
                    >
                        <template #content>
                            {{ __('common:actions.delete') }}
                        </template>
                        <CButton
                            round
                            variant="danger"
                            @click="remove(contract)"
                        >
                            <i
                                aria-hidden="true"
                                class="fa fa-trash"
                            />
                        </CButton>
                    </CTooltip>
                </div>
            </div>
            <h1
                v-if="!filteredContracts.length"
                class="h1 tw-font-grandhotel tw-text-center"
            >
                {{ __('common:empty_contract_index') }}
            </h1>
        </div>

        <ContractModal
            v-if="showContractModal"
            :contract="selectedContract"
            :staff="staff"
            @hidden="resetSelectedContract"
            @saved="onContractSaved"
        />

        <CModal
            ref="modalBroke"
            :header-title="__('hr_contract:break_contract')"
        >
            <div v-if="contractCopy">
                <CFormGroup>
                    <CLabel>
                        {{ __('hr_contract:broken_at') }}
                    </CLabel>
                    <CFormDatepicker
                        v-model="contractCopy.value.attributes.broked_at"
                        format="YYYY-MM-DD"
                    />
                </CFormGroup>
            </div>
            <template #footer>
                <div class="tw-flex tw-w-full">
                    <CButton
                        class="tw-ml-auto tw-mr-2"
                        variant="light"
                        @click="$refs.modalBroke.hide()"
                    >
                        {{ __('common:actions.cancel') }}
                    </CButton>
                    <CButton
                        variant="primary"
                        @click="broke"
                    >
                        {{ __('common:actions.break') }}
                    </CButton>
                </div>
            </template>
        </CModal>
    </div>
</template>

<script lang="ts">
    import type {PropType} from 'vue';
    import {computed, defineComponent, ref} from 'vue';
    import type StaffModel from '@/modules/human-resources/models/StaffModel';
    import ContractModal from '@/modules/human-resources/components/contract/Modals/ContractModal.vue';
    import ContractModel from '@/modules/human-resources/models/ContractModel';
    import {collect, Epoch} from '@meekohq/lumos';
    import _orderBy from 'lodash-es/orderBy';
    import _cloneDeep from 'lodash-es/cloneDeep';
    import Mirror from '@/modules/legacy/helpers/mirror.helper';
    import useAuth from '@/modules/app/composables/useAuth';
    import useEpoch from '@/modules/app/composables/useEpoch';
    import __ from '@/modules/app/utils/i18n-facade';
    import useMagicModal from '@/modules/app/composables/useMagicModal';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';

    export default defineComponent({
        components: {
            ContractModal,
        },
        props: {
            staff: {
                type: Object as PropType<StaffModel>,
            },
            nursery: {
                type: Object,
            },
        },
        setup(props) {
            const showContractModal = ref(false);
            const {legacyUser} = useAuth();
            const allowedOrga = collect(legacyUser.value.nurseries).keyBy('id').keys().all();
            const {parse} = useEpoch();

            // Get contracts in allowed organization.
            const getAllowedOrganizationsContracts = function(contract: ContractModel) {
                return contract.organizations().value();
            };

            const selectedContract = ref<ContractModel | null>(new ContractModel());
            const modalBroke = ref<null | { show: () => null; hide: () => null }>(null);
            const contractCopy = ref<Mirror>();

            function resetSelectedContract() {
                showContractModal.value = false;
                selectedContract.value = null;
            }

            function openContractModal(contract: ContractModel, duplicate: boolean) {
                if (contract.id) {
                    if (duplicate) {
                        const newContract = new ContractModel();
                        newContract.attributes = _cloneDeep(contract.attributes);
                        newContract.attributes.id = newContract.uuid();
                        selectedContract.value = newContract;
                    } else {
                        selectedContract.value = contract;
                    }
                } else {
                    selectedContract.value = new ContractModel();
                }
                showContractModal.value = true;
            }

            const filteredContracts = computed(() => {
                // Get contract value with its every relation.
                return _orderBy(props.staff?.contracts().value()?.all(), contract => contract.attributes.started_at, 'desc');
            });

            function openBrokeModal(contract: ContractModel) {
                contractCopy.value = new Mirror(contract);
                if (!contract.attributes.broked_at) {
                    contractCopy.value.value.attributes.broked_at = Epoch.now().toISOString();
                }
                modalBroke.value?.show();
            }

            function broke() {
                contractCopy.value?.value.save().then(() => {
                    contractCopy.value?.commit();
                    useNotification().success(__('hr_contract:contract_brooken_successfully'));
                    modalBroke.value?.hide();
                });
            }

            function remove(contract: ContractModel) {
                useMagicModal().deleteConfirmationModal({
                    text: __('hr_staff:wont_able_to_restore_data_warning'),
                }).then(result => {
                    if (result.isConfirmed) {
                        contract.delete().then(() => {
                            const contracts = props.staff?.contracts().value()?.all();
                            const contractToDelete = contracts?.find(
                                item => item.id === contract.id,
                            );

                            if (contractToDelete) {
                                const index = contracts?.indexOf(contractToDelete);

                                if (index !== undefined && index >= 0) {
                                    contracts?.splice(index, 1);
                                }
                            }
                            useNotification().success(__('hr_contract:contract_deleted_successfully'));
                        });
                    }
                });
            }

            function onContractSaved(contract: ContractModel) {
                const staffContract = props.staff?.contracts().value().first(c => {
                    return c.getKey() === contract.getKey();
                });

                if (staffContract) {
                    contract.copyTo(staffContract);
                } else {
                    props.staff?.contracts().value().push(contract);
                }
            }

            return {
                Epoch,
                filteredContracts,
                parse,
                openBrokeModal,
                remove,
                modalBroke,
                broke,
                contractCopy,
                selectedContract,
                openContractModal,
                getAllowedOrganizationsContracts,
                allowedOrga,
                resetSelectedContract,
                showContractModal,
                onContractSaved,
            };
        },
    });
</script>
