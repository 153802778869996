<template>
    <div>
        <hr class="hr">
        <div class="tw-mb-2">
            <FontAwesomeIcon
                class="tw-mr-2 tw-text-danger-500 tw-text-xl"
                icon="fad fa-exclamation-triangle"
            />
            <span v-html="__('tag:tag_already_exists_colon', {name: selectedTag.attributes.name})"/>
        </div>
        <div class="tw-flex tw-flex-col tw-gap-2">
            <div
                v-for="({tag, organizations}, index) in matchingInOtherOrganization"
                :key="'orga' + index"
                class="tw-rounded-lg tw-bg-gray-50 tw-p-4 tw-flex tw-flex-col tw-gap-4"
            >
                <div class="tw-flex tw-flex-wrap tw-items-center tw-justify-between tw-gap-2">
                    <div class="tw-flex tw-flex-row tw-items-center tw-gap-1.5">
                        <TagPill :tag="tag.attributes"/>
                        {{ tag.attributes.name }}
                    </div>
                    <CButton
                        :label="__('tag:import_this_tag')"
                        variant="link"
                        @click="$emit('attachTagToOrganization', tag)"
                    />
                </div>
                <div>
                    <MHeading
                        class="tw-mb-1"
                        level="h4"
                    >
                        {{ __('common:exists_on_colon') }}
                    </MHeading>
                    <ul class="tw-flex tw-flex-col tw-gap-1">
                        <li
                            v-for="(organization, i) in organizations"
                            :key="i + organization.attributes.name"
                        >
                            - {{ organization.attributes.name }}
                            <CBadge variant="blue">
                                {{ organization.attributes.address.city }}
                            </CBadge>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import {defineComponent, type PropType} from 'vue';
    import type TagModel from '@/modules/tag/models/TagModel';
    import TagPill from '@/modules/tag/components/TagPill.vue';

    export default defineComponent({
        components: {
            TagPill,
        },
        props: {
            selectedTag: {
                type: Object as PropType<TagModel>,
                required: true,
            },
            matchingInOtherOrganization: {
                type: Array,
                default: null,
            },
        },
    });
</script>
