<template>
    <div
        v-if="contracts.count()"
        class="tw-mb-2 tw-rounded-lg tw-text-base tw-relative tw-bg-blue-100 tw-text-blue-900 tw-p-3"
    >
        <div class="tw-px-0 tw-flex">
            <div :class="showEmployeesList ? 'tw-w-3/12' : 'tw-w-2/12'">
                <CText
                    font-weight="semibold"
                    :uppercase="true"
                >
                    {{ __('hr:job_one') }}
                </CText>
            </div>
            <template v-if="!showEmployeesList">
                <div class="tw-w-2/12">
                    <CText
                        font-weight="semibold"
                        :uppercase="true"
                    >
                        {{ __('hr:mentor') }}
                    </CText>
                </div>
                <div class="tw-w-1/12">
                    <CText
                        font-weight="semibold"
                        :uppercase="true"
                    >
                        {{ __('common:location') }}
                    </CText>
                </div>
            </template>
            <div
                v-if="showEmployeesList"
                class="tw-w-2/12"
            >
                <CText
                    font-weight="semibold"
                    :uppercase="true"
                >
                    {{ __('common:contract_type') }}
                </CText>
            </div>
            <div :class="showEmployeesList ? 'tw-w-2/12' : 'tw-w-1/12'">
                <CText
                    font-weight="semibold"
                    :uppercase="true"
                >
                    {{ __('common:entry') }}
                </CText>
            </div>
            <div :class="showEmployeesList ? 'tw-w-2/12' : 'tw-w-1/12'">
                <CText
                    font-weight="semibold"
                    :uppercase="true"
                >
                    {{ __('common:exit') }}
                </CText>
            </div>
            <div :class="showEmployeesList ? 'tw-w-2/12' : 'tw-w-1/12'">
                <CText
                    font-weight="semibold"
                    :uppercase="true"
                >
                    {{ __('common:broken') }}
                </CText>
            </div>
            <div class="tw-w-2/12">
                <CTooltip placement="left">
                    <CText
                        font-weight="semibold"
                        :uppercase="true"
                    >
                        {{ __('hr:qualifications') }}
                    </CText>
                    <template #content>
                        <CParagraph class="tw-w-36">
                            {{ __('hr:qualification_explanation') }}
                        </CParagraph>
                    </template>
                </CTooltip>
            </div>
            <div class="tw-w-1/12">
                <CText
                    font-weight="semibold"
                    :uppercase="true"
                >
                    {{ __('common:note_other') }}
                </CText>
            </div>
        </div>
        <hr class="hr tw-my-1 tw-border-gray-200">

        <CVStack gap="3">
            <StaffRegisterContractListItem
                v-for="contract in orderedContracts"
                :key="contract.id"
                :contract="contract"
                :selected-organizations-ids="selectedOrganizationsIds"
                :staff="staff"
            />
        </CVStack>
    </div>
</template>


<script lang="ts">
    import type {PropType} from 'vue';
    import {computed, defineComponent, ref} from 'vue';
    import type StaffModel from '@/modules/human-resources/models/StaffModel';
    import StaffRegisterContractListItem
        from '@/modules/human-resources/components/registry/List/StaffRegisterContractListItem.vue';
    import useStaffList from '@/modules/human-resources/composables/staff-register/useStaffList';

    export default defineComponent({
        components: {StaffRegisterContractListItem},
        props: {
            staff: {type: Object as PropType<StaffModel>, required: true},
            selectedOrganizationsIds: {required: true, type: Array as PropType<Array<string | number>>},
        },
        setup(props) {
            const contracts = ref(props.staff.contracts().value());
            const {showEmployeesList} = useStaffList();

            const orderedContracts = computed(() => contracts.value.sortBy(item => item.attributes.started_at));

            return {
                contracts,
                showEmployeesList,
                orderedContracts,
            };
        },
    });
</script>
