<template>
    <div class="tw-flex tw-flex-wrap tw-items-center md:tw-text-center tw-border-t tw-border-gray-200 tw-pt-2">
        <div class="tw-order-1 tw-w-1/2 md:tw-w-1/6 tw-my-2">
            <div class="tw-flex tw-items-center">
                <i
                    v-if="planning.success"
                    aria-hidden="true"
                    class="fa fa-check tw-text-green-500 tw-pl-2 tw-mr-3"
                />
                <CFormSelect
                    v-model="planning.type"
                    button-class="CButton-sm"
                    class="tw-w-full tw-mr-3"
                    :disabled="disabled"
                    :options="planningTypeOptions"
                    :search-bar="false"
                    :variant="planningTypeOptions.find(item => item.value === planning.type).variant"
                />
            </div>
        </div>
        <div class="tw-order-3 md:tw-order-2 tw-w-full md:tw-w-1/6 tw-my-2">
            <CFormDatepicker
                v-model="planning.started_at"
                class="tw-mr-3"
                :disabled="disabled"
            />
        </div>
        <div class="tw-order-4 md:tw-order-3 tw-w-1/2 md:tw-w-1/6 tw-my-2 tw-pr-3">
            <MTimePicker
                v-model="planning.start_time"
                class="tw-w-full"
                :disabled="disabled"
                :modifier="startOfMinute"
            />
        </div>
        <div class="tw-order-5 md:tw-order-4 tw-w-1/2 md:tw-w-1/6 tw-my-2 tw-pr-3">
            <MTimePicker
                v-model="planning.end_time"
                class="tw-w-full"
                :disabled="disabled"
                :modifier="startOfMinute"
            />
        </div>
        <div class="tw-order-6 md:tw-order-5 tw-w-full md:tw-w-1/6 tw-my-2">
            <CButton
                class="tw-w-full"
                :disabled="disabled"
                variant="light"
                @click="$refs.priceDropdown.toggle()"
            >
                <i
                    aria-hidden="true"
                    class="fa fa-pencil tw-text-sm tw-mr-2"
                />{{ totalText }}
            </CButton>
            <CDropdown
                ref="priceDropdown"
                class="tw-min-w-60 tw-max-w-96"
                legacy-mode
                placement="top"
            >
                <div v-if="planning.type === 'absence'">
                    <CFormGroup>
                        <CLabel>
                            {{ __('planning:how_to_deduct') }}
                        </CLabel>
                        <CButtonGroup>
                            <CButton
                                size="sm"
                                :variant="planning.unit === 'day' ? 'primary' : 'light'"
                                @click="planning.unit = 'day'"
                            >
                                {{ __('common:the_day') }}
                            </CButton>
                            <CButton
                                size="sm"
                                :variant="planning.unit === 'halfday' ? 'primary' : 'light'"
                                @click="planning.unit = 'halfday'"
                            >
                                {{ __('common:the_halfday') }}
                            </CButton>
                            <CButton
                                size="sm"
                                :variant="planning.unit === 'hour' ? 'primary' : 'light'"
                                @click="planning.unit = 'hour'"
                            >
                                {{ __('planning:units.by_the_hour') }}
                            </CButton>
                            <CButton
                                size="sm"
                                :variant="planning.unit === 'free' ? 'primary' : 'light'"
                                @click="planning.unit = 'free'"
                            >
                                {{ __('planning:units.non_deducted') }}
                            </CButton>
                        </CButtonGroup>
                    </CFormGroup>
                    <CFormGroup class="tw-mt-3">
                        <CCheckbox v-model="planning.vacation">
                            {{ __('planning:take_a_vacation_day_off') }}
                        </CCheckbox>
                    </CFormGroup>
                </div>
                <div v-else>
                    <CFormGroup class="tw-mb-4">
                        <CLabel>
                            {{ __('planning:how_to_bill_planning') }}
                        </CLabel>
                        <CButtonGroup>
                            <CButton
                                size="sm"
                                :variant="planning.unit === 'day' ? 'primary' : 'light'"
                                @click="planning.unit = 'day'"
                            >
                                {{ __('common:the_day') }}
                            </CButton>
                            <CButton
                                size="sm"
                                :variant="planning.unit === 'halfday' ? 'primary' : 'light'"
                                @click="planning.unit = 'halfday'"
                            >
                                {{ __('common:the_halfday') }}
                            </CButton>
                            <CButton
                                size="sm"
                                :variant="planning.unit === 'hour' ? 'primary' : 'light'"
                                @click="planning.unit = 'hour'"
                            >
                                {{ __('planning:units.by_the_hour') }}
                            </CButton>
                            <CButton
                                size="sm"
                                :variant="planning.unit === 'free' ? 'primary' : 'light'"
                                @click="planning.unit = 'free'"
                            >
                                {{ __('planning:units.free_female') }}
                            </CButton>
                        </CButtonGroup>
                    </CFormGroup>
                    <CFormGroup>
                        <div
                            v-if="planning.unit === 'hour'"
                            class="tw-flex tw-items-center"
                        >
                            <CInputGroup class="tw-mr-3">
                                <CInput
                                    v-model="planning.hours"
                                    size="sm"
                                />
                                <CInputAddon>{{ __('common:hour_lower_short') }}</CInputAddon>
                            </CInputGroup>

                            <CInputGroup class="tw-ml-3">
                                <CInput
                                    v-model="planning.hourly_rate"
                                    size="sm"
                                />
                                <CInputAddon class="tw-w-2/3">
                                    {{ organization.currency }} {{ __('common:per_hour_short') }}
                                </CInputAddon>
                            </CInputGroup>
                        </div>
                        <div v-else-if="planning.unit !== 'free'">
                            <CInputGroup class="tw-w-1/2">
                                <CInput
                                    v-model="planning.hourly_rate"
                                    size="sm"
                                />
                                <CInputAddon class="tw-w-2/3">
                                    {{ organization.currency }} / {{ __('common:hour_lower_short') }}
                                </CInputAddon>
                            </CInputGroup>
                        </div>
                    </CFormGroup>
                </div>
            </CDropdown>
        </div>
        <div class="tw-order-2 md:tw-order-6 tw-w-1/2 md:tw-w-1/6 tw-text-right tw-my-2">
            <CText
                v-if="planning.loading"
                class="tw-text-yellow-600 tw-pr-2"
                font-weight="semibold"
            >
                <i
                    aria-hidden="true"
                    class="fa fa-circle-notch fa-spin tw-mr-2"
                />{{ __('common:in_progress') }}
            </CText>
            <template v-else-if="planning.success">
                <CText
                    class="tw-pr-2"
                    font-weight="semibold"
                    variant="success"
                >
                    <i
                        aria-hidden="true"
                        class="fa fa-check tw-mr-2"
                    />{{ __('common:actions.added') }}
                </CText>
                <CTooltip placement="top">
                    <CButton
                        size="sm"
                        @click="$emit('remove')"
                    >
                        <i
                            aria-hidden="true"
                            class="fa fa-eye-slash"
                        />
                    </CButton>
                    <div slot="content">
                        {{ __('common:actions.hide') }}
                    </div>
                </CTooltip>
            </template>
            <CButton
                v-else
                :disabled="disabled"
                size="sm"
                variant="danger"
                @click="$emit('remove')"
            >
                <i
                    aria-hidden="true"
                    class="fa fa-trash-alt tw-mr-2"
                />{{ __('common:actions.delete') }}
            </CButton>
        </div>
        <CText
            v-if="planning.error"
            as="div"
            class="tw-order-7"
            font-size="sm"
            variant="danger"
        >
            <i
                aria-hidden="true"
                class="fad fa-exclamation-triangle tw-mr-1"
            />{{ planning.error }}
        </CText>
    </div>
</template>

<script lang="ts">
    import {computed, defineComponent, watch} from 'vue';
    import _cloneDeep from 'lodash-es/cloneDeep';
    import useManager from '@/modules/app/composables/useManager';
    import __ from '@/modules/app/utils/i18n-facade';
    import {useDateTimeModifiersPresets} from '@/modules/meeko-ui/composables/useDateTimeModifiers';

    export default defineComponent({
        props: {
            planning: {
                type: Object,
                required: true,
            },
        },
        setup(props, {emit}) {
            const {legacyNursery: organization} = useManager();
            const {startOfMinute} = useDateTimeModifiersPresets();

            const planningTypeOptions = [
                {
                    value: 'occasional',
                    text: __('common:occasional'),
                    variant: 'primary',
                },
                {
                    value: 'adaptation',
                    text: __('common:adaptation'),
                    variant: 'yellow',
                },
                {
                    value: 'absence',
                    text: __('common:absence'),
                    variant: 'danger',
                },
            ];

            watch(() => props.planning.type, (val, oldVal) => {
                const previousPlanning = _cloneDeep(props.planning);
                previousPlanning.type = oldVal;
                emit('change', props.planning, previousPlanning);
            });
            watch(() => props.planning.started_at, (val, oldVal) => {
                const previousPlanning = _cloneDeep(props.planning);
                previousPlanning.started_at = oldVal;
                emit('change', props.planning, previousPlanning);
            });

            watch(() => [props.planning.start_time, props.planning.end_time, props.planning.unit], () => {
                if (props.planning.unit === 'hour') {
                    props.planning.hours = getHours();
                }
            }, {immediate: true});

            function getHours() {
                if (!props.planning.start_time || !props.planning.end_time) {
                    return null;
                }

                return props.planning.start_time.difference(props.planning.end_time).as('hour');
            }

            const totalPrice = computed(() => {
                let total = props.planning.hourly_rate;
                const hoursInDay = props.planning.hours_in_day;

                switch (props.planning.unit) {
                    case 'day':
                        total = total * hoursInDay;
                        props.planning.hours = hoursInDay;
                        break;
                    case 'halfday':
                        total = total * hoursInDay / 2;
                        props.planning.hours = hoursInDay / 2;
                        break;
                    case 'hour':
                        total = total * props.planning.hours;
                        break;
                    case 'free':
                        total = 0;
                        break;
                    default:
                        total = 0;
                }

                return Math.round(total * 100) / 100;
            });

            const totalText = computed(() => {
                if (props.planning.type === 'absence') {
                    switch (props.planning.unit) {
                        case 'day':
                            return __('planning:units.deducted_day');
                        case 'halfday':
                            return __('planning:units.deducted_halfday');
                        case 'hour':
                            return __('planning:units.deducted_hours');
                        case 'free':
                            return __('planning:units.non_deducted');
                        default:
                            return '';
                    }
                }

                return totalPrice.value ? `${totalPrice.value}${organization.value.currency}` : __('planning:units.free_male');
            });

            const disabled = computed(() => {
                return props.planning.loading || props.planning.success;
            });

            return {
                startOfMinute,
                organization,
                planningTypeOptions,
                totalText,
                disabled,
            };
        },
    });
</script>
