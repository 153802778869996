<template>
    <MModal
        :header-title="__('dashboard:export_data')"
        :modal="modal"
        size="4xl"
        @hidden="onHide"
    >
        <CForm class="ExportsModal__filters">
            <CFormThreeColumns>
                <CFormGroup>
                    <CLabel>{{ __('dashboard:for_organizations_colon') }}</CLabel>
                    <OrganizationFinder
                        v-if="organizationsQueryBuilder"
                        v-model="selectedOrganizations"
                        button-class="tw-w-full"
                        :inject-query="organizationsQueryBuilder"
                        multi
                        :multi-minimum="1"
                    />
                </CFormGroup>
                <CFormGroup>
                    <CLabel>
                        {{ __('common:starting_from') }}
                    </CLabel>
                    <MDatePicker
                        v-model="fromDate"
                        format="yyyy-MM-dd"
                    />
                </CFormGroup>
                <CFormGroup>
                    <CLabel>
                        {{ __('common:end_until') }}
                    </CLabel>
                    <MDatePicker
                        v-model="toDate"
                        format="yyyy-MM-dd"
                    />
                </CFormGroup>
            </CFormThreeColumns>
        </CForm>

        <CFormErrorMessage v-if="!selectedOrganizations.length">
            {{ __('please_select_at_least_one_organization') }}
        </CFormErrorMessage>

        <div
            v-else
            class="ExportsModal__categories-container"
        >
            <div class="ExportsModal__categories-actions">
                <MButton @click="selectAll(true)">
                    <template #left-icons>
                        <FontAwesomeIcon icon="fa fa-check-circle"/>
                    </template>
                    {{ __('common:actions.select_all') }}
                </MButton>

                <MButton @click="selectAll(false)">
                    <template #left-icons>
                        <FontAwesomeIcon
                            class="tw-text-danger-500"
                            icon="fa fa-times-circle"
                        />
                    </template>
                    {{ __('common:actions.unselect_all') }}
                </MButton>
            </div>

            <div
                v-for="(cat, i) in allowedCategories"
                :key="'category' + i"
            >
                <MHeading
                    class="tw-mb-2"
                    level="h3"
                >
                    {{ cat.name }}
                </MHeading>

                <div class="ExportsModal__category-list">
                    <div
                        v-for="(item, j) in cat.items"
                        :key="'item' + j"
                    >
                        <div
                            class="ExportsModal__category-item"
                            :class="{'ExportsModal__category-item--active': includesExport(item.key, cat.key)}"
                            @click="toggleExport(item.key, cat.key)"
                        >
                            <img
                                v-if="item.image"
                                :alt="item.name"
                                :src="item.image"
                                :width="item.image_width"
                            >
                            <FontAwesomeIcon
                                v-else
                                :icon="item.icon"
                                :style="item.style"
                            />
                            {{ item.name }}
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <template #footer-end>
            <MButton
                :label="__('common:actions.cancel')"
                variant="light"
                @click="hideModal(true)"
            />
            <MButton
                v-if="loading && uniqId"
                :label="__('dashboard:send_by_email_when_ready')"
                variant="primary"
                @click="hideModal(false)"
            />
            <MButton
                :label="__('common:actions.export')"
                :loading="loading"
                variant="primary"
                @click="exportData"
            />
        </template>
    </MModal>
</template>

<script>
    import swal from 'sweetalert2/dist/sweetalert2.js';
    import route from '@/modules/legacy/libs/ziggy';
    import useApi from '@/modules/app/composables/useApi';
    import moment from 'moment';
    import _forEach from 'lodash-es/forEach';
    import _head from 'lodash-es/head';
    import useBroadcast from '@/modules/app/composables/useBroadcast';
    import __ from '@/modules/app/utils/i18n-facade';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';
    import {useExportCategories} from '@/modules/dashboard/components/useExportCategories';
    import OrganizationFinder from '@/modules/organization/components/OrganizationFinder.vue';
    import useAuth from '@/modules/app/composables/useAuth';
    import OrganizationModel from '@/modules/organization/models/OrganizationModel';
    import useManager from '@/modules/app/composables/useManager';

    export default {
        components: {
            OrganizationFinder,
        },
        props: {
            user: {},
            nursery: {},
            modal: {},
        },
        data() {
            return {
                selectedOrganizations: [],
                exportList: [],
                loading: false,
                uniqId: null,
                fromDate: moment()
                    .startOf('month')
                    .format('YYYY-MM-DD'),
                toDate: moment()
                    .endOf('month')
                    .format('YYYY-MM-DD'),
                organizationsQueryBuilder: null,
            };
        },
        computed: {
            /*
             * Calculates the intersection of permissions across selected organizations.
             *
             * Algorithm:
             * - Returns an empty array if no organizations are selected, as there are no permissions to consider.
             * - For a single selected organization, returns all its permissions directly, since there's no need to find common permissions.
             * - For multiple selected organizations, iteratively narrows down the list of permissions to those shared by all:
             *   1. Starts with the full set of permissions from the first organization.
             *   2. For each subsequent organization, retains only those permissions that are also present in this organization.
             */
            globalPermissions() {
                return this.selectedOrganizations.reduce((acc, org) => {
                    const nursery = this.user.nurseries.find(
                        item => item.id === org.getKey(),
                    );
                    if (!nursery) {
                        return acc;
                    }

                    /*
                     * Filters out permissions that have no actions associated. This is necessary because as of today,
                     * when we deselect all actions for a permission in the UI. Only the actions are removed, not the full permission
                     */
                    const nurseryPermissions = nursery.permissions.filter(permission => permission.actions?.length > 0);
                    if (acc.length === 0) {
                        return nurseryPermissions;
                    }

                    return acc.filter(item => {
                        return nurseryPermissions.some(innerItem => {
                            return innerItem.name === item.name;
                        });
                    });
                }, []);
            },
            categories() {
                return useExportCategories(this.globalPermissions).categories.value;
            },

            /*
             * An array of categories and their items that includes only categories for which the user has permission
             * and items that are not hidden when multiple nurseries are selected (if multiple nurseries are selected)
             */
            allowedCategories() {
                const allowedCategories = [];

                this.categories.forEach(category => {
                    const items = category.items.filter(item => {
                        return (
                            item.hasPermission &&
                            (!item.hideWhenMultipleNurseries ||
                                this.selectedOrganizations.length === 1)
                        );
                    });

                    if (items.length > 0) {
                        allowedCategories.push({
                            ...category,
                            items,
                        });
                    }
                });

                return allowedCategories;
            },
        },
        watch: {
            /*
             * Remove an export resource type from the selected list when it's not part of the allowed categories
             */
            allowedCategories(newAlloweds, oldAlloweds) {
                oldAlloweds.forEach(oldCategory => {
                    const newCategory = newAlloweds.find(
                        item => item.key === oldCategory.key,
                    );

                    if (!newCategory) {
                        oldCategory.items.forEach(oldItem => {
                            this.removeExport(oldItem.key, oldCategory.key);
                        });

                        return;
                    }

                    oldCategory.items.forEach(oldItem => {
                        const newItem = newCategory.items.find(
                            innerItem => innerItem.key === oldItem.key,
                        );

                        if (!newItem) {
                            this.removeExport(oldItem.key, oldCategory.key);
                        }
                    });
                });
            },
        },
        created() {
            this.init();
            this.startListening();
        },
        methods: {
            async init() {
                const {legacyUser} = useAuth();
                const {activeOrganization} = useManager();

                const allowedNurseries = legacyUser.value.nurseries.filter(nursery => {
                    if (legacyUser.value.is_owner) {
                        return true;
                    }

                    const userRessource = nursery.permissions.find(
                        item => item.name === 'export',
                    );
                    if (userRessource) {
                        const userAction = userRessource.actions.find(
                            item => item === 'read',
                        );

                        return !!userAction;
                    }

                    return false;
                });

                this.organizationsQueryBuilder = OrganizationModel.query().whereIn(
                    'id',
                    allowedNurseries.map(nursery => nursery.id),
                );

                this.selectedOrganizations = [activeOrganization.value];
            },

            startListening() {
                useBroadcast().sessionChannel.bind('export_data', response => {
                    const link = document.createElement('a');
                    link.href = response.url;
                    link.dispatchEvent(
                        new MouseEvent('click', {
                            bubbles: true,
                            cancelable: true,
                            view: window,
                        }),
                    );
                    this.loading = false;
                    this.confirmDownload(response.url);
                });
            },

            stopListening() {
                useBroadcast().sessionChannel.unbind('export_data');
            },

            exportData() {
                this.loading = true;
                this.uniqId = null;

                const resources = [];
                this.exportList.forEach(ex => {
                    resources.push({
                        name: ex.item,
                        filters: {
                            belongs_to: ex.category,
                            from: this.fromDate,
                            to: this.toDate,
                        },
                    });
                });

                useApi().legacy.post(route('export'), {
                    nurseries: this.selectedOrganizations.map(org => org.getKey()),
                    resources,
                })
                    .then(response => {
                        this.uniqId = response.data.id;
                    })
                    .catch(error => {
                        this.loading = false;
                        if (
                            error &&
                            error.response &&
                            error.response.status === 422
                        ) {
                            _forEach(error.response.data.errors, value => {
                                useNotification().error(_head(value));
                            });
                        } else {
                            useNotification().error(error);
                        }
                    });
            },

            sendByEmail() {
                if (!this.uniqId) {
                    return;
                }

                this.loading = false;

                useApi().legacy.post(
                    route('export.email', {
                        id: this.uniqId,
                    }),
                )
                    .catch(error => {
                        this.loading = false;
                        if (error?.response?.status === 422) {
                            _forEach(error.response.data.errors, value => {
                                useNotification().error(_head(value));
                            });
                        } else {
                            useNotification().error(error);
                        }
                    });
            },

            confirmDownload(url) {
                swal({
                    title: __('dashboard:download_starting'),
                    html: __('dashboard:click_here_if_nothing_happens', {url}),
                    type: 'success',
                    showCancelButton: false,
                    confirmButtonClass: 'btn btn-primary',
                    confirmButtonText: __('common:actions.close'),
                }).then(() => {
                    this.hideModal(true);
                });
            },

            toggleExport(item, category) {
                this.includesExport(item, category)
                    ? this.removeExport(item, category)
                    : this.addExport(item, category);
            },

            addExport(item, category) {
                this.exportList.push({
                    item,
                    category,
                });
            },

            removeExport(item, category) {
                const itemToDelete = this.includesExport(item, category);
                if (itemToDelete) {
                    const index = this.exportList.indexOf(itemToDelete);
                    this.exportList.splice(index, 1);
                }
            },

            includesExport(item, category) {
                return this.exportList.find(
                    ex => ex.item === item && ex.category === category,
                );
            },

            selectAll(select) {
                this.exportList = [];
                if (select) {
                    this.allowedCategories.forEach(cat => {
                        cat.items.forEach(item => {
                            this.exportList.push({
                                item: item.key,
                                category: cat.key,
                            });
                        });
                    });
                }
            },

            onHide() {
                this.stopListening();
                if (this.loading) {
                    this.sendByEmail();
                    useNotification().success(__('dashboard:data_going_to_sent_in_mail'));
                }
            },

            hideModal(cancel) {
                cancel ? (this.loading = false) : null;
                this.modal.hide();
            },
        },
    };
</script>

<style lang="scss" scoped>
    .ExportsModal__filters {
        @apply tw-mb-8;
    }

    .ExportsModal__categories-container {
        @apply tw-flex tw-flex-col tw-gap-4;
    }

    .ExportsModal__categories-actions {
        @apply tw-flex tw-flex-row tw-gap-2;
    }

    .ExportsModal__category-list {
        @apply tw-grid md:tw-grid-cols-4 tw-grid-cols-2 tw-gap-2;
    }

    .ExportsModal__category-item {
        @apply tw-cursor-pointer tw-border-2 tw-rounded-lg tw-p-2 tw-pl-3 tw-truncate tw-flex tw-flex-row tw-items-center tw-gap-2 tw-opacity-50 hover:tw-opacity-70;
    }

    .ExportsModal__category-item--active {
        @apply tw-border-primary-500 tw-opacity-100 #{!important};
    }
</style>
