<template>
    <MTooltip :label="tooltip">
        <CButton
            :to="to"
            variant="gray"
        >
            <FontAwesomeIcon icon="fas fa-cog"/>
        </CButton>
    </MTooltip>
</template>

<script lang="ts">
    import {defineComponent} from 'vue';
    import __ from '@/modules/app/utils/i18n-facade';

    export default defineComponent({
        props: {
            tooltip: {
                type: String,
                default: () => __('common:settings'),
                required: false,
            },
            to: {
                type: Object,
                required: true,
            },
        },
    });
</script>
