import {Model} from '@meekohq/lumos';
import OrganizationModel from '@/modules/organization/models/OrganizationModel';
import RequestTypeModel from '@/modules/request/models/RequestTypeModel';

export default class RequestTypeOrganizationPivotModel extends Model {
    public type = 'request/types_organizations_pivot';

    attributes: {
        id: string,
        account_id: string | undefined;
        request_type_id: string | undefined,
        organization_id: string | undefined,
        archived_at: string | undefined,
    } = {
            id: this.uuid(),
            account_id: undefined,
            request_type_id: undefined,
            organization_id: undefined,
            archived_at: undefined,
        };

    organization() {
        return this.belongsTo('organization', OrganizationModel, 'organization_id');
    }

    requestType() {
        return this.belongsTo('requestType', RequestTypeModel, 'request_type_id');
    }
}
