<template>
    <div v-if="organizations.isNotEmpty()">
        <CButton @click="$refs.organizationsFilter.toggle()">
            <i
                aria-hidden="true"
                class="fad fa-home tw-text-blue-500 tw-mr-2"
            />
            {{ __('hr_balance:filter.by_organization') }}
            <i
                aria-hidden="true"
                class="fa fa-caret-down tw-ml-1"
            />
        </CButton>
        <CDropdown
            ref="organizationsFilter"
            legacy-mode
        >
            <CList class="tw-cursor-pointer">
                <CListRow
                    v-for="organization in organizations.all()"
                    :key="organization.id"
                    @click="toggleOrganization(organization)"
                >
                    <i
                        v-if="value.find(org => org.id === organization.id)"
                        aria-hidden="true"
                        class="fa fa-check tw-mr-2"
                    />
                    <CBadge
                        v-if="organization.attributes.city"
                        class="tw-text-sm tw-font-semibold tw-text-blue-500 tw-whitespace-nowrap tw-mr-1"
                        size="sm"
                        variant="primary"
                    >
                        {{ organization.attributes.city }}
                    </CBadge>
                    {{ organization.attributes.name }}
                </CListRow>
            </CList>
        </CDropdown>
    </div>
</template>

<script lang="ts">
    import type {PropType} from 'vue';
    import {defineComponent, ref} from 'vue';
    import type {Collection} from '@meekohq/lumos';
    import {collect} from '@meekohq/lumos';
    import OrganizationModel from '@/modules/organization/models/OrganizationModel';

    export default defineComponent({
        name: 'OrganizationsFilter',
        props: {
            value: {type: Array as PropType<OrganizationModel[]>, required: true},
        },
        setup(props, {emit}) {
            const organizations = ref<Collection<OrganizationModel>>(collect());

            const fetchData = async function() {
                organizations.value = await OrganizationModel.query().get();
            };

            fetchData();

            function toggleOrganization(organization: OrganizationModel) {
                // If trying to unselect last selected organization, block action to have at least one organization selected
                // Else If organization already selected => unseled else seledt
                if (props.value?.length === 1 && props.value?.find(org => org.id === organization.id)) {
                    return;
                } else if (props.value?.find(org => org.id === organization.id)) {
                    emit('input', props.value?.filter(org => org.id !== organization.id));
                } else {
                    emit('input', [...props.value, organization]);
                }
            }

            return {
                organizations,
                toggleOrganization,
            };
        },
    });
</script>
