<template>
    <apexcharts
        v-if="chartOptions"
        :height="height"
        :options="chartOptions"
        :series="series"
        type="line"
    />
</template>

<script>
    import VueApexCharts from 'vue-apexcharts';

    export default {
        components: {
            apexcharts: VueApexCharts,
        },
        props: {
            height: {
                type: Number,
                default: 100,
            },
            nursery: {
                type: Object,
            },
            series: {
                type: Array,
            },
            chartData: {
                type: Object,
            },
        },
        data: () => ({
            chartOptions: null,
        }),
        mounted() {
            this.updateChart();
        },
        methods: {
            updateChart() {
                const self = this;
                this.chartOptions = {
                    chart: {
                        sparkline: {
                            enabled: true,
                        },
                        toolbar: {
                            show: false,
                        },
                    },
                    dataLabels: {
                        enabled: false,
                    },
                    grid: {
                        show: false,
                    },
                    xaxis: {
                        categories: self.chartData.categories,
                        type: 'category',
                        labels: {
                            show: true,
                        },
                    },
                    tooltip: {
                        x: {
                            show: true,
                        },
                        y: {
                            show: true,
                            formatter(val) {
                                if (val === null || typeof val === 'undefined') {
                                    return '';
                                }

                                return val + ' ' + self.nursery.currency;
                            },
                        },
                        marker: {
                            show: false,
                        },
                        custom({series, seriesIndex, dataPointIndex}) {
                            return '<h6 class="h6 tw-text-sm px-2 pt-1 m-0">' + self.chartData.categories[dataPointIndex] + '</h6>' +
                                '<div class="tw-text-base tw-font-semibold px-2 pb-1">' +
                                '<span>' + series[seriesIndex][dataPointIndex] + self.nursery.currency + '</span>' +
                                '</div>';
                        },
                    },
                    markers: {
                        size: 0,
                        colors: ['#FFF'],
                        fillOpacity: 1,
                        strokeOpacity: 0,
                        strokeWidth: 0,
                        radius: 0,
                        hover: {
                            size: 5,
                        },
                    },
                    stroke: {
                        curve: 'smooth',
                        colors: ['#FFF'],
                    },
                };
            },
        },
    };
</script>
