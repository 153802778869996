<template>
    <section class="SimplePageTemplate">
        <header class="SimplePageTemplate__header">
            <slot name="title"/>
            <div class="SimplePageTemplate__actions">
                <slot name="actions"/>
            </div>
        </header>
        <div class="SimplePageTemplate__content">
            <slot name="content"/>
        </div>
    </section>
</template>

<script lang="ts">
    export default {
        components: {},
        props: {
            variant: {type: String, default: 'base'},
        },
        setup() {
            return {};
        },
    };
</script>

<style lang="scss" scoped>
    .SimplePageTemplate__header {
        @apply tw-rounded-t-inherit;
        @apply tw-flex tw-flex-col md:tw-flex-row md:tw-items-center tw-justify-between tw-gap-6;
        @apply tw-mt-4 tw-mb-8;
        @apply print:tw-hidden;
    }

    .SimplePageTemplate__actions {
        @apply tw-flex tw-flex-wrap tw-gap-2 tw-items-center tw-justify-start md:tw-justify-end;
    }
</style>
