import type VueRouter from 'vue-router';
import useManager from '@/modules/app/composables/useManager';
import useAbility from '@/modules/app/composables/useAbility';

export default function subscribeToMeekoMiddleware(router: VueRouter) {
    const {legacyCustomer, legacyNursery, subscribed, onTrial} = useManager();
    const {waitUntilPermissionsLoaded} = useAbility();

    router.afterEach(async to => {
        // Check that the route requires subscription but not if one of its children does not require subscription
        if (
            to.matched.some(record => record.meta.requiresSubscribe === true)
            && !to.matched.some(record => record.meta.requiresSubscribe === false)
        ) {
            await waitUntilPermissionsLoaded();

            const mustSubscribe = legacyCustomer.value && !subscribed.value && !onTrial.value;

            if (mustSubscribe) {
                await router.push({name: 'subscriptions.plan', params: {nursery: legacyNursery.value.id}});
            }
        }
    });
}
