<template>
    <dl class="MListItemValue tw-group">
        <dt
            class="MListItemValue__title"
            :class="{
                'MListItemValue__title--is-hidden-on-desktop': hideTitleOnDesktop,
                'MListItemValue__title--is-hidden-on-tablet': hideTitleOnTablet,
            }"
        >
            <slot name="title"/>
        </dt>
        <dd class="MListItemValue__value">
            <template v-if="displayPopover">
                <MTooltip
                    class="!tw-block tw-w-full"
                    :hoverable="isTruncated"
                    placement="top"
                >
                    <div
                        ref="valueEl"
                        class="tw-truncate"
                    >
                        <slot/>
                    </div>
                    <template #content>
                        <div class="MListItemValue__popover">
                            <slot/>
                        </div>
                    </template>
                </MTooltip>
            </template>
            <template v-else>
                <slot/>
            </template>
        </dd>
    </dl>
</template>

<script lang="ts">
    import {defineComponent, ref, watch} from 'vue';
    import {useWindowSize} from '@vueuse/core';

    export default defineComponent({
        props: {
            hideTitleOnDesktop: {
                type: Boolean,
                default: false,
            },
            hideTitleOnTablet: {
                type: Boolean,
                default: false,
            },
            displayPopover: {
                type: Boolean,
                default: true,
            },
            value: {
                type: String,
                default: undefined,
                required: false,
            },
        },
        setup() {
            const valueEl = ref();
            const isTruncated = ref(false);

            const {width: windowWidth, height: windowHeight} = useWindowSize();

            watch([valueEl, windowWidth, windowHeight], () => {
                if (valueEl.value) {
                    isTruncated.value = valueEl.value.offsetWidth < valueEl.value.scrollWidth;
                }
            }, {immediate: true});

            return {
                valueEl,
                isTruncated,
            };
        },
    });
</script>

<style lang="scss" scoped>
    .MListItemValue {
        @apply tw-relative;
        @apply tw-flex tw-flex-row tw-items-center tw-gap-x-2;
        @apply lg:tw-flex-col;
    }

    .MListItemValue__title {
        @apply tw-text-gray-500 tw-font-display;
        @apply tw-shrink-0;
        @apply tw-truncate;

        @screen lg {
            @apply tw-pointer-events-none tw-absolute tw-inset-x-0 tw-text-xs;
            @apply tw-transition-all tw-ease-in-out;
            @apply tw-opacity-0 group-hover:tw-opacity-100;
            @apply tw-translate-y-0 group-hover:tw--translate-y-3;
        }
    }

    .MListItemValue__title--is-hidden-on-desktop {
        @apply lg:tw-hidden;
    }

    .MListItemValue__title--is-hidden-on-tablet {
        @apply md:tw-hidden;
    }

    .MListItemValue__value {
        @apply tw-w-full;
        @apply tw-truncate;
    }

    .MListItemValue__popover {
        @apply tw-whitespace-normal tw-w-max;
        max-width: 50vw;
    }
</style>
