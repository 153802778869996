<template>
    <CHStack
        align="center"
        distribute="between"
    >
        <MHeading level="h3">
            <slot/>
        </MHeading>
        <CHStack
            align="center"
            distribute="start"
            gap="4"
        >
            <CCheckbox
                :value="selectAllMail"
                @input="$emit('change:select-all-mail', $event)"
            >
                <FontAwesomeIcon
                    class="tw-text-cyan-600"
                    icon="fa fa-envelope"
                />
            </CCheckbox>
            <CCheckbox
                :value="selectAllBroadcast"
                @input="$emit('change:select-all-broadcast', $event)"
            >
                <FontAwesomeIcon
                    class="tw-text-yellow-600"
                    icon="fa fa-bell"
                />
            </CCheckbox>
        </CHStack>
    </CHStack>
</template>

<script lang="ts">
    import {defineComponent} from 'vue';

    export default defineComponent({
        props: {
            selectAllMail: {
                type: Boolean, required: true,
            },
            selectAllBroadcast: {
                type: Boolean, required: true,
            },
        },
    });
</script>
