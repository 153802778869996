<script setup lang="ts">
    import {computed, defineProps, ref, watch} from 'vue';
    import {filter} from 'lodash-es';
    import {collect, type Model} from '@meekohq/lumos';
    import type CustomerModel from '@/modules/cashier/models/CustomerModel';
    import ContactModel from '@/modules/cashier/models/ContactModel';
    import MemberModel from '@/modules/family/models/MemberModel';
    import EndpointModel from '@/modules/cashier/models/EndpointModel';
    import type OrderModel from '@/modules/cashier/models/OrderModel';
    import type TenantModel from '@/modules/cashier/models/TenantModel';
    import NotificationsOfflineSubscriptionToggle
        from '@/modules/cashier/components/core/notification/atoms/NotificationsOfflineSubscriptionToggle.vue';
    import CreateContactModal from '@/modules/cashier/components/core/contact/organisms/CreateContactModal.vue';
    import SubscriptionModel from '@/modules/cashier/models/SubscriptionModel';
    import OfflineEndpointSubscriberService
        from '@/modules/cashier/utils/core/notification/OfflineEndpointSubscriberService';
    import FamilyModel from '@/modules/family/models/FamilyModel';
    import NotificationsListItem
        from '@/modules/cashier/components/core/notification/molecules/NotificationsListItem.vue';
    import useModal from '@/modules/app/composables/useModal';

    const props = withDefaults(defineProps<{
        defaultEmail?: string;
        customerModel: CustomerModel;
        endpointModels: Model[];
        orderModel: OrderModel;
        tenantModel: TenantModel;
        autoSubcribeMember?: boolean;
        contract: any;
    }>(), {
        defaultEmail: undefined,
        autoSubcribeMember: false,
    });

    const emit = defineEmits(['endpointsChanged', 'loadingMember']);

    const topics = computed(() => {
        return collect(Object.values(SubscriptionModel.TOPICS));
    });

    const contactModels = ref<ContactModel[]>([]);
    const memberModels = ref<MemberModel[]>([]);

    const isModelsLoading = ref<boolean>(false);

    const endpointCollection = computed(() => {
        return [
            ...contactModels.value,
            ...memberModels.value,
        ];
    });

    const createContactModal = useModal({
        component: CreateContactModal,
        props: {
            customerModel: props.customerModel,
        },
        listeners: modal => ({
            created(event: ContactModel) {
                onContactCreated(event);
                modal.hide();
            },
        }),
    });

    const isResourceFirstOrder = computed(() => {
        return props.customerModel.orders().value().count() === 0
            || (
                props.customerModel.orders().value().count() === 1
                && props.customerModel.orders().value().first()?.getKey() === props.orderModel.getKey()
            );
    });

    watch(endpointCollection, models => {
        emit('endpointsChanged', models);
    }, {deep: true});

    watch(() => props.customerModel, async () => {
        isModelsLoading.value = true;
        await Promise.all([
            fetchContact(),
            getOrFetchMember(),
        ]);
        isModelsLoading.value = false;
    }, {immediate: true});

    async function getOrFetchMember() {
        if (props.endpointModels.length) {
            contactModels.value = filter(props.endpointModels, (model: Model) => {
                return model instanceof ContactModel;
            }) as ContactModel[];

            memberModels.value = filter(props.endpointModels, (model: Model) => {
                return model instanceof MemberModel;
            }) as MemberModel[];

            return;
        }

        MemberModel.query()
            .whereHas(new MemberModel().families(), query => {
                query.whereHas(new FamilyModel().customers(), query1 => {
                    query1.where('id', props.customerModel.getKey());
                });
            })
            .with(new MemberModel().notificationEndpoint(), query => {
                query.with(new EndpointModel().subscriptions());
            })
            .get()
            .then(response => {
                const models = response.all();
                models.forEach(memberModel => {
                    if (!memberModel.notificationEndpoint().value()) {
                        const endpointModel = new EndpointModel();
                        endpointModel.attributes.tenant_id = props.customerModel.attributes.tenant_id;
                        endpointModel.attributes.type = EndpointModel.TYPE.RESOURCE;
                        endpointModel.attributes.resource_type = memberModel.type;
                        endpointModel.attributes.resource_id = memberModel.getKey();
                        memberModel.notificationEndpoint().set(endpointModel);
                    }
                });

                memberModels.value = models;
                if (props.autoSubcribeMember) {
                    memberModels.value.forEach(memberModel => {
                        subscribeToNotification(memberModel as MemberModel);
                    });
                }
            });
    }

    async function fetchContact() {
        // Fixe le changement de tab sur les contact qui faisait refetch les contacts et donc qui nous faisait perdre les abonnements
        const contactModalAlreadySet = props.endpointModels?.some(model => {
            return model instanceof ContactModel && model.attributes.customer_id === props.customerModel.getKey();
        });

        if (contactModalAlreadySet) {
            return;
        }

        ContactModel.query()
            .where('customer_id', props.customerModel.getKey())
            .with(new ContactModel().notificationEndpoint(), query => {
                query.with(new EndpointModel().subscriptions());
            })
            .get()
            .then(response => {
                const models = response.all();
                models.forEach(contactModel => {
                    if (!contactModel.notificationEndpoint().value()) {
                        const endpointModel = new EndpointModel();
                        endpointModel.attributes.tenant_id = props.customerModel.attributes.tenant_id;
                        endpointModel.attributes.type = EndpointModel.TYPE.RESOURCE;
                        endpointModel.attributes.resource_type = contactModel.type;
                        endpointModel.attributes.resource_id = contactModel.getKey();
                        contactModel.notificationEndpoint().set(endpointModel);
                    }
                });

                contactModels.value = models;
            });
    }

    function onContactCreated(contactModel: ContactModel) {
        contactModel.fresh().then(response => {
            if (!response.notificationEndpoint().value()) {
                const endpointModel = new EndpointModel();
                endpointModel.attributes.tenant_id = props.customerModel.attributes.tenant_id;
                endpointModel.attributes.type = EndpointModel.TYPE.RESOURCE;
                endpointModel.attributes.resource_type = response.type;
                endpointModel.attributes.resource_id = response.getKey();
                contactModel.notificationEndpoint().set(endpointModel);
            }
            contactModels.value.unshift(contactModel);
        });
    }

    function subscribeToNotification(source: MemberModel | ContactModel): void {
        if (source.attributes.email) {
            const endpointModel = source.notificationEndpoint().value();

            if (isResourceFirstOrder.value) {
                const subscriberService = new OfflineEndpointSubscriberService(endpointModel, props.customerModel);

                topics.value.each(topic => {
                    if (!subscriberService.isSubscribedToTopic(topic)) {
                        subscriberService.subscribeToTopic(endpointModel, topic);
                    }
                });
            } else {
                const subscriberService = new OfflineEndpointSubscriberService(endpointModel, props.orderModel);

                topics.value
                    .filter(topic => topic !== SubscriptionModel.TOPICS.TAX_CERTIFICATE_SENT)
                    .each(topic => {
                        if (!subscriberService.isSubscribedToTopic(topic)) {
                            subscriberService.subscribeToTopic(endpointModel, topic);
                        }
                    });
            }

        }
    }
</script>

<template>
    <div>
        <div class="tw-flex sm:tw-flex-row tw-flex-col sm:tw-items-center tw-items-start tw-justify-between lg:tw-gap-3 tw-gap-1">
            <div class="tw-font-semibold">
                {{ __('billing_core:send_invoices_to_colon') }}
            </div>
            <div>
                <CButton
                    class="tw-whitespace-nowrap"
                    size="sm"
                    @click="createContactModal.show()"
                >
                    <FontAwesomeIcon
                        class="tw-text-blue-500 tw-mr-2"
                        icon="fa fa-plus"
                    />
                    {{ __('billing_customer:add_contact') }}
                </CButton>
            </div>
        </div>
        <loader
            v-if="isModelsLoading"
            class="col text-center"
        />
        <CList
            v-else
            class="tw-space-y-4 tw-mt-2"
        >
            <template v-if="contactModels.length || memberModels.length">
                <CListSection
                    v-if="contactModels.length"
                    variant="orange"
                >
                    <template #title>
                        {{ __('billing_customer:customer_account_contacts') }}
                    </template>
                    <CListRow
                        v-for="contactModel in contactModels"
                        :key="contactModel.getKey()"
                        :hover="false"
                    >
                        <NotificationsListItem :model="contactModel">
                            <NotificationsOfflineSubscriptionToggle
                                :customer-model="customerModel"
                                :resource-model="orderModel"
                                :source-model="contactModel"
                                @subscribe="subscribeToNotification($event)"
                            />
                        </NotificationsListItem>
                    </CListRow>
                </CListSection>
                <CListSection
                    v-if="memberModels.length"
                    variant="purple"
                >
                    <template #title>
                        {{ __('billing_core:members_family') }}
                    </template>
                    <CListRow
                        v-for="memberModel in memberModels"
                        :key="memberModel.getKey()"
                        :hover="false"
                    >
                        <NotificationsListItem :model="memberModel">
                            <NotificationsOfflineSubscriptionToggle
                                :customer-model="customerModel"
                                :resource-model="orderModel"
                                :source-model="memberModel"
                                @subscribe="subscribeToNotification($event)"
                            />
                        </NotificationsListItem>
                    </CListRow>
                </CListSection>
            </template>
            <div v-else>
                <div class="tw-text-center tw-p-4 tw-text-disabled">
                    {{ __('billing_customer:no_contact_available') }}
                </div>
            </div>
        </CList>
    </div>
</template>
