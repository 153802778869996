<template>
    <div
        class="card-chart"
        :class="{'settings': showSettings}"
    >
        <div class="chart-title">
            <div class="ml-1 mb-3">
                <div class="d-flex flex-row ml-1 mb-3">
                    <div class="d-flex align-items-center tw-text-base tw-font-semibold text-uppercase">
                        <i
                            aria-hidden="true"
                            class="fas fa-calendar-alt mr-2"
                        />{{ __('dashboard:occupation') }}
                    </div>
                    <div
                        class="d-flex align-items-center flex-row ml-auto tw-text-sm tw-font-semibold text-uppercase pointer"
                        @click="showSettings = !showSettings"
                    >
                        {{ __('common:longweek_with_number', {week: selectedWeek.clone().week()}) }}
                        <span
                            v-if="loading"
                            class="fas fa-circle-notch fa-spin ml-1"
                        />
                        <span
                            v-else
                            class="ml-1"
                        >
                            ({{ weekOccupationRate }}%)
                        </span>
                        <i
                            aria-hidden="true"
                            class="fas fa-pencil ml-2"
                        />
                    </div>
                </div>
            </div>
            <div class="actions">
                <div class="tw-grid tw-grid-cols-2 tw-gap-2">
                    <MMenu class="tw-w-full">
                        <MMenuButton class="tw-w-full">
                            <MButton
                                class="tw-w-full"
                                icon-menu
                                :label="selectedGroup.name"
                            />
                        </MMenuButton>
                        <MMenuItems>
                            <MMenuItem @click="selectedGroup = {id: null, name: __('common:all_groups')}">
                                {{ __('common:all_groups') }}
                            </MMenuItem>
                            <MMenuDivider/>
                            <MMenuItem
                                v-for="(group, i) in selectedNursery.groups"
                                :key="'nursery' + i"
                                @click="selectedGroup = group"
                            >
                                {{ group.name }}
                            </MMenuItem>
                        </MMenuItems>
                    </MMenu>
                    <MDropdown class="tw-w-full">
                        <MButton
                            class="tw-w-full"
                            icon-menu
                            :label="__('common:longweek_with_number', {week: selectedWeek.clone().week()})"
                        />
                        <template #content>
                            <MHeading
                                class="tw-text-center tw-mb-2"
                                level="h3"
                            >
                                {{ __('common:longweek_with_number', {week: selectedWeek.clone().week()}) }}
                            </MHeading>
                            <div class="tw-flex tw-items-center tw-gap-6 tw-mb-2">
                                <MButton
                                    variant="link"
                                    @click="selectedWeek = selectedWeek.clone().subtract(1, 'weeks')"
                                >
                                    <template #left-icons>
                                        <FontAwesomeIcon icon="fa fa-angle-left"/>
                                    </template>
                                    {{ __('common:longweek_with_number', {week: selectedWeek.clone().subtract(1, 'weeks').week()}) }}
                                </MButton>
                                <MButton
                                    variant="link"
                                    @click="selectedWeek = selectedWeek.clone().add(1, 'weeks')"
                                >
                                    {{ __('common:longweek_with_number', {week: selectedWeek.clone().add(1, 'weeks').week()}) }}
                                    <template #right-icons>
                                        <FontAwesomeIcon icon="fa fa-angle-right"/>
                                    </template>
                                </MButton>
                            </div>
                            <MHeading
                                class="tw-text-center"
                                level="h4"
                            >
                                {{ $formatDate(fromDay) }} - {{ $formatDate(toDay) }}
                            </MHeading>
                        </template>
                    </MDropdown>
                </div>
            </div>
        </div>
        <div class="chart-content">
            <div class="chart-container p-0">
                <loader
                    v-if="loading"
                    class="text-center"
                    light="true"
                    shadow="false"
                    size="sm"
                />
                <div
                    v-else-if="days.length === 0"
                    class="p-2 tw-text-gray-600"
                >
                    {{ __('dashboard:closed_for_selected_week') }}
                </div>
                <OccupationListByDay
                    v-else
                    :days="days"
                    :nursery="nursery"
                    :selected-nursery="selectedNursery"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import __ from '@/modules/app/utils/i18n-facade';
    import route from '@/modules/legacy/libs/ziggy';
    import useApi from '@/modules/app/composables/useApi';
    import moment from 'moment';
    import _head from 'lodash-es/head';
    import _forEach from 'lodash-es/forEach';
    import _round from 'lodash-es/round';
    import _sumBy from 'lodash-es/sumBy';
    import * as ProcessedData from '@/modules/legacy/libs/ProcessedData';
    import useBroadcast from '@/modules/app/composables/useBroadcast';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';
    import OccupationListByDay from '@/modules/dashboard/components/OccupationListByDay.vue';

    export default {
        components: {
            OccupationListByDay,
        },
        props: {
            nursery: {
                type: Object,
                required: true,
            },
        },
        data: () => ({
            date: moment(),
            selectedWeek: moment(),
            days: [],
            selectedNursery: {},
            selectedGroup: {},
            broadcast_id: Math.floor(100000 + Math.random() * 900000),
            loading: false,
            showSettings: false,
        }),
        computed: {
            moment() {
                return moment;
            },
            fromDay() {
                return this.date.clone().startOf('week');
            },
            toDay() {
                return this.date.clone().endOf('week');
            },
            weekOccupationRate() {
                const val = _round((_sumBy(this.days, 'percent') / this.days.length), 1);

                return val ? val : 0;
            },
        },
        watch: {
            selectedWeek(val) {
                this.date = moment(val).clone();
                this.getOccupation();
            },
            'selectedNursery.id': {
                handler() {
                    if (this.selectedNursery && this.selectedNursery.id) {
                        this.switchGroup();
                        this.getOccupation();
                    }
                },
                deep: true,
            },
            'nursery.id': {
                handler() {
                    this.selectedNursery = this.nursery;
                },
                deep: true,
            },
            'selectedGroup.id': {
                handler() {
                    this.getOccupation();
                },
            },
        },
        mounted() {
            this.selectedNursery = this.nursery;
            this.switchGroup();

            useBroadcast().sessionChannel.bind('occupation-' + this.broadcast_id, response => {
                ProcessedData.retrieve(response.processedDataUrl, response => {
                    this.days = [];
                    response.data.forEach(item => {
                        this.days.push({
                            date: item.date,
                            kids: item.kids,
                            percent: _round(item.percent),
                            series: [item.percent > 100 ? 100 : _round(item.percent)],
                            hours: _round(item.attendances / 60 / 60, 1),
                            totalHours: this.getDayHours(item.date),
                        });
                    });
                    this.loading = false;
                });
            });
        },
        beforeDestroy() {
            useBroadcast().sessionChannel.unbind('occupation-' + this.broadcast_id);
        },
        methods: {
            getOccupation() {
                if (this.loading) {
                    return;
                }

                this.loading = true;
                useApi().legacy.get(route('nurseries.occupation', {nurseries: this.selectedNursery.id}), {
                    params: {
                        from: this.fromDay.unix(),
                        to: this.toDay.unix(),
                        group_id: this.selectedGroup.id ? this.selectedGroup.id : null,
                        interval: 15,
                        broadcast_id: this.broadcast_id,
                    },
                }).catch(error => {
                    this.loading = false;
                    if (error && error.response && error.response.status === 422) {
                        _forEach(error.response.data.errors, value => {
                            useNotification().error(_head(value));
                        });
                    } else {
                        useNotification().error(error);
                    }
                });
            },

            switchGroup(group) {
                if (!group) {
                    this.selectedGroup = {
                        id: null,
                        name: __('common:all_groups'),
                    };
                } else {
                    this.selectedGroup = group;
                }

                if (this.selectedNursery && this.selectedNursery.id) {
                    this.getOccupation();
                }
            },

            getDayHours(day) {
                let hours = 0;
                const dayOfWeek = moment(day).clone().locale('en').format('ddd').toLowerCase();
                const nurseryDay = this.selectedNursery.openingHours.find(item => item.day === dayOfWeek);
                if (nurseryDay) {
                    hours = moment(nurseryDay.ended_at, 'HH:mm:ss').diff(moment(nurseryDay.started_at, 'HH:mm:ss'), 'minutes');
                    const places = this.selectedGroup.id ? this.selectedGroup.places : this.selectedNursery.places;
                    hours = hours * places;
                }

                return Math.round((hours / 60) * 100) / 100;
            },
        },
    };
</script>
