<template>
    <CCard>
        <template #header>
            <CCardTitle>
                <img
                    :alt="__('subscription:mandate_logo')"
                    class="mr-2"
                    src="@/modules/subscription/assets/sepa.svg"
                    width="40"
                >
                {{ __('subscription:save_iban') }}
            </CCardTitle>
        </template>
        <CAlert
            v-if="stripe.error"
            class="tw-mb-4"
            :close-button="false"
            variant="danger"
        >
            {{ stripe.error }}
        </CAlert>
        <CForm
            id="sepa-form"
            @submit.prevent="createCard"
        >
            <CFormTwoColumns>
                <CFormGroup>
                    <CLabel>{{ __('common:full_name') }}</CLabel>
                    <CInput
                        v-model="stripe.name"
                        :placeholder="__('common:full_name')"
                        required
                        size="lg"
                    />
                </CFormGroup>
                <CFormGroup>
                    <CLabel>{{ __('common:iban') }}</CLabel>
                    <div ref="elementRef"/>
                    <span class="tw-font-semibold tw-text-sm">{{ stripe.bankName }}</span>
                </CFormGroup>
            </CFormTwoColumns>
        </CForm>
        <template #footer>
            <CVStack gap="2">
                <CButton
                    class="tw-w-full"
                    form="sepa-form"
                    :loading="loader.isLoading()"
                    size="lg"
                    type="submit"
                    variant="primary"
                >
                    {{ __('subscription:save_the_iban') }}
                </CButton>
                <CText variant="disabled">
                    <CParagraph size="sm">
                        {{ __('subscription:by_providing_your_iban_you_authorize_etc') }}
                    </CParagraph>
                </CText>
            </CVStack>
        </template>
    </CCard>
</template>

<script lang="ts">
    import {defineComponent, reactive, ref} from 'vue';
    import useApi from '@/modules/app/composables/useApi';
    import route from '@/modules/legacy/libs/ziggy';
    import __ from '@/modules/app/utils/i18n-facade';
    import Loader from '@/modules/legacy/helpers/loader.helper';
    import {useElementVisibility, watchOnce} from '@vueuse/core';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';
    import {config} from '@meekohq/lumos';

    export default defineComponent({
        props: ['customer', 'email'],
        setup(props, {emit}) {
            // @ts-ignore
            const StripeJS = window.Stripe;
            const stripe = reactive({
                name: '',
                error: '',
                client: StripeJS(config('services.stripe.key')) as any,
                element: {} as any,
                bankName: '',
            });
            const loader = reactive(new Loader());

            const elementRef = ref(null);
            const targetIsVisible = useElementVisibility(elementRef);
            watchOnce(targetIsVisible, () => {
                initStripe();
            });

            const createCard = function() {
                loader.start();
                useApi().legacy.post(route('customers.sources.createSetupIntent', {
                    customer: props.customer.id,
                }), {}).then(response => {
                    stripe.client.confirmSepaDebitSetup(response.data.client_secret, {
                        payment_method: {
                            sepa_debit: stripe.element,
                            billing_details: {
                                name: stripe.name,
                                address: {
                                    line1: props.customer.address,
                                    city: props.customer.city,
                                    postal_code: props.customer.zipcode,
                                    country: props.customer.country,
                                },
                                email: props.email,
                            },
                        },
                    }).then(result => {
                        loader.stop();
                        // Handle result.error or result.setupIntent
                        if (result.error) {
                            stripe.error = result.error.message;
                        } else {
                            initStripe();
                            stripe.error = '';
                            emit('created');
                            useNotification().success(__('subscription:iban_saved'));

                            useApi().legacy.post(route('customers.sources.setDefault', {
                                customer: props.customer.id,
                            }), {
                                id: result.setupIntent.payment_method,
                            });
                        }
                    });
                });
            };

            const initStripe = function() {
                const elements = stripe.client.elements();

                const style = {
                    base: {
                        'color': '#212529',
                        'lineHeight': '27px',
                        'fontSmoothing': 'antialiased',
                        'fontSize': '16px',
                        '::placeholder': {
                            color: '#aab7c4',
                        },
                    },
                    invalid: {
                        color: '#dc3545',
                        iconColor: '#dc3545',
                    },
                };

                stripe.element = elements.create('iban', {
                    style,
                    supportedCountries: ['SEPA'],
                });

                if (elementRef.value) {
                    stripe.element.mount(elementRef.value);
                }

                stripe.element.on('change', event => {
                    if (event.error) {
                        stripe.error = event.error.message;
                    } else {
                        stripe.error = '';
                    }

                    if (event.bankName) {
                        stripe.bankName = event.bankName;
                    } else {
                        stripe.bankName = '';
                    }
                });
            };

            return {
                stripe,
                loader,
                elementRef,
                createCard,
            };
        },
    });
</script>
