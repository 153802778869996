<template>
    <WidgetPortal :click-outside="!preventClickOutside">
        <template #clickable="{toggle}">
            <CButton
                class="tw-relative"
                round
                zoom
                @click="toggle"
            >
                <font-awesome-icon
                    class="tw-text-info-500"
                    icon="fa-solid fa-list-check"
                />
                <div
                    v-if="unifiedCount"
                    class="tw-absolute tw--top-2 tw--right-2"
                >
                    <MNotificationBadge :count="unifiedCount"/>
                </div>
            </CButton>
        </template>
        <TodoWidgetTemplate>
            <template #nav>
                <TodoWidgetTabMenu
                    :requests-count="unreadRequestsCount"
                    :selected="selectedTab"
                    :tasks-count="tasksCount"
                    @select="selectTab($event)"
                />
            </template>
            <template #content>
                <keep-alive>
                    <TasksTab
                        v-if="can('read', 'tasks') && selectedTab === 'tasks'"
                        @preventClickOutside="preventClickOutside = $event"
                        @task-deleted="taskDeleted($event)"
                        @task-saved="taskSaved($event)"
                    />
                    <TeamsRequests
                        v-if="can('read', 'hr_request') && selectedTab === 'requests'"
                        @markAsRead="markAllRequestAsRead"
                        @preventClickOutside="preventClickOutside = $event"
                    />
                </keep-alive>
            </template>
        </TodoWidgetTemplate>
    </WidgetPortal>
</template>

<script lang="ts">
    import {computed, defineComponent, onMounted, ref, watch} from 'vue';
    import TasksTab from '@/modules/todo-widget/components/organisms/TasksTab.vue';
    import TodoWidgetTabMenu from '@/modules/todo-widget/components/organisms/TodoWidgetTabMenu.vue';
    import TodoWidgetTemplate from '@/modules/todo-widget/components/templates/TodoWidgetTemplate.vue';
    import TeamsRequests from '@/modules/request/components/TeamsRequests.vue';
    import useApi from '@/modules/app/composables/useApi';
    import useBroadcast from '@/modules/app/composables/useBroadcast';
    import route from '@/modules/legacy/libs/ziggy';
    import MNotificationBadge from '@/modules/meeko-ui/components/MNotificationBadge.vue';
    import useAbility from '@/modules/app/composables/useAbility';
    import useGetTasks from '@/modules/activity/composables/useGetTasks';
    import useNotificationStore from '@/modules/app/composables/useNotificationStore';
    import _debounce from 'lodash-es/debounce';
    import useAuth from '@/modules/app/composables/useAuth';
    import WidgetPortal from '@/modules/app/components/atoms/WidgetPortal.vue';

    export default defineComponent({
        components: {WidgetPortal, MNotificationBadge, TodoWidgetTemplate, TodoWidgetTabMenu, TasksTab, TeamsRequests},
        props: {},
        setup() {

            const {can} = useAbility();

            const {user} = useAuth();

            const preventClickOutside = ref(false);

            const {
                tasksCount,
                showVaccines,
                taskExpiredDaysLimit,
                setTaskCount,
                taskSaved,
                taskDeleted,
                setShowVaccines,
                setTaskExpiredDaysLimit,
            } = useNotificationStore();

            const showVaccinesFilter = ref<boolean>();

            const taskExpiredDaysLimitFilter = ref<number>();

            const unreadRequestsCount = ref(0);

            const selectedTab = ref(can('read', 'tasks') ? 'tasks' : 'requests');

            const unifiedCount = computed(() => unreadRequestsCount.value + tasksCount.value);

            const {ticketQuery, computeTicketQuery, waitForQueryToBeReady} = useGetTasks();

            async function getTaskCount() {
                if (!can('read', 'tasks')) {
                    return;
                }

                await waitForQueryToBeReady();

                computeTicketQuery();

                // Update the store on each fetch
                setTaskCount(await ticketQuery.value.count());
            }

            const markAllRequestAsRead = async function() {
                await useApi().legacy.post(route('notifications.markasread'), {
                    types: ['new-request'],
                });
                unreadRequestsCount.value = 0;
            };

            const countNotifications = function() {
                if (!can('read', 'hr_request')) {
                    return;
                }

                // Index notifications to get sum of unread notifications
                // Thanks to paginator
                useApi().legacy.get(route('notifications.index'), {
                    params: {
                        types: ['new-request'],
                        unread: 1,
                    },
                }).then(response => {
                    unreadRequestsCount.value = response.data.total;
                });
            };

            function selectTab(tab: string) {
                selectedTab.value = tab;
            }

            onMounted(async () => {
                // Load user settings
                const userSettings = await user.value.settings().load();

                // Get the vaccine filter from the user settings, if it's not set, we default to false
                showVaccinesFilter.value = userSettings.first(setting => setting.attributes.name === 'tasks:show_vaccines')?.attributes.value === true;

                const daysLimitUserSetting = userSettings.first(setting => setting.attributes.name === 'expired_days_limit');

                // Get the expired days limit from the user settings, if it's not set, we default to 3
                taskExpiredDaysLimitFilter.value = daysLimitUserSetting ? daysLimitUserSetting.attributes.value : 3;

                // Set the show vaccines filter value in the store to be shared with other components
                setShowVaccines(showVaccinesFilter.value);

                // Set the expired days limit filter value in the store to be shared with other components
                setTaskExpiredDaysLimit(taskExpiredDaysLimitFilter.value as number);

                countNotifications();

                useBroadcast().privateChannel.bind(useBroadcast().notificationEvent, () => countNotifications());
            });

            // When the user changes the settings, we have to refresh the tasks count
            watch([showVaccines, taskExpiredDaysLimit], _debounce(() => {
                getTaskCount();
            }, 500), {immediate: true});

            return {
                can,
                preventClickOutside,
                tasksCount,
                selectedTab,
                unreadRequestsCount,
                unifiedCount,
                taskSaved,
                taskDeleted,
                markAllRequestAsRead,
                selectTab,
            };
        },
    });
</script>
