import {ServiceProvider} from '@meekohq/lumos';
import RequestModel from '@/modules/request/models/RequestModel';
import RequestStageModel from '@/modules/request/models/RequestStageModel';
import RequestTypeModel from '@/modules/request/models/RequestTypeModel';
import RequestTypeUserPivotModel from '@/modules/request/models/RequestTypeUserPivotModel';
import RequestTypeOrganizationPivotModel from '@/modules/request/models/RequestTypeOrganizationPivotModel';
import RequestTypeStageModel from '@/modules/request/models/RequestTypeStageModel';

export default class RequestServiceProvider extends ServiceProvider {
    public boot() {
        this.loadModelsFrom([
            RequestModel,
            RequestStageModel,
            RequestTypeModel,
            RequestTypeUserPivotModel,
            RequestTypeOrganizationPivotModel,
            RequestTypeStageModel,
        ]);
    }
}
