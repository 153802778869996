<template>
    <CButtonGroup>
        <CButton
            :variant="isUrl('https://api.meeko.app') ? 'primary' : 'light'"
            @click="setUrl('production')"
        >
            Use Production API
        </CButton>
        <CButton
            :variant="isUrl('https://staging-api.meeko.app') ? 'primary' : 'light'"
            @click="setUrl('staging')"
        >
            Use Staging API
        </CButton>
        <CButton
            :variant="isUrl('http://localhost:8080') ? 'primary' : 'light'"
            @click="setUrl('local')"
        >
            Use Local API
        </CButton>
    </CButtonGroup>
</template>

<script lang="ts">
    import {defineComponent, ref} from 'vue';
    import {config} from '@meekohq/lumos';
    import {useStorage} from '@vueuse/core';

    export default defineComponent({
        props: {},
        setup() {
            const defaultUrl = ref(config('app.api_base_url'));
            const storedUrl = useStorage('cto_api_base_url', null);
            const storedPusherKey = useStorage('cto_pusher_key', null);

            function setUrl(mode) {
                let url;
                let pusherKey;

                if (mode === 'production') {
                    url = config('app.production_api_base_url');
                    pusherKey = config('services.pusher.productionKey');
                }

                if (mode === 'staging') {
                    url = config('app.staging_api_base_url');
                    pusherKey = config('services.pusher.stagingKey');
                }

                if (mode === 'local') {
                    url = config('app.local_api_base_url');
                    pusherKey = config('services.pusher.localKey');
                }

                storedUrl.value = url;
                storedPusherKey.value = pusherKey;
                window.location.reload();
            }

            function isUrl(url) {
                return storedUrl.value === url;
            }

            return {
                setUrl,
                isUrl,
                defaultUrl,
            };
        },
    });

</script>

<style lang="scss" scoped>

</style>
