<template>
    <div/>
</template>

<script lang="ts">
    import {defineComponent, onMounted} from 'vue';
    import {useRouter} from 'vue-router/composables';
    import type TransactionModel from '@/modules/cashier/transaction/domain/TransactionModel';
    import TransactionModalEdit from '@/modules/cashier/transaction/infrastructure/components/TransactionModalEdit.vue';
    import useModal from '@/modules/app/composables/useModal';

    export default defineComponent({
        setup(props, {emit}) {
            const router = useRouter();
            const route = router.currentRoute;

            function showEditTransactionModal() {
                useModal({
                    component: TransactionModalEdit,
                    props: {
                        canSelectCustomer: true,
                        transactionId: route.params.transaction,
                    },
                    listeners: () => ({
                        updated: (transaction: TransactionModel) => {
                            onUpdated(transaction);
                        },
                        hidden: () => {
                            redirect();
                        },
                    }),
                }).show();
            }

            onMounted(() => {
                showEditTransactionModal();
            });

            function redirect() {
                router.push({name: 'payments.index'});
            }

            function onUpdated(transaction: TransactionModel) {
                emit('updated', transaction);
            }

            return {};
        },
    });
</script>
