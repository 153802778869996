<template>
    <CVStack align="center">
        <CButtonGroup class="tw-w-full">
            <CFormSelect
                v-model="selectedCountry"
                :options="callCountries"
            />
            <CInput
                v-model="phoneNumber"
                :has-error="!!phoneNumber && !isValidPhone"
                type="tel"
            />
            <CFormSelect
                v-if="suggestedPhones.length"
                v-model="selectedSuggestedPhone"
                force-fallback
                :options="suggestedPhones"
                :search-bar="showSuggestedPhonesSearchBar"
                text-path="label"
                value-path="number"
            >
                <template #fallback>
                    <i
                        aria-hidden="true"
                        class="far fa-address-book"
                    />
                </template>
            </CFormSelect>
        </CButtonGroup>
    </CVStack>
</template>
<script lang="ts">
    import type {PropType} from 'vue';
    import {computed, defineComponent, nextTick, ref, watch} from 'vue';
    import type {CountryCode} from 'libphonenumber-js';
    import {getCountries, getCountryCallingCode, isValidPhoneNumber, parsePhoneNumber} from 'libphonenumber-js';
    import useManager from '@/modules/app/composables/useManager';

    export interface SuggestedPhone {
        label: string,
        number: string,
    }

    export default defineComponent({
        props: {
            value: {
                type: String,
                default: () => null,
            },
            suggestedPhones: {
                type: Array as PropType<SuggestedPhone[]>,
                required: false,
                default: () => [],
            },
            showSuggestedPhonesSearchBar: {
                type: Boolean,
                default: () => false,
            },
        },
        setup(props, {emit}) {
            const callCountries = ref(getCountries().map(country => {
                return {
                    text: `${country} +${getCountryCallingCode(country)}`,
                    value: country,
                };
            }));

            const {activeOrganization} = useManager();
            const countryCode = activeOrganization.value.attributes.address.country_code ?? 'FR';
            const selectedCountry = ref<CountryCode>(countryCode as CountryCode);

            const phoneNumber = ref(props.value ?? '');

            const isValidPhone = computed(() => isValidPhoneNumber(phoneNumber.value, selectedCountry.value));

            const selectedSuggestedPhone = ref(null);

            watch(phoneNumber, value => {
                nextTick(() => {
                    const sanitizedValue = cleanInvalidCharacters(value);

                    if (!sanitizedValue || sanitizedValue.length < 3) {
                        phoneNumber.value = sanitizedValue ?? '';

                        return;
                    }

                    phoneNumber.value = parsePhoneNumber(sanitizedValue, selectedCountry.value).format('NATIONAL');

                    emit('input', parsePhoneNumber(phoneNumber.value, selectedCountry.value).format('E.164'));
                });
            }, {immediate: true});

            watch(selectedSuggestedPhone, value => {
                if (value) {
                    phoneNumber.value = value;
                    // reset selectedSuggestedPhone to trigger reactivity next time if same phone is selected
                    selectedSuggestedPhone.value = null;
                }
            });

            function cleanInvalidCharacters(value: string) {
                // accept only possible phone number characters
                return value.match(/[()\-+0-9\s]*/g)?.join('');
            }

            return {
                phoneNumber,
                callCountries,
                isValidPhone,
                selectedCountry,
                selectedSuggestedPhone,
            };
        },
    });
</script>
