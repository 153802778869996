import {Model} from '@meekohq/lumos';
import type MemberRoleValue from '@/modules/family/utils/member/MemberRoleValue';

export default class KidMemberPivot extends Model {
    public type = 'family/kids_members_pivot';

    public attributes: {
        id: string;
        account_id: string | undefined;
        kid_id: string | undefined;
        family_member_id: string | undefined;
        role: MemberRoleValue | undefined;
        permissions: string[] | undefined;
        can_pickup_kid: number | undefined;
    } = {
            id: this.uuid(),
            account_id: undefined,
            kid_id: undefined,
            family_member_id: undefined,
            role: undefined,
            permissions: undefined,
            can_pickup_kid: undefined,
        };
}
