import type TicketModel from '@/modules/activity/ticket/domain/TicketModel';
import {readonly, ref} from 'vue';
import {Epoch, now} from '@meekohq/lumos';
import MedicalActionModel from '@/modules/health/models/MedicalActionModel';
import useUserStaff from '@/modules/human-resources/composables/staff/useUserStaff';

// Store the tasks count to be used in the notification badge
const tasksCount = ref(0);

// Store the value of the setting to include vaccines when counting or fetching tasks. It is also used to
// conditionally include or exclude the medical treatment option from the pivot resource type filter
const showVaccines = ref(false);

// Store the value of the setting that defines the number of days we add from today to determine
// the date boundary for tasks to be considered expiring
const taskExpiredDaysLimit = ref(0);

// Store the
const messagePending = ref(undefined);

const {userStaff} = useUserStaff();

/**
 * This function is used to manage the tasks count and react to changes in the settings and from created,
 * updated or deleted tasks.
 */
export default function() {
    /**
     * Update the tasks count when a task is saved
     * @param {TicketModel} task
     */
    function taskSaved(task: TicketModel) {
        // We always add even if we want to decrement counter because 1 + -1 = 0
        setTaskCount(tasksCount.value + getCountDiff(task));

        return task;
    }

    /**
     * Update the tasks count when a task is deleted
     * @param task
     */
    function taskDeleted(task: TicketModel) {
        // We always add even if we want to decrement counter because 1 + -1 = 0
        setTaskCount(tasksCount.value + getCountDiff(task));
    }

    /**
     * This function is used to determine if a task should be included in the tasks count and
     * returns an integer that can be used to increment or decrement the tasks count accordingly
     * @param task
     */
    function getCountDiff(task: TicketModel) {
        // we define variables for the data we need to determine if the task should be included in the tasks count
        const oldOriginalAttributes = task.extra.oldOriginalAttributes;

        const oldCompletedAt = oldOriginalAttributes?.completed_at;

        const newCompletedAt = task.attributes.completed_at;

        const dateLimit = now().endOfDay().addDays(taskExpiredDaysLimit.value).toJSDate();

        const newExpiredAt = Epoch.fromISOString(task.attributes.expired_at as string);

        const oldExpiredAt = oldOriginalAttributes?.expired_at ? Epoch.fromISOString(oldOriginalAttributes.expired_at as string) : null;

        const taskWasAttachedToAMedicalAction = task.extra.oldResourceTypes.contains(new MedicalActionModel().getType());

        const taskIsAttachedToAMedicalAction = task.ticketPivots().value().contains(new MedicalActionModel().getType());


        let taskWasIncludedInCounter = false;
        let taskShouldBeIncludedInCounter = false;

        // Check all the conditions that would make the task be included in the tasks count before
        if (!oldCompletedAt
            && oldExpiredAt?.lessThanOrEqualTo(dateLimit)
            && [userStaff.value?.getKey(), null].includes(oldOriginalAttributes?.assignee_id)
            && ((taskWasAttachedToAMedicalAction && showVaccines) || !taskWasAttachedToAMedicalAction)) {
            taskWasIncludedInCounter = true;
        }

        // Check all the conditions that would make the task be included in the tasks count now
        if (
            task.exists
            && !newCompletedAt
            && newExpiredAt.lessThanOrEqualTo(dateLimit)
            && [userStaff.value?.getKey(), null].includes(task.attributes.assignee_id)
            && ((taskIsAttachedToAMedicalAction && showVaccines) || !taskIsAttachedToAMedicalAction)) {
            taskShouldBeIncludedInCounter = true;
        }

        // If the task should be included and oldOriginalAttributes is not defined, the task is a new task
        // and should be included in the tasks count
        if (taskShouldBeIncludedInCounter && !oldOriginalAttributes) {
            return 1;
        }

        // If the task was not included in the tasks count before and is now, we increment the tasks count
        if (!taskWasIncludedInCounter && taskShouldBeIncludedInCounter) {
            return 1;
        }

        // If the task was included in the tasks count before and is not anymore, we decrement the tasks count
        if (taskWasIncludedInCounter && !taskShouldBeIncludedInCounter) {
            return -1;
        }

        return 0;
    }

    function setTaskCount(value: number) {
        tasksCount.value = value;
    }

    function setShowVaccines(value: boolean) {
        showVaccines.value = value;
    }

    function setTaskExpiredDaysLimit(value: number) {
        taskExpiredDaysLimit.value = value;
    }

    function setMessagePending(notification: any) {
        messagePending.value = notification;
    }

    return {
        taskSaved,
        taskDeleted,
        setTaskCount,
        setShowVaccines,
        setTaskExpiredDaysLimit,
        setMessagePending,
        tasksCount: readonly(tasksCount),
        showVaccines: readonly(showVaccines),
        taskExpiredDaysLimit: readonly(taskExpiredDaysLimit),
        messagePending: readonly(messagePending),
    };
}
