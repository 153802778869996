<template>
    <div
        v-if="kid"
        class="tw-h-72 tw-border tw-border-gray-200 tw-rounded-lg tw-bg-blue-50 tw-p-2"
    >
        <CSkeletonItem
            v-if="loading"
            class="tw-h-full "
        />
        <apexcharts
            v-else-if="weights.length > 1"
            height="100%"
            :options="chartOptions"
            :series="series"
            type="area"
        />
        <div
            v-else
            class="tw-h-full tw-flex tw-items-center tw-justify-center"
        >
            <div class="tw-text-center tw-font-medium">
                <div class="tw-text-blue-500 tw-text-lg tw-mb-1">
                    <i class="fad fa-chart-line-up tw-mr-2"/>{{ __('family_kid:not_enough_data_to_display_weight_trend') }}
                </div>
                <div class="tw-text-gray-600">
                    {{ __('family_kid:you_can_add_weights_from_the_meeko_team_health_menu') }}
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import {computed, defineComponent, onMounted, ref} from 'vue';
    import {Epoch, now} from '@meekohq/lumos';
    import useApi from '@/modules/app/composables/useApi';
    import useKidAge from '@/modules/family/composables/kid/useKidAge';
    import route from '@/modules/legacy/libs/ziggy';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';
    import __ from '@/modules/app/utils/i18n-facade';
    import {forEach, head, orderBy} from 'lodash-es';
    import VueApexCharts from 'vue-apexcharts';

    export default defineComponent({
        components: {
            apexcharts: VueApexCharts,
        },
        props: {
            kid: {
                type: Object,
                required: true,
            },
        },
        setup(props) {
            const loading = ref(false);
            const chartOptions = ref({});
            const series = ref<any[]>([]);
            const weights = ref<any[]>([]);

            const exportFilename = computed(() => {
                return __('family_kid:weight_trends', {name: props.kid.first_name});
            });

            onMounted(() => {
                getWeights();
            });

            function getWeights() {
                loading.value = true;

                const from = Epoch.fromISOString(props.kid.birthdate).startOfDay();
                const to = now().endOfDay();

                // If children is not born, don't call API (no weights, and most importantly no presence in planning)
                if (to.greaterThan(from)) {
                    useApi().legacy.get(
                        route('reporting', {
                            nurseries: props.kid.nursery_id,
                            reporting: 'weights',
                        }), {
                            params: {
                                kid_id: props.kid.id,
                                from: from.toTimestamp(),
                                to: to.toTimestamp(),
                            },
                        },
                    )
                        .then(response => {
                            weights.value = [];
                            response.data[0]?.weights.forEach((weight: any) => {
                                if (weight.done_at) {
                                    const birthDate = Epoch.fromISOString(props.kid.birthdate);
                                    const doneAt = Epoch.fromTimestamp(weight.done_at);

                                    weights.value.push({
                                        x: weight.done_at,
                                        y: weight.kilogram,
                                        note: weight.note,
                                        done_at: weight.done_at,
                                        age: useKidAge().getByBirthdate(birthDate, doneAt),
                                    });
                                }
                            });
                            weights.value = orderBy(weights.value, 'done_at');
                            series.value = [{
                                name: __('family_kid:weights_with_kg_unit'),
                                data: weights.value,
                            }];
                            loading.value = false;
                            updateChart();
                        })
                        .catch(error => {
                            loading.value = false;
                            if (error.response?.status === 422) {
                                forEach(error.response.data?.errors, value => {
                                    useNotification().error(head(value));
                                });
                            } else {
                                throw new Error(error);
                            }
                        });
                } else {
                    loading.value = false;
                }
            }

            function updateChart() {
                chartOptions.value = {
                    chart: {
                        defaultLocale: 'fr',
                        locales: [{
                            name: 'fr',
                            options: {
                                toolbar: {
                                    exportToSVG: __('common:exports.export_to_svg'),
                                    exportToPNG: __('common:exports.export_to_png'),
                                    exportToCSV: __('common:exports.export_to_csv'),
                                    download: __('common:actions.download'),
                                },
                            },
                        }],
                        toolbar: {
                            show: true,
                            tools: {
                                selection: false,
                                zoom: false,
                                zoomin: false,
                                zoomout: false,
                                pan: false,
                                reset: false,
                            },
                            export: {
                                csv: {
                                    filename: exportFilename.value,
                                    columnDelimiter: ',',
                                    headerCategory: 'Date',
                                },
                                svg: {
                                    filename: exportFilename.value,
                                },
                                png: {
                                    filename: exportFilename.value,
                                },
                            },
                        },
                        zoom: {
                            enabled: false,
                        },
                    },
                    legend: {
                        show: false,
                    },
                    title: {
                        text: exportFilename.value,
                    },
                    grid: {
                        show: false,
                    },
                    stroke: {
                        width: 3,
                        curve: 'smooth',
                    },
                    xaxis: {
                        offsetX: 1,
                        axisBorder: {
                            show: true,
                            height: 2,
                        },
                        tooltip: {
                            enabled: false,
                        },
                        labels: {
                            style: {
                                colors: '#888',
                            },
                            formatter(value: number) {
                                return Epoch.fromTimestamp(value).toLocaleString(Epoch.presets.DATE_SHORT);
                            },
                        },
                        type: 'datetime',
                    },
                    yaxis: {
                        axisBorder: {
                            show: false,
                        },
                        axisTicks: {
                            show: false,
                        },
                        labels: {
                            show: false,
                        },
                    },
                    dataLabels: {
                        enabled: true,
                        offsetY: -8,
                        formatter(val: number) {
                            return val + ' kg';
                        },
                    },
                    colors: ['#7372F4'],
                    fill: {
                        type: 'gradient',
                        gradient: {
                            shadeIntensity: 0.8,
                            opacityFrom: 0.4,
                            opacityTo: 1,
                        },
                    },
                    tooltip: {
                        custom({series: chartSeries, seriesIndex, dataPointIndex}) {
                            const doneAt = Epoch.fromTimestamp(weights.value[dataPointIndex].done_at).toLocaleString(Epoch.presets.DATE_SHORT);
                            let customTooltip = `<div class="tw-font-semibold tw-text-sm tw-border-b tw-border-gray-200 tw-px-2 tw-py-1"> ${doneAt} (${weights.value[dataPointIndex].age}) </div>
                                <div class="tw-text-purple-700 tw-font-semibold tw-text-sm tw-px-2 tw-py-1"> ${chartSeries[seriesIndex][dataPointIndex]} kg</div>`;

                            if (weights.value[dataPointIndex].note) {
                                customTooltip += `<div class="tw-text-gray-500 tw-text-sm tw-px-2 tw-pb-1"> ${weights.value[dataPointIndex].note} </div>`;
                            }

                            return customTooltip;
                        },
                    },
                    markers: {
                        size: 4,
                        strokeWidth: 0,
                        hover: {
                            size: 8,
                        },
                    },
                };
            }

            return {
                loading,
                weights,
                chartOptions,
                series,
            };
        },
    });
</script>
