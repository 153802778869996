import RequestTypeUserPivotModel from '@/modules/request/models/RequestTypeUserPivotModel';
import RequestTypeModel from '@/modules/request/models/RequestTypeModel';
import type {ChannelType} from '@/modules/user/utils/ReleaseChannel';
import ReleaseChannel from '@/modules/user/utils/ReleaseChannel';
import {app, Model} from '@meekohq/lumos';
import {trim} from 'lodash-es';
import OrganizationModel from '@/modules/organization/models/OrganizationModel';
import OrganizationUserPivot from '@/modules/organization/models/OrganizationUserPivot';
import UserSettingModel from '@/modules/user/models/UserSettingModel';
import type LegacyUserGate from '@/modules/user/utils/LegacyUserGate';
import useAuth from '@/modules/app/composables/useAuth';
import useManager from '@/modules/app/composables/useManager';
import type {ActionsType, SubjectsType} from '@/modules/user/utils/ActionsType';
import {Types} from '@/types';

export default class UserModel extends Model {
    public type = 'user/users';

    public attributes: {
        id: string;
        account_id: string | undefined;
        first_name: string | undefined;
        last_name: string | undefined;
        email: string | undefined;
        password: string | undefined;
        is_owner: number | undefined;
        is_device: number | undefined;
        via_mail: string[] | undefined;
        via_broadcast: string[] | undefined;
        device_version: string | undefined;
        device_name: string | undefined;
        device_policies: string[] | undefined;
        release_channel: ChannelType;
        locale: string | undefined;
    } = {
            id: this.uuid(),
            account_id: undefined,
            first_name: undefined,
            last_name: undefined,
            email: undefined,
            password: undefined,
            is_owner: undefined,
            is_device: undefined,
            via_mail: undefined,
            via_broadcast: undefined,
            device_version: undefined,
            device_name: undefined,
            device_policies: undefined,
            release_channel: 'stable',
            locale: undefined,
        };

    get fullname() {
        return trim(this.attributes.first_name + ' ' + this.attributes.last_name);
    }

    get releaseChannel() {
        return new ReleaseChannel([this.attributes.release_channel]);
    }

    requestTypes() {
        return this.belongsToMany('requestTypes', RequestTypeModel, RequestTypeUserPivotModel, 'user_id', 'request_type_id');
    }

    organizations() {
        return this.belongsToMany('organizations', OrganizationModel, OrganizationUserPivot, 'user_id', 'organization_id');
    }

    settings() {
        return this.hasMany('settings', UserSettingModel, 'user_id');
    }

    /**
     * Determine if the entity has the given abilities.
     * @param abilities
     * @param args
     */
    public can(abilities: ActionsType | ActionsType[], args: SubjectsType) {
        const {legacyUser} = useAuth();
        const {activeOrganization} = useManager();

        return app<LegacyUserGate>(Types.Gate).for(legacyUser.value, activeOrganization.value).check(abilities, args);
    }

    /**
     * Determine if the entity has any of the given abilities.
     * @param abilities
     * @param args
     */
    public canAny(abilities: ActionsType | ActionsType[], args: SubjectsType) {
        const {legacyUser} = useAuth();
        const {activeOrganization} = useManager();

        return app<LegacyUserGate>(Types.Gate).for(legacyUser.value, activeOrganization.value).any(abilities, args);
    }

    /**
     * Determine if the entity does not have the given abilities.
     * @param abilities
     * @param args
     */
    public cant(abilities: ActionsType | ActionsType[], args: SubjectsType) {
        return !this.can(abilities, args);
    }
}
