import type {MqlSetup} from '@meekohq/lumos';
import {app, config, Container, lumosVersion, ServiceProvider} from '@meekohq/lumos';
import createLegacyAxiosInstance from '@/modules/app/utils/createLegacyAxiosInstance';
import setupAxiosInstance from '@/modules/app/utils/setupAxiosInstance';
import type {AxiosInstance} from 'axios';
import {Types} from '@/types';

export default class HttpServiceProvider extends ServiceProvider {
    public boot() {
        app<MqlSetup>(Container.contracts.httpMql).interceptors.document.request.use(document => {
            document.meta = {
                app: 'Manager',
                app_version: config('app.version'),
                client: 'Lumos/' + lumosVersion,
            };
        });

        app<MqlSetup>(Container.contracts.httpMql).interceptors.operation.request.use(operation => {
            operation.meta = {
                source_url: window.location.href,
            };
        });
    }

    public register() {
        this.app.singleton<AxiosInstance>(Types.Api).toCallback(app1 => {
            // Retrieve repository axios instance from Lumos
            const api = app1.make<AxiosInstance>(Container.contracts.httpMqlAxios);

            // Setup instance with base url, interceptors, etc.
            setupAxiosInstance(api);

            return api;
        });

        this.app.singleton(Types.LegacyApi).to(createLegacyAxiosInstance());
    }
}
