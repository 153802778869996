<template>
    <MagicIndex
        :filter-count="activeCount"
        :loading="isLoading"
        :paginator="paginator"
        variant="family"
        @reset-filters="resetFilters"
    >
        <template #filters>
            <MagicIndexSearchInputFilter
                v-model="search.data.value"
                :placeholder="__('document:search_document_dots')"
            />
            <TagsAttached
                filter
                :selected-tags.sync="selectedTags"
                size="sm"
                taggable-type="document"
                :tags="activeOrganization.tags().value()"
            />
        </template>
        <template #content="{hasResults}">
            <div class="DocumentList__content">
                <transition
                    mode="out-in"
                    name="transition-fade"
                >
                    <MagicIndexLoader
                        v-if="isLoading"
                        key="loader"
                    />
                    <div
                        v-else-if="hasResults"
                        class="DocumentList__list"
                    >
                        <template v-for="(item, index) in paginator?.items()">
                            <DocumentItem
                                :key="index"
                                :document="item"
                                :organization="activeOrganization"
                                :permission-key="permissionKey"
                                :show-organizations="showOrganizations"
                                :show-visibilities="showVisibilities"
                                :user="user"
                                @update="editItemFn($event)"
                            />
                        </template>
                    </div>
                    <MagicIndexEmptyList v-else>
                        <template #empty-list>
                            {{ __('common:empty_result') }}
                        </template>
                        <template #empty-list-action>
                            {{ __('common:try_modifying_filters_or_adding_data') }}
                        </template>
                    </MagicIndexEmptyList>
                </transition>
                <MagicIndexPaginator
                    v-if="paginator"
                    :paginator="paginator"
                    @change="updatePagination($event)"
                />
            </div>
        </template>
    </MagicIndex>
</template>

<script lang="ts">
    import type {PropType, Ref} from 'vue';
    import {defineComponent, onBeforeMount, ref, watch} from 'vue';
    import TagsAttached from '@/modules/tag/components/TagsAttached.vue';
    import MagicIndexPaginator from '@/modules/magic-index/components/molecules/MagicIndexPaginator.vue';
    import MagicIndexLoader from '@/modules/magic-index/components/atoms/MagicIndexLoader.vue';
    import MagicIndexEmptyList from '@/modules/magic-index/components/atoms/MagicIndexEmptyList.vue';
    import MagicIndex from '@/modules/magic-index/components/organisms/MagicIndex.vue';
    import DocumentItem from '@/modules/document/components/molecules/DocumentListItem.vue';
    import useAuth from '@/modules/app/composables/useAuth';
    import useManager from '@/modules/app/composables/useManager';
    import {useRoute} from 'vue-router/composables';
    import type DocumentModel from '@/modules/document/models/DocumentModel';
    import type {Model, ModelCollection} from '@meekohq/lumos';
    import {collect, type Collection} from '@meekohq/lumos';
    import useAbility from '@/modules/app/composables/useAbility';
    import type TagModel from '@/modules/tag/models/TagModel';
    import useFetchDocumentAction from '@/modules/document/composables/useFetchDocumentAction';
    import type {Emitter} from 'mitt';
    import mitt from 'mitt';
    import type {VisibilitiesType} from '@/modules/document/stores/useDocumentStore';
    import type OrganizationModel from '@/modules/organization/models/OrganizationModel';
    import MagicIndexSearchInputFilter from '@/modules/magic-index/components/atoms/MagicIndexSearchInputFilter.vue';
    import useDocumentsFiltersStore from '@/modules/document/composables/useDocumentsFiltersStore';
    import {syncRef} from '@vueuse/core';
    import {until} from '@vueuse/core/index';

    export type BusType = {
        documentCreated: DocumentModel,
        documentUpdated: DocumentModel,
        documentDeleted: DocumentModel,
    };

    export default defineComponent({
        components: {
            MagicIndexSearchInputFilter,
            TagsAttached,
            MagicIndexPaginator,
            MagicIndexLoader,
            MagicIndexEmptyList,
            MagicIndex,
            DocumentItem,
        },
        props: {
            relatedResource: {
                type: Object as PropType<Model>,
                default: undefined,
            },
            permissionKey: {
                type: String,
                default: 'documents',
            },
            queryStringParam: {
                type: String,
                default: 'p',
            },
            bus: {
                type: Object as PropType<Emitter<BusType>>,
                default: () => mitt(),
            },
            editItemFn: {
                type: Function,
                required: true,
            },
            showVisibilities: {
                type: Object as PropType<VisibilitiesType>,
                required: false,
                default: () => ({
                    staffs_access: false,
                    parents_access: false,
                    web_access: false,
                }),
            },
            showOrganizations: {
                type: Boolean,
                default: false,
            },
        },
        setup(props) {
            const {user} = useAuth();
            const {activeOrganization} = useManager();

            const route = useRoute();

            const {can} = useAbility();

            const filtersStore = useDocumentsFiltersStore({
                search: {},
                tags: {},
                page: {},
            });

            const {
                searchFilter: search,
                reset: resetFilters,
                tagsFilter,
                activeCount,
                pageFilter,
            } = filtersStore;


            const selectedTags = ref<Collection<TagModel>>(collect([]));

            /*
             Sync the selected tags with the tagsFilter and transform the data to a Collection or an array.
             The transform is necessary because the tagsFilter (MagicFilter) expects an array and selectedTags a Collection for
             the TagsAttached component.
            */
            syncRef(tagsFilter.data, selectedTags, {
                transform: {
                    ltr: left => {
                        return collect(left) as Collection<TagModel>;
                    },
                    rtl: right => {
                        return right.toArray();
                    },
                },
            });

            const showModal = ref(false);

            const documentableTypeToConstrainTo = ref(props.relatedResource?.getType());
            const documentableIdToConstrainTo = ref(props.relatedResource?.getKey());

            const {
                debounceGetDocuments,
                paginator,
                isLoading,
                initWatch,
                updatePagination,
            } = useFetchDocumentAction({
                organizationsToConstrainTo: ref(collect([activeOrganization.value])) as Ref<Collection<OrganizationModel>>,
                documentableTypeToConstrainTo,
                documentableIdToConstrainTo,
                search: search.data,
                tagsToConstrainTo: (selectedTags as Ref<ModelCollection<TagModel>>),
            }, pageFilter.data);

            // If the related resource changes, update the documentableTypeToConstrainTo and documentableIdToConstrainTo.
            // This will trigger a new fetch of documents.
            watch(() => props.relatedResource, () => {
                documentableTypeToConstrainTo.value = props.relatedResource?.getType();
                documentableIdToConstrainTo.value = props.relatedResource?.getKey();
            });

            until(filtersStore.isReady).toBe(true).then(async () => {
                initWatch();

                await debounceGetDocuments();
            });

            onBeforeMount(async () => {
                isLoading.value = true;
            });

            props.bus.on('documentCreated', async (document: DocumentModel) => {
                const documentIsInOrganization = document.organizations().value().contains(organization => {
                    return organization.getKey() === activeOrganization.value.getKey();
                });

                if (!documentIsInOrganization) {
                    return;
                }

                // If the current page is not the first page or in the current organization, then we need to fetch the documents again.
                if (paginator.value?.currentPage() !== 1) {
                    await debounceGetDocuments();
                } else {
                    // We add the document to the paginator items.
                    paginator.value?.items().prepend(document);

                    // If the number of items is greater than the per page, then we need to remove the last item.
                    if (paginator.value?.items().count() > paginator.value?.perPage()) {
                        paginator.value?.items().pop();
                    }
                }
            });

            props.bus.on('documentUpdated', async (document: DocumentModel) => {
                const documentIsInOrganization = document.organizations().value().contains(organization => {
                    return organization.getKey() === activeOrganization.value.getKey();
                });

                if (!documentIsInOrganization) {
                    const documentIndex = paginator.value?.items().search((item: DocumentModel) => {
                        return item.getKey() === document.getKey();
                    });

                    // Mutate the paginator items to remove the document.
                    paginator.value?.items().forget(documentIndex);
                }

                const localDocument = paginator.value?.items().first(item => {
                    return item.getKey() === document.getKey();
                });

                if (localDocument) {
                    // We refresh the tags before copying the updated document so as not to lose the tags
                    await document.tags().fresh();
                    document.copyTo(localDocument);
                }
            });

            props.bus.on('documentDeleted', async (document: DocumentModel) => {
                const documentIndex = paginator.value?.items().search((item: DocumentModel) => {
                    return item.getKey() === document.getKey();
                });

                // Mutate the paginator items to remove the document.
                paginator.value?.items().forget(documentIndex);
            });

            return {
                user,
                route,
                activeOrganization,
                search,
                selectedTags,
                activeCount,
                paginator,
                isLoading,
                showModal,
                can,
                updatePagination,
                resetFilters,
            };
        },
    });
</script>

<style lang="scss" scoped>
    .DocumentList__content {
        @apply tw-w-full tw-flex tw-flex-col tw-p-4 tw-gap-2;
    }

    .DocumentList__list {
        @apply tw-w-full;
        @apply tw-grid xl:tw-grid-cols-4 lg:tw-grid-cols-3 md:tw-grid-cols-2 tw-grid-cols-1;
        @apply tw-gap-4;
    }
</style>
