import _round from 'lodash-es/round';

export default class NumberValue {
    protected value: number;

    public constructor(value: number) {
        this.value = value;
    }

    public static fake(min: number = 0, max: number = 2500): NumberValue {
        const minCeiled = Math.ceil(min);
        const maxFloored = Math.floor(max);
        const number = Math.floor(Math.random() * (maxFloored - minCeiled) + minCeiled);

        return new NumberValue(number);
    }

    public getValue(): number {
        return this.value;
    }

    public round(precision: number): NumberValue {
        return new NumberValue(_round(_round(this.value, precision + 1), precision));
    }
}
