<template>
    <div class="PhotoList">
        <div class="PhotoList__grid-wrapper">
            <div class="PhotoList__grid">
                <template v-for="(item, index) in paginator.items()">
                    <div :key="index">
                        <slot
                            :index="index"
                            :item="item"
                            name="item"
                        />
                    </div>
                </template>
                <div
                    v-if="hasMore"
                    ref="target"
                    class="PhotoList__loader"
                >
                    <RingLoaderIcon/>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import type {PropType} from 'vue';
    import {defineComponent, ref, watch} from 'vue';
    import type {LengthAwarePaginator} from '@meekohq/lumos';
    import {useElementVisibility, useIntersectionObserver, useIntervalFn} from '@vueuse/core';
    import RingLoaderIcon from '@/modules/app/components/atoms/RingLoaderIcon.vue';

    export default defineComponent({
        components: {RingLoaderIcon},
        props: {
            hasMore: {
                type: Boolean,
                default: false,
                required: false,
            },
            loading: {
                type: Boolean,
                default: false,
                required: false,
            },
            paginator: {
                type: Object as PropType<LengthAwarePaginator<unknown>>,
                default: undefined,
                required: false,
            },
        },
        setup(props, {emit}) {
            const target = ref(null);
            const targetIsVisible = useElementVisibility(target);

            useIntersectionObserver(
                target,
                ([{isIntersecting}]) => {
                    targetIsVisible.value = isIntersecting;
                },
            );

            // Emit the load-more event each second
            const {pause, resume} = useIntervalFn(() => {
                emit('load-more');
            }, 500);

            // Pause the interval when emit is not needed
            watch([() => props.loading, () => props.hasMore, targetIsVisible], values => {
                const [isLoading, hasMore, isVisible] = values;
                if (!isLoading && hasMore && isVisible) {
                    resume();
                } else {
                    pause();
                }
            }, {immediate: true});

            return {
                target,
                targetIsVisible,
            };
        },
    });
</script>

<style lang="scss" scoped>
    .PhotoList {
        @apply tw-w-full tw-flex-1 tw-relative;
    }

    .PhotoList__loader {
        @apply tw-flex tw-justify-center tw-items-center tw-aspect-square;

        svg {
            @apply tw-h-12 tw-w-12 tw-stroke-gray-200;
        }
    }

    .PhotoList__grid-wrapper {
        @apply tw-overflow-auto tw-absolute tw-inset-0;
    }

    .PhotoList__grid {
        @apply tw-grid 2xl:tw-grid-cols-10 xl:tw-grid-cols-8 lg:tw-grid-cols-6 md:tw-grid-cols-5 sm:tw-grid-cols-4 tw-grid-cols-3;
        @apply tw-gap-0.5;
    }
</style>

