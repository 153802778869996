<template>
    <div class="row sparklines">
        <div
            v-for="(nursery, i) in orderedNurseries"
            :key="'nursery' + i"
            class="my-2 d-inline-block"
            :class="getGridClass(nurseries.length)"
        >
            <div
                class="rounded nursery p-0 tw-shadow"
                :class="[{'selected': nursery.selected && nurseries.length > 1}, {'zoom-small pointer': nurseries.length > 1}]"
                @click="selectNursery(nursery)"
            >
                <div class="pb-3">
                    <div class="titles pt-2 pl-3">
                        <h6 class="h6 tw-text-white tw-text-sm mb-1 tw-truncate">
                            {{ nursery.name + ' - ' + nursery.city }}
                        </h6>
                        <h5 class="h5 tw-text-white tw-text-lg tw-font-semibold">
                            {{ nursery.average + ' ' + nursery.currency }}
                        </h5>
                    </div>

                    <loader
                        v-if="loading"
                        class="p-0"
                        shadow="false"
                        size="sm"
                    />
                    <sparkline-chart
                        v-else
                        :chart-data="chartData"
                        :height="50"
                        :nursery="nursery"
                        :series="nursery.series"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';
    import useApi from '@/modules/app/composables/useApi';
    import route from '@/modules/legacy/libs/ziggy';
    import moment from 'moment';
    import _head from 'lodash-es/head';
    import _forEach from 'lodash-es/forEach';
    import _map from 'lodash-es/map';
    import _sumBy from 'lodash-es/sumBy';
    import _round from 'lodash-es/round';
    import _orderBy from 'lodash-es/orderBy';
    import * as ProcessedData from '@/modules/legacy/libs/ProcessedData';
    import SparklineChart from '@/modules/legacy/components/Modules/Charts/Sparkline.vue';
    import useBroadcast from '@/modules/app/composables/useBroadcast';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';

    export default {
        components: {
            SparklineChart,
        },
        props: {
            nursery: {
                type: Object,
            },
            nurseries: {
                type: Array,
            },
            selectedInvoiceType: {},
            fromDate: {},
            toDate: {},
        },
        data: () => ({
            chartData: {
                categories: [],
            },
            broadcast_id: Math.floor(100000 + Math.random() * 900000),
            loading: false,
        }),
        computed: {
            orderedNurseries() {
                return _orderBy(this.nurseries, 'name');
            },
        },
        watch: {
            selectedInvoiceType(val) {
                window.localStorage.setItem('sparklinesInvoiceType', val);
                this.getRevenues();
            },
            fromDate(val) {
                window.localStorage.setItem('sparklinesFromDate', val);
                this.getRevenues();
            },
            toDate(val) {
                window.localStorage.setItem('sparklinesToDate', val);
                this.getRevenues();
            },
        },
        mounted() {
            this.nurseries.forEach(nursery => {
                Vue.set(nursery, 'selected', false);
                Vue.set(nursery, 'average', 0);
                Vue.set(nursery, 'series', []);
            });
            const myNursery = this.nurseries.find(item => item.id === this.nursery.id);
            this.nurseries.length ? this.selectNursery(myNursery ? myNursery : this.nurseries[0]) : null;

            useBroadcast().sessionChannel.bind('billing-stats-' + this.broadcast_id, response => {
                ProcessedData.retrieve(response.processedDataUrl, response => {
                    response.data.forEach(item => {
                        const nursery = this.nurseries.find(nurs => nurs.id === item.nursery_id);
                        if (nursery) {
                            nursery.average = _round(_sumBy(item.stats, 'amount'), 2);
                            nursery.series = [{
                                name: '',
                                data: _map(item.stats, 'amount'),
                            }];
                        }
                    });
                    this.rereshChartCategories();
                    this.loading = false;
                });
            });

            this.getRevenues();
        },
        beforeDestroy() {
            useBroadcast().sessionChannel.unbind('billing-stats-' + this.broadcast_id);
        },
        methods: {
            getRevenues() {
                if (this.loading) {
                    return;
                }

                this.loading = true;
                useApi().legacy.post(route('stats.sales'), {
                    from: this.fromDate,
                    to: this.toDate,
                    nurseries: _map(this.nurseries, 'id'),
                    type: this.selectedInvoiceType,
                    broadcast_id: this.broadcast_id,
                }).catch(error => {
                    this.loading = false;
                    if (error && error.response && error.response.status === 422) {
                        _forEach(error.response.data.errors, value => {
                            useNotification().error(_head(value));
                        });
                    } else {
                        useNotification().error(error);
                    }
                });
            },

            rereshChartCategories() {
                this.chartData.categories = [];
                const dateStart = moment(this.fromDate);
                const dateEnd = moment(this.toDate);
                while (dateEnd > dateStart || dateStart.format('M') === dateEnd.format('M')) {
                    this.chartData.categories.push(dateStart.format('MMMM YYYY'));
                    dateStart.add(1, 'month');
                }
            },

            selectNursery(nursery) {
                this.nurseries.forEach(item => {
                    item.selected = false;
                });
                nursery.selected = true;
                this.$emit('nurseryChanged', nursery);
            },

            getGridClass(length) {
                if (length >= 4) {
                    return 'col-12 col-sm-6 col-md-4 col-lg-3';
                } else if (length === 3) {
                    return 'col-12 col-sm-6 col-md-4';
                } else {
                    return 'col-12 col-sm';
                }
            },
        },
    };
</script>

<style lang="scss" scoped>
    .sparklines {
        flex-wrap: nowrap;
        overflow-x: auto;
        overflow-y: hidden;
    }

    .nursery {
        background: linear-gradient(135deg, #6dc2ff 10%, #0396FF 100%);

        &.selected {
            background: linear-gradient(135deg, #9076fd 10%, #d187ff 100%);
        }
    }
</style>
