<template>
    <SelectJob
        v-model="jobModel"
        :button-class="buttonClass"
        :has-unselect="true"
        :organization-model="Manager.activeOrganization"
        :unselect-value="null"
        :with-contract="true"
        @input="onChange"
    >
        <template #unselect-item/>
        <template #unselectedValue>
            {{ __('common:employee_one') }}
        </template>
    </SelectJob>
</template>

<script>
    import JobModel from '@/modules/human-resources/models/JobModel';
    import Manager from '@/modules/legacy/store/manager.store';
    import SelectJob from '@/modules/human-resources/components/job/SelectJob.vue';

    export default {
        components: {SelectJob},
        props: {
            value: {
                required: true,
            },
            buttonClass: {
                required: false,
                default: '',
            },
        },

        data() {
            return {
                jobModel: null,
                Manager,
            };
        },

        watch: {
            value: {
                immediate: true,
                async handler(value) {
                    if (this.value) {
                        this.jobModel = await JobModel.query().find(value);
                    } else {
                        this.jobModel = null;
                    }
                },
            },
        },

        methods: {
            onChange(jobModel) {
                this.$emit('input', jobModel?.id);
            },
        },
    };
</script>

