import type {Ref} from 'vue';
import {computed, ref} from 'vue';
import {app, type ModelCollection} from '@meekohq/lumos';
import type PaymentModel from '@/modules/cashier/models/PaymentModel';
import {Types} from '@/types';
import type {AbstractAllocationAggregate} from '@/modules/cashier/payment/domain/AbstractAllocationAggregate';
import type {
    GetAllocationsForInvoiceUseCase,
} from '@/modules/cashier/payment/application/GetAllocationsForInvoiceUseCase';
import type {
    GetAllocationsForCreditNoteUseCase,
} from '@/modules/cashier/payment/application/GetAllocationsForCreditNoteUseCase';

export default function usePaymentAllocationsState() {
    const allocations: Ref<AbstractAllocationAggregate[]> = ref([]);
    const isFetching = ref(false);
    const originalRemaining = ref(0);

    const countAllocatableWithRemaining = computed(() => {
        return allocations.value?.filter(
            allocationAggregate => allocationAggregate.isAllocatable,
        ).length ?? 0;
    });

    const paymentRemainingAmount = computed(() => {
        return allocations.value[0]?.sharedRemainingAmount ?? originalRemaining.value;
    });

    async function fetchForInvoice(payment: PaymentModel) {
        isFetching.value = true;

        originalRemaining.value = payment.computed.remaining_amount;

        try {
            const result = await app()
                .make<GetAllocationsForInvoiceUseCase>(Types.GetAllocationsForInvoiceUseCase)
                .fetch(payment);

            allocations.value = orderAllocationsByDate(result);
        } finally {
            isFetching.value = false;
        }
    }

    async function fetchForCreditNote(payments: ModelCollection<PaymentModel>, creditNoteId: string) {
        isFetching.value = true;

        originalRemaining.value = payments.first(payment => !!payment.attributes.refund)?.computed.remaining_amount ?? 0;

        try {
            const result = await app()
                .make<GetAllocationsForCreditNoteUseCase>(Types.GetAllocationsForCreditNoteUseCase)
                .fetch(creditNoteId, payments);

            allocations.value = orderAllocationsByDate(result);
        } finally {
            isFetching.value = false;
        }
    }

    function orderAllocationsByDate(allocationAggregates: AbstractAllocationAggregate[]): AbstractAllocationAggregate[] {
        return allocationAggregates?.sort((a: AbstractAllocationAggregate, b: AbstractAllocationAggregate) => {
            return a.allocatableDate?.greaterThan(b.allocatableDate) ? 1 : -1;
        });
    }

    return {
        allocations,
        countAllocatableWithRemaining,
        fetchForInvoice,
        fetchForCreditNote,
        isFetching,
        paymentRemainingAmount,
    };
}
