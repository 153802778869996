import type {QueryBuilder} from '@meekohq/lumos';
import {Model} from '@meekohq/lumos';
import EventTypeModel from '@/modules/human-resources/models/EventTypeModel';
import StaffModel from '@/modules/human-resources/models/StaffModel';
import KidsGroupModel from '@/modules/organization/models/KidsGroupModel';
import BalanceAllocationModel from '@/modules/human-resources/models/BalanceAllocationModel';
import OrganizationModel from '@/modules/organization/models/OrganizationModel';
import {minutesToHours} from '@/modules/legacy/libs/planning/planning';
import type {ICalculable} from '@/modules/human-resources/utils/balance/ICalculable';
import type ICanBeSplitted from '@/modules/human-resources/utils/calendar/ICanBeSplitted';
import type SplittableEvent from '@/modules/human-resources/utils/calendar/SplittableEvent';
import moment from 'moment';
import CalculateEventTime from '@/modules/human-resources/utils/statistics/CalculateEventTime';
import SplittableEventBuilder from '@/modules/human-resources/utils/calendar/Builders/SplittableEventBuilder';
import type {Moment} from 'moment/moment';
import {scopeInPeriod, scopeInRange} from '@/modules/human-resources/utils/calendar/EventScopes';
import RequestModel from '@/modules/request/models/RequestModel';
import type {EventStatus} from '@/modules/human-resources/utils/calendar/Values/EventStatus';
import {eventStatusPending} from '@/modules/human-resources/utils/calendar/Values/EventStatus';

export default class EventModel extends Model implements ICalculable, ICanBeSplitted {
    public type = 'hr/calendar/events';

    public attributes: {
        id: string;
        account_id: string | undefined;
        staff_id: string | null | undefined;
        organization_id: string | null | undefined;
        kids_group_id: string | null | undefined;
        type_id: string | undefined;
        reference_event_id: string | null | undefined;
        template_event_id: string | undefined;
        datetime_event: {
            started_at: string | undefined;
            ended_at: string | undefined;
        } | null | undefined;
        date_event: {
            started_at: string | undefined;
            ended_at: string | undefined;
        } | null | undefined;
        note: string | null | undefined;
        forecast: boolean | undefined;
        factor: number | undefined;
        supervise_kid: boolean | null | undefined;
        request_id: string | undefined;
        replaced_by_event_id: string | undefined | null;
        status: EventStatus | undefined;
    } = {
            id: this.uuid(),
            account_id: undefined,
            staff_id: undefined,
            organization_id: undefined,
            kids_group_id: undefined,
            type_id: undefined,
            reference_event_id: undefined,
            template_event_id: undefined,
            datetime_event: {
                started_at: undefined,
                ended_at: undefined,
            },
            date_event: {
                started_at: undefined,
                ended_at: undefined,
            },
            note: undefined,
            forecast: undefined,
            factor: undefined,
            supervise_kid: undefined,
            request_id: undefined,
            replaced_by_event_id: undefined,
            status: undefined,
        };

    get startedAt(): Moment {
        const start = this.attributes.datetime_event?.started_at ?? this.attributes.date_event?.started_at;

        return moment(start);
    }

    set startedAt(val) {
        this.attributes.datetime_event = {
            started_at: moment(val).format(),
            ended_at: this.endedAt.format(),
        };
    }

    get startedAtRaw(): string | undefined {
        return this.attributes.datetime_event?.started_at ?? this.attributes.date_event?.started_at;
    }

    get endedAt() {
        const end = this.attributes.datetime_event?.ended_at ?? this.attributes.date_event?.ended_at;

        return end ? moment(end) : moment();
    }

    set endedAt(val) {
        this.attributes.datetime_event = {
            started_at: this.startedAt.format(),
            ended_at: moment(val).format(),
        };
    }

    get endedAtRaw(): string | undefined {
        return this.attributes.datetime_event?.ended_at ?? this.attributes.date_event?.ended_at;
    }

    get isSameDay(): boolean {
        return this.startedAt.isSame(this.endedAt, 'day');
    }

    get duration(): number {
        return this.endedAt.unix() - this.startedAt.unix();
    }

    get workingDuration(): number {
        return new CalculateEventTime([this]).getEventsTime().total * 60;
    }

    get isFullDay(): boolean {
        return !!this.attributes.date_event?.started_at;
    }

    get readableHours(): string {
        return minutesToHours(this.duration / 60);
    }

    get isPending(): boolean {
        return eventStatusPending.includes(this.attributes.status as EventStatus);
    }

    request() {
        return this.belongsTo('request', RequestModel, 'request_id');
    }

    organization() {
        return this.belongsTo('organization', OrganizationModel, 'organization_id');
    }

    eventType() {
        return this.belongsTo('eventType', EventTypeModel, 'type_id');
    }

    staff() {
        return this.belongsTo('staff', StaffModel, 'staff_id');
    }

    kidsGroup() {
        return this.belongsTo('kidsGroup', KidsGroupModel, 'kids_group_id');
    }

    balanceAllocations() {
        return this.morphMany('balanceAllocations', BalanceAllocationModel, 'source');
    }

    referenceEvent() {
        return this.belongsTo('event', EventModel, 'reference_event_id');
    }

    referencedEvents() {
        return this.hasMany('events', EventModel, 'reference_event_id');
    }

    // Scope to filter events begening or ending in the range
    scopeInRange(mainQuery: QueryBuilder<any>, range: [from: string | Moment, to: string | Moment]) {
        return scopeInRange(mainQuery, range);
    }

    // Scope to filter event strictly in the range, begin and end
    scopeInPeriod(mainQuery: QueryBuilder<any>, range: [from: string | Moment, to: string | Moment]) {
        return scopeInPeriod(mainQuery, range);
    }

    public mapToSplittableEvent(): SplittableEvent | SplittableEvent[] {
        return SplittableEventBuilder.fromEvent(this);
    }
}
