<template>
    <div
        v-if="!category.disabled"
        class="ResourcesMenu__item-content"
        :class="{
            'ResourcesMenu__item-content--is-disabled' : category.disabled,
            'ResourcesMenu__item-content--is-selected' : selected
        }"
        @click="$emit('selected', category)"
    >
        <div class="ResourcesMenu__item-label">
            <div class="tw-w-6 tw-h-6 tw-flex tw-items-center tw-justify-center">
                <FontAwesomeIcon
                    :class="{
                        'tw-text-blue-500': !selected,
                        'tw-text-white': selected
                    }"
                    :icon="'fa ' + category.icon"
                />
            </div>
            {{ category.label }}
        </div>
        <font-awesome-icon
            class="ResourcesMenu__item-icon"
            icon="fa-solid fa-chevron-right"
        />
    </div>
    <CTooltip
        v-else
        class="ResourcesMenu__item"
    >
        <div
            class="ResourcesMenu__item-content"
            :class="{
                'ResourcesMenu__item-content--is-disabled' : category.disabled,
                'ResourcesMenu__item-content--is-selected' : selected
            }"
        >
            <div class="ResourcesMenu__item-label">
                <div class="tw-w-6 tw-h-6 tw-flex tw-items-center tw-justify-center">
                    <FontAwesomeIcon
                        class="tw-text-blue-500"
                        :icon="category.icon"
                    />
                </div>
                {{ category.label }}
            </div>
            <font-awesome-icon
                class="ResourcesMenu__item-icon"
                icon="fa-solid fa-chevron-right"
            />
        </div>
        <template
            v-if="category.disabled"
            #content
        >
            {{ __('app:structure_records_are_not_compatible_with_other_record_types') }}
        </template>
    </CTooltip>
</template>

<script lang="ts">
    import type {PropType} from 'vue';
    import {defineComponent} from 'vue';
    import type {AvailableCategory} from '@/modules/app/composables/useResourceSelectorCategories';

    export default defineComponent({
        components: {},
        props: {
            category: {
                type: Object as PropType<AvailableCategory>,
                required: true,
            },
            selected: {
                type: Boolean,
                default: false,
            },
        },
    });
</script>

<style lang="scss" scoped>
    .ResourcesMenu {
        &__item {
            @apply tw-w-full;
        }

        &__item-content {
            @apply tw-flex tw-justify-between tw-items-center;
            @apply tw-rounded-lg;
            @apply tw-py-2 tw-px-3;
            @apply hover:tw-bg-blue-100 hover:tw-cursor-pointer;
            @apply tw-font-semibold;
        }

        &__item-content--is-selected {
            @apply tw-bg-blue-500 hover:tw-bg-blue-500 tw-text-white;
        }

        &__item-content--is-disabled {
            @apply tw-opacity-50 hover:tw-bg-white hover:tw-cursor-not-allowed;
        }

        &__item-label {
            @apply tw-flex tw-items-center tw-gap-1;
        }

        &__item-icon {
            @apply tw-pl-4 tw-opacity-50;
        }

        &__item-tooltip {
            @apply tw--ml-2 tw-pr-1;
        }
    }
</style>
