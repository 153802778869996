<script setup lang="ts">
    import type {ModalType} from '@/modules/app/composables/useModal';
    import PaymentSuggestionsList from '@/modules/cashier/payment/infrastructure/components/PaymentSuggestionsList.vue';
    import type PaymentModel from '@/modules/cashier/models/PaymentModel';
    import {computed} from 'vue';
    import __ from '@/modules/app/utils/i18n-facade';

    const props = defineProps<{
        modal: ModalType,
        payment: PaymentModel,
        suggestionType: 'transaction' | 'credit_note',
    }>();

    const modalTitle = computed(() => {
        if (props.suggestionType === 'transaction' && props.payment.attributes.refund === false) {
            return __('billing_transaction:actions.use_existing_credit');
        } else if (props.suggestionType === 'transaction' && props.payment.attributes.refund === true) {
            return __('billing_transaction:actions.use_existing_debit');
        } else {
            return __('billing_transaction:actions.use_credit_note');
        }
    });

    const emit = defineEmits<{
        (e: 'created'): void;
    }>();

    function onSaved() {
        emit('created');
        props.modal.hide();
    }
</script>

<template>
    <MModal
        :header-title="modalTitle"
        :modal="modal"
        size="4xl"
    >
        <PaymentSuggestionsList
            :payment="payment"
            :suggestion-type="suggestionType"
            @saved="onSaved"
        />
    </MModal>
</template>
