<template>
    <CButton
        :size="size"
        @click.stop="$refs.tagsList.toggle()"
    >
        <div
            v-if="checkedTags.count()"
            class="selected-tags tw-inline-flex"
        >
            <transition-group
                name="list-bottom"
                tag="div"
            >
                <tag-pill
                    v-for="(tag, i) in orderedCheckedTags"
                    :key="'tag-pill' + tag.attributes.id"
                    class="tag-pill"
                    :class="{'tw-mr-2' : i !== orderedCheckedTags.count() - 1}"
                    :tag="tag.attributes"
                />
            </transition-group>
        </div>
        <template v-else>
            {{ __('common:tags') }}
        </template>
        <i
            aria-hidden="true"
            class="fa fa-caret-down tw-ml-1"
        />
        <CDropdown
            ref="tagsList"
            legacy-mode
            placement="bottom"
        >
            <CList class="tw-cursor-pointer">
                <CListRow
                    v-if="filteredTags && !filteredTags.count()"
                    class="tw-p-1 tw-text-2xs tw-font-semibold"
                >
                    {{ __('tag:no_tags') }}
                </CListRow>
                <template v-else>
                    <CCenter
                        v-if="loading"
                        class="tw-absolute tw-bg-white tw-bg-opacity-80 tw-inset-2"
                    >
                        <CLoader/>
                    </CCenter>
                    <CListRow
                        v-for="(tag, i) in filteredTags"
                        :key="'filteredTags' + i"
                        class="tag-list-item tw-cursor-pointer tw-flex"
                        :class="{'selected': selectedTagsIds.includes(tag.id)}"
                        @click.stop="toggleTag(tag)"
                    >
                        <div>
                            <tag-pill
                                class="tag-pill tw-mr-1"
                                :tag="tag.attributes"
                            />
                        </div>
                        <div>
                            {{ tag.attributes.name }}
                        </div>
                    </CListRow>
                </template>
            </CList>
        </CDropdown>
    </CButton>
</template>

<script lang="ts">
    import type {PropType} from 'vue';
    import {computed, defineComponent, ref, watch} from 'vue';
    import _cloneDeep from 'lodash-es/cloneDeep';
    import TagPill from '@/modules/tag/components/TagPill.vue';
    import type TagModel from '@/modules/tag/models/TagModel';
    import type {Model, ModelCollection, MorphToMany} from '@meekohq/lumos';
    import {collect} from '@meekohq/lumos';
    import TagPivot from '@/modules/tag/models/TagPivot';
    import ErrorHandler from '@/modules/legacy/libs/errors/errorHandler';
    import useApi from '@/modules/app/composables/useApi';
    import _forEach from 'lodash-es/forEach';
    import _head from 'lodash-es/head';
    import route from '@/modules/legacy/libs/ziggy';
    import __ from '@/modules/app/utils/i18n-facade';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';

    export default defineComponent({
        components: {
            TagPill,
        },
        props: {
            tags: {
                type: Object as PropType<ModelCollection<TagModel>>,
                default: () => collect(),
            },
            taggableModel: {
                type: Object as PropType<Model & { tags(): MorphToMany }>,
                default: undefined,
            },
            taggableType: {
                type: String,
                required: true,
            },
            selectedTags: {
                type: Object as PropType<ModelCollection<TagModel>>,
                default: () => collect(),
            },
            filter: {
                type: Boolean,
                default: false,
            },
            size: {
                type: String,
                default: 'base',
            },
        },

        setup(props, {emit}) {

            const errorHandler = ref(new ErrorHandler());

            const emitUpdateTagsKey = 'update:selectedTags';

            const loading = ref(false);

            const selectedTagsIds = ref<number[]>([]);

            const filteredTags = computed(() => {
                let clonedTags = _cloneDeep(props.tags);

                if (props.taggableType && props.tags.count()) {
                    clonedTags = _cloneDeep(props.tags).filter(tag => tag.attributes.type === props.taggableType);
                }

                return sortTags(clonedTags);
            });

            // Compute tags that are selected
            const checkedTags = computed(() => {
                return filteredTags.value.filter((tag: any) => selectedTagsIds.value.includes(tag.id));
            });

            // Order tags by name
            const orderedCheckedTags = computed(() => {
                return checkedTags.value.sortBy('attributes.name');
            });

            // Sync passed by prop selected tags with selectedTagsIds
            watch(() => props.selectedTags, () => {
                selectedTagsIds.value = props.selectedTags.map((tag: TagModel) => tag.attributes.id).toArray();
            }, {immediate: true});

            function sortTags(tags: ModelCollection<TagModel>) {
                return tags.sortBy('attributes.name');
            }

            function attachTag(tag: TagModel) {
                if (loading.value) {
                    return;
                }

                loading.value = true;

                const tagPivot = new TagPivot();
                tagPivot.attributes.account_id = tag.attributes.account_id;
                tagPivot.attributes.tag_id = tag.attributes.id;
                tagPivot.attributes.resource_type = props.taggableModel.getType();
                tagPivot.attributes.resource_id = props.taggableModel[props.taggableModel.getKeyName()];

                tagPivot.save()
                    .then(() => {
                        addTag(tag);
                        useNotification().success(__('tag:tag_attached_with_name', {name: tag.attributes.name}));
                    })
                    .catch(error => {
                        errorHandler.value.reset(error);
                    }).finally(() => loading.value = false);
            }

            function detachTag(tag: TagModel) {
                if (loading.value) {
                    return;
                }

                loading.value = true;

                // If the taggable model is a lumos model, we can use the detach method
                // Otherwise, we use the legacy API
                if (props.taggableModel) {
                    props.taggableModel.tags()
                        .detach(tag.getKey())
                        .then(() => {
                            removeTag(tag);
                            useNotification().success(__('tag:tag_detached_with_name', {name: tag.attributes.name}));
                        })
                        .catch(error => {
                            errorHandler.value.reset(error);
                        }).finally(() => loading.value = false);
                } else {
                    useApi().legacy.post(route('tags.detach', {
                        tag: tag.getKey(),
                    }), {
                        model_id: props.taggableModel[(props.taggableModel as Model).getKeyName()],
                        model_type: props.taggableType,
                    }).then(() => {
                        removeTag(tag);
                        useNotification().info(__('tag:tag_detached_with_name', {name: tag.attributes.name}));
                    }).catch(error => {
                        if (error && error.response && error.response.status === 422) {
                            _forEach(error.response.data.errors, (value: string[]) => {
                                useNotification().error(_head(value) ?? 'Erreur');
                            });
                        } else {
                            useNotification().error(error);
                        }
                    }).finally(() => loading.value = false);
                }
            }

            function toggleTag(tag) {
                const isIncluded = selectedTagsIds.value && selectedTagsIds.value.includes(tag.id);

                if (props.filter) {
                    isIncluded ? removeTag(tag) : addTag(tag);
                } else {
                    isIncluded ? detachTag(tag) : attachTag(tag);
                }
            }

            function addTag(tag: TagModel) {
                selectedTagsIds.value.push(tag.getKey());
                emit(emitUpdateTagsKey, props.tags?.filter(tag => selectedTagsIds.value.includes(tag.id)));
                emit('created:tag', tag);
            }

            function removeTag(tag) {
                if (selectedTagsIds.value.includes(tag.id)) {
                    const index = selectedTagsIds.value.findIndex(id => id === tag.id);
                    selectedTagsIds.value.splice(index, 1);
                }

                if (selectedTagsIds.value && selectedTagsIds.value.length === 0) {
                    cancelTags();
                    emit('deleted:tag', tag);
                } else {
                    emit(emitUpdateTagsKey, props.tags?.filter(tag => selectedTagsIds.value.includes(tag.id)));
                    emit('deleted:tag', tag);
                }
            }

            function cancelTags() {
                selectedTagsIds.value = selectedTagsIds.value.slice();
                emit(emitUpdateTagsKey, collect());
            }

            return {
                filteredTags,
                checkedTags,
                orderedCheckedTags,
                selectedTagsIds,
                toggleTag,
                loading,
            };
        },
    });
</script>

<style lang="scss" scoped>
    .selected-tags {
        padding: 1px 0 1px 13px;

        .tag-pill {
            margin-left: -12px;
        }
    }

    .tag-list-item {
        font-size: 14px;
        font-weight: 600;
        color: #728497;
        opacity: .4;
        align-items: center;

        &:hover {
            opacity: .7;
        }

        &.selected {
            opacity: 1;
        }
    }
</style>
