<template>
    <CPopover
        :clickable="false"
        hoverable
        placement="top"
    >
        <MHoverable>
            <TransactionListItemInvoiceDropdownItem :allocation="allocation"/>
        </MHoverable>
        <template #content>
            <div class="TransactionListItemInvoicePopover">
                <TransactionListItemInvoicePopoverHeader :allocation="allocation"/>
                <TransactionListItemInvoicePopoverInformations
                    :allocation="allocation"
                    class="tw-mt-3"
                    :customer="customer"
                />
                <TransactionListItemInvoicePopoverDescription
                    :allocation="allocation"
                    class="tw-mt-6"
                />
                <TransactionListItemInvoicePopoverTotal
                    :allocation="allocation"
                    class="tw-mt-3 tw-pt-3"
                />
                <TransactionListItemInvoicePopoverPaymentList
                    :allocation="allocation"
                    class="tw-mt-6"
                />
            </div>
        </template>
    </CPopover>
</template>

<script lang="ts">
    import type {PropType} from 'vue';
    import {defineComponent} from 'vue';
    import type AllocationModel from '@/modules/cashier/models/AllocationModel';
    import type CustomerModel from '@/modules/cashier/models/CustomerModel';
    import TransactionListItemInvoiceDropdownItem
        from '@/modules/cashier/transaction/infrastructure/components/TransactionListItemInvoiceDropdownItem.vue';
    import TransactionListItemInvoicePopoverPaymentList
        from '@/modules/cashier/transaction/infrastructure/components/TransactionListItemInvoiceDropdownPaymentList.vue';
    import TransactionListItemInvoicePopoverDescription
        from '@/modules/cashier/transaction/infrastructure/components/TransactionListItemInvoiceDropdownDescription.vue';
    import TransactionListItemInvoicePopoverInformations
        from '@/modules/cashier/transaction/infrastructure/components/TransactionListItemInvoiceDropdownInformations.vue';
    import TransactionListItemInvoicePopoverHeader
        from '@/modules/cashier/transaction/infrastructure/components/TransactionListItemInvoiceDropdownHeader.vue';
    import TransactionListItemInvoicePopoverTotal
        from '@/modules/cashier/transaction/infrastructure/components/TransactionListItemInvoiceDropdownTotal.vue';

    export default defineComponent({
        components: {
            TransactionListItemInvoicePopoverTotal,
            TransactionListItemInvoicePopoverHeader,
            TransactionListItemInvoicePopoverInformations,
            TransactionListItemInvoicePopoverDescription,
            TransactionListItemInvoicePopoverPaymentList,
            TransactionListItemInvoiceDropdownItem,
        },
        props: {
            allocation: {
                type: Object as PropType<AllocationModel>,
                required: true,
            },
            customer: {
                type: Object as PropType<CustomerModel>,
                required: true,
            },
        },
    });
</script>

<style lang="scss" scoped>
    .TransactionListItemInvoicePopover {
        @apply tw-p-2;
    }
</style>
