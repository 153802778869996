<template>
    <SimplePageTemplate
        id="planning"
        @click="hidePopovers"
    >
        <template #title>
            <MPageTitle>
                {{ __('app:menu.kids_planning') }}
            </MPageTitle>
        </template>
        <template #actions>
            <top-actions
                :current-display="__('common:daily')"
                :date="date"
                :nursery="nursery"
            />
        </template>
        <template #content>
            <TopActionsSwitcher
                :current-display="__('common:daily')"
                :date="date"
                :nursery="nursery"
            />
            <div class="card card-3d tw-mt-3">
                <div class="card-body">
                    <div class="row d-print-none">
                        <div class="col-6 col-sm-4 order-2 order-sm-1 text-left">
                            <button
                                class="btn btn-success btn-sm"
                                @click="date = date.clone().subtract(1, 'weeks'); pushQueryDate(date, 'day');"
                            >
                                <i
                                    aria-hidden="true"
                                    class="fa fa-angle-left mr-1"
                                />{{ __('common:longweek_with_number', {week: fromMoment(date).subWeeks(1).weekNumber}) }}
                            </button>
                        </div>
                        <div class="col-12 col-sm-4 order-1 order-sm-2 text-center mb-3">
                            <MHeading class="d-flex align-items-center justify-content-center">
                                {{ __('common:longweek_with_number', {week: fromMoment(date).weekNumber}) }}
                                <OccupationBadge
                                    class="tw-ml-2"
                                    :enable-count="false"
                                    :loading="occupationLoading"
                                    :occupations="occupations"
                                />
                            </MHeading>
                            <div class="text-muted mb-1">
                                {{ fromMoment(date).startOfWeek().toLocaleString(Epoch.presets.DATE_SHORT) }} - {{ fromMoment(date).endOfWeek().toLocaleString(Epoch.presets.DATE_SHORT) }}
                            </div>
                        </div>
                        <div class="col-6 col-sm-4 order-3 order-sm-3 text-right">
                            <button
                                class="btn btn-success btn-sm"
                                @click="date = date.clone().add(1, 'weeks'); pushQueryDate(date, 'day');"
                            >
                                {{ __('common:longweek_with_number', {week: fromMoment(date).addWeeks(1).weekNumber}) }}<i
                                    aria-hidden="true"
                                    class="fa fa-angle-right ml-1"
                                />
                            </button>
                        </div>
                    </div>
                    <h4 class="h4 d-none d-print-flex mt-0 mb-4 text-primary text-center justify-content-center w-100 text-capitalize">
                        {{
                            fromMoment(date).toLocaleString({
                                weekday: 'long',
                                day: 'numeric',
                                month: 'numeric',
                                year: 'numeric',
                            }) + ' - ' + __('common:longweek_with_number', {week: fromMoment(date).weekNumber})
                        }}
                    </h4>
                    <div class="tw-flex print:tw-hidden tw-justify-center tw-mb-4">
                        <div class="tw-flex tw-overflow-x-auto tw-p-3">
                            <div
                                v-for="(day, i) in enumerateDaysBetweenDates"
                                :key="'day' + i"
                                class="tw-text-gray-600 tw-bg-gray-100 hover:tw-bg-gray-200 tw-w-32 tw-rounded-lg tw-text-center tw-cursor-pointer tw-transition-all tw-mx-2 tw-p-1 tw-shrink-0"
                                :class="[
                                    {
                                        'tw-ring tw-ring-primary-500 tw-ring-offset-2 tw-text-primary-500': day.isSame(
                                            moment().startOf('day')
                                        ),
                                    },
                                    {
                                        '!tw-bg-primary-500 hover:!tw-bg-primary-500 !tw-text-white tw-scale-110': date.isBetween(
                                            day.clone().startOf('day'),
                                            day.clone().endOf('day'),
                                            null,
                                            '[]'
                                        ),
                                    },
                                ]"
                                @click="date = day"
                            >
                                <h1 class="h1 tw-text-inherit tw-mb-0">
                                    {{ fromMoment(day).day }}
                                </h1>
                                <h5 class="h5 tw-text-inherit text-uppercase">
                                    {{ fromMoment(day).weekdayShort.replace('.', '') }}
                                </h5>
                                <OccupationBadge
                                    class="tw-mt-2 tw-mb-1"
                                    :day="day"
                                    enable-half-day
                                    :loading="occupationLoading"
                                    :occupations="occupations"
                                />
                            </div>
                        </div>
                    </div>
                    <transition>
                        <div id="context-parent">
                            <div class="d-table w-100 mb-2">
                                <div
                                    class="d-none d-sm-block"
                                    style="padding-left: 185px; padding-right: 50px;"
                                >
                                    <hours
                                        :closing="closing"
                                        :opening="opening"
                                    />
                                </div>
                            </div>
                            <div class="d-table w-100 mb-2">
                                <div class="kid pr-sm-4 float-none float-sm-left mb-2 mb-sm-0">
                                    <MDropdown class="tw-w-full">
                                        <MButton
                                            class="tw-w-full"
                                            icon-menu
                                            :label="currentGroup.id ? currentGroup.name : __('common:all_kids')"
                                            size="sm"
                                            variant="primary"
                                        />
                                        <template #content>
                                            <div>
                                                <CDropdownMenuItem
                                                    class="tw-w-full"
                                                    :label="__('common:all_kids')"
                                                    @click="switchGroup()"
                                                />
                                                <CDropdownMenuItem
                                                    v-for="(group, i) in nursery.groups"
                                                    :key="'group' + i"
                                                    class="tw-w-full"
                                                    :label="group.name"
                                                    @click="switchGroup(group)"
                                                />
                                                <CDropdownMenuDivider class="tw-my-1"/>
                                                <tags
                                                    class="tw-px-3 tw-py-2"
                                                    filter
                                                    :selected-tags.sync="selectedTags"
                                                    taggable-type="kid"
                                                    :tags="nursery.tags"
                                                />
                                            </div>
                                        </template>
                                    </MDropdown>
                                </div>
                                <div class="planning summary-bar">
                                    <summary-bar
                                        :class="{'mb-1': showStaffSummary}"
                                        :closing="closing"
                                        :day="date"
                                        :loading="occupationLoading"
                                        :nursery="nursery"
                                        :opening="opening"
                                        :summary="weekOccupationRateBar"
                                    />
                                    <summary-bar-staff
                                        v-if="showStaffSummary"
                                        :closing="closing"
                                        :day="date"
                                        :loading="supervisionLoading"
                                        :nursery="nursery"
                                        :opening="opening"
                                        style="margin-left: 10px;"
                                        :summary="weekOccupationRateBarStaff"
                                    />
                                </div>
                            </div>
                            <loader
                                v-if="loading"
                                light="true"
                                shadow="false"
                            />
                            <div
                                v-show="!loading"
                                style="margin: 0 -1.25rem;"
                            >
                                <div
                                    v-for="(kid, i) in kids"
                                    :key="'kid' + i"
                                    class="line d-table w-100"
                                >
                                    <planning
                                        :closing="closing"
                                        :date="date"
                                        :day="from"
                                        :kid="kid"
                                        :nursery="nursery"
                                        :opening="opening"
                                        :show-menu="$can('update', 'kids_planning')"
                                        show-note
                                        :tasks="tasksByKidId[kid.id]"
                                    />
                                </div>
                            </div>
                            <context-menu
                                id="contextMenu"
                                :event="event"
                                :nursery="nursery"
                                :visible="toggleMenu"
                            />
                        </div>
                    </transition>
                    <h1
                        v-if="!Object.keys(kids).length"
                        class="h1 tw-font-grandhotel text-center"
                    >
                        {{ __('common:no_kids') }}
                    </h1>
                </div>
            </div>
        </template>
    </SimplePageTemplate>
</template>

<script>
    import route from '@/modules/legacy/libs/ziggy';
    import useApi from '@/modules/app/composables/useApi';
    import moment from 'moment';
    import {collect, Epoch} from '@meekohq/lumos';
    import _filter from 'lodash-es/filter';
    import _debounce from 'lodash-es/debounce';
    import _find from 'lodash-es/find';
    import _map from 'lodash-es/map';
    import _head from 'lodash-es/head';
    import _forEach from 'lodash-es/forEach';
    import _concat from 'lodash-es/concat';
    import * as ProcessedData from '@/modules/legacy/libs/ProcessedData';
    import {nursery} from '@/modules/legacy/mixins/nursery';
    import {url} from '@/modules/legacy/mixins/url';
    import {
        getOverrunEvents,
        getPlanningEventsFromKidContracts,
        getPresenceEvents,
    } from '@/modules/legacy/libs/Planning';
    import useBroadcast from '@/modules/app/composables/useBroadcast';
    import useGetTasks from '@/modules/activity/composables/useGetTasks';
    import SummaryBar from '@/modules/legacy/components/Modules/PlanningGenerator/Summary.vue';
    import SummaryBarStaff from '@/modules/legacy/components/Modules/PlanningGenerator/StaffSummary.vue';
    import Hours from '@/modules/legacy/components/Modules/PlanningGenerator/Hours.vue';
    import Planning from '@/modules/planning/components/kid/Planning.vue';
    import ContextMenu from '@/modules/legacy/components/Modules/PlanningGenerator/ContextMenu.vue';
    import TopActions from '@/modules/planning/components/kid/TopActions.vue';
    import OccupationBadge from '@/modules/planning/components/core/OccupationBadge.vue';
    import useCalendar from '@/modules/human-resources/composables/calendar/useCalendar';
    import KidModel from '@/modules/family/models/KidModel';
    import __ from '@/modules/app/utils/i18n-facade';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';
    import SimplePageTemplate from '@/modules/app/components/templates/SimplePageTemplate.vue';
    import TopActionsSwitcher from '@/modules/planning/components/kid/TopActionsSwitcher.vue';

    export default {
        components: {
            TopActionsSwitcher,
            SimplePageTemplate,
            'summary-bar': SummaryBar,
            'hours': Hours,
            'planning': Planning,
            'context-menu': ContextMenu,
            'top-actions': TopActions,
            SummaryBarStaff,
            OccupationBadge,
        },
        mixins: [nursery, url],
        props: ['nursery', 'user'],
        data: () => ({
            loading: {},
            occupationLoading: false,
            supervisionLoading: false,
            now: moment().unix(),
            date: moment(),
            kids: [],
            currentGroup: {},
            occupations: [],
            supervision: [],
            event: {},
            toggleMenu: false,
            showStaffSummary: window.localStorage.getItem('showStaffSummary') === 'true',
            selectedTags: JSON.parse(localStorage.getItem('tags:kids:planning')),
            useGetTasks: undefined,
            tasks: undefined,
            tasksByKidId: collect(),
            Epoch,
        }),
        computed: {
            moment() {
                return moment;
            },
            fromDay() {
                return this.date.clone().startOf('week');
            },
            toDay() {
                return this.date.clone().endOf('week');
            },
            from() {
                return this.date.clone().startOf('day');
            },
            to() {
                return this.date.clone().endOf('day');
            },
            opening() {
                let hours = this.weekOccupationRateBar.filter(item => item.kids > 0);

                if (this.showStaffSummary && this.weekOccupationRateBarStaff) {
                    hours = hours.concat(this.weekOccupationRateBarStaff.filter(item => item.staffs !== 0));
                }

                return this.$getOpening(this.nursery.openingHours, true, false, hours);
            },
            closing() {
                let hours = this.weekOccupationRateBar.filter(item => item.kids > 0);

                if (this.showStaffSummary && this.weekOccupationRateBarStaff) {
                    hours = hours.concat(this.weekOccupationRateBarStaff.filter(item => item.staffs !== 0));
                }

                return this.$getClosing(this.nursery.openingHours, true, false, hours);
            },
            enumerateDaysBetweenDates() {
                const dates = [];

                const currDate = moment(this.fromDay).startOf('day');
                const lastDate = moment(this.toDay).startOf('day');

                dates.push(currDate.clone());
                while (currDate.add(1, 'days').diff(lastDate) <= 0) {
                    const day = currDate.clone().locale('en').format('ddd').toLowerCase();
                    if (day === 'sat' || day === 'sun') {
                        const hasDay = _find(this.nursery.openingHours, {day});
                        hasDay ? dates.push(currDate.clone()) : null;
                    } else {
                        dates.push(currDate.clone());
                    }
                }

                return dates;
            },
            weekOccupationRateBar() {
                const filter = _filter(this.occupations, occupation => {
                    return occupation.date === moment(this.date).format('YYYY-MM-DD');
                });

                if (filter.length) {
                    return _head(filter).summary;
                }

                return [];
            },
            weekOccupationRateBarStaff() {
                const filter = _filter(this.supervision, supervision => {
                    return supervision.date === moment(this.date).format('YYYY-MM-DD');
                });

                if (filter.length) {
                    return _head(filter).summary;
                }

                return [];
            },
        },
        watch: {
            'date': function(val, old) {
                if (val.unix() !== old.unix() || val.unix() === this.now) {
                    this.loading = true;
                    this.debounceDate();
                }
            },
            'fromDay': function(val, oldVal) {
                if (val.format('YYYY-MM-DD') !== oldVal.format('YYYY-MM-DD')) {
                    this.occupationLoading = true;
                    this.debounceFromDay(val, oldVal);
                }
            },
            'nursery.id': function() {
                this.fetchNursery();
            },
            'selectedTags': _debounce(function(val) {
                if (val) {
                    localStorage.setItem('tags:kids:planning', JSON.stringify(val));
                } else {
                    localStorage.removeItem('tags:kids:planning');
                }

                this.getKids();
                this.getOccupation();
                this.showStaffSummary ? this.getSupervision() : null;
            }, 500),
            '$route.query.date': function(date) {
                this.date = date ? moment.unix(date) : moment();
            },
            'kids': async function(kids) {
                this.tasks = await this.useGetTasks.getTasks(kids.map(kid => kid.id));
            },
            tasks() {
                this.tasksByKidId = this.useGetTasks.groupTasks();
            },
        },
        created() {
            this.useGetTasks = useGetTasks({
                constrainToResourceModel: KidModel,
            });
        },
        beforeMount() {
            this.date = this.$route.query.date ? moment.unix(this.$route.query.date) : useCalendar().getClosestOpeningDay();
        },
        mounted() {
            if (this.selectedTags) {
                const existingTags = this.selectedTags.filter(tag => this.nursery.tags.find(item => item.id === tag));
                if (existingTags.length !== this.selectedTags.length) {
                    this.selectedTags = existingTags;
                }
            }

            useBroadcast().sessionChannel.bind('occupation', response => {
                ProcessedData.retrieve(response.processedDataUrl, response => {
                    this.occupations = response.data;
                    this.occupationLoading = false;
                });
            });

            useBroadcast().sessionChannel.bind('kid_plannings', response => {
                ProcessedData.retrieve(response.processedDataUrl, response => {
                    this.kids = _map(response.data, kid => {
                        _map(kid.presences, presence => {
                            presence.type = 'presence';
                            presence.started_at = presence.droped_at;
                            presence.ended_at = presence.picked_up_at;
                            presence.nursery_id = this.nursery.id;

                            return presence;
                        });

                        _map(kid.absences, absence => {
                            absence.type = 'absence';
                            absence.event_type = 'absence';
                            absence.nursery_id = this.nursery.id;

                            return absence;
                        });

                        kid.events = this.getEvents(kid);

                        return kid;
                    });

                    this.loading = false;
                });
            });

            if (this.showStaffSummary) {
                useBroadcast().sessionChannel.bind('supervision', response => {
                    ProcessedData.retrieve(response.processedDataUrl, response => {
                        this.supervision = response.data;
                        this.supervisionLoading = false;
                    });
                });
            }

            this.fetchNursery();
        },
        beforeDestroy() {
            this.$bus.$off('refresh:kids');

            this.$bus.$off('clicked:event');

            this.$bus.$off('created:absence');
            this.$bus.$off('updated:absence');
            this.$bus.$off('deleted:absence');

            this.$bus.$off('created:presence');
            this.$bus.$off('updated:presence');
            this.$bus.$off('deleted:presence');

            this.$bus.$off('created:planning');
            this.$bus.$off('updated:planning');
            this.$bus.$off('deleted:planning');

            useBroadcast().sessionChannel.unbind('occupation');
            useBroadcast().sessionChannel.unbind('kid_plannings');
            this.showStaffSummary ? useBroadcast().sessionChannel.unbind('supervision') : null;
        },
        methods: {
            fromMoment: Epoch.fromMoment,
            debounceDate: _debounce(function() {
                this.getKids();
            }, 1000, {
                leading: true,
                trailing: true,
            }),
            debounceFromDay: _debounce(function() {
                if (this.nursery.places && this.nursery.openingHours.length) {
                    this.getOccupation();
                    this.showStaffSummary ? this.getSupervision() : null;
                }
            }, 1000, {
                leading: true,
                trailing: true,
            }),
            fetchNursery() {
                this.occupationLoading = false;

                // Get date after clicking on monthly or yearly
                if (this.$route.params.date) {
                    this.date = moment(this.$route.params.date, 'DD/MM/Y');
                    this.pushQueryDate(this.date, 'day');
                }

                this.currentGroup = {
                    id: null,
                    name: __('common:all_kids'),
                };

                this.$bus.$on('clicked:event', event => {
                    this.event = event;
                    this.event.start_time = moment.unix(this.event.started_at).format(
                        'HH:mm:ss');
                    this.event.end_time = moment.unix(this.event.ended_at).format('HH:mm:ss');

                    this.toggleMenu = true;
                    setTimeout(() => {
                        this.toggleMenu = false;
                    }, 200);
                });

                this.$bus.$on('refresh:kids', () => {
                    this.getKids();
                });

                this.$bus.$on('created:absence', absences => {
                    if (!absences.length) {
                        absences = [absences];
                    }

                    absences.forEach(absence => {
                        const kid = this.kids.find(item => item.id === absence.kid_id);
                        if (kid) {
                            absence.type = 'absence';
                            absence.event_type = 'absence';
                            absence.nursery_id = this.nursery.id;
                            kid.absences.push(absence);
                            kid.events = this.getEvents(kid);
                        }
                    });

                    this.getOccupation();
                    this.showStaffSummary ? this.getSupervision() : null;
                });

                this.$bus.$on('updated:absence', absence => {
                    const kid = this.kids.find(item => item.id === absence.kid_id);
                    if (kid && kid.absences.length) {
                        const abs = kid.absences.find(item => item.id === absence.id);
                        if (abs) {
                            abs.started_at = absence.started_at;
                            abs.ended_at = absence.ended_at;
                            abs.vacation = absence.vacation;
                            abs.billed = absence.billed;
                            abs.note = absence.note;
                            abs.unit = absence.unit;
                            abs.hours = absence.hours;
                            abs.hourly_rate = absence.hourly_rate;
                            kid.events = this.getEvents(kid);
                            this.getOccupation();
                            this.showStaffSummary ? this.getSupervision() : null;
                        }
                    }
                });

                this.$bus.$on('deleted:absence', absence => {
                    const kid = this.kids.find(item => item.id === absence.kid_id);
                    if (kid && kid.absences.length) {
                        const abs = kid.absences.find(item => item.id === absence.id);
                        if (abs) {
                            const i = kid.absences.indexOf(abs);
                            kid.absences.splice(i, 1);
                            kid.events = this.getEvents(kid);
                            this.getOccupation();
                            this.showStaffSummary ? this.getSupervision() : null;
                        }
                    }
                });

                this.$bus.$on('created:presence', presence => {
                    const kid = this.kids.find(item => item.id === presence.kid_id);
                    if (kid) {
                        presence.started_at = presence.droped_at;
                        presence.ended_at = presence.picked_up_at;
                        presence.type = 'presence';
                        presence.nursery_id = this.nursery.id;
                        kid.presences.push(presence);
                        kid.events = this.getEvents(kid);
                        this.getOccupation();
                        this.showStaffSummary ? this.getSupervision() : null;
                    }
                });

                this.$bus.$on('updated:presence', presence => {
                    const kid = this.kids.find(item => item.id === presence.kid_id);
                    if (kid && kid.presences.length) {
                        const pre = kid.presences.find(item => item.id === presence.id);
                        if (pre) {
                            pre.started_at = presence.droped_at;
                            pre.ended_at = presence.picked_up_at;
                            pre.drop_note = presence.drop_note;
                            pre.pick_up_note = presence.pick_up_note;

                            kid.events = this.getEvents(kid);
                            this.getOccupation();
                            this.showStaffSummary ? this.getSupervision() : null;
                        }
                    }
                });

                this.$bus.$on('deleted:presence', presence => {
                    const kid = this.kids.find(item => item.id === presence.kid_id);
                    if (kid && kid.presences.length) {
                        const pre = kid.presences.find(item => item.id === presence.id);
                        if (pre) {
                            const i = kid.presences.indexOf(pre);
                            kid.presences.splice(i, 1);
                            kid.events = this.getEvents(kid);
                            this.getOccupation();
                            this.showStaffSummary ? this.getSupervision() : null;
                        }
                    }
                });

                this.$bus.$on('created:planning', planning => {
                    const kid = this.kids.find(item => item.id === planning.kid_id);
                    if (kid) {
                        const contract = kid.contracts.find(item => item.id === planning.contract_id);
                        if (contract) {
                            planning.nursery_id = this.nursery.id;
                            contract.plannings.push(planning);
                            kid.events = this.getEvents(kid);
                            this.getOccupation();
                            this.showStaffSummary ? this.getSupervision() : null;
                        }
                    } else {
                        this.getKids();
                        this.getOccupation();
                        this.showStaffSummary ? this.getSupervision() : null;
                    }
                });

                this.$bus.$on('updated:planning', planning => {
                    const kid = this.kids.find(item => item.id === planning.kid_id);
                    if (kid) {
                        const contract = kid.contracts.find(item => item.id === planning.contract_id);
                        if (contract && contract.plannings.length) {
                            const plan = contract.plannings.find(item => item.id === planning.id);
                            if (plan) {
                                plan.week = planning.week;
                                plan.day = planning.day;
                                plan.start_time = planning.start_time;
                                plan.end_time = planning.end_time;
                                plan.note = planning.note;
                                plan.unit = planning.unit;
                                plan.hours = planning.hours;
                                plan.hourly_rate = planning.hourly_rate;
                            }
                        }

                        kid.events = this.getEvents(kid);

                        this.getOccupation();
                        this.showStaffSummary ? this.getSupervision() : null;
                    }
                });

                this.$bus.$on('deleted:planning', planning => {
                    const kid = this.kids.find(item => item.id === planning.kid_id);
                    if (kid) {
                        const contract = kid.contracts.find(item => item.id === planning.contract_id);
                        if (contract && contract.plannings.length) {
                            const plan = contract.plannings.find(item => item.id === planning.id);
                            if (plan) {
                                const i = contract.plannings.indexOf(plan);
                                contract.plannings.splice(i, 1);
                            }
                        }
                        kid.absences = kid.absences.filter(item => item.planning_id !== planning.id);

                        kid.events = this.getEvents(kid);

                        this.getOccupation();
                        this.showStaffSummary ? this.getSupervision() : null;
                    }
                });

                this.getOccupation();
                this.showStaffSummary ? this.getSupervision() : null;
            },
            getKids() {
                this.loading = true;

                let tags = undefined;
                if (this.selectedTags) {
                    tags = this.selectedTags.length ? this.selectedTags : '';
                }

                if (this.currentGroup) {
                    useApi().legacy.get(route('nurseries.planning.kids', {nurseries: this.nursery.id}), {
                        params: {
                            from: this.from.unix(),
                            to: this.to.unix(),
                            group_id: this.currentGroup.id ? this.currentGroup.id : null,
                            tags,
                            order_by: window.localStorage.getItem('display:orderBy') ? window.localStorage.getItem('display:orderBy') : 'first_name',
                        },
                    }).catch(error => {
                        if (error && error.response && error.response.status === 422) {
                            _forEach(error.response.data.errors, function(value) {
                                useNotification().error(_head(value));
                            });
                        } else {
                            useNotification().error(error);
                        }
                    });
                }
            },
            getOccupation() {
                this.occupationLoading = true;

                let tags = undefined;
                if (this.selectedTags) {
                    tags = this.selectedTags.length ? this.selectedTags : '';
                }

                useApi().legacy.get(route('nurseries.occupation', {nurseries: this.nursery.id}), {
                    params: {
                        from: this.fromDay.unix(),
                        to: this.toDay.unix(),
                        group_id: this.currentGroup.id ? this.currentGroup.id : null,
                        interval: 5,
                        tags,
                    },
                }).catch(error => {
                    this.occupationLoading = false;
                    if (error && error.response && error.response.status === 422) {
                        _forEach(error.response.data.errors, function(value) {
                            useNotification().error(_head(value));
                        });
                    } else {
                        useNotification().error(error);
                    }
                });
            },
            getSupervision() {
                this.supervisionLoading = true;

                let tags = undefined;
                if (this.selectedTags) {
                    tags = this.selectedTags.length ? this.selectedTags : '';
                }

                useApi().legacy.get(route('nurseries.supervision', {nurseries: this.nursery.id}), {
                    params: {
                        from: this.fromDay.unix(),
                        to: this.toDay.unix(),
                        group_id: this.currentGroup.id ? this.currentGroup.id : null,
                        interval: 5,
                        tags,
                    },
                }).catch(error => {
                    this.supervisionLoading = false;
                    if (error && error.response && error.response.status === 422) {
                        _forEach(error.response.data.errors, function(value) {
                            useNotification().error(_head(value));
                        });
                    } else {
                        useNotification().error(error);
                    }
                });
            },
            getEvents(kid) {
                const kidPlannings = this.computeKidPlanning(kid);
                const kidPresences = getPresenceEvents(kid.presences, kidPlannings);
                const kidOverruns = getOverrunEvents(kidPresences, kidPlannings);

                return _concat(kidPlannings, kidPresences, kid.absences, kidOverruns);
            },
            computeKidPlanning(kid) {
                let output = [];
                _forEach(this.enumerateDaysBetweenDates, day => {
                    output = _concat(output, getPlanningEventsFromKidContracts(kid.contracts, day));
                });

                return output;
            },
            switchGroup(group) {
                if (!group) {
                    this.currentGroup = {
                        id: null,
                        name: __('common:all_kids'),
                    };
                } else {
                    this.currentGroup = group;
                }
                this.getKids();
                this.getOccupation();
                this.showStaffSummary ? this.getSupervision() : null;
            },

            hidePopovers() {
                this.$root.$emit('bv::hide::popover');
            },
        },
    };
</script>

<style lang="scss" scoped>
    @import "bootstrap/scss/functions";
    @import "@/assets/_bootstrap/variables";
    @import "bootstrap/scss/mixins/breakpoints";

    @include media-breakpoint-up(sm) {
        .kid {
            width: 200px;
        }

        .planning {
            width: calc(100% - 190px - 35px);
        }

        .summary-bar {
            margin-left: 190px;
        }
    }

    @media print {
        @page {
            size: auto;
            max-height: 100%;
            max-width: 100%;
        }
        body {
            writing-mode: tb-rl; //landscape
        }
        .line {
            padding: 0.5rem 1.25rem;
        }
    }

    .btn {
        -webkit-print-color-adjust: exact !important; /* Chrome, Safari */
        print-color-adjust: exact !important; /*Firefox*/
    }

    .line {
        padding: 0.75rem 0.75rem;

        &:nth-child(even) {
            @apply tw-bg-primary-50;
        }
    }
</style>
