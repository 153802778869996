<template>
    <CModal
        id="useTemplate"
        ref="modal"
        :header-title="__('hr_calendar:replace_weeks_by_week_template_colon')"
        size="7xl"
        :visible="true"
        @hidden="onHidden"
        @shown="onShown"
    >
        <loader
            v-if="weekTemplatesLoading"
            light="true"
            shadow="false"
            size="sm"
            :title="__('common:loading_dots')"
        />
        <template v-else-if="selectedTemplate || filteredTemplates.length">
            <div
                v-if="!filteredTemplates.length"
                class="tw-p-3"
            >
                <h5 class="h5 tw-text-center tw-mb-0">
                    {{ __('hr_calendar:no_week_template') }}
                </h5>
            </div>
            <div
                v-else
                class="tw-flex tw-flex-wrap tw-mb-2"
            >
                <div class="tw-flex tw-flex-wrap tw-w-full">
                    <CFormGroup
                        class="tw-w-full sm:tw-w-1/3 tw-mb-3"
                    >
                        <CButton
                            class="tw-w-full"
                            @click="$refs.weekTemplatesDropdown.toggle()"
                        >
                            {{ selectedTemplate.attributes.name }}
                            <i
                                aria-hidden="true"
                                class="fa fa-caret-down tw-ml-1"
                            />
                        </CButton>
                        <CDropdown
                            ref="weekTemplatesDropdown"
                            legacy-mode
                        >
                            <CList class="tw-cursor-pointer">
                                <CListRow
                                    v-for="(weekTemplate,
                                            i) in filteredTemplates"
                                    :key="'weekTemplate' + i"
                                    @click="selectedTemplate = weekTemplate"
                                >
                                    <i
                                        v-if="
                                            selectedTemplate.id ===
                                                weekTemplate.id
                                        "
                                        class="fa fa-check tw-mr-1"
                                    />
                                    {{ weekTemplate.attributes.name }}
                                </CListRow>
                            </CList>
                        </CDropdown>
                    </CFormGroup>
                </div>
            </div>

            <loader
                v-if="calendarLoading"
                light="true"
                shadow="false"
                size="sm"
                :title="__('hr_calendar:loading_week_template_dots')"
            />

            <div
                v-else
                class="tw-border tw-border-gray-200 tw-rounded-lg tw-mt-2 tw-p-3"
            >
                <div class="tw-text-sm tw-text-gray-600 tw-text-center tw-mb-3">
                    {{ __('hr_calendar:update_typical_week_before_applying_colon') }}
                </div>

                <loader
                    v-if="calendarLoading"
                    light="true"
                    shadow="false"
                    size="sm"
                    :title="__('common:loading_dots')"
                />
                <div
                    v-else
                    class="tw-pt-3"
                >
                    <div class="tw-flex">
                        <div class="tw-w-24 tw-mt-4">
                            <div class="tw-h-9"/>
                            <div
                                v-for="(planningTemplate, i) in planningsTemplates"
                                :key="'planning_template_staff' + i"
                                class="tw-flex tw-items-center tw-w-full tw-h-9 tw-mb-1"
                            >
                                <StaffFinder
                                    class="tw-w-full"
                                    :excluded-ids="planningTemplate.staff().value() ? [planningTemplate.staff().value().getKey()] : null"
                                    only-user-organizations
                                    @fallback="switchStaffPlanning(planningTemplate)"
                                    @input="switchStaffPlanning(planningTemplate, $event)"
                                >
                                    <template #fallback>
                                        {{ __('hr_calendar:not_allocated') }}
                                    </template>
                                    <template #trigger="{toggle}">
                                        <div
                                            class="tw-h-full tw-w-full tw-rounded hover:tw-bg-gray-50 hover:tw-border tw-border-dashed tw-border-blue-200 tw-cursor-pointer tw-p-1"
                                            @click="toggle"
                                            @mouseleave="$refs['staffDetail' + i][0].hide()"
                                            @mouseover="$refs['staffDetail' + i][0].show()"
                                        >
                                            <div
                                                v-if="planningTemplate.staff().value()"
                                                class="tw-flex tw-items-center tw-h-full"
                                            >
                                                <StaffAvatar
                                                    class="tw-mr-1 tw-shrink-0 tw-w-6"
                                                    :staff-model="planningTemplate.staff().value()"
                                                />
                                                <div
                                                    class="tw-text-base tw-truncate"
                                                    :class="planningTemplate.staff().value().genderColor"
                                                >
                                                    {{ planningTemplate.staff().value().fullname }}
                                                </div>
                                            </div>
                                            <div
                                                v-else
                                                class="tw-flex tw-items-center tw-h-full"
                                            >
                                                <div class="tw-w-6 tw-h-6 tw-bg-gray-300 tw-rounded-full tw-mr-1 tw-shrink-0 tw-flex tw-items-center">
                                                    <i
                                                        aria-hidden="true"
                                                        class="fas fa-question tw-text-gray-500 tw-w-full tw-text-center tw-text-sm"
                                                    />
                                                </div>
                                                <div class="tw-text-gray-600 tw-text-base tw-truncate">
                                                    N/A
                                                </div>
                                            </div>
                                            <CDropdown
                                                :ref="'staffDetail' + i"
                                                :clickable="false"
                                                hoverable
                                                legacy-mode
                                                placement="top-start"
                                            >
                                                <div class="tw-capitalize">
                                                    <div
                                                        v-if="planningTemplate.staff().value()"
                                                        :class="planningTemplate.staff().value().genderColor"
                                                    >
                                                        {{ planningTemplate.staff().value().fullname }}
                                                    </div>
                                                    <div
                                                        v-else
                                                        class="tw-text-gray-600"
                                                    >
                                                        {{ __('hr_calendar:not_allocated') }}
                                                    </div>
                                                </div>
                                                <div class="tw-my-2">
                                                    <EventTotals
                                                        :contract="planningTemplate.staff().value() && planningTemplate.staff().value().activeContract() ? `${planningTemplate.staff().value().activeContract().attributes.hours_per_week}h` : null"
                                                        :forecast="totalHours(planningTemplate)"
                                                        :real="null"
                                                    />
                                                </div>
                                                <div class="tw-flex tw-border-t tw-border-gray-200 tw-mt-1 tw-pt-2">
                                                    <CButton
                                                        class="tw-mr-2"
                                                        size="xs"
                                                        variant="link"
                                                        @click.stop="toggle"
                                                    >
                                                        <i
                                                            aria-hidden="true"
                                                            class="fa fa-retweet tw-mr-1"
                                                        />{{ __('common:actions.change') }}
                                                    </CButton>
                                                    <CButton
                                                        size="xs"
                                                        variant="link-danger"
                                                        @click.stop="confirmRemovePlanningTemplate(planningTemplate)"
                                                    >
                                                        <i
                                                            aria-hidden="true"
                                                            class="fa fa-trash-alt tw-mr-1"
                                                        />
                                                        {{ __('common:actions.delete_dots') }}
                                                    </CButton>
                                                </div>
                                            </CDropdown>
                                        </div>
                                    </template>
                                </StaffFinder>
                            </div>
                        </div>

                        <div class="tw-flex tw-justify-between tw-w-full tw-overflow-x-scroll">
                            <div
                                v-for="(dayOfWeek,
                                        dayIndex) in daysOfWeek"
                                :key="'currentDay' + dayIndex"
                                class="tw-w-full tw-min-w-48 tw-ml-3 showDayHoursTrigger"
                            >
                                <div class="tw-relative tw-h-5">
                                    <div class="tw-capitalize tw-text-blue-500 tw-w-full tw-text-center showDay">
                                        {{ dayOfWeek.format('dddd') }}
                                    </div>
                                </div>

                                <div class="tw-h-7 tw--ml-2 tw-pr-3.5 tw-mt-1">
                                    <Hours
                                        class="showDayHours"
                                        :closing="closing(dayOfWeek, true)"
                                        grid-on-hover
                                        :opening="opening(dayOfWeek, true)"
                                        simple
                                        small
                                    />
                                </div>

                                <div
                                    v-for="(planningTemplate, i) in planningsTemplates"
                                    :key="'planning_template' + i"
                                    class="tw-flex tw-items-center tw-h-9 tw-mb-1"
                                >
                                    <PlanningGenerator
                                        always-show-ghost
                                        :auto-save="false"
                                        :closing="closing(dayOfWeek, true)"
                                        create-with-modal
                                        :day="dayOfWeek"
                                        is-template
                                        :opening="opening(dayOfWeek, true)"
                                        :show-clock="false"
                                        :show-divider="false"
                                        show-note
                                        size="md"
                                        :template-events="eventsForCurrentDay(dayOfWeek, planningTemplate)"
                                        :week-template="planningTemplate.id"
                                        @pushEventTemplate="planningTemplate.planningEvents().value().all().push($event)"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <StaffFinder
                        class="tw-w-full tw-mt-3"
                        :excluded-ids="staffFinderExceptionIds"
                        only-user-organizations
                        @fallback="addPlanningTemplate"
                        @input="addPlanningTemplate"
                    >
                        <template #trigger="{toggle}">
                            <CVStack
                                align="center"
                                class="tw-border-2 tw-border-dashed hover:tw-border-blue-500 tw-rounded-xl tw-py-1 tw-cursor-pointer tw-duration-300 tw-transition-alltw-border-gray-300 hover:tw-border-blue-500 hover:tw-bg-blue-100 tw-group"
                                distribute="center"
                                @click="toggle"
                            >
                                <CText
                                    class="tw-text-gray-700 group-hover:tw-text-black"
                                    font-weight="semibold"
                                >
                                    <template v-if="planningTemplateLoading">
                                        <i
                                            aria-hidden="true"
                                            class="fa fa-circle-notch fa-spin tw-text-blue-500 tw-mr-2"
                                        />
                                        {{ __('common:adding_in_progress_dots') }}
                                    </template>
                                    <template v-else>
                                        <i
                                            aria-hidden="true"
                                            class="fad fa-plus-circle tw-text-blue-500 tw-mr-2"
                                        />
                                        {{ __('common:actions.add_line') }}
                                    </template>
                                </CText>
                            </CVStack>
                        </template>
                        <template #fallback>
                            {{ __('hr_calendar:not_allocated') }}
                        </template>
                    </StaffFinder>
                </div>
            </div>

            <div class="tw-text-gray-600 tw-text-base tw-font-semibold tw-mt-9 tw-mb-3">
                {{ __('hr_calendar:replace_weeks_by_week_template_colon') }}
            </div>

            <SelectWeeks :selected-weeks.sync="selectedWeeks"/>
            <hr class="hr">
            <CHStack>
                <CCheckbox v-model="deleteRealEvents">
                    {{ __('hr_calendar:delete_real_events_in_selected_weeks') }}
                </CCheckbox>
            </CHStack>

            <EventModal
                v-if="selectedEvent"
                ref="modalEditEvent"
                :event="selectedEvent"
                is-template
                soft-update
                @add="onEventAdded"
                @delete="onEventDeleted"
                @edit="onEventEdited"
                @hidden="selectedEvent = null"
            />
        </template>
        <div
            v-else
            class="tw-p-5 tw-bg-blue-100 tw-text-blue-500 tw-rounded-md tw-text-center"
        >
            <div class="tw-mb-3">
                {{ __('hr_calendar:no_week_templates') }}
            </div>
            <div class="tw-mb-3">
                <CButton
                    :icon-external-link="true"
                    onclick="Intercom('showArticle', 5538054)"
                    size="none"
                    variant="link"
                >
                    {{ __('common:show_guide') }}
                </CButton>
            </div>
        </div>
        <div class="tw-mt-4">
            <FeatureFlag name="enable-new-template-service">
                <InvalidEventErrors :error-handler="invalidEventErrors"/>
                <template #disabled>
                    <FailedEventsAlert :failed-events="failedEvents"/>
                </template>
            </FeatureFlag>
        </div>
        <template #footer>
            <CButton
                class="tw-ml-auto"
                variant="light"
                @click="$refs.modal.hide()"
            >
                {{ __('common:actions.cancel') }}
            </CButton>

            <CButton
                v-if="filteredTemplates.length"
                class="tw-ml-2"
                :loading="saveLoading"
                variant="primary"
                @click="!saveLoading ? useWeek() : null"
            >
                {{ __('hr_calendar:apply_week_template') }}
            </CButton>
        </template>
    </CModal>
</template>

<script lang="ts">
    import {defineComponent, ref, set} from 'vue';
    import useGlobalWeekTemplate from '@/modules/human-resources/composables/calendar/useGlobalWeekTemplate';
    import Hours from '@/modules/legacy/components/Modules/PlanningGenerator/Hours.vue';
    import Line from '@/modules/human-resources/components/calendar/Line.vue';
    import EventModal from '@/modules/human-resources/components/calendar/EventModal.vue';
    import EventTotals from '@/modules/human-resources/components/calendar/EventTotals.vue';
    import SelectWeeks from '@/modules/human-resources/components/calendar/CalendarTemplate/SelectWeeks.vue';
    import StaffFinder from '@/modules/request/components/Teams/StaffFinder.vue';
    import FailedEventsAlert
        from '@/modules/human-resources/components/calendar/CalendarTemplate/FailedEventsAlert.vue';
    import StaffAvatar from '@/modules/app/components/atoms/avatars/StaffAvatar.vue';
    import FeatureFlag from '@/modules/app/components/atoms/FeatureFlag.vue';
    import InvalidEventErrors
        from '@/modules/human-resources/apply-template/infrastructure/components/InvalidEventErrors.vue';

    export default defineComponent({
        components: {
            InvalidEventErrors,
            FeatureFlag,
            StaffAvatar,
            FailedEventsAlert,
            Hours,
            PlanningGenerator: Line,
            EventModal,
            EventTotals,
            StaffFinder,
            SelectWeeks,
        },
        setup(props, {emit}) {
            const {
                modal,
                onShown,
                onHide,

                calendarLoading,
                opening,
                closing,
                daysOfWeek,

                selectedWeeks,
                saveLoading,
                deleteRealEvents,
                failedEvents,
                invalidEventErrors,
                useWeek,

                staffFinderExceptionIds,
                filteredTemplates,
                selectedTemplate,
                weekTemplatesLoading,

                planningsTemplates,
                planningTemplateLoading,
                addPlanningTemplate,
                switchStaffPlanning,
                confirmRemovePlanningTemplate,

                totalHours,
                eventsForCurrentDay,
                resetEvents,
                deleteEvents,
                selectedEvent,
                modalEditEvent,
                onEventAdded,
                onEventEdited,
                onEventDeleted,
            } = useGlobalWeekTemplate(ref(null), false);

            const onHidden = function() {
                onHide();
                emit('hidden');
            };

            return {
                set,
                modal,
                onShown,
                onHide,

                calendarLoading,
                opening,
                closing,
                daysOfWeek,

                selectedWeeks,
                saveLoading,
                deleteRealEvents,
                failedEvents,
                invalidEventErrors,
                useWeek,

                staffFinderExceptionIds,
                filteredTemplates,
                selectedTemplate,
                weekTemplatesLoading,

                planningsTemplates,
                planningTemplateLoading,
                addPlanningTemplate,
                switchStaffPlanning,
                confirmRemovePlanningTemplate,

                totalHours,
                eventsForCurrentDay,
                resetEvents,
                deleteEvents,
                selectedEvent,
                modalEditEvent,
                onEventAdded,
                onEventEdited,
                onEventDeleted,

                onHidden,
            };
        },
    });
</script>

<style lang="scss" scoped>
    .showDayHours {
        @apply tw-opacity-0 tw-transition-all tw-ease-in-out tw-duration-300;
    }

    .showDay {
        @apply tw-transition-all tw-ease-in-out tw-absolute tw-top-5;
    }

    .showDayHoursTrigger:hover {
        .showDayHours {
            @apply tw-opacity-100;
        }

        .showDay {
            @apply tw-top-0;
        }
    }
</style>
