<template>
    <CInline wrap>
        <div class="tw-w-full md:tw-w-1/4 tw-min-w-64 tw-p-3 tw-border-b md:tw-border-b-0 md:tw-border-r tw-border-gray-200">
            <slot name="menu"/>
        </div>
        <div class="tw-flex-1 tw-p-3">
            <slot/>
        </div>
    </CInline>
</template>

<script lang="ts">
    import {defineComponent} from 'vue';

    export default defineComponent({
        components: {},
        props: {},
        setup() {
            return {};
        },
    });
</script>

