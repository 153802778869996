<template>
    <list-item
        :archived="isPivotArchived"
        :shared="isUsedInAnotherOrganization"
    >
        <CHStack
            class="tw-w-full"
            distribute="between"
        >
            <div class="tw-grid tw-grid-cols-6 tw-gap-x-4 tw-flex-1">
                <CText class="tw-col-span-9 md:tw-col-span-4">
                    {{ model.attributes.name }}
                </CText>
                <CHStack class="tw-col-span-3 md:tw-col-span-2">
                    <CCenter
                        v-for="(eventType, index) in eventTypesRelation.value()"
                        :key="index + eventType.id"
                        class="tw-h-6 tw-w-6 tw-rounded-full tw-text-sm"
                        :style="getIconStyle(eventType.attributes.color)"
                    >
                        <CIcon
                            v-if="DataStore.iconPath(eventType.attributes.icon_id)"
                            :path="DataStore.iconPath(eventType.attributes.icon_id).attributes.path"
                            :provider="DataStore.iconPath(eventType.attributes.icon_id).attributes.provider"
                        />
                    </CCenter>
                </CHStack>
            </div>
        </CHStack>
        <template #action>
            <BalanceTypeListItemMenu
                v-if="activePivot"
                :is-pivot-archived="isPivotArchived"
                :is-used="isUsed"
                :is-used-in-another-organization="isUsedInAnotherOrganization"
                :model="model"
                :organization-pivot="activePivot"
                @deleted="onDeletedItem"
                @updated="onUpdatedItem"
            />
        </template>
    </list-item>
</template>

<script lang="ts">
    import type {PropType} from 'vue';
    import {defineComponent, ref, toRef, watch} from 'vue';
    import BalanceTypeListItemMenu
        from '@/modules/human-resources/components/balance-type/List/BalanceTypeListItemMenu.vue';
    import type BalanceTypeModel from '@/modules/human-resources/models/BalanceTypeModel';
    import useSharedList from '@/modules/app/composables/useSharedList';
    import DataStore from '@/modules/legacy/store/data.store';
    import useEventStyle from '@/modules/human-resources/composables/calendar/useEventStyle';

    export default defineComponent({
        components: {
            BalanceTypeListItemMenu,
        },
        props: {
            model: {type: Object as PropType<BalanceTypeModel>, required: true},
        },
        setup(props, {emit}) {
            const isUsed = ref(false);

            // Fetch relations
            const eventTypesRelation = props.model.eventTypes().setQuery(q => {
                q.whereHas('organizations', q1 => q1.scope('active'));
            });
            eventTypesRelation.load();
            props.model.balancePeriods().load();
            props.model.balanceAllocations().load().then(response => isUsed.value = response.isNotEmpty());

            const getIconStyle = function(color) {
                return color ? useEventStyle(ref(color), ref(false), 'md', true).badgeStyle.value : null;
            };

            const {
                relation,
                activePivot,
                isPivotArchived,
                isUsedInAnotherOrganization,
            } = useSharedList(toRef(props, 'model') as any);

            watch(props.model, () => {
                relation.load();
            }, {immediate: true});

            const onUpdatedItem = function(value) {
                emit('updated', value);
            };

            const onDeletedItem = function(value) {
                emit('deleted', value);
            };

            return {
                activePivot,
                isPivotArchived,
                isUsedInAnotherOrganization,
                isUsed,
                DataStore,
                getIconStyle,
                onUpdatedItem,
                onDeletedItem,
                eventTypesRelation,
            };
        },
    });
</script>
