<template>
    <div @click="$root.$emit('bv::hide::popover')">
        <h2
            class="h2 tw-font-grandhotel text-capitalize print:!tw-block tw-hidden tw-text-center"
            :class="kid.gender === 'male' ? 'tw-text-boy' : 'tw-text-girl'"
        >
            {{ $fullName(kid) }}
        </h2>
        <div class="row print:tw-hidden">
            <div class="col-12 col-lg-6 mb-4 mb-lg-0">
                <LegacyKidAvatar
                    class="tw-float-left tw-mr-2 tw-w-16"
                    :kid="kid"
                />
                <div>
                    <h2
                        class="h2 tw-font-grandhotel d-inline-block text-capitalize mr-3"
                        :class="kid.gender === 'male' ? 'tw-text-boy' : 'tw-text-girl'"
                    >
                        {{ $fullName(kid) }}
                    </h2>
                    <MButton
                        v-if="$can('update', 'kids_details')"
                        :label="__('common:actions.update')"
                        variant="primary"
                        @click="editKidModal.show"
                    >
                        <template #left-icons>
                            <FontAwesomeIcon icon="fa fa-pencil"/>
                        </template>
                    </MButton>
                </div>
                <div>
                    <p class="text-muted mb-0 d-inline-block">
                        <span v-if="kid.birthdate">
                            <template v-if="parse(kid.birthdate, 'yyyy-MM-dd').lessThanOrEqualTo(Epoch.now())">
                                {{ __('common:born_on', {context: kid.gender}) }}
                            </template>
                            <template v-else>
                                {{ __('common:scheduled_for_female') }}
                            </template>

                            {{ parse(kid.birthdate, 'yyyy-MM-dd').toLocaleString(Epoch.presets.DATE_SHORT) }}
                        </span>
                        <span v-else>
                            {{ __('common:not_filled') }}
                        </span>
                        -
                        <span v-if="kid.group_id">{{ kid.group.name }}</span>
                        <span v-else>{{ __('common:no_group') }}</span>
                    </p>
                </div>
            </div>
            <div class="col-12 col-lg-6">
                <CFormTextArea
                    v-model="kid.note"
                    class="tw-resize-none !tw-bg-primary-50"
                    :placeholder="__('common:note_one')"
                    readonly
                    rows="3"
                />
            </div>
        </div>
        <div class="print:tw-hidden tw-my-6 tw-bg-blue-50 tw-rounded-lg tw-p-2">
            <div class="row">
                <div class="col-6 text-center">
                    <template v-if="kid.hygiene === 'baby'">
                        <i
                            class="fa fa-tint mr-2 text-primary text-center"
                            style="width: 20px;"
                        />
                        {{ __('common:hygiene_types.diaper') }}
                    </template>
                    <template v-else-if="kid.hygiene === 'mixed'">
                        <i
                            class="fa fa-tint mr-2 text-primary text-center"
                            style="width: 20px;"
                        />
                        {{ __('common:learning') }}
                    </template>
                    <template v-else-if="kid.hygiene === 'potty'">
                        <i
                            class="fa fa-tint mr-2 text-primary text-center"
                            style="width: 20px;"
                        />
                        {{ __('family_kid:potty') }}
                    </template>
                </div>
                <MTooltip
                    class="col-6 tw-flex tw-items-center tw-justify-center tw-cursor-pointer"
                    :label="__('family_kid:last_value_added')"
                    placement="top"
                >
                    <div @click="$emit('weightClicked')">
                        <i
                            class="fa fa-weight mr-2 text-primary text-center"
                            style="width: 20px;"
                        />
                        {{ __('family_kid:weight_value', {weight: kid.kilogram}) }}
                    </div>
                </MTooltip>
            </div>
        </div>

        <div
            v-if="$can('read', 'kids_planning')"
            class="row"
        >
            <div class="col-12">
                <planning
                    :kid="kid"
                    :nursery="nursery"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import {Epoch} from '@meekohq/lumos';
    import useEpoch from '@/modules/app/composables/useEpoch';
    import {defineComponent, toRef} from 'vue';
    import useModal from '@/modules/app/composables/useModal';
    import NoAvatarImage from '@/assets/images/no-avatar.png';
    import Planning from './Summary/Planning/Show.vue';
    import LegacyKidAvatar from '@/modules/app/components/atoms/avatars/LegacyKidAvatar.vue';
    import EditKidModal from '@/modules/family/components/kid/Kid/Summary/EditKidModal/organisms/EditKidModal.vue';

    export default defineComponent({
        components: {
            LegacyKidAvatar,
            planning: Planning,
        },
        props: {
            kid: {
                type: Object,
                required: true,
            },
            nursery: {
                type: Object,
                required: true,
            },
        },
        setup(props) {
            const {parse} = useEpoch();

            const editKidModal = useModal({
                component: EditKidModal,
                props: {
                    kid: toRef(props, 'kid'),
                    nursery: toRef(props, 'nursery'),
                },
            });

            return {
                Epoch,
                parse,
                NoAvatarImage,
                editKidModal,
            };
        },
    });
</script>
