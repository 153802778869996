<template>
    <CVStack class="tw-w-full">
        <CButton
            v-if="filteredParticipants.isEmpty()"
            @click="addDraftParticipant"
        >
            {{ __('activity:add_participant') }}
        </CButton>
        <CButtonGroup
            v-else
            @click.native="addDraftParticipant"
        >
            <CButton
                align="left"
                class="tw-flex-1 !tw-pr-4"
                :truncate="false"
            >
                <div class="tw-flex tw-flex-wrap tw-gap-1">
                    <template v-for="participant in orderedParticipants">
                        <ParticipantItem
                            :key="participant.getKey()"
                            :participant="participant"
                            @remove="removeParticipant"
                        />
                    </template>
                </div>
            </CButton>
            <MTooltip
                v-if="participantsWithoutEmail.isNotEmpty()"
                class="tw-h-auto"
            >
                <CButton
                    class="tw-h-full"
                    variant="danger"
                >
                    <FontAwesomeIcon icon="far fa-exclamation-triangle"/>
                </CButton>
                <template #content>
                    {{ __('activity:following_participants_not_have_email_and_therefore_not_receive_notifications_warning_colon') }}
                    {{ participantsWithoutEmailString }}
                </template>
            </MTooltip>
        </CButtonGroup>
        <CDropdown
            ref="addParticipantDropdown"
            legacy-mode
            @hidden="onHidden"
        >
            <CVStack>
                <ParticipantForm
                    v-if="draftParticipant"
                    v-model="draftParticipant"
                    :allowed-organization-ids="allowedOrganizationIds"
                    :custom-select="customSelect"
                    :exclude-participants="filteredParticipants"
                    @cancel="removeDraftParticipant"
                    @validate="addParticipant"
                />
            </cvstack>
        </CDropdown>
    </CVStack>
</template>

<script lang="ts">
    import type {PropType} from 'vue';
    import {computed, defineComponent, ref} from 'vue';
    import MeetingParticipantModel from '@/modules/activity/models/MeetingParticipantModel';
    import type MeetingModel from '@/modules/activity/models/MeetingModel';
    import ParticipantForm from '@/modules/activity/components/ParticipantForm.vue';
    import type {CustomSelectType} from '@/modules/activity/utils/CustomSelectType';
    import ParticipantItem from '@/modules/activity/components/ParticipantItem.vue';
    import type {ModelCollection} from '@meekohq/lumos';

    export type ParticipantType = 'custom' | 'staff' | 'config';

    export default defineComponent({
        components: {ParticipantItem, ParticipantForm},
        props: {
            value: {
                type: Object as PropType<ModelCollection<MeetingParticipantModel>>,
                required: true,
            },
            meeting: {
                type: Object as PropType<MeetingModel>,
                required: true,
            },
            customSelect: {
                type: Object as PropType<CustomSelectType>,
                default: null,
            },
            allowedOrganizationIds: {
                type: Array as PropType<string[]>,
                default: null,
            },
        },
        setup(props) {
            const filteredParticipants = computed(() => {
                return props.value.filter(participant => !participant.markedForDeletion);
            });

            const orderedParticipants = computed(() => {
                return filteredParticipants.value.sortBy('attributes.resource_type').sort();
            });

            const addParticipantDropdown = ref();

            const draftParticipant = ref();

            function onHidden() {
                removeDraftParticipant();
            }

            const participantsWithoutEmail = computed(() =>
                orderedParticipants.value.filter(participant => participant.attributes.email === null),
            );

            const participantsWithoutEmailString = computed(() =>
                participantsWithoutEmail.value.map(part => {
                    let fullname = part.attributes.first_name;

                    if (part.attributes.last_name) {
                        fullname += ` ${part.attributes.last_name}`;
                    }

                    return fullname;
                }).join(', '),
            );

            function addDraftParticipant() {
                draftParticipant.value = new MeetingParticipantModel();

                addParticipantDropdown.value.show();
            }

            function removeDraftParticipant() {
                draftParticipant.value = undefined;

                addParticipantDropdown.value.hide();
            }

            function addParticipant(participant: MeetingParticipantModel) {
                participant.meeting().associate(props.meeting);
                props.value.push(participant);

                addParticipantDropdown.value.hide();
            }

            /**
             * Two kinds of remove:
             *  - If the participant was already saved, mark it for deletion. It will be deleted when the meeting is saved
             *  - If the participant was not saved, remove it from the collection, so it doesn't get saved by the meeting
             * @param participant item to remove
             */
            function removeParticipant(participant: MeetingParticipantModel) {
                if (participant.exists) {
                    participant.markForDeletion();
                } else {
                    const idx = props.value.all().findIndex(item => item.getKey() === participant.getKey());
                    if (idx !== -1) {
                        props.value.splice(idx, 1);
                    }
                }

                addParticipantDropdown.value.hide();
            }

            return {
                addDraftParticipant,
                addParticipant,
                addParticipantDropdown,
                draftParticipant,
                filteredParticipants,
                onHidden,
                orderedParticipants,
                participantsWithoutEmail,
                participantsWithoutEmailString,
                removeDraftParticipant,
                removeParticipant,
            };
        },
    });
</script>

