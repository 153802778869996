<script setup lang="ts">
    import {ref, watch} from 'vue';
    import {useElementSize, useWindowSize} from '@vueuse/core';

    const isTruncated = ref(false);
    const valueEl = ref();
    const {width: windowWidth, height: windowHeight} = useWindowSize();
    const {width: valueElWidth} = useElementSize(valueEl);

    watch([valueEl, windowWidth, windowHeight, valueElWidth], () => {
        if (valueEl.value) {
            isTruncated.value = valueEl.value.offsetWidth < valueEl.value.scrollWidth;
        }
    }, {immediate: true});
</script>

<template>
    <MTooltip
        class="MClamp"
        :hoverable="isTruncated"
        placement="top"
    >
        <div
            ref="valueEl"
            class="MClamp__value"
        >
            <slot/>
        </div>
        <template #content>
            <slot/>
        </template>
    </MTooltip>
</template>

<style lang="scss" scoped>
    .MClamp {
        @apply tw-w-full tw-min-w-0;
    }

    .MClamp__value {
        @apply tw-truncate;
    }
</style>

