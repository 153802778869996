import {Model} from '@meekohq/lumos';

export default class RequestOrganizationPivotModel extends Model {
    public type = 'request/requests_organizations_pivot';

    attributes: {
        id: string,
        account_id: string | undefined;
        request_id: string | undefined,
        organization_id: string | undefined,
    } = {
            id: this.uuid(),
            account_id: undefined,
            request_id: undefined,
            organization_id: undefined,
        };
}
