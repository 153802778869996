<template>
    <div :class="{'tw-grid tw-gap-4 lg:tw-grid-cols-3 md:tw-grid-cols-2 tw-grid-cols-1': viewMode === 'cards'}">
        <div
            v-for="registration in registrationsLegaciesAndModels"
            :key="registration.model.getKey()"
        >
            <RowItem
                v-if="viewMode === 'list'"
                :nursery="nursery"
                :registration="registration.legacy"
                :registration-model="registration.model"
                @estimate="$emit('estimate', registration.legacy)"
            />
            <CardItem
                v-else
                :key="registration.model.getKey()"
                :nursery="nursery"
                :registration="registration.legacy"
                :registration-model="registration.model"
                @estimate="$emit('estimate', registration.legacy)"
            />
        </div>
    </div>
</template>

<script lang="ts">
    import RowItem from '@/modules/registration/components/molecules/List/RowItem.vue';
    import CardItem from '@/modules/registration/components/molecules/List/CardItem.vue';
    import {computed, type PropType} from 'vue';
    import {defineComponent, set, watch} from 'vue';
    import RegistrationModel from '@/modules/registration/models/RegistrationModel';
    import useGetTasks from '@/modules/activity/composables/useGetTasks';
    import {watchIgnorable} from '@vueuse/core';
    import useMetrics from '@/modules/app/composables/useRum';

    export default defineComponent({
        components: {
            RowItem,
            CardItem,
        },
        props: {
            nursery: {
                type: Object,
                required: true,
            },
            registrations: {
                type: Array as PropType<Array<{ id, tasks }>>,
                required: true,
            },
            registrationsModels: {
                type: Array as PropType<RegistrationModel[]>,
                required: true,
            },
            viewMode: {
                type: String as PropType<'cards' | 'list'>,
                required: true,
            },
        },
        setup(props) {
            watch(() => props.viewMode, () => {
                useMetrics().addAction('M_Registrations_Display', {value: props.viewMode === 'cards' ? 'cards' : 'list'});
            }, {immediate: true});

            const {
                tasks,
                tasksGroupByModelId: tasksByRegistrationId,
                getTasks: getTasksRelatedToRegistrations,
            } = useGetTasks({
                constrainToResourceModel: RegistrationModel,
            });

            const registrationsLegaciesAndModels = computed(() => {
                return props.registrations.map(legacy => {
                    const model = props.registrationsModels.find(registrationModel => registrationModel.getKey() === legacy.id);

                    return {
                        legacy,
                        model,
                    };
                });
            });

            // @ts-ignore
            const {ignoreUpdates} = watchIgnorable(() => props.registrations, registrations => {
                getTasksRelatedToRegistrations(registrations.map(registration => registration.id)).then(tasksRelatedToRegistrations => {
                    return tasks.value = tasksRelatedToRegistrations;
                });
            }, {immediate: true});

            watch(tasksByRegistrationId, tasks => {
                ignoreUpdates(() => {
                    props.registrations.map(registration => {
                        if (tasks[registration.id]) {
                            set(registration, 'tasks', tasks[registration.id]);
                        }

                        return registration;
                    });
                });
            }, {immediate: true});

            return {
                tasksByRegistrationId,
                registrationsLegaciesAndModels,
            };
        },
    });
</script>
