<template>
    <div>
        <CButton @click="$refs.dropdownExport.show()">
            <i
                aria-hidden="true"
                class="fad fa-file-download tw-text-blue-500 tw-mr-2"
            />{{ __('billing_customer:accounting_export') }}
        </CButton>
        <CDropdown
            ref="dropdownExport"
            :content-class="['tw-w-min']"
            legacy-mode
        >
            <div>
                <MDatePeriodFilter v-model="periodFilter"/>
                <OrganizationFinder
                    v-model="selectedOrganizations"
                    button-class="tw-w-full tw-mt-3"
                    :inject-query="organizationFinderQuery"
                    multi
                    :multi-minimum="1"
                />

                <CFormSelect
                    v-model="exportTypes"
                    button-class="tw-w-full tw-mt-3"
                    multi
                    :options="exportTypesOptions"
                    :search-bar="false"
                />

                <CButton
                    class="tw-w-full tw-mt-5"
                    :disabled="!selectedOrganizations.length"
                    :loading="loading"
                    variant="primary"
                    @click="accountingExport()"
                >
                    {{ __('common:actions.export') }}
                </CButton>
            </div>
        </CDropdown>
    </div>
</template>

<script lang="ts">
    import {computed, defineComponent, ref} from 'vue';
    import moment from 'moment';
    import __ from '@/modules/app/utils/i18n-facade';
    import {MqlOperation, type ResponseObject, Str} from '@meekohq/lumos';
    import useUserFilters, {OutputType} from '@/modules/app/composables/useUserFilters';
    import useManager from '@/modules/app/composables/useManager';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';
    import TenantModel from '@/modules/cashier/models/TenantModel';
    import OrganizationModel from '@/modules/organization/models/OrganizationModel';
    import MDatePeriodFilter from '@/modules/meeko-ui/components/MDatePeriodFilter.vue';
    import OrganizationFinder from '@/modules/organization/components/OrganizationFinder.vue';

    export default defineComponent({
        components: {
            MDatePeriodFilter,
            OrganizationFinder,
        },
        setup() {
            const loading = ref(false);
            const {error: toastError} = useNotification();

            const {selectedOptions: from} = useUserFilters('billing:account:export:from', OutputType.value, moment().startOf('month').toISOString(true));
            const {selectedOptions: to} = useUserFilters('billing:account:export:to', OutputType.value, moment().endOf('month').toISOString(true));

            const periodFilter = ref({
                from: from.value,
                to: to.value,
            });

            const {selectedOptions: exportTypes} = useUserFilters('billing:account:export:types', OutputType.array, ['invoices', 'transactions', 'deposits']);

            const exportTypesOptions = [
                {value: 'invoices', text: __('common:invoice_other')},
                {value: 'transactions', text: __('common:transactions')},
                {value: 'deposits', text: __('common:deposit_other')},
            ];

            const {activeOrganization} = useManager();
            const selectedOrganizations = ref([activeOrganization.value]);

            const organizationFinderQuery = computed(() => OrganizationModel.query().scope('userOrganizations'));

            async function accountingExport() {
                loading.value = true;

                try {
                    const tenantsModels = await getTenantsFromSelectedOrganizations();
                    const tenantsIds: string[] = tenantsModels.pluck('id').toArray();

                    new MqlOperation<ResponseObject.CustomObject>('cashier/accouting_export', {
                        // Hack to remove timezone from date because we use a plain date (will be fixed in the future)
                        from: moment.utc(moment(periodFilter.value.from).format('YYYY-MM-DDTHH:mm:ss')).unix(),
                        to: moment.utc(moment(periodFilter.value.to).format('YYYY-MM-DDTHH:mm:ss')).unix(),
                        tenant_ids: tenantsIds,
                        types: exportTypes.value,
                    }).run().then(async result => {
                        // Generate fake link to download
                        const link = document.createElement('a');
                        link.download = Str.uuid() + '.csv';
                        link.target = '_blank';
                        link.href = 'data:application/csv;base64,' + result.content.data.csv;
                        link.dispatchEvent(new MouseEvent('click', {bubbles: true, cancelable: true, view: window}));
                    }).catch(() => {
                        toastError(__('common:errors.generic'));
                    });

                    loading.value = false;
                } catch (error) {
                    loading.value = false;
                }
            }

            async function getTenantsFromSelectedOrganizations() {
                const organizationsIds: string[] = selectedOrganizations.value.map(item => item.getKey());

                return await TenantModel.query().whereHas(new TenantModel().organizations(), query => {
                    query.whereIn('id', organizationsIds);
                }).get();
            }

            return {
                accountingExport,
                exportTypes,
                exportTypesOptions,
                loading,
                organizationFinderQuery,
                periodFilter,
                selectedOrganizations,
            };
        },
    });
</script>
