<template>
    <div>
        <p class="tw-text-gray-600 tw-text-base mb-0">
            <span class="tw-font-semibold">{{ notification.data.title }}</span>.
        </p>
        <p class="tw-text-sm mb-0 mt-1">
            {{ notification.data.content }}
        </p>
    </div>
</template>

<script>
    import moment from 'moment';

    export default {
        props: {
            notification: {type: Object},
        },
        computed: {
            moment() {
                return moment;
            },
        },
    };
</script>
