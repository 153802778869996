<template>
    <AbstractItem>
        <template #icon>
            <CTooltip>
                <font-awesome-icon
                    class="tw-text-pink-600"
                    fixed-width
                    :icon="getMeetingStateIcon(meeting)"
                    size="lg"
                />
                <template #content>
                    {{ __('activity:meeting_state', {context: meeting.attributes.state}) }}
                </template>
            </CTooltip>
        </template>
        <template #title>
            <CVStack
                class="tw-flex-1 sm:tw-rounded-lg sm:tw-p-2"
                :class="{'sm:hover:tw-bg-gray-50 sm:tw-transition-colors sm:tw-ease-in-out tw-cursor-pointer': !isEditing}"
                @click="isEditing ? undefined : setIsEditing(true)"
            >
                <CText
                    class="tw-break-all"
                    font-size="lg"
                >
                    <template v-if="!meeting.exists">
                        {{ __('activity:meeting_one') }}
                    </template>
                    <template v-else>
                        <CLongText :value="meeting.attributes.name"/>
                        <CText
                            class="tw-capitalize"
                            font-weight="semibold"
                        >
                            {{ formatDate(meeting.attributes.datetime_period.started_at, 'dddd D MMM YYYY') }} ({{ duration }})
                        </CText>
                        <CTooltip
                            v-if="meeting.rescheduledMeeting().value()"
                            class="tw-ml-2 tw-text-sm"
                            placement="bottom"
                        >
                            <i
                                aria-hidden="true"
                                class="far fa-history tw-text-primary-800 tw-cursor-pointer"
                                @click="$emit('meeting:scroll', meeting.rescheduledMeeting().value().getKey())"
                            />
                            <template #content>
                                <CText>
                                    {{ __('activity:rescheduled_from_colon') }} {{ meeting.rescheduledMeeting().value().attributes.name }}
                                </CText>
                            </template>
                        </CTooltip>
                    </template>
                </CText>
                <CText
                    v-if="!meeting.participants().value().count()"
                    variant="disabled"
                >
                    {{ __('activity:meeting_total', {count: meeting.participants().value().count()}) }}
                </CText>
                <CTooltip
                    v-else
                    class="tw-w-fit"
                    placement="bottom"
                >
                    <CText variant="disabled">
                        {{ __('activity:meeting_total', {count: meeting.participants().value().count()}) }}
                    </CText>
                    <template #content>
                        <CList :striped="true">
                            <CHStack
                                v-for="participant in meeting.participants().value()"
                                :key="participant.getKey()"
                                gap="5"
                            >
                                <CText
                                    class="tw-w-1/6"
                                    variant="disabled"
                                >
                                    {{ participant.attributes.first_name }}
                                </CText>
                                <CText
                                    class="tw-w-1/6"
                                    variant="disabled"
                                >
                                    {{ participant.attributes.last_name }}
                                </CText>
                                <CText
                                    class="tw-w-3/6"
                                    variant="disabled"
                                >
                                    {{ participant.attributes.email }}
                                </CText>
                                <CBadge
                                    class="tw-mr-1 tw-w-1/6"
                                    size="sm"
                                    :variant="getAvailabilityVariant(participant)"
                                >
                                    {{ __('activity:participant_state', {context: participant.attributes.availability}) }}
                                </CBadge>
                            </CHStack>
                        </CList>
                    </template>
                </CTooltip>
                <CHStack
                    v-if="meeting.attributes.location"
                    align="center"
                    class="tw-text-sm"
                >
                    <i
                        aria-hidden="true"
                        class="far fa-map-marker-alt tw-mr-1 tw-text-disabled"
                    />
                    <CText variant="disabled">
                        {{ meeting.attributes.location }}
                    </CText>
                </CHStack>
            </CVStack>
        </template>
        <template #date>
            <CVStack class="tw-whitespace-nowrap">
                <CText variant="disabled">
                    {{ formatDate(meeting.computed.created_at, 'DD/MM/YYYY - HH:mm') }}
                </CText>
                <CText
                    v-if="reporter"
                    font-size="sm"
                    variant="disabled"
                >
                    {{ __('common:by') }}
                    {{ reporter.fullname }}
                </CText>
            </CVStack>
        </template>
        <template #actions>
            <CDropdownMenu v-if="can('update', 'registrations')">
                <template #button>
                    <CButton icon-ellipsis/>
                </template>
                <CDropdownMenuItem
                    class="tw-whitespace-nowrap"
                    :disabled="meeting.attributes.state === MeetingStateValue.rescheduled"
                    icon="far fa-history"
                    :label="__('activity:reschedule_the_meeting')"
                    @click="rescheduleMeeting"
                />
                <CDropdownMenuItem
                    v-if="meeting.attributes.state === MeetingStateValue.canceled"
                    icon="fa-solid fa-calendar-plus"
                    :label="__('common:actions.restore')"
                    @click="toggleState"
                />
                <CDropdownMenuItem
                    v-else
                    icon="fa-solid fa-calendar-xmark"
                    :label="__('activity:cancel_meeting')"
                    @click="toggleState"
                />
                <CDropdownMenuDivider/>
                <CDropdownMenuItem
                    class="tw-whitespace-nowrap"
                    icon="fa fa-pen"
                    :label="__('common:actions.update')"
                    @click="setIsEditing(true)"
                />
                <CDropdownMenuItem
                    class="tw-whitespace-nowrap"
                    icon="fa fa-trash"
                    :label="__('common:actions.delete')"
                    variant="danger"
                    @click="remove"
                />
            </CDropdownMenu>
        </template>
        <template
            v-if="isEditing || meetingHasExtraInfos"
            #content
        >
            <template v-if="isEditing">
                <MeetingForm
                    :allowed-organization-ids="allowedOrganizationIds"
                    :disable-save-button="saving"
                    :meeting="editedMeeting"
                    :participants-custom-select="meetingParticipantsCustomSelect"
                    @saved="save"
                    @undo="cancel"
                />
            </template>
            <template v-else>
                <CVStack gap="4">
                    <CText v-if="meeting.attributes.description">
                        {{ meeting.attributes.description }}
                    </CText>
                    <template v-if="meeting.attributes.internal_note">
                        <CHStack
                            align="center"
                            class="tw-text-yellow-700 tw-pt-3 tw-border-t tw-border-gray-200"
                        >
                            <i
                                aria-hidden="true"
                                class="fad fa-note tw-mr-2"
                            />
                            <CText>
                                {{ meeting.attributes.internal_note }}
                            </CText>
                        </CHStack>
                    </template>
                </CVStack>
            </template>
        </template>
    </AbstractItem>
</template>
<script lang="ts">
    import type {PropType} from 'vue';
    import {computed, defineComponent, ref, toRef, watch} from 'vue';
    import type MeetingModel from '@/modules/activity/models/MeetingModel';
    import MeetingStateValue from '@/modules/activity/utils/MeetingStateValue';
    import useAbility from '@/modules/app/composables/useAbility';
    import useMoment from '@/modules/app/composables/useMoment';
    import useAttachMeeting from '@/modules/activity/composables/useAttachMeeting';
    import useMeetingParticipantsCustomSelect
        from '@/modules/registration/composables/useMeetingParticipantsCustomSelect';
    import AbstractItem from '@/modules/registration/components/molecules/Timeline/AbstractItem.vue';
    import MeetingForm from '@/modules/activity/components/MeetingForm.vue';
    import {readableHoursFromDates} from '@/modules/legacy/libs/planning/planning';
    import {MqlOperation} from '@meekohq/lumos';
    import swal from 'sweetalert2/dist/sweetalert2.js';
    import moment from 'moment';
    import useLegacyRegistration from '@/modules/registration/composables/useLegacyRegistration';
    import {useToggle} from '@vueuse/core';
    import __ from '@/modules/app/utils/i18n-facade';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';

    export enum AvailibilityVariant {
        pending = 'gray',
        accepted = 'success',
        rejected = 'danger'
    }

    export enum StatusIcon {
        scheduled = 'fa-duotone fa-calendar-alt',
        rescheduled = 'fa-duotone fa-calendar-clock',
        canceled = 'fa-regular fa-calendar-xmark'
    }

    export default defineComponent({
        components: {MeetingForm, AbstractItem},
        props: {
            meeting: {
                type: Object as PropType<MeetingModel>,
                required: true,
            },
            registration: {
                type: Object,
                required: true,
            },
        },
        setup(props, {emit}) {
            const [isEditing, setIsEditing] = useToggle(!props.meeting.exists);
            const reporter = computed(() => props.meeting.reporter().value());
            const {can} = useAbility();
            const {formatDate} = useMoment();
            const saving = ref(false);

            const duration = computed(() => {
                return readableHoursFromDates(
                    moment(props.meeting.attributes.datetime_period?.started_at),
                    moment(props.meeting.attributes.datetime_period?.ended_at),
                );
            });

            const {attach} = useAttachMeeting(
                toRef(props, 'meeting'),
                props.registration.id.toString(),
                'registration/registrations',
            );

            const meetingHasExtraInfos = computed(() => {
                return props.meeting.attributes.description || !!props.meeting.attributes.internal_note;
            });

            const getAvailabilityVariant = function(participant) {
                return AvailibilityVariant[participant.attributes.availability];
            };

            const getMeetingStateIcon = function(meeting) {
                return StatusIcon[meeting.attributes.state];
            };

            const {meetingParticipantsCustomSelect} = useMeetingParticipantsCustomSelect(props.registration);
            const {getOrganizationIds} = useLegacyRegistration(props.registration);

            const allowedOrganizationIds = getOrganizationIds();

            const editedMeeting = ref();

            const save = async function(meeting: MeetingModel) {
                saving.value = true;
                await meeting.participants().fresh();

                const isNew = !props.meeting.exists;
                props.meeting.pullFromCache();
                meeting.copyTo(props.meeting);
                await attach();

                const result = await notifyModal();

                if (result.value) {
                    await new MqlOperation('activity/meeting/notify', {
                        meeting_id: props.meeting.getKey(),
                        event_type: isNew ? 'created' : 'updated',
                    }).run();
                }
                setIsEditing(false);
                saving.value = false;
            };

            const cancel = function() {
                editedMeeting.value.rollback();
                setIsEditing(false);
                if (!props.meeting.exists) {
                    emit('deleted', props.meeting);
                }
            };

            const toggleState = async function() {
                swal({
                    title: __('activity:change_meeting_state'),
                    text: __('activity:participants_will_be_notified_warning'),
                    type: 'warning',
                    confirmButtonClass: 'btn btn-primary mr-2',
                    confirmButtonText: __('common:actions.confirm'),
                    cancelButtonText: __('common:actions.cancel'),
                }).then(async result => {
                    if (result.value) {
                        if (props.meeting.attributes.state === MeetingStateValue.canceled) {
                            props.meeting.attributes.state = MeetingStateValue.scheduled;
                        } else {
                            props.meeting.attributes.state = MeetingStateValue.canceled;
                        }

                        try {
                            await props.meeting.save({params: {notify: true}});
                            useNotification().success(__('activity:meeting_updated_successfully'));
                        } catch (e) {
                            props.meeting.rollback();
                        }
                    }
                });
            };

            async function notifyModal() {
                return swal({
                    title: __('activity:notification_one'),
                    text: __('activity:do_you_want_to_notify_participants_ask'),
                    confirmButtonClass: 'btn btn-primary mr-2',
                    type: 'info',
                    confirmButtonText: __('common:actions.confirm'),
                    cancelButtonText: __('common:actions.cancel'),
                });
            }

            async function remove() {
                swal({
                    title: __('activity:delete_meeting'),
                    text: __('activity:participants_will_be_notified_warning'),
                    confirmButtonText: __('common:actions.remove'),
                    cancelButtonText: __('common:actions.cancel'),
                }).then(async result => {
                    if (result.value) {
                        const notifyModalResult = await notifyModal();

                        if (props.meeting.exists) {
                            const rescheduledMeeting = props.meeting.rescheduledMeeting().value();

                            const params = {notify: false};

                            if (notifyModalResult.value) {
                                params.notify = true;
                            }

                            await props.meeting.delete({params});

                            if (rescheduledMeeting) {
                                rescheduledMeeting.attributes.state = MeetingStateValue.scheduled;
                                await rescheduledMeeting.save();
                            }
                        }

                        emit('deleted', props.meeting);
                    }
                });
            }

            watch(isEditing, value => {
                if (value) {
                    editedMeeting.value = props.meeting.clone();
                }
            }, {immediate: true});

            const rescheduleMeeting = function() {
                emit('reschedule', props.meeting);
            };

            return {
                saving,
                allowedOrganizationIds,
                MeetingStateValue,
                formatDate,
                isEditing,
                setIsEditing,
                editedMeeting,
                remove,
                getAvailabilityVariant,
                getMeetingStateIcon,
                can,
                save,
                cancel,
                toggleState,
                rescheduleMeeting,
                meetingHasExtraInfos,
                duration,
                reporter,
                meetingParticipantsCustomSelect,
            };
        },
    });
</script>
