import type {Ref} from 'vue';
import {computed} from 'vue';
import __ from '@/modules/app/utils/i18n-facade';

export default function(familyMemberGender: Ref<string>) {
    const relationshipOptions = computed(() => {
        if (familyMemberGender.value === 'female') {
            return [
                {text: __('common:relationship.parent_female'), value: 'mother'},
                {text: __('common:relationship.sibling_female'), value: 'sister'},
                {text: __('common:relationship.pibling_female'), value: 'aunt'},
                {text: __('common:relationship.grandparent'), value: 'grandparent'},
                {text: __('common:relationship.nanny'), value: 'nanny'},
                {text: __('common:relationship.parent_in_law_female'), value: 'stepmother'},
                {text: __('common:relationship.cousin_female'), value: 'cousin'},
                {text: __('common:relationship.legal_custodian_female'), value: 'legal_custodian_female'},
                {text: __('common:other_one'), value: 'other'},
            ];
        } else {
            return [
                {text: __('common:relationship.parent_male'), value: 'father'},
                {text: __('common:relationship.sibling_male'), value: 'brother'},
                {text: __('common:relationship.pibling_male'), value: 'uncle'},
                {text: __('common:relationship.grandparent'), value: 'grandparent'},
                {text: __('common:relationship.nanny'), value: 'nanny'},
                {text: __('common:relationship.parent_in_law_male'), value: 'stepfather'},
                {text: __('common:relationship.cousin_male'), value: 'cousin'},
                {text: __('common:relationship.legal_custodian_male'), value: 'legal_custodian_male'},
                {text: __('common:other_one'), value: 'other'},
            ];
        }
    });

    return {relationshipOptions};
}
