<template>
    <CPopover
        v-if="generatedLines.length > 0 || manualLines.length > 0"
        class="InvoicesListItemDetailsPopover"
        hoverable
        placement="bottom"
    >
        <MHoverable class="InvoicesListItemDetailsPopover__container">
            <div class="InvoicesListItemDetailsPopover__amount-container">
                <CPopover
                    v-if="rateWarning(nursery, invoice) && invoice.type === 'presence' || hoursWarning(nursery, invoice) && invoice.type === 'presence'"
                    hoverable
                >
                    <FontAwesomeIcon
                        class="tw-text-orange-500"
                        icon="fad fa-exclamation-triangle"
                    />
                    <template #content>
                        <template v-if="rateWarning(nursery, invoice) && invoice.type === 'presence'">
                            {{ __('billing_invoice:warnings.rate_warning') }}
                        </template>
                        <template v-else-if="hoursWarning(nursery, invoice) && invoice.type === 'presence'">
                            {{ __('billing_invoice:warnings.hours_warning') }}
                        </template>
                    </template>
                </CPopover>
                <div class="InvoicesListItemDetailsPopover__amount">
                    {{ format(invoice.grand_total) }}
                </div>
            </div>
            <div class="InvoicesListItemDetailsPopover__icons-container">
                <template v-if="generatedLines.length">
                    <InvoicesListItemDetailsLineIcon
                        v-for="line in generatedLines"
                        :key="'generatedLine-' + line.id"
                        :line-type="line.type"
                    />
                </template>
                <div
                    v-if="generatedLines.length && manualLines.length"
                    class="InvoicesListItemDetailsPopover__separator"
                />
                <template v-if="manualLines.length">
                    <InvoicesListItemDetailsLineIcon
                        v-for="line in manualLines"
                        :key="'manualLines-' + line.id"
                        :line-type="line.type"
                    />
                </template>
            </div>
        </MHoverable>
        <template #content>
            <div class="InvoicesListItemDetailsPopover__popper-container">
                <!-- LINES CREATED BY CONTRACT -->
                <InvoicesListItemDetailsLine
                    v-for="line in generatedLines"
                    :key="'invoiceLineGenerated-' + line.id"
                    :currency="invoice.currency_iso_code"
                    :line="line"
                />
                <!-- LINES NOT CREATED BY CONTRACT -->
                <InvoicesListItemDetailsLine
                    v-for="line in manualLines"
                    :key="'invoiceLineManual-' + line.id"
                    :currency="invoice.currency_iso_code"
                    :line="line"
                />
            </div>
        </template>
    </CPopover>
    <div
        v-else
        class="InvoicesListItemDetailsPopover__amount-container--without-popover"
    >
        <div class="InvoicesListItemDetailsPopover__amount">
            {{ format(invoice.grand_total) }}
        </div>
    </div>
</template>

<script lang="ts">
    import {invoice} from '@/modules/legacy/mixins/invoice';
    import type {PropType} from 'vue';
    import {computed, defineComponent} from 'vue';
    import useFormatNumber from '@/modules/cashier/composables/useFormatNumber';
    import OrganizationModel from '@/modules/organization/models/OrganizationModel';
    import InvoicesListItemDetailsLine from './InvoicesListItemDetailsLine.vue';
    import InvoicesListItemDetailsLineIcon
        from '@/modules/cashier/components/billing/invoice/atoms/InvoicesListItemDetailsLineIcon.vue';

    export default defineComponent({
        components: {
            InvoicesListItemDetailsLine,
            InvoicesListItemDetailsLineIcon,
        },
        mixins: [invoice],
        props: {
            invoice: {
                type: Object as PropType<{
                    type: string,
                    grand_total: number,
                    lines: Array<{ billable_type: string }>
                    currency_iso_code: string,
                }>,
                required: true,
            },
            nursery: {
                type: OrganizationModel,
                required: true,
            },
        },
        setup(props) {
            const generatedLines = computed(() => {
                return props.invoice.lines.filter(
                    item => item.billable_type === 'kid_contract',
                );
            });
            const manualLines = computed(() => {
                return props.invoice.lines.filter(
                    item => item.billable_type !== 'kid_contract',
                );
            });

            const {format} = useFormatNumber(props.invoice.currency_iso_code);

            return {
                format,
                generatedLines,
                manualLines,
            };
        },
    });
</script>

<style lang="scss" scoped>
    .InvoicesListItemDetailsPopover__container {
        @apply tw-flex tw-flex-col lg:tw-items-end;
        @apply tw-transition-all tw-rounded;
        @apply lg:tw-flex-col;
    }

    .InvoicesListItemDetailsPopover__amount-container {
        @apply tw-flex tw-flex-row lg:tw-items-center tw-gap-1;
    }

    .InvoicesListItemDetailsPopover__amount-container--without-popover {
        @apply tw-text-end tw-font-semibold tw-pr-1;
    }

    .InvoicesListItemDetailsPopover__amount {
        @apply tw-text-gray-800 tw-font-semibold tw-whitespace-nowrap;
    }

    .InvoicesListItemDetailsPopover__icons-container {
        @apply tw-flex tw-flex-wrap;
    }

    .InvoicesListItemDetailsPopover__popper-container {
        @apply tw-text-sm tw-font-semibold tw-flex tw-flex-col tw-gap-1;
    }

    .InvoicesListItemDetailsPopover__separator {
        @apply tw-shrink-0 tw-mx-1 tw-w-0.5 tw-rounded-full tw-h-4 tw-bg-gray-300;
    }
</style>
