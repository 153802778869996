<template>
    <component
        :is="level"
        class="CHeading"
        :class="baseClass"
    >
        <slot/>
    </component>
</template>

<script lang="ts">
    import {computed, defineComponent} from 'vue';
    import {get} from 'lodash-es';

    export default defineComponent({
        components: {},
        props: {
            level: {type: String, default: 'h1'},
            margin: {type: Boolean, default: true},
        },
        setup(props) {
            const baseClass = computed(() => {
                const output = [] as any[];

                output.push(get(levelValues, props.level));

                if (props.margin) {
                    output.push('CHeading--margin');
                }

                return output;
            });

            return {
                baseClass,
            };
        },
    });

    export const levelValues = {
        h1: 'CHeading--h1',
        h2: 'CHeading--h2',
        h3: 'CHeading--h3',
        h4: 'CHeading--h4',
        h5: 'CHeading--h5',
        h6: 'CHeading--h6',
    };
</script>

<style lang="scss" scoped>
    .CHeading {
        @apply tw-font-semibold last:tw-mb-0 tw-leading-normal tw-tracking-tight tw-text-inherit;

        &--h1 {
            @apply tw-text-3xl;

            &.CHeading--margin {
                @apply tw-mb-6;
            }
        }

        &--h2 {
            @apply tw-text-2xl;

            &.CHeading--margin {
                @apply tw-mb-5;
            }
        }

        &--h3 {
            @apply tw-text-xl;

            &.CHeading--margin {
                @apply tw-mb-4;
            }
        }

        &--h4 {
            @apply tw-text-lg;

            &.CHeading--margin {
                @apply tw-mb-3;
            }
        }

        &--h5 {
            @apply tw-text-base;

            &.CHeading--margin {
                @apply tw-mb-2;
            }
        }

        &--h6 {
            @apply tw-text-sm;

            &.CHeading--margin {
                @apply tw-mb-1;
            }
        }
    }
</style>
