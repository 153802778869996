import {Application, Container, type Contracts, Kernel} from '@meekohq/lumos';
import * as config from '@/config';

/*
|--------------------------------------------------------------------------
| Create The Application
|--------------------------------------------------------------------------
|
| The first thing we will do is create a new Lumos application instance
| which serves as the "glue" for all the components of Lumos, and is
| the IoC container for the system binding all of the various parts.
|
*/

const app = new Application(config);

/*
|--------------------------------------------------------------------------
| Bind Important Interfaces
|--------------------------------------------------------------------------
|
| Next, we need to bind some important interfaces into the container so
| we will be able to resolve them when needed.
|
*/

app.singleton<Contracts.Foundation.KernelContract>(Container.contracts.kernel)
    .to(new Kernel(app));

/*
|--------------------------------------------------------------------------
| Return The Application
|--------------------------------------------------------------------------
|
| This script returns the application instance. The instance is given to
| the calling script so we can separate the building of the instances
| from the actual running of the application and sending responses.
|
*/

export default app;
