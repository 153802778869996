<template>
    <MModal
        :modal="modal"
        size="5xl"
    >
        <template #header-text>
            {{ __('billing_invoice:download_sepa') }}
            -
            <strong class="tw-capitalize">{{ date.clone().format('MMMM Y') }}</strong>
        </template>

        <select-invoices
            action="sepa"
            :date="date"
            :nursery="nursery"
            :selected-invoices.sync="selectedInvoices"
        />

        <hr class="hr">

        <div
            v-if="nurserySepas.length > 1"
            class="mt-3"
        >
            <h6 class="h6">
                {{ __('billing_invoice:wich_mandate') }}
            </h6>
            <div class="row">
                <div
                    v-for="(sepa, i) in nurserySepas"
                    :key="'nurserySepa' + i"
                    class="col"
                >
                    <div
                        class="border rounded pointer p-2"
                        :class="{'selected tw-text-white tw-bg-gray-600': selectedSepa.id === sepa.id}"
                        @click="selectedSepa = sepa"
                    >
                        <strong> {{ sepa.name }} </strong>
                        <div class="small">
                            {{ sepa.iban }}
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row mt-2">
            <div class="col-12 col-sm-6">
                <label class="label">{{ __('billing_invoice:payment_term_colon') }}</label>
                <CInputGroup>
                    <CInput
                        v-model.number="payment_delay"
                        class="w-50"
                        type="number"
                    />
                    <CInputAddon class="tw-lowercase">
                        <template v-if="payment_delay && payment_delay > 1">
                            {{ __('common:day_other') }}
                        </template>
                        <template v-else>
                            {{ __('common:day_one') }}
                        </template>
                    </CInputAddon>
                </CInputGroup>
            </div>
        </div>

        <div class="mt-3">
            <CCheckbox
                v-model="payments"
                :unchecked-value="false"
            >
                {{ __('billing_invoice:add_automatically_payments') }}
            </CCheckbox>
            <CFormErrorMessage v-if="isErrorSepaAmount">
                {{ __('billing_invoice:warning.automatic_add_explanation') }}
            </CFormErrorMessage>
        </div>
        <div
            v-if="!nurserySepas.length"
            class="tw-mt-4 sm:tw-text-right tw-text-gray-700"
        >
            <FontAwesomeIcon
                class="tw-mr-0.5"
                icon="fad fa-exclamation-triangle"
            />
            {{ __('billing_invoice:error.no_sepa_in_organizations') }}
        </div>
        <template #footer-end="{hide}">
            <MButton
                :label="__('common:actions.cancel')"
                variant="light"
                @click="hide"
            />
            <MTooltip
                :label="__('billing_invoice:get_csv_summary')"
                placement="top"
            >
                <MButton
                    :disabled="!nurserySepas.length"
                    :loading="csvLoading"
                    variant="light"
                    @click="downloadCSV()"
                >
                    <FontAwesomeIcon icon="fa fa-file-csv"/>
                </MButton>
            </MTooltip>
            <MButton
                :disabled="!nurserySepas.length"
                :loading="loading"
                variant="primary"
                @click="sepa"
            >
                {{ __('common:actions.download') }}
                <template v-if="payments">
                    {{ __('billing_invoice:and_add_payments') }}
                </template>
            </MButton>
        </template>
    </MModal>
</template>

<script>
    import route from '@/modules/legacy/libs/ziggy';
    import useApi from '@/modules/app/composables/useApi';
    import _pick from 'lodash-es/pick';
    import _map from 'lodash-es/map';
    import _head from 'lodash-es/head';
    import _forEach from 'lodash-es/forEach';
    import __ from '@/modules/app/utils/i18n-facade';
    import SelectInvoices from './SelectInvoices.vue';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';

    export default {
        components: {
            'select-invoices': SelectInvoices,
        },
        props: {
            nursery: {},
            sepas: {},
            date: {},
            modal: {
                type: Object,
                required: true,
            },
        },
        data() {
            return {
                selectedInvoices: [],
                loading: false,
                csvLoading: false,
                payments: true,
                nurserySepas: [],
                selectedSepa: null,
                payment_delay: 1,
                isErrorSepaAmount: false,
            };
        },
        watch: {
            selectedSepa(val) {
                this.payment_delay = val.payment_delay;
            },
            selectedInvoices(invoices) {
                this.isErrorSepaAmount = false;
                invoices.forEach(invoice => {
                    if (invoice.sepa_amount > invoice.computed.remaining_amount) {
                        this.isErrorSepaAmount = true;
                        this.payments = false;
                    }
                });
            },
        },
        mounted() {
            this.nurserySepas = [];
            if (this.sepas) {
                this.sepas.forEach(sepa => {
                    this.nursery.sepaMandates.find(item => item.id === sepa.id) ? this.nurserySepas.push(sepa) : null;
                });
            }
            this.selectedSepa = this.nurserySepas.length ? this.nurserySepas[0] : null;
        },
        methods: {
            sepa() {
                if (this.loading) {
                    return;
                }

                this.loading = true;

                useApi().legacy.post(route('invoices.sepa'), {
                    invoices: _map(this.selectedInvoices, i => _pick(i, 'id', 'sepa_amount')),
                    add_payments: this.payments,
                    payment_delay: this.payment_delay,
                    sepa_mandate_id: this.selectedSepa.id,
                }).then(response => {
                    const blob = new Blob([response.data.xml], {type: 'application/xml'});
                    const link = document.createElement('a');
                    link.download = this.date.clone().format('MMMM Y') ? 'Sepa - ' + this.date.clone().format('MMMM Y') + '.xml' : 'Sepa.xml';
                    link.target = '_blank';
                    link.href = window.URL.createObjectURL(blob);
                    link.dispatchEvent(new MouseEvent('click', {bubbles: true, cancelable: true, view: window}));

                    this.$emit('updated', response.data.invoices);
                    this.loading = false;
                    useNotification().info(__('billing_invoice:downloading_summary_dots'));

                    setTimeout(() => {
                        useNotification().success(__('billing_invoice:file_donwloaded_with_link', {link: link.download}));
                        this.downloadCSVandCloseModal();
                    }, 1000);
                }).catch(error => {
                    this.loading = false;
                    if (error && error.response && error.response.status === 422) {
                        _forEach(error.response.data.errors, value => {
                            useNotification().error(_head(value));
                        });
                    } else {
                        useNotification().error(error);
                    }
                });
            },
            downloadCSVandCloseModal() {
                this.downloadCSV();
            },
            downloadCSV() {
                if (this.csvLoading) {
                    return;
                }

                this.csvLoading = true;

                useApi().legacy.post(route('invoices.sepa'), {
                    invoices: _map(this.selectedInvoices, i => _pick(i, 'id', 'sepa_amount')),
                    add_payments: false,
                    payment_delay: this.payment_delay,
                    sepa_mandate_id: this.selectedSepa.id,
                }).then(response => {
                    const link = document.createElement('a');
                    link.target = '_blank';
                    link.href = response.data.xls;
                    link.dispatchEvent(new MouseEvent('click', {bubbles: true, cancelable: true, view: window}));
                }).catch(error => {
                    if (error && error.response && error.response.status === 422) {
                        _forEach(error.response.data.errors, value => {
                            useNotification().error(_head(value));
                        });
                    } else {
                        useNotification().error(error);
                    }
                }).finally(() => {
                    this.csvLoading = false;
                });
            },
        },
    };
</script>
