import {useWindowSize} from '@vueuse/core';

export const {width: windowSize} = useWindowSize();
// export const windowSize = ref();
//
// const screens = {
//     'sm': 640,
//     'md': 768,
//     'lg': 1024,
//     'xl': 1280,
//     '2xl': 1536,
// };
//
// watch(windowSize, () => {
//     windowSize.value = windowSize.value;
//
//     // if (windowSize.value >= screens['2xl']) {
//     //     windowScreenSize.value = screens['2xl'];
//     // } else if (windowSize.value >= screens.xl) {
//     //     windowScreenSize.value = screens.xl;
//     // } else if (windowSize.value >= screens.lg) {
//     //     windowScreenSize.value = screens.lg;
//     // } else if (windowSize.value >= screens.md) {
//     //     windowScreenSize.value = screens.md;
//     // } else if (windowSize.value >= screens.sm) {
//     //     windowScreenSize.value = screens.sm;
//     // } else {
//     //     windowScreenSize.value = 0;
//     // }
// }, {immediate: true});
