import {app} from '@meekohq/lumos';
import {Types} from '@/types';
import useNotification from '@/modules/meeko-ui/composables/useNotification';
import __ from '@/modules/app/utils/i18n-facade';
import useMagicModal from '@/modules/app/composables/useMagicModal';
import type {DetachCreditNoteToInvoiceUseCase} from '@/modules/cashier/payment/application/DetachCreditNoteToInvoiceUseCase';
import {DetachCreditNoteToInvoiceRequest} from '@/modules/cashier/payment/application/DetachCreditNoteToInvoiceRequest';
import type InvoiceTypeValue from '@/modules/cashier/utils/billing/invoice/InvoiceTypeValue';

export function useDetachCreditNote(invoiceType: InvoiceTypeValue) {
    async function detachCreditNote(allocationId: string, creditNoteId: string, paymentId: string)  {
        if (!await openDetachModal()) {
            return false;
        }

        try {
            await app().make<DetachCreditNoteToInvoiceUseCase>(Types.DetachCreditNoteUseCase).detach(
                DetachCreditNoteToInvoiceRequest.make(
                    {
                        allocationId,
                        creditNoteId,
                        paymentId,
                    },
                ),
            );

            return true;
        } catch (err) {
            useNotification().error(__('common:errors.generic'));
            throw err;
        }
    }

    async function openDetachModal() {
        return new Promise<boolean>(resolve => {
            useMagicModal().confirmationModal({
                text: __('billing_transaction:ask_detach', {context: invoiceType}),
                onConfirm: async () => {
                    resolve(true);
                },
                onDismiss: async () => {
                    resolve(false);
                },
            });
        });
    }

    return {
        detachCreditNote,
    };
}
