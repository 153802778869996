import {ServiceProvider} from '@meekohq/lumos';
import FamilyModel from '@/modules/family/models/FamilyModel';
import FamilyMemberPivot from '@/modules/family/models/FamilyMemberPivot';
import FamilyCustomerPivot from '@/modules/family/models/FamilyCustomerPivot';
import FamilyOrganizationPivot from '@/modules/family/models/FamilyOrganizationPivot';
import MemberModel from '@/modules/family/models/MemberModel';
import MemberOrganizationPivot from '@/modules/family/models/MemberOrganizationPivot';
import KidModel from '@/modules/family/models/KidModel';
import KidMemberPivot from '@/modules/family/models/KidMemberPivot';
import MigrateKidIndexFiltersStorage from '@/modules/family/utils/kid/MigrateKidIndexFiltersStorage';
import type {NotifiableError} from '@bugsnag/js';
import Bugsnag from '@bugsnag/js';

export default class FamilyServiceProvider extends ServiceProvider {
    public boot() {
        this.loadModelsFrom([
            KidModel,
            KidMemberPivot,

            FamilyModel,
            FamilyMemberPivot,
            FamilyCustomerPivot,
            FamilyOrganizationPivot,

            MemberModel,
            MemberOrganizationPivot,
        ]);

        try {
            const migrateKidIndexFiltersStorage = new MigrateKidIndexFiltersStorage();
            migrateKidIndexFiltersStorage.migrateContracts();
            migrateKidIndexFiltersStorage.migrateGroups();
            migrateKidIndexFiltersStorage.migrateTags();
        } catch (e) {
            Bugsnag.notify(e as NotifiableError);
        }
    }
}
