import {Model} from '@meekohq/lumos';
import {isEmpty} from 'lodash-es';

export default class FamilyCustomerPivot extends Model {
    public type = 'family/families_customers_pivot';

    public attributes: {
        id: string;
        account_id: string | undefined;
        family_id: string | undefined;
        customer_id: string | undefined;
    } = {
            id: this.uuid(),
            account_id: undefined,
            family_id: undefined,
            customer_id: undefined,
        };

    static async search(familyId: string, customerId: string): Promise<FamilyCustomerPivot | undefined> {
        const result = await FamilyCustomerPivot.query()
            .where('family_id', familyId)
            .where('customer_id', customerId)
            .all();

        if (isEmpty(result.all())) {
            return undefined;
        }

        return result.first();
    }
}
