import {ref} from 'vue';
import type {HasMany, QueryBuilder} from '@meekohq/lumos';
import {collect} from '@meekohq/lumos';
import MeetingModel from '@/modules/activity/models/MeetingModel';
import _cloneDeep from 'lodash-es/cloneDeep';
import moment from 'moment';

export default function(pivotConstraint: (query: QueryBuilder<any>, relation: HasMany<any, any>) => void) {
    const meetings = ref(collect<MeetingModel>());


    const getMeetings = async function() {
        meetings.value = await MeetingModel.query(query => pivotConstraint(query, new MeetingModel().meetingPivots()))
            .with(new MeetingModel().meetingPivots())
            .with(new MeetingModel().reporter())
            .with(new MeetingModel().participants())
            .with(new MeetingModel().rescheduledMeeting())
            .get();
    };

    const newMeeting = function() {
        const meeting = new MeetingModel();
        meeting.extra.draftDate = moment();

        meetings.value.push(meeting);
    };

    const newMeetingFromRescheduled = function(rescheduledMeeting: MeetingModel) {
        const meeting = new MeetingModel();

        meeting.attributes = _cloneDeep(rescheduledMeeting.attributes);
        meeting.attributes.id = meeting.uuid();

        if (rescheduledMeeting.reporter().value()) {
            meeting.reporter().associate(rescheduledMeeting.reporter().value());
        }

        meeting.rescheduledMeeting().associate(rescheduledMeeting);

        meetings.value.push(meeting);
    };

    const removeMeeting = function(deletedMeeting: MeetingModel) {
        meetings.value = meetings.value.reject(meeting => meeting.id === deletedMeeting.id);
    };

    const scrollToMeeting = function(meetingId) {
        document.getElementById(meetingId)?.scrollIntoView({behavior: 'smooth'});
    };

    return {
        getMeetings,
        meetings,
        newMeeting,
        newMeetingFromRescheduled,
        removeMeeting,
        scrollToMeeting,
    };
}
