<template>
    <div
        ref="transactionListItemEl"
        class="TransactionListItem"
    >
        <MagicIndexItemColumn
            :class="showCustomerInformations? 'TransactionListItem__column-amount' : 'TransactionListItem__column-amount--expanded'"
            :grid-enabled="false"
        >
            <TransactionListItemAmount :transaction="transaction"/>
        </MagicIndexItemColumn>
        <MagicIndexItemColumn
            class="TransactionListItem__column-actions"
            :grid-enabled="false"
        >
            <CDropdownMenu ref="actionsDropdown">
                <template #button>
                    <CButton
                        icon-ellipsis
                        variant="ghost"
                    />
                </template>
                <DownloadTemplate
                    as="CDropdownMenuItem"
                    custom-class="tw-w-full"
                    doc-type="transaction"
                    :item="transaction"
                    :nursery="legacyNursery"
                    :user="user"
                    @downloaded="actionsDropdown.hide()"
                />
                <CDropdownMenuItem
                    v-if="can('update', 'transactions')"
                    ref="editButtonRef"
                    icon="fa fa-pen"
                    @click="onEditButtonClicked"
                >
                    {{ __('common:actions.update') }}
                </CDropdownMenuItem>
                <CDropdownMenuItem
                    v-if="can('update', 'transactions') && !transaction.isFailed"
                    ref="rejectButtonRef"
                    icon="fa fa-reply"
                    variant="danger"
                    @click="onRejectButtonClicked"
                >
                    {{ __('common:actions.reject') }}
                </CDropdownMenuItem>
                <CDropdownMenuItem
                    v-if="can('update', 'transactions') && transaction.isFailed"
                    ref="restoreButtonRef"
                    icon="fa fa-check"
                    @click="onRestoreButtonClicked"
                >
                    {{ __('billing_transaction:actions.dont_reject') }}
                </CDropdownMenuItem>
                <CDropdownMenuItem
                    v-if="can('delete', 'transactions')"
                    ref="deleteButtonRef"
                    icon="fa fa-trash"
                    variant="danger"
                    @click="onDeleteButtonClicked"
                >
                    {{ __('common:actions.delete_dots') }}
                </CDropdownMenuItem>
            </CDropdownMenu>
            <AlertModal
                v-if="isRejectAlertModalVisible"
                :confirm-button-disabled="transactionError.has('failureDate')"
                :confirm-text="__('common:actions.reject')"
                :loading="loading"
                :title="__('billing_transaction:reject_transaction', {
                    context: transaction.isCredit ? 'credit' : 'debit',
                })"
                @canceled="cancelRejection"
                @confirmed="handleReject"
                @hidden="isRejectAlertModalVisible = false"
            >
                {{
                    __('billing_transaction:ask_reject_transaction', {
                        context: transaction.isCredit ? 'credit' : 'debit',
                    })
                }}

                <CFormGroup class="tw-mt-4">
                    <CLabel>
                        {{ __('billing_transaction:failed_at') }}
                    </CLabel>
                    <CFormDatepicker
                        v-model="failureDate"
                        button-class="tw-w-full"
                        :has-error="transactionError.has('failureDate')"
                    />
                    <CFormErrorMessageList :errors="transactionError.get('failureDate')"/>
                </CFormGroup>
            </AlertModal>
        </MagicIndexItemColumn>
        <MagicIndexItemColumn
            v-if="alwaysShowAllocations || allocations.length > 1"
            :class="showCustomerInformations ? 'TransactionListItem__column-allocations' : 'TransactionListItem__column-allocations--expanded'"
            :container-width="width"
            :grid-title="__('billing_transaction:allocation')"
        >
            <div class="TransactionListItem__allocations">
                <TransactionListItemAllocationsProgressBar
                    v-if="transaction.computed.remaining_to_distribute_amount > 0"
                    :transaction="transaction"
                />
                <div class="tw-flex tw-gap-2 tw-flex-wrap tw-items-baseline">
                    <TransactionListItemInvoiceDropdown
                        v-for="allocation in allocations"
                        :key="allocation.attributes.id"
                        :allocation="allocation"
                        :customer="transaction.customer().value()"
                    />
                </div>
            </div>
        </MagicIndexItemColumn>
        <MagicIndexItemColumn
            class="TransactionListItem__column-date"
            :container-width="width"
            :grid-title="__('common:date')"
        >
            <div class="tw-font-medium">
                {{ Epoch.fromISOString(transaction.attributes.date).toLocaleString(Epoch.presets.DATE_SHORT) }}
            </div>
        </MagicIndexItemColumn>
        <div class="TransactionListItem__column-details">
            <MagicIndexItemColumn
                v-if="paymentMethodName"
                class="TransactionListItem__column-payment-method"
                :container-width="width"
                :grid-title="__('billing_payment:payment_method')"
            >
                <div class="TransactionListItem__row">
                    <FontAwesomeIcon
                        class="TransactionListItem__icon"
                        fixed-width
                        icon="fad fa-money-check-dollar"
                    />
                    {{ paymentMethodName }}
                </div>
            </MagicIndexItemColumn>
            <MagicIndexItemColumn
                v-if="transaction.attributes.reference"
                class="TransactionListItem__column-reference"
                :container-width="width"
                :grid-title="__('billing_transaction:reference')"
            >
                <div class="TransactionListItem__row">
                    <FontAwesomeIcon
                        class="TransactionListItem__icon"
                        fixed-width
                        icon="fad fa-receipt"
                    />
                    <MClamp>
                        {{ transaction.attributes.reference }}
                    </MClamp>
                </div>
            </MagicIndexItemColumn>
            <MagicIndexItemColumn
                v-if="transaction.attributes.description"
                class="TransactionListItem__column-description"
                :container-width="width"
                :grid-title="__('common:description')"
            >
                <div class="TransactionListItem__row">
                    <FontAwesomeIcon
                        class="TransactionListItem__icon"
                        fixed-width
                        icon="fad fa-pencil"
                    />
                    {{ transaction.attributes.description }}
                </div>
            </MagicIndexItemColumn>
        </div>
        <div
            v-if="showCustomerInformations"
            class="TransactionListItem__column-informations"
        >
            <MagicIndexItemColumn
                class="TransactionListItem__column-customer"
                :container-width="width"
                :grid-title="__('common:customer_one')"
            >
                <div class="TransactionListItem__row">
                    <FontAwesomeIcon
                        class="TransactionListItem__icon"
                        fixed-width
                        icon="fad fa-file-user"
                    />
                    <MButton
                        v-if="transaction.customer().value()"
                        class="tw-p-0 tw-font-normal"
                        :label="transaction.customer().value().attributes.name"
                        :to="{ name: 'cashier/customers.show.payments', params: { customer: transaction.customer().value().getKey() }}"
                        variant="link"
                    />
                </div>
            </MagicIndexItemColumn>
            <MagicIndexItemColumn
                v-if="transaction.tenant().value()"
                class="TransactionListItem__column-tenant"
                :container-width="width"
                :grid-title="__('common:organization_one')"
            >
                <div class="TransactionListItem__row">
                    <FontAwesomeIcon
                        class="TransactionListItem__icon"
                        fixed-width
                        icon="fad fa-house-blank"
                    />
                    {{ user.organizations().value().count() > 1 ? transaction.tenant().value().attributes.name : undefined }}
                </div>
            </MagicIndexItemColumn>
        </div>
    </div>
</template>

<script lang="ts">
    import type {ComponentPublicInstance, PropType} from 'vue';
    import {computed, defineComponent, ref, watch} from 'vue';
    import {collect, Epoch, MqlUnprocessableEntityError} from '@meekohq/lumos';
    import __ from '@/modules/app/utils/i18n-facade';
    import useAuth from '@/modules/app/composables/useAuth';
    import useManager from '@/modules/app/composables/useManager';
    import useAbility from '@/modules/app/composables/useAbility';
    import {mapPaymentToAllocation} from '@/modules/cashier/composables/issuing/transaction/useTransactionState';
    import useRejectRestoreTransaction
        from '@/modules/cashier/transaction/infrastructure/components/useRejectRestoreTransaction';
    import type {MagicMassSelectType} from '@/modules/app/composables/useMagicMassSelect';
    import type TransactionModel from '@/modules/cashier/transaction/domain/TransactionModel';
    import type OrganizationModel from '@/modules/organization/models/OrganizationModel';
    import DownloadTemplate from '@/modules/legacy/components/Modules/DownloadTemplate.vue';
    import AlertModal from '@/modules/app/components/organisms/AlertModal.vue';
    import TransactionModalEdit from '@/modules/cashier/transaction/infrastructure/components/TransactionModalEdit.vue';
    import TransactionListItemAmount
        from '@/modules/cashier/transaction/infrastructure/components/TransactionListItemAmount.vue';
    import TransactionListItemInvoiceDropdown
        from '@/modules/cashier/transaction/infrastructure/components/TransactionListItemInvoiceDropdown.vue';
    import type PaymentModel from '@/modules/cashier/models/PaymentModel';
    import useMagicModal from '@/modules/app/composables/useMagicModal';
    import useModal from '@/modules/app/composables/useModal';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';
    import useError from '@/modules/app/composables/useError';
    import useEpoch from '@/modules/app/composables/useEpoch';
    import {useElementSize} from '@vueuse/core';
    import MagicIndexItemColumn from '@/modules/magic-index/components/atoms/MagicIndexItemColumn.vue';
    import TransactionListItemAllocationsProgressBar
        from '@/modules/cashier/transaction/infrastructure/components/TransactionListItemAllocationsProgressBar.vue';

    export default defineComponent({
        components: {
            TransactionListItemAllocationsProgressBar,
            MagicIndexItemColumn,
            TransactionListItemInvoiceDropdown,
            TransactionListItemAmount,
            AlertModal,
            DownloadTemplate,
        },
        props: {
            transaction: {
                type: Object as PropType<TransactionModel>,
                required: true,
            },
            organization: {
                type: Object as PropType<OrganizationModel>,
                required: true,
            },
            payment: {
                type: Object as PropType<PaymentModel>,
                required: false,
                default: undefined,
            },
            massSelect: {
                type: Object as PropType<MagicMassSelectType>,
                default: undefined,
                required: false,
            },
            showCustomerInformations: {
                type: Boolean,
                default: false,
            },
            alwaysShowAllocations: {
                type: Boolean,
                default: false,
            },
        },
        setup(props, {emit}) {
            const user = useAuth().user;
            const legacyNursery = useManager().legacyNursery;

            const actionsDropdown = ref();
            const statusReference = ref();

            const editButtonRef = ref<ComponentPublicInstance>();
            const deleteButtonRef = ref<ComponentPublicInstance>();
            const rejectButtonRef = ref<ComponentPublicInstance>();
            const restoreButtonRef = ref<ComponentPublicInstance>();

            const {can} = useAbility();

            const allocations = computed(() => {
                const data = collect();

                // Transaction can have multiple allocation on same payment
                props.transaction.allocations.each(allocation => {
                    data.push(mapPaymentToAllocation(allocation));
                });

                return data;
            });

            const canOpenActions = computed(() => {
                return !props.massSelect || !props.massSelect.isEnabled.value;
            });

            const isRejectAlertModalVisible = ref(false);

            const transactions = computed(() => collect(props.transaction));
            const failureDate = ref(new Epoch().toISOString());

            const transactionError = useError();

            const {fromISOString} = useEpoch();

            const {bus, reject, restore, loading} = useRejectRestoreTransaction();

            bus.on('restored', () => emit('restored', props.transaction));
            bus.on('rejected', () => emit('rejected', props.transaction));
            bus.on('error', error => {
                // TODO: Remplacer par une erreur métier et non MQL
                if (error instanceof MqlUnprocessableEntityError && error.operation.result.status === 422) {
                    useNotification().error(__('billing_transaction:errors.restore_transaction', {
                        context: props.transaction.isCredit ? 'credit' : 'debit',
                    }));
                } else {
                    useNotification().error(__('common:errors.generic'));
                }
            });

            watch(failureDate, value => {
                const transactionDate = fromISOString(props.transaction.attributes.date as string);
                const safeFailureDate = value ? fromISOString(value as string) : undefined;

                // We check the failure date set to the end of the day to avoid any timezone issue
                if (!safeFailureDate || transactionDate.greaterThanOrEqualTo(safeFailureDate.endOfDay())) {
                    transactionError.add('failureDate', 'failure-date-must-be-future', __('billing_transaction:errors.failure_date_cannot_be_before_transaction_date'));
                } else {
                    transactionError.remove('failureDate', 'failure-date-must-be-future');
                }
            });

            const editModal = useModal({
                component: TransactionModalEdit,
                props: {
                    payment: props.payment,
                    transactionId: props.transaction.getKey(),
                },
                listeners: modal => ({
                    updated(event) {
                        emit('updated', event);
                        modal.hide();
                    },
                }),
            });

            function onEditButtonClicked() {
                editModal.show();
            }

            async function onDeleteButtonClicked() {
                await useMagicModal().deleteConfirmationModal({
                    text: __('billing_transaction:delete_transaction_ask'),
                    onConfirm: async () => {
                        loading.value = true;
                        await props.transaction.delete();
                        emit('deleted', props.transaction);
                        loading.value = false;
                    },
                });
            }

            async function onRestoreButtonClicked() {
                await useMagicModal().confirmationModal({
                    text: __('billing_transaction:ask_restore_transaction', {
                        context: props.transaction.isCredit ? 'credit' : 'debit',
                    }),
                    onConfirm: () => restore(transactions.value),
                });
            }

            function handleReject() {
                reject(transactions.value, Epoch.fromISOString(failureDate.value));
            }

            function onRejectButtonClicked() {
                openRejectionModal();
            }

            function openRejectionModal() {
                failureDate.value = new Epoch().toISOString();
                isRejectAlertModalVisible.value = true;
            }

            function cancelRejection() {
                failureDate.value = new Epoch().toISOString();
            }

            const paymentMethodName = computed(() => props.transaction.paymentMethod().value()?.attributes.name);

            const transactionListItemEl = ref(null);
            const {width} = useElementSize(transactionListItemEl);

            return {
                width,
                transactionListItemEl,
                paymentMethodName,
                actionsDropdown,
                editButtonRef,
                deleteButtonRef,
                rejectButtonRef,
                restoreButtonRef,
                isRejectAlertModalVisible,
                allocations,
                legacyNursery,
                user,
                loading,
                failureDate,
                can,
                canOpenActions,
                openRejectionModal,
                cancelRejection,
                reject,
                restore,
                Epoch,
                onEditButtonClicked,
                onDeleteButtonClicked,
                onRestoreButtonClicked,
                onRejectButtonClicked,
                statusReference,
                transactionError,
                handleReject,
            };
        },
    });
</script>

<style lang="scss" scoped>
    .TransactionListItem {
        @apply tw-grid tw-grid-cols-24 tw-items-center tw-gap-3;
        container-type: inline-size;
        container-name: TransactionListItem;
    }

    .TransactionListItem__column-amount {
        @apply tw-col-span-20;

        @container TransactionListItem (min-width: 48rem) {
            @apply tw-col-span-7;
        }

        @container TransactionListItem (min-width: 64rem) {
            @apply tw-col-span-3;
        }
    }

    .TransactionListItem__column-amount--expanded {
        @apply tw-col-span-20;

        @container TransactionListItem (min-width: 48rem) {
            @apply tw-col-span-13;
        }

        @container TransactionListItem (min-width: 64rem) {
            @apply tw-col-span-3;
        }
    }

    .TransactionListItem__column-allocations {
        @apply tw-col-span-full;
        @apply tw-order-none;

        @container TransactionListItem (min-width: 48rem) {
            @apply tw-order-last;
        }

        @container TransactionListItem (min-width: 64rem) {
            @apply tw-order-none tw-col-span-7;
        }
    }

    .TransactionListItem__column-allocations--expanded {
        @apply tw-col-span-full;
        @apply tw-order-none;

        @container TransactionListItem (min-width: 48rem) {
            @apply tw-order-last;
        }

        @container TransactionListItem (min-width: 64rem) {
            @apply tw-order-none tw-col-span-12;
        }
    }

    .TransactionListItem__allocations {
        @apply tw-flex tw-flex-1 tw-flex-col tw-gap-2;
        @apply tw-border-dashed;

        @container TransactionListItem (min-width: 48rem) {
            @apply tw-pt-2 tw-flex-none tw-border-t;
        }

        @container TransactionListItem (min-width: 64rem) {
            @apply tw-flex-none tw-border-0 tw-pt-0;
        }
    }

    .TransactionListItem__column-date {
        @apply tw-col-span-full;

        @container TransactionListItem (min-width: 48rem) {
            @apply tw-col-span-4;
        }

        @container TransactionListItem (min-width: 64rem) {
            @apply tw-col-span-3;
        }
    }

    .TransactionListItem__column-details {
        @apply tw-grid tw-grid-cols-2 tw-gap-2;
        @apply tw-col-span-full;

        @container TransactionListItem (min-width: 48rem) {
            @apply tw-col-span-6 tw-gap-0.5;
        }
        @container TransactionListItem (min-width: 64rem) {
            @apply tw-col-span-5;
        }
    }

    .TransactionListItem__column-payment-method {
        @apply tw-col-span-1;

        @container TransactionListItem (min-width: 48rem) {
            @apply tw-col-span-full;
        }
    }

    .TransactionListItem__column-reference {
        @apply tw-col-span-1;

        @container TransactionListItem (min-width: 48rem) {
            @apply tw-col-span-full;
        }
    }

    .TransactionListItem__column-description {
        @apply tw-col-span-full;
    }

    .TransactionListItem__column-informations {
        @apply tw-grid tw-grid-cols-2 tw-gap-2;
        @apply tw-col-span-full;

        @container TransactionListItem (min-width: 48rem) {
            @apply tw-col-span-6 tw-gap-0.5;
        }

        @container TransactionListItem (min-width: 64rem) {
            @apply tw-col-span-5 ;
        }
    }

    .TransactionListItem__column-customer {
        @apply tw-col-span-1;

        @container TransactionListItem (min-width: 48rem) {
            @apply tw-col-span-full;
        }
    }

    .TransactionListItem__column-tenant {
        @apply tw-col-span-1;

        @container TransactionListItem (min-width: 48rem) {
            @apply tw-col-span-full;
        }
    }

    .TransactionListItem__column-actions {
        @apply tw-col-span-4;
        @apply tw-order-none;
        @apply tw-text-right;

        @container TransactionListItem (min-width: 48rem) {
            @apply tw-col-span-1 tw-order-5;
        }

        @container TransactionListItem (min-width: 64rem) {
            @apply tw-order-last;
        }
    }

    .TransactionListItem__icon {
        @apply tw-hidden tw-text-gray-500;

        @container TransactionListItem (min-width: 48rem) {
            @apply tw-block;
        }
    }

    .TransactionListItem__row {
        @apply tw-flex tw-items-baseline tw-gap-2;
    }
</style>
