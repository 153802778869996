<template>
    <MModal
        :header-title="modalTitle"
        :loading="isFetching"
        :modal="modal"
        size="3xl"
        @hidden="$emit('hidden')"
    >
        <TransactionModalContent
            :can-select-customer="canSelectCustomer"
            :customer="customer"
            :invoice="invoice"
            :payment="payment"
            :transaction-state="transactionState"
        />
        <template #footer-end="{hide}">
            <TransactionModalFooter
                :transaction-state="transactionState"
                @hide="hide"
            />
        </template>
    </MModal>
</template>

<script lang="ts">
    import {computed, defineComponent, onBeforeMount, type PropType} from 'vue';
    import {useRoute} from 'vue-router/composables';
    import _round from 'lodash-es/round';
    import useTransactionState, {
        setupStoreForCreation,
    } from '@/modules/cashier/composables/issuing/transaction/useTransactionState';
    import TransactionStatusValue from '@/modules/cashier/transaction/domain/TransactionStatusValue';
    import TransactionTypeValue from '@/modules/cashier/transaction/domain/TransactionTypeValue';
    import type PaymentModel from '@/modules/cashier/models/PaymentModel';
    import type CustomerModel from '@/modules/cashier/models/CustomerModel';
    import type TransactionModel from '@/modules/cashier/transaction/domain/TransactionModel';
    import {Epoch, ModelCollection} from '@meekohq/lumos';
    import {
        loadTransactionsRelationships,
    } from '@/modules/cashier/utils/issuing/transaction/loadTransactionsRelationships';
    import type {LegacyInvoiceType} from '@/modules/cashier/utils/billing/invoice/LegacyInvoiceType';
    import type {ModalType} from '@/modules/app/composables/useModal';
    import __ from '@/modules/app/utils/i18n-facade';
    import TransactionModalContent
        from '@/modules/cashier/transaction/infrastructure/components/TransactionModalContent.vue';
    import TransactionModalFooter
        from '@/modules/cashier/transaction/infrastructure/components/TransactionModalFooter.vue';

    export default defineComponent({
        components: {
            TransactionModalFooter,
            TransactionModalContent,
        },
        props: {
            modal: {
                type: Object as PropType<ModalType>,
                required: true,
            },
            canSelectCustomer: {
                type: Boolean,
                default: true,
            },
            invoice: {
                type: Object as PropType<LegacyInvoiceType>,
                required: false,
                default: undefined,
            },
            tenantId: {
                type: String,
                required: true,
            },
            payment: {
                type: Object as PropType<PaymentModel>,
                required: false,
                default: undefined,
            },
            customer: {
                type: Object as PropType<CustomerModel>,
                default: undefined,
            },
        },
        setup(props, {emit}) {
            const route = useRoute();

            // Get transaction state
            const transactionState = useTransactionState();
            const {
                bus,
                transaction,
                loading: isFetching,
            } = transactionState;

            const modalTitle = computed(() => {
                if (transaction.value?.isCredit) {
                    return __('billing_transaction:actions.create_credit');
                }

                return __('billing_transaction:actions.create_debit');
            });

            onBeforeMount(async () => {
                await setupState();
            });

            bus.on('saved', async (transactionModel: TransactionModel) => {
                // Here we load the relationships the exact same way as in the TransactionList
                await loadTransactionsRelationships(new ModelCollection<TransactionModel>([transactionModel]));

                props.modal.hide();
                // Then we emit the updated transaction with the relationships loaded
                emit('created', transactionModel);
            });

            async function setupState() {
                const attributes: Partial<TransactionModel['attributes']> = {
                    date: Epoch.now().toISOString(),
                    status: TransactionStatusValue.succeeded,
                };

                if (props.payment) {
                    attributes.type = props.payment.attributes.refund ? TransactionTypeValue.debit : TransactionTypeValue.credit;
                    attributes.amount = _round(props.payment.computed.remaining_amount, 2);
                } else {
                    attributes.type = route.query?.type as TransactionTypeValue ?? TransactionTypeValue.credit;
                }

                await setupStoreForCreation(
                    transactionState,
                    props.tenantId,
                    props.payment?.invoicesRelation.first()?.attributes.customer_id ?? props.customer?.getKey(),
                    attributes,
                );
            }

            return {
                bus,
                isFetching,
                modalTitle,
                transaction,
                transactionState,
            };
        },
    });
</script>

<style lang="scss" scoped>
    .TransactionCreateModal__separator {
        @apply tw-w-full tw-h-1 tw-rounded-full tw-bg-gray-200 tw-my-6;
    }

    .TransactionCreateModal__footer {
        @apply tw-w-full tw-flex tw-justify-end tw-gap-3;
    }
</style>
