<template>
    <CAlert v-if="failedEvents.count()">
        <CVStack gap="3">
            <CText font-weight="semibold">
                {{ __('hr_calendar:fail_add_some_following_plannings_colon') }}
            </CText>
            <CText
                v-for="({error, model}, index) in failedEvents"
                :key="'failed-event-' + index"
            >
                <span
                    v-html="__('hr_calendar:from_to_colon', {
                        from: Epoch.fromMoment(model.startedAt).toLocaleString(Epoch.presets.DATETIME_SHORT),
                        to: Epoch.fromMoment(model.endedAt).toLocaleString(Epoch.presets.DATETIME_SHORT),
                        error: error.getErrors('/attributes/datetime_event/started_at').first()
                    })"
                />
            </CText>
        </CVStack>
    </CAlert>
</template>

<script lang="ts">
    import type {PropType} from 'vue';
    import {defineComponent} from 'vue';
    import type {Collection} from '@meekohq/lumos';
    import {Epoch} from '@meekohq/lumos';
    import type {EventWithError} from '@/modules/human-resources/composables/calendar/useEventWithError';

    export default defineComponent({
        components: {},
        props: {
            failedEvents: {
                required: true,
                type: Object as PropType<Collection<EventWithError>>,
            },
        },
        computed: {
            Epoch() {
                return Epoch;
            },
        },
    });

</script>
