<template>
    <MBox class="tw-p-4">
        <MHeading class="tw-mb-4">
            {{ __('user:update_email') }}
        </MHeading>
        <CForm>
            <CFormGroup>
                <CLabel>
                    {{ __('user:current_email') }}
                </CLabel>
                <MInput
                    v-model="currentEmail"
                    :placeholder="__('common:email_example')"
                    :readonly="true"
                />
            </CFormGroup>
            <CFormGroup>
                <CLabel>
                    {{ __('user:new_email') }}*
                </CLabel>
                <div class="tw-flex tw-flex-row tw-gap-2">
                    <MInput
                        v-model="newEmail"
                        :focus="true"
                        :placeholder="__('common:email_example')"
                    />
                    <MButton
                        v-if="canVerifyEmail"
                        class="tw-shrink-0"
                        :disabled="!canVerifyEmail"
                        :label="__('user:verify_email')"
                        :loading="buttonLoader"
                        @click="verifyEmail"
                    />
                    <MTooltip
                        v-else
                        :label="__('user:enter_new_email')"
                        placement="top"
                    >
                        <CButton
                            class="tw-shrink-0"
                            :disabled="!canVerifyEmail"
                            :label="__('user:verify_email')"
                            :loading="buttonLoader"
                            @click="verifyEmail"
                        />
                    </MTooltip>
                </div>
                <CFormErrorMessageList
                    v-if="errorsEmail.hasErrors"
                    :errors="errorsEmail.getErrors('new_email')"
                />
                <div
                    v-if="emailVerified"
                    class="tw-mt-2 tw-text-green-500"
                >
                    <FontAwesomeIcon
                        class="tw-mr-1"
                        icon="fad fa-circle-check"
                    />
                    {{ __('user:email_verified') }}
                </div>
            </CFormGroup>
            <CFormGroup v-if="emailVerified">
                <CLabel>
                    {{ __('common:password') }}
                </CLabel>
                <MInput
                    v-model="password"
                    autocomplete="false"
                    :has-error="hasErrorLogin"
                    :placeholder="__('common:password')"
                    type="password"
                />
                <CFormErrorMessage v-if="hasErrorLogin">
                    <template v-if="errorLogin === 'invalid_credential'">
                        {{ __('user:errors.invalid_credential') }}
                    </template>
                    <template v-if="errorLogin === 'email_already_exist'">
                        {{ __('user:errors.email_already_exist') }}
                    </template>
                    <template v-if="errorLogin === 'invalid_token'">
                        {{ __('user:errors.invalid_token') }}
                    </template>
                </CFormErrorMessage>
            </CFormGroup>
            <CFormGroup class="tw-text-right">
                <MButton
                    :disabled="!canUpdateEmail"
                    :label="__('common:actions.validate')"
                    :loading="updatingEmail"
                    variant="primary"
                    @click="updateEmail"
                />
            </CFormGroup>
        </CForm>
    </MBox>
</template>

<script lang="ts">
    import {computed, defineComponent, onBeforeUnmount, ref} from 'vue';
    import useApi from '@/modules/app/composables/useApi';
    import {collect, JsonAPISchema, ValidationError, ValidationErrorItem} from '@meekohq/lumos';
    import route from '@/modules/legacy/libs/ziggy';
    import Loader from '@/modules/legacy/helpers/loader.helper';
    import ErrorHandler from '@/modules/legacy/libs/errors/errorHandler';
    import useBroadcast from '@/modules/app/composables/useBroadcast';
    import useAuth from '@/modules/app/composables/useAuth';
    import __ from '@/modules/app/utils/i18n-facade';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';

    export default defineComponent({
        setup() {
            const {legacyUser} = useAuth();
            const errorsEmail = ref(new ErrorHandler());
            const loader = ref(new Loader());
            const currentEmail = ref(legacyUser.value.email);
            const newEmail = ref('');
            const password = ref('');
            const buttonLoader = ref(false);
            const token = ref('');
            const emailVerified = ref(false);
            const hasErrorLogin = ref(false);
            const errorLogin = ref('');
            const updatingEmail = ref(false);
            const broadcastChannel = 'email-update';

            const canUpdateEmail = computed(() =>
                password.value.length > 0 && emailVerified.value,
            );

            const canVerifyEmail = computed(() =>
                newEmail.value.length > 0 && newEmail.value !== currentEmail.value,
            );

            onBeforeUnmount(() => {
                useBroadcast().privateChannel.unbind(broadcastChannel);
            });

            async function verifyEmail() {
                useBroadcast().privateChannel.unbind(broadcastChannel);
                buttonLoader.value = true;
                try {
                    await useApi().legacy.post(route('user/users.send-new-email-url'), new JsonAPISchema.JsonApiDocument(
                        new JsonAPISchema.JsonApiData({
                            new_email: newEmail.value,
                        })),
                    );
                    useNotification().success(__('user:email_sent'));
                    useBroadcast().privateChannel.bind(broadcastChannel, response => {
                        token.value = response.token;
                        emailVerified.value = true;
                        buttonLoader.value = false;
                        errorsEmail.value.reset();
                    });
                } catch (err: any) {
                    const errorCollect = collect(err.response.data.errors).map((e: any) =>
                        new ValidationErrorItem(e.title, e.code, e.detail, e.source),
                    );
                    errorsEmail.value.reset(new ValidationError(errorCollect));
                    buttonLoader.value = false;
                }
            }

            async function updateEmail() {
                updatingEmail.value = true;
                try {
                    await useApi().legacy.post(route('user/users.update-email'), new JsonAPISchema.JsonApiDocument(
                        new JsonAPISchema.JsonApiData({
                            current_email: currentEmail.value,
                            new_email: newEmail.value,
                            password: password.value,
                            token: token.value,
                        })),
                    );
                    legacyUser.value.email = newEmail.value;
                    useNotification().success(__('user:email_updated_successfully'));
                } catch (err: any) {
                    errorLogin.value = err.response.data.title;
                    hasErrorLogin.value = true;
                    updatingEmail.value = false;
                }
            }

            return {
                canUpdateEmail,
                emailVerified,
                buttonLoader,
                canVerifyEmail,
                currentEmail,
                newEmail,
                password,
                loader,
                errorsEmail,
                errorLogin,
                hasErrorLogin,
                updatingEmail,
                updateEmail,
                verifyEmail,
            };
        },
    });

</script>
