import {Str} from '@meekohq/lumos';
import {get, has} from 'lodash-es';
import Vue from 'vue';

export const store = {} as any;

export default class Store {
    protected id: string = Str.uuid();

    constructor(id: string | null = null) {
        if (id !== null) {
            this.id = id;
        }
    }

    get data(): any {
        if (!has(store, this.id)) {
            Vue.set(store, this.id, {});
        }

        return get(store, this.id);
    }

    set data(value) {
        Vue.set(store, this.id, {});
    }

    flush() {
        Vue.set(store, this.id, {});
    }
}
