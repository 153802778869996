<script lang="ts" setup>
    import {QueryBuilder} from '@meekohq/lumos';
    import {computed, type PropType, ref} from 'vue';
    import {clone} from 'lodash-es';
    import SelectResource from '@/modules/app/components/SelectResource.vue';
    import MemberModel from '@/modules/family/models/MemberModel';
    import type OrganizationModel from '@/modules/organization/models/OrganizationModel';
    import MemberAvatar from '@/modules/app/components/atoms/avatars/MemberAvatar.vue';
    import TextHighlighter from '@/modules/legacy/helpers/text-highlighter.helper';

    const props = defineProps({
        value: {
            type: Object as PropType<MemberModel>,
            default: undefined,
        },
        extendBuilder: {
            type: QueryBuilder<any>,
            default: () => new QueryBuilder(),
        },
        organizationModel: {
            type: Object as PropType<OrganizationModel>,
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        customModal: {
            type: Boolean,
            default: false,
        },
        createOptionEnabled: {
            type: Boolean,
            default: true,
        },
        hasUnselect: {
            type: Boolean,
            default: true,
        },
        fallbackText: {
            type: String,
            default: undefined,
        },
        unselectText: {
            type: String,
            default: undefined,
        },
        dropdownPosition: {
            type: String,
            default: undefined,
        },
        dropdownSizeClass: {
            type: String,
            default: undefined,
        },
        dropdownClass: {
            type: String,
            default: undefined,
        },
        buttonClass: {
            type: String,
            default: undefined,
        },
    });

    const emit = defineEmits(['hideModal', 'showModal', 'input', 'change']);

    const modelValue = ref<MemberModel | undefined>(clone(props.value));
    const defaultModal = ref();
    const selectResourceEl = ref<typeof SelectResource>();

    const textHighlighter = new TextHighlighter();
    const searchValue = ref('');

    const builder = computed(() => {
        return MemberModel.query()
            .where(query => {
                query.whereLike('first_name', `*${searchValue.value}*`)
                    .orWhereLike('last_name', `*${searchValue.value}*`);
            })
            .whereHas(new MemberModel().organizations(), query => {
                query.where('id', props.organizationModel.getKey());
            }).inject(props.extendBuilder);
    });

    function onSearchValue(event: string) {
        searchValue.value = event;
    }

    function showModal() {
        if (props.customModal) {
            emit('showModal');
        } else {
            defaultModal.value?.show();
        }

        selectResourceEl.value?.hide();
    }

    function onModelSelected(model: MemberModel | undefined) {
        // Emit input and change events
        // input event is used to bind the value to the v-model
        emit('input', model);
        emit('change', model);
    }
</script>

<template>
    <div>
        <SelectResource
            ref="selectResourceEl"
            :builder="builder"
            :button-class="buttonClass"
            :has-unselect="true"
            :model="MemberModel"
            :value="modelValue"
            @fallback="showModal"
            @input="onModelSelected"
            @search="onSearchValue"
        >
            <template #button>
                <template v-if="value">
                    {{ value.fullname }}
                </template>
                <template v-else-if="fallbackText">
                    {{ fallbackText }}
                </template>
                <template v-else>
                    {{ __('family_member:find_or_add_member') }}
                </template>
            </template>

            <template
                v-if="createOptionEnabled"
                #fallback="{searchValue}"
            >
                <template v-if="searchValue">
                    <i class="fas fa-plus fa-fw tw--ml-1 tw-mr-1"/>{{ __('common:actions.add') }} {{ searchValue }}
                </template>
                <template v-else>
                    <i class="fas fa-plus fa-fw tw--ml-1 tw-mr-1"/>{{ __('common:add_account') }}
                </template>
            </template>

            <template
                v-if="hasUnselect"
                #unselect-item
            >
                <template v-if="unselectText">
                    {{ unselectText }}
                </template>
                <template v-else>
                    {{ __('hr:member_zero') }}
                </template>
            </template>

            <template #default="{option, searchValue}">
                <div class="tw-flex tw-pr-4 tw-space-x-2 tw-items-center">
                    <MemberAvatar
                        class="tw-w-6"
                        :member-model="option"
                    />
                    <div
                        class="tw-truncate"
                        v-html="textHighlighter.highlight(searchValue, option.fullname)"
                    />
                </div>
            </template>
        </SelectResource>
    </div>
</template>
