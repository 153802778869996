import {Model} from '@meekohq/lumos';
import OrganizationModel from '@/modules/organization/models/OrganizationModel';
import TenantModel from '@/modules/cashier/models/TenantModel';

export default class OrganizationTenantPivot extends Model {
    public type = 'organization/organizations_tenants_pivot';

    public attributes: {
        id: string;
        account_id: string | undefined;
        tenant_id: string | undefined;
        organization_id: string | undefined;
    } = {
            id: this.uuid(),
            account_id: undefined,
            tenant_id: undefined,
            organization_id: undefined,
        };

    tenant() {
        return this.belongsTo('tenant', TenantModel, 'tenant_id');
    }

    organization() {
        return this.belongsTo('organization', OrganizationModel, 'organization_id');
    }
}
