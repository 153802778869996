import {Model} from '@meekohq/lumos';
import RequestModel from '@/modules/request/models/RequestModel';
import RequestTypeStageModel from '@/modules/request/models/RequestTypeStageModel';
import EventTypeModel from '@/modules/human-resources/models/EventTypeModel';
import RequestTypeOrganizationPivotModel from '@/modules/request/models/RequestTypeOrganizationPivotModel';
import OrganizationModel from '@/modules/organization/models/OrganizationModel';
import RequestTypeUserPivotModel from '@/modules/request/models/RequestTypeUserPivotModel';

export default class RequestTypeModel extends Model {
    public type = 'request/types';

    attributes: {
        id: string,
        account_id: string | undefined;
        type_stage_id: string | undefined,
        default_request_type_stage_id: string | undefined,
        name: string | undefined,
    } = {
            id: this.uuid(),
            account_id: undefined,
            type_stage_id: undefined,
            default_request_type_stage_id: undefined,
            name: undefined,
        };

    requests() {
        return this.hasMany('requests', RequestModel, 'request_type_id');
    }

    requestTypeStages() {
        return this.hasMany('requestTypeStages', RequestTypeStageModel, 'request_type_id');
    }

    eventTypes() {
        return this.hasMany('eventTypes', EventTypeModel, 'request_type_id');
    }

    requestTypeOrganizationPivot() {
        return this.hasMany('requestTypeOrganizationPivot', RequestTypeOrganizationPivotModel, 'request_type_id');
    }

    organizations() {
        return this.belongsToMany('organizations', OrganizationModel, RequestTypeOrganizationPivotModel, 'request_type_id', 'organization_id');
    }

    requestTypeUserPivot() {
        return this.hasMany('requestTypeUserPivot', RequestTypeUserPivotModel, 'request_type_id');
    }
}
