import type OrganizationOpeningContract from '@/modules/organization/utils/OrganizationOpeningContract';
import TimePeriodValue from '@/modules/app/utils/Values/TimePeriodValue';
import {collect} from '@meekohq/lumos';
import type {Moment} from 'moment';
import moment from 'moment';
import momentTz from 'moment-timezone';

export interface LegacyNurseryInterface {
    openingHours: OpeningHour[];
    closedPeriods: ClosedPeriod[];
    timezone: string;
}

interface OpeningHour {
    id: number;
    day: string;
    started_at: string;
    ended_at: string;
}

interface ClosedPeriod {
    id: number;
    started_at: number;
    ended_at: number;
}

export default class LegacyNurseryOpening implements OrganizationOpeningContract {
    private _closedPeriods: ClosedPeriod[] = [];
    private _openingHours: OpeningHour[] = [];
    private _timezone = 'Europe/Paris';

    public forOrganization(organization: LegacyNurseryInterface): this {
        this._closedPeriods = organization.closedPeriods;
        this._openingHours = organization.openingHours;
        this._timezone = organization.timezone;

        return this;
    }

    /**
     * Check if the organization is opened on the given date.
     *
     * @param date
     */
    public isOpenedOn(date: Moment): boolean {
        if (this.isDayInClosedPeriod(date)) {
            return false;
        }

        return this.isDayInOpening(date);
    }

    /**
     * Check if the organization is closed on the given date.
     *
     * @param date
     */
    public isClosedOn(date: Moment): boolean {
        return !this.isOpenedOn(date);
    }

    /**
     * Get of the opening hours of the day the given date is.
     *
     * @param date
     */
    public getOpeningHoursOn(date: Moment): TimePeriodValue | null {
        const opening = collect(this._openingHours).filter(openingHour => {
            // Convert date to english format because in legacy opening hour, the day is in english format
            const englishDate = date.clone();
            englishDate.locale('en');

            return englishDate.format('ddd').toLowerCase() === openingHour.day;
        }).first();

        return opening ? new TimePeriodValue({startedAt: opening.started_at, endedAt: opening.ended_at}) : null;
    }

    /**
     * Check if the given date is in an opening hour.
     *
     * @param date
     * @private
     */
    private isDayInOpening(date: Moment): boolean {
        // Convert date to english format because in legacy opening hour, the day is in english format
        const englishDate = date.clone();
        englishDate.locale('en');

        const secondsOfOpening = collect(this._openingHours).filter(openingHour => {
            return englishDate.format('ddd').toLowerCase() === openingHour.day;
        }).sum(openingHour => {
            // Generate a moment object for the closing hour
            const closing = moment().hours(moment.duration(openingHour.ended_at).hours())
                .minutes(moment.duration(openingHour.ended_at).minutes())
                .seconds(moment.duration(openingHour.ended_at).seconds())
                .unix();

            // Generate a moment object for the opening hour
            const opening = moment().hours(moment.duration(openingHour.started_at).hours())
                .minutes(moment.duration(openingHour.started_at).minutes())
                .seconds(moment.duration(openingHour.started_at).seconds())
                .unix();

            // Subtract the opening hour from the closing hour to get the duration of the opening
            return closing - opening;
        });

        return secondsOfOpening > 0;
    }

    /**
     * Check if the given date is in a closed period.
     *
     * @param date
     * @private
     */
    private isDayInClosedPeriod(date: Moment): boolean {
        const closedPeriods = collect(this._closedPeriods).filter(closedPeriod => {
            // Check if date is between start and end of closed period
            return date.isBetween(
                momentTz.unix(closedPeriod.started_at).tz(this._timezone).startOf('day'),
                momentTz.unix(closedPeriod.ended_at).tz(this._timezone).endOf('day'),
            );
        });

        return closedPeriods.count() > 0;
    }
}
