<script lang="ts" setup>
    import {computed, type PropType, ref} from 'vue';
    import {QueryBuilder} from '@meekohq/lumos';
    import CustomerModel from '@/modules/cashier/models/CustomerModel';
    import type TenantModel from '@/modules/cashier/models/TenantModel';
    import TextHighlighter from '@/modules/legacy/helpers/text-highlighter.helper';
    import SelectResource from '@/modules/app/components/SelectResource.vue';

    const props = defineProps({
        value: {
            type: Object as PropType<CustomerModel>,
            default: undefined,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        tenantModel: {
            type: Object as PropType<TenantModel>,
            required: true,
        },
        withLegal: {
            type: Boolean,
            default: true,
        },
        withNatural: {
            type: Boolean,
            default: true,
        },
        extendBuilder: {
            type: Object as PropType<QueryBuilder<any>>,
            default: () => new QueryBuilder(),
        },
        createOptionEnabled: {
            type: Boolean,
            default: true,
        },
    });

    const emits = defineEmits<{
        (e: 'hideModal'): void,
        (e: 'showModal'): void,
        (e: 'input', model: CustomerModel | undefined): void
    }>();

    const textHighlighter = new TextHighlighter();

    const selectResource = ref();
    const searchValue = ref('');

    const personFilter = computed(() => {
        const filter = [] as string[];
        if (props.withLegal) {
            filter.push('legal');
        }
        if (props.withNatural) {
            filter.push('natural');
        }

        return filter;
    });

    const builder = computed(() => {
        return CustomerModel.query().tap(query => {
            if (searchValue.value) {
                query.whereLike('name', `*${searchValue.value}*`);
            } else {
                query.whereNull('archived_at');
            }
        }).whereIn('person', personFilter.value)
            .where('tenant_id', props.tenantModel.getKey())
            .orderBy('name')
            .inject(props.extendBuilder)
            .setEagerLoads(props.extendBuilder.getEagerLoads());
    });

    function onSearchValue(event: string) {
        searchValue.value = event;
    }

    function onModelSelected(model: CustomerModel | undefined) {
        emits('input', model);
    }

    function showModal() {
        emits('showModal');
        selectResource.value.hide();
    }
</script>

<template>
    <div>
        <SelectResource
            ref="selectResource"
            :builder="builder"
            :disabled="disabled"
            :has-unselect="true"
            :model="CustomerModel"
            :value="value"
            @fallback="showModal"
            @input="onModelSelected"
            @search="onSearchValue"
        >
            <template #button>
                <template v-if="value">
                    <i
                        v-if="value.attributes.person === 'legal'"
                        class="fad fa-city fa-fw tw-text-blue-600"
                    />
                    <i
                        v-else
                        class="fas fa-user fa-fw tw-text-blue-500"
                    />
                    {{ value.attributes.name }}
                </template>
                <template v-else-if="createOptionEnabled">
                    {{ __('billing_customer:find_or_add_an_account') }}
                </template>
                <template v-else>
                    {{ __('billing_customer:find_an_account') }}
                </template>
            </template>
            <template
                v-if="createOptionEnabled"
                #fallback="{searchValue}"
            >
                <template v-if="searchValue">
                    <i class="fas fa-plus fa-fw tw--ml-1 tw-mr-1"/>{{ __('common:actions.add') }} {{ searchValue }}
                </template>
                <template v-else>
                    <i class="fas fa-plus fa-fw tw--ml-1 tw-mr-1"/>{{ __('common:add_account') }}
                </template>
            </template>

            <template
                v-if="value"
                #unselect-item
            >
                <i class="fad fa-unlink fa-fw tw--ml-1 tw-mr-1"/>{{ __('billing_customer:deselect_account') }} {{ value.attributes.name }}
            </template>

            <template #default="{option, searchValue}">
                <div class="tw-flex tw-pr-4 tw-items-center">
                    <div class="tw--ml-1 tw-mr-2">
                        <i
                            v-if="option.attributes.person === 'legal'"
                            class="fad fa-city fa-fw tw-text-xl tw-text-blue-600"
                        />
                        <i
                            v-else
                            class="fas fa-user fa-fw tw-text-xl tw-text-blue-500"
                        />
                    </div>
                    <div
                        class="tw-truncate"
                        v-html="textHighlighter.highlight(searchValue, option.attributes.name)"
                    />
                </div>
            </template>
        </SelectResource>
        <slot
            :modal="{searchValue}"
            name="modal"
        />
    </div>
</template>

