import __ from '@/modules/app/utils/i18n-facade';

(function($) {
    'use strict';

    // Adds the extra button definition
    $.extend(true, $.trumbowyg, {
        plugins: {
            variables: {
                init: function(trumbowyg) {
                    trumbowyg.addBtnDef('kid_variables', {
                        dropdown: variablesSelector(trumbowyg, kidVariables(), 'kid'),
                        hasIcon: false,
                        text: __('common:kid_one'),
                    });

                    trumbowyg.addBtnDef('nursery_variables', {
                        dropdown: variablesSelector(trumbowyg, nurseryVariables(), 'nursery'),
                        hasIcon: false,
                        text: __('common:organization_one'),
                    });

                    trumbowyg.addBtnDef('contract_variables', {
                        dropdown: variablesSelector(trumbowyg, contractVariables(), 'contract'),
                        hasIcon: false,
                        text: __('common:contract_one'),
                    });

                    trumbowyg.addBtnDef('staff_variables', {
                        dropdown: variablesSelector(trumbowyg, staffVariables(), 'staff'),
                        hasIcon: false,
                        text: __('hr_staff:staff_member'),
                    });

                    trumbowyg.addBtnDef('staff_contract_variables', {
                        dropdown: variablesSelector(trumbowyg, staffContractVariables(), 'staff_contract'),
                        hasIcon: false,
                        text: __('hr_staff:staff_contract'),
                    });

                    trumbowyg.addBtnDef('billing_variables', {
                        dropdown: variablesSelector(trumbowyg, billingVariables(), 'billing'),
                        hasIcon: false,
                        text: __('common:billing'),
                    });

                    trumbowyg.addBtnDef('caf_variables', {
                        dropdown: variablesSelector(trumbowyg, CAFVariables(), 'caf'),
                        hasIcon: false,
                        text: 'CAF',
                    });

                    trumbowyg.addBtnDef('deposit_variables', {
                        dropdown: variablesSelector(trumbowyg, depositVariables(), 'deposit'),
                        hasIcon: false,
                        text: __('common:deposit_one'),
                    });

                    trumbowyg.addBtnDef('transaction_variables', {
                        dropdown: variablesSelector(trumbowyg, transactionVariables(), 'transaction'),
                        hasIcon: false,
                        text: __('common:payment_one'),
                    });

                    trumbowyg.addBtnDef('company_variables', {
                        dropdown: variablesSelector(trumbowyg, companyVariables(), 'company'),
                        hasIcon: false,
                        text: __('common:company_one'),
                    });

                    trumbowyg.addBtnDef('family1_variables', {
                        dropdown: variablesSelector(trumbowyg, family1Variables(), 'family1'),
                        hasIcon: false,
                        text: __('common:parent_with_count', {count: 1}),
                    });

                    trumbowyg.addBtnDef('family2_variables', {
                        dropdown: variablesSelector(trumbowyg, family2Variables(), 'family2'),
                        hasIcon: false,
                        text: __('common:parent_with_count', {count: 2}),
                    });

                    trumbowyg.addBtnDef('sepa_mandate_variables', {
                        dropdown: variablesSelector(trumbowyg, sepaMandateVariables(), 'sepa_mandate'),
                        hasIcon: false,
                        text: __('common:sepa_mandate_one'),
                    });

                    trumbowyg.addBtnDef('customer_account_variables', {
                        dropdown: variablesSelector(trumbowyg, customerAccountVariables(), 'customer_account'),
                        hasIcon: false,
                        text: __('common:customer_one'),
                    });

                    trumbowyg.addBtnDef('other_variables', {
                        dropdown: variablesHtmlSelector(trumbowyg, otherVariables(), 'other'),
                        hasIcon: false,
                        text: __('common:other_other'),
                    });
                },
            },
        },
    });

    // Creates the template-selector dropdown.
    function variablesSelector(trumbowyg, arrayToLoop, name) {
        const variables = [];

        $.each(arrayToLoop, function(index, customVariable) {
            if (customVariable) {
                trumbowyg.addBtnDef(name + '_variable_' + index, {
                    fn: function() {
                        trumbowyg.execCmd('insertHTML', '${' + customVariable.identifier + '} ');
                    },
                    hasIcon: false,
                    title: customVariable.name,
                });
                variables.push(name + '_variable_' + index);
            }
        });

        return variables;
    }

    function variablesHtmlSelector(trumbowyg, arrayToLoop, name) {
        const variables = [];

        $.each(otherVariables(), function(index, otherVariable) {
            if (otherVariable) {
                trumbowyg.addBtnDef(name + '_variable_' + index, {
                    fn: function() {
                        trumbowyg.execCmd('insertHTML', otherVariable.identifier);
                    },
                    hasIcon: false,
                    title: otherVariable.name,
                });
                variables.push(name + '_variable_' + index);
            }
        });

        return variables;
    }

    function kidVariables() {
        return [{
            name: __('common:first_name'),
            identifier: 'prenom-de-l-enfant',
        }, {
            name: __('common:last_name'),
            identifier: 'nom-de-l-enfant',
        }, {
            name: __('common:birthdate'),
            identifier: 'anniversaire-de-l-enfant',
        }, {
            name: __('common:note_one'),
            identifier: 'note-sur-l-enfant',
        }];
    }

    function nurseryVariables() {
        return [{
            name: __('common:logo'),
            identifier: 'logo-de-la-creche',
        }, {
            name: __('common:stamp'),
            identifier: 'cachet-de-la-creche',
        }, {
            name: __('common:signature'),
            identifier: 'signature-de-la-creche',
        }, {
            name: __('common:name'),
            identifier: 'nom-de-la-creche',
        }, {
            name: __('common:address.address'),
            identifier: 'adresse-de-la-creche',
        }, {
            name: __('common:address.zip_code'),
            identifier: 'code-postal-de-la-creche',
        }, {
            name: __('common:address.city'),
            identifier: 'ville-de-la-creche',
        }, {
            name: __('common:number_of_places'),
            identifier: 'nombre-de-places-dans-la-creche',
        }, {
            name: __('organization:surface'),
            identifier: 'surface-de-la-creche',
        }, {
            name: __('common:website'),
            identifier: 'site-internet-de-la-creche',
        }, {
            name: __('common:email'),
            identifier: 'email-de-la-creche',
        }, {
            name: __('common:phone.general'),
            identifier: 'telephone-de-la-creche',
        }, {
            name: __('common:curency_one'),
            identifier: 'devise-de-la-creche',
        }, {
            name: __('organization:openings.opening_hours'),
            identifier: 'ouverture-de-la-creche',
        }, {
            name: __('organization:openings.closed_periods'),
            identifier: 'fermeture-de-la-creche',
        }, {
            name: __('organization:openings.public_holidays'),
            identifier: 'jours-feries-de-la-creche',
        }, {
            name: __('common:opening_date'),
            identifier: 'date-ouverture-structure',
        }, {
            name: __('organization:company_name'),
            identifier: 'denomination-sociale-structure',
        }, {
            name: __('organization:rcs'),
            identifier: 'rcs-structure',
        }, {
            name: __('organization:share_capital'),
            identifier: 'capital-social-structure',
        }, {
            name: __('organization:siren'),
            identifier: 'siren-structure',
        }, {
            name: __('organization:siret'),
            identifier: 'siret-structure',
        }, {
            name: __('common:open_note'),
            identifier: 'note-libre-structure',
        }];
    }

    function staffVariables() {
        return [{
            name: __('common:first_name'),
            identifier: 'prenom-membre-equipe',
        }, {
            name: __('common:last_name'),
            identifier: 'nom-membre-equipe',
        }, {
            name: __('common:maiden_name'),
            identifier: 'nom-jeune-fille-membre-equipe',
        }, {
            name: __('common:nationality'),
            identifier: 'nationalite-membre-equipe',
        }, {
            name: __('common:social_security_number'),
            identifier: 'numero-securite-social-membre-equipe',
        }, {
            name: __('common:birth_place'),
            identifier: 'lieu-naissance-membre-equipe',
        }, {
            name: __('common:gender_one'),
            identifier: 'genre-membre-equipe',
        }, {
            name: __('common:gender.mrs_or_mr'),
            identifier: 'titre-membre-equipe',
        }, {
            name: __('common:birthday'),
            identifier: 'anniversaire-membre-equipe',
        }, {
            name: __('hr:job_one'),
            identifier: 'poste-membre-equipe',
        }, {
            name: __('common:email'),
            identifier: 'email-membre-equipe',
        }, {
            name: __('common:phone.landline'),
            identifier: 'telephone-membre-equipe',
        }, {
            name: __('common:phone.cellular'),
            identifier: 'mobile-membre-equipe',
        }, {
            name: __('common:address.address'),
            identifier: 'adresse-membre-equipe',
        }, {
            name: __('common:address.zip_code'),
            identifier: 'code-postal-membre-equipe',
        }, {
            name: __('common:address.city'),
            identifier: 'ville-membre-equipe',
        }, {
            name: __('common:clockin_code'),
            identifier: 'code-pointage-membre-equipe',
        }, {
            name: __('common:biography'),
            identifier: 'biographie-membre-equipe',
        }, {
            name: __('common:note_one'),
            identifier: 'note-membre-equipe',
        }];
    }

    function staffContractVariables() {
        return [{
            name: __('common:start_date'),
            identifier: 'date-de-debut-de-contrat-de-travail',
        }, {
            name: __('common:end_date'),
            identifier: 'date-de-fin-de-contrat-de-travail',
        }, {
            name: __('common:creation_date'),
            identifier: 'date-de-creation-du-contrat-de-travail',
        }, {
            name: __('hr_staff:termination_date'),
            identifier: 'date-de-rupture-de-contrat-de-travail',
        }, {
            name: __('hr_staff:trial_period_end_date'),
            identifier: 'date-de-fin-essai-du-contrat-de-travail',
        }, {
            name: __('hr_staff:contract_salary'),
            identifier: 'salaire-du-contrat-de-travail',
        }, {
            name: __('common:hours_per_week'),
            identifier: 'heures-par-semaine-du-contrat-de-travail',
        }, {
            name: __('common:note_one'),
            identifier: 'note-du-contrat-de-travail',
        }, {
            name: __('hr:job_title_one'),
            identifier: 'intitule-du-poste-du-contrat-de-travail',
        }, {
            name: __('hr_staff:internship_supervisor_first_name'),
            identifier: 'prenom-du-responsable-de-stage',
        }, {
            name: __('hr_staff:internship_supervisor_last_name'),
            identifier: 'nom-du-responsable-de-stage',
        }, {
            name: __('hr_staff:employment_contract_qualification'),
            identifier: 'qualification-du-contrat-de-travail',
        }];
    }

    function contractVariables() {
        return [{
            name: __('common:start_date'),
            identifier: 'date-de-debut-de-contrat',
        }, {
            name: __('common:end_date'),
            identifier: 'date-de-fin-de-contrat',
        }, {
            name: __('common:creation_date'),
            identifier: 'date-de-creation-du-contrat',
        }, {
            name: __('common:contract_number'),
            identifier: 'no-du-contrat',
        }, {
            name: __('planning:recurrent_planning'),
            identifier: 'semaine-type',
        }, {
            name: __('planning:planning_without_total'),
            identifier: 'semaine-type-sans-total',
        }, {
            name: __('planning:planning_without_hours'),
            identifier: 'semaine-type-sans-heures',
        }, {
            name: __('planning:adaptation_planning'),
            identifier: 'semaine-d-adaptation',
        }, {
            name: __('planning:adaptation_without_total'),
            identifier: 'semaine-d-adaptation-sans-total',
        }, {
            name: __('billing_core:annual_resources'),
            identifier: 'ressources-annuelles-de-la-famille',
        }, {
            name: __('common:dependent_children'),
            identifier: 'enfants-a-charge',
        }, {
            name: __('common:holidays_days'),
            identifier: 'jours-de-vacances',
        }, {
            name: __('common:holidays_days_used'),
            identifier: 'jours-de-vacances-utilises',
        }, {
            name: __('common:holidays_days_remaining'),
            identifier: 'jours-de-vacances-restants',
        }, {
            name: __('common:note_one'),
            identifier: 'note-du-contrat',
        }];
    }

    function transactionVariables() {
        return [{
            name: __('billing_payment:payment_sender'),
            identifier: 'emetteur-du-paiement',
        }, {
            name: __('billing_payment:payment_amount'),
            identifier: 'montant-du-paiement',
        }, {
            name: __('billing_payment:payment_method'),
            identifier: 'methode-du-paiement',
        }, {
            name: __('billing_payment:payment_number'),
            identifier: 'numero-du-paiement',
        }, {
            name: __('billing_payment:payment_date'),
            identifier: 'date-du-paiement',
        }];
    }

    function billingVariables() {
        return [{
            name: __('billing_invoice:monthly_rate_with_currency'),
            identifier: 'tarif-mensuel-avec-devise',
        }, {
            name: __('billing_invoice:daily_rate_with_currency'),
            identifier: 'tarif-journalier-avec-devise',
        }, {
            name: __('billing_invoice:hourly_rate_with_currency'),
            identifier: 'tarif-horaire-avec-devise',
        }, {
            name: __('billing_invoice:days_per_month'),
            identifier: 'jours-par-mois',
        }, {
            name: __('billing_invoice:days_per_week'),
            identifier: 'jours-par-semaine',
        }, {
            name: __('billing_invoice:number_of_monthly_payments'),
            identifier: 'nombre-de-mensualites',
        }, {
            name: __('common:hours_per_month'),
            identifier: 'heures-par-mois',
        }, {
            name: __('common:hours_per_week'),
            identifier: 'heures-par-semaine',
        }, {
            name: __('billing_invoice:contract_hours'),
            identifier: 'heures-dans-le-contrat',
        }, {
            name: __('billing_invoice:halfday_per_month'),
            identifier: 'demi-journee-par-mois',
        }, {
            name: __('billing_invoice:halfday_per_week'),
            identifier: 'demi-journee-par-semaine',
        }, {
            name: __('billing_invoice:quantity_by_month'),
            identifier: 'quantite-par-mois',
        }, {
            name: __('billing_invoice:months_in_smoothing'),
            identifier: 'mois-dans-le-lissage',
        }, {
            name: __('billing_invoice:weeks_in_contract'),
            identifier: 'semaines-dans-le-contrat',
        }, {
            name: __('billing_invoice:weeks_in_smoothing'),
            identifier: 'semaines-dans-le-lissage',
        }, {
            name: __('billing_invoice:closing_weeks'),
            identifier: 'semaines-de-fermetures',
        }, {
            name: __('common:registration_fees'),
            identifier: 'frais-d-inscription',
        }, {
            name: __('billing_invoice:schedule'),
            identifier: 'liste-des-factures',
        }, {
            name: __('family_contract:fees'),
            identifier: 'liste-des-frais',
        }, {
            name: __('billing_invoice:family_brackets'),
            identifier: 'tranche-de-la-famille',
        }];
    }

    function CAFVariables() {
        return [{
            name: __('billing_setting:amount_cmg'),
            identifier: 'montant-de-la-cmg',
        }, {
            name: 'Reste à charge après CMG',
            identifier: 'reste-a-charge',
        }, {
            name: 'Reste à charge après CMG & CI',
            identifier: 'reste-a-charge-avec-ci',
        }, {
            name: 'Crédit d\'impôt',
            identifier: 'credit-d-impot',
        }, {
            name: 'Taux d\'effort',
            identifier: 'taux-d-effort',
        }, {
            name: 'Plafond CAF',
            identifier: 'revenu-plafond',
        }, {
            name: 'Plancher CAF',
            identifier: 'revenu-plancher',
        }];
    }

    function depositVariables() {
        return [{
            name: __('common:amount_one'),
            identifier: 'montant-de-la-caution',
        }, {
            name: __('billing_invoice:deposited_the'),
            identifier: 'caution-deposee-le',
        }, {
            name: __('billing_payment:deposited_by_payment_method'),
            identifier: 'caution-deposee-par',
        }, {
            name: __('billing_invoice:restituted_the'),
            identifier: 'caution-restituee-le',
        }, {
            name: __('billing_payment:restituted_by_payment_method'),
            identifier: 'caution-restituee-par',
        }];
    }

    function companyVariables() {
        return [{
            name: __('hr:employee_name'),
            identifier: 'nom-de-l-employe',
        }, {
            name: __('common:company_name'),
            identifier: 'nom-de-l-entreprise',
        }, {
            name: __('common:company_email'),
            identifier: 'email-de-l-entreprise',
        }, {
            name: __('common:company_address'),
            identifier: 'adresse-de-l-entreprise',
        }, {
            name: __('common:billed_amount'),
            identifier: 'montant-facture-a-l-entreprise',
        }, {
            name: __('billing_invoice:schedule'),
            identifier: 'liste-des-factures-entreprise',
        }];
    }

    function family1Variables() {
        return [{
            name: __('common:gender.mrs_or_mr'),
            identifier: 'titre-du-parent-1',
        }, {
            name: __('common:first_name'),
            identifier: 'prenom-du-parent-1',
        }, {
            name: __('common:last_name'),
            identifier: 'nom-du-parent-1',
        }, {
            name: __('common:email'),
            identifier: 'email-du-parent-1',
        }, {
            name: __('common:birthdate'),
            identifier: 'anniversaire-du-parent-1',
        }, {
            name: __('common:job'),
            identifier: 'profession-du-parent-1',
        }, {
            name: __('family_member:csp'),
            identifier: 'csp-du-parent-1',
        }, {
            name: __('common:address.address'),
            identifier: 'adresse-du-parent-1',
        }, {
            name: __('common:address.city'),
            identifier: 'ville-du-parent-1',
        }, {
            name: __('common:address.state'),
            identifier: 'etat-du-parent-1',
        }, {
            name: __('common:address.country'),
            identifier: 'pays-du-parent-1',
        }, {
            name: __('common:address.zip_code'),
            identifier: 'code-postal-du-parent-1',
        }, {
            name: __('common:phone.landline'),
            identifier: 'telephone-du-parent-1',
        }, {
            name: __('common:phone.cellular'),
            identifier: 'portable-du-parent-1',
        }, {
            name: __('common:phone.work'),
            identifier: 'telephone-pro-du-parent-1',
        }, {
            name: __('common:salary'),
            identifier: 'salaire-du-parent-1',
        }, {
            name: __('family_member:beneficiary_number_short'),
            identifier: 'numero-alloc-du-parent-1',
        }];
    }

    function family2Variables() {
        return [{
            name: __('common:gender.mrs_or_mr'),
            identifier: 'titre-du-parent-2',
        }, {
            name: __('common:first_name'),
            identifier: 'prenom-du-parent-2',
        }, {
            name: __('common:last_name'),
            identifier: 'nom-du-parent-2',
        }, {
            name: __('common:email'),
            identifier: 'email-du-parent-2',
        }, {
            name: __('common:birthdate'),
            identifier: 'anniversaire-du-parent-2',
        }, {
            name: __('common:job'),
            identifier: 'profession-du-parent-2',
        }, {
            name: __('family_member:csp'),
            identifier: 'csp-du-parent-2',
        }, {
            name: __('common:address.address'),
            identifier: 'adresse-du-parent-2',
        }, {
            name: __('common:address.city'),
            identifier: 'ville-du-parent-2',
        }, {
            name: __('common:address.state'),
            identifier: 'etat-du-parent-2',
        }, {
            name: __('common:address.country'),
            identifier: 'pays-du-parent-2',
        }, {
            name: __('common:address.zip_code'),
            identifier: 'code-postal-du-parent-2',
        }, {
            name: __('common:phone.landline'),
            identifier: 'telephone-du-parent-2',
        }, {
            name: __('common:phone.cellular'),
            identifier: 'portable-du-parent-2',
        }, {
            name: __('common:phone.work'),
            identifier: 'telephone-pro-du-parent-2',
        }, {
            name: __('common:salary'),
            identifier: 'salaire-du-parent-2',
        }, {
            name: __('family_member:beneficiary_number_short'),
            identifier: 'numero-alloc-du-parent-2',
        }];
    }

    function sepaMandateVariables() {
        return [{
            name: __('billing_payment:creditor_IBAN'),
            identifier: 'iban-du-creancier',
        }, {
            name: __('billing_payment:creditor_BIC'),
            identifier: 'bic-du-creancier',
        }, {
            name: __('billing_payment:creditor_ICS'),
            identifier: 'ics-du-creancier',
        }, {
            name: __('billing_setting:creditor_name'),
            identifier: 'nom-du-creancier',
        }, {
            name: __('common:delay'),
            identifier: 'delai-de-paiement-sepa',
        }, {
            name: __('billing_payment:debtor_IBAN'),
            identifier: 'iban-du-debiteur',
        }, {
            name: __('billing_payment:debtor_BIC'),
            identifier: 'bic-du-debiteur',
        }, {
            name: __('billing_core:debtor_name'),
            identifier: 'nom-du-debiteur',
        }, {
            name: __('billing_core:sepa_mandate_unique_reference'),
            identifier: 'rum-du-debiteur',
        }, {
            name: __('common:signature_date'),
            identifier: 'date-de-signature-du-mandat',
        }];
    }

    function otherVariables() {
        return [{
            name: __('common:user_last_name'),
            identifier: '${nom-de-l-utilisateur}',
        }, {
            name: __('common:user_first_name'),
            identifier: '${prenom-de-l-utilisateur}',
        }, {
            name: __('common:user_email'),
            identifier: '${email-de-l-utilisateur}',
        }, {
            name: __('common:page_break'),
            identifier: '${saut-de-page}',
        }, {
            name: __('common:date_format.day_from_zero'),
            identifier: '${date-du-jour}',
        }, {
            name: __('common:current_hour'),
            identifier: '${heure-actuelle}',
        }, {
            name: __('common:current_day'),
            identifier: '${jour-actuel}',
        }, {
            name: __('common:current_month'),
            identifier: '${mois-actuel}',
        }, {
            name: __('common:current_year'),
            identifier: '${annee-actuelle}',
        }];
    }

    function customerAccountVariables() {
        return [{
            name: __('common:customer_name'),
            identifier: 'nom-du-client',
        }, {
            name: __('billing_invoice:name_on_billing_address'),
            identifier: 'nom-sur-adresse-facturation',
        }, {
            name: __('common:billing_address_one'),
            identifier: 'adresse-de-facturation',
        }, {
            name: __('common:address.extra_adress'),
            identifier: 'complement-adresse-facturation',
        }, {
            name: __('common:address.zip_code'),
            identifier: 'code-postal-de-facturation',
        }, {
            name: __('common:address.city'),
            identifier: 'ville-de-facturation',
        }, {
            name: __('common:address.country'),
            identifier: 'pays-de-facturation',
        }, {
            name: __('common:billed_amount'),
            identifier: 'montant-facture-du-compte',
        }, {
            name: __('common:upcoming_amount'),
            identifier: 'montant-a-venir-du-compte',
        }, {
            name: __('common:pending_amount'),
            identifier: 'montant-en-attente-du-compte',
        }, {
            name: __('common:unpaid_amount'),
            identifier: 'montant-impaye-du-compte',
        }, {
            name: __('common:count_unpaid_amount'),
            identifier: 'nombre-de-factures-impayees-du-compte',
        }, {
            name: __('common:account_balance'),
            identifier: 'solde-du-compte',
        }, {
            name: __('common:credit_amounts'),
            identifier: 'montant-des-avoirs-du-compte',
        }, {
            name: __('common:credit_count'),
            identifier: 'nombre-d-avoirs-du-compte',
        }, {
            name: __('common:uncollectible_invoice_count'),
            identifier: 'nombre-de-factures-irrecouvrables-du-compte',
        }, {
            name: __('billing_invoice:period_amount_with_currency'),
            identifier: 'montant-sur-la-periode-avec-devise',
        }];
    }
})(jQuery);
