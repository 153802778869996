import type {Collection, ModelCollection} from '@meekohq/lumos';
import type {Ref} from 'vue';
import {computed, set, watch} from 'vue';
import type CustomerModel from '@/modules/cashier/models/CustomerModel';
import type KidModel from '@/modules/family/models/KidModel';

/**
 * Composable to manage the selection of customers to send tax certificates
 *
 * @param customers
 */
export default function(customers: Ref<Collection<CustomerModel>>) {
    const selectedCustomers = computed(() => customers.value.filter(customer => customer.extra.selected));

    /**
     * Format the customers collection to add extra data
     *
     * @param customers
     */
    function customersFormatStrategy(customers: Ref<Collection<CustomerModel>>) {
        customers.value.all().forEach(customer => {
            set(customer.extra, 'selected', false);

            // Concat all members and contacts
            const sentTo: Array<{ name: string; email: string }> = [];

            customer.contacts().value().filter(contact => !!contact.attributes.email).each(contact => {
                sentTo.push({
                    name: contact.attributes.first_name + ' ' + contact.attributes.last_name,
                    email: contact.attributes.email as string,
                });

            });

            customer.families().value().each(family => {
                family.members().value().filter(member =>
                    !!member.attributes.email,
                ).each(member => {
                    sentTo.push({
                        name: member.attributes.first_name + ' ' + member.attributes.last_name,
                        email: member.attributes.email as string,
                    });
                });
            });

            set(customer.extra, 'sentTo', sentTo);
        });
    }

    /**
     * Toggle the selection of a kid
     *
     * @param kidModel
     * @param customerModel
     * @param selected
     */
    function toggleKid(kidModel: KidModel, customerModel: CustomerModel, selected: boolean) {
        customers.value.filter(customer => customer.getKey() === customerModel.getKey()).all().forEach(customer => {
            customer.families().value().all().forEach(family => {
                family.kids().mutate(kids => kids.map(kid => {
                    if (kid.getKey() === kidModel.getKey()) {
                        set(kid.extra, 'selected', selected);
                    }

                    return kid;
                }) as ModelCollection<KidModel>);
            });
        });

        // Select customer if not already selected
        if (selected && !customerModel.extra.selected) {
            toggleCustomer(customerModel, selected);
        } else {
            // Unselect customer only if it has not other selected kid
            const hasSelectedKid = customers.value.filter(customer => {
                return customer.getKey() === customerModel.getKey()
                    && customer.families().value().filter(family => {
                        return family.kids().value().where('extra.selected', '===', true).isNotEmpty();
                    }).isNotEmpty();
            }).isNotEmpty();

            if (!hasSelectedKid) {
                toggleCustomer(customerModel, selected);
            }
        }
    }

    /**
     * Toggle the selection of a customer
     *
     * @param customerModel
     * @param selected
     */
    function toggleCustomer(customerModel, selected: boolean) {
        customers.value.filter(customer => customer.getKey() === customerModel.getKey()).all().forEach(customer => {
            set(customer.extra, 'selected', selected);
        });
    }

    /**
     * Toggle the selection of all customers
     *
     * @param selected
     */
    function toggleAll(selected: boolean) {
        customers.value.all().forEach(customer => {
            customer.extra.selected = selected;
            customer.families().value().all().forEach(family => {
                family.kids().mutate(kids => kids.map(kid => {
                    set(kid.extra, 'selected', selected);

                    return kid;
                }) as ModelCollection<KidModel>);
            });
        });
    }

    watch(customers, () => {
        customersFormatStrategy(customers);
    });

    return {
        customersFormatStrategy,
        selectedCustomers,
        toggleAll,
        toggleCustomer,
        toggleKid,
    };
}
