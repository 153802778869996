<template>
    <div
        class="TaxCertificates__card-container"
        @click="$emit('toggleCustomer')"
    >
        <CCard
            body-class="TaxCertificates__card-body"
            body-size="xs"
            :class="{'TaxCertificates__card-body--is-selected': customer.extra.selected}"
        >
            <div class="TaxCertificates__card-body-content">
                <div>
                    <FontAwesomeIcon
                        v-if="customer.attributes.person === 'legal'"
                        class="TaxCertificates__icon"
                        :class="customer.extra.selected ? 'tw-text-white' : 'tw-text-blue-600'"
                        icon="fad fa-city fa-fw"
                    />
                    <FontAwesomeIcon
                        v-else
                        class="TaxCertificates__icon"
                        :class="customer.extra.selected ? 'tw-text-white' : 'tw-text-blue-500'"
                        icon="fas fa-user fa-fw"
                    />
                </div>
                <div class="TaxCertificates__informations">
                    <CText
                        as="div"
                        class="TaxCertificates__name"
                        font-weight="semibold"
                        :variant="customer.extra.selected ? 'white' : 'black'"
                    >
                        {{ customer.attributes.name }}
                    </CText>
                    <CText
                        v-if="!customer.extra.sentTo || customer.extra.sentTo.length === 0"
                        as="div"
                        class="tw-tracking-wider"
                        font-size="sm"
                        font-weight="semibold"
                        uppercase
                        :variant="customer.extra.selected ? 'white' : 'warning'"
                    >
                        <FontAwesomeIcon icon="fad fa-exclamation-triangle"/>
                        {{ __('common:emails_count', {count: 0}) }}
                    </CText>
                    <MTooltip v-else>
                        <CText
                            as="div"
                            font-size="sm"
                            font-weight="semibold"
                            :variant="customer.extra.selected ? 'white' : 'black'"
                        >
                            {{ __('common:emails_count', {count: customer.extra.sentTo.length}) }}
                        </CText>
                        <template #content>
                            <div
                                v-for="(sentTo, j) in customer.extra.sentTo"
                                :key="'email-' + j"
                                class="TaxCertificates__mail"
                            >
                                {{ sentTo.email }}
                            </div>
                        </template>
                    </MTooltip>
                </div>
            </div>
        </CCard>
    </div>
</template>

<script lang="ts">
    export default {
        props: {
            customer: {
                type: Object,
                required: true,
            },
        },
    };
</script>

<style lang="scss" scoped>
    .TaxCertificates__card-container {
        @apply tw-cursor-pointer;
    }

    .TaxCertificates__card-body {
        @apply tw-flex tw-items-center;
        @apply tw-truncate;
    }

    .TaxCertificates__card-body--is-selected {
        @apply tw-bg-blue-500 tw-border-blue-500 tw-text-white;
    }

    .TaxCertificates__card-body-content {
        @apply tw-flex tw-items-center;
        @apply tw-h-14;
    }

    .TaxCertificates__informations {
        @apply tw-ml-3 tw-min-w-0;
    }

    .TaxCertificates__icon {
        @apply tw-flex-shrink-0 tw-text-2xl;
    }

    .TaxCertificates__name {
        @apply tw-capitalize tw-truncate;
    }

    .TaxCertificates__mail {
        @apply tw-lowercase tw-truncate;
    }
</style>
