<template>
    <div class="TransactionListItemInvoicePopoverHeader">
        <MHeading level="h2">
            {{ allocation.extra.invoice.attributes.no }}
        </MHeading>
        <InvoiceStatusBadge :invoice="invoice"/>
    </div>
</template>

<script lang="ts">
    import type {PropType} from 'vue';
    import {defineComponent, ref} from 'vue';
    import type AllocationModel from '@/modules/cashier/models/AllocationModel';
    import InvoiceStatusBadge from '@/modules/cashier/components/billing/invoice/atoms/InvoiceStatusBadge.vue';

    export default defineComponent({
        components: {InvoiceStatusBadge},
        props: {
            allocation: {
                type: Object as PropType<AllocationModel>,
                required: true,
            },
        },
        setup(props) {
            const invoice = ref(props.allocation.extra.invoice);

            return {
                invoice,
            };
        },
    });
</script>

<style lang="scss" scoped>
    .TransactionListItemInvoicePopoverHeader {
        @apply tw-flex tw-items-center tw-justify-between tw-gap-4;
    }
</style>
