import AutoNumeric from 'autonumeric';
import useManager from '@/modules/app/composables/useManager';
import type {MaybeRef, MaybeRefOrGetter} from '@vueuse/core';
import {toValue} from '@vueuse/core';

enum Precision {
    short = 2,
    long = 4
}

const countriesFormatting = {
    FR: {
        digitGroupSeparator: ' ',
        decimalCharacter: ',',
        decimalCharacterAlternative: '.',
        currencySymbolPlacement: 's',
        negativePositiveSignPlacement: 'p',
        currencySeparator: '\u202f',
    },
    CH: {
        digitGroupSeparator: '\'',
        decimalCharacter: '.',
        decimalCharacterAlternative: ',',
        currencySymbolPlacement: 's',
        negativePositiveSignPlacement: 'p',
        currencySeparator: '\u202f',
    },
};

const currenciesFormatting = {
    EUR: '€',
};

/**
 * The purpose of this composable is to get a specific number format from an amount. It can handle currency and country.
 * Example :
 * const {format} = useFormatNumber('EUR', 'FR')
 * console.log(format(1125.5))  // 1 125,5 €
 */
export default function(currencyRef?: MaybeRefOrGetter<string | undefined>, countryCodeRef?: MaybeRef<string>) {
    // Unwrap possible ref
    const currency = toValue(currencyRef);
    const currencyFormat = getCurrencyFormatting(currency);

    let countryCode = toValue(countryCodeRef);
    const countryFormat = getCountryFormatting(countryCode);

    let instance: AutoNumeric;
    const activeOrganization = useManager().activeOrganization;


    countryCode = countryCode ?? activeOrganization.value?.attributes.address.country_code;

    function getCountryFormatting(countryCode?: string | undefined) {
        if (countryCode) {
            return countriesFormatting[countryCode.toUpperCase()] ?? countriesFormatting['FR'];
        }

        return countriesFormatting['FR'];
    }

    function getCurrencyFormatting(currency: string | undefined) {
        if (!currency) {
            return undefined;
        }

        const symbol = currenciesFormatting[currency] ?? currency;

        return {
            name: currency,
            symbol: symbol.length ? symbol : undefined,
        };
    }

    function mapIsoCodeToAutoNumericPresetName(isoCode: string): string {
        switch (isoCode.toUpperCase()) {
            case 'US':
            case 'USD':
                return 'dollar';
            case 'UK':
            case 'GBP':
                return 'British';
            case 'CH':
            case 'CHF':
                return 'Swiss';
            case 'EUR':
            default:
                return 'euro';
        }
    }

    /**
     * Compute the symbol of the preset. If the currency is defined, we use the configured symbol for this currency.
     * Otherwise, we use the country symbol. Defaults to '' if nothing is defined.
     * @param preset
     */
    function computeSymbol(preset: AutoNumeric.Options) {
        const currencySymbol = currencyFormat?.symbol ?? preset.currencySymbol;

        return currency ? currencySymbol : '';
    }

    /**
     * Defaults to French.
     *
     */
    function getAutoNumericOptions(longPrecision: boolean) {
        const isoCode = currencyFormat?.name ?? countryCode;

        const presetName = mapIsoCodeToAutoNumericPresetName(isoCode ?? 'EUR');

        const basePreset: AutoNumeric.Options = AutoNumeric.getPredefinedOptions()[presetName];

        const preset = {
            ...basePreset,
            decimalPlaces: longPrecision ? Precision.long : Precision.short,
            digitGroupSeparator: countryFormat.digitGroupSeparator ?? basePreset.digitGroupSeparator,
            decimalCharacter: countryFormat.decimalCharacter ?? basePreset.decimalCharacter,
            currencySymbolPlacement: countryFormat.currencySymbolPlacement ?? basePreset.currencySymbolPlacement,
            negativePositiveSignPlacement: countryFormat.negativePositiveSignPlacement ?? basePreset.negativePositiveSignPlacement,
            decimalCharacterAlternative: countryFormat.decimalCharacterAlternative ?? basePreset.decimalCharacterAlternative,
            currencySymbol: computeSymbol(basePreset),
            emptyInputBehavior: 'null',
        };

        // AutoNumeric doesn't support separator between currency and number, so we add it manually
        if (preset.currencySymbolPlacement === 's') {
            preset.currencySymbol = countryFormat.currencySeparator + preset.currencySymbol;
        } else {
            preset.currencySymbol = preset.currencySymbol + countryFormat.currencySeparator;
        }

        return preset;
    }

    /**
     * Function wich handle the format of an amount.
     * You can change the format of an amount with options and locale parameter.
     *
     * Example :
     * const {format} = useFormatNumber('FR', 'EUR')
     * format(1250.5) => 1.250,5 EUR
     */
    function format(amount: string|number, longPrecision = false): string {
        return AutoNumeric.format(amount, getAutoNumericOptions(longPrecision));
    }

    function humanize(amount: number, longPrecision = false): string {
        // Truncate number to the right precision
        const precision = longPrecision ? Precision.long : Precision.short;
        const rawValueToString = AutoNumeric.format(amount, {
            decimalPlaces: precision,
        });

        // Remove trailing zeros
        const rawValue = parseFloat(rawValueToString.replace(',', '.'));

        // If number is an integer, we don't need to display decimals
        if (rawValue % 1 === 0) {
            return rawValue.toString();
        }

        // If number has 1 or 2 decimals, we return the best effort short precision
        if (rawValue.toString().split('.')[1].length <= 2) {
            return AutoNumeric.format(rawValue, {
                decimalPlaces: Precision.short,
            });
        }

        // If number has more than 2 decimals, we return the best effort long precision
        return AutoNumeric.format(rawValue, {
            decimalPlaces: Precision.long,
        });
    }

    function bindToInput(
        elementOrSelector: string | HTMLInputElement | HTMLElement,
        initialValue?: string | number | null,
        longPrecision = false,
    ) {
        instance = new AutoNumeric(elementOrSelector, initialValue, getAutoNumericOptions(longPrecision));

        return instance;
    }

    function set(newValue: string | number | null, options?: AutoNumeric.Options | undefined, saveChangeToHistory?: boolean | undefined): void {
        instance.set(newValue, options, saveChangeToHistory);
    }

    function getNumber(): number | null {
        return instance.getNumber();
    }

    function getInstance(): AutoNumeric {
        return instance;
    }

    function getSymbol(): string {
        return getAutoNumericOptions(false).currencySymbol.replace(' ', '');
    }

    function setReadonly(isReadonly: boolean) {
        instance.update({readOnly: isReadonly});
    }

    return {
        bindToInput,
        format,
        humanize,
        getNumber,
        getInstance,
        getSymbol,
        set,
        setReadonly,
    };
}
