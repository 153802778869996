<template>
    <CVStack
        class="tw-shadow-md tw-bg-gradient-to-br tw-from-cyan-600 tw-to-blue-600 tw-rounded-xl"
        style="max-width: 400px;"
    >
        <CVStack
            class="tw-p-4 tw-text-white"
            gap="4"
        >
            <CHeading class="tw-text-center !tw-mb-0 tw-tracking-wide">
                {{ __('subscription:all_inclusive') }}
            </CHeading>
            <div class="tw-h-1 tw-bg-white tw-bg-opacity-50 tw-rounded-full"/>
            <div>
                <CVStack align="center">
                    <span
                        class="tw-font-display tw-text-5xl tw-leading-none"
                        v-html="__('subscription:price')"
                    />
                    <small class="small tw-text-2xl tw-leading-none">{{ __('subscription:vat_per_month') }}</small>
                </CVStack>
            </div>
            <div class="tw-h-1 tw-bg-white tw-bg-opacity-50 tw-rounded-full"/>
            <CVStack
                align="center"
                gap="2"
            >
                <span class="tw-text-xl tw-leading-none">{{ __('subscription:all_management_models') }}</span>
                <span class="tw-text-sm">{{ __('subscription:planning_billing_website_and_more') }}</span>
            </CVStack>
            <div class="tw-h-1 tw-bg-white tw-bg-opacity-50 tw-rounded-full"/>
            <CVStack
                align="center"
                gap="2"
            >
                <span class="tw-text-xl tw-leading-none">{{ __('subscription:all_tools_for_your_team') }}</span>
                <span class="tw-text-sm">{{ __('subscription:activities_check_ins_meals_naps_and_more') }}</span>
            </CVStack>
            <div class="tw-h-1 tw-bg-white tw-bg-opacity-50 tw-rounded-full"/>
            <CVStack
                align="center"
                gap="2"
            >
                <span class="tw-text-xl tw-leading-none">{{ __('subscription:a_family_portal_dedicated_to_parents') }}</span>
                <span class="tw-text-sm">{{ __('subscription:transmissions_photos_reservations_absences_and_more') }}</span>
            </CVStack>
            <div class="tw-h-1 tw-bg-white tw-bg-opacity-50 tw-rounded-full"/>
            <template v-if="!legacyUser.is_owner">
                <CText
                    align="center"
                    class="tw-text-red-800"
                    font-size="lg"
                >
                    {{ __('subscription:log_in_with_admin_to_take_subscription') }}
                </CText>
                <CButton
                    class="tw-text-center tw-border-none"
                    disabled
                    size="lg"
                >
                    {{ __('subscription:monthly_full_price') }}
                </CButton>
                <CButton
                    class="tw-text-center tw-border-none"
                    disabled
                    size="lg"
                    variant="light"
                >
                    <CVStack
                        class="tw-py-2"
                        gap="2"
                    >
                        <div
                            class="tw-leading-none"
                            v-html="__('subscription:yearly_full_price')"
                        />
                        <small class="small tw-leading-none"><em>{{ __('subscription:save_eight_percent') }}</em></small>
                    </CVStack>
                </CButton>
            </template>
            <template v-else>
                <CButton
                    class="tw-text-center tw-border-none"
                    size="lg"
                    :to="{name: 'subscriptions.subscribe', params:{nursery:nursery.id, plan:'monthly-99-2018'}}"
                >
                    {{ __('subscription:monthly_full_price') }}
                </CButton>
                <CButton
                    class="tw-text-center tw-border-none"
                    size="lg"
                    :to="{name: 'subscriptions.subscribe', params:{nursery:nursery.id, plan:'yearly-1188-2018'}}"
                    variant="light"
                >
                    <CVStack
                        class="tw-py-2"
                        gap="2"
                    >
                        <div
                            class="tw-leading-none"
                            v-html="__('subscription:yearly_full_price')"
                        />
                        <small class="small tw-leading-none"><em>{{ __('subscription:save_eight_percent') }}</em></small>
                    </CVStack>
                </CButton>
            </template>
            <div
                v-if="customer.is_premium"
                class="small mb-0 mt-3"
            >
                <div>{{ __('subscription:monthly_premium_discount') }}</div>
                <div>{{ __('subscription:yearly_premium_discount') }}</div>
            </div>
        </CVStack>
    </CVStack>
</template>

<script lang="ts">
    import {defineComponent} from 'vue';
    import useAuth from '@/modules/app/composables/useAuth';

    export default defineComponent({
        props: {
            customer: {type: Object, required: true},
            nursery: {type: Object, required: true},
        },
        setup() {
            const {legacyUser} = useAuth();

            return {legacyUser};
        },
    });
</script>

<style
    lang="scss"
    scoped
>
    //
</style>
