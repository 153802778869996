<template>
    <div>
        <CDropdownMenu
            ref="menu"
            placement="left"
        >
            <template #button>
                <CButton
                    icon-ellipsis
                    size="sm"
                    variant="ghost"
                />
            </template>
            <CDropdownMenuItem
                icon="fas fa-pen"
                @click="showUpdateModal = true"
            >
                {{ __('common:actions.update') }}
            </CDropdownMenuItem>
            <template v-if="!eventType.attributes.internal_id">
                <CDropdownMenuDivider/>
                <template>
                    <CDropdownMenuItem
                        v-if="!isPivotArchived"
                        icon="fas fa-archive"
                        @click="toggleArchive()"
                    >
                        <span>{{ __('common:actions.archive') }}</span>
                    </CDropdownMenuItem>
                    <CDropdownMenuItem
                        v-else
                        icon="fas fa-archive"
                        @click="toggleArchive()"
                    >
                        <span>{{ __('common:actions.unarchive') }}</span>
                    </CDropdownMenuItem>
                </template>
                <template
                    v-if="user.is_owner && isUsedInAnotherOrganization"
                >
                    <CDropdownMenuItem
                        v-if="!isPivotArchived"
                        icon="fas fa-archive"
                        @click="toggleArchiveForAll"
                    >
                        <span>{{ __('common:actions.archive_of_all_organizations') }}</span>
                    </CDropdownMenuItem>
                    <CDropdownMenuItem
                        v-else
                        icon="fas fa-archive"
                        @click="toggleArchiveForAll"
                    >
                        <span>{{ __('common:actions.unarchive_of_all_organizations') }}</span>
                    </CDropdownMenuItem>
                </template>
                <template v-if="!hasEvents">
                    <CDropdownMenuItem
                        icon="fas fa-trash"
                        variant="danger"
                        @click="deleteEventType()"
                    >
                        <span>{{ __('common:actions.delete') }}</span>
                    </CDropdownMenuItem>
                </template>
                <template v-if="user.is_owner && isUsedInAnotherOrganization && !hasEvents">
                    <CDropdownMenuItem
                        icon="fas fa-trash"
                        variant="danger"
                        @click="$refs.deleteModal.show()"
                    >
                        <span>{{ __('common:actions.delete_of_all_organizations') }}</span>
                    </CDropdownMenuItem>
                </template>
            </template>
            <template #extra>
                <UpdateEventTypeModal
                    v-if="showUpdateModal"
                    ref="updateEventTypeModal"
                    :model="eventType"
                    :shared="isUsedInAnotherOrganization"
                    @hide="showUpdateModal = !showUpdateModal"
                />
                <DeleteEventTypeModal
                    ref="deleteModal"
                    :model="eventType"
                    :shared="isUsedInAnotherOrganization"
                    @deleted="$emit('deleted', $event)"
                />
            </template>
        </CDropdownMenu>
    </div>
</template>

<script lang="ts">
    import moment from 'moment';
    import type EventTypeModel from '@/modules/human-resources/models/EventTypeModel';
    import type EventTypeOrganizationModel from '@/modules/human-resources/models/EventTypeOrganizationModel';
    import type {PropType} from 'vue';
    import {defineComponent, ref} from 'vue';
    import UpdateEventTypeModal from '@/modules/human-resources/components/event-type/Modals/UpdateEventTypeModal.vue';
    import DeleteEventTypeModal from '@/modules/human-resources/components/event-type/Modals/DeleteEventTypeModal.vue';
    import useAuth from '@/modules/app/composables/useAuth';

    export default defineComponent({
        components: {DeleteEventTypeModal, UpdateEventTypeModal},
        props: {
            eventType: {required: true, type: Object as PropType<EventTypeModel>},
            activePivot: {required: true, type: Object as PropType<EventTypeOrganizationModel>},
            isPivotArchived: {required: true, type: Boolean},
            isUsedInAnotherOrganization: {required: true, type: Boolean},
            hasEvents: {required: true, type: Boolean},
        },
        setup(props, {emit}) {
            const organizationsPivot = props.eventType.organizationsPivots().value();
            const {legacyUser: user} = useAuth();
            const showUpdateModal = ref(false);

            function toggleArchive() {
                const pivot = props.activePivot;
                toggleArchiveAndSavePivot(pivot);
            }

            function toggleArchiveForAll() {
                organizationsPivot.each(pivot => {
                    toggleArchiveAndSavePivot(pivot);
                });
            }

            function toggleArchiveAndSavePivot(pivot: EventTypeOrganizationModel) {
                if (pivot.attributes.archived_at === null) {
                    pivot.attributes.archived_at = moment().toISOString(true);
                } else {
                    pivot.attributes.archived_at = null;
                }
                pivot.save();
            }

            async function deleteEventType() {
                if (props.isUsedInAnotherOrganization) {
                    // delete pivot because its used in another organization
                    await props.activePivot.delete();
                } else {
                    // delete eventType because its used only in this organization
                    await props.eventType.delete();
                }
                emit('deleted', props.eventType);
            }


            return {
                user,
                toggleArchive,
                toggleArchiveForAll,
                deleteEventType,
                showUpdateModal,
            };
        },
    });
</script>
