<template>
    <CModal
        ref="modal"
        :emitter="bus"
        :header-title="__('common:add_document')"
        :loading="isLoading"
        visible
        @hidden="$emit('hidden')"
    >
        <DocumentForm
            :displayed-visibilities="displayedVisibilities"
            :error-form="documentFormError"
            :show-organizations="showOrganizations"
            :store="documentStore"
        />
        <template #footer-end="{closeDialog}">
            <CButton
                :label="__('common:actions.cancel')"
                @click="closeDialog"
            />
            <CButton
                :loading="loading"
                variant="primary"
                @click="save"
            >
                {{ __('common:actions.add') }}
            </CButton>
        </template>
    </CModal>
</template>

<script lang="ts">
    import type {PropType} from 'vue';
    import {defineComponent, onBeforeMount, ref, toRef} from 'vue';
    import type {VisibilitiesType} from '@/modules/document/stores/useDocumentStore';
    import useDocumentStore from '@/modules/document/stores/useDocumentStore';
    import DocumentForm from '@/modules/document/components/organisms/DocumentForm.vue';
    import type {BelongsToMany, Model} from '@meekohq/lumos';
    import type UserModel from '@/modules/user/models/UserModel';
    import type OrganizationModel from '@/modules/organization/models/OrganizationModel';
    import type {Emitter} from 'mitt';
    import mitt from 'mitt';
    import type {EventType} from '@/modules/meeko-ui/utils/ModalEventType';

    export default defineComponent({
        components: {DocumentForm},
        props: {
            user: {
                type: Object as PropType<UserModel>,
                default: undefined,
            },
            activeOrganization: {
                type: Object as PropType<OrganizationModel>,
                default: undefined,
            },
            showOrganizations: {
                type: Boolean,
                default: false,
            },
            availableOrganizationsToLoad: {
                type: Object as PropType<BelongsToMany<any, OrganizationModel, any>>,
                required: true,
            },
            displayedVisibilities: {
                type: Object as PropType<VisibilitiesType>,
                required: false,
                default: () => ({
                    staffs_access: false,
                    parents_access: false,
                    web_access: false,
                }),
            },
            visibilities: {
                type: Object as PropType<VisibilitiesType>,
                required: false,
                default: () => ({
                    staffs_access: false,
                    parents_access: false,
                    web_access: false,
                }),
            },
            relatedResource: {
                type: Object as PropType<Model>,
                default: undefined,
            },
            bus: {type: Object as PropType<Emitter<EventType>>, default: () => mitt()},
        },
        setup(props, {emit}) {
            const isLoading = ref(true);

            // Instantiate the service
            const documentStore = useDocumentStore(toRef(props, 'activeOrganization'));

            // Extract the properties we need for template
            const {
                bus,
                document,
                documentFormError,
                loading,
                relatedResource,
                save,
                setAvailableOrganizations,
                visibilities,
            } = documentStore;

            // Set store values
            visibilities.value = props.visibilities;
            relatedResource.value = props.relatedResource;

            onBeforeMount(async () => {
                // Load the user organizations and set them in the available organizations of the store
                setAvailableOrganizations(await props.availableOrganizationsToLoad.load());

                isLoading.value = false;
            });

            bus.on('saved', () => emit('created', document.value));

            return {
                documentFormError,
                isLoading,
                loading,
                documentStore,
                save,
            };
        },
    });
</script>

