<template>
    <SimpleSubPageTemplate>
        <template #title>
            <MHeading>
                {{ __('common:equipment_other') }}
            </MHeading>
        </template>
        <template #actions>
            <CButton
                icon-plus
                variant="primary"
                @click="editEquipment()"
            >
                {{ __('equipment:add_equipment') }}
            </CButton>
            <EquipmentModal
                v-if="showModal"
                :loading="updating"
                :selected-equipment="selectedEquipment"
                @add="saveEquipment()"
                @closed="showModal = false"
            />
        </template>
        <template #content>
            <MagicIndex
                :loading="loading"
                :paginator="paginator"
                variant="equipment"
                @change="getEquipment($event)"
            >
                <template #header>
                    <MagicIndexHeader>
                        <MagicIndexHeaderColumn
                            :label="__('common:name')"
                            :size="{lg: 2}"
                        />
                    </MagicIndexHeader>
                </template>
                <template #item="{item}">
                    <EquipmentItem
                        :equipment="item"
                        @delete="removeEquipment(item)"
                        @edit="editEquipment(item)"
                    />
                </template>
                <template #empty-list>
                    {{ __('equipment:empty_equipment_index') }}
                </template>
                <template #empty-list-action>
                    {{ __('equipment:try_modifying_filters_or_adding_equipment') }}
                </template>
            </MagicIndex>
        </template>
        <div/>
    </simplesubpagetemplate>
</template>

<script>
    import Vue from 'vue';
    import useApi from '@/modules/app/composables/useApi';
    import route from '@/modules/legacy/libs/ziggy';
    import swal from 'sweetalert2/dist/sweetalert2.js';
    import _forEach from 'lodash-es/forEach';
    import _head from 'lodash-es/head';
    import _cloneDeep from 'lodash-es/cloneDeep';
    import MagicIndex from '@/modules/magic-index/components/organisms/MagicIndex.vue';
    import EquipmentItem from '@/modules/equipment/components/molecules/EquipmentItem.vue';
    import EquipmentModal from '@/modules/equipment/components/molecules/EquipmentModal.vue';
    import {LengthAwarePaginator} from '@meekohq/lumos';
    import {url} from '@/modules/legacy/mixins/url';
    import __ from '@/modules/app/utils/i18n-facade';
    import SimpleSubPageTemplate from '@/modules/app/components/templates/SimpleSubPageTemplate.vue';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';
    import MagicIndexHeaderColumn from '@/modules/magic-index/components/atoms/MagicIndexHeaderColumn.vue';
    import MagicIndexHeader from '@/modules/magic-index/components/atoms/MagicIndexHeader.vue';

    export default {
        components: {
            MagicIndexHeader,
            MagicIndexHeaderColumn,
            SimpleSubPageTemplate, EquipmentModal, EquipmentItem, MagicIndex,
        },
        mixins: [url],
        props: {
            nursery: {
                type: Object,
                required: true,
            },
        },
        data: () => ({
            equipment: [],
            selectedEquipment: {},
            loading: false,
            updating: false,
            paginator: undefined,
            showModal: false,
        }),
        watch: {
            paginator() {
                this.pushQueryString('p', this.paginator.currentPage());
            },
        },
        beforeMount() {
            this.getEquipment(this.$route.query.p);
        },
        methods: {
            getEquipment(page = 1, perPage = 20) {
                this.loading = true;
                useApi().legacy.get(route('equipments.index', {
                    nursery_id: this.nursery.id,
                    page,
                    per_page: perPage,
                })).then(response => {
                    this.equipment = response.data.data;
                    this.paginator = new LengthAwarePaginator(
                        response.data.data,
                        parseInt(response.data.total),
                        parseInt(response.data.per_page),
                        parseInt(response.data.current_page),
                        parseInt(response.data.last_page),
                    );
                }).catch(error => {
                    if (error && error.response && error.response.status === 422) {
                        _forEach(error.response.data.errors, value => {
                            useNotification().error(_head(value));
                        });
                    } else {
                        useNotification().error(error);
                    }
                }).finally(() => this.loading = false);
            },

            editEquipment(equipment) {
                if (equipment) {
                    this.selectedEquipment = _cloneDeep(equipment);
                } else {
                    this.selectedEquipment = {
                        id: null,
                        name: null,
                        nurseries: [{
                            id: this.nursery.id,
                            pivot: {},
                        }],
                    };
                }
                this.showModal = true;
            },

            removeEquipment(equipment) {
                swal({
                    title: __('equipment:delete_equipment_ask'),
                    text: __('equipment:delete_equipment_explanation'),
                    type: 'warning',
                    confirmButtonClass: 'btn btn-danger mr-2',
                    confirmButtonText: __('common:actions.delete'),
                    cancelButtonText: __('common:actions.cancel'),
                }).then(result => {
                    if (result.value) {
                        useApi().legacy.delete(route('equipments.destroy', {
                            equipment: equipment.id,
                        })).then(() => {
                            const eqToDelete = this.equipment.find(item => item.id === equipment.id);
                            if (eqToDelete) {
                                const index = this.equipment.indexOf(eqToDelete);
                                this.equipment.splice(index, 1);
                                useNotification().success(__('equipment:equipment_deleted_successfully'));
                            }
                        }).catch(error => {
                            if (error && error.response && error.response.status === 422) {
                                _forEach(error.response.data.errors, value => {
                                    useNotification().error(_head(value));
                                });
                            } else {
                                useNotification().error(error);
                            }
                        });
                    }
                });
            },

            saveEquipment() {
                this.updating = true;

                if (this.selectedEquipment.id) {
                    this.selectedEquipment.nursery_id = this.nursery.id;
                    useApi().legacy.put(route('equipments.update', {
                        id: this.selectedEquipment.id,
                    }), this.selectedEquipment)
                        .then(response => {
                            const eqToUpdate = this.equipment.find(item => item.id === this.selectedEquipment.id);
                            if (eqToUpdate) {
                                const index = this.equipment.indexOf(eqToUpdate);
                                Vue.set(this.equipment, index, response.data);
                                useNotification().success(__('equipment:equipment_updated_successfully'));
                            }

                            this.updating = false;
                            this.showModal = false;
                        }).catch(error => {
                            this.updating = false;
                            if (error && error.response && error.response.status === 422) {
                                _forEach(error.response.data.errors, value => {
                                    useNotification().error(_head(value));
                                });
                            } else {
                                useNotification().error(error);
                            }
                        });
                } else {
                    useApi().legacy.post(route('equipments.store'), this.selectedEquipment)
                        .then(response => {
                            this.equipment.push(response.data);
                            this.updating = false;
                            useNotification().success(__('equipment:equipment_added_successfully'));
                            this.showModal = false;
                        }).catch(error => {
                            this.updating = false;
                            if (error && error.response && error.response.status === 422) {
                                _forEach(error.response.data.errors, value => {
                                    useNotification().error(_head(value));
                                });
                            } else {
                                useNotification().error(error);
                            }
                        });
                }
            },
        },
    };
</script>
