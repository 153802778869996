<template>
    <button
        class="AuthButton"
        :disabled="isLoading"
        type="submit"
    >
        {{ label }}
        <FontAwesomeIcon
            v-if="isLoading"
            class="AuthButton--is-loading"
            icon="far fa-spinner-third fa-spin"
        />
        <FontAwesomeIcon
            v-else
            class="AuthButton--is-not-loading"
            icon="far fa-arrow-alt-circle-right"
        />
    </button>
</template>

<script lang="ts">
    import {defineComponent} from 'vue';

    export default defineComponent({
        components: {},
        props: {
            isLoading: {type: Boolean, required: false, default: false},
            label: {type: String, required: true, default: 'Bouton'},
            isCardButton: {type: Boolean, required: false, default: false},
        },
        setup() {
            return {};
        },
    });
</script>

<style lang="scss" scoped>
    .AuthButton {
        @apply tw-relative;
        @apply tw-bg-primary-500;
        @apply tw-text-white tw-font-semibold tw-text-lg;
        @apply tw-h-12;
        @apply tw-w-full;
        @apply tw-flex tw-justify-center tw-items-center;
        @apply tw-border-t-2 tw-border-primary-700;
    }

    .AuthButton--is-not-loading {
        @apply tw-absolute tw-right-4;
        @apply tw-text-primary-700 tw-text-3xl;
    }

    .AuthButton--is-loading {
        @apply tw-absolute tw-right-4;
        @apply tw-text-white tw-text-3xl;
    }
</style>
