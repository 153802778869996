import type {Ref} from 'vue';
import {computed} from 'vue';
import type {LegacyInvoiceType} from '@/modules/cashier/utils/billing/invoice/LegacyInvoiceType';
import InvoiceModel from '@/modules/cashier/models/InvoiceModel';
import InvoiceStatusValue from '@/modules/cashier/utils/billing/invoice/InvoiceStatusValue';
import {isNil} from 'lodash-es';

// Status rajouté pour le front
enum AdditionnalInvoiceStatuses {
    editing = 'editing',
}

export const invoiceStatuses = {
    ...InvoiceStatusValue,
    ...AdditionnalInvoiceStatuses,
};

export type InvoiceStatusType = InvoiceStatusValue | AdditionnalInvoiceStatuses;

export function useInvoiceStatusState(invoice: Ref<InvoiceModel | LegacyInvoiceType>) {
    const invoiceStatus = computed<InvoiceStatusType>(() => {
        if (invoice.value instanceof InvoiceModel) {
            if (invoice.value.isEditing) {
                return invoiceStatuses.editing;
            }

            return invoiceStatuses[invoice.value.attributes.status ?? 'draft'];
        }

        if (invoice.value.status === 'draft' && !isNil(invoice.value.increment)) {
            return invoiceStatuses.editing;
        }

        return invoiceStatuses[invoice.value.status];
    });

    return {
        invoiceStatus,
    };
}
