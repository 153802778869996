import {type Ref, ref, unref, watch} from 'vue';
import {app} from '@meekohq/lumos';
import {debounce} from 'lodash-es';
import CurrencyModel from '@/modules/cashier/models/CurrencyModel';
import type {TransactionListFiltersType} from '@/modules/cashier/transaction/infrastructure/components/useFiltersState';
import useConcurrentCallback from '@/modules/app/composables/useConcurrentCallback';
import {Types} from '@/types';
import type {
    SumTransactionsByCurrenciesUseCase,
} from '@/modules/cashier/transaction/application/use-cases/SumTransactionsByCurrenciesUseCase';
import {TransactionFilterDto} from '@/modules/cashier/transaction/application/dto/TransactionFilterDto';

export default function useSumTransactionsTotalByCurrencies(options: Ref<Partial<TransactionListFiltersType>>) {
    const totalAmountByCurrency: Ref<Array<{ code: string, amount: number }>> = ref([]);
    const isLoading = ref(false);

    const {resolveLastCallback} = useConcurrentCallback();

    /**
     * Fetch the total amount by currency when the options change. We group the currencies by code
     * as multiple currencies can have the same code. We then fetch the total amount for each currency.
     * Debounce the fetch method to avoid multi queries.
     */
    const fetchTotalAmountByCurrency = debounce(async () => {
        isLoading.value = true;

        const optionsUnref = unref(options);

        try {
            totalAmountByCurrency.value = await resolveLastCallback(async () => {
                const currenciesQuery = CurrencyModel.query();

                if (optionsUnref.tenants?.length) {
                    currenciesQuery.whereIn('tenant_id', optionsUnref.tenants.map(tenant => tenant.getKey()));
                }

                const currencies = await currenciesQuery.get();

                const dto = TransactionFilterDto.make({
                    period: optionsUnref.period,
                    amountMin: optionsUnref.amountMin,
                    amountMax: optionsUnref.amountMax,
                    tenants: optionsUnref.tenants,
                    customers: optionsUnref.customers,
                    invoiceIds: optionsUnref.invoiceIds,
                    transactionIds: optionsUnref.transactionIds,
                    paymentMethodName: optionsUnref.paymentMethodName,
                    hasRemainingAmount: optionsUnref.hasRemainingAmount,
                    search: optionsUnref.search,
                    orderBy: optionsUnref.orderBy,
                    queryDirection: optionsUnref.queryDirection,
                });

                return app().make<SumTransactionsByCurrenciesUseCase>(Types.SumTransactionsByCurrenciesUseCase)
                    .sumByCurrenciesAndFilters(
                        currencies.toArray(),
                        dto,
                    );
            });

            isLoading.value = false;

            return true;
        } catch (e) {
            // We don't do anything here, the result is outdated
            return false;
        }
    }, 500, {leading: true, trailing: true});

    /**
     * Watch for options changes to paginate the transactions.
     */
    function watchOptions() {
        watch(options, () => fetchTotalAmountByCurrency(), {immediate: true, deep: true});
    }

    return {
        totalAmountByCurrency,
        fetchTotalAmountByCurrency,
        isLoading,
        watchOptions,
    };
}
