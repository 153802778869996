<template>
    <div>
        <CButton
            icon-sort
            @click="$refs.contractStateSelector.toggle()"
        >
            <i
                v-if="value"
                aria-hidden="true"
                class="fa tw-mr-1"
                :class="selectedOption.classes"
            />
            <template v-if="value === 'pending'">
                {{ __('common:pending') }}
            </template>
            <template v-else-if="value === 'accepted_with_draft_contract'">
                {{ __('registration:registration_status_accepted_with_draft_contract') }}
            </template>
            <template v-else-if="value === 'rejected'">
                {{ __('registration:registration_status_rejected') }}
            </template>
        </CButton>
        <CDropdown
            ref="contractStateSelector"
            legacy-mode
            placement="bottom"
        >
            <CList>
                <CListRow
                    v-for="option in options"
                    :key="option.state"
                    :clickable="true"
                    @click="updateState(option.state)"
                >
                    <CHStack
                        align="center"
                        gap="2"
                    >
                        <i
                            aria-hidden="true"
                            class="fa"
                            :class="option.classes"
                        />
                        <CText class="tw-whitespace-nowrap">
                            {{ option.text }}
                        </CText>
                    </CHStack>
                </CListRow>
            </CList>
        </CDropdown>
    </div>
</template>

<script lang="ts">
    import {computed, defineComponent, ref} from 'vue';
    import __ from '@/modules/app/utils/i18n-facade';

    export default defineComponent({
        props: {
            value: {
                type: String,
                required: true,
            },
        },
        setup(props, {emit}) {
            const contractStateSelector = ref();

            const options = [
                {
                    state: 'pending',
                    classes: ['fa-clock', 'tw-text-warning-500'],
                    text: __('common:pending'),
                },
                {
                    state: 'accepted_with_draft_contract',
                    classes: ['fa-check', 'tw-text-success-500'],
                    text: __('registration:registration_status_accepted_with_draft_contract'),
                },
                {
                    state: 'rejected',
                    classes: ['fa-archive', 'tw-text-danger-500'],
                    text: __('registration:registration_status_rejected'),
                },
            ];

            const selectedOption = computed(() => options.find(option => option.state === props.value));

            function updateState(state: string) {
                emit('input', state);
                contractStateSelector.value?.toggle();
            }

            return {
                options,
                contractStateSelector,
                selectedOption,
                updateState,
            };
        },
    });
</script>
