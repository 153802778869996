<script setup lang="ts">
    import {inject, onMounted, ref, watch} from 'vue';
    import type MFloatingContext from '@/modules/meeko-ui/components/MFloatingContext';
    import {useElementHover, useFocusWithin} from '@vueuse/core';

    const context = inject<ReturnType<typeof MFloatingContext>>('MFloatingContext');

    if (!context) {
        throw new Error('MMenuButton must be used inside a MMenu');
    }

    const {
        togglePopover,
        setHovering,
        isOpen,
        trigger,
        buttonId,
        panelId,
        buttonEl,
        openPopover,
        closePopover,
        hasOverlay,
    } = context;

    const myButton = ref();

    onMounted(() => {
        buttonEl.value = myButton.value;
    });

    if (trigger.value === 'hover') {
        const isHovered = useElementHover(myButton);
        watch(isHovered, setHovering);

        const {focused} = useFocusWithin(myButton);
        watch(focused, () => {
            if (focused.value) {
                openPopover();
            } else {
                closePopover();
            }
        });
    }

    function onClick() {
        if (trigger.value === 'click') {
            togglePopover();
        }
    }

    function onEsc() {
        if (trigger.value === 'hover') {
            closePopover();
        }
    }

    function onSpaceOrEnter() {
        if (trigger.value === 'hover') {
            openPopover();
        }
    }
</script>

<template>
    <div
        :id="buttonId"
        ref="myButton"
        :aria-controls="panelId"
        :aria-expanded="isOpen"
        aria-haspopup="dialog"
        :style="{
            zIndex: hasOverlay && isOpen ? 3060 : null,
        }"
        tabindex="-1"
        @click="onClick"
        @keydown.enter="onSpaceOrEnter"
        @keydown.esc="onEsc"
        @keydown.space="onSpaceOrEnter"
    >
        <slot :toggle="togglePopover"/>
    </div>
</template>
