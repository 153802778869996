<template>
    <MDropdown>
        <MButton
            icon-menu
            :size="size"
        >
            <template
                v-if="checkedTags && filteredTags"
                #left-icons
            >
                <FontAwesomeIcon
                    v-if="checkedTags.length === filteredTags.length && filter"
                    icon="fas fa-check"
                />
                <FontAwesomeIcon
                    v-else-if="!checkedTags.length && filter"
                    icon="far fa-ban"
                />
            </template>
            <template v-if="checkedTags && filteredTags">
                <template v-if="checkedTags.length === filteredTags.length && filter">
                    {{ __('tag:all_tags') }}
                </template>
                <template v-else-if="checkedTags.length">
                    <div class="selected-tags d-inline-flex">
                        <transition-group
                            name="list-bottom"
                            tag="div"
                        >
                            <tag-pill
                                v-for="(tag, i) in orderedCheckedTags"
                                :key="'tag-pill' + tag.id"
                                class="tag-pill"
                                :class="{'mr-2' : i !== orderedCheckedTags.length - 1}"
                                :tag="tag"
                            />
                        </transition-group>
                    </div>
                </template>
                <template v-else>
                    {{ __('tag:without_tags') }}
                </template>
            </template>
            <template v-else>
                {{ __('common:tags') }}
            </template>
        </MButton>

        <template #content>
            <template v-if="filter">
                <div class="py-0">
                    <div class="tw-flex tw-flex-row tw-items-center tw-justify-between">
                        <div class="tw-text-sm tw-text-gray-600 tw-uppercase">
                            {{ __('common:actions.filter') }}
                        </div>
                        <MTooltip
                            :label="__('tag:reset_selected_tags')"
                            placement="top"
                        >
                            <MButton
                                class="tw-text-gray-500"
                                variant="link"
                                @click="cancelTags"
                            >
                                <FontAwesomeIcon icon="fas fa-undo"/>
                            </MButton>
                        </MTooltip>
                    </div>
                </div>
                <CDropdownMenuDivider/>
                <div>
                    <div
                        class="tag-list-item my-2 px-1 pointer"
                        :class="{'selected': checkedTags && filteredTags && checkedTags.length === filteredTags.length}"
                        @click="selectAll"
                    >
                        <div class="tag-pill d-inline-flex text-center mr-2 tw-shrink-0">
                            <i
                                aria-hidden="true"
                                class="far fa-check icon w-100 justify-content-center align-self-center"
                            />
                        </div>
                        {{ __('tag:all_tags') }}
                    </div>
                    <div
                        class="tag-list-item my-2 px-1 pointer"
                        :class="{'selected': checkedTags && checkedTags.length === 0}"
                        @click="noTags"
                    >
                        <div class="tag-pill d-inline-flex text-center mr-2 tw-shrink-0">
                            <i
                                aria-hidden="true"
                                class="far fa-ban icon w-100 justify-content-center align-self-center"
                            />
                        </div>
                        {{ __('tag:without_tags') }}
                    </div>
                </div>
                <CDropdownMenuDivider/>
            </template>

            <div>
                <CCenter
                    v-if="loading"
                    class="tw-absolute tw-bg-white tw-bg-opacity-80 tw-inset-2"
                >
                    <CLoader/>
                </CCenter>
                <div
                    v-if="filteredTags && !filteredTags.length"
                    class="p-1 small tw-font-semibold"
                >
                    {{ __('tag:no_tags') }}
                </div>
                <div
                    v-for="(tag, i) in filteredTags"
                    v-else
                    :key="'filteredTags' + i"
                    class="tag-list-item my-2 px-1 pointer d-flex"
                    :class="{'selected': tag.selected}"
                    @click.prevent.stop="toggleTag(tag)"
                >
                    <div>
                        <tag-pill
                            class="tag-pill mr-1"
                            :tag="tag"
                        />
                    </div>
                    <div>
                        {{ tag.name }}
                    </div>
                </div>
            </div>
        </template>
    </MDropdown>
</template>

<script>
    import useApi from '@/modules/app/composables/useApi';
    import route from '@/modules/legacy/libs/ziggy';
    import __ from '@/modules/app/utils/i18n-facade';

    import _cloneDeep from 'lodash-es/cloneDeep';
    import _orderBy from 'lodash-es/orderBy';
    import _map from 'lodash-es/map';
    import _forEach from 'lodash-es/forEach';
    import _head from 'lodash-es/head';

    import TagPill from './TagPill.vue';
    import TagPivot from '@/modules/tag/models/TagPivot';
    import ErrorHandler from '@/modules/legacy/libs/errors/errorHandler';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';

    export default {
        components: {
            TagPill,
        },
        props: {
            tags: {
                type: Array,
                default: () => [],
            },
            taggableId: {
                type: String,
            },
            taggableType: {
                type: String,
            },
            //set this prop to a Model to work with selectedTags array of Tag models and attach using Lumos
            taggableModel: {
                type: Object,
                default: undefined,
            },
            selectedTags: {
                type: Array,
                default: () => [],
            },
            filter: {
                type: Boolean,
                default: false,
            },
            size: {
                type: String,
                default: 'sm',
            },
        },
        data: () => ({
            filteredTags: [],
            checkedTags: [],
            errorHandler: new ErrorHandler(),
            loading: false,
        }),
        computed: {
            orderedCheckedTags() {
                return _orderBy(this.checkedTags, 'name');
            },
        },
        mounted() {
            this.initTags();
        },
        methods: {
            initTags() {
                const tagsWithType = this.taggableType && this.tags ? this.tags.filter(tag => tag.type === this.taggableType) : this.tags;
                const tags = _cloneDeep(tagsWithType);

                // Set default selected tags
                let selectedTagsIds = [];

                if (this.selectedTags) {
                    selectedTagsIds = this.selectedTags;
                }

                this.filteredTags = _orderBy(tags, 'name')
                    .map(tag => {
                        const match = selectedTagsIds && selectedTagsIds.find(item => item === tag.id);
                        tag.selected = !!match;

                        return tag;
                    });

                this.checkedTags = this.filteredTags && this.selectedTags ? _cloneDeep(this.filteredTags).filter(tag => tag.selected) : null;
            },

            attachTag(tag) {
                if (this.loading) {
                    return;
                }

                this.loading = true;
                if (this.taggableModel) {
                    const tagPivot = new TagPivot();
                    //ensure value is string
                    tagPivot.attributes.account_id = tag.account_id;
                    tagPivot.attributes.tag_id = tag.id;
                    tagPivot.attributes.resource_type = this.taggableModel.getType();
                    tagPivot.attributes.resource_id = this.taggableModel[this.taggableModel.getKeyName()];
                    tagPivot
                        .save()
                        .then(() => {
                            this.addTag(tag);
                            useNotification().success(__('tag:tag_attached_with_name', {name: tag.name}));
                        })
                        .catch(error => {
                            this.errorHandler.reset(error);
                        }).finally(() => this.loading = false);
                } else {
                    useApi().legacy.post(route('tags.attach', {
                        tag: tag.id,
                    }), {
                        model_id: this.taggableId,
                        model_type: this.taggableType,
                    }).then(() => {
                        this.addTag(tag);
                        useNotification().success(__('tag:tag_attached_with_name', {name: tag.name}));
                    }).catch(error => {
                        if (error && error.response && error.response.status === 422) {
                            _forEach(error.response.data.errors, value => {
                                useNotification().error(_head(value));
                            });
                        } else {
                            useNotification().error(error);
                        }
                    }).finally(() => this.loading = false);

                }
            },

            detachTag(tag) {
                if (this.loading) {
                    return;
                }

                this.loading = true;
                useApi().legacy.post(route('tags.detach', {
                    tag: tag.id,
                }), {
                    model_id: this.taggableId,
                    model_type: this.taggableType,
                }).then(() => {
                    this.removeTag(tag);
                    useNotification().success(__('tag:tag_detached_with_name', {name: tag.name}));
                }).catch(error => {
                    if (error && error.response && error.response.status === 422) {
                        _forEach(error.response.data.errors, value => {
                            useNotification().error(_head(value));
                        });
                    } else {
                        useNotification().error(error);
                    }
                })
                    .finally(() => this.loading = false);
            },

            toggleTag(tag) {
                const isIncluded = this.checkedTags && this.checkedTags.find(item => item.id === tag.id);
                if (this.filter) {
                    isIncluded ? this.removeTag(tag) : this.addTag(tag);
                } else {
                    isIncluded ? this.detachTag(tag) : this.attachTag(tag);
                }
            },

            addTag(tag) {
                const filteredTag = this.filteredTags.find(item => item.id === tag.id);
                filteredTag ? filteredTag.selected = true : null;

                this.checkedTags === null ? this.checkedTags = [] : null;
                this.checkedTags.push(tag);

                const tags = this.checkedTags ? _map(this.checkedTags, 'id') : null;
                this.$emit('update:selectedTags', tags);
            },

            removeTag(tag) {
                const filteredTag = this.filteredTags.find(item => item.id === tag.id);
                filteredTag ? filteredTag.selected = false : null;

                const tagToDelete = this.checkedTags ? this.checkedTags.find(item => item.id === tag.id) : null;
                if (tagToDelete) {
                    const index = this.checkedTags.indexOf(tagToDelete);
                    this.checkedTags.splice(index, 1);
                }

                if (this.checkedTags && this.checkedTags.length === 0) {
                    this.cancelTags();
                } else {
                    const tags = this.checkedTags ? _map(this.checkedTags, 'id') : null;
                    this.$emit('update:selectedTags', tags);
                }
            },

            selectAll() {
                this.checkedTags = [];
                this.filteredTags.forEach(tag => {
                    this.addTag(tag);
                });
            },

            deselectAll() {
                this.checkedTags = [];
                this.filteredTags.forEach(tag => {
                    this.removeTag(tag);
                });
            },

            noTags() {
                this.deselectAll();
                this.checkedTags = [];
                this.$emit('update:selectedTags', []);
            },

            cancelTags() {
                this.checkedTags = null;
                this.filteredTags.forEach(tag => {
                    tag.selected = false;
                });
                const tags = this.checkedTags ? _map(this.checkedTags, 'id') : null;
                this.$emit('update:selectedTags', tags);
            },
        },
    };
</script>

<style lang="scss" scoped>
    .selected-tags {
        padding-left: 13px;

        .tag-pill {
            margin-left: -13px;
        }
    }

    .tag-list-item {
        font-size: 14px;
        font-weight: 600;
        color: #728497;
        opacity: .4;
        align-items: center;

        &:hover {
            opacity: .7;
        }

        &.selected {
            opacity: 1;
        }
    }
</style>
