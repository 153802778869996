<template>
    <section class="TasksTab">
        <header class="TasksTab__header">
            <div class="TasksTab__actions">
                <TasksTabActions
                    :assignees.sync="assigneesToConstrainTo"
                    :categories.sync="resourceTypesToConstrainTo"
                    :completed.sync="completedFilter"
                    :filtered-tasks-count="filteredTasksCount"
                    :user-staff="userStaff"
                    @create-task="toggleCreateTaskModal(true)"
                />
            </div>
            <CModalPortal>
                <CreateTaskModal
                    v-if="isCreateTaskModalVisible === true"
                    @hidden="toggleCreateTaskModal(false)"
                    @saved="taskSaved($event)"
                    @undo="toggleCreateTaskModal(false)"
                />
            </CModalPortal>
        </header>
        <div class="TasksTab__list">
            <template v-if="tasks.count()">
                <template v-for="(task, i) in tasks">
                    <TaskItem
                        :key="task.getKey()"
                        :can-delete-resource="false"
                        class="TasksTab__item"
                        display="item-with-modal"
                        :is-modal-editing="true"
                        :resources="resourcesByTaskId[task.getKey()]"
                        :task="task"
                        @completed="$emit('task-saved', task)"
                        @deleted="taskDeleted(task, i)"
                        @preventClickOutside="$emit('preventClickOutside', $event)"
                        @saved="$emit('task-saved', task)"
                    />
                </template>
                <CListHasMoreTrigger @isVisible="fetchNextPage"/>
            </template>
            <template v-if="loading">
                <TaskSkeleton/>
            </template>
            <template v-if="!tasks.count() && !loading">
                <CCenter class="tw-my-6">
                    <CVStack
                        align="center"
                        gap="2"
                    >
                        <CText
                            as="h1"
                            font-size="lg"
                            font-weight="semibold"
                        >
                            {{ __('activity:no_tasks_found_with_selected_filters') }}
                        </CText>
                        <CText as="h2">
                            {{ __('activity:try_modifying_the_filters_or_add_tasks') }}
                        </CText>
                    </CVStack>
                </CCenter>
            </template>
        </div>
    </section>
</template>

<script lang="ts">
    import type {Ref} from 'vue';
    import {computed, defineComponent, onBeforeMount, ref, watch} from 'vue';
    import type TicketModel from '@/modules/activity/ticket/domain/TicketModel';
    import TaskItem from '@/modules/registration/components/molecules/Timeline/TaskItem.vue';
    import {useToggle} from '@vueuse/core';
    import TasksTabActions from '@/modules/todo-widget/components/molecules/TasksTabActions.vue';
    import CreateTaskModal from '@/modules/todo-widget/components/organisms/CreateTaskModal.vue';
    import type StaffModel from '@/modules/human-resources/models/StaffModel';
    import {ModelCollection} from '@meekohq/lumos';
    import {debounce} from 'lodash-es';
    import usePaginator from '@/modules/legacy/utils/usePaginator';
    import useGetTasks from '@/modules/activity/composables/useGetTasks';
    import useUserStaff from '@/modules/human-resources/composables/staff/useUserStaff';
    import TaskSkeleton from '@/modules/todo-widget/components/molecules/TaskSkeleton.vue';
    import useTaskFilters from '@/modules/activity/composables/useTaskFilters';

    export default defineComponent({
        components: {TaskSkeleton, CreateTaskModal, TasksTabActions, TaskItem},
        props: {},
        setup(props, {emit}) {
            const [isCreateTaskModalVisible, toggleCreateTaskModal] = useToggle(false);

            const loading = ref(true);

            const {
                resourceTypesToConstrainTo,
                assigneesToConstrainTo,
                completedFilter,
            } = useTaskFilters();

            const {userStaff} = useUserStaff();

            const {ticketQuery, computeTicketQuery, waitForQueryToBeReady} = useGetTasks({
                resourceTypesToConstrainTo: (resourceTypesToConstrainTo as Ref<string[]>),
                assigneesToConstrainTo: (assigneesToConstrainTo as Ref<Array<StaffModel | {
                    id: string,
                    fullname: string
                }>>),
                constrainToCompletedTasks: (completedFilter as Ref<boolean>),
                constrainByExpiredDateLimit: ref(null),
                constrainToStaffUser: ref(false),
                withResourceOrganizations: ref(true),
            });

            const tasks = ref<ModelCollection<TicketModel>>(new ModelCollection());

            async function getTasks(page = 1, perPage = 20) {
                computeTicketQuery();

                const response = await ticketQuery.value.paginate(perPage, page);

                currentPage.value = page;
                setPaginator({
                    current_page: response.currentPage(),
                    last_page: response.lastPage(),
                    total: response.total(),
                });

                if (response.currentPage() === 1) {
                    tasks.value = response.items();
                } else {
                    response.items().each(task => tasks.value.push(task));
                }

                loading.value = false;

            }

            const debounceGetTasks = debounce(getTasks, 1500, {
                leading: false,
                trailing: true,
            });

            const {paginator, currentPage, setPaginator} = usePaginator(debounceGetTasks, false);

            const filteredTasksCount = computed(() => {
                return paginator.value?.total ?? 0;
            });

            function fetchNextPage() {
                if (loading.value || paginator.value?.lastPage === currentPage.value) {
                    return;
                }

                loading.value = true;
                // Updating the page triggers a watcher in the paginator to fetch the next page
                currentPage.value++;
            }

            const resourcesByTaskId = computed(() => {
                return tasks.value?.mapWithKeys(task => {
                    const key = task.getKey();
                    const resources = task.ticketPivots().value().map(ticketPivot => ticketPivot.resource().value()).all();

                    return [key, resources];
                }).all();
            });

            function taskSaved(task: TicketModel) {
                if (task.wasRecentlyCreated) {
                    tasks.value = tasks.value?.push(task).sortBy('attributes.expired_at');
                }

                emit('task-saved', task);
                toggleCreateTaskModal();
            }

            function taskDeleted(task: TicketModel, index: number) {
                tasks.value?.forget(index);
                emit('task-deleted', task);
            }

            const firstLoadSucceded = ref(false);

            onBeforeMount(async () => {
                await waitForQueryToBeReady();

                await getTasks();

                firstLoadSucceded.value = true;
            });

            watch([resourceTypesToConstrainTo, assigneesToConstrainTo, completedFilter], () => {
                if (!firstLoadSucceded.value) {
                    return;
                }

                loading.value = true;
                tasks.value = new ModelCollection<TicketModel>();
                debounceGetTasks();
            });

            watch(isCreateTaskModalVisible, () => emit('preventClickOutside', isCreateTaskModalVisible.value));

            return {
                ticketQuery,
                loading,
                fetchNextPage,
                resourceTypesToConstrainTo,
                assigneesToConstrainTo,
                isCreateTaskModalVisible,
                toggleCreateTaskModal,
                tasks,
                taskSaved,
                taskDeleted,
                resourcesByTaskId,
                completedFilter,
                paginator,
                filteredTasksCount,
                userStaff,

            };
        },
    });
</script>

<style lang="scss" scoped>
    .TasksTab {
        @apply tw-h-full tw-flex tw-flex-col;
    }

    .TasksTab__header {
        @apply tw-p-3;
    }

    .TasksTab__actions {
        @apply lg:tw-flex tw-block tw-justify-between tw-items-center;
    }

    .TasksTab__list {
        @apply tw-flex tw-flex-col;
        @apply tw-overflow-auto;
    }

    .TasksTab__item {
        @apply tw-mt-2 first:tw-mt-0 tw-border-t tw-border-gray-300;
    }
</style>
