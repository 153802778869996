<template>
    <div class="TaxCertificates__alert">
        <MagicIndexEmptyList>
            <template #empty-list>
                {{ __('billing_core:no_template_for_tax_certificates') }}
            </template>
            <template #empty-list-action>
                <CText><span v-html="__('billing_core:first_create_a_document_template_with_instructions')"/></CText>
            </template>
        </MagicIndexEmptyList>
        <CButton
            onclick="Intercom('showArticle', 2988211)"
            :to="{ name: 'settings' }"
            :truncate="false"
        >
            <i
                aria-hidden="true"
                class="fad fa-external-link mr-1"
            /> {{ __('billing_core:show_tax_certificate_guide') }}
        </CButton>
    </div>
</template>

<script>
    import MagicIndexEmptyList from '@/modules/magic-index/components/atoms/MagicIndexEmptyList.vue';

    export default {
        components: {MagicIndexEmptyList},
    };
</script>
<style lang="scss" scoped>
    .TaxCertificates__alert {
        @apply tw-flex tw-flex-col tw-items-center;
    }
</style>
