import moment from 'moment';


export default function() {
    const formatDate = function(date: string | undefined, format = 'DD/MM/YYYY') {
        return date ? moment(date).locale('fr').format(format) : undefined;
    };

    const secondsToMinutes = function(seconds: number) {
        return Math.floor(moment.duration(seconds, 'seconds').asMinutes());
    };

    return {
        formatDate,
        secondsToMinutes,
    };
}
