import __ from '@/modules/app/utils/i18n-facade';

export const notification = {
    data: () => ({
        notificationsCharacteristics: [
            {
                type: 'message-request-v1',
                component: 'message-request',
                title: __('common:message_one'),
                icon: 'fa fa-comment',
                style: {padding: '2px 5px', background: '#D6EAFF', color: '#0059B8'},
                values: ['message-request'],
                visible: true,
            },
            {
                type: 'absence-request-v1',
                component: 'absence-request',
                title: __('common:absence_one'),
                icon: 'fa fa-calendar-times',
                style: {padding: '2px 5px', background: '#FFE3E3', color: '#AB091E'},
                values: ['absence-request'],
                visible: true,
            },
            {
                type: 'occasional-request-v1',
                component: 'occasional-request',
                title: __('common:booking_one'),
                icon: 'fa fa-calendar-plus',
                style: {padding: '2px 5px', background: '#D6EAFF', color: '#0059B8'},
                values: ['occasional-request'],
                visible: true,
            },
            {
                title: __('common:delay_one'),
                type: 'late-request-v1',
                component: 'late-request',
                icon: 'fa fa-clock',
                style: {padding: '2px 5px', background: '#FFECD9', color: '#C53C07'},
                values: ['late-request'],
                visible: true,
            },
            {
                title: __('common:payment_one'),
                type: 'invoice-unpaid-v1',
                component: 'invoice-unpaid',
                icon: 'fa fa-file-alt',
                style: {padding: '2px 5px', background: '#FFECD9', color: '#C53C07'},
                values: ['invoice-unpaid'],
                visible: true,
            },
            {
                title: __('common:news_one'),
                type: 'new-post-v1',
                component: 'new-post',
                icon: 'fa fa-globe',
                style: {padding: '2px 5px', background: '#FFF3C4', color: '#CB6E17'},
                values: ['new-post'],
                visible: true,
            },
            {
                title: __('common:security'),
                type: 'throttle-login-v1',
                component: 'throttle-login',
                icon: 'fa fa-lock',
                style: {padding: '2px 5px', background: '#FFE3E3', color: '#AB091E'},
                values: ['throttle-login'],
                visible: true,
            },
            {
                title: __('common:registration_one'),
                type: 'new-registration-form-v1',
                component: 'new-registration-form',
                icon: 'fa fa-file',
                style: {padding: '2px 5px', background: '#D5F4F7', color: '#0995A4'},
                values: ['new-registration-form', 'updated-registration-form'],
                visible: true,
            },
            {
                title: __('common:registration_one'),
                type: 'updated-registration-form-v1',
                component: 'updated-registration-form',
                icon: 'fa fa-file',
                style: {padding: '2px 5px', background: '#D5F4F7', color: '#0995A4'},
                visible: false,
            },
            {
                title: __('common:clocking_in_one'),
                type: 'presence-unclocked-v1',
                component: 'presence-unclocked',
                icon: 'fa fa-check',
                style: {padding: '2px 5px', background: '#DDF9C3', color: '#0B7500'},
                values: ['presence-unclocked', 'staff-presence-unclocked'],
                visible: true,
            },
            {
                title: __('common:clocking_in_one'),
                type: 'staff-presence-unclocked-v1',
                component: 'staff-presence-unclocked',
                icon: 'fa fa-check',
                style: {padding: '2px 5px', background: '#DDF9C3', color: '#0B7500'},
                visible: false,
            },
            {
                title: __('common:document_one'),
                type: 'new-document-v1',
                component: 'new-document',
                icon: 'fad fa-copy fa-swap-opacity',
                style: {padding: '2px 5px', background: '#F2EBFE', color: '#580894'},
                values: ['new-document', 'updated-document'],
                visible: true,
            },
            {
                title: __('common:document_one'),
                type: 'updated-document-v1',
                component: 'updated-document',
                icon: 'fad fa-copy fa-swap-opacity',
                style: {padding: '2px 5px', background: '#F2EBFE', color: '#580894'},
                visible: false,
            },
            {
                title: __('activity:meeting_one'),
                type: 'meeting-v1',
                component: 'meeting',
                icon: 'fa fa-calendar',
                style: {padding: '2px 5px', background: '#F2EBFE', color: '#580894'},
                values: ['meeting'],
                visible: true,
            },
            {
                title: __('notification:general_task'),
                type: 'task-reminder-v1',
                component: 'task-reminder',
                icon: 'fa fa-list-check',
                style: {padding: '2px 5px', background: '#E8EAEE', color: '#4E5B6A'},
                values: ['task-reminder'],
                visible: true,
            },
            {
                title: __('notification:kid_task'),
                type: 'task-kid-reminder-v1',
                component: 'task-reminder',
                icon: 'fa fa-child-reaching',
                style: {padding: '2px 5px', background: '#F2EBFE', color: '#580894'},
                values: ['task-kid-reminder'],
                visible: true,
            },
            {
                title: __('notification:family_member_task'),
                type: 'task-family-member-reminder-v1',
                component: 'task-reminder',
                icon: 'fa fa-user-tie',
                style: {padding: '2px 5px', background: '#F2EBFE', color: '#580894'},
                values: ['task-family-reminder'],
                visible: true,
            },
            //
            // Cette catégorie n'existe pas encore dans la création de tâche, mais on la laisse pour le futur
            //
            // {
            //     title: __('notification:family_task'),
            //     type: 'task-family-member-reminder-v1',
            //     component: 'task-reminder',
            //     icon: 'fa fa-user-tie',
            //     style: {padding: '2px 5px', background: '#F2EBFE', color: '#580894'},
            //     values: ['task-family-member-reminder'],
            //     visible: true,
            // },
            {
                title: __('notification:staff_task'),
                type: 'task-staff-reminder-v1',
                component: 'task-reminder',
                icon: 'fa fa-users',
                style: {padding: '2px 5px', background: '#FFE3EC', color: '#A70666'},
                values: ['task-staff-reminder'],
                visible: true,
            },
            {
                title: __('notification:registration_task'),
                type: 'task-registration-reminder-v1',
                component: 'task-reminder',
                icon: 'fa fa-pen-alt',
                style: {padding: '2px 5px', background: '#D5F4F7', color: '#0995A4'},
                values: ['task-registration-reminder'],
                visible: true,
            },
            {
                title: __('notification:customer_task'),
                type: 'task-customer-reminder-v1',
                component: 'task-reminder',
                icon: 'fa fa-file-user',
                style: {padding: '2px 5px', background: '#FFECD9', color: '#C53C07'},
                values: ['task-customer-reminder'],
                visible: true,
            },
            {
                title: __('notification:vaccine_task'),
                type: 'task-vaccine-reminder-v1',
                component: 'task-reminder',
                icon: 'fa fa-syringe',
                style: {padding: '2px 5px', background: '#DDF9C3', color: '#0B7500'},
                values: ['task-vaccine-reminder'],
                visible: true,
            },
        ],
    }),
    methods: {
        $getNotificationCharacteristics(type) {
            // @ts-ignore
            const notifCharacteristics = this.notificationsCharacteristics.find(item => item.type === type);
            if (notifCharacteristics) {
                return notifCharacteristics;
            }
        },
    },
};
