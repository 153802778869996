<template>
    <div class="InvoicesListItemDetailsLine">
        <InvoicesListItemDetailsLineIcon :line-type="line.type"/>
        <div class="InvoicesListItemDetailsLine__label">
            {{ line.name }}
        </div>
        <div class="InvoicesListItemDetailsLine__total">
            {{ format(line.total) }}
        </div>
    </div>
</template>

<script lang="ts">
    import InvoicesListItemDetailsLineIcon
        from '@/modules/cashier/components/billing/invoice/atoms/InvoicesListItemDetailsLineIcon.vue';
    import {invoice} from '@/modules/legacy/mixins/invoice';
    import type {PropType} from 'vue';
    import {defineComponent} from 'vue';
    import useFormatNumber from '@/modules/cashier/composables/useFormatNumber';

    export default defineComponent({
        components: {
            InvoicesListItemDetailsLineIcon,
        },
        mixins: [invoice],
        props: {
            line: {
                type: Object as PropType<{ type: string, name: string, total: number }>,
                required: true,
            },
            currency: {
                type: String,
                required: true,
            },
        },
        setup(props) {
            const {format} = useFormatNumber(props.currency);

            return {
                format,
            };
        },
    });
</script>

<style lang="scss" scoped>
    .InvoicesListItemDetailsLine {
        @apply tw-flex tw-flex-row tw-items-center tw-gap-2;
    }

    .InvoicesListItemDetailsLine__label {
        @apply tw-max-w-xs tw-break-words;
    }

    .InvoicesListItemDetailsLine__total {
        @apply tw-font-bold tw-whitespace-nowrap;
    }
</style>
