<script setup lang="ts">
    import {computed, watch} from 'vue';
    import useManager from '@/modules/app/composables/useManager';
    import type PaymentModel from '@/modules/cashier/models/PaymentModel';
    import type {ModelCollection} from '@meekohq/lumos';
    import type {LegacyInvoiceType} from '@/modules/cashier/utils/billing/invoice/LegacyInvoiceType';
    import PaymentAllocationsListItemTransaction
        from '@/modules/cashier/payment/infrastructure/components/PaymentAllocationsListItemTransaction.vue';
    import PaymentAllocationsListItemInvoice
        from '@/modules/cashier/payment/infrastructure/components/PaymentAllocationsListItemInvoice.vue';
    import {
        TransactionDebitAllocationAggregate,
    } from '@/modules/cashier/payment/domain/TransactionDebitAllocationAggregate';
    import {InvoiceAllocationAggregate} from '@/modules/cashier/payment/domain/InvoiceAllocationAggregate';
    import PaymentAllocationsAbstractList
        from '@/modules/cashier/payment/infrastructure/components/PaymentAllocationsAbstractList.vue';
    import usePaymentAllocationsState
        from '@/modules/cashier/payment/infrastructure/components/usePaymentAllocationsState';

    const props = withDefaults(defineProps<{
        creditNote: LegacyInvoiceType
        payments?: ModelCollection<PaymentModel>;
        editingAllocations: boolean;
    }>(), {
        payments: undefined,
    });

    const emit = defineEmits<{
        (e: 'refreshInvoice'): void
        (e: 'loaded'): void
        (e: 'editingAllocations', editingAllocations: boolean): void
    }>();

    const activeOrganization = useManager().activeOrganization.value;

    const refundPayment = computed(() => {
        return props.payments?.first(payment => !!payment.attributes.refund);
    });

    const {
        allocations,
        countAllocatableWithRemaining,
        isFetching,
        fetchForCreditNote,
        paymentRemainingAmount,
    } = usePaymentAllocationsState();

    async function prepareRefresh() {
        emit('refreshInvoice');
    }

    watch(() => props.payments, async () => {
        if (props.payments) {
            await fetchForCreditNote(props.payments, props.creditNote.id);
        } else {
            allocations.value = [];
        }
        emit('loaded');
    });
</script>
<template>
    <PaymentAllocationsAbstractList
        :allocations="allocations"
        :count-allocatable-with-remaining="countAllocatableWithRemaining"
        :editing-allocations="editingAllocations"
        :invoice="creditNote"
        :is-fetching="isFetching"
        :payment="refundPayment"
        :payment-remaining-amount="paymentRemainingAmount"
        @created="prepareRefresh"
        @editingAllocations="emit('editingAllocations', $event)"
        @saved="prepareRefresh"
    >
        <template #item="{allocation}">
            <PaymentAllocationsListItemTransaction
                v-if="allocation instanceof TransactionDebitAllocationAggregate"
                class="PaymentAllocationsListForCreditNote__item-content"
                :display-status="allocation.destination.isFailed"
                :editing-allocations="editingAllocations"
                :invoice="creditNote"
                :organization="activeOrganization"
                :payment="allocation.source"
                :transaction="allocation.destination"
                @deleted="prepareRefresh"
                @rejected="prepareRefresh"
                @restored="prepareRefresh"
                @updated="prepareRefresh"
            />
            <PaymentAllocationsListItemInvoice
                v-if="allocation instanceof InvoiceAllocationAggregate"
                :allocation="allocation"
                class="PaymentAllocationsListForCreditNote__item-content"
                :editing-allocations="editingAllocations"
                :invoice="allocation.destination.invoices().value().first()"
                @detached="prepareRefresh"
            />
        </template>
    </PaymentAllocationsAbstractList>
</template>

<style lang="scss" scoped>
    .PaymentAllocationsListForCreditNote__item-content {
        @apply tw-bg-gray-50 tw-p-4 tw-rounded-lg tw-w-full;
    }
</style>
