<template>
    <div class="p-3">
        <div class="tw-flex tw-flex-wrap tw-justify-between tw-items-center tw-gap-2">
            <div class="tw-flex tw-flex-wrap tw-gap-2">
                <MButton
                    class="sm:tw-w-auto tw-w-full"
                    @click="selectAll(true)"
                >
                    <template #left-icons>
                        <FontAwesomeIcon
                            class="tw-text-primary-500"
                            icon="fa fa-check-circle"
                        />
                    </template>
                    {{ __('common:actions.select_all') }}
                </MButton>
                <MButton
                    class="sm:tw-w-auto tw-w-full"
                    @click="selectAll(false)"
                >
                    <template #left-icons>
                        <FontAwesomeIcon
                            class="tw-text-danger-500"
                            icon="fa fa-times-circle"
                        />
                    </template>
                    {{ __('common:actions.unselect_all') }}
                </MButton>
            </div>

            <div class="tw-flex tw-flex-wrap tw-gap-2">
                <MButton
                    class="sm:tw-w-auto tw-w-full"
                    :variant="showOnlyCompany ? 'primary' : 'light'"
                    @click="showOnlyCompany = !showOnlyCompany"
                >
                    <template #left-icons>
                        <FontAwesomeIcon icon="fa fa-city"/>
                    </template>
                    {{ __('common:company_other') }}
                </MButton>
                <MButton
                    v-if="action !== 'convert'"
                    class="sm:tw-w-auto tw-w-full"
                    @click="showAllInvoices"
                >
                    <template #left-icons>
                        <FontAwesomeIcon :icon="showAll ? 'fa fa-eye-slash' : 'fa fa-eye'"/>
                    </template>
                    <template v-if="showAll">
                        {{ __('billing_invoice:hide_proccessed_invoices') }}
                    </template>
                    <template v-else>
                        {{ __('billing_invoice:show_processed_invoices') }}
                    </template>
                </MButton>
            </div>
        </div>
        <hr class="hr">
        <div class="row mt-3">
            <div class="col-12 col-lg-6">
                <TagsResourceFinder
                    v-model="selectedTagsModels"
                    has-unselect
                    :inject-query="tagFinderQuery"
                    multi
                    only-active-organization
                    :search-bar="false"
                    :without-tags-option-is-selected="withoutTags"
                    @without-tags-option-is-selected="withoutTags = $event"
                />
            </div>
            <div class="col-12 col-lg-6 ml-lg-auto">
                <MInput
                    v-model="search"
                    :placeholder="__('common:search_dots')"
                />
            </div>
        </div>
        <div class="row mt-4">
            <loader
                v-if="loading"
                class="col text-center"
            />
            <h6
                v-else-if="!filteredInvoices.length"
                class="h6 text-center mt-3 w-100"
            >
                {{ __('billing_invoice:they_are_no_invoices') }}
            </h6>
            <div
                v-for="invoice in filteredInvoicesLegaciesWithModels"
                v-else
                :key="invoice.model.getKey()"
                class="col-6 col-xl-3 mb-3"
            >
                <SelectInvoicesCard
                    :action="action"
                    :invoice="invoice.legacy"
                    :invoice-model="invoice.model"
                    :nursery="nursery"
                    @click.native="invoice.legacy.selected = !invoice.legacy.selected"
                />
            </div>

            <div
                v-if="otherFilteredInvoices.length && action === 'sepa'"
                class="col-12 border-top mt-5 mb-3 pt-4"
            >
                <i class="fa fa-exclamation-triangle ml-1 mr-2"/>
                {{ __('billing_invoice:warning_missing_sepa_for') }}
            </div>
            <div
                v-for="invoice in otherFilteredInvoicesLegaciesWithModels"
                :key="'otherInvoice' + invoice.model.getKey()"
                class="col-6 col-xl-3 mb-3"
            >
                <SelectInvoicesCard
                    :action="action"
                    :invoice="invoice.legacy"
                    :invoice-model="invoice.model"
                    :is-other-invoices="true"
                    :nursery="nursery"
                />
            </div>
        </div>

        <div
            v-if="totalSelectedInvoices"
            class="mt-3 w-100 text-primary"
        >
            {{ __('billing_invoice:selected_invoices_count', {count: totalSelectedInvoices}) }}
        </div>
    </div>
</template>

<script>
    import moment from 'moment';
    import route from '@/modules/legacy/libs/ziggy';
    import useApi from '@/modules/app/composables/useApi';
    import _head from 'lodash-es/head';
    import _sortBy from 'lodash-es/sortBy';
    import _forEach from 'lodash-es/forEach';
    import _filter from 'lodash-es/filter';
    import _debounce from 'lodash-es/debounce';
    import {invoice} from '@/modules/legacy/mixins/invoice';
    import useFormatNumber from '@/modules/cashier/composables/useFormatNumber';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';
    import useTagLegacyModelBridge from '@/modules/app/composables/useTagLegacyModelBridge';
    import SelectInvoicesCard from '@/modules/cashier/components/billing/invoice/Actions/SelectInvoicesCard.vue';
    import TagModel from '@/modules/tag/models/TagModel';
    import TagsResourceFinder from '@/modules/tag/components/TagsResourceFinder.vue';
    import InvoiceModel from '@/modules/cashier/models/InvoiceModel';

    export default {
        components: {
            TagsResourceFinder,
            SelectInvoicesCard,
        },
        mixins: [invoice],
        props: {
            nursery: {
                type: Object,
                required: true,
            },
            date: {
                type: moment,
                required: true,
            },
            action: {
                type: String,
                default: null,
            },
            selectedInvoices: {
                type: Array,
                required: true,
            },
        },
        data() {
            return {
                invoices: [],
                invoicesModels: [],
                search: null,
                showAll: true,
                showOnlyCompany: false,
                selectedTagsModels: [],
                withoutTags: JSON.parse(localStorage.getItem('without_tags:invoices:index')) || false,
                loading: false,
                useFormatNumber,
            };
        },
        computed: {

            filteredInvoices() {
                let myInvoices = this.invoices;

                if (this.action === 'convert') {
                    myInvoices = this.invoices.filter(
                        item => item.status === 'draft',
                    );
                }

                if (this.action === 'send') {
                    myInvoices = this.invoices.filter(
                        item => item.status !== 'draft',
                    );
                }

                if (this.action === 'sendReminders') {
                    myInvoices = this.invoices.filter(
                        item => item.status !== 'draft'
                            && item.sent_at
                            && item.invoice_type === 'invoice',
                    );
                }

                if (this.action === 'caf') {
                    myInvoices = myInvoices.filter(
                        item => item.type === 'presence'
                            && item.status !== 'draft'
                            && item.invoice_type === 'invoice',
                    );
                }

                return this.invoiceFilters(myInvoices, true);
            },

            filteredInvoicesLegaciesWithModels() {
                return this.createLegaciesModelsArray(
                    this.filteredInvoices,
                    this.invoicesModels,
                );
            },

            otherFilteredInvoices() {
                return this.action === 'sepa'
                    ? this.invoiceFilters(this.invoices, false)
                    : [];
            },

            otherFilteredInvoicesLegaciesWithModels() {
                return this.createLegaciesModelsArray(
                    this.otherFilteredInvoices,
                    this.invoicesModels,
                );
            },

            totalSelectedInvoices() {
                const invoices = this.invoices.filter(item => item.selected);

                return invoices && invoices.length ? invoices.length : 0;
            },

            tagFinderQuery() {
                return TagModel.query().where('type', 'invoice');
            },

            tagsFilters() {
                return {
                    selectedTagsModels: this.selectedTagsModels,
                    withoutTags: this.withoutTags,
                };
            },
        },
        watch: {
            invoices: {
                handler() {
                    this.$emit(
                        'update:selectedInvoices',
                        this.invoices.filter(item => item.selected),
                    );
                },
                deep: true,
            },
            tagsFilters: {
                handler: _debounce(function({selectedTagsModels, withoutTags}) {
                    if (selectedTagsModels) {
                        localStorage.setItem(
                            'tags:invoices:index',
                            JSON.stringify(selectedTagsModels.map(item => item.getKey())),
                        );
                    } else {
                        localStorage.removeItem('tags:invoices:index');
                    }
                    localStorage.setItem('without_tags:invoices:index', JSON.stringify(withoutTags));

                    this.currentPage = 1;
                    this.getInvoices();
                }, 500),
            },
        },
        mounted() {
            this.loading = true;
            this.getSelectedTagsModels();
        },
        methods: {
            invoiceFilters(invoices, isForFilteredInvoices) {
                if (this.action === 'sepa') {
                    invoices = invoices.filter(
                        item => {
                            const hasSepa = isForFilteredInvoices ? item.has_sepa : !item.has_sepa;

                            return hasSepa
                                && item.status !== 'draft'
                                && item.invoice_type === 'invoice';
                        },
                    );
                }

                if (this.search) {
                    invoices = _filter(invoices, item => {
                        if (!item.kid_name) {
                            return true;
                        }

                        const kidName = item.kid_name
                            .toLowerCase()
                            .normalize('NFD')
                            .replace(/[\u0300-\u036f]/g, '');
                        const search = this.search
                            .toLowerCase()
                            .normalize('NFD')
                            .replace(/[\u0300-\u036f]/g, '');

                        return kidName.indexOf(search) > -1;
                    });
                }

                if (this.showOnlyCompany) {
                    invoices = invoices.filter(item => item.company);
                }

                if (this.showAll) {
                    return _sortBy(invoices, 'kid_name');
                } else {

                    if (this.action === 'sepa') {
                        return _sortBy(
                            invoices.filter(item => item.computed.remaining_amount > 0),
                            'kid_name',
                        );
                    } else if (this.action === 'caf') {
                        return _sortBy(
                            invoices.filter(item => !item.caf_sent_at),
                            'kid_name',
                        );
                    }

                    return _sortBy(
                        invoices.filter(item => !item.sent),
                        'kid_name',
                    );
                }
            },
            getInvoices() {
                this.loading = true;

                const tags = useTagLegacyModelBridge().tagsModelsAndWithoutTagsToLegacyFilter(this.selectedTagsModels, this.withoutTags);

                useApi().legacy.get(
                    route('nurseries.invoices.index', {
                        nurseries: this.nursery.id,
                    }),
                    {
                        params: {
                            per_page: 200,
                            from: moment(this.date)
                                .startOf('month')
                                .format('Y-MM-DD'),
                            to: moment(this.date)
                                .endOf('month')
                                .format('Y-MM-DD'),
                            tags,
                        },
                    },
                )
                    .then(response => {
                        const invoices = response.data.data;
                        invoices.forEach(invoice => {
                            invoice.selected = false;
                            invoice.company = invoice.type === 'company';

                            if (this.action === 'send' && invoice.sent_at) {
                                invoice.sent = true;
                            }
                            if (this.action === 'sendReminders' && invoice.dunning_dates && invoice.dunning_dates.length) {
                                invoice.sent = true;
                            }
                            if (this.action === 'caf' && invoice.caf_sent_at) {
                                invoice.sent = true;
                            }
                            if (this.action === 'sepa') {
                                if (invoice.sepa_done_at) {
                                    invoice.sent = true;
                                }
                                invoice.sepa_amount = invoice.computed.remaining_amount;
                            }
                        });

                        return this.getInvoicesModels(invoices);
                    }).then(() => {
                        this.loading = false;
                    }).catch(error => {
                        this.loading = false;
                        if (
                            error &&
                            error.response &&
                            error.response.status === 422
                        ) {
                            _forEach(error.response.data.errors, value => {
                                useNotification().error(_head(value));
                            });
                        } else {
                            useNotification().error(error);
                        }
                    });
            },

            async getInvoicesModels(invoices) {
                if (!invoices.length) {
                    this.invoicesModels = [];
                    this.invoices = [];

                    return;
                }

                this.invoicesModels = (await InvoiceModel.query()
                    .whereIn('id', invoices.map(invoice => invoice.id))
                    .with(new InvoiceModel().tags())
                    .get()
                ).all();
                this.invoices = invoices;
            },

            showAllInvoices() {
                this.filteredInvoices.forEach(invoice => {
                    if ((this.action === 'send' && invoice.sent) ||
                        (this.action === 'sendReminders' && invoice.dunning_dates && invoice.dunning_dates.length) ||
                        (this.action === 'sepa' && parseInt(invoice.computed.remaining_amount) === 0)) {
                        invoice.selected = false;
                    }
                });

                // If this line is before foreach, invoices is not unselect because this value compute filteredInvoice
                this.showAll = !this.showAll;
            },

            selectAll(select) {
                this.filteredInvoices.forEach(invoice => {
                    if (!select) {
                        invoice.selected = false;
                    } else if (!this.showAll) {
                        invoice.sent
                            ? (invoice.selected = false)
                            : (invoice.selected = true);
                    } else {
                        invoice.selected = true;
                    }
                });
            },

            async getSelectedTagsModels() {
                const selectedTagIds = JSON.parse(localStorage.getItem('tags:invoices:index'));
                this.selectedTagsModels = await useTagLegacyModelBridge().getTagsModelsFromIds(selectedTagIds);
            },

            createLegaciesModelsArray(legacies, models) {
                return legacies.map(legacy => {
                    const model = models.find(model => model.getKey() === legacy.id);

                    return {
                        legacy,
                        model,
                    };
                });
            },
        },
    };
</script>
