export default {
    defaultNamespace: 'common',
    namespaces: [
        'activity',
        'app',
        'auth',
        'billing_core',
        'billing_customer',
        'billing_invoice',
        'billing_payment',
        'billing_setting',
        'billing_transaction',
        'calendar',
        'common',
        'components',
        'dashboard',
        'document',
        'equipment',
        'family_contract',
        'family_family',
        'family_kid',
        'family_member',
        'foods',
        'health',
        'hr',
        'hr_balance',
        'hr_calendar',
        'hr_contract',
        'hr_staff',
        'news',
        'notification',
        'organization',
        'photo',
        'planning',
        'registration',
        'request',
        'subscription',
        'tag',
        'transmission',
        'user',
    ],
    detection: {
        order: ['navigator'],
    },
};
