import type RegistrationModel from '@/modules/registration/models/RegistrationModel';
import type {Moment} from 'moment';
import moment from 'moment';
import useManager from '@/modules/app/composables/useManager';

interface PlanningInterface {
    started_time: string;
    ended_time: string;
}

export default function useRegistrationPlanning() {
    const {activeOrganization} = useManager();

    // Generate planning object for render components
    function generatePlanning(registration: RegistrationModel, date: Moment): PlanningInterface[] {
        // Return empty if contract not already start
        if (
            date.unix() < moment(registration.attributes.contract_date_period.started_at).unix()
            || date.unix() > moment(registration.attributes.contract_date_period.ended_at).unix()
        ) {
            return [];
        }

        const dayNumber = date.clone().isoWeekday();
        const dayDate = date.clone().format('YYYY-MM-DD');
        const referenceWeek = getReferenceWeekToUse(registration, date);

        return registration.plannings().value().filter(planning => {
            const planningDayNumber = getPlanningDayNumber(planning.attributes.day);

            return !!planningDayNumber
                && dayNumber === planningDayNumber
                && planning.attributes.week === referenceWeek
                && !!planning.attributes.time_period.ended_at
                && !!planning.attributes.time_period.started_at;
        }).map(planning => {
            return {
                started_time: planning.attributes.time_period.started_at,
                ended_time: planning.attributes.time_period.ended_at,
                started_at: moment(dayDate + ' ' + planning.attributes.time_period.started_at, 'YYYY-MM-DD HH:mm:ss').unix(),
                ended_at: moment(dayDate + ' ' + planning.attributes.time_period.ended_at, 'YYYY-MM-DD HH:mm:ss').unix(),
                nursery_id: Number(activeOrganization.value.getKey()),
                type: 'recurrent',
                event_type: 'planning-recurrent',
            };
        }).toArray();
    }

    // Get week day number from week day string in english short version
    function getPlanningDayNumber(day?: string): number | null {
        if (day) {
            return moment().locale('en').isoWeekday(day).isoWeekday();
        }

        return null;
    }

    function getWeekNbrSinceRegistration(registration: RegistrationModel, date: Moment) {
        return date.diff(
            moment(registration.attributes.contract_date_period.started_at, 'YYYY-MM-DD').startOf('week'),
            'week',
        ) + 1;
    }

    // Get planning week to use for specific day
    function getReferenceWeekToUse(registration: RegistrationModel, date: Moment) {
        const weeks: string[] = registration.plannings().value()
            .unique(planning => planning.attributes.week)
            .pluck('attributes.week')
            .all() as string[];

        let referenceWeek: string | null = null;

        weeks.forEach(week => {
            const isReferenceWeek = Number.isInteger((getWeekNbrSinceRegistration(registration, date)) / Number(week));

            if (isReferenceWeek) {
                referenceWeek = week;
            }
        });

        return referenceWeek;
    }

    return {
        generatePlanning,
    };
}
