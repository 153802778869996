<template>
    <CForm class="tw-min-w-96">
        <!-- Staff selector -->
        <StaffFinder
            v-model="selectedStaff"
            button-class="tw-w-full"
            has-active-contract
            :inject-query="staffFinderQuery"
            @input="validate('staff')"
        >
            <template #button-text-empty>
                {{ __('activity:select_staff') }}
            </template>
        </StaffFinder>
        <!-- Custom selector -->
        <CFormSelect
            v-model="custom"
            :fallback-text="customSelectLabel"
            :options="customSelectOptions"
            :search-bar="false"
            @input="validate('custom')"
        />
        <!-- Participant form -->
        <CDisclosure
            :open="false"
            size="sm"
            :title="__('activity:add_participant')"
        >
            <CFormTwoColumns>
                <CFormGroup>
                    <CLabel>{{ __('common:first_name') }}</CLabel>
                    <CInput
                        v-model="custom.firstname"
                        type="string"
                    />
                </CFormGroup>
                <CFormGroup>
                    <CLabel>{{ __('common:last_name') }}</CLabel>
                    <CInput
                        v-model="custom.lastname"
                        type="string"
                    />
                </CFormGroup>
                <CFormErrorMessageList
                    v-if="participantErrors.hasError('domain')"
                    :errors="participantErrors.getErrors('domain')"
                />
            </CFormTwoColumns>
            <CFormGroup class="tw-mt-4">
                <CLabel>{{ __('common:email') }}</CLabel>
                <CInput
                    v-model="custom.email"
                    type="email"
                />
                <CFormErrorMessageList
                    v-if="participantErrors.hasError('email')"
                    :errors="participantErrors.getErrors('email')"
                />
            </CFormGroup>
            <CHStack
                class="tw-mt-4"
                distribute="end"
            >
                <CButton
                    class="tw-mr-2"
                    @click="cancel"
                >
                    {{ __('common:actions.cancel') }}
                </CButton>
                <CButton
                    variant="primary"
                    @click="validate('custom')"
                >
                    {{ __('common:actions.validate') }}
                </CButton>
            </CHStack>
        </CDisclosure>
    </CForm>
</template>

<script lang="ts">
    import type {PropType} from 'vue';
    import {defineComponent, ref, toRef} from 'vue';
    import type MeetingParticipantModel from '@/modules/activity/models/MeetingParticipantModel';
    import type StaffModel from '@/modules/human-resources/models/StaffModel';
    import useParticipantStaffSelector from '@/modules/activity/composables/useParticipantStaffSelector';
    import useParticipantCustomSelector from '@/modules/activity/composables/useParticipantCustomSelector';
    import ParticipantErrorHandler from '@/modules/activity/utils/ParticipantErrorHandler';
    import useManager from '@/modules/app/composables/useManager';
    import StaffFinder from '@/modules/request/components/Teams/StaffFinder.vue';
    import type {ModelCollection} from '@meekohq/lumos';
    import Validator from '@/modules/legacy/helpers/validator.helper';
    import type {CustomSelectType} from '@/modules/activity/utils/CustomSelectType';
    import type {CustomOptionType} from '@/modules/activity/utils/CustomOptionType';
    import __ from '@/modules/app/utils/i18n-facade';

    export default defineComponent({
        components: {StaffFinder},
        props: {
            value: {
                type: Object as PropType<MeetingParticipantModel>,
                required: true,
            },
            customSelect: {
                type: Object as PropType<CustomSelectType>,
                default: null,
            },
            allowedOrganizationIds: {
                type: Array as PropType<string[]>,
                default: null,
            },
            excludeParticipants: {
                type: Object as PropType<ModelCollection<MeetingParticipantModel>>,
                default: null,
            },
        },
        setup(props, {emit}) {
            const {activeOrganization} = useManager();

            const {
                selectedStaff,
                staffFinderQuery,
            } = useParticipantStaffSelector(toRef(props, 'excludeParticipants'), props.allowedOrganizationIds);

            const {
                customSelectLabel,
                customSelectOptions,
            } = useParticipantCustomSelector(props.customSelect, toRef(props, 'excludeParticipants'));

            const custom = ref<CustomOptionType>({
                firstname: null, lastname: null, email: null, resource_type: null, resource_id: null,
            });

            function fillParticipantWithStaff(staff: StaffModel) {
                selectedStaff.value = staff;

                props.value.resource().associate(staff);
                props.value.attributes.account_id = activeOrganization.value.attributes.account_id;
                props.value.attributes.first_name = staff.attributes.first_name || null;
                props.value.attributes.last_name = staff.attributes.last_name || null;
                props.value.attributes.email = staff.attributes.email || null;
            }

            function fillParticipantWithCustom(custom: CustomOptionType) {
                props.value.attributes.account_id = activeOrganization.value.attributes.account_id;
                props.value.attributes.first_name = custom.firstname;
                props.value.attributes.last_name = custom.lastname;
                props.value.attributes.email = custom.email;
                props.value.attributes.resource_id = custom.resource_id;
                props.value.attributes.resource_type = custom.resource_type;
            }

            const participantErrors = ref(new ParticipantErrorHandler());

            function prevalidate(participant: MeetingParticipantModel): boolean {
                const errors: Array<{ message: string, code: string, detail: any, source: { pointer: string } }> = [];

                // At least one field must be filled
                if (!participant.attributes.first_name && !participant.attributes.last_name && !participant.attributes.email) {
                    errors.push({
                        message: __('common:error.required'),
                        code: '0x335D90AE82',
                        detail: {},
                        source: {pointer: 'domain'},
                    });
                }

                if (participant.attributes.email && !Validator.isValidEmail(participant.attributes.email)) {
                    errors.push({
                        message: __('common:error.email.format'),
                        code: '0x2EAA817032',
                        detail: {},
                        source: {pointer: 'email'},
                    });
                }

                if (errors.length) {
                    participantErrors.value.reset({response: {data: {errors}}});

                    return false;
                }

                return true;
            }

            function validate(type: 'staff' | 'custom') {
                // Fill participant with selected item or custom form data
                if (type === 'staff' && selectedStaff.value) {
                    fillParticipantWithStaff(selectedStaff.value);
                } else {
                    fillParticipantWithCustom(custom.value);
                }

                // Validate format of data
                if (!prevalidate(props.value)) {
                    return;
                }

                emit('validate', props.value);
            }

            function cancel() {
                emit('cancel');
            }

            return {
                cancel,
                custom,
                customSelectLabel,
                customSelectOptions,
                fillParticipantWithStaff,
                fillParticipantWithCustom,
                participantErrors,
                selectedStaff,
                staffFinderQuery,
                validate,
            };
        },
    });
</script>
