import Vue from 'vue';
import _get from 'lodash-es/get';

export default class Loader {
    private list: object = {};

    set(value: boolean, name = 'main'): void {
        Vue.set(this.list, name, value);
    }

    start(name = 'main'): void {
        Vue.set(this.list, name, true);
    }

    stop(name = 'main'): void {
        Vue.set(this.list, name, false);
    }

    isLoading(name = 'main', defaultValue = false): boolean {
        return _get(this.list, name, defaultValue);
    }

    isDone(name = 'main'): boolean {
        return !this.isLoading(name);
    }
}
