<template>
    <div>
        <CButton
            :icon-sort="true"
            @click="$refs.planningFilter.show()"
        >
            {{ __('planning:planning_type') }}
        </CButton>
        <CDropdown
            ref="planningFilter"
            legacy-mode
        >
            <CList>
                <CListRow
                    v-for="option in realOptions"
                    :key="option.value"
                    class="tw-flex tw-items-center tw-text-gray-500 tw-cursor-pointer"
                    :class="{ 'tw-text-gray-900': filterValue.includes(option.value) }"
                    @click="updateFilter(option.value)"
                >
                    <div class="tw-mr-2">
                        {{ option.text }}
                    </div>
                    <i
                        v-if="filterValue.includes(option.value)"
                        aria-hidden="true"
                        class="fa fa-check tw-ml-auto"
                    />
                </CListRow>
            </CList>
        </CDropdown>
    </div>
</template>

<script lang="ts">
    import type {PropType} from 'vue';
    import {defineComponent, ref, watch} from 'vue';
    import __ from '@/modules/app/utils/i18n-facade';

    export default defineComponent({
        props: {
            value: {
                type: Array as PropType<String[]>,
                default: () => [],
            },
        },
        emits: ['input'],
        setup(props, {emit}) {
            const filterValue = ref<String[]>(props.value);

            const realOptions = [
                {
                    value: 'forecast-occasional',
                    text: __('common:occasional'),
                },
                {
                    value: 'forecast-adaptation',
                    text: __('common:adaptation'),
                },
                {
                    value: 'real-absence',
                    text: __('common:absence_other'),
                },
            ];

            function updateFilter(value: string) {
                if (filterValue.value.includes(value)) {
                    filterValue.value = filterValue.value.filter(item => item !== value);
                } else {
                    filterValue.value.push(value);
                }

                emit('input', filterValue.value);
            }

            watch(() => props.value, () => {
                filterValue.value = props.value;
            });

            return {
                filterValue,
                realOptions,
                updateFilter,
            };
        },
    });
</script>
