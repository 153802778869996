<template>
    <section class="SimpleFullPageTemplate">
        <header class="SimpleFullPageTemplate__header">
            <slot name="title"/>
            <div class="SimpleFullPageTemplate__actions">
                <slot name="actions"/>
            </div>
        </header>
        <div class="SimpleFullPageTemplate__content">
            <slot name="content"/>
        </div>
    </section>
</template>

<script lang="ts">
    export default {
        components: {},
        props: {
            variant: {type: String, default: 'base'},
        },
        setup() {
            return {};
        },
    };
</script>

<style lang="scss" scoped>
    .SimpleFullPageTemplate {
        @apply tw-flex tw-flex-col tw-flex-1;
    }

    .SimpleFullPageTemplate__header {
        @apply tw-rounded-t-inherit;
        @apply tw-flex tw-flex-col md:tw-flex-row tw-justify-between tw-gap-6;
        @apply tw-mt-4 tw-mb-8;
    }

    .SimpleFullPageTemplate__actions {
        @apply tw-flex tw-flex-wrap tw-gap-2 tw-items-center tw-justify-start md:tw-justify-end;
    }

    .SimpleFullPageTemplate__content {
        @apply tw-flex tw-flex-col tw-flex-1;
    }
</style>
