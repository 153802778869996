import TagModel from '@/modules/tag/models/TagModel';

/**
 * Bridge between legacy API and TagModel for tags related logics
 */
export default function useTagLegacyModelBridge() {

    /**
     * Convert tags models and withoutTags to legacyApi endpoint parameter
     * @param tagsModels
     * @param withoutTags
     */
    function tagsModelsAndWithoutTagsToLegacyFilter(tagsModels?: TagModel[], withoutTags = false) {
        let tags: '' | string[] | undefined;

        if (withoutTags) {
            tags = ''; // Empty string means without tags
        } else if (tagsModels?.length) {
            tags = tagsModels.map(item => item.getKey()); // Only selected tags
        } else {
            tags = undefined; // Any tags
        }

        return tags;
    }

    /**
     * Get tags models from tags ids. Only if tagsIds is an array and is not empty
     * @param tagsIds
     */
    async function getTagsModelsFromIds(tagsIds?: string[]): Promise<TagModel[]> {
        if (!tagsIds?.length) {
            return [];
        }

        return (await TagModel.query().whereIn('id', tagsIds).get()).all();
    }

    return {
        getTagsModelsFromIds,
        tagsModelsAndWithoutTagsToLegacyFilter,
    };
}
