<template>
    <CModal
        ref="modal"
        footer-class
        :header-title="__('planning:add_absence')"
        size="5xl"
        @shown="modalShown"
    >
        <div class="tw-text-left">
            <select-kids
                v-if="selectingKids"
                :nursery="nursery"
                :selected-kids.sync="selectedKids"
            />
            <div v-else>
                <div
                    v-if="selectedKids.length"
                    class="tw-mb-2"
                >
                    {{ __('planning:absence_for_kids_colon', {count: selectedKids.length}) }}
                </div>
                <div
                    v-if="selectedKids.length"
                    class="tw-bg-gray-50 tw-px-3 tw-mb-4 tw-rounded-lg tw-overflow-y-scroll"
                    style="max-height: 200px;"
                >
                    <div
                        v-for="(kid, i) in selectedKids"
                        :key="'selectedKid' + i"
                        class="tw-text-center tw-mr-3 tw-my-2 tw-inline-block"
                    >
                        <LegacyKidAvatar
                            class="tw-w-10 tw-m-auto"
                            :kid="kid"
                        />
                        <div class="tw-capitalize tw-text-sm tw-mt-1">
                            {{ kid.first_name + ' ' + (kid.last_name ? kid.last_name[0] + '.' : '') }}
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-6">
                        <label class="label">{{ __('common:beginning') }}</label>
                        <MDatePicker
                            v-model="absence.started_at"
                            class="tw-w-full"
                            format="yyyy-MM-dd HH:mm"
                            with-time
                        />
                    </div>
                    <div class="col-6">
                        <label class="label">{{ __('common:end') }}</label>
                        <MDatePicker
                            v-model="absence.ended_at"
                            class="tw-w-full"
                            format="yyyy-MM-dd HH:mm"
                            with-time
                        />
                    </div>
                </div>

                <div class="row mt-3">
                    <div class="col-12">
                        <textarea
                            v-model="absence.note"
                            class="form-control"
                            :placeholder="__('common:comment_dots')"
                            rows="2"
                        />
                    </div>
                </div>

                <div class="row tw-border-t tw-border-gray-300 tw-pt-3 mt-4">
                    <CForm class="col-12">
                        <CFormGroup>
                            <CLabel>{{ __('planning:how_to_deduct') }}</CLabel>
                            <MButtonSelect
                                v-model="absence.unit"
                                :options="unitAbsOptions"
                            />
                        </CFormGroup>
                        <CFormGroup>
                            <CCheckbox v-model="absence.vacation">
                                {{ __('planning:take_a_vacation_day_off') }}
                            </CCheckbox>
                        </CFormGroup>
                    </CForm>
                </div>
            </div>
        </div>
        <template #footer>
            <MButton
                v-if="!selectingKids"
                :label="__('common:actions.previous')"
                :loading="loading"
                variant="primary"
                @click="selectingKids = true"
            />
            <MButton
                class="tw-ml-auto tw-mr-2"
                :label="__('common:actions.cancel')"
                :loading="loading"
                variant="light"
                @click="$refs.modal.hide()"
            />
            <MButton
                v-if="selectingKids"
                :label="__('common:actions.next')"
                :loading="loading"
                variant="primary"
                @click="selectingKids = false"
            />
            <MButton
                v-else-if="selectedKids.length"
                :label="__('common:actions.validate')"
                :loading="loading"
                variant="primary"
                @click="save"
            />
            <MTooltip
                v-else
                :label="__('planning:no_kid_selected')"
                placement="top"
            >
                <MButton
                    :disabled="true"
                    :label="__('common:actions.validate')"
                    variant="primary"
                />
            </MTooltip>
        </template>
    </CModal>
</template>

<script>
    import route from '@/modules/legacy/libs/ziggy';
    import useApi from '@/modules/app/composables/useApi';
    import _cloneDeep from 'lodash-es/cloneDeep';
    import _head from 'lodash-es/head';
    import _forEach from 'lodash-es/forEach';
    import {nursery} from '@/modules/legacy/mixins/nursery';
    import SelectKids from '@/modules/legacy/components/Modules/PlanningGenerator/SelectKids.vue';
    import __ from '@/modules/app/utils/i18n-facade';
    import LegacyKidAvatar from '@/modules/app/components/atoms/avatars/LegacyKidAvatar.vue';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';
    import {Epoch} from '@meekohq/lumos';

    export default {
        components: {
            LegacyKidAvatar,
            SelectKids,
        },
        mixins: [nursery],
        props: {
            nursery: {type: Object},
            date: {},
        },
        data() {
            return {
                absence: {
                    started_at: null,
                    ended_at: null,
                    nursery_id: null,
                    kid_id: null,
                    type: 'absence',
                    note: null,
                    contract_hourly_rate: null,
                    hourly_rate: null,
                    isNew: true,
                    rangeAbsence: true,
                    vacation: false,
                    unit: 'free',
                },
                selectedKids: [],
                selectingKids: true,
                loading: false,
                errored: false,
                remainingRequests: 0,
                unitAbsOptions: [
                    {
                        value: 'day',
                        text: __('common:day_female_one'),
                    },
                    {
                        value: 'halfday',
                        text: __('common:halfday_one'),
                    },
                    {
                        value: 'hour',
                        text: __('common:hour_other'),
                    },
                    {
                        value: 'free',
                        text: __('planning:units.non_deducted'),
                    },
                ],
            };
        },
        watch: {
            remainingRequests(val) {
                if (this.loading && val === 0) {
                    this.loading = false;

                    // If an error happened, we don't want to show the success message and close the modal
                    if (this.errored) {
                        this.errored = false;

                        return;
                    }

                    useNotification().success(__('planning:absences_added'));
                    this.$refs.modal.hide();
                    this.selectedKids.length > 10 ? location.reload() : null;
                }
            },
        },
        methods: {
            save() {
                this.remainingRequests = this.selectedKids.length;
                this.loading = true;

                const absence = _cloneDeep(this.absence);
                absence.started_at = absence.started_at ? Epoch.parse(absence.started_at, 'yyyy-MM-dd HH:mm').toTimestamp() : null;
                absence.ended_at = absence.ended_at ? Epoch.parse(absence.ended_at, 'yyyy-MM-dd HH:mm').toTimestamp() : null;

                this.selectedKids.forEach(kid => {
                    setTimeout(() => {
                        absence.kid_id = kid.id;
                        this.storeAbsence(absence);
                    }, 300);
                });
            },

            storeAbsence(absence) {
                useApi().legacy.post(
                    route('kid.absences.store'),
                    absence,
                )
                    .then(response => {
                        this.selectedKids.length <= 10 ? this.$bus.$emit('created:absence', response.data) : null;
                        this.remainingRequests--;
                    })
                    .catch(error => {
                        this.errored = true;
                        this.remainingRequests--;
                        if (error?.response?.status === 422) {
                            _forEach(error.response.data.errors, value => {
                                useNotification().error(_head(value));
                            });
                        } else {
                            useNotification().error(error);
                        }
                    });
            },

            modalShown() {
                this.loading = false;
                this.selectedKids = [];
                this.selectingKids = true;
                this.remainingRequests = 0;

                const opening = this.$getOpening(this.nursery.openingHours, false);
                const closing = this.$getClosing(this.nursery.openingHours, false);

                // Convert `date` props (moment) to Epoch and format it
                const date = Epoch.fromJSDate(this.date.toDate()).toFormat('yyyy-MM-dd');

                this.absence.started_at = Epoch.parse(`${date} ${opening}`, 'yyyy-MM-dd HH:mm:ss').toFormat('yyyy-MM-dd HH:mm');
                this.absence.ended_at = Epoch.parse(`${date} ${closing}`, 'yyyy-MM-dd HH:mm:ss').toFormat('yyyy-MM-dd HH:mm');
                this.absence.nursery_id = this.nursery.id;
            },
        },
    };
</script>
