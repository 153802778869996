<template>
    <CFormSelect
        v-model="selectedConfig"
        :disablec="disabled"
        :options="configs"
        :search-bar="false"
    />
</template>

<script lang="ts">
    import type {PropType} from 'vue';
    import {defineComponent, ref, watch} from 'vue';
    import FrequencyValue from '@/modules/calendar/utils/RuleSet/values/FrequencyValue';
    import DirectionValue from '@/modules/calendar/utils/RuleSet/values/DirectionValue';
    import OptionValue from '@/modules/calendar/utils/RuleSet/values/OptionValue';
    import Rule from '@/modules/calendar/utils/RuleSet/Rule';
    import useRuleRef from '@/modules/calendar/composables/RuleSet/useRuleRef';
    import {collect} from '@meekohq/lumos';
    import _isEqual from 'lodash-es/isEqual';
    import DayValue from '@/modules/calendar/utils/RuleSet/values/DayValue';
    import type RuleInterface from '@/modules/calendar/utils/RuleSet/RuleInterface';

    export type ConfigType =
        { interval?: number, freq: FrequencyValue, options?: OptionValue[], direction?: DirectionValue }
        | 'custom';
    export type ConfigsType = Array<{ value: ConfigType, text: string }>;

    export default defineComponent({
        props: {
            value: {
                type: Object as PropType<Rule>,
                default: undefined,
            },
            configs: {
                type: Array as PropType<ConfigsType>,
                required: true,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
        },
        setup(props, {emit}) {
            const ruleCopy = ref<Rule>(props.value ? Rule.fromJSON(props.value) : new Rule());
            const {reset: resetRule} = useRuleRef(ruleCopy);

            const selectedConfig = ref<ConfigType>();

            function getDirection(rule: Rule) {
                if (rule.interval === undefined || rule?.interval > 0) {
                    return DirectionValue.futur;
                } else {
                    return DirectionValue.past;
                }
            }

            // Init selectedConfig
            selectedConfig.value = collect(props.configs).first(config => {
                if (!props.value || config.value === 'custom') {
                    return false;
                }

                let equal = true;

                if (config.value.direction !== undefined && !_isEqual(config.value?.direction, getDirection(props.value))) {
                    equal = false;
                }

                if (config.value.options !== undefined && !_isEqual(config.value?.options, props.value.options)) {
                    equal = false;
                }

                if (!_isEqual(config.value?.freq, props.value.freq)) {
                    equal = false;
                }

                return equal;
            })?.value;

            // Update ruleset on selector value changes
            watch(selectedConfig, config => {
                if (!config) {
                    return;
                }

                if (config === 'custom') {
                    // Set default custom config
                    resetRule({
                        interval: -1,
                        freq: FrequencyValue.minute,
                        count: 1,
                        options: [OptionValue.excludeStartDate],
                        wkst: DayValue.monday,
                    });

                    emit('select:custom', config);
                } else {
                    // If direction, update interval
                    if (config.direction) {
                        if (config.interval) {
                            config.interval = config.direction === DirectionValue.futur
                                ? Math.abs(config.interval)
                                : -Math.abs(config.interval);
                        }

                        if (props.value?.interval) {
                            config.interval = config.direction === DirectionValue.futur
                                ? Math.abs(props.value?.interval)
                                : -Math.abs(props.value?.interval);
                        }

                    }

                    // Remove direction of config to set rule
                    delete config.direction;

                    const newConfig = {...config as RuleInterface};

                    // One rep (count) is set by default except for the "once" frequency because it's implicite
                    if (newConfig.freq !== FrequencyValue.once) {
                        newConfig.count = 1;
                    }

                    // Set selected rule config
                    resetRule(newConfig);
                }

                emit('input', ruleCopy.value);
            });

            return {
                selectedConfig,
            };
        },
    });
</script>
