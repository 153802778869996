<script setup lang="ts">
    import useAbility from '@/modules/app/composables/useAbility';

    defineProps({
        selectedTemplate: {
            type: Object,
            required: true,
        },
        value: {
            type: File,
            default: null,
        },
    });

    const {can} = useAbility();
</script>

<template>
    <MBox class="DocTemplatesManagerWordFile">
        <div class="DocTemplatesManagerWordFile__form-container">
            <CForm class="DocTemplatesManagerWordFile__form">
                <CFormGroup
                    v-if="selectedTemplate.filename && selectedTemplate.file_url"
                    class="tw-mb-8"
                >
                    <CLabel>
                        {{ __('organization:current_file_name_colon') }}
                    </CLabel>
                    <CButtonGroup class="tw-w-full">
                        <MInput
                            :disabled="true"
                            :value="selectedTemplate.filename"
                        />
                        <a
                            id="downloadWordFile"
                            :href="selectedTemplate.file_url"
                            target="_blank"
                        >
                            <MButton class="tw-shrink-0">
                                <template #left-icons>
                                    <FontAwesomeIcon icon="fas fa-download"/>
                                </template>
                                {{ __('common:actions.download') }}
                            </MButton>
                        </a>
                    </CButtonGroup>
                </CFormGroup>
                <CFormGroup>
                    <CLabel>
                        {{ __('document:select_new_file') }}
                    </CLabel>
                    <CFileInput
                        class="tw-w-full"
                        :disabled="!can('update', 'doc_templates')"
                        :max-size="104857600"
                        :value="value"
                        @input="$emit('input', $event)"
                    />
                    <div class="DocTemplatesManagerWordFile__info">
                        <FontAwesomeIcon icon="fad fa-circle-info"/>
                        {{ __('organization:only_files_in_docx_format_are_accepted') }}
                    </div>
                </CFormGroup>
                <CFormGroup>
                    <CLabel>
                        {{ __('organization:to_fill_word_file_colon') }}
                    </CLabel>
                    <MButton
                        class="tw-w-full"
                        icon-external-link
                        :label="__('organization:following_guide')"
                        onclick="Intercom('showArticle', 2543786)"
                    />
                </CFormGroup>
            </CForm>
        </div>
    </MBox>
</template>

<style lang="scss" scoped>
    .DocTemplatesManagerWordFile {
        @apply tw-p-4;
    }

    .DocTemplatesManagerWordFile__form-container {
        @apply tw-flex tw-flex-col tw-items-center;
    }

    .DocTemplatesManagerWordFile__form {
        @apply sm:tw-w-auto tw-w-full;
    }

    .DocTemplatesManagerWordFile__info {
        @apply tw-flex tw-flex-row tw-items-baseline tw-gap-1 tw-mt-2 tw-text-sm tw-text-gray-500;
    }
</style>
