<template>
    <div :class="baseClass">
        <div class="tw--m-1 list tw-flex tw-flex-wrap">
            <slot/>
        </div>
    </div>
</template>

<script lang="ts">
    import {computed, defineComponent} from 'vue';

    export default defineComponent({
        components: {},
        props: {
            variant: {type: String, default: 'default'},
        },
        setup(props) {
            const baseClass = computed(() => {
                const output = [] as any[];

                output.push('tw-rounded-xl tw-p-2 tw-shadow-md');

                const variants = {
                    default: 'tw-bg-blue-400',
                    orange: 'bg-billing-gradient',
                    pink: 'bg-hr-gradient',
                    white: 'tw-bg-white',
                    settings: 'tw-bg-gray-400',
                    equipment: 'tw-bg-gradient-to-br tw-from-module-equipment-500 tw-to-module-equipment-600',
                    family: 'tw-bg-gradient-to-br tw-from-purple-500 tw-to-purple-600',
                };
                output.push(variants[props.variant]);

                return output;
            });

            return {baseClass};
        },
    });
</script>

<style
    lang="scss"
    scoped
>
</style>
