<template>
    <apexcharts
        v-if="chartOptions"
        :height="height"
        :options="chartOptions"
        :series="series"
        :type="type"
        :width="width"
    />
</template>

<script>
    import VueApexCharts from 'vue-apexcharts';

    export default {
        components: {
            apexcharts: VueApexCharts,
        },
        props: {
            series: {
                type: Array,
                required: true,
            },
            displayedPercent: {
                type: Number,
                required: true,
            },
            colors: {
                type: Array,
                required: true,
            },
            colorsTo: {
                type: Array,
                required: true,
            },
            height: {
                type: Number,
                default: 165,
            },
            width: {
                type: Number,
                default: 165,
            },
            type: {
                type: String,
                default: 'radialBar',
            },
        },
        data: () => ({
            chartOptions: null,
        }),
        mounted() {
            this.updateChart();
        },
        methods: {
            updateChart() {
                const self = this;
                self.chartOptions = {
                    chart: {
                        type: self.type,
                        height: self.height,
                        width: self.width,
                    },
                    plotOptions: {
                        radialBar: {
                            offsetY: -20,
                            track: {
                                background: '#fff',
                                dropShadow: {
                                    enabled: true,
                                    blur: 0.7,
                                    opacity: 0.2,
                                },
                            },
                            dataLabels: {
                                name: {
                                    show: false,
                                },
                                value: {
                                    show: true,
                                    color: self.colorsTo[0],
                                    fontSize: '100%',
                                    offsetY: 5,
                                    formatter() {
                                        return self.displayedPercent + '%';
                                    },
                                },
                            },
                        },
                    },
                    fill: {
                        type: 'gradient',
                        colors: self.colors,
                        gradient: {
                            gradientToColors: self.colorsTo,
                            type: 'horizontal',
                            inverseColors: true,
                            stops: [0, 100],
                        },
                    },
                    stroke: {
                        lineCap: 'round',
                    },
                };
            },
        },
    };
</script>
