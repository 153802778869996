<script setup lang="ts">
    import __ from '@/modules/app/utils/i18n-facade';
    import type {ModalType} from '@/modules/app/composables/useModal';
    import useError from '@/modules/app/composables/useError';
    import {computed, ref} from 'vue';
    import type {Epoch} from '@meekohq/lumos';
    import {catcher, MqlForbiddenError, MqlOperation, ValidationError} from '@meekohq/lumos';
    import useAbility from '@/modules/app/composables/useAbility';
    import InvoiceStatusValue from '@/modules/cashier/utils/billing/invoice/InvoiceStatusValue';
    import type {Operation} from '@meekohq/lumos/dist/Http/Mql';
    import type {ErrorsObject} from '@meekohq/lumos/dist/Http/ResponseObject';

    const props = withDefaults(defineProps<{
        modal: ModalType,
        invoiceId: string,
        invoiceStatus: string,
        date: Epoch | null,
        description?: string | null,
    }>(), {
        date: null,
        description: null,
    });

    const emit = defineEmits<{
        (e: 'saved'): void,
    }>();

    const errorHandler = useError();

    const {can} = useAbility();

    const isSaving = ref(false);
    const uncollectibleDate = ref(props.date ? props.date.toFormat('yyyy-MM-dd') : null);
    const uncollectibleDescription = ref(props.description);

    const isDateRequired = computed(() => props.invoiceStatus !== InvoiceStatusValue.uncollectible);

    async function save() {
        errorHandler.reset();

        if (isDateRequired.value && !uncollectibleDate.value) {
            errorHandler.addErrorCode('date', '0x2EAA809FB3');

            return;
        }

        try {
            await new MqlOperation('cashier/update_invoice_uncollectible_details', {
                invoice_id: props.invoiceId,
                uncollectible_date: uncollectibleDate.value ? uncollectibleDate.value : null,
                uncollectible_description: uncollectibleDescription.value?.length ? uncollectibleDescription.value : null,
            }).run();

            emit('saved');
        } catch (err) {
            catcher()
                .on(MqlForbiddenError, mqlForbiddenError => {
                    const operation = mqlForbiddenError.operation as Operation;
                    const content = operation.result.content as ErrorsObject;

                    if (content.data[0].code) {
                        errorHandler.addErrorCode('generalError', content.data[0].code);
                    }
                })
                .on(ValidationError, validationError => {
                    errorHandler.addValidationError(validationError);
                })
                .catch(err);
        } finally {
            isSaving.value = false;
        }
    }
</script>

<template>
    <MModal
        :header-title="__('billing_invoice:uncollectible_invoice')"
        :modal="modal"
    >
        <CForm>
            <CFormGroup>
                <CLabel>{{ __('billing_invoice:uncollectible_since') }}</CLabel>
                <MDatePicker
                    v-model="uncollectibleDate"
                    class="tw-w-full"
                    :disabled="!can('update', 'invoices')"
                    format="yyyy-MM-dd"
                />
                <div class="UncollectibleInvoiceModal__helper">
                    <FontAwesomeIcon icon="fad fa-info-square"/>
                    {{ __('billing_invoice:let_empty_to_cancel_uncollectible_status') }}
                </div>
                <CFormErrorMessageList :errors="errorHandler.get('date')"/>
            </CFormGroup>
            <CFormGroup>
                <CLabel>{{ __('common:description') }}</CLabel>
                <CFormTextArea
                    v-model="uncollectibleDescription"
                    :disabled="!can('update', 'invoices')"
                    :placeholder="__('common:note_one')"
                />
                <CFormErrorMessageList :errors="errorHandler.get('description')"/>
            </CFormGroup>
            <CFormGroup v-if="errorHandler.has('generalError')">
                <CFormErrorMessageList :errors="errorHandler.get('generalError')"/>
            </CFormGroup>
        </CForm>
        <template #footer-end="{hide}">
            <MButton
                :label="__('common:actions.cancel')"
                @click="hide"
            />
            <MButton
                v-if="can('update', 'invoices')"
                :label="__('common:actions.save')"
                :loading="isSaving"
                variant="primary"
                @click="save"
            />
        </template>
    </MModal>
</template>

<style lang="scss" scoped>
    .UncollectibleInvoiceModal__helper {
        @apply tw-text-gray-500 tw-mt-1 tw-text-sm;
    }
</style>
