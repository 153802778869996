<template>
    <CVStack
        :as="as"
        :gap="gap"
        v-on="$listeners"
    >
        <slot/>
    </CVStack>
</template>

<script lang="ts">
    import {defineComponent} from 'vue';

    export default defineComponent({
        components: {},
        props: {
            as: {type: String, default: 'form'},
            gap: {type: String, default: '4'},
        },
        setup() {
            return {};
        },
    });
</script>
