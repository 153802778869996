<template>
    <div
        class="tw-w-full"
        @mouseleave="dropdown.hide(200)"
        @mousemove="dropdown.show(200)"
    >
        <div :class="wrapperBaseClass">
            <router-link
                class="tw-block tw-text-reset tw-no-underline"
                :to="{name:'kids.show',params:{nursery:activeOrganization.id, kid:kidModel.id}}"
            >
                <div :class="baseClass">
                    <div class="tw-flex tw-items-center tw-relative">
                        <KidAvatar
                            class="tw-w-10"
                            :kid-model="kidModel"
                        />
                        <div class="tw-min-w-0 tw-ml-2">
                            <div class="tw-font-display tw-text-white tw-truncate tw-text-base">
                                {{ kidModel.attributes.first_name }}
                            </div>
                            <div class="tw-flex tw-flex-wrap tw-items-center">
                                <div>
                                    <div class="tw-mr-1 tw-whitespace-nowrap tw-text-sm tw-px-1 tw-bg-white tw-text-black tw-opacity-75 tw-font-semibold tw-rounded">
                                        {{ kidModel.humanAge }}
                                    </div>
                                </div>
                                <div class="tw-min-w-0">
                                    <div
                                        v-if="groupName"
                                        class="tw-text-sm tw-text-white tw-truncate"
                                    >
                                        {{ groupName }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </router-link>
        </div>
        <CDropdown
            ref="dropdown"
            legacy-mode
            no-padding
            placement="bottom-start"
        >
            <KidProfile :kid-model="kidModel"/>
        </CDropdown>
    </div>
</template>

<script lang="ts">
    import type KidModel from '@/modules/family/models/KidModel';
    import KidProfile from '@/modules/family/components/kid/KidProfile.vue';
    import {find} from 'lodash-es';
    import type {PropType} from 'vue';
    import {computed, defineComponent, ref} from 'vue';
    import useManager from '@/modules/app/composables/useManager';
    import KidAvatar from '@/modules/app/components/atoms/avatars/KidAvatar.vue';

    export default defineComponent({
        components: {KidAvatar, KidProfile},
        props: {
            kidModel: {type: Object as PropType<KidModel>, required: true},
            groups: {type: Array, default: () => [], required: false},
        },
        setup(props) {
            const dropdown = ref(null);
            const {activeOrganization} = useManager();

            const groupName = computed(() => {
                const group = find(props.groups, (group: any) => {
                    return group.id === props.kidModel.attributes.group_id;
                }) as any;

                if (group) {
                    return group.name;
                }

                return null;
            });

            const wrapperBaseClass = computed(() => {
                const output = [] as any[];

                const variants = {
                    male: 'boy-gradient',
                    female: 'girl-gradient',
                };
                output.push(variants[props.kidModel.attributes.gender as string]);

                output.push([
                    'tw-shadow',
                    'hover:tw-scale-105',
                    'tw-cursor-pointer',
                ]);

                output.push('tw-rounded-lg');

                output.push([
                    'tw-transition',
                    'tw-duration-150',
                    'tw-ease-out',
                ]);

                return output;
            });

            const baseClass = computed(() => {
                const output = [] as any[];

                output.push('tw-p-3');

                return output;
            });

            return {
                dropdown,
                activeOrganization,
                groupName,
                wrapperBaseClass,
                baseClass,
            };
        },
    });
</script>

<style
    lang="scss"
    scoped
>
    .boy-gradient {
        background-image: linear-gradient(to bottom right, theme('colors.boy.gradient.from'), theme('colors.boy.gradient.to'));
    }

    .girl-gradient {
        background-image: linear-gradient(to bottom right, theme('colors.girl.gradient.from'), theme('colors.girl.gradient.to'));
    }
</style>
