<template>
    <transition
        mode="out-in"
        name="fade"
    >
        <div>
            <div class="row">
                <div class="col-12 col-lg-4 col-md-6 mb-3 mb-md-0">
                    <MHeading class="tw-mb-2">
                        <i
                            aria-hidden="true"
                            class="fab fa-pagelines text-success mr-2"
                        />{{ __('family_kid:foods.allergies') }}
                    </MHeading>
                    <ul class="list-group mb-3">
                        <li
                            v-if="kid.allergies.length === 0"
                            class="list-group-item p-2 pl-3 pr-3 text-muted"
                        >
                            <em>{{ __('family_kid:empty_allergy') }}</em>
                        </li>
                        <li
                            v-for="(allergy, i) in _sortBy(_filter(kid.allergies, {type: 'food'}), 'name')"
                            :key="i"
                            class="list-group-item p-2 pl-3 pr-3"
                        >
                            {{ allergy.name }}
                        </li>
                    </ul>
                </div>
                <div class="col-12 col-lg-8">
                    <MHeading class="tw-mb-2">
                        <i
                            aria-hidden="true"
                            class="far fa-thumbs-up text-primary mr-2"
                        />{{ __('family_kid:foods.eating_habits') }}
                    </MHeading>
                    <div class="row">
                        <div class="col-12 col-md-6">
                            <div class="form-group">
                                <label class="label form-label">{{ __('family_kid:meal_type_colon') }}</label>
                                <div>
                                    <div class="form-check form-check-inline">
                                        <label class="label form-check-label">
                                            <input
                                                v-model="kid.meal_type"
                                                class="form-check-input"
                                                :disabled="!$can('update', 'kids_food')"
                                                type="radio"
                                                value="milk"
                                                @click="switchMeal"
                                            >{{ __('common:meal_types.milk') }}
                                        </label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <label class="label form-check-label">
                                            <input
                                                v-model="kid.meal_type"
                                                class="form-check-input"
                                                :disabled="!$can('update', 'kids_food')"
                                                type="radio"
                                                value="mixed"
                                                @click="switchMeal"
                                            >{{ __('common:meal_types.mixed') }}
                                        </label>
                                    </div>
                                </div>
                                <div>
                                    <div class="form-check form-check-inline">
                                        <label class="label form-check-label">
                                            <input
                                                v-model="kid.meal_type"
                                                class="form-check-input"
                                                :disabled="!$can('update', 'kids_food')"
                                                type="radio"
                                                value="crushed"
                                                @click="switchMeal"
                                            >{{ __('common:meal_types.crushed') }}
                                        </label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <label class="label form-check-label">
                                            <input
                                                v-model="kid.meal_type"
                                                class="form-check-input"
                                                :disabled="!$can('update', 'kids_food')"
                                                type="radio"
                                                value="mix"
                                                @click="switchMeal"
                                            >{{ __('common:meal_types.mix') }}
                                        </label>
                                    </div>
                                </div>
                                <div>
                                    <div class="form-check form-check-inline">
                                        <label class="label form-check-label">
                                            <input
                                                v-model="kid.meal_type"
                                                class="form-check-input"
                                                :disabled="!$can('update', 'kids_food')"
                                                type="radio"
                                                value="normal"
                                                @click="switchMeal"
                                            >{{ __('common:meal_types.normal') }}
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-6">
                            <div class="form-group">
                                <div class="form-check">
                                    <label class="label form-check-label">
                                        <input
                                            v-model="kid.vegetarian"
                                            class="form-check-input"
                                            :disabled="!$can('update', 'kids_food')"
                                            type="checkbox"
                                            value="true"
                                        >
                                        {{ __('family_kid:vegetarian', {context: kid.gender}) }}
                                    </label>
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="label form-label">{{ __('family_kid:specialized_meat_diet_colon') }}</label>
                                <textarea
                                    v-model="kid.meat_diet"
                                    class="form-control"
                                    :disabled="!$can('update', 'kids_food')"
                                    rows="2"
                                />
                                <div class="form-row">
                                    <div class="col-6">
                                        <small class="small form-text text-muted">
                                            {{ __('family_kid:leave_empty_if_no') }}
                                        </small>
                                    </div>
                                    <div
                                        v-if="$can('update', 'kids_food')"
                                        class="col-6 text-right"
                                    >
                                        <button
                                            class="btn btn-success btn-sm mt-3"
                                            @click="save()"
                                        >
                                            {{ __('common:actions.save') }}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mt-3">
                <MHeading class="tw-mb-2">
                    <i
                        aria-hidden="true"
                        class="fas fa-utensils text-info mr-2"
                    />{{ __('family_kid:foods.introductions') }}
                </MHeading>
                <food-list
                    :foods="foods"
                    :kid="kid"
                />
            </div>
        </div>
    </transition>
</template>

<script>
    import route from '@/modules/legacy/libs/ziggy';
    import useApi from '@/modules/app/composables/useApi';
    import moment from 'moment';
    import swal from 'sweetalert2/dist/sweetalert2.js';
    import __ from '@/modules/app/utils/i18n-facade';
    import _head from 'lodash-es/head';
    import _forEach from 'lodash-es/forEach';
    import _sortBy from 'lodash-es/sortBy';
    import _filter from 'lodash-es/filter';
    import FoodList from './FoodList.vue';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';

    export default {
        components: {
            FoodList,
        },
        props: ['kid', 'allergies', 'foods'],
        data: () => ({
            savedMealType: null,
        }),
        computed: {
            moment() {
                return moment;
            },
            name() {
                return this.kid.first_name + ' ' + this.kid.last_name;
            },
            _sortBy() {
                return _sortBy;
            },
            _filter() {
                return _filter;
            },
        },
        mounted() {
            this.savedMealType = this.kid.meal_type;
        },
        methods: {
            save() {
                useApi().legacy.put(route('kids.update', {kid: this.kid.id}), {
                    meal_type: this.kid.meal_type,
                    vegetarian: this.kid.vegetarian,
                    meat_diet: this.kid.meat_diet,
                }).then(() => {
                    useNotification().success(__('family_kid:power_preferences_updated_successfully'));
                }).catch(error => {
                    if (error && error.response && error.response.status === 422) {
                        _forEach(error.response.data.errors, value => {
                            useNotification().error(_head(value));
                        });
                    } else {
                        useNotification().error(error);
                    }
                });
            },

            switchMeal() {
                swal({
                    title: __('family_kid:change_meal_types_ask'),
                    text: __('family_kid:changing_the_meal_type_may_affect_the_price_of_future_invoices'),
                    type: 'warning',
                    confirmButtonClass: 'btn btn-primary mr-2',
                    confirmButtonText: __('common:actions.update'),
                    cancelButtonText: __('common:actions.cancel'),
                }).then(result => {
                    if (result.value) {
                        //this.save();
                    } else {
                        this.kid.meal_type = this.savedMealType;
                        this.savedMealType = this.kid.meal_type;
                    }
                });
            },
        },
    };
</script>
