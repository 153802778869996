<template>
    <svg
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M12 2C6.49 2 2 6.49 2 12s4.49 10 10 10h5v-2h-5c-4.43 0-8-3.57-8-8s3.57-8 8-8 8 3.57 8 8v1.5c0 .84-.66 1.5-1.5 1.5s-1.5-.66-1.5-1.5V12c0-2.75-2.25-5-5-5s-5 2.25-5 5 2.25 5 5 5c1.41 0 2.69-.6 3.6-1.55A3.5 3.5 0 0 0 22 13.5V12c0-5.51-4.49-10-10-10zm0 7c1.67 0 3 1.33 3 3s-1.33 3-3 3-3-1.33-3-3 1.33-3 3-3z"
            font-family="sans-serif"
            font-weight="400"
            overflow="visible"
            style="line-height:normal;text-indent:0;text-align:start;text-decoration-line:none;text-decoration-style:solid;text-decoration-color:#000;text-transform:none;block-progression:tb;isolation:auto;mix-blend-mode:normal"
        />
    </svg>
</template>

<script lang="ts">
    import {defineComponent} from 'vue';

    export default defineComponent({});
</script>
