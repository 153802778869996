<template>
    <CInline space="sm">
        <CInline v-if="!isContractsExist">
            <CBadge>
                {{ jobModel.attributes.created_at }}
                {{ __('common:unused') }}
            </CBadge>
        </CInline>
        <CInline space="sm">
            <CTooltip
                v-if="jobModel.attributes.archived_at"
                placement="top"
            >
                <CBadgeArchived
                    variant="orange-tooltip"
                    :zoom="true"
                />
                <template #content>
                    {{ __('common:archived') }}
                </template>
            </CTooltip>

            <CTooltip
                v-if="isJobExistInOtherOrganization"
                class="tw-flex tw-items-center tw-justify-center"
                placement="top"
            >
                <i
                    aria-hidden="true"
                    class="fas fa-link tw-text-gray-700"
                />
                <template #content>
                    {{ __('hr:present_in_mutliple_organizations') }}
                </template>
            </CTooltip>
            <slot name="update"/>
        </CInline>
    </CInline>
</template>

<script>
    import Manager from '@/modules/legacy/store/manager.store';
    import CBadgeArchived from '@/modules/coherence-ui/components/Tools/CBadgeArchived.vue';
    import {isEmpty} from 'lodash-es';

    export default {
        components: {CBadgeArchived},
        props: {
            jobModel: {
                required: true,
            },
            jobPivotInOtherOrganization: {
                required: true,
            },
        },

        data() {
            return {
                Manager,
            };
        },

        computed: {
            isContractsExist() {
                return !isEmpty(this.jobModel?.relationships?.contracts);
            },

            isJobExistInOtherOrganization() {
                return !isEmpty(this.jobPivotInOtherOrganization.all());
            },
        },

    };
</script>

<style
    lang="scss"
    scoped
>
</style>
