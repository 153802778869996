import {Model} from '@meekohq/lumos';


export default class MeetingPivot extends Model {

    public type = 'activity/meetings_pivot';

    public attributes: {
        id: string,
        account_id: string | undefined,
        meeting_id: string | undefined,
        resource_id: string | undefined,
        resource_type: string | undefined,
    } = {
            id: this.uuid(),
            account_id: undefined,
            meeting_id: undefined,
            resource_id: undefined,
            resource_type: undefined,
        };
}
