<template>
    <component :is="dynamicTemplate">
        <template #icon>
            <div
                :class="iconClass"
                @click="updatedCompleteStatus(!task.isCompleted)"
            >
                <FontAwesomeIcon icon="fa-solid fa-check"/>
            </div>
        </template>
        <template #title>
            <CHStack
                align="center"
                distribute="between"
            >
                <CVStack
                    class="tw-flex-1 sm:tw-rounded-lg sm:tw-p-2"
                    :class="{'sm:hover:tw-bg-gray-50 sm:tw-transition-colors sm:tw-ease-in-out tw-cursor-pointer': !isEditing}"
                    @click="editOnClick"
                >
                    <CText
                        class="tw-break-all"
                        font-size="lg"
                    >
                        <template v-if="!task.exists">
                            {{ __('registration:title') }}
                        </template>
                        <CHStack v-else>
                            <MTooltip
                                v-if="recurrence"
                                class="tw-flex tw-items-center tw-justify-center"
                                :label="recurrenceDetails"
                                placement="top"
                            >
                                <FontAwesomeIcon
                                    class="tw-mr-1 tw-text-gray-500"
                                    icon="fa-solid fa-repeat"
                                    size="sm"
                                />
                            </MTooltip>
                            <CLongText :value="task.attributes.name"/>
                        </CHStack>
                    </CText>
                    <CText variant="disabled">
                        <template v-if="task.attributes.completed_at">
                            <span v-if="completedBy">
                                {{ completedBy.fullname }}
                            </span>
                            <span>
                                {{ __('registration:completed_by_at_short') }}
                                {{ Epoch.fromISOString(task.attributes.completed_at).toLocaleString(Epoch.presets.DATETIME_SHORT) }}
                            </span>
                        </template>
                        <template v-else-if="assignee">
                            {{ assignee.fullname }}
                        </template>
                    </CText>
                </CVStack>
            </CHStack>
        </template>
        <template #date>
            <CHStack
                align="center"
                class="tw-whitespace-nowrap"
                gap="4"
            >
                <CVStack class="tw-whitespace-nowrap">
                    <CText
                        v-if="task.attributes.expired_at"
                        class="tw-cursor-help"
                        :font-weight="task.isLate ? 'semibold' : 'normal'"
                        :variant="task.isLate ? 'danger' : 'gray'"
                        @mouseout.native="$refs.datesDropdown.hide()"
                        @mouseover.native="$refs.datesDropdown.show()"
                    >
                        {{
                            useDuration().toHuman({
                                dateFrom: Epoch.now(),
                                dateTo: Epoch.fromISOString(task.attributes.expired_at),
                                options: {
                                    autoSuffix: true,
                                },
                            })
                        }}
                    </CText>
                    <CDropdown
                        ref="datesDropdown"
                        legacy-mode
                    >
                        <div>
                            <div class="tw-p-3">
                                <CText as="div">
                                    {{
                                        __('registration:todo_at', {
                                            date: Epoch.fromISOString(task.attributes.expired_at).toLocaleString(Epoch.presets.DATETIME_SHORT),
                                        })
                                    }}
                                </CText>
                                <CText
                                    v-if="task.attributes.completed_at"
                                    as="div"
                                    class="tw-mt-1"
                                >
                                    <span
                                        v-html="__('registration:completed_at', {
                                            date: Epoch.fromISOString(task.attributes.completed_at).toLocaleString(Epoch.presets.DATETIME_SHORT)
                                        })"
                                    />
                                </CText>
                            </div>
                            <div class="tw-border-t tw-border-gray-200 tw-text-gray-500 tw-p-3">
                                <CText
                                    v-if="reporter"
                                    as="div"
                                    font-size="sm"
                                >
                                    {{ __('registration:reporter') }}
                                    {{ reporter.fullname }}
                                </CText>
                                <CText
                                    v-if="task.computed.created_at"
                                    as="div"
                                    font-size="sm"
                                >
                                    {{ __('common:the') }}
                                    {{ Epoch.fromISOString(task.computed.created_at).toLocaleString(Epoch.presets.DATETIME_SHORT) }}
                                </CText>
                            </div>
                        </div>
                    </CDropdown>
                </CVStack>
            </CHStack>
        </template>
        <template #actions>
            <CDropdownMenu v-if="can('update', 'tasks') || can('delete', 'tasks')">
                <template #button>
                    <CButton icon-ellipsis/>
                </template>
                <CDropdownMenuItem
                    v-if="can('update', 'tasks')"
                    :disabled="isEditing"
                    icon="fa fa-pen"
                    :label="__('common:actions.update')"
                    @click="setIsEditing(true)"
                />
                <CDropdownMenuItem
                    v-if="can('delete', 'tasks')"
                    icon="fa fa-trash"
                    :label="__('common:actions.delete')"
                    variant="danger"
                    @click="remove"
                />
            </CDropdownMenu>
        </template>
        <template
            v-if="isEditing && isModalEditing"
            #modal
        >
            <CModalPortal>
                <CModal
                    :header-title="__('activity:edit_task')"
                    :hide-footer="true"
                    visible
                    @closed="setIsEditing(false)"
                >
                    <TaskForm
                        :multi-resources="multiResources"
                        :task="editedTask"
                        @saved="save"
                        @undo="cancel"
                    />
                </CModal>
            </CModalPortal>
        </template>
        <template
            v-if="(isEditing && !isModalEditing) || task.attributes.completion_note || task.attributes.description || showResources"
            #content
        >
            <TaskForm
                v-if="isEditing && !isModalEditing"
                :multi-resources="multiResources"
                :task="editedTask"
                @saved="save"
                @undo="cancel"
            />
            <div
                v-else
                class="tw-flex tw-flex-col tw-gap-2"
            >
                <ResourceList
                    v-if="showResources"
                    :resources="resources"
                    resources-are-links
                />
                <div v-if="task.attributes.completion_note || task.attributes.description">
                    <FontAwesomeIcon
                        class="tw-mr-1 tw-text-blue-500"
                        icon="fad fa-comment-lines"
                    />
                    <CText class="tw-flex-1 tw-whitespace-pre-line">
                        {{ task.attributes.completion_note ? task.attributes.completion_note : task.attributes.description }}
                    </CText>
                </div>
            </div>
        </template>
    </component>
</template>

<script lang="ts">
    import type {PropType} from 'vue';
    import {computed, defineComponent, ref, watch} from 'vue';
    import {Epoch} from '@meekohq/lumos';
    import useAuth from '@/modules/app/composables/useAuth';
    import StaffModel from '@/modules/human-resources/models/StaffModel';
    import type TicketModel from '@/modules/activity/ticket/domain/TicketModel';
    import TaskForm from '@/modules/activity/components/TaskForm.vue';
    import useAbility from '@/modules/app/composables/useAbility';
    import AbstractItem from '@/modules/registration/components/molecules/Timeline/AbstractItem.vue';
    import useDeleteTask from '@/modules/activity/composables/useDeleteTask';
    import useRuleSetHumanDetails from '@/modules/calendar/composables/RuleSet/useRuleSetHumanDetails';
    import ResourceList from '@/modules/app/components/resource/organisms/ResourceList.vue';
    import {useToggle} from '@vueuse/core';
    import AbstractItemWithModal from '@/modules/registration/components/molecules/Timeline/AbstractItemWithModal.vue';
    import useDuration from '@/modules/app/composables/useDuration';

    export default defineComponent({
        components: {TaskForm, AbstractItem, AbstractItemWithModal, ResourceList},
        props: {
            task: {
                type: Object as PropType<TicketModel>,
                required: true,
            },
            resources: {
                type: Array as PropType<any[]>,
                default: () => [],
            },
            showResources: {type: Boolean, default: true},
            display: {type: String, default: 'item'},
            isModalEditing: {type: Boolean, default: false},
            multiResources: {type: Boolean, default: true},
        },
        setup(props, {emit}) {
            const dynamicTemplate = computed(() => {
                if (props.display === 'item-with-modal') {
                    return 'AbstractItemWithModal';
                }

                return 'AbstractItem';
            });

            const {user} = useAuth();
            const userStaff = ref(new StaffModel());

            const reporter = computed(() => props.task.reporter().value());
            const assignee = computed(() => props.task.assignee().value());
            const completedBy = computed(() => props.task.completedBy().value());
            const recurrence = computed(() => props.task.attributes.parent_id
                ? props.task.parent().value().attributes.recurrence_ruleset
                : props.task.attributes.recurrence_ruleset,
            );

            const {getRuleSetDetails} = useRuleSetHumanDetails();
            const recurrenceDetails = computed(() => getRuleSetDetails(recurrence.value, 'recurrence'));

            const {can} = useAbility();

            const [isEditing, setIsEditing] = useToggle(!props.task.exists);

            // Edition functions
            const editedTask = ref();

            const taskTicketPivots = computed(() => props.task.ticketPivots().value());

            // Keep edited task updated
            watch([() => props.task, taskTicketPivots], () => {
                editedTask.value = props.task.clone();
            }, {immediate: true});

            const {askDeleteTask} = useDeleteTask();

            // Update ticket with saved data and create pivot if not exists
            async function save(task: TicketModel) {
                // Re-sync task with cache data to keep on track
                props.task.pullFromCache();
                task.copyTo(props.task);
                props.task.extra = task.extra;

                setIsEditing(false);
                emit('saved', props.task);
            }

            async function remove() {
                emit('preventClickOutside', true);

                askDeleteTask(props.task, async () => {
                    // Add original attributes and old pivots resource types to task extra, so we can compare changes
                    // and update the task count correctly
                    props.task.extra.oldOriginalAttributes = {
                        ...props.task.getOriginal(),
                    };
                    props.task.extra.oldResourceTypes = props.task.ticketPivots().value().map(ticketPivot => ticketPivot.attributes.resource_type);

                    if (props.task.exists) {
                        await props.task.delete();
                    }
                    emit('preventClickOutside', false);
                    emit('deleted', props.task);
                });
            }

            function cancel() {
                editedTask.value.rollback();
                setIsEditing(false);
                if (!props.task.exists) {
                    emit('deleted', props.task);
                }
            }

            async function updatedCompleteStatus(isComplete) {
                if (!can('update', 'tasks') || !props.task.exists) {
                    return;
                }

                // Add extra properties to keep track of changes in tasks counter
                props.task.extra.oldOriginalAttributes = {
                    ...props.task.getOriginal(),
                };
                props.task.extra.oldResourceTypes = props.task.ticketPivots().value().map(ticketPivot => ticketPivot.attributes.resource_type);

                if (isComplete) {
                    let staff = userStaff.value as StaffModel | undefined;
                    if (staff instanceof StaffModel && !staff.exists) {
                        staff = await StaffModel.query().where('email', user.value.attributes.email).first();
                    }

                    if (staff) {
                        editedTask.value.completedBy().associate(staff);
                    }
                    editedTask.value.attributes.completed_at = Epoch.now().toISOString();
                } else {
                    editedTask.value.attributes.completed_at = null;
                    editedTask.value.completedBy().dissociate();
                }

                await editedTask.value.save();

                editedTask.value.copyTo(props.task);

                emit('completed', props.task);
            }

            function editOnClick() {
                if (can('update', 'tasks') && !isEditing.value) {
                    isEditing.value = true;
                }
            }

            const iconClass = computed(() => {
                const classValues: string[] = ['tw-w-6 tw-h-6 tw-flex tw-items-center tw-justify-center tw-rounded'];

                if (can('update', 'tasks') && props.task.exists) {
                    classValues.push('tw-cursor-pointer');
                    classValues.push('zoom');
                }

                if (props.task.isCompleted) {
                    classValues.push('tw-bg-green-500 tw-text-white');
                } else if (props.task.isLate) {
                    classValues.push('tw-bg-gray-200 tw-text-gray-600 tw-border-2 tw-border-dashed tw-border-red-500');
                } else {
                    classValues.push('tw-bg-gray-200 tw-text-gray-600');
                }

                return classValues;
            });

            watch(isEditing, () => emit('preventClickOutside', isEditing.value));

            return {
                Epoch,
                useDuration,
                dynamicTemplate,
                reporter,
                editedTask,
                remove,
                save,
                cancel,
                assignee,
                completedBy,
                updatedCompleteStatus,
                can,
                iconClass,
                isEditing,
                setIsEditing,
                editOnClick,
                recurrence,
                recurrenceDetails,
            };
        },
    });
</script>
