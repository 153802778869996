<template>
    <div>
        <TagsManagerHeader
            class="tw-mb-8"
            :nurseries="nurseries"
            @importTags="importTags"
            @initNewTagAndShowModal="initNewTagAndShowModal"
        />
        <TagsManagerList
            :categories="categories"
            :tags-with-category="tagsWithCategory"
            @copyTagAndShowModal="copyTagAndShowModal"
        />
        <TagsManagerModal
            v-if="tagModal.isVisible.value"
            :categories="categories"
            :error-handler="errorHandler"
            :get-category-by-type="getCategoryByType"
            :loading="loading"
            :matching-in-other-organization="matchingInOtherOrganization"
            :nurseries="nurseries"
            :selected-tag="selectedTag.value"
            :show-matching-tags="showMatchingTags"
            :tag-modal="tagModal"
            @attachTagToOrganization="attachTagToOrganization"
            @createTag="createTag"
            @detachTag="detachTag"
            @removeTag="removeTag"
            @updateTag="updateTag()"
        />
    </div>
</template>

<script>
    import TagModel from '@/modules/tag/models/TagModel';
    import swal from 'sweetalert2/dist/sweetalert2.js';
    import {catcher, collect, ValidationError} from '@meekohq/lumos';
    import _head from 'lodash-es/head';
    import _forEach from 'lodash-es/forEach';
    import TagsOrganizationsPivot from '@/modules/tag/models/TagsOrganizationsPivot';
    import Manager from '@/modules/legacy/store/manager.store';
    import Mirror from '@/modules/legacy/helpers/mirror.helper';
    import OrganizationModel from '@/modules/organization/models/OrganizationModel';
    import {isEmpty} from 'lodash-es';
    import __ from '@/modules/app/utils/i18n-facade';
    import ErrorHandler from '@/modules/legacy/libs/errors/errorHandler';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';
    import useModal from '@/modules/app/composables/useModal';
    import useMagicModal from '@/modules/app/composables/useMagicModal';
    import TagsManagerHeader from '@/modules/organization/components/TagsManager/TagsManagerHeader.vue';
    import TagsManagerModal from '@/modules/organization/components/TagsManager/TagsManagerModal.vue';
    import TagsManagerList from '@/modules/organization/components/TagsManager/TagsManagerList.vue';

    export default {
        components: {
            TagsManagerList,
            TagsManagerModal,
            TagsManagerHeader,
        },
        props: {
            nursery: {
                type: Object,
                required: true,
            },
            nurseries: {
                type: Array,
                required: true,
            },
        },
        data: () => ({
            categories: [
                {
                    name: __('common:kid_other'),
                    value: 'kid',
                    icon: 'fas fa-child-reaching',
                },
                {
                    name: __('common:team_one'),
                    value: 'staff',
                    icon: 'fad fa-users',
                },
                {
                    name: __('common:parent_other'),
                    value: 'family_member',
                    icon: 'fas fa-user',
                },
                {
                    name: __('common:registration_other'),
                    value: 'registration',
                    icon: 'fad fa-pen-alt',
                },
                {
                    name: __('common:customer_other'),
                    value: 'customer',
                    icon: 'fad fa-file-user',
                },
                {
                    name: __('common:invoice_other'),
                    value: 'invoice',
                    icon: 'fad fa-file-invoice',
                },
                {

                    name: __('common:photos'),
                    value: 'photo',
                    icon: 'fad fa-images',
                },
                {
                    name: __('common:document_other'),
                    value: 'document',
                    icon: 'fad fa-copy fa-swap-opacity',
                },
                {
                    name: __('organization:teams_notes'),
                    value: 'staff_note',
                    icon: 'fad fa-comment-alt-edit',
                },
            ],
            organizations: collect(),
            tags: collect(),
            pivots: collect(),
            showMatchingTags: false,
            organizationModel: Manager.activeOrganization,
            selectedTag: new Mirror(new TagModel()),
            loading: false,
            errorHandler: new ErrorHandler(),
        }),

        computed: {
            organizationTags() {
                return this.pivots.groupBy(item => item.attributes.organization_id)
                    .get(this.organizationModel.id, collect())
                    .map(item => this.tags.where('id', '===', `${item.attributes.tag_id}`).first())
                    .filter(item => item !== undefined);
            },

            otherTags() {
                return this.tags.reject(tag => {
                    const foundTag = this.organizationTags.first(item => item.id === tag.id);

                    return tag.id === foundTag?.id;
                }).filter(item => item !== undefined);
            },

            matchingInOtherOrganization() {
                const tag = this.selectedTag.value.attributes;
                const tagName = tag.name ? tag.name.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '') : '';
                const matchingTags = this.checkTagsAndReturnToArray(this.otherTags, tag, tagName);

                if (isEmpty(matchingTags)) {
                    return null;
                } else {
                    return matchingTags.map(item => {
                        return {
                            tag: item,
                            organizations: this.fetchOrganization(item),
                        };
                    });
                }
            },

            tagModal() {
                return useModal();
            },
        },

        watch: {
            organizationTags: {
                deep: true,
                handler(value) {
                    if (!isEmpty(value)) {
                        this.pushTagsToLegacyArray(value);
                    }
                },
            },
        },

        mounted() {
            TagModel.query().all().then(response => {
                this.tags = response;
            });
            TagsOrganizationsPivot.query().all().then(response => {
                this.pivots = response;
            });
            OrganizationModel.query().all().then(response => {
                this.organizations = response;
            });
        },

        methods: {
            checkTagsAndReturnToArray(tagComputed, tag, tagName) {
                return tagComputed
                    .filter(item => {
                        const name = item.attributes.name.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
                        const matchingTag = tagName === name;
                        const matchingType = tag.type === item.attributes.type;

                        return matchingTag && matchingType;
                    },
                    )
                    .all();
            },

            fetchOrganization(tag) {
                const pivots = this.pivots.filter(item => item.attributes.tag_id === tag.id);

                return pivots.map(item => {
                    return this.organizations.first(value => value.id === item.attributes.organization_id);
                });

            },

            initNewTagAndShowModal() {
                const tag = new TagModel();
                tag.attributes.color = '#f44336';
                tag.attributes.type = 'kid';
                this.selectedTag = new Mirror(tag);
                this.showMatchingTags = true;
                this.errorHandler.reset();
                this.tagModal.show();
            },

            async createTag() {
                try {
                    this.loading = true;
                    const tag = this.selectedTag.value;

                    await this.attachTagToOrganization(tag);
                    this.loading = false;

                } catch (error) {
                    this.loading = false;
                    this.errorResponse(error);
                }
            },

            copyTagAndShowModal(tag) {
                this.selectedTag = new Mirror(tag);
                this.showMatchingTags = false;
                this.errorHandler.reset();
                this.tagModal.show();
            },

            async updateTag() {
                try {
                    this.loading = true;

                    await this.selectedTag.value.save();
                    this.selectedTag.commit();

                    this.loading = false;
                    this.tagModal.hide();
                    useNotification().success(__('tag:tag_updated_successfully'));
                } catch (e) {
                    this.loading = false;
                    catcher()
                        .on(ValidationError, value => {
                            this.errorHandler.reset(value);
                        })
                        .catch(e);
                }
            },

            async detachTag(tagId) {
                try {
                    this.loading = true;

                    const pivotToDelete = this.pivots.first(pivot => {
                        return pivot.attributes.organization_id === this.organizationModel.id && pivot.attributes.tag_id === tagId;
                    });

                    await pivotToDelete.delete();
                    this.pivots = this.pivots.reject(item => item.id === pivotToDelete.id);

                    this.loading = false;
                    this.tagModal.hide();
                    useNotification().success(__('tag:tag_detached_successfully'));
                } catch (error) {
                    this.loading = false;
                    this.errorResponse(error);
                }
            },

            async attachTagToOrganization(tag) {
                try {
                    this.loading = true;

                    const pivot = await tag.attachToOrga(this.organizationModel);

                    await this.organizationModel.tags().fresh();

                    this.tags.push(tag);
                    this.pivots.push(pivot);

                    this.loading = false;
                    this.tagModal.hide();
                    useNotification().success(__('tag:tag_added_successfully'));
                } catch (e) {
                    this.loading = false;
                    catcher()
                        .on(ValidationError, value => {
                            this.errorHandler.reset(value);
                        })
                        .catch(e);
                }
            },

            async removeTag(tag) {
                await useMagicModal().deleteConfirmationModal({
                    text: __('tag:tag_will_be_removed_from_all_organizations'),
                    onConfirm: async () => {
                        try {
                            const tagToDelete = this.tags.first(item => item.id === tag.id);
                            await tagToDelete.delete();
                            await this.organizationModel.tags().fresh();

                            this.tags = this.tags.reject(item => item === tagToDelete);
                            this.nursery.tags = this.nursery.tags.filter(item => item.id !== tag.id);
                            this.pivots = this.pivots.reject(item => item.attributes.tag_id === tag.id);
                            this.tagModal.hide();
                            useNotification().success(__('tag:tag_removed_successfully'));
                        } catch (error) {
                            this.errorResponse(error);
                        }
                    },
                });
            },

            async importTags() {
                const organizations = this.organizations;
                const organizationsName = organizations
                    .reject(organisation => organisation.id === this.organizationModel.id)
                    .map(item => item.attributes.name)
                    .all();

                const choice = await swal({
                    title: __('tag:import_tags_from_which_organization_ask'),
                    text: null,
                    type: null,
                    confirmButtonClass: 'btn btn-info mr-2',
                    confirmButtonText: __('common:actions.confirm'),
                    cancelButtonText: __('common:actions.cancel'),
                    input: 'select',
                    inputOptions: organizationsName,
                });

                if (choice.value) {
                    const selectedName = organizationsName[choice.value];
                    const organizationModel = organizations.where('attributes.name', '===', selectedName).first();

                    try {
                        this.loading = true;

                        const pivots = await TagsOrganizationsPivot.query().where('organization_id', organizationModel.id).get();

                        pivots.reject(item => {
                            const findTagInOrganization = this.organizationTags.where('id', '===', `${item.attributes.tag_id}`).first();

                            return `${item.attributes.tag_id}` === findTagInOrganization?.id;
                        })
                            .each(async item => {
                                const tag = this.tags.where('id', '===', `${item.attributes.tag_id}`).first();
                                const pivot = await tag.attachToOrga(this.organizationModel);
                                this.pivots.push(pivot);
                            });

                        this.loading = false;
                        useNotification().success(__('tag:tag_imported_successfully'));
                    } catch (error) {
                        this.errorResponse(error);
                    }
                }
            },

            pushTagsToLegacyArray(tags) {
                this.nursery.tags = tags.filter(item => item !== undefined)
                    .map(function(tag) {
                        return tag.clone().attributes;
                    })
                    .all();
            },

            tagsWithCategory(category) {
                return this.organizationTags.filter(tag => tag.attributes.type === category);
            },

            getCategoryByType(val) {
                const cat = collect(this.categories);

                return cat.first(item => item.value === val);
            },

            errorResponse(error) {
                if (error?.response?.status === 422) {
                    _forEach(error.response.data.errors, value => {
                        useNotification().error(_head(value));
                    });
                } else {
                    useNotification().error(error);
                }
            },
        },
    };
</script>
