import type {Moment} from 'moment';
import moment from 'moment';
import _find from 'lodash-es/find';
import _minBy from 'lodash-es/minBy';
import _maxBy from 'lodash-es/maxBy';
import ManagerStore from '@/modules/legacy/store/manager.store';
import {Epoch} from '@meekohq/lumos';

export function daysBetweenDates(
    start: any,
    end: any,
    openingHours: any[] = ManagerStore.legacyNursery.openingHours,
) {
    const dates: any[] = [];

    const currDate = moment(start).startOf('day');
    const lastDate = moment(end).startOf('day');

    dates.push(currDate.clone());
    while (currDate.add(1, 'days').diff(lastDate) <= 0) {
        const day = currDate
            .clone()
            .locale('en')
            .format('ddd')
            .toLowerCase();
        if (openingHours && (day === 'sat' || day === 'sun')) {
            const hasDay = _find(openingHours, {day});
            hasDay ? dates.push(currDate.clone()) : null;
        } else {
            dates.push(currDate.clone());
        }
    }

    return dates;
}

export function epochDaysBetweenDates(
    start: any,
    end: any,
    openingHours: any[] = ManagerStore.legacyNursery.openingHours,
): Epoch[] {
    const openSunday = openingHours && openingHours.some(item => item.day === 'sat');
    const openSaturday = openingHours && openingHours.some(item => item.day === 'sun');

    const dates: Epoch[] = [];

    let currDate = Epoch.fromISOString(start).startOfDay();
    const lastDate = Epoch.fromISOString(end).startOfDay();

    while (lastDate.difference(currDate, 'days').days <= 0) {
        const dayNumber = currDate.weekday;

        if (openingHours) {
            const pushData = dayNumber < 6 || (dayNumber === 6 && openSaturday) || (dayNumber === 7 && openSunday);

            if (pushData) {
                dates.push(currDate);
            }
        } else {
            dates.push(currDate);
        }

        currDate = currDate.addDays(1);
    }

    return dates;
}

export function minutesToHours(min: number) {
    const days = moment.duration(min, 'minutes').days();
    let hours = moment.duration(min, 'minutes').hours();
    const minutes = moment.duration(min, 'minutes').minutes();
    const readableMinutes = minutes > 9 ? minutes : `0${minutes}`;

    days ? (hours += days * 24) : null;

    return minutes ? hours + 'h' + readableMinutes : hours + 'h';
}

export function stringDayToNumber(day: string) {
    switch (day) {
        case 'mon':
            return 1;
        case 'tue':
            return 2;
        case 'wed':
            return 3;
        case 'thu':
            return 4;
        case 'fri':
            return 5;
        case 'sat':
            return 6;
        case 'sun':
            return 7;
    }
}

export function getOpening(
    hours: any[],
    shouldRemoveHour: boolean,
    exact: boolean,
    hoursFromPlannings: any[] = [],
) {
    hours = hours.map(item => item.started_at);

    if (hoursFromPlannings.length) {
        hours = hours.concat(hoursFromPlannings);
    }

    const min: string = _minBy(hours) as string;
    const hour: string = min ? min : '07:00:00';

    const momentHour = moment(hour, 'HH:mm:ss');
    momentHour.hour() === 0 ? (exact = true) : false;

    if (!exact) {
        if (momentHour.minutes() >= 30) {
            momentHour.minutes(0);
        } else {
            momentHour.minutes(0);
            shouldRemoveHour ? momentHour.subtract(1, 'hour') : null;
        }
    }

    return momentHour.format('HH:mm:ss');
}

export function getClosing(
    hours: any[],
    shouldRemoveHour: boolean,
    exact: boolean,
    hoursFromPlannings: any[] = [],
) {
    hours = hours.map(item => item.ended_at);

    if (hoursFromPlannings.length) {
        hours = hours.concat(hoursFromPlannings);
    }

    let max: string = _maxBy(hours) as string;
    if (hours.some(item => moment(item, 'HH:mm:ss').hours() <= 1)) {
        max = '23:59:00';
    }
    const hour: string = max ? max : '18:00:00';

    const momentHour = moment(hour, 'HH:mm:ss');
    momentHour.hour() >= 23 ? (exact = true) : false;

    if (!exact) {
        shouldRemoveHour ? momentHour.add(1, 'hour') : null;
    }

    return momentHour.format('HH:mm:ss');
}

export function unixToPercent(
    unix: number,
    day,
    opening,
    closing,
    limit = false,
) {
    const openingDuration = moment.duration(opening);
    const closingDuration = moment.duration(closing);

    const openingTimestamp = Epoch.fromMoment(day).set({
        hour: openingDuration.hours(),
        minute: openingDuration.minutes(),
        second: openingDuration.seconds(),
    }).toTimestamp();

    const closingTimestamp = Epoch.fromMoment(day).set({
        hour: closingDuration.hours(),
        minute: closingDuration.minutes(),
        second: closingDuration.seconds(),
    }).toTimestamp();

    const percent =
        ((unix - openingTimestamp) * 100) /
        (closingTimestamp - openingTimestamp);

    if (limit) {
        if (percent > 100) {
            return 100;
        } else if (percent < 0) {
            return 0;
        }
    }

    return percent;
}

export function percentToUnix(
    percent: number,
    day,
    opening,
    closing,
    roundInterval: number | undefined = 5,
) {
    const openingTimestamp = moment(day)
        .clone()
        .hours(moment.duration(opening).hours())
        .minutes(moment.duration(opening).minutes())
        .seconds(moment.duration(opening).seconds())
        .unix();

    const closingTimestamp = moment(day)
        .clone()
        .hours(moment.duration(closing).hours())
        .minutes(moment.duration(closing).minutes())
        .seconds(moment.duration(closing).seconds())
        .unix();

    let unix =
        (percent * (closingTimestamp - openingTimestamp) +
            100 * openingTimestamp) /
        100;

    if (roundInterval > 0) {
        const momentDate = moment.unix(unix);
        const roundedMinutes =
            Math.round(momentDate.clone().minute() / roundInterval) *
            roundInterval;
        unix = momentDate
            .clone()
            .minute(roundedMinutes)
            .second(0)
            .unix();
    }

    return unix;
}

export function readableHoursFromDate(date: Moment) {
    return date.minutes() > 0 ? date.format('H:mm') : date.format('H') + 'h';
}

export function readableHoursFromDates(from: Moment, to: Moment) {
    return readableHoursFromDate(from) + ' - ' + readableHoursFromDate(to);
}

export function isHalfday(planning, nursery) {
    const halfdays = {
        am: false,
        pm: false,
    };

    const format = 'YYYY-MM-DD HH:mm';

    const start = moment(`${planning.started_at} ${planning.start_time}`, format).unix();
    const end = moment(`${planning.started_at} ${planning.end_time}`, format).unix();

    const morningEndAt = moment(`${planning.started_at} ${nursery.morning_end_at}`, format).unix();
    const afternoonStartAt = moment(`${planning.started_at} ${nursery.afternoon_start_at}`, format).unix();

    if (start < afternoonStartAt && end > morningEndAt) {
        halfdays.am = true;
        halfdays.pm = true;
    } else if (start <= morningEndAt && end <= morningEndAt) {
        halfdays.am = true;
    } else if (start >= afternoonStartAt && end >= afternoonStartAt) {
        halfdays.pm = true;
    } else {
        halfdays.am = true;
    }

    return halfdays;
}
