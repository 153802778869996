<script setup lang="ts">
    import {toRefs} from 'vue';
    import useFormatNumber from '@/modules/cashier/composables/useFormatNumber';
    import PaymentSuggestionsListItemHeaderTransaction
        from '@/modules/cashier/payment/infrastructure/components/PaymentSuggestionsListItemHeaderTransaction.vue';
    import PaymentSuggestionsListItemHeaderCreditNote
        from '@/modules/cashier/payment/infrastructure/components/PaymentSuggestionsListItemHeaderCreditNote.vue';
    import __ from '@/modules/app/utils/i18n-facade';
    import MProgressBar from '@/modules/meeko-ui/components/MProgressBar.vue';
    import {Epoch} from '@meekohq/lumos';
    import type {AbstractAllocationAggregate} from '@/modules/cashier/payment/domain/AbstractAllocationAggregate';
    import {CreditNoteAllocationAggregate} from '@/modules/cashier/payment/domain/CreditNoteAllocationAggregate';

    const props = defineProps<{
        allocation: AbstractAllocationAggregate;
        saving: boolean;
    }>();

    const {allocation} = toRefs(props);

    const emit = defineEmits<{
        (e: 'add-allocation', value: AbstractAllocationAggregate): void;
    }>();

    const {format} = useFormatNumber(allocation.value.currencyIsoCode);

    function addAllocation() {
        allocation.value.allocationAmount = allocation.value.maxAllocatableAmount;

        emit('add-allocation', allocation.value);
    }
</script>

<template>
    <div class="PaymentSuggestionsListItem">
        <div class="PaymentSuggestionsListItem__column-header">
            <PaymentSuggestionsListItemHeaderCreditNote
                v-if="allocation instanceof CreditNoteAllocationAggregate"
                :allocation="allocation"
            />
            <PaymentSuggestionsListItemHeaderTransaction
                v-else
                :allocation="allocation"
            />
        </div>
        <div class="PaymentSuggestionsListItem__column-progress-bar">
            <div class="PaymentSuggestionListItem__progress-bar-label">
                <template v-if="allocation instanceof CreditNoteAllocationAggregate">
                    {{ __('billing_transaction:remaining_amount_to_refund', {value: format(allocation.allocatableRemainingAmount)}) }}
                </template>
                <template v-else>
                    {{ __('billing_transaction:remaining_amount_to_allocate', {value: format(allocation.allocatableRemainingAmount)}) }}
                </template>
            </div>
            <MProgressBar
                :remaining="allocation.allocatableRemainingAmount"
                :total="allocation.allocatableAmount"
            />
        </div>
        <div class="PaymentSuggestionsListItem__column-informations">
            <div class="PaymentSuggestionsListItem__information">
                <FontAwesomeIcon
                    fixed-width
                    icon="fad fa-calendar"
                />
                {{ allocation.allocatableDate.toLocaleString(Epoch.presets.DATE_SHORT) }}
            </div>
            <div
                v-if="allocation.allocatableReference"
                class="PaymentSuggestionsListItem__information"
            >
                <FontAwesomeIcon
                    fixed-width
                    icon="fad fa-receipt"
                />
                {{ allocation.allocatableReference }}
            </div>
        </div>
        <div class="PaymentSuggestionsListItem__action">
            <MButton
                class="tw-w-full"
                :loading="saving"
                variant="primary"
                @click="addAllocation"
            >
                {{ __('billing_transaction:actions.use_amount', {amount: format(allocation.maxAllocatableAmount)}) }}
            </MButton>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    .PaymentSuggestionsListItem {
        @apply tw-grid md:tw-grid-cols-4 tw-grid-cols-2 tw-gap-4 tw-items-center;
        @apply tw-py-4 tw-bg-white tw-border-b tw-border-gray-200;
    }

    .PaymentSuggestionsListItem__column-header {
        @apply md:tw-col-span-1 tw-col-span-2;
    }

    .PaymentSuggestionsListItem__column-progress-bar {
        @apply tw-col-span-1;
    }

    .PaymentSuggestionsListItem__column-informations {
        @apply tw-col-span-1;
    }

    .PaymentSuggestionsListItem__information {
        @apply tw-text-gray-500 tw-text-sm tw-truncate;
    }

    .PaymentSuggestionsListItem__action {
        @apply md:tw-col-span-1 tw-col-span-2;
        @apply tw-order-last;
    }
</style>
