<template>
    <CModal
        ref="modal"
        :header-title="header"
        :hide-header-fullscreen="true"
        @show="onShow"
    >
        <p class="tw-mb-0">
            <span v-html="__('hr:delete_team_in_all_organization_warning', {name: teamModel.attributes.name})"/>
        </p>
        <p class="tw-mt-2">
            {{ __('common:irreversible_action') }}
        </p>
        <template #footer>
            <CButton
                class="tw-ml-auto"
                @click="$refs.modal.hide()"
            >
                {{ __('common:actions.cancel') }}
            </CButton>
            <CTooltip
                v-if="!canRemoveTeam"
                placement="top"
            >
                <CButton
                    class="tw-ml-2"
                    :disabled="!canRemoveTeam"
                    variant="danger"
                >
                    {{ __('common:actions.delete') }}
                </CButton>
                <template #content>
                    {{ __('common:deleting_unable') }}
                </template>
            </CTooltip>
            <CButton
                v-else
                class="tw-ml-2"
                variant="danger"
                @click="removeTeam"
            >
                {{ __('common:actions.delete') }}
            </CButton>
        </template>
    </CModal>
</template>

<script lang="ts">
    import type {PropType, Ref} from 'vue';
    import {computed, defineComponent, ref} from 'vue';
    import __ from '@/modules/app/utils/i18n-facade';
    import TeamModel from '@/modules/human-resources/models/TeamModel';
    import {isEmpty} from 'lodash-es';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';

    export default defineComponent({
        props: {
            teamModel: {
                type: Object as PropType<TeamModel>,
                required: true,
            },
        },

        setup(props, {emit}) {
            const canRemoveTeam: Ref<null | boolean> = ref(null);
            const modal = ref();

            async function onShow(): Promise<void> {
                const activeTeam = TeamModel.query()
                    .where('id', '=', props.teamModel.id)
                    .whereHas('staffs');

                if (props.teamModel.attributes.internal_id) {
                    canRemoveTeam.value = false;
                } else {
                    canRemoveTeam.value = isEmpty(activeTeam.all());
                }
            }

            function show(): void {
                modal.value.show();
            }

            function hide(): void {
                modal.value.hide();
            }

            async function removeTeam(): Promise<void> {
                await props.teamModel.delete();
                hide();
                emit('removeTeam', props.teamModel);
                useNotification().success(__('hr:event_type_deleted_successfully'));
            }

            const header = computed((): string => {
                return __('hr:delete_team_name_in_all_organization', {name: props.teamModel.attributes.name});
            });

            return {
                modal,
                canRemoveTeam,
                onShow,
                removeTeam,
                header,
                show,
            };
        },
    });
</script>
