import Vue from 'vue';
import SelectImage from '@/modules/news/components/SelectImage.vue';
import __ from '@/modules/app/utils/i18n-facade';

(function($) {
    'use strict';

    $.extend(true, $.trumbowyg, {
        plugins: {
            selectImage: {
                init: function(trumbowyg) {
                    const btnDef = {
                        title: __('common:meeko_library'),
                        ico: 'insertImage',
                        fn: function() {
                            trumbowyg.saveRange();

                            const ComponentClass = Vue.extend(SelectImage);
                            const instance = new ComponentClass();
                            instance.$mount();
                            instance.$on('select-photo', photo => {
                                if (photo.video) {
                                    trumbowyg.execCmd('insertHTML', `<video src="${photo.photo_url}" meeko-resource-id="${photo.id}" meeko-resource-type="video" width="100%" height="auto" preload="metadata" controls><source src="${photo.photo_url}" meeko-resource-id="${photo.id}" meeko-resource-type="video"/></video>`);
                                } else {
                                    trumbowyg.execCmd('insertHTML', `<img src="${photo.photo_url}" meeko-resource-id="${photo.id}" meeko-resource-type="photo" width="250px"/>`);
                                }
                                trumbowyg.closeModal();
                            });

                            const $modal = trumbowyg.openModal(
                                __('common:meeko_library'),
                                `
                                <div class="tw-text-right tw-text-sm tw-px-3 tw-mb-4">
                                    <button id="close-select-image" class="trumbowyg-modal-reset tw-bg-white tw-border tw-border-gray-200 tw-rounded-lg tw-shadow-sm hover:tw-shadow-md tw-px-2 tw-py-1 tw-ml-2">Fermer</button>
                                </div>
                                <div id="trumbowyg-select-image-content" class="tw-px-3"></div>
                                `,
                                false,
                            );

                            $($modal).css({
                                top: '100px',
                                height: '600px',
                                overflow: 'scroll',
                            });

                            $(instance.$el).appendTo('#trumbowyg-select-image-content');

                            $('#close-select-image').click(() => {
                                trumbowyg.closeModal();
                            });
                        },
                    };

                    trumbowyg.addBtnDef('selectImage', btnDef);
                },
            },
        },
    });
})(jQuery);
