<template>
    <div>
        <CButton
            icon-ellipsis
            size="sm"
            variant="ghost"
            @click="onShow"
        />
        <CDropdownMenu
            ref="menu"
            placement="left"
        >
            <slot name="update"/>
            <template>
                <CDropdownMenuItem
                    v-if="!pivot.attributes.archived_at"
                    icon="fas fa-archive"
                    variant="light"
                    @click="toggleArchive('archive')"
                >
                    <span>{{ __('common:actions.archive') }}</span>
                </CDropdownMenuItem>
                <CDropdownMenuItem
                    v-else
                    icon="fas fa-box-open"
                    variant="light"
                    @click="toggleArchive()"
                >
                    <span>{{ __('common:actions.unarchive') }}</span>
                </CDropdownMenuItem>
            </template>
            <template v-if="user.is_owner">
                <CDropdownMenuItem
                    v-if="!pivot.attributes.archived_at"
                    icon="fas fa-archive"
                    variant="light"
                    @click="toggleArchiveAll('archiveAll')"
                >
                    <span>{{ __('common:actions.archive_of_all_organizations') }}</span>
                </CDropdownMenuItem>
                <CDropdownMenuItem
                    v-else
                    icon="fas fa-box-open"
                    variant="light"
                    @click="toggleArchiveAll()"
                >
                    <span>{{ __('common:actions.unarchive_of_all_organizations') }}</span>
                </CDropdownMenuItem>
            </template>
            <template>
                <CDropdownMenuItem
                    icon="fa fa-trash"
                    variant="danger"
                    @click="onDelete"
                >
                    <span>{{ __('common:actions.delete') }}</span>
                </CDropdownMenuItem>
            </template>
            <template v-if="user.is_owner">
                <template>
                    <CTooltip v-if="teamModelUsed">
                        <CDropdownMenuItem
                            :disabled="teamModelUsed"
                            icon="fa fa-trash"
                            variant="danger"
                        >
                            <span>{{ __('common:actions.delete_of_all_organizations') }}</span>
                        </CDropdownMenuItem>
                        <template #content>
                            {{ __('hr:used_team') }}
                        </template>
                    </CTooltip>
                    <CDropdownMenuItem
                        v-else
                        icon="fa fa-trash"
                        variant="danger"
                        @click="$emit('ownerDelete', teamModel)"
                    >
                        <span>{{ __('common:actions.delete_of_all_organizations') }}</span>
                    </CDropdownMenuItem>
                </template>
            </template>
        </CDropdownMenu>
    </div>
</template>

<script lang="ts">
    import {defineComponent, ref, toRef} from 'vue';
    import TeamOrganizationPivot from '@/modules/human-resources/models/TeamOrganizationPivot';
    import TeamModel from '@/modules/human-resources/models/TeamModel';
    import moment from 'moment';
    import useAuth from '@/modules/app/composables/useAuth';
    import __ from '@/modules/app/utils/i18n-facade';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';

    export default defineComponent(
        {
            components: {},
            props: {
                pivot: {
                    type: TeamOrganizationPivot,
                    required: true,
                },
                teamModel: {
                    type: TeamModel,
                    required: true,
                },
                isOnlyInOrganization: {
                    type: Boolean,
                    required: true,
                },
            },
            setup(props, {emit}) {
                const menu = ref();
                const {legacyUser: user} = useAuth();
                const pivot = ref(toRef(props, 'pivot'));
                const teamModelUsed = ref<boolean | null>(null);
                /*
                 * Archive Actions
                */

                const updateArchivedAtOnPivot = function(value) {
                    if (value) {
                        pivot.value.attributes.archived_at = moment().toISOString(true);
                    } else {
                        pivot.value.attributes.archived_at = null;
                    }
                };

                function toggleArchive(value: 'archive' | null): void {
                    let archiveOrNot;

                    if (value === 'archive') {
                        archiveOrNot = __('common:archived');
                    } else {
                        archiveOrNot = __('common:unarchived');
                    }

                    updateArchivedAtOnPivot(value);

                    pivot.value.save();
                    useNotification().success(__('hr:team_action_successfully', {action: archiveOrNot}));
                }

                async function toggleArchiveAll(value: 'archiveAll' | null) {
                    let archiveOrNot;

                    if (value === 'archiveAll') {
                        archiveOrNot = __('common:archived');
                    } else {
                        archiveOrNot = __('common:unarchived');
                    }

                    (await TeamOrganizationPivot.query().where('team_id', '=', props.teamModel.id).get()).map((item: TeamOrganizationPivot) => {
                        updateArchivedAtOnPivot(value);

                        return item.save();
                    });
                    useNotification().success(__('hr:team_action_on_all_organizations_successfully', {action: archiveOrNot}));
                }

                /*
                 * Delete Actions
                 */
                async function onDelete() {
                    if (props.isOnlyInOrganization) {
                        await removeTeamModel();
                    } else {
                        await detach();
                    }
                }

                async function removeTeamModel() {
                    await props.teamModel.delete();
                    emitTeamModelToDelete();
                }

                async function detach() {
                    await pivot.value.delete();
                    emitTeamModelToDelete();
                }


                function emitTeamModelToDelete() {
                    hide();
                    emit('teamModelToDelete', props.teamModel);
                    useNotification().success(__('hr:team_deleted_successfully'));
                }

                /*
                 * Modal actions
                */

                function show() {
                    menu.value.show();
                }

                function hide() {
                    menu.value.hide();
                }

                async function onShow() {
                    const fetchTeamModelUsed = await TeamModel.query()
                        .where('id', '=', toRef(props, 'teamModel').value.id as string)
                        .whereHas('staffs')
                        .all(100);
                    teamModelUsed.value = fetchTeamModelUsed.isNotEmpty();
                    show();
                }

                return {
                    menu,
                    user,
                    show,
                    onShow,
                    toggleArchive,
                    toggleArchiveAll,
                    onDelete,
                    teamModelUsed,
                    removeTeamModel,
                };
            },
        },
    );
</script>

