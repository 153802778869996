import {ServiceProvider} from '@meekohq/lumos';
import SimulationListModel from '@/modules/planning/models/SimulationListModel';
import SimulationListPivot from '@/modules/planning/models/SimulationListPivot';

export default class PlanningServiceProvider extends ServiceProvider {
    public boot() {
        this.loadModelsFrom([
            SimulationListModel,
            SimulationListPivot,
        ]);
    }
}
