import type {
    AmountToAllocateMustBePositiveError,
} from '@/modules/cashier/payment/domain/errors/AmountToAllocateMustBePositiveError';
import type {
    AmountToAllocateIsGreaterThanSourceRemainingError,
} from '@/modules/cashier/payment/domain/errors/AmountToAllocateIsGreaterThanSourceRemainingError';
import type {
    AmountToAllocateIsGreaterThanDestinationRemainingError,
} from '@/modules/cashier/payment/domain/errors/AmountToAllocateIsGreaterThanDestinationRemainingError';
import type {ValidationError} from '@meekohq/lumos';
import type {AmountToAllocateIsRequired} from '@/modules/cashier/payment/domain/errors/AmountToAllocateIsRequired';

export type SaveAllocationResultFailed = SaveAllocationResultCommon & {
    status: 'failed'
    reason:
    | AmountToAllocateIsRequired
    | AmountToAllocateMustBePositiveError
    | AmountToAllocateIsGreaterThanSourceRemainingError
    | AmountToAllocateIsGreaterThanDestinationRemainingError
    | ValidationError;
};

type SaveAllocationResultSuccess = SaveAllocationResultCommon & {
    status: 'success'
};

type SaveAllocationResultCommon = {
    sourceKey: string;
};

export type SaveAllocationResult = SaveAllocationResultFailed | SaveAllocationResultSuccess;

export class SaveAllocationAggregateResponseModel {
    public readonly hasError: boolean;

    public constructor(
        public readonly results: SaveAllocationResult[],
    ) {
        this.hasError = results.some(result => result.status === 'failed');
    }

    get errors(): SaveAllocationResultFailed[] {
        return this.results.filter(result => result.status === 'failed') as SaveAllocationResultFailed[];
    }
}
