import _round from 'lodash-es/round';

/**
 * WARNING : this behaviour is synced with a specific flow from the InvoiceController.
 * InvoiceController => legacy/Modules/billing/src/Http/Controllers/InvoiceController.php
 * Flow in details => https://meeko.atlassian.net/wiki/spaces/DEV/pages/622526477/R+gles+d+arrondis+JS+Invoices+Manager+PHP+InvoiceController
 */
export default function(total: number): number {
    if (total.toString().length > 15) {
        return _round(_round(total, 2 + 1), 2);
    }

    return _round(total, 2);
}
