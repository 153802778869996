<template>
    <CVStack class="tw-w-72">
        <CHStack align="center">
            <CText>{{ __('common:each') }}</CText>
            <CInput
                v-model="rule.interval"
                class="tw-w-16 tw-mx-2"
                min="1"
                type="number"
            />
            <CText>{{ __('common:month_lower_colon') }}</CText>
        </CHStack>
        <CHStack
            align="center"
            class="tw-my-4"
        >
            <CRadio
                v-model="format"
                name="format"
                option="many"
            />
            <CText class="tw-ml-2">
                {{ __('common:each') }}
            </CText>
        </CHStack>
        <ByMonthDay
            v-model="monthDay"
            :disabled="format !== 'many'"
        />
        <CHStack
            align="center"
            class="tw-mt-2"
        >
            <CRadio
                v-model="format"
                name="format"
                option="one"
            />
            <CText class="tw-ml-2">
                {{ __('common:the') }}
            </CText>
        </CHStack>
        <CHStack
            align="center"
            class="tw-mt-2"
        >
            <BySetPos
                v-model="position"
                :disabled="format !== 'one'"
            />
            <ByWeekDay
                v-model="weekDay"
                class="tw-ml-2"
                :disabled="format !== 'one'"
            />
        </CHStack>
    </CVStack>
</template>

<script lang="ts">
    import type {PropType} from 'vue';
    import {defineComponent, ref, watch} from 'vue';
    import type Rule from '@/modules/calendar/utils/RuleSet/Rule';
    import ByMonthDay from '@/modules/calendar/components/RuleSet/Rule/ByMonthDay.vue';
    import BySetPos from '@/modules/calendar/components/RuleSet/Rule/BySetPos.vue';
    import ByWeekDay from '@/modules/calendar/components/RuleSet/Rule/ByWeekDay.vue';

    export default defineComponent({
        components: {ByWeekDay, BySetPos, ByMonthDay},
        props: {
            rule: {
                type: Object as PropType<Rule>,
                required: true,
            },
        },
        setup(props) {
            const format = ref('many');
            const position = ref(props.rule.bysetpos ?? []);
            const weekDay = ref(props.rule.byweekday ?? []);
            const monthDay = ref(props.rule.bymonthday ?? []);

            initData();

            watch(format, () => {
                if (format.value === 'many') {
                    props.rule.bymonthday = monthDay.value;
                    props.rule.byweekday = [];
                    props.rule.bysetpos = [];
                } else {
                    props.rule.bymonthday = [];
                    props.rule.byweekday = weekDay.value;
                    props.rule.bysetpos = position.value;
                }
            }, {immediate: true});

            watch(position, () => {
                props.rule.bysetpos = position.value;
            });

            watch(weekDay, () => {
                props.rule.byweekday = weekDay.value;
            });

            watch(monthDay, () => {
                props.rule.bymonthday = monthDay.value;
            });


            function initData() {
                if (!props.rule.interval) {
                    props.rule.interval = 1;
                }

                if (props.rule.bysetpos?.length && props.rule.byweekday?.length) {
                    format.value = 'one';
                }
            }

            return {
                format,
                monthDay,
                position,
                weekDay,
            };
        },
    });
</script>
