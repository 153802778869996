<template>
    <list-item
        :archived="isPivotArchived"
        :shared="isUsedInAnotherOrganization && !eventType.attributes.internal_id"
    >
        <CHStack
            v-if="loader.isDone('fetchData') && eventType"
            align-y="baseline"
        >
            <CHStack
                align="center"
                class="tw-w-2/5 tw-flex tw-items-center"
            >
                <div
                    class="tw-h-7 tw-w-7 tw-mr-3 tw-rounded-full tw-flex tw-justify-center tw-items-center tw-shrink-0"
                    :style="getIconStyle(eventType.attributes.color)"
                >
                    <CIcon
                        v-if="icon"
                        class="icon justify-content-center align-self-center"
                        :path="icon.attributes.path"
                        :provider="icon.attributes.provider"
                    />
                    <template v-else/>
                </div>
                <span>{{ eventType.attributes.name }}</span>
            </CHStack>
        </CHStack>
        <template #action>
            <EventTypeListItemMenu
                v-if="activePivot"
                :active-pivot="activePivot"
                class="tw-my-1"
                :event-type="eventType"
                :has-events="hasEvents"
                :is-pivot-archived="isPivotArchived"
                :is-used-in-another-organization="isUsedInAnotherOrganization"
                @deleted="$emit('deleted', $event)"
            />
        </template>
    </list-item>
</template>

<script lang="ts">
    import type {PropType} from 'vue';
    import {computed, defineComponent, reactive, ref, toRef, watch} from 'vue';
    import Loader from '@/modules/legacy/helpers/loader.helper';
    import DataStore from '@/modules/legacy/store/data.store';
    import type EventTypeModel from '@/modules/human-resources/models/EventTypeModel';
    import EventTypeListItemMenu from '@/modules/human-resources/components/event-type/List/EventTypeListItemMenu.vue';
    import useEventStyle from '@/modules/human-resources/composables/calendar/useEventStyle';
    import useManager from '@/modules/app/composables/useManager';
    import type IconModel from '@/modules/app/models/IconModel';
    import useSharedList from '@/modules/app/composables/useSharedList';


    export default defineComponent({
        components: {
            EventTypeListItemMenu,
        },
        props: {
            eventType: {
                required: true,
                type: Object as PropType<EventTypeModel>,
            },
        },
        setup(props) {
            const {activeOrganization} = useManager();
            const loader = reactive(new Loader());
            const icon = ref<IconModel>();

            const {
                activePivot,
                isPivotArchived,
                isUsedInAnotherOrganization,
            } = useSharedList(toRef(props, 'eventType'));

            const getIconStyle = function(color) {
                return color ? useEventStyle(ref(color), ref(false), 'md', true).badgeStyle.value : null;
            };

            const calendar = computed(() => {
                props.eventType.calendar().value();
            });

            const hasEvents = computed(() => {
                return !!props.eventType.events().aggregateValue('count');
            });

            watch(props.eventType, (value): void => {
                icon.value = DataStore.iconPath(value.attributes.icon_id as string);
            }, {immediate: true});

            return {
                activeOrganization,
                loader,
                icon,
                isPivotArchived,
                isUsedInAnotherOrganization,
                activePivot,
                calendar,
                getIconStyle,
                hasEvents,
            };
        },
    });
</script>
