<template>
    <CHStack
        align="baseline"
        as="li"
        wrap
    >
        <CText
            as="dt"
            :class="titleClass"
            variant="gray"
        >
            <slot name="title">
                {{ title }}
            </slot>
        </CText>
        <dd>
            <slot v-if="hasValue"/>
            <slot
                v-else
                name="fallback"
            >
                <span class="tw-text-disabled">{{ fallback }}</span>
            </slot>
        </dd>
    </CHStack>
</template>

<script lang="ts">
    import {defineComponent} from 'vue';

    export default defineComponent({
        components: {},
        props: {
            hasValue: {type: Boolean, default: true},
            titleClass: {type: String, default: 'tw-w-32'},
            title: {type: String, default: ''},
            fallback: {type: String, default: 'Non renseigné'},
        },
        setup() {
            return {};
        },
    });
</script>

