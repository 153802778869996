<template>
    <CListRow
        :active="active"
        class="TagsSelectorItem"
        :class="{'TagsSelectorItem--active': active, 'TagsSelectorItem--loading': loading}"
        size="sm"
        @click="toggle"
    >
        <TagPill_v2 :tag="tag"/>
        <span>
            {{ tag.attributes.name }}
        </span>
        <div class="TagsSelectorItem__loader">
            <FontAwesomeIcon
                icon="fa-duotone fa-spinner-third"
                spin
            />
        </div>
    </CListRow>
</template>

<script lang="ts">
    import type {PropType} from 'vue';
    import {defineComponent, ref, watch} from 'vue';
    import type TagModel from '@/modules/tag/models/TagModel';
    import type {Model, MorphToMany} from '@meekohq/lumos';
    import TagPivot from '@/modules/tag/models/TagPivot';
    import ErrorHandler from '@/modules/legacy/libs/errors/errorHandler';
    import useApi from '@/modules/app/composables/useApi';
    import _forEach from 'lodash-es/forEach';
    import _head from 'lodash-es/head';
    import route from '@/modules/legacy/libs/ziggy';
    import TagPill_v2 from '@/modules/tag/components/TagPill_v2.vue';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';

    export default defineComponent({
        components: {
            TagPill_v2,
        },
        props: {
            tag: {
                type: Object as PropType<TagModel>,
                required: true,
            },
            taggableModel: {
                type: Object as PropType<Model & { tags(): MorphToMany }>,
                default: undefined,
            },
            taggableType: {
                type: String,
                required: true,
            },
            active: {
                type: Boolean,
                default: false,
            },
        },

        setup(props, {emit}) {
            const errorHandler = ref(new ErrorHandler());

            const loading = ref(false);

            watch(loading, value => {
                emit('loading', value);
            });

            async function attachTag(tag: TagModel) {
                if (loading.value) {
                    return;
                }

                loading.value = true;

                const tagPivot = new TagPivot();
                tagPivot.attributes.account_id = tag.attributes.account_id;
                tagPivot.attributes.tag_id = tag.attributes.id;
                tagPivot.attributes.resource_type = props.taggableModel.getType();
                tagPivot.attributes.resource_id = props.taggableModel[props.taggableModel.getKeyName()];

                try {
                    await tagPivot.save();
                    emit('attached', tag);
                } catch (e) {
                    errorHandler.value.reset(e);
                }

                loading.value = false;
            }

            async function detachTag(tag: TagModel) {
                if (loading.value) {
                    return;
                }

                loading.value = true;

                // If the taggable model is a lumos model, we can use the detach method
                // Otherwise, we use the legacy API
                if (props.taggableModel) {
                    try {
                        await props.taggableModel.tags().detach(tag.getKey());
                        emit('detached', tag);
                    } catch (e) {
                        errorHandler.value.reset(e);
                    }
                } else {
                    try {
                        await useApi().legacy.post(route('tags.detach', {
                            tag: tag.getKey(),
                        }), {
                            model_id: props.taggableModel[(props.taggableModel as Model).getKeyName()],
                            model_type: props.taggableType,
                        });
                        emit('detached', tag);
                    } catch (e: any) {
                        if (e && e.response && e.response.status === 422) {
                            _forEach(e.response.data.errors, (value: string[]) => {
                                useNotification().error(_head(value) ?? 'Erreur');
                            });
                        } else {
                            useNotification().error(e);
                        }
                    }
                }

                loading.value = false;
            }

            function toggle() {
                props.active ? detachTag(props.tag) : attachTag(props.tag);
            }

            return {
                toggle,
                loading,
            };
        },
    });
</script>

<style lang="scss" scoped>
    .TagsSelectorItem {
        @apply tw-flex tw-items-center tw-gap-2 tw-cursor-pointer tw-select-none;
    }

    .TagsSelectorItem__loader {
        @apply tw-ml-auto tw-text-blue-800/60 tw-opacity-0 tw-transition-all;
    }

    .TagsSelectorItem--loading {
        .TagsSelectorItem__loader {
            @apply tw-opacity-100;
        }
    }
</style>
