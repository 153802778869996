<template>
    <div>
        <div class="tw-flex tw-gap-4 tw-items-center">
            <MAvatar
                class="tw-w-24 tw-shrink-0"
                fallback-teddybear
                :url="nursery.logo_url"
            />
            <div class="tw-flex tw-flex-col tw-gap-1">
                <div class="tw-flex tw-flex-wrap tw-gap-2 tw-items-center">
                    <MHeading>
                        {{ nursery.name }}
                    </MHeading>
                    <MButton
                        v-if="can('update', 'nursery')"
                        icon-edit
                        :label="__('common:actions.update')"
                        variant="primary"
                        @click="$refs.editModal.$refs.modal.show()"
                    />
                </div>
                <div
                    v-if="nursery.city"
                    class="tw-text-gray-500 tw-text-sm"
                >
                    <FontAwesomeIcon
                        class="tw-text-primary-500 tw-mr-1"
                        icon="fa fa-map-marker"
                    />
                    {{ $capitalize(nursery.city) }} {{ $capitalize(nursery.state) }}
                </div>
            </div>
        </div>
        <div class="tw-my-6 tw-bg-blue-50 tw-rounded-lg tw-p-2">
            <div class="tw-grid sm:tw-grid-cols-3 tw-grid-cols-1 tw-gap-4">
                <div class="tw-flex tw-justify-center tw-gap-2 tw-items-center tw-min-w-0">
                    <FontAwesomeIcon
                        v-if="nursery.places"
                        class="tw-text-primary-500"
                        fixed-width
                        icon="fa fa-users"
                    />
                    <div class="tw-truncate">
                        {{ __('common:place_with_count', {count: nursery.places}) }}
                    </div>
                </div>
                <div class="tw-flex tw-justify-center tw-gap-2 tw-items-center tw-min-w-0">
                    <FontAwesomeIcon
                        v-if="nursery.phone"
                        class="tw-text-primary-500"
                        fixed-width
                        icon="fa fa-phone"
                    />
                    <div class="tw-truncate">
                        {{ nursery.phone }}
                    </div>
                </div>
                <div class="tw-flex tw-justify-center tw-gap-2 tw-items-center tw-min-w-0">
                    <FontAwesomeIcon
                        v-if="nursery.email"
                        class="tw-text-primary-500"
                        fixed-width
                        icon="fa fa-envelope"
                    />
                    <div class="tw-truncate">
                        {{ nursery.email }}
                    </div>
                </div>
            </div>
        </div>
        <div
            v-if="can('update', 'nursery')"
            class="mt-3"
        >
            <div>
                <div class="row">
                    <div class="col-12 col-sm-4">
                        <MHeading
                            class="tw-text-center"
                            level="h3"
                        >
                            {{ __('common:logo') }}
                        </MHeading>
                        <div class="tw-flex tw-flex-col tw-items-center mt-2">
                            <MAvatarEditor
                                :can-delete="!!nursery.logo_url"
                                class="tw-w-28"
                                @click.native="clickLogoInput()"
                                @delete="deleteLogo()"
                            >
                                <MAvatar
                                    class="tw-w-full"
                                    :url="nursery.logo_url"
                                />
                            </MAvatarEditor>
                            <small class="small">{{ __('common:click_for_update') }}</small>
                            <input
                                ref="logoInput"
                                accept="image/png, image/jpeg"
                                class="d-none"
                                type="file"
                                @change="updateLogo($event)"
                            >
                        </div>
                    </div>
                    <div class="col-12 col-sm-4 mt-4 mt-sm-0">
                        <MHeading
                            class="tw-text-center"
                            level="h3"
                        >
                            {{ __('common:signature') }}
                        </MHeading>
                        <div class="tw-flex tw-flex-col tw-items-center mt-2">
                            <MAvatarEditor
                                :can-delete="!!nursery.signature_url"
                                class="tw-w-28"
                                @click.native="clickSignatureInput()"
                                @delete="deleteSignature()"
                            >
                                <MAvatar
                                    class="tw-w-full"
                                    :url="nursery.signature_url"
                                />
                            </MAvatarEditor>
                            <small class="small">{{ __('common:click_for_update') }}</small>
                            <input
                                ref="signatureInput"
                                accept="image/png, image/jpeg"
                                class="d-none"
                                type="file"
                                @change="updateSignature($event)"
                            >
                        </div>
                    </div>
                    <div class="col-12 col-sm-4 mt-4 mt-sm-0">
                        <MHeading
                            class="tw-text-center"
                            level="h3"
                        >
                            {{ __('common:stamp') }}
                        </MHeading>
                        <div class="tw-flex tw-flex-col tw-items-center mt-2">
                            <MAvatarEditor
                                :can-delete="!!nursery.stamp_url"
                                class="tw-w-28"
                                @click.native="clickStampInput()"
                                @delete="deleteStamp()"
                            >
                                <MAvatar
                                    class="tw-w-full"
                                    :url="nursery.stamp_url"
                                />
                            </MAvatarEditor>
                            <small class="small">{{ __('common:click_for_update') }}</small>
                            <input
                                ref="stampInput"
                                accept="image/png, image/jpeg"
                                class="d-none"
                                type="file"
                                @change="updateStamp($event)"
                            >
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <edit
            ref="editModal"
            :nursery="nursery"
            :user="user"
        />
    </div>
</template>

<script lang="ts">
    import _forEach from 'lodash-es/forEach';
    import {defineComponent, ref} from 'vue';
    import route from '@/modules/legacy/libs/ziggy';
    import useApi from '@/modules/app/composables/useApi';
    import Edit from './Summary/Edit.vue';
    import {upload} from '@/modules/legacy/libs/usercontent';
    import __ from '@/modules/app/utils/i18n-facade';
    import useAbility from '@/modules/app/composables/useAbility';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';

    export default defineComponent({
        components: {
            edit: Edit,
        },
        props: {
            nursery: {
                type: Object,
                required: true,
            },
            user: {
                type: Object,
                required: true,
            },
        },
        setup(props) {
            const logoInput = ref<HTMLInputElement>();
            const signatureInput = ref<HTMLInputElement>();
            const stampInput = ref<HTMLInputElement>();

            const {can} = useAbility();

            function clickLogoInput() {
                logoInput.value?.click();
            }

            function updateLogo(event) {
                if (!event.target.files[0] || !validateFileMIME(event.target.files[0].type)) {
                    return;
                }

                upload(event.target.files[0]).then(response => {
                    const data = {
                        logo: {
                            key: response.key,
                            hash: response.hash,
                        },
                    };

                    useApi().legacy.put(route('nurseries.update', {nurseries: props.nursery.id}), data).then(response => {
                        props.nursery.logo_url = response.data.logo_url;
                        useNotification().success(__('organization:summary.logo_updated'));
                    }).catch(error => {
                        if (error && error.response && error.response.status === 422) {
                            _forEach(error.response.data.errors, function(value) {
                                handleError(value);
                            });
                        } else {
                            useNotification().error(error);
                        }
                    });
                });
            }

            function deleteLogo() {
                useApi().legacy.delete(route('nurseries.logo.destroy', {nurseries: props.nursery.id})).then(() => {
                    props.nursery.logo_url = null;
                    useNotification().success(__('organization:summary.logo_deleted'));
                }).catch(error => {
                    if (error && error.response && error.response.status === 422) {
                        _forEach(error.response.data.errors, value => {
                            useNotification().error(value[0]);
                        });
                    } else {
                        useNotification().error(error);
                    }
                });
            }

            function clickSignatureInput() {
                signatureInput.value?.click();
            }

            function updateSignature(event) {
                if (!event.target.files[0] || !validateFileMIME(event.target.files[0].type)) {
                    return;
                }

                upload(event.target.files[0]).then(response => {
                    const data = {
                        signature: {
                            key: response.key,
                            hash: response.hash,
                        },
                    };

                    useApi().legacy.put(route('nurseries.update', {nurseries: props.nursery.id}), data).then(response => {
                        props.nursery.signature_url = response.data.signature_url;
                        useNotification().success(__('organization:summary.signature_updated'));
                    }).catch(error => {
                        if (error && error.response && error.response.status === 422) {
                            _forEach(error.response.data.errors, function(value) {
                                handleError(value);
                            });
                        } else {
                            useNotification().error(error);
                        }
                    });
                });
            }

            function deleteSignature() {
                useApi().legacy.delete(route('nurseries.signature.destroy', {nurseries: props.nursery.id})).then(() => {
                    props.nursery.signature_url = null;
                    useNotification().success(__('organization:summary.signature_deleted'));
                }).catch(error => {
                    if (error && error.response && error.response.status === 422) {
                        _forEach(error.response.data.errors, value => {
                            useNotification().error(value[0]);
                        });
                    } else {
                        useNotification().error(error);
                    }
                });
            }

            function clickStampInput() {
                stampInput.value?.click();
            }

            function updateStamp(event) {
                if (!event.target.files[0] || !validateFileMIME(event.target.files[0].type)) {
                    return;
                }

                upload(event.target.files[0]).then(response => {
                    const data = {
                        stamp: {
                            key: response.key,
                            hash: response.hash,
                        },
                    };

                    useApi().legacy.put(route('nurseries.update', {nurseries: props.nursery.id}), data).then(response => {
                        props.nursery.stamp_url = response.data.stamp_url;
                        useNotification().success(__('organization:summary.stamp_updated'));
                    }).catch(error => {
                        if (error && error.response && error.response.status === 422) {
                            _forEach(error.response.data.errors, function(value) {
                                handleError(value);
                            });
                        } else {
                            useNotification().error(error);
                        }
                    });
                });
            }

            function deleteStamp() {
                useApi().legacy.delete(route('nurseries.stamp.destroy', {nurseries: props.nursery.id})).then(() => {
                    props.nursery.stamp_url = null;
                    useNotification().success(__('organization:summary.stamp_deleted'));
                }).catch(error => {
                    if (error && error.response && error.response.status === 422) {
                        _forEach(error.response.data.errors, value => {
                            useNotification().error(value[0]);
                        });
                    } else {
                        useNotification().error(error);
                    }
                });
            }

            function handleError(error) {
                // toast an Error for invalid MIME type. To replace when moving to the new API
                if (error.code && error.code === '0x338257A2B1') {
                    useNotification().error(__('common:errors.incorrect_file_format'));
                } else {
                    useNotification().error(error[0]);
                }
            }

            function validateFileMIME(type) {
                if (!['image/png', 'image/jpeg'].includes(type)) {
                    useNotification().error(__('common:errors.incorrect_file_format'));

                    return false;
                }

                return true;
            }

            return {
                can,
                logoInput,
                signatureInput,
                stampInput,
                clickLogoInput,
                updateLogo,
                deleteLogo,
                clickSignatureInput,
                updateSignature,
                deleteSignature,
                clickStampInput,
                updateStamp,
                deleteStamp,
            };
        },
    });
</script>
