import type {PropType, Ref} from 'vue';
import {ref, watch} from 'vue';
import {findLast, forEach, isArray, isEqual, isNil, isObject, mapKeys} from 'lodash-es';
import {windowSize} from '@/modules/coherence-ui/hooks/useWindowScreenSize';
import type {ResponsivePropType} from '@/modules/coherence-ui/hooks/useResponsiveProp';

export function containerSizeProp() {
    return {type: Number as PropType<number>, default: undefined};
}

export function responsiveProp<T>(type, defaultValue?: unknown) {
    return {
        type: [type, Object, Array] as PropType<ResponsivePropType<T>>,
        default: defaultValue,
        required: false,
    };
}

type ScreenValues = 'base' | 'sm' | 'md' | 'lg' | 'xl' | '2xl';
type ResponsivePropObjectType<T> = Record<ScreenValues | number, T>;
// type ResponsivePropObjectType<T> = {
//     base?: T,
//     sm?: T,
//     md?: T,
//     lg?: T,
//     xl?: T,
//     '2xl'?: T,
// };

const screenSizes = {
    'base': 0,
    'sm': 640,
    'md': 768,
    'lg': 1024,
    'xl': 1280,
    '2xl': 1536,
};

type OptionsFlags<T> = {
    [Property in keyof T]: T[Property]
};

function findBreakpointOnObject<TX>(prop: ResponsivePropObjectType<TX> | TX, localWidth) {
    // Transform screens size to pixel value.
    const objectProp = mapKeys(prop as ResponsivePropObjectType<TX>, function(value, key) {
        if (isNil(screenSizes[key])) {
            // Screen size is not defined, so we assume it's a pixel value.
            return key;
        } else {
            // Screen size is defined, so we transform it to a pixel value.
            return screenSizes[key];
        }
    });

    // Find the last breakpoint that matches the current screen size.
    return findLast(objectProp, (value, key) => localWidth >= key);
}

function findBreakpointOnArray<TX>(localWidth, prop: TX[], defaultValue: undefined) {
    if (localWidth >= screenSizes['2xl'] && prop[5]) {
        return prop[5];
    } else if (localWidth >= screenSizes.xl && prop[4]) {
        return prop[4];
    } else if (localWidth >= screenSizes.lg && prop[3]) {
        return prop[3];
    } else if (localWidth >= screenSizes.md && prop[2]) {
        return prop[2];
    } else if (localWidth >= screenSizes.sm && prop[1]) {
        return prop[1];
    } else {
        return prop[0] || defaultValue;
    }
}

function getValue<TX>(prop: ResponsivePropType<TX>, localWidth, defaultValue?: undefined) {
    if (isArray(prop)) {
        return findBreakpointOnArray(localWidth, prop, defaultValue);
    }

    if (isObject(prop)) {
        return findBreakpointOnObject(prop as ResponsivePropObjectType<TX>, localWidth);
    }

    return prop || defaultValue;
}

export default function <T extends Record<string, unknown>, TK extends Array<keyof T>, TKO extends OptionsFlags<T>>(props: T, responsive: TK, containerSizeProp: keyof T) {
    const responsiveProps: Ref<TKO> = ref({} as any);

    // const {width: elementSize} = useElementSize(document.getElementById(el.value as any));
    // const elementScreenSize = ref();
    // watch(el, throttle(() => {
    //     const elementSize = ref(el);
    //     if (isNil(el.value)) {
    //         elementScreenSize.value = undefined;
    //     } else if (elementSize.value >= screens['2xl']) {
    //         elementScreenSize.value = screens['2xl'];
    //     } else if (elementSize.value >= screens.xl) {
    //         elementScreenSize.value = screens.xl;
    //     } else if (elementSize.value >= screens.lg) {
    //         elementScreenSize.value = screens.lg;
    //     } else if (elementSize.value >= screens.md) {
    //         elementScreenSize.value = screens.md;
    //     } else if (elementSize.value >= screens.sm) {
    //         elementScreenSize.value = screens.sm;
    //     } else {
    //         elementScreenSize.value = 0;
    //     }
    //
    // }, 100, {leading: true, trailing: true}), {immediate: true});

    // const containerSize = computed(() => {
    //     return props[containerSizeProp];
    // });

    watch([props, windowSize], () => {
        const a: TKO = {} as any;
        forEach(responsive, prop => {
            a[prop] = getValue(props[prop], props[containerSizeProp] !== undefined ? props[containerSizeProp] : windowSize.value) as any;
        });

        if (!isEqual(responsiveProps.value, a)) {
            responsiveProps.value = a;
        }
    }, {immediate: true, deep: true});

    return {
        responsiveProps,
    };
}
