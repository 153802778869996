<template>
    <div
        class="card-object"
        :class="{big: isDefault, 'card-gradient': paymentMethod.type === 'card', 'sepa-gradient': paymentMethod.type === 'sepa_debit'}"
    >
        <CButton
            v-if="allowDelete"
            class="delete-element"
            :loading="loader.isLoading('delete')"
            round
            size="xs"
            variant="danger"
            @click="deletePaymentMethod"
        >
            <i
                aria-hidden="true"
                class="fa fa-trash"
            />
        </CButton>
        <CVStack class="card-size">
            <CInline align-x="between">
                <div>
                    <CButton
                        v-if="allowDefault"
                        :class="{'tw-border-none': isDefault}"
                        :loading="loader.isLoading('default')"
                        size="sm"
                        variant="light"
                        @click="setDefaultPaymentMethod($event)"
                    >
                        <template v-if="isDefault">
                            <i
                                aria-hidden="true"
                                class="fa fa-check mr-1 tw-text-green-500"
                            />{{ __('subscription:active') }}
                        </template>
                        <template v-else>
                            <i
                                aria-hidden="true"
                                class="fa fa-times mr-1"
                            />{{ __('subscription:inactive') }}
                        </template>
                    </CButton>
                </div>
                <div>
                    <img
                        :alt="__('common:logo')"
                        height="40"
                        :src="brandImage"
                        width="64"
                    >
                </div>
            </CInline>
            <template v-if="paymentMethod.type === 'card'">
                <CVStack
                    class="tw-flex-1"
                    distribute="start"
                    gap="2"
                >
                    <div class="card-shiny"/>
                    <CInline
                        class="tw-text-xl tw-font-mono tw-text-white text-shadow"
                        space="sm"
                    >
                        <div>••••</div>
                        <div>{{ paymentMethod.card.last4 }}</div>
                    </CInline>
                </CVStack>
                <CInline
                    align-x="between"
                    align-y="baseline"
                    class="tw-text-base tw-font-mono tw-text-white text-shadow"
                >
                    <span>{{ paymentMethod.billing_details.name }}</span>
                    <span class="tw-text-sm">{{ ('0' + paymentMethod.card.exp_month).slice(-2) }}/{{ paymentMethod.card.exp_year.toString().slice(2) }}</span>
                </CInline>
            </template>

            <template v-if="paymentMethod.type === 'sepa_debit'">
                <CVStack
                    class="tw-flex-1"
                    distribute="center"
                    gap="2"
                >
                    <a
                        :href="paymentMethod.sepa_debit.mandate_url"
                        target="_blank"
                    >
                        <CButton
                            class="tw-border-none"
                            size="xs"
                        >
                            <i
                                aria-hidden="true"
                                class="fas fa-check tw-mr-1 tw-text-blue-500"
                            />{{ __('subscription:show_sepa_mandate') }}
                        </CButton>
                    </a>
                    <CInline class="tw-text-base tw-font-mono tw-text-white text-shadow">
                        <div>{{ paymentMethod.sepa_debit.country }}</div>
                        <div>••••</div>
                        <div>{{ paymentMethod.sepa_debit.last4 }}</div>
                    </CInline>
                </CVStack>
                <CInline
                    align-x="between"
                    align-y="baseline"
                    class="tw-text-base tw-font-mono tw-text-white text-shadow"
                >
                    <span>{{ paymentMethod.billing_details.name }}</span>
                </CInline>
            </template>
        </CVStack>
    </div>
</template>

<script lang="ts">
    import type {Ref, SetupContext, UnwrapRef} from 'vue';
    import {computed, defineComponent, reactive, toRef} from 'vue';
    import useApi from '@/modules/app/composables/useApi';
    import route from '@/modules/legacy/libs/ziggy';
    import __ from '@/modules/app/utils/i18n-facade';
    import SepaImage from '@/modules/subscription/assets/cards-light/sepa.png';
    import VisaImage from '@/modules/subscription/assets/cards-light/1.png';
    import MastercardImage from '@/modules/subscription/assets/cards-light/2.png';
    import Loader from '@/modules/legacy/helpers/loader.helper';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';

    export default defineComponent({
        props: {
            paymentMethod: {type: Object, required: true},
            isDefault: {type: Boolean, default: false},
            customer: {type: Object, required: true},
            allowDelete: {type: Boolean, default: true},
            allowDefault: {type: Boolean, default: true},
        },
        setup(props, context) {
            const loader = reactive(new Loader);

            const brandImage = useBrandImage(toRef(props, 'paymentMethod'));

            const {
                setDefaultPaymentMethod,
                deletePaymentMethod,
            } = useManagePaymentMethod(context, loader, toRef(props, 'paymentMethod'), toRef(props, 'customer'));

            return {
                loader,
                brandImage,
                setDefaultPaymentMethod,
                deletePaymentMethod,
            };
        },
    });

    function useBrandImage(paymentMethod: Ref) {
        return computed(() => {
            {
                const images = {
                    sepa: SepaImage,
                    visa: VisaImage,
                    mastercard: MastercardImage,
                };

                if (paymentMethod.value.type === 'sepa_debit') {
                    return images['sepa'];
                }

                return images[paymentMethod.value.card.brand.toLowerCase()];
            }
        });
    }

    function useManagePaymentMethod(context: SetupContext, loader: UnwrapRef<Loader>, paymentMethod: Ref, customer: Ref) {
        const setDefaultPaymentMethod = function() {
            loader.start('default');

            const data = {
                id: paymentMethod.value.id,
            };

            useApi().legacy.post(route('customers.sources.setDefault', {
                customer: customer.value.id,
            }), data).then(response => {
                context.emit('defaultPaymentMethodSelected', response.data);
                useNotification().success(__('subscription:default_payment_method_added'));
            }).catch(() => {
                //
            }).then(() => {
                loader.stop('default');
            });
        };

        const deletePaymentMethod = function() {
            loader.start('delete');

            useApi().legacy.delete(route('customers.sources.destroy', {
                customer: customer.value.id,
                source: paymentMethod.value.id,
            })).then(response => {
                context.emit('paymentMethodDeleted', paymentMethod.value);
                context.emit('defaultPaymentMethodSelected', response.data.defaultSource);
                useNotification().success(__('subscription:payment_method_deleted'));
            }).catch(() => {
                //
            }).then(() => {
                // Reset Loading State
                loader.stop('delete');
            });
        };

        return {
            setDefaultPaymentMethod,
            deletePaymentMethod,
        };
    }
</script>

<style
    lang="scss"
    scoped
>
    .card-gradient {
        background: linear-gradient(135deg, #007aff 0%, #3db5c7 100%)
    }

    .card-object {
        @extend .card-gradient;
        @apply tw-relative tw-rounded-lg tw-transition-transform tw-duration-200 hover:tw-scale-105 tw-shadow-md tw-p-4;
    }

    .sepa-gradient {
        background: linear-gradient(135deg, #938fa5 0%, #253f73 100%);
    }

    .text-shadow {
        text-shadow: 0 1px rgba(#000, .3);
    }

    .card-size {
        min-width: 240px;
        min-height: 150px;
    }

    .card-shiny {
        width: 50px;
        height: 35px;
        border-radius: 5px;
        background: rgba(255, 255, 255, .6);
        position: relative;

        &:before {
            content: " ";
            display: block;
            width: 70%;
            height: 60%;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
            background: rgba(255, 255, 255, .4);
            position: absolute;
            top: 20%;
        }
    }

    .card-object {
        &:hover {
            .delete-element {
                opacity: 1;
            }
        }
    }

    .delete-element {
        opacity: 0;
        top: -7px;
        right: -7px;
        position: absolute;
        width: 22px;
        height: 22px;
    }

    .big {
        z-index: 1;
        transform: scale(1.07);
    }
</style>
