<template>
    <div class="CustomerHeroProperties">
        <CustomerHeroPropertiesLine
            :has-value="!!customerModel.attributes.name"
            :title="__('common:name')"
            :value="customerModel.attributes.name"
        />
        <CustomerHeroPropertiesLine
            :has-value="!!customerModel.attributes.person"
            :title="__('billing_customer:entity')"
        >
            <CBadge>
                <template v-if="customerModel.attributes.person === 'legal'">
                    <FontAwesomeIcon
                        class="tw-mr-1"
                        icon="fad fa-city"
                    />
                    {{ __('billing_customer:entity_legal') }}
                </template>
                <template v-else>
                    <FontAwesomeIcon
                        class="tw-mr-1"
                        icon="fas fa-user"
                    />
                    {{ __('billing_customer:entity_natural') }}
                </template>
            </CBadge>
        </CustomerHeroPropertiesLine>
        <CustomerHeroPropertiesLine
            :has-value="!!customerModel.attributes.account_number"
            :title="__('billing_customer:account_number')"
            :value="customerModel.attributes.account_number"
        />
        <CustomerHeroPropertiesLine
            v-if="customerModel.attributes.person === 'legal'"
            :has-value="!!customerModel.attributes.vat_number"
            :title="__('billing_customer:tva_number')"
            :value="customerModel.attributes.vat_number"
        />
        <CustomerHeroPropertiesLine
            v-if="familyModelCollection.length"
            :has-value="!!familyModelCollection"
            :title="__('billing_customer:linked_family')"
        >
            <ul>
                <li
                    v-for="familyModel in familyModelCollection"
                    :key="familyModel.id"
                >
                    <MDropdown
                        hoverable
                        no-padding
                        placement="right"
                    >
                        <CButton
                            class="tw-p-0"
                            :icon-external-link="true"
                            :to="{ name: 'families.show', params: { family: familyModel.id }}"
                            variant="link-purple"
                        >
                            <template #left-icons>
                                <FontAwesomeIcon icon="fad fa-address-book"/>
                            </template>
                            {{ familyModel.attributes.name }}
                        </CButton>
                        <template #content>
                            <FamilyProfile :family-model="familyModel"/>
                        </template>
                    </MDropdown>
                </li>
            </ul>
        </CustomerHeroPropertiesLine>
    </div>
</template>

<script lang="ts">
    import type CustomerModel from '@/modules/cashier/models/CustomerModel';
    import type FamilyModel from '@/modules/family/models/FamilyModel';
    import type {PropType} from 'vue';
    import {defineComponent} from 'vue';
    import FamilyProfile from '@/modules/family/components/family/FamilyProfile.vue';
    import CustomerHeroPropertiesLine
        from '@/modules/cashier/components/core/customer/CustomerHero/molecules/CustomerHeroPropertiesLine.vue';

    export default defineComponent({
        components: {CustomerHeroPropertiesLine, FamilyProfile},
        props: {
            customerModel: {type: Object as PropType<CustomerModel | undefined>, default: undefined},
            familyModelCollection: {type: Array as PropType<FamilyModel[]>, required: true},
        },
    });
</script>

<style lang="scss" scoped>
    .CustomerHeroProperties {
        @apply tw-flex-1;
        @apply tw-flex tw-flex-col tw-gap-2;
        @apply tw-min-w-0;
    }
</style>

