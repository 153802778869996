<template>
    <div>
        <p
            class="tw-text-gray-600 tw-text-base mb-0"
            v-html="__('notification:invoice_with_name_unpaid', {
                name: `${notification.data.no} (${notification.data.kid_first_name} ${notification.data.kid_last_name})`
            })"
        />
        <p
            v-show="notification.data.amount"
            class="tw-text-gray-800 tw-text-sm mb-0 mt-1"
        >
            {{ __('notification:amount_of_invoice_colon') }}
            <span class="tw-text-blue-500 tw-font-semibold">{{ notification.data.amount }}</span>
        </p>

        <div
            v-if="notification.data.nursery_id"
            class="mt-2"
        >
            <router-link
                class="btn btn-light btn-sm"
                :to="{name:'kids.show', params: {nursery: notification.data.nursery_id, kid:notification.data.kid_id}}"
                @click.native="handleEmit"
            >
                {{ __('common:show_kid_page') }}
            </router-link>
        </div>
    </div>
</template>

<script>
    import moment from 'moment';

    export default {
        props: {
            notification: {type: Object},
        },
        computed: {
            moment() {
                return moment;
            },
        },
        mounted() {
            //
        },
        methods: {
            handleEmit() {
                this.$emit('closeModal');
                !this.notification.read_at ? this.notification.read_at = moment().unix() : null;
                this.$bus.$emit('update:notification', this.notification);
            },
        },
    };
</script>
