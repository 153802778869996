<script setup lang="ts">
    import {Epoch} from '@meekohq/lumos';
    import type {PropType} from 'vue';
    import {computed} from 'vue';
    import {trim} from 'lodash-es';

    const props = defineProps({
        kid: {
            type: Object as PropType<any>,
            required: true,
        },
    });

    const fullname = computed(() => {
        let value = props.kid.first_name ?? '';

        if (props.kid.last_name) {
            value += ' ' + props.kid.last_name;
        }

        return trim(value);
    });
</script>

<template>
    <MAvatar
        :birthdate="kid.birthdate ? Epoch.parse(kid.birthdate, 'yyyy-MM-dd').toISOString() : undefined"
        fallback-initials
        :gender="kid.gender"
        :name="fullname"
        :url="kid.avatar_url"
    />
</template>
