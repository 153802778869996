<script setup lang="ts">
    import useModal from '@/modules/app/composables/useModal';
    import TransactionModalCreate
        from '@/modules/cashier/transaction/infrastructure/components/TransactionModalCreate.vue';
    import type PaymentModel from '@/modules/cashier/models/PaymentModel';
    import PaymentSuggestionsModal
        from '@/modules/cashier/payment/infrastructure/components/PaymentSuggestionsModal.vue';
    import type {LegacyInvoiceType} from '@/modules/cashier/utils/billing/invoice/LegacyInvoiceType';
    import {computed} from 'vue';

    const props = defineProps<{
        invoice: LegacyInvoiceType;
        payment: PaymentModel;
        editingAllocations: boolean;
    }>();


    const emit = defineEmits(['created']);

    const isButtonAddAllocationDisabled = computed(() => {
        return Number(props.invoice.computed.remaining_amount) === 0 || props.editingAllocations;
    });

    function showPaymentSuggestionsModal(suggestionType: 'transaction' | 'credit_note') {
        useModal({
            component: PaymentSuggestionsModal,
            props: {
                payment: props.payment,
                suggestionType,
            },
            listeners: () => ({
                created: () => {
                    emit('created', true);
                },
            }),
        }).show();
    }

    function showCreateTransactionModal() {
        useModal({
            component: TransactionModalCreate,
            props: {
                canSelectCustomer: false,
                invoice: props.invoice,
                payment: props.payment,
                tenantId: props.payment.attributes.tenant_id,
            },
            listeners: () => ({
                created: () => {
                    emit('created', true);
                },
            }),
        }).show();
    }
</script>

<template>
    <MTooltip
        v-if="isButtonAddAllocationDisabled"
        class="tw-w-full"
        placement="top"
    >
        <CButtonGroup class="tw-w-full">
            <MButton
                class="tw-w-full"
                disabled
                :label="__('billing_transaction:actions.create_credit')"
                size="lg"
                variant="primary"
            >
                <template v-if="invoice.invoice_type === 'credit_note'">
                    {{ __('billing_transaction:actions.create_debit') }}
                </template>
                <template v-else>
                    {{ __('billing_transaction:actions.create_credit') }}
                </template>
            </MButton>
            <MButton
                disabled
                size="lg"
                variant="primary"
            >
                <FontAwesomeIcon icon="fa fa-caret-down"/>
            </MButton>
        </CButtonGroup>
        <template #content>
            <template v-if="invoice.invoice_type === 'credit_note'">
                {{
                    editingAllocations
                        ? __('billing_invoice:cant_create_debit')
                        : __('billing_invoice:credit_note_paid')
                }}
            </template>
            <template v-else>
                {{
                    editingAllocations
                        ? __('billing_invoice:cant_create_credit')
                        : __('billing_invoice:invoice_paid')
                }}
            </template>
        </template>
    </MTooltip>
    <CButtonGroup
        v-else
        class="tw-w-full"
    >
        <MButton
            class="tw-w-full"
            size="lg"
            variant="primary"
            @click="showCreateTransactionModal"
        >
            <template v-if="invoice.invoice_type === 'credit_note'">
                {{ __('billing_transaction:actions.create_debit') }}
            </template>
            <template v-else>
                {{ __('billing_transaction:actions.create_credit') }}
            </template>
        </MButton>
        <MMenu placement="bottom-end">
            <MMenuButton>
                <MButton
                    size="lg"
                    variant="primary"
                >
                    <FontAwesomeIcon icon="fa fa-caret-down"/>
                </MButton>
            </MMenuButton>
            <MMenuItems>
                <MMenuItem @click="showPaymentSuggestionsModal('transaction')">
                    <template v-if="invoice.invoice_type === 'credit_note'">
                        {{ __('billing_transaction:actions.use_existing_debit') }}
                    </template>
                    <template v-else>
                        {{ __('billing_transaction:actions.use_existing_credit') }}
                    </template>
                </MMenuItem>
                <MMenuItem
                    v-if="invoice.invoice_type === 'invoice'"
                    @click="showPaymentSuggestionsModal('credit_note')"
                >
                    {{ __('billing_transaction:actions.use_credit_note') }}
                </MMenuItem>
            </MMenuItems>
        </MMenu>
    </CButtonGroup>
</template>
