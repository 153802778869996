<template>
    <div class="edit-btn">
        <router-link
            v-if="to"
            class="btn btn-primary rounded-circle"
            :to="to"
        >
            <i
                aria-hidden="true"
                class="fa fa-pencil"
            />
        </router-link>
        <span
            v-else
            class="btn btn-primary rounded-circle"
        >
            <i
                aria-hidden="true"
                class="fa fa-pencil"
            />
        </span>
    </div>
</template>

<script>
    export default {
        props: ['to'],
    };
</script>

<style lang="scss" scoped>
    .edit-btn {
        a, span {
            width: 36px;
            height: 36px;
            padding: 5px 0;

            transition: all 200ms ease-in-out;
            transform: scale(1);

            &:hover {
                animation: zoom .5s 0s;
                transition: all 200ms ease-in-out;
                transform: scale(1.1);
            }

            @keyframes zoom {
                0% {
                    transform: scale(1);
                }
                50% {
                    transform: scale(1.2);
                }
                100% {
                    transform: scale(1.1)
                }
            }
        }
    }
</style>
