<template>
    <CPopover
        ref="dropdown"
        :arrow="false"
        :clickable="false"
        content-class="searchPopover"
        no-padding
    >
        <template #default="{show}">
            <div>
                <transition name="fade-spinner">
                    <div
                        v-show="searching"
                        class="spinner tw-relative tw-z-10"
                    >
                        <i
                            aria-hidden="true"
                            class="fa fa-circle-notch fa-spin"
                        />
                    </div>
                </transition>
                <form
                    autocomplete="off"
                    class="tw-relative tw-z-0"
                    @submit.prevent
                >
                    <CInput
                        v-model="query"
                        autocomplete="!off"
                        class="search tw-shadow !tw-border-transparent focus:!tw-border-primary-500"
                        :placeholder="__('common:search_dots')"
                        type="text"
                        @click="show"
                    />
                </form>
            </div>
        </template>
        <template #content>
            <div
                v-if="query"
                class="suggestions"
            >
                <template v-if="!_isEmpty(suggestions.kids) && $can('read', 'kids_details')">
                    <div class="type kids">
                        <i
                            aria-hidden="true"
                            class="fas fa-child-reaching mr-2"
                        />{{ __('common:kid_other') }}
                    </div>
                    <kid-suggestion
                        v-for="(suggestion, key) in suggestions.kids"
                        :key="'suggestions.kids-' + key"
                        :query="query"
                        :suggestion="suggestion"
                        @click.native="hideResults"
                    />
                </template>
                <template v-if="!_isEmpty(suggestions.customers) && allows('access-to-billings-module')">
                    <div class="type customers">
                        <i
                            aria-hidden="true"
                            class="fas fa-file-user mr-2"
                        />{{ __('common:customer_other') }}
                    </div>
                    <customer-suggestion
                        v-for="(suggestion, key) in suggestions.customers"
                        :key="'suggestions.customers-' + key"
                        :nursery="nursery"
                        :query="query"
                        :suggestion="suggestion"
                        @click.native="hideResults"
                    />
                </template>
                <template v-if="!_isEmpty(suggestions.registrations) && $can('read', 'registrations')">
                    <div class="type registrations">
                        <i
                            aria-hidden="true"
                            class="fas fa-pen-alt mr-2"
                        />{{ __('common:registration_other') }}
                    </div>
                    <registration-suggestion
                        v-for="(suggestion, key) in suggestions.registrations"
                        :key="'suggestions.registrations-' + key"
                        :nursery="nursery"
                        :query="query"
                        :suggestion="suggestion"
                        @click.native="hideResults"
                    />
                </template>
                <template v-if="!_isEmpty(suggestions.staffs) && $can('read', 'staffs_details')">
                    <div class="type staffs">
                        <i
                            aria-hidden="true"
                            class="fas fa-users mr-2"
                        />{{ __('common:team_other') }}
                    </div>
                    <staff-suggestion
                        v-for="(suggestion, key) in suggestions.staffs"
                        :key="'suggestions.staffs-' + key"
                        :query="query"
                        :suggestion="suggestion"
                        @click.native="hideResults"
                    />
                </template>
                <template v-if="!_isEmpty(suggestions.familyMembers) && $can('read', 'family_members_details')">
                    <div class="type familyMembers">
                        <i
                            aria-hidden="true"
                            class="fas fa-user-tie mr-2"
                        />{{ __('common:parent_other') }}
                    </div>
                    <familyMember-suggestion
                        v-for="(suggestion, key) in suggestions.familyMembers"
                        :key="'suggestions.familyMembers-' + key"
                        :query="query"
                        :suggestion="suggestion"
                        @click.native="hideResults"
                    />
                </template>
                <template v-if="!_isEmpty(suggestions.documents) && $can('read', 'documents')">
                    <div class="type documents">
                        <i
                            aria-hidden="true"
                            class="fas fa-file-invoice mr-2"
                        />{{ __('common:document_other') }}
                    </div>
                    <document-suggestion
                        v-for="(suggestion, key) in suggestions.documents"
                        :key="'suggestions.documents-' + key"
                        :query="query"
                        :suggestion="suggestion"
                        @click.native="hideResults"
                    />
                </template>
                <template v-if="!_isEmpty(suggestions.invoices) && $can('read', 'invoices')">
                    <div class="type invoices">
                        <i
                            aria-hidden="true"
                            class="fas fa-file-invoice mr-2"
                        />{{ __('common:invoice_other') }}
                    </div>
                    <invoice-suggestion
                        v-for="(suggestion, key) in suggestions.invoices"
                        :key="'suggestions.invoices-' + key"
                        :query="query"
                        :suggestion="suggestion"
                        @click.native="hideResults"
                    />
                </template>
                <CText
                    v-if="_isEmpty(suggestions.kids) && _isEmpty(suggestions.staffs) && _isEmpty(suggestions.invoices) && _isEmpty(suggestions.familyMembers) && _isEmpty(suggestions.documents) && _isEmpty(suggestions.registrations) && _isEmpty(suggestions.customers)"
                    align="center"
                    as="div"
                    class="tw-p-4"
                    font-size="lg"
                    variant="disabled"
                >
                    {{ __('common:no_match') }}
                </CText>
            </div>
            <div v-else>
                <CText
                    align="center"
                    as="div"
                    class="tw-p-4"
                    font-size="lg"
                    variant="disabled"
                >
                    {{ __('common:search_kid_parent_team_invoice') }}
                </CText>
            </div>
        </template>
    </CPopover>
</template>

<script>
    import _debounce from 'lodash-es/debounce';
    import useApi from '@/modules/app/composables/useApi';
    import route from '@/modules/legacy/libs/ziggy';
    import _isEmpty from 'lodash-es/isEmpty';
    import Kid from './Types/Kid.vue';
    import Staff from './Types/Staff.vue';
    import Invoice from './Types/Invoice.vue';
    import FamilyMember from './Types/FamilyMember.vue';
    import Document from './Types/Document.vue';
    import Registration from './Types/Registration.vue';
    import Customer from './Types/Customer.vue';
    import useAbility from '@/modules/app/composables/useAbility';

    export default {
        components: {
            'kid-suggestion': Kid,
            'staff-suggestion': Staff,
            'invoice-suggestion': Invoice,
            'familyMember-suggestion': FamilyMember,
            'document-suggestion': Document,
            'registration-suggestion': Registration,
            'customer-suggestion': Customer,
        },
        props: ['nursery'],
        data() {
            return {
                query: '',
                showSuggestions: false,
                suggestions: [],
                searching: false,
            };
        },
        computed: {
            allows() {
                return useAbility().allows;
            },
            _isEmpty() {
                return _isEmpty;
            },
        },
        watch: {
            query: _debounce(function() {
                if (this.query) {
                    this.search();
                }
            }, 500),
        },
        mounted() {
            const app = document.getElementById('dashboard');

            app.addEventListener('click', () => {
                this.showSuggestions = false;
            });
        },
        methods: {
            search() {
                this.searching = true;
                useApi().legacy.get(route('search'), {
                    params: {
                        nursery: this.nursery.id,
                        query: this.query,
                    },
                }).then(response => {
                    this.suggestions = response.data;
                }).finally(() => {
                    this.searching = false;
                });
            },
            hideResults() {
                this.showSuggestions = false;
                this.$refs.dropdown.hide();
            },
        },
    };
</script>

<style lang="scss">
    @import "bootstrap/scss/functions";
    @import "@/assets/_bootstrap/variables";
    @import "bootstrap/scss/mixins/breakpoints";

    .searchPopover {
        @apply tw-w-full;
        width: calc(100vw - 16px);
        @screen sm {
            width: 460px;
        }
    }
</style>
<style lang="scss" scoped>
    @import "bootstrap/scss/functions";
    @import "@/assets/_bootstrap/variables";
    @import "bootstrap/scss/mixins/breakpoints";

    .suggestions {
        @apply tw-rounded-xl tw-overflow-y-auto;
        max-height: calc(100vh - 120px);
        -webkit-overflow-scrolling: touch;
    }

    .spinner {
        position: absolute;
        z-index: 1;
        right: 10px;
        top: 3px;
        font-size: 15px;
        @apply tw-text-gray-400;
    }

    .search {
        background: #fff url(data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTguMS4xLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iMCAwIDI1MC4zMTMgMjUwLjMxMyIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMjUwLjMxMyAyNTAuMzEzOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSIgd2lkdGg9IjY0cHgiIGhlaWdodD0iNjRweCI+CjxnIGlkPSJTZWFyY2giPgoJPHBhdGggc3R5bGU9ImZpbGwtcnVsZTpldmVub2RkO2NsaXAtcnVsZTpldmVub2RkOyIgZD0iTTI0NC4xODYsMjE0LjYwNGwtNTQuMzc5LTU0LjM3OGMtMC4yODktMC4yODktMC42MjgtMC40OTEtMC45My0wLjc2ICAgYzEwLjctMTYuMjMxLDE2Ljk0NS0zNS42NiwxNi45NDUtNTYuNTU0QzIwNS44MjIsNDYuMDc1LDE1OS43NDcsMCwxMDIuOTExLDBTMCw0Ni4wNzUsMCwxMDIuOTExICAgYzAsNTYuODM1LDQ2LjA3NCwxMDIuOTExLDEwMi45MSwxMDIuOTExYzIwLjg5NSwwLDQwLjMyMy02LjI0NSw1Ni41NTQtMTYuOTQ1YzAuMjY5LDAuMzAxLDAuNDcsMC42NCwwLjc1OSwwLjkyOWw1NC4zOCw1NC4zOCAgIGM4LjE2OSw4LjE2OCwyMS40MTMsOC4xNjgsMjkuNTgzLDBDMjUyLjM1NCwyMzYuMDE3LDI1Mi4zNTQsMjIyLjc3MywyNDQuMTg2LDIxNC42MDR6IE0xMDIuOTExLDE3MC4xNDYgICBjLTM3LjEzNCwwLTY3LjIzNi0zMC4xMDItNjcuMjM2LTY3LjIzNWMwLTM3LjEzNCwzMC4xMDMtNjcuMjM2LDY3LjIzNi02Ny4yMzZjMzcuMTMyLDAsNjcuMjM1LDMwLjEwMyw2Ny4yMzUsNjcuMjM2ICAgQzE3MC4xNDYsMTQwLjA0NCwxNDAuMDQzLDE3MC4xNDYsMTAyLjkxMSwxNzAuMTQ2eiIgZmlsbD0iIzg3OThhYSIvPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+Cjwvc3ZnPgo=) no-repeat 10px 8px;
        background-size: 13px 13px;
        padding-left: 32px;
        padding-right: 32px;
    }

    .type {
        margin-top: -1px;
        position: relative;
        z-index: 2;
        padding: 12px 15px 11px;
        color: rgba(255, 255, 255, .9);
        font-size: 80%;
        font-weight: $font-weight-bold;
        text-transform: uppercase;

        &.kids {
            background: #e9e0fb;
            color: #663ab7;
        }

        &.staffs {
            background: #ffeff4;
            color: #e91e63;
        }

        &.invoices {
            background: #ffe6cc;
            color: #ff5722;
        }

        &.familyMembers {
            background: #ffeffb;
            color: #b73a9c;
        }

        &.documents {
            background: #e3cdf8;
            color: #673ab7;
        }

        &.registrations {
            background: #b1f3fc;
            color: #0098ac;
        }

        &.customers {
            background: #ffe6cc;
            color: #FF7E23;
        }
    }

    .type-icon {
        position: absolute;
        left: 11px;
        top: 11px;
        font-size: 18px;
    }

    .fade-spinner-enter-active, .fade-spinner-leave-active {
        transition: opacity .2s;
    }

    .fade-spinner-enter, .fade-spinner-leave-to /* .fade-leave-active below version 2.1.8 */
    {
        opacity: 0;
    }
</style>


<style lang="scss">
    @import "bootstrap/scss/functions";
    @import "@/assets/_bootstrap/variables";
    @import "bootstrap/scss/mixins/breakpoints";

    .suggestions > .suggestion {
        margin-top: -1px;
        padding: 19px 29px 18px;
        display: block;
        overflow: hidden;
        position: relative;

        &:after {
            content: '';
            border-bottom: 1px solid #ECF0F4;
            position: absolute;
            bottom: 0;
            right: 29px;
            left: 29px;
        }

        &:last-of-type {
            &:after {
                display: none;
            }
        }

        &:hover {
            background-color: #f6f9fc;
            text-decoration: none;

            &:after {
                display: none;
            }

            .header {
                .title {
                    color: $primary;
                }
            }

            dt {
                color: darken($gray-tint-1, 10%);
            }

            dd {
                color: darken($gray-tint-2, 10%);
            }
        }

        .avatar {
            width: 30px;
            height: 30px;
            float: left;
            margin-right: 10px;
        }

        .additionals {
            margin-top: 0.5rem;
        }

        .additional {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;

            dt {
                clear: both;
                display: inline-block;
                width: 25%;
                color: $gray-tint-1;
                font-weight: normal;
                margin-bottom: 0;
                float: left;
            }

            dd {
                display: inline;
                width: 75%;
                word-break: break-all;
                color: $gray-tint-2;
                margin-bottom: 0;
                float: left;
            }
        }

        .header {
            margin: 6px 0 5px;

            .title {
                @apply tw-text-xl tw-font-display tw-text-gray-700;
                flex-shrink: 1;
                line-height: 24px;
                word-break: break-word;
                padding-right: 5px;
            }
        }
    }
</style>
