<template>
    <CModal
        ref="modal"
        class="TaxCertificates"
        :header-title="__('common:tax_certificate_other')"
        size="5xl"
        visible
        @hidden="onHidden"
    >
        <TaxCertificatesEmptyList v-if="!hasDocTemplate"/>
        <div
            v-else
            class="TaxCertificates__container"
        >
            <TaxCertificatesIntroductionMessage/>
            <TaxCertificatesFilters
                :customer-person-options="customerPersonSelectorOptions"
                :filters="filters"
                :show-tenants-filter="showTenantsFilter"
            >
                <CFormErrorMessageList
                    v-if="periodErrors.isNotEmpty()"
                    class="tw-basis-full tw-pt-4"
                    :errors="periodErrors"
                />
            </TaxCertificatesFilters>
            <TaxCertificatesToggleSelector
                @selectAll="toggleAll(true)"
                @unselectAll="toggleAll(false)"
            />
            <TaxCertificatesList
                :customers="customers"
                :loading="isFiltersLoading || isListLoading"
                :toggle-kid-fn="toggleKid"
            />
            <TaxCertificatesSendToSelector
                :email-model="email"
                :is-sent-to-single-email="isSentToSingleEmail"
                @sendToEachParents="isSentToSingleEmail = false"
                @sendToSingleEmail="isSentToSingleEmail = true"
            />
        </div>
        <template #footer>
            <CButton
                class="TaxCertificates__cancel-button"
                variant="light"
                @click="$emit('hide')"
            >
                {{ __('common:actions.cancel') }}
            </CButton>
            <CButton
                class="TaxCertificates__send-button"
                :disabled="selectedCustomers.count() === 0 && !filters.docTemplate"
                :loading="isSending"
                variant="primary"
                @click="sendCertificates(() => $emit('hide'))"
            >
                {{ __('common:actions.send') }}
            </CButton>
        </template>
    </CModal>
</template>

<script lang="ts">
    import {computed, defineComponent, onBeforeMount, reactive, ref} from 'vue';
    import TaxCertificatesEmptyList
        from '@/modules/cashier/components/tax/tax-certificate/molecules/TaxCertificatesEmptyList.vue';
    import TaxCertificatesIntroductionMessage
        from '@/modules/cashier/components/tax/tax-certificate/molecules/TaxCertificatesIntroductionMessage.vue';
    import TaxCertificatesFilters
        from '@/modules/cashier/components/tax/tax-certificate/organisms/TaxCertificatesFilters.vue';
    import TaxCertificatesToggleSelector
        from '@/modules/cashier/components/tax/tax-certificate/organisms/TaxCertificatesToggleSelector.vue';
    import TaxCertificatesSendToSelector
        from '@/modules/cashier/components/tax/tax-certificate/organisms/TaxCertificatesSendToSelector.vue';
    import TaxCertificatesList
        from '@/modules/cashier/components/tax/tax-certificate/organisms/TaxCertificatesList.vue';
    import useManager from '@/modules/app/composables/useManager';
    import useCustomersList from '@/modules/cashier/composables/tax/tax-certificate/useCustomersList';
    import useCustomersSelector from '@/modules/cashier/composables/tax/tax-certificate/useCustomersSelector';
    import useSendTaxCertificates from '@/modules/cashier/composables/tax/tax-certificate/useSendTaxCertificates';
    import useCustomersFilters from '@/modules/cashier/composables/tax/tax-certificate/useCustomersFilters';
    import DocTemplateTypeValues from '@/modules/organization/utils/Values/DocTemplateTypeValues';
    import useAuth from '@/modules/app/composables/useAuth';

    export default defineComponent({
        components: {
            TaxCertificatesList,
            TaxCertificatesSendToSelector,
            TaxCertificatesToggleSelector,
            TaxCertificatesFilters,
            TaxCertificatesIntroductionMessage,
            TaxCertificatesEmptyList,
        },
        setup(props, {emit}) {
            const {legacyNursery, activeOrganization} = useManager();
            const {user} = useAuth();

            /**
             * Email to send tax certificates
             */
            const email = ref(user.value?.attributes.email || '');

            /**
             * Check if nursery has tax certificate template
             */
            const hasDocTemplate = legacyNursery.value?.docTemplates
                .filter(item => item.type === DocTemplateTypeValues.taxCertificate).length > 0;

            const showTenantsFilter = computed(() => user.value.organizations().value().count() > 1);


            const {
                customerPersonSelectorOptions,
                filters,
                waitForFiltersToBeLoaded,
                isLoading: isFiltersLoading,
                initFiltersValues,
                initWatchers: initFiltersWatchers,
            } = useCustomersFilters();

            const {
                customers,
                isLoading: isListLoading,
                initWatchers: initListWatchers,
                debounceGetCustomers,
            } = useCustomersList(filters);

            const {
                selectedCustomers,
                toggleAll,
                toggleKid,
            } = useCustomersSelector(customers);

            const {
                isSentToSingleEmail,
                periodErrors,
                sendCertificates,
                isSending,
            } = useSendTaxCertificates(customers, {
                ...filters,
                selectedCustomers,
                organization: activeOrganization,
                email,
            });

            onBeforeMount(async () => {
                // Await initial filters loading
                await waitForFiltersToBeLoaded();
                // Await initial orgnaization and doc template filters loading
                await initFiltersValues();
                // Await second filters loading to not trigger twice reload of customers list
                await waitForFiltersToBeLoaded();
                initFiltersWatchers();
                await debounceGetCustomers();
                initListWatchers();
            });

            function onHidden() {
                emit('hide');
            }

            // In Vue's template, refs contained in the "filters" object are not automatically unwrapped.
            // To solve this issue, we use "reactive" to unwrap the refs in the template.
            const reactiveFilters = reactive({...filters});

            return {
                isListLoading,
                isSending,
                isFiltersLoading,

                isSentToSingleEmail,
                email,
                customers,
                selectedCustomers,
                periodErrors,

                hasDocTemplate,
                customerPersonSelectorOptions,
                filters: reactiveFilters,
                showTenantsFilter,

                onHidden,
                sendCertificates,
                toggleKid,
                toggleAll,
            };
        },
    });
</script>

<style lang="scss" scoped>
    .TaxCertificates__container {
        @apply tw-flex tw-flex-col tw-gap-5;
    }

    .TaxCertificates__cancel-button {
        @apply tw-ml-auto;
    }

    .TaxCertificates__send-button {
        @apply tw-ml-2;
    }
</style>
