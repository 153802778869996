<script setup lang="ts">
    import {inject} from 'vue';
    import {keyMCalendarNavigation} from '@/modules/meeko-ui/components/MCalendar/useMCalendarNavigation';

    const navigation = inject(keyMCalendarNavigation);
    if (!navigation) {
        throw new Error('keyMCalendarNavigation must be provided');
    }

    const {
        activeView,
        activeMonth,
        toggleActiveView,
        yearsRange,
    } = navigation;

    function previous() {
        if (activeView.value === 'day') {
            activeMonth.value = activeMonth.value.subMonths(1);

            return;
        }

        if (activeView.value === 'month') {
            activeMonth.value = activeMonth.value.subYears(1);

            return;
        }

        if (activeView.value === 'year') {
            yearsRange.value = {
                from: yearsRange.value.from - 12,
                to: yearsRange.value.from - 1,
            };
        }
    }

    function next() {
        if (activeView.value === 'day') {
            activeMonth.value = activeMonth.value.addMonths(1);

            return;
        }

        if (activeView.value === 'month') {
            activeMonth.value = activeMonth.value.addYears(1);

            return;
        }

        if (activeView.value === 'year') {
            yearsRange.value = {
                from: yearsRange.value.to + 1,
                to: yearsRange.value.to + 12,
            };
        }
    }
</script>

<template>
    <div class="MCalendarControl">
        <MButton
            class="MCalendarControl__arrow-button"
            variant="light-alt"
            @click="previous"
        >
            <FontAwesomeIcon icon="fa fa-chevron-left"/>
        </MButton>
        <MButton
            v-if="activeView === 'day'"
            class="MCalendarControl__button"
            :truncate="false"
            variant="light-alt"
            @click="toggleActiveView('month')"
        >
            {{ activeMonth.toLocaleString({month: 'long'}) }}
        </MButton>
        <MButton
            v-if="activeView === 'day' || activeView === 'month'"
            class="MCalendarControl__button"
            variant="light-alt"
            @click="toggleActiveView('year')"
        >
            {{ activeMonth.toLocaleString({year: 'numeric'}) }}
        </MButton>
        <div
            v-if="activeView === 'year'"
            class="MCalendarControl__title"
        >
            {{ yearsRange.from }} - {{ yearsRange.to }}
        </div>
        <MButton
            v-if="activeView === 'yearsRange'"
            class="MCalendarControl__button"
            variant="light-alt"
            @click="toggleActiveView('year')"
        >
            {{ yearsRange.from }} - {{ yearsRange.to }}
        </MButton>
        <MButton
            class="MCalendarControl__arrow-button"
            variant="light-alt"
            @click="next"
        >
            <FontAwesomeIcon icon="fa fa-chevron-right"/>
        </MButton>
    </div>
</template>

<style scoped lang="scss">
    .MCalendarControl {
        @apply tw-flex tw-items-baseline tw-gap-1 tw-w-full;
    }

    .MCalendarControl__title {
        @apply tw-px-3 tw-py-1.5 tw-capitalize tw-flex-1 tw-text-center tw-font-medium tw-font-display tw-text-gray-500;
    }

    .MCalendarControl__button {
        @apply tw-bg-transparent hover:tw-bg-primary-200 tw-px-3 tw-py-1.5 tw-capitalize tw-flex-1;
    }

    .MCalendarControl__arrow-button {
        @apply tw-bg-transparent hover:tw-bg-primary-200 tw-px-3 tw-py-1.5;
    }
</style>
