import swal from 'sweetalert2/dist/sweetalert2.js';
import __ from '@/modules/app/utils/i18n-facade';

export const success = swal.mixin({
    type: 'success',
    showCloseButton: true,
    showConfirmButton: true,
    showCancelButton: false,
    confirmButtonText: __('common:understand'),
    confirmButtonClass: 'btn btn-primary ml-1 mr-1 btn-block',
    buttonsStyling: false,
});

export const error = swal.mixin({
    type: 'error',
    showCloseButton: true,
    showConfirmButton: true,
    showCancelButton: false,
    confirmButtonText: __('common:actions.close'),
    confirmButtonClass: 'btn btn-primary ml-1 mr-1',
    buttonsStyling: false,
});

export const base = swal.mixin({
    type: 'success',
    showCloseButton: true,
    showConfirmButton: true,
    showCancelButton: true,
    confirmButtonText: __('common:actions.confirm'),
    cancelButtonText: __('common:actions.cancel'),
    confirmButtonClass: 'btn btn-primary ml-1 mr-1',
    cancelButtonClass: 'btn btn-light ml-1 mr-1',
    buttonsStyling: false,
});
