<template>
    <svg
        viewBox="0 0 30 30"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M15 3a6 6 0 0 0-6 6v1a6 6 0 1 0 12 0V9a6 6 0 0 0-6-6zm0 16c-4 0-9.15 2.17-10.63 4.09A1.82 1.82 0 0 0 5.83 26h18.34c1.5 0 2.37-1.72 1.45-2.91C24.16 21.17 19 19 15 19z"/>
    </svg>
</template>

<script lang="ts">
    import {defineComponent} from 'vue';

    export default defineComponent({});
</script>
