import {Model} from '@meekohq/lumos';
import MedicalTreatmentModel from '@/modules/health/models/MedicalTreatmentModel';
import KidModel from '@/modules/family/models/KidModel';
import moment from 'moment';

export default class MedicalActionModel extends Model {
    public type = 'health/medical_actions';

    public attributes: {
        id: string,
        account_id: string | undefined,
        medical_treatment_id: string | undefined,
        kid_id: string | undefined,
        note: string | null,
        scheduled_at: string | null,
        done_at: string | null,
        task_scheduled_at: string | null,
        task_created_at: string | null
    } = {
            id: this.uuid(),
            account_id: undefined,
            medical_treatment_id: undefined,
            kid_id: undefined,
            note: null,
            scheduled_at: null,
            done_at: null,
            task_scheduled_at: null,
            task_created_at: null,
        };

    get isLate() {
        return this.attributes.done_at || !this.attributes.scheduled_at ? false : moment(this.attributes.scheduled_at).isBefore(moment());
    }

    medicalTreatment() {
        return this.belongsTo('medicalTreatment', MedicalTreatmentModel, 'medical_treatment_id');
    }

    kid() {
        return this.belongsTo('kid', KidModel, 'kid_id');
    }
}
