<template>
    <MagicIndexFilters :variant="variant">
        <template #filters>
            <TagsResourceFinder
                button-size="sm"
                has-unselect
                :inject-query="tagFinderQuery"
                multi
                only-active-organization
                :search-bar="false"
                :value="selectedTagsModels"
                :without-tags-option-is-selected="withoutTags"
                @input="$emit('update:selectedTagsModels', $event)"
                @without-tags-option-is-selected="$emit('update:withoutTags', $event)"
            />
            <div v-if="nursery.groups && nursery.groups.length">
                <KidGroupFinder
                    button-class="tw-h-6"
                    :fall-back-text="__('photo:filter_by_group')"
                    has-unselect
                    :inject-query="groupFinderQuery"
                    only-active-organization
                    :value="selectedGroup"
                    @input="selectGroup"
                />
            </div>
            <div>
                <KidFinder
                    button-class="tw-h-6"
                    :fall-back-text="__('photo:filter_by_kid')"
                    has-unselect
                    :inject-query="kidFinderQuery"
                    :value="selectedKid"
                    @input="$emit('update:selectedKid', $event)"
                />
            </div>
        </template>
    </MagicIndexFilters>
</template>

<script lang="ts">
    import type {PropType} from 'vue';
    import {computed, defineComponent} from 'vue';
    import MagicIndexFilters from '@/modules/magic-index/components/organisms/MagicIndexFilters.vue';
    import KidFinder from '@/modules/family/components/kid/KidFinder.vue';
    import KidGroupFinder from '@/modules/organization/components/KidGroupFinder.vue';
    import KidsGroupModel from '@/modules/organization/models/KidsGroupModel';
    import KidModel from '@/modules/family/models/KidModel';
    import useManager from '@/modules/app/composables/useManager';
    import TagModel from '@/modules/tag/models/TagModel';
    import TagsResourceFinder from '@/modules/tag/components/TagsResourceFinder.vue';

    export default defineComponent({
        components: {TagsResourceFinder, KidGroupFinder, KidFinder, MagicIndexFilters},
        props: {
            nursery: {
                type: Object,
                required: true,
            },
            selectedKid: {
                type: Object as PropType<KidModel>,
                default: undefined,
            },
            selectedGroup: {
                type: Object as PropType<KidsGroupModel>,
                default: undefined,
            },
            kids: {
                type: Array,
                required: true,
            },
            selectedTagsModels: {
                type: Array as PropType<TagModel[]>,
                required: true,
            },
            withoutTags: {
                type: Boolean,
                required: true,
            },
            variant: {
                type: String,
                default: 'base',
            },
        },

        setup(props, {emit}) {
            const {activeOrganization} = useManager();

            const tagFinderQuery = TagModel.query().where('type', 'photo');

            const kidFinderQuery = computed(() => {
                const query = KidModel.query().whereHas(new KidModel().organizations(), query2 => {
                    query2.where('id', activeOrganization.value.getKey());
                });

                if (props.selectedGroup) {
                    query.whereHas(new KidModel().group(), query2 => {
                        query2.where('id', props.selectedGroup?.getKey());
                    });
                }

                return query;
            });

            const groupFinderQuery = computed(() => KidsGroupModel.query().whereHas(new KidsGroupModel().organization(), query => {
                query.where('id', activeOrganization.value.getKey());

                return query;
            }));

            function selectGroup(groupModel: KidsGroupModel | null) {
                emit('update:selectedGroup', groupModel);
                emit('update:selectedKid', undefined);
            }

            return {
                tagFinderQuery,
                kidFinderQuery,
                groupFinderQuery,
                selectGroup,
            };
        },
    });
</script>

<style lang="scss">
    .PhotoFilters__list {
        @apply tw-p-2 tw-cursor-pointer;
    }
</style>
