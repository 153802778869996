import type {Moment} from 'moment';

export type OpeningHour = {
    day: string,
    open: Moment,
    close: Moment
};

const weekDays = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];

export class OpeningHourValue {
    protected day: string;
    protected open: Moment;
    protected close: Moment;

    public constructor(openingHour: OpeningHour) {
        if (!this.validate(openingHour)) {
            throw new Error('Bad format');
        }
        this.day = openingHour.day;
        this.open = openingHour.open;
        this.close = openingHour.close;
    }

    public validate(openingHour: OpeningHour): boolean {
        let valid = true;

        if (!weekDays.includes(openingHour.day) || !openingHour.open.isValid() || !openingHour.close.isValid()) {
            valid = false;
        }

        return valid;
    }

    public getDay() {
        return this.day;
    }

    public getOpening() {
        return this.open;
    }

    public getOpeningHour() {
        return this.open.hour();
    }

    public getOpeningMinute() {
        return this.open.minute();
    }

    public getOpeningSecond() {
        return this.open.second();
    }

    public getClosing() {
        return this.close;
    }

    public getClosingHour() {
        return this.close.hour();
    }

    public getClosingMinute() {
        return this.close.minute();
    }

    public getClosingSecond() {
        return this.close.second();
    }
}
