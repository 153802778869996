<script setup lang="ts">
    import FeesInput from '@/modules/cashier/components/settings/RulesCards/FeesInput.vue';
    import CurrencyInput from '@/modules/app/components/atoms/CurrencyInput.vue';
    import __ from '@/modules/app/utils/i18n-facade';

    defineProps({
        nursery: {
            type: Object,
            required: true,
        },
        fee: {
            type: Object,
            required: true,
        },
    });

    const customFeesPeriodOptions = [
        {
            text: __('common:per_day'),
            value: 'day',
        }, {
            text: __('common:per_month'),
            value: 'month',
        },
    ];
</script>

<template>
    <div>
        <div class="tw-grid tw-grid-cols-4 tw-gap-4 tw-mb-4">
            <FeesInput
                class="md:tw-col-span-2 tw-col-span-full"
                :fee="fee"
                @removeFees="$emit('removeFees', fee)"
            />
        </div>
        <div class="tw-grid lg:tw-grid-cols-4 sm:tw-grid-cols-2 tw-grid-cols-1 tw-gap-4">
            <CurrencyInput
                v-model="fee.amount"
                :currency="nursery.currency"
            />
            <CFormSelect
                v-model="fee.period"
                :options="customFeesPeriodOptions"
                :search-bar="false"
            />
        </div>
    </div>
</template>
