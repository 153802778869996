import useApi from '@/modules/app/composables/useApi';
import route from '@/modules/legacy/libs/ziggy';
import {UploadError} from '@/modules/legacy/errors';

const multipartChunkSize = 5 * 1024 * 1024;

export function getFileChunk(file, part) {
    const start = (part - 1) * multipartChunkSize;
    const end = Math.min((start + multipartChunkSize), file.size);

    return file.slice(start, end);
}

export function presignRequest(file, key, partNumber, uploadId, hash) {
    return new Promise(function(resolve, reject) {
        useApi().legacy.post(route('upload.presigned'), {
            part_number: partNumber,
            upload_id: uploadId,
            key,
            hash,
            name: file.name,
            type: file.type,
        }).then(response => {
            resolve(response.data);
        }).catch(error => {
            reject(error);
        });
    });
}

export function putObject(url, blob, file) {
    return new Promise(function(resolve, reject) {
        useApi().legacy.put(url, blob, {
            headers: {
                'Content-Type': file.type,
            },
            transformRequest: [(data, headers) => {
                headers.delete('Authorization');

                return data;
            }],
        }).then(response => {
            resolve(response.data);
        }).catch(error => {
            reject(error);
        });
    });
}

export function processQueue(queue, file, uploadId, key, hash, resolve, reject) {
    // Get the first job in the queue then remove it from queue
    const job = queue.shift();

    presignRequest(file, key, job.currentPart, uploadId, hash).then((response: any) => {
        uploadId = response.upload_id;
        key = response.key;
        hash = response.hash;

        putObject(response.url, job.blob, file).then(() => {
            if (queue.length) {
                // There is more job in the queue
                processQueue(queue, file, uploadId, key, hash, resolve, reject);
            } else {
                // No more job in the queue
                useApi().legacy.post(route('upload.complete', {
                    upload_id: uploadId,
                    key,
                    hash,
                })).then(response => {
                    resolve(response.data);
                }).catch(error => {
                    reject(error);
                });
            }
        }).catch(error => {
            job.try++;
            queue.push(job);

            // Stop after 3 tries
            if (job.retry <= 3) {
                // Wait 10 seconds and retry
                setTimeout(() => {
                    processQueue(queue, file, uploadId, key, hash, resolve, reject);
                }, 10000);
            } else {
                // Stop uploading, it won't work :(
                reject(error);
            }
        });
    }).catch(error => {
        job.try++;
        queue.push(job);

        // Stop after 3 tries
        if (job.retry <= 3) {
            // Wait 10 seconds and retry
            setTimeout(() => {
                processQueue(queue, file, uploadId, key, hash, resolve, reject);
            }, 10000);
        } else {
            // Stop uploading, it won't work :(
            reject(error);
        }
    });
}

export function upload(file) {
    try {
        return new Promise(function(resolve, reject) {
            const totalParts = file ? Math.ceil(file.size / multipartChunkSize) : 0;

            const queue: any[] = [];
            let currentPart = 1;

            do {
                queue.push({
                    blob: getFileChunk(file, currentPart),
                    currentPart,
                    try: 1,
                });

                currentPart++;
            } while (currentPart <= totalParts);

            processQueue(queue, file, undefined, undefined, undefined, response => {
                resolve(response);
            }, error => {
                reject(error);
            });
        });
    } catch (err) {
        throw new UploadError('Upload Error', {cause: err});
    }
}

// export function upload(file) {
//     return new Promise(async function (resolve, reject) {
//         useApi().legacy.get(route('upload.presigned', {size: file.size})).then((response) => {
//             let uri = response.data.uri;
//             let object = response.data.object;
//             let hash = response.data.hash;
//
//             useApi().legacy.put(uri, file, {
//                 headers: {
//                     'Content-Type': file.type,
//                     'Content-Disposition': 'inline; filename="' + file.name + '"; filename*=UTF-8\'\'"' + encodeURI(file.name) + '"',
//                 },
//                 transformRequest: [(data, headers) => {
//                     delete headers.common.Authorization;
//                     return data;
//                 }],
//             }).then(() => {
//                 resolve({object: object, hash: hash});
//             }).catch((error) => {
//                 Vue.toasted.error(error);
//                 reject(error);
//             });
//         }).catch((error) => {
//             Vue.toasted.error(error);
//             reject(error);
//         });
//     });
// }
