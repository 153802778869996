<template>
    <div>
        <div class="tw-flex tw-flex-wrap tw-gap-2 tw-items-center tw-border-b tw-border-gray-200 tw-pb-4 tw-mb-4">
            <MDatePeriodFilter v-model="periodFilter"/>
            <MDropdown>
                <MButton>
                    <template #left-icons>
                        <FontAwesomeIcon icon="fad fa-calendar-star"/>
                    </template>
                    {{ __('hr:filter_event') }}
                </MButton>
                <template #content>
                    <div class="tw-flex tw-flex-col tw-gap-4">
                        <EventTypeFinder
                            v-model="eventTypes"
                            button-class="tw-w-full"
                            multi
                            @input="onEventTypeClicked"
                        />
                        <OrganizationFinder
                            v-if="hasManyOrganizationsInAccount"
                            v-model="selectedOrganizations"
                            button-class="tw-w-full"
                            :inject-query="organizationFinderQueryBuilder"
                            multi
                            multi-minimum="1"
                        />
                        <CCheckbox v-model="getEventsWithoutOrganizations">
                            {{ __('hr:include_events_without_organization') }}
                        </CCheckbox>
                        <FilterEvents
                            class="tw-w-full"
                            :default-text="__('common:more_filters')"
                            :event-filters.sync="eventFilters"
                            local-storage-key="reportEvents"
                        />
                    </div>
                </template>
            </MDropdown>
        </div>

        <loader
            v-if="loading"
            light="true"
            shadow="false"
            size="sm"
            :title="__('common:loading_dots')"
        />
        <div
            v-else
            class="tw-grid lg:tw-grid-cols-3 tw-grid-cols-1 tw-gap-4"
        >
            <MBox
                v-if="!staff"
                class="lg:tw-col-span-1 tw-bg-blue-50 tw-p-4"
            >
                <StaffsList
                    :organizations="selectedOrganizations"
                    :selected-contracts.sync="selectedContracts"
                    :selected-staff.sync="selectedStaff"
                    :selected-tags.sync="selectedTags"
                    :selected-teams.sync="selectedTeams"
                    :staff-events="staffEvents"
                    :staffs="filteredStaffs"
                    :without-tags.sync="withoutTags"
                />
                <CPagination
                    v-if="paginator"
                    class="tw-text-center tw-mt-2"
                    :paginator="paginator"
                    @navigate="$page => getStaffs($page)"
                />
            </MBox>

            <div :class="staff ? 'lg:tw-col-span-3' : 'lg:tw-col-span-2'">
                <loader
                    v-if="loadingEvent"
                    light="true"
                    shadow="false"
                    size="sm"
                />
                <template v-else>
                    <div
                        v-if="selectedStaff"
                        class="tw-flex md:tw-flex-row tw-flex-col tw-gap-4"
                    >
                        <div class="tw-flex tw-flex-col tw-gap-4 tw-w-full md:tw-w-7/12">
                            <StaffDetails
                                :from="periodFilter.from"
                                :staff="selectedStaff"
                                :staff-statistics="staffStatistics"
                                :to="periodFilter.to"
                            />
                            <EventChartByTypes
                                v-if="staffEvents"
                                :events="staffEvents"
                            />
                        </div>
                        <div class="tw-w-full md:tw-w-5/12">
                            <EventsByDay
                                :events="staffEvents"
                                :from="periodFilter.from"
                                :to="periodFilter.to"
                            />
                        </div>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import type {PropType, Ref} from 'vue';
    import {computed, defineComponent, onMounted, ref, watch} from 'vue';
    import moment from 'moment';
    import useStaff from '@/modules/human-resources/composables/staff/useStaff';
    import useStaffsStatistics from '@/modules/human-resources/composables/statistics/useStaffsStatistics';
    import type StaffModel from '@/modules/human-resources/models/StaffModel';
    import StaffsList from '@/modules/human-resources/components/statistics/StaffsList.vue';
    import EventsByDay from '@/modules/human-resources/components/statistics/EventsByDay.vue';
    import StaffDetails from '@/modules/human-resources/components/statistics/StaffDetails.vue';
    import EventChartByTypes from '@/modules/human-resources/components/statistics/EventChartByTypes.vue';
    import FilterEvents from '@/modules/human-resources/components/calendar/FilterEvents.vue';
    import OrganizationFinder from '@/modules/organization/components/OrganizationFinder.vue';
    import EventTypeFinder from '@/modules/human-resources/components/event-type/EventTypeFinder.vue';
    import MDatePeriodFilter from '@/modules/meeko-ui/components/MDatePeriodFilter.vue';
    import OrganizationModel from '@/modules/organization/models/OrganizationModel';
    import useUserFilters, {OutputType} from '@/modules/app/composables/useUserFilters';
    import type TeamModel from '@/modules/human-resources/models/TeamModel';
    import {ModelCollection} from '@meekohq/lumos';

    export default defineComponent({
        components: {
            EventChartByTypes,
            EventTypeFinder,
            EventsByDay,
            FilterEvents,
            MDatePeriodFilter,
            OrganizationFinder,
            StaffDetails,
            StaffsList,
        },
        props: {
            staff: {
                type: Object as PropType<StaffModel>,
                required: false,
            },
        },
        setup(props) {
            const loadingEvent = ref(false);
            const periodFilter = ref({
                from: localStorage.getItem('staffs:statistics:from') || moment().startOf('month').toISOString(true),
                to: localStorage.getItem('staffs:statistics:to') || moment().endOf('month').toISOString(true),
            });
            const dropdownFilterEvents = ref();
            const organizationFinderQueryBuilder = computed(() => OrganizationModel.query().scope('userOrganizations'));

            const {selectedOptions: selectedTeams} =
                useUserFilters('staffs:statictics:teams', OutputType.array, []);

            watch(selectedTeams, () => {
                getStaffs(1);
            });

            const {
                filteredStaffs,
                selectedStaff,
                loading,
                selectedTags,
                selectedContracts,
                selectedOrganizations,
                selectedOrganizationsIds,
                initFilters,
                paginator,
                withoutTags,
                getStaffs,
                getEventsWithoutOrganizations,
            } = useStaff(
                null,
                props.staff,
                null,
                periodFilter,
                false,
                ref({teams: (selectedTeams as Ref<TeamModel[]>)}),
            );

            watch(selectedStaff, () => {
                if (selectedStaff.value && periodFilter.value.from && periodFilter.value.to) {
                    loadingEvent.value = true;
                    staffEvents.value = new ModelCollection([]);
                    getStaffEvents(periodFilter, selectedOrganizationsIds, getEventsWithoutOrganizations).then(events => {
                        staffEvents.value = filteringStaffEvents(events);
                        loadingEvent.value = false;
                    });
                }
            });

            watch(getEventsWithoutOrganizations, () => {
                getStaffs();
                dropdownFilterEvents.value.toggle();
            });

            const staffStatistics = useStaffsStatistics(selectedStaff);
            const {
                eventFilters,
                staffEvents,
                calendars,
                hasManyOrganizationsInAccount,

                totalEvents,
                totalEventsForCalendar,

                eventTypes,
                getStaffEvents,
                filteringStaffEvents,
                onEventTypeClicked,
            } = staffStatistics;

            onMounted(() => {
                initFilters();
            });

            return {
                moment,
                periodFilter,
                hasManyOrganizationsInAccount,
                getEventsWithoutOrganizations,
                dropdownFilterEvents,

                filteredStaffs,
                selectedTags,
                selectedContracts,
                selectedTeams,
                selectedOrganizations,
                organizationFinderQueryBuilder,
                getStaffs,
                paginator,

                selectedStaff,
                staffStatistics,
                withoutTags,
                loading,
                loadingEvent,

                staffEvents,
                eventFilters,
                calendars,

                totalEvents,
                totalEventsForCalendar,

                eventTypes,
                onEventTypeClicked,
            };
        },
    });
</script>
