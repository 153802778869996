<script setup lang="ts">
    import {inject, ref, watch} from 'vue';
    import type {MCalendarMonthsItem} from '@/modules/meeko-ui/components/MCalendar/useMCalendarMonths';
    import {keyMCalendarMonths} from '@/modules/meeko-ui/components/MCalendar/useMCalendarMonths';
    import {useElementHover} from '@vueuse/core';

    const props = defineProps<{
        month: MCalendarMonthsItem
    }>();

    const monthsState = inject(keyMCalendarMonths);
    if (!monthsState) {
        throw new Error('keyMCalendarMonths must be provided');
    }

    const monthElement = ref();
    const isHovered = useElementHover(monthElement);

    const {
        hoveredMonth,
        handleMonthClick,
    } = monthsState;

    watch(isHovered, newValue => {
        if (newValue) {
            hoveredMonth.value = props.month.month;
        }
    });
</script>

<template>
    <button
        ref="monthElement"
        class="MCalendarMonthsItem"
        :class="{
            'MCalendarMonthsItem--selecting': month.isSelecting,
            'MCalendarMonthsItem--in-period': month.isInPeriod,
            'MCalendarMonthsItem--hovered': isHovered,
            'MCalendarMonthsItem--selected': month.isSelected,
        }"
        @click="handleMonthClick(month.month)"
    >
        {{ month.month.toLocaleString({month: 'long'}) }}
    </button>
</template>

<style scoped lang="scss">
    .MCalendarMonthsItem {
        @apply tw-p-2 tw-rounded tw-font-display tw-cursor-pointer tw-select-none;
        @apply tw-transition-all hover:tw-bg-gray-200;
        @apply tw-text-center tw-text-gray-800 tw-capitalize;
    }

    .MCalendarMonthsItem--hovered {
        @apply tw-bg-gray-200;
    }

    .MCalendarMonthsItem--in-period {
        &:not(.MCalendarMonthsItem--selecting) {
            @apply tw-bg-primary-200 tw-text-primary-900;
        }

        &.MCalendarMonthsItem--selecting {
            @apply tw-bg-gray-200;
        }
    }

    .MCalendarMonthsItem--selected {
        @apply tw-bg-primary-500 tw-text-white #{!important};

        &.MCalendarMonthsItem--hovered {
            @apply tw-bg-primary-600 #{!important};
        }
    }
</style>
