import type {Ref} from 'vue';
import {ref} from 'vue';
import {MqlTransaction} from '@meekohq/lumos';
import type {Emitter} from 'mitt';
import StoreCustomerAction from '@/modules/cashier/utils/core/customer/StoreCustomerAction';
import CustomerModel from '@/modules/cashier/models/CustomerModel';
import type FamilyModel from '@/modules/family/models/FamilyModel';

export interface SaveCustomerRefParams {
    bus: Emitter<{ error: Error, stored: void }>,
    customer: Ref<CustomerModel>,
    customerWithSameAccountNumber: Ref<CustomerModel | undefined>,
    selectedFamily: Ref<FamilyModel | undefined>,
    isSameAccountWarningModalVisible: Ref<boolean>,
    isConfirmedDuplicateAccount: Ref<boolean>,
}

export default function useSaveCustomer(customerParams: SaveCustomerRefParams) {
    const loading = ref(false);
    const {
        bus,
        customer,
        customerWithSameAccountNumber,
        selectedFamily,
        isSameAccountWarningModalVisible,
        isConfirmedDuplicateAccount,
    } = customerParams;

    async function saveCustomer() {
        loading.value = true;
        const storeCustomer = new StoreCustomerAction();
        let mqlRunner: MqlTransaction = new MqlTransaction();

        try {
            mqlRunner.enableDryRun();
            // Store the transaction
            const {customerPromise, pivotPromise} = storeCustomer.storeAndAssociateCustomer(
                customer.value as CustomerModel,
                selectedFamily.value as FamilyModel,
                mqlRunner,
            );

            customerPromise.catch(error => bus.emit('error', error));

            if (pivotPromise) {
                pivotPromise.catch(error => bus.emit('error', error));
            }

            await mqlRunner.run();
            if (mqlRunner.failed) {
                return;
            }

            // Here we check if account number is set, if one other customer has the same account number
            if (customer.value.attributes.account_number &&
                customer.value.attributes.account_number !== customer.value.getOriginal().account_number
            ) {
                customerWithSameAccountNumber.value = await CustomerModel.query()
                    .where('account_number', customer.value.attributes.account_number)
                    .where('tenant_id', customer.value.attributes.tenant_id)
                    .where('id', '!=', customer.value.attributes.id)
                    .first();
            }

            if (customerWithSameAccountNumber.value && !isConfirmedDuplicateAccount.value) {
                isSameAccountWarningModalVisible.value = true;

                return;
            }

            // Wait previous resolutions models for rerun a new Transaction
            await customerPromise;
            await pivotPromise;

            mqlRunner = new MqlTransaction();

            storeCustomer.storeAndAssociateCustomer(
                customer.value as CustomerModel,
                selectedFamily.value as FamilyModel,
                mqlRunner,
            );

            await mqlRunner.run(true);

            if (!customer.value.isCompany && pivotPromise && selectedFamily.value !== undefined) {
                customer.value.families().value().push(selectedFamily.value);
            }

            bus.emit('stored');
        } finally {
            loading.value = false;
        }
    }

    return {
        loading,
        saveCustomer,
    };
}
