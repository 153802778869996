<template>
    <CModal
        ref="modal"
        size="3xl"
    >
        <template #header>
            <h2 class="h2 tw-w-full tw-font-grandhotel tw-text-center tw-mb-0">
                {{ $capitalize(newNursery.name) }}
            </h2>
        </template>


        <CHStack gap="5">
            <CFormGroup class="tw-flex-1">
                <CLabel>{{ __('common:last_name') + '*' }}</CLabel>
                <CInput
                    v-model="newNursery.name"
                    :has-error="errors.has('name')"
                    :placeholder="__('common:name_of_organization')"
                />
                <CFormErrorMessageList :errors="errors.get('name')"/>
            </CFormGroup>
        </CHStack>

        <CHStack
            class="tw-mt-8"
            gap="5"
        >
            <CFormGroup class="tw-flex-1">
                <CLabel>{{ __('common:email') + '*' }}</CLabel>
                <CInput
                    v-model="newNursery.email"
                    :has-error="errors.has('email')"
                    :placeholder="__('organization:email_of_organizations')"
                />
                <CFormErrorMessageList :errors="errors.get('email')"/>
            </CFormGroup>
            <CFormGroup class="tw-flex-1">
                <CLabel>{{ __('common:phone.general') + '*' }}</CLabel>
                <CInput
                    v-model="newNursery.phone"
                    :has-error="errors.has('phone')"
                    :placeholder="__('common:phone.general')"
                />
                <CFormErrorMessageList :errors="errors.get('phone')"/>
            </CFormGroup>
        </CHStack>

        <CHStack
            class="tw-mt-3"
            gap="5"
        >
            <CFormGroup class="tw-flex-1">
                <CLabel>{{ __('common:address.address') + '*' }}</CLabel>
                <CInput
                    v-model="newNursery.address"
                    :has-error="errors.has('address')"
                    :placeholder="__('common:address.address')"
                />
                <CFormErrorMessageList :errors="errors.get('address')"/>
            </CFormGroup>
        </CHStack>

        <CHStack
            class="tw-mt-3"
            gap="5"
        >
            <CFormGroup class="tw-flex-1">
                <CLabel>{{ __('common:address.zip_code') + '*' }}</CLabel>
                <CInput
                    v-model="newNursery.zipcode"
                    :has-error="errors.has('zipcode')"
                    :placeholder="__('common:address.zip_code')"
                />
                <CFormErrorMessageList :errors="errors.get('zipcode')"/>
            </CFormGroup>
            <CFormGroup class="tw-flex-1">
                <CLabel>{{ __('common:address.city') + '*' }}</CLabel>
                <CInput
                    v-model="newNursery.city"
                    :has-error="errors.has('city')"
                    :placeholder="__('common:address.city')"
                />
                <CFormErrorMessageList :errors="errors.get('city')"/>
            </CFormGroup>
            <CFormGroup class="tw-flex-1">
                <CLabel>{{ __('common:address.country') }}*</CLabel>
                <SelectCountry
                    v-model="newNursery.country"
                    class="tw-w-full"
                    :has-error="errors.has('country')"
                />
                <CFormErrorMessageList :errors="errors.get('country')"/>
            </CFormGroup>
        </CHStack>

        <CHStack
            class="tw-mt-8"
            gap="5"
        >
            <CFormGroup class="tw-flex-1">
                <CLabel>{{ __('common:language') }}</CLabel>
                <CFormSelect
                    v-model="newNursery.locale"
                    :has-error="errors.has('locale')"
                    :options="availableLanguages"
                />
                <CFormErrorMessageList :errors="errors.get('locale')"/>
            </CFormGroup>
            <CFormGroup class="tw-flex-1">
                <CLabel>{{ __('common:number_of_places') + '*' }}</CLabel>
                <CInput
                    v-model="newNursery.places"
                    :has-error="errors.has('places')"
                    placeholder="12"
                />
                <CFormErrorMessageList :errors="errors.get('places')"/>
            </CFormGroup>
            <CFormGroup class="tw-flex-1">
                <CLabel>{{ __('organization:surface') }}</CLabel>
                <CInput
                    v-model="newNursery.surface"
                    :has-error="errors.has('surface')"
                    :placeholder="__('organization:one_hundred_and_forty')"
                />
                <CFormErrorMessageList :errors="errors.get('surface')"/>
            </CFormGroup>
        </CHStack>

        <CHStack
            class="tw-mt-8"
            gap="5"
        >
            <CFormGroup class="tw-flex-1">
                <CLabel>{{ __('common:opening_date') }}</CLabel>
                <CFormDatepicker
                    v-model="newNursery.opening_date"
                    format="YYYY-MM-DD"
                    :has-error="errors.has('opening_date')"
                />
                <CFormErrorMessageList :errors="errors.get('opening_date')"/>
            </CFormGroup>
        </CHStack>

        <CDisclosure
            class="tw-mt-5"
            :open="false"
            :title="__('organization:company_details')"
        >
            <CHStack gap="5">
                <CFormGroup class="tw-flex-1">
                    <CLabel>{{ __('organization:company_name') }}</CLabel>
                    <CInput
                        v-model="newNursery.company_name"
                        :has-error="errors.has('company_name')"
                        :placeholder="__('organization:acronym_or_company_name')"
                    />
                    <CFormErrorMessageList :errors="errors.get('company_name')"/>
                </CFormGroup>
            </CHStack>

            <CHStack
                class="tw-mt-4"
                gap="5"
            >
                <CFormGroup class="tw-flex-1">
                    <CLabel>{{ __('organization:rcs') }}</CLabel>
                    <CInput
                        v-model="newNursery.rcs"
                        :has-error="errors.has('rcs')"
                        :placeholder="__('organization:your_rcs')"
                    />
                    <CFormErrorMessageList :errors="errors.get('rcs')"/>
                </CFormGroup>
                <CFormGroup class="tw-flex-1">
                    <CLabel>{{ __('organization:share_capital') }}</CLabel>
                    <CInput
                        v-model="newNursery.share_capital"
                        :has-error="errors.has('share_capital')"
                        :placeholder="__('organization:share_capital')"
                    />
                    <CFormErrorMessageList :errors="errors.get('share_capital')"/>
                </CFormGroup>
            </CHStack>

            <CHStack
                class="tw-mt-4"
                gap="5"
            >
                <CFormGroup class="tw-flex-1">
                    <CLabel>{{ __('organization:siren') }}</CLabel>
                    <CInput
                        v-model="newNursery.siren"
                        :has-error="errors.has('siren')"
                        :placeholder="__('organization:your_siren')"
                    />
                    <CFormErrorMessageList :errors="errors.get('siren')"/>
                </CFormGroup>
                <CFormGroup class="tw-flex-1">
                    <CLabel>{{ __('organization:siret') }}</CLabel>
                    <CInput
                        v-model="newNursery.siret"
                        :has-error="errors.has('siret')"
                        :placeholder="__('organization:your_siret')"
                    />
                    <CFormErrorMessageList :errors="errors.get('siret')"/>
                </CFormGroup>
            </CHStack>

            <CHStack
                class="tw-mt-4"
                gap="5"
            >
                <CFormGroup class="tw-flex-1">
                    <CLabel>{{ __('common:note_one') }}</CLabel>
                    <CFormTextArea
                        v-model="newNursery.note"
                        :has-error="errors.has('note')"
                        :placeholder="__('common:note_one')"
                    />
                    <CFormErrorMessageList :errors="errors.get('note')"/>
                </CFormGroup>
            </CHStack>
        </CDisclosure>

        <template #footer>
            <div class="tw-flex tw-w-full tw-mt-3">
                <CButton
                    v-if="user.is_owner"
                    class="tw-mr-auto"
                    variant="danger"
                    @click="remove"
                >
                    {{ __('common:actions.delete_dots') }}
                </CButton>
                <CButton
                    class="tw-mr-2"
                    variant="light"
                    @click="$refs.modal.hide(); init();"
                >
                    {{ __('common:actions.cancel') }}
                </CButton>
                <CButton
                    variant="primary"
                    @click="save"
                >
                    {{ __('common:actions.save') }}
                </CButton>
            </div>
        </template>
    </CModal>
</template>

<script>
    import Vue from 'vue';
    import route from '@/modules/legacy/libs/ziggy';
    import useApi from '@/modules/app/composables/useApi';
    import moment from 'moment';
    import __ from '@/modules/app/utils/i18n-facade';
    import _head from 'lodash-es/head';
    import _merge from 'lodash-es/merge';
    import _forEach from 'lodash-es/forEach';
    import swal from 'sweetalert2/dist/sweetalert2.js';
    import SelectCountry from '@/modules/legacy/components/Modules/SelectCountry.vue';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';
    import useLocale from '@/modules/app/composables/useLocale.ts';
    import useError from '@/modules/app/composables/useError.ts';
    import {collect} from '@meekohq/lumos';

    export default {
        components: {SelectCountry},
        props: ['nursery', 'user'],
        data: () => ({
            errors: useError(),
            newNursery: {},
        }),
        computed: {
            moment() {
                return moment;
            },
            availableLanguages() {
                return useLocale().availableLanguages;
            },
        },
        mounted() {
            this.init();
        },
        methods: {
            init() {
                this.newNursery = Object.assign({}, this.nursery);
            },
            save() {
                this.errors.reset();

                const data = {};
                data.name = this.newNursery.name;
                data.email = this.newNursery.email;
                data.phone = this.newNursery.phone;
                data.slug = this.newNursery.slug;
                data.address = this.newNursery.address;
                data.country = this.newNursery.country;
                data.locale = this.newNursery.locale;
                data.zipcode = this.newNursery.zipcode;
                data.city = this.newNursery.city;
                data.places = this.newNursery.places;
                data.surface = this.newNursery.surface;
                data.opening_date = this.newNursery.opening_date;
                data.company_name = this.newNursery.company_name;
                data.rcs = this.newNursery.rcs;
                data.share_capital = this.newNursery.share_capital;
                data.siren = this.newNursery.siren;
                data.siret = this.newNursery.siret;
                data.note = this.newNursery.note;

                useApi().legacy.put(route('nurseries.update', {nurseries: this.nursery.id}), data).then(response => {
                    this.$bus.$emit('refresh:nursery', response.data.id);
                    this.$refs.modal.hide();
                    useNotification().success(__('organization:organization_updated_successfully'));
                    this.errors = [];

                    const nurseryToUpdate = this.user.nurseries.find(n => n.id === response.data.id);

                    if (nurseryToUpdate) {
                        const index = this.user.nurseries.indexOf(nurseryToUpdate);

                        // Preserve the permissions that are not present in the response.data nursery
                        const mergedNursery = _merge(nurseryToUpdate, response.data);

                        Vue.set(this.user.nurseries, index, mergedNursery);
                    }
                }).catch(error => {
                    if (error?.response?.status === 422) {
                        const errors = error.response.data.errors;
                        collect(errors).each((sourceErrors, source) => {
                            collect(sourceErrors).each((message, key) => {
                                this.errors.add(source, key, message);
                            });
                        });
                    } else {
                        useNotification().error(error);
                    }
                });
            },
            remove() {
                this.$refs.modal.hide();
                swal({
                    title: __('organization:delete_organization'),
                    text: __('organization:enter_admin_password'),
                    input: 'password',
                    confirmButtonText: __('common:actions.remove'),
                    cancelButtonText: __('common:actions.cancel'),
                }).then(result => {
                    if (result.value) {
                        const password = result.value;
                        useApi().legacy.delete(route('nurseries.destroy', {nurseries: this.nursery.id}), {
                            params: {
                                password,
                            },
                        }).then(() => {
                            useNotification().success(__('organization:organization_deleted_successfully'));
                            setTimeout(function() {
                                window.location = '/nurseries';
                            }, 500);
                        }).catch(error => {
                            if (error?.response?.status === 422) {
                                _forEach(error.response.data.errors, function(value) {
                                    useNotification().error(_head(value));
                                });
                            } else {
                                useNotification().error(error);
                            }
                        });
                    }
                });
            },
        },
    };
</script>
