import type {Ref} from 'vue';
import {pull} from 'lodash-es';

const ignore: Array<Ref<HTMLElement>> = [];

function useClickOutsideIgnoredElementsStore() {
    function add(el: Ref<HTMLElement>) {
        ignore.push(el);
    }

    function remove(el: Ref<HTMLElement>) {
        pull(ignore, el);
    }

    return {
        add,
        remove,
        ignore,
    };
}

export default useClickOutsideIgnoredElementsStore;
