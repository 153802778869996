<template>
    <CModal
        ref="modal"
        :header-title="__('hr:update_event_type')"
        :shared="shared"
        visible
        @hide="$emit('hide')"
    >
        <EventTypeForm
            ref="form"
            :model="updatedEventType"
            @loading="loading = $event"
            @saved="update"
        />
        <template #footer>
            <CButton
                class="tw-ml-auto"
                @click="onClose"
            >
                {{ __('common:actions.close') }}
            </CButton>
            <CButton
                class="tw-ml-2"
                :loading="loading"
                variant="primary"
                @click="$refs.form.save()"
            >
                {{ __('common:actions.save') }}
            </CButton>
        </template>
    </CModal>
</template>

<script lang="ts">
    import type {PropType, Ref} from 'vue';
    import {defineComponent, ref, watch} from 'vue';
    import type EventTypeModel from '@/modules/human-resources/models/EventTypeModel';
    import EventTypeForm from '@/modules/human-resources/components/event-type/EventTypeForm.vue';
    import __ from '@/modules/app/utils/i18n-facade';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';
    import {useLegacyModalBus} from '@/modules/meeko-ui/composables/useLegacyModalBus';

    export default defineComponent({
        components: {EventTypeForm},
        props: {
            model: {type: Object as PropType<EventTypeModel>, required: true},
            shared: {type: Boolean, default: false},
        },
        setup(props) {
            const loading = ref(false);
            const {modal, hide} = useLegacyModalBus();
            const updatedEventType = ref() as Ref<EventTypeModel>;

            const update = function(eventType) {
                eventType.copyTo(props.model);
                hide();
                useNotification().success(__('hr:event_type_updated_successfully'));
            };

            const onClose = function() {
                updatedEventType.value.rollback();
                hide();
            };

            watch(() => props.model, value => {
                updatedEventType.value = value.clone();
            }, {immediate: true});

            return {
                update,
                onClose,
                modal,
                loading,
                updatedEventType,
                hide,
            };
        },
    });
</script>
