<template>
    <table class="table table-sm">
        <thead>
            <tr class="text-center">
                <th>{{ __('common:date') }}</th>
                <th>{{ __('common:forecasted_male_one') }}</th>
                <th>{{ __('common:real_one') }}</th>
                <th>{{ __('planning:early') }}</th>
                <th>{{ __('planning:late') }}</th>
            </tr>
        </thead>
        <thead v-if="summary && showTotal">
            <tr class="text-center tw-bg-gray-200">
                <th>{{ __('common:total') }}</th>
                <th>{{ getTotalSummary().planning }}</th>
                <th>{{ getTotalSummary().presences }}</th>
                <th>{{ getTotalSummary().arrival_overruns }}</th>
                <th>{{ getTotalSummary().departure_overruns }}</th>
            </tr>
        </thead>
        <tbody>
            <tr
                v-for="(day, i) in summary"
                :key="i"
                class="text-center"
            >
                <td
                    class="text-capitalize"
                    scope="row"
                >
                    {{ Epoch.parse(day.date, 'yyyy-MM-dd').toLocaleString(Epoch.presets.WEEKDAY_WITH_DAY_AND_MONTH) }}
                </td>
                <td>
                    <div
                        class="row m-auto"
                        style="max-width: 150px;"
                    >
                        <div
                            v-if="getSum(day.date, 'recurrent')"
                            class="col p-1"
                        >
                            <MTooltip
                                class="tw-w-full"
                                :label="__('planning:planning_forecast', {sum: getSum(day.date, 'recurrent')})"
                                placement="top"
                            >
                                <span class="planning-badge badge badge-success p-2">
                                    {{ getSum(day.date, 'recurrent') }}
                                </span>
                            </MTooltip>
                        </div>
                        <div
                            v-if="getSum(day.date, 'occasional')"
                            class="col p-1"
                        >
                            <MTooltip
                                class="tw-w-full"
                                :label="__('planning:occasionnal_forecast', {sum: getSum(day.date, 'occasional')})"
                                placement="top"
                            >
                                <span class="planning-badge badge badge-primary p-2">
                                    {{ getSum(day.date, 'occasional') }}
                                </span>
                            </MTooltip>
                        </div>
                        <div
                            v-if="getSum(day.date, 'adaptation')"
                            class="col p-1"
                        >
                            <MTooltip
                                class="tw-w-full"
                                :label="__('planning:adaptation_forecast', {sum: getSum(day.date, 'adaptation')})"
                                placement="top"
                            >
                                <span class="planning-badge badge badge-warning p-2">
                                    {{ getSum(day.date, 'adaptation') }}
                                </span>
                            </MTooltip>
                        </div>
                        <div
                            v-if="getSum(day.date, 'absence')"
                            class="col p-1"
                        >
                            <MTooltip
                                class="tw-w-full"
                                :label="__('planning:absence_forecast', {sum: getSum(day.date, 'absence')})"
                                placement="top"
                            >
                                <span class="badge badge-danger p-2">
                                    {{ getSum(day.date, 'absence') }}
                                </span>
                            </MTooltip>
                        </div>
                    </div>
                </td>
                <td>
                    <div
                        class="row m-auto"
                        style="max-width: 150px;"
                    >
                        <div
                            v-if="getSum(day.date, 'recurrent_presence')"
                            class="col p-1"
                        >
                            <MTooltip
                                class="tw-w-full"
                                :label="__('planning:planning_real', {sum: getSum(day.date, 'recurrent_presence')})"
                                placement="top"
                            >
                                <span class="badge badge-success p-2">
                                    {{ getSum(day.date, 'recurrent_presence') }}
                                </span>
                            </MTooltip>
                        </div>
                        <div
                            v-if="getSum(day.date, 'occasional_presence')"
                            class="col p-1"
                        >
                            <MTooltip
                                class="tw-w-full"
                                :label="__('planning:occasionnal_real', {sum: getSum(day.date, 'occasional_presence')})"
                                placement="top"
                            >
                                <span class="badge badge-primary p-2">
                                    {{ getSum(day.date, 'occasional_presence') }}
                                </span>
                            </MTooltip>
                        </div>
                        <div
                            v-if="getSum(day.date, 'adaptation_presence')"
                            class="col p-1"
                        >
                            <MTooltip
                                class="tw-w-full"
                                :label="__('planning:adaptation_real', {sum: getSum(day.date, 'adaptation_presence')})"
                                placement="top"
                            >
                                <span class="badge badge-warning p-2">
                                    {{ getSum(day.date, 'adaptation_presence') }}
                                </span>
                            </MTooltip>
                        </div>
                        <div
                            v-if="getSum(day.date, 'absence')"
                            class="col p-1"
                        >
                            <MTooltip
                                class="tw-w-full"
                                :label="__('planning:absence_real', {sum: getSum(day.date, 'absence')})"
                                placement="top"
                            >
                                <span class="badge badge-danger p-2">
                                    {{ getSum(day.date, 'absence') }}
                                </span>
                            </MTooltip>
                        </div>
                    </div>
                </td>
                <td>
                    <div
                        class="row m-auto"
                        style="max-width: 150px;"
                    >
                        <div
                            v-for="(overrun, i) in day.arrival_overruns"
                            :key="'overrun_arrival' + i"
                            class="col p-1"
                        >
                            <MTooltip
                                class="tw-w-full"
                                :label="__('planning:overrun') + ' ' + getTimeFromMins(overrun)"
                                placement="top"
                            >
                                <span
                                    v-if="overrun"
                                    :key="i"
                                    class="badge badge-primary p-2"
                                >
                                    {{ getTimeFromMins(overrun) }}
                                </span>
                            </MTooltip>
                        </div>
                    </div>
                </td>
                <td>
                    <div
                        class="row m-auto"
                        style="max-width: 150px;"
                    >
                        <div
                            v-for="(overrun, i) in day.departure_overruns"
                            :key="'overrun_departure' + i"
                            class="col p-1"
                        >
                            <MTooltip
                                class="tw-w-full"
                                :label="__('planning:overrun') + ' ' + getTimeFromMins(overrun)"
                                placement="top"
                            >
                                <span
                                    v-if="overrun"
                                    :key="i"
                                    class="badge badge-primary p-2"
                                >
                                    {{ getTimeFromMins(overrun) }}
                                </span>
                            </MTooltip>
                        </div>
                    </div>
                </td>
            </tr>
        </tbody>
        <tfoot v-if="summary && showTotal">
            <tr class="text-center tw-bg-gray-200">
                <th>{{ __('common:total') }}</th>
                <th>{{ getTotalSummary().planning }}</th>
                <th>{{ getTotalSummary().presences }}</th>
                <th>{{ getTotalSummary().arrival_overruns }}</th>
                <th>{{ getTotalSummary().departure_overruns }}</th>
            </tr>
        </tfoot>
    </table>
</template>

<script>
    import {getTimeFromMins, getTotalSummary} from '@/modules/legacy/libs/Summary.ts';
    import {Epoch} from '@meekohq/lumos';

    export default {
        props: {
            nursery: {},
            summary: {},
            billingDisplay: {},
            showTotal: {
                type: Boolean,
                default: true,
            },
        },
        computed: {
            Epoch() {
                return Epoch;
            },
        },
        methods: {
            getSum(date, type) {
                const day = this.summary.find(item => item.date === date);

                if (!day) {
                    return null;
                }

                if (
                    this.billingDisplay === 'day' ||
                    this.billingDisplay === 'halfDay'
                ) {
                    let am = false;
                    let pm = false;

                    day.am[type] ? (am = true) : null;
                    day.pm[type] ? (pm = true) : null;

                    if (am && pm) {
                        return this.__('common:day_female_one');
                    } else if (am) {
                        return this.__('common:morning');
                    } else if (pm) {
                        return this.__('common:afternoon');
                    } else {
                        return null;
                    }
                } else {
                    return day.am[type] + day.pm[type]
                        ? this.getTimeFromMins(day.am[type] + day.pm[type])
                        : null;
                }
            },
            getTimeFromMins(minutes) {
                return getTimeFromMins(minutes);
            },
            getTotalSummary() {
                return getTotalSummary(this.summary, this.billingDisplay);
            },
        },
    };
</script>

<style lang="scss" scoped>
    .table {
        td,
        th {
            vertical-align: middle;
            border: 1px solid #eee;
        }

        .badge {
            min-width: 50px;
            width: 100%;

            &.badge-disabled {
                background-color: #c6dbf3;
                color: #2e83e0;
            }
        }
    }

    @media print {
        .badge {
            border: 0 !important;
            -webkit-print-color-adjust: exact !important; /* Chrome, Safari */
            print-color-adjust: exact !important; /*Firefox*/
        }
    }
</style>
