<template>
    <div
        class="Segment"
        :style="stylingSegmentFn(segment)"
        @mouseleave="onMouseLeave"
        @mouseover="onMouseOver"
    >
        <p class="Segment__label">
            {{ segment.count }}
        </p>

        <CDropdown
            v-if="!hideTooltip && tooltipTextFn && tooltipTextFn(segment).length > 0"
            ref="tooltip"
            legacy-mode
            placement="top"
        >
            <CText>
                <div v-html="tooltipTextFn(segment)"/>
            </CText>
        </CDropdown>
    </div>
</template>

<script lang="ts">
    import type {PropType} from 'vue';
    import {defineComponent, ref} from 'vue';

    export default defineComponent({
        props: {
            segment: {
                type: Object as PropType<{ count: number }>,
                required: true,
            },
            stylingSegmentFn: {
                type: Function as PropType<(segment) => Object>,
                default: () => ({}),
            },
            tooltipTextFn: {
                type: Function as PropType<(segment) => string>,
                default: undefined,
            },
            hideTooltip: {
                type: Boolean,
                default: false,
            },
        },
        setup(props) {
            const tooltip = ref();

            function onMouseOver() {
                if (!props.hideTooltip && props.tooltipTextFn && props.tooltipTextFn(props.segment).length > 0) {
                    tooltip.value.show();
                }
            }

            function onMouseLeave() {
                if (!props.hideTooltip && props.tooltipTextFn && props.tooltipTextFn(props.segment).length > 0) {
                    tooltip.value.hide();
                }
            }

            return {
                onMouseLeave,
                onMouseOver,
                tooltip,
            };
        },
    });
</script>

<style lang="scss" scoped>
    .Segment {
        @apply tw-flex tw-items-center tw-justify-center tw-h-full tw-absolute;
        @apply tw-text-white tw-font-semibold tw-text-sm tw-text-ellipsis;
        @apply first:tw-rounded-l-inherit last:tw-rounded-r-inherit;
    }

    .Segment__label {
        @apply tw-font-semibold tw-text-sm tw-text-white;
    }

</style>
