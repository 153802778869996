import {ServiceProvider} from '@meekohq/lumos';
import CallModel from '@/modules/activity/models/CallModel';
import CallPivot from '@/modules/activity/models/CallPivot';
import TicketModel from '@/modules/activity/ticket/domain/TicketModel';
import TicketPivot from '@/modules/activity/ticket/domain/TicketPivot';
import MeetingModel from '@/modules/activity/models/MeetingModel';
import MeetingPivot from '@/modules/activity/models/MeetingPivot';
import MeetingParticipantModel from '@/modules/activity/models/MeetingParticipantModel';
import {Types} from '@/types';
import type {GetTasksUseCase} from '@/modules/activity/ticket/application/use-cases/GetTasksUseCase';
import {GetTaskService} from '@/modules/activity/ticket/application/GetTaskService';
import TicketRepositoryAdapter from '@/modules/activity/ticket/infrastructure/TicketRepositoryAdapter';

export default class ActivityServiceProvider extends ServiceProvider {
    public boot() {
        this.loadModelsFrom([
            CallModel,
            CallPivot,
            TicketModel,
            TicketPivot,
            MeetingModel,
            MeetingPivot,
            MeetingParticipantModel,
        ]);
    }

    public register() {
        this.app.bind<GetTasksUseCase>(Types.GetTasksUseCase).toCallback(() => {
            return new GetTaskService(new TicketRepositoryAdapter());
        });
    }
}
